const privacyPolicyContent = {
  privacyPolicyHeader: 'Privacy Policy',
  lastUpdate: 'Last updated: August 05, 2019',
  acceptanceAndApplication: {
    title: 'ACCEPTANCE AND APPLICATION',
    clauses: [
      'Solutions Guiker Inc. ("Corporation", "we," "us" or "our") provides this privacy policy (“Privacy Policy”) to inform you (“User” or “Users” or “you”, which includes any user of the Services (as defined below) affiliated, employed or otherwise contracted by the User) of our policies and procedures regarding the collection, use and disclosure of personal information and data we receive from Users using the Corporation’s website, products and services (the “Services” or the “Guiker Platform”). This Privacy Policy applies to any and all persons using the Services. This Privacy Policy applies to our Payment Services as defined in the Terms of Service. ',
      'Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meaning as in our Terms of Service. Be sure to return to this page periodically to review the most current version of the Privacy Policy. We reserve the right at any time, at our sole discretion, and without any liability whatsoever, to change or otherwise modify the Privacy Policy without prior notice, and your continued access or use of the Services signifies your acceptance of the updated or modified Privacy Policy. Following a modification, we will update the "Last Updated" date at the top of the Privacy Policy. By continuing to access or use the Services, you are indicating that you agree to be bound by the modified Privacy Policy. If the Privacy Policy or any future modified version is not acceptable to you, please do not use the Services.',
    ],
  },
  informationCollectionAndUse: {
    title: 'INFORMATION COLLECTION AND USE',
    infoProvidedToUs: {
      title: 'Information You Provide to Us. ',
      forPlatform: {
        title: 'Information that is necessary for the use of the Guiker Platform.',
        clause:
          'We collect the following personal information about you when you use the Services. This information is necessary for the adequate performance of the Services and to allow us to comply with our legal obligations. Without it, we may not be able to provide you with all the requested Services.',
        list: [
          {
            title: 'Account Information. ',
            clause:
              'When you sign up for a Guiker Account, we require information such as your first name, last name, email address, phone number, and date of birth;',
          },
          {
            title: 'Application Information. ',
            clause:
              'When you apply for a Listing with your Guiker Account, we require information such as your potential co-tenants and dependants, your occupation, your nationality, your gender, your drivers license number, information about your pet or your car, your current living situation including your current address and your move out date, your current rent, your current landlord’s phone number and e-mail, and the reason why you are leaving your current appartment, your current salary, your student status and your curriculum vitae as well as your linkedin profile;',
          },
          {
            title: 'Guarantor Information. ',
            clause:
              'In certain situations we may ask for a guarantor and require their contact information, their name, their curriculum vitae, a copy of their official ID, as well as their proof of income;',
          },
          {
            title: 'Listing Information. ',
            clause:
              'To create a Listing on the Guiker Platform, we may ask you to provide additional information, which may include your address, phone number, personal references, personal preferences, and a profile picture;',
          },
          {
            title: 'Identity Verification Information. ',
            clause:
              'To help create and maintain a trusted environment, we may collect identity verification information (such as images of your government issued ID, passport, national ID card, or driving license, as permitted by applicable laws) or other authentication information;',
          },
          {
            title: 'Payment Information.',
            clause:
              'To use certain features of the Guiker Platform (such as signing a Lease, creating a Listing or applying for a Listing), we may require you to provide certain financial information (like your bank account or credit card information) in order to facilitate the processing of payments;',
          },
          {
            title: 'Communications with Guiker and other Users. ',
            clause:
              'When you communicate with Guiker or use the Guiker Platform to communicate with other Users, we collect information about your communication and any other information you choose to provide.',
          },
        ],
      },
      forPayment: {
        title: 'Information that is necessary for the use of the Payment Services.',
        clause:
          'Guiker needs to collect the following information in order to ensure the adequate performance of the Services and to comply with applicable law. Without it, you will not be able to use the Payment Services:',
        list: [
          {
            title: 'Payment Information. ',
            clause:
              'When you use the Payment Services, Guiker requires certain financial information (like your bank account or credit card information) in order to process payments and comply with applicable law;',
          },
          {
            title: 'Identity Verification and Other Information. ',
            clause:
              'We may require identity verification information (such as images of your government issued ID, passport, or drivers license) or other authentication information, your date of birth, your address, your email address, your phone number and other information in order to verify your identity, provide the Payment Services to you, and to comply with applicable law.',
          },
        ],
      },
    },
    informationPaymentAutoCollected: {
      title: 'Information We Automatically Collect when you use the Guiker Platform and Payment Services.',
      clause:
        'When you use the Guiker Platform and the Payment Services, we automatically collect personal information about the Services you use and how you use them. This information is necessary for the Services to perform adequately, to enable us to comply with legal obligations as well as to provide and improve the functionalities of the Guiker Platform and the Payment Services.',
      list: [
        {
          title: 'Geo-location Information. ',
          clause:
            'When you use certain features of the Guiker Platform, we may collect information about your precise or approximate location using data such as your IP address or mobile device’s GPS in order to offer you a better user experience. On most mobile devices, the use of location services can be controlled or disabled in the device’s settings menu. Depending on your settings or device permissions, this information may be collected by Guiker even when you are not using the website.',
        },
        {
          title: 'Usage Information. ',
          clause:
            'We collect information about how you interact with the Guiker Platform such as the pages or content you view, your searches for Listings, Listing applications you have made, and other actions on the Guiker Platform.',
        },
        {
          title: 'Log Data and Device Information. ',
          clause:
            'When you access or use the Guiker Platform, we automatically collect log data and device information, even if you have not created a Guiker Account or logged in. That information includes, but is not limited to: details about how you’ve used the Guiker Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Guiker Platform.',
        },
        {
          title: 'Cookies and Similar Technologies. ',
          clause:
            'We use cookies and other similar technologies when you use our platform, or engage with our online ads or email communications. A cookie is a small data file which is transferred to your device and which allows us recognize your browser and store your preferences. We may collect certain information by automatically using technologies such as cookies, web beacons, pixels, browser analysis tools, server logs, and mobile identifiers. In many cases the information we collect using cookies and other tools is only used in a non-identifiable way without reference to personal information. For example, we may use information we collect to better understand website traffic patterns and to optimize our website experience. In some cases we associate the information we collect using cookies and other technology with your personal information. Our business partners may also use these tracking technologies on the Guiker Platform or engage others to track your behavior on our behalf. We use cookies and similar technologies to :',
          list: [
            'enable you to use and access the Guiker Platform and the Payment Services.',
            'enable and facilitate the functioning of and your access to the Guiker Platform.',
            'better understand how you navigate through and interact with the Guiker Platform and to improve the Guiker Platform.',
            'serve you tailored advertising.',
            'show you content that is more relevant to you.',
            'monitor and analyze the performance, operation, and effectiveness of the Guiker Platform and Guiker advertisements.',
            'enforce legal agreements that govern your use of the Guiker Platform.',
            'prevent and detect fraud detection, for trust and safety, and for investigations.',
            'provide customer support, analytics, research, product development, and regulatory compliance.',
          ],
          closingStatement:
            'The Help feature on most browsers will tell you how to manage or disable your cookies. Your mobile device may allow you to control cookies through its settings function. Refer to your device manufacturer’s instructions for more information. If you choose to decline cookies, some parts of the Guiker Platform may not work as intended or may not work at all.',
        },
        {
          title: 'Pixels and SDKs. ',
          clause:
            'Third parties, including Facebook, may use cookies, web beacons, and other storage technologies to collect or receive information from our websites and elsewhere on the internet and use that information to provide measurement services and target ads. Third party apps, including Facebook, may collect or receive information from other apps and use that information to provide measurement services and targeted ads. Users can opt-out of the collection and use of information for ad targeting by updating their Facebook account ad settings and by contacting compliance@guiker.com.',
        },
        {
          title: 'Do Not Track Signals. ',
          clause:
            'While you may disable the usage of cookies through your browser settings, the Guiker Platform currently does not have the ability to respond to a "Do Not Track" signal in the HTTP header from your browser or mobile application.',
        },
        {
          title: 'Payment Transaction Information. ',
          clause:
            'Guiker collects information related to your payment transactions through the Guiker Platform, including the payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, your email address, bank account details, your address, the IP address where the transaction was performed and other related transaction details. This information is necessary for the adequate performance of the Services and to allow us to provide you with the Payment Services.',
        },
      ],
    },
  },
  useInformationCollected: {
    title: 'HOW WE USE THE INFORMATION WE COLLECT',
    clause:
      'We may use, store, and process personal information to (1) provide, understand, improve, and develop the Guiker Platform, (2) create and maintain a trusted and safe environment and (3) provide, personalize, and improve our advertising and marketing.',
    list: [
      {
        title: 'Provide, Improve, and Develop the Guiker Platform. ',
        clause: 'We may use the personal information to provide, improve, and develop the Guiker Platform such as to:',
        usages: [
          'create your account and allow you to access the Guiker Platform and the Services,',
          'enable you to communicate with other Users,',
          'provide customer service,',
          'To perform internal operations, including, for example, to prevent fraud and abuse of our Services; to troubleshoot software bugs and operational problems; to conduct data analysis, testing and research; and to monitor and analyze usage and activity trends within the Guiker Platform;',
          'send you service or support messages, updates, security alerts, and account notifications;',
          'if you provide us with your contacts’ information, we may process this information: (i) to facilitate your referral invitations, (ii) send your requests for references, (iii) for fraud detection and prevention, and (iv) for any purpose you authorize at the time of collection,',
          'to operate, protect, improve, and optimize the Guiker Platform and experience, and personalize and customize your experience (such as making Listing suggestions, ranking search results), we conduct profiling based on your interactions with the Guiker Platform, your search history, your profile information and preferences, and other content you submit to the Guiker Platform,',
        ],
      },
      {
        title: 'Create and Maintain a Trusted and Safer Environment. ',
        clause: 'We may use the personal information to create and maintain a trusted and safe environment such as to:',
        usages: [
          'detect and prevent fraud, spam, abuse, security incidents, and other harmful activity,',
          'conduct security investigations and risk assessments,',
          'verify or authenticate information or identifications provided by you,',
          'conduct checks against databases and other information sources, including background or police checks, to the extent permitted by applicable laws and with your consent where required,',
          'comply with our legal obligations,',
          'resolve any disputes with any of our Users and enforce our agreements with third parties,',
          'enforce our Terms of Service and other policies, and',
          'in connection with the activities above, we may conduct profiling based on your interactions with the Guiker Platform, your profile information and other content you submit to the Guiker Platform, and information obtained from third parties.',
        ],
      },
      {
        title: 'Provide, Personalize, and Improve our Advertising and Marketing. ',
        clause:
          'We may use the personal information to provide, personalize, measure, and improve our advertising and marketing such as to:',
        usages: [
          'send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences (including information about Guiker or partner campaigns and services) and social media advertising through social media platforms such as Facebook, Instagram or Google),',
          'personalize, measure, and improve our advertising,',
          'Administer referral programs, surveys, contests, or other promotional activities or events sponsored or managed by Guiker or its third party partners;',
          'conduct profiling on your characteristics and preferences (based on the information you provide to us, your interactions with the Guiker Platform, information obtained from third parties, and your search and booking history) to send you promotional messages, marketing, advertising and other information that we think may be of interest to you,',
        ],
      },
    ],
    closeStatement:
      'We process this personal information we collect for the purposes listed above given our legitimate interest in protecting the Guiker Platform, to measure the adequate performance of our Services, and to comply with applicable laws. Additionally, Personal Information may be used and disclosed to the extent the Corporation may deem reasonably necessary to enforce the terms of any agreement between you and the Corporation, or to protect the rights, property or safety of any person or entity, or if required by law, especially in response to a demand from government authorities.',
  },
  rightToExamineInfo: {
    title: 'RIGHT TO EXAMINE INFORMATION',
    clause:
      'You have the right to examine any of your Personal Information that we collect. Should you wish to examine such information, please send us a written request to compliance@guiker.com. We reserve the right to charge you a reasonable administrative fee to access your information, as permitted by applicable law. In certain cases we may not be able to provide you with access to all of your Personal Information (ex: if the information also pertains to the Personal Information of another User).',
  },
  withdrawalOfConsent: {
    title: 'WITHDRAWAL OF CONSENT',
    clause:
      'You may withdraw your consent to the collection of Personal Information at any time by sending a written request to compliance@guiker.com. Upon receiving notice that you have revoked your consent, we will stop using your Personal Information within a reasonable time, which will vary depending on what information we have collected and for what purpose. Please note that we will send you an email confirmation upon receipt of your request. Please note that if you do choose to withdraw such consent, your experience of the Services may be diminished.',
  },
  promotionalCommunications: {
    title: 'PROMOTIONAL COMMUNICATIONS',
    clause:
      'You may opt out of receiving promotional messages from us by following the instructions in those messages. If you opt out, we may still send you non-promotional communications, such as those about your account, about Services you have requested, or our ongoing business relations.',
  },
  sharingInfo: {
    title: 'SHARING INFORMATION',
    clause:
      'We will not sell, rent or disclose to outside parties the information we collect, save and except that we may share the collected information with other parties as follows:',
    list: [
      {
        title: 'Affiliated Service Providers: ',
        clause:
          'We have agreements with various affiliated service providers to facilitate the rendering of our Services, with whom we may share the information we have collected. All administrative service providers that we use are required to have the same level of privacy protection as we have, and therefore we expect that your information will be handled with the same level of care that we employ.',
      },
      {
        title: 'Statistical Analysis: ',
        clause:
          'We may share Non-Personal Information and aggregated anonymized information with third parties, including but not limited to, for statistical, machine learning, advertising or marketing purposes. Non Personal Information will be shared in this manner. Non-Personal information is information we collect which does not allow you to be personally identified such as your anonymous IP address and location.',
      },
      {
        title: 'Transactions: ',
        clause:
          'In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation where Personal Information may be disclosed or transferred as one of our business assets. However, under no circumstances will such transactions result in your information being used for other purposes than those for which you gave us your consent.',
      },
    ],
  },
  security: {
    title: 'SECURITY',
    clause:
      'The Services have security measures in place to protect the loss, misuse, and alteration of the information under our control. Please note, however, that no data security measures can be guaranteed to be completely effective. Consequently, we cannot ensure or warrant the security of any Personal Information or other information. You transmit Personal Information to us at your own risk.',
  },
  employeesAgentsAndContractors: {
    title: 'EMPLOYEES, AGENTS AND CONTRACTORS',
    clauses: [
      'The Corporation employs other companies and/or individuals to perform functions on our behalf. We may appoint third parties to develop software applications and/or databases functions or to send communications on our behalf. We may also appoint third parties to perform functions such as but not limited to cleaning services, minor repair services, bug control services, plumbing services, or maintenance services. ',
      'Your Personal Information may be disclosed to such individuals in the course of the Corporation’s business operations. All of the Corporation’s employees, agents, affiliates and contractors who have access to your Personal Information are required to protect the information in a manner that is consistent with this Privacy Policy.',
    ],
  },
  thirdParties: {
    title: 'THIRD PARTIES',
    clause:
      'Guiker may collect information, including personal information, that others provide about you when they use the Guiker Platform and the Payment Services, or obtain information from other sources and combine that with information we collect through the Guiker Platform and the Payment Services. We have no control or supervise or respond to how the third parties providing your information process your Personal Information, and are not responsible for their privacy practices. We encourage you to read their privacy policies. Any information request regarding the disclosure of your personal information to us should be directed to such third parties.',
    list: [
      {
        title: 'Third Party Services. ',
        clause:
          'If you link, connect, or login to your Guiker Account with a third party service (e.g. Google, Facebook), the third party service may send us information such as your registration, friends list, and profile information from that service. This information varies and is controlled by that service or as authorized by you via your privacy settings specific to that service.',
      },
      {
        title: 'Background Information. ',
        clause:
          'Guiker may, to the extent permitted by applicable laws and with your consent when it is required, obtain police and background checks. We may use your information, including your full name and date of birth, to obtain such reports.',
      },
      {
        title: 'Referrals. ',
        clause:
          'If you are invited to the Guiker Platform through a referral by another person, the person who invited you may submit personal information about you such as your contact information including your e-mail address.',
      },
      {
        title: 'Other Sources. ',
        clause:
          'To the extent permitted by applicable law, in an effort to prevent fraud and assess risk, we may receive additional information about you through third party service providers (for example fraud warnings from third party fraud prevention providers or background check results from identity verification services) and combine it with information we already have about you.',
      },
    ],
  },
  internationalTransfer: {
    title: 'INTERNATIONAL TRANSFER',
    clause:
      'Your information may be transferred to and maintained on computers located outside of your jurisdiction, where privacy laws may not be as protective as your jurisdiction. Your consent to this Privacy Policy represents your consent to any such transfer.',
  },
  contactUs: {
    title: 'CONTACT US',
    clause:
      'If you have any questions, comments, or concerns about this Privacy Policy, please feel free to email us at compliance@guiker.com.',
  },
  termsOfService: {
    title: 'TERMS OF SERVICE',
    clause:
      'This Privacy Policy is incorporated into and forms part of our Terms of Service, which outlines the terms and conditions you agree to when using the Services, and which can be found at the bottom of most of the pages of the website.',
  },
}

export { privacyPolicyContent }
