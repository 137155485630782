import React, { useEffect, useState } from 'react'

import {
  ApiForm,
  Button,
  Divider,
  extractFieldError,
  FeedbackComponent,
  Flex,
  Link,
  makeStyles,
  P,
  SecondaryButton,
  SuccessDialog,
  TextField,
  theme,
  useFieldArray,
  useForm,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'

import { useBookingContext } from '../../../../../hooks'
import { BookingPanelModal } from '../../../../BookingPanelModal'

type ApplicantInvitationFormProps = React.PropsWithChildren & {
  bookingId: string
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  disablePortal?: boolean
}

const useStyles = makeStyles({
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'block',
    paddingTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.status.alert.main,
  },
})

type Invitee = {
  firstName: string
  emailAddress: string
}

const createInvitee = (): Invitee => ({
  firstName: '',
  emailAddress: '',
})

type Invitation = {
  invitees: Invitee[]
}

const ApplicantInvitationForm: React.FC<ApplicantInvitationFormProps> = ({ bookingId, open = false, setOpen }) => {
  const classes = useStyles()
  const { t } = useTranslation(['main-booking', 'common'])
  const { apiClient } = useBookingContext()
  const [apiErrorMsg, setApiErrorMsg] = useState<string>()
  const [invitationSentModalOpen, setInvitationSentModalOpen] = useState(false)

  const { mutate: inviteBookingApplicants, status } = useMutation(
    ({ invitees }: { invitees: Invitee[] }) =>
      apiClient.inviteBookingApplicants({ pathParams: { bookingId }, payload: invitees }),
    {
      onSuccess: () => {
        setInvitationSentModalOpen(true)
      },
      onError: (error: Error) => {
        if (error['meta']?.errorCode === 'InvitedApplicantIsOneOfParticipantsError') {
          setApiErrorMsg(t('main-booking:errors.invitedApplicantIsOneOfParticipantsError'))
        } else {
          setApiErrorMsg(t('main-booking:errors.inviteApplicantsFailed'))
        }
      },
    },
  )

  const formMethods = useForm<Invitation>({ defaultValues: { invitees: [] as Invitee[] } })
  const { control, reset: resetForm, formState } = formMethods
  const { fields, append } = useFieldArray({ control, name: 'invitees' })

  const handleClose = () => {
    resetForm({ invitees: [createInvitee()] })
    setInvitationSentModalOpen(false)
    setOpen(false)
  }

  useEffect(() => {
    fields?.length === 0 && append(createInvitee())
  }, [])

  return (
    <>
      <ApiForm formName='BookingApplicantInviteForm' onSubmit={inviteBookingApplicants}>
        <BookingPanelModal
          open={open}
          onClose={handleClose}
          title={t('main-booking:actions.invite.inviteApplicantsTitle')}
          subtitle={t('main-booking:actions.invite.inviteApplicantsSubtitle')}
          actions={
            <>
              <SecondaryButton onClick={handleClose} disabled={status === 'loading'} fullWidth>
                {t('common:actions.cancel')}
              </SecondaryButton>
              <Button type='submit' disabled={status === 'loading'} fullWidth>
                {t('common:actions.invite')}
              </Button>
            </>
          }
        >
          <Flex flexDirection='column' gap={2}>
            {fields.map((item, index, all) => (
              <Flex key={item.id} flexDirection='column' gap={2}>
                <TextField maxWidth='100%' label={t('common:user.firstName')} name={`invitees.${index}.firstName`} />
                <TextField
                  maxWidth='100%'
                  label={t('common:user.emailAddress')}
                  type='email'
                  name={`invitees.${index}.emailAddress`}
                />
                {index !== all.length - 1 && <Divider />}
              </Flex>
            ))}
            {!!extractFieldError(formState.errors, 'invitees')?.type && (
              <P color={'alert'}>
                {t(`main-booking:errors.${extractFieldError(formState.errors, 'invitees').type}Applicants`)}
              </P>
            )}
            <Link className={classes.link} onClick={() => append(createInvitee())}>
              {t('main-booking:actions.invite.inviteMorePeople')}
            </Link>
            {apiErrorMsg && (
              <FeedbackComponent
                loading={status === 'loading'}
                toasterMessage={apiErrorMsg}
                toasterType='error'
                onToasterClose={() => setApiErrorMsg(undefined)}
              />
            )}
          </Flex>
        </BookingPanelModal>
      </ApiForm>

      <InvitationSentModal
        open={invitationSentModalOpen}
        buttonProps={{ onClick: handleClose, children: t('common:actions.close') }}
        text={t('main-booking:actions.invite.invitationSentSuccess')}
        illustration={null}
        maxWidth='initial'
      />
    </>
  )
}

const InvitationSentModal = (props: any) => <SuccessDialog {...props} keepMounted={false} />

export { ApplicantInvitationForm }
