import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const leaseTerm = yup.object<Omit<Ontario.LeaseTerm, 'completed'>>({
  type: yup.mixed<Ontario.LeaseTermTypes>().oneOf(Object.values(Ontario.LeaseTermTypes)).required(),
  startDate: yup.string().required(),
  endDate: yup.string().when('type', {
    is: Ontario.LeaseTermTypes.Fixed,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
  otherDescription: yup.string().when('type', {
    is: Ontario.LeaseTermTypes.Other,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

export type LeaseTerm = TypeOf<typeof leaseTerm>
