import React from 'react'

import { Flex, P, PBig, theme, useMediaQuery } from '@guiker/components-library'

type iconDescriptionProps = {
  icon?: React.ReactNode
  icon2?: React.ReactNode
  description: string
  value?: string | number
  value2?: string | number
}

export const IconDescription: React.FC<iconDescriptionProps> = ({ icon, icon2, value2, description, value }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ValueComponent = isMobile ? P : PBig

  if (isMobile) {
    return (
      <Flex pl={3} gap={1} flexDirection={'row'} alignItems='center'>
        <Flex alignItems='center' justifyContent={'flex-start'} gap={2}>
          {icon}
          <Flex alignItems='center' justifyContent={'center'} gap={1}>
            {value && <ValueComponent>{value}</ValueComponent>}
            {!!value2 ? <ValueComponent>{`+ ${value2}`}</ValueComponent> : undefined}
            <P mb={0}>{description}</P>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex gap={1} flexDirection={'column'} justifyContent={'flex-end'} alignItems='center'>
      <Flex alignItems='center' justifyContent={'flex-end'} gap={1}>
        {value && <ValueComponent>{value}</ValueComponent>}
        {icon}
        {icon2 && value2 ? (
          <Flex alignItems='center' justifyContent={'flex-end'} gap={1}>
            <ValueComponent>+ {value2}</ValueComponent>
            {icon2}
          </Flex>
        ) : undefined}
      </Flex>
      <P mb={0}>{description}</P>
    </Flex>
  )
}
