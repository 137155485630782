import { EnvelopeStatus, ParticipantDocumentStatus } from '@guiker/document-shared'
import { last } from '@guiker/lodash'

import { BookingDocument } from '..'
import { ParticipantStepStatus } from '../participant'

const findAllActiveDocuments = (documents: BookingDocument[], isApplicant?: boolean): BookingDocument[] => {
  const inactiveStatus = isApplicant ? [EnvelopeStatus.CANCELLED, EnvelopeStatus.CREATED] : [EnvelopeStatus.CANCELLED]
  return documents.filter((d) => !inactiveStatus.includes(d.status))
}

export const inferApplicantDocumentStepStatus = (
  documents: BookingDocument[],
  userId: string,
): ParticipantStepStatus => {
  const deliveredDocuments = findAllActiveDocuments(documents, true)
  if (deliveredDocuments.length === 0) {
    return ParticipantStepStatus.NOT_READY
  }
  const activeDocument = last(deliveredDocuments)
  const documentParticipant = activeDocument.participants.find((p) => p.userId === userId)
  if (documentParticipant?.documents?.every((d) => d.status === ParticipantDocumentStatus.COMPLETED)) {
    return ParticipantStepStatus.COMPLETED
  }
  if (documentParticipant?.documents?.every((d) => d.status === ParticipantDocumentStatus.INCOMPLETED)) {
    return ParticipantStepStatus.NOT_STARTED
  }
  return ParticipantStepStatus.STARTED
}

export const inferUnitManagerDocumentStepStatus = (
  documents: BookingDocument[],
  canEditDocument: boolean,
): ParticipantStepStatus => {
  const activeDocuments = findAllActiveDocuments(documents)
  if (activeDocuments.length === 0 && !canEditDocument) {
    return ParticipantStepStatus.NOT_READY
  }
  if (activeDocuments.some((d) => d.status === EnvelopeStatus.COMPLETED)) {
    return ParticipantStepStatus.COMPLETED
  }
  if (activeDocuments.some((d) => [EnvelopeStatus.CREATED, EnvelopeStatus.SENT].includes(d.status))) {
    return ParticipantStepStatus.STARTED
  }
  return ParticipantStepStatus.NOT_STARTED
}
