import React from 'react'

import {
  DatePicker,
  FormSubSection,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { GuarantorProfile, TenantApplication } from '@guiker/tenant-application-shared'

type GuarantorProfileProps = {
  defaultValue: Partial<TenantApplication>
  formPrefix: string
  schema?: yup.ObjectSchema
  readOnly?: boolean
}

const GuarantorProfile: React.FC<GuarantorProfileProps> = ({ defaultValue, formPrefix, schema, readOnly = false }) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const hasGuarantor = useWatch<TenantApplication, 'hasGuarantor'>({ name: 'hasGuarantor' })
  const willInviteGuarantor = useWatch<TenantApplication, 'willInviteGuarantor'>({
    name: 'willInviteGuarantor',
  })

  const namespace = 'main-tenantApplication:user'
  const dependency = {
    hasGuarantor,
    willInviteGuarantor,
  }

  const inputProps = useGetInputProps({
    namespaces: ['common', 'main-tenantApplication'],
    tPrefix: 'common:user',
    formPrefix,
    defaultValue,
    schema,
    readOnly,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
  })

  return (
    <FormSubSection title={t(`main-tenantApplication:screens.steps.guarantor.profile.title`)}>
      <TwoColumnsGridLayout>
        <TextField {...inputProps('legalFirstName', { dependency })} />
        <TextField {...inputProps('legalLastName', { dependency })} />
        <TextField {...inputProps('occupation', { dependency })} />
        <TextField {...inputProps('annualSalary', { namespace, dependency })} />
        <TextField {...inputProps('phoneNumber', { dependency })} />
        <DatePicker disableFuture {...inputProps('dateOfBirth', { namespace, dependency })} />
      </TwoColumnsGridLayout>
    </FormSubSection>
  )
}

export { GuarantorProfile }
