import { yup } from '@guiker/shared-framework'

export const searchStringSchema = yup.object<{
  searchString?: string
}>({
  searchString: yup
    .string()
    .nullable()
    .transform((v) => (!!v ? v : null)),
})

export type SearchStringSchema = yup.InferType<typeof searchStringSchema>
