import React from 'react'

import { Checkbox, useGetInputProps, useTranslation } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { Consent } from '@guiker/tenant-application-shared'

type BackgroundCheckConsentFormContentProps = {
  defaultValue: Consent
  formPrefix: string
  schema?: yup.ObjectSchema
  readOnly?: boolean
  disabled?: boolean
  consentFor?: string
}

const BackgroundCheckConsentFormContent: React.FC<BackgroundCheckConsentFormContentProps> = ({
  defaultValue,
  formPrefix,
  schema,
  readOnly = false,
  disabled = false,
  consentFor = 'applicant',
}) => {
  const { t } = useTranslation(['main-tenantApplication'])

  const inputProps = useGetInputProps({
    namespaces: 'main-tenantApplication',
    tPrefix: 'consent',
    formPrefix,
    defaultValue,
    schema,
    readOnly,
  })

  return (
    <Checkbox
      {...inputProps('isConsentGiven')}
      label={t('consent.isConsentGiven', {
        person: consentFor === 'applicant' ? t('consent.applicant') : t('consent.guarantor'),
      })}
      labelPlacement='end'
      defaultValue={defaultValue?.isConsentGiven ? defaultValue?.isConsentGiven : false}
      justifyContent='flex-start'
      maxWidth='100%'
      readOnly={readOnly}
      disabled={disabled}
    />
  )
}

export { BackgroundCheckConsentFormContent }
