import React from 'react'

import { useLayoutContext } from '@guiker/components-library'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { InfiniteList, InfiniteListProps, PaginatedGrid } from '@guiker/react-query-components'

import { PropertySaleListingCard } from '../PropertySaleListingCard'

type PropertySaleListingGridProps = Omit<InfiniteListProps<PropertySaleListing>, 'CellComponent'>

export const PropertySaleListingGrid: React.FC<PropertySaleListingGridProps> = ({ queryKey, ...props }) => {
  const CellComponent = ({ data }) => <PropertySaleListingCard listing={data} shadow={true} />

  const { isMobile } = useLayoutContext()
  if (isMobile) {
    return <InfiniteList CellComponent={CellComponent} queryKey={queryKey} {...props} />
  }

  return <PaginatedGrid cacheKey={queryKey} {...props} CellComponent={CellComponent} />
}
