import React from 'react'

import { InquirySuccessCard } from '@guiker/base-listing-components'
import { Button, useNavigate } from '@guiker/components-library'
import { webappRoutes } from '@guiker/property-sale-shared'

import { useTranslation } from '../../hooks'

type PropertySaleInquirySuccessCardProp = {
  isOpen: boolean
  onClose: () => void
  displayName: string
}

export const PropertySaleInquirySuccessCard: React.FC<PropertySaleInquirySuccessCardProp> = ({
  isOpen,
  onClose,
  displayName,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('main-propertySale')
  const onModalClose = () => {
    onClose()
    navigate(webappRoutes.listings.path)
  }

  return (
    <InquirySuccessCard
      open={isOpen}
      onClose={onModalClose}
      header={t('components.inquiryCard.successCard.header', { listing: displayName })}
      description={t('components.inquiryCard.successCard.description')}
      ctaButton={<Button onClick={onModalClose}>{t('components.inquiryCard.successCard.cta')}</Button>}
    />
  )
}
