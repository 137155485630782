import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { StockIssuer } from '../../entity'
import { baseEvent, BaseEventTypes } from '../base'

const baseStockIssuerEvent = {
  ...baseEvent,
  entity: 'STOCK_ISSUER',
  data: null as unknown as StockIssuer,
}

export const StockIssuerEventTypes = {
  ...BaseEventTypes,
}

export const StockIssuerEventsDefinition = generateEventsDefinition(baseStockIssuerEvent)
export const StockIssuerEvents = generateEventsFromEnum(baseStockIssuerEvent)(StockIssuerEventTypes)
