import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const smoking = yup.object<Omit<Ontario.Smoking, 'completed'>>({
  isSmokingRulesAgreed: yup.boolean().required(),
  smokingRulesDescription: yup.string().when('isSmokingRulesAgreed', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

export type Smoking = TypeOf<typeof smoking>
