import { compact, isObjectId } from '@guiker/lodash'

import { AssetFile, AssetGroup, S3AccessControlList } from '../entity'

type FlattenedAsset = AssetFile & { groupType: string; index: number }

export const isImageType = (type: string): boolean => {
  return type?.startsWith('image')
}

export const isPdfType = (type: string): boolean => {
  return type === 'application/pdf'
}

export const buildFlattenedAsset = (assetGroups?: AssetGroup[]) => {
  const flattened = assetGroups?.reduce(
    (accu, group) =>
      compact([...accu, ...group.assets?.map((asset, index) => ({ ...asset, index, groupType: group.type }))]),
    [],
  ) as FlattenedAsset[]

  return flattened?.filter((f) => !f.id || isObjectId(f.id))
}

export const buildBulkUpdatePayload = ({
  flattenedAssets,
  deletedAssets,
  acl,
}: {
  flattenedAssets?: FlattenedAsset[]
  deletedAssets: AssetFile[]
  acl: S3AccessControlList
}) => {
  const payload = (flattenedAssets ?? [])
    .map(({ id, fileName, displayName, mimeType, size }) => {
      return isObjectId(id) || !id ? { id, fileName, displayName, mimeType, size, acl, _delete: false } : undefined
    })
    .concat(
      deletedAssets.map(({ id, fileName, displayName, mimeType, size }) => {
        return isObjectId(id) ? { id, fileName, displayName, mimeType, size, acl, _delete: true } : undefined
      }),
    )

  return compact(payload)
}

export const isSameAsset = (field: AssetFile & { key?: string }, asset: AssetFile) => {
  const comparableAttributes = ['id', 'name', 'fileName', 'mimeType', 'size']
  return comparableAttributes.every((attr) => field[attr] === asset[attr])
}

export const fileToAssetFile = (file: File, name: string) => {
  return {
    fileName: file.name,
    presignedDownloadUrl: URL.createObjectURL(file),
    name,
    mimeType: file.type,
    size: file.size,
    file,
  }
}
