import { yup } from '@guiker/yup-util'

import { Quebec } from '../../../entity'
import { address } from '../../schemas/address'

export const participant = yup.object<Quebec.Person>({
  address: address,
  emailAddress: yup.string().email().required(),
  fullName: yup.string().required(),
  phoneNumber: yup.string().required(),
})

export type Participant = Quebec.Person
