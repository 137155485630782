import React from 'react'

import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { Flex, HiddenInput, PBold, PSmall, useTranslation } from '@guiker/react-framework'

import { ExpenseConfiguration } from '../../../utils'
import { ExpenseValue } from './ExpenseValue'

type Props = {
  name: string
  costAssumptionType: 'purchase' | 'operation'
  defaultValue: InvestmentAssumptions.BaseExpense | undefined
  expenseConfig: ExpenseConfiguration
  readOnly?: boolean
}

export const EditCost: React.FC<Props> = ({ costAssumptionType, name, defaultValue, expenseConfig, readOnly }) => {
  const { t } = useTranslation('common-propertySale')
  const { jurisdiction, type, frequency, value } = expenseConfig
  const formPrefix = `${name}.${type}`

  return (
    <div>
      <Flex justifyContent='space-between'>
        <PBold mb={1}>{`${t(`listing.assumptions.${costAssumptionType}.costs.${jurisdiction}.${type}`)} *`}</PBold>
        {frequency !== 'oneTimeAtPurchase' && <PSmall mb={1}>{t(`frequency.${frequency}`)}</PSmall>}
      </Flex>
      <HiddenInput name={`${formPrefix}.jurisdiction`} defaultValue={jurisdiction} />
      <HiddenInput name={`${formPrefix}.type`} defaultValue={type} />
      <HiddenInput name={`${formPrefix}.frequency`} defaultValue={frequency} />
      <ExpenseValue
        name={`${formPrefix}.value`}
        defaultValue={defaultValue?.value}
        expenseValue={value}
        readOnly={readOnly}
      />
    </div>
  )
}
