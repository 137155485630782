import { yup } from '@guiker/shared-framework'

export const reactivatePayoutProviderSchema = yup.object({
  payoutProviderAccountId: yup
    .string()
    .transform((s) => s.trim())
    .required(),
})

export type ReactivatePayoutProviderSchema = yup.InferType<typeof reactivatePayoutProviderSchema>
