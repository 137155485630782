import React, { useState } from 'react'

import { BookingActions, BookingStatus } from '@guiker/booking-shared'
import { Flex, Link, Link as RouterLink, makeStyles, PSmall, TextButton, useT } from '@guiker/react-framework'

import { addressUtils, getFullListingRoutePath } from '../../../../'
import { useBookingContext } from '../../../../hooks'
import { BookingInfo } from '../../../BookingInfo/BookingInfo'
import { UpdateBookingInfoModal } from '../UpdateBookingInfoModal'

const MAX_HEIGHT = 120

const useStyles = makeStyles(
  (theme) => ({
    topContent: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'nowrap',
    },
    bottomContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'nowrap',
    },
    editButton: {
      cursor: 'pointer',
      marginTop: theme.spacing(1 / 2),
    },
    link: {
      width: '100%',
      height: MAX_HEIGHT,
    },
    rent: {
      padding: theme.spacing(3),
    },
    thumbnail: {
      width: '100%',
      height: MAX_HEIGHT,
      objectFit: 'cover',
      objectPosition: 'center',
    },
  }),
  {
    name: 'BookingOverview',
  },
)

const BookingOverview: React.FC = () => {
  const classes = useStyles()
  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false)
  const { tBase } = useT({ screenName: 'screens.bookingModule' })
  const { booking, bookingId, can } = useBookingContext()

  const { listing } = booking
  const { address, thumbnailUrl, externalId } = listing
  const { countryCode: country, apartmentNumber: suite, room } = address

  const fullListingRouteUrl = getFullListingRoutePath({ address, id: `${externalId}` }) ?? `/listings/${externalId}`

  const canEdit = {
    pricing: can(BookingActions.EditPricing),
    period: can(BookingActions.EditPeriod),
    bookingSteps: can(BookingActions.EditBookingSteps),
    editBookingDocuments: can(BookingActions.EditBookingDocument, { adminOnly: true }),
  }

  const canUpdateBookingInfo =
    Object.values(canEdit).some((can: boolean) => can) &&
    booking.status !== BookingStatus.BOOKED &&
    !booking?.statusHistory.some((sh) => sh.status === BookingStatus.CONFIRM_REQUESTED)

  return (
    <Flex flexDirection='column' gap={3}>
      <Flex flexDirection='column' gap={2}>
        <RouterLink isExternalLink to={fullListingRouteUrl} target='_top' className={classes.link}>
          <img src={thumbnailUrl} className={classes.thumbnail} alt='listing' />
        </RouterLink>
        <Link isExternalLink to={fullListingRouteUrl} underline={false} target='_top'>
          <PSmall mb={0}>{addressUtils.printShortAddress({ ...address, room, suite, country })}</PSmall>
        </Link>
      </Flex>
      <Flex justifyContent='space-between' gap={2}>
        <BookingInfo booking={booking} />

        {canUpdateBookingInfo && (
          <div>
            <TextButton size='small' className={classes.editButton} onClick={() => setEditInfoModalOpen(true)}>
              {tBase('actions.edit')}
            </TextButton>
            <UpdateBookingInfoModal open={editInfoModalOpen} setOpen={setEditInfoModalOpen} bookingId={bookingId} />
          </div>
        )}
      </Flex>
    </Flex>
  )
}

export { BookingOverview }
