import { routesBuilder } from '@guiker/shared-framework'

import { Notification } from '../../../entity'
import { paths } from './paths'
import { updateManyNotificationStatusSchema, updateNotificationStatusSchema } from './schemas'

const { buildGet, buildPut } = routesBuilder<Notification>()({
  basePath: paths.notification.all.base(),
})

const { buildPut: buildIdNestedPut } = routesBuilder<Notification>()({
  basePath: paths.notification.all.withId(),
})

export const routes = {
  readAllInAppNotifications: buildGet({
    paginated: true,
    additionalMeta: {} as { unreadCount: number },
  }),
  updateNotificationStatus: buildIdNestedPut({
    payloadSchema: updateNotificationStatusSchema,
  }),
  updateManyNotificationStatus: buildPut({
    payloadSchema: updateManyNotificationStatusSchema,
    responseIsArray: true,
  }),
}
