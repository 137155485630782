import React, { createContext, PropsWithChildren } from 'react'

import { PropSharingListing } from '@guiker/propsharing-shared'
import { generateUseContext } from '@guiker/react-framework'

import { StockContextProvider } from '../StockContextProvider'

type ListingContextProviderProps = PropsWithChildren & {
  data: PropSharingListing
}

type ListingContext = {
  data: PropSharingListing
}

const ListingContext = createContext<ListingContext>(null)

export const ListingContextProvider = ({ children, data }: ListingContextProviderProps) => {
  return (
    <ListingContext.Provider value={{ data }}>
      <StockContextProvider data={data.stock}>{children}</StockContextProvider>
    </ListingContext.Provider>
  )
}

export const useListing = generateUseContext(ListingContext)
