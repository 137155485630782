import { Jurisdiction } from './expense'
import { Gatineau, Jurisdiction as GatineauJurisdiction } from './gatineau'
import { Jurisdiction as MontrealJurisdiction, Montreal } from './montreal'
import { Jurisdiction as QuebecCityJurisdiction, QuebecCity } from './quebec-city'

export type QuebecJurisdiction = Jurisdiction | GatineauJurisdiction | MontrealJurisdiction | QuebecCityJurisdiction
export * from './operation-cost'
export * from './purchase-cost'
export * from './purchase-taxes'

export { Gatineau, Montreal, QuebecCity }
