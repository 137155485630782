import { BackgroundCheckRoleNames } from '@guiker/background-check-shared'
import { Locations, User as BaseUser } from '@guiker/base-entity'
import { DateTime, Interval } from '@guiker/date'
import { DocumentRoleNames, EnvelopeStatus } from '@guiker/document-shared'
import { LeaseRoleNames } from '@guiker/lease-shared'
import { initializeCanWithUser } from '@guiker/permissions'
import { RentPaymentRoleNames } from '@guiker/rent-payment-shared'

import { BookingActions } from '../../permissions'
import { Applicant, ApplicantRoles } from '../applicant'
import { ApplicationStatus, RoommateIntroStatus } from '../application'
import { Booking } from '../booking'
import { BookingAccessControlClaims } from '../booking-access-control-token'
import { BookingDocument } from '../booking-document'
import { BookingLeaseStatus } from '../booking-lease'
import { BookingRoleResolver } from '../booking-role-resolver'
import { BookingStatus } from '../booking-status'
import { ContributionStatus, inferApplicantStepStatus as inferApplicantPaymentStepStatus } from '../contribution'
import { InvitationStatus, Participant, ParticipantStepStatus } from '../participant'
import { BookingRoleNames, UnitManager } from '../unit-manager'
import {
  inferApplicantApplicationStepStatus,
  inferRoommateProfileStepStatus,
  inferUnitManagerRoommateProfileStepStatus,
  inferUnitManagerStepStatus as inferUnitManagerApplicationStepStatus,
} from './booking-application'
import { inferApplicantDocumentStepStatus, inferUnitManagerDocumentStepStatus } from './booking-document'
import { inferApplicantLeaseStepStatus, inferUnitManagerLeaseStepStatus } from './booking-lease'
import { getPaymentVerifications } from './booking-payment'
import {
  ApplicantIsOneOfUnitManagersError,
  BookingIsNotReadyToBeConfirmedError,
  ExpirationTimerCannotExtendError,
  InvalidBookingStatusTransitionError,
  InvalidLeasePeriodError,
} from './errors'

type User = {
  id?: string
  emailAddress: string
}

const hasApplication = (booking: Booking): boolean => {
  return booking.applicants && booking.applicants.filter((applicant) => applicant.application).length > 0
}

const isRoommatable = (listing: Booking['listing']) => {
  return !!listing?.address.room && listing.address.countryCode === 'US'
}

const hasDocuments = (booking: Booking): boolean => {
  return booking.documents && booking.documents.filter((document) => document.documents).length > 0
}

const getApplicantSteps = (booking: Booking, applicant: Applicant): Applicant['steps'] => {
  const isBookingConfirmed = booking.status === BookingStatus.BOOKED

  if (isBookingConfirmed) {
    return {
      application: { status: ParticipantStepStatus.FINALIZED },
      roommate: { status: ParticipantStepStatus.FINALIZED },
      document: { status: ParticipantStepStatus.FINALIZED },
      lease: { status: ParticipantStepStatus.FINALIZED },
      payment: { status: ParticipantStepStatus.FINALIZED },
    }
  }

  let paymentStatus = ParticipantStepStatus.NOT_STARTED
  if (booking.bookingPayment && booking.bookingPayment.contributions.length > 0) {
    const contribution = booking.bookingPayment.contributions.find((c) => c.userId === applicant.userId)
    paymentStatus = inferApplicantPaymentStepStatus(contribution?.status)
  }

  let applicationStatus = ParticipantStepStatus.NOT_STARTED
  let roommateStatus = ParticipantStepStatus.NOT_READY
  if (hasApplication(booking)) {
    const application = booking.applicants.find((a) => a.userId === applicant.userId).application
    applicationStatus = inferApplicantApplicationStepStatus(application?.status)
    roommateStatus = inferRoommateProfileStepStatus(application?.roommateIntro?.status, application?.status)
  }

  let documentStatus = ParticipantStepStatus.NOT_READY
  if (hasDocuments(booking)) {
    documentStatus = inferApplicantDocumentStepStatus(booking.documents, applicant.userId)
  }

  let leaseStatus = ParticipantStepStatus.NOT_READY
  if (booking.lease && booking.lease.participants.length > 0) {
    const leaseParticipant = booking.lease.participants.find(
      (p) => p.userId?.toString() === applicant.userId?.toString(),
    )

    leaseStatus = inferApplicantLeaseStepStatus(leaseParticipant?.status, booking.lease?.status)
  }

  return {
    application: {
      status: applicationStatus,
    },
    roommate: {
      status: roommateStatus,
    },
    document: {
      status: documentStatus as Exclude<ParticipantStepStatus, ParticipantStepStatus.PROCESSING>,
    },
    payment: {
      status: paymentStatus,
    },
    lease: {
      status: leaseStatus,
    },
  }
}

const getUnitManagerSteps = (booking: Booking, unitManager: UnitManager): UnitManager['steps'] => {
  const isBookingConfirmed = booking.status === BookingStatus.BOOKED

  if (isBookingConfirmed) {
    return {
      application: { status: ParticipantStepStatus.FINALIZED },
      roommate: { status: ParticipantStepStatus.FINALIZED },
      lease: { status: ParticipantStepStatus.FINALIZED },
      document: { status: ParticipantStepStatus.FINALIZED },
    }
  }

  let applicationStatus = ParticipantStepStatus.NOT_READY
  const applications = booking.applicants.map((a) => a.application)
  if (hasApplication(booking)) {
    applicationStatus = inferUnitManagerApplicationStepStatus(applications)
  }

  let roommateStatus = ParticipantStepStatus.NOT_READY
  if (isRoommatable(booking.listing)) {
    roommateStatus = inferUnitManagerRoommateProfileStepStatus(applications)
  }

  const { can } = initializeCanWithUser<Booking, BookingActions>({
    user: { id: unitManager.userId, emailAddress: unitManager.emailAddress },
    roleResolver: BookingRoleResolver,
    entity: booking,
  })

  const documentStatus = inferUnitManagerDocumentStepStatus(
    booking.documents,
    can(BookingActions.EditBookingDocument, { adminOnly: true }),
  )

  return {
    application: {
      status: applicationStatus,
    },
    roommate: {
      status: roommateStatus,
    },
    document: {
      status: documentStatus,
    },
    lease: {
      status: inferUnitManagerLeaseStepStatus(booking.lease?.status, unitManager.role),
    },
  }
}

const buildAccessControlClaims = (
  booking: Booking,
  userId: string,
  emailAddress: string,
): BookingAccessControlClaims => {
  if (!booking || !userId) {
    return undefined
  }

  const { can } = initializeCanWithUser<Booking, BookingActions>({
    user: { id: userId, emailAddress },
    roleResolver: BookingRoleResolver,
    entity: booking,
  })

  const leaseRoleNames: LeaseRoleNames[] = []
  const backgroundCheckRoleNames: BackgroundCheckRoleNames[] = []
  const documentRoleNames: DocumentRoleNames[] = []
  const rentPaymentRoleNames: RentPaymentRoleNames[] = []

  if (can(BookingActions.DraftLease)) {
    leaseRoleNames.push(LeaseRoleNames.DRAFTER)
  } else if (can(BookingActions.ViewLease)) {
    leaseRoleNames.push(LeaseRoleNames.SPECTATOR)
  }

  if (can(BookingActions.ConductBackgroundCheck)) {
    backgroundCheckRoleNames.push(BackgroundCheckRoleNames.REQUESTER)
  }

  if (can(BookingActions.DownloadBackgroundCheck)) {
    backgroundCheckRoleNames.push(BackgroundCheckRoleNames.SPECTATOR)
  }

  if (isUnitManager({ booking, userId })) {
    if (can(BookingActions.EditBookingDocument, { adminOnly: true })) {
      documentRoleNames.push(DocumentRoleNames.EDITOR)
    } else if (can(BookingActions.ViewBookingDocument)) {
      documentRoleNames.push(DocumentRoleNames.SPECTATOR)
    }
  } else if (isApplicant({ booking, userId }) || isGuarantor({ booking, emailAddress })) {
    documentRoleNames.push(DocumentRoleNames.SIGNER)
  }

  if (can(BookingActions.GetBookingPayment)) {
    rentPaymentRoleNames.push(RentPaymentRoleNames.UNIT_MANAGER)
  } else if (isApplicant({ booking, userId })) {
    rentPaymentRoleNames.push(RentPaymentRoleNames.PAYER)
  }

  const backgroundCheckIds = booking.applicants
    .reduce((ids, applicant) => {
      if (applicant.application?.content) {
        ids.push(applicant.application.content.backgroundCheck?.id)
        ids.push(applicant.application.content.guarantor?.backgroundCheck?.id)
      }
      return ids
    }, [] as string[])
    .filter((i) => !!i)

  const accessControlClaims: BookingAccessControlClaims = {
    scope: { id: booking.id, type: 'booking' },
    userId,
    emailAddress,
    acl: {
      backgroundCheck: {
        roles: backgroundCheckRoleNames,
        references: backgroundCheckIds,
      },
      document: { roles: documentRoleNames },
      lease: { roles: leaseRoleNames },
      payment: { roles: rentPaymentRoleNames },
    },
  }
  return accessControlClaims
}

const injectSteps = (booking: Booking): Booking => {
  booking.applicants.forEach((applicant) => (applicant.steps = getApplicantSteps(booking, applicant)))
  booking.unitManagers.forEach((unitManager) => (unitManager.steps = getUnitManagerSteps(booking, unitManager)))
  return booking
}

const getParticipant = ({
  booking,
  user,
}: {
  booking: Booking
  user: User | undefined
}): UnitManager | Applicant | undefined => {
  if (!user) return
  return (
    booking.applicants.find(
      (applicant) => applicant.userId === user.id || applicant.emailAddress === user.emailAddress,
    ) ||
    booking.unitManagers.find(
      (unitManager) => unitManager.userId === user.id || unitManager.emailAddress === user.emailAddress,
    )
  )
}

const getParticipantType = (participant: Participant): 'applicant' | 'unitManager' => {
  if ((participant as unknown as Applicant).role === ApplicantRoles.APPLICANT) {
    return 'applicant'
  } else {
    return 'unitManager'
  }
}

const invitationStatusIsInvited = ({ booking, user }: { booking: Booking; user: User }): boolean => {
  return getParticipant({ booking, user })?.invitation?.status === InvitationStatus.INVITED
}

const isMultitenant = (booking: Booking) => {
  return booking.applicants.length > 1
}

const haveApplicantsCompletedOneStep = (booking: Booking): boolean => {
  return !!booking.applicants.find(
    (participant) =>
      participant.steps.application?.status === ParticipantStepStatus.COMPLETED ||
      participant.steps.roommate?.status === ParticipantStepStatus.COMPLETED ||
      participant.steps.lease?.status === ParticipantStepStatus.COMPLETED ||
      participant.steps.payment?.status === ParticipantStepStatus.COMPLETED ||
      participant.steps.document?.status === ParticipantStepStatus.COMPLETED,
  )
}

const isSpectator = (role: BookingRoleNames) => role === BookingRoleNames.SPECTATOR

const shouldSkipStep = ({
  bookingListing,
  step,
}: {
  bookingListing: Booking['listing']
  step: keyof Participant['steps']
}) => {
  const { countryCode } = bookingListing.address
  switch (step) {
    case 'roommate':
      return !isRoommatable(bookingListing)
    case 'document':
      return countryCode !== Locations.US.countryCode
    case 'lease':
      return countryCode === Locations.US.countryCode
    default:
      return false
  }
}

const checkCompletedDocuments = (documents: BookingDocument[]): boolean => {
  const activeDocument = documents.find((d) => d.status !== EnvelopeStatus.CANCELLED)
  return !activeDocument || activeDocument.status === EnvelopeStatus.COMPLETED
}

const checkConfirmReadiness = (booking: Booking) => {
  const { bookingPayment, lease } = booking

  const areDocumentsReady =
    !booking.hasEnabledStep.document || !booking.documents || checkCompletedDocuments(booking.documents)

  const areLeaseReady = !booking.hasEnabledStep.lease || lease?.status === BookingLeaseStatus.SIGNED_BY_ALL_PARTIES

  const contributionsEqualTotalAmount =
    bookingPayment.total.amount === bookingPayment.contributions.reduce((sum, c) => sum + (c.amount || 0), 0)

  const allContributionsAreReady =
    bookingPayment.contributions.every((contribution) => {
      const isReadyStatus = [
        ContributionStatus.PAY_IN_METHOD_VERIFIED,
        ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION,
      ].includes(contribution.status)
      return contribution.amount === 0 || (contribution.amount > 0 && isReadyStatus)
    }) && contributionsEqualTotalAmount

  const arePaymentReady = !booking.hasEnabledStep.payment || allContributionsAreReady

  const isPayoutReady = !booking.hasEnabledStep.payment || Object.values(bookingPayment.eligibility).some((e) => e)

  // TODO: add more check readiness later.
  const areApplicationsReady =
    !booking.hasEnabledStep.application ||
    !booking.applicants.some((applicant) =>
      [ApplicationStatus.CHANGE_REQUESTED, ApplicationStatus.REJECTED].includes(applicant.application?.status),
    )

  const areRoommateReady =
    !booking.hasEnabledStep.roommate ||
    booking.applicants.every(({ application }) => application?.roommateIntro?.status === RoommateIntroStatus.ACCEPTED)

  return {
    documents: areDocumentsReady,
    roommates: areRoommateReady,
    applications: areApplicationsReady,
    payments: arePaymentReady,
    payout: isPayoutReady,
    lease: areLeaseReady,
    isReady: areApplicationsReady && arePaymentReady && areLeaseReady && isPayoutReady && areDocumentsReady, //did not add to confirm readiness
  }
}

const isUnitManager = ({ booking, userId }: { booking: Booking; userId: string }) =>
  !!booking?.unitManagers?.find((um) => um.userId === userId)

const findUnitManager = ({ booking, user }: { booking: Booking; user: BaseUser }) =>
  booking?.unitManagers?.find((um) => um.userId === user.id || um.emailAddress === user.emailAddress)

const isApplicant = ({ booking, userId }: { booking: Booking; userId: string }) =>
  !!booking?.applicants?.find((applicant) => applicant.userId === userId)

const isGuarantor = ({ booking, emailAddress }: { booking: Booking; emailAddress: string }) => {
  !!booking?.applicants.some(
    (applicant) => applicant.application?.content?.guarantor?.profile?.emailAddress === emailAddress,
  )
}

const isUnitManagerRole = ({
  booking,
  userId,
  role,
}: {
  booking: Booking
  userId?: string
  role: BookingRoleNames
}): boolean => {
  return !!booking?.unitManagers.find((um) => um.userId === userId && um.role === role)
}

const isParticipant = ({
  booking,
  userId,
  emailAddress,
}: {
  booking: Booking
  userId?: string
  emailAddress?: string
}): boolean => {
  if (!userId && !emailAddress) {
    throw new Error('Expected userId or emailAddress to be passed')
  }

  const userIdMatch =
    booking.unitManagers.some((um) => um?.userId === userId) || booking.applicants.some((a) => a?.userId === userId)

  const emailAddressMatch =
    booking.unitManagers.some((um) => um?.emailAddress === emailAddress) ||
    booking.applicants.some((a) => a?.emailAddress === emailAddress)

  return userIdMatch || emailAddressMatch
}

const hasConfirmRequested = (booking: Booking) => {
  return booking.statusHistory?.some((sh) => sh.status === BookingStatus.CONFIRM_REQUESTED)
}

const BookingStatusStateTransition = {
  [BookingStatus.STARTED]: [
    BookingStatus.STARTED,
    BookingStatus.CONFIRM_REQUESTED,
    BookingStatus.WITHDRAWN,
    BookingStatus.REJECTED,
    BookingStatus.EXPIRED,
  ],
  [BookingStatus.WITHDRAWN]: [BookingStatus.STARTED],
  [BookingStatus.REJECTED]: [] as BookingStatus[],
  [BookingStatus.CONFIRM_REQUESTED]: [BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION, BookingStatus.BOOKED],
  [BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION]: [
    BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION,
    BookingStatus.CONFIRM_REQUESTED,
    BookingStatus.REJECTED,
    BookingStatus.BOOKED,
  ],
  [BookingStatus.EXPIRED]: [BookingStatus.STARTED],
  [BookingStatus.BOOKED]: [] as BookingStatus[],
}

const validateStatusTransitionOrThrow = ({ booking, status }: { booking: Booking; status: BookingStatus }): void => {
  if (!BookingStatusStateTransition[booking.status].includes(status)) {
    throw new InvalidBookingStatusTransitionError({
      bookingId: booking.id,
      currentStatus: booking.status,
      newStatus: status,
    })
  }
}

const validateExpirationTimerIsExtendableOrThrow = ({ booking }: { booking: Booking }) => {
  if (!isExpirationTimerExtendable({ booking }) || !booking.timer?.expiresAt) {
    throw new ExpirationTimerCannotExtendError(booking.id, booking.status, booking.timer?.expiresAt)
  }
}

const isExpirationTimerExtendable = ({ booking }: { booking: Booking }) => {
  const confirmRequest = booking.statusHistory.find((sh) => sh.status === BookingStatus.CONFIRM_REQUESTED)
  const oneDayInSeconds = 24 * 60 * 60
  /**
   * @description
   * consult BookingStatusStateTransition. if transition status is empty array, booking cannot be extended
   */
  const nonExtendableStatus = [BookingStatus.REJECTED, BookingStatus.BOOKED]

  /**
   * @description
   * Once confirmed, payment service can hold pre-authorization up to 3-5 business days
   * To be safe, allow the extension of countdown by ONE day after first confirm request
   */
  return (
    !nonExtendableStatus.includes(booking.status) &&
    (!confirmRequest ||
      Interval.fromDateTimes(DateTime.fromISO(confirmRequest.changedAt), DateTime.local()).toDuration().seconds <
        oneDayInSeconds)
  )
}

const validateApplicantIsNotUnitManager = ({
  unitManagers,
  applicants,
}: {
  applicants: { userId?: string }[]
  unitManagers: { userId?: string }[]
}) => {
  const unitManagerAndApplicant = unitManagers.find((um) =>
    applicants.find((applicant) => applicant?.userId === um?.userId),
  )

  if (unitManagerAndApplicant) {
    throw new ApplicantIsOneOfUnitManagersError(Number(unitManagerAndApplicant.userId))
  }
}

const validateLeaseDurationOrThrow = ({ from, to }: { from: Date; to: Date }) => {
  const periodDuration = Interval.fromDateTimes(from, to).toDuration('months').toObject()

  if (!periodDuration.months || periodDuration.months < 1) {
    throw new InvalidLeasePeriodError()
  }
}

const validateConfirmReadinessOrThow = (booking: Booking) => {
  const confirmReadiness = checkConfirmReadiness(booking)

  if (!confirmReadiness.isReady) {
    throw new BookingIsNotReadyToBeConfirmedError(booking.id, confirmReadiness)
  }
}

const TIMER_MAX_HRS_EXTENSION = 336

const hasConfirmFailed = (booking: Booking) => {
  return (
    booking?.status === BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION &&
    getPaymentVerifications(booking).every((v) => v.stepStatus !== ParticipantStepStatus.PROCESSING)
  )
}

const isBooked = (booking: Booking) => {
  return booking?.status === BookingStatus.BOOKED
}

const isRejectable = (booking: Booking) => {
  const { isReady } = checkConfirmReadiness(booking)
  const isRejectableStatus = [
    BookingStatus.STARTED,
    BookingStatus.EXPIRED,
    BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION,
  ].includes(booking.status)

  return !isBooked(booking) && isRejectableStatus && (!isReady || hasConfirmFailed(booking))
}

export {
  buildAccessControlClaims,
  checkConfirmReadiness,
  findUnitManager,
  getApplicantSteps,
  getParticipant,
  getParticipantType,
  getUnitManagerSteps,
  hasConfirmFailed,
  haveApplicantsCompletedOneStep,
  hasConfirmRequested,
  injectSteps,
  invitationStatusIsInvited,
  isRoommatable,
  isApplicant,
  isExpirationTimerExtendable,
  isMultitenant,
  isParticipant,
  isSpectator,
  isRejectable,
  isUnitManager,
  isUnitManagerRole,
  shouldSkipStep,
  validateApplicantIsNotUnitManager,
  validateConfirmReadinessOrThow,
  validateExpirationTimerIsExtendableOrThrow,
  validateLeaseDurationOrThrow,
  validateStatusTransitionOrThrow,
  TIMER_MAX_HRS_EXTENSION,
}
