import React from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { Flex, Link, PlusIcon, PSmall, SecondaryButton, useTranslation } from '@guiker/react-framework'

export const AddCreditCard: React.FC = () => {
  const { modalManager } = usePayInMethodContext()
  const { openModal } = modalManager.addCreditCard
  const { t } = useTranslation('common-payment')
  const LinkButton = modalManager.buttonType.value === 'button' ? SecondaryButton : PSmall

  return (
    <Flex gap={1} alignItems='center'>
      <PlusIcon width={16} height={16} />
      <Link color={50} onClick={openModal} underline={modalManager.buttonType.value === 'p'}>
        <LinkButton mb={0}>{t('addCreditCard')}</LinkButton>
      </Link>
    </Flex>
  )
}
