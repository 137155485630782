import { yup } from '@guiker/yup-util'

export const paysafeConfigSchema = yup
  .object({
    accountApiToken: yup.string().required(),
    customerVaultApiToken: yup.string().required(),
    url: yup.string().required(),
    salesSupportEmail: yup.string().required(),
    productCode: yup
      .object({
        directDebitAccount: yup.string().required(),
        creditCardAccount: yup.string().required(),
      })
      .required(),
  })
  .required()
