import { yup } from '@guiker/yup-util'

export const participant = yup
  .object({
    firstName: yup.string().nullable().default('-'),
    lastName: yup.string().nullable().default('-'),
    emailAddress: yup.string().required(),
    userId: yup.string(),
    roleName: yup.string().required(),
  })
  .required()
