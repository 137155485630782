import { PayInMethodType } from '@guiker/payment-shared'
import { CountryCode, CurrencyISO } from '@guiker/shared-framework'

import { Employment } from './employment'
import { Financial } from './financial'
import { IdentityVerification } from './identity-verification'
import { Personal } from './personal'
import { InvestorProfileReviewalStatus } from './reviewal-status'
import { InvestorProfileStatus } from './status'

export type WithLiquidatedStock<T> = T & {
  buyoutStock: {
    stockId: string
    quantity: number
    price: number
    currency: CurrencyISO
  }
}

export type InvestmentRules = { twelveMonthsAllowanceLimit: number }

export type InvestorProfile = {
  id: string
  userId: string
  employment: Employment
  financial: Financial
  personal: Personal
  identityVerification: IdentityVerification
  status: InvestorProfileStatus
  reviewalStatus: InvestorProfileReviewalStatus
  investmentRules?: InvestmentRules
  payInMethod?: {
    id: string
    type: PayInMethodType
  }
  createdAt: string
  supportedCountry: CountryCode
}

export enum UnavailableReason {
  FULLY_FUNDED = 'FULLY_FUNDED',
  PROFILE_ALLOWANCE = 'PROFILE_ALLOWANCE',
  STOCK_TYPE_ALLOWANCE = 'STOCK_TYPE_ALLOWANCE',
}

export type StockAllowance = {
  stockId: string
  unavailableReason?: UnavailableReason | null
  minQuantity: number
  maxQuantity: number
}

export type InvestorProfileWithStockAllowance = InvestorProfile & { stockAllowance: StockAllowance }
