import { getLocationService } from '@guiker/base-entity'
import { slugifyLowercase } from '@guiker/lodash'

export const buildWebappRoutes = ({ rootPath }: { rootPath: string }) => {
  return {
    listings: {
      path: rootPath,
      byTickerSymbol: function (tickerSymbol: string) {
        return {
          path: `${this.path}/${tickerSymbol}`,
        }
      },
      countryNested: function (countryCode: string) {
        return {
          path: `${this.path}/${slugifyLowercase(countryCode)}`,
          cityNested: function (cityName: string, stateCode: string) {
            return {
              path: `${this.path}/${slugifyLowercase(`${cityName}-${stateCode}`)}`,
              neighbourhoodNested: function (neighbourhoodName: string) {
                return {
                  path: `${this.path}/${
                    neighbourhoodName
                      ? slugifyLowercase(`${neighbourhoodName}`)
                      : getLocationService().getCityBySlug(cityName)?.city?.defaultNeighbourhood
                  }`,
                  withId: function (listingSlug: string) {
                    return {
                      path: `${this.path}/${listingSlug}`,
                    }
                  },
                }
              },
            }
          },
        }
      },
    },
  } as const
}
