import { SupportedLanguages } from '@guiker/base-entity'
import { parseDateTime } from '@guiker/date'
import { yup } from '@guiker/yup-util'

import { TenantApplicationProfile } from '../../entity'

export const tenantApplicationProfileSchema = yup.object<Omit<TenantApplicationProfile, 'gender'>>({
  legalFirstName: yup.string().required(),
  legalLastName: yup.string().required(),
  dateOfBirth: yup
    .string()
    .required()
    .transform((date) => parseDateTime(date).toFormat('yyyy-LL-dd')),
  emailAddress: yup.string().email().required(),
  phoneNumber: yup.string().required(),
  language: yup.mixed().oneOf(Object.values(SupportedLanguages)).required(),
  driversLicense: yup.string().nullable(),
})
