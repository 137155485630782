import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { FundingAccountDeposit } from '../../entity'
import { baseEvent } from '../base'

export const definition = generateEventsDefinition({
  ...baseEvent,
  entity: 'FUNDING_ACCOUNT_DEPOSIT',
  data: null as FundingAccountDeposit,
  eventTypes: {
    PROCESSED: 'PROCESSED',
    FAILED: 'FAILED',
    PAYMENT_SETTLED: 'PAYMENT_SETTLED',
    COMPLETED: 'COMPLETED',
    JOURNAL_ENTRY_INSERTION_FAILED: 'JOURNAL_ENTRY_INSERTION_FAILED',
  } as const,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
