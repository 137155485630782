import React from 'react'

import { FundingAccountWithdrawal, FundingAccountWithdrawalStatus } from '@guiker/payment-shared'
import { Chip, ChipProps, StatusKey } from '@guiker/react-framework'

import { useT } from '../../i18n'

type WithdrawalStatusChipProps = Pick<ChipProps, 'size' | 'variant'> & {
  withdrawal: Pick<FundingAccountWithdrawal, 'status'>
  withDate?: boolean
}

export const getWithdrawalStatusColor = (withdrawal: Pick<FundingAccountWithdrawal, 'status'>): StatusKey => {
  return withdrawal.status === FundingAccountWithdrawalStatus.COMPLETED
    ? 'success'
    : withdrawal.status === FundingAccountWithdrawalStatus.PROCESSING
    ? 'positive'
    : withdrawal.status === FundingAccountWithdrawalStatus.FAILED
    ? 'alert'
    : 'info'
}

export const WithdrawalStatusChip: React.FC<WithdrawalStatusChipProps> = ({
  withdrawal,
  size = 'small',
  variant = 'filled',
}) => {
  const { tShared } = useT({ entity: 'fundingAccount.withdrawal' })
  const color = getWithdrawalStatusColor(withdrawal)

  return (
    <Chip color={color} size={size} variant={variant}>
      {tShared(`status.options.${withdrawal.status}`)}
    </Chip>
  )
}
