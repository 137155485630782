import React from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  Box,
  Button,
  Display2,
  HeroLayout,
  makeStyles,
  margin,
  PageMetaTags,
  PBig,
  RouterLink,
  theme,
  useQuery,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { Testimonial } from '../../components'
import { useCareerApiClient } from '../../hooks'
import { OpenPositions } from './OpenPositions'
import { OurCulture } from './OurCulture'
import { StarterPack } from './StarterPack'
import { StaticPictureContent } from './StaticPictureContent'
import { WhoWeAre } from './WhoWeAre'

const useStyle = makeStyles({
  supportingContainer: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.common.white,
    maxWidth: 650,
  },
  heroWrapper: {
    backgroundImage: ({ heroUrl }: { heroUrl: string }) => `url(${heroUrl})`,
  },
  hero: {
    margin: margin(0, 'auto'),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: theme.dimensions.pageLayout.maxWidth.desktop,
  },
})

const Careers: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('careers')
  const classes = useStyle({ heroUrl: assets.hero })

  const careerApiClient = useCareerApiClient()
  const { data: positions = [] } = useQuery('fetchOpenPositions', () => careerApiClient.getJobList())

  return (
    <>
      <PageMetaTags subtitle={t('careers.seo.subtitle')} description={t('careers.seo.description')} />
      <div className={classes.heroWrapper}>
        <HeroLayout
          className={classes.hero}
          left={
            <Box className={classes.supportingContainer}>
              <Display2>{t('careers.subHeader')}</Display2>
              <PBig color={60}>{t('careers.description')}</PBig>
              <RouterLink isExternalLink to='#open-positions'>
                <Button>{t('careers.seeOpenOptions')}</Button>
              </RouterLink>
            </Box>
          }
          right={<></>}
        />
      </div>

      <Box width='100%'>
        <WhoWeAre />
        <OurCulture />
        <StaticPictureContent asset={assets.image2} />
        <StarterPack />

        {positions.length > 0 && <OpenPositions positions={positions} />}

        <Box mx='auto' p={2} maxWidth={1130}>
          <Testimonial
            mb={10}
            url={assets.testimonial}
            alt={'Careers testimonial'}
            headline={t('careers.testimonialHeadline')}
            content={t('careers.testimonialContent')}
            author={t('careers.testimonialAuthor')}
            authorRole={t('careers.testimonialAuthorRole')}
          />
        </Box>
      </Box>
    </>
  )
}

export { Careers }
