import { yup } from '@guiker/yup-util'

import { updatePropertyBuildingDetailSchema } from './shared'

export const updatePropertyAndBuildingSchema = yup.object({
  totalSquareFootage: yup.number().min(0).nullable(),
  building: updatePropertyBuildingDetailSchema.nullable(),
})

export type UpdatePropertyAndBuildingSchema = yup.InferType<typeof updatePropertyAndBuildingSchema>
