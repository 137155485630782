import React from 'react'

import { supportedLanguages } from '@guiker/base-entity'
import {
  DatePicker,
  Dropdown,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { useAuthenticationContext } from '../../../hooks'

type ProfileContentProps = {
  defaultValue: Partial<TenantApplication>
  schema?: yup.ObjectSchema
  readOnly?: boolean
}

const ProfileContent: React.FC<ProfileContentProps> = ({ defaultValue, schema, readOnly = false }) => {
  const namespaces = ['common', 'main-tenantApplication']
  const { user } = useAuthenticationContext()
  const { t } = useTranslation(namespaces)

  const inputProps = useGetInputProps({
    namespaces,
    formPrefix: 'profile',
    tPrefix: 'common:user',
    defaultValue,
    schema,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
    readOnly,
  })

  return (
    <TwoColumnsGridLayout>
      <TextField {...inputProps('legalFirstName')} />
      <TextField {...inputProps('legalLastName')} />
      <DatePicker disableFuture {...inputProps('dateOfBirth')} />
      <TextField
        {...inputProps('emailAddress')}
        defaultValue={inputProps('emailAddress').defaultValue ?? user.emailAddress}
      />
      <TextField {...inputProps('phoneNumber')} />
      <TextField {...inputProps('driversLicense', { namespace: 'main-tenantApplication:user' })} />
      <Dropdown
        {...inputProps('language', { i18nKey: 'label', namespace: 'main-tenantApplication:languages' })}
        options={supportedLanguages.map((language) => ({
          value: language,
          label: t(`main-tenantApplication:languages.options.${language}`),
        }))}
      />
    </TwoColumnsGridLayout>
  )
}

export { ProfileContent }
