import { HttpMethod } from '@guiker/http'

import { MapLocation } from '../../entity'
import { paths } from './paths'
import { coordinateSchema } from './schemas'

const BASE_MAP_URL = paths.map.all.base()

export const routes = {
  locatePointsOfInterest: {
    path: `${BASE_MAP_URL}/locate-points-of-interest`,
    method: HttpMethod.POST,
    authenticated: false,
    responseValidator: (response: unknown) => Promise.resolve(response as MapLocation),
    payloadValidator: (payload: unknown) => coordinateSchema.validate(payload),
  },
}
