import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'
import { buildPaginationMeta, Paginate } from '@guiker/paginate'
import { RecommendedListing } from '@guiker/recommended-listing-entity'

import { buildListingIdQueryParams } from './build-listing-id-query-param-schema'
import { paths } from './paths'

export const routes = {
  readAllRecommendedListings: {
    path: paths.recommendedListing.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: RecommendedListing[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    queryParamsValidator: (payload: unknown) =>
      buildListingIdQueryParams().validate(payload) as Promise<Partial<Paginate & { listingId: string }>>,
  },
  readAllRecommendedListingsByListingId: {
    path: paths.recommendedListing.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: RecommendedListing[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    queryParamsValidator: (payload: unknown) =>
      buildListingIdQueryParams().validate(payload) as Promise<Partial<Paginate & { listingId: string }>>,
  },
  readAllRecommendedListingsByTenantPreferences: {
    path: paths.recommendedListing.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: RecommendedListing[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    queryParamsValidator: (payload: unknown) =>
      buildListingIdQueryParams().validate(payload) as Promise<Partial<Paginate & { listingId: string }>>,
  },
}
