import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'
import { AdminApiClient } from '@guiker/rent-payment-shared'

export const { useContext: useAdminRentPaymentsPlanContext, NestedRouter: AdminRentPaymentsPlanNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    cacheKey: ({ scopeType, scopeId }: { scopeType: string; scopeId: string }) =>
      `readRentPaymentsPlanByScope-${scopeType}-${scopeId}`,
    fetcher: (client, { scopeType, scopeId }: { scopeType: string; scopeId: string }) =>
      client.readRentPaymentsPlanByScope({ pathParams: { scopeType, scopeId } }),
  })
