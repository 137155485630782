import { addressFormatter as baseAddressFormatter } from '@guiker/base-entity'

import { Listing } from '../legacy'

const printFullAddressFromLegacyListing = (listing: Listing) =>
  baseAddressFormatter.printFullAddress({
    suite: listing.suiteNumber,
    streetNumber: listing.streetNumber,
    street: listing.streetName,
    city: listing.city.cityName,
    postalCode: listing.zipcode,
    country: listing.city.country,
  })

export const addressFormatter = {
  printFullAddressFromLegacyListing,
}
