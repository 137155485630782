import React, { useState } from 'react'

import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import {
  Flex,
  H3,
  Inline,
  makeStyles,
  Note,
  numberFormatter,
  PBold,
  theme,
  useTranslation,
} from '@guiker/react-framework'
import { Sticky } from '@guiker/react-sticky'
import { Currency, money } from '@guiker/shared-framework'

const useStyle = makeStyles({
  investmentResultsContainer: {
    width: 200,
    height: 'auto',
    position: 'relative',
  },
})

type Props = {
  investmentResults: InvestmentAssumptions.Results
  currency: Currency
  containerId?: string
  note?: {
    content: React.ReactNode
    position: 'top' | 'bottom'
  }
}

const Item: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <Flex flexDirection='column'>
      <Inline mb={0}>{label}</Inline>
      <PBold mb={0}>{value}</PBold>
    </Flex>
  )
}

export const StickyResultsSummary: React.FC<Props> = (props) => {
  const { investmentResults, note, currency, containerId = 'investment-results' } = props
  const { t } = useTranslation('common-investmentAssumption')

  const classes = useStyle()
  const [isSticky, setIsSticky] = useState(false)

  const { totalProjectCost, loanAmount, targetEquity, irr, coc, monthlyMortgagePayment } = investmentResults

  const renderNote = () => <Note title={''}>{note.content}</Note>

  return (
    <div id={containerId} className={classes.investmentResultsContainer}>
      <Sticky
        boundaryElement={`#${containerId}`}
        topOffset={-theme.dimensions.appBar.height.desktop}
        onFixedToggle={setIsSticky}
      >
        <Flex gap={2} flexDirection='column' mt={isSticky ? 12 : 0}>
          <H3 mb={0}>{t('results.title')}</H3>
          {note?.content && note.position === 'top' && renderNote()}
          <Item label={t('results.projectCost')} value={money.fromAmount(totalProjectCost, currency).toString()} />
          <Item label={t('results.loanAmount')} value={money.fromAmount(loanAmount, currency).toString()} />
          <Item label={t('results.targetEquity')} value={money.fromAmount(targetEquity, currency).toString()} />
          <Item label={t('results.irr')} value={`${numberFormatter.percentage(4).toDisplay(irr)} %`} />
          <Item label={t('results.coc')} value={`${numberFormatter.percentage(4).toDisplay(coc)} %`} />
          <Item
            label={t('results.monthlyMortgage')}
            value={money.fromAmount(monthlyMortgagePayment, currency).toString()}
          />
          {note?.content && note.position === 'bottom' && renderNote()}
        </Flex>
      </Sticky>
    </div>
  )
}
