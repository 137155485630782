import { Event } from '@guiker/event'
import { HttpMethod, routesBuilder } from '@guiker/shared-framework'

import { Invoice, ScopeType } from '../../entity'
import { byScope, invoiceIdPathParamsSchema } from '../schemas'
import { fundingAccountRoutes } from './funding-account'
import { paths } from './paths'
import { routes as payInMethodRoutes } from './pay-in-methods'
import { payOneInvoicePayloadSchema } from './schemas'

const INVOICE_WITH_ID_PATH = paths.invoice.all.withId(':invoiceId')
const TAX_PATH = paths.tax.one.base()
const { buildGet } = routesBuilder<Invoice>()({
  basePath: paths.invoice.all.base(),
})

const invoiceResponseValidator = (payload: unknown) => Promise.resolve(payload as Invoice)

export const routes = {
  ...fundingAccountRoutes,
  ...payInMethodRoutes,
  readAllLegacyInvoices: buildGet({
    path: '/rental',
    paginated: true,
    response: null as Invoice<ScopeType.TENANT_INSTALMENT>,
  }),
  readAllLateInvoices: buildGet({
    path: '/late-info',
    responseIsArray: true,
  }),
  readAllInvoicesByPaymentMethod: buildGet({
    path: '/pay-in-methods/:id/invoices',
    paginated: true,
  }),
  readAllInvoices: buildGet({ paginated: true }),
  readOneInvoice: {
    path: `${INVOICE_WITH_ID_PATH}`,
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: (params: unknown) => invoiceIdPathParamsSchema.validate(params),
    responseValidator: invoiceResponseValidator,
  },
  readLastInvoiceByScope: {
    path: paths.invoice.one.withScope(),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: (params: unknown) => byScope.validate(params),
    responseValidator: invoiceResponseValidator,
  },
  payOneInvoice: {
    path: `${INVOICE_WITH_ID_PATH}/pay`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => payOneInvoicePayloadSchema.validate(payload),
    pathParamsValidator: (params: unknown) => invoiceIdPathParamsSchema.validate(params),
    responseValidator: invoiceResponseValidator,
  },
  readAllTaxes: {
    path: TAX_PATH,
    method: HttpMethod.GET,
    authenticated: true,
  },
  readAllInvoiceEventsByInvoiceId: buildGet({
    path: `/:invoiceId/events`,
    response: [] as Event<Invoice>[],
  }),
}
