import { yup } from '@guiker/yup-util'

import { CreditScoreRanking, Occupation, OccupationType, Professional, Student } from '../../entity'

export const preApplicationStudentSchema = yup.object<Student>({
  annualSalary: yup
    .string()
    .nullable()
    .transform((value) => value || undefined),
})

export const preApplicationProfessionalSchema = yup.object<Professional>({
  annualSalary: yup
    .string()
    .nullable()
    .transform((value) => value || undefined),
})

export const preApplicationGuarantorProfileSchema = yup.object({
  estimatedCreditScore: yup
    .mixed()
    .oneOf(Object.values(CreditScoreRanking))
    .transform((value) => value || undefined),
  occupation: yup
    .string()
    .nullable()
    .transform((value) => value || undefined),
  annualSalary: yup
    .string()
    .nullable()
    .transform((value) => value || undefined),
})

export const preApplicationProfileSchema = yup.object({
  estimatedCreditScore: yup
    .mixed()
    .oneOf(Object.values(CreditScoreRanking))
    .transform((value) => value || undefined),
})

export const preApplicationGuarantorSchema = yup.object({
  profile: preApplicationGuarantorProfileSchema.required(),
})

export const preApplicationOccupationSchema = yup.object<Occupation>({
  type: yup.mixed().oneOf(Object.values(OccupationType)).required(),
  student: preApplicationStudentSchema.when('type', {
    is: (type: string) => type === 'STUDENT',
    then: preApplicationStudentSchema.required(),
    otherwise: preApplicationStudentSchema.nullable().transform(() => null as null),
  }),
  professional: preApplicationProfessionalSchema.when('type', {
    is: (type: string) => type === 'PROFESSIONAL',
    then: preApplicationProfessionalSchema.required(),
    otherwise: preApplicationProfessionalSchema.nullable().transform(() => null as null),
  }),
  title: yup
    .string()
    .nullable()
    .transform((value) => value || undefined),
})
