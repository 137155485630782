import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import Webcam from 'react-webcam'

import {
  Button,
  CloseIcon,
  clsx,
  makeStyles,
  MUIModal,
  safelyGetWindow,
  TextButton,
  useTranslation,
} from '@guiker/react-framework'

import { FaceDetectionResults, useFaceDetection } from './use-face-detection'

type FacePictureProps = {
  enabled?: boolean
  label: string
  onSuccess: ({ image }: { image: File }) => unknown
  onClose: () => unknown
}

const OVERLAY_OVALE_RATIO = 75 / 100
const OVERLAY_SIZE_RATIO = 2 / 3

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.black,
      width: '100%',
      height: '100%',
    },
    webcam: {
      height: 'fit-content',
      width: 'fit-content',
    },
    overlay: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    overlayContent: {
      borderColor: 'red',
      borderRadius: '100%',
      borderStyle: 'solid',
      borderWidth: 3,
    },
    floatingButton: {
      position: 'absolute',
      right: theme.spacing(4),
    },
    floatingTop: {
      top: theme.spacing(4),
    },
    floatingBottom: {
      bottom: theme.spacing(4),
    },
  }),
  { name: 'FacePicture' },
)

const canvasToBlobPromise = (canvas: HTMLCanvasElement, imageType) => {
  return new Promise<Blob>((resolve, reject) => {
    return canvas.toBlob((blob) => {
      if (!blob) {
        reject()
      }
      resolve(blob)
    }, imageType)
  })
}

const canvasToFile = async (canvas: HTMLCanvasElement, fileName: string, imageType = 'image/jpeg') => {
  const blob = await canvasToBlobPromise(canvas, imageType)
  return new File([blob], fileName, { type: imageType })
}

export const FacePicture: React.FC<FacePictureProps> = ({ enabled, onSuccess, label, onClose }) => {
  const classes = useStyles()
  const webcamRef = useRef<Webcam>()
  const { t } = useTranslation('common')
  const [cameraIsEnabled, setCameraIsEnabled] = useState<boolean>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [screenDimensions, setScreenDimensions] = useState({
    height: safelyGetWindow().innerHeight,
    width: safelyGetWindow().innerWidth,
    ratio: safelyGetWindow().innerWidth / safelyGetWindow().innerHeight,
  })

  const setDimensions = () => {
    setScreenDimensions({
      height: safelyGetWindow().innerHeight,
      width: safelyGetWindow().innerWidth,
      ratio: safelyGetWindow().innerWidth / safelyGetWindow().innerHeight,
    })
  }

  useLayoutEffect(() => {
    safelyGetWindow().addEventListener('resize', setDimensions)
    return () => safelyGetWindow().removeEventListener('resize', setDimensions)
  }, [])

  const processSuccess = async (image: HTMLCanvasElement) => {
    if (!isProcessing && enabled) {
      setIsProcessing(true)
      onSuccess?.({
        image: await canvasToFile(image as HTMLCanvasElement, 'face-picture'),
      })
      setIsProcessing(false)
    }
  }

  const onFaceDetection = useCallback(async (results: FaceDetectionResults) => {
    return processSuccess(results.image as HTMLCanvasElement)
  }, [])

  const onTakePictureClicked = () => {
    if (webcamRef?.current) {
      return processSuccess(webcamRef.current.getCanvas())
    }
  }

  const onStreamAquisition = () => {
    setCameraIsEnabled(true)
  }

  useFaceDetection({
    enabled: enabled && cameraIsEnabled,
    webcamRef,
    options: {
      maxNumFaces: 1,
      refineLandmarks: false,
      minDetectionConfidence: 0.9,
      minTrackingConfidence: 0.9,
    },
    onSuccess: onFaceDetection,
  })

  const { overlayHeight, overlayWidth } = useMemo(() => {
    const referenceDimension = Math.min(screenDimensions.height, screenDimensions.width)
    const shapeDiameter = referenceDimension * OVERLAY_SIZE_RATIO

    return {
      overlayWidth: shapeDiameter * OVERLAY_OVALE_RATIO,
      overlayHeight: shapeDiameter,
    }
  }, [screenDimensions])

  return (
    <MUIModal open={enabled}>
      <div className={classes.root}>
        <Webcam
          className={classes.webcam}
          ref={webcamRef}
          audio={false}
          mirrored={true}
          onUserMedia={onStreamAquisition}
          videoConstraints={{
            height: screenDimensions.height,
            width: screenDimensions.width,
          }}
        />
        <svg
          viewBox={`0 0 ${screenDimensions.width} ${screenDimensions.height}`}
          xmlns='http://www.w3.org/2000/svg'
          className={classes.overlay}
        >
          <mask id='ellipseMask'>
            <rect width='100%' height='100%' fill='white' />
            <ellipse cx='50%' cy='50%' rx={overlayWidth / 2} ry={overlayHeight / 2} fill='black' />
          </mask>
          <rect width='100%' height='100%' fill='black' fillOpacity={0.5} mask='url(#ellipseMask)' />
        </svg>
        <TextButton
          className={clsx(classes.floatingButton, classes.floatingTop)}
          onClick={onClose}
          startIcon={<CloseIcon size='smaller' />}
          size='small'
        >
          {t('common:actions.close')}
        </TextButton>
        {cameraIsEnabled && (
          <Button className={clsx(classes.floatingButton, classes.floatingBottom)} onClick={onTakePictureClicked}>
            {label}
          </Button>
        )}
      </div>
    </MUIModal>
  )
}
