import { TypeOf, yup } from '@guiker/yup-util'

import { petsSchema, tenantApplicationProfileSchema, vehicleSchema } from '../../schemas'

export const updateTenantApplicationProfilePayloadValidator = yup.object({
  profile: tenantApplicationProfileSchema.required(),
  hasPets: yup.boolean().required(),
  pets: yup.object().when('hasPets', {
    is: (val: boolean) => val === true,
    then: petsSchema.required(),
    otherwise: petsSchema.nullable().transform(() => null as null),
  }),
  hasVehicle: yup.boolean().required(),
  vehicle: yup.object().when('hasVehicle', {
    is: (val: boolean) => val === true,
    then: vehicleSchema.required(),
    otherwise: vehicleSchema.nullable().transform(() => null as null),
  }),
})

export type UpdateTenantApplicationProfilePayload = TypeOf<typeof updateTenantApplicationProfilePayloadValidator>
