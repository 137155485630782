import React, { useEffect } from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import {
  clsx,
  ColumnGridItem,
  Flex,
  FullScreenSpinner,
  makeStyles,
  P,
  PSmall,
  RadioGroup,
  useFormContext,
} from '@guiker/react-framework'

import { useGetPayInMethodLabelInfo } from '../../utils'
import { MinimizedPayInMethodSelector } from '../MinimizedPayInMethodSelector'
import { PayInMethodDisabledChip } from '../PayInMethodDisabledChip'
import { PayInMethodIcon } from '../PayInMethodIcon'
import { AddPayInMethod } from './AddPayInMethod'
import { SelectedPayInMethod } from './SelectedPayInMethod'

const useStyles = makeStyles(
  {
    payInMethodOptionRadioInput: {
      alignSelf: 'center',
    },
    disabledRadioLabel: {
      opacity: 0.4,
    },
  },
  { name: 'PayInMethodSelector' },
)

type Props = {
  condensed?: boolean
  invoiceAmount?: number
  name?: string
  showLabel?: boolean
}

export const FormContent: React.FC<Props> = ({ name: fieldName, invoiceAmount }) => {
  const classes = useStyles()
  const name = fieldName ?? 'payInMethod'

  const { getLabelInfo } = useGetPayInMethodLabelInfo()
  const { region, setSelectedById, selected, payInMethods, isLoading } = usePayInMethodContext()
  const { setValue } = useFormContext()

  useEffect(() => {
    if (selected) {
      setValue(name, selected.id)
    }
  }, [selected])

  if (isLoading) return <FullScreenSpinner />

  return (
    <ColumnGridItem md={2}>
      <RadioGroup
        name={name}
        maxWidth='100%'
        defaultValue={selected?.id}
        adornmentPosition='start'
        onChange={(e) => setSelectedById(e.target.value)}
        options={
          payInMethods?.map((payInMethod) => {
            const label = getLabelInfo({ payInMethod, region, amount: invoiceAmount })
            const { id, type, brand, brandName, routingNumber, lastDigits, secondaryLabel, disabled } = label

            return {
              value: id,
              label: (
                <Flex fullWidth flexWrap='wrap' justifyContent='space-between' alignItems='flex-start' gap={1}>
                  <Flex flexDirection='column' className={clsx({ [classes.disabledRadioLabel]: disabled })}>
                    <Flex gap={1} flexWrap='nowrap'>
                      <P component='span' fontWeight={500}>
                        {brandName}
                      </P>
                      <P component='span' color={60}>
                        {lastDigits}
                      </P>
                    </Flex>
                    <PSmall color={60}>{secondaryLabel}</PSmall>
                  </Flex>
                  <PayInMethodDisabledChip payInMethod={payInMethod} />
                </Flex>
              ),
              className: classes.payInMethodOptionRadioInput,
              adornment: <PayInMethodIcon type={type} brand={brand} routingNumber={routingNumber} />,
              disabled: disabled,
            }
          }) || []
        }
      />
    </ColumnGridItem>
  )
}

export const PayInMethodSelector: React.FC<Props> = ({
  showLabel = true,
  invoiceAmount,
  name = 'payInMethod',
  condensed = false,
}) => {
  const { modalManager } = usePayInMethodContext()

  return (
    <Flex flexDirection='column' gap={2}>
      {condensed ? (
        <MinimizedPayInMethodSelector name={name} invoiceAmount={invoiceAmount} showLabel={showLabel} />
      ) : (
        <FormContent name={name} invoiceAmount={invoiceAmount} />
      )}
      <Flex flexDirection='column' gap={modalManager.buttonType.value === 'p' ? 1 : 2}>
        <AddPayInMethod />
        <SelectedPayInMethod />
      </Flex>
    </Flex>
  )
}
