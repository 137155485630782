import { Gender, SupportedLanguages } from '@guiker/base-entity'
import { TypeOf, yup } from '@guiker/yup-util'

import { User } from '../../../entity'

export const updateUserPayloadSchema = yup.object<Partial<User>>({
  fullLegalName: yup.string(),
  birthDate: yup.string(),
  nationality: yup.string(),
  emailAddress: yup.string(),
  phone: yup.string(),
  language: yup.mixed().oneOf(Object.values(SupportedLanguages)),
  gender: yup.mixed().oneOf(Object.values(Gender)).nullable(),
})

export type UpdateUserPayloadSchema = TypeOf<typeof updateUserPayloadSchema>
