import React from 'react'

import {
  ApplicationStatus,
  Booking,
  BookingLeaseStatus,
  BookingStatus,
  ContributionStatus,
  getParticipantType,
  inferApplicantApplicationStepStatus,
  isBookingInProgress,
  isRoommatable,
  Participant,
  ParticipantStepStatus,
  RoommateIntroStatus,
  StepTypes,
} from '@guiker/booking-shared'

export type EnabledStep = {
  enabled: boolean
  order: number
  isCompleted: boolean
  isFirst: boolean
  isLast: boolean
  isNext: boolean
  status: ParticipantStepStatus
}

export type EnabledSteps = Partial<{
  [key in StepTypes]: EnabledStep
}>

const getUMSteps = (booking: Booking): StepTypes[] => {
  return [
    StepTypes.APPLICATION,
    ...(isRoommatable(booking.listing) ? [StepTypes.ROOMMATE] : []),
    ...(!!booking.documents?.length || isBookingInProgress(booking) ? [StepTypes.DOCUMENT] : []),
    StepTypes.LEASE,
    StepTypes.PAYMENT,
  ]
}

const getApplicantSteps = (booking: Booking): StepTypes[] => {
  return [
    StepTypes.APPLICATION,
    ...(isRoommatable(booking.listing) ? [StepTypes.ROOMMATE] : []),
    StepTypes.DOCUMENT,
    StepTypes.LEASE,
    StepTypes.PAYMENT,
  ]
}

export const isCompleted = (bookingStatus: BookingStatus, status: ParticipantStepStatus, stepName: string) => {
  if (stepName === 'payment') {
    return (
      [ParticipantStepStatus.COMPLETED, ParticipantStepStatus.FINALIZED].includes(status) ||
      (status === ParticipantStepStatus.PROCESSING && bookingStatus === BookingStatus.CONFIRM_REQUESTED)
    )
  }
  return [ParticipantStepStatus.COMPLETED, ParticipantStepStatus.FINALIZED].includes(status)
}

const allContributionPayInMethodReady = (booking: Booking) => {
  return (
    booking.bookingPayment.contributions.every((c) => {
      return c.isWaived || c.status === ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION || c.amount === 0
    }) && isContributionTotalAmountFull(booking)
  )
}

export const isContributionTotalAmountFull = (booking: Booking) => {
  const { contributions, total } = booking.bookingPayment
  const contributionTotal = contributions.reduce((sum, c) => sum + (c.amount || 0), 0)
  return contributionTotal === total.amount
}

const getApplicantStatus = (booking: Booking, participant: Participant, step: StepTypes) => {
  switch (step) {
    case StepTypes.APPLICATION:
      const applicant = booking?.applicants?.find((a) => a.userId === participant.userId)
      return inferApplicantApplicationStepStatus(applicant?.application?.status)
    case StepTypes.ROOMMATE:
      const roommateStep = participant.steps.roommate
      return roommateStep?.status ?? ParticipantStepStatus.NOT_STARTED
    case StepTypes.PAYMENT:
      const paymentStep = participant.steps.payment

      if (paymentStep?.status === ParticipantStepStatus.COMPLETED) {
        return isContributionTotalAmountFull(booking) ? ParticipantStepStatus.COMPLETED : ParticipantStepStatus.STARTED
      }

      return paymentStep?.status
    default:
      return participant.steps[step]?.status
  }
}

const getUMStatus = (booking: Booking, participant: Participant, step: StepTypes) => {
  switch (step) {
    case StepTypes.APPLICATION:
      if (booking.applicants.every((a) => a.application?.status === ApplicationStatus.ACCEPTED)) {
        return ParticipantStepStatus.COMPLETED
      }
      return participant.steps[step]?.status
    case StepTypes.ROOMMATE:
      if (booking.applicants.every((a) => a.application?.roommateIntro?.status === RoommateIntroStatus.ACCEPTED)) {
        return ParticipantStepStatus.COMPLETED
      }
      return participant.steps[step]?.status ?? ParticipantStepStatus.NOT_STARTED
    case StepTypes.DOCUMENT:
      if (!booking.documents || booking.documents.length === 0) {
        return ParticipantStepStatus.NOT_STARTED
      }

      return participant.steps[step]?.status
    case StepTypes.LEASE:
      if (booking.lease?.status === BookingLeaseStatus.SIGNED_BY_ALL_PARTIES) {
        return ParticipantStepStatus.COMPLETED
      }

      return participant.steps[step]?.status
    case StepTypes.PAYMENT:
      if (booking.status === BookingStatus.BOOKED) {
        return ParticipantStepStatus.COMPLETED
      }

      if (allContributionPayInMethodReady(booking)) {
        return ParticipantStepStatus.COMPLETED
      }

      if (booking.bookingPayment.contributions.find((c) => c.status === ContributionStatus.PAY_IN_METHOD_REJECTED)) {
        return ParticipantStepStatus.FAILED
      }

      return ParticipantStepStatus.NOT_STARTED
  }
}

export const calculateSteps = (booking: Booking, participant: Participant) => {
  let completedStepsCount = 0
  let stepsCount = 0

  if (!booking.hasEnabledStep) {
    return {
      stepsCount,
      completedStepsCount,
      enabledSteps: {},
    }
  }
  const isApplicant = getParticipantType(participant) === 'applicant'
  const steps = isApplicant ? getApplicantSteps(booking) : getUMSteps(booking)
  const isBooked = booking.status === BookingStatus.BOOKED

  let orderIndex = 1
  let lastActiveStep = ''
  let isNextSet = false

  const enabledSteps = steps.reduce((result, step) => {
    const enabled = booking.hasEnabledStep[step]
    const order = enabled ? orderIndex++ : 0
    const status = isApplicant
      ? getApplicantStatus(booking, participant, step)
      : getUMStatus(booking, participant, step)
    let isNext = false
    const isStepCompleted = isBooked || isCompleted(booking.status, status, step)

    if (enabled) {
      lastActiveStep = step
      stepsCount++
    }

    if (enabled && isStepCompleted) {
      completedStepsCount++
    }

    if (enabled && !isNextSet && !isStepCompleted) {
      isNextSet = true
      isNext = true
    }

    result[step] = {
      isFirst: order === 1,
      isLast: false,
      isNext,
      isCompleted: isStepCompleted,
      enabled,
      order,
      status,
    }

    return result
  }, {} as EnabledSteps)

  if (enabledSteps[lastActiveStep]) {
    enabledSteps[lastActiveStep].isLast = true
  }

  return {
    stepsCount,
    completedStepsCount,
    enabledSteps,
  }
}

type ProgressTrackerItemBuilder = {
  content: React.ReactNode
  collapsed?: boolean
  collapsible?: React.ReactNode
  disabled?: boolean
  link?: string
  isExternalLink?: boolean
}

export const generateProgressTrackerItemBuilder = (step: EnabledStep) => {
  return (builder: (step?: EnabledStep) => ProgressTrackerItemBuilder) => {
    if (!step) return
    const { content, collapsed = true, collapsible, link, isExternalLink, disabled } = builder(step)
    const isDisabled =
      disabled || [ParticipantStepStatus.NOT_READY, ParticipantStepStatus.PROCESSING].includes(step.status)

    const { isCompleted, status, ...stepAttributes } = step
    const isFailed = step.status === ParticipantStepStatus.FAILED

    return {
      collapsed,
      collapsible,
      content,
      disabled: isDisabled,
      link,
      isExternalLink,
      marker: {
        ...stepAttributes,
        isFailed,
        isCompleted: isCompleted || step.status === ParticipantStepStatus.COMPLETED,
        status,
      },
    }
  }
}
