import { User } from '@guiker/authentication-shared'
import { flatten } from '@guiker/lodash'
import { Role, RoleResolver } from '@guiker/permissions'

import { AdminRoleNames, Roles } from './roles'

const findRole = (authUser: User, roleName: string) => {
  return authUser && authUser.appRoles?.some(({ appRoleName }) => appRoleName === roleName)
}

const accessControlRoleResolver: RoleResolver<User> = ({ entity: authUser, user: roleOwner }): Role[] => {
  const roles = []
  if (findRole(authUser, AdminRoleNames.SUPER_ADMINISTRATOR) && authUser.id === roleOwner?.id) {
    roles.push(Roles.SuperAdministrator)
  }
  if (findRole(authUser, AdminRoleNames.INVESTOR_PROFILE_REVIEWER)) {
    roles.push(Roles.InvestorProfileReviewer)
  }
  return roles
}

const accessControlPermissionResolver = (roles: Role[]): string[] => {
  return flatten(
    roles.map((role) =>
      flatten(
        role.permissions.map((permission) =>
          Array.isArray(permission.action) ? flatten(permission.action) : [permission.action],
        ),
      ),
    ),
  )
}

export { accessControlRoleResolver, accessControlPermissionResolver }
