import { CurrencyISO } from '@guiker/money'

import { SupportedCountries } from '../payment'

export const getCountryByCurrency = (currency: CurrencyISO) => {
  switch (currency) {
    case CurrencyISO.CAD:
      return SupportedCountries.Canada
    case CurrencyISO.USD:
      return SupportedCountries.UnitedStates
    default:
      throw new Error(`no country mapped for ${currency}`)
  }
}
