import React from 'react'

import { AuthAssetFetcherContextProvider } from '@guiker/asset-uploader'

import { IdentityVerification } from '../IdentityVerification'
import { IdentityVerificationContextProvider } from '../IdentityVerificationContext'
import { IdentityVerificationDocuments } from '../IdentityVerificationDocuments'

type IdentityVerificationProps = {
  defaultValue?: IdentityVerification
}

export const IdentityVerificationSummary: React.FC<IdentityVerificationProps> = ({ defaultValue }) => {
  return (
    <IdentityVerificationContextProvider
      defaultValue={{
        ...defaultValue,
        governmentIdFront: { image: defaultValue?.governmentIdFront },
        governmentIdBack: { image: defaultValue?.governmentIdBack },
        facePicture: { image: defaultValue?.facePicture },
      }}
    >
      <AuthAssetFetcherContextProvider
        assetGroups={[
          {
            type: 'governmentIdFront',
            assets: [defaultValue.governmentIdFront],
          },
          {
            type: 'governmentIdBack',
            assets: [defaultValue.governmentIdBack],
          },
          {
            type: 'facePicture',
            assets: [defaultValue.facePicture],
          },
        ]}
      >
        <IdentityVerificationDocuments defaultValue={defaultValue} />
      </AuthAssetFetcherContextProvider>
    </IdentityVerificationContextProvider>
  )
}
