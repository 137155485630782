const quebecUniversityWhitelist = {
  ConcordiaUniversityLoyolaCampus: 'ChIJp3MoHy4XyUwRkr_5bwBScNw',
  ConcordiaUniversity: 'ChIJOdZtY2oayUwR8CdYQcbKhZ4',
  McGillUniversity: 'ChIJNZYPUUYayUwRdK6kAJLa5N0',
  ETS: 'ChIJjdS1JAMbyUwR7_P-H7BXk04',
  UQAM: 'ChIJDW5a808ayUwRgFg-f63Zwh0',
  UniversiteDeMontreal: 'ChIJ8T18jkYZyUwRKGKDCTUosBQ',
}

const torontoUniversityWhitelist = {
  GeorgeBrownCollegeCasaLomaCampus: 'ChIJf3IUfpw0K4gRDfOavQVu0Is',
  CentennialCollege: 'ChIJjwfZH_LQ1IkRGCvNwJL-40Y',
  UniversityOfTorontoStGeorgeCampus: 'ChIJm_0x87g0K4gR93ZadrabHY0',
  UniversityOfTorontoScarborough: 'ChIJf9Wrt2_a1IkRrHuIaQFuZbs',
  OCADUniversity: 'ChIJXRsCmM80K4gRutjp5gJkQR8',
  RyersonUniversity: 'ChIJlRMcQzXL1IkR9NYlkdaL7eg',
  YorkUniversity: 'ChIJtah9KqwvK4gRGKm9qB7qb64',
  TrinityCollege: 'ChIJzQd-DLw0K4gRoqqSLgWbDtE',
}

export const universityWhitelist = {
  ...quebecUniversityWhitelist,
  ...torontoUniversityWhitelist,
}
