import React, { useState } from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import {
  Box,
  CheckboxAdornment,
  Flex,
  FloatingBadge,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  P,
  PSmall,
  PSmaller,
  RouterLink,
  TaskIcon,
  toPx,
  useDateFormatter,
  useT,
} from '@guiker/react-framework'
import { taskMapping } from '@guiker/task-components'
import { useAuthenticatedTaskContext } from '@guiker/task-context'
import { Status } from '@guiker/task-shared'

export type TaskItem = {
  date?: string
  path?: string
  header: string
  body: string
  category?: string
}

const useStyle = makeStyles({
  checkbox: {
    width: toPx(15),
    height: toPx(15),
    marginTop: toPx(5),
  },
})

export const TaskMenuItem: React.FC<TaskItem> = ({ path, header, body, date }) => {
  const classes = useStyle()
  const { formatWithRelativeDateTime } = useDateFormatter()

  return (
    <MenuItem>
      <RouterLink to={path}>
        <Flex gap={2} p={1} width='100%' alignItems='flex-start'>
          <CheckboxAdornment checked={false} className={classes.checkbox} />
          <Flex flexDirection='column' gap={1} flexGrow={1}>
            <Flex flexDirection='column' gap={0.5}>
              <P mb={0} color={'black'} fontWeight={500}>
                {header}
              </P>
              {body && (
                <PSmall mb={0} color={60}>
                  {body}
                </PSmall>
              )}
            </Flex>
            <PSmaller mb={0} color={60}>
              {formatWithRelativeDateTime(date)}
            </PSmaller>
          </Flex>
        </Flex>
      </RouterLink>
    </MenuItem>
  )
}

export const TasksMenu: React.FC = () => {
  const { getFilteredTasks, tasks } = useAuthenticatedTaskContext()
  const [anchor, setAnchor] = useState<Element>(null)
  const { tShared, t } = useT({ domain: 'task' })
  const filteredTask = getFilteredTasks({ status: [Status.CREATED] })
    ?.slice(0, 4)
    ?.map((task) => taskMapping(task, t))

  const onClick = (event?: React.SyntheticEvent) => {
    anchor ? onClose() : setAnchor(event?.currentTarget)
  }

  const onClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <FloatingBadge badgeNumber={filteredTask?.length > 0 ? filteredTask?.length : null}>
          <TaskIcon />
        </FloatingBadge>
        <Menu
          maxWidth={500}
          open={!!anchor}
          anchorEl={anchor}
          onClose={onClose}
          disableAutoFocusItem={true}
          anchorOrigin={{ vertical: 32, horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {filteredTask?.length ? (
            <>
              {filteredTask.map((task, index) => {
                return <TaskMenuItem key={`task-${index}`} {...task} />
              })}

              <MenuItem button={false} minHeight={24}>
                <Box pl={1}>
                  <Link color={60} to={mainPathBuilder.root.todos.path} underline={false}>
                    <PSmall mb={0} color={60}>
                      {tShared('tasks.seeAll', { count: tasks?.length })}
                    </PSmall>
                  </Link>
                </Box>
              </MenuItem>
            </>
          ) : (
            <MenuItem>
              <Box pl={3}>
                <Flex flexDirection='column' gap={0.5}>
                  <P mb={0} fontWeight={500} color='secondary'>
                    {tShared('tasks.emptyState.label')}
                  </P>
                  <PSmall mb={0} color={60}>
                    {tShared('tasks.emptyState.description')}
                  </PSmall>
                </Flex>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </IconButton>
    </>
  )
}
