export const MultipostListingInquiryEventTypes = {
  RENTSYNC_INQUIRY_CREATED: {
    type: 'RENTSYNC_INQUIRY_CREATED',
    eventData: null as {
      firstName: string
      lastName: string
      fullName: string
      phone: string
      source: string
      email: string
      comment: string
      sentAt: string
      adUrl: string
      recipientEmail: string
      inboundEmailId: number
      buildingId: string
      propertyName: string
      listingId: string
    },
  },
}
