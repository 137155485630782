import { TypeOf, yup } from '@guiker/yup-util'

import { Creator, Initials, LeaseRoleNames, Quebec } from '../../../entity'
import { initials } from '../../schemas'

const nullString = yup
  .string()
  .nullable()
  .transform(() => null)

const nullNumber = yup
  .number()
  .nullable()
  .transform(() => null)

const nullableBoolean = yup.boolean().nullable().default(false)

const conditionalNumberSchema = (name: string) =>
  yup.number().when(name, {
    is: true,
    then: yup.number().required(),
    otherwise: nullNumber,
  })

const conditionalStringSchema = (name: string) =>
  yup.string().when(name, {
    is: true,
    then: yup.string().required(),
    otherwise: nullString,
  })

const Purpose = yup.object<Quebec.DwellingPurpose>({
  isResidentialOnly: yup.boolean().required(),
  additionalPurpose: yup.string().when('isResidentialOnly', {
    is: false,
    then: yup.string().required(),
    otherwise: nullString,
  }),
})

const ParkingInformation = yup.object<Quebec.ParkingInformation>({
  isAvailable: nullableBoolean,
  numberOfPlaces: conditionalNumberSchema('isAvailable'),
  spaces: conditionalStringSchema('isAvailable'),
})

const Appliances = yup.object<Quebec.Appliances>({
  dishwasher: nullableBoolean,
  dryer: nullableBoolean,
  microwaveOven: nullableBoolean,
  refrigerator: nullableBoolean,
  stove: nullableBoolean,
  washer: nullableBoolean,
})

const Furniture = yup.object<Quebec.Furniture>({
  armchairs: nullableBoolean,
  armchairsNumber: conditionalNumberSchema('armchairs'),
  beds: nullableBoolean,
  bedsNumber: conditionalNumberSchema('beds'),
  bedsSize: yup.array().of(yup.string()),
  chairs: nullableBoolean,
  chairsNumber: conditionalNumberSchema('chairs'),
  chestsOfDrawers: nullableBoolean,
  chestsOfDrawersNumber: conditionalNumberSchema('chestsOfDrawers'),
  couches: nullableBoolean,
  couchesNumber: conditionalNumberSchema('couches'),
  other: nullableBoolean,
  otherDescription: conditionalStringSchema('other'),
  tables: nullableBoolean,
  tablesNumber: conditionalNumberSchema('tables'),
})

export const lockerOrStorageSpace = yup.object<Quebec.LockerOrStorageSpace>({
  isAvailable: nullableBoolean,
  description: conditionalStringSchema('isAvailable'),
})

export const accessoriesAndDependencies = yup.object<Quebec.AccessoriesAndDependencies>({
  isAvailable: nullableBoolean,
  description: conditionalStringSchema('isAvailable'),
})

export const dwelling = yup.object<
  Omit<Quebec.Dwelling & { smokeDetectorsInitials: Initials; draftedAs: Creator['draftedAs'] }, 'address' | 'completed'>
>({
  accessoriesAndDependencies: accessoriesAndDependencies.required(),
  lockerOrStorageSpace: lockerOrStorageSpace.required(),
  appliances: Appliances.nullable(),
  furniture: Furniture.nullable(),
  isCoOwnership: yup.boolean().required(),
  indoorParking: ParkingInformation.required(),
  isFurnished: yup.boolean().required().default(false),
  numberOfRooms: yup.string().required(),
  outdoorParking: ParkingInformation.required(),
  purpose: Purpose.nullable(),
  smokeDetectorsInitials: initials.when('draftedAs', {
    is: LeaseRoleNames.DRAFTER,
    then: initials.nullable().transform(() => null),
    otherwise: initials.required(),
  }),
  draftedAs: yup
    .mixed<LeaseRoleNames.DRAFTER | LeaseRoleNames.LESSOR>()
    .oneOf([LeaseRoleNames.DRAFTER, LeaseRoleNames.LESSOR])
    .required(),
})

export type Dwelling = TypeOf<typeof dwelling>
