import { addressFormatter } from '@guiker/base-entity'
import { BuildingTypes, Project, projectHasDocument } from '@guiker/real-estate-shared'

import { PropSharingListing } from '../entity'

export const hasDocument = (listing: PropSharingListing, project?: Project) => {
  return (listing.documents && listing.documents.length) || projectHasDocument(project)
}

export const getDisplayName = (listing: PropSharingListing, project?: Project) => {
  if (listing.stock.label) {
    return listing.stock.label
  }

  const isPreCon = listing?.property?.building?.type === BuildingTypes.CONDO && !!project

  const streetNumber =
    listing?.property?.units[0]?.address?.streetNumber ?? listing?.property?.building?.address?.streetNumbers[0]

  return isPreCon
    ? `${project.name} - ${listing?.property?.units[0]?.address?.unitNumber}`
    : addressFormatter.printShortAddress({
        ...listing?.property?.building?.address,
        postalCode: listing?.property?.building?.address?.zipcode,
        suite: listing?.property?.units[0]?.address?.unitNumber,
        streetNumber,
      })
}
