import { DateTime } from '@guiker/date'
import { TFunction } from '@guiker/i18n'
import { VisitSchedule } from '@guiker/listing-inquiry-shared'
import { extractAsLeadOption, RentalListing } from '@guiker/rental-listing-shared'
import { addressFormatter, stringToNumber } from '@guiker/shared-framework'

type Args = {
  listing: RentalListing
  phone: string
  t: TFunction
  message: string
  visitSchedules?: VisitSchedule[]
  moveInDate?: string
  rentalOptionId?: string
}

const buildAvailabilities = (visitSchedules: VisitSchedule[], t: TFunction) => {
  const tPrefix = 'main-rentalListingInquiry:timeSlotScheduler'
  const availabilities = visitSchedules.map(({ date, timeSlot }) => {
    const filteredTimes = Object.keys(timeSlot).filter((key: keyof typeof timeSlot) => timeSlot[key])
    const formattedTimes = filteredTimes
      .map((key, index) => {
        const time = t(`${tPrefix}.options.${key}`)
        return `${index > 0 ? (index === filteredTimes.length - 1 ? ` ${t('common:or')} ` : ', ') : ''}${time}`
      })
      .join('')
    return `${DateTime.fromISO(date).toFormat('MMM dd')} / ${formattedTimes}`
  })
  return `${t(`${tPrefix}.myAvailabilities`)}\n${availabilities.join('\n')}`
}

export const formatInquiryMessage = ({
  listing,
  phone,
  t,
  message,
  visitSchedules = [],
  moveInDate = undefined,
  rentalOptionId = undefined,
}: Args) => {
  const selectedOption = extractAsLeadOption(listing, stringToNumber(rentalOptionId))
  const isRoomRental = selectedOption.type === 'PRIVATE_ROOM'
  const tPrefix = 'main-rentalListingInquiry:inquiryMessage'
  const address = addressFormatter.printFullAddress(listing.address)
  let result = t(`${tPrefix}.${isRoomRental ? 'roomRental' : 'unitRental'}`, { address })

  if (phone) {
    result += t(`${tPrefix}.withPhone`, { phone })
  }

  if (visitSchedules.length) {
    result += `\n\n${buildAvailabilities(visitSchedules, t)}`
  }

  if (moveInDate) {
    result += `\n\nMove-in date: ${moveInDate}`
  }

  if (message) {
    result += `\n\n${message}`
  }

  return result
}
