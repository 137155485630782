import { assumptionsSchema, priceSchema } from '@guiker/base-listing-shared'
import { TypeOf, yup } from '@guiker/yup-util'

export const updateListingAssumptionsSchema = yup
  .object({
    price: priceSchema.required(),
    assumptions: assumptionsSchema.required(),
  })
  .required()

export type UpdateListingAssumptionsSchema = TypeOf<typeof updateListingAssumptionsSchema>
