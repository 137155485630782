import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { PropertySale } from '../../entity'
import { baseEvent } from '../base'

const basePropertySaleEvent = {
  ...baseEvent,
  entity: 'PROPERTY_SALE',
  data: null as unknown as PropertySale,
}

export enum PropertySaleEventTypes {
  REPRESENTATIVE_ADDED = 'REPRESENTATIVE_ADDED',
  REPRESENTATIVE_LEFT = 'REPRESENTATIVE_LEFT',
}

export const PropertySaleEventsDefinition = generateEventsDefinition({
  ...basePropertySaleEvent,
  eventTypes: PropertySaleEventTypes,
})

export const PropertySaleEvents = generateEventsFromEnum(basePropertySaleEvent)(PropertySaleEventTypes)
