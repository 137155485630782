import React, { createContext, PropsWithChildren } from 'react'

import { generateUseContext } from '@guiker/react-context'

import { BaseTaskContext, TaskContextProvider } from '../base'
import { useAdminApiClient } from './use-api-client'

export const AdminTaskContext = createContext<BaseTaskContext>(null)

export const AdminTaskContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const taskApiClient = useAdminApiClient()

  return (
    <TaskContextProvider apiClient={taskApiClient} ContextProvider={AdminTaskContext.Provider}>
      {children}
    </TaskContextProvider>
  )
}

export const useAdminTaskContext = generateUseContext(AdminTaskContext)
