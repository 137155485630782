export enum EventTypes {
  ADDITIONAL_TERMS_UPDATED = 'ADDITIONAL_TERMS_UPDATED',
  CONTACT_INFORMATION_UPDATED = 'CONTACT_INFORMATION_UPDATED',
  GUARANTOR_SIGNATURE_UPDATED = 'GUARANTOR_SIGNATURE_UPDATED',
  LEASE_TERM_AND_RENT_UPDATED = 'LEASE_TERM_AND_RENT_UPDATED',
  LESSEE_SIGNATURE_UPDATED = 'LESSEE_SIGNATURE_UPDATED',
  LESSOR_SIGNATURE_UPDATED = 'LESSOR_SIGNATURE_UPDATED',
  PARTIES_TO_THE_AGREEMENT_AND_RENTAL_UNIT_UPDATED = 'PARTIES_TO_THE_AGREEMENT_AND_RENTAL_UNIT_UPDATED',
  RENT_DEPOSIT_RENT_DISCOUNTS_AND_KEY_DEPOSIT_UPDATED = 'RENT_DEPOSIT_RENT_DISCOUNTS_AND_KEY_DEPOSIT_UPDATED',
  SERVICES_AND_UTILITIES_UPDATED = 'SERVICES_AND_UTILITIES_UPDATED',
  SMOKING_AND_TENANTS_INSURANCE_UPDATED = 'SMOKING_AND_TENANTS_INSURANCE_UPDATED',
}
