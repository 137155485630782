import { money } from '@guiker/money'

import { CountryCode } from '../address'

export const getCurrencyByCountry = (country: CountryCode) => {
  switch (country) {
    case CountryCode.CA:
      return money.currency.CAD
    case CountryCode.US:
      return money.currency.USD
    default:
      throw new Error(`no currency mapped for ${country}`)
  }
}
