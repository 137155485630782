import { TypeOf, yup } from '@guiker/yup-util'

import { imageSchema } from './common'

export const compareFacesSchema = yup
  .object({
    source: imageSchema.required(),
    target: imageSchema.required(),
  })
  .required()

export type CompareFacesSchema = TypeOf<typeof compareFacesSchema>
