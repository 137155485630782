import { Payment, yup } from '@guiker/shared-framework'

import { SupportedCountries } from '../../../../entity'

export const createDirectDebitWithVerificationPayloadSchema = yup.object({
  recaptchaToken: yup.string().required(),
  bankAccountId: yup.string().required(),
  publicToken: yup.string().required(),
  payInMethodType: yup
    .string()
    .oneOf<Payment.PayInMethodType>([
      Payment.PayInMethodType.DIRECT_DEBIT_ACH,
      Payment.PayInMethodType.DIRECT_DEBIT_PAD,
    ])
    .required(),
  region: yup
    .string()
    .oneOf(Object.values(SupportedCountries))
    .when('payInMethodType', (type: Payment.PayInMethodType, schema: yup.StringSchema) => {
      if (type === Payment.PayInMethodType.DIRECT_DEBIT_ACH) {
        return schema.oneOf([SupportedCountries.UnitedStates]).required()
      } else if (type === Payment.PayInMethodType.DIRECT_DEBIT_PAD) {
        return schema.oneOf([SupportedCountries.Canada]).required()
      } else {
        return false
      }
    })
    .required(),
})

export type CreateDirectDebitWithVerificationPayloadSchema = yup.InferType<
  typeof createDirectDebitWithVerificationPayloadSchema
>
