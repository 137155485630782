export * from './address'
export * from './creator'
export * from './initials'
export * from './lease'
export * from './collaborator'
export * from './lease-term'
export * from './lease-types'
export * from './person'
export * from './role-names'
export * from './signature'
import * as Legacy from './legacy'
import * as Ontario from './ontario'
import * as Quebec from './quebec'

export { Ontario, Quebec, Legacy }
