import React from 'react'

import {
  Chip,
  Collapse,
  FormSection,
  FormSubSection,
  MessagesBubbleIcon,
  Note,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { documentTypes, GuarantorStatus, TenantApplication } from '@guiker/tenant-application-shared'

import { BackgroundCheckConsentFormContent } from './../BackgroundCheck/BackgroundCheckConsentFormContent'
import { GuarantorAddress } from './GuarantorAddress'
import { GuarantorProfile } from './GuarantorProfile'
import { GuarantorSupportingDocument } from './GuarantorSupportingDocument'
import { InviteGuarantor } from './InviteGuarantor'

type GuarantorDetailsProps = {
  defaultValue: Partial<TenantApplication>
  formPrefix?: string
  hasGuarantor: boolean
  schema: yup.ObjectSchema
  readOnly: boolean
  withThumbnail: boolean
}

const GuarantorDetails: React.FC<GuarantorDetailsProps> = ({
  defaultValue,
  hasGuarantor,
  formPrefix = 'guarantor',
  schema,
  readOnly,
}) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const tPrefix = 'main-tenantApplication:'
  const guarantorCompleted = defaultValue?.guarantor?.status === GuarantorStatus.COMPLETED
  const guarantor = defaultValue?.guarantor

  const willInviteGuarantor = useWatch<TenantApplication, 'willInviteGuarantor'>({
    name: 'willInviteGuarantor',
    defaultValue: defaultValue?.willInviteGuarantor,
  })

  const guarantorEmail = useWatch<TenantApplication, 'guarantor.profile.emailAddress'>({
    name: 'guarantor.profile.emailAddress',
    defaultValue: guarantor?.profile?.emailAddress,
  })

  const hasGuarantorEmailChanged = guarantor?.profile?.emailAddress !== guarantorEmail

  const namespace = 'main-tenantApplication:user'
  const dependency = {
    hasGuarantor,
  }

  const inputProps = useGetInputProps({
    namespaces: ['common', 'main-tenantApplication'],
    tPrefix: 'common:user',
    formPrefix: 'guarantor.profile',
    defaultValue: defaultValue,
    schema,
    readOnly,
  })

  return (
    <>
      <FormSubSection
        title={t(`${tPrefix}screens.steps.guarantor.details.title`)}
        subtitle={!readOnly && t(`${tPrefix}screens.steps.guarantor.details.description`)}
      >
        <TwoColumnsGridLayout>
          <TextField {...inputProps('relationship', { namespace, dependency })} />
          <TextField {...inputProps('emailAddress', { dependency })} />
        </TwoColumnsGridLayout>
        <RadioGroup
          name='willInviteGuarantor'
          defaultValue={defaultValue?.willInviteGuarantor}
          options={[
            {
              label: t(`${tPrefix}willInviteGuarantor.true`),
              value: true,
              adornment: !hasGuarantorEmailChanged && !!guarantor?.status && (
                <Chip color={guarantorCompleted ? 'success' : undefined}>
                  {t('main-tenantApplication:willInviteGuarantor.response', {
                    status: guarantorCompleted
                      ? t('main-tenantApplication:willInviteGuarantor.complete')
                      : t('main-tenantApplication:willInviteGuarantor.pending'),
                  })}
                </Chip>
              ),
              adornmentPosition: 'end',
            },
            { label: t(`${tPrefix}willInviteGuarantor.false`), value: false },
          ]}
          mb={3}
          maxWidth='100%'
          readOnly={readOnly}
          condensed={readOnly}
        />
        {willInviteGuarantor && <InviteGuarantor defaultValue={guarantor} />}
      </FormSubSection>
      <Collapse
        in={!willInviteGuarantor || (guarantor.status === GuarantorStatus.COMPLETED && !hasGuarantorEmailChanged)}
      >
        <FormSection>
          <GuarantorProfile
            defaultValue={defaultValue}
            formPrefix={`${formPrefix}.profile`}
            schema={schema}
            readOnly={readOnly}
          />
          <GuarantorAddress
            defaultValue={guarantor?.address}
            formPrefix={`${formPrefix}.address`}
            schema={schema}
            readOnly={readOnly}
          />

          <FormSubSection title={t(`main-tenantApplication:screens.steps.guarantor.supportingDocuments.title`)}>
            {documentTypes.map((documentType, index) => (
              <GuarantorSupportingDocument
                key={index}
                defaultValue={guarantor?.supportingDocuments?.find((doc) => doc?.type === documentType)}
                documentType={documentType}
                name={`guarantor.supportingDocuments[${index}]`}
                readOnly={readOnly}
              />
            ))}
          </FormSubSection>

          <FormSubSection title={t(`main-tenantApplication:screens.steps.guarantor.backgroundCheck.title`)}>
            <Note statusColor='info' title={t('common:note')} icon={<MessagesBubbleIcon />} mb={2} mt={1}>
              {t(`${tPrefix}screens.steps.guarantor.note`)}
            </Note>
            <BackgroundCheckConsentFormContent
              defaultValue={guarantor?.backgroundCheck?.consent}
              formPrefix={`${formPrefix}.backgroundCheck.consent`}
              schema={schema}
              consentFor='guarantor'
              disabled
            />
          </FormSubSection>
        </FormSection>
      </Collapse>
    </>
  )
}

export { GuarantorDetails }
