import React, { createContext, useMemo } from 'react'

import { DeepPartial, merge } from '@guiker/shared-framework'

import { baseConfig } from './base-config'
import { Config } from './config'

export const ConfigContext = createContext<Config>(null)

type Props = React.PropsWithChildren & { value?: DeepPartial<Config> }

export const ConfigContextProvider: React.FC<Props> = ({ children, value = {} }) => {
  const contextValue = useMemo(() => merge(baseConfig, value), [baseConfig, value])
  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>
}
