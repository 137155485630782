import React, { useState } from 'react'

import { useTranslation } from '@guiker/i18n'
import { EyeMiniIcon } from '@guiker/icons'
import { TextField } from '@guiker/react-framework'

import { makeStyles, TextFieldProps } from '..'

const useStyles = makeStyles({
  adornment: {
    cursor: 'pointer',
  },
})

const PasswordTextField: React.FC<TextFieldProps> = ({
  className,
  label,
  name = 'password',
  required = true,
  disabled,
}) => {
  const { t } = useTranslation('common')
  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)
  const handleShowPasswordClick = () => setShowPassword(!showPassword)

  return (
    <TextField
      className={className}
      label={label || t('password')}
      name={name}
      type={showPassword ? 'text' : 'password'}
      disabled={disabled}
      required={required}
      endAdornment={<EyeMiniIcon className={classes.adornment} onClick={handleShowPasswordClick} />}
    />
  )
}

export { PasswordTextField }
