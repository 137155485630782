export type Address = {
  city: string
  cityName?: string
  country: string
  neighbourhood?: string
  postalCode: string
  state?: string
  street: string
  streetNumber: string
  suite?: string | null
}

export enum CountryCode {
  CA = 'CA',
  US = 'US',
}
