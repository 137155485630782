import { Article, Media } from './article'

export const getFeaturedMedia = (article: Article): Media | undefined => {
  return (
    article._embedded &&
    article._embedded['wp:featuredmedia']?.find((featureMedia) => featureMedia.id === article.featured_media)
  )
}

export const getMediaUrl = (article: Article, size: keyof Media['media_details']['sizes']): string => {
  const media = getFeaturedMedia(article)

  return media?.media_details.sizes[size]?.source_url || media?.media_details.sizes.full.source_url
}

export const getCategories = (article: Article) => {
  return (
    article._embedded &&
    article._embedded['wp:term'] &&
    article._embedded['wp:term'][0]?.filter((t) => t.taxonomy === 'category')
  )
}

export const getCategory = (article: Article, categoryId: number) => {
  return getCategories(article)?.find((c) => c.id === categoryId)
}
