import React from 'react'

import { PublicApi } from '@guiker/authentication-shared'
import {
  ApiForm,
  Button,
  Flex,
  H3,
  PageLayout,
  PageMetaTags,
  PSmall,
  TextField,
  theme,
  yupResolver,
} from '@guiker/react-framework'
import { useNavigate } from '@guiker/router'

import { useMediaQuery, usePublicAuthenticationApiClient } from '../../hooks'
import { useTranslation } from '../../i18n'
import { routes } from '../../routes'

const FormContent: React.FC<{ isProcessing: boolean }> = ({ isProcessing }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const MAX_WIDTH = 328
  return (
    <Flex width={isMobile ? '100%' : MAX_WIDTH} flexDirection='column' gap={4}>
      <TextField label={t('common:user.emailAddress')} name='email' required />
      <Button color='primary' type='submit' disabled={isProcessing} fullWidth={true}>
        {t('webapp:forgotPassword.cta')}
      </Button>
    </Flex>
  )
}

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('webapp')
  const navigate = useNavigate()

  const apiClient = usePublicAuthenticationApiClient()
  const onSubmit = async (payload: PublicApi.Schemas.PasswordReset) => apiClient.requestPasswordReset({ payload })
  const onSuccess = () => navigate(routes.RequestForgotPasswordSuccess.path)
  const resolver = yupResolver(PublicApi.Schemas.passwordReset)

  return (
    <>
      <PageMetaTags subtitle={t('forgotPassword.seo.subtitle')} description={t('forgotPassword.seo.description')} />
      <PageLayout>
        <H3 my={2}>{t('forgotPassword.title')}</H3>
        <PSmall mb={4}>{t('forgotPassword.description')}</PSmall>
        <ApiForm
          formName={'RequestPasswordResetForm'}
          onSubmit={onSubmit}
          apiOptions={{ onSuccess }}
          formOptions={{ resolver }}
        >
          {({ isLoading, isSuccess }) => <FormContent isProcessing={isLoading || isSuccess} />}
        </ApiForm>
      </PageLayout>
    </>
  )
}

export { ForgotPassword }
