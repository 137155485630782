import React from 'react'

import { getLocationService } from '@guiker/base-entity'

import { Button, Flex, H1, Link } from '../../../components'
import { useStaticAssetContext, useTranslation } from '../../../hooks'
import { Testimonial } from './Testimonial'

export const TestimonialSection: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('services')
  const numberOfEnabledCities = getLocationService()
    .getCities()
    .filter((c) => !c.city.disabled).length

  return (
    <Flex flexDirection='column' alignItems='center' my={10}>
      <Flex fullWidth mb={10} mx={2}>
        <H1>{t('services.testimonials.header', { cities: numberOfEnabledCities })}</H1>
      </Flex>
      <Testimonial
        content={t('services.testimonials.testimonial1.content')}
        author={t('services.testimonials.testimonial1.author')}
        cta={
          <Link isExternalLink to={assets.landlordTestimonial} target='_blank' underline={false} hoverUnderline={false}>
            <Button>{t('services.testimonials.cta')}</Button>
          </Link>
        }
      />
      <Testimonial
        content={t('services.testimonials.testimonial2.content')}
        author={t('services.testimonials.testimonial2.author')}
        cta={
          <Link isExternalLink to={assets.agentTestimonial} target='_blank' underline={false} hoverUnderline={false}>
            <Button>{t('services.testimonials.cta')}</Button>
          </Link>
        }
        direction='right'
      />
    </Flex>
  )
}
