import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'
import { address } from '../../schemas/address'

export const contactInformation = yup.object<Omit<Ontario.ContactInformation, 'completed'>>({
  address: address,
  isAgreedToReceivedNoticesViaEmail: yup.boolean().required(),
  noticesEmailAddress: yup.string().when('isAgreedToReceivedNoticesViaEmail', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
  isLandlordProvidingContactInfo: yup.boolean().required(),
  landlordContactInfo: yup.string().when('isLandlordProvidingContactInfo', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

export type ContactInformation = TypeOf<typeof contactInformation>
