import React from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { Booking, getApplicantFullName } from '@guiker/booking-shared'
import { Flex, FormSection2, FormSection3, P, ThreeColumnsGridLayout, useT } from '@guiker/react-framework'
import { PayerRentPaymentsPlanTable } from '@guiker/rent-payment-components'
import { RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'
import { getUserFullName } from '@guiker/shared-framework'

type BookingTenantProp = {
  booking: Booking
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  userId: string
}

export const BookingTenant: React.FC<BookingTenantProp> = ({ booking, userId, rentPaymentsPlan }) => {
  const { tComponents } = useT({ domain: 'booking', componentName: 'bookingTenant' })
  const applicant = booking?.applicants?.find((applicant) => applicant.userId === userId)
  const payer = rentPaymentsPlan?.payers?.find((payer) => payer.userId === userId)

  const tenantName = getApplicantFullName(applicant) || getUserFullName(applicant)

  if (!payer) return

  return (
    <FormSection2 hasDivider={false}>
      <ThreeColumnsGridLayout>
        <Flex flexDirection='column'>
          <P>{addressFormatter.printShortAddress(booking.listing.address)}</P>
        </Flex>
        <Flex flexDirection='column'>
          <P>{tenantName}</P>
        </Flex>
        <Flex flexDirection='column'></Flex>
      </ThreeColumnsGridLayout>
      <FormSection3 title={tComponents('rentalTable')}>
        <PayerRentPaymentsPlanTable rentPaymentsPlan={rentPaymentsPlan} payer={payer} />
      </FormSection3>
    </FormSection2>
  )
}
