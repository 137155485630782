import React from 'react'

import { useTranslation } from '@guiker/i18n'
import { FieldPath, PathValue, useFormContext, useFormInput } from '@guiker/react-hook-form'

import { MaskedTextField, MaskedTextFieldProps } from '../MaskedTextField'

export type TextFieldProps<T extends object = any, P extends FieldPath<T> = any> = MaskedTextFieldProps<T, P> & {
  formatting?: 'CA' | 'US'
}

const SsnTextField = <T extends object = any, P extends FieldPath<T> = any>({
  name,
  readOnly,
  formatting = 'US',
  ...textFieldProps
}: TextFieldProps<T, P>) => {
  const { t } = useTranslation(['common-backgroundCheck'])
  const { error, errorMessage } = useFormInput<T, P>({ name: name, t })
  const { setValue } = useFormContext()

  const parseSsn = (value: string): PathValue<T, P> => {
    value = value.replace(/\D/g, '')
    value =
      formatting === 'US' ? value.replace(/^(\d{3})(\d{1,2})/, '$1-$2') : value.replace(/^(\d{3})(\d{1,3})/, '$1-$2')
    value =
      formatting === 'US'
        ? value.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3')
        : value.replace(/^(\d{3})-(\d{3})(.+)/, '$1-$2-$3')

    return value as PathValue<T, P>
  }

  const parseSsnHidden = (value: string): PathValue<T, P> => {
    value = value.substring(0, 9)
    value = formatting === 'US' ? value.replace(/^(.{3})(.{1,2})/, '$1-$2') : value.replace(/^(.{3})(.{1,3})/, '$1-$2')
    value =
      formatting === 'US'
        ? value.replace(/^(.{3})-(.{2})(.+)/, '$1-$2-$3')
        : value.replace(/^(.{3})-(.{3})(.+)/, '$1-$2-$3')

    return value.replace(/[a-zA-Z0-9]/g, '*') as PathValue<T, P>
  }

  return (
    <MaskedTextField
      {...textFieldProps}
      readOnly={readOnly}
      name={name}
      label={t('ssn')}
      error={error}
      errorMessage={errorMessage}
      onChange={(text) => setValue(name, text)}
      formatVisibleText={parseSsn}
      formatHiddenText={parseSsnHidden}
      inputProps={{
        maxLength: 11,
      }}
      placeholder={formatting === 'US' ? '_ _ _-_ _-_ _ _ _' : '_ _ _-_ _ _-_ _ _'}
    />
  )
}

export { SsnTextField }
