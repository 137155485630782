import React from 'react'

import { AnyColor } from '@guiker/components-core'
import { Note } from '@guiker/components-library'

type Props = React.PropsWithChildren & {
  className?: string
  color: AnyColor
  text: string
  icon?: React.ReactNode
}

const ListingBanner: React.FC<Props> = ({ color, text, children, icon }) => {
  return <Note icon={icon ?? 'warning'} statusColor={color} title={text} actions={children} />
}

export { ListingBanner }
