import React from 'react'

import {
  AppBar as BaseAppBar,
  Features,
  Flex,
  Inline,
  isMobile,
  makeStyles,
  margin,
  padding,
  ThemeContextProvider,
  toPx,
  useFeatureFlag,
} from '@guiker/react-framework'

import { useAuthenticationContext } from '../../hooks'
import { canSpoof, useSpoofInContext } from '../SpoofInModal'
import { useNavigationItems } from './nav-items'

export type AppBarProps = {}

const useStyles = makeStyles((theme) => ({
  admin: {
    zIndex: theme.zIndexs.appBar,
    position: 'relative',
    margin: margin(0, 'auto'),
    maxWidth: theme.dimensions.pageLayout.maxWidth.desktop,
    '& > span': {
      position: 'absolute',
      top: theme.dimensions.appBar.height.desktop,
      padding: padding(0, 2),
      fontSize: theme.typography.variants.bodySmaller.fontSize,
      backgroundColor: theme.palette.background.primary,
      boxShadow: theme.shadows.medium,
      borderRadius: `0 0 ${toPx(20)} ${toPx(20)}`,
      cursor: 'pointer',
      [isMobile]: {
        top: theme.dimensions.appBar.height.mobile,
      },
    },
  },
}))

const AppBarContent: React.FC<AppBarProps> = () => {
  const { user } = useAuthenticationContext()
  const { navBarItems, topMenuItems, bottomMenuItems, taskMenu, notificationMenu } = useNavigationItems()

  const isNewMobileNavigationEnabled = useFeatureFlag().isEnabled(Features.DarkAppBar)

  const appBar = (
    <BaseAppBar
      taskMenu={taskMenu}
      notificationMenu={notificationMenu}
      bottomMenuItems={bottomMenuItems}
      hasBorder={true}
      navBarItems={navBarItems}
      topMenuItems={topMenuItems}
      user={user}
    />
  )

  if (isNewMobileNavigationEnabled) {
    return appBar
  }

  return <ThemeContextProvider isDarkMode={true}>{appBar}</ThemeContextProvider>
}

const AppBar: React.FC<AppBarProps> = (props) => {
  const classes = useStyles()
  const { user } = useAuthenticationContext()
  const { originalUser, spoof } = useSpoofInContext()

  return (
    <div>
      {canSpoof(originalUser || user) && (
        <Flex justifyContent='center' className={classes.admin}>
          <Inline onClick={() => spoof.setIsOpen(true)}>
            Logged in as: {user.firstName} / {user.emailAddress}
          </Inline>
        </Flex>
      )}
      <AppBarContent {...props} />
    </div>
  )
}

export { AppBar }
