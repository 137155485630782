import { HttpMethod } from '@guiker/http'

import { Ontario } from '../../../entity'
import { leaseIdNested } from '../../lease-id-nested'
import {
  GuarantorSignature,
  guarantorSignature,
  InvitedLessorSignature,
  invitedLessorSignature,
} from '../../schemas/ontario'
import { paths } from './paths'

export const ONTARIO_LEASE_INFORMATION_WITH_ID_PATH = `${paths.ontarioLease.one.withId(':leaseId')}/lease-information`

const responseValidator = (payload: unknown) => Promise.resolve(payload as Ontario.Lease)

export const routes = {
  updateOntarioLeaseGuarantorSignature: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-guarantor-signature`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<GuarantorSignature> => guarantorSignature.validate(payload),
  },
  updateOntarioLeaseInvitedLessorSignature: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/invited-lessor-signature`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<InvitedLessorSignature> => invitedLessorSignature.validate(payload),
  },
}
