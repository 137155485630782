import { TypeOf, yup } from '@guiker/yup-util'

export const sinBackgroundCheckPayloadValidator = yup.object({
  sin: yup
    .string()
    .required()
    .test('invalidSIN', 'must be 9 digits', (value: string) => {
      const parsed = value.split('-').join('')
      if (parsed.length < 9) return false
      return true
    }),
})

export type SinBackgroundCheckPayload = TypeOf<typeof sinBackgroundCheckPayloadValidator>
