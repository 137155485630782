export enum Status {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export type StatusHistory = {
  changedBy?: string //userId
  changedAt: string
  status: Status
}
