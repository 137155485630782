import { yup } from '@guiker/yup-util'

import { Coordinate } from '../../../entity'

export const coordinateSchema = yup
  .object<Coordinate>({
    lat: yup.number().required(),
    lng: yup.number().required(),
  })
  .required()
