import React, { createContext, useEffect, useState } from 'react'

import { LocalStorageKey, persistancy } from '@guiker/react-persistance'

import { PackageTier, PackageType } from '../components/PackageEntity'

type ContextType = {
  setPackageType: (type: PackageType) => void
  packageType: PackageType
  setPackageTier: (tier: PackageTier) => void
  packageTier: PackageTier
}

export const ServicesScreenContext = createContext<ContextType>(undefined)

export const ServicesScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { persistItem, getItem } = persistancy(LocalStorageKey.SERVICES_PACKAGE_TYPE)
  const [packageType, _setPackageType] = useState(getItem<PackageType>() || PackageType.LANDLORD)
  const [packageTier, setPackageTier] = useState<PackageTier>('basic')

  const setPackageType = (type: PackageType) => {
    _setPackageType(type)
    persistItem(type)
  }

  useEffect(() => {
    const savedPackageType = getItem<PackageType>()
    savedPackageType && setPackageType(savedPackageType)
  }, [])

  const value = {
    packageType,
    setPackageType,
    packageTier,
    setPackageTier,
  }

  return <ServicesScreenContext.Provider value={value}>{children}</ServicesScreenContext.Provider>
}
