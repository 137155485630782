import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

type Args = {
  defaults?: {
    listingId?: string
  }
}

export const buildListingIdQueryParams = ({ defaults: { listingId = null } = {} }: Args = {}) =>
  buildPaginationQueryParamSchema().shape({
    listingId: yup.string().default(listingId).nullable(),
  })
