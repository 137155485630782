import { Payment } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'

import { FundingAccountWithdrawal } from '../funding-account'
import { Invoice } from '../invoice'
import { PayoutProviderName } from '../payout-method/payout-provider'

export enum TransferScopeType {
  INVOICE = 'invoice',
  FUNDING_ACCOUNT_WITHDRAWAL = 'fundingAccountWithdrawal',
}

export enum TransferStatus {
  CREATED = 'CREATED',
  PAID = 'PAID',
  FAILED = 'FAILED',
  REVERSED = 'REVERSED',
}

export type Transfer = {
  id: string
  scope: {
    type: TransferScopeType
    id: string
  }
  provider: {
    name: PayoutProviderName
    externalId?: string
  }
  batchId?: string
  info: Payment.PaymentInfo
  meta: Record<string, unknown>
  status: TransferStatus
  amount: number
  currency: CurrencyISO
  payoutMethodId?: string
  createdAt?: string
}

type TransferScopeData<S extends TransferScopeType> = S extends TransferScopeType.INVOICE
  ? Invoice
  : S extends TransferScopeType.FUNDING_ACCOUNT_WITHDRAWAL
  ? FundingAccountWithdrawal
  : never
export type TransferProjection<S extends TransferScopeType = TransferScopeType> = Omit<Transfer, 'scope'> & {
  scope: {
    id: string
    type: S
    data?: TransferScopeData<S>
  }
}
