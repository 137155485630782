import { yup } from '@guiker/shared-framework'

export type CreatePayoutWithdrawalSchema = {
  userId: string
  note: string
  amount: number
}

export const createPayoutWithdrawalSchema = yup.object<CreatePayoutWithdrawalSchema>({
  userId: yup.string().required(),
  note: yup.string().required(),
  amount: yup.number().positive().required(),
})
