import { Payment, yup } from '@guiker/shared-framework'

export const paymentInfoSchema = yup
  .object<Payment.PaymentInfo>({
    label: yup.string().required(),
    description: yup.string().nullable().notRequired(),
    payerUserId: yup.string().nullable().notRequired(),
    payerEmailAddress: yup.string().nullable().notRequired(),
    payerName: yup.string().nullable().notRequired(),
  })
  .required()
