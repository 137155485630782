import { User } from '@guiker/authentication-shared'
import { BookingInfo, UnitManager } from '@guiker/booking-shared'
import { RentalListing } from '@guiker/rental-listing-shared'

export const ListingInquiryEventTypes = {
  CREATED: 'CREATED',
  COMPLETED: {
    type: 'COMPLETED',
    eventData: null as {
      listing: RentalListing
      conversationId: string
      user: User
      bookingApplicants: User[]
      bookingUnitManagers: UnitManager[]
      info: BookingInfo
      shouldAutoReply: boolean
    },
  },
} as const
