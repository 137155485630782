import React from 'react'

import { Roommate } from '@guiker/base-entity'
import { RoommateIntroTenant, RoommateIntroTenantDecision } from '@guiker/booking-shared'
import { Card, Chip, Divider, Flex, PBold, PSmall, useT } from '@guiker/react-framework'
import { formatDate, pascalCase } from '@guiker/shared-framework'

import { RoommateContent } from './RoommateContent'

type RoommateCardProps = {
  roommate?: Roommate
  tenant?: RoommateIntroTenant
  width?: number | string
}

const findColor = (decision: RoommateIntroTenantDecision) => {
  return decision === RoommateIntroTenantDecision.INITIAL
    ? 50
    : decision === RoommateIntroTenantDecision.ACCEPTED
    ? 'success'
    : 'alert'
}

export const RoommateCard: React.FC<RoommateCardProps> = ({ roommate, tenant, width = 350 }) => {
  const { tShared } = useT({ domain: 'baseListing' })

  if (!roommate) return <></>
  const contentKeys = ['age', 'gender', 'cleanliness', 'livingWithPets', 'interests', 'sleepSchedule']
  const hasContent = contentKeys.some((key) => !!roommate[key])

  return (
    <Card shadow='medium' p={4} width={width}>
      <Flex flexDirection='column' gap={2}>
        <Flex flexDirection='column' gap={1}>
          <PBold>{`${pascalCase(roommate.firstName)} - ${tShared('roommateCard.room')}: ${
            roommate.address.room
          }`}</PBold>
          {!!tenant && (
            <Chip size='smaller' color={findColor(tenant.decision)}>
              {tShared(`roommateCard.decision.${tenant.decision}`, {
                reason: tenant.reason ? tShared(`roommateCard.reason.${tenant.reason}`) : undefined,
              })}
            </Chip>
          )}
          <PSmall color={50}>{`${formatDate(roommate.moveInDate)} - ${formatDate(roommate.moveOutDate)}`}</PSmall>
        </Flex>
        {hasContent && <Divider />}
        <RoommateContent roommate={roommate} />
      </Flex>
    </Card>
  )
}
