import React from 'react'

import { FormSectionGroup } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { CurrentAddressContent } from './CurrentAddress'
import { OccupationContent } from './Occupation'

type CurrentAddressFormContentProps = {
  tenantApplication?: TenantApplication
  schema?: yup.ObjectSchema
  readOnly?: boolean
}

const CurrentAddressFormContent: React.FC<CurrentAddressFormContentProps> = ({
  tenantApplication,
  schema,
  readOnly,
}) => {
  return (
    <FormSectionGroup>
      <CurrentAddressContent defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
      <OccupationContent defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
    </FormSectionGroup>
  )
}

export { CurrentAddressFormContent }
