import { Modify } from '@guiker/ts-utils'

enum VerificationProvider {
  PLAID = 'PLAID',
}

type Verification = {
  provider: VerificationProvider
  token: string
  details: unknown
}

type PlaidVerification = Modify<
  Verification,
  {
    details: {
      itemId: string
      bankAccountId: string
    }
  }
>

export { VerificationProvider, Verification, PlaidVerification }
