import { HttpMethod, StatusCodes } from '@guiker/http'

import { TenantPreference } from '../../entity'
import { tenantPreferenceByUserId, UpsertTenantPreference, upsertTenantPreference } from '../schemas'
import { paths } from './paths'

export const TENANT_PREFERENCE_WITH_ID_PATH = `${paths.tenantPreference.one.withId(':tenantPreferenceId')}`

const responseValidator = (payload: unknown) => Promise.resolve(payload as TenantPreference)

export const routes = {
  readOneTenantPreference: {
    path: paths.tenantPreference.one.withScope('user', ':userId'),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => tenantPreferenceByUserId.validate(payload),
  },
  upsertTenantPreference: {
    path: paths.tenantPreference.one.withScope('user', ':userId'),
    method: HttpMethod.POST,
    authenticated: true,
    successStatusCode: StatusCodes.CREATED,
    responseValidator,
    pathParamsValidator: (payload: unknown) => tenantPreferenceByUserId.validate(payload),
    payloadValidator: (payload: unknown): Promise<UpsertTenantPreference> =>
      upsertTenantPreference.validate(payload, { stripUnknown: true }),
  },
}
