import { yup } from '@guiker/yup-util'

import { AnimalType, Pets } from '../../entity'

export const petsSchema = yup.object<Pets>({
  additionalInfo: yup
    .string()
    .notRequired()
    .transform((value) => (!value ? undefined : value)),
  isSupportServiceAnimal: yup.boolean().required().default(false),
  type: yup.mixed().oneOf(Object.values(AnimalType)).required(),
  other: yup.string().when('type', {
    is: AnimalType.OTHER,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
})
