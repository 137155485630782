import { OfflinePayInMethod, PayInMethodType, SupportedCountries } from '../../entity'

const payInMethodBuilder = ({ userId, region }: { userId: string; region: SupportedCountries }) => ({
  offline: (): OfflinePayInMethod => ({
    id: `offline_${userId}`,
    type: PayInMethodType.OFFLINE,
    legacyId: null,
    userId: userId,
    region,
    provider: {
      name: 'GUIKER',
      uid: `offline-method-${userId}`,
      token: `offline-method-${userId}`,
    },
    details: {},
  }),
})

export { payInMethodBuilder }
