import React, { useState } from 'react'

import { usePaymentApiClient } from '@guiker/payment-context'
import { Invoice, PayInMethod, TransactionStatus } from '@guiker/payment-shared'
import {
  ButtonWithLoader,
  Flex,
  SuccessDialog,
  useMutation,
  useNavigate,
  useTranslation,
} from '@guiker/react-framework'

type AuthButtonContainerProps = {
  invoice: Invoice
  payInMethod?: PayInMethod
}

export const AuthButtonContainer: React.FC<AuthButtonContainerProps> = ({ invoice, payInMethod }) => {
  const { t } = useTranslation(['common-payment'])
  const tPrefix = 'dialog'
  const apiClient = usePaymentApiClient()
  const navigate = useNavigate()
  const [status, setStatus] = useState<TransactionStatus>(null)

  const { mutate: payOneInvoice, isLoading } = useMutation(
    async () =>
      apiClient.payOneInvoice({
        pathParams: { invoiceId: invoice.id },
        payload: { payInMethod: { id: payInMethod.id, type: payInMethod.type } },
      }),
    {
      onSuccess: (invoice) => {
        const lastTransaction = invoice.transactions[invoice.transactions.length - 1]
        setStatus(lastTransaction.status)
      },
      onError: () => {
        setStatus('FAILED')
      },
    },
  )

  return (
    <Flex flexDirection='column' alignItems='flex-end'>
      <ButtonWithLoader disabled={!payInMethod} isLoading={isLoading} onClick={() => payOneInvoice()}>
        {t('actions.payNow')}
      </ButtonWithLoader>
      <SuccessDialog
        illustration={null}
        open={!!status}
        text={t(`${tPrefix}.${status?.toLowerCase()}`)}
        buttonProps={{
          /** @todo naviagte to should come from context */
          onClick: () => (status === 'FAILED' ? window.top.location.reload() : navigate('../../')),
          children: status === 'FAILED' ? t(`${tPrefix}.tryAgain`) : t(`${tPrefix}.ok`),
        }}
      />
    </Flex>
  )
}
