import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { computeInvestmentResult, InvestmentAssumptions } from '@guiker/base-listing-shared'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { DeepPartial } from '@guiker/react-framework'
import { throttle } from '@guiker/react-throttle-debounce'
import { Currency, isEqual, merge } from '@guiker/shared-framework'

type InvestmentAssumptionContextType = {
  investmentAssumptions: InvestmentAssumptions.Assumptions
  investmentResults: InvestmentAssumptions.Results
  currency: Currency
  setInvestmentAssumptions: (assumptions: DeepPartial<InvestmentAssumptions.Assumptions>) => void
}

type Props = React.PropsWithChildren & {
  assumptions: InvestmentAssumptions.Assumptions
  currency: Currency
}

type Assumptions = PropertySaleListing['assumptions']

export const InvestmentAssumptionContext = createContext<InvestmentAssumptionContextType>(null)

export const InvestmentAssumptionContextProvider: React.FC<Props> = ({
  children,
  currency,
  assumptions: propsAssumptions,
}) => {
  const [investmentAssumptions, _setInvestmentAssumptions] = useState<Assumptions>(propsAssumptions)

  const [investmentResults, setInvestmentResults] = useState<PropertySaleListing['results']>(
    useMemo(() => computeInvestmentResult(investmentAssumptions), []),
  )

  const setInvestmentAssumptions = (assumptions: InvestmentAssumptions.Assumptions) => {
    _setInvestmentAssumptions((curr) => {
      const updatedAssumptions = merge({}, curr, assumptions)
      if (isEqual(curr, updatedAssumptions)) return curr

      return updatedAssumptions
    })
  }

  useEffect(() => {
    setInvestmentAssumptions(propsAssumptions)
  }, [propsAssumptions])

  const debouncedComputation = useCallback(
    throttle(10, (investmentAssumptions) => setInvestmentResults(computeInvestmentResult(investmentAssumptions))),
    [],
  )

  useEffect(() => {
    debouncedComputation(investmentAssumptions)
  }, [investmentAssumptions])

  const value = {
    currency,
    investmentResults,
    investmentAssumptions,
    setInvestmentAssumptions,
  }

  return <InvestmentAssumptionContext.Provider value={value}>{children}</InvestmentAssumptionContext.Provider>
}

export const useInvestmentAssumptionContext = () => {
  const context = useContext(InvestmentAssumptionContext)

  if (context === undefined) {
    throw new Error('useInvestmentAssumptionContext can only be used inside InvestmentAssumptionContextProvider')
  }

  return context
}
