import { CurrencyISO } from '@guiker/money'
import { PayInMethod, PayInMethodType } from '@guiker/payment-shared'

import { InvestorProfile } from './investor-profile'
import { Stock } from './stock'
import { StockOrderShare } from './stock-order-share'

export const stockOrderTransactionFee: {
  [key in CurrencyISO]: number
} = {
  [CurrencyISO.CAD]: 499,
  [CurrencyISO.USD]: 0,
}

export const allowedPaymentTypes = [
  PayInMethodType.DIRECT_DEBIT_PAD,
  PayInMethodType.FUNDING_ACCOUNT,
  /** @todo add FundingAccount type */
]

export enum StockOrderStatus {
  CREATED = 'CREATED',
  // 'LISTED' is for secondary market
  LISTED = 'LISTED',
  PENDING_EXECUTION_COMPLETION = 'PENDING_EXECUTION_COMPLETION',
  PENDING_SHARE_ISSUANCE = 'PENDING_SHARE_ISSUANCE',
  FULFILLED = 'FULFILLED',
  CANCELLED = 'CANCELLED',
}

export enum StockOrderAction {
  BUY = 'buy',
  SELL = 'sell',
}

export enum StockOrderType {
  IPO = 'IPO',
  BUYOUT = 'BUYOUT',
  PROMOTION = 'PROMOTION',
}

export const ReservedStockOrderStatus = [
  StockOrderStatus.PENDING_SHARE_ISSUANCE,
  StockOrderStatus.PENDING_EXECUTION_COMPLETION,
] as const

export type RiskAcknowledgement = {
  id: string //from contract service
  fingerprint: string //md5
  consent: {
    ipAddress: string
    isConsentGiven: boolean
    date: string //ISO Date
  }
}

export type StockOrder = {
  id: string
  action: StockOrderAction
  stock: Stock
  seriesSlug?: string
  share: StockOrderShare
  status: StockOrderStatus
  investorProfile: InvestorProfile | null
  payInMethod?: PayInMethod
  riskAcknowledgement?: RiskAcknowledgement
  type: StockOrderType
}

export type StockOrderProjection = StockOrder

export type StockOrderStats = {
  key: StockOrderStatus.FULFILLED | StockOrderStatus.PENDING_SHARE_ISSUANCE | 'FUNDING_ACCOUNT_BALANCE'
  amount: number
}
