import { AuthApiClient, PublicApiClient } from '@guiker/propsharing-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export * from './use-api-client'

export const {
  useContext: usePublicPropSharingListingIdContext,
  NestedRouter: PublicPropSharingListingIdNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.PUBLIC,
  apiClientGen: PublicApiClient,
  cacheKey: ({ id }: { id: string }) => `readOneListingByUid-${id}`,
  fetcher: (apiClient, { id }: { id: string }) => apiClient.readOneListing({ pathParams: { id } }),
})

export const {
  useContext: usePublicPropSharingListingContext,
  Context: PublicPropSharingListingContext,
  ContextProvider: PublicPropSharingListingContextProvider,
  NestedRouter: PublicPropSharingListingRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.PUBLIC,
  apiClientGen: PublicApiClient,
})

export const {
  useContext: useAuthenticatedPropSharingStockOrderIdContext,
  NestedRouter: AuthenticatedPropSharingStockOrderIdNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthApiClient,
  cacheKey: ({ id }: { id: string }) => `readOneStockOrder-${id}`,
  fetcher: (apiClient, { id }: { id: string }) => apiClient.readOneStockOrder({ pathParams: { id } }),
})
