import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { getGraphColorKey, numberFormatter, useGetInputProps, useWatch } from '@guiker/react-framework'
import { Property } from '@guiker/real-estate-shared'
import { CurrencyISO, math, money } from '@guiker/shared-framework'
import { yup } from '@guiker/yup-util'

import { useT } from '../../../i18n'

const findMinAndMax = (value: number, amount: number) => {
  return {
    min: value - value * amount,
    max: value + value * amount,
  }
}

const findMinAndMaxWithRange = (value: number, amount: number, range: number) => {
  const min = value - value * amount
  const minRange = value - range < 0 ? 0 : value - range
  const max = value + value * amount
  const maxRange = value + range
  return {
    min: min < minRange ? math.decimal.round(min, 2) : math.decimal.round(minRange, 2),
    max: max > maxRange ? math.decimal.round(max, 2) : math.decimal.round(maxRange, 2),
  }
}

const useInvestmentInputWatch = (name: string, assumptions: InvestmentAssumptions.Assumptions) => {
  const rentalRevenue = useWatch({
    name: `${name}.revenue.rentalRevenue`,
    defaultValue: assumptions.revenue.rentalRevenue,
  })
  const rentalGrowthRate = useWatch({
    name: `${name}.revenue.rentalGrowthRate`,
    defaultValue: assumptions.revenue.rentalGrowthRate,
  })
  const holdingPeriod = useWatch({
    name: `${name}.base.holdingPeriod`,
    defaultValue: assumptions.base.holdingPeriod,
  })
  const propertyAppreciationRate = useWatch({
    name: `${name}.base.propertyAppreciationRate`,
    defaultValue: assumptions.base.propertyAppreciationRate,
  })

  return { rentalRevenue, rentalGrowthRate, holdingPeriod, propertyAppreciationRate }
}

const getInputProps = (
  defaultValue: PropertySaleListing,
  name: string,
  schema: yup.ObjectSchema,
  readOnly: boolean,
) => {
  return {
    getBaseInputProps: useGetInputProps({
      defaultValue,
      formPrefix: `${name}.base`,
      schema,
      tPrefix: 'common-investmentAssumption:base',
      readOnly,
    }),
    getRevenueInputProps: useGetInputProps({
      defaultValue,
      formPrefix: `${name}.revenue`,
      schema,
      tPrefix: 'common-investmentAssumption:revenue',
      readOnly,
    }),
  }
}

export const useRentalDetails = (args: {
  revenue: InvestmentAssumptions.RevenueAssumptions
  yearlyCashflows: InvestmentAssumptions.YearlyCashflow
  currency: CurrencyISO
  property: Property
}) => {
  const { tShared, t } = useT({ entity: 'rentalDetails' })
  const { currency, yearlyCashflows, revenue, property } = args
  const { occupancies, isEstimatedRevenue, rentalRevenue, rentalGrowthRate } = revenue

  const { vacancyAllowance, assetManagementFee } = yearlyCashflows
  const operatingExpenses = yearlyCashflows.operatingExpenses.filter((e) => e.computedValue !== 0)
  const expenseAmount = operatingExpenses.reduce((t, e) => t + e.computedValue, 0)
  const totalExpenses = money.fromAmount(expenseAmount - vacancyAllowance - assetManagementFee, currency)

  return {
    yearlyCashflows,
    currency,
    totalExpenses,
    kpiItems: [
      {
        label: tShared('kpi.rentalStatus'),
        description:
          occupancies.length === property.units.length && occupancies?.every((o) => o.isOccupied)
            ? tShared('kpi.fullyRented')
            : !isEstimatedRevenue
            ? tShared('kpi.partiallyRented')
            : tShared('kpi.vacant'),
      },
      {
        label: tShared('kpi.rentalRevenue'),
        description: money.fromAmount(rentalRevenue, currency).toString('onlySymbol', true),
        unit: isEstimatedRevenue ? t('common-investmentAssumption:revenue.occupancy.monthlyRent.estimated') : undefined,
      },
      {
        label: tShared('kpi.rentalGrowthRate'),
        description: `${numberFormatter.percentage(2).toDisplay(rentalGrowthRate)} %`,
      },
    ],
    financialItems: [
      {
        label: tShared('cashFlow.rentalRevenue'),
        value: yearlyCashflows.rentalIncome,
      },
      {
        label: tShared('cashFlow.operatingExpenses'),
        value: -totalExpenses.amount,
        collapsibleItems: [
          {
            label: tShared('cashFlow.vacancyAllowance'),
            value: -vacancyAllowance,
          },
          ...operatingExpenses.map((cost) => ({
            label: tShared(`cashFlow.${cost.type}`),
            value: cost.computedValue,
          })),
        ],
      },
      {
        label: tShared('cashFlow.netOperatingIncome'),
        value: yearlyCashflows.netOperatingIncome,
      },
      {
        label: tShared('cashFlow.debtService'),
        value: yearlyCashflows.totalDebtService,
        collapsibleItems: [
          {
            label: tShared('cashFlow.principalPayment'),
            value: yearlyCashflows.debtService.principalPaidSum,
          },
          {
            label: tShared('cashFlow.interestPayment'),
            value: yearlyCashflows.debtService.interestPaidSum,
          },
        ],
      },
      {
        label: tShared('cashFlow.assetManagementFee'),
        isPrimary: false,
        value: yearlyCashflows.assetManagementFee,
      },
    ],
    pieChartItems: [
      ...operatingExpenses.map((cost, index) => ({
        key: cost.type,
        label: tShared(`cashFlow.${cost.type}`),
        value: money.fromAmount(cost.computedValue, currency).monetized,
        color: getGraphColorKey(index),
      })),
    ],
  }
}

export { findMinAndMax, findMinAndMaxWithRange, useInvestmentInputWatch, getInputProps }
