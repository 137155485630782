import { Role, RoleNameRoleResolver } from '@guiker/permissions'

import { RentPaymentRoleNames } from './role-names'
import * as Roles from './roles'

export const RentPaymentRoleResolver: {
  byRoleNames: RoleNameRoleResolver<RentPaymentRoleNames>
} = {
  byRoleNames: ({ roleNames }): Role[] => {
    if (!roleNames || roleNames.length === 0) {
      return []
    }

    const roles: Role[] = roleNames.map((name) => {
      switch (name) {
        case RentPaymentRoleNames.PAYER:
          return Roles.PayerRole
        case RentPaymentRoleNames.UNIT_MANAGER:
          return Roles.UnitManagerRole
      }
    })

    return roles
  },
}
