import React from 'react'

import { KeyValuePair, PSmall, TwoColumnsGridLayout, useT } from '@guiker/react-framework'

type AccountInformationProps = {
  state: {
    name: string
    routingNumber: string
    accountNumber: string
    mask: string
    email: string
  }
}

export const AccountInformation: React.FC<AccountInformationProps> = ({ state }) => {
  const { tMain } = useT({ domain: 'payment', screenName: 'screens.mandateAgreement.accountDetails' })
  const [institution, transit] = state.routingNumber?.split('-') ?? []

  const accountInformationContent = [
    { label: tMain('date'), value: new Date(Date.now()).toLocaleString().split(',')[0] },
    { label: tMain('type'), value: tMain('business') },
    { label: tMain('accountHolder'), value: state.name },
    { label: tMain('email'), value: state.email },
    { label: tMain('institution'), value: institution },
    { label: tMain('transit'), value: transit },
    { label: tMain('accountNumber'), value: state.accountNumber },
  ]
  return (
    <TwoColumnsGridLayout>
      {accountInformationContent.map(({ label, value }) => (
        <KeyValuePair KeyComponent={PSmall} ValueComponent={PSmall} keyName={label} value={value} />
      ))}
    </TwoColumnsGridLayout>
  )
}
