import React from 'react'

import { FormSection, Link, useTranslation } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { PetContent } from './PetContent'
import { ProfileContent } from './ProfileContent'
import { VehicleContent } from './VehicleContent'

type MyProfileFormContentProps = {
  tenantApplication?: TenantApplication
  schema?: yup.ObjectSchema
  editRoute?: string
  readOnly?: boolean
}

const MyProfileFormContent: React.FC<MyProfileFormContentProps> = ({
  tenantApplication,
  schema,
  editRoute,
  readOnly,
}) => {
  const { t } = useTranslation('main-tenantApplication')

  return (
    <FormSection
      title={t('screens.steps.profile.title')}
      subtitle={t('screens.steps.profile.description')}
      topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
    >
      <ProfileContent defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
      <PetContent defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
      <VehicleContent defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
    </FormSection>
  )
}

export { MyProfileFormContent }
