import { CurrencyISO } from '@guiker/shared-framework'
import { yup } from '@guiker/yup-util'

import { RepresentativeRole, Stock, StockStatus } from '../../../entity'

export const representativeSchema = yup.object({
  id: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  emailAddress: yup.string().required(),
  roles: yup
    .array()
    .of<RepresentativeRole>(yup.mixed<RepresentativeRole>().oneOf(Object.values(RepresentativeRole)).required())
    .required(),
})

export const stockSchema = yup.object<Omit<Stock, 'id'>>({
  issuer: yup
    .object({
      name: yup.string().required(),
    })
    .required(),
  currency: yup.mixed<CurrencyISO>().oneOf(Object.values(CurrencyISO)).default(CurrencyISO.CAD),
  status: yup.string().oneOf(Object.values(StockStatus)).default(StockStatus.LISTED),
  minPerTransaction: yup.number().min(1).default(1),
  maxPerAccount: yup.number().min(0).required(),
  label: yup.string().required(),
  tickerSymbol: yup.string().required(),
  representatives: yup.array().of(representativeSchema).nullable(),
})
