import React, { createContext, PropsWithChildren } from 'react'

import { Stock } from '@guiker/propsharing-shared'
import { generateUseContext } from '@guiker/react-framework'

type StockContextProviderProps = PropsWithChildren & {
  data: Stock
}

type StockContext = {
  data: Stock
}

const StockContext = createContext<StockContext>(null)

export const StockContextProvider: React.FC<StockContextProviderProps> = ({ children, data }) => {
  return <StockContext.Provider value={{ data }}>{children}</StockContext.Provider>
}

export const useStock = generateUseContext(StockContext)
