import { ChannelStatus, ChannelType, Notification } from '@guiker/notification-shared'
import { UseMutateFunction, useNavigate, useT } from '@guiker/react-framework'
import { ValueOf } from '@guiker/shared-framework'

import { useMetadataParser } from './metadata-parser'

export type NotificationItem = {
  adornment?: React.ReactNode
  primaryText: string
  secondaryText?: string
  date: string
  status?: 'READ' | 'VIEWED' | 'CREATED'
  onClick?: () => void
}

export const parsedNotifications = (
  notifications: Notification[],
  markAsRead: UseMutateFunction<
    Notification,
    unknown,
    {
      id: string
      url: string
      isExternalLink?: boolean
    },
    unknown
  >,
): NotificationItem[] => {
  const { parse: parseMetadata } = useMetadataParser()
  const navigate = useNavigate()
  const { tMain } = useT({ domain: 'notification', screenName: 'notifications' })

  return notifications?.length
    ? notifications?.map((notification) => {
        const { primaryTextParams, secondaryTextParams, url, isExternalLink } = parseMetadata(notification)
        const status = notification.channels.find((c) => c.type === ChannelType.IN_APP)?.status as ValueOf<
          ChannelStatus['IN_APP']
        >

        return {
          onClick: () => {
            if (status !== 'READ') {
              markAsRead({ id: notification.id, url })
            } else {
              navigate(url, { isExternalLink })
            }
          },
          date: notification.createdAt,
          status,
          primaryText: tMain(
            `${notification.type.context}.${notification.type.entity}.${notification.type.type}.primaryText`,
            primaryTextParams,
          ),
          secondaryText: tMain(
            `${notification.type.context}.${notification.type.entity}.${notification.type.type}.secondaryText`,
            { ...secondaryTextParams, nullIfEmpty: true },
          ),
        }
      })
    : [
        {
          status: 'READ',
          primaryText: tMain('emptyState.primaryText'),
          secondaryText: tMain('emptyState.secondaryText'),
          date: null,
        },
      ]
}
