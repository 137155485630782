export const buildUrl = ({ baseUrl, path }: { baseUrl: string; path: string }): string => {
  let result = ''

  if (baseUrl[baseUrl.length - 1] === '/') {
    result += baseUrl.slice(0, baseUrl.length - 1)
  } else {
    result += baseUrl
  }

  if (path[0] === '/') {
    result += path
  } else {
    result += `/${path}`
  }

  return result
}
