import { TypeOf, yup } from '@guiker/yup-util'

export const contractPropSharingBuyStockOrderGeneratePayloadSchema = yup.object({
  investorProfile: yup.object(),
  stockOrder: yup.object(),
})

export type ContractPropSharingBuyStockOrderGeneratePayloadSchema = TypeOf<
  typeof contractPropSharingBuyStockOrderGeneratePayloadSchema
>
