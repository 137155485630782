import { TypeOf, yup } from '@guiker/yup-util'

import { Quebec } from '../../../entity'

const lessorLesseeTypes = yup
  .mixed<Quebec.LessorLesseeTypes | null>()
  .oneOf([...Object.values(Quebec.LessorLesseeTypes), null])
  .nullable()

const nullableString = yup.string().nullable()

export const servicesAndConditions = yup.object<Omit<Quebec.ServicesAndConditions, 'completed'>>({
  coOwnerShipImmovableDate: nullableString,
  byLawsImmovableDate: nullableString,
  workAndRepairs: yup.object({
    repairBeforeDwellingDelivered: nullableString,
    repairDuringLease: nullableString,
  }),
  janitorialServices: yup.object({
    specification: nullableString,
    janitor: yup.object({
      name: nullableString,
      phoneNumber: nullableString,
      emailAddress: nullableString,
      otherPhoneNumber: nullableString,
    }),
  }),
  conditions: yup.object({
    accessToLand: yup.object({
      hasAccess: yup.boolean().required(),
      description: yup.string().when('hasAccess', {
        is: true,
        then: yup.string().required(),
        otherwise: yup
          .string()
          .nullable()
          .transform(() => null),
      }),
    }),
    rightToKeepAnimal: yup.object({
      hasRight: yup.boolean().required(),
      description: yup.string().when('hasRight', {
        is: true,
        then: yup.string().required(),
        otherwise: yup
          .string()
          .nullable()
          .transform(() => null),
      }),
    }),
  }),
  servicesTaxesAndConsumptionCosts: yup.object({
    heating: lessorLesseeTypes,
    heatingType: yup.mixed<Quebec.HeatingTypes | null>().when('heating', {
      is: null,
      then: yup
        .mixed()
        .nullable()
        .transform(() => null),
      otherwise: yup.mixed<Quebec.HeatingTypes | null>().oneOf(Object.values(Quebec.HeatingTypes)),
    }),
    gas: lessorLesseeTypes,
    electricity: lessorLesseeTypes,
    waterHeater: lessorLesseeTypes,
    hotWater: lessorLesseeTypes,
    waterConsumption: lessorLesseeTypes,
    snowRemoval: yup
      .object({
        parkingArea: lessorLesseeTypes,
        balcony: lessorLesseeTypes,
        entrance: lessorLesseeTypes,
        stairs: lessorLesseeTypes,
      })
      .required(),
  }),
  otherServicesRestrictionCondition: nullableString,
})

export type ServicesAndConditions = TypeOf<typeof servicesAndConditions>
