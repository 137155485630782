import { yup } from '@guiker/yup-util'

import { RoommateIntroTenantDecision, RoommateIntroTenantDecisionReason } from '../../../entity'

export const updateRoommateIntroTenantDecisionPayloadSchema = yup.object({
  tenantDecision: yup
    .string()
    .oneOf<RoommateIntroTenantDecision>(Object.values(RoommateIntroTenantDecision))
    .required(),
  reason: yup.string().when('tenantDecision', {
    is: RoommateIntroTenantDecision.REJECTED,
    then: yup.string().oneOf(Object.values(RoommateIntroTenantDecisionReason)).required(),
    otherwise: yup.string().nullable(),
  }),
  otherReason: yup.string().when('reason', {
    is: RoommateIntroTenantDecisionReason.OTHER,
    then: yup.string().max(280).required(),
    otherwise: yup.string().nullable(),
  }),
})

export type UpdateRoommateIntroTenantDecisionPayloadSchema = yup.InferType<
  typeof updateRoommateIntroTenantDecisionPayloadSchema
>
