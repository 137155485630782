import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'
import { address } from '../../schemas/address'

export const rentalUnit = yup.object<Omit<Ontario.RentalUnit, 'completed'>>({
  address: address,
  numberOfParkingSpaceAndDescription: yup.string().required(),
  isInCondominium: yup.boolean().required(),
})

export type RentalUnit = TypeOf<typeof rentalUnit>
