import { CurrencyISO } from '@guiker/money'

import { BaseExpense } from './expense'
import { Canada, UnitedStates } from './jurisdiction'

export type Costs = {
  [key in string]: BaseExpense
}

export const OperationConfiguration = {
  'toronto-on': Canada.Ontario.Toronto.operation,
  'ottawa-on': Canada.Ontario.Ottawa.operation,
  'gatineau-qc': Canada.Quebec.Gatineau.operation,
  'montreal-qc': Canada.Quebec.Montreal.operation,
  'quebec-city-qc': Canada.Quebec.QuebecCity.operation,
  'chicago-il': UnitedStates.Illinois.Chicago.operation,
  [CurrencyISO.CAD]: Canada.Base.operation,
  [CurrencyISO.USD]: UnitedStates.Base.operation,
} as const
