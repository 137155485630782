import { routesBuilder } from '@guiker/shared-framework'

import { Portfolio } from '../../entity'
import { paths } from './paths'

const { buildGet } = routesBuilder<Portfolio>()({
  basePath: paths.portfolio.one.base(),
})

export const routes = {
  readOnePortfolio: buildGet(),
}
