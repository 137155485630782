import React from 'react'

import { H1 } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'

import { ErrorPage } from '../ErrorPage/ErrorPage'
import { I18nProvider } from '../I18nProvider'

const PageNotFound: React.FC = () => {
  return (
    <I18nProvider>
      <BasePageNotFound />
    </I18nProvider>
  )
}

const BasePageNotFound: React.FC = () => {
  const { t } = useTranslation('pageNotFound')

  return (
    <ErrorPage
      illustration={<H1 textAlign='center'>404 - Page Not Found</H1>}
      primaryText={t('primaryText')}
      secondaryText={t('secondaryText')}
    />
  )
}

export { PageNotFound }
