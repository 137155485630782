import { yup } from '@guiker/shared-framework'

import { InvoiceCancelReason } from '../../../../entity'

export const cancelOneInvoicePayloadSchema = yup
  .object({
    reason: yup.string().oneOf(Object.values(InvoiceCancelReason)).required(),
  })
  .required()

export type CancelOneInvoicePayloadSchema = yup.InferType<typeof cancelOneInvoicePayloadSchema>
