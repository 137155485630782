import React from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Collapse,
  Flex,
  isAtMostTablette,
  makeStyles,
  P,
  PBig,
} from '@guiker/react-framework'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderColor: theme.palette.grey[30],
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: 1,
      padding: theme.spacing(3),
      [isAtMostTablette]: {
        padding: theme.spacing(2),
      },
    },
    index: {
      marginRight: theme.spacing(2),
    },
    collapsable: {
      marginTop: theme.spacing(3),
      [isAtMostTablette]: {
        marginTop: theme.spacing(2),
      },
    },
    icon: {
      marginTop: 4,
    },
  }),
  { name: 'InvestorHowItWorksCard' },
)

type InvestorHowItWorksCardProps = {
  isOpen: boolean
  i18nKey: string
  index: number
  onClick: () => unknown
}

const InvestorHowItWorksCard: React.FC<InvestorHowItWorksCardProps> = ({ i18nKey, isOpen, index, onClick }) => {
  const classes = useStyles()
  const { t } = useTranslation(['webapp', 'common'])

  return (
    <div className={classes.root}>
      <Flex justifyContent='space-between' alignItems='flex-start' mb={0} gap={1}>
        <PBig>
          <span className={classes.index}>0{index + 1}</span>
          {t(`webapp:investors.howItWorks.${i18nKey}.title`)}
        </PBig>
        <span className={classes.icon}>
          {isOpen ? (
            <ChevronUpIcon color='textPrimary' onClick={onClick} />
          ) : (
            <ChevronDownIcon color='textPrimary' onClick={onClick} />
          )}
        </span>
      </Flex>
      <Collapse in={isOpen}>
        <P className={classes.collapsable} color={40}>
          {t(`webapp:investors.howItWorks.${i18nKey}.content`)}
        </P>
      </Collapse>
    </div>
  )
}

export { InvestorHowItWorksCard }
