import React from 'react'

import { PropertySaleListing } from '@guiker/property-sale-shared'
import { BuildingTypes } from '@guiker/real-estate-shared'

import { MixedUnitConfig } from './mixedUnitConfig'
import { MultiUnitConfig } from './multiUnitConfig'
import { SingleUnitConfig } from './singleUnitConfig'

type UnitConfigProps = {
  property: PropertySaleListing['property']
}

export const UnitConfig: React.FC<UnitConfigProps> = ({ property }) => {
  if (!property || !property.building || !property.units) return <></>
  return (
    <>
      {property.building.type !== BuildingTypes.COMMERCIAL && property.units.length === 1 ? (
        <SingleUnitConfig unit={property.units[0]} buildingType={property.building.type} />
      ) : property.building.type === BuildingTypes.MIXED && property.units.length >= 1 ? (
        <MixedUnitConfig units={property.units} buildingType={property.building.type} />
      ) : (
        <MultiUnitConfig units={property.units} buildingType={property.building?.type} />
      )}
    </>
  )
}
