import { Address } from './address'

export enum RepresentativeType {
  OWNER = 'OWNER',
  DIRECTOR = 'DIRECTOR',
  EXECUTIVE = 'EXECUTIVE',
  OTHER = 'OTHER',
}

export type Business = {
  name: string
  legalName: string
  type: 'SOLEPROP' | 'PARTNERSHP' | 'CORP'
  phoneNumber: string
  yearlyVolumeRange: 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH'
  taxNumber: string
  address: Address
  representativeTitle: string
  representativeType: RepresentativeType
}
