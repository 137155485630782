import { PolyUtil } from 'node-geometry-library'

type Coordinate = {
  longitude: number
  latitude: number
}

const convertToPath = (coordinate: Coordinate) => ({
  lat: coordinate.latitude,
  lng: coordinate.longitude,
})

export const Geometry = {
  polygon: {
    containsLocation: (point: Coordinate, coverage: Coordinate[]) => {
      return PolyUtil.containsLocation(
        convertToPath(point),
        coverage.map((c) => convertToPath(c)),
      )
    },
  },
}
