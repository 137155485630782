import { TypeOf, yup } from '@guiker/yup-util'

import { ServiceEnquiry, ServiceEnquiryData } from '../../../entity'

export const serviceEnquirySchema = yup.object<Omit<ServiceEnquiry, 'enquiryType'> & { recaptchaToken: string }>({
  emailAddress: yup.string().email().required(),
  recaptchaToken: yup.string().required(),
  data: yup.object<ServiceEnquiryData>({
    type: yup.string().required(),
    package: yup.string().required(),
    name: yup.string().required(),
    phone: yup.string().required(),
    unitsManaged: yup.string().required(),
    propertyInformation: yup.string().nullable(),
    linkToProperty: yup.string().nullable(),
    comments: yup.string().nullable(),
  }),
})

export type ServiceEnquirySchema = TypeOf<typeof serviceEnquirySchema>
