import { AuthLevel, generateApiPath } from '@guiker/api-definition'

const paths = {
  listingInquiry: generateApiPath({
    prefix: AuthLevel.AUTH,
    name: 'listing-inquiry',
    plural: 'listing-inquiries',
  }),
}

export { paths }
