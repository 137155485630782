import React from 'react'

import { Booking } from '@guiker/booking-shared'
import { Flex } from '@guiker/react-framework'

import { BookingPeriod } from '../BookingPeriod'
import { BookingRent } from '../BookingRent'

type RentProps = {
  booking: Pick<Booking, 'info'>
}

export const BookingInfo: React.FC<RentProps> = ({ booking }) => {
  return (
    <Flex flexDirection='column' gap={1}>
      <BookingRent booking={booking} />

      <BookingPeriod booking={booking} />
    </Flex>
  )
}
