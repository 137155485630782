import React, { useEffect, useState } from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  ApiForm,
  ApiFormAction,
  ColumnGridItem,
  ConfirmDialog,
  ConfirmDialogProps,
  Dropdown,
  FeedbackComponent,
  Flex,
  FormSection,
  RadioGroup,
  SuccessDialog,
  TextField,
  TwoColumnsGridLayout,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import { RecaptchaProvider, useRecaptcha } from '@guiker/react-recaptcha'
import { RecaptchaAction } from '@guiker/recaptcha-action'
import { PublicApi } from '@guiker/user-enquiry-shared'

import { useGetInputProps, useUserEnquiryApiClient } from '../../../hooks'
import { useServicesScreenContext } from '../context'
import { packageTiers, PackageType } from './PackageEntity'

const tPrefix = 'webapp:services'

const FormContent: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const { t } = useTranslation(['common', 'webapp'])
  const apiClient = useUserEnquiryApiClient()
  const { packageType: defaultPackageType, packageTier } = useServicesScreenContext()

  const [apiErrorMsg, setApiErrorMsg] = useState(null)
  const [packageTierOptions, setPackageTierOptions] = useState([])

  const packageType = useWatch({ name: 'data.type', defaultValue: defaultPackageType })
  const { executeRecaptcha } = useRecaptcha()

  const sendServiceEnquiry = async (payload: PublicApi.Schema.ServiceEnquirySchema) => {
    const recaptchaToken = await executeRecaptcha(RecaptchaAction.emailServiceQuestion)

    return apiClient.createServiceEnquiry({
      payload: { ...payload, recaptchaToken },
    })
  }

  const packageTypeOptions = Object.values(PackageType).map((type) => ({
    value: type,
    label: t(`${tPrefix}.packages.${type}.name`),
  }))

  const multilineProps = { multiline: true, rows: 3, rowsMax: 10 }

  const inputProps = useGetInputProps({
    formPrefix: '',
    schema: PublicApi.Schema.serviceEnquirySchema,
    namespaces: 'webapp',
    tPrefix: `${tPrefix}.form.label`,
  })

  useEffect(() => {
    setPackageTierOptions(
      packageTiers.map((tier) => ({
        value: tier,
        label: t(`${tPrefix}.packages.${packageType}.${tier}.name`),
      })),
    )
  }, [packageType])

  return (
    <FormSection>
      <FormSection hasDivider>
        <TwoColumnsGridLayout>
          <RadioGroup
            {...inputProps('data.type', { i18nKey: 'type' })}
            direction='row'
            options={packageTypeOptions}
            defaultValue={packageType}
          />
          <ColumnGridItem md={2} xs={1}>
            <Dropdown
              {...inputProps('data.package', { i18nKey: 'package' })}
              defaultValue={packageTier}
              options={packageTierOptions}
            />
          </ColumnGridItem>
        </TwoColumnsGridLayout>
        <TwoColumnsGridLayout>
          <TextField {...inputProps('data.name', { i18nKey: 'name' })} />
          <TextField {...inputProps('emailAddress')} />
          <TextField {...inputProps('data.phone', { i18nKey: 'phone' })} />
          <Dropdown
            {...inputProps('data.unitsManaged', { i18nKey: 'unitsManaged' })}
            defaultValue={'1-4'}
            options={['1-4', '5-9', '10+'].map((value) => ({ value, label: value }))}
          />
          <ColumnGridItem md={2} xs={1}>
            <TextField
              {...inputProps('data.propertyInformation', { i18nKey: 'propertyInformation' })}
              {...multilineProps}
            />
          </ColumnGridItem>
          <ColumnGridItem md={2} xs={1}>
            <TextField {...inputProps('data.linkToProperty', { i18nKey: 'linkToProperty' })} />
          </ColumnGridItem>
          <ColumnGridItem md={2} xs={1}>
            <TextField {...inputProps('data.comments', { i18nKey: 'comments' })} {...multilineProps} />
          </ColumnGridItem>
        </TwoColumnsGridLayout>
      </FormSection>
      <Flex justifyContent='flex-end'>
        <ApiFormAction
          onClick={sendServiceEnquiry}
          options={{
            onSuccess,
            onError: () => setApiErrorMsg(t('webapp:services.recaptchaFailed')),
          }}
        >
          {t('common:actions.send')}
        </ApiFormAction>
      </Flex>
      {apiErrorMsg && (
        <FeedbackComponent toasterMessage={apiErrorMsg} onToasterClose={() => setApiErrorMsg(undefined)} />
      )}
    </FormSection>
  )
}

const ServiceEnquiryModal: React.FC<ConfirmDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation(['common', 'webapp'])
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const resolver = yupResolver((PublicApi.Schema.serviceEnquirySchema as any).pick(['emailAddress', 'data']))

  const handleOnClose = () => {
    onClose && onClose()
    setOpenSuccessDialog(!openSuccessDialog)
  }

  return (
    <RecaptchaProvider>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        maxWidth={900}
        title={t(`${tPrefix}.form.title`)}
        subtitle={t(`${tPrefix}.form.description`)}
      >
        <ApiForm formName='OurServiceEnquiryForm' formOptions={{ resolver }}>
          <FormContent onSuccess={() => setOpenSuccessDialog(true)} />
        </ApiForm>
      </ConfirmDialog>
      <SuccessDialog
        open={openSuccessDialog}
        onClose={handleOnClose}
        text={t(`${tPrefix}.form.successModal.title`)}
        description={t(`${tPrefix}.form.successModal.description`)}
        buttonProps={{
          children: t('common:actions.close'),
          onClick: handleOnClose,
        }}
      />
    </RecaptchaProvider>
  )
}

export { ServiceEnquiryModal }
