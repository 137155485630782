export enum EventTypes {
  DWELLING_UPDATED = 'DWELLING_UPDATED',
  GUARANTOR_UPDATED = 'GUARANTOR_UPDATED',
  LEASE_RESTRICTIONS_AND_MODIFICATIONS_UPDATED = 'LEASE_RESTRICTIONS_AND_MODIFICATIONS_UPDATED',
  LEASE_TERM_UPDATED = 'LEASE_TERM_UPDATED',
  LESSEE_UPDATED = 'LESSEE_UPDATED',
  NEW_LESSEE_NOTICE_UPDATED = 'NEW_LESSEE_NOTICE_UPDATED',
  PARTICIPANT_UPDATED = 'PARTICIPANT_UPDATED',
  RENT_UPDATED = 'RENT_UPDATED',
  SERVICES_AND_CONDITIONS_UPDATED = 'SERVICES_AND_CONDITIONS_UPDATED',
  SIGNATURES_AND_GUARANTORS_UPDATED = 'SIGNATURES_AND_GUARANTORS_UPDATED',
  SIGNATURES_AND_LIABILITY_UPDATED = 'SIGNATURES_AND_LIABILITY_UPDATED',
}
