import { Payment } from '@guiker/base-entity'

import { PayoutMethodType } from '../entity'

const regionPayoutMethodMapper = (region: Payment.SupportedCountries[]): PayoutMethodType[] => {
  const types = []
  if (region.includes(Payment.SupportedCountries.Canada)) types.push(PayoutMethodType.CANADIAN_BANK_ACCOUNT)
  if (region.includes(Payment.SupportedCountries.UnitedStates)) types.push(PayoutMethodType.US_BANK_ACCOUNT)
  return types
}

export { regionPayoutMethodMapper }
