import { EntityDocument, ValueOf } from '@guiker/shared-framework'

export type FinancialStatementEntry = {
  label: string
  amount: number
}

export type BalanceSheet = {
  assets: FinancialStatementEntry[]
  liabilities: FinancialStatementEntry[]
  totalAssetValue: number
  totalLiabilityValues: number
  netAssetValue: number
}

export type ShareholderEquityStatement = {
  totalUnitCount: number
  netAssetValuePerUnit: number
}

export const FrequencyType = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  INITIAL: 'INITIAL',
  CLOSING: 'CLOSING',
  SERIES_OPENING: 'SERIES_OPENING',
  SERIES_CLOSING: 'SERIES_CLOSING',
} as const

export type FrequencyType = ValueOf<typeof FrequencyType>

export type FinancialStatements = EntityDocument & {
  date: string
  frequencyType: FrequencyType
  stockId: string
  seriesSlug?: string
  balanceSheet: BalanceSheet
  shareholderEquityStatement: ShareholderEquityStatement
}

export type ShareholderEquityStatementDocument = ShareholderEquityStatement & {}
export type BalanceSheetDocument = Omit<BalanceSheet, 'totalAssetValue' | 'totalLiabilityValues' | 'netAssetValue'>
export type FinancialStatementsDocument = Omit<FinancialStatements, 'balanceSheet' | 'shareholderEquityStatement'> & {
  balanceSheet: BalanceSheetDocument
  shareholderEquityStatement: ShareholderEquityStatementDocument
}
