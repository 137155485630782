// This should be deprecated gradually as we migrate angular to react
export type MyListingRoute = 'listing' | 'rentals' | 'operators'

export const legacyRoutes = {
  Admin: '/admin/features',
  ListingSearch: '/listings',
  MyAccount: '/myaccount/account/profile',
  MyHome: '/myaccount/home',
  MyListings: '/myaccount/listings/manage',
  MyListingEdit: (listingId: number | string, route: MyListingRoute) => `/myaccount/listings/${listingId}/${route}`,
  MyPaymentHistory: '/myaccount/account/transaction-history',
  PaymentMethods: '/myaccount/account/payment-methods',
  PrivacyPolicy: '/privacy/general',
  RentersTermsAndConditions: '/privacy/renters',
  Security: '/myaccount/account/security',
  LandlordsTermsAndConditions: '/privacy/landlords',
  Wishlist: '/myaccount/wishlist',
}
