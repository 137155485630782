import React from 'react'

import { Trans } from '@guiker/i18n'
import { Display2, Flex, Inline, makeStyles, margin } from '@guiker/react-framework'

const useStyles = makeStyles(
  (theme) => ({
    headline: {
      margin: margin(16, 0),
      maxWidth: 1280,
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
    whiteHeadline: {
      color: theme.palette.text.primary.main,
    },
  }),
  { name: 'InvestorHeadline' },
)

const InvestorHeadline: React.FC = () => {
  const classes = useStyles()

  return (
    <Flex justifyContent='center'>
      <Display2 color={30} className={classes.headline}>
        <Trans i18nKey={'webapp:investors.headline'}>
          <Inline color='textPrimary'></Inline>
        </Trans>
      </Display2>
    </Flex>
  )
}

export { InvestorHeadline }
