import React, { useCallback, useState } from 'react'

import { BaseListing } from '@guiker/base-listing-shared'
import { CloseIcon, Flex, Height, makeStyles, SecondaryButton } from '@guiker/react-framework'
import { GoogleMap, Markers, OVERLAY_MOUSE_TARGET, OverlayViewF } from '@guiker/react-google-maps'

import { useMapLayoutContext } from '../MapLayout/MapLayoutContext'

const useStyles = makeStyles((theme) => ({
  overlayWidth: {
    maxWidth: 280,
    width: 280,
  },
  bottomContainer: {
    position: 'absolute',
    zIndex: 200,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bottom: {
    width: '100%',
  },
  closeButton: {
    padding: 4,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    minWidth: 0,
  },
}))

type SearchScreenMapProps<T extends BaseListing> = {
  height?: Height
  listings: T[]
  Component: React.FC<{ listing: T; className?: string }>
  cardStyle?: 'bottom' | 'on-marker'
}

const MapCard = <T extends BaseListing>(props: {
  Component: React.FC<{ listing: T; className?: string }>
  className: string
  cardModal: T
  closeCardModal: () => void
}) => {
  const { Component, cardModal, closeCardModal } = props
  const classes = useStyles()

  return (
    <>
      <Component listing={cardModal} className={classes.overlayWidth} />
      <SecondaryButton
        size='small'
        startIcon={<CloseIcon size='smallest' />}
        onClick={closeCardModal}
        className={classes.closeButton}
      />
    </>
  )
}

export const SearchScreenMap = <T extends BaseListing>({
  listings,
  height = '100%',
  Component,
  cardStyle = 'on-marker',
}: SearchScreenMapProps<T>) => {
  const classes = useStyles()
  const [cardModal, setCardModal] = useState<T>()
  const { mapCenter, setMapInfo, zoom } = useMapLayoutContext()

  const openCardModal = useCallback((listing: T) => {
    return () => {
      setMapInfo({ center: listing.property.building.geocoordinates })
      setCardModal(listing)
    }
  }, [])

  const closeCardModal = useCallback(() => setCardModal(undefined), [])

  const getPixelPositionOffset = useCallback((width: number, height: number) => {
    return { x: -(width / 2), y: 0 - height - 32 }
  }, [])

  const cardModalProps = { Component, cardModal, closeCardModal }

  return (
    <GoogleMap
      center={mapCenter}
      zoom={zoom}
      height={height}
      width='100%'
      onCenterChanged={({ center, bounds }) => setMapInfo({ center, bounds })}
    >
      {listings?.length &&
        listings.map((listing) => {
          return (
            <Markers.Guiker
              position={listing.property.building.geocoordinates}
              key={listing.id}
              onClick={openCardModal(listing)}
            />
          )
        })}
      {cardStyle === 'on-marker' && cardModal && (
        <OverlayViewF
          position={cardModal.property.building.geocoordinates}
          mapPaneName={OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <Flex flexDirection='column' alignItems='center'>
            <MapCard {...cardModalProps} className={classes.overlayWidth} />
            <svg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 0H0L8 8L16 0Z' fill='white' />
            </svg>
          </Flex>
        </OverlayViewF>
      )}
      {cardStyle === 'bottom' && cardModal && (
        <Flex className={classes.bottomContainer}>
          <MapCard {...cardModalProps} className={classes.bottom} />
        </Flex>
      )}
    </GoogleMap>
  )
}
