import React, { createContext, useEffect, useRef, useState } from 'react'

import { TimerCount } from '@guiker/booking-shared'
import { generateUseContext, useIsMountedRef, useTimeInterval } from '@guiker/react-framework'
import { parseDateTime } from '@guiker/shared-framework'

type TimerContextProviderProps = React.PropsWithChildren & {
  timer: TimerCount
}

type TimerContext = {
  timerState: CountdownState
}

interface CountdownState {
  hours: number
  minutes: number
  seconds: number
  expiresAt: number
  startsAt: number
  status: 'expired' | 'aboutToExpire' | 'started'
}

const TimerContext = createContext<TimerContext>(null)

export const TimerContextProvider: React.FC<TimerContextProviderProps> = ({ children, timer }) => {
  const isMountedRef = useIsMountedRef()
  const timerRef = useRef<TimerCount>()

  const _computeCountdownState = ({ state, timer }: { state?: CountdownState; timer?: TimerCount }): CountdownState => {
    const deadline = timer?.expiresAt ? parseDateTime(timer.expiresAt).toMillis() : state?.expiresAt
    const startTime = timer?.startsAt ? parseDateTime(timer.startsAt).toMillis() : state?.startsAt
    const timeLeftSecs = (deadline - Date.now()) / 1000
    const hours = Math.floor(timeLeftSecs / 60 / 60)
    const minutes = Math.floor(timeLeftSecs / 60) - (hours > 0 ? hours * 60 : 0)
    const seconds = Math.floor(timeLeftSecs % 60)
    const isExpired = timeLeftSecs <= 0
    const isAboutToExpire = !isExpired && hours <= 6

    return {
      hours: hours >= 0 ? hours : 0,
      minutes: minutes >= 0 ? minutes : 0,
      seconds: seconds >= 0 ? seconds : 0,
      expiresAt: deadline,
      startsAt: startTime,
      status: isExpired ? 'expired' : isAboutToExpire ? 'aboutToExpire' : 'started',
    }
  }
  const [state, setState] = useState<CountdownState>(() => _computeCountdownState({ timer }))

  useEffect(() => {
    timerRef.current = timer
    setState(
      _computeCountdownState({
        state,
        timer: timerRef.current,
      }),
    )
  }, [timer])

  useTimeInterval(
    () => {
      const timer = { ...timerRef.current }
      if (isMountedRef.current && timer.startsAt && timer.expiresAt) {
        setState(_computeCountdownState({ timer }))
      }
    },
    {
      enabled: !!timer,
      delay: 1000,
    },
  )

  const value = {
    timerState: state,
  }

  return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
}

export const useTimer = generateUseContext(TimerContext)
