import React from 'react'

import { BookingActions } from '@guiker/booking-shared'

import { PaymentVerificationContextProvider, useBookingContext } from '../../../../hooks'
import { PaymentVerification } from './PaymentVerification'

const UnitManagerBookingPayment: React.FC = () => {
  const { booking, can } = useBookingContext()
  const canGetBookingPayment = can(BookingActions.GetBookingPayment)

  if (!booking.hasEnabledStep.payment || !canGetBookingPayment) {
    return <></>
  }

  return (
    <PaymentVerificationContextProvider>
      <PaymentVerification />
    </PaymentVerificationContextProvider>
  )
}

export { UnitManagerBookingPayment }
