import { buildWebAppRoutes } from '@guiker/shared-framework'

export const { routes: mainRoutes, pathBuilder: mainPathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        home: '/home',
        tenantPreferences: '/tenant-preferences',
      },
    },
  } as const,
  'rental-tenancy',
)
