import {
  buildInvoiceMonetaryDetails,
  getTransactionFeeRate,
  Invoice,
  InvoiceStatus,
  PayInMethod,
  PayInMethodType,
  ScopeType,
} from '@guiker/payment-shared'
import { MarkOptional } from '@guiker/ts-utils'

import { StockOrderProjection, stockOrderTransactionFee } from '../entity'

type InvoiceDetails = {
  buildInvoiceMonetaryDetails: typeof buildInvoiceMonetaryDetails
  invoice: MarkOptional<Invoice<ScopeType.BUY_STOCK_ORDER>, 'id' | 'customer'>
  payment: {
    method: PayInMethod | { id: string; type: PayInMethodType }
    transactionFeeRate: number
  }
}

type InvoiceDetailsBuilderArgs = {
  stockOrder: StockOrderProjection
  payInMethod: PayInMethod | { id: string; type: PayInMethodType }
}

export const stockOrderInvoiceDetailsBuilder = {
  fromBuy: {
    ipo: ({ stockOrder, payInMethod }: InvoiceDetailsBuilderArgs): InvoiceDetails => {
      const currency = stockOrder.stock.currency
      const timestamp = new Date().toISOString()

      return {
        payment: {
          method: payInMethod,
          transactionFeeRate: getTransactionFeeRate(payInMethod?.type),
        },
        invoice: {
          scope: {
            type: ScopeType.BUY_STOCK_ORDER,
            id: stockOrder.id,
          },
          status: InvoiceStatus.SENT,
          autoChargeEnabled: false,
          shouldVerify: false,
          transactions: [],
          currency,
          info: {
            label: stockOrder.stock.label,
          },
          items: [
            {
              label: stockOrder.stock.label,
              quantity: stockOrder.share.quantity,
              pricePerUnit: stockOrder.share.askedPrice,
              taxes: [],
            },
            {
              label: 'Transaction Fee',
              quantity: 1,
              pricePerUnit: stockOrderTransactionFee[currency],
            },
          ],
          metadata: {
            stockLabel: stockOrder.stock.label,
            stockId: stockOrder.stock.id,
            stockOrderExecutionId: undefined,
          },
          createdAt: timestamp,
          updatedAt: timestamp,
        },
        buildInvoiceMonetaryDetails,
      }
    },
  },
  fromExecution: {},
}
