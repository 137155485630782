import React from 'react'

import {
  Flex,
  Grid,
  GridSize,
  H3,
  H4,
  isMobile,
  makeStyles,
  PSmall,
  theme,
  TooltipUnderline,
  toPx,
  useMediaQuery,
} from '@guiker/components-library'

type Item = {
  label: string
  description: string
  unit?: string
  tip?: string
}

type KpiGridProp = {
  items: Item[]
}

type KpiProp = {
  item: Item
  fullRow: boolean
  total: number
}

const useStyle = makeStyles((theme) => ({
  description: {
    textWrap: 'nowrap',
  },
  container: {
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[15]}`,
    borderRadius: 8,
    overflow: 'hidden',
  },
  item: {
    alignItems: 'center',
    border: `0.5px solid ${theme.palette.grey[15]}`,
    display: 'flex',
    padding: `${toPx(theme.spacing(2))} ${toPx(theme.spacing(4))}`,
    [isMobile]: {
      padding: theme.spacing(2),
    },
  },
  tip: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
  },
}))

const Kpi: React.FC<KpiProp> = ({ item, fullRow, total }) => {
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const gridSize = Math.floor(12 / total) < 3 ? 3 : Math.floor(12 / total)
  const DescriptionComponent = isMobile ? H4 : H3

  return (
    <Grid
      item
      className={classes.item}
      xs={fullRow && isMobile ? 12 : isMobile ? 6 : undefined}
      md={fullRow && isMobile ? 12 : isMobile ? 6 : (gridSize as GridSize)}
    >
      <Flex flexDirection='column' gap={1} width='100%'>
        {item.tip ? (
          <TooltipUnderline
            title={item.tip}
            color={theme.palette.primary.main}
            backgroundColor={theme.palette.others.alabaster.background}
          >
            <PSmall color={60} textAlign='left' mb={0}>
              {item.label}
            </PSmall>
          </TooltipUnderline>
        ) : (
          <PSmall color={60} textAlign='left' mb={0}>
            {item.label}
          </PSmall>
        )}
        <Flex gap={1} alignItems='flex-end'>
          <DescriptionComponent mb={0} textAlign='left' className={classes.description}>
            {item.description}
          </DescriptionComponent>
          {!!item.unit && (
            <Flex mb={0.6}>
              <PSmall textAlign='left' color={60}>
                {item.unit}
              </PSmall>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Grid>
  )
}

export const KpiGrid: React.FC<KpiGridProp> = ({ items }) => {
  const classes = useStyle()

  return (
    <Grid container className={classes.container}>
      {items.map((item, index) => (
        <Kpi item={item} fullRow={index === 0 && items.length % 2 === 1} total={items.length} />
      ))}
    </Grid>
  )
}
