import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { LabelizeResult } from '../../entity'
import { CompareFacesResult } from '../../entity/compare-faces-result'
import { paths } from './paths'
import { compareFacesSchema, verifyGovernmentIdSchema } from './schemas'

export const routes = {
  compareFaces: {
    path: paths.imageRecognition.all.withId('compare-faces'),
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (res: unknown) => Promise.resolve(res as CompareFacesResult),
    payloadValidator: validatorBuilder.buildPayloadValidator(compareFacesSchema),
  },
  verifyGovernmentId: {
    path: paths.imageRecognition.all.withId('verify-government-id'),
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (res: unknown) => Promise.resolve(res as LabelizeResult),
    payloadValidator: validatorBuilder.buildPayloadValidator(verifyGovernmentIdSchema),
  },
}
