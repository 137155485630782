import { routesBuilder } from '@guiker/api-definition'
import { Roommate } from '@guiker/base-entity'

import { Booking } from '../../entity'
import { paths } from './paths'

const { buildGet } = routesBuilder<Booking>()({ basePath: paths.booking.all.withScope('listings', ':listingId') })

export const routes = {
  readRoommatesByListingId: buildGet({
    path: '/roommates',
    responseIsArray: true,
    response: null as Roommate,
  }),
}
