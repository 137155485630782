import { routes as backgroundCheckRoutes } from './background-check'
import * as Schemas from './background-check/schemas'
import { routes as creditsRoutes } from './credits'

const routes = {
  ...backgroundCheckRoutes,
  ...creditsRoutes,
}

export { routes, Schemas }
