import { HttpMethod } from '@guiker/http'

import { UserEnquiry } from '../../entity'
import { paths } from './paths'
import { foundListingOutsideEnquirySchema, investorsEnquirySchema, serviceEnquirySchema } from './schemas'

export const routes = {
  createServiceEnquiry: {
    path: `${paths.userEnquiry.all.base()}/services`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as UserEnquiry),
    payloadValidator: (payload: unknown) => serviceEnquirySchema.validate(payload),
  },
  createFoundListingOutsideEnquiry: {
    path: `${paths.userEnquiry.all.base()}/found-listing-outside`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as UserEnquiry),
    payloadValidator: (payload: unknown) => foundListingOutsideEnquirySchema.validate(payload),
  },
  createInvestorsEnquiry: {
    path: `${paths.userEnquiry.all.base()}/investors`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as UserEnquiry),
    payloadValidator: (payload: unknown) => investorsEnquirySchema.validate(payload),
  },
}
