import { Address } from '../address'

export interface DwellingPurpose {
  isResidentialOnly: boolean
  additionalPurpose?: string | null
}
export interface ParkingInformation {
  isAvailable: boolean
  numberOfPlaces?: number
  spaces?: string
}

export enum ApplianceTypes {
  dishwasher = 'dishwasher',
  dryer = 'dryer',
  microwaveOven = 'microwaveOven',
  refrigerator = 'refrigerator',
  stove = 'stove',
  washer = 'washer',
}

export type Appliances = { [key in ApplianceTypes]: boolean }

export enum FurnitureTypes {
  armchairs = 'armchairs',
  beds = 'beds',
  chairs = 'chairs',
  chestsOfDrawers = 'chestsOfDrawers',
  couches = 'couches',
  tables = 'tables',
  other = 'other',
}

export type Furniture = { [key in FurnitureTypes]: boolean } & {
  armchairsNumber?: number
  bedsNumber?: number
  bedsSize?: string[]
  chairsNumber?: number
  chestsOfDrawersNumber?: number
  couchesNumber?: number
  tablesNumber?: number
  otherDescription?: string
}

export type LockerOrStorageSpace = {
  isAvailable: boolean
  description?: string
}

export interface AccessoriesAndDependencies {
  isAvailable: boolean
  description?: string
}

export interface Dwelling {
  accessoriesAndDependencies: AccessoriesAndDependencies
  address: Address
  appliances: Appliances
  completed: boolean
  furniture: Furniture
  isCoOwnership: boolean
  indoorParking: ParkingInformation
  isFurnished: boolean
  lockerOrStorageSpace: LockerOrStorageSpace
  numberOfRooms: string
  outdoorParking: ParkingInformation
  purpose: DwellingPurpose
}
