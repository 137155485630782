import { CurrencyISO, yup } from '@guiker/shared-framework'

import { CSVTransformedBulkInvoiceData } from '../../../../entity'

const CSVInvoiceSchema = yup.object<CSVTransformedBulkInvoiceData>({
  csvIndex: yup.number().required(),
  payeeEmail: yup.string().nullable(),
  currency: yup.string().oneOf(Object.values(CurrencyISO)).nullable(),
  reason: yup.string().nullable(),
  taxableItemLabel: yup.string().nullable(),
  nonTaxableItemLabel: yup.string().nullable(),
  nonTaxableAmount: yup.number().nullable(),
  taxableAmount: yup.number().nullable(),
  taxRegion: yup.string().nullable(),
  taxableItemDescription: yup.string().nullable(),
  nonTaxableItemDescription: yup.string().nullable(),
  dueBy: yup.string().nullable(),
  idempotencyKey: yup.string().nullable(),
})

export const bulkChargeInvoiceSchema = yup.object({
  csvPayload: yup.array().of(CSVInvoiceSchema).min(1).required(),
})

export type BulkChargeInvoiceSchema = yup.InferType<typeof bulkChargeInvoiceSchema>
