import React, { createContext, useEffect, useState } from 'react'

import { Application, Booking } from '@guiker/booking-shared'
import { FullScreenSpinner } from '@guiker/components-library'
import { merge } from '@guiker/lodash'
import { generateUseContext } from '@guiker/react-framework'
import { useMutation } from '@guiker/react-query'

import { useBookingContext } from '../hooks'
import { useBookingApiClient } from '../lib'
import { findApplicationFromBookingById } from '../utils'

type Context = {
  booking: Booking
  setBookingApplication: (bookingApplication: Application) => void
  bookingApplication: Application
  isLoading: boolean
  decryptSSN: () => Promise<string>
  decryptGuarantorSSN: () => Promise<string>
  refetchBooking: () => void
}

type BookingApplicationContextProviderProps = {
  applicantId: string
  children: React.ReactNode | RenderChildren
}

type RenderChildren = (context: Context) => React.ReactNode

export const BookingApplicationContext = createContext<Context>(null)

export const BookingApplicationContextProvider: React.FC<BookingApplicationContextProviderProps> = ({
  applicantId,
  children,
}) => {
  const { booking, isFetching: isLoading, refetchBooking } = useBookingContext()
  const bookingApiClient = useBookingApiClient()

  const [bookingApplication, setBookingApplication] = useState<Application>(
    findApplicationFromBookingById(booking, applicantId),
  )

  useEffect(() => {
    setBookingApplication(findApplicationFromBookingById(booking, applicantId))
  }, [booking, applicantId])

  const { mutateAsync: decryptSSN } = useMutation(
    async () =>
      bookingApiClient.revealApplicantSSN({
        pathParams: { bookingId: booking.id, applicantUserId: applicantId },
      }),
    {
      onSuccess: (res) => {
        setBookingApplication((curr) =>
          merge(curr, {
            content: {
              backgroundCheck: {
                ssn: res,
              },
            },
          }),
        )
      },
    },
  )

  const { mutateAsync: decryptGuarantorSSN } = useMutation(
    async () =>
      bookingApiClient.revealGuarantorSSN({
        pathParams: { bookingId: booking.id, applicantUserId: applicantId },
      }),
    {
      onSuccess: (res) => {
        setBookingApplication((curr) =>
          merge(curr, {
            content: {
              guarantor: {
                backgroundCheck: {
                  ssn: res,
                },
              },
            },
          }),
        )
      },
    },
  )

  const value = {
    booking,
    bookingApplication,
    setBookingApplication,
    decryptSSN,
    decryptGuarantorSSN,
    isLoading,
    refetchBooking,
  }

  return (
    <BookingApplicationContext.Provider value={value as any}>
      {isLoading ? (
        <FullScreenSpinner />
      ) : typeof children === 'function' ? (
        (children as RenderChildren)(value as any)
      ) : (
        children
      )}
    </BookingApplicationContext.Provider>
  )
}

export const useBookingApplicationContext = generateUseContext(BookingApplicationContext)
