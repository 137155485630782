import { routesBuilder } from '@guiker/shared-framework'

import { Contract } from '../../entity'
import { paths } from './paths'
import { readAllByScopeQuerySchema } from './schemas'

const { buildGet } = routesBuilder<Contract>()({
  basePath: paths.contract.path,
})

export const routes = {
  readAllByScope: buildGet({
    path: '/contracts',
    paginated: true,
    queryParamsSchema: readAllByScopeQuerySchema,
  }),
}
