import React from 'react'

import { findLatestSeries, StockOrder, StockOrderStatus, webappRoutes } from '@guiker/propsharing-shared'
import {
  Chip,
  ColumnConfig,
  Flex,
  Link,
  PaginatedTable,
  PSmall,
  PSmaller,
  RouterLink,
  TextButton,
  theme,
  useDateFormatter,
  useNavigate,
} from '@guiker/react-framework'
import { DataAndMeta, money, numberToString, Paginate, WithTimestamps } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { getStockOrderStatusColor } from '../utils'

type StockOrderTableProps = {
  queryKey: string
  fetcher: (paginate: Paginate & { filters: { statuses: StockOrderStatus[] } }) => Promise<DataAndMeta<StockOrder[]>>
}

const useGetColumns = (): ColumnConfig<WithTimestamps<StockOrder>>[] => {
  const { tShared, tBase } = useT({ entity: 'stockOrder' })
  const { tShared: tSharedListing } = useT({ entity: 'listing.stock' })
  const { formatDate } = useDateFormatter()

  const headerAlign = {
    right: { textAlign: 'right' },
    center: { textAlign: 'center' },
  } as const

  return [
    {
      name: 'stockOrder.tickerSymbol',
      size: 2,
      renderValue: (stockOrder) => {
        const navigate = useNavigate()

        return (
          <Link onClick={() => navigate(webappRoutes.listings.byTickerSymbol(stockOrder.stock.tickerSymbol).path)}>
            <Flex flexDirection='column'>
              <PSmall mb={0}>{stockOrder.stock.tickerSymbol}</PSmall>
              <PSmaller mb={0} color={60}>
                {stockOrder.stock.label}
              </PSmaller>
            </Flex>
          </Link>
        )
      },
    },
    {
      label: tShared('updatedAt'),
      name: 'updatedAt',
      size: 1,
      renderValue: (stockOrder) => {
        return formatDate(stockOrder.updatedAt)
      },
    },
    {
      label: tShared('share.quantity'),
      name: 'share.quantity',
      headerOptions: headerAlign.right,
      renderValue: (stockOrder) => {
        return (
          <Flex gap={0.5} alignItems='baseline' justifyContent='flex-end'>
            <PSmall mb={0} textAlign='right'>
              {numberToString(stockOrder.share.quantity, 0)}
            </PSmall>
            <PSmall mb={0} color={60}>
              {tSharedListing('units')}
            </PSmall>
          </Flex>
        )
      },
    },
    {
      label: tShared('totalValue'),
      headerOptions: headerAlign.right,
      size: 1,
      renderValue: (stockOrder) => {
        const series =
          stockOrder.stock.series?.find((s) => s.slug === stockOrder.seriesSlug) ?? findLatestSeries(stockOrder.stock)

        return (
          series.pricePerUnit &&
          money
            .fromAmount(stockOrder.share.askedPrice * stockOrder.share.quantity, stockOrder.stock.currency)
            .toString('onlySymbol')
        )
      },
    },
    {
      label: tShared('type'),
      name: 'action',
      headerOptions: headerAlign.right,
      size: 1,
      renderValue: ({ action }) => {
        return (
          <Flex alignItems='center' justifyContent='flex-end'>
            <PSmall>{action.toUpperCase()}</PSmall>
          </Flex>
        )
      },
    },
    {
      label: tShared('status'),
      headerOptions: headerAlign.center,
      name: 'status',
      size: 1,
      renderValue: (stockOrder) => {
        const color = getStockOrderStatusColor(stockOrder)

        return (
          <Flex flexDirection='column' alignItems='center'>
            {stockOrder.status === StockOrderStatus.CREATED ? (
              <RouterLink to={webappRoutes.stockOrder.byId(stockOrder.id).checkout.path}>
                <TextButton size='small'>{tBase('actions.continue')}</TextButton>
              </RouterLink>
            ) : (
              <Chip size='small' variant='outlined' color={color}>
                {tShared(`statuses.${stockOrder.status}`)}
              </Chip>
            )}
          </Flex>
        )
      },
    },
  ]
}

export const StockOrderTable: React.FC<StockOrderTableProps> = ({ queryKey, fetcher }) => {
  const columns = useGetColumns()

  return (
    <PaginatedTable
      queryKey={queryKey}
      fetcher={fetcher}
      columns={columns}
      condensed
      minWidth={theme.dimensions.narrowPageLayout.maxWidth.desktop}
    />
  )
}
