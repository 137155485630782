import { Errors, StatusCodes } from '@guiker/common'

export class UnauthorizedError extends Errors.BaseError {
  constructor(action: string | string[]) {
    super(
      `The current user is not authroized to perform action[${JSON.stringify(action)}]`,
      UnauthorizedError.name,
      StatusCodes.FORBIDDEN,
    )
  }
}
