import { TypeOf } from '@guiker/yup-util'

import { aboutYouSchema } from './update-about-you-schema'
import { employmentInformationSchema } from './update-employment-information-schema'
import { financialProfileSchema } from './update-financial-profile-schema'
import { IdentityVerificationSchema, identityVerificationSchema } from './update-identity-verification'

export const updateInvestorProfileStepperSchema = {
  AboutYou: aboutYouSchema,
  EmploymentInformation: employmentInformationSchema,
  FinancialProfile: financialProfileSchema,
  IdentityVerification: identityVerificationSchema,
}

export type UpdateInvestorProfileStepperSchema = {
  AboutYou: TypeOf<typeof aboutYouSchema>
  EmploymentInformation: TypeOf<typeof employmentInformationSchema>
  FinancialProfile: TypeOf<typeof financialProfileSchema>
  IdentityVerification: IdentityVerificationSchema
}
