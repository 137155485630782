import React, { useLayoutEffect, useState } from 'react'

import { Booking, isBookingEnded, isBookingFailed, isBookingSuccess } from '@guiker/booking-shared'
import { AnyColor, Chip } from '@guiker/react-framework'

import { useT } from '../../i18n'

type BookingStatusChipProps = { booking: Pick<Booking, 'status' | 'info'> }

export const BookingStatusChip: React.FC<BookingStatusChipProps> = ({ booking }) => {
  const { tShared } = useT({ entity: 'booking.statuses' })
  const [color, setColor] = useState<AnyColor>(undefined)

  useLayoutEffect(() => {
    if (isBookingEnded(booking)) setColor('cancelled')
    else if (isBookingSuccess(booking)) setColor('success')
    else if (isBookingFailed(booking)) setColor('alert')
    else return setColor('info')
  }, [booking])

  if (!color) return <></>

  return (
    <Chip color={color} size='small'>
      {tShared(booking.status)}
    </Chip>
  )
}
