import React, { useState } from 'react'

import { AuthenticatedApi } from '@guiker/booking-shared'
import {
  ApiForm,
  Box,
  ButtonWithLoader,
  Modal,
  NumberFormat,
  P,
  TextButton,
  useMutation,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'

type TimerExtensionProps = {
  hasExpired: boolean
}

const TimerExtension: React.FC<TimerExtensionProps> = ({ hasExpired }) => {
  const { t } = useTranslation(['common', 'main-booking'])
  const { apiClient, booking, bookingUser, refetchBooking } = useBookingContext()

  const [openModal, setOpenModal] = useState(false)
  const handleModal = () => setOpenModal(!openModal)

  const { mutate: handleExtendTimerExpiration, isLoading } = useMutation(
    (payload: { expirationHours: number }) => {
      const params = { pathParams: { bookingId: booking.id, userId: bookingUser.id }, payload }
      return hasExpired ? apiClient.resetTimerExpiration(params) : apiClient.extendTimerExpiration(params)
    },
    {
      onSuccess: () => {
        refetchBooking()
        handleModal()
      },
    },
  )
  const resolver = yupResolver(AuthenticatedApi.Schema.extendTimerExpirationPayloadSchema)

  return (
    <>
      <TextButton size='small' onClick={() => handleModal()}>
        {t(`main-booking:actions.time.${hasExpired ? 'reset' : 'extend'}`)}
      </TextButton>
      <Modal
        open={openModal}
        title={t(`main-booking:screens.bookingModule.time.${hasExpired ? 'resetModal' : 'extendModal'}.title`)}
        onClose={() => handleModal()}
      >
        <ApiForm formName='BookingTimerExtensionForm' onSubmit={handleExtendTimerExpiration} formOptions={{ resolver }}>
          <NumberFormat
            name='expirationHours'
            endAdornment={t('main-booking:screens.bookingModule.time.extendModal.suffix')}
          />
          <P color={50} fontStyle='italic'>
            {t('main-booking:screens.bookingModule.time.extendModal.description')}
          </P>
          <Box my={1}>
            <ButtonWithLoader fullWidth type='submit' isLoading={isLoading}>
              {t('common:actions.confirm')}
            </ButtonWithLoader>
          </Box>
        </ApiForm>
      </Modal>
    </>
  )
}

export { TimerExtension }
