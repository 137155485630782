import { yup } from '@guiker/yup-util'

import { CountryCode } from '../../../entity'

export const createLinkTokenSchema = yup.object<{
  countryCode: CountryCode
  accessToken?: string
}>({
  countryCode: yup.mixed().oneOf(Object.values(CountryCode)).required(),
  accessToken: yup.string().optional(),
})

export const readPlaidBankAccountDetailsSchema = yup.object<{
  countryCode: CountryCode
  publicToken: string
  accountId: string
}>({
  countryCode: yup.mixed().oneOf(Object.values(CountryCode)).required(),
  publicToken: yup.string().required(),
  accountId: yup.string().required(),
})
