import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { Lease } from '../../../entity'
import {
  GetLeaseDownloadLinkByScopeQueryParams,
  getLeaseDownloadLinkByScopeQueryParams,
} from '../../get-lease-download-link-by-scope'
import { leaseByScope } from '../../lease-by-scope'
import { leaseIdNested } from '../../lease-id-nested'
import { leaseAccessControlClaimsSchema } from '../../schemas'
import { paths } from './paths'

const responseValidator = (payload: unknown) => Promise.resolve(payload as Lease)

export const routes = {
  readOneLeaseByToken: {
    path: `${paths.lease.all.withId(':leaseId')}/access`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (params: unknown) => leaseIdNested.validate(params),
  },
  readOneLeaseByScopeAndRoleToken: {
    path: paths.lease.one.withScope(),
    method: HttpMethod.GET,
    authenticated: true,
    accessControlValidator: validatorBuilder.buildAccessControlValidator(leaseAccessControlClaimsSchema),
    responseValidator,
    pathParamsValidator: (params: unknown) => leaseByScope.validate(params),
  },
  getLeaseDownloadLinkByScopeAndRoleToken: {
    path: `${paths.lease.one.withScope()}/download`,
    method: HttpMethod.GET,
    authenticated: true,
    accessControlValidator: validatorBuilder.buildAccessControlValidator(leaseAccessControlClaimsSchema),
    responseValidator: (response: unknown) => Promise.resolve(response as { downloadLink: string }),
    pathParamsValidator: (params: unknown) => leaseByScope.validate(params),
    queryParamsValidator: (params: unknown): Promise<Partial<GetLeaseDownloadLinkByScopeQueryParams>> =>
      getLeaseDownloadLinkByScopeQueryParams.validate(params),
  },
}
