import { yup } from '@guiker/yup-util'

export const upsertPropSharingInquirySchema = yup.object({
  listingId: yup.string().required(),
  stockId: yup.string().required(),
  assumptions: yup.object(),
  results: yup.object(),
  unitAmount: yup.number().min(1).required(),
  askedPrice: yup.number().min(0).required(),
  payInMethod: yup.string().nullable(),
})

export type UpsertPropSharingInquirySchema = yup.InferType<typeof upsertPropSharingInquirySchema>
