import React from 'react'

import {
  CurrencyTextField,
  FormSection3,
  HiddenInput,
  P,
  PBold,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useInvestmentAssumptionContext } from '../../../hooks'
import { AssumptionComponentProps as Props } from '../assumption-props'

export const Price: React.FC<Props> = ({ defaultValue, name, schema, readOnly = false }) => {
  const { t } = useTranslation()
  const tPrefix = 'common-propertySale:listing.assumptions.purchase'
  const { investmentResults, currency, investmentAssumptions } = useInvestmentAssumptionContext()

  const inputProps = useGetInputProps({ defaultValue, formPrefix: name, schema, tPrefix, readOnly })

  return (
    <FormSection3>
      <HiddenInput {...inputProps('currency')} defaultValue={currency?.iso} />
      <TwoColumnsGridLayout>
        <CurrencyTextField {...inputProps('amount')} currency={currency} allowNegative={false} />
        <CurrencyTextField
          name={'assumptions.base.assessedValue'}
          label={t('common-investmentAssumption:base.assessedValue')}
          defaultValue={investmentAssumptions.base.assessedValue}
          currency={currency}
          allowNegative={false}
        />
      </TwoColumnsGridLayout>
      <TwoColumnsGridLayout gap={2}>
        {investmentResults?.computedPurchaseTaxes?.map((tax) => (
          <>
            <P mb={0}>{t(`${tPrefix}.taxes.${tax.jurisdiction}.${tax.type}`)}</P>
            <PBold>{money.fromAmount(tax.computedValue, currency).toString()}</PBold>
          </>
        ))}
      </TwoColumnsGridLayout>
    </FormSection3>
  )
}
