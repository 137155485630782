export enum EmploymentType {
  Employed = 'Employed',
  HomeMaker = 'HomeMaker',
  Retired = 'Retired',
  SelfEmployed = 'SelfEmployed',
  Student = 'Student',
  Unemployed = 'Unemployed',
}

export const businessTypes = [
  'Agriculture',
  'Construction',
  'EducationalServices',
  'FinancialServices',
  'HealthAndSocialServices',
  'HospitalityIndustry',
  'ManagementOfCompanies',
  'Manufacturing',
  'ProfessionalServices',
  'PublicAdministration',
  'RecreationalServices',
  'ResourcesIndustries',
  'Technology',
  'TransportAndWarehousing',
  'Utilities',
] as const

const commonIncomeSources = [
  'Spouse',
  'Gift',
  'Savings',
  'SaleOfAssets',
  'Parents',
  'OtherFamilyMember',
  'Other',
] as const

export const incomeSources = {
  [EmploymentType.HomeMaker]: ['PreviousEmployment', 'Investments', 'Inheritance', ...commonIncomeSources],
  [EmploymentType.Retired]: ['Pension', 'Investments', ...commonIncomeSources],
  [EmploymentType.Student]: [
    'PartTimeJob',
    'StudentLoan',
    'SummerJob',
    'PaidInternship',
    'Scholarship',
    ...commonIncomeSources,
  ],
  [EmploymentType.Unemployed]: [
    'PreviousEmployment',
    'DisabilityOrUnemploymentInsurance',
    'Inheritance',
    'Investments',
    ...commonIncomeSources,
  ],
} as const

export type Employment = {
  isPEPorHIO: boolean
  type: EmploymentType
  incomeSource: string
  employerName: string
  businessType: string
  jobTitle: string
}
