import React, { useEffect, useState } from 'react'

import { User } from '@guiker/authentication-shared'
import { DataTrackingCTA, useDataTrackingContext } from '@guiker/data-tracking-context'
import { safelyGetWindow } from '@guiker/react-framework'
import { useMutation } from '@guiker/react-query'

import { AuthType } from '../../auth-type'
import { Authenticator, useAuthenticationContext, usePublicAuthenticationApiClient } from '../../authentication'
import { config } from './config'
import { loadScript } from './load-scripts'

interface Props {
  authenticator: Authenticator
  initialized: boolean
  authType: AuthType
}

export const LogInButtonGoogle: React.FC<Props> = ({ authenticator, authType }) => {
  const [] = useState(false)
  const apiClient = usePublicAuthenticationApiClient()
  const { sendEvent } = useDataTrackingContext()
  const { user, setUser } = useAuthenticationContext()

  const { mutate: handleLogIn } = useMutation(
    async (auth: CredentialResponse) => {
      return apiClient.socialLogIn({
        payload: {
          authenticatorId: authenticator.id,
          password: auth.credential,
          invitationToken: user?.invitationToken,
        },
      })
    },
    {
      onSuccess: (res: User) => {
        sendEvent({ event: authType, userId: res.id.toString() })
        setUser(res, { type: authType })
      },
    },
  )

  const initializeAuthClient = async () => {
    const { provider, scriptSrc, clientId: client_id } = config.google

    return new Promise<boolean>((resolve, reject) => {
      loadScript(provider, scriptSrc, () => {
        if (safelyGetWindow()?.google) {
          google.accounts.id.initialize({
            client_id,
            callback: handleLogIn,
          })
          google.accounts.id.disableAutoSelect()
          /** https://developers.google.com/identity/gsi/web/reference/js-reference */
          google.accounts.id.renderButton(document.getElementById('google-login-button') as unknown as string, {
            text: 'continue_with',
            theme: 'outline',
            size: 'large',
            type: 'standard',
            shape: 'rectangular',
            logo_alignment: 'left',
            width: '100%',
          })
          resolve(true)
        } else {
          reject(false)
        }
      })
    })
  }

  useEffect(() => {
    initializeAuthClient()
  }, [])

  return (
    <DataTrackingCTA key={'GoogleLogIn'}>
      <div id='google-login-button'></div>
    </DataTrackingCTA>
  )
}
