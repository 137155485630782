import React from 'react'

import { clsx, Flex, makeStyles, theme } from '@guiker/react-framework'

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      width: '100%',
    },
    bar: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
      height: theme.spacing(1),
    },
    text: {
      fontSize: 12,
    },
    firstPart: {
      width: ({ progress }: BookingCompletionProgressBarProps) => `calc(${Math.max(progress * 100, 5)}%)`,
    },
    secondPart: {
      backgroundColor: theme.palette.grey[30],
      width: ({ progress }: BookingCompletionProgressBarProps) => `calc(${(1 - progress) * 100}%)`,
    },
  },
  {
    name: 'BookingCompletionProgressBar',
  },
)

type BookingCompletionProgressBarProps = {
  progress: number
}

const BookingCompletionProgressBar: React.FC<BookingCompletionProgressBarProps> = ({ progress }) => {
  const classes = useStyles({ progress })
  return (
    <Flex fullWidth alignItems='center' gap={1}>
      <div className={clsx(classes.bar, classes.firstPart)}>&nbsp;</div>
      <div className={classes.text}>{Math.floor(progress * 100)}%</div>
      <div className={clsx(classes.bar, classes.secondPart)}>&nbsp;</div>
    </Flex>
  )
}

export { BookingCompletionProgressBar }
