import { useContext } from 'react'

import { ConfigContext } from './ConfigContext'

export const useConfig = () => {
  const context = useContext(ConfigContext)

  if (context === undefined) {
    throw new Error('useConfig can only be used inside ConfigContextProvider')
  }

  return context
}
