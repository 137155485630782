import React from 'react'

import { CardNumberElement as StripeCardNumberElement, CardNumberElementProps } from '@stripe/react-stripe-js'

import { CardElementProps, RenderProps } from '../context'
import { InputBase } from './InputBase'

type Props = CardElementProps<CardNumberElementProps>

export const CardNumberElement: React.FC<Props> = ({ id, label, inputError, ...props }) => (
  <InputBase id={id} label={label} inputError={inputError}>
    {(renderProps: RenderProps) => <StripeCardNumberElement {...renderProps} {...props} />}
  </InputBase>
)
