import { useContext } from 'react'

import { ApplicationComponentsContext } from './ApplicationComponentsContext'

const useApplicationComponentsContext = () => {
  const context = useContext(ApplicationComponentsContext)

  if (context === undefined) {
    throw new Error('useApplicationComponentsContext can only be used inside ApplicationComponentsContextProvider')
  }

  return context
}

export { useApplicationComponentsContext }
