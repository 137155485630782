import React from 'react'

import { Display3, DisplayBodyBig, Flex, useLayoutContext } from '@guiker/react-framework'

import { useT } from '../../i18n'

export const OurVision: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.home.ourVision' })
  const { isAtMostTablette } = useLayoutContext()

  return (
    <Flex fullWidth justifyContent='center' alignItems='center'>
      <Flex maxWidth={1024} flexWrap='wrap' gap={isAtMostTablette ? 4 : 8}>
        <Display3 mb={0}>{tMain('title')}</Display3>
        <Flex maxWidth={700}>
          <DisplayBodyBig breakline>{tMain('content')}</DisplayBodyBig>
        </Flex>
      </Flex>
    </Flex>
  )
}
