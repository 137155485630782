import { CurrencyISO } from '@guiker/money'
import { DeepPartial } from '@guiker/ts-utils'

import { RentalType } from '../rentalListing'
import { Amenity } from './amenity'
import { Inventory } from './inventory'
import { ListingLeaseRequirement } from './listing-lease-requirement'
import { ListingUser } from './listing-user'
import { ListingUserRole } from './listing-user-roles'
import { Picture } from './picture'

export type ListingStatus = 'CREATED' | 'LISTED' | 'PENDING' | 'UNLISTED'

export enum ManageType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export enum PropertyType {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  HOMESTAY = 'HOMESTAY',
  CONDO = 'CONDO',
  HIGHRISE = 'HIGHRISE',
  MULTI_FAMILY = 'MULTI_FAMILY',
}

export type UpdateListingPayload = {
  description: string
  cityId: number
  cityName: string
  streetNumber: string
  streetName: string
  zipcode: string
  latitude?: number
  longitude?: number
  amenityIds: number[]
  pictures: {
    isExternalLink: boolean
    pictureName: string
  }[]
}

export type CreateListingPayload = UpdateListingPayload & {
  externalId: string
  reference: string
  title: string
  managedType: ManageType
  propertyType: PropertyType
  inventories: DeepPartial<Inventory & { amenityIds: number[] }>[]
  listingUsers: {
    role: ListingUserRole
    isContactPerson?: boolean
    isCreator?: boolean
    isLeaseContractSigner?: boolean
    user: {
      phone?: string
      firstName?: string
      lastName?: string
      email: string
    }
  }[]
  listingSource: string
  status: string
}

export type Listing = {
  id: number
  city: {
    cityName: string
    country: 'CA' | 'US'
    state: string
    currency?: CurrencyISO
  }
  contactPerson: {
    user: {
      id: string
    }
  }
  listingType: {
    key: 'NEW' | 'SUBLEASE' | 'TRANSFER'
  }
  listingLeaseRequirements: ListingLeaseRequirement[]
  description: string
  status: ListingStatus
  title: string
  suiteNumber: string
  latitude?: string
  longitude?: string
  streetNumber: string
  streetName: string
  zipcode: string
  cityName?: string
  neighbourhood?: string
  externalId: string
  listingUsers: ListingUser[]
  inventories: Inventory[]
  pictures: Picture[]
  rent: {
    amount: number
    currency: 'CAD' | 'USD'
    frequency: 'MONTHLY' | 'WEEKLY'
  }
  availability: {
    from: string
    to: string
  }
  source: {
    id: number
    name: string
    cityId: number
  }
  reference?: string
  amenities: Amenity[]
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}

// from GET /ids call it returns lite version without inventories
export type ListingLite = Listing & {
  availableOptionCount: number
  lowestPrice: number
  lowestConfigurationType: RentalType
  lowestBedroomCount: number
}
