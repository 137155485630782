export const CommonOperationCost = {
  assetManagementFee: {
    jurisdiction: 'common',
    type: 'assetManagementFee',
    frequency: 'yearly',
    value: {
      type: 'percentage',
      base: 'rentalIncome',
      rate: 0.05,
    },
  },
  managementFee: {
    jurisdiction: 'common',
    type: 'managementFee',
    frequency: 'yearly',
    value: {
      type: 'percentage',
      base: 'rentalIncome',
      rate: 0.05,
    },
  },
  repairAllowance: {
    jurisdiction: 'common',
    type: 'repairAllowance',
    frequency: 'yearly',
    value: {
      type: 'percentage',
      base: 'rentalIncome',
      rate: 0.05,
    },
  },
} as const
