import { buildWebAppRoutes } from '@guiker/shared-framework'

export const { routes: mainRoutes, pathBuilder: mainPathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        root: '/',
        todos: '/todos',
        investmentDashboard: '/investment-dashboard',
        payouts: {
          path: '/payouts',
          routes: {
            byId: {
              path: '/:payoutId',
              routes: {
                root: {
                  path: '/',
                },
              },
            },
          },
        },
        rentalDashboard: {
          path: '/rental-dashboard',
          routes: {
            root: '/',
            listings: {
              path: '/listings',
              routes: {
                byListingId: {
                  path: '/:listingId',
                  routes: {
                    root: '/',
                    units: {
                      path: '/units',
                      routes: {
                        byInventoryId: {
                          path: '/:inventoryId',
                          routes: {
                            root: '/',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        rentCollection: '/rent-collection',
        rentPayout: '/rent-payout',
        rentalListings: '/rental-listings',
        rentalTenancy: {
          path: '/rental-tenancy',
          routes: {
            tenantInstalments: {
              path: '/tenant-instalments',
              routes: {
                byId: {
                  path: '/:id',
                  routes: {
                    root: {
                      path: '/',
                    },
                  },
                },
              },
            },
            bookings: {
              path: '/bookings',
              routes: {
                byBookingId: {
                  path: '/:bookingId',
                  routes: {
                    tenants: {
                      path: '/tenants',
                      routes: {
                        byTenantId: {
                          path: '/:tenantId',
                        },
                      },
                    },
                    tenantInstalments: {
                      path: '/instalments',
                      routes: {
                        byTenantInstalmentId: {
                          path: '/:tenantInstalmentId',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        tenants: '/tenants',
      },
    },
  } as const,
  'my-investments',
)
