import React from 'react'

import {
  Chip,
  Flex,
  makeStyles,
  P,
  padding,
  PSmall,
  RouterLink,
  SvgIconProps,
  useDateFormatter,
} from '@guiker/react-framework'

import { Item } from '../../utils'

export type SearchBarItemProps = Item & {
  StartAdornment?: React.FC<SvgIconProps>
  onClose: () => unknown
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: padding(0.5, 1.5),
      '&:hover': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[5],
      },
    },
    withEllipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100%)',
    },
  }),
  { name: 'SearchBarItem' },
)

export const SearchBarItem: React.FC<SearchBarItemProps> = ({
  StartAdornment,
  primary,
  secondary,
  date,
  onClose,
  status,
  url,
}) => {
  const classes = useStyles()
  const { formatDate } = useDateFormatter()

  return (
    <RouterLink to={url} onClick={onClose}>
      <Flex alignItems='center' gap={2} className={classes.root}>
        <Flex flexGrow={1} gap={1.5} alignItems='center' flexWrap='nowrap' maxWidth='100%'>
          {StartAdornment && <StartAdornment size='small' color={60} />}
          <P color='black' mb={0} whiteSpace='nowrap' className={classes.withEllipsis}>
            {primary}
          </P>
          {secondary && (
            <PSmall color={60} mb={0} whiteSpace='nowrap'>
              {secondary}
            </PSmall>
          )}
          {status && (
            <Chip size='small' variant='light' color={status.color}>
              {status.text}
            </Chip>
          )}
        </Flex>
        {date && (
          <PSmall mb={0} color={60} whiteSpace='nowrap'>
            {formatDate(date)}
          </PSmall>
        )}
      </Flex>
    </RouterLink>
  )
}
