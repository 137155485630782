import { compact } from '@guiker/lodash'

export const getTimer = () => {
  let startTime: number
  let endTime: number
  let lastLap: number

  return {
    start: () => {
      startTime = Date.now()
      lastLap = startTime

      return {
        sinceLastLap: 0,
        elapsedTime: 0,
        startTime,
        endTime,
      }
    },
    elapsedTime: () => {
      const now = Date.now()
      const sinceLastLap = lastLap ? now - lastLap : 0
      lastLap = now

      return {
        sinceLastLap,
        elapsedTime: now - startTime,
        startTime,
        endTime,
      }
    },
    stop: () => {
      endTime = Date.now()
      const sinceLastLap = lastLap ? endTime - lastLap : 0

      return {
        sinceLastLap,
        elapsedTime: endTime - startTime,
        startTime,
        endTime,
      }
    },
  }
}

let timer = getTimer()

export const timedLogger = (logger: (str: string) => void = console.log) => {
  const log = (timeString: string, otherStr: string) => logger(compact([timeString, otherStr]).join(' - '))

  return {
    start: (str?: string) => {
      timer.start()
      log(`Start`, str)
    },
    lap: (str?: string) => {
      const { elapsedTime, sinceLastLap } = timer.elapsedTime()
      log(`Elapsed time: ${elapsedTime}ms (${sinceLastLap}ms)`, str)
    },
    stop: (str?: string) => {
      const { endTime, sinceLastLap } = timer.stop()
      log(`End: ${endTime}ms (${sinceLastLap}ms)`, str)
    },
    reset: () => {
      timer = getTimer()
    },
  }
}
