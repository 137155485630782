import { yup } from '@guiker/yup-util'

export const legacyConversationApiConfigSchema = yup
  .object<{
    apiKey: string
    baseUrl: string
    mongoUrl?: string
  }>({
    apiKey: yup.string().required(),
    baseUrl: yup.string().required(),
    mongoUrl: yup.string().required(),
  })
  .required()
