import { AuthApiClient } from '@guiker/propsharing-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export * from './use-api-client'
export * from './InvestorProfileContext'

export const {
  useContext: useAuthenticatedPropSharingListingIdContext,
  NestedRouter: AuthenticatedPropSharingListingIdNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthApiClient,
  cacheKey: ({ id }: { id: string }) => `readOneListingByUid-${id}`,
  fetcher: (apiClient, { id }: { id: string }) => apiClient.readOneListing({ pathParams: { id } }),
})

export const {
  useContext: useAuthenticatedPropSharingListingContext,
  Context: AuthenticatedPropSharingListingContext,
  ContextProvider: AuthenticatedPropSharingListingContextProvider,
  NestedRouter: AuthenticatedPropSharingListingRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthApiClient,
})

export const {
  useContext: useAuthenticatedPropSharingInvestorProfileContext,
  NestedRouter: AuthenticatedPropSharingInvestorProfileRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthApiClient,
  cacheKey: ({ userId }: { userId: string }) => `readOneInvestorProfile-${userId}`,
  fetcher: (apiClient, _: { userId: string }) => apiClient.readOneInvestorProfile(),
})
