import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Developer } from '../../entity'
import { baseEvent } from '../base'

export const DeveloperEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'DEVELOPERS',
  data: null as unknown as Developer,
  eventTypes: {} as const,
})

export const DeveloperEvents = DeveloperEventsDefinition.events
export const DeveloperEventTypes = DeveloperEventsDefinition.eventTypes
export type DeveloperEventTypes = ValueOfEventTypes<typeof DeveloperEventsDefinition>
