import { PhoneNumber } from '@guiker/base-entity'
import { Booking } from '@guiker/booking-shared'

export type VisitSchedule = {
  date: string // YYYY-MM-DD
  timeSlot: {
    morning?: boolean
    afternoon?: boolean
    evening?: boolean
  }
}

export enum ListingInquiryStatus {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export enum ListingInquirySource {
  MULTIPOSTING = 'MULTIPOSTING',
  ORGANIC = 'ORGANIC',
  OFFICE_LEASING_ASSOCIATE = 'OFFICE_LEASING_ASSOCIATE',
  SPLITSPOT = 'SPLITSPOT',
}

export type ListingInquiry = {
  id?: string
  user: {
    id: string
    firstName: string
    lastName: string
    emailAddress: string
    phone: PhoneNumber
  }
  isBookingNotificationEnabled?: boolean
  visitSchedules: VisitSchedule[]
  moveInDate?: string
  message: string
  listingId: string
  rentalOptionId?: string
  conversationId: string
  bookingId: string
  status: ListingInquiryStatus
  source: ListingInquirySource
  booking?: Booking
}
