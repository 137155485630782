import { RentalType } from '@guiker/rental-listing-shared'

export enum MultipostListingInquirySource {
  manual = 'scheduledFetch',
  rentsync = 'rentsyncWebhook',
  zillow = 'zillowWebhook',
  costar = 'costarWebhook',
  email = 'sendgridWebhook',
  leadsbridge = 'leadsbridgeWebhook',
}

export type MultipostListingInquiryStats = {
  listingId: string
  source: MultipostListingInquirySource
  count: number
  timestamps: string[]
}

export type MultipostListingInquiry = {
  id?: string
  user: {
    name: string
    emailAddress: string
    phoneNumber?: string
    firstName?: string
    lastName?: string
  }
  channel: string
  originalLink?: string
  rentalType?: RentalType
  source: MultipostListingInquirySource
  message?: string
  listingId?: string
  optionId?: string
  emailBody?: string
  inquiredAt: string
  processedAt?: string
  matchedAvailability?: boolean
  postingUrl?: string
  moveInDate?: string
  raw?: unknown
}
