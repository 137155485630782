import { JwtAuthenticatedApiClient } from '@guiker/booking-shared'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { useConfig } from '@guiker/config-context'
import { v4 } from '@guiker/uuid'

export const getJwtApiClient = () => {
  const claimsContext = useClaimsAuthenticationContext()
  const config = useConfig()
  const accessToken = claimsContext?.claims?.token as string

  return JwtAuthenticatedApiClient({
    accessToken,
    baseUrl: config.apiBaseUrl,
    correlatedRequestId: v4(),
    logger: config.debug ? console : undefined,
  })
}
