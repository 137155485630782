import { CurrencyISO } from '@guiker/money'
import { InvoiceStatus, PayInMethod } from '@guiker/payment-shared'

import { InvestorProfile } from './investor-profile'
import { Series } from './series'
import { Stock } from './stock'

export enum StockOrderExecutionStatus {
  CREATED = 'CREATED',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
  PENDING_SHARE_ISSUANCE = 'PENDING_SHARE_ISSUANCE',
  PROCESSING_SHARE_ISSUANCE = 'PROCESSING_SHARE_ISSUANCE',
  PROCESSING_BUYOUT = 'PROCESSING_BUYOUT',
  PROCESSING_PROMOTION = 'PROCESSING_PROMOTION',
  FULFILLED = 'FULFILLED',
  CANCELLED = 'CANCELLED',
}

export enum StockOrderExecutionType {
  IPO = 'IPO',
  BUYOUT = 'BUYOUT',
  MARKET_TRADE = 'MARKET_TRADE',
}

export type StockOrderExecution = {
  id: string
  status: StockOrderExecutionStatus
  buyStockOrder: {
    id: string
    investorProfile?: InvestorProfile
    payment?: {
      status?: InvoiceStatus
      payInMethod: PayInMethod
    }
  }
  sellStockOrder: {
    id: string
    investorProfile?: InvestorProfile
  }
  series: Series
  stock: Stock
  price: {
    quantity: number
    amount: number
    currency: CurrencyISO
  }
  updatedAt?: string
  createdAt?: string
}
