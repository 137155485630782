import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'
import { AuthenticatedApiClient, transformLegacyListingToListing } from '@guiker/rental-listing-shared'

export const {
  useContext: useAuthenticatedListingUIDNestedContext,
  Context: AuthenticatedListingUIDNestedContext,
  ContextProvider: AuthenticatedListingUIDNestedContextProvider,
  NestedRouter: AuthenticatedListingUIDNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthenticatedApiClient,
  cacheKey: ({ listingUid }: { listingUid: string }) => `readOneListingByUid-${listingUid}`,
  fetcher: (apiClient, { listingUid }: { listingUid: string }) =>
    apiClient
      .readOneListingByUid({ queryParams: { uid: listingUid } })
      .then((listing) => transformLegacyListingToListing({ listing })),
})

export const {
  useContext: useAuthenticatedListingContext,
  Context: AuthenticatedListingContext,
  ContextProvider: AuthenticatedListingContextProvider,
  NestedRouter: AuthenticatedListingNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthenticatedApiClient,
  cacheKey: ({ listingId }: { listingId: string }) => `readOneListing-${listingId}`,
  fetcher: (apiClient, { listingId }: { listingId: string }) =>
    apiClient
      .readOneListing({ pathParams: { listingId } })
      .then((listing) => transformLegacyListingToListing({ listing })),
})

export const {
  useContext: useAuthenticatedListingUnitContext,
  Context: AuthenticatedListingUnitContext,
  ContextProvider: AuthenticatedListingUnitContextProvider,
  NestedRouter: AuthenticatedListingUnitNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthenticatedApiClient,
  cacheKey: ({ listingId, unitId }: { listingId: string; unitId: string }) =>
    `readOneListingUnit-${listingId}-${unitId}`,
  fetcher: (apiClient, { listingId, unitId }: { listingId: string; unitId: string }) =>
    apiClient.readOneListingUnitById({ pathParams: { listingId, unitId } }),
})
