export const upsert = <T extends object>(
  array: T[],
  predicate: (value: T, index: number) => boolean,
  updator: (curr: T) => T,
) => {
  const found = array.find(predicate)

  if (!found) {
    array.push(updator({} as T))
  } else {
    array = array.map((element, index) => {
      if (predicate(element, index)) {
        return updator(element)
      }

      return element
    })

    return array
  }
}
