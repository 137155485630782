import React from 'react'

import { calculateReportItems } from '@guiker/background-check-shared'
import { AnyColor } from '@guiker/components-core'
import { Chip } from '@guiker/components-library'

import { i18n, I18nextProvider, useTranslation } from '../../i18n'
import { getGradeColor } from '../../utils'

interface CreditReportOverallScoreChipProps {
  creditScore: number
}

const Content: React.FC<CreditReportOverallScoreChipProps> = ({ creditScore }) => {
  const { t } = useTranslation()

  const reportItem = calculateReportItems(creditScore)
  const score = `${reportItem?.grade} | ${creditScore}`
  const color = getGradeColor(reportItem?.grade)
  const label = t(`common-backgroundCheck:creditReport.score`, { score })

  return <Chip color={color as AnyColor}>{label}</Chip>
}

const CreditReportOverallScoreChip: React.FC<CreditReportOverallScoreChipProps> = (props) => (
  <I18nextProvider i18n={i18n}>
    <Content {...props} />
  </I18nextProvider>
)

export { CreditReportOverallScoreChip }
