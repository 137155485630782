import { routesBuilder } from '@guiker/shared-framework'

import { RentPaymentsPlanWithTenantInstalments } from '../../entity'
import { paths } from './paths'
import { accessControlClaimsSchema } from './schemas'

const withScope = routesBuilder<RentPaymentsPlanWithTenantInstalments>()({
  basePath: paths.rentPaymentsPlan.one.withScope(),
})

export const routes = {
  readOneRentPaymentsPlanByToken: withScope.buildGet({
    accessControlSchema: accessControlClaimsSchema,
  }),
}
