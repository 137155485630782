import React from 'react'

import { Divider, Flex, makeStyles, padding, PSmall, RouterLink } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { ItemGroup } from '../../utils'
import { SearchBarItemGroup } from './SearchBarItemGroup'

type SearchMenuProps = {
  groups: ItemGroup[]
  isLoading: boolean
  itemPerGroupLimit?: number
  onClose: () => unknown
  searchString: string
  showSeeAll?: boolean
  withAdornment?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    seeAllLink: {
      lineHeight: theme.typography.variants.body.lineHeight,
      padding: padding(0.5, 2, 0.5, 4.5),
      '&:hover': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[5],
      },
    },
  }),
  { name: 'SearchMenu' },
)

export const SearchMenu: React.FC<SearchMenuProps> = ({
  groups,
  isLoading,
  itemPerGroupLimit,
  onClose,
  searchString,
  showSeeAll = true,
  withAdornment,
}) => {
  const classes = useStyles()
  const { tOffice } = useT({ screenName: 'components' })

  return (
    <Flex flexDirection='column' my={1} mt={1}>
      {showSeeAll && searchString && (
        <>
          <RouterLink to={`/search?searchString=${searchString}`} onClick={onClose}>
            <PSmall color={60} mb={0} pl={4} mx={0.5} className={classes.seeAllLink}>
              {tOffice('seeAllResults', { searchString })}
            </PSmall>
          </RouterLink>
        </>
      )}
      {!isLoading && groups && (
        <>
          {showSeeAll && <Divider mt={1} mb={1} />}
          {groups?.map((group, index) => (
            <>
              <SearchBarItemGroup
                {...group}
                onClose={onClose}
                searchString={searchString}
                showSeeAll={showSeeAll}
                itemPerGroupLimit={itemPerGroupLimit}
                withAdornment={withAdornment}
              />
              {index < groups.length - 1 && <Divider mt={1} mb={1} />}
            </>
          ))}
        </>
      )}
    </Flex>
  )
}
