export enum SupportedPayInMethodType {
  /** Database persisted types  */
  CREDIT_CARD = 'CREDIT_CARD',
  DIRECT_DEBIT_PAD = 'DIRECT_DEBIT_PAD',
  DIRECT_DEBIT_EFT = 'DIRECT_DEBIT_EFT',
  DIRECT_DEBIT_ACH = 'DIRECT_DEBIT_ACH',
  FUNDING_ACCOUNT = 'FUNDING_ACCOUNT',

  /** Transient types  */
  OFFLINE = 'OFFLINE',
  STALE = 'STALE',
}
