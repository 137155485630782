import { yup } from '@guiker/yup-util'

import { ListingUtilities } from '../../entity'
import { amenitySchema } from './amenity-schema'

export const utilitiesSchema = yup.object<ListingUtilities>({
  hotWater: amenitySchema,
  electricity: amenitySchema,
  heating: amenitySchema,
  wifi: amenitySchema,
})
