export enum OccupationType {
  STUDENT = 'STUDENT',
  PROFESSIONAL = 'PROFESSIONAL',
}

export type Student = {
  annualSalary?: string
  studyProgram?: string
  school?: string
}

export type Professional = {
  annualSalary?: string
  linkedIn?: string
}

export type Occupation = {
  type: OccupationType
  student?: Student
  professional?: Professional
  title?: string
}
