import { Payer, RentPaymentsPlanWithTenantInstalments, TenantInstalment } from '@guiker/rent-payment-shared'

type ParsedTenantInstalment = TenantInstalment & { amount: number }

const parseTenantInstalments = ({
  rentPaymentsPlan,
  payer,
}: {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
}): ParsedTenantInstalment[] => {
  const { tenantInstalments, instalments, total } = rentPaymentsPlan
  const filtered = tenantInstalments.filter((t) => t.tenantUserId === payer.userId)

  return filtered.map((tenantInstalment) => {
    const instalmentAmount = instalments.find((i) => i.chargeDate === tenantInstalment.chargeDate)?.amount || 0
    const amount = Math.round((payer.amount * instalmentAmount) / total.amount)
    return { ...tenantInstalment, amount }
  })
}

export { ParsedTenantInstalment, parseTenantInstalments }
