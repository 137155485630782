import { Headers } from '@guiker/base-rest-client'
import { LegacyRestClient, Logger } from '@guiker/legacy-rest-client'

import { BookingPayInMethodVerficationPayload, LegacyPayInMethod } from '../entity'

const BASE_API_PATH = 'app_api'
const BASE_BOOKING_PATH = `${BASE_API_PATH}/bookings`
const BASE_USER_PATH = `${BASE_API_PATH}/users`

const endpoints = {
  upsertBookingContributions: (bookingId: string): string => `${BASE_BOOKING_PATH}/${bookingId}/contributions`,
  fetchOrCreateOfflinePaymentMethod: (userId: string): string => `${BASE_USER_PATH}/${userId}/payment_methods/offline`,
}

interface AppApiClientArgs {
  baseUrl: string
  logger?: Logger
  headers?: Headers
}

const LegacyAppApiClient = ({ baseUrl, logger, headers }: AppApiClientArgs) => {
  const baseClient = LegacyRestClient({ baseUrl, logger, headers })

  return {
    processPayInMethodVerfication: async (bookingId: string, payload: BookingPayInMethodVerficationPayload) => {
      return baseClient
        .post(endpoints.upsertBookingContributions(bookingId), payload)
        .then((r) => r.body.data)
        .catch((error) => {
          throw error
        })
    },
    fetchOrCreateOfflinePaymentMethod: async ({ userId }: { userId: string }): Promise<LegacyPayInMethod> => {
      return baseClient
        .post<LegacyPayInMethod>(endpoints.fetchOrCreateOfflinePaymentMethod(userId), null)
        .then((r) => r.body.data)
    },
  }
}

export { LegacyAppApiClient }
