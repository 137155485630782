type Options = {
  rejectIfErrors?: boolean
  sequential?: boolean
}

/**
 * @param promises - array of promises
 */
const promiseAll = async <T>(
  promises: Promise<T>[],
  options: Options = { rejectIfErrors: true, sequential: false },
): Promise<T[]> => {
  const errors: { name: string; message: string; stack: string }[] = []

  const performCall = (promise: Promise<T>) => {
    const wrapped = new Promise<T>((resolve) => resolve(promise))

    return wrapped.catch((err: Error) => {
      if (err instanceof Error) {
        const { name, message, stack } = err
        errors.push({ name, message, stack })
      } else {
        errors.push(err)
      }
      return undefined
    })
  }

  let result: T[] = []

  if (!options.sequential) {
    result = await Promise.all(promises.map(performCall))
  } else {
    for (const promise of promises) {
      const r = await performCall(promise)
      result.push(r)
    }
  }

  return new Promise((resolve, reject) => {
    if (errors.length > 0 && options.rejectIfErrors) {
      reject(errors)
    } else {
      resolve(result)
    }
  })
}

export { promiseAll }
