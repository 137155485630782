export enum EmploymentType {
  Employed = 'Employed',
  HomeMaker = 'HomeMaker',
  Retired = 'Retired',
  SelfEmployed = 'SelfEmployed',
  Student = 'Student',
  Unemployed = 'Unemployed',
}

export type Employment = {
  type: EmploymentType
  employerName?: string
  jobTitle?: string
}
