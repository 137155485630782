import { routesBuilder } from '@guiker/shared-framework'

import { InvestorProfile, InvestorProfileWithStockAllowance } from '../../entity'
import { paths } from './paths'
import { financialTaxIdSchema, updateInvestorProfileStepperSchema } from './schemas'

const { buildGet, buildPut } = routesBuilder<InvestorProfile>()({
  basePath: paths.investorProfile.one.base(),
})

const { buildGet: InvestorProfileStockAllowance } = routesBuilder<InvestorProfileWithStockAllowance>()({
  basePath: paths.investorProfile.one.base(),
})

export const investorProfileRoutes = {
  readOneInvestorProfile: buildGet(),
  readOneOrCreateInvestorProfileByUserId: buildGet({
    path: paths.investorProfile.one.withScope('user', ':userId'),
  }),
  getInvestorProfileAllowanceForStock: InvestorProfileStockAllowance({
    path: paths.investorProfile.one.withScope('stock', ':stockId'),
  }),
  updateInvestorProfileStepAboutYou: buildPut({
    path: '/about-you',
    payloadSchema: updateInvestorProfileStepperSchema.AboutYou,
  }),
  updateInvestorProfileStepEmploymentInformation: buildPut({
    path: '/employment-information',
    payloadSchema: updateInvestorProfileStepperSchema.EmploymentInformation,
  }),
  updateInvestorProfileStepFinancialProfile: buildPut({
    path: '/financial-profile',
    payloadSchema: updateInvestorProfileStepperSchema.FinancialProfile,
  }),
  updateInvestorProfileStepIdentityVerification: buildPut({
    path: '/identity-verification',
    payloadSchema: updateInvestorProfileStepperSchema.IdentityVerification,
  }),
  updateFinancialTaxIdNumber: buildPut({
    path: '/financial-profile/tax-id-number',
    payloadSchema: financialTaxIdSchema,
  }),
}
