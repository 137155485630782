import { DateTime } from '@guiker/shared-framework'

import {
  Payer,
  RentPaymentsPlanWithTenantInstalments,
  TenantInstalment,
  TenantInstalmentInvoiceEventStatus,
  TenantInstalmentStatus,
} from '../entity'

export type RentPaymentsData = TenantInstalment & {
  amount: number
  payout: {
    amount: number
    arrivalDate: string
  }
}

const buildRentPaymentsPayout = (args: {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  tenantInstalment: TenantInstalment
  payableAmount: number
}): RentPaymentsData['payout'] => {
  const { rentPaymentsPlan, tenantInstalment, payableAmount } = args

  const transactions = tenantInstalment.events ?? []

  const settledTransaction = transactions.find((t) => t.status === TenantInstalmentInvoiceEventStatus.SETTLED)
  const chargeTransaction = transactions.find((t) => t.status === TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED)
  const lastTransaction = transactions[transactions.length - 1]

  /** @todo handle FIXED AMOUNT type too */
  const serviceFeeAmount = Math.round(payableAmount * (rentPaymentsPlan.fee.value / 100))

  let arrivalDate: string
  let amount = payableAmount - serviceFeeAmount

  if (tenantInstalment.status === TenantInstalmentStatus.completed || settledTransaction) {
    const transaction = settledTransaction ?? lastTransaction
    arrivalDate = DateTime.fromISO(transaction.createdAt).toISODate()
    amount = transaction.transferAmount
  } else if (!!chargeTransaction && tenantInstalment.status === TenantInstalmentStatus.processing) {
    arrivalDate = DateTime.fromISO(chargeTransaction.createdAt).plus({ days: 5 }).toISODate()
    amount = chargeTransaction.transferAmount
  } else if (!!lastTransaction) {
    amount = lastTransaction.transferAmount
  }

  return {
    amount,
    arrivalDate,
  }
}

export const parseTenantInstalmentsWithPayout = ({
  rentPaymentsPlan,
  payer,
}: {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
}) => {
  const tenantInstalments = rentPaymentsPlan.tenantInstalments.filter((t) => {
    return t.tenantUserId === payer.userId
  })

  return tenantInstalments
    .map((tenantInstalment): RentPaymentsData => {
      const instalment = rentPaymentsPlan.instalments.find((i) => i.chargeDate === tenantInstalment.chargeDate)

      if (!instalment) return

      const payableAmount = tenantInstalment.processedWith
        ? tenantInstalment.processedWith.amount
        : Math.round((payer.amount * instalment.amount) / rentPaymentsPlan.total.amount)

      return {
        ...tenantInstalment,
        amount: payableAmount,
        payout: buildRentPaymentsPayout({ rentPaymentsPlan, tenantInstalment, payableAmount }),
      }
    })
    .filter((v) => !!v)
}
