import { yup } from '@guiker/yup-util'

import { Deal, LoanType } from '../../../entity'

export const seriesDealSchema = yup.object<Deal>({
  liabilities: yup.object({
    loan: yup.object({
      rate: yup.number().required(),
      amount: yup.number().required(),
      termsInYear: yup.number().required(),
      loanType: yup.string().oneOf(Object.values(LoanType)).required().default(LoanType.MORTGAGE),
    }),
  }),
  assets: yup.object({
    taxesAndFees: yup.number().required(),
    propertyValue: yup.number().required(),
    roundCashValue: yup.number().required(),
    cashValue: yup.number().required(),
  }),
})
