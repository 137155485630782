import { PayInProviderName } from './payment-provider-account'
import { SupportedCountries } from './supported-locations'
import { SupportedPayInMethodType } from './supported-payin-methods'

enum DisabledType {
  PLAID_TOKEN_EXPIRED = 'PLAID_TOKEN_EXPIRED',
  CREDIT_CARD_EXPIRED = 'CREDIT_CARD_EXPIRED',
  INSUFFICIENT_FUND_FLAGGED = 'INSUFFICIENT_FUND_FLAGGED',
  WRONG_ACCOUNT_INFO = 'WRONG_ACCOUNT_INFO',
  BANK_CANNOT_PROCESS = 'BANK_CANNOT_PROCESS',
}

import PayInMethodType = SupportedPayInMethodType
type CreditCardBrand = 'VISA' | 'MASTERCARD'

type Provider = {
  name: PayInProviderName
  uid: string
  token: string
}

type PayInMethod = {
  id?: string
  userId: string
  legacyId: number | null // to be deprecated in the future
  type: PayInMethodType
  region: SupportedCountries
  provider: Provider
  details: unknown
  disabled?: DisabledType
}

type PayInProfile = {
  accountHolderName: string
  birthDate?: string
  billingAddress?: {
    nickName?: string | null
    street2?: string | null
    streetNumber: string
    streetName: string
    city: string
    zip: string
    country: string
    state: string
  }
}

type CreditCardPayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.CREDIT_CARD
  details: {
    lastDigits: string
    expirationDate: string
    brand: CreditCardBrand
  }
}

type DirectDebitEFTPayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.DIRECT_DEBIT_EFT
  details: {
    institutionId: string
    transitNumber: string
    accountNumber: string
    accountHolderName: string
    paymentVerificationId?: string
  }
}

type DirectDebitPADPayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.DIRECT_DEBIT_PAD
  details: {
    bankName?: string
    institutionId: string
    transitNumber: string
    accountNumber: string
    mandateId: string

    plaidAccessToken?: string
    plaidItemId?: string
    plaidBankAccountId?: string
  }
}

type DirectDebitACHPayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.DIRECT_DEBIT_ACH
  details: {
    bankName?: string
    wireRouting?: string
    routingNumber: string
    accountNumber: string
    accountHolderName: string

    stripeBankAccountToken?: string
    plaidAccessToken?: string
    plaidItemId?: string
    plaidBankAccountId?: string
  }
}

type FundingAccountPayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.FUNDING_ACCOUNT
  details: {
    balance?: number
    fundingAccountId: string
  }
}

type OfflinePayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.OFFLINE
  details: {
    bankName?: string
  }
}

type StalePayInMethod = Omit<PayInMethod, 'details'> & {
  type: PayInMethodType.STALE
  details: {}
}

export {
  Provider,
  CreditCardBrand,
  PayInMethod,
  PayInMethodType,
  PayInProfile,
  CreditCardPayInMethod,
  DirectDebitEFTPayInMethod,
  DirectDebitPADPayInMethod,
  FundingAccountPayInMethod,
  OfflinePayInMethod,
  StalePayInMethod,
  DirectDebitACHPayInMethod,
  DisabledType,
}
