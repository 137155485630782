export const sanitizeFileName = (str: string) => str.replace(/[^a-zA-Z0-9-_+.]+/g, '')

export const testIfContainSpecialChars = (str: string) => {
  return /[#|,@!$%^&*()+=:-]/.test(str)
}

export const testIfContainOnlyAlphaChars = (str: string) => {
  const alphachar = new RegExp(`^[ A-Za-z_@\/!$.%^#&*+()-]+$`, 'g')
  return alphachar.test(str)
}

export const testIfContainsAlpha = (str: string) => {
  return /[a-zA-Z]/.test(str)
}

export const removeSpecialCharsAndWhiteSpaces = (val: string): string => {
  const regex = /[^0-9a-zA-Z]+/gi
  return val.replace(regex, '').toUpperCase()
}

export const removeAllWhiteSpaces = (str: string) => {
  return str?.replace(/ /g, '')
}

export const removeAllExtraWhiteSpaces = (str: string) => {
  return str?.replace(/\s+/g, ' ').trim()
}

export const replaceNonNumeric = (str: string) => {
  return str?.replace(/[^\d]/g, '')
}

export const testIfNumeric = (str: string) => {
  return /^\d+$/.test(str)
}

export const hasDuplicatedChar = (str: string, char: string) => {
  return str.indexOf(char) !== str.lastIndexOf(char)
}
