import { routes as financialStatementsRoutes } from './financial-statements'
import { routes as investorProfileRoutes } from './investor-profile-routes'
import { routes as listingRoutes } from './listing-routes'
import { routes as portfolioRoutes } from './portfolio-routes'
import { routes as stockOrderRoutes } from './stock-order-routes'
import { routes as stockRoutes } from './stock-routes'

export const routes = {
  ...investorProfileRoutes,
  ...listingRoutes,
  ...portfolioRoutes,
  ...stockOrderRoutes,
  ...stockRoutes,
  ...financialStatementsRoutes,
}
