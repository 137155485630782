import React from 'react'

import { CLDropdownProps as DropdownProps, Dropdown, makeStyles, toPx } from '@guiker/react-framework'
import { rebuildRentalOptions, TransformedRentalOption } from '@guiker/rental-listing-shared'

import { RentalOptionLabel } from './RentalOptionLabel'

type RentalOptionsDropdownProps = Omit<DropdownProps, 'options' | 'onChange'> & {
  defaultValue?: string
  rentalOptions: TransformedRentalOption[]
  setSelectedPriceOptionId: (value: React.SetStateAction<string>) => void
  setRentalOptions: (value: React.SetStateAction<TransformedRentalOption[]>) => void
}

const useStyle = makeStyles({
  icon: {
    '& .MuiSelect-icon': {
      top: toPx(21),
    },
  },
})

const RentalOptionsDropdown: React.FC<RentalOptionsDropdownProps> = ({
  label,
  maxWidth,
  name,
  defaultValue,
  rentalOptions,
  setSelectedPriceOptionId,
  setRentalOptions,
  ...otherProps
}) => {
  const classes = useStyle()

  const rebuildDropdownOptions = (value: string) => {
    const { filteredRentalOptions, selectedOption } = { ...rebuildRentalOptions(value, rentalOptions) }
    setSelectedPriceOptionId(selectedOption?.value)
    setRentalOptions(filteredRentalOptions)
  }

  if (!rentalOptions?.length) {
    return <></>
  }

  return (
    <Dropdown
      label={label}
      maxWidth={maxWidth}
      name={name}
      defaultValue={defaultValue}
      className={classes.icon}
      options={rentalOptions.map((rentalOption) => ({
        ...rentalOption,
        label: <RentalOptionLabel rentalOption={rentalOption} />,
      }))}
      onChange={(e) => rentalOptions?.length > 1 && rebuildDropdownOptions(e.target.value)}
      {...otherProps}
    />
  )
}

export { RentalOptionsDropdown }
