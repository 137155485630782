import { TypeOf, yup } from '@guiker/yup-util'

import { CountryCode } from '../../../entity'

export const createBackgroundCheckPayloadValidator = yup.object({
  requester: yup
    .object({
      userId: yup.string().required(),
      emailAddress: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().nullable(),
    })
    .nullable(),
  requestSoftcheck: yup.boolean(),
  consent: yup.boolean(),
  targetId: yup.string(),
  requestCreditReport: yup.boolean().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  provinceState: yup.string().required(),
  postalCode: yup.string().required(),
  country: yup.string().required(),
  dateOfBirth: yup.string().required(),
  ssn: yup.string().nullable(),
  email: yup.string().email().required(),
  sanitize: yup.boolean().nullable().default(true),
  countryCode: yup.string().oneOf([CountryCode.CA, CountryCode.US]).required(),
})

export type CreateBackgroundCheckPayloadValidator = TypeOf<typeof createBackgroundCheckPayloadValidator>
