import { BookingStatus, ParticipantType } from '@guiker/booking-shared'

export const searchOptions = {
  statusOptions: [
    { label: 'All', value: 'ALL' },
    { label: BookingStatus.STARTED, value: BookingStatus.STARTED },
    { label: BookingStatus.CONFIRM_REQUESTED, value: BookingStatus.CONFIRM_REQUESTED },
    {
      label: BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION,
      value: BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION,
    },
    { label: BookingStatus.EXPIRED, value: BookingStatus.EXPIRED },
    { label: BookingStatus.BOOKED, value: BookingStatus.BOOKED },
  ],
  participantOptions: [
    { label: 'All', value: 'ALL' },
    { label: ParticipantType.APPLICANT, value: ParticipantType.APPLICANT },
    { label: ParticipantType.UNIT_MANAGER, value: ParticipantType.UNIT_MANAGER },
  ],
}
