import { yup } from '@guiker/yup-util'

export const invitedUnitManagerClaimsSchema = yup.object({
  bookingId: yup
    .string()
    .required()
    .when('$bookingId', (bookingId: string, schema: yup.StringSchema) =>
      bookingId ? schema.test('bookingId', 'mismatch', (id: string) => id === bookingId) : schema,
    )
    .required(),
  userId: yup.string().required(),
  emailAddress: yup.string().required(),
})

export type InvitedUnitManagerClaimsSchema = yup.InferType<typeof invitedUnitManagerClaimsSchema>
