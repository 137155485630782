import { yup } from '@guiker/shared-framework'

export type GetTotalBalanceSchema = {
  interval?: number
  entries?: number
}

export const getTotalBalanceSchema = yup.object<GetTotalBalanceSchema>({
  entries: yup
    .number()
    .positive()
    .notRequired()
    .transform((v) => (!!v ? v : undefined)),
  interval: yup
    .number()
    .positive()
    .notRequired()
    .transform((v) => (!!v ? v : undefined)),
})
