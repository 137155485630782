import React from 'react'

import { BaseAssetContainer, BaseAssetContainerProps } from './BaseAssetContainer'
import { RHFAssetContainer } from './RHFAssetContainer'

export type AssetContainerProps = BaseAssetContainerProps

export const AssetContainer: React.FC<AssetContainerProps> = (props) => {
  if (!props.readOnly) {
    return <RHFAssetContainer {...props} />
  } else {
    return <BaseAssetContainer {...props} />
  }
}
