import { paginated, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { User } from '../../entity'
import { readAllUsersQueryParamsSchema, userIdNested } from '../schemas'
import { paths } from './paths'
import { updateUserPayloadSchema } from './schemas'

export const routes = {
  readOneUser: {
    path: paths.user.all.withId(':userId'),
    method: HttpMethod.GET,
    authenticated: true,
    paginated: false,
    pathParamsValidator: validatorBuilder.buildParamsValidator(userIdNested),
    responseValidator: validatorBuilder.buildResponseValidator<User>(),
  },
  readAllUsers: {
    path: paths.user.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    paginated,
    responseValidator: validatorBuilder.buildResponseValidator<User[]>(),
    queryParamsValidator: validatorBuilder.buildParamsValidator(readAllUsersQueryParamsSchema),
  },
  updateOneUser: {
    path: paths.user.all.withId(':userId'),
    method: HttpMethod.PUT,
    authenticated: true,
    paginated: false,
    pathParamsValidator: validatorBuilder.buildParamsValidator(userIdNested),
    payloadValidator: validatorBuilder.buildPayloadValidator(updateUserPayloadSchema),
  },
}
