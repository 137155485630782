import { TypeOf, yup } from '@guiker/yup-util'

import { Quebec } from '../../../entity'

export const rent = yup.object<Omit<Quebec.Rent, 'completed'>>({
  rentAmount: yup.number().required(),
  rentFrequency: yup.mixed<Quebec.RentFrequency>().oneOf(Object.values(Quebec.RentFrequency)).required(),
  serviceCharge: yup.number().required(),
  isLesseeEligibleForSubsidy: yup.boolean().required(),
  rentSubsidy: yup.string().when('isLesseeEligibleForSubsidy', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null),
  }),
  firstPaymentDate: yup.string().required(),
  paymentDate: yup.mixed<Quebec.PaymentDates>().oneOf(Object.values(Quebec.PaymentDates)),
  paymentDateOther: yup.string().when('paymentDate', (paymentDate: Quebec.PaymentDates, schema: yup.StringSchema) => {
    if (paymentDate !== Quebec.PaymentDates.other) {
      return schema.nullable().transform(() => '')
    } else {
      return schema
    }
  }),
  paymentMethod: yup.mixed<Quebec.PaymentMethod>().oneOf(Object.values(Quebec.PaymentMethod)),
  otherPayment: yup.string().when('paymentMethod', (paymentMethod: Quebec.PaymentMethod, schema: yup.StringSchema) => {
    if (paymentMethod !== Quebec.PaymentMethod.other) {
      return schema.nullable().transform(() => '')
    } else {
      return schema
    }
  }),
  lesseeAgreeOnPostDatedCheque: yup.boolean().nullable(),
  rentPayableAt: yup.string(),
})

export type Rent = TypeOf<typeof rent>
