import { scopeSchema } from '@guiker/access-control-shared'
import { yup } from '@guiker/yup-util'

import { BackgroundCheckRoleNames } from '../../../permissions'

export const backgroundCheckAccessControlClaimsSchema = yup.object({
  scope: scopeSchema,
  userId: yup.string(),
  emailAddress: yup.string().required(),
  acl: yup
    .object({
      backgroundCheck: yup
        .object({
          roles: yup
            .array()
            .of(yup.string().oneOf(Object.values(BackgroundCheckRoleNames)))
            .required(),
          references: yup
            .array()
            .of(yup.string())
            .when('$backgroundCheckId', (backgroundCheckId: string, schema: yup.StringSchema) =>
              backgroundCheckId
                ? schema.test('backgroundCheckId', 'mismatch scope Id', (references: string[]) =>
                    references.includes(backgroundCheckId),
                  )
                : schema,
            )
            .required(),
        })
        .required(),
    })
    .required(),
})

export type BackgroundCheckAccessControlClaimsSchema = yup.InferType<typeof backgroundCheckAccessControlClaimsSchema>
