import React from 'react'

import '@guiker/fonts'

import { createRoot } from 'react-dom/client'

import { ClientApp } from './ClientApp'

createRoot(document.getElementById('root')!).render(<ClientApp />)

export { ClientApp }
