import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useQuery } from '@guiker/react-query'
import { sortTaskByRanking, Status, Task } from '@guiker/task-shared'
import { useWebsocketListener } from '@guiker/use-websocket'

export type BaseTaskContext = {
  tasks: Task[]
  getFilteredTasks: (args?: { status?: Status[]; context?: string[] }) => Task[]
}

type Props = React.PropsWithChildren & {
  ContextProvider: React.Context<BaseTaskContext>['Provider']
  apiClient: {
    readAllTasks: (params: {
      queryParams: {
        page: number
        perPage: number
        sortOrder: -1 | 1
        sort: string
      }
    }) => Promise<{ data: Task[] }>
  }
}

export const TaskContextProvider = ({ apiClient, children, ContextProvider }: Props) => {
  const { user } = useAuthenticationContext()

  const { data, refetch: refetchTasks } = useQuery(
    'tasks',
    () =>
      apiClient
        .readAllTasks({
          queryParams: {
            page: 1,
            perPage: 100,
            sortOrder: -1,
            sort: 'createdAt',
          },
        })
        .then((res) => {
          return sortTaskByRanking(res?.data)
        }),
    {
      retry: 1,
      enabled: !!user,
    },
  )

  useWebsocketListener({
    pattern: ['TASK'],
    callback: async ({}: { entityId: string }) => {
      await refetchTasks()
    },
  })

  const getFilteredTasks = ({ status = [Status.CREATED], context }: { status?: Status[]; context?: string[] }) => {
    return data?.filter((task) => {
      return status.includes(task?.status) && (!context || context.includes(task.context))
    })
  }

  const value = {
    tasks: data,
    refetchTasks,
    getFilteredTasks,
  }

  return <ContextProvider value={value}>{children}</ContextProvider>
}
