import { TypeOf, yup } from '@guiker/yup-util'

export const replaceListingUnitPayloadSchema = yup.object({
  destination: yup
    .object({
      listingId: yup.string().required(),
      configurationId: yup.string().required(),
    })
    .required(),
})

export type ReplaceListingUnitPayloadSchema = TypeOf<typeof replaceListingUnitPayloadSchema>
