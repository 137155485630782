import { yup } from '@guiker/yup-util'

export const byScope = yup.object({
  scopeType: yup.string().required(),
  scopeId: yup.string().required(),
})

export const byScopeType = yup.object({
  scopeType: yup.string().required(),
})
