export const stringToNumber = (value: string, option: { ignoreNaN?: boolean } = { ignoreNaN: true }) => {
  if (!value) return undefined
  const parsed = Number(value)

  if (option.ignoreNaN) {
    return isNaN(parsed) ? undefined : parsed
  } else {
    return parsed
  }
}
