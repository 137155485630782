import React from 'react'

import { Cleanliness, HangingWithFriends, Interest, PetConsent, SleepSchedule } from '@guiker/base-entity'
import {
  Autocomplete,
  Checkbox,
  Dropdown,
  FormSection,
  FormSubSection,
  Link,
  Note,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { Gender, yup } from '@guiker/shared-framework'
import { ReferralType, TenantApplication } from '@guiker/tenant-application-shared'

type RoommateProfileFormContentProps = {
  tenantApplication?: TenantApplication
  schema?: yup.ObjectSchema
  readOnly?: boolean
  editRoute?: string
}

const RoommateProfileFormContent: React.FC<RoommateProfileFormContentProps> = ({
  tenantApplication,
  schema,
  readOnly = false,
  editRoute,
}) => {
  const namespaces = ['common', 'main-tenantApplication']
  const tPrefix = 'main-tenantApplication:roommateProfile'
  const { t } = useTranslation(namespaces)

  const referralType = useWatch<TenantApplication, 'roommateProfile.referral.type'>({
    name: 'roommateProfile.referral.type',
    defaultValue: tenantApplication?.roommateProfile?.referral?.type,
  })

  const genderClassification = useWatch<TenantApplication, 'profile.gender.classification'>({
    name: 'profile.gender.classification',
    defaultValue: tenantApplication?.profile?.gender?.classification,
  })

  const inputProps = useGetInputProps({
    namespaces,
    formPrefix: 'roommateProfile',
    tPrefix: 'main-tenantApplication:roommateProfile',
    defaultValue: tenantApplication,
    schema,
    readOnly,
  })

  return (
    <FormSection
      title={t('main-tenantApplication:roommateProfile.title')}
      topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
    >
      {!readOnly && <Note statusColor={'alabaster'}>{t(`${tPrefix}.subtitle`)}</Note>}
      <Autocomplete
        name={'roommateProfile.sleepSchedule'}
        required
        readOnly={readOnly}
        type='tag'
        options={Object.values(SleepSchedule)}
        label={t('main-tenantApplication:roommateProfile.sleepSchedule.label')}
        defaultValue={tenantApplication?.roommateProfile?.sleepSchedule}
        getOptionLabel={(value: string) => {
          return t(`main-tenantApplication:roommateProfile.sleepSchedule.${value}`)
        }}
      />

      <TwoColumnsGridLayout>
        <Dropdown
          {...inputProps('cleanliness')}
          label={t('main-tenantApplication:roommateProfile.cleanliness.label')}
          options={Object.values(Cleanliness).map((cleanliness) => ({
            label: t(`main-tenantApplication:roommateProfile.cleanliness.${cleanliness}`),
            value: cleanliness,
          }))}
          fullWidth
        />
        <Dropdown
          {...inputProps('livingWithPets')}
          label={t('main-tenantApplication:roommateProfile.livingWithPets.label')}
          options={Object.values(PetConsent).map((consent) => ({
            label: t(`main-tenantApplication:roommateProfile.livingWithPets.${consent}`),
            value: consent,
          }))}
          fullWidth
        />
        <Dropdown
          {...inputProps('friendLevel')}
          label={t('main-tenantApplication:roommateProfile.friendLevel.label')}
          options={Object.values(HangingWithFriends).map((friendliness) => ({
            label: t(`main-tenantApplication:roommateProfile.friendLevel.${friendliness}`),
            value: friendliness,
          }))}
          fullWidth
        />
        <Dropdown
          name='profile.gender.classification'
          required
          label={t('main-tenantApplication:gender.question')}
          helperText={readOnly ? undefined : t('main-tenantApplication:gender.helperText')}
          options={Object.values(Gender).map((identity) => ({
            label: t(`main-tenantApplication:gender.${identity}`),
            value: identity,
          }))}
          defaultValue={tenantApplication?.profile?.gender?.classification}
          fullWidth
          readOnly={readOnly}
        />
        {genderClassification === Gender.OTHER ? (
          <TextField
            name='profile.gender.other'
            required
            label={t('main-tenantApplication:gender.other')}
            defaultValue={tenantApplication?.profile?.gender?.other}
            readOnly={readOnly}
          />
        ) : (
          <div />
        )}
      </TwoColumnsGridLayout>
      <Autocomplete
        required
        readOnly={readOnly}
        type='tag'
        name='roommateProfile.interests'
        helperText={readOnly ? undefined : t(`${tPrefix}.interests.helperText`)}
        label={t(`${tPrefix}.interests.title`)}
        options={Object.values(Interest)}
        defaultValue={tenantApplication?.roommateProfile?.interests}
        getOptionLabel={(value: string) => {
          return t(`${tPrefix}.interests.${value}`)
        }}
      />
      <TextField
        multiline
        rows={readOnly ? undefined : 5}
        placeholder={readOnly ? undefined : t('main-tenantApplication:roommateProfile.personalDescriptionMin')}
        helperText={readOnly ? undefined : t('main-tenantApplication:roommateProfile.personalDescriptionHelperText')}
        {...inputProps('personalDescription')}
      />
      <TwoColumnsGridLayout>
        <Dropdown
          {...inputProps('referral.type')}
          label={t('main-tenantApplication:roommateProfile.referral.label')}
          options={Object.values(ReferralType).map((type) => ({
            label: t(`main-tenantApplication:roommateProfile.referral.type.${type}`),
            value: type,
          }))}
          fullWidth
        />
        {referralType === ReferralType.OTHER ? (
          <TextField
            {...inputProps('referral.other')}
            label={t(`main-tenantApplication:roommateProfile.referral.other`)}
          />
        ) : (
          <div />
        )}
      </TwoColumnsGridLayout>
      <FormSubSection title={t(`main-tenantApplication:roommateProfile.acknowledgement`)}>
        <Checkbox
          name={'roommateProfile.minimumStayConsent.isConsentGiven'}
          defaultValue={tenantApplication?.roommateProfile?.minimumStayConsent?.isConsentGiven}
          readOnly={readOnly}
          required
          label={t(`main-tenantApplication:roommateProfile.minimumStayConsent`)}
          labelPlacement='end'
          maxWidth={'100%'}
        />
      </FormSubSection>
    </FormSection>
  )
}

export { RoommateProfileFormContent }
