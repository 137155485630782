import { Scope, User } from '@guiker/base-entity'
import { EnvelopeStatus, ParticipantDocument } from '@guiker/document-shared'

export type LeaseContract = {
  id: string
  fee?: string
  envelopeId: string
  status: EnvelopeStatus
  scope: Scope
  participants: DocumentParticipant[]
  createdAt: string
  updatedAt: string
  documents?: AdditionalDocument[]
  signedWith?: SignedWith
  sender?: User
}

export type SignedWith = {
  type: SignedWithType
  id: string
}

export enum SignedWithType {
  LISTING = 'listing',
}

export const contractSigner = 'Owner'

export type AdditionalDocument = {
  uid: string
  name: string
}

export type DocumentParticipant = {
  userId?: string
  documents?: ParticipantDocument[]
  firstName?: string
  lastName?: string
  emailAddress?: string
}

export const ServiceFeeTabName = 'ServiceFee'

export enum ScopeType {
  USER = 'user',
}
