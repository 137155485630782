import { yup } from '@guiker/yup-util'

export const getUrlPayloadValidator = yup.object<{
  redirectUrl: string
  disableSend?: boolean
  userId?: string
}>({
  redirectUrl: yup.string().required(),
  disableSend: yup.boolean().nullable(),
  userId: yup.string().nullable(),
})
