import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { FundingAccountJournalEntry, FundingAccountJournalEntrySource } from '../../entity'
import { baseEvent } from '../base'

export const definition = generateEventsDefinition({
  ...baseEvent,
  entity: 'FUNDING_ACCOUNT_JOURNAL_ENTRY',
  data: null as FundingAccountJournalEntry,
  eventTypes: {
    SUCCEED: 'SUCCEED',
    FAILED: {
      type: 'FAILED',
      eventData: {
        source: null as FundingAccountJournalEntrySource,
      },
    },
  } as const,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
