import { TypeOf, yup } from '@guiker/shared-framework'

import { verificationSchema } from './verification'

export const updateBankInformationInvestmentSchema = yup.object({
  bankInformation: yup
    .object({
      verification: verificationSchema(true).required(),
    })
    .required(),
})

export type UpdateBankInformationInvestmentSchema = TypeOf<typeof updateBankInformationInvestmentSchema>
