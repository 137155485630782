import { Event } from '@guiker/event'
import { HttpMethod } from '@guiker/http'

import { Booking } from '../../entity'
import { paths } from './paths'
import {
  bookingIdApplicantUserIdPathParamsSchema,
  bookingIdSchema,
  updateBookingApplicationStatusByTokenPayloadSchema,
} from './schemas'

const responseValidator = (payload: unknown) => Promise.resolve(payload as Booking)

export const routes = {
  readOneByInvitedUnitManagerToken: {
    path: `${paths.booking.one.withId(':bookingId')}`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (params: unknown) => bookingIdSchema.validate(params),
  },
  updateBookingApplicationStatusByToken: {
    path: `${paths.booking.one.withId(':bookingId')}/applicants/:applicantUserId/update-application-status`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (params: unknown) => bookingIdApplicantUserIdPathParamsSchema.validate(params),
    payloadValidator: (payload: unknown) => updateBookingApplicationStatusByTokenPayloadSchema.validate(payload),
  },
  readAllApplicantApplicationEventsByToken: {
    path: `${paths.booking.one.withId(':bookingId')}/applicants/:applicantUserId/events`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Event<Booking>[]),
    pathParamsValidator: (payload: unknown) => bookingIdApplicantUserIdPathParamsSchema.validate(payload),
  },
}
