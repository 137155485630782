import { CheckboxTab, TextTab } from '@guiker/docusign'
import { yup } from '@guiker/yup-util'

const textTabSchema = yup
  .object({
    value: yup.string().nullable(),
    tabLabel: yup.string().required(),
  })
  .nullable()

const checkboxTabSchema = yup
  .object({
    selected: yup.boolean().nullable(),
    tabLabel: yup.string().nullable(),
  })
  .nullable()

export const updateEnvelopePrefillTabsPayload = yup
  .object({
    documentId: yup.string().required(),
    prefillTabs: yup
      .object({
        textTabs: yup.array<TextTab>(textTabSchema).nullable(),
        checkboxTabs: yup.array<CheckboxTab>(checkboxTabSchema).nullable(),
      })
      .nullable(),
  })
  .required()

export type UpdateEnvelopePrefillTabsPayload = yup.InferType<typeof updateEnvelopePrefillTabsPayload>
