import { TypeOf, yup } from '@guiker/yup-util'

import { signature } from '../../schemas'
import { leaseEmailUniquenessSchema } from '../shared'

export const guarantorSignature = yup.object({
  fullName: yup.string(),
  emailAddress: leaseEmailUniquenessSchema,
  signature: signature,
})

export type GuarantorSignature = TypeOf<typeof guarantorSignature>
