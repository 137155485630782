import { compareDate } from '@guiker/date'

import { Booking } from '../booking'
import { BookingStatus } from '../booking-status'

const BEING_CONFIRMED_STATUS = [BookingStatus.CONFIRM_REQUESTED, BookingStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION]
const FAILED_STATUS = [BookingStatus.WITHDRAWN, BookingStatus.REJECTED, BookingStatus.EXPIRED]
const SUCCESS_STATUS = [BookingStatus.BOOKED]
const IN_PROGRESS_STATUS = [BookingStatus.STARTED]
const INACTIVE_STATUS = [...FAILED_STATUS, ...SUCCESS_STATUS]

export const isBookingBeingConfirmed = (booking: Pick<Booking, 'status'>) =>
  BEING_CONFIRMED_STATUS.includes(booking.status)
export const isBookingFailed = (booking: Pick<Booking, 'status'>) => FAILED_STATUS.includes(booking.status)
export const isBookingInProgress = (booking: Pick<Booking, 'status'>) => IN_PROGRESS_STATUS.includes(booking.status)
export const isBookingSuccess = (booking: Pick<Booking, 'status'>) => SUCCESS_STATUS.includes(booking.status)
export const isBookingInactive = (booking: Pick<Booking, 'status'>) => INACTIVE_STATUS.includes(booking.status)
export const isBookingEnded = (booking: Pick<Booking, 'info'>) =>
  compareDate(new Date()).isAfter(booking.info.period.to)
