import React from 'react'

import { clsx, DropdownIcon, makeStyles, PBold, PSmall, theme } from '@guiker/react-framework'

type PersonHeaderProps = React.PropsWithChildren & {
  count: number
  collapsed: boolean
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    width: 12,
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  iconCollapsed: {
    transform: 'rotate(0)',
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
  inlineBlock: {
    display: 'inline-block',
  },
})

const ParticipantHeader: React.FC<PersonHeaderProps> = ({ count, collapsed = true, onClick, children }) => {
  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.container}>
      <div>
        <PBold mb={0} className={classes.inlineBlock}>
          {children?.toString()}&nbsp;
        </PBold>
        <PSmall component='span' className={classes.inlineBlock}>
          ({count})
        </PSmall>
      </div>
      <DropdownIcon className={clsx(classes.icon, collapsed ? classes.iconCollapsed : classes.iconExpanded)} />
    </div>
  )
}

export { ParticipantHeader }
