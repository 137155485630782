import React from 'react'

import { Flex, makeStyles, padding, PSmall, RouterLink } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { ItemGroup } from '../../utils'
import { SearchBarItem } from './SearchBarItem'

export type SearchBarItemGroupProps = ItemGroup & {
  itemPerGroupLimit?: number
  onClose: () => unknown
  searchString: string
  showSeeAll?: boolean
  withAdornment?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    item: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    header: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    seeAllLink: {
      lineHeight: theme.typography.variants.body.lineHeight,
      padding: padding(0.5, 2, 0.5, 4.5),
      '&:hover': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[5],
      },
    },
  }),
  { name: 'SearchBarItemGroup' },
)

export const SearchBarItemGroup: React.FC<SearchBarItemGroupProps> = ({
  itemPerGroupLimit = 5,
  items,
  label,
  onClose,
  searchString,
  seeAllResultsUrl,
  showSeeAll = true,
  StartAdornment,
  total,
  withAdornment = true,
}) => {
  const classes = useStyles()
  const { tOffice } = useT({ screenName: 'components' })

  return (
    <Flex flexDirection='column' gap={1} mt={1}>
      <PSmall color={60} mb={0} className={classes.header}>
        {label}
      </PSmall>
      <Flex flexDirection='column' className={classes.item}>
        {items.slice(0, itemPerGroupLimit).map((item) => (
          <SearchBarItem StartAdornment={withAdornment && StartAdornment} onClose={onClose} {...item} />
        ))}
      </Flex>
      {showSeeAll && (
        <RouterLink to={seeAllResultsUrl || `/search?searchString=${searchString}`} onClick={onClose}>
          <PSmall color={60} mb={0} mx={0.5} className={classes.seeAllLink}>
            {tOffice('seeAllResultsWithCount', { count: total })}
          </PSmall>
        </RouterLink>
      )}
    </Flex>
  )
}
