import React from 'react'

import { Booking } from '@guiker/booking-shared'
import { Flex, PBold, PSmall } from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type BookingRentProps = { booking: Pick<Booking, 'info'> }

export const BookingRent: React.FC<BookingRentProps> = ({ booking }) => {
  const { tBase } = useT({})
  const { currency, frequency, amount } = booking.info.price

  const frequencyStr =
    frequency.toUpperCase() === 'MONTHLY'
      ? `/${tBase('duration.month')}`
      : frequency.toUpperCase() === 'WEEKLY'
      ? `/${tBase('duration.week')}`
      : ''

  return (
    <Flex gap={1} alignItems='baseline'>
      <PBold mb={0}>
        <PSmall mb={0} component='span'>
          {money.currency[currency].symbol}{' '}
        </PSmall>
        {money.fromAmount(amount, money.currency[currency]).toString({ currencySymbol: false, noDecimal: false })}
      </PBold>

      <PSmall mb={0} color={60}>
        {frequencyStr}
      </PSmall>
    </Flex>
  )
}
