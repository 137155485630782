import { TypeOf, yup } from '@guiker/yup-util'

export const changePasswordSchema = yup.object({
  password: yup
    .string()
    .required()
    .when('$stage', (stage: string, schema: yup.StringSchema) =>
      stage === 'production'
        ? schema.test('authentication.password', 'password does not meet requirements', (password: string) => {
            const regexPattern = `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&_-])[0-9a-zA-Z@$!%*?&_-]{8,32}$`
            const regex = new RegExp(regexPattern)
            return regex.test(password)
          })
        : schema,
    )
    .when('currentPassword', (currentPassword: string, schema: yup.StringSchema) =>
      currentPassword
        ? schema.test(
            'authentication.uniqueNewPassword',
            'new password cannot be the same as old password',
            (password: string) => password !== currentPassword,
          )
        : schema,
    ),
  currentPassword: yup.string().min(8).max(32).required(),
  confirmPassword: yup
    .string()
    .required()
    .when('password', (password: string, schema: yup.StringSchema) =>
      password
        ? schema.test(
            'authentication.confirmPassword',
            'confirm password does not match password',
            (confirmPassword: string) => confirmPassword === password,
          )
        : schema,
    ),
})

export type ChangePasswordSchema = TypeOf<typeof changePasswordSchema>
