import { AuthLevel, buildPaths } from '@guiker/api-definition'

export const paths = buildPaths(
  {
    contract: {
      propSharing: {
        stockOrder: {},
      },
    },
    blankContract: {},
  },
  AuthLevel.ADMIN,
)
