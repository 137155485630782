import { routesBuilder } from '@guiker/shared-framework'

import { FinancialStatements } from '../../entity'
import { paths } from './paths'

const { buildGet } = routesBuilder<FinancialStatements>()({
  basePath: paths.stocks.withId.financialStatements.path,
})

export const routes = {
  readLatestFinancialStatementsByStockId: buildGet({}),
}
