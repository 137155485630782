import React from 'react'

import { DataAndMeta } from '@guiker/api-definition'
import { PageSection1 } from '@guiker/components-library'
import { Paginate } from '@guiker/paginate'
import { Invoice, PayInMethod, SupportedPayInMethodType } from '@guiker/payment-shared'

import { PayInMethodLabel } from '../PayInMethodLabel'
import { AllInvoicesByPaymentMethodTable } from './components'

type PaymentMethodProps = {
  paymentMethod: PayInMethod
  fetcher: (paginate: Paginate) => Promise<DataAndMeta<Invoice[]>>
  topActions?: React.ReactNode
}

export const ViewPaymentMethod: React.FC<PaymentMethodProps> = ({ paymentMethod, fetcher, topActions }) => {
  return (
    <PageSection1 hasDivider={false} topActions={topActions} title={<PayInMethodLabel payInMethod={paymentMethod} />}>
      {!!paymentMethod.legacyId ? (
        <AllInvoicesByPaymentMethodTable payInMethod={paymentMethod} type='RENTALS' fetcher={fetcher} />
      ) : undefined}
      {!paymentMethod.legacyId || paymentMethod.type === SupportedPayInMethodType.CREDIT_CARD ? (
        <AllInvoicesByPaymentMethodTable payInMethod={paymentMethod} type='PAYMENTS' fetcher={fetcher} />
      ) : undefined}
    </PageSection1>
  )
}
