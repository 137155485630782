import React from 'react'

import { Box } from '@guiker/components-library'
import { CallToActionEvent } from '@guiker/react-google-tag-manager'

import { useDataTrackingContext } from './use-data-tracking-context'

type Props = React.PropsWithChildren & {
  key: CallToActionEvent['ctaText']
  position?: CallToActionEvent['ctaPosition']
}

const DataTrackingCTA: React.FC<Props> = ({ children, ...props }) => {
  const context = useDataTrackingContext()
  const { key: ctaText, position: ctaPosition } = props

  const handleClick = () => {
    context?.sendEvent({ event: 'ctaClick', ctaText, ctaPosition })
  }

  return <Box onClick={handleClick}>{children}</Box>
}

export { DataTrackingCTA }
