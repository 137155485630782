import React from 'react'

import {
  Box,
  BusinessContractIcon,
  BusinessTeamIcon,
  Display2,
  Display3,
  DisplayBody,
  DisplayBodyBig,
  Flex,
  Grid,
  HeroLayout,
  makeStyles,
  PageLayout,
  PageMetaTags,
  TargetCenterIcon,
  theme,
  useLayoutContext,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { StaticPageContent } from '../../components'
import { useTranslation } from '../../hooks'

const useStyle = makeStyles({
  investorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  investorsGrid: {
    maxWidth: 900,
    alignItems: 'center',
    justifyContent: 'center',
  },
  investors: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 140,
    '& > img': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
  },
  featureContent: {
    display: 'flex',
    gap: theme.spacing(6),
    flexDirection: ({ isAtMostTablette }: { isAtMostTablette: boolean }) => (isAtMostTablette ? 'column' : 'row'),
    alignItems: ({ isAtMostTablette }: { isAtMostTablette: boolean }) => (isAtMostTablette ? 'center' : 'start'),
    justifyContent: ({ isAtMostTablette }: { isAtMostTablette: boolean }) => (isAtMostTablette ? 'center' : 'start'),
  },
  featureText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: ({ isAtMostTablette }: { isAtMostTablette: boolean }) => (isAtMostTablette ? 'center' : 'left'),
  },
})

const About: React.FC = () => {
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('about-us')

  const { t } = useTranslation(['webapp'])
  const { isAtMostTablette } = useLayoutContext()
  const classes = useStyle({ isAtMostTablette })

  return (
    <>
      <PageMetaTags subtitle={t('about.seo.subtitle')} description={t('about.seo.description')} />
      <PageLayout noPaddingTop>
        <HeroLayout
          left={
            <Flex height={400} flexDirection='column' justifyContent='center'>
              <Display2>{t('about.subHeader')}</Display2>
            </Flex>
          }
          right={
            <img
              src='https://s3.amazonaws.com/guiker.resources/assets/static-pages/home/v2/invest/hero.webp'
              width={500}
            />
          }
        />
        <Flex
          flexDirection='column'
          pt={10}
          gap={isAtMostTablette ? 8 : 16}
          alignItems={isAtMostTablette ? 'flex-start' : 'center'}
        >
          <Box className={classes.investorsContainer}>
            <Display3>{t('about.ourInvestorsHeader')}</Display3>
            <Grid container spacing={3} className={classes.investorsGrid}>
              {assets.investors.map((investor, index) => (
                <Grid item key={index} className={classes.investors}>
                  <img src={investor} alt='investors' />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Flex flexDirection='column' gap={10} width='100%'>
            <StaticPageContent asset={assets?.image1} direction='left'>
              <Display3>{t('about.whatWeDoHeader')}</Display3>
              <DisplayBodyBig>{t('about.whatWeDoSubtext')}</DisplayBodyBig>
            </StaticPageContent>

            <StaticPageContent asset={assets?.image2} direction='right'>
              <Display3>{t('about.ourCraftHeader')}</Display3>
              <DisplayBodyBig>{t('about.ourCraftSubtext')}</DisplayBodyBig>
            </StaticPageContent>
          </Flex>

          <Flex flexDirection='column' gap={8} maxWidth={800}>
            <div className={classes.featureContent}>
              <div>
                <TargetCenterIcon color='primary' />
              </div>
              <div className={classes.featureText}>
                <Display3>{t('about.feature1Header')}</Display3>
                <DisplayBody>{t('about.feature1Subtext')}</DisplayBody>
              </div>
            </div>
            <div className={classes.featureContent}>
              <div>
                <BusinessContractIcon color='primary' />
              </div>
              <div className={classes.featureText}>
                <Display3>{t('about.feature2Header')}</Display3>
                <DisplayBody>{t('about.feature2Subtext')}</DisplayBody>
              </div>
            </div>
            <div className={classes.featureContent}>
              <div>
                <BusinessTeamIcon color='primary' />
              </div>
              <div className={classes.featureText}>
                <Display3>{t('about.feature3Header')}</Display3>
                <DisplayBody>{t('about.feature3Subtext')}</DisplayBody>
              </div>
            </div>
          </Flex>
        </Flex>
      </PageLayout>
    </>
  )
}

export { About }
