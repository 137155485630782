import React from 'react'

import { Flex, P, useT } from '@guiker/react-framework'

export const Terms: React.FC = () => {
  const { tMain, MainTrans } = useT({ domain: 'payment', screenName: 'screens.mandateAgreement.terms' })

  return (
    <Flex flexDirection='column' gap={2}>
      <P>{tMain('p1')}</P>
      <P>{tMain('p2')}</P>
      <P>{tMain('p3')}</P>
      <P>{tMain('p4')}</P>
      <P>{tMain('p5')}</P>
      <P>
        <MainTrans i18nKey={'p6'}>
          <a href='https://www.payments.ca' />
        </MainTrans>
      </P>
      <P>
        <MainTrans i18nKey={'p7'}>
          <a href='https://www.payments.ca' />
        </MainTrans>
      </P>
      <P>
        <MainTrans i18nKey={'p8'}>
          <a href='https://www.stripe.com' />
        </MainTrans>
      </P>
    </Flex>
  )
}
