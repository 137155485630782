import { FeaturesConfigurations } from './configurations'
import { Features } from './features'
import { FeatureFlagCheckOptions } from './types'

export const FeatureFlag = (options: FeatureFlagCheckOptions) => {
  const getFeatureConfirguration = (feature: Features) => FeaturesConfigurations.find(({ name }) => name === feature)

  const isEnabled = (feature: Features) => {
    const configuration = getFeatureConfirguration(feature)

    if (typeof configuration?.enabled === 'function') {
      return configuration.enabled(options)
    } else {
      return configuration?.enabled || false
    }
  }

  return {
    isEnabled,
    isDisabled: (feature: Features) => {
      return !isEnabled(feature)
    },
  }
}
