import { yup } from '@guiker/shared-framework'

import { InvoiceStatus } from '../../../entity'

export const invoiceStatusSchema = yup.object({
  statuses: yup
    .array()
    .of<InvoiceStatus>(yup.mixed<InvoiceStatus>())
    .nullable()
    .transform((v) => (!!v ? v : null)),
})

export type InvoiceStatusSchema = yup.InferType<typeof invoiceStatusSchema>
