import { CountByStatusMetadata } from '@guiker/api-definition'
import { routesBuilder } from '@guiker/shared-framework'

import { Invoice, InvoiceStatus } from '../../../../../entity'
import { paths } from '../../../paths'
import { readAllBatchChargeInvoicesSchemas } from './schemas'

const { buildGet } = routesBuilder<Invoice>()({
  basePath: paths.batchCharges.withId.invoices.path,
})

export const routes = {
  readAllBatchChargeInvoices: buildGet({
    paginated: true,
    queryParamsSchema: readAllBatchChargeInvoicesSchemas,
    additionalMeta: {} as CountByStatusMetadata<InvoiceStatus>,
  }),
} as const
