import { buildWebappRoutes, slugifyListingProperty } from '@guiker/base-listing-shared'
import { buildWebAppRoutes } from '@guiker/shared-framework'
import { DeepPartial } from '@guiker/ts-utils'

import { config } from '../config'
import { PropertySaleListing } from '../entity'

export const webappRoutes = buildWebappRoutes({ rootPath: `/${config.domainName}/listings` })

export const slugifyListing = (listing: DeepPartial<PropertySaleListing>) => {
  const { id: objectId, sequentialId, property } = listing
  const slug = slugifyListingProperty(property)
  return `${sequentialId ?? objectId}/${slug}`
}

export const buildNestedListingRoutes = (listing: PropertySaleListing) => {
  const address = listing.property.building.address
  const countryNested = webappRoutes.listings.countryNested(address.country)
  const cityNested = countryNested.cityNested(address.city, address.state)
  const neighbourhoodNested = cityNested.neighbourhoodNested(address.neighbourhood)
  const listingPath = neighbourhoodNested.withId(slugifyListing(listing) ?? listing.id)

  return {
    countryNested: countryNested.path,
    cityNested: cityNested.path,
    neighbourhoodNested: neighbourhoodNested.path,
    listing: listingPath.path,
  }
}

export const { routes: officeRoutes, pathBuilder: officePathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        listings: {
          path: '/listings',
          routes: {
            listListings: '/',
            createListing: '/new',
            byId: {
              path: '/:id',
              routes: {
                completeCreation: '/edit',
                financials: '/financials',
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
