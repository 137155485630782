import React, { useState } from 'react'

import {
  ApiForm,
  Box,
  Button,
  Collapse,
  FeedbackComponent,
  Flex,
  FormSection4,
  RadioGroup,
  SecondaryButton,
  TextField,
  TFunction,
  useForm,
  useMutation,
  useTranslation,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'

import { BookingPanelModal } from '../../../../'
import { useAuthenticationContext, useBookingContext } from '../../../../hooks'

type BookingWithdrawalModalProps = {
  isMultitenant: boolean
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  disablePortal?: boolean
}

type WithdrawFormData = {
  who: string
  reason: string
  reasonOther?: string
}

const reasons = {
  foundAnotherPlace: 'found_another_place',
  notComfortableWithGuiker: 'not_comfortable_with_guiker',
  somethingElse: 'something_else',
}

const whos = {
  userOnly: 'just_me',
  all: 'me_and_all_applicants',
}

const validationSchema = (t: TFunction) =>
  yup.object({
    who: yup.string().required(t('main-booking:errors.required')),
    reason: yup.string().required(t('main-booking:errors.required')),
    reasonOther: yup.string().when('reason', {
      is: reasons.somethingElse,
      then: yup.string().required(t('main-booking:errors.required')),
      otherwise: yup
        .string()
        .nullable()
        .transform(() => null),
    }),
  })

const BookingWithdrawalModal: React.FC<BookingWithdrawalModalProps> = ({ isMultitenant, open = false, setOpen }) => {
  const { t } = useTranslation(['main-booking', 'common'])
  const { user } = useAuthenticationContext()
  const { bookingId, apiClient } = useBookingContext()
  const [errorMsg, setErrorMsg] = useState(null)

  const { mutate: withdrawBooking, status } = useMutation(({ reason, reasonOther }: WithdrawFormData) =>
    apiClient.withdrawBooking({
      pathParams: { bookingId },
      payload: { cancelReason: reasonOther || t(reason) },
    }),
  )
  const { mutate: withdrawApplicant } = useMutation(
    ({ userId, reason, reasonOther }: { userId: string; reason: string; reasonOther?: string }) =>
      apiClient.disinviteBookingApplicant({
        pathParams: { bookingId, applicantUserId: userId },
        payload: { reason: reasonOther || t(reason) },
      }),
    {
      onSuccess: () => {
        setOpen(false)
      },
      onError: () => setErrorMsg(t('main-booking:errors.disinviteApplicantFailed')),
    },
  )

  const resolver = yupResolver(validationSchema(t))
  const { reset: resetForm } = useForm<WithdrawFormData>({
    defaultValues: {
      who: isMultitenant ? whos.userOnly : whos.all,
      reason: reasons.foundAnotherPlace,
    },
  })

  const handleClose = async () => {
    resetForm()
    setOpen(false)
  }

  const requestWithdraw = async (formData: WithdrawFormData) => {
    if (formData.who === whos.all) {
      await withdrawBooking(formData)
    } else {
      await withdrawApplicant({
        userId: user.id,
        reason: formData.reason,
        reasonOther: formData.reasonOther,
      })
    }
    setOpen(false)
  }

  return (
    <ApiForm
      formName='BookingWithDrawalForm'
      formOptions={{
        resolver,
        defaultValues: {
          who: isMultitenant ? whos.userOnly : whos.all,
          reason: reasons.foundAnotherPlace,
        },
      }}
      onSubmit={requestWithdraw}
    >
      <BookingPanelModal
        open={open}
        onClose={handleClose}
        title={t('main-booking:actions.withdraw.title')}
        actions={
          <>
            <SecondaryButton onClick={handleClose} disabled={status === 'loading'} fullWidth>
              {t('common:actions.cancel')}
            </SecondaryButton>
            <Button type='submit' disabled={status === 'loading'} fullWidth>
              {t('main-booking:actions.withdraw.cta')}
            </Button>
          </>
        }
      >
        <Flex flexDirection='column' gap={3}>
          <Box hidden={!isMultitenant}>
            <FormSection4 title={t('main-booking:actions.withdraw.multiTenantTitle')}>
              <RadioGroup
                name='who'
                options={Object.keys(whos).map((key) => ({
                  label: t(`main-booking:actions.withdraw.${whos[key]}`),
                  value: whos[key],
                }))}
              />
            </FormSection4>
          </Box>

          <FormSection4 title={t('main-booking:actions.withdraw.reasonTitle')}>
            <RadioGroup
              name='reason'
              options={Object.keys(reasons).map((key) => ({
                label: t(`main-booking:actions.withdraw.${reasons[key]}`),
                value: reasons[key],
              }))}
            />
            <ReasonTextField />
          </FormSection4>
        </Flex>
      </BookingPanelModal>
      {errorMsg && (
        <FeedbackComponent
          loading={status === 'loading'}
          toasterMessage={errorMsg}
          onToasterClose={() => setErrorMsg(undefined)}
        />
      )}
    </ApiForm>
  )
}

const ReasonTextField: React.FC = () => {
  const watchReason = useWatch({ name: 'reason', defaultValue: undefined })

  return (
    <Collapse in={watchReason === reasons.somethingElse}>
      <TextField maxWidth='100%' name='reasonOther' mb={0} mt={-3} />
    </Collapse>
  )
}

export { BookingWithdrawalModal }
