export enum PackageType {
  AGENT = 'AGENT',
  LANDLORD = 'LANDLORD',
}

export type PackageTier = 'basic' | 'standard' | 'full'
export type Packages = {
  [key in string]: {
    [key in PackageTier]: boolean
  }
}

export const packageTiers: PackageTier[] = ['basic', 'standard', 'full']

export const landlordPackages: Packages = {
  listing: { basic: true, standard: true, full: true },
  creditCheck: { basic: true, standard: true, full: true },
  application: { basic: true, standard: true, full: true },
  lease: { basic: true, standard: true, full: true },
  payment: { basic: true, standard: true, full: true },
  visits: { basic: false, standard: true, full: true },
  ad: { basic: false, standard: true, full: true },
  inspection: { basic: false, standard: false, full: true },
  cleaning: { basic: false, standard: false, full: true },
  renewal: { basic: false, standard: false, full: true },
  mail: { basic: false, standard: false, full: true },
}

export const agentPackages: Packages = {
  ad: { basic: true, standard: true, full: true },
  listing: { basic: true, standard: true, full: true },
  creditCheck: { basic: true, standard: true, full: true },
  application: { basic: true, standard: true, full: true },
  lease: { basic: true, standard: true, full: true },
  visits: { basic: false, standard: true, full: true },
  payment: { basic: false, standard: false, full: true },
  management: { basic: false, standard: false, full: true },
}
