import { routesBuilder } from '@guiker/shared-framework'

import { TenantInstalment } from '../../../../entity'
import { paths } from '../../paths'

const { buildPost } = routesBuilder<TenantInstalment>()({
  basePath: paths.tenantInstalments.withId.path,
})
export const routes = {
  createInvoice: buildPost({
    path: `/create-invoice`,
  }),
}
