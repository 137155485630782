import { AuthLevel, generateApiPath } from '@guiker/api-definition'

const paths = {
  listing: generateApiPath({ prefix: AuthLevel.PUBLIC, name: 'listing' }),
  listingInquiry: generateApiPath({
    prefix: AuthLevel.PUBLIC,
    name: 'listing-inquiry',
    plural: 'listing-inquiries',
  }),
}

export { paths }
