import { routesBuilder } from '@guiker/shared-framework'

import { CreditCardPayInMethod, PayInMethod } from '../../../entity'
import { routes as nestedRoutes } from './:payInMethodId'
import { paths } from './paths'
import { createDirectDebitWithVerificationPayloadSchema, createPayInMethodPayloadSchema } from './schemas'

const builder = routesBuilder<PayInMethod>()({ basePath: paths.payInMethod.all.base() })

export const routes = {
  ...nestedRoutes,
  createPayInMethod: builder.buildPost({
    payloadSchema: createPayInMethodPayloadSchema,
  }),
  createCreditCard: builder.buildPost({
    path: '/credit-card',
    payloadSchema: createPayInMethodPayloadSchema,
    response: {} as CreditCardPayInMethod,
  }),
  createDirectDebitWithVerification: builder.buildPost({
    path: '/direct-debit',
    payloadSchema: createDirectDebitWithVerificationPayloadSchema,
  }),
  readAllPayInMethods: builder.buildGet({
    responseIsArray: true,
  }),
} as const
