import React from 'react'

import { Step, StepProps } from '@guiker/components-library'

import { FacePictureVerificator } from '../FacePictureVerificator'
import { useIdentityVerificationContext } from '../IdentityVerificationContext'

type FacePictureVerificatorStepProps = {}

export const FacePictureVerificatorStep: React.FC<StepProps & FacePictureVerificatorStepProps> = (props) => {
  const { facePicture } = useIdentityVerificationContext()
  const isLoading = false

  const hasError = facePicture && facePicture?.status !== 'SUCCESS'
  const isSuccess = facePicture && facePicture?.status === 'SUCCESS'

  const onClickNext = () => {
    if (isSuccess) {
      props.onClickNext?.()
    }
  }

  return (
    <Step
      {...props}
      hasBackButton={false}
      nextButtonType='button'
      isSubmitting={isLoading}
      onClickNext={onClickNext}
      nextDisabled={!facePicture}
      errors={!isLoading && hasError ? { facePicture: hasError } : undefined}
    >
      <FacePictureVerificator />
    </Step>
  )
}
