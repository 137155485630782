import { CanadaOperationCost } from './operation-cost'
import { PurchaseBase } from './purchase-base'
import { CanadaPurchaseCost } from './purchase-cost'
import { CanadaPurchaseTaxes } from './purchase-taxes'

export * from './expense'

export const Base = {
  purchase: () => ({
    ...PurchaseBase,
    costs: CanadaPurchaseCost,
    taxes: CanadaPurchaseTaxes,
  }),
  operation: {
    costs: CanadaOperationCost,
  },
} as const
