import { routeBuilderHelper, routesBuilder } from '@guiker/shared-framework'

import { StockOrder, StockOrderStatus } from '../../entity'
import { paths } from './paths'

const investorProfileIdNested = routesBuilder<StockOrder>()({
  basePath: paths.investorProfiles.withId.stockOrders.path,
})

const stockIdNested = routesBuilder<StockOrder>()({
  basePath: paths.stock.withId.stockOrders.path,
})

export const routes = {
  readAllStockOrdersByStock: stockIdNested.buildGet(routeBuilderHelper.withStatus(Object.values(StockOrderStatus))),
  readAllInvestorProfileStockOrders: investorProfileIdNested.buildGet(
    routeBuilderHelper.withStatus(Object.values(StockOrderStatus)),
  ),
  cancelReservedIpoStockOrder: stockIdNested.buildPut({
    path: '/:id/cancel',
    responseIsArray: true,
  }),
  forceOfflinePaymentStockOrder: stockIdNested.buildPut({
    path: '/:id/force-offline-payment',
  }),
  readOneStockOrder: stockIdNested.buildGet({
    path: '/:id',
  }),
}
