import React from 'react'

import { useTranslation } from '@guiker/i18n'
import { Display3, OneThirdTwoThirdsColumnsGridLayout, PBig, useLayoutContext } from '@guiker/react-framework'

const InvestorWhatWeDo: React.FC = () => {
  const { t } = useTranslation(['webapp', 'common'])
  const { isAtMostTablette } = useLayoutContext()

  return (
    <OneThirdTwoThirdsColumnsGridLayout gap={isAtMostTablette ? 0 : 10}>
      <div>
        <Display3 mb={0}>{t(`webapp:investors.whatWeDo.title`)}</Display3>
      </div>
      <div>
        <PBig mb={3}>{t('webapp:investors.whatWeDo.content1')}</PBig>
        <PBig>{t('webapp:investors.whatWeDo.content2')}</PBig>
      </div>
    </OneThirdTwoThirdsColumnsGridLayout>
  )
}

export { InvestorWhatWeDo }
