import { generateEventsDefinition } from '@guiker/event'

import { MultipostListingInquiry } from '../../entity'
import { MultipostListingInquiryEventTypes as EventEnums } from './event-types'

export const MultipostListingInquiryEventsDefinition = generateEventsDefinition({
  context: 'LISTING',
  entity: 'MULTIPOST_LISTING_INQUIRY',
  data: null as unknown as MultipostListingInquiry,
  eventTypes: EventEnums,
})

export const { eventTypes: MultipostListingInquiryEventTypes, events: MultipostListingInquiryEvents } =
  MultipostListingInquiryEventsDefinition
