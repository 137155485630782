import React from 'react'

import { CardExpiryElement as StripeCardExpiryElement, CardExpiryElementProps } from '@stripe/react-stripe-js'

import { CardElementProps, RenderProps } from '../context'
import { InputBase } from './InputBase'

type Props = CardElementProps<CardExpiryElementProps>

export const CardExpiryElement: React.FC<Props> = ({ id, label, inputError, ...props }) => (
  <InputBase id={id} label={label} inputError={inputError}>
    {(renderProps: RenderProps) => <StripeCardExpiryElement {...renderProps} {...props} />}
  </InputBase>
)
