import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Envelope } from '../entity'

export const EventsDefinition = generateEventsDefinition({
  context: 'DOCUMENT',
  entity: 'ENVELOPE',
  data: null as unknown as Envelope,
  eventTypes: {
    ENVELOPE_UPDATED: 'ENVELOPE_UPDATED',
    ENVELOPE_COMPLETED: 'ENVELOPE_COMPLETED',
    RECIPIENT_SENT: 'RECIPIENT_SENT',
    RECIPIENT_FINISH_LATER: 'RECIPIENT_FINISH_LATER',
    RECIPIENT_OPENED: 'RECIPIENT_OPENED',
    RECIPIENT_COMPLETED: 'RECIPIENT_COMPLETED',
    RECIPIENT_DECLINED: 'RECIPIENT_DECLINED',
    RECIPIENT_DELIVERY_FAILED: 'RECIPIENT_DELIVERY_FAILED',
    TEMPLATE_UPDATED: 'TEMPLATE_UPDATED',
  } as const,
})
export const Events = EventsDefinition.events
export const EventTypes = EventsDefinition.eventTypes
export type EventTypes = ValueOfEventTypes<typeof EventsDefinition>
