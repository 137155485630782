import { TypeOf, yup } from '@guiker/yup-util'

import { signature } from '../../schemas'
import { guarantorWithoutSignature } from './guarantor'

export const lesseeSignature = yup.object({
  emailAddress: yup
    .string()
    .email()
    .test('lease.sameAsGuarantor', "Email cannot be the same as guarantor's email", function (value) {
      return (
        !this.parent.guarantorWithoutSignature.hasGuarantor ||
        value !== this.parent.guarantorWithoutSignature.emailAddress
      )
    })
    .required(),
  guarantorWithoutSignature: guarantorWithoutSignature,
  signature: signature,
})

export type LesseeSignature = TypeOf<typeof lesseeSignature>
