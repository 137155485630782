import { Role, RoleNameRoleResolver } from '@guiker/permissions'

import { DocumentRoleNames } from './role-names'
import * as Roles from './roles'

export const DocumentRoleNamesRoleResolver: RoleNameRoleResolver<DocumentRoleNames> = ({ roleNames }): Role[] => {
  if (!roleNames || roleNames.length === 0) {
    return []
  }

  const roles: Role[] = roleNames.map((name) => {
    switch (name) {
      case DocumentRoleNames.SPECTATOR:
        return Roles.SpectatorRole
      case DocumentRoleNames.SIGNER:
        return Roles.SignerRole
      case DocumentRoleNames.EDITOR:
        return Roles.EditorRole
    }
  })

  return roles
}
