import { yup } from '@guiker/yup-util'

import { Appliances } from '../../entity'
import { nullableBoolean } from './common'

export const appliances = yup.object<Appliances>({
  washer: nullableBoolean,
  dishwasher: nullableBoolean,
  fridge: nullableBoolean,
  airConditioner: nullableBoolean,
  oven: nullableBoolean,
  balcony: nullableBoolean,
  petsAllowedSmall: nullableBoolean,
  petsAllowedLarge: nullableBoolean,
})

export type AppliancesSchema = yup.InferType<typeof appliances>
