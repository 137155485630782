import { TypeOf, yup } from '@guiker/yup-util'

export const updateBookingInfoSchema = yup.object({
  price: yup
    .object({
      amount: yup.number().required(),
    })
    .required(),
  period: yup
    .object({
      from: yup.date().required(),
      to: yup.date().required(),
    })
    .required(),
  optionId: yup.number().required(),
})

export type UpdateBookingInfoSchema = TypeOf<typeof updateBookingInfoSchema>
