import React from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { Flex, FormSection2 } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { PaymentMethod } from './PaymentMethod'

type Prop = {
  paymentMethods: PayInMethod[]
  title?: String
  topActions?: React.ReactNode
  renderViewAction?: (payInMethodId: string) => React.ReactNode
}

const ListPaymentMethods: React.FC<Prop> = ({ paymentMethods, topActions, title, renderViewAction }) => {
  const { tShared } = useT({})

  return (
    <FormSection2 title={title ?? tShared('paymentMethods.title')} topActions={topActions}>
      <Flex flexDirection='column' gap={4}>
        {paymentMethods?.map((paymentMethod) => (
          <PaymentMethod paymentMethod={paymentMethod} renderViewAction={renderViewAction} />
        ))}
      </Flex>
    </FormSection2>
  )
}

export { ListPaymentMethods }
