import React from 'react'

import { PayoutMethod, typeChecker } from '@guiker/payout-shared'
import { getBankByRouting } from '@guiker/shared-framework'

import { BankIcon } from '../BankIcon'

type Props = {
  payoutMethod: PayoutMethod
}

const PayoutMethodIcon: React.FC<Props> = ({ payoutMethod }) => {
  const routingNumber = typeChecker.isACH(payoutMethod)
    ? payoutMethod.details?.routingNumber
    : typeChecker.isEFT(payoutMethod)
    ? payoutMethod.details?.institutionNumber
    : undefined
  const bank = getBankByRouting(routingNumber)
  return <BankIcon bank={bank?.name} />
}

export { PayoutMethodIcon }
