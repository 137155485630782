import React, { useState } from 'react'

import { Table } from '@guiker/react-framework'
import {
  parseTenantInstalmentsWithPayout,
  Payer,
  RentPaymentsData,
  RentPaymentsPlanWithTenantInstalments,
} from '@guiker/rent-payment-shared'

import { HistoryModal } from './HistoryModal'
import { useReceiverTenantInstalmentColumns } from './utils'

type Prop = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
}

export const PayerRentPaymentsPlanTable: React.FC<Prop> = ({ rentPaymentsPlan, payer }) => {
  const [rentPaymentsData, setRentPaymentsData] = useState<RentPaymentsData>()
  const columns = useReceiverTenantInstalmentColumns(rentPaymentsPlan)

  return (
    <>
      <Table
        columns={columns}
        data={parseTenantInstalmentsWithPayout({ rentPaymentsPlan, payer })}
        onRowClick={(data) => data.events?.length > 0 && setRentPaymentsData(data)}
      />
      {!!rentPaymentsData && <HistoryModal rentPaymentsData={rentPaymentsData} />}
    </>
  )
}
