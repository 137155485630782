import { Inventory } from './inventory'
import { Option } from './option'

export enum RentalType {
  ENTIRE = 'ENTIRE',
  PRIVATE_ROOM = 'PRIVATE_ROOM',
  SHARED_ROOM = 'SHARED_ROOM',
}

export type Configuration = {
  id: number
  title?: string
  type: RentalType
  inventoryId: number
  inventory?: Inventory
  parentId?: number
  createdAt: Date
  updatedAt: Date
  options: Option[]
  isActive: boolean
  isDeleted: boolean
  isFiltered: boolean
}
