import { AuthenticatedApiClient } from '@guiker/authentication-shared'
import { useConfig } from '@guiker/config-context'
import { v4 as uuid } from '@guiker/uuid'

export const useAuthenticationApiClient = (accessToken: string) => {
  const { apiBaseUrl: baseUrl } = useConfig()

  return AuthenticatedApiClient({
    baseUrl,
    accessToken,
    correlatedRequestId: uuid(),
  })
}
