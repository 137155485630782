import React from 'react'

import { useTranslation } from '@guiker/i18n'
import { Display2, Flex, makeStyles, P } from '@guiker/react-framework'

const useStyles = makeStyles(
  () => ({
    value: {
      marginBottom: 0,
      fontWeight: 500,
    },
  }),
  { name: 'InvestorKpi' },
)

type InvestorKpiProps = {
  i18nKey: string
  value: string
}

const InvestorKpi: React.FC<InvestorKpiProps> = ({ i18nKey, value }) => {
  const classes = useStyles()
  const { t } = useTranslation(['webapp', 'common'])

  return (
    <Flex flexDirection='column' alignItems='center'>
      <Display2 className={classes.value}>{value}</Display2>
      <P mb={0}>{t(`webapp:investors.kpis.${i18nKey}`)}</P>
    </Flex>
  )
}

export { InvestorKpi }
