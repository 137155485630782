import { CategoryTasks, Task } from '@guiker/task-shared'

export const categoryMapping = (tasks: Task[]): (Task | Task[])[] => {
  const taskCategorized = []
  const categorizedTypes = []
  for (const task of tasks) {
    if (CategoryTasks.includes(task.type) && !categorizedTypes.includes(task.type)) {
      const typeCategory = tasks.filter((t) => t.type === task.type)
      categorizedTypes.push(task.type)
      taskCategorized.push(typeCategory)
      continue
    } else if (!CategoryTasks.includes(task.type)) {
      taskCategorized.push(task)
    }
  }
  return taskCategorized
}
