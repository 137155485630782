import { Capabilities, getPayoutConfigs, PayoutMethodStatus, PayoutMethodType, PayoutProvider } from '../entity'

const providerStatusToPayoutMethodStatusMapper = (
  type: PayoutMethodType,
  providers: PayoutProvider[] | undefined,
  capabilities: Capabilities[],
): PayoutMethodStatus => {
  if (!providers?.length) return undefined
  const requiredProviders = getPayoutConfigs(type, { capabilities }).requiredProviders
  const filteredProviders = providers.filter((provider) => requiredProviders.includes(provider.name))
  if (
    filteredProviders.every((p) => {
      if (p.status === 'ENABLED') return true
      else if (filteredProviders.find((provider) => p.name === provider.name && provider.status === 'ENABLED'))
        return true
      return false
    })
  )
    return PayoutMethodStatus.ENABLED
  if (providers.some((p) => p.status === 'REJECTED')) return PayoutMethodStatus.REJECTED
  if (providers.some((p) => p.status === 'NEED_MORE_INFORMATION')) return PayoutMethodStatus.NEED_MORE_INFORMATION
  return undefined
}

export { providerStatusToPayoutMethodStatusMapper }
