import { TypeOf, yup } from '@guiker/yup-util'

import { FoundListingOutsideEnquiry, FoundListingOutsideEnquiryData } from '../../../entity'

export const foundListingOutsideEnquirySchema = yup.object<
  Omit<FoundListingOutsideEnquiry, 'enquiryType'> & { recaptchaToken: string }
>({
  emailAddress: yup.string().email().required(),
  recaptchaToken: yup.string().required(),
  data: yup.object<FoundListingOutsideEnquiryData>({
    name: yup.string().nullable(),
    phone: yup.string().nullable(),
    moveInDate: yup.string().nullable(),
    interestedUnits: yup.string().nullable(),
    comments: yup.string().nullable(),
  }),
})

export type FoundListingOutsideEnquirySchema = TypeOf<typeof foundListingOutsideEnquirySchema>
