import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { FullScreenSpinner, PageLayout, PageLayoutProps } from '@guiker/react-framework'

import { useT } from '../i18n'

type Props = React.PropsWithChildren & {
  breadcrumbItems?: PageLayoutProps['breadcrumbItems']
  hasButtonContainer?: boolean
  isLoading?: boolean
}

const AccountPageLayout: React.FC<Props> = ({
  children,
  hasButtonContainer = false,
  isLoading = false,
  breadcrumbItems,
}) => {
  const { tMain: tNav } = useT({ screenName: 'sideMenu.navItem' })

  const sideMenuNavItems = [
    { text: tNav('profile'), href: webappRoutes.root.profile().path },
    {
      text: tNav('wallet'),
      href: webappRoutes.root.wallet().path,
      nestedItems: [
        {
          text: tNav('fundingAccount'),
          href: webappRoutes.root.wallet().fundingAccount().path,
        },
        { text: tNav('payments'), href: webappRoutes.root.wallet().payments().path },
        { text: tNav('payoutPreferences'), href: webappRoutes.root.wallet().payoutPreferences().path },
      ],
    },
    { text: tNav('notifications'), href: webappRoutes.root.notifications().path },
    { text: tNav('documents'), href: webappRoutes.root.documents().path },
    { text: tNav('security'), href: webappRoutes.root.security().path },
  ]

  return (
    <PageLayout
      hasButtonContainer={hasButtonContainer}
      sideMenuProps={{ items: sideMenuNavItems }}
      breadcrumbItems={breadcrumbItems}
    >
      {isLoading ? <FullScreenSpinner /> : children}
    </PageLayout>
  )
}

export { AccountPageLayout }
