import { conditionallyRequiredSchema, yup } from '@guiker/shared-framework'

import { RepresentativeType } from '../../../../entity'
import { addressSchema } from './address'

export const businessSchema = (required: boolean) =>
  yup.object({
    name: conditionallyRequiredSchema(yup.string(), required),
    legalName: conditionallyRequiredSchema(yup.string(), required),
    type: yup
      .string()
      .oneOf(['SOLEPROP', 'PARTNERSHP', 'CORP'])
      .when('name', {
        is: (name: string) => !!name,
        then: yup.string().oneOf(['SOLEPROP', 'PARTNERSHP', 'CORP']).required(),
        otherwise: yup.string().optional(),
      }),
    phoneNumber: conditionallyRequiredSchema(yup.string(), required),
    yearlyVolumeRange: yup.string().oneOf(['LOW', 'MEDIUM', 'HIGH', 'VERY_HIGH']).optional().default('LOW'),
    taxNumber: conditionallyRequiredSchema(
      yup
        .string()
        .length(9)
        .test('invalidFormat', 'Values must be number', (value: string) => /^[0-9]{9}$/.test(value)),
      required,
    ),
    address: addressSchema(required, { forBusiness: true }),
    representativeType: conditionallyRequiredSchema(yup.mixed().oneOf(Object.values(RepresentativeType)), required),
    representativeTitle: yup.string().when('representativeType', {
      is: RepresentativeType.OTHER,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
  })
