import { TransferScopeType } from '@guiker/payment-shared'
import { Payout, PayoutMethod, PayoutStatus, TransferProjection } from '@guiker/payout-shared'
import { ValueOfUnion } from '@guiker/shared-framework'

export type PayoutStatusGroupName = 'all' | 'received' | 'inTransit' | 'upcoming' | 'paidOffline'

export const RentPayoutStatus = { ...PayoutStatus, PAID_OFFLINE: 'PAID_OFFLINE' } as const
export type RentPayoutStatus = typeof RentPayoutStatus

export const RentCollectionPayoutStatusGroups: {
  [key in PayoutStatusGroupName]: ValueOfUnion<RentPayoutStatus>[]
} = {
  all: [],
  received: [PayoutStatus.PAID],
  inTransit: [PayoutStatus.IN_TRANSIT],
  upcoming: [PayoutStatus.PENDING],
  paidOffline: ['PAID_OFFLINE'],
}

export type RentCollectionPayoutTableDataPoint = Payout & {
  id: string
  payoutMethod: PayoutMethod
  transfers: TransferProjection<TransferScopeType.INVOICE>[]
}

export type RentCollectionPayoutCountByStatus = {
  status: ValueOfUnion<RentPayoutStatus>
  count: number
}
