import React from 'react'

import { mainPathBuilder } from '@guiker/conversation-shared'
import {
  ButtonWithLoader,
  Checkbox,
  Collapse,
  ColumnGridLayout,
  ColumnGridLayoutProps,
  Divider,
  Dropdown,
  Flex,
  Link,
  P,
  PSmall,
  TextField,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { DeepPartial } from '@guiker/shared-framework'
import { CreditScoreRanking, OccupationType, TenantApplication } from '@guiker/tenant-application-shared'

type PreApplicationFormProps = {
  defaultValue?: DeepPartial<TenantApplication>
  isLoading: boolean
}

export const PreApplicationForm: React.FC<PreApplicationFormProps> = ({ defaultValue, isLoading = false }) => {
  const { t } = useTranslation(['main-tenantApplication'])
  const defaultOccupation = defaultValue?.occupation

  const occupation = useWatch({
    name: 'occupation.type',
    defaultValue: defaultOccupation?.type,
  })
  const hasGuarantor = useWatch({
    name: 'hasGuarantor',
    defaultValue: defaultValue?.hasGuarantor ?? false,
  })

  const creditScoreDropdownOptions = Object.values(CreditScoreRanking).map((option) => ({
    label: t(`creditScore.rankings.${option}`),
    value: option,
  }))

  const getInputProps = useGetInputProps({ tPrefix: '', defaultValue, namespaces: 'main-tenantApplication' })

  const gridLayoutProps: ColumnGridLayoutProps = {
    xs: { gridTemplateColumns: 1, gridColumn: { span: 1 } },
    md: { gridTemplateColumns: 1, gridColumn: { span: 1 } },
  }

  return (
    <Flex gap={2} flexDirection='column'>
      <ColumnGridLayout {...gridLayoutProps}>
        <Dropdown
          {...getInputProps('occupation.type', { i18nKey: 'occupation.type.label' })}
          options={[
            { label: t('occupation.type.professional.title'), value: OccupationType.PROFESSIONAL },
            { label: t('occupation.type.student.title'), value: OccupationType.STUDENT },
          ]}
        />

        <Checkbox {...getInputProps('hasGuarantor', { i18nKey: 'hasGuarantor.true' })} />
      </ColumnGridLayout>

      <Collapse in={!!occupation && !hasGuarantor}>
        <ColumnGridLayout {...gridLayoutProps}>
          <TextField {...getInputProps('occupation.title')} />
          <Dropdown
            {...getInputProps('profile.estimatedCreditScore', {
              i18nKey: 'creditScore.label',
              dependency: { hasGuarantor },
            })}
            options={creditScoreDropdownOptions}
          />
          <TextField
            {...getInputProps(`occupation.${occupation?.toLowerCase()}.annualSalary`, { i18nKey: 'user.annualSalary' })}
            defaultValue={defaultOccupation && defaultOccupation[occupation?.toLowerCase()]?.annualSalary}
          />
        </ColumnGridLayout>
      </Collapse>

      <Collapse in={hasGuarantor}>
        <ColumnGridLayout {...gridLayoutProps}>
          <Divider />
          <P color={60}>{t('screens.preApplication.guarantor.description')}</P>
          <TextField
            {...getInputProps('guarantor.profile.occupation', { i18nKey: 'occupation.title' })}
            defaultValue={defaultValue?.guarantor?.profile?.occupation}
          />
          <Dropdown
            {...getInputProps('guarantor.profile.estimatedCreditScore', { i18nKey: 'creditScore.label' })}
            defaultValue={defaultValue?.guarantor?.profile?.estimatedCreditScore}
            options={creditScoreDropdownOptions}
          />
          <TextField
            {...getInputProps('guarantor.profile.annualSalary', { i18nKey: 'user.estimatedAnnualSalary' })}
            defaultValue={defaultValue?.guarantor?.profile?.annualSalary}
          />
        </ColumnGridLayout>
      </Collapse>

      <Flex flexDirection='column' alignItems='center' gap={2} mt={2}>
        <ButtonWithLoader type='submit' isLoading={isLoading} fullWidth disabled={!occupation}>
          {t('common:actions.send')}
        </ButtonWithLoader>
        <Link color={30} to={mainPathBuilder.root.messages.path()}>
          <PSmall color={30}>{t('screens.preApplication.skip')}</PSmall>
        </Link>
      </Flex>
    </Flex>
  )
}
