import React from 'react'

import { Dropzone, SupportedFileType } from '@guiker/dropzone'

type UploadAssetButtonProps = {
  disableDragAndDrop: boolean
  dropzoneOptions: {
    accept?: SupportedFileType | SupportedFileType[]
    onDrop?: (file: File) => void
    maxFiles?: number
    maxSize?: number
  }
  fullWidth?: boolean
}

const UploadAssetButton: React.FC<UploadAssetButtonProps> = ({ disableDragAndDrop, dropzoneOptions, fullWidth }) => {
  const { accept, onDrop, maxSize, maxFiles } = dropzoneOptions
  const style = { ...(!disableDragAndDrop && { width: fullWidth ? '100%' : 284, height: 284 / 1.61 }) }

  return (
    <Dropzone
      mutateOptions={{ onSuccess: onDrop }}
      fileOptions={{ acceptedFileTypes: accept, maxSize, maxFiles }}
      shouldRenderAcceptedFileNames={false}
      inputType={disableDragAndDrop ? 'button' : 'zone'}
      {...style}
    />
  )
}

export { UploadAssetButton }
