import { routesBuilder } from '@guiker/shared-framework'

import { PropSharingInquiry } from '../../entity'
import { paths } from './paths'
import { upsertPropSharingInquirySchema } from './schemas'

const base = routesBuilder<PropSharingInquiry>()({ basePath: paths.inquiry.all.base() })
const idNested = routesBuilder<PropSharingInquiry>()({
  basePath: paths.inquiry.one.withId(),
})

export const inquiryRoutes = {
  createInquiry: base.buildPost({
    payloadSchema: upsertPropSharingInquirySchema,
  }),
  readAllInquiries: base.buildGet({
    responseIsArray: true,
  }),
  updateInquiry: idNested.buildPut({
    payloadSchema: upsertPropSharingInquirySchema,
  }),
  readOneInquiry: idNested.buildGet(),
}
