export enum BookingPermissions {
  ViewTenancy = 'booking:ViewTenancy',
  ParticipateChat = 'booking:ParticipateChat',
  ChangeUnitManagerRole = 'booking:ChangeUnitManagerRole',
  ConfirmBooking = 'booking:ConfirmBooking',
  DisableBookingPayment = 'booking:DisableBookingPayment',
  EditPricing = 'booking:EditPricing',
  EditPeriod = 'booking:EditPeriod',
  EditProrata = 'booking:EditProrata',
  EditServiceFee = 'booking:EditServiceFee',
  EditPaymentCondition = 'booking:EditPaymentCondition',
  EditBookingSteps = 'booking:EditBookingSteps',
  ExtendCountdown = 'booking:ExtendCountdown',
  RejectBooking = 'booking:RejectBooking',
  GetBookingSteps = 'booking:GetBookingSteps',
  GetBookingPayment = 'booking:GetBookingPayment',
  WaiveBookingPaymentContribution = 'booking:WaiveBookingPaymentContribution',
  ViewSSN = 'booking:ViewSSN',
  InviteApplicant = 'booking:inviteApplicant',
  InviteUnitManager = 'booking:inviteUnitManager',
  InviteListingOperator = 'booking:InviteListingOperator',
  DisinviteUnitManager = 'booking:DisinviteUnitManager',
  DisinviteApplicant = 'booking:disinviteApplicant',
  EditApplicationStatus = 'booking:EditApplicationStatus',
  RequestBackgroundCheck = 'booking:RequestBackgroundCheck',
  EditBookingDocument = 'document:EditBookingDocument',
  ViewBookingDocument = 'document:ViewBookingDocument',
  ViewLease = 'lease:ViewLease',
  DraftLease = 'lease:DraftLease',
  ConductBackgroundCheck = 'backgroundCheck:ConductBackgroundCheck',
  ViewBackgroundCheck = 'backgroundCheck:ViewBackgroundCheck',
  DownloadBackgroundCheck = 'backgroundCheck:Download',
}

export enum LeasePermissions {
  DraftLease = 'lease:DraftLease',
  SignLeaseAsLessee = 'lease:SignLeaseAsLessee',
  SignLeaseAsGuarantor = 'lease:SignLeaseAsGuarantor',
  SignLeaseAsLessor = 'lease:SignLeaseAsLessor',
  ViewLease = 'lease:ViewLease',
}

export enum BackgroundCheckPermissions {
  ConductBackgroundCheck = 'backgroundCheck:ConductBackgroundCheck',
  ViewBackgroundCheck = 'backgroundCheck:ViewBackgroundCheck',
}

export enum DocumentPermissions {
  EditBookingDocument = 'document:EditBookingDocument',
  ViewBookingDocument = 'document:ViewBookingDocument',
}
