import React, { useState } from 'react'

import { PlusIcon, TextButton, useTranslation } from '@guiker/react-framework'

import { BaseBox } from '../BaseBox'
import { UnitManagerInvitationForm } from './UnitManagerInvitationForm'

const InviteUnitManager: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BaseBox onClick={() => setModalOpen(true)}>
        <PlusIcon />

        <TextButton>{t('actions.invite.inviteManager')}</TextButton>
      </BaseBox>

      <UnitManagerInvitationForm open={modalOpen} setOpen={setModalOpen} />
    </>
  )
}

export { InviteUnitManager }
