import { Event } from '@guiker/event'
import { HttpMethod } from '@guiker/http'

import { Booking, UnitManager } from '../../../entity'
import { bookingIdSchema } from '../../schema'
import { paths } from '../paths'
import {
  bookingIdAndUserIdPathParamsSchema,
  extendTimerExpirationPayloadSchema,
  inviteBookingUnitManagerByEmailPayloadSchema,
  inviteBookingUnitManagersPayloadSchema,
} from '../schema'

const WITH_BOOKING_SCOPE = paths.bookingUnitManager.one.withScope('booking', ':bookingId')
const UNIT_MANAGER_WITH_ID_PATH = paths.bookingUnitManager.all.withScopeAndId('booking', ':bookingId', ':userId')

const arrayResponseValidator = (payload: unknown) => Promise.resolve(payload as UnitManager[])

export const routes = {
  readAvailableUnitManagers: {
    path: `${paths.booking.one.withId(':bookingId')}/available-unit-managers`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Omit<UnitManager, 'steps' | 'invitation'>[]),
    pathParamsValidator: (payload: unknown) => bookingIdSchema.validate(payload),
  },
  inviteBookingUnitManagers: {
    path: `${WITH_BOOKING_SCOPE}/invite`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => inviteBookingUnitManagersPayloadSchema.validate(payload),
    responseValidator: arrayResponseValidator,
    pathParamsValidator: (payload: unknown) => bookingIdSchema.validate(payload),
  },
  inviteBookingUnitManagerByEmail: {
    path: `${WITH_BOOKING_SCOPE}/invite-by-email`,
    method: HttpMethod.POST,
    payloadValidator: (payload: unknown) => inviteBookingUnitManagerByEmailPayloadSchema.validate(payload),
    responseValidator: arrayResponseValidator,
    pathParamsValidator: (payload: unknown) => bookingIdSchema.validate(payload),
  },
  disinviteBookingUnitManager: {
    path: `${paths.bookingUnitManager.one.withScopeAndId('booking', ':bookingId', ':userId')}/disinvite`,
    method: HttpMethod.POST,
    responseValidator: arrayResponseValidator,
    pathParamsValidator: (payload: unknown) => bookingIdAndUserIdPathParamsSchema.validate(payload),
  },
  extendTimerExpiration: {
    path: `${UNIT_MANAGER_WITH_ID_PATH}/extend-timer`,
    method: HttpMethod.PUT,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Booking),
    pathParamsValidator: (payload: unknown) => bookingIdAndUserIdPathParamsSchema.validate(payload),
    payloadValidator: (payload: unknown) => extendTimerExpirationPayloadSchema.validate(payload),
  },
  resetTimerExpiration: {
    path: `${UNIT_MANAGER_WITH_ID_PATH}/reset-timer`,
    method: HttpMethod.PUT,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Booking),
    pathParamsValidator: (payload: unknown) => bookingIdAndUserIdPathParamsSchema.validate(payload),
    payloadValidator: (payload: unknown) => extendTimerExpirationPayloadSchema.validate(payload),
  },
  readAllBookingEvents: {
    path: `${WITH_BOOKING_SCOPE}/events`,
    method: HttpMethod.GET,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Event[]),
    pathParamsValidator: (payload: unknown) => bookingIdSchema.validate(payload),
  },
  approveBookingRoommateIntro: {
    path: `${UNIT_MANAGER_WITH_ID_PATH}/approve`,
    method: HttpMethod.PUT,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Booking),
    pathParamsValidator: (payload: unknown) => bookingIdAndUserIdPathParamsSchema.validate(payload),
  },
}
