import { yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const person = yup.object<Ontario.Person>({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
})

export type Person = Ontario.Person
