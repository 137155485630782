import { AuthLevel, buildPaths, generateApiPath } from '@guiker/shared-framework'

const prefix = AuthLevel.ADMIN

const paths = {
  constant: buildPaths(
    {
      payoutMethod: {
        payoutProvider: {},
      },
      user: {
        payoutMethod: {},
      },
      transfer: {
        payout: {},
      },
      platformPayoutMethods: {},
    },
    prefix,
  ),
  dynamic: {
    transfer: generateApiPath({ prefix, name: 'transfer' }),
  },
}

export { paths }
