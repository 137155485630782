import { ValueOf } from '@guiker/ts-utils'

import { PayoutMethod } from '../payout-method'
import { WithFundingAccount, WithUser } from './entity'

export const FundingAccountWithdrawalStatus = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
} as const

export type FundingAccountWithdrawalStatus = ValueOf<typeof FundingAccountWithdrawalStatus>

export enum FundingAccountWithdrawalType {
  WITHDRAWAL = 'WITHDRAWAL',
  CHARGE = 'CHARGE',
}

export type FundingAccountWithdrawal = {
  id: string
  fundingAccountId: string
  type: FundingAccountWithdrawalType
  payoutMethod?: {
    id: string
  }
  amount: number
  status: FundingAccountWithdrawalStatus
  metadata?: { note?: string } & Record<string, any>
  createdAt?: string
}

export type WithChargeType<T> = Omit<T, 'metadata'> & {
  metadata: { invoiceId: string; note: string } & Record<string, any>
}

export type WithPayoutMethod<T> = T & {
  payoutMethod?: PayoutMethod
}

export type FundingAccountWithdrawalProjection = WithUser<
  WithFundingAccount<WithPayoutMethod<Omit<FundingAccountWithdrawal, 'payoutMethod'>>>
>
