import React from 'react'

import { AssetUploader } from '@guiker/asset-uploader'
import { isAtMostTablette, makeStyles } from '@guiker/components-core'
import { Flex } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'

import { IdentityVerification } from '../IdentityVerification'

const useStyles = makeStyles(
  () => ({
    assetsRow: {
      flexDirection: 'row',
      [isAtMostTablette]: {
        flexDirection: 'column',
      },
    },
  }),
  { name: 'SummaryStep' },
)

type IdentityVerificationProps = {
  defaultValue?: IdentityVerification
}

export const IdentityVerificationDocuments: React.FC<IdentityVerificationProps> = ({ defaultValue }) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'common-components'])
  const tPrefix = 'common-components:identityVerification'

  return (
    <Flex flexDirection='column' gap={4}>
      <Flex className={classes.assetsRow} gap={4}>
        <AssetUploader
          label={t(`${tPrefix}.governmentIdFront.label`)}
          name='governmentIdFront'
          readOnly
          withThumbnail
        />
        {((defaultValue && !!defaultValue?.governmentIdBack) || !defaultValue) && (
          <AssetUploader
            label={t(`${tPrefix}.governmentIdBack.label`)}
            name='governmentIdBack'
            readOnly
            withThumbnail
          />
        )}
      </Flex>
      <AssetUploader label={t(`${tPrefix}.facePicture.label`)} name='facePicture' readOnly withThumbnail />
    </Flex>
  )
}
