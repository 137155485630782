import { ValueOf } from '@guiker/ts-utils'

export const LabelNames = {
  DRIVING_LICENSE: 'DRIVING_LICENSE',
  ID: 'ID',
  PASSPORT: 'PASSPORT',
  DOCUMENT: 'DOCUMENT',
} as const

export type LabelNames = ValueOf<typeof LabelNames>

export type Label = {
  name: LabelNames
  confidence: number
}

export type LabelizeResult = {
  labels: Label[]
}
