import { toUpper } from '@guiker/lodash'

// @todo: format postal code on import / creation of listing
export const formatPostalCode = (postalCode: string, country: string): string => {
  const formattedPostalCode = toUpper(postalCode?.split(' ').join(''))

  switch (country) {
    case 'CA':
      return `${formattedPostalCode.substring(0, 3)} ${formattedPostalCode.substring(3, 6)}`
    default:
      return formattedPostalCode
  }
}
