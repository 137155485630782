import { merge, sumBy } from '@guiker/shared-framework'

import { FinancialStatements, FinancialStatementsDocument } from '../entity/financial-statements'

export const hydrateFinancialStatements = (
  financialStatementsDocument: FinancialStatementsDocument,
): FinancialStatements => {
  const totalLiabilityValues = sumBy(financialStatementsDocument.balanceSheet.liabilities, 'amount')
  const totalAssetValue = sumBy(financialStatementsDocument.balanceSheet.assets, 'amount')
  const netAssetValue = totalAssetValue - totalLiabilityValues

  return merge({}, financialStatementsDocument, {
    balanceSheet: {
      totalLiabilityValues,
      totalAssetValue,
      netAssetValue,
    },
    shareholderEquityStatement: {
      netAssetValuePerUnit: netAssetValue / financialStatementsDocument.shareholderEquityStatement.totalUnitCount,
    },
  })
}
