import React from 'react'

import { Checkbox, Dropdown, FormSection4, TwoColumnsGridLayout, useTranslation } from '@guiker/react-framework'
import { BuildingPreferences as BuildingPreferencesType, Parking } from '@guiker/tenant-preference-shared'

type BuildingPreferencesProps = {
  defaultValue: BuildingPreferencesType
  name: string
}

const buildingPreferences = [
  { key: 'elevator' },
  { key: 'gym' },
  { key: 'pool' },
  { key: 'recreationRoom' },
  { key: 'wheelchairAccessible' },
]

export const BuildingPreferences: React.FC<BuildingPreferencesProps> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common', 'common-rentalListing', 'common-tenantPreference'])

  return (
    <FormSection4 title={t('common-tenantPreference:buildingPreferences.title')}>
      <TwoColumnsGridLayout>
        <Dropdown
          defaultValue={defaultValue?.parking}
          name={`${name}.parking`}
          label={t('common-tenantPreference:buildingPreferences.parkingType')}
          options={Object.values(Parking).map((parkingType) => {
            return {
              value: parkingType,
              label: t(`common-rentalListing:amenities.${parkingType}`),
            }
          })}
        />
        <Dropdown
          defaultValue={`${defaultValue?.storage}`}
          name={`${name}.storage`}
          label={t('common-tenantPreference:buildingPreferences.storage')}
          options={[
            { value: 'true', label: t('common:yes') },
            { value: 'false', label: t('common:no') },
          ]}
        />
        {buildingPreferences.map(({ key }) => (
          <Checkbox
            key={key}
            defaultValue={defaultValue?.[key]}
            name={`${name}.${key}`}
            label={t(`common-rentalListing:amenities.${key}`)}
          />
        ))}
      </TwoColumnsGridLayout>
    </FormSection4>
  )
}
