import { routesBuilder } from '@guiker/api-definition'

import { NEWProperty, WithBuilding } from '../../../entity'
import { paths } from './paths'

const basePath = paths.NEWProperty.all.withId(':id')
const { buildGet } = routesBuilder<NEWProperty>()({ basePath })

export const NEWPropertyRoutes = {
  readOneNEWProperty: buildGet({
    response: null as WithBuilding<NEWProperty>,
  }),
}
