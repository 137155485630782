import { TypeOf, yup } from '@guiker/yup-util'

export const contractPropSharingBuyStockOrderPreviewPayloadSchema = yup.object({
  investorProfile: yup.object(),
  stockOrder: yup.object(),
  consent: yup.object({
    date: yup.string(),
  }),
})

export type ContractPropSharingBuyStockOrderPreviewPayloadSchema = TypeOf<
  typeof contractPropSharingBuyStockOrderPreviewPayloadSchema
>
