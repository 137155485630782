import { routesBuilder } from '@guiker/shared-framework'

import { SearchResult } from '../../entity'
import { searchQueryParams } from '../schemas'
import { paths } from './paths'

const { buildGet } = routesBuilder<SearchResult>()({
  basePath: paths.search.one.base(),
})

export const routes = {
  search: buildGet({ queryParamsSchema: searchQueryParams, responseIsArray: true }),
}
