import { yup } from '@guiker/yup-util'

import { Vehicle } from '../../entity'

export const vehicleSchema = yup.object<Vehicle>({
  brand: yup.string().required(),
  model: yup.string().required(),
  dateOfIssue: yup.string().required(),
  plateNumber: yup
    .string()
    .notRequired()
    .transform((value) => (!value ? undefined : value)),
})
