import { TypeOf, yup } from '@guiker/yup-util'

import { email } from './email'
import { password } from './password'

export const signUp = yup
  .object({
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    email: email,
    password: password,
    invitationToken: yup.string().trim().notRequired(),
  })
  .required()

export type SignUp = TypeOf<typeof signUp>
