import { Payment } from '@guiker/base-entity'

import { ParticipantStepStatus } from './participant'
import ContributionPayInMethodType = Payment.SupportedPayInMethodType

enum ContributionStatus {
  INITIAL_STATE = 'INITIAL_STATE',
  CONTRIBUTION_INCOMPLETE = 'CONTRIBUTION_INCOMPLETE',
  PENDING_PAY_IN_METHOD_VERIFICATION = 'PENDING_PAY_IN_METHOD_VERIFICATION',
  PROCESSING_PAY_IN_METHOD_VERIFICATION = 'PROCESSING_PAY_IN_METHOD_VERIFICATION',
  PAY_IN_METHOD_REJECTED = 'PAY_IN_METHOD_REJECTED',
  PAY_IN_METHOD_VERIFIED = 'PAY_IN_METHOD_VERIFIED',
  COMPLETED = 'COMPLETED',
}

export const ContributionStatusStateTransitions = {
  [ContributionStatus.INITIAL_STATE]: [
    ContributionStatus.CONTRIBUTION_INCOMPLETE,
    ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION,
  ],
  [ContributionStatus.CONTRIBUTION_INCOMPLETE]: [
    ContributionStatus.CONTRIBUTION_INCOMPLETE,
    ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION,
  ],
  [ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION]: [
    ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION,
    ContributionStatus.CONTRIBUTION_INCOMPLETE,
    ContributionStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION,
  ],
  [ContributionStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION]: [
    ContributionStatus.PAY_IN_METHOD_REJECTED,
    ContributionStatus.PAY_IN_METHOD_VERIFIED,
  ],
  [ContributionStatus.PAY_IN_METHOD_REJECTED]: [ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION],
  [ContributionStatus.PAY_IN_METHOD_VERIFIED]: [
    ContributionStatus.COMPLETED,
    ContributionStatus.PAY_IN_METHOD_REJECTED,
  ],
  [ContributionStatus.COMPLETED]: [] as ContributionStatus[],
}

type PaymentStepStatus = Exclude<ParticipantStepStatus, ParticipantStepStatus.NOT_READY>
const inferApplicantStepStatus = (status?: ContributionStatus): PaymentStepStatus => {
  switch (status) {
    case ContributionStatus.INITIAL_STATE:
      return ParticipantStepStatus.NOT_STARTED
    case ContributionStatus.CONTRIBUTION_INCOMPLETE:
    case ContributionStatus.PAY_IN_METHOD_REJECTED:
      return ParticipantStepStatus.STARTED
    case ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION:
      return ParticipantStepStatus.COMPLETED
    case ContributionStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION:
      return ParticipantStepStatus.PROCESSING
    case ContributionStatus.PAY_IN_METHOD_VERIFIED:
    case ContributionStatus.COMPLETED:
      return ParticipantStepStatus.FINALIZED
    default:
      return ParticipantStepStatus.NOT_STARTED
  }
}

type Contribution = {
  userId: string
  status: ContributionStatus
  amount: number | null
  payInMethodId: string | null
  payInMethodType: ContributionPayInMethodType | null
  isWaived: boolean
}

export { Contribution, ContributionStatus, ContributionPayInMethodType, inferApplicantStepStatus }
