import { lighten, toPx } from '@guiker/components-core'
import { theme } from '@guiker/components-library'

import { StripeError } from './types'

export type CardElementProps<T> = T & {
  label: string
  inputError: StripeError
}

export type RenderProps = {
  onFocus: () => void
  onBlur: () => void
}

export const buildCardElementOptions = {
  style: {
    base: {
      fontWeight: '400',
      color: theme.palette.grey[60],
      fontFamily: theme.typography.fontFamilies.body.normal,
      fontStyle: 'normal',
      letterSpacing: 'normal',
      fontSize: toPx(16),
      lineHeight: toPx(24),
    },
    invalid: {
      color: theme.palette.status.alert.main,
    },
  },
  placeholder: '',
}

export const cardElementStyle = {
  inputContainer: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(2),
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: `transparent`,
    cursor: 'text',
    '&:hover': {
      backgroundColor: theme.palette.grey[10],
    },
    '& .StripeElement': {
      width: '100%',
    },
  },
  focused: {
    borderColor: `${theme.palette.common.black} !important`,
  },
  inputError: {
    borderColor: lighten(theme.palette.status.alert.main, 0.45),
    backgroundColor: lighten(theme.palette.status.alert.background, 0.65),
    color: theme.palette.status.alert.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.status.alert.background, 0.65),
    },
  },
}
