import { yup } from '@guiker/yup-util'

export const docusignConfigSchema = yup
  .object({
    accountId: yup.string().required(),
    clientId: yup.string().required(),
    userId: yup.string().required(),
    managementAccount: yup
      .object({
        email: yup.string().required(),
        name: yup.string().required(),
      })
      .required(),
  })
  .required()
