import { AuthLevel, buildPaths, generateApiPath } from '@guiker/api-definition'

const prefix = AuthLevel.ADMIN

const paths = {
  ...buildPaths(
    {
      batchCharge: {
        invoice: {},
      },
      fundingAccount: {
        deposit: {},
        withdrawal: {},
      },
      user: {
        fundingAccount: {},
      },
    },
    AuthLevel.ADMIN,
  ),
  paymentMethod: generateApiPath({ prefix, name: 'payment-method' }),
  invoice: generateApiPath({ prefix, name: 'invoice' }),
  tax: generateApiPath({ prefix, name: 'tax' }),
  fundingAccount: generateApiPath({ prefix, name: 'funding-account' }),
}

export { paths }
