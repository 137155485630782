import { TypeOf, yup } from '@guiker/yup-util'

import { TenantApplicationGuarantor } from '../../../entity'
import {
  inviteGuarantorSchema,
  tenantApplicationBackgroundCheckSchema,
  tenantApplicationGuarantorSchema,
} from '../../schemas'

export const updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator = yup.object({
  hasGuarantor: yup.boolean().required(),
  willInviteGuarantor: yup.boolean().when('hasGuarantor', {
    is: true,
    then: yup.boolean().required(),
    otherwise: yup.boolean().nullable(),
  }),
  guarantor: tenantApplicationGuarantorSchema.when(
    ['hasGuarantor', 'willInviteGuarantor'],
    (
      hasGuarantor: boolean,
      willInviteGuarantor: boolean,
      schema: yup.ObjectSchema<Partial<TenantApplicationGuarantor>>,
    ) => {
      return hasGuarantor
        ? willInviteGuarantor
          ? inviteGuarantorSchema
          : schema.required()
        : schema.nullable().transform(() => null as null)
    },
  ),
  backgroundCheck: tenantApplicationBackgroundCheckSchema,
})

export type UpdateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator = TypeOf<
  typeof updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator
>
