import { CountByStatusMetadata, routesBuilder } from '@guiker/shared-framework'

import {
  FundingAccountDeposit,
  FundingAccountDepositProjection,
  FundingAccountDepositStatus,
} from '../../../../entity/funding-account'
import { paths } from '../../paths'
import { getStatusesSchemas } from '../../schemas/status-schema'
import { createSystemDepositSchema, searchStringSchema } from '../schemas'

const prjectionBuilder = routesBuilder<FundingAccountDepositProjection>()({
  basePath: paths.fundingAccounts.deposits.path,
})
const builder = routesBuilder<FundingAccountDeposit>()({
  basePath: paths.fundingAccounts.deposits.path,
})

export const routes = {
  readAllDeposits: prjectionBuilder.buildGet({
    paginated: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountDepositStatus>()),
    additionalMeta: {} as CountByStatusMetadata<FundingAccountDepositStatus>,
  }),
  exportDeposits: prjectionBuilder.buildGet({
    path: '/export',
    responseIsArray: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountDepositStatus>()),
  }),
  createSystemDeposit: builder.buildPost({
    payloadSchema: createSystemDepositSchema,
  }),
} as const
