import { CountryCode } from '@guiker/base-entity'

import { UserMetadata } from '../entity'

export const getRegion = (metadata: Pick<UserMetadata, 'identity'>) => {
  if (!metadata?.identity) {
    return CountryCode.CA
  }

  return (
    metadata.identity.asOwner.regions[0] ||
    metadata.identity.asManagement.regions[0] ||
    metadata.identity.asAgent.regions[0]
  )
}

export const hasProperty = (metadata: Pick<UserMetadata, 'identity'>) =>
  metadata?.identity?.asInvestor?.holds?.properties > 0

export const hasRentalListing = (metadata: Pick<UserMetadata, 'identity'>) => {
  const { asOwner, asManagement } = { ...metadata?.identity }
  return (asManagement?.holds?.listings ?? 0) + (asOwner?.holds?.listings ?? 0) > 0
}

export const canAccessRentalListing = (metadata: Pick<UserMetadata, 'identity'>) => {
  const { asAgent } = { ...metadata?.identity }
  return hasRentalListing(metadata) || (asAgent?.holds?.listings ?? 0) > 0
}

export const hasPropSharingInvestments = (metadata: Pick<UserMetadata, 'identity'>) =>
  metadata?.identity?.asInvestor?.holds?.stockOrders > 0
