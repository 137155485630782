import React, { createContext } from 'react'

import { PayInMethodContextProvider, usePayInMethodContext } from '@guiker/payment-context'
import {
  computeInvoiceTotalAmount,
  getOnlineTransactionThreshold,
  getSupportedCountryFromCurrency,
  Invoice,
  InvoiceStatus,
  invoiceTypeChecker,
  isInvoiceCancelable,
  isInvoiceFailed,
  isInvoicePayable,
  isInvoiceRefundable,
  isInvoiceSuccess,
  PayInMethodType,
  SupportedCountries,
  Tax,
  Transaction,
} from '@guiker/payment-shared'
import { FullScreenSpinner } from '@guiker/react-framework'
import { compact, CurrencyISO, Event, last } from '@guiker/shared-framework'

import { PayInMethodModals } from '../components/PayInMethodModals/PayInMethodModals'

type Context = {
  isLoading: boolean
  invoice: Invoice
  invoiceState: {
    isCancelable: boolean
    isSucceed: boolean
    isFailed: boolean
    isPayable: boolean
    isRefundable: boolean
    isPaidOffline: boolean
    isPendingWireTransfer: boolean
  }
  lastTransaction: Transaction
  taxes: Tax[]
  totalAmount: number
  allowedPayInMethodTypes: PayInMethodType[]
  usedPayInMethodType: PayInMethodType
  region: SupportedCountries
  events: Event<Invoice>[]
}

type InvoiceContextProviderProps = React.PropsWithChildren & {
  invoice: Invoice
  isLoading: boolean
  taxes: Tax[]
  events?: Event<Invoice>[]
  fetchPayInMethods?: boolean
}

type InvoiceContextProviderWrappedProps = React.PropsWithChildren & {
  invoice: Invoice
  events?: Event<Invoice>[]
  isLoading: boolean
  taxes: Tax[]
  allowedPayInMethodTypes: PayInMethodType[]
  region: SupportedCountries
}

export const InvoiceContext = createContext<Context>(undefined)

export const InvoiceContextProviderWrapped: React.FC<InvoiceContextProviderWrappedProps> = ({
  taxes,
  events,
  invoice,
  children,
  isLoading,
  region,
  allowedPayInMethodTypes,
}) => {
  const { selected: payInMethod } = usePayInMethodContext()
  const lastTransaction = last(invoice?.transactions)
  const paidMethodType = lastTransaction?.payInMethod?.type
  const totalAmount = computeInvoiceTotalAmount(invoice)
  const payInMethodType =
    payInMethod?.userId === invoice?.customer.id ? payInMethod?.type || paidMethodType : paidMethodType

  const isPayable = isInvoicePayable(invoice)
  const isSucceed = isInvoiceSuccess(invoice)
  const isFailed = isInvoiceFailed(invoice)
  const isCancelable = isInvoiceCancelable(invoice)
  const isRefundable = isInvoiceRefundable(invoice)
  const isPaidOffline = isSucceed && paidMethodType === PayInMethodType.OFFLINE

  const threshold = getOnlineTransactionThreshold({ scopeType: invoice.scope?.type, currency: invoice.currency })
  const isWireTransfer = invoice?.status === InvoiceStatus.PENDING && paidMethodType === PayInMethodType.OFFLINE
  const isThresholdBreached = (totalAmount ?? 0) > threshold

  const value = {
    allowedPayInMethodTypes,
    usedPayInMethodType: payInMethodType,
    invoice,
    lastTransaction,
    region,
    taxes,
    events,
    totalAmount,
    isLoading,
    invoiceState: {
      isCancelable,
      isSucceed,
      isFailed,
      isRefundable,
      isPayable,
      isPaidOffline,
      isPendingWireTransfer: isWireTransfer || isThresholdBreached,
    },
  }

  return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>
}

const getContextProps = (invoice: Invoice) => {
  const region = getSupportedCountryFromCurrency(invoice.currency)
  const { scope: scopeCheck } = invoiceTypeChecker
  const isCAD = region === SupportedCountries.Canada && invoice.currency === CurrencyISO.CAD
  const isUSD = region === SupportedCountries.UnitedStates && invoice.currency === CurrencyISO.USD

  const allowedPayInMethodTypes = compact([
    (scopeCheck.isTenantInstalment(invoice) || scopeCheck.isNone(invoice)) && PayInMethodType.CREDIT_CARD,
    isUSD && PayInMethodType.DIRECT_DEBIT_ACH,
    isCAD && scopeCheck.isBuyStockOrder(invoice) && PayInMethodType.FUNDING_ACCOUNT,
    isCAD && invoice.metadata['isLegacy'] !== 'true' && PayInMethodType.DIRECT_DEBIT_PAD,
  ])

  return { region, allowedPayInMethodTypes }
}

export const InvoiceContextProvider: React.FC<InvoiceContextProviderProps> = ({
  fetchPayInMethods = true,
  invoice,
  children,
  ...props
}) => {
  if (!invoice) {
    return <FullScreenSpinner />
  }

  const { allowedPayInMethodTypes, region } = getContextProps(invoice)
  const lastTransaction = last(invoice?.transactions)
  const paidMethodId = lastTransaction?.payInMethod?.id

  return (
    <PayInMethodContextProvider
      enabled={fetchPayInMethods}
      region={region}
      allowedTypes={allowedPayInMethodTypes}
      selectedId={paidMethodId}
    >
      <InvoiceContextProviderWrapped
        invoice={invoice}
        allowedPayInMethodTypes={allowedPayInMethodTypes}
        region={region}
        {...props}
      >
        {children}
      </InvoiceContextProviderWrapped>
      <PayInMethodModals />
    </PayInMethodContextProvider>
  )
}
