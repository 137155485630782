export enum PayoutCollectionKpiKey {
  PAID = 'PAID',
  PENDING = 'PENDING',
}

type RentCollection = {
  complete: number
  incomplete: number
}

type PayoutCollection = {
  last: {
    amount: number
    expectedPayoutDate: string
  }
  next: {
    amount: number
    expectedPayoutDate: string
  }
}

export type SummaryCollectionKpi = {
  rentCollection: RentCollection
  payoutSummary: PayoutCollection
}
