import { Errors, StatusCodes } from '@guiker/common'

export class UnprocessableServiceFeeTypeError extends Errors.BaseError {
  constructor() {
    super(
      `Uunprocessable service fee type for FIXED AMOUNT`,
      'UnprocessableServiceFeeTypeError',
      StatusCodes.UNPROCESSABLE_ENTITY,
    )
  }
}
