import { idNestedParamsValidator, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { Property } from '../../../entity'
import {
  completePropertyCreationPayloadSchema,
  updatePropertyAndBuildingSchema,
  updatePropertyBuildingAddressSchema,
} from '../schemas'
import { paths } from './paths'

export const propertyRoutes = {
  createProperty: {
    path: paths.property.all.base(),
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: validatorBuilder.buildResponseValidator<Property>(),
  },
  upsertPropertyBuilding: {
    path: `${paths.property.all.withId()}/buildings`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updatePropertyBuildingAddressSchema.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<Property>(),
  },
  readOneProperty: {
    path: paths.property.all.withId(),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: validatorBuilder.buildResponseValidator<Property>(),
  },
  readAllProperties: {
    path: paths.property.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    queryParamsValidator: validatorBuilder.buildPaginationQueryParamValidator(),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<Property, {}>(),
  },
  completePropertyCreation: {
    path: `${paths.property.all.withId()}/units`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => completePropertyCreationPayloadSchema.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<Property>(),
  },
  updatePropertyAndBuilding: {
    path: `${paths.property.all.withId()}/building`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updatePropertyAndBuildingSchema.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<Property>(),
  },
}
