import { routesBuilder } from '@guiker/api-definition'

import { Invoice } from '../../../../../../../entity'
import { paths } from '../../../../../paths'

const { buildGet } = routesBuilder<Invoice>()({
  basePath: `${paths.invoice.all.withScope('users', ':userId')}/pay-in-methods/:payInMethodId`,
})

export const routes = {
  readAllInvoicesByUserAndPaymentMethod: buildGet({
    paginated: true,
  }),
}
