import { HttpMethod } from '@guiker/http'

import { Profile } from '../../../entity'
import { paths } from '../paths'

const BASE_PATH = paths.profile.one.base()

const responseValidator = (res: unknown) => Promise.resolve(res as Profile)

export const routes = {
  readOrCreateBackgroundCheckProfile: {
    path: BASE_PATH,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: responseValidator,
  },
}
