import { ValueOfUnion } from '@guiker/ts-utils'
import { yup } from '@guiker/yup-util'

import { ChannelStatus, ChannelType } from '../../../../entity'

const base = {
  channelType: yup.mixed<ChannelType>().oneOf(Object.values(ChannelType)).required(),
  channelStatus: yup
    .mixed<ValueOfUnion<ChannelStatus[ChannelType]>>()
    .oneOf(
      Object.values(ChannelType).reduce(
        <CT extends ChannelType>(acc: ValueOfUnion<ChannelStatus[ChannelType]>[], channelType: CT) => [
          ...acc,
          ...Object.values(ChannelStatus[channelType]),
        ],
        [] as ValueOfUnion<ChannelStatus[ChannelType]>[],
      ),
    )
    .required(),
}

export const updateNotificationStatusSchema = yup.object(base)

export const updateManyNotificationStatusSchema = yup.object({
  ids: yup.array().of(yup.string()),
  ...base,
})
