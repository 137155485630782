import { Scope } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'
import { Payer, TenantInstalment, TenantInstalmentStatus, TotalRent, Unit } from '@guiker/rent-payment-shared'

import { RentCollectionStatus } from './rent-collection-status'

export type PayerWithTenantInstalment = Payer & {
  amount: number
  tenantInstalment: TenantInstalment
  payable: number
  receivable: number
  room?: string
}

export type RentCollectionStatusGroupName = 'all' | 'received' | 'collected' | 'processing' | 'bad' | 'pending'

export const RentCollectionStatusGroups: { [key in RentCollectionStatusGroupName]: RentCollectionStatus[] } = {
  all: [],
  received: [RentCollectionStatus.received, RentCollectionStatus.terminated],
  collected: [RentCollectionStatus.collected, RentCollectionStatus.paidOffline],
  pending: [RentCollectionStatus.pending],
  processing: [RentCollectionStatus.processing],
  bad: [RentCollectionStatus.overdue, RentCollectionStatus.failed],
}

export type RentCollectionTableDataPoint = {
  id: string
  status: RentCollectionStatus
  scope: Scope
  unit: Unit
  payer: PayerWithTenantInstalment
  total: TotalRent
  createdAt: string
  updatedAt: string
}

export type RentCollectionCountByStatus = {
  status: RentCollectionStatus
  count: number
}

export type CSVBody = {
  streetNumber: string
  streetName: string
  unit: string
  room: string
  tenantFullName: string
  tenantEmail: string
  currency: CurrencyISO
  rent: number
  receivable: number
  status: RentCollectionStatus
}

export { TenantInstalment, TenantInstalmentStatus }
