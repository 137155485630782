import { BaseTrigger } from './base-trigger'

export type ComplexAction<T extends string = string, P = unknown> = {
  type: T
  payload: P
}

export type Action = string | ComplexAction

export type Actions<T extends { [key in string]: Action }> = {
  [K in keyof T]: T[K] extends ComplexAction ? ComplexAction<string & K, T[K]['payload']> : K
}

export type InferPayload<T, K extends keyof T> = T[K] extends ComplexAction ? T[K]['payload'] : never

export const isComplexAction = (action: unknown): action is ComplexAction => {
  if (typeof action === 'string') {
    return false
  }

  const assumed = action as ComplexAction
  return !!assumed.type && !!assumed.payload
}

export type Trigger<
  Payload = unknown,
  Context extends string = string,
  TargetType extends string = string,
  Action extends string = string,
> = BaseTrigger<Context, TargetType, Action, Payload> & {
  id?: string
  uuid: string

  correlatedRequestId: string
  sourceUserId?: string
  serviceName: string
  stage: string
}
