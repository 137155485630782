import React from 'react'

import { Applicant, Booking, ParticipantStepStatus } from '@guiker/booking-shared'
import { useTranslation } from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'
import { getApplicantName } from '../../../../utils'
import { StatusComponent } from './StatusComponent'

type RoommateStatusProps = {
  booking: Booking
  applicant: Applicant
}

const RoommateStatus: React.FC<RoommateStatusProps> = ({ booking, applicant }) => {
  const { t } = useTranslation(['main-booking'])
  const { bookingUser } = useBookingContext()
  const userId = applicant.userId
  const status = bookingUser.steps.roommate?.status
  const applicantName = getApplicantName({ booking, userIdOrEmailAddress: userId, t })

  return (
    <StatusComponent
      signeeName={applicantName}
      status={t(`unitManagerBookingSteps.roommateStatus.${status}`)}
      checked={[ParticipantStepStatus.COMPLETED, ParticipantStepStatus.FINALIZED].includes(status)}
      failed={ParticipantStepStatus.FAILED === status}
    />
  )
}

export { RoommateStatus }
