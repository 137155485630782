import React, { createContext, useEffect, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { useConfig } from '@guiker/config-context'
import * as Sentry from '@sentry/browser'

/**
 * https://sentry.io/settings/guiker/projects/main-ui/security-and-privacy/
 * @params trusted - added to Safe Fields
 */
type CaptureException = {
  error: Error
  errorInfo?: Record<string, string>
  trusted?: string
  correlatedRequestId?: string
}

type CaptureMessage = {
  message: string
  messageInfo?: Record<string, string>
  trusted?: string
  correlatedRequestId?: string
}

type Context = {
  captureException: (error: CaptureException) => void
  captureMessage: (message: CaptureMessage) => void
  showReportDialog: (options?: Sentry.ReportDialogOptions) => void
}

const SentryContext = createContext<Context>(undefined)

const parseUser = (user: { id?: string; emailAddress?: string }) => ({
  id: user?.id,
  email: user?.emailAddress,
})

const SentryContextProvider: React.FC<React.PropsWithChildren & { serviceName: string }> = ({
  serviceName,
  children,
}) => {
  const { sentry, stage, logger } = useConfig()
  const [initialized, setInitialized] = useState(false)

  const { user: authContextUser } = useAuthenticationContext({ shouldThrowIfUndefined: false })
  const { claims } = useClaimsAuthenticationContext({ shouldThrowIfUndefined: false })
  const user = authContextUser || claims || { id: 'unknown', emailAddress: 'unknown' }

  useEffect(() => {
    if (sentry.enabled) {
      Sentry.init({
        dsn: sentry.dsn,
        environment: stage,
      })
      setInitialized(true)
    }
  }, [sentry.enabled])

  const captureException = ({ error, errorInfo, trusted, correlatedRequestId }: CaptureException) => {
    if (initialized) {
      Sentry.withScope((scope) => {
        ;(errorInfo || trusted) && scope.setExtras({ ...errorInfo, trusted })
        scope.setTag('serviceName', serviceName)
        scope.setTag('correlatedRequestId', correlatedRequestId)
        scope.setUser(parseUser(user))
        Sentry.captureException(error)
      })
    } else {
      logger.log('SentryContext:captureException', error, errorInfo)
    }
  }

  const captureMessage = ({ message, messageInfo, trusted, correlatedRequestId }: CaptureMessage) => {
    if (initialized) {
      Sentry.withScope((scope) => {
        ;(messageInfo || trusted) && scope.setExtras({ ...messageInfo, trusted })
        scope.setTag('serviceName', serviceName)
        scope.setTag('correlatedRequestId', correlatedRequestId)
        scope.setUser(parseUser(user))
        Sentry.captureMessage(message)
      })
    } else {
      logger.log('SentryContext:captureMessage', message, messageInfo)
    }
  }

  const showReportDialog = (options?: Sentry.ReportDialogOptions) => Sentry.showReportDialog(options)

  const contextValues = {
    captureException,
    captureMessage,
    showReportDialog,
  }

  return <SentryContext.Provider value={contextValues}>{children}</SentryContext.Provider>
}

export { SentryContext, SentryContextProvider }
