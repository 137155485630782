import { EventType } from './event-type'

export type GeneratedEvents<ET extends Record<string, EventType>, C extends string, E extends string, D> = {
  [key in keyof ET]: {
    context: C
    entity: E
    type: ET[key] extends { type: string } ? ET[key]['type'] : ET[key]
    eventData: ET[key] extends { eventData: unknown } ? ET[key]['eventData'] : Partial<D>
    data: D
  }
}

export const generateEventsFromEnum =
  <C extends string, E extends string, D>({ context, entity, data }: { context: C; entity: E; data?: D }) =>
  // specData?: D,
  <ET extends Record<string, EventType>>(eventTypes: ET) => {
    return Object.keys(eventTypes).reduce((acc, eventType) => {
      const type = (eventType as unknown as { type: string }).type || eventType

      return {
        ...acc,
        [type]: {
          context,
          entity,
          type,
          data: data || undefined,
          eventData: undefined as Partial<D>,
          // ...(specData || data ? { data: specData || data } : {}),
        } as const,
      } as const
    }, {}) as {
      [key in keyof ET]: {
        context: C
        entity: E
        type: ET[key] extends { type: string } ? ET[key]['type'] : ET[key]
        eventData: ET[key] extends { eventData: unknown } ? ET[key]['eventData'] : Partial<D>
        data: D
      }
    }
  }
