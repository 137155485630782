import React from 'react'

import { Box, Button, Display2, HeroLayout, Link, makeStyles, useStaticAssetContext } from '@guiker/react-framework'

import { useLayoutContext, useTranslation } from '../../../hooks'

const useStyle = makeStyles(
  {
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    imageOnTop: {
      width: 609,
      maxWidth: '100%',
    },
  },
  {
    name: 'Services',
  },
)

export const ServicesHeroSection: React.FC = () => {
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('services')
  const { isMobile } = useLayoutContext()

  const classes = useStyle()
  const { t } = useTranslation(['webapp', 'common'])

  return (
    <HeroLayout
      left={
        <>
          <Display2>{t('services.hero.header')}</Display2>
          <Link isExternalLink to='#PackageSection' underline={false} hoverUnderline={false}>
            <Button size='large' fullWidth={isMobile}>
              {t('services.hero.cta')}
            </Button>
          </Link>
        </>
      }
      right={
        <Box className={classes.imageContainer}>
          <img src={assets.heroImage} className={classes.imageOnTop} height='auto' />
        </Box>
      }
    />
  )
}
