import { yup } from '@guiker/yup-util'

import { ContributionPayInMethodType } from '../../../entity'

export const updateOneBookingPaymentContributionSchema = yup.object({
  amount: yup.number().nullable(),
  payInMethodId: yup.string().nullable(),
  payInMethodType: yup.string().oneOf(Object.values(ContributionPayInMethodType)).nullable(),
})

export const reconnectCanadianPaymentMethodSchema = yup.object({
  payInMethodId: yup.string().required(),
  payInMethodType: yup.string().oneOf([ContributionPayInMethodType.DIRECT_DEBIT_PAD]).required(),
})

export type UpdateOneBookingPaymentContributionSchema = yup.InferType<typeof updateOneBookingPaymentContributionSchema>
export type ReconnectCanadianPaymentMethodSchema = yup.InferType<typeof reconnectCanadianPaymentMethodSchema>
