import { DeprecatedAmount } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'

export const CommonPurchaseCost = (currency: CurrencyISO) =>
  ({
    initialRenovation: {
      jurisdiction: 'common',
      type: 'initialRenovation',
      frequency: 'oneTimeAtPurchase',
      value: {
        type: 'flat',
        amount: {
          value: 0,
          currency,
        } as DeprecatedAmount,
      },
    },
    cashReserve: {
      jurisdiction: 'common',
      type: 'cashReserve',
      frequency: 'oneTimeAtPurchase',
      value: {
        type: 'flat',
        amount: {
          value: 0,
          currency,
        } as DeprecatedAmount,
      },
    },
    closingCost: {
      jurisdiction: 'common',
      type: 'closingCost',
      frequency: 'oneTimeAtPurchase',
      value: {
        type: 'flat',
        amount: {
          value: 0,
          currency,
        } as DeprecatedAmount,
      },
    },
    offeringCost: {
      jurisdiction: 'common',
      type: 'offeringCost',
      frequency: 'oneTimeAtPurchase',
      value: {
        type: 'flat',
        amount: {
          value: 0,
          currency,
        } as DeprecatedAmount,
      },
    },
    holdingCost: {
      jurisdiction: 'common',
      type: 'holdingCost',
      frequency: 'oneTimeAtPurchase',
      value: {
        type: 'flat',
        amount: {
          value: 0,
          currency,
        } as DeprecatedAmount,
      },
    },
  } as const)
