import { Payout, WithPayoutMethod, WithTransfers } from '@guiker/payout-shared'
import { Event, routesBuilder } from '@guiker/shared-framework'

import { paths } from '../paths'

const payout = routesBuilder<WithPayoutMethod<WithTransfers<Payout>>>()({
  basePath: paths.statistic.one.withScope('payout', ':payoutId'),
})

export const routes = {
  readOnePayout: payout.buildGet({}),
  readOnePayoutEvents: payout.buildGet({
    path: '/events',
    response: null as Event<Payout>,
    responseIsArray: true,
  }),
}
