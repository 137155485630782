import React from 'react'

import { useFieldArray, useFormContext } from '@guiker/react-hook-form'

import { AssetFile } from '../../entity'
import { BaseAssetContainer, BaseAssetContainerProps } from './BaseAssetContainer'

export type AssetContainerProps = Omit<BaseAssetContainerProps, 'assets'>

export const RHFAssetContainer: React.FC<AssetContainerProps> = ({ name, readOnly, ...props }) => {
  const { control } = useFormContext()
  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
    keyName: 'key' as unknown as 'id',
  })

  const onAddAsset = (asset: AssetFile) => {
    append(asset)
    props.onAddAsset?.(asset)
  }

  const onRemoveAsset = (index: number) => {
    remove(index)
    props.onRemoveAsset?.(index)
  }

  const onMoveAsset = (dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex)
    props.onMoveAsset?.(dragIndex, hoverIndex)
  }

  return (
    <BaseAssetContainer
      assets={fields}
      name={name}
      readOnly={readOnly}
      {...props}
      onAddAsset={onAddAsset}
      onMoveAsset={onMoveAsset}
      onRemoveAsset={onRemoveAsset}
    />
  )
}
