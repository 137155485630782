import { HttpMethod } from '@guiker/http'
import { validateUnionSync } from '@guiker/yup-util'

import { BookingPayment } from '../../../entity'
import { bookingIdSchema } from '../../schema'
import { paths } from '../paths'
import {
  bookingApplicantPathParamsSchema,
  ReconnectCanadianPaymentMethodSchema,
  reconnectCanadianPaymentMethodSchema,
  UpdateOneBookingPaymentContributionSchema,
  updateOneBookingPaymentContributionSchema,
} from '../schema'

const BOOKING_CONTRIBUTION_PATH = paths.bookingContribution.one.withScope('booking', ':bookingId')
const BOOKING_APPLICANT_CONTRIBUTION_PATH = paths.bookingContribution.one.withScopes([
  { type: 'booking', id: ':bookingId' },
  { type: 'applicant', id: ':applicantUserId' },
])
const responseValidator = (payload: unknown) => Promise.resolve(payload as BookingPayment)

export const routes = {
  updateOneBookingPaymentContribution: {
    path: BOOKING_CONTRIBUTION_PATH,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => bookingIdSchema.validate(payload),
    payloadValidator: async (payload: unknown): Promise<UpdateOneBookingPaymentContributionSchema> =>
      validateUnionSync(updateOneBookingPaymentContributionSchema)(payload),
  },
  waiveOneBookingPaymentContribution: {
    path: `${BOOKING_APPLICANT_CONTRIBUTION_PATH}/waive`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  requireOneBookingPaymentContribution: {
    path: `${BOOKING_APPLICANT_CONTRIBUTION_PATH}/require`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  /** @todo remove when migration completes */
  reconnectCanadianPaymentMethod: {
    path: `${BOOKING_APPLICANT_CONTRIBUTION_PATH}/reconnect-eft`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
    payloadValidator: async (payload: unknown): Promise<ReconnectCanadianPaymentMethodSchema> =>
      validateUnionSync(reconnectCanadianPaymentMethodSchema)(payload),
  },
}
