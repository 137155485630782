import React from 'react'

import { AuthenticationModalContextProvider } from '@guiker/authentication-components'
import { ThemeContextProvider } from '@guiker/components-core'
import { ConfigContextProvider } from '@guiker/config-context'
import { NotificationContextProvider } from '@guiker/notification-context'
import { PromotionContextProvider } from '@guiker/promotion-context'
import { GoogleMapProvider } from '@guiker/react-google-maps'
import { LocalStorageKey, PersistancyContextProvider } from '@guiker/react-persistance'
import { QueryClient, QueryClientProvider } from '@guiker/react-query'
import { DeviceContextProvider } from '@guiker/react-use-device'
import { RouterContextProvider } from '@guiker/router'
import { SentryContextProvider } from '@guiker/sentry-context'
import { WebsocketProvider } from '@guiker/use-websocket'

import { LayoutContextProvider, Page } from './components'
import { config } from './config'
import {
  AuthenticationContextProvider,
  ClaimsAuthenticationContextProvider,
  DataTrackingContextProvider,
  StaticAssetContextProvider,
} from './hooks'
import { i18n, I18nextProvider } from './i18n'
import { routes } from './routes'

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

const AppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <PersistancyContextProvider sessionKey={LocalStorageKey.WEBAPP_SESSION}>
      <ThemeContextProvider isDarkMode={false}>
        <DeviceContextProvider>
          <I18nextProvider i18n={i18n}>
            <ConfigContextProvider value={config}>
              <QueryClientProvider client={queryClient}>
                <PromotionContextProvider>
                  <ClaimsAuthenticationContextProvider>
                    <AuthenticationContextProvider>
                      <DataTrackingContextProvider>
                        <GoogleMapProvider>
                          <SentryContextProvider serviceName={'main-ui'}>
                            <RouterContextProvider
                              routes={routes}
                              unauthenticatedRedirectPath={routes.LogIn.path}
                              accountActivationRedirectPath={routes.Invited.path}
                              prefixWithLocale={true}
                            >
                              <AuthenticationModalContextProvider
                                forgetPasswordUrl={routes.RequestForgotPassword.path}
                                privacyPolicyUrl={routes.PrivacyPolicy.path}
                                termsAndConditionUrl={routes.TermsAndConditions.path}
                              >
                                <StaticAssetContextProvider>
                                  <LayoutContextProvider>
                                    <WebsocketProvider>
                                      <NotificationContextProvider>
                                        <Page>{children}</Page>
                                      </NotificationContextProvider>
                                    </WebsocketProvider>
                                  </LayoutContextProvider>
                                </StaticAssetContextProvider>
                              </AuthenticationModalContextProvider>
                            </RouterContextProvider>
                          </SentryContextProvider>
                        </GoogleMapProvider>
                      </DataTrackingContextProvider>
                    </AuthenticationContextProvider>
                  </ClaimsAuthenticationContextProvider>
                </PromotionContextProvider>
              </QueryClientProvider>
            </ConfigContextProvider>
          </I18nextProvider>
        </DeviceContextProvider>
      </ThemeContextProvider>
    </PersistancyContextProvider>
  )
}

export { AppContextProvider }
