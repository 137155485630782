import { useEffect, useRef } from 'react'

import { isEqual } from '@guiker/lodash'

export const useCompareEffect = <T>(callback: () => void, deps: T[]) => {
  const ref = useRef<T[]>([...deps])

  if (deps.some((d, index) => !isEqual(d, ref.current[index]))) {
    ref.current = [...deps]
  }

  useEffect(callback, [ref.current])
}
