import { routesBuilder } from '@guiker/api-definition'

import { NEWBuilding } from '../../../entity'
import { paths } from './paths'

const basePath = paths.NEWBuilding.all.withId(':id')
const { buildGet } = routesBuilder<NEWBuilding>()({ basePath })

export const NEWBuildingRoutes = {
  readOneNEWBuilding: buildGet(),
}
