import { CreationStatus } from '@guiker/real-estate-shared'
import { TypeOf, yup } from '@guiker/yup-util'

export const updateListingStatusSchema = yup
  .object({
    isPublished: yup.boolean().required(),
    creationStatus: yup.mixed().oneOf(Object.values(CreationStatus)).required(),
  })
  .required()

export type UpdateListingStatusSchema = TypeOf<typeof updateListingStatusSchema>
