import { yup } from '@guiker/yup-util'

import { LegalAddress } from '../../entity'

const addressRequiredCountries = ['US', 'CA']
const countryDependent = yup.string().when('country', {
  is: (country: string) => addressRequiredCountries.includes(country),
  then: yup.string().required(),
  otherwise: yup.string().nullable(),
})

export const addressSchema = yup.object<LegalAddress['address'] & { streetAddress: string }>({
  streetAddress: yup
    .string()
    .when(
      ['streetNumber', 'streetName', 'country'],
      (streetNumber: string, streetName: string, country: string, schema: yup.StringSchema) => {
        return addressRequiredCountries.includes(country) && (!streetNumber || !streetName)
          ? schema.required().test('address.streetAddress', 'Incomplete street address', () => false)
          : schema.nullable().strip(true)
      },
    ),
  suite: yup.string().nullable(),
  streetNumber: countryDependent,
  streetName: countryDependent,
  city: countryDependent,
  state: countryDependent,
  postalCode: countryDependent,
  country: yup.string().required(),
})

export const legalAddressSchema = yup.object<LegalAddress>({
  isSameAsCurrentAddress: yup.boolean().required().default(true),
  address: addressSchema.required(),
})
