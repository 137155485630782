import React from 'react'

import { useTranslation } from '@guiker/i18n'
import { Button, Display2, Flex, makeStyles, margin, RouterLink } from '@guiker/react-framework'

import { routes } from '../../../routes'

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: 980,
      alignSelf: 'center',
      textAlign: 'center',
      margin: margin(0, 'auto'),
    },
  }),
  { name: 'InvestorContactUs' },
)

type InvestorContactUsProps = {
  onClick: () => unknown
}

const InvestorContactUs: React.FC<InvestorContactUsProps> = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation(['webapp', 'common'])

  return (
    <Flex className={classes.root} gap={4} flexDirection='column' justifyContent='center'>
      <Display2>{t('webapp:investors.ourPackages.title')}</Display2>
      <RouterLink to={routes.Services.path}>
        <Button>{t('webapp:investors.ourPackages.cta')}</Button>
      </RouterLink>
    </Flex>
  )
}

export { InvestorContactUs }
