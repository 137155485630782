import { parse } from 'querystring'

/**
 * Change a format for querystring
 * @param querystring email=test@guiker.com&id=12345
 * @return { email: 'test@guiker.com', id: '12345' }
 */
const parseQuerystringToJson = <T>(querystring: string): T => {
  return JSON.parse(JSON.stringify(parse(querystring))) as T
}

export { parseQuerystringToJson }
