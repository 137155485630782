import { LeadOption, RentalListing } from '../rentalListing'

export const extractAsLeadOption = (listing: RentalListing, targetOptionId?: number) => {
  const leadOptionId = listing.leadOption?.priceOption.legacyOptionId
  if (!targetOptionId || leadOptionId === targetOptionId) return listing.leadOption

  const found = listing.rentalOptions
    .map((rentalOption) => {
      const priceOption = rentalOption.priceOptions.find((o) => o.legacyOptionId === targetOptionId)
      return { ...rentalOption, priceOption }
    })
    .filter((o) => !!o.priceOption)
    .pop()

  return (found || listing.leadOption) as LeadOption
}
