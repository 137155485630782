import { DeepPartial, Payment, yup } from '@guiker/shared-framework'

import { RentPaymentsPlan } from '../../../../../entity'

export const updateRentPaymentsPlanSchema = yup.object<DeepPartial<RentPaymentsPlan>>({
  fee: yup.object({
    type: yup.string().oneOf([Payment.RentalServiceFeeType.PERCENTAGE]).required(),
    value: yup.number().min(0).required(),
  }),
})
