import React from 'react'

import { isPaidOffline, Payout, PayoutStatus } from '@guiker/payout-shared'
import { AnyColor, Chip, useT } from '@guiker/react-framework'

type Prop = {
  payout: Omit<Payout, 'status'> & { status: PayoutStatus | 'PAID_OFFLINE' }
}

const PayoutStatusChip: React.FC<Prop> = ({ payout }) => {
  const { tShared } = useT({ domain: 'payout', entity: 'status' })

  if (!payout) return

  const getColor = (): { label: string; color: AnyColor } => {
    switch (payout.status) {
      case PayoutStatus.PENDING:
        return { color: 'info', label: 'pending' }
      case PayoutStatus.IN_TRANSIT:
        return { color: 'warning', label: 'inTransit' }
      case PayoutStatus.PAID:
        return isPaidOffline(payout as Payout)
          ? { color: 'positive', label: 'paidOffline' }
          : { color: 'success', label: 'paid' }
      case PayoutStatus.FAILED:
        return { color: 'alert', label: 'failed' }
      case 'PAID_OFFLINE':
        return { color: 'success', label: 'paidOffline' }
      default:
        return { color: 'cancelled', label: 'cancelled' }
    }
  }

  const payoutInfo = getColor()
  return (
    <Chip size='small' color={payoutInfo.color}>
      {tShared(payoutInfo.label)}
    </Chip>
  )
}

export { PayoutStatusChip }
