const config = {
  google: {
    provider: 'google',
    scriptSrc: 'https://accounts.google.com/gsi/client',
    clientId: '831385563023-3ss9nf2ladj87naehug7jn298mbqc9fo.apps.googleusercontent.com',
  },
  facebook: {
    provider: 'facebook',
    scriptSrc: 'https://connect.facebook.net/en_US/sdk.js',
    clientId: '1758459664300942',
  },
}

export { config }
