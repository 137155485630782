import React from 'react'

import { math } from '@guiker/lodash'
import { Currency, money } from '@guiker/money'

import { Flex, PBold, PSmall } from '../'

type Props = {
  label: string
  name: string
  value: number
  currency?: Currency
  type?: '%' | 'currency'
}

export const ResultItem: React.FC<Props> = ({ label, name, value, currency, type = '%' }) => {
  const valueDisplay =
    type === '%' ? `${math.decimal.round(value, 2)}%` : money.fromAmount(value, currency).toString('onlySymbol', true)

  return (
    <>
      <input type='hidden' name={name} value={value} />
      <Flex alignItems='center' justifyContent='space-between'>
        <PSmall mb={0}>{label}</PSmall>
        <PBold mb={0}>{valueDisplay}</PBold>
      </Flex>
    </>
  )
}
