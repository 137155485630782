import { CheckboxTab, TextTab } from '@guiker/docusign'
import { yup } from '@guiker/yup-util'

import { Participant, Template } from '../../../entity'
import { participant, template } from '../../schemas'

const textTabSchema = yup
  .object({
    value: yup.string().nullable(),
    tabLabel: yup.string().required(),
  })
  .nullable()

const checkboxTabSchema = yup
  .object({
    selected: yup.boolean().nullable(),
    tabLabel: yup.string().nullable(),
  })
  .nullable()

export const createEnvelopePayload = yup
  .object({
    templates: yup.array<Template>(template),
    participants: yup.array<Participant & { roleName: string }>(participant),
    redirectUrl: yup.string().required(),
    title: yup.string().required(),
    disableSend: yup.boolean().nullable(),
    includeManagementAccount: yup.boolean().nullable(),
    prefillTabs: yup
      .object({
        textTabs: yup.array<TextTab>(textTabSchema).nullable(),
        checkboxTabs: yup.array<CheckboxTab>(checkboxTabSchema).nullable(),
      })
      .nullable(),
  })
  .required()

export type CreateEnvelopePayload = yup.InferType<typeof createEnvelopePayload>
