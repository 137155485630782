import React, { useEffect } from 'react'

import { Flex, Modal, P, PSmaller, useDateFormatter, useModal, useT } from '@guiker/react-framework'
import { RentPaymentsData } from '@guiker/rent-payment-shared'
import { sortBy } from '@guiker/shared-framework'

type Props = {
  rentPaymentsData: RentPaymentsData
}

export const HistoryModal: React.FC<Props> = ({ rentPaymentsData }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const { formatWithRelativeDateTime } = useDateFormatter()
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'rentPaymentsPlanTable' })
  const history = rentPaymentsData?.events

  useEffect(() => {
    rentPaymentsData ? openModal() : closeModal()
  }, [rentPaymentsData])

  return (
    <Modal open={isOpen} onClose={() => closeModal()} minWidth={'80%'} maxWidth={860}>
      <Flex flexDirection='column' gap={2} py={2}>
        {history?.length > 0 &&
          sortBy(history, 'createdAt').map(({ status, createdAt }, index) => (
            <Flex key={index} gap={2} justifyContent='space-between' alignItems='center' fullWidth>
              <P mb={0}>{tMain(`history.${status}`)}</P>
              <PSmaller mb={0} textAlign='right'>
                {formatWithRelativeDateTime(createdAt)}
              </PSmaller>
            </Flex>
          ))}
      </Flex>
    </Modal>
  )
}
