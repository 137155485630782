import { routeBuilderHelper, routesBuilder } from '@guiker/shared-framework'

import { InvestorProfile, InvestorProfileReviewalStatus } from '../../entity'
import { paths } from './paths'
import { approveInvestorProfileSchema } from './schemas'

const { buildGet } = routesBuilder<InvestorProfile>()({
  basePath: paths.investorProfiles.path,
})

const idNested = routesBuilder<InvestorProfile>()({
  basePath: paths.investorProfiles.withId.path,
})

const userNested = routesBuilder<InvestorProfile>()({
  basePath: paths.users.withId.investorProfile.path,
})

export const routes = {
  revealInvestorProfileTIN: idNested.buildGet({
    path: `/reveal-TIN`,
    response: null as string,
  }),
  readAllInvestorProfiles: buildGet(routeBuilderHelper.withStatus(Object.values(InvestorProfileReviewalStatus))),
  readOneInvestorProfile: idNested.buildGet({}),
  readOneInvestorProfileByUserId: userNested.buildGet({}),
  rejectInvestorProfile: idNested.buildPost({
    path: `/reject`,
  }),
  approveInvestorProfile: idNested.buildPost({
    path: '/approve',
    payloadSchema: approveInvestorProfileSchema,
  }),
}
