import React from 'react'

import {
  Collapse,
  FormSection,
  Link,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { OccupationType, TenantApplication } from '@guiker/tenant-application-shared'

type OccupationContentProps = {
  defaultValue: Partial<TenantApplication>
  schema?: yup.ObjectSchema
  editRoute?: string
  readOnly?: boolean
}

const OccupationContent: React.FC<OccupationContentProps> = ({ defaultValue, schema, editRoute, readOnly = false }) => {
  const { t } = useTranslation(['main-tenantApplication'])

  const occupation = useWatch({
    name: 'occupation.type',
    defaultValue: defaultValue?.occupation?.type ?? OccupationType.PROFESSIONAL,
  })

  const inputProps = useGetInputProps({
    namespaces: 'main-tenantApplication',
    formPrefix: 'occupation',
    tPrefix: 'occupation.type',
    defaultValue,
    schema,
    readOnly,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
  })

  return (
    <FormSection
      title={t('occupation.title')}
      topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
    >
      <RadioGroup
        name={'occupation.type'}
        defaultValue={occupation || defaultValue?.occupation?.type}
        options={Object.keys(OccupationType).map((type) => ({
          value: type,
          label: t(`occupation.type.${type.toLowerCase()}.title`),
        }))}
        maxWidth='100%'
        readOnly={readOnly}
        condensed={readOnly}
        mb={0}
      />
      <Collapse in={occupation === 'STUDENT'} unmountOnExit={true}>
        <TwoColumnsGridLayout>
          <TextField {...inputProps('student.studyProgram')} />
          <TextField {...inputProps('student.school')} />
        </TwoColumnsGridLayout>
      </Collapse>
      <Collapse in={occupation === 'PROFESSIONAL'} unmountOnExit={true}>
        <TwoColumnsGridLayout>
          <TextField
            {...inputProps('professional.annualSalary', {
              i18nKey: 'annualSalary',
              namespace: 'main-tenantApplication:user',
            })}
          />
          <TextField
            {...inputProps('professional.linkedIn', {
              i18nKey: 'linkedIn',
              namespace: 'main-tenantApplication:user',
            })}
          />
        </TwoColumnsGridLayout>
      </Collapse>
    </FormSection>
  )
}

export { OccupationContent }
