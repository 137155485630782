import { RentalListing } from '..'

type ListingAvailability = 'listed' | 'unlisted' | 'rentedOut'

const getListingAvailability = (listing: RentalListing): ListingAvailability => {
  if (!listing || listing.status === 'UNLISTED') {
    return 'unlisted'
  }
  if (listing?.rentalOptions?.length === 0) {
    return 'rentedOut'
  }
  return 'listed'
}

export { ListingAvailability, getListingAvailability }
