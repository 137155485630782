import React, { useEffect } from 'react'

import { Controller, useFormContext, useWatch } from '@guiker/react-hook-form'

export const RHFAssetInput: React.FC<{ name: string; value: unknown }> = ({ name, value }) => {
  const formDefaultValue = useWatch({ name, defaultValue: value })
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue(name, value)
  }, [value])

  return (
    <Controller
      name={name}
      defaultValue={formDefaultValue}
      render={({ field }) => <input {...field} type='hidden' />}
    />
  )
}
