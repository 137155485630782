import { TypeOf, yup } from '@guiker/yup-util'

import { initials, signature } from '../../schemas'

export const invitedLessorSignatureAndInitials = yup.object({
  // For Step B :: Description and destination
  dwelling: yup.object({
    smokeDetectorsInitials: initials.required(),
  }),

  // For Step G :: Notice to a new lessee
  newLesseeNotice: yup.object({
    lessorSignature: signature.required(),
  }),

  // For Step H :: Signature fields
  signature: signature,
  initials: initials,
})

export type InvitedLessorSignatureAndInitials = TypeOf<typeof invitedLessorSignatureAndInitials>
