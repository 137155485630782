export type LegacyApplicant = {
  id: number
  user: {
    id: number
    email: string
    firstName: string
    lastName: string
    status: UserStatus
    isAdmin: boolean
    accessToken: string | null
  }
}

export enum UserStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DENY = 'DENY',
  DELETED = 'DELETED',
}
