import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { DeepPartial } from '@guiker/ts-utils'

import { StockOrder } from './stock-order'

export enum PropSharingInquiryStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
}

export type PropSharingInquiry = {
  id: string
  listingId: string
  user: {
    userId: string
    emailAddress: string
    firstName: string
    lastName: string
  }
  unitAmount: number
  assumptions?: Partial<InvestmentAssumptions.Assumptions>
  results?: InvestmentAssumptions.Results
  stockOrder?: DeepPartial<StockOrder>
  status: PropSharingInquiryStatus
}

export type PropSharingInquiryWithStockOrder = PropSharingInquiry & {
  stockOrder: StockOrder
}
