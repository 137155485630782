import React from 'react'

import { FileRejection } from 'react-dropzone'

import { FeedbackComponent } from '@guiker/react-framework'

export type Props = React.PropsWithChildren & {
  fileRejections: FileRejection[]
}

export const DropzoneFeedback: React.FC<Props> = ({ fileRejections = undefined }) => {
  return (
    <FeedbackComponent
      toasterMessage={fileRejections
        .map(({ file, errors }) => `${file.name} - ${errors.map((e) => e.message)}`)
        .join('\n')}
      toasterDuration={5000}
    />
  )
}
