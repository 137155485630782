import { Payment, yup } from '@guiker/shared-framework'

export const chargeInvoicePayloadSchema = yup.object({
  payInMethod: yup
    .object({
      id: yup.string().required(),
      type: yup.string().oneOf(Object.values(Payment.PayInMethodType)).required(),
    })
    .required(),
})

export type ChargeInvoicePayloadSchema = yup.InferType<typeof chargeInvoicePayloadSchema>
