import React from 'react'

import { Divider, Flex, P, PSmall, useT } from '@guiker/react-framework'
import { Task } from '@guiker/task-shared'

import { Category } from '../Category'
import { IndividualTask } from '../IndividualTask'

type TaskListProps = {
  tasks?: (Task | Task[])[]
}

export const TaskList: React.FC<TaskListProps> = ({ tasks }) => {
  const { tShared } = useT({ domain: 'task' })

  return (
    <Flex flexDirection='column' gap={1}>
      {tasks?.length > 0 ? (
        tasks.map((task, index) => (
          <React.Fragment key={index}>
            {Array.isArray(task) ? <Category tasks={task} /> : <IndividualTask task={task} />}
            {index < tasks?.length - 1 && <Divider />}
          </React.Fragment>
        ))
      ) : (
        <>
          <P mb={0} fontWeight={500}>
            {tShared('tasks.emptyState.label')}
          </P>
          <PSmall mb={0} color={60}>
            {tShared('tasks.emptyState.description')}
          </PSmall>
        </>
      )}
    </Flex>
  )
}
