import { yup } from '@guiker/yup-util'

export const scopeSchema = yup
  .object({
    id: yup
      .string()
      .required()
      .when('$scopeId', (scopeId: string, schema: yup.StringSchema) =>
        scopeId ? schema.test('scopeId', 'mismatch scope Id', (id: string) => id === scopeId) : schema,
      )
      .required(),
    type: yup
      .string()
      .required()
      .when('$scopeType', (scopeType: string, schema: yup.StringSchema) =>
        scopeType
          ? schema.test('scopeType', 'mismatch scope type', (type: string) => type === scopeType.toLowerCase())
          : schema,
      )
      .required(),
  })
  .required()

export type ScopeSchema = yup.InferType<typeof scopeSchema>
