import { TypeOf, yup } from '@guiker/yup-util'

import { keyDeposit } from './key-deposit'
import { rentDeposit } from './rent-deposit'
import { rentDiscounts } from './rent-discounts'

export const rentDepositRentDiscountsAndKeyDeposit = yup.object({
  keyDeposit: keyDeposit,
  rentDeposit: rentDeposit,
  rentDiscounts: rentDiscounts,
})

export type RentDepositRentDiscountsAndKeyDeposit = TypeOf<typeof rentDepositRentDiscountsAndKeyDeposit>
