import React from 'react'

import { Flex, PBold, PSmall } from '@guiker/components-library'

type ProjectAttributeProp = {
  label: string
  value: string | number
}

const ProjectAttribute: React.FC<ProjectAttributeProp> = ({ label, value }) => {
  return (
    <Flex flexDirection='column' gap={3}>
      <PSmall mb={0}>{label}</PSmall>
      <PBold mb={0}>{value}</PBold>
    </Flex>
  )
}

export { ProjectAttribute }
