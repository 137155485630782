import { optionalConcat } from '@guiker/lodash'

type NameLike = {
  firstName: string
  middleName?: string
  lastName?: string
}

export const getUserFullName = <N extends NameLike>(legalName?: N) => {
  return optionalConcat([legalName?.firstName, legalName?.middleName, legalName?.lastName], ' ')
}
