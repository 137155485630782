import { getBankByRouting, money } from '@guiker/money'
import {
  CREDIT_CARD_TRANSACTION_RATE,
  getCurrencyFromSupportedCountry,
  PayInMethod,
  payInMethodInfoBuilder,
  SupportedCountries,
  typeChecker,
} from '@guiker/payment-shared'

import { useT } from '../i18n'

export const useGetPayInMethodLabel = () => {
  const { tShared } = useT()

  return (payInMethod: PayInMethod, options: { hideCCBrand?: boolean } = {}): string => {
    if (!payInMethod) return ''

    const { getLastDigits } = payInMethodInfoBuilder(payInMethod)
    const lastDigits = tShared('endingWith', { lastDigits: getLastDigits() })
    const currency = getCurrencyFromSupportedCountry(payInMethod.region)

    if (typeChecker.isCreditCard(payInMethod)) {
      return options.hideCCBrand
        ? lastDigits
        : `${tShared(`creditCard.brands.${payInMethod.details.brand}`)} ${lastDigits}`
    } else if (typeChecker.isEFT(payInMethod)) {
      const eftBank = getBankByRouting(payInMethod.details?.institutionId)
      return `${eftBank?.shortName ?? tShared('bank')} ${lastDigits}`
    } else if (typeChecker.isACH(payInMethod)) {
      const achBank = getBankByRouting(payInMethod.details?.routingNumber)
      return `${achBank?.shortName ?? payInMethod.details?.bankName ?? tShared('bank')} ${lastDigits}`
    } else if (typeChecker.isPAD(payInMethod)) {
      const padBank = getBankByRouting(payInMethod.details?.institutionId)
      return `${padBank?.shortName ?? payInMethod.details?.bankName ?? tShared('bank')} ${lastDigits}`
    } else if (typeChecker.isFundingAccount(payInMethod)) {
      const balance = money.fromAmount(payInMethod.details?.balance, currency).toString()
      return `${tShared('fundingAccount.label', { balance })}`
    } else if (typeChecker.isOffline(payInMethod)) {
      return `${tShared('offline')} ${payInMethod.details?.bankName ?? ''}`
    } else {
      return tShared('offline')
    }
  }
}

export const useGetPayInMethodLabelInfo = () => {
  const { tShared } = useT()

  return {
    getLabelInfo: (args: { payInMethod?: PayInMethod; region: SupportedCountries; amount?: number }) => {
      const { payInMethod, region, amount } = args
      const { id, type, details = {} } = { ...payInMethod }

      const brand = details ? details['brand'] : undefined
      const { getLastDigits } = payInMethodInfoBuilder(payInMethod)
      const lastDigits = tShared('endingWith', { lastDigits: getLastDigits() })

      const brandName =
        typeChecker.isACH(payInMethod) || typeChecker.isPAD(payInMethod)
          ? payInMethod.details?.bankName
          : typeChecker.isEFT(payInMethod)
          ? tShared('directDebit.EFT.label')
          : typeChecker.isFundingAccount(payInMethod)
          ? tShared('fundingAccount.label')
          : typeChecker.isCreditCard(payInMethod)
          ? tShared(`creditCard.brands.${brand}`)
          : tShared('offline')

      const secondaryLabel = typeChecker.isCreditCard(payInMethod)
        ? tShared('processingFee', {
            processingFeePercentage: `${CREDIT_CARD_TRANSACTION_RATE * 100}%`,
          })
        : typeChecker.isFundingAccount(payInMethod) && payInMethod.details.balance != null
        ? `${money.fromAmount(payInMethod.details.balance, getCurrencyFromSupportedCountry(region))}`
        : null

      const routingNumber = details['routingNumber'] ?? details['institutionId']
      const disabled =
        typeChecker.isFundingAccount(payInMethod) && amount != null && payInMethod.details.balance < amount

      return {
        id,
        brand,
        brandName,
        disabled: !!payInMethod?.disabled || disabled,
        lastDigits,
        routingNumber,
        secondaryLabel,
        type,
      }
    },
  }
}
