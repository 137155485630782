import { getLocationService, Listing as BaseListingEntities } from '@guiker/base-entity'
import { RentalType } from '@guiker/rental-listing-shared'
import { yup } from '@guiker/yup-util'

import { LeasePreferences } from '../../entity'

export const leasePreferences = yup.object<LeasePreferences>({
  rentalType: yup.mixed().oneOf<RentalType | null>(['ENTIRE', 'PRIVATE_ROOM', null]).nullable(),
  location: yup.string().oneOf(getLocationService().allCitySlugs).required(),
  budget: yup.number().required(),
  floorPlan: yup
    .string()
    .oneOf<BaseListingEntities.FloorPlans>([
      BaseListingEntities.FloorPlans.any,
      BaseListingEntities.FloorPlans.studio,
      BaseListingEntities.FloorPlans.oneBed,
      BaseListingEntities.FloorPlans.twoBed,
      BaseListingEntities.FloorPlans.threeBed,
      BaseListingEntities.FloorPlans.fourBed,
      BaseListingEntities.FloorPlans.fiveBed,
      null,
    ])
    .nullable(),
  leaseStart: yup.string().required(),
  furniture: yup.boolean().nullable(),
  neighbourhoods: yup.array<string>().nullable(),
})

export type LeasePreferencesSchema = yup.InferType<typeof leasePreferences>
