import React from 'react'

import { ColumnConfig, P, useT } from '@guiker/react-framework'
import { RentPaymentsData, RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'
import { money, parseDateTime } from '@guiker/shared-framework'

import { TenantInstalmentStatusChipWithTooltip } from '../TenantInstalmentStatusChip'

export const useReceiverTenantInstalmentColumns = (
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments,
): ColumnConfig<RentPaymentsData>[] => {
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'rentPaymentsPlanTable' })

  return [
    {
      label: tMain(`columns.month`),
      name: 'month',
      size: 1,
      renderValue: ({ chargeDate }) => {
        return <P m={0}>{parseDateTime(chargeDate).toFormat('dd MMM yyyy')}</P>
      },
    },
    {
      label: tMain(`columns.status`),
      name: 'status',
      size: 1,
      renderValue: (instalment) => <TenantInstalmentStatusChipWithTooltip tenantInstalment={instalment} />,
    },
    {
      label: tMain(`columns.rentAmount`),
      name: 'rentAmount',
      size: 1,
      renderValue: ({ processedWith, amount }) => {
        const currency = rentPaymentsPlan.total.currency
        const processedAmount = processedWith?.amount ?? amount ?? 0
        return <P m={0}>{money.fromAmount(processedAmount, currency).toString()}</P>
      },
    },
    {
      label: tMain(`columns.payoutAmount`),
      name: 'payoutAmount',
      size: 1,
      renderValue: ({ payout }) => {
        const currency = rentPaymentsPlan.total.currency
        return <P m={0}>{money.fromAmount(payout.amount, currency).toString()}</P>
      },
    },
    {
      label: tMain(`columns.arrivalDate`),
      name: 'arrival',
      size: 1,
      renderValue: ({ payout }) => {
        return <P m={0}>{payout.arrivalDate}</P>
      },
    },
  ]
}
