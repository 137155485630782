import {
  About,
  Careers,
  DeprecatedConversation,
  DeprecatedGeneralPolicy,
  DeprecatedInvestmentListings,
  DeprecatedLandlordsPolicy,
  DeprecatedRentersPolicy,
  DocumentSuccess,
  ExpiredLink,
  ForgotPassword,
  ForgotPasswordSuccess,
  HomeScreen,
  Investors,
  Invited,
  LogIn,
  PageNotFound,
  PrivacyPolicy,
  Redirect,
  Services,
  SignUp,
  Success,
  TermsAndConditions,
} from '../screens'
import { legacyRoutes } from '.'
import { defaultRouteConfig } from './default-config'

export const mainRoutes = {
  Home: {
    ...defaultRouteConfig,
    path: '/',
    isDarkMode: true,
    Component: HomeScreen,
  },
  rent: {
    ...defaultRouteConfig,
    path: '/rent',
    isDarkMode: true,
    Component: HomeScreen,
  },
  invest: {
    ...defaultRouteConfig,
    path: '/invest',
    isDarkMode: true,
    Component: HomeScreen,
  },
  services: {
    ...defaultRouteConfig,
    path: '/services',
    isDarkMode: true,
    Component: HomeScreen,
  },
  investLearnMore: {
    ...defaultRouteConfig,
    isDarkMode: true,
    path: '/invest/learn-more',
    Component: Investors,
  },
  LogIn: {
    ...defaultRouteConfig,
    path: '/login',
    Component: LogIn,
    displayFooter: false,
  },
  SignUp: {
    ...defaultRouteConfig,
    path: '/signup',
    Component: SignUp,
    displayFooter: false,
  },
  DocumentSuccess: {
    ...defaultRouteConfig,
    path: '/document/success',
    Component: DocumentSuccess,
    displayFooter: true,
  },
  Redirect: {
    ...defaultRouteConfig,
    path: '/redirect',
    Component: Redirect,
    displayAppBar: false,
    displayFooter: false,
  },
  RequestForgotPassword: {
    ...defaultRouteConfig,
    path: '/forgot-password',
    Component: ForgotPassword,
  },
  RequestForgotPasswordSuccess: {
    ...defaultRouteConfig,
    path: '/forgot-password-success',
    Component: ForgotPasswordSuccess,
  },
  Services: {
    ...defaultRouteConfig,
    path: '/services/learn-more',
    Component: Services,
  },
  Invited: {
    ...defaultRouteConfig,
    displayAppBar: false,
    displayFooter: false,
    path: '/invited',
    Component: Invited,
  },
  About: {
    ...defaultRouteConfig,
    path: '/about-us',
    Component: About,
  },
  Careers: {
    ...defaultRouteConfig,
    path: '/careers',
    Component: Careers,
  },
  PrivacyPolicy: {
    ...defaultRouteConfig,
    path: '/privacy-policy',
    Component: PrivacyPolicy,
  },
  Success: {
    ...defaultRouteConfig,
    path: '/success',
    Component: Success,
  },
  PageNotFound: {
    ...defaultRouteConfig,
    path: '/page-not-found',
    Component: PageNotFound,
  },
  ExpiredLink: {
    ...defaultRouteConfig,
    path: '/expired',
    Component: ExpiredLink,
  },
  TermsAndConditions: {
    ...defaultRouteConfig,
    path: '/terms-and-conditions',
    Component: TermsAndConditions,
  },
  DeprecatedPrivacyPolicy: {
    ...defaultRouteConfig,
    path: legacyRoutes.PrivacyPolicy,
    Component: DeprecatedGeneralPolicy,
  },
  DeprecatedRentersTermsAndConditions: {
    ...defaultRouteConfig,
    path: legacyRoutes.RentersTermsAndConditions,
    Component: DeprecatedRentersPolicy,
  },
  DeprecatedLandlordsTermsAndConditions: {
    ...defaultRouteConfig,
    path: legacyRoutes.LandlordsTermsAndConditions,
    Component: DeprecatedLandlordsPolicy,
  },
  DeprecatedInvestmentListings: {
    ...defaultRouteConfig,
    path: '/investment-listings/*',
    Component: DeprecatedInvestmentListings,
  },
  DeprecatedConversation: {
    ...defaultRouteConfig,
    path: '/myaccount/messages',
    Component: DeprecatedConversation,
  },
  DeprecatedInvestors: {
    ...defaultRouteConfig,
    isDarkMode: true,
    path: '/investors',
    Component: Investors,
  },
} as const
