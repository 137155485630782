import { buildWebAppRoutes, castType } from '@guiker/shared-framework'

import { config } from '..'

export const { routes: mainRoutes, pathBuilder: mainPathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        messages: {
          path: '/',
          queryParams: {
            convoId: castType<string>(),
            bookingId: castType<string>(),
          },
        },
      },
    },
  } as const,
  config.domainName,
)
