import { routesBuilder } from '@guiker/shared-framework'

import { Message } from '../../entity/legacy/v2'
import { paths } from './paths'

const basePath = paths.conversations.all
const bookingIdNested = routesBuilder<Message>()({ basePath: basePath.withScope('bookings', ':bookingId') })

export const routes = {
  readAllMessages: bookingIdNested.buildGet({
    path: '/messages',
    responseIsArray: true,
  }),
}
