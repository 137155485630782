import { Role, RoleNameRoleResolver } from '@guiker/permissions'

import { BackgroundCheckRoleNames } from './role-names'
import * as Roles from './roles'

export const BackgroundCheckRoleNamesRoleResolver: RoleNameRoleResolver<BackgroundCheckRoleNames> = ({
  roleNames,
}): Role[] => {
  if (!roleNames || roleNames.length === 0) {
    return []
  }

  const roles: Role[] = roleNames.map((name) => {
    switch (name) {
      case BackgroundCheckRoleNames.SPECTATOR:
        return Roles.SpectatorRole
      case BackgroundCheckRoleNames.APPLICANT:
        return Roles.ApplicantRole
      case BackgroundCheckRoleNames.REQUESTER:
        return Roles.RequesterRole
    }
  })

  return roles
}
