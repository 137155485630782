import { Errors, StatusCodes } from '@guiker/common'

import { BookingStatus } from '../../booking-status'

export class ExpirationTimerCannotExtendError extends Errors.BaseError {
  constructor(bookingId: string, status: BookingStatus, expiresAt: string) {
    super(
      `Booking ${bookingId} in status ${status} with expire timestamp ${expiresAt} cannot be extended`,
      'ExpirationCountdownCannotExtendError',
      StatusCodes.UNPROCESSABLE_ENTITY,
    )
  }
}
