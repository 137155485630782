import { routesBuilder } from '@guiker/shared-framework'
import { yup } from '@guiker/yup-util'

const paths = {
  stripe: '/webhook/stripe',
  transferwise: '/webhook/transferwise',
} as const

const stripe = routesBuilder<unknown>()({ basePath: paths.stripe })
const transferwise = routesBuilder<unknown>()({ basePath: paths.transferwise })

export const routes = {
  handleStripePayoutConnect: stripe.buildPost({
    path: '/connect/payout',
    payloadSchema: yup.object(),
  }),
  handleStripePayoutDirect: stripe.buildPost({
    path: '/direct/payout',
    payloadSchema: yup.object(),
  }),
  handleStripeCAConnectAccount: stripe.buildPost({
    path: '/ca/connect/account',
    payloadSchema: yup.object(),
  }),
  handleStripeUSConnectAccount: stripe.buildPost({
    path: '/us/connect/account',
    payloadSchema: yup.object(),
  }),
  handleTransferwisePayout: transferwise.buildPost({
    path: '/payout',
    payloadSchema: yup.object(),
  }),
  handleTransferwiseIssue: transferwise.buildPost({
    path: '/issue',
    payloadSchema: yup.object(),
  }),
  handleTransferwiseBalance: transferwise.buildPost({
    path: '/balance',
    payloadSchema: yup.object(),
  }),
}
