import { yup } from '@guiker/yup-util'

export const transferwiseConfigSchema = yup
  .object({
    apiToken: yup.string().required(),
    webhook: yup
      .object({
        publicKey: yup.string().required(),
      })
      .required(),
  })
  .required()
