import { BookingStatus } from '@guiker/booking-shared'

type LegacyBookingEvent =
  | {
      type: 'BOOKING_LOADED'
      /** @description height of the booking module */
      value: BookingStatus
    }
  | {
      type: 'BOOKING_UNIT_MANAGER_LEFT'
      /** @description userId of unit manager left */
      value: string
    }

const LegacyBooking = {
  pushEvent: (event: LegacyBookingEvent) => {
    // eslint-disable-next-line no-restricted-globals
    window.parent.postMessage(event, location.origin)
  },
}

export { LegacyBooking }
