import React, { useEffect } from 'react'

import { App } from '@guiker/main-app'
import { BrowserRouter } from '@guiker/router'

export const ClientApp: React.FC = () => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }
  })

  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}
