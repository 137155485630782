import { conditionallyRequiredSchema, yup } from '@guiker/yup-util'

import { Occupation, OccupationType, Professional, Student } from '../../entity'

export const tenantApplicationStudentSchema = (required: boolean) =>
  yup.object<Student>({
    studyProgram: conditionallyRequiredSchema(yup.string(), required),
    school: conditionallyRequiredSchema(yup.string(), required),
  })

export const tenantApplicationProfessionalSchema = (required: boolean) =>
  yup.object<Professional>({
    annualSalary: conditionallyRequiredSchema(yup.string(), required),
    linkedIn: yup.string().nullable(),
  })

export const tenantApplicationOccupationSchema = yup.object<Occupation>({
  type: yup.mixed().oneOf(Object.values(OccupationType)).required(),
  student: tenantApplicationStudentSchema(false).when('type', {
    is: OccupationType.STUDENT,
    then: tenantApplicationStudentSchema(true).required(),
    otherwise: tenantApplicationStudentSchema(false)
      .nullable()
      .transform(() => null as null),
  }),
  professional: tenantApplicationProfessionalSchema(false).when('type', {
    is: OccupationType.PROFESSIONAL,
    then: tenantApplicationProfessionalSchema(true).required(),
    otherwise: tenantApplicationProfessionalSchema(false)
      .nullable()
      .transform(() => null as null),
  }),
})
