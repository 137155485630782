import React, { useState } from 'react'

import { BookingActions, InvitationStatus, UnitManager } from '@guiker/booking-shared'
import {
  Button,
  ConfirmDialog,
  Modal,
  SecondaryButton,
  SuccessDialog,
  Typography,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'

import { LegacyBooking } from '../../../../../'
import { useBookingContext } from '../../../../../hooks'

type UnitManagerViewModalProps = {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  disablePortal?: boolean
} & UnitManager

const UnitManagerViewModal: React.FC<UnitManagerViewModalProps> = ({
  open,
  setOpen,
  firstName,
  lastName,
  invitation,
  role,
  userId,
}) => {
  const { t } = useTranslation('common')
  const { apiClient, bookingId, bookingUser, can } = useBookingContext()
  const canDisinviteUnitManager = can(BookingActions.DisinviteUnitManager)

  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [reminderSentModalOpen, setReminderSentModalOpen] = useState(false)

  const [, setApiErrorMsg] = useState(null)

  const { mutate: disinviteBookingUnitManager } = useMutation(
    (userId: string) => apiClient.disinviteBookingUnitManager({ pathParams: { bookingId, userId } }),
    {
      onSuccess: () => {
        setRemoveModalOpen(false)
        LegacyBooking.pushEvent({ type: 'BOOKING_UNIT_MANAGER_LEFT', value: userId })
      },
      onError: () => setApiErrorMsg(t('main-booking:errors.disinviteUnitManagersFailed')),
    },
  )

  const handleRemoveClick = () => {
    setOpen(false)
    setRemoveModalOpen(true)
  }

  const handleRemoveConfirm = async () => {
    await disinviteBookingUnitManager(userId)
    setOpen(false)
  }

  const handleRemoveCancel = () => {
    setRemoveModalOpen(false)
    setOpen(true)
  }

  const invitationPending = invitation.status === InvitationStatus.INVITED

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={`${firstName} ${lastName}`}
        subtitle={
          invitationPending
            ? t('main-booking:actions.invite.invitationPending')
            : t(`common:user.role.${role.toLowerCase()}`)
        }
        actions={
          <>
            <SecondaryButton onClick={() => setOpen(false)} fullWidth>
              {t('common:actions.close')}
            </SecondaryButton>
            {canDisinviteUnitManager && (
              <Button fullWidth onClick={handleRemoveClick}>
                {t('common:actions.remove')}
              </Button>
            )}
          </>
        }
      >
        <Typography mb={0}>{t(`common:user.role.definition_${role.toLowerCase()}`)}</Typography>
      </Modal>

      {bookingUser.type === 'unitManager' && (
        <ConfirmDialog
          open={removeModalOpen}
          description={t('main-booking:actions.remove.removeUnitManagerConfirmationMessage')}
          confirmButtonProps={{ onClick: handleRemoveConfirm, children: t('common:yes'), color: 'alert' }}
          cancelButtonProps={{ onClick: handleRemoveCancel, children: t('common:no') }}
          onClose={handleRemoveCancel}
        />
      )}

      <SuccessDialog
        open={reminderSentModalOpen}
        text={t('main-booking:actions.reminder.reminderSent')}
        buttonProps={{ onClick: () => setReminderSentModalOpen(false) }}
      />
    </>
  )
}

export { UnitManagerViewModal }
