import { buildingRoutes } from './buildings'
import { developerRoutes } from './developer'
import { NEWBuildingRoutes } from './NEWBuilding'
import { NEWPropertyRoutes } from './NEWProperty'
import { NEWPropertyOwnershipRoutes } from './NEWProperty-ownership'
import { projectRoutes } from './project'
import { propertyRoutes } from './properties'
import { propertyOwnershipRoutes } from './property-ownership'

export const routes = {
  ...buildingRoutes,
  ...developerRoutes,
  ...projectRoutes,
  ...propertyRoutes,
  ...propertyOwnershipRoutes,
  ...NEWPropertyRoutes,
  ...NEWPropertyOwnershipRoutes,
  ...NEWBuildingRoutes,
  ...NEWPropertyRoutes,
}
