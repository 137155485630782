import { routesBuilder } from '@guiker/api-definition'

import { BankAccount, Plaid } from '../../entity'
import { paths } from './paths'
import { createLinkTokenSchema, readPlaidBankAccountDetailsSchema } from './schemas'

const { buildPost } = routesBuilder()({ basePath: paths.paymentVerification.all.base() })

export const routes = {
  createPlaidLinkToken: buildPost({
    path: '/create-link-token',
    payloadSchema: createLinkTokenSchema,
    response: null as Plaid.LinkTokenResponse,
  }),
  readPlaidBankAccountDetails: buildPost({
    path: '/plaid/bank-account',
    payloadSchema: readPlaidBankAccountDetailsSchema,
    response: null as BankAccount,
  }),
  createStripeLinkToken: buildPost({
    path: '/stripe/create-link-token',
    payloadSchema: createLinkTokenSchema,
    response: null as string,
  }),
}
