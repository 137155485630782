import React from 'react'

import {
  clsx,
  ColumnConfig,
  H3,
  H4,
  makeStyles,
  P,
  PBig,
  PMedium,
  PSmaller,
  TableHeader,
  TableRow,
} from '@guiker/components-library'
import { Currency, moneyHelper } from '@guiker/shared-framework'

const useStyle = makeStyles((theme) => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
  },
  row: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderStyle: 'none',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  collapse: {
    backgroundColor: theme.palette.grey[5],
    '&:hover': {
      backgroundColor: theme.palette.grey[5],
    },
  },
  totalRow: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.others.alabaster.background,
    textAlign: 'center',
  },
}))

type BaseItem = {
  label: string
  value: number
  isPrimary?: boolean
}

type Item = BaseItem & {
  collapsibleItems?: BaseItem[]
}

type FinancialBreakdownProp = {
  currency: Currency
  items: Item[]
  total?: {
    label: string
    value: number
  }
}

const getColumns = (currency: Currency): ColumnConfig<Item>[] => {
  const { toMonetized, formatter } = moneyHelper(currency)
  const moneyFormatter = formatter('onlySymbol', true)

  return [
    {
      name: 'label',
      label: 'label',
      size: 3,
      renderValue: (item) =>
        item.collapsibleItems?.length || item.isPrimary === false ? (
          <P mb={0} textAlign='right'>
            {item.label}
          </P>
        ) : (
          <PMedium mb={0} textAlign='right'>
            {item.label}
          </PMedium>
        ),
      collapsibleValues: ({ collapsibleItems }) => collapsibleItems?.filter((i) => !!i.value),
      renderCollapsibleValue: ({ label }: { label: string }) => (
        <PSmaller mb={0} textAlign='right'>
          {label}
        </PSmaller>
      ),
    },
    {
      name: 'value',
      label: 'value',
      size: 2,
      renderValue: (item) =>
        item.collapsibleItems?.length || item.isPrimary === false ? (
          <PMedium mb={0} textAlign='right'>
            {moneyFormatter(toMonetized(item.value))}
          </PMedium>
        ) : (
          <H4 mb={0} textAlign='right'>
            {moneyFormatter(toMonetized(item.value))}
          </H4>
        ),
      collapsibleValues: ({ collapsibleItems }) => collapsibleItems?.filter((i) => !!i.value),
      renderCollapsibleValue: ({ value }: { value: number }) => (
        <P mb={0} textAlign='right'>
          {moneyFormatter(toMonetized(value))}
        </P>
      ),
    },
    {
      name: 'collapseRow',
      size: 0.2,
      renderValue: () => <div />,
    },
  ]
}

export const FinancialBreakdown: React.FC<FinancialBreakdownProp> = ({ items, currency, total }) => {
  const classes = useStyle()
  const columns = getColumns(currency)
  const { toMonetized, formatter } = moneyHelper(currency)
  const moneyFormatter = formatter('onlySymbol', true)

  return (
    <table className={classes.table}>
      <TableHeader columns={columns} showLabel={false} />
      {(items || [])
        .filter((i) => i.value || (i.collapsibleItems?.length && i.collapsibleItems.some((ci) => ci.value)))
        .map((item, index) => (
          <TableRow
            columns={columns}
            isRowCollapsible={!!item.collapsibleItems?.length}
            item={item}
            index={index}
            key={index}
            onClick={() => {}}
            className={classes.row}
            collapseClassName={clsx(classes.row, classes.collapse)}
          />
        ))}
      {total && (
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <P py={1.5} mb={0}></P>
            </td>
          </tr>
          <tr>
            <td className={classes.totalRow} colSpan={columns.length}>
              <PBig mb={2}>{total.label}</PBig>
              <H3 mb={0}>{moneyFormatter(toMonetized(total.value))}</H3>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  )
}
