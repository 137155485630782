import React, { useState } from 'react'

import { useTranslation } from '@guiker/i18n'
import { mainPathBuilder } from '@guiker/investment-shared'
import {
  Button,
  clsx,
  Display1,
  Display3,
  Flex,
  HeroLayout,
  makeStyles,
  PageLayout,
  PageMetaTags,
  RouterLink,
  useLayoutContext,
  useStaticAssetContext,
} from '@guiker/react-framework'

import {
  InvestorContactUs,
  InvestorHeadline,
  InvestorHowItWorks,
  InvestorKpis,
  InvestorModal,
  InvestorPartners,
  InvestorServices,
  InvestorWhatWeDo,
} from './components'
import { PublicUserEnquiryContextProvider } from './InvestorsScreenContext'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    heroSection: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'right',
      backgroundImage: ({ heroImage }: { heroImage: string }) => `url("${heroImage}")`,
      transition: theme.transitions.create(['background-image'], {
        duration: theme.transitions.duration.short,
      }),
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%',
      width: '100%',
    },
    image: {
      width: 765,
      maxWidth: '100%',
    },
  }),
  {
    name: 'Investors',
  },
)

export const Investors: React.FC = () => {
  const { t } = useTranslation(['webapp', 'common'])

  return (
    <>
      <PageMetaTags subtitle={t('webapp:investors.seo.subtitle')} description={t('webapp:investors.seo.description')} />
      <PublicUserEnquiryContextProvider>
        <InvestorsScreenContent />
      </PublicUserEnquiryContextProvider>
    </>
  )
}

const InvestorsScreenContent: React.FC = () => {
  const { t } = useTranslation(['webapp', 'common'])
  const { isMobile, isAtMostTablette } = useLayoutContext()
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('investors')
  const classes = useStyles({ heroImage: assets.heroImage })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <>
      <PageLayout noPaddingTop>
        <HeroLayout
          className={clsx(classes.heroSection)}
          left={
            <Flex maxWidth={765} flexDirection='column' gap={8}>
              <Flex flexDirection='column'>
                <Display1>{t('webapp:investors.hero.header')}</Display1>
                <Display3 mb={0} color={15}>
                  {t('webapp:investors.hero.subheader')}
                </Display3>
              </Flex>
              <div>
                <RouterLink to={mainPathBuilder.root.path}>
                  <Button size='large' fullWidth={isMobile}>
                    {t('investors.hero.cta')}
                  </Button>
                </RouterLink>
              </div>
            </Flex>
          }
        />
        <Flex flexDirection='column' pt={10} gap={isAtMostTablette ? 16 : 26}>
          <InvestorHeadline />
          <InvestorWhatWeDo />
          <InvestorPartners />
          <InvestorServices />
          <InvestorKpis />
          <InvestorHowItWorks onClick={openModal} />
          <InvestorContactUs onClick={openModal} />
        </Flex>
      </PageLayout>
      <InvestorModal open={isModalOpen} title={t('webapp:investors.contactUs.cta')} onClose={closeModal} />
    </>
  )
}
