import { generateEventsDefinition } from '@guiker/event'

import { User } from '../../entity'

export const UserEventsDefinition = generateEventsDefinition({
  context: 'USER',
  entity: 'USER',
  data: undefined as unknown as User,
})

export const Events = UserEventsDefinition.events
export const EventTypes = UserEventsDefinition.eventTypes
