import React from 'react'

import { PartialAsset } from '@guiker/asset-shared'
import { PageLayout, TakeOver, theme } from '@guiker/components-library'

import { DocumentTypes, IdentityVerificationContextProvider } from './IdentityVerificationContext'
import { IdentityVerificationStepper } from './IdentityVerificationSteps'
import { IdentityVerificationSummary } from './IdentityVerificationSummary'

export type IdentityVerification = {
  documentType: DocumentTypes
  facePicture: PartialAsset
  governmentIdBack?: PartialAsset
  governmentIdFront: PartialAsset
}

export type OnSubmit = ({
  documentType,
  facePicture,
  governmentIdFront,
  governmentIdBack,
}: IdentityVerification) => void

type IdentityVerificationProps = {
  scope: {
    type: string
    id: string
  }
  onClose?: () => void
  onSubmit?: OnSubmit
  defaultValue?: IdentityVerification
  title?: string
}
export const IdentityVerification: React.FC<IdentityVerificationProps> = ({
  defaultValue,
  onClose,
  onSubmit,
  scope,
  title,
}) => {
  if (defaultValue) {
    return (
      <TakeOver title={title} onClose={onClose}>
        <PageLayout title={title} maxWidth={theme.dimensions.narrowPageLayout.maxWidth.desktop}>
          <IdentityVerificationSummary defaultValue={defaultValue} />
        </PageLayout>
      </TakeOver>
    )
  }

  const stepperProps = { scope, title, onClose, onSubmit }
  return (
    <IdentityVerificationContextProvider>
      <IdentityVerificationStepper {...stepperProps} />
    </IdentityVerificationContextProvider>
  )
}
