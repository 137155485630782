export type Scope<ScopeType extends string = string> = {
  type: ScopeType
  id: string
}

export const isMatchingScope = (scope: Scope, type: string) => {
  return scope.type.toLowerCase() === type.toLowerCase()
}

export const isScope = (arg: any): arg is { scope: Scope } => {
  return !!(arg?.scope as Scope)?.type
}
