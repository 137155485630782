import { TenantApplication } from '../entity'
import { hasCompletedSupportingDocument } from './has-completed-supporting-document'

export const tenantApplicationValidator = (tenantApplication: TenantApplication) => {
  const checklist = [
    'profile',
    'CurrentAddressOccupation',
    'GuarantorWithoutDocuments',
    'BackgroundCheck',
    'SupportingDocuments',
  ] as const

  function hasCompletedProfile(): boolean {
    return tenantApplication?.profile && 'legalFirstName' in tenantApplication?.profile
  }

  function hasCompletedCurrentAddressOccupation(): boolean {
    return (
      tenantApplication &&
      'currentAddress' in tenantApplication &&
      'occupation' in tenantApplication &&
      tenantApplication?.currentAddress?.isPreviouslyLessee !== undefined &&
      tenantApplication?.occupation?.type !== undefined
    )
  }

  function hasCompletedGuarantorWithDocuments(): boolean {
    return (
      tenantApplication?.hasGuarantor === false ||
      (tenantApplication?.hasGuarantor === true &&
        'guarantor' in tenantApplication &&
        tenantApplication?.guarantor?.supportingDocuments?.every((supportingDocument) =>
          hasCompletedSupportingDocument(supportingDocument),
        ))
    )
  }

  function hasCompletedGuarantorWithoutDocuments(): boolean {
    return (
      tenantApplication?.hasGuarantor === false ||
      (tenantApplication?.hasGuarantor === true && 'guarantor' in tenantApplication)
    )
  }

  function hasCompletedSupportingDocuments(): boolean {
    return (
      tenantApplication?.supportingDocuments?.length &&
      tenantApplication?.supportingDocuments?.every((supportingDocument) =>
        hasCompletedSupportingDocument(supportingDocument),
      )
    )
  }

  function hasCompletedBackgroundCheck(): boolean {
    return tenantApplication?.backgroundCheck ? true : false
  }

  function hasCompletedWithoutDocuments(): boolean {
    return (
      tenantApplication &&
      this.hasCompletedProfile() &&
      this.hasCompletedCurrentAddressOccupation() &&
      this.hasCompletedGuarantorWithoutDocuments() &&
      this.hasCompletedBackgroundCheck()
    )
  }

  function hasCompletedRoommateProfile(): boolean {
    const completeKeys = [
      'livingWithPets',
      'friendLevel',
      'sleepSchedule',
      'cleanliness',
      'interests',
      'personalDescription',
      'minimumStayConsent',
    ]
    const roommateProfile = tenantApplication?.roommateProfile as { [key in string]: unknown }
    return roommateProfile && completeKeys.every((val) => !!roommateProfile[val])
  }

  function hasCompletedSomeOf(stepNames: typeof checklist) {
    return stepNames.some((stepName) => {
      const validate = this[`hasCompleted${stepName}`]
      return typeof validate === 'function' && validate(tenantApplication)
    })
  }

  return {
    hasCompletedProfile,
    hasCompletedCurrentAddressOccupation,
    hasCompletedGuarantorWithDocuments,
    hasCompletedGuarantorWithoutDocuments,
    hasCompletedSupportingDocuments,
    hasCompletedBackgroundCheck,
    hasCompletedWithoutDocuments,
    hasCompletedRoommateProfile,
    hasCompletedSomeOf,
    hasCompletedAny: function () {
      return !!tenantApplication && this.hasCompletedSomeOf(checklist)
    },
  }
}

export const hasCompleteAddress = (address: { [key in string]: unknown }) => {
  const completeKeys = ['streetNumber', 'streetName', 'city', 'state', 'postalCode', 'country']
  return !!address && typeof address === 'object' && completeKeys.every((val) => !!address[val])
}

export const hasCompleteProfile = (profile: { [key in string]: unknown }) => {
  const completeKeys = ['legalFirstName', 'legalLastName', 'dateOfBirth', 'emailAddress']
  return !!profile && typeof profile === 'object' && completeKeys.every((val) => !!profile[val])
}
