import React, { useState } from 'react'

import { AuthType } from '@guiker/authentication-context'
import { useDataTrackingContext } from '@guiker/data-tracking-context'
import { ApiForm, TextField } from '@guiker/react-framework'
import { yupResolver } from '@guiker/react-hook-form'

import { PublicApi, useAuthenticationContext, usePublicAuthenticationApiClient } from '../../../authentication'
import { i18n, I18nextProvider, useTranslation } from '../../../i18n'
import { ButtonWithLoader, ColumnGridLayout, PSmall } from '../../'
import { PasswordTextField } from '../../PasswordTextField'
import { AuthenticationForm, AuthenticationFormProps } from '../AuthenticationForm'

type Props = Omit<AuthenticationFormProps, 'authType'> & {
  onSuccess?: () => void
}

const Content: React.FC<Props> = ({ onSuccess, ...props }) => {
  const { t } = useTranslation(['common', 'authentication'])
  const [isProcessing, setIsProcessing] = useState(false)

  const resolver = yupResolver(PublicApi.Schemas.logIn)
  const apiClient = usePublicAuthenticationApiClient()
  const { setUser } = useAuthenticationContext()
  const { sendEvent } = useDataTrackingContext()
  const onSubmit = async (payload: PublicApi.Schemas.LogIn) => apiClient.logIn({ payload })

  return (
    <AuthenticationForm authType={AuthType.LOGIN} {...props}>
      <ApiForm
        onSubmit={onSubmit}
        formOptions={{ resolver }}
        apiOptions={{
          onSuccess: async (user) => {
            setIsProcessing(true)
            await setUser(user)
            sendEvent({ event: 'login', userId: user.id.toString() })
            onSuccess?.()
            setIsProcessing(false)
          },
        }}
        formName={'LogInForm'}
      >
        {({ isLoading, error }) => (
          <ColumnGridLayout gap={2} gridTemplateColumns={1}>
            {error && <PSmall color='alert'>{t('authentication:errors.wrongCredentials')}</PSmall>}
            <TextField label={t('user.emailAddress')} name='email' required disabled={isLoading || isProcessing} />
            <PasswordTextField
              label={t('user.password')}
              name='password'
              required
              disabled={isLoading || isProcessing}
            />
            <ButtonWithLoader type='submit' isLoading={isLoading || isProcessing} fullWidth={true}>
              {t('authentication:login.cta')}
            </ButtonWithLoader>
          </ColumnGridLayout>
        )}
      </ApiForm>
    </AuthenticationForm>
  )
}

const LogInForm: React.FC<Props> = (props) => (
  <I18nextProvider i18n={i18n}>
    <Content {...props} />
  </I18nextProvider>
)

export { LogInForm }
