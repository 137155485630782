import { NotificationTypeConfig } from './notification-type-config'

export type NotiticationTypes = {
  [C in keyof typeof NotificationTypeConfig]: {
    context: C
  } & {
    [E in keyof (typeof NotificationTypeConfig)[C]]: {
      context: C
      entity: E
    } & {
      [T in keyof (typeof NotificationTypeConfig)[C][E]]: {
        context: C
        entity: E
        type: T
        entityId: string
      }
    }
  }
}

export const NotificationTypes = Object.keys(NotificationTypeConfig).reduce(
  <C extends keyof typeof NotificationTypeConfig>(acc: NotiticationTypes, context: string) => ({
    ...acc,
    [context]: Object.keys(NotificationTypeConfig[context as C]).reduce(
      <E extends keyof (typeof NotificationTypeConfig)[C]>(nestedAcc: NotiticationTypes, entity: string) => ({
        ...nestedAcc,
        context,
        [entity]: Object.keys(NotificationTypeConfig[context as C][entity as E]).reduce(
          (secondNestedAcc: NotiticationTypes, type: string) => ({
            ...secondNestedAcc,
            entity,
            [type]: {
              context,
              entity,
              type,
            },
          }),
          nestedAcc,
        ),
      }),
      acc,
    ),
  }),
  {} as NotiticationTypes,
)
