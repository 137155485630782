import { parseDateTime } from '@guiker/shared-framework'
import { TypeOf, yup } from '@guiker/yup-util'

export const updateProfileSchema = yup.object({
  firstName: yup
    .string()
    .nullable()
    .transform((val) => {
      const trimmed = val?.trim()
      return !!trimmed ? trimmed : null
    }),
  lastName: yup
    .string()
    .nullable()
    .transform((val) => {
      const trimmed = val?.trim()
      return !!trimmed ? trimmed : null
    }),
  birthDate: yup
    .string()
    .nullable()
    .transform((val) => {
      if (val) return parseDateTime(val).toISODate()
      return null
    }),
  phone: yup
    .string()
    .nullable()
    .transform((val) => {
      const trimmed = val?.trim()
      return !!trimmed ? trimmed : null
    }),
})

export type UpdateProfileSchema = TypeOf<typeof updateProfileSchema>
