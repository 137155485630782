import React, { useState } from 'react'

import { Asset, DEFAULT_MAX_WIDTH, fileToAssetFile } from '@guiker/asset-uploader'
import { isAtMostTablette } from '@guiker/components-core'
import { Button, Flex, makeStyles, Note } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { SuccessCheckmarkIcon } from '@guiker/icons'

import { FacePicture } from '../FacePicture'
import { useIdentityVerificationContext } from '../IdentityVerificationContext'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexDirection: 'row',
      gap: theme.spacing(4),
      [isAtMostTablette]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
    button: {
      width: '100%',
      minWidth: DEFAULT_MAX_WIDTH,
      maxWidth: DEFAULT_MAX_WIDTH,
      [isAtMostTablette]: {
        maxWidth: '100%',
      },
    },
  }),
  { name: 'FacePictureVerificator' },
)

export const FacePictureVerificator: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation(['common-components'])
  const tPrefix = 'common-components:identityVerification.facePicture'
  const { facePicture, validateAndSetFacePicture, resetFacePicture } = useIdentityVerificationContext()
  const [cameraIsOpen, setCameraIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const hasError = facePicture && facePicture?.status !== 'SUCCESS'
  const isSuccess = facePicture && facePicture?.status === 'SUCCESS'

  const openCamera = () => {
    setCameraIsOpen(true)
  }

  const closeCamera = () => {
    setCameraIsOpen(false)
  }

  const verifyIdentity = async ({ image }: { image: File }) => {
    if (!isProcessing) {
      closeCamera()
      setIsProcessing(true)

      await validateAndSetFacePicture({ image: fileToAssetFile(image, image.name) })

      setIsProcessing(false)
    }
  }

  const onRemoveClick = () => {
    resetFacePicture()
  }

  return (
    <Flex gap={4} className={classes.root}>
      {!facePicture ? (
        <div>
          <Button onClick={openCamera} fullWidth={false} className={classes.button}>
            {t(`${tPrefix}.cta`)}
          </Button>
          {cameraIsOpen && (
            <FacePicture
              enabled={!isProcessing}
              onSuccess={verifyIdentity}
              onClose={closeCamera}
              label={t(`${tPrefix}.cta`)}
            />
          )}
        </div>
      ) : (
        <Asset asset={facePicture.image} flexDirection='column' withThumbnail={true} removeAsset={onRemoveClick} />
      )}
      <Flex flexDirection='column' fullWidth>
        {isProcessing && <Note icon='loading' title={t(`${tPrefix}.processing.title`)}></Note>}
        {!isProcessing && isSuccess && (
          <Note
            icon={<SuccessCheckmarkIcon width={16} height={16} />}
            statusColor='success'
            title={t(`${tPrefix}.success.title`)}
          >
            {t(`${tPrefix}.success.content`)}
          </Note>
        )}
        {!isProcessing && hasError && (
          <Note statusColor='warning' icon='warning' title={t(`${tPrefix}.failure.title`)}>
            {t(`${tPrefix}.failure.content`)}
            <ul>
              <li>{t(`${tPrefix}.fixRecommendations.line1`)}</li>
              <li>{t(`${tPrefix}.fixRecommendations.line2`)}</li>
            </ul>
          </Note>
        )}
      </Flex>
    </Flex>
  )
}
