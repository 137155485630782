import React, { useState } from 'react'

import { NotificationItem, parsedNotifications } from '@guiker/notification-components'
import { useNotificationContext } from '@guiker/notification-context'
import {
  Flex,
  FloatingBadge,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  NotificationIcon,
  P,
  PSmall,
  PSmaller,
  useDateFormatter,
} from '@guiker/react-framework'

const useStyles = makeStyles(
  (theme) => ({
    status: {
      width: 12,
      borderRadius: 6,
      height: 12,
      backgroundColor: theme.palette.grey[80],
    },
  }),
  { name: 'NotificationsMenu' },
)

const NotificationStatusIcon = () => {
  const classes = useStyles()
  return <div className={classes.status} />
}

export const NotificationMenuItem: React.FC<NotificationItem> = ({
  onClick,
  adornment,
  date,
  primaryText,
  secondaryText,
  status,
}) => {
  const { formatWithRelativeDateTime } = useDateFormatter()
  const isUnread = status !== 'READ'

  return (
    <MenuItem onClick={onClick}>
      <Flex gap={2} p={1} width='100%'>
        <Flex width={24} height={24}>
          {adornment}
        </Flex>
        <Flex flexDirection='column' gap={1} flexGrow={1}>
          <Flex flexDirection='column' gap={0.5}>
            <P mb={0} color={'black'} fontWeight={isUnread ? 500 : 400}>
              {primaryText}
            </P>
            {secondaryText && (
              <PSmall mb={0} color={60}>
                {secondaryText}
              </PSmall>
            )}
          </Flex>
          <PSmaller mb={0} color={60}>
            {formatWithRelativeDateTime(date)}
          </PSmaller>
        </Flex>
        <Flex width={24} height={24} alignItems='center' justifyContent='center'>
          {isUnread && <NotificationStatusIcon />}
        </Flex>
      </Flex>
    </MenuItem>
  )
}

export const NotificationsMenu: React.FC = () => {
  const { notifications, unreadCount, markAsRead } = useNotificationContext()
  const notificationCopy = parsedNotifications(notifications, markAsRead)
  const [anchor, setAnchor] = useState<Element>(null)

  const onClick = (event?: React.SyntheticEvent) => {
    anchor ? onClose() : setAnchor(event?.currentTarget)
  }

  const onClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <FloatingBadge badgeNumber={unreadCount > 0 ? unreadCount : null}>
          <NotificationIcon />
        </FloatingBadge>
        <Menu
          maxWidth={500}
          open={!!anchor}
          anchorEl={anchor}
          onClose={onClose}
          anchorOrigin={{ vertical: 32, horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {notificationCopy.map((notification, index) => {
            return <NotificationMenuItem key={`notification-${index}`} {...notification} />
          })}
        </Menu>
      </IconButton>
    </>
  )
}
