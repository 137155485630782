import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const keyDeposit = yup.object<Omit<Ontario.KeyDeposit, 'completed'>>({
  isKeyDepositRequired: yup.boolean().required(),
  keyDepositAmount: yup.number().when('isKeyDepositRequired', {
    is: true,
    then: yup.number().required(),
    otherwise: yup
      .number()
      .nullable()
      .transform(() => null as null),
  }),
  keyDepositDetails: yup.string().nullable(),
})

export type KeyDeposit = TypeOf<typeof keyDeposit>
