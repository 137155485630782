import { HttpMethod } from '@guiker/http'

import { paths } from './paths'

export const routes = {
  generateSignUrl: {
    path: `${paths.contract.all.base()}/generate-sign-url`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as string),
  },
}
