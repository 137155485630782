import { BookingRoleNames } from '../permissions'
import { Participant, ParticipantStepStatus } from './participant'

export { BookingRoleNames }

export interface UnitManager extends Participant {
  role: BookingRoleNames
  steps: {
    application: {
      status: ParticipantStepStatus
    }
    roommate: {
      status: ParticipantStepStatus
    }
    lease: {
      status: ParticipantStepStatus
    }
    document: {
      status: ParticipantStepStatus
    }
  }
}
