import { Cleanliness, HangingWithFriends, Interest, PetConsent, SleepSchedule } from '@guiker/base-entity'
import { yup } from '@guiker/yup-util'

import { referralSchema } from './referral'

export const roommateProfileSchema = yup.object({
  minimumStayConsent: yup
    .object({
      isConsentGiven: yup.boolean().required().oneOf([true], 'This must be accepted.'),
    })
    .required(),
  livingWithPets: yup.mixed().oneOf(Object.values(PetConsent)).required(),
  friendLevel: yup.mixed().oneOf(Object.values(HangingWithFriends)).required(),
  sleepSchedule: yup
    .array()
    .of(yup.mixed().oneOf(Object.values(SleepSchedule)))
    .min(1)
    .max(2)
    .required(),
  cleanliness: yup.mixed().oneOf(Object.values(Cleanliness)).required(),
  personalDescription: yup.string().min(80, 'Minimum 80 characters').required(),
  interests: yup
    .array()
    .of(yup.mixed().oneOf(Object.values(Interest)))
    .min(2, 'Pick at least 2 interests')
    .max(5, 'Pick at max 5 interests')
    .required(),
  referral: referralSchema.required(),
})
