import { mainPathBuilder } from '@guiker/investment-shared'
import { staticAssets } from '@guiker/static-asset-context'

import { legacyRoutes, routes } from '../../routes'

export interface FooterLinkGroup {
  title: string
  links: {
    name: string
    to: string
    isExternalLink?: boolean
  }[]
}

const footerLinkGroups: FooterLinkGroup[] = [
  {
    title: 'webapp:footer.guiker',
    links: [
      { name: 'webapp:nav.aboutUs', to: routes.About.path },
      // { name: 'webapp:nav.careers', to: routes.Careers.path },
      { name: 'webapp:nav.blog', to: 'https://blog.guiker.com', isExternalLink: true },
      { name: 'webapp:nav.privacyPolicy', to: routes.PrivacyPolicy.path },
    ],
  },
  {
    title: 'webapp:footer.rentals',
    links: [
      { name: 'webapp:nav.findRental', to: legacyRoutes.ListingSearch, isExternalLink: true },
      { name: 'webapp:nav.submitListing', to: legacyRoutes.MyListings, isExternalLink: true },
      { name: 'webapp:nav.termsAndConditions', to: routes.TermsAndConditions.path },
    ],
  },
  {
    title: 'webapp:footer.invest',
    links: [
      { name: 'webapp:nav.findInvestment', to: mainPathBuilder.root.path },
      { name: 'webapp:nav.faq', to: 'https://blog.guiker.com/faqs', isExternalLink: true },
      { name: 'webapp:nav.termsAndConditions', to: staticAssets['terms-and-conditions'].invest, isExternalLink: true },
    ],
  },
]

export { footerLinkGroups }
