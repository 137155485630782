import { PhoneNumberSchema } from '@guiker/phone-number'
import { yup } from '@guiker/yup-util'

import { visitSchedulesSchema } from '../../schemas'

export const bookVisitSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  message: yup.string().nullable(),
  phone: PhoneNumberSchema(true).required(),
  isBookingNotificationEnabled: yup.boolean().default(false),
  visitSchedules: visitSchedulesSchema,
})

export type BookVisitSchema = yup.InferType<typeof bookVisitSchema>
