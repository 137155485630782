import React from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { Fade, FadeProps, Modal, ModalProps, theme, useMediaQuery } from '@guiker/react-framework'

type Props = React.PropsWithChildren & {
  className?: string
  open: boolean
  onClose: () => void
  title?: string
  subtitle?: string
  actions?: ModalProps['actions']
}

const TransitionWithRef = React.forwardRef<typeof Fade, FadeProps>((props, ref) => {
  return <Fade ref={ref} {...props} />
})

export const BookingPanelModal: React.FC<Props> = ({ children, ...props }) => {
  const { containerRef } = useBookingContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Modal
      disablePortal
      keepMounted
      fullScreen
      hideBackdrop={!isMobile}
      style={{ position: 'absolute' }}
      container={containerRef?.current}
      TransitionComponent={TransitionWithRef}
      {...props}
    >
      {children}
    </Modal>
  )
}
