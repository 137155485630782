import { merge } from '@guiker/lodash'
import { Role } from '@guiker/permissions'

import { BookingActions } from '../booking-actions'
import { BookingRoleNames } from '../role-names'
import { UnitManagerRole } from './unit-manager-role'

export const ListingOperatorRole: Role = merge(UnitManagerRole, {
  name: BookingRoleNames.LISTING_OPERATOR,
  permissions: Object.values(BookingActions).map((action) => ({ action })),
})
