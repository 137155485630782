import { routesBuilder } from '@guiker/shared-framework'

import { RentCharge } from '../../../entity'
import { RentChargeActions } from '../../../permissions'
import { paths } from '../paths'
import { createRentCharge } from './schemas'

const { buildPost, buildGet } = routesBuilder<RentCharge>()({
  basePath: paths.rentCharges.path,
})

export const routes = {
  readAllRentCharges: buildGet({
    actionPermissions: [RentChargeActions.ListRentCharge],
    paginated: true,
  }),
  createRentCharge: buildPost({
    actionPermissions: [RentChargeActions.CreateRentCharge],
    payloadSchema: createRentCharge,
  }),
}
