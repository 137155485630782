import { yup } from '@guiker/yup-util'

import { ListingUnitAmenities } from '../../entity'
import { amenitySchema } from './amenity-schema'

export const unitAmenitiesSchema = yup.object<ListingUnitAmenities>({
  airConditioner: amenitySchema,
  balcony: amenitySchema,
  dishwasher: amenitySchema,
  fridge: amenitySchema,
  petsAllowedSmall: amenitySchema,
  petsAllowedLarge: amenitySchema,
  washer: amenitySchema,
  oven: amenitySchema,
})
