import React from 'react'

import { Flex, Inline, makeStyles, theme } from '@guiker/react-framework'

import { StepIcon } from '../../StepButton'

type ApplicantStepProps = {
  label: string
  completed: boolean
  dimmed?: boolean
}

const useStyles = makeStyles({
  container: {
    marginBottom: theme.spacing(1),
    opacity: ({ dimmed = false }: ApplicantStepProps) => (dimmed ? 0.5 : 1),
  },
})

const ApplicantStep: React.FC<ApplicantStepProps> = (props) => {
  const { label, completed } = props
  const classes = useStyles(props)

  return (
    <Flex className={classes.container} gap={1.5}>
      <StepIcon checked={completed} />
      <Inline>{label}</Inline>
    </Flex>
  )
}

export { ApplicantStep }
