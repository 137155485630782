import { yup } from '@guiker/yup-util'

export const readAllBookingsWithListingIdsQueryParamsSchema = yup.object({
  listingIds: yup
    .array()
    .of<string>(yup.mixed<string>())
    .nullable()
    .transform((v) => (!!v ? v : null)),
  searchString: yup.string().nullable(),
})
