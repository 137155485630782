import { Payment } from '@guiker/base-entity'

import { Invoice, TransactionIntent, TransactionStatus, TransactionType } from '../invoice'

const findTransaction = (
  invoice: Invoice,
  query: {
    intent?: TransactionIntent
    type?: TransactionType
    status?: TransactionStatus
    details?: {
      payInMethodType?: Payment.PayInMethodType
    }
  },
) =>
  [...invoice.transactions]
    .reverse()
    .find(
      (t) =>
        (!query.intent || t.intent === query.intent) &&
        (!query.type || t.type === query.type) &&
        (!query.status || t.status === query.status) &&
        (!query.details?.payInMethodType || t.details.payInMethodType === query.details.payInMethodType),
    )

export { findTransaction }
