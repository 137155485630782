import React from 'react'

import { Chip, Flex, makeStyles, PSmall, useT } from '@guiker/react-framework'
import { camelCase, ValueOfUnion } from '@guiker/shared-framework'
import { RentPayoutStatus } from '@guiker/statistic-shared'

import { findPayoutColor } from '../../utils'

const useStyle = makeStyles({
  status: {
    display: 'inline',
  },
})

const StatusChip: React.FC<{ status: ValueOfUnion<RentPayoutStatus> }> = ({ status }) => {
  const { tMain } = useT({ domain: 'statistic', screenName: 'components' })
  const classes = useStyle()
  return (
    <Chip size='smaller' color={findPayoutColor(status)} className={classes.status}>
      {tMain(`perPayoutTable.status.${camelCase(status)}`)}
    </Chip>
  )
}

const PayoutStatusChip: React.FC<{ status: ValueOfUnion<RentPayoutStatus> }> = ({ status }) => {
  const { tMain } = useT({ domain: 'statistic', screenName: 'components' })
  return (
    <div>
      <StatusChip status={status} />
      <PSmall color={'textSecondary'} mb={0} component={'span'} ml={1}>
        {tMain(`perPayoutTable.tooltip.${camelCase(status)}`)}
      </PSmall>
    </div>
  )
}

const RentCollectionPayoutStatusGrouping: React.FC<{ group: ValueOfUnion<RentPayoutStatus>[] }> = ({ group }) => {
  return (
    <Flex flexDirection='column' gap={2}>
      {group?.map((status) => (
        <PayoutStatusChip status={status} />
      ))}
    </Flex>
  )
}
export { RentCollectionPayoutStatusGrouping }
