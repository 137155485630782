import { routesBuilder } from '@guiker/shared-framework'

import { RentPaymentsPlanWithTenantInstalments } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<RentPaymentsPlanWithTenantInstalments>()({
  basePath: paths.payInMethods.withId.rentPaymentPlans.path,
})

export const routes = {
  readAllRentPaymentsPlansByPayInMethod: buildGet({ responseIsArray: true }),
}
