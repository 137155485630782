import React from 'react'

import { Listing as BaseListingEntities } from '@guiker/base-entity'
import {
  Checkbox,
  DatePicker,
  Dropdown,
  TextField,
  TwoColumnsGridLayout,
  useTranslation,
} from '@guiker/react-framework'
import { LeasePreferences as LeasePreferencesType } from '@guiker/tenant-preference-shared'

import { LeasePreferencesLocation } from './LeasePreferencesLocation'

type LeasePreferencesProps = {
  defaultValue: LeasePreferencesType
  name: string
}

const rentalTypeOptions = () => {
  const { t } = useTranslation(['common-tenantPreference', 'common-rentalListing'])

  const options = ['ENTIRE', 'PRIVATE_ROOM'].map((rentalType) => {
    return {
      value: rentalType,
      label: t(`common-tenantPreference:rentalType.${rentalType}`),
    }
  })
  options.push({ value: null, label: t(`common-tenantPreference:rentalType.ANY`) })

  return options
}

export const LeasePreferences: React.FC<LeasePreferencesProps> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common-tenantPreference', 'common-rentalListing'])

  return (
    <TwoColumnsGridLayout>
      <LeasePreferencesLocation name={name} defaultValue={defaultValue} />
      <TextField
        defaultValue={defaultValue?.budget}
        name={`${name}.budget`}
        label={t('common-tenantPreference:leasePreferences.budget')}
      />
      <Dropdown
        defaultValue={defaultValue?.floorPlan}
        name={`${name}.floorPlan`}
        label={t('common-rentalListing:floorPlan.title')}
        options={Object.keys(BaseListingEntities.FloorPlans).map((unit) => {
          return {
            value: BaseListingEntities.FloorPlans[unit],
            label: t(`common-rentalListing:floorPlan.${unit}`),
          }
        })}
      />
      <DatePicker
        defaultValue={defaultValue?.leaseStart}
        name={`${name}.leaseStart`}
        disablePast
        label={t('common-tenantPreference:leasePreferences.leaseStart')}
      />
      <TextField
        defaultValue={defaultValue?.leaseDuration}
        name={`${name}.leaseDuration`}
        label={t('common-tenantPreference:leasePreferences.leaseDuration')}
      />
      <Checkbox
        defaultValue={defaultValue?.furniture}
        name={`${name}.furniture`}
        label={t('common-rentalListing:furnished')}
      />
      <Dropdown
        defaultValue={defaultValue?.rentalType}
        name={`${name}.rentalType`}
        label={t('common-tenantPreference:rentalType.title')}
        options={rentalTypeOptions()}
      />
    </TwoColumnsGridLayout>
  )
}
