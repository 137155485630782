import React, { useState } from 'react'

import {
  Flex,
  FullScreenSpinner,
  makeStyles,
  paddingPx,
  theme,
  useMediaQuery,
  ZoomInIcon,
  ZoomOutIcon,
} from '@guiker/react-framework'

import { Document, Page } from './react-pdf'

type PdfViewerProps = {
  assetUrl: string
  width?: string
  height?: string
}

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  container: {
    width: ({ width }: { width: string }) => (width ? width : '100%'),
    maxWidth: theme.dimensions.narrowPageLayout.maxWidth.desktop,
    background: theme.palette.grey[5],
    overflowY: 'scroll',
    overflowX: 'auto',
    height: ({ height }: { height: string }) => (height ? height : '60vh'),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    justifyContent: 'center',
    alignContent: 'center',
  },
  controls: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  zoomIn: {
    padding: paddingPx(6, 6, 2, 6),
  },
  zoomOut: {
    borderLeft: `1px solid ${theme.palette.grey[30]}`,
    padding: paddingPx(6, 6, 2, 6),
  },
})

export const PdfViewer: React.FC<PdfViewerProps> = ({ assetUrl, width, height }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [totalPages, setTotalPages] = useState(0)
  const [scale, setScale] = useState(1.5)
  const classes = useStyles({ width, height })

  const documentOnLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages)
  }

  const handleZoom = (zoom: number) => {
    if (zoom <= 4 && zoom >= 0.5) setScale(zoom)
  }

  if (!assetUrl) return <></>

  return (
    <div className={classes.wrapper}>
      <Flex className={classes.container}>
        <Document file={assetUrl} onLoadSuccess={documentOnLoadSuccess} loading={<FullScreenSpinner />}>
          <Flex flexDirection='column' gap={1}>
            {Array.from(new Array(totalPages), (_, index) => (
              <Page break scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Flex>
        </Document>
      </Flex>
      <Flex className={classes.controls}>
        <div onClick={() => handleZoom(scale - (isMobile ? 0.25 : 0.5))} className={classes.zoomIn}>
          <ZoomOutIcon color={10} />
        </div>
        <div onClick={() => handleZoom(scale + (isMobile ? 0.25 : 0.5))} className={classes.zoomOut}>
          <ZoomInIcon color={10} />
        </div>
      </Flex>
    </div>
  )
}
