import { TypeOf, yup } from '@guiker/shared-framework'

import { canadianBankInformationSchema } from './bank-information'
import { verificationSchema } from './verification'

export const updateBankInformationSchema = yup.object({
  bankInformation: yup
    .object({
      isManualInput: yup.boolean().required(),
      details: canadianBankInformationSchema(false).when('isManualInput', {
        is: true,
        then: canadianBankInformationSchema(true).required(),
        otherwise: canadianBankInformationSchema(false)
          .nullable()
          .transform(() => null as null),
      }),
      verification: verificationSchema(false).when('isManualInput', {
        is: false,
        then: verificationSchema(true).required(),
        otherwise: verificationSchema(false)
          .nullable()
          .transform(() => null as null),
      }),
    })
    .required(),
})

export type UpdateBankInformationSchema = TypeOf<typeof updateBankInformationSchema>
