import { TypeOf, yup } from '@guiker/yup-util'

export const findOneUserByEmailAddress = yup
  .object({
    email: yup.string().email().required(),
    exactMatch: yup.boolean().default(false).nullable(),
  })
  .required()

export type FindOneUserByEmailAddress = TypeOf<typeof findOneUserByEmailAddress>
