enum LegacyPaymentAgentCategory {
  CARD = 'CARD',
  EFT = 'EFT',
  OFFLINE = 'OFFLINE',
}
interface LegacyPaymentAgent {
  id: number
  name: string
  brand: string
  category: LegacyPaymentAgentCategory
  serviceFeePercentage: number
}

export { LegacyPaymentAgent, LegacyPaymentAgentCategory }
