import { useAuthenticationContext } from '@guiker/authentication-context'
import { useConfig } from '@guiker/config-context'
import { v4 as uuidv4 } from '@guiker/uuid'
import { ApiClient } from '@guiker/websocket-connection-api-client'

export const useWebsocketConnectionApiClient = () => {
  const { user } = useAuthenticationContext()
  const config = useConfig()

  const apiClient = ApiClient({
    accessToken: user?.accessToken,
    baseUrl: config.apiBaseUrl,
    correlatedRequestId: uuidv4(),
    logger: config.debug ? console : undefined,
  })

  return apiClient
}
