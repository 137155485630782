import React, { useState } from 'react'

import { webappRoutes } from '@guiker/main-webapp-shared'
import {
  Card,
  clsx,
  Flex,
  HeroLayout,
  isAtMostTablette,
  makeStyles,
  ThemeContextProvider,
  useLayoutContext,
  useLocation,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { HomeTabs } from './HomeTabs'
import { InvestHeroBox } from './Invest/InvestHeroBox'
import { RentHeroBox } from './Rent'
import { ServicesHeroBox } from './Services/ServicesHeroBox'

const useStyles = makeStyles(() => ({
  imageContainer: {
    justifyContent: 'center',
    width: '100%',
    '& > object': {
      maxWidth: 500,
      maxHeight: 600,
      [isAtMostTablette]: {
        objectFit: 'contain',
        width: '100%',
      },
    },
    [isAtMostTablette]: {
      minHeight: 'initial',
    },
  },
  shifted: {
    marginTop: -75,
  },
}))

export const HeroSection: React.FC = () => {
  const { isAtMostTablette } = useLayoutContext()
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('home')
  const loc = useLocation()
  const classes = useStyles()

  const heroSectionConfig = [
    {
      boxComponent: <RentHeroBox />,
      imageUrl: assets.v3.hero.rent,
    },
    {
      boxComponent: <InvestHeroBox />,
      imageUrl: assets.v3.hero.invest,
    },
    {
      boxComponent: <ServicesHeroBox />,
      imageUrl: assets.v3.hero.services,
    },
  ]

  const path = loc.pathname
  const lastPart = path.split('/').pop()
  const indexFromLoc =
    lastPart === webappRoutes.Services.path.split('/').pop()
      ? 2
      : lastPart === webappRoutes.Invest.path.split('/').pop()
      ? 1
      : 0

  const [selectedTabIndex, setSelectedTabIndex] = useState(indexFromLoc)

  const onTabChange = (index: number) => {
    window.history.replaceState(
      null,
      '',
      index === 2 ? webappRoutes.Services.path : index === 1 ? webappRoutes.Invest.path : webappRoutes.Rent.path,
    )
    setSelectedTabIndex(index)
  }

  const { boxComponent, imageUrl } = heroSectionConfig[selectedTabIndex]

  const leftComponent = (
    <ThemeContextProvider isDarkMode={false}>
      <Card maxWidth={600} width='100%' alignSelf='center'>
        <HomeTabs currentIndex={selectedTabIndex} onChange={onTabChange} />
        {boxComponent}
      </Card>
    </ThemeContextProvider>
  )

  if (isAtMostTablette) {
    return <HeroLayout left={leftComponent} />
  }

  return (
    <HeroLayout
      left={leftComponent}
      right={
        imageUrl.includes('.svg') ? (
          <Flex className={clsx(classes.imageContainer, { [classes.shifted]: indexFromLoc === 1 })}>
            <object type='image/svg+xml' data={imageUrl}>
              svg-animation
            </object>
          </Flex>
        ) : (
          <img src={imageUrl} width={500} />
        )
      }
    />
  )
}
