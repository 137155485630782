import { LegacyRestClient } from '@guiker/legacy-rest-client'
import { buildPaginationMeta, DataAndMeta, flatMap } from '@guiker/shared-framework'

import { Legacy } from '../entity'

const BASE_APP_API_PATH = 'app_api'
const BASE_LISTING_PATH = `${BASE_APP_API_PATH}/listings`
const BASE_LEASE_PATH = `${BASE_APP_API_PATH}/leases`
const BASE_INSTALMENT_PATH = `${BASE_APP_API_PATH}/instalments`

const endpoints = {
  readEffectiveLeasesByListingId: (listingId: number, page: number, perPage: number) =>
    `${BASE_LISTING_PATH}/${listingId}/effective_leases?page=${page}&perPage=${perPage}`,
  readLeaseByBookingId: (bookingId: string) => `${BASE_APP_API_PATH}/bookings/${bookingId}/inquiry`,
  readLeasePaymentHistory: (leaseId: number) => `${BASE_LEASE_PATH}/${leaseId}/payment_history`,
  terminateLease: (leaseId: number) => `${BASE_LEASE_PATH}/${leaseId}/terminate`,
  createTransactionForOfflinePaid: (instalmentId: string) => `${BASE_INSTALMENT_PATH}/${instalmentId}/offline_charge`,
  deleteTransactionForOfflinePaid: (instalmentId: string) =>
    `${BASE_INSTALMENT_PATH}/${instalmentId}/undo_offline_paid`,
}

export const LegacyAppApiClient = ({ baseUrl, accessToken }: { baseUrl: string; accessToken: string }) => {
  const baseClient = LegacyRestClient({ baseUrl, headers: { 'Access-Token': accessToken } })

  return {
    readEffectiveLeasesByListingId: async ({
      listingId,
      page = 1,
      perPage = 20,
    }: {
      listingId: number
      page?: number
      perPage?: number
    }): Promise<DataAndMeta<Legacy.Lease[]>> => {
      const leasesResponse = await baseClient
        .get(endpoints.readEffectiveLeasesByListingId(listingId, page - 1, perPage))
        .then((r) => r.body)

      const total = leasesResponse.meta.total as number
      return {
        meta: buildPaginationMeta(total, page, perPage),
        data: leasesResponse.data as Legacy.Lease[],
      }
    },
    readLeaseByBookingId: async ({ bookingId }: { bookingId: string }) => {
      return baseClient.get<Legacy.Lease>(endpoints.readLeaseByBookingId(bookingId)).then((r) => r.body)
    },
    readLeasePaymentHistory: async ({
      leaseId,
      page = 1,
      perPage = 20,
    }: {
      leaseId: number
      page?: number
      perPage?: number
    }): Promise<DataAndMeta<Legacy.TenantTransaction[]>> => {
      const inventoryTransactionOverview = await baseClient
        .get<Legacy.InventoryTransactionOverview>(endpoints.readLeasePaymentHistory(leaseId))
        .then((r) => r.body.data)

      const flatten = flatMap(
        inventoryTransactionOverview.tenants.map(({ leaseUser, instalments }) => {
          const user = leaseUser.user
          return instalments.map(
            (instalment) =>
              ({
                ...instalment,
                userId: user.id.toString(),
                fullName: [user.firstName, user.lastName].join(' '),
              } as Legacy.TenantTransaction),
          )
        }),
      )
      const tenantTransactions = flatten.sort((a, b) => {
        if (a.chargeDate > b.chargeDate) {
          return 1
        } else if (a.chargeDate < b.chargeDate) {
          return -1
        } else {
          return a.userId >= b.userId ? 1 : -1
        }
      })

      return {
        meta: buildPaginationMeta(tenantTransactions.length, page, perPage),
        data: tenantTransactions.slice((page - 1) * perPage, page * perPage),
      }
    },
    terminateLease: async ({ leaseId }: { leaseId: number }) => baseClient.put(endpoints.terminateLease(leaseId)),
    createTransactionForOfflinePaid: async ({ instalmentId }: { instalmentId: string }) =>
      baseClient.post(endpoints.createTransactionForOfflinePaid(instalmentId), {}),
    deleteTransactionForOfflinePaid: async ({ instalmentId }: { instalmentId: string }) =>
      baseClient.delete(endpoints.deleteTransactionForOfflinePaid(instalmentId)),
  }
}
