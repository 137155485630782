import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'

import { Box, Flex, Grid, H2, H4, Link, P, PBig, SecondaryButton, useMediaQuery } from '../../components'
import { Position } from '../../hooks'
import { ContentSection } from './ContentSection'

const useStyle = makeStyles({
  title: {
    width: '100%',
  },
  applyGridItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const OpenPositions: React.FC<{ positions: Position[] }> = ({ positions }) => {
  const { t } = useTranslation(['webapp'])
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ContentSection justifyContent='flex-start'>
      <H2 id='open-positions' name='open-positions' className={classes.title}>
        {t('careers.openPositions')}
      </H2>
      {isMobile && (
        <Box mt={3} p={1}>
          {positions.map((p, index) => (
            <Box mb={3} key={index}>
              <Flex>
                <PBig color='primary' mr={1.5}>
                  {'//'}
                </PBig>
                <Link color='black' isExternalLink to={p.link}>
                  <PBig color='black'>{`${t(p.jobTitle)}`}</PBig>
                </Link>
              </Flex>
              <PBig>
                <i>{`${t(p.type)}, ${t(p.location)}`}</i>
              </PBig>
            </Box>
          ))}
        </Box>
      )}

      {!isMobile && (
        <Box mx='auto' px={2} width='100%'>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <H4 mt={4}>{t('careers.jobTitle')}</H4>
            </Grid>
            <Grid item xs={12} sm={2}>
              <H4 mt={4}>{t('careers.types')}</H4>
            </Grid>
            <Grid item xs={12} sm={2}>
              <H4 mt={4}>{t('careers.location')}</H4>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
          {positions.map((p, index) => (
            <Grid key={index} container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Flex alignItems='center' height='100%'>
                  <P color='black' mt={2} mb={0}>
                    {t(p.jobTitle)}
                  </P>
                </Flex>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Flex alignItems='center' height='100%'>
                  <P color='black' mt={2} mb={0}>
                    {t(p.type)}
                  </P>
                </Flex>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Flex alignItems='center' height='100%'>
                  <P color='black' mt={2} mb={0}>
                    {t(p.location)}
                  </P>
                </Flex>
              </Grid>
              <Grid item xs={12} sm={2} className={classes.applyGridItem}>
                <SecondaryButton target='_blank' href={p.link} color={'primary'}>
                  {t('careers.apply')}
                </SecondaryButton>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </ContentSection>
  )
}

export { OpenPositions }
