import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { useLayoutContext } from '@guiker/components-library'
import { DeleteMiniIcon, PaperclipSmallIcon } from '@guiker/icons'
import { PdfThumbnail } from '@guiker/pdf-viewer'
import { isMobile, isNotMobile, makeStyles, TextField, theme } from '@guiker/react-framework'
import { useFormContext } from '@guiker/react-hook-form'

import { AssetFile } from '../../entity'
import { isImageType, isPdfType } from '../../utils'
import { Flex, PSmall, SecondaryButton } from '../'
import { AssetImage } from './AssetImage'
import { RHFAssetInput } from './RHFAssetInput'
import { ViewAsset } from './ViewAsset'

type AssetProps = {
  id?: string
  asset: AssetFile
  index?: number
  flexDirection?: 'column' | 'row'
  displayNameEditable?: boolean
  name?: string
  additionalFields?: {
    name: string
    prop: keyof AssetFile
  }[]
  readOnly?: boolean
  withThumbnail?: boolean
  removeAsset?: () => void
}

export const THUMBNAIL_RATIO = 1.61
export const DEFAULT_MAX_WIDTH = 284
export const DEFAULT_MAX_HEIGHT = DEFAULT_MAX_WIDTH / THUMBNAIL_RATIO

const FLEX_COLUMN_MAX_HEIGHT = 40
const FLEX_COLUMN_MAX_WIDTH = 60

const useStyle = makeStyles(
  {
    root: {
      width: '100%',
      [isMobile]: {
        minWidth: '100%',
        maxWidth: '100%',
      },
      [isNotMobile]: {
        minWidth: ({ flexDirection }: AssetProps) => (flexDirection === 'row' ? '100%' : DEFAULT_MAX_WIDTH),
        maxWidth: ({ flexDirection }: AssetProps) => (flexDirection === 'row' ? '100%' : DEFAULT_MAX_WIDTH),
      },
    },
    thumbnail: {
      cursor: 'pointer',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      aspectRatio: `${THUMBNAIL_RATIO}`,
      width: '100%',
      height: '100%',
      minWidth: ({ flexDirection }: AssetProps) =>
        flexDirection === 'row' ? FLEX_COLUMN_MAX_WIDTH : DEFAULT_MAX_WIDTH,
      maxWidth: ({ flexDirection }: AssetProps) =>
        flexDirection === 'row' ? FLEX_COLUMN_MAX_WIDTH : DEFAULT_MAX_WIDTH,
      maxHeight: ({ flexDirection }: AssetProps) =>
        flexDirection === 'row' ? FLEX_COLUMN_MAX_HEIGHT : DEFAULT_MAX_HEIGHT,
      minHeight: ({ flexDirection }: AssetProps) =>
        flexDirection === 'row' ? FLEX_COLUMN_MAX_HEIGHT : DEFAULT_MAX_HEIGHT,
      [isMobile]: {
        minWidth: ({ flexDirection }: AssetProps) => (flexDirection === 'row' ? FLEX_COLUMN_MAX_WIDTH : '100%'),
        maxWidth: ({ flexDirection }: AssetProps) => (flexDirection === 'row' ? FLEX_COLUMN_MAX_WIDTH : '100%'),
        maxHeight: ({ flexDirection }: AssetProps) => (flexDirection === 'row' ? FLEX_COLUMN_MAX_HEIGHT : '100%'),
        minHeight: ({ flexDirection }: AssetProps) => (flexDirection === 'row' ? FLEX_COLUMN_MAX_HEIGHT : '100%'),
      },
    },
    assetButton: {
      flexGrow: 1,
      height: 40,
      justifyContent: 'flex-start',
      minWidth: undefined,
      width: '100%',
    },
    textField: {
      width: '80%',
    },
    truncatedText: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    deleteButton: {
      height: 40,
      minWidth: 40,
      '&:active': {
        background: theme.palette.grey[10],
      },
    },
  },
  { name: 'Asset' },
)

const Asset: React.FC<AssetProps> = ({
  id,
  asset,
  additionalFields,
  name,
  displayNameEditable,
  flexDirection,
  removeAsset,
  readOnly,
  withThumbnail,
}) => {
  const classes = useStyle({ readOnly, flexDirection })
  const methods = useFormContext()
  const { isMobile } = useLayoutContext()
  const [open, setOpen] = useState<boolean>(false)

  const handleViewAsset = () => {
    if (!isImageType(asset.mimeType)) {
      typeof window !== 'undefined' && window.open(asset.presignedDownloadUrl)
    } else {
      setOpen(!open)
    }
  }

  return (
    <Flex
      className={classes.root}
      flexDirection={flexDirection}
      gap={flexDirection === 'column' ? 1 : 2}
      alignItems={flexDirection === 'row' ? 'center' : 'flex-start'}
    >
      {methods && <RHFAssetInput name={`${name}.id`} value={id} />}
      {methods &&
        additionalFields?.length > 0 &&
        additionalFields.map((field) => (
          <RHFAssetInput key={field.name} name={`${name}.${field.name}`} value={asset[field.prop]} />
        ))}
      {withThumbnail && asset && (
        <div onClick={handleViewAsset} className={classes.thumbnail}>
          {isImageType(asset.mimeType) && <AssetImage asset={asset} />}
          {isPdfType(asset.mimeType) && (
            <PdfThumbnail
              height={isMobile ? FLEX_COLUMN_MAX_HEIGHT : DEFAULT_MAX_HEIGHT}
              width={isMobile ? FLEX_COLUMN_MAX_WIDTH : DEFAULT_MAX_WIDTH}
              assetUrl={asset.presignedDownloadUrl!}
            />
          )}
        </div>
      )}
      <Flex gap={flexDirection === 'column' ? 1 : 2} className={classes.root}>
        {!readOnly && displayNameEditable ? (
          <TextField
            name={`${name}.displayName`}
            fullWidth
            condensed
            className={classes.textField}
            defaultValue={asset.displayName || asset?.fileName}
          />
        ) : (
          <SecondaryButton
            size='small'
            startIcon={<PaperclipSmallIcon color='textPrimary' />}
            className={classes.assetButton}
            onClick={handleViewAsset}
            fullWidth
          >
            <PSmall mb={0} className={classes.truncatedText}>
              {asset.displayName || asset?.fileName}
            </PSmall>
          </SecondaryButton>
        )}
        {!readOnly && (
          <SecondaryButton
            size='small'
            className={clsx(classes.deleteButton)}
            onClick={removeAsset}
            startIcon={<DeleteMiniIcon color={30} />}
          />
        )}
      </Flex>
      <ViewAsset readOnly={readOnly} asset={asset} open={open} removeAsset={removeAsset} onClose={handleViewAsset} />
    </Flex>
  )
}

export { Asset }
