import { BaseTrigger } from './base-trigger'

export type TransformedEvent<T> = T extends BaseTrigger
  ? {
      context: T['context']
      entity: T['target']['type']
      type: T['action']
      data: T['payload']
    }
  : never

export const transformToEventDefinition = (trigger: BaseTrigger): TransformedEvent<BaseTrigger> => {
  const { context, target, action, executesAt, payload, ...common } = trigger

  return {
    ...common,
    context,
    entity: target.type,
    type: action,
    data: trigger.payload,
  }
}
