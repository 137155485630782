import { AdminApi } from '@guiker/real-estate-shared'
import { TypeOf, yup } from '@guiker/yup-util'

export const updateListingPropertyBuildingDetailSchema = yup.object({
  property: yup
    .object({
      totalSquareFootage: yup.number().min(0).nullable(),
      building: AdminApi.Schema.updatePropertyBuildingDetailSchema.required(),
    })
    .required(),
})

export type UpdateListingPropertyBuildingDetailSchema = TypeOf<typeof updateListingPropertyBuildingDetailSchema>
