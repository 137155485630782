import React from 'react'

import {
  AnyColor,
  Chip,
  Flex,
  makeStyles,
  P,
  padding,
  PSmall,
  RouterLink,
  useDateFormatter,
} from '@guiker/react-framework'

export type SearchListItemProps = {
  url?: string
  primary: string
  secondary?: string
  date?: string
  status?: {
    text: string
    color: AnyColor
  }
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: padding(0.5, 1.5),
      '&:hover': {
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.grey[5],
      },
    },
  }),
  { name: 'SearchBarItem' },
)

export const SearchListItem: React.FC<SearchListItemProps> = ({ primary, secondary, date, status, url }) => {
  const classes = useStyles()
  const { formatDate } = useDateFormatter()

  const content = (
    <Flex alignItems='center' gap={2} className={classes.root}>
      <Flex flexGrow={1} gap={1.5} alignItems='center'>
        <P color='black' mb={0}>
          {primary}
        </P>
        {secondary && (
          <PSmall color={60} mb={0}>
            {secondary}
          </PSmall>
        )}
        {status && (
          <Chip size='small' variant='light' color={status.color}>
            {status.text}
          </Chip>
        )}
      </Flex>
      <PSmall mb={0} color={60}>
        {date && formatDate(date)}
      </PSmall>
    </Flex>
  )

  return url ? <RouterLink to={url}>{content}</RouterLink> : content
}
