import { HttpMethod, StatusCodes } from '@guiker/http'

import { Quebec } from '../../../entity'
import { createLeaseFromBookingPayloadSchema } from '../../create-lease-from-booking'
import { leaseIdNested } from '../../lease-id-nested'
import {
  bookingIdNested,
  Dwelling,
  dwelling,
  LeaseRestrictionsAndModifications,
  leaseRestrictionsAndModifications,
  LeaseTerm,
  leaseTerm,
  NewLesseeNotice,
  newLesseeNotice,
  NewLesseeNoticeAndLeaseRestrictionsAndModifications,
  newLesseeNoticeAndLeaseRestrictionsAndModifications,
  Participant,
  participant,
  Participants,
  participants,
  Rent,
  rent,
  ServicesAndConditions,
  servicesAndConditions,
  SignatureAndLesseeInformation,
  signatureAndLesseeInformation,
  SignatureAndLiability,
  signatureAndLiability,
} from '../../schemas/quebec'
import { paths } from './paths'

export const QUEBEC_LEASE_INFORMATION_WITH_ID_PATH = `${paths.quebecLease.one.withId(':leaseId')}/lease-information`

const responseValidator = (payload: unknown) => Promise.resolve(payload as Quebec.Lease)

export const routes = {
  createQuebecLease: {
    path: paths.quebecLease.one.base(),
    method: HttpMethod.POST,
    successStatusCode: StatusCodes.CREATED,
    authenticated: true,
    responseValidator,
    payloadValidator: (payload: unknown) => Promise.resolve(payload),
  },
  createQuebecLeaseFromBooking: {
    path: paths.quebecLease.one.withScope('booking', ':bookingId'),
    method: HttpMethod.POST,
    successStatusCode: StatusCodes.CREATED,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => bookingIdNested.validate(payload),
    payloadValidator: (payload: unknown) => createLeaseFromBookingPayloadSchema.validate(payload),
  },
  updateQuebecLeaseParticipants: {
    path: QUEBEC_LEASE_INFORMATION_WITH_ID_PATH,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<Participants> => participants.validate(payload),
  },
  updateQuebecLeaseCurrentParticipant: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/participant`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<Participant> => participant.validate(payload),
  },
  updateQuebecLeaseTerm: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/lease-term`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<LeaseTerm> => leaseTerm.validate(payload),
  },
  updateQuebecLeaseRent: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/rent`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<Rent> => rent.validate(payload),
  },
  updateQuebecLeaseServicesAndConditions: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/services-and-conditions`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<ServicesAndConditions> => servicesAndConditions.validate(payload),
  },
  updateQuebecLeaseRestrictionsAndModifications: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/lease-restrictions-and-modifications`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<LeaseRestrictionsAndModifications> =>
      leaseRestrictionsAndModifications.validate(payload),
  },
  updateQuebecLeaseDwelling: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/dwelling`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<Dwelling> => dwelling.validate(payload),
  },
  updateQuebecLeaseNewLesseeNotice: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/new-lessee-notice`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<NewLesseeNotice> => newLesseeNotice.validate(payload),
  },
  updateQuebecLeaseNewLesseeNoticeAndLeaseRestrictionsAndModifications: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/new-lessee-notice-and-lease-restrictions-and-modifications`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<NewLesseeNoticeAndLeaseRestrictionsAndModifications> =>
      newLesseeNoticeAndLeaseRestrictionsAndModifications.validate(payload),
  },
  updateQuebecLeaseSignatureAndLesseeInformation: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/signature-and-lessee-information`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<SignatureAndLesseeInformation> =>
      signatureAndLesseeInformation.validate(payload),
  },
  updateQuebecLeaseSignatureAndLiability: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/signature-and-liability`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<SignatureAndLiability> => signatureAndLiability.validate(payload),
  },
}
