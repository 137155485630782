import React from 'react'

import { printRoomConfiguration } from '@guiker/base-listing-components'
import { Flex, PBig, PSmall, PSmaller, useT, useTranslation } from '@guiker/react-framework'
import { UnitType } from '@guiker/real-estate-shared'
import { CurrencyISO, money } from '@guiker/shared-framework'

import { RentPeriod } from './RentPeriod'
import { Unit } from './type'

type UnitConfigurationProp = {
  unit: Unit
  className?: string
}

export const UnitConfiguration: React.FC<UnitConfigurationProp> = ({ unit, className }) => {
  const { t } = useTranslation(['common-investmentAssumption'])
  const { tShared } = useT({ domain: 'realEstate', entity: 'unit' })

  return (
    <Flex flexDirection='column' alignItems='flex-start' gap={0.5} className={className}>
      <Flex gap={2} alignItems='center'>
        <PBig mb={0} fontWeight={500}>
          {t(`revenue.occupancy.leaseTerm.${unit.isOccupied ? 'rented' : 'vacant'}`)}
        </PBig>
        {unit.isOccupied && <RentPeriod period={unit.period} />}
      </Flex>
      <PSmall mb={0} color={60}>
        {unit.type === UnitType.COMMERCIAL ? tShared(`type.options.${unit.type}`) : printRoomConfiguration({ unit, t })}
      </PSmall>
    </Flex>
  )
}

type RentalInfoProp = {
  unit: Unit
  currency: CurrencyISO
}

export const RentalInfo: React.FC<RentalInfoProp> = ({ unit, currency }) => {
  const { SharedTrans, tShared } = useT({
    domain: 'investmentAssumption',
    entity: 'revenue.occupancy',
  })
  return (
    <Flex flexDirection='column' alignItems='flex-end'>
      <Flex gap={1} alignItems='flex-end'>
        <SharedTrans
          i18nKey='monthlyRent.value'
          values={{ price: money.fromAmount(unit.monthlyRent, currency).toString('onlySymbol', true) }}
        >
          <PBig textAlign='right' mb={0} fontWeight={500}></PBig>
        </SharedTrans>
      </Flex>
      {!unit.isOccupied && (
        <PSmaller mb={0} textAlign='right'>
          {tShared('monthlyRent.estimated')}
        </PSmaller>
      )}
    </Flex>
  )
}
