import { Lease, LeaseRoleNames, Person } from '../entity'
import { typeChecker } from './type-checker'

export const hasLessorSigned = ({ lease }: { lease: Lease }) => {
  if (typeChecker.isCanadaQuebecLease(lease)) {
    return lease.leaseInformation?.lessors?.every((l) => !!l.signature?.content)
  }

  if (typeChecker.isCanadaOntarioLease(lease)) {
    return lease.leaseInformation?.lessors?.every((l) => !!l.signature?.content)
  }

  return false
}

export const isLeaseSigner = (args: {
  userIdOrEmailAddress: string
  lease: Lease
  as: LeaseRoleNames
}): {
  isSigner: boolean
  role: LeaseRoleNames
} => {
  const { lease, userIdOrEmailAddress, as: role } = args
  const { collaborators = [], lessors = [], lessees = [] } = { ...lease }

  const match = ({ userId, emailAddress }: Person) => {
    return [userId, emailAddress?.toLowerCase()].filter((i) => !!i).includes(userIdOrEmailAddress.toLowerCase())
  }

  const isLessor =
    lessors.some((lessor) => match(lessor)) ||
    collaborators.some((c) => {
      return c.type === 'SIGNER' && match({ userId: c.userId, emailAddress: c.emailAddress } as Person)
    })
  if (isLessor && role === LeaseRoleNames.LESSOR) {
    return { isSigner: true, role }
  }

  const isLessee = lessees.some((lessee) => match(lessee))
  if (isLessee && role === LeaseRoleNames.LESSEE) {
    return { isSigner: true, role }
  }

  const isGuarantor = typeChecker.isCanadaQuebecLease(lease)
    ? lease?.leaseInformation?.lessees?.some((l) => l.guarantor?.emailAddress === userIdOrEmailAddress)
    : typeChecker.isCanadaOntarioLease(lease)
    ? lease?.leaseInformation?.lessees?.some((l) => l.guarantor?.emailAddress === userIdOrEmailAddress)
    : false

  if (isGuarantor && role === LeaseRoleNames.GUARANTOR) {
    return { isSigner: true, role }
  }

  return { isSigner: false, role: LeaseRoleNames.SPECTATOR }
}
