import React, { createContext } from 'react'

import { Asset, AuthenticatedApi, S3AccessControlList } from '@guiker/asset-shared'
import { sleep } from '@guiker/lodash'
import { promiseAll } from '@guiker/promise-utils'
import { ApiClient, generateUseContext, renderChildren } from '@guiker/react-framework'
import { useMutation } from '@guiker/react-query'
import { useSentryContext } from '@guiker/sentry-context'

import { AssetGroup } from '../entity'
import { useAssetAuthenticatedApiClient, useAssetJwtAuthenticatedApiClient } from '../hooks'
import { buildBulkUpdatePayload, buildFlattenedAsset, getS3ApiClient } from '../utils'
import {
  AuthAssetFetcherContextProvider,
  GeneratorAssetFetcherContextProviderProps,
  JwtAssetFetcherContextProvider,
  useAssetFetcher,
} from './AssetFetcher'
import { AssetGroupStorage, useAssetStorageContext } from './AssetStorageContext'

export type Context = {
  assetGroups: AssetGroup[]
  bulkUpdate: () => Promise<void>
  getOneAssetGroupByName: (groupName: string) => AssetGroupStorage
  isUploading: boolean
  isFetching: boolean
}

export const BulkAssetUploaderContext = createContext<Context>(null)

type BulkAssetUploaderContextProviderProps = React.PropsWithChildren & {
  scope: {
    type: string
    id?: string
  }
  acl?: S3AccessControlList
}

type GeneratedBulkAssetUploaderContextProviderProps = BulkAssetUploaderContextProviderProps & {
  apiClient: ApiClient<typeof AuthenticatedApi.routes>
}

type GeneratorBulkAssetUploaderContextProviderProps = BulkAssetUploaderContextProviderProps &
  GeneratorAssetFetcherContextProviderProps & {
    authType: 'jwt' | 'auth'
  }

const BulkAssetUploaderContextProvider = ({
  apiClient,
  children,
  scope,
  acl = S3AccessControlList.PRIVATE,
}: GeneratedBulkAssetUploaderContextProviderProps) => {
  const s3ApiClient = getS3ApiClient()
  const { captureException } = useSentryContext()
  const { isFetching } = useAssetFetcher()

  const { assetGroups, deletedAssets, setAssetGroups, getOneAssetGroupByName } = useAssetStorageContext()

  const { mutateAsync: uploadFile, isLoading: isUploading } = useMutation(s3ApiClient.uploadFile)

  const { mutateAsync: bulkUpdate, isLoading: isUpdating } = useMutation(async () => {
    const flattenedAssets = buildFlattenedAsset(assetGroups)
    const payload = buildBulkUpdatePayload({ flattenedAssets, deletedAssets, acl })

    const bulkUpdateResponse = await (scope.id
      ? apiClient.bulkUpdate({
          pathParams: { scopeType: scope.type, scopeId: scope.id },
          payload,
        })
      : apiClient.bulkUpdateByScopeType({
          pathParams: { scopeType: scope.type },
          payload,
        }))

    await promiseAll(
      bulkUpdateResponse.items.map(async (item, index) => {
        if (item.status !== 'success' || !item.data) {
          return sleep(100)
        }

        const { url, ...s3Info } = (item.data as Asset).details
        const assetFile = flattenedAssets && flattenedAssets[index]
        const assetGroupIndex = assetGroups.findIndex((assetGroup) => assetGroup.type === assetFile.groupType)

        if (!url || !assetFile?.file) {
          return sleep(100)
        }

        await uploadFile(
          { url, file: assetFile.file, s3Info: s3Info },
          {
            onError: (error: Error) => captureException({ error, errorInfo: { file: s3Info.s3Key } }),
          },
        )

        const presignedDownloadUrl = assetFile.presignedDownloadUrl
        assetGroups[assetGroupIndex].assets.splice(assetFile.index, 1, {
          ...item.data,
          presignedDownloadUrl,
        })
      }),
    ).catch((error: Error) => captureException({ error }))

    setAssetGroups(assetGroups)
  })

  const value = {
    assetGroups,
    bulkUpdate,
    isUploading: isUploading || isUpdating,
    isFetching,
    getOneAssetGroupByName,
  }

  return (
    <BulkAssetUploaderContext.Provider value={value}>
      {renderChildren<typeof value>(children, value)}
    </BulkAssetUploaderContext.Provider>
  )
}

const BaseAuthTypeBulkAssetUploadContextProvider: React.FC<GeneratorBulkAssetUploaderContextProviderProps> = ({
  authType,
  children,
  assetGroups,
  ...props
}) => {
  const apiClient = authType === 'jwt' ? useAssetJwtAuthenticatedApiClient() : useAssetAuthenticatedApiClient()
  const AssetFetcher = authType === 'jwt' ? JwtAssetFetcherContextProvider : AuthAssetFetcherContextProvider

  return (
    <AssetFetcher assetGroups={assetGroups} {...props}>
      <BulkAssetUploaderContextProvider apiClient={apiClient as any} {...props}>
        {children}
      </BulkAssetUploaderContextProvider>
    </AssetFetcher>
  )
}

export const JwtBulkAssetUploaderContextProvider = (
  props: Omit<GeneratorBulkAssetUploaderContextProviderProps, 'authType'>,
) => <BaseAuthTypeBulkAssetUploadContextProvider authType='jwt' {...props} />

export const AuthBulkAssetUploaderContextProvider = (
  props: Omit<GeneratorBulkAssetUploaderContextProviderProps, 'authType'>,
) => <BaseAuthTypeBulkAssetUploadContextProvider authType='auth' {...props} />

export const useBulkAssetUploaderContext = generateUseContext(BulkAssetUploaderContext)
