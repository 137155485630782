import React, { useEffect, useState } from 'react'

import {
  ApplicationStatusGroup,
  BookingLeaseStatus,
  BookingStatus,
  hasConfirmRequested,
  hasRentPaymentsPlanSupport,
  isMultitenant as getIsMultitenant,
  Participant,
  ParticipantStepStatus,
  RoommateIntroStatusGroup,
} from '@guiker/booking-shared'
import { legacyRoutes } from '@guiker/legacy-routes'
import {
  Flex,
  IconTooltip,
  makeStyles,
  ProgressTracker,
  PSmall,
  Spinner,
  TextButton,
  theme,
  Trans,
  useTranslation,
  WithTooltip,
} from '@guiker/react-framework'
import { compact } from '@guiker/shared-framework'
import { hasCompletedRoommateProfile, tenantApplicationValidator } from '@guiker/tenant-application-shared'

import { calculateSteps } from '../../../../'
import { useAuthenticationContext, useBookingContext, useLeaseDownloadLink } from '../../../../hooks'
import { useT } from '../../../../i18n'
import { Countdown } from '../'
import { BookingCompletionProgressBar } from '../BookingCompletionProgressBar'
import { SectionHeader } from '../SectionHeader'
import { getSteps } from './get-steps'
import { BookingWithdrawalModal } from './WithdrawModal'

const isRoommateTooltipDisplayable = (status: ParticipantStepStatus): boolean =>
  [ParticipantStepStatus.STARTED, ParticipantStepStatus.NOT_STARTED].includes(status)

const useStyles = makeStyles({
  applicationStatus: {
    fontStyle: 'italic',
    fontSize: '12px',
    marginBottom: theme.spacing(0.5),
    padding: 0,
    paddingTop: theme.spacing(0.5),
    lineHeight: '12px',
    color: 'inherit',
    width: 'fit-content',
  },
})

const ProgressTrackerItemTooltip: React.FC<{ tooltip: string | React.ReactNode; content: string }> = ({
  tooltip,
  content,
}) => (
  <WithTooltip
    title={
      <Flex flexDirection='column' gap={2}>
        <PSmall color={'textSecondary'}>{tooltip}</PSmall>
      </Flex>
    }
  >
    <PSmall component='span'>{content}</PSmall>
  </WithTooltip>
)

const BookingSteps: React.FC<{ isMultitenant: boolean }> = ({ isMultitenant }) => {
  const classes = useStyles()
  const { tMain } = useT({ screenName: 'applicantBookingSteps' })
  const { booking, bookingUser, bookingStatus, linkPath } = useBookingContext()
  const { leaseDownloadLink } = useLeaseDownloadLink(booking)
  const { user } = useAuthenticationContext()

  const [stepStatuses, setEnabledSteps] = useState(calculateSteps(booking, bookingUser as Participant))
  const { enabledSteps, stepsCount, completedStepsCount } = stepStatuses

  const application = booking.applicants?.find((a) => a.userId === user.id)?.application
  const bookingPayment = booking.bookingPayment
  const hasCompletedApplication = tenantApplicationValidator(application.content).hasCompletedWithoutDocuments()

  const applicationLink =
    hasCompletedApplication || bookingStatus.isBooked ? linkPath.applicants.view.path : linkPath.applicants.edit.path
  const roommateProfileLink =
    (application?.content && hasCompletedRoommateProfile(application.content)) || bookingStatus.isBooked
      ? linkPath.applicants.view.path
      : `${linkPath.applicants.edit.path}?step=5`
  const isBookedWithRentPaymentsPlan = bookingStatus.isBooked && hasRentPaymentsPlanSupport({ bookingPayment })

  useEffect(() => {
    setEnabledSteps(calculateSteps(booking, bookingUser as Participant))
  }, [booking.hasEnabledStep])

  const steps = getSteps({ isBooked: bookingStatus.isBooked, enabledSteps, application })

  return (
    <Flex flexDirection='column' gap={2}>
      {!bookingStatus.isBooked && <BookingCompletionProgressBar progress={completedStepsCount / stepsCount} />}
      <ProgressTracker
        items={compact([
          steps.application.enabled &&
            steps.application.item(() => ({
              link: applicationLink,
              disabled: bookingStatus.isExpired,
              content: (
                <Flex flexDirection='column' width='100%'>
                  {bookingUser?.steps.application?.status === ParticipantStepStatus.STARTED ? (
                    <ProgressTrackerItemTooltip
                      tooltip={tMain('tooltip.applicationStep_started')}
                      content={tMain('applicationStep_started')}
                    />
                  ) : (
                    tMain(`applicationStep_${bookingUser?.steps.application?.status.toLowerCase()}`)
                  )}
                  {!bookingStatus.isBooked && ApplicationStatusGroup.REVIEWABLE.includes(application?.status) && (
                    <PSmall className={classes.applicationStatus}>
                      {tMain(`applicationStep_${application?.status.toLowerCase()}`)}
                    </PSmall>
                  )}
                </Flex>
              ),
            })),
          steps.roommate.enabled &&
            steps.roommate?.item(() => ({
              link: roommateProfileLink,
              disabled: bookingStatus.isExpired,
              content: (
                <Flex flexDirection='column' width='100%'>
                  {isRoommateTooltipDisplayable(bookingUser?.steps.application?.status) ? (
                    <ProgressTrackerItemTooltip
                      tooltip={tMain('tooltip.roommateStep_not_started')}
                      content={tMain('roommateStep_not_started')}
                    />
                  ) : (
                    tMain(`roommateStep_${bookingUser?.steps.application?.status.toLowerCase()}`)
                  )}
                  {!bookingStatus.isBooked &&
                    RoommateIntroStatusGroup.REVIEWABLE.includes(application?.roommateIntro?.status) && (
                      <PSmall className={classes.applicationStatus}>
                        {tMain(`roommateStep_${application.roommateIntro.status.toLowerCase()}`)}
                      </PSmall>
                    )}
                </Flex>
              ),
            })),
          steps.document.enabled &&
            steps.document.item((step) => ({
              link: linkPath.document.view.path,
              disabled: bookingStatus.isExpired,
              content: tMain(`documentStep_${step.status.toLowerCase()}`),
            })),
          steps.lease.enabled &&
            steps.lease.item((step) => ({
              link: bookingStatus.isBooked ? leaseDownloadLink : linkPath.lease.sign.path,
              isExternalLink: bookingStatus.isBooked,
              disabled: bookingStatus.isExpired,
              content: (
                <>
                  {step.status === ParticipantStepStatus.STARTED ? (
                    <ProgressTrackerItemTooltip
                      tooltip={tMain('tooltip.leaseStep_started')}
                      content={tMain('leaseStep_started')}
                    />
                  ) : (
                    tMain(`leaseStep_${step.status.toLowerCase()}`)
                  )}
                </>
              ),
            })),
          steps.payment.enabled &&
            steps.payment.item((step) => ({
              link: isBookedWithRentPaymentsPlan
                ? linkPath.rentPayment.path
                : bookingStatus.isBooked
                ? legacyRoutes.MyHome
                : linkPath.payment.contribution.path,
              isExternalLink: !isBookedWithRentPaymentsPlan && bookingStatus.isBooked,
              disabled: bookingStatus.isExpired,
              content: (
                <>
                  {step.status === ParticipantStepStatus.NOT_STARTED ? (
                    <ProgressTrackerItemTooltip
                      tooltip={
                        <Trans
                          i18nKey={
                            isMultitenant
                              ? 'main-booking:applicantBookingSteps.tooltip.paymentStepMultitenant_not_started'
                              : 'main-booking:applicantBookingSteps.tooltip.paymentStep_not_started'
                          }
                        >
                          <PSmall fontWeight={'bold'} color={'textSecondary'} />
                        </Trans>
                      }
                      content={tMain(isMultitenant ? 'paymentStepMultitenant_not_started' : 'paymentStep_not_started')}
                    />
                  ) : (
                    tMain(
                      isMultitenant
                        ? `paymentStepMultitenant_${step.status.toLowerCase()}`
                        : `paymentStep_${step.status.toLowerCase()}`,
                    )
                  )}
                </>
              ),
            })),
        ])}
      />
    </Flex>
  )
}

const ApplicantBookingSteps: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const { booking, bookingUser } = useBookingContext()
  const isMultitenant = getIsMultitenant(booking)
  const { steps } = { ...bookingUser }

  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false)

  if (!steps) {
    return <Spinner />
  }

  const isBooked = booking?.status === BookingStatus.BOOKED
  const confirmRequested = hasConfirmRequested(booking)

  const hasBeenSignedByAllParties = booking.lease?.status === BookingLeaseStatus.SIGNED_BY_ALL_PARTIES
  const { expiresAt, startsAt } = { ...booking.timer }
  const showCountdown = !hasBeenSignedByAllParties && startsAt && expiresAt
  const showTooltip = showCountdown

  return (
    <Flex flexDirection='column' gap={2}>
      <SectionHeader
        subtitle={!confirmRequested && !showTooltip && t('applicantBookingSteps.caption')}
        adornment={showTooltip && <IconTooltip title={t('applicantBookingSteps.caption')} />}
      >
        {t(isBooked ? 'applicantBookingSteps.bookingStepsCompleted' : 'screens.bookingModule.bookingSteps.title')}
      </SectionHeader>
      <Flex flexDirection='column' gap={4}>
        {showCountdown && <Countdown />}
        <BookingSteps isMultitenant={isMultitenant} />
      </Flex>

      {!confirmRequested && (
        <Flex mt={6}>
          <TextButton size='small' onClick={() => setWithdrawalModalOpen(true)}>
            {t('actions.withdraw.withdrawApplication')}
          </TextButton>

          <BookingWithdrawalModal
            open={withdrawalModalOpen}
            setOpen={setWithdrawalModalOpen}
            isMultitenant={isMultitenant}
          />
        </Flex>
      )}
    </Flex>
  )
}

export { ApplicantBookingSteps }
