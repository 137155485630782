import React, { createContext } from 'react'

import { useConfig } from '@guiker/config-context'
import { mapValues } from '@guiker/lodash'
import { DataLayer, DataLayerEvent, PageViewEvent, useGoogleTagManager } from '@guiker/react-google-tag-manager'

export type Context = {
  dataLayer: DataLayer
  sendEvent: (event: DataLayerEvent) => void
  sendPageView: (page: Omit<PageViewEvent, 'event'>) => void
}

export const DataTrackingContext = createContext<Context>(null)

type DataTrackingContextProviderProps = React.PropsWithChildren

const DataTrackingContextProvider: React.FC<DataTrackingContextProviderProps> = ({ children }) => {
  const { logger } = useConfig()
  const { debug, enabled, dataLayer } = useGoogleTagManager()

  const logEvent = (event: DataLayerEvent) => {
    logger.log(
      mapValues(event, (val) => {
        try {
          const parsed = JSON.parse(val)
          return parsed
        } catch {
          return val
        }
      }),
    )
  }

  if (!enabled) {
    const emptyState = {
      dataLayer,
      sendPageView: (page: Omit<PageViewEvent, 'event'>) => {
        debug && logger.log(page)
      },
      sendEvent: (event: DataLayerEvent) => {
        debug && logEvent(event)
      },
    }

    return <DataTrackingContext.Provider value={emptyState}>{children}</DataTrackingContext.Provider>
  }

  const sendEvent = (event: DataLayerEvent) => {
    if (debug) {
      logEvent(event)
    }

    dataLayer.push(event)
  }

  const value = {
    dataLayer,
    sendPageView: (page: Omit<PageViewEvent, 'event'>) => sendEvent({ event: 'pageView', ...page }),
    sendEvent,
  }

  return <DataTrackingContext.Provider value={value}>{children}</DataTrackingContext.Provider>
}

export { DataTrackingContextProvider }
