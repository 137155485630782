import React from 'react'

import { getLocationService } from '@guiker/base-entity'
import { getLocalizedNeighbourhood, useT } from '@guiker/i18n'
import {
  Autocomplete,
  Box,
  ButtonWithLoader,
  Checkbox,
  DatePicker,
  Dropdown,
  Flex,
  Grid,
  TextField,
} from '@guiker/react-framework'
import { DeepPartial } from '@guiker/shared-framework'
import { TenantPreference } from '@guiker/tenant-preference-shared'

type SimplifiedTenantPrefenceFormProps = {
  tenantPreference: DeepPartial<TenantPreference>
  maxWidth?: number
  buttonMarginTop?: number
  isLoading?: boolean
}

export const SimplifiedTenantPreferenceForm: React.FC<SimplifiedTenantPrefenceFormProps> = ({
  tenantPreference,
  maxWidth = 1024,
  buttonMarginTop,
  isLoading = false,
}) => {
  const { tMain, tShared: tSharedPreference } = useT({ domain: 'tenantPreference' })
  const { tShared, t } = useT({ domain: 'rentalListing' })
  const { getCityBySlug, getCityNeighbourhoods, defaultCity } = getLocationService()
  const cityLocation = getCityBySlug(tenantPreference?.leasePreferences?.location || defaultCity)
  const { country, state, city } = cityLocation
  const neighbourhoods = getCityNeighbourhoods(country.countryCode, state.stateCode, city.slug).map((n) => n.slug)

  const getOptionLabel = (slug: string) =>
    getLocalizedNeighbourhood(t, {
      countryCode: country.countryCode,
      citySlug: city.slug,
      neighbourhoodSlug: slug,
    })

  return (
    <Box p={2} mx={'auto'} maxWidth={maxWidth}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Autocomplete
            name={`tenantPreference.leasePreferences.neighbourhoods`}
            label={tSharedPreference('leasePreferences.location.neighbourhood')}
            options={neighbourhoods}
            getOptionLabel={getOptionLabel}
            type='tag'
            maxWidth='100%'
            mb={0}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Dropdown
            options={[
              ...['ENTIRE', 'PRIVATE_ROOM'].map((rentalType) => ({
                value: rentalType,
                label: tMain(`filter.rentalType.${rentalType}`),
              })),
              {
                value: null,
                label: tMain(`filter.rentalType.ANY`),
              },
            ]}
            label={tMain(`filter.rentalType.title`)}
            defaultValue={tenantPreference?.leasePreferences?.rentalType}
            name={`tenantPreference.leasePreferences.rentalType`}
            maxWidth='100%'
            mb={0}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            defaultValue={tenantPreference?.leasePreferences?.budget}
            name={`tenantPreference.leasePreferences.budget`}
            label={tSharedPreference('leasePreferences.maxBudget')}
            maxWidth='100%'
            mb={0}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DatePicker
            defaultValue={tenantPreference?.leasePreferences?.leaseStart}
            name={`tenantPreference.leasePreferences.leaseStart`}
            label={tSharedPreference('leasePreferences.leaseStart')}
            maxWidth='100%'
            mb={0}
          />
        </Grid>
        <Grid item sm={6} xs={12} />
        <Grid item sm={6} xs={12}>
          <Checkbox
            defaultValue={tenantPreference?.leasePreferences?.furniture}
            name={`tenantPreference.leasePreferences.furniture`}
            label={tShared('furnished')}
            maxWidth='100%'
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Checkbox
            defaultValue={tenantPreference?.unitPreferences?.petsAllowed ?? true}
            name={`tenantPreference.unitPreferences.petsAllowed`}
            label={tSharedPreference('unitPreferences.pets')}
            maxWidth='100%'
          />
        </Grid>
      </Grid>
      <Flex width='100%' alignContent='flex-end' flexDirection='row-reverse' mt={buttonMarginTop ?? 8}>
        <ButtonWithLoader type='submit' isLoading={isLoading}>
          {tSharedPreference('cta')}
        </ButtonWithLoader>
      </Flex>
    </Box>
  )
}
