import React from 'react'

import {
  Checkbox,
  Collapse,
  makeStyles,
  PBold,
  PMedium,
  PSmall,
  TextField,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { SupportingDocument } from '@guiker/tenant-application-shared'

import { AssetUploader } from '../..'

type Props = {
  defaultValue: SupportingDocument
  readOnly: boolean
  name: `guarantor.supportingDocuments[${number}]`
  documentType: string
}

const useStyles = makeStyles({
  italic: {
    fontStyle: 'italic',
  },
  readOnlyDocument: {
    fontSize: '12px',
  },
})

const RHFAssetInput: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  const { register } = useFormContext()

  return <input type='hidden' value={value} {...register(name)} />
}

const GuarantorSupportingDocument: React.FC<Props> = ({ documentType, name, defaultValue, readOnly = false }) => {
  const { t } = useTranslation('main-tenantApplication')
  const classes = useStyles()

  const isWaiveRequested: boolean = useWatch({
    name: `${name}.isWaiveRequested`,
    defaultValue: defaultValue?.isWaiveRequested ?? false,
  })

  return (
    <div>
      {!readOnly ? (
        <>
          <PMedium mb={0}>{t(`${documentType}.guarantorLabel`)}</PMedium>
          <PSmall className={classes.italic} color={60} mb={2}>
            {t(`${documentType}.description`)}
          </PSmall>
        </>
      ) : (
        <>
          <PBold className={classes.readOnlyDocument} color={60}>
            {t(`${documentType}.guarantorLabel`).toUpperCase()}
          </PBold>
        </>
      )}
      {!readOnly && <RHFAssetInput name={`${name}.type`} value={documentType} />}
      <Collapse in={readOnly || !isWaiveRequested}>
        <AssetUploader
          readOnly={readOnly}
          groupName={documentType}
          name={`${name}.assets`}
          displayDirection={'row'}
          maxSizeMB={5}
          withThumbnail
          disableDragAndDrop
        />
      </Collapse>
      {readOnly && !isWaiveRequested ? null : (
        <Checkbox
          defaultValue={isWaiveRequested}
          name={`${name}.isWaiveRequested`}
          label={t('cannotProvide')}
          maxWidth={420}
          readOnly={readOnly}
        />
      )}
      <Collapse in={isWaiveRequested}>
        <TextField
          defaultValue={defaultValue?.reasonForWaive}
          readOnly={readOnly}
          maxWidth={420}
          label={t('reasonForCannotProvide')}
          name={`${name}.reasonForWaive`}
          required
        />
      </Collapse>
    </div>
  )
}

export { GuarantorSupportingDocument }
