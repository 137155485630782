import React from 'react'

import { PayInMethodLabel } from '@guiker/payment-app-components'
import {
  Card,
  Divider,
  EyeMiniIcon,
  Flex,
  Grid,
  Link,
  makeStyles,
  PBold,
  PMedium,
  theme,
  useDateFormatter,
  useNavigate,
  useTranslation,
} from '@guiker/react-framework'
import { Payer, RentPaymentsPlanWithTenantInstalments, TenantInstalmentEventTypes } from '@guiker/rent-payment-shared'
import { currency as BaseCurrency, CurrencyISO, money } from '@guiker/shared-framework'

import { parseTenantInstalments } from '../../utils'
import { TenantInstalmentStatusChip } from '../TenantInstalmentStatusChip'

type MobileRentPaymentsPlanTableProps = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
  currency: CurrencyISO
}

const useStyle = makeStyles({
  seeDetailsContainer: {
    gap: theme.spacing(2),
    cursor: 'pointer',
  },
})

export const MobileRentPaymentsPlanTable: React.FC<MobileRentPaymentsPlanTableProps> = ({
  rentPaymentsPlan,
  payer,
  currency,
}) => {
  const { t } = useTranslation(['main-bookingRentPayment'])
  const { formatMonthAndYear } = useDateFormatter()
  const classes = useStyle()
  const tPrefix = 'rentPaymentsPlanTable.mobile.headers'
  const tenantInstalments = parseTenantInstalments({ rentPaymentsPlan, payer })

  return (
    <Grid container spacing={3}>
      {tenantInstalments.map((tenantInstalment) => {
        const navigate = useNavigate()
        const { id, chargeDate, events = [], processedWith } = { ...tenantInstalment }

        const { payInMethod } = processedWith ? processedWith.payer : payer

        const invoiceEvents = events?.filter(({ type }) => type === TenantInstalmentEventTypes.INVOICE)
        const currentInvoiceId = invoiceEvents[invoiceEvents.length - 1]?.id

        const amount = processedWith
          ? money.fromAmount(processedWith.amount, BaseCurrency[processedWith.currency]).toString()
          : money.fromAmount(payer.amount, BaseCurrency[currency]).toString()

        return (
          <Grid item key={id} xs={12}>
            <Card
              p={2}
              width='100%'
              shadow='medium'
              radius={2}
              onClick={() => currentInvoiceId && navigate(`invoice/${currentInvoiceId}`)}
            >
              <Flex alignItems='center' justifyContent='space-between' mb={2}>
                <PMedium mb={0}>
                  {t(`${tPrefix}.card.month`, {
                    month: formatMonthAndYear(chargeDate),
                  })}
                </PMedium>
                <TenantInstalmentStatusChip tenantInstalment={tenantInstalment} />
              </Flex>
              <Flex alignItems='center' justifyContent='space-between'>
                <PayInMethodLabel payInMethod={payInMethod} />
                <PBold mb={0}>{amount}</PBold>
              </Flex>
              {tenantInstalment.processedWith && (
                <>
                  <Divider mt={2} color={10} />
                  <Flex alignItems='center' justifyContent='flex-end' className={classes.seeDetailsContainer}>
                    <EyeMiniIcon />
                    <Link color='textPrimary' to={`./invoice/${currentInvoiceId}`} disabled={!currentInvoiceId}>
                      {t('rentPaymentsPlanTable.actions.seeDetails')}
                    </Link>
                  </Flex>
                </>
              )}
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}
