import { Signature } from '..'

export enum Frequency {
  perMonth = 'month',
  perWeek = 'week',
  other = 'other',
}

export interface NewLesseeNotice {
  completed: boolean
  differentConditions: string
  lessorSignature: Signature
  lowestPrecedingRent: number
  lowestPrecedingRentFrequency: Frequency
  lowestPrecedingRentFrequencyOther: string
  sameCondition: boolean
}
