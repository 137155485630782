import * as React from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { CardWithMedia, Chip, Flex, makeStyles, P, PBold, PSmall } from '@guiker/components-library'
import { getLocalizedNeighbourhood } from '@guiker/i18n'
import { currency, money } from '@guiker/money'
import { RentalListing, routesFromListing } from '@guiker/rental-listing-shared'
import { Link } from '@guiker/router'

import { useTranslation } from '../../hooks'

const IMAGE_HEIGHT = 240

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      height: '100%',
      maxWidth: ({ maxWidth }: { maxWidth: number | string }) => maxWidth,
      minWidth: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    image: {
      width: '100%',
      objectFit: 'cover',
      height: IMAGE_HEIGHT,
      minHeight: IMAGE_HEIGHT,
    },
    anchor: {
      textDecoration: 'none',
      width: '100%',
      height: '100%',
    },
  },
  { name: 'ListingCard' },
)

export type ListingCardProps = {
  className?: string
  listing: RentalListing
  maxWidth?: number | string
}

const ListingCard: React.FC<ListingCardProps> = ({ listing, maxWidth = 380 }) => {
  const classes = useStyles({ maxWidth })
  const { t } = useTranslation(['listing', 'common'])

  let addressLine = ''

  if (listing.address.neighbourhood) {
    addressLine = `${getLocalizedNeighbourhood(t, {
      countryCode: listing.address.country,
      citySlug: listing.address.city,
      neighbourhoodSlug: listing.address.neighbourhood,
    })} · `
  }

  addressLine = `${addressLine}${addressFormatter.printShortAddress(listing.address)}`

  const { roomCount, priceOption } = { ...listing.leadOption }

  const bedroomCountLabel =
    roomCount?.bedroom === 0 ? t('bedroomCount.studio') : t('bedroomCount.nonStudio', { count: roomCount?.bedroom })
  const bathroomCountLabel = t('bathroomCount', { count: roomCount?.bathroom })

  return (
    <Link to={routesFromListing(listing).listing} className={classes.anchor}>
      <CardWithMedia media={{ images: listing.pictures.map((pic) => ({ url: pic.url })) }}>
        <Flex flexDirection='column' gap={1}>
          {!!listing.recommendedSimilarListing ? (
            <Chip color={'info'} size='smaller'>
              {t('chip.similarListing')}
            </Chip>
          ) : (
            <Chip color={'positive'} size='smaller'>
              {t('chip.exactMatch')}
            </Chip>
          )}
          <Flex justifyContent='space-between' mb={1.25}>
            <P mb={0}>{`${bedroomCountLabel} · ${bathroomCountLabel}`}</P>
            <PBold mb={0}>
              {priceOption &&
                money.fromAmount(priceOption?.price.amount, currency[priceOption?.price.currency]).toString(true, true)}
            </PBold>
          </Flex>
          <PSmall mb={0} color={60}>
            {addressLine}
          </PSmall>
        </Flex>
      </CardWithMedia>
    </Link>
  )
}

export { ListingCard }
