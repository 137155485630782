import { LeaseRoleNames } from '@guiker/lease-shared'
import { initializeCanWithRoleNames } from '@guiker/permissions'

import { BookingActions, BookingRoleNames } from '../../permissions'
import { BookingLeaseParticipantRole, BookingLeaseParticipantStatus, BookingLeaseStatus } from '../booking-lease'
import { BookingRoleNamesRoleResolver } from '../booking-role-names-role-resolver'
import { ParticipantStepStatus } from '../participant'

export const inferApplicantLeaseStepStatus = (
  status: BookingLeaseParticipantStatus | undefined,
  leaseStatus: BookingLeaseStatus,
): ParticipantStepStatus => {
  switch (status) {
    case BookingLeaseParticipantStatus.WAITING_ON_LEASE_TO_BE_READY:
      return ParticipantStepStatus.NOT_READY
    case BookingLeaseParticipantStatus.MISSING_SIGNATURE:
      return ParticipantStepStatus.NOT_STARTED
    case BookingLeaseParticipantStatus.SIGNED:
      return ParticipantStepStatus.COMPLETED
    default:
      if (!leaseStatus) {
        return ParticipantStepStatus.NOT_READY
      }

      return ParticipantStepStatus.NOT_STARTED
  }
}

export const inferUnitManagerLeaseStepStatus = (
  leaseStatus?: BookingLeaseStatus,
  unitManagerRole?: BookingRoleNames,
) => {
  const { can } = initializeCanWithRoleNames<BookingRoleNames, BookingActions>({
    roleNames: [unitManagerRole],
    roleResolver: BookingRoleNamesRoleResolver,
  })

  const canDraftLease = can(BookingActions.DraftLease)

  if (!leaseStatus) {
    return canDraftLease ? ParticipantStepStatus.NOT_STARTED : ParticipantStepStatus.NOT_READY
  }

  if (leaseStatus === BookingLeaseStatus.SIGNED_BY_ALL_PARTIES) {
    return ParticipantStepStatus.COMPLETED
  }

  if (BookingLeaseStatus.STARTED && !canDraftLease) {
    return ParticipantStepStatus.NOT_READY
  }

  if ([BookingLeaseStatus.STARTED, BookingLeaseStatus.READY_TO_BE_SIGNED].includes(leaseStatus)) {
    return ParticipantStepStatus.STARTED
  }

  return ParticipantStepStatus.NOT_STARTED
}

export const mapLeaseRoleToBookingLeaseRole = (role: LeaseRoleNames): BookingLeaseParticipantRole => {
  switch (role) {
    case LeaseRoleNames.LESSOR:
      return BookingLeaseParticipantRole.LESSOR
    case LeaseRoleNames.LESSEE:
      return BookingLeaseParticipantRole.LESSEE
    case LeaseRoleNames.GUARANTOR:
      return BookingLeaseParticipantRole.GUARANTOR
    default:
      return BookingLeaseParticipantRole.UNKNOWN
  }
}
