import { Envelope, Template } from '@guiker/document-shared'
import { HttpMethod } from '@guiker/http'

import { bookingIdSchema } from '../../schema'
import { paths } from '../paths'
import { attachBookingDocumentPayloadValidator } from '../schema'

const BOOKING_DOCUMENT_PATH = paths.bookingDocument.one.withScope('booking', ':bookingId')

export const routes = {
  attachBookingDocuments: {
    path: BOOKING_DOCUMENT_PATH,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as Envelope & { editUrl: string }),
    pathParamsValidator: (params: unknown) => bookingIdSchema.validate(params),
    payloadValidator: (payload: unknown) => attachBookingDocumentPayloadValidator.validate(payload),
  },
  readAllBookingDocumentTemplates: {
    path: `${BOOKING_DOCUMENT_PATH}/new`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Template[]),
    pathParamsValidator: (payload: unknown) => bookingIdSchema.validate(payload),
  },
}
