import { Event } from '@guiker/event'
import { HttpMethod } from '@guiker/http'

import { Applicant, Application, Booking } from '../../../entity'
import { paths } from '../paths'
import {
  bookingApplicantPathParamsSchema,
  disinviteBookingApplicantRequestSchema,
  inviteBookingApplicantsRequestSchema,
  submitBookingApplicationPathParamsSchema,
  submitBookingApplicationPayloadSchema,
  updateBookingApplicantApplicationStatusPayloadSchema,
} from '../schema'

const APPLICANT_PATH = paths.bookingApplicant.one.withScope('booking', ':bookingId')
const APPLICANT_WITH_ID_PATH = paths.bookingApplicant.one.withScopeAndId('booking', ':bookingId', ':applicantUserId')

export const routes = {
  inviteBookingApplicants: {
    path: `${APPLICANT_PATH}/invite`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Applicant[]),
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.index.validate(payload),
    payloadValidator: (payload: unknown) => inviteBookingApplicantsRequestSchema.validate(payload),
  },
  sendApplicantInvitationReminder: {
    path: `${APPLICANT_WITH_ID_PATH}/send-invitation-reminder`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  disinviteBookingApplicant: {
    path: `${APPLICANT_WITH_ID_PATH}/disinvite`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
    payloadValidator: (payload: unknown) => disinviteBookingApplicantRequestSchema.validate(payload),
  },
  updateBookingApplicantApplicationStatus: {
    path: `${APPLICANT_WITH_ID_PATH}/update-application-status`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
    payloadValidator: (payload: unknown) => updateBookingApplicantApplicationStatusPayloadSchema.validate(payload),
  },
  readAllApplicantApplicationEvents: {
    path: `${APPLICANT_WITH_ID_PATH}/application/events`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Event<Booking>[]),
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  revealApplicantSSN: {
    path: `${APPLICANT_WITH_ID_PATH}/application/reveal-ssn`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as string),
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  revealGuarantorSSN: {
    path: `${APPLICANT_WITH_ID_PATH}/application/reveal-guarantor-ssn`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as string),
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  submitBookingApplication: {
    path: `${APPLICANT_WITH_ID_PATH}/application/submit`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as Application),
    payloadValidator: (payload: unknown) => submitBookingApplicationPayloadSchema.validate(payload),
    pathParamsValidator: (payload: unknown) => submitBookingApplicationPathParamsSchema.validate(payload),
  },
  requestBackgroundCheck: {
    path: `${APPLICANT_WITH_ID_PATH}/application/request-background-check`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
  requestGuarantorBackgroundCheck: {
    path: `${APPLICANT_WITH_ID_PATH}/application/guarantor/request-background-check`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => bookingApplicantPathParamsSchema.one.validate(payload),
  },
}
