import { conditionallyRequiredSchema, yup } from '@guiker/shared-framework'

import { VerificationProvider } from '../../../../entity'

export const verificationSchema = (required: boolean) =>
  yup.object({
    provider: conditionallyRequiredSchema(yup.string().oneOf(Object.values(VerificationProvider)), required),
    publicToken: yup.string().nullable(),
    details: conditionallyRequiredSchema(
      yup.object().when('provider', (provider: VerificationProvider) => {
        if (provider === VerificationProvider.PLAID) {
          return yup.object({
            bankAccountId: yup.string().required(),
          })
        }

        return yup.object().oneOf([null])
      }),
      required,
    ),
  })
