import { Asset } from '@guiker/asset-shared'

import { SupportingDocument, TenantApplication } from '../entity'

export const buildSupportingDocuments = (supportingDocuments: SupportingDocument[], scopedAssets: Asset[]) => {
  return supportingDocuments?.map(({ assets, ...attributes }: SupportingDocument) => ({
    ...attributes,
    assets: assets?.map((asset) => scopedAssets.find(({ id }) => id === asset?.id)).filter((a) => !!a),
  }))
}

export const injectSupportingDocuments = (tenantApplication: TenantApplication, assets: Asset[]) => {
  return {
    ...tenantApplication,
    supportingDocuments: buildSupportingDocuments(tenantApplication?.supportingDocuments, assets),
    guarantor: {
      ...tenantApplication?.guarantor,
      supportingDocuments: buildSupportingDocuments(tenantApplication?.guarantor?.supportingDocuments, assets),
    },
  }
}

const hasSupporintDocumentsChanged = (prevDocuments: SupportingDocument[], currentDocuments: SupportingDocument[]) => {
  if (!currentDocuments || prevDocuments.length !== currentDocuments.length) return true

  return prevDocuments.some((prevDocument, index) => {
    if (prevDocument.assets.length !== currentDocuments[index].assets.length) return true
    return !currentDocuments[index].assets.every((asset, index) => prevDocument.assets[index].id === asset.id)
  })
}

export const shouldInjectSupportingDocumentAssets = ({
  cachedApplication,
  incomingApplication,
}: {
  cachedApplication: TenantApplication
  incomingApplication: TenantApplication
}) => {
  if (!cachedApplication) {
    return true
  }

  const hasPrevSupportingDocuments = !!(cachedApplication.supportingDocuments ?? []).length
  const hasPrevGuarantorSupportingDocuments = !!(cachedApplication.guarantor?.supportingDocuments ?? []).length

  if (hasPrevSupportingDocuments || hasPrevGuarantorSupportingDocuments) {
    const hasCurrentSupporintDocuments = (incomingApplication?.supportingDocuments ?? []).length
    const hasCurrentGuarantorSupportingDocuments = (incomingApplication?.guarantor?.supportingDocuments ?? []).length

    return (
      (hasPrevSupportingDocuments &&
        hasCurrentSupporintDocuments &&
        hasSupporintDocumentsChanged(cachedApplication.supportingDocuments, incomingApplication.supportingDocuments)) ||
      (hasPrevGuarantorSupportingDocuments &&
        hasCurrentGuarantorSupportingDocuments &&
        hasSupporintDocumentsChanged(
          cachedApplication.guarantor.supportingDocuments,
          incomingApplication.guarantor.supportingDocuments,
        ))
    )
  } else {
    return true
  }
}
