import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

export const readAllListingsQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object({
    searchString: yup.string(),
  }),
)

export type ReadAllListingsQueryParamsSchema = yup.InferType<typeof readAllListingsQueryParamsSchema>
