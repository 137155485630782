import React from 'react'

import { CountryCode } from '@guiker/background-check-shared'
import { ApiForm, ApiFormAction, Box, ConfirmDialog, P, useTranslation } from '@guiker/react-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { useApplicationComponentsContext } from '../../../context'
import { findLegalAddressFromTenantApplication } from './helpers'

type RequestBackgroundcheckModalProps = {
  tenantApplication: TenantApplication
}

export const RequestBackgroundCheckModal: React.FC<RequestBackgroundcheckModalProps> = ({ tenantApplication }) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const { conductCheckFor, requestBackgroundCheck, openBackgroundCheckModal, handleBackgroundCheckModal } =
    useApplicationComponentsContext()

  const isConductingCheckForGuarantor = conductCheckFor === 'guarantor'
  const address = findLegalAddressFromTenantApplication({
    tenantApplication,
    isGuarantor: isConductingCheckForGuarantor,
  })
  const countryCode = address?.country === 'US' ? CountryCode.US : CountryCode.CA

  return (
    <ApiForm formName='BackgroundCheckRequestCheckForm'>
      <ConfirmDialog
        open={openBackgroundCheckModal}
        title={t('main-tenantApplication:screens.steps.backgroundCheck.conductCheck')}
        description={
          <Box mb={2}>
            <P color={60}>
              {t('main-tenantApplication:screens.steps.backgroundCheck.conductQuestion', {
                country: t(`main-tenantApplication:screens.steps.backgroundCheck.modal.${countryCode}`),
              })}
            </P>
          </Box>
        }
        onClose={handleBackgroundCheckModal}
        cancelButtonProps={{ children: t('common:actions.cancel'), onClick: handleBackgroundCheckModal }}
      >
        <ApiFormAction
          onClick={() => requestBackgroundCheck({ conductCheckFor })}
          options={{ onSuccess: handleBackgroundCheckModal }}
          buttonProps={{ fullWidth: true }}
        >
          {t('common:actions.confirm')}
        </ApiFormAction>
      </ConfirmDialog>
    </ApiForm>
  )
}
