import { TypeOf, yup } from '@guiker/yup-util'

import { ListingOperator } from '../../../entity'
import { addressSchema } from '../../schemas/address-schema'
import { listingOperatorSchema } from './listing-operator-schema'

export const createListingPayloadSchema = yup.object({
  address: addressSchema.required(),
  listingOperators: yup
    .array()
    .of(listingOperatorSchema)
    .required()
    .test('contactPersonRequired', 'contact person is required', (value: ListingOperator[]) => {
      return value?.filter((v) => v.isContactPerson).length === 1
    })
    .test('leaseContractSignerRequired', 'lease contract signer is required', (value: ListingOperator[]) => {
      return value?.filter((v) => v.isLeaseContractSigner).length === 1
    }),
})

export type CreateListingPayloadSchema = TypeOf<typeof createListingPayloadSchema>
