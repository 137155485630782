import axios, { AxiosRequestConfig } from '@guiker/axios'
import { StatusCodes } from '@guiker/http'

import { ApiError } from './api-error'

export interface Headers {
  [key: string]: string
}

export type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'

export interface Response<T> {
  body: T
  statusCode: number
  headers: Record<string, string>
}

type Options = {
  mutePayload?: boolean
}

const defaultHeader: Headers = {
  'Content-Type': 'application/json',
}

export interface Logger {
  info: (message: string, data?: unknown) => void
  error: (message: string, data?: unknown) => void
}

export interface RequestOptions {
  headers?: Headers
  queryParams?: object
  mutePayload?: boolean
}

async function _performRequest<T>(
  method: HttpMethod,
  url: string,
  correlatedRequestId?: string,
  logger?: Logger,
  payload?: unknown,
  options?: RequestOptions,
): Promise<Response<T>> {
  let fullHeaders = defaultHeader

  if (options && options.headers) {
    fullHeaders = {
      ...fullHeaders,
      ...options.headers,
    }
  }

  if (correlatedRequestId) {
    fullHeaders['x-correlated-request-id'] = correlatedRequestId
  }

  const config: AxiosRequestConfig = {
    method,
    url,
    headers: fullHeaders,
    ...(options?.queryParams && { params: options.queryParams }),
  }

  if (payload) {
    config.data = payload
  }

  logger &&
    logger.info(`Started request: ${method} ${url}`, { ...config, data: !options.mutePayload ? config.data : 'MUTED' })

  return axios(config)
    .then((response) => {
      logger && logger.info(`Completed request: ${method} ${url} with status: ${response.status}`)

      return {
        statusCode: response.status,
        headers: response.headers,
        body: response.data,
      }
    })
    .catch((error) => {
      logger &&
        logger.error(
          `Completed request with error: ${method} ${url} with status: ${error.response && error.response.status}, ${
            error.message
          } ${error.stack}`,
        )

      if (error.response.status === StatusCodes.NOT_MODIFIED) {
        return {
          statusCode: StatusCodes.NOT_MODIFIED,
          headers: error.response.headers,
          body: undefined,
        }
      }

      throw new ApiError(error.config?.method, error.config?.url, error.response?.status, error, correlatedRequestId)
    })
}

export class BaseRestClient {
  headers: Headers
  logger?: Logger
  correlatedRequestId?: string
  options: Options

  constructor(logger?: Logger, correlatedRequestId?: string, headers: Headers = {}, options = { mutePayload: false }) {
    this.logger = logger
    this.correlatedRequestId = correlatedRequestId
    this.headers = headers
    this.headers = headers
    this.options = options
  }

  public get<T>(url: string, options?: RequestOptions): Promise<Response<T>> {
    return this.performRequest('GET', url, null, options)
  }
  public post<T>(url: string, payload: unknown, options?: RequestOptions): Promise<Response<T>> {
    return this.performRequest('POST', url, payload, options)
  }
  public put<T>(url: string, payload: unknown, options?: RequestOptions): Promise<Response<T>> {
    return this.performRequest('PUT', url, payload, options)
  }
  public patch<T>(url: string, payload: unknown, options?: RequestOptions): Promise<Response<T>> {
    return this.performRequest('PATCH', url, payload, options)
  }
  public delete<T>(url: string, options?: RequestOptions): Promise<Response<T>> {
    return this.performRequest('DELETE', url, null, options)
  }

  public performRequest<T>(
    httpMethod: HttpMethod,
    url: string,
    payload?: unknown,
    options?: RequestOptions,
  ): Promise<Response<T>> {
    return _performRequest(
      httpMethod,
      url,
      this.correlatedRequestId,
      this.logger,
      payload,
      this.buildOptions({ ...options, ...this.options }),
    )
  }

  private buildOptions(options?: RequestOptions): RequestOptions {
    return {
      headers: this.headers,
      ...(options || {}),
    }
  }
}

export function initializeRestClient(logger?: Logger, correlatedRequestId?: string): BaseRestClient {
  return new BaseRestClient(logger, correlatedRequestId)
}
