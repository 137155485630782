import { UnitAmenityName } from '../amenity'

type UnitAppliances<T = boolean | null> = {
  airConditioner: T
  balcony: T
  dishwasher: T
  fridge: T
  petsAllowedSmall: T
  petsAllowedLarge: T
  washer: T // washer & dryer
}

type KitchenAppliances<T = boolean | null> = { oven: T }

const LegacyUnitAmenityMap: {
  [key in UnitAmenityName]: keyof UnitAppliances | keyof KitchenAppliances
} = {
  CONDITIONER: 'airConditioner',
  BALCONY: 'balcony',
  DISH: 'dishwasher',
  REFRIGE: 'fridge',
  SMALL_PETS_ALLOWED: 'petsAllowedSmall',
  LARGE_PETS_ALLOWED: 'petsAllowedLarge',
  WASHER: 'washer',
  OVEN: 'oven',
}

export { UnitAppliances, KitchenAppliances, LegacyUnitAmenityMap }
