import { Errors, StatusCodes } from '@guiker/common'
export class ApplicantIsOneOfUnitManagersError extends Errors.BaseError {
  constructor(id: number) {
    super(
      `Unit manager userId:${id} cannot be an applicant`,
      'ApplicantIsOneOfUnitManagersError',
      StatusCodes.UNPROCESSABLE_ENTITY,
    )
  }
}
