import React, { useState } from 'react'

import { DateTime, toISODate } from '@guiker/date'
import { MinusSmallIcon } from '@guiker/icons'
import { VisitSchedule } from '@guiker/listing-inquiry-shared'
import { useFormContext } from '@guiker/react-hook-form'

import { useTranslation } from '../../hooks'
import { Button, Checkbox, DatePicker, Flex, FormHelperText, Link, makeStyles, PSmall, SecondaryButton } from '../'

export type TimeSlotProps = {
  name: string
  dateName?: string
  timeSlotName?: string
  defaultValue: VisitSchedule
  condensed?: boolean
  onRemove: () => void
  error: boolean
  errorMessage: string
  timeslotError: boolean
  timeslotErrorMessage: string
  hideRemoveLink?: boolean
}

export enum TimeSlotOptions {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening',
}

export type TimeSlots = {
  [key in TimeSlotOptions]?: boolean
}

const useStyle = makeStyles({
  hidden: {
    display: 'none',
  },
})

export const TimeSlot: React.FC<TimeSlotProps> = ({
  name,
  defaultValue,
  dateName = `${name}.date`,
  timeSlotName = `${name}.timeSlot`,
  condensed = false,
  error = false,
  errorMessage = '',
  timeslotError = false,
  timeslotErrorMessage = '',
  onRemove,
  hideRemoveLink,
}) => {
  const { t } = useTranslation(['main-rentalListingInquiry'])
  const classes = useStyle()
  const { setValue } = useFormContext()
  const [timeSlots, setTimeSlots] = useState<TimeSlots>(defaultValue?.timeSlot)

  const handleSelectTimeSlot = (option: TimeSlotOptions, value: boolean) => {
    setTimeSlots({ ...timeSlots, [option]: value })
    setValue(`${timeSlotName}.${option}`, value, { shouldDirty: true })
  }

  const spacing = condensed ? 1 : 2

  return (
    <Flex py={spacing} gap={spacing} flexDirection='column'>
      <Flex justifyContent='space-between'>
        <DatePicker
          name={dateName}
          maxDate={toISODate(DateTime.local().plus({ months: 1 }))}
          defaultValue={defaultValue.date}
          disablePast
          error={error}
          errorMessage={errorMessage}
          condensed={condensed}
        />
        {!hideRemoveLink && (
          <Link
            color={60}
            underline={false}
            TextComponent={PSmall}
            onClick={onRemove}
            adornment={<MinusSmallIcon size='smallest' />}
          >
            {t('common:actions.remove')}
          </Link>
        )}
      </Flex>

      <Flex fullWidth gap={spacing}>
        {Object.keys(TimeSlotOptions).map((timeSlot) => {
          const TimeSlotButton = timeSlots[timeSlot] ? Button : SecondaryButton
          return (
            <div key={timeSlot}>
              <TimeSlotButton
                onClick={() => handleSelectTimeSlot(TimeSlotOptions[timeSlot], !timeSlots[timeSlot])}
                size={condensed ? 'small' : 'medium'}
              >
                {t(`timeSlotScheduler.options.${timeSlot}`)}
              </TimeSlotButton>
              <div className={classes.hidden}>
                <Checkbox
                  name={`${timeSlotName}.${timeSlot}`}
                  defaultValue={timeSlots[timeSlot] ?? false}
                  value={timeSlots[timeSlot] ?? false}
                  error={timeslotError}
                  errorMessage={timeslotErrorMessage}
                />
              </div>
            </div>
          )
        })}
      </Flex>
      {timeslotError && (
        <FormHelperText color='alert' role='alert'>
          {timeslotErrorMessage}
        </FormHelperText>
      )}
    </Flex>
  )
}
