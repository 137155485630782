import { Address, Location } from '@guiker/base-entity'
import { buildWebappRoutes } from '@guiker/base-listing-shared'
import { slugifyLowercase } from '@guiker/lodash'
import { DeepPartial } from '@guiker/ts-utils'

import { RentalListing } from '../entity'

export const webappRoutes = {
  ...buildWebappRoutes({ rootPath: '/listings' }),
  notFound: {
    path: '/listings/not-found',
  },
  manageListing: {
    path: '/myaccount/listings',
    addListing: function () {
      return {
        path: `${this.path}/new`,
      }
    },
    withId: function (listingId: string) {
      return {
        path: `${this.path}/${listingId}/listing`,
      }
    },
  },
} as const

export const slugifyListing = (listing: DeepPartial<RentalListing>) => {
  const { id, address } = { ...listing }
  if (!id || !address) return

  const slug = slugifyLowercase(`${listing.address.streetNumber}-${listing.address.street}`)
  return `${listing.id}/${slug}`
}

export const routesFromListing = (listing: { address: Address; id: string; slug?: string }) => {
  const countryNested = webappRoutes.listings.countryNested(listing.address.country)
  const cityNested = countryNested.cityNested(listing.address.city, listing.address.state)
  const neighbourhoodNested = cityNested.neighbourhoodNested(listing.address.neighbourhood)
  const listingPath = neighbourhoodNested.withId(listing.slug || slugifyListing(listing))

  return {
    countryNested: countryNested.path,
    cityNested: cityNested.path,
    neighbourhoodNested: neighbourhoodNested.path,
    listing: listingPath.path,
    manageListing: webappRoutes.manageListing.withId(listing.id).path,
  }
}

export const routesFromLocation = (location: Location) => {
  const countryNested = webappRoutes.listings.countryNested(location.country.countryCode)
  const cityNested = countryNested.cityNested(location.city?.slug, location.state?.stateCode)
  const neighbourhoodNested = location.neighbourhood
    ? cityNested.neighbourhoodNested(location.neighbourhood?.slug)
    : null

  return {
    countryNested,
    cityNested,
    neighbourhoodNested,
  }
}
