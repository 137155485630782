import React, { useEffect, useState } from 'react'

import { SocialAuthenticationProvider } from '@guiker/authentication-shared'
import { Flex } from '@guiker/components-library'
import { useQuery } from '@guiker/react-query'

import { AuthType } from '../../auth-type'
import { Authenticator, useAuthenticationContext, usePublicAuthenticationApiClient } from '../../authentication'
import { LogInButtonFacebook } from './LogInButtonFacebook'
import { LogInButtonGoogle } from './LogInButtonGoogle'

type Props = {
  authType?: AuthType
}

const SocialLogIn: React.FC<Props> = ({ authType = AuthType.LOGIN }) => {
  const apiClient = usePublicAuthenticationApiClient()
  const [authenticators, setAuthenticators] = useState<Authenticator[]>([])
  const [socialAuthButtons, setSocialAuthButtons] = useState([])
  const { socialAuthProviders } = useAuthenticationContext()

  const { isSuccess: initialized } = useQuery('authenticators', (_) => apiClient.getAllAuthenticators(), {
    onSuccess: (authenticators) => setAuthenticators(authenticators),
  })

  const socialLogInButtons = [
    {
      key: 'GOOGLE',
      Component: LogInButtonGoogle,
      authenticator: authenticators.find((auth) => auth.provider === 'GOOGLE' && auth.enabled),
    },
    {
      key: 'FACEBOOK',
      Component: LogInButtonFacebook,
      authenticator: authenticators.find((auth) => auth.provider === 'FACEBOOK' && auth.enabled),
    },
  ]

  useEffect(() => {
    setSocialAuthButtons(
      socialLogInButtons.filter((btn) => {
        return socialAuthProviders.includes(btn.key as SocialAuthenticationProvider)
      }),
    )
  }, [authenticators, socialAuthProviders])

  return (
    <Flex flexDirection='column' gap={2}>
      {socialAuthButtons.map(({ Component, authenticator, key }) => (
        <Component key={key} authType={authType} authenticator={authenticator} initialized={initialized} />
      ))}
    </Flex>
  )
}

export { SocialLogIn }
