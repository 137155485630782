import React, { createContext, useLayoutEffect, useState } from 'react'

import { SocialAuthenticationProvider } from '@guiker/authentication-shared'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { usePersistancyContext } from '@guiker/react-persistance'

import { usePublicAuthenticationApiClient, useUserMetadataAuthApiClient } from './lib'
import { useAuthenticationAppApiClient } from './lib/use-legacy-app-api-client'
import { AuthError, AuthType, ContextUser, SetUserOptions } from './types'
import { parseInvitationToken, usePersistedUser } from './utils'

type Context = {
  user: ContextUser | null
  setUser: (user?: ContextUser, options?: SetUserOptions) => Promise<void>
  unsetUser: () => void
  error?: AuthError
  socialAuthProviders: SocialAuthenticationProvider[]
}

export const AuthenticationContext = createContext<Context>({
  user: null,
  setUser: () => {},
  unsetUser: () => {},
} as Context)

type Props = React.PropsWithChildren &
  Partial<Context> & {
    socialAuthProviders?: SocialAuthenticationProvider[]
  }

export const AuthenticationContextProvider: React.FC<Props> = ({
  socialAuthProviders = ['FACEBOOK', 'GOOGLE'],
  children,
}) => {
  const claimContext = useClaimsAuthenticationContext()
  const token = claimContext?.token

  const publicAuthApiClient = usePublicAuthenticationApiClient()
  const userMetadataApiClient = useUserMetadataAuthApiClient()

  const [error, setError] = useState<AuthError>()
  const { user, setUser: _setPersistUser } = usePersistedUser()
  const { unsetData: unsetUser } = usePersistancyContext()
  const legacyApiClient = useAuthenticationAppApiClient()

  const setPersistUser = (newUser: ContextUser) => {
    const compareFactors = ['id', 'metadata', 'status'] as (keyof ContextUser)[]
    if (compareFactors.some((factor) => newUser?.[factor] !== user?.[factor])) {
      _setPersistUser(newUser)
    }
  }

  const canSkipPersist = (newUser: ContextUser = undefined) => {
    const { id, status } = { ...newUser }
    return !!user && user.id === id && user.status === status
  }

  const setUser = async (newUser: ContextUser = undefined, options: SetUserOptions = {}) => {
    if (options.type === AuthType.ACTIVATE && !!user && user.invitationToken && user.id !== newUser?.id) {
      setError('activation.accountMismatch')
      return
    }
    if (!!token && canSkipPersist(newUser)) return

    setError(undefined)

    await Promise.all([
      userMetadataApiClient(newUser.accessToken).readOneUserMetadataByUser(),
      newUser.isAdmin ? legacyApiClient.fetchUserAppRoles(newUser.accessToken, newUser.id) : [],
    ])
      .then(([{ id, userId, ...metadata }, appRoles]) => setPersistUser({ ...newUser, metadata, appRoles }))
      .catch(() => setPersistUser(newUser))
  }

  const authenticateWithInvitationToken = async (token: string) => {
    const authenticated = await publicAuthApiClient.authenticateWithInvitationToken({ payload: { token } })
    setUser({ ...authenticated, invitationToken: token })
  }

  useLayoutEffect(() => {
    !!parseInvitationToken(token) && authenticateWithInvitationToken(token)
  }, [token])

  const contextValue = {
    user,
    setUser,
    unsetUser,
    error,
    socialAuthProviders,
  }

  return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>
}
