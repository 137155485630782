import React from 'react'

import { SecondaryButton } from '@guiker/components-library'
import { useT } from '@guiker/i18n'
import { useQueryClient } from '@guiker/react-query'
import { ApiFormAction } from '@guiker/rhf-components'
import { AuthenticatedApi } from '@guiker/tenant-preference-shared'

import {
  useAuthenticatedTenantPreferenceApiClient,
  useAuthenticationContext,
  useFormContext,
  useTenantPreferenceContext,
} from '../../hooks'

type ButtonContainerProps = {
  onClose: () => void
}

export const ButtonContainer: React.FC<ButtonContainerProps> = ({ onClose }) => {
  const { tBase } = useT()
  const { user } = useAuthenticationContext()
  const { reset } = useFormContext()
  const { setTenantPreference, tenantPreference } = useTenantPreferenceContext()
  const apiClient = useAuthenticatedTenantPreferenceApiClient()
  const client = useQueryClient()

  const handleSubmit = (payload: AuthenticatedApi.Schema.UpsertTenantPreference) =>
    apiClient.upsertTenantPreference({
      pathParams: { userId: user.id },
      payload,
    })

  const handleClear = () => {
    reset({ tenantPreference: { leasePreferences: { neighbourhoods: [] } } })
  }

  return (
    <>
      <SecondaryButton onClick={handleClear}>{tBase('actions.clear')}</SecondaryButton>
      <ApiFormAction
        onClick={handleSubmit}
        options={{
          onSuccess: (data) => {
            client
              .getQueryCache()
              .find(['recommended-listings', tenantPreference?.id, tenantPreference?.updatedAt])
              ?.fetch()

            setTenantPreference(data)
            onClose()
          },
        }}
        buttonProps={{ fullWidth: true }}
      >
        {tBase('actions.save')}
      </ApiFormAction>
    </>
  )
}
