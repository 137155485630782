import { DateTime } from 'luxon'

import { isString } from '@guiker/lodash'

export type EligibleDate = Date | DateTime | string | number | ISO8601Date
export type ISO8601Date = `${number}-${number}-${number}`

export const toISODate = (date: EligibleDate): ISO8601Date => {
  if (!date) return
  if (isString(date) && isValidISO8601Date(date)) return date

  return parseDateTime(date).toISODate() as ISO8601Date
}

const ISO8601DateRegex = /^\d\d\d\d-\d\d-\d\d$/
const isValidISO8601Date = (date: string): date is ISO8601Date => ISO8601DateRegex.test(date)

export const parseDateTime = (date: EligibleDate): DateTime => {
  if (!date) {
    return
  }

  if (date instanceof DateTime) {
    return date.toUTC()
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date)
  } else {
    try {
      return parseDate(date)
    } catch (_error) {
      return DateTime.fromJSDate(new Date(date))
    }
  }
}

export const parseDate = (date: EligibleDate, isUTC = false): DateTime => {
  if (!date) return

  if (date instanceof DateTime) {
    return isUTC ? date.toUTC() : date
  } else if (date instanceof Date) {
    return isUTC ? DateTime.fromJSDate(date, { zone: 'utc' }) : DateTime.fromJSDate(date)
  } else if (typeof date === 'number') {
    return DateTime.fromMillis(date, { zone: 'utc' })
  } else {
    if (isValidISO8601Date(date)) {
      return DateTime.fromISO(date).toLocal()
    }

    try {
      return isUTC ? DateTime.fromJSDate(new Date(date), { zone: 'utc' }) : DateTime.fromJSDate(new Date(date))
    } catch (_error) {
      return isUTC ? DateTime.fromISO(date, { zone: 'utc' }) : DateTime.fromISO(date)
    }
  }
}
