import React, { createContext, useEffect, useState } from 'react'

import { Payment } from '@guiker/base-entity'
import { Capabilities, PayoutMethod, PayoutMethodType, regionPayoutMethodMapper } from '@guiker/payout-shared'
import { generateUseContext, useQuery } from '@guiker/react-framework'

import { useAuthenticatedPayoutApiClient } from '../hooks'

type Context = {
  selected: PayoutMethod | undefined
  allowedTypes: PayoutMethodType[]
  setSelected: (payoutMethod: PayoutMethod) => void
  isLoading: boolean
  payoutMethods: PayoutMethod[]
  refetch: () => void
  unsetSelected: () => void
  capabilities: Capabilities[]
  regions: Payment.SupportedCountries[]
}

type Props = React.PropsWithChildren & {
  regions: Payment.SupportedCountries[]
  capabilities: Capabilities[]
}

export const PayoutContext = createContext<Context>(null)

export const PayoutContextProvider: React.FC<Props> = ({
  regions: propRegions,
  capabilities: propCapabilities,
  children,
}) => {
  const [selected, setSelected] = useState<PayoutMethod | undefined>(undefined)
  const [regions, setRegion] = useState<Payment.SupportedCountries[]>(propRegions)
  const [capabilities, setCapabilities] = useState<Capabilities[]>(propCapabilities)
  const [allowedTypes, setAllowedTypes] = useState<PayoutMethodType[]>(regionPayoutMethodMapper(propRegions))

  const apiClient = useAuthenticatedPayoutApiClient()

  const { data, isLoading, refetch } = useQuery(
    'PayoutContextProvider-readAllPayoutMethods',
    () =>
      apiClient
        .readAllPayoutMethods({
          queryParams: { sort: '', page: 1, perPage: 10, sortOrder: 1, searchString: '' },
        })
        .then((v) => v?.data),
    {
      retry: true,
    },
  )

  useEffect(() => {
    propRegions !== regions && setRegion(propRegions)
    setAllowedTypes(regionPayoutMethodMapper(propRegions))
  }, [propRegions])

  useEffect(() => {
    propCapabilities !== capabilities && setCapabilities(propCapabilities)
  }, [propCapabilities])

  const unsetSelected = () => {
    setSelected(undefined)
  }

  const value = {
    selected,
    capabilities,
    allowedTypes,
    unsetSelected,
    setSelected,
    isLoading,
    payoutMethods: data,
    refetch,
    regions,
  }

  return <PayoutContext.Provider value={value}>{children}</PayoutContext.Provider>
}

export const usePayoutContext = generateUseContext(PayoutContext)
