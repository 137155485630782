import React from 'react'

import { Box, H4, makeStyles, PSmall, useNavigate, useTranslation } from '@guiker/react-framework'

type SectionHeaderProps = {
  title: string
  onClickEditRoute?: string
}

const useStyle = makeStyles({
  editButton: {
    cursor: 'pointer',
  },
})

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, onClickEditRoute }) => {
  const { t } = useTranslation(['common'])
  const classes = useStyle()
  const navigate = useNavigate()

  const onClickEdit = () => navigate(onClickEditRoute)

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <H4>{title}</H4>
      {onClickEditRoute && (
        <Box className={classes.editButton} onClick={onClickEdit}>
          <PSmall color={30}>{t('actions.edit')}</PSmall>
        </Box>
      )}
    </Box>
  )
}
