import { yup } from '@guiker/yup-util'

import { SupportedCountries } from '../../../entity'
import { profileSchema } from '../../schemas'

const createDirectDebitACHPayloadSchema = yup.object({
  region: yup.string().oneOf(Object.values(SupportedCountries)).default(SupportedCountries.UnitedStates).required(),
  bankAccount: yup.object({
    routingNumber: yup.string().min(9).max(9).required(),
    wireRouting: yup.string().min(9).max(12),
    accountNumber: yup.string().min(5).max(12).required(),
  }),
  profile: profileSchema.required(),
})

type CreateDirectDebitACHPayloadSchema = yup.InferType<typeof createDirectDebitACHPayloadSchema>

export { createDirectDebitACHPayloadSchema, CreateDirectDebitACHPayloadSchema }
