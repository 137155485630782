import React from 'react'

import { FundingAccountDeposit, FundingAccountDepositStatus } from '@guiker/payment-shared'
import { PaginatedTable, PaginatedTableProps, Pagination } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { useGetColumns } from './get-columns'

const StatusMap = {
  success: [FundingAccountDepositStatus.COMPLETED, FundingAccountDepositStatus.PAYMENT_SETTLED],
  processing: [FundingAccountDepositStatus.PROCESSING],
  bad: [FundingAccountDepositStatus.FAILED, FundingAccountDepositStatus.JOURNAL_ENTRY_INSERTION_FAILED],
}

type FundingAccountDepositsTableProps<StatusGroupName extends string = never, TabValue extends object = never> = Pick<
  PaginatedTableProps<FundingAccountDeposit, StatusGroupName, FundingAccountDepositStatus, TabValue>,
  'queryKey' | 'fetcher' | 'tabs' | 'exportAsCSV'
> & {
  withSearch?: boolean
  withGroups?: boolean
  pagination?: Partial<Pagination>
}

export const FundingAccountDepositsTable = <StatusGroupName extends string = never, TabValue extends object = never>({
  exportAsCSV,
  fetcher,
  pagination,
  queryKey,
  tabs,
  withGroups = true,
  withSearch = true,
}: FundingAccountDepositsTableProps<StatusGroupName, TabValue>) => {
  const { tShared } = useT({ entity: 'fundingAccount.deposit' })
  const columns = useGetColumns({ callbackArgs: { showNote: true } })

  return (
    <PaginatedTable
      exportAsCSV={exportAsCSV}
      queryKey={queryKey}
      fetcher={fetcher}
      tabs={tabs}
      search={withSearch}
      pagination={pagination}
      statusGroups={
        withGroups
          ? {
              mapper: StatusMap,
              groups: [
                { name: 'all', label: tShared('statusGroup.all') },
                { name: 'success', label: tShared('statusGroup.success') },
                { name: 'processing', label: tShared('statusGroup.processing') },
                { name: 'bad', label: tShared('statusGroup.bad') },
              ],
            }
          : undefined
      }
      columns={columns}
    />
  )
}
