import { routesBuilder } from '@guiker/shared-framework'

import { User } from '../../entity'
import { paths } from './paths'
import { changePasswordSchema, updateProfileSchema } from './schemas'

const base = routesBuilder<User>()({
  basePath: paths.user.all.base(),
})

export const routes = {
  authenticate: base.buildGet({
    path: '/authenticate',
  }),
  readOneById: base.buildGet({
    path: '/:userId',
  }),
  changePassword: base.buildPut({
    path: '/:userId/password',
    payloadSchema: changePasswordSchema,
  }),
  updateProfile: base.buildPut({
    path: '/:userId/update-profile',
    payloadSchema: updateProfileSchema,
  }),
}
