import React, { createContext } from 'react'

import { Series } from '@guiker/propsharing-shared'
import { generateUseContext } from '@guiker/react-framework'

type SeriesContextProviderProps = React.PropsWithChildren & {
  data: Series
}

type SeriesContext = {
  data: Series
}

const SeriesContext = createContext<SeriesContext>(null)

export const SeriesContextProvider: React.FC<SeriesContextProviderProps> = ({ children, data }) => {
  const value = {
    data,
  }

  return <SeriesContext.Provider value={value}>{children}</SeriesContext.Provider>
}

export const useSeries = generateUseContext(SeriesContext)
