import React from 'react'

import { FormSection, IconTooltip, Label, Link, RadioGroup, useTranslation, useWatch } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { OccupationType, SupportingDocument, TenantApplication } from '@guiker/tenant-application-shared'

import { useBulkAssetUploaderContext } from '../../../hooks'
import { GuarantorDetails } from './GuarantorDetails'

type GuarantorProps = {
  defaultValue: Partial<TenantApplication>
  schema?: yup.ObjectSchema
  editRoute?: string
  readOnly?: boolean
  withThumbnail?: boolean
}

const Guarantor: React.FC<GuarantorProps> = ({
  defaultValue,
  schema,
  editRoute,
  readOnly = false,
  withThumbnail = true,
}) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const tPrefix = 'main-tenantApplication:'
  const hasGuarantor = useWatch<TenantApplication, 'hasGuarantor'>({
    name: 'hasGuarantor',
    defaultValue:
      defaultValue?.hasGuarantor ?? defaultValue?.occupation?.type === OccupationType.STUDENT ? true : undefined,
  })

  const { assetGroups } = useBulkAssetUploaderContext()

  return (
    <>
      <FormSection
        title={t('main-tenantApplication:screens.steps.guarantor.title')}
        subtitle={t('main-tenantApplication:hasGuarantor.description')}
        topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
      >
        {readOnly && defaultValue?.hasGuarantor ? null : (
          <RadioGroup
            label={
              <Label
                adornment={{
                  node: <IconTooltip title={t(`${tPrefix}hasGuarantor.tooltip`)} />,
                  position: 'end',
                }}
                text={t(`${tPrefix}hasGuarantor.title`)}
              />
            }
            name='hasGuarantor'
            defaultValue={hasGuarantor || undefined}
            options={[
              { label: t(`${tPrefix}hasGuarantor.true`), value: true },
              { label: t('common:no'), value: false },
            ]}
            readOnly={readOnly}
            condensed={readOnly}
          />
        )}
        {hasGuarantor && (
          <GuarantorDetails
            hasGuarantor={hasGuarantor}
            defaultValue={{
              ...defaultValue,
              guarantor: { ...defaultValue?.guarantor, supportingDocuments: assetGroups as SupportingDocument[] },
            }}
            withThumbnail={withThumbnail}
            schema={schema}
            readOnly={readOnly}
          />
        )}
      </FormSection>
    </>
  )
}

export { Guarantor }
