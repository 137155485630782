import React from 'react'

import { AssetFile, BaseAssetContainer } from '@guiker/asset-uploader'
import { isAtMostTablette } from '@guiker/components-core'
import { Flex, makeStyles, Note } from '@guiker/components-library'
import { FileMimeType } from '@guiker/dropzone'
import { useTranslation } from '@guiker/i18n'

import { ImageWithResult } from '../IdentityVerificationContext'

type GovernmentIdProps = {
  name: string
  onAdd: (asset: AssetFile) => void
  onRemove: () => void
  isLoading: boolean
  imageWithResult: ImageWithResult
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexDirection: 'row',
      gap: theme.spacing(4),
      [isAtMostTablette]: {
        gap: theme.spacing(2),
        flexDirection: 'column',
      },
    },
  }),
  { name: 'GovernmentId' },
)

export const GovernmentId: React.FC<GovernmentIdProps> = ({ isLoading, name, onAdd, onRemove, imageWithResult }) => {
  const classes = useStyles()
  const [t] = useTranslation(['common-components'])
  const tPrefix = 'common-components:identityVerification.governmentId'

  const hasError = imageWithResult && imageWithResult?.status && imageWithResult?.status !== 'SUCCESS'
  const isSuccess = imageWithResult && imageWithResult?.status && imageWithResult?.status === 'SUCCESS'

  return (
    <Flex gap={4} className={classes.root}>
      <BaseAssetContainer
        showDropzone={!imageWithResult}
        onAddAsset={onAdd}
        onRemoveAsset={onRemove}
        assets={imageWithResult?.image ? [imageWithResult.image] : []}
        name={name}
        withThumbnail={true}
        dropzoneOptions={{ maxFiles: 1, accept: [FileMimeType.jpg, FileMimeType.png] }}
      />
      <Flex flexDirection='column' fullWidth>
        {isLoading && <Note icon='loading' title={t(`${tPrefix}.processing.title`)} />}
        {!isLoading && isSuccess && (
          <Note icon='success' statusColor='success' title={t(`${tPrefix}.success.title`)}>
            {t(`${tPrefix}.success.content`)}
          </Note>
        )}
        {!isLoading && hasError && (
          <Note statusColor='warning' icon='warning' title={t(`${tPrefix}.failure.title`)}>
            {t(`${tPrefix}.failure.content`)}
            <ul>
              <li>{t(`${tPrefix}.fixRecommendations.line1`)}</li>
              <li>{t(`${tPrefix}.fixRecommendations.line2`)}</li>
              <li>{t(`${tPrefix}.fixRecommendations.line3`)}</li>
              <li>{t(`${tPrefix}.fixRecommendations.line4`)}</li>
            </ul>
          </Note>
        )}
      </Flex>
    </Flex>
  )
}
