import { generateEventsDefinition } from '@guiker/event'

import { NotificationPreference } from '../../entity'
import { baseEvent as domainBaseEvent } from '../base'

export const EventsDefinition = generateEventsDefinition({
  ...domainBaseEvent,
  entity: 'NOTIFICATION_PREFERENCE',
  data: null as unknown as NotificationPreference,
  eventTypes: {} as const,
})
export const Events = EventsDefinition.events
