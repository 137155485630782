import { NotificationTypeConfig } from './notification-type-config'

export type PreferenceTypes = {
  [C in keyof typeof NotificationTypeConfig]: {
    context: C
  } & {
    [E in keyof (typeof NotificationTypeConfig)[C]]: {
      context: C
      entity: E
    }
  }
}

export const PreferenceTypes = Object.keys(NotificationTypeConfig).reduce(
  <C extends keyof typeof NotificationTypeConfig>(acc: PreferenceTypes, context: string) => ({
    ...acc,
    [context]: Object.keys(NotificationTypeConfig[context as C]).reduce(
      <E extends keyof (typeof NotificationTypeConfig)[C]>(nestedAcc: PreferenceTypes, entity: string) => ({
        ...nestedAcc,
        context,
        [entity as E]: {
          context,
          entity,
        },
      }),
      acc,
    ),
  }),
  {} as PreferenceTypes,
)

export type PreferenceType<
  C extends keyof typeof PreferenceTypes = any,
  E extends keyof (typeof PreferenceTypes)[C] = any,
> = {
  context: C
  entity: E
}
