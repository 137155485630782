import { TypeOf, yup } from '@guiker/yup-util'

import { rentalOptionSchema } from '../../schemas'
import { createListingPayloadSchema } from './create-listing-payload-schema'
import { updateListingPayloadSchema } from './update-listing-payload-schema'

export const upsertListingWithRentalOptionSchema = createListingPayloadSchema.concat(updateListingPayloadSchema).shape({
  externalId: yup.string().required(),
  title: yup.string().optional(),
  rentalOptions: yup.array().of(rentalOptionSchema).min(1).ensure(),
  status: yup.string().oneOf(['LISTED', 'UNLISTED']).required(),
  source: yup.object({
    name: yup.string().required(),
    cityId: yup.number().required(),
    postedBy: yup.string().nullable(),
    reference: yup.string().nullable(),
  }),
})

export type UpsertListingWithRentalOptionSchema = TypeOf<typeof upsertListingWithRentalOptionSchema>
