import React, { useState } from 'react'

import { Payment } from '@guiker/base-entity'
import { usePayInMethodContext } from '@guiker/payment-context'
import {
  ApiForm,
  FormSection4,
  makeStyles,
  Modal,
  Spinner,
  TextField,
  theme,
  TwoColumnsGridLayout,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'
import { RecaptchaProvider } from '@guiker/react-recaptcha'
import {
  buildCardElementOptions,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  StripeProvider,
  useStripe,
} from '@guiker/stripe-context'
import { yup } from '@guiker/yup-util'

import { PayInMethodIcon } from '../../PayInMethodIcon'
import { ButtonContainer } from './ButtonContainer'
import { InputErrors } from './utils'

type StripeAppProps = {
  region: Payment.SupportedCountries
}

const useStyles = makeStyles(
  {
    cardBrandContainer: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      '& > svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  {
    name: 'StripeApp',
  },
)

const FormContent: React.FC<{ inputError: InputErrors }> = ({ inputError }) => {
  const tPrefix = 'common-payment:creditCard'
  const { t } = useTranslation('common-payment')
  const { elements } = useStripe()

  if (!elements) {
    return <Spinner />
  }

  return (
    <FormSection4>
      <TextField required maxWidth='100%' label={t(`${tPrefix}.cardholderName`)} name='cardholderName' />

      <CardNumberElement
        label={t(`${tPrefix}.cardNumber`)}
        options={buildCardElementOptions}
        inputError={inputError.number}
      />
      <TwoColumnsGridLayout>
        <CardExpiryElement
          label={t(`${tPrefix}.cardExpirationDate`)}
          options={buildCardElementOptions}
          inputError={inputError.expiry}
        />
        <CardCvcElement label={t(`${tPrefix}.cardCvc`)} options={buildCardElementOptions} inputError={inputError.cvc} />
      </TwoColumnsGridLayout>
    </FormSection4>
  )
}

export const StripeAppModal: React.FC<StripeAppProps> = ({ region }) => {
  const { t } = useTranslation('common-payment')
  const classes = useStyles()
  const resolver = yupResolver(yup.object({ cardholderName: yup.string().required() }))
  const { modalManager } = usePayInMethodContext()
  const { isOpen, closeModal } = modalManager.addCreditCard

  const [inputError, setInputError] = useState<InputErrors>({})

  return (
    <RecaptchaProvider>
      <StripeProvider region={region}>
        <Modal
          open={isOpen}
          onClose={closeModal}
          title={t('common-payment:creditCard.title')}
          disableBackdropClick
          maxWidth={800}
        >
          <ApiForm formName={'CreateCreditCardForm'} formOptions={{ resolver }}>
            <div className={classes.cardBrandContainer}>
              <PayInMethodIcon type={Payment.PayInMethodType.CREDIT_CARD} brand='MASTERCARD' />
              <PayInMethodIcon type={Payment.PayInMethodType.CREDIT_CARD} brand='VISA' />
            </div>
            <FormContent inputError={inputError} />
            <ButtonContainer onClose={closeModal} setInputError={setInputError} />
          </ApiForm>
        </Modal>
      </StripeProvider>
    </RecaptchaProvider>
  )
}
