import { Role } from '@guiker/permissions'

import { BookingActions } from '../booking-actions'
import { BookingRoleNames } from '../role-names'

export const LeasingAssociateRole: Role = {
  name: BookingRoleNames.LEASING_ASSOCIATE,
  permissions: [
    { action: BookingActions.ParticipateChat },
    { action: BookingActions.InviteApplicant },
    { action: BookingActions.DisinviteApplicant },
    { action: BookingActions.InviteUnitManager },
    { action: BookingActions.GetBookingSteps },
    { action: BookingActions.GetBookingPayment },
    { action: BookingActions.EditApplicationStatus },
    { action: BookingActions.EditPricing },
    { action: BookingActions.EditPeriod },
    { action: BookingActions.ViewLease },
    { action: BookingActions.ViewSSN },
    { action: BookingActions.ConductBackgroundCheck },
    { action: BookingActions.DownloadBackgroundCheck },
    { action: BookingActions.ViewBookingDocument },
  ],
}
