import { useEffect, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useLocationQuery } from '@guiker/router'

interface RedirectToState {
  path: string
  force: boolean
}

const useRedirectOnAuthenticate = () => {
  const { user } = useAuthenticationContext()
  const [redirectTo, setRedirectTo] = useState<RedirectToState>()
  const query = useLocationQuery('redirect')

  useEffect(() => {
    if (user) {
      if (query.redirect) {
        setRedirectTo({ path: query.redirect, force: false })
      } else {
        setTimeout(() => (window.top.location.href = '/'), 500)
      }
    }
  }, [user, query])

  return { redirectTo }
}

export { useRedirectOnAuthenticate }
