import { useEffect, useState } from 'react'

import { isEqual } from '@guiker/lodash'

export const useKeyPress = (targetKeys: string[], callback: () => unknown) => {
  const [keys, setKeys] = useState<string[]>([])

  const downHandler = ({ key }: { key: string }) => {
    setKeys((keys) => [...keys, key])
  }

  const upHandler = ({ key }: { key: string }) => {
    setKeys((keys) => keys.filter((k) => k !== key))
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  const keysMatch = isEqual(keys.sort(), targetKeys.sort())

  useEffect(() => {
    keysMatch && callback()
  }, [keysMatch])
}
