import { CountryCode, parsePhoneNumber as baseParsePhoneNumber } from 'libphonenumber-js'

export const parsePhoneNumber = (text: any, defaultCountry?: CountryCode) => {
  if (!text || typeof text !== 'string') return

  const phoneNumberText = text[0] === '+' && text.split(' ').length > 1 ? text.split(' ').slice(1).join('') : text

  return baseParsePhoneNumber(phoneNumberText, defaultCountry)
}

export const regexNumber = new RegExp('^[0-9 ]+$', 'g')

export const isValidPhoneNumber = (phoneNumber: string, country: CountryCode) => {
  const pn = parsePhoneNumber(phoneNumber, country)
  return pn.isValid() && phoneNumber.substring(0, 1) === '+' && regexNumber.test(phoneNumber.substring(1))
}
