import { User } from '@guiker/authentication-shared'
import { Booking, getParticipant, getParticipantType, Participant } from '@guiker/booking-shared'

export type BookingUser = User &
  Partial<Participant> & {
    type?: ReturnType<typeof getParticipantType>
  }

export const toBookingUser = ({ booking, user }: { booking: Booking; user: User }) => {
  const participant = booking && user && getParticipant({ booking, user })

  const bookingUser = {
    ...user,
  }

  if (!participant) {
    return bookingUser
  }

  return {
    ...bookingUser,
    ...participant,
    type: getParticipantType(participant),
  }
}
