import { TenantInstalmentInvoiceEventStatus } from '@guiker/rent-payment-shared'

export const simplifyTenantInstalmentInvoiceEventStatus = (status: TenantInstalmentInvoiceEventStatus) => {
  const failedStatuses = ['VERIFICATION_FAILED', 'CHARGE_FAILED', 'CHARGE_REFUND_FAILED', 'TRANSFER_FAILED']
  if (failedStatuses.includes(status)) return 'failed'
  if (['CANCELED'].includes(status)) return 'cancelled'
  if (['REFUND_SUCCEED'].includes(status)) return 'refunded'

  return 'unsettled'
}
