import { CreditReport } from './credit-check'
import { IdentityVerificationReport } from './identity-verification'
import { SoftcheckReport } from './soft-check'

export { CountryCode } from '@guiker/base-entity'

export type OverallScore = 'PASS' | 'WARN' | 'FAIL' | 'NONE'
export type RequestStatus = 'RETURNED' | 'ANALYZING' | 'ERROR' | 'PENDING' | 'PARTIAL' | 'NONE'
export type BackgroundCheckResult = 'Review' | 'Cleared' | 'None'
export type BackgroundCheckStatus = 'Pending' | 'Analyzing' | 'Returned'

export type ConductFor = 'applicant' | 'guarantor'

export type OverallReport = {
  result: BackgroundCheckResult
  status: BackgroundCheckStatus
  certnScore: number
  certnScoreLabel: OverallScore
}

export type Address = {
  address: string
  city: string
  country: string
  provinceState: string
  postalCode: string
}

export type BackgroundCheck = {
  id?: string
  firstName: string
  lastName: string
  addresses: Address[]
  dateOfBirth: string
  ssn?: string
  email?: string
  requestSoftcheck: boolean
  requestCreditReport: boolean
  requestEnhancedIdentityVerification: boolean
  consent?: boolean
  requesterId?: string
  targetId?: string
  identityVerification?: {
    documentType: string
    governmentIdFront: {
      id: string
    }
    governmentIdBack?: {
      id: string
    }
    facePicture: {
      id: string
    }
  }
  applicantId?: string // applicant === 'target' for certn
  results: {
    overall: OverallReport
    softcheck?: SoftcheckReport
    creditReport?: CreditReport
    identityVerification?: IdentityVerificationReport
  }
}
