import { LegacyUser } from '@guiker/authentication-shared'
import { Legacy as LegacyListing } from '@guiker/rental-listing-shared'
import { money } from '@guiker/shared-framework'

import {
  BookingRoleNames,
  BookingStatus,
  getProbableRentPeriod,
  InvitationStatus,
  isRoommatable,
  UnitManager,
} from '..'

export const getRoomName = ({ option }: { option: LegacyListing.Option }) => {
  if (option.configuration.type === LegacyListing.RentalType.PRIVATE_ROOM) {
    return option.configuration.title ?? 'Room'
  }
}

export const transformToUnitManager = (
  legacyUser: Partial<LegacyUser>,
  role: BookingRoleNames,
): Partial<UnitManager> => ({
  avatarUrl: legacyUser.avatar,
  emailAddress: legacyUser.email,
  firstName: legacyUser.firstName,
  lastName: legacyUser.lastName,
  userId: legacyUser.id.toString(),
  role,
  invitation: {
    status: InvitationStatus.ACCEPTED,
  },
})

export const initBookingFromListing = (args: { listing: LegacyListing.Listing; option: LegacyListing.Option }) => {
  const { listing, option } = args
  const currency = money.currency[listing.rent.currency]
  const unitManagers = listing.listingUsers.map((listingUser) =>
    listingUser.isContactPerson
      ? transformToUnitManager(listingUser.user, BookingRoleNames.LISTING_OPERATOR)
      : transformToUnitManager(listingUser.user, BookingRoleNames.SPECTATOR),
  )
  const contactUser = listing.listingUsers.find((listingUser) => listingUser.isContactPerson)

  if (!contactUser) {
    throw Error('No contactUserFound')
  }

  const bookingListing = {
    externalId: listing.id,
    address: {
      apartmentNumber: listing.suiteNumber,
      room: getRoomName({ option }),
      city: listing.city.cityName,
      state: listing.city.state,
      countryCode: listing.city.country,
      postalCode: listing.zipcode,
      street: listing.streetName,
      streetNumber: listing.streetNumber,
    },
    thumbnailUrl: listing.pictures[0]?.pictureName || null,
  }

  const bookingPeriod = isRoommatable(bookingListing)
    ? getProbableRentPeriod({ status: BookingStatus.STARTED, period: listing.availability })
    : listing.availability

  return {
    info: {
      price: {
        ...listing.rent,
        // Note: 2020-June-2
        // legacy listing.readOne returns monetized amount
        amount: money.toAmount(listing.rent.amount, currency).amount,
        currency: currency.iso,
      },
      period: bookingPeriod,
    },
    listing: bookingListing,
    primaryUnitManagerUserId: contactUser?.user.id.toString(),
    unitManagers,
  }
}
