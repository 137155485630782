import { AdminApiClient } from '@guiker/authentication-shared'
import { useConfig } from '@guiker/config-context'
import { v4 as uuid } from '@guiker/uuid'

export const useAdminAuthenticationApiClient = (accessToken?: string) => {
  const { apiBaseUrl: baseUrl } = useConfig()

  return AdminApiClient({
    baseUrl,
    accessToken,
    correlatedRequestId: uuid(),
  })
}
