import { TypeOf, yup } from '@guiker/shared-framework'

import { verificationDocumentSchema } from './verification-document'

export const updateVerificationDocumentsSchema = yup
  .object({
    verificationDocuments: yup.array().of(verificationDocumentSchema.required()).required(),
  })
  .required()

export type UpdateVerificationDocumentsSchema = TypeOf<typeof updateVerificationDocumentsSchema>
