import { HttpMethod, StatusCodes } from '@guiker/http'

import { Ontario } from '../../../entity'
import { createLeaseFromBookingPayloadSchema } from '../../create-lease-from-booking'
import { leaseIdNested } from '../../lease-id-nested'
import {
  AdditionalTerms,
  additionalTerms,
  bookingIdNested,
  ContactInformation,
  contactInformation,
  LeaseTermAndRent,
  leaseTermAndRent,
  LesseeSignature,
  lesseeSignature,
  LessorSignature,
  lessorSignature,
  PartiesToTheAgreementAndRentalUnit,
  partiesToTheAgreementAndRentalUnit,
  RentDepositRentDiscountsAndKeyDeposit,
  rentDepositRentDiscountsAndKeyDeposit,
  ServicesAndUtilities,
  servicesAndUtilities,
  SmokingAndTenantsInsurance,
  smokingAndTenantsInsurance,
} from '../../schemas/ontario'
import { paths } from './paths'

export const ONTARIO_LEASE_INFORMATION_WITH_ID_PATH = `${paths.ontarioLease.one.withId(':leaseId')}/lease-information`

const responseValidator = (payload: unknown) => Promise.resolve(payload as Ontario.Lease)

export const routes = {
  createOntarioLease: {
    path: paths.ontarioLease.one.base(),
    method: HttpMethod.POST,
    successStatusCode: StatusCodes.CREATED,
    authenticated: true,
    responseValidator,
    payloadValidator: (payload: unknown) => Promise.resolve(payload),
  },
  createOntarioLeaseFromBooking: {
    path: paths.ontarioLease.one.withScope('booking', ':bookingId'),
    method: HttpMethod.POST,
    successStatusCode: StatusCodes.CREATED,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => bookingIdNested.validate(payload),
    payloadValidator: (payload: unknown) => createLeaseFromBookingPayloadSchema.validate(payload),
  },
  updateOntarioLeasePartiesToTheAgreementAndRentalUnit: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-parties-to-the-agreement-and-rental-unit`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<PartiesToTheAgreementAndRentalUnit> =>
      partiesToTheAgreementAndRentalUnit.validate(payload),
  },
  updateOntarioLeaseContactInformation: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-contact-information`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<ContactInformation> => contactInformation.validate(payload),
  },
  updateOntarioLeaseTermAndRent: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-term-and-rent`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<LeaseTermAndRent> => leaseTermAndRent.validate(payload),
  },
  updateOntarioLeaseServicesAndUtilities: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-services-and-utilities`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<ServicesAndUtilities> => servicesAndUtilities.validate(payload),
  },
  updateOntarioLeaseRentDepositRentDiscountsAndKeyDeposit: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-rent-deposit-rent-discounts-and-key-deposit`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<RentDepositRentDiscountsAndKeyDeposit> =>
      rentDepositRentDiscountsAndKeyDeposit.validate(payload),
  },
  updateOntarioLeaseSmokingAndTenantsInsurance: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-smoking-and-tenants-insurance`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<SmokingAndTenantsInsurance> =>
      smokingAndTenantsInsurance.validate(payload),
  },
  updateOntarioAdditionalTerms: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/additional-terms`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<AdditionalTerms> => additionalTerms.validate(payload),
  },
  updateOntarioLeaseLessorSignature: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-lessor-signature`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<LessorSignature> => lessorSignature.validate(payload),
  },
  updateOntarioLeaseLesseeSignature: {
    path: `${ONTARIO_LEASE_INFORMATION_WITH_ID_PATH}/lease-lessee-signature`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<LesseeSignature> => lesseeSignature.validate(payload),
  },
}
