import { Application } from './application'
import { Participant, ParticipantStepStatus } from './participant'

export enum ApplicantRoles {
  APPLICANT = 'APPLICANT',
}

export interface Applicant extends Participant {
  role: ApplicantRoles
  steps?: {
    application: {
      status: Exclude<ParticipantStepStatus, ParticipantStepStatus.NOT_READY>
    }
    roommate: {
      status: Exclude<ParticipantStepStatus, ParticipantStepStatus.PROCESSING>
    }
    document: {
      status: Exclude<ParticipantStepStatus, ParticipantStepStatus.PROCESSING>
    }
    lease: {
      status: ParticipantStepStatus
    }
    payment: {
      status: Exclude<ParticipantStepStatus, ParticipantStepStatus.NOT_READY>
    }
  }
  application?: Application
}

export type InvitedApplicant = Omit<Applicant, 'steps'> & { userId: string; accessToken?: string }
