import { routesBuilder, StatusCodes } from '@guiker/shared-framework'

import { PayoutMethod } from '../../../entity'
import { paths } from '../paths'
import { payoutMethodCapabilitySchema, readAllPayoutMethodsSchema } from '../schemas'
import { routes as nestedRoutes } from './:payoutMethodId'

export const path = paths.payoutMethod.all.base()

const { buildGet, buildPost } = routesBuilder<PayoutMethod>()({
  basePath: path,
})

export const routes = {
  ...nestedRoutes,
  readAllPayoutMethods: buildGet({
    paginated: true,
    queryParamsSchema: readAllPayoutMethodsSchema,
  }),
  createPayoutMethod: buildPost({
    payloadSchema: payoutMethodCapabilitySchema,
    successStatusCode: StatusCodes.CREATED,
  }),
}
