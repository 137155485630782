import { User } from '@guiker/authentication-shared'
import { usePersistancyContext } from '@guiker/react-persistance'

type Session = {
  originalUser?: User
  user?: User
}

export const useSpoofInContext = () => {
  const { data, setData, spoof } = usePersistancyContext<Session>()
  const originalUser = data?.originalUser

  const setOriginalUser = (args: Session) => {
    setData({ originalUser: args.originalUser, user: args.user })
  }

  const unsetOriginalUser = () => {
    setData({ originalUser: null })
  }

  return { originalUser, setOriginalUser, unsetOriginalUser, spoof }
}
