import { useContext } from 'react'

import { StaticAssetContext } from './StaticAssetContext'

const useStaticAssetContext = () => {
  const context = useContext(StaticAssetContext)

  if (context === undefined) {
    throw new Error('useStaticAssetContext can only be used inside StaticAssetContextProvider')
  }

  return context
}

export { useStaticAssetContext }
