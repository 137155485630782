import React from 'react'

import { TabLayout } from '@guiker/react-framework'
import { RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'
import { optionalConcat } from '@guiker/shared-framework'

import { PayerRentPaymentsPlanTable } from './PayerRentPaymentsPlanTable'

type RentPaymentProps = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
}

export const RentPaymentByReceiver: React.FC<RentPaymentProps> = ({ rentPaymentsPlan }) => {
  return (
    <TabLayout
      tabs={rentPaymentsPlan.payers.map((payer) => ({
        label: optionalConcat([payer.firstName, payer.lastName], ' '),
        content: <PayerRentPaymentsPlanTable rentPaymentsPlan={rentPaymentsPlan} payer={payer} />,
      }))}
    />
  )
}
