import React from 'react'

import { parseAccessControlToken } from '@guiker/access-control-shared'
import { useAuthenticationContext } from '@guiker/authentication-context'
import {
  Booking,
  BookingActions,
  BookingRoleResolver,
  BookingStatus,
  mainPathBuilder,
  typeChecker,
} from '@guiker/booking-shared'
import { mainPathBuilder as conversationPathBuilder } from '@guiker/conversation-shared'
import { initializeCanWithUser } from '@guiker/permissions'
import { useNavigate, useQuery } from '@guiker/react-framework'
import { useWebsocketListener } from '@guiker/use-websocket'

import { toBookingUser } from './booking-user'
import { BookingContext, BookingContextType } from './BookingContext'
import { getApiClient } from './get-api-client'

type RenderChildren = (context: BookingContextType) => React.ReactNode

type BookingProviderProps = {
  bookingId: string
  children: React.ReactNode | RenderChildren
  containerRef?: React.MutableRefObject<React.ReactInstance>
}

const BookingContextProvider: React.FC<BookingProviderProps> = ({ bookingId, containerRef, children }) => {
  const { user } = useAuthenticationContext()
  const apiClient = getApiClient()
  const navigate = useNavigate()

  const chatLinkPath = conversationPathBuilder.root.messages.path({ bookingId })
  const queryKey = ['booking', bookingId]
  const {
    data: booking,
    error,
    refetch,
    isLoading,
  } = useQuery<Booking>(queryKey, (_) => apiClient.readOneBooking({ pathParams: { bookingId } }), {
    enabled: !!bookingId,
    retry: 1,
    staleTime: 1000,
  })

  const tokenKey = ['token', bookingId]
  const { data: accessControl } = useQuery(
    tokenKey,
    async () => {
      const res = await apiClient.generateAccessControlToken({ pathParams: { bookingId } })

      const claims = parseAccessControlToken(res, { validateExpiration: true })

      if (typeChecker.isBookingRoleToken(claims, bookingId)) {
        return { token: res, claims }
      }
    },
    {
      enabled: !!user?.id,
      staleTime: 1000,
    },
  )

  useWebsocketListener({
    pattern: ['BOOKING'],
    callback: async ({}: { entityId: string }) => {
      await refetch()
    },
  })

  const { can, canOrThrow } = initializeCanWithUser<Booking, BookingActions>({
    user,
    roleResolver: BookingRoleResolver,
    entity: booking,
  })

  const refetchBooking = async () => {
    await refetch()
  }

  const value = {
    bookingId,
    booking,
    can,
    canOrThrow,
    apiClient,
    bookingUser: toBookingUser({ user, booking }),
    isFetching: isLoading,
    error,
    accessControl,
    refetchBooking,
    containerRef,
    linkPath: mainPathBuilder.root.byId(bookingId),
    bookingChat: {
      path: chatLinkPath,
      navigate: () => navigate(chatLinkPath),
    },
    bookingStatus: {
      isBooked: booking?.status === BookingStatus.BOOKED,
      isExpired: booking?.status === BookingStatus.EXPIRED,
    },
  }

  return (
    <BookingContext.Provider value={value}>
      {typeof children === 'function' ? (children as RenderChildren)(value) : children}
    </BookingContext.Provider>
  )
}

export { BookingContextProvider }
