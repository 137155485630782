import { TypeOf, yup } from '@guiker/yup-util'

import { Quebec } from '../../../entity'
import { signature } from '../../schemas'
import { leaseEmailUniquenessSchema } from '../shared'

export type GuarantorWithoutSignature = Partial<Quebec.Guarantor> & {
  fullName: string
  address: string
  emailAddress: string
}

export type Guarantor = Partial<Quebec.Guarantor> & {
  fullName: string
  address: string
  signature: TypeOf<typeof signature>
}

export const guarantorWithoutSignature = yup.object<GuarantorWithoutSignature>({
  capacity: yup.string(),
  fullName: yup.string(),
  address: yup.string(),
  phoneNumber: yup.string(),
  emailAddress: leaseEmailUniquenessSchema,
})

export const guarantor = yup.object<Guarantor>({
  capacity: yup.string(),
  fullName: yup.string(),
  address: yup.string(),
  emailAddress: leaseEmailUniquenessSchema,
  phoneNumber: yup.string(),
  signature: signature.required(),
})
