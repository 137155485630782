import { idNestedParamsValidator, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { Developer, DeveloperWithProjects } from '../../../entity'
import { upsertDeveloperSchema } from '../schemas'
import { paths } from './paths'

const basePath = paths.developer.all

export const developerRoutes = {
  readAllDevelopers: {
    path: basePath.base(),
    method: HttpMethod.GET,
    authenticated: true,
    queryParamsValidator: validatorBuilder.buildPaginationQueryParamValidator(),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<DeveloperWithProjects, {}>(),
  },
  readOneDeveloper: {
    path: basePath.withId(':id'),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: validatorBuilder.buildResponseValidator<DeveloperWithProjects>(),
  },
  createDeveloper: {
    path: basePath.base(),
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: validatorBuilder.buildPayloadValidator(upsertDeveloperSchema),
    responseValidator: validatorBuilder.buildResponseValidator<Developer>(),
  },
  updateDeveloper: {
    path: basePath.withId(':id'),
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: validatorBuilder.buildPayloadValidator(upsertDeveloperSchema),
    responseValidator: validatorBuilder.buildResponseValidator<Developer>(),
  },
}
