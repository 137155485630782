import React, { useState } from 'react'

import { Applicant, BookingActions, BookingStatus, InvitationStatus } from '@guiker/booking-shared'
import {
  Box,
  Button,
  ConfirmDialog,
  ConfirmDialogProps,
  FeedbackComponent,
  Grid,
  H4,
  makeStyles,
  Modal,
  SecondaryButton,
  SuccessDialog,
  SuccessDialogProps,
  theme,
  Typography,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'

import { isCompleted } from '../../../../../'
import { useBookingContext } from '../../../../../hooks'
import { ApplicantStep } from './ApplicantStep'

type ApplicantViewModalProps = React.PropsWithChildren & {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  disablePortal?: boolean
  applicant: Applicant
}

const useStyles = makeStyles({
  container: {
    padding: 30,
    minWidth: 220,
    maxWidth: 350,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    paddingBottom: 10,
    marginBottom: 26,
  },
  stepsContainer: {
    marginBottom: 20,
  },
  removeButton: {
    color: theme.palette.status.alert.main,
    '&:hover': {
      backgroundColor: theme.palette.status.alert.background,
    },
  },
})

const ApplicantViewModal: React.FC<ApplicantViewModalProps> = ({ applicant, open, setOpen, disablePortal = false }) => {
  const { t } = useTranslation(['main-booking', 'common'])
  const classes = useStyles()
  const { userId, firstName, lastName, steps, invitation } = applicant
  const { bookingId, booking, apiClient, bookingUser, can } = useBookingContext()

  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [reminderSentModalOpen, setReminderSentModalOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const { mutate: sendInvitationReminder, status } = useMutation(
    () => apiClient.sendApplicantInvitationReminder({ pathParams: { bookingId, applicantUserId: userId } }),
    {
      onSuccess: () => {
        setOpen(false)
        setReminderSentModalOpen(true)
      },
      onError: () => setErrorMsg(t('main-booking:errors.inviteApplicantsFailed')),
    },
  )

  const { mutate: removeApplicant } = useMutation(
    (userId: string) =>
      apiClient.disinviteBookingApplicant({
        pathParams: { bookingId, applicantUserId: userId },
        payload: null,
      }),
    {
      onSuccess: () => {
        setRemoveModalOpen(false)
        setOpen(false)
      },
      onError: () => setErrorMsg(t('main-booking:errors.disinviteApplicantFailed')),
    },
  )

  const canDisinvite =
    (bookingUser.type === 'applicant' || can(BookingActions.DisinviteApplicant)) &&
    bookingUser.id !== applicant.userId &&
    booking?.applicants?.length > 1 &&
    !booking.statusHistory.some((sh) => sh.status === BookingStatus.CONFIRM_REQUESTED)

  const handleRemoveClick = () => {
    setOpen(false)
    setRemoveModalOpen(true)
  }

  const handleRemoveConfirm = async () => {
    await removeApplicant(applicant.userId)
  }

  const handleRemoveCancel = () => {
    setRemoveModalOpen(false)
    setOpen(true)
  }

  const invitationPending = invitation.status === InvitationStatus.INVITED

  const enabledSteps = Object.fromEntries(
    Object.entries(steps).filter(([key]) => booking.hasEnabledStep[key]),
  ) as Applicant['steps']

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} disablePortal={disablePortal}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <H4 mb={0}>
              {firstName} {lastName}
            </H4>

            <Typography mb={0}>
              {invitationPending
                ? t('main-booking:actions.invite.invitationPending')
                : t('main-booking:screens.bookingModule.bookingSteps.application.status')}
            </Typography>
          </Box>

          <StepList
            steps={enabledSteps}
            className={classes.stepsContainer}
            dimmed={invitationPending}
            bookingStatus={booking.status}
          />

          <Box mt={4}>
            <Grid container spacing={3} direction='column'>
              <Grid item xs>
                <SecondaryButton onClick={() => setOpen(false)} fullWidth disabled={status === 'loading'}>
                  {t('common:actions.close')}
                </SecondaryButton>
              </Grid>

              {invitationPending && (
                <Grid item xs>
                  <Button onClick={sendInvitationReminder} fullWidth disabled={status === 'loading'}>
                    {t('main-booking:actions.reminder.cta')}
                  </Button>
                </Grid>
              )}

              {canDisinvite && (
                <Grid item xs>
                  <Box className={classes.removeButton} onClick={handleRemoveClick}>
                    <Button color='alert' fullWidth disabled={status === 'loading'}>
                      {t('common:actions.remove')}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        {errorMsg && (
          <FeedbackComponent
            loading={status === 'loading'}
            toasterMessage={errorMsg}
            onToasterClose={() => setErrorMsg(undefined)}
          />
        )}
      </Modal>

      {canDisinvite && (
        <RemoveApplicantModal
          open={removeModalOpen}
          description={t('main-booking:actions.remove.removeApplicantConfirmationMessage')}
          confirmButtonProps={{ onClick: handleRemoveConfirm, children: t('common:actions.yes') }}
          cancelButtonProps={{ onClick: handleRemoveCancel, children: t('common:actions.no') }}
          onClose={handleRemoveCancel}
        />
      )}
      <ReminderSentModal
        open={reminderSentModalOpen}
        text={t('main-booking:actions.reminder.reminderSent')}
        buttonProps={{ onClick: () => setReminderSentModalOpen(false) }}
      />
    </>
  )
}

const StepList: React.FC<{
  steps: Applicant['steps']
  className: string
  dimmed: boolean
  bookingStatus: BookingStatus
}> = ({ steps, className, dimmed, bookingStatus }) => {
  const { t } = useTranslation()

  return (
    <Box className={className}>
      {Object.keys(steps).map((stepName) => {
        return (
          <ApplicantStep
            key={stepName}
            label={t(`main-booking:screens.bookingModule.bookingSteps.${stepName}.label`)}
            completed={isCompleted(bookingStatus, steps[stepName].status, stepName)}
            dimmed={dimmed}
          />
        )
      })}
    </Box>
  )
}

const RemoveApplicantModal = (props: ConfirmDialogProps) => <ConfirmDialog {...props} />

const ReminderSentModal = (props: SuccessDialogProps) => <SuccessDialog {...props} />

export { ApplicantViewModal }
