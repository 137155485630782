import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { PropSharingInquiry } from '../../entity'
import { baseEvent } from '../base'

const basePropSharingInquiryEvent = {
  ...baseEvent,
  entity: 'FRACTIONAL_OWNERSHIP_INQUIRY',
  data: null as unknown as PropSharingInquiry,
}

export enum PropSharingInquiryEventTypes {}

export const PropSharingInquiryEventsDefinition = generateEventsDefinition({
  ...basePropSharingInquiryEvent,
  eventTypes: PropSharingInquiryEventTypes,
})

export const PropSharingInquiryEvents =
  generateEventsFromEnum(basePropSharingInquiryEvent)(PropSharingInquiryEventTypes)
