import React from 'react'

import { TwoColumnsGridLayout } from '@guiker/react-framework'

import { useInvestmentAssumptionContext } from '../../../hooks'
import { AssumptionComponentProps as Props } from '../assumption-props'
import { EditCost } from '../Cost'

export const PurchaseAssumptions: React.FC<Props> = ({ name, readOnly }) => {
  const { investmentAssumptions } = useInvestmentAssumptionContext()
  const costs = investmentAssumptions?.purchase?.costs

  return (
    <TwoColumnsGridLayout>
      {Object.values(costs || {}).map(({ type, ...expenseConfig }) => (
        <EditCost
          key={type}
          name={`${name}.costs`}
          costAssumptionType='purchase'
          defaultValue={costs?.hasOwnProperty(type) ? costs[type] : undefined}
          expenseConfig={{ ...expenseConfig, type } as any}
          readOnly={readOnly}
        />
      ))}
    </TwoColumnsGridLayout>
  )
}
