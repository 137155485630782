import React from 'react'

import { FormSection, Link, PSmall, useTranslation, useWatch } from '@guiker/react-framework'
import { CountryCode, yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { SsnTextField } from '../../'
import { BackgroundCheckConsentFormContent } from './BackgroundCheckConsentFormContent'
import { LegalAddress } from './LegalAddress'

type BackgroundCheckProps = {
  defaultValue: TenantApplication
  schema?: yup.ObjectSchema
  editRoute?: string
  readOnly?: boolean
}

const BackgroundCheck: React.FC<BackgroundCheckProps> = ({ defaultValue, schema, editRoute, readOnly = false }) => {
  const { t } = useTranslation(['main-tenantApplication'])
  const tPrefix = 'main-tenantApplication:screens.steps.backgroundCheck'
  const { backgroundCheck } = { ...defaultValue }

  const legalAddress = backgroundCheck?.legalAddress
  const isSameAsCurrentAddress =
    legalAddress?.isSameAsCurrentAddress === undefined || legalAddress?.isSameAsCurrentAddress

  const country = useWatch({
    name: 'backgroundCheck.legalAddress.address.country',
    defaultValue: isSameAsCurrentAddress ? defaultValue?.currentAddress?.country : legalAddress?.address?.country,
  })

  const shouldShowSSN = country === CountryCode.US
  const ssnInformationUrl =
    'https://help.certn.co/hc/en-us/articles/360051930533-Why-do-you-ask-for-Social-Security-Numbers-'

  return (
    <FormSection
      title={t(`${tPrefix}.title`)}
      subtitle={!readOnly && t(`${tPrefix}.description`)}
      topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
    >
      <LegalAddress defaultValue={defaultValue} schema={schema} readOnly={readOnly} />
      <BackgroundCheckConsentFormContent
        defaultValue={backgroundCheck?.consent}
        formPrefix='backgroundCheck.consent'
        schema={schema}
        readOnly={readOnly}
      />
      {shouldShowSSN && (
        <SsnTextField
          defaultValue={backgroundCheck?.ssn}
          name='backgroundCheck.ssn'
          readOnly={readOnly}
          required={true}
        />
      )}
      {shouldShowSSN && !readOnly && (
        <Link color='textPrimary' isExternalLink to={ssnInformationUrl} target='_blank' underline>
          <PSmall>{t(`${tPrefix}:ssnArticleLink`)}</PSmall>
        </Link>
      )}
    </FormSection>
  )
}

export { BackgroundCheck }
