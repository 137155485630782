import React, { PropsWithChildren } from 'react'

import { webappRoutes as AccountRoutes } from '@guiker/account-shared'
import { ChatIcon, LogOutIcon, UserSwapIcon } from '@guiker/icons'
import { NotificationContextProvider } from '@guiker/notification-context'
import { Features, useFeatureFlag } from '@guiker/react-framework'
import { useQuery } from '@guiker/react-query'
import { Link } from '@guiker/router'
import { AuthenticatedTaskContextProvider } from '@guiker/task-context'

import {
  AuthType,
  useAuthenticationContext,
  useAuthenticationModalContext,
  useConversationApiClient,
  useTranslation,
} from '../../hooks'
import { legacyRoutes, pathBuilder, routes } from '../../routes'
import { FloatingBadge, IconButton, P, SecondaryButton, theme, useMediaQuery } from '..'
import { canSpoof, useSpoofInContext } from '../SpoofInModal'
import { NotificationsMenu } from './NotificationsMenu'
import { TasksMenu } from './TasksMenu'

export type NavigationItem = {
  text?: string
  href?: string
  to?: string
  onClick?: () => void
  icon?: React.ReactNode
  Component?: React.FC
}

type NavItems = {
  bottomMenuItems: NavigationItem[]
  navBarItems: NavigationItem[]
  topMenuItems: NavigationItem[]
  taskMenu?: React.ReactNode
  notificationMenu?: React.ReactNode
}

export const useNavigationItems = (): NavItems => {
  const { user, unsetUser } = useAuthenticationContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('webapp')
  const apiClient = useConversationApiClient()
  const isNewMobileNavigationEnabled = useFeatureFlag().isEnabled(Features.NewMobileTabsNavigation)
  const { originalUser, spoof } = useSpoofInContext()

  const { data: conversationStats } = useQuery('readStats', () => apiClient.readStats(), {
    enabled: !!user,
    staleTime: 1000,
  })

  const Items = {
    Tasks: {
      Component: () => (
        <AuthenticatedTaskContextProvider>
          <TasksMenu />
        </AuthenticatedTaskContextProvider>
      ),
    },
    Notification: {
      Component: () => (
        <NotificationContextProvider>
          <NotificationsMenu />
        </NotificationContextProvider>
      ),
    },
    Admin: {
      text: t('nav.admin'),
      isExternalLink: true,
      to: legacyRoutes.Admin,
    },
    Chat: {
      text: t('nav.myMessages'),
      isExternalLink: false,
      to: pathBuilder.conversation.root.messages.path(),
      ...(!isMobile
        ? {
            Component: (props: NavigationItem & PropsWithChildren) => (
              <Link {...props}>
                <P mb={0}>
                  <FloatingBadge
                    overlap={false}
                    badgeNumber={conversationStats?.unreadCount > 0 ? conversationStats?.unreadCount : null}
                  >
                    {props.children}
                  </FloatingBadge>
                </P>
              </Link>
            ),
          }
        : {
            Component: (props: NavigationItem & PropsWithChildren) => (
              <Link {...props}>
                <IconButton>
                  <FloatingBadge
                    badgeNumber={conversationStats?.unreadCount > 0 ? conversationStats?.unreadCount : null}
                  >
                    <ChatIcon />
                  </FloatingBadge>
                </IconButton>
              </Link>
            ),
          }),
    },
    Login: {
      text: t('nav.login'),
      onClick: () => openAuthenticationModal(AuthType.LOGIN),
    },
    Logout: {
      text: t('nav.logout'),
      icon: <LogOutIcon />,
      onClick: unsetUser,
    },
    MyAccount: {
      text: t('nav.myAccount'),
      to: AccountRoutes.root.profile().path,
    },
    MyHome: {
      text: t('nav.myHome'),
      to: pathBuilder.rentalTenancy.root.home.path,
    },
    MyInvestments: {
      text: t('nav.myInvestments'),
      to: routes.MyInvestments.externalRoutes.root,
    },
    Rent: {
      text: t('nav.rent'),
      isExternalLink: true,
      to: routes.Listing.externalRoutes.root,
    },
    Invest: {
      text: t('nav.invest'),
      to: pathBuilder.investment.root.path,
    },
    Services: {
      text: t('nav.services'),
      to: routes.Services.path,
    },
    SignUp: {
      text: t('nav.signUp'),
      onClick: () => openAuthenticationModal(AuthType.SIGN_UP),
      Component: (props) => <SecondaryButton size='small' {...props} />,
    },
    Spoof: {
      text: 'Spoof',
      icon: <UserSwapIcon />,
      onClick: () => spoof.setIsOpen(true),
    },
  }

  if (user) {
    const baseAuthenticatedItems = {
      navBarItems: [Items.MyInvestments, Items.MyHome] as NavigationItem[],
      topMenuItems: [Items.MyAccount],
      bottomMenuItems: [Items.Logout],
      taskMenu: <Items.Tasks.Component />,
      notificationMenu: <Items.Notification.Component />,
    }

    if (!user.firstName) {
      user.firstName = '-'
    }

    if (user && user.isAdmin) {
      baseAuthenticatedItems.navBarItems.splice(baseAuthenticatedItems.navBarItems.length, 0, Items.Admin)
    }

    if (canSpoof(originalUser || user)) {
      baseAuthenticatedItems.bottomMenuItems.splice(0, 0, Items.Spoof)
    }

    if (!isMobile) {
      baseAuthenticatedItems.navBarItems.splice(2, 0, Items.Chat)
    }

    return isMobile
      ? {
          navBarItems: isNewMobileNavigationEnabled ? [] : [Items.Chat],
          topMenuItems: baseAuthenticatedItems.navBarItems,
          taskMenu: baseAuthenticatedItems.taskMenu,
          notificationMenu: baseAuthenticatedItems.notificationMenu,
          bottomMenuItems: [...baseAuthenticatedItems.topMenuItems, ...baseAuthenticatedItems.bottomMenuItems],
        }
      : {
          ...baseAuthenticatedItems,
          navBarItems: baseAuthenticatedItems.navBarItems,
        }
  } else {
    const baseItems = {
      navBarItems: [Items.Rent, Items.Invest, Items.Services, Items.Login, Items.SignUp],
      topMenuItems: [],
      bottomMenuItems: [],
      taskMenu: <></>,
      notificationMenu: <></>,
    }

    return isMobile
      ? {
          bottomMenuItems: [],
          navBarItems: [],
          taskMenu: <></>,
          notificationMenu: <></>,
          topMenuItems: [...baseItems.navBarItems],
        }
      : {
          bottomMenuItems: [],
          navBarItems: [...baseItems.navBarItems],
          topMenuItems: [],
          taskMenu: <></>,
          notificationMenu: <></>,
        }
  }
}
