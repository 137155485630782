import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'

import { Box } from '../../components'

const useStyle = makeStyles({
  sectionContainer: {
    width: '100%',
    position: 'relative',
    display: 'block',
    clear: 'both',
    marginBottom: theme.spacing(10),
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    padding: theme.spacing(2),
  },
})

type Props = React.PropsWithChildren & {
  justifyContent?: 'center' | 'flex-start' | 'flex-end'
  className?: string
}

const ContentSection: React.FC<Props> = ({ justifyContent = 'center', className, children }) => {
  const classes = useStyle()

  return (
    <Box className={clsx(className, classes.sectionContainer)}>
      <Box justifyContent={justifyContent} className={classes.contentContainer}>
        {children}
      </Box>
    </Box>
  )
}

export { ContentSection }
