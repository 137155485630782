import { routesBuilder } from '@guiker/shared-framework'

import { UserMetadata } from '../../entity'
import { paths } from './paths'

const { buildGet } = routesBuilder<UserMetadata>()({
  basePath: paths.userMetadata.path,
})

export const routes = {
  readOneUserMetadataByUser: buildGet(),
}
