import { BaseRestClient, Headers, Logger, RequestOptions, Response } from '@guiker/base-rest-client'

import { LegacyApiError } from './legacy-api-error'
export { Logger }

export interface LegacyResponseBody<T> {
  meta: { [key: string]: unknown }
  data: T
}

export interface LegacyRequestOptions extends RequestOptions {
  headers?: Headers & {
    'Access-Token'?: string
  }
}

export type LegacyResponse<T> = Response<LegacyResponseBody<T>> | null

function fullUrl(baseUrl: string, url: string): string {
  const sanitizedUrl = url
    .split('/')
    .filter((i) => !!i)
    .join('/')
  return `${baseUrl}/${sanitizedUrl}`
}

type LegacyRestClientArgs = {
  baseUrl: string
  headers?: Headers
  logger?: Logger
  correlatedRequestId?: string
}

export function LegacyRestClient({ baseUrl, logger, correlatedRequestId, headers = {} }: LegacyRestClientArgs) {
  const restClient = new BaseRestClient(logger, correlatedRequestId, headers)

  const handleError = (error: any) => {
    logger && logger.info('Legacy error response', error)
    throw new LegacyApiError(error)
  }

  return {
    get: async <T>(url: string, options?: LegacyRequestOptions): Promise<LegacyResponse<T>> => {
      return restClient.get<LegacyResponseBody<T>>(fullUrl(baseUrl, url), options).catch(handleError)
    },
    post: async <T>(url: string, payload?: unknown, options?: LegacyRequestOptions): Promise<LegacyResponse<T>> => {
      return restClient.post<LegacyResponseBody<T>>(fullUrl(baseUrl, url), payload || {}, options).catch(handleError)
    },
    put: async <T>(url: string, payload?: unknown, options?: LegacyRequestOptions): Promise<LegacyResponse<T>> => {
      return restClient.put<LegacyResponseBody<T>>(fullUrl(baseUrl, url), payload || {}, options).catch(handleError)
    },
    delete: async <T>(url: string, options?: LegacyRequestOptions): Promise<LegacyResponse<T>> => {
      return restClient.delete<LegacyResponseBody<T>>(fullUrl(baseUrl, url), options).catch(handleError)
    },
  }
}
