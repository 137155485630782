import { TypeOf, yup } from '@guiker/yup-util'

import { signature } from '../../schemas'

export const invitedLessorSignature = yup.object({
  // For Step I (Section 17) :: Signatures
  signature: signature,
})

export type InvitedLessorSignature = TypeOf<typeof invitedLessorSignature>
