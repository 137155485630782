import { PhoneNumberSchema } from '@guiker/phone-number'
import { yup } from '@guiker/yup-util'

import { ListingInquirySource, ListingInquiryStatus } from '../../../entity'
import { visitSchedulesSchema } from '../../schemas'

export const createInquirySchema = yup.object({
  listingId: yup.string().required(),
  rentalOptionId: yup.string().required(),
  user: yup
    .object({
      id: yup.string().required(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      emailAddress: yup.string().required(),
      phone: PhoneNumberSchema(false),
    })
    .required(),
  moveInDate: yup.string(),
  isBookingNotificationEnabled: yup.boolean().nullable(),
  message: yup.string().nullable(),
  visitSchedules: visitSchedulesSchema,
  status: yup.mixed<ListingInquiryStatus>().oneOf(Object.values(ListingInquiryStatus)).required(),
  source: yup.mixed<ListingInquirySource>().oneOf(Object.values(ListingInquirySource)).required(),
})

export type CreateInquirySchema = yup.InferType<typeof createInquirySchema>
