import { ApplicationStatus } from '@guiker/booking-shared'

const getTitleStatusColor = (status: ApplicationStatus) => {
  switch (status) {
    case ApplicationStatus.ACCEPTED:
      return 'success'
    case ApplicationStatus.REJECTED:
    case ApplicationStatus.CHANGE_REQUESTED:
      return 'alert'
    default:
      return 'white'
  }
}

export { getTitleStatusColor }
