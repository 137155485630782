import React from 'react'

import { Flex, makeStyles, P, PBig } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'

import { Link } from '..'
import { FooterLinkGroup } from './footer-link-group'

const useStyles = makeStyles(
  {
    link: {
      '&:hover': {
        opacity: 1,
      },
      opacity: 0.6,
    },
  },
  {
    name: 'FooterLinkGroup',
  },
)

interface Props extends FooterLinkGroup {}

const FooterLinksGroup: React.FC<Props> = ({ title, links }) => {
  const classes = useStyles()
  const { t } = useTranslation(['footer', 'common'])

  return (
    <Flex flexDirection='column' gap={3}>
      <PBig color='primary' mb={0}>
        {t(title)}
      </PBig>

      {links.map((link, index) => (
        <Link
          key={index}
          to={link.to}
          target={link.isExternalLink ? '_blank' : undefined}
          isExternalLink={link.isExternalLink}
          underline={false}
          hoverUnderline={false}
          color='white'
          className={classes.link}
        >
          <P color='white' mb={0}>
            {t(link.name)}
          </P>
        </Link>
      ))}
    </Flex>
  )
}

export { FooterLinksGroup }
