import React, { useMemo } from 'react'

import { AuthBulkAssetUploaderContextProvider, S3AccessControlList } from '@guiker/asset-uploader'
import { StepProps } from '@guiker/components-library'

import { OnSubmit } from '../IdentityVerification'
import { useIdentityVerificationContext } from '../IdentityVerificationContext'
import { IdentityVerificationDocumentsForm } from '../IdentityVerificationDocuments/IdentityVerificationDocumentsForm'

type SummaryStepProps = StepProps & { scope: { type: string; id: string }; onSubmit?: OnSubmit }

export const SummaryStep: React.FC<StepProps & SummaryStepProps> = (props) => {
  const { governmentIdFront, governmentIdBack, facePicture } = useIdentityVerificationContext()

  const assetGroups = useMemo(
    () => [
      {
        type: 'governmentIdFront',
        assets: [governmentIdFront?.image],
      },
      {
        type: 'governmentIdBack',
        assets: [governmentIdBack?.image],
      },
      {
        type: 'facePicture',
        assets: [facePicture?.image],
      },
    ],
    [governmentIdFront, governmentIdBack, facePicture],
  )

  return (
    <AuthBulkAssetUploaderContextProvider
      acl={S3AccessControlList.PRIVATE}
      scope={props.scope}
      assetGroups={assetGroups}
    >
      <IdentityVerificationDocumentsForm {...props} />
    </AuthBulkAssetUploaderContextProvider>
  )
}
