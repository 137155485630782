import { Property } from '@guiker/real-estate-shared'

import { ListingAddress } from './address'
import { Deal } from './deal'
import { Document } from './document'
import { Picture } from './picture'
import { Price } from './price'
import { Source } from './source'

export enum CreationStatus {
  DRAFTED = 'DRAFTED',
  COMPLETED = 'COMPLETED',
}

export type BaseListing = {
  id: string
  property: Property
  description: string
  pictures?: Picture[]
  documents: Document[]
  address?: ListingAddress
  onSaleSince: string
  price: Price
  source?: Source
  deal: Deal
  status: {
    creationStatus: CreationStatus
    isPublished: boolean
  }
  createdAt: string
  updatedAt: string
}
