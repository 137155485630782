import React, { useState } from 'react'

import { DataAndMeta, Paginate } from '@guiker/api-definition'
import { useAdminPropSharingContext } from '@guiker/propsharing-context'
import { PropSharingListing } from '@guiker/propsharing-shared'
import { PaginatedTable } from '@guiker/react-query-components'

import { ToggleStatusArgs, useGetListingColumns } from './get-listing-columns'

type PropSharingTableProps = {
  queryKey: string
  fetcher: (paginate: Paginate) => Promise<DataAndMeta<PropSharingListing[]>>
  onRowClick: (data: PropSharingListing) => void
  search?: { label?: string }
}

export const PropSharingTable: React.FC<PropSharingTableProps> = ({ onRowClick, search, queryKey, fetcher }) => {
  const { apiClient } = useAdminPropSharingContext()
  const [refetchTrigger, setRefetchTrigger] = useState<string>(undefined)

  const toggleStatus = async ({ id, status }: ToggleStatusArgs) => {
    const { isPublished, creationStatus } = status

    await apiClient
      .updateListingStatus({
        pathParams: { id },
        payload: { isPublished: !isPublished, creationStatus },
      })
      .finally(() => setRefetchTrigger(`${id}:${!isPublished}`))
  }

  return (
    <PaginatedTable
      queryKey={queryKey}
      fetcher={fetcher}
      columns={useGetListingColumns(toggleStatus)}
      refetchTrigger={refetchTrigger}
      onRowClick={(data) => {
        onRowClick(data)
      }}
      search={search}
    />
  )
}
