import React, { useEffect, useState } from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  getCountryCallingCode,
  getPhoneableCountryCodes,
  parsePhoneNumber,
  Phone,
  PhoneableCountryCode,
} from '@guiker/phone-number'
import {
  Dropdown,
  FieldPath,
  Flex,
  Inline,
  makeStyles,
  P,
  TextField,
  TextFieldProps,
  toPx,
  useFormContext,
  useFormInput,
} from '@guiker/react-framework'
import { useStaticAssetContext } from '@guiker/static-asset-context'

type PhoneNumberProps<T extends object = any, P extends FieldPath<T> = any> = TextFieldProps<T, P> & {
  defaultCountry?: PhoneableCountryCode
  disableDropdown?: boolean
  disableFlags?: boolean
  defaultValue?: Phone
  label?: React.ReactNode
  readOnly?: boolean
  required?: boolean
  countryName?: string
  numberName?: string
  maxWidth?: string | number
  priorityList?: PhoneableCountryCode[]
}

const useStyles = makeStyles(
  (theme) => ({
    flags: {
      width: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    label: {
      fontSize: 16,
      lineHeight: toPx(20),
      ...theme.typography.variants.bodyBold,
      color: theme.palette.text.primary.main,
    },
  }),
  { name: 'PhoneNumber' },
)

const defaultPriorityList = ['CA', 'US'] as PhoneableCountryCode[]

const PhoneNumber = <T extends object = any, P extends FieldPath<T> = any>({
  defaultCountry,
  label,
  defaultValue,
  disableDropdown = false,
  disableFlags = false,
  readOnly = false,
  name,
  required,
  countryName,
  numberName,
  maxWidth,
  priorityList = defaultPriorityList,
}: PhoneNumberProps<T, P>) => {
  const classes = useStyles({ label })
  const { t } = useTranslation()
  const { getAsset } = useStaticAssetContext()
  const { setValue } = useFormContext()
  const { error } = useFormInput<T, P>({ name, t })
  const [country, setCountry] = useState<PhoneableCountryCode>(defaultValue?.country || defaultCountry || 'CA')
  const [phoneNumber, setPhoneNumber] = useState<string>(() => {
    try {
      const parsed = parsePhoneNumber(defaultValue?.number, country)
      return parsed?.isValid() ? parsed.formatInternational() : undefined
    } catch {
      return undefined
    }
  })

  const priorityListSortCountries = (countries: PhoneableCountryCode[]): PhoneableCountryCode[] => {
    priorityList.forEach((country) => {
      const index = countries.indexOf(country)
      countries.splice(index, 1)
      countries.splice(0, 0, country)
    })
    return countries
  }

  const initializeCountries = () => {
    const countries = priorityListSortCountries(getPhoneableCountryCodes())
    let flagAssets
    if (!disableFlags) {
      flagAssets = getAsset('flags')
    }
    return countries.map((country) => ({
      country,
      flag: disableFlags ? '' : flagAssets(country),
      code: `${country} (+${getCountryCallingCode(country)})`,
    }))
  }

  const [countries] = useState(initializeCountries)

  useEffect(() => {
    try {
      const parsed = parsePhoneNumber(phoneNumber, country)
      if (parsed?.isValid()) {
        const formatted = parsed.formatInternational()
        setPhoneNumber(formatted)
        setValue(numberName || `${name}.number`, formatted)
      }
    } catch {
      setPhoneNumber(phoneNumber)
    }
  }, [phoneNumber])

  useEffect(() => {
    setPhoneNumber(undefined)
  }, [country])

  return (
    <Flex flexDirection='column' gap={1}>
      <TextField
        customAdornment={
          <Dropdown
            error={error}
            mb={0}
            maxWidth={80}
            readOnly={readOnly}
            disabled={disableDropdown}
            name={countryName || `${name}.country`}
            defaultValue={country}
            value={country}
            onChange={(event) => setCountry(event.target.value as PhoneableCountryCode)}
            options={countries.map((key) => ({
              value: key.country,
              label: (
                <Flex alignItems='center' justifyContent='center'>
                  {!disableFlags && <img className={classes.flags} src={key.flag} alt={key.country} />}
                  {country !== key.country && <P mb={0}>{key.code}</P>}
                  {disableFlags && country === key.country && (
                    <Inline mb={0} mr={0.5}>
                      {key.country}
                    </Inline>
                  )}
                </Flex>
              ),
            }))}
          />
        }
        label={label}
        defaultValue={phoneNumber}
        name={numberName || `${name}.number`}
        width='100%'
        maxWidth={maxWidth}
        readOnly={readOnly}
        required={required}
        onChange={(event) => setPhoneNumber(event.target.value)}
        value={phoneNumber}
        error={error}
      />
    </Flex>
  )
}
export { PhoneNumber }
