import React from 'react'

import { OverallScore } from '@guiker/background-check-shared'
import { Chip, ChipProps } from '@guiker/components-library'

import { i18n, I18nextProvider, useTranslation } from '../../i18n'

interface ReportChipProps {
  score: number
  creditStatus: OverallScore
}

const Content: React.FC<ReportChipProps> = ({ score, creditStatus }) => {
  const { t } = useTranslation(['common-backgroundCheck'])

  const getColor = (): ChipProps['color'] => {
    switch (creditStatus) {
      case 'PASS':
        return 'success'
      case 'WARN':
        return 'warning'
      case 'FAIL':
        return 'alert'
    }
  }

  const statusLabel = t(`overall.overallScore.${creditStatus.toLowerCase()}`)
  const label = score ? `${statusLabel} | ${score} credit score` : statusLabel

  return <Chip color={getColor()}>{label}</Chip>
}

const ReportChip: React.FC<ReportChipProps> = (props) => (
  <I18nextProvider i18n={i18n}>
    <Content {...props} />
  </I18nextProvider>
)

export { ReportChip }
