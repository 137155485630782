import { TypeOf, yup } from '@guiker/yup-util'

import { LeaseRoleNames } from '../../../entity'
import { initials, signature } from '../../schemas'

export const signatureAndLiability = yup.object({
  signature: signature.when('draftedAs', {
    is: LeaseRoleNames.DRAFTER,
    then: signature.nullable().transform(() => null),
    otherwise: signature.required(),
  }),
  initials: initials.when('draftedAs', {
    is: LeaseRoleNames.DRAFTER,
    then: initials.nullable().transform(() => null),
    otherwise: initials.required(),
  }),
  liability: yup.object({
    lesseesAreSolidarilyLiable: yup.boolean().required(),
  }),
  draftedAs: yup
    .mixed<LeaseRoleNames.DRAFTER | LeaseRoleNames.LESSOR>()
    .oneOf([LeaseRoleNames.DRAFTER, LeaseRoleNames.LESSOR])
    .required(),
})

export type SignatureAndLiability = TypeOf<typeof signatureAndLiability>
