import React from 'react'

import { FinancialBreakdown, KpiGrid } from '@guiker/base-listing-components'
import { BaseListing, InvestmentAssumptions } from '@guiker/base-listing-shared'
import { PieChart } from '@guiker/react-chart'
import {
  isAtMostTablette,
  makeStyles,
  Note,
  PageSection3,
  RentalProcessIcon,
  toPx,
  TwoColumnsGridLayout,
  useLayoutContext,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useInvestmentAssumptionContext } from '../../../context'
import { useT } from '../../../i18n'
import { Unit, UnitBreakdown } from './UnitBreakdown'
import { useRentalDetails } from './utils'

const useStyles = makeStyles(() => ({
  occupancyTable: {
    '& > table': {
      [isAtMostTablette]: {
        width: '100%',
        maxWidth: 600,
      },
    },
  },
  pieChart: {
    height: 260,
    width: '100%',
    fontSize: toPx(14),
    marginBottom: 200,
  },
}))

type Props = {
  property: BaseListing['property']
  assumptions: InvestmentAssumptions.Assumptions
  handleNote: ({ title, content }: { title: string; content: string }) => void
}

export const RentalDetails: React.FC<Props> = ({ assumptions, property, handleNote }) => {
  const classes = useStyles()
  const { tShared } = useT({ entity: 'rentalDetails' })
  const { investmentResults } = useInvestmentAssumptionContext()
  const yearlyCashflows = investmentResults.yearlyCashflows[0]
  const { isAtMostTablette } = useLayoutContext()

  const { financialItems, kpiItems, totalExpenses, pieChartItems, currency } = useRentalDetails({
    yearlyCashflows,
    currency: assumptions.purchase.price.currency,
    revenue: assumptions.revenue,
    property,
  })

  const occupancies = assumptions.revenue.occupancies?.reduce((acc, occupancy, index) => {
    return { ...acc, [index]: occupancy }
  }, {} as { [key in string]: InvestmentAssumptions.Occupancy })

  const units: Unit[] =
    occupancies &&
    property?.units?.map((unit, index) => ({
      ...unit,
      ...occupancies[index],
    }))

  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)

  return (
    <>
      <Note
        type='collapsible'
        title={tShared('title')}
        icon={<RentalProcessIcon color='textPrimary' />}
        statusColor='alabaster'
        isCollapsed={true}
        onMobileOpen={() =>
          handleNote({
            title: tShared('title'),
            content: tShared('description'),
          })
        }
      >
        {tShared('description')}
      </Note>
      <KpiGrid items={kpiItems} />
      <UnitBreakdown units={units} currency={currency} />
      <TwoColumnsGridLayout gap={isAtMostTablette ? 4 : 8}>
        <PageSection3 title={tShared('cashFlow.title')}>
          <FinancialBreakdown
            currency={money.currency[currency]}
            items={financialItems}
            total={{
              label: tShared('cashFlow.netCashflow'),
              value: yearlyCashflows.netCashFlow,
            }}
          />
        </PageSection3>
        <PageSection3 title={tShared('cashFlow.expensesBreakdown')}>
          <div className={classes.pieChart}>
            <PieChart
              dataset={pieChartItems}
              labelFormatter={moneyFormatter}
              total={totalExpenses.monetized}
              showLegend
              highlight={{
                label: tShared('cashFlow.total'),
                value: totalExpenses.monetized,
                labelFormatter: moneyFormatter,
              }}
            />
          </div>
        </PageSection3>
      </TwoColumnsGridLayout>
    </>
  )
}
