import { Accept, DropzoneRef, useDropzone as useReactDropzone } from 'react-dropzone'

import { UseDropzoneOptions } from './use-dropzone'
import { FileMimeTypeMap } from './utils'

export const useFileSelector = (options?: UseDropzoneOptions): DropzoneRef => {
  const accept = (Array.isArray(options?.accept) ? options.accept : [options.accept]).reduce((res, fileType) => {
    res[fileType] = FileMimeTypeMap[fileType] ?? []
    return res
  }, {} as Accept)

  const { open } = useReactDropzone({
    ...options,
    maxSize: options.maxSize * 1024 * 1024,
    accept,
  })

  return { open }
}
