import React, { useState } from 'react'

import { Box, InputAdornment, makeStyles } from '@guiker/components-library'
import { EyeIcon } from '@guiker/icons'
import { CLTextField as BaseTextField, FieldPath, PathValue, TextField, TextFieldProps } from '@guiker/react-framework'

export type MaskedTextFieldProps<T extends object = any, P extends FieldPath<T> = any> = TextFieldProps<T, P> & {
  onChange?: (text: PathValue<T, P>) => void
  formatHiddenText?: (text: PathValue<T, P>) => PathValue<T, P>
  formatVisibleText?: (text: PathValue<T, P>) => PathValue<T, P>
  revealText?: () => any
}

const useStyles = makeStyles({
  adornment: {
    cursor: 'pointer',
  },
})

const MaskedTextField = <T extends object = any, P extends FieldPath<T> = any>({
  onChange,
  formatHiddenText = (text: PathValue<T, P>) => text,
  formatVisibleText = (text: PathValue<T, P>) => text,
  revealText,
  defaultValue,
  name,
  ...textFieldProps
}: MaskedTextFieldProps<T, P>) => {
  const classes = useStyles()
  const [realText, setRealText] = useState<PathValue<T, P>>((defaultValue || '') as PathValue<T, P>)
  const [isRevealed, setIsRevealed] = useState(defaultValue ? false : true)
  const [displayText, setDisplayText] = useState<PathValue<T, P>>(
    (isRevealed ? formatVisibleText(realText) : formatHiddenText(realText) || '') as PathValue<T, P>,
  )

  const handleRevealText = async () => {
    if (!isRevealed) {
      const revealedText = revealText ? await revealText() : undefined
      const displayableText = revealedText || realText

      const shouldRevealText = !isRevealed
      setIsRevealed(shouldRevealText)
      setDisplayText(shouldRevealText ? formatVisibleText(displayableText) : formatHiddenText(displayableText))
    }
  }

  const handleChange = (text: PathValue<T, P>) => {
    setRealText(text)
    setDisplayText(isRevealed ? formatVisibleText(text) : formatHiddenText(text))
    onChange && onChange(text)
  }

  return (
    <>
      <BaseTextField
        {...textFieldProps}
        defaultValue={displayText}
        value={displayText}
        onChange={(event) => handleChange(event.target.value as PathValue<T, P>)}
        endAdornment={
          !isRevealed && (
            <InputAdornment className={classes.adornment} onClick={handleRevealText} position='end'>
              <EyeIcon />
            </InputAdornment>
          )
        }
      />
      <Box display={'none'}>
        <TextField name={name} defaultValue={realText} value={realText} />
      </Box>
    </>
  )
}

export { MaskedTextField }
