import React from 'react'

import { Divider, Flex, makeStyles, PBold, theme } from '@guiker/react-framework'
import { CurrencyISO } from '@guiker/shared-framework'

import { RentalInfo, UnitConfiguration } from './BaseComponents'
import { Unit } from './type'

const useStyles = makeStyles(() => ({
  negativeMargin: {
    marginTop: ({ isOccupied, hasRent }: { isOccupied: boolean; hasRent: boolean }) =>
      !isOccupied && hasRent ? theme.spacing(-2) : undefined,
  },
}))

type Prop = {
  unit: Unit
  currency: CurrencyISO
  index: number
}

type MobileUnitBreakdownProps = {
  units: Unit[]
  currency: CurrencyISO
}

const UnitBreakdown: React.FC<Prop> = ({ unit, currency, index }) => {
  const classes = useStyles({ isOccupied: !!unit.isOccupied, hasRent: !!unit.monthlyRent })
  return (
    <Flex width='100%' flexDirection='column'>
      {index !== 0 && <Divider my={2} />}
      <Flex justifyContent='space-between'>
        <PBold mb={0}>{unit.unit}</PBold>
        {!!unit.monthlyRent && <RentalInfo unit={unit} currency={currency} />}
      </Flex>
      <UnitConfiguration unit={unit} className={classes.negativeMargin} />
    </Flex>
  )
}

export const MobileUnitBreakdown: React.FC<MobileUnitBreakdownProps> = ({ units, currency }) => {
  return (
    <Flex width='100%' flexDirection='column'>
      {units.map((unit, index) => (
        <UnitBreakdown unit={unit} index={index} currency={currency} />
      ))}
    </Flex>
  )
}
