import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'
import { Invoice, PayInMethodType } from '../entity'

export const Queue = {
  CreateRentPaymentInvoice: buildQueueDefinition({
    name: 'CreateRentPaymentInvoice',
    serviceName: config.apiName,
    payload: null as Partial<Invoice & { payInMethod: { id: string; type: PayInMethodType } }>[],
    batchSize: 1,
  }),
  BulkChargeInvoice: buildQueueDefinition({
    name: 'BulkChargeInvoice',
    serviceName: config.apiName,
    payload: null as Partial<Invoice>[],
    batchSize: 1,
  }),
} as const
