import React, { useState } from 'react'

import { getUserFullName } from '@guiker/base-entity'
import {
  Applicant,
  Booking,
  BookingStatus,
  getApplicantFirstName,
  getApplicantFullName,
  getApplicantLastName,
} from '@guiker/booking-shared'
import { Collapse, Divider, Flex, Link, P, PSmall, RotatingIcon, RouterLink, TextButton } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { ParticipantAvatar } from '../BookingPanel/components/Participants/ParticipantAvatar'

type ApplicantListProps = {
  booking: Booking
}

type ApplicantListItemProps = {
  applicant: Applicant
  booking: Booking
}

const ApplicantListItem: React.FC<ApplicantListItemProps> = ({ applicant, booking }) => {
  const { tBase } = useT()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Flex flexDirection='column'>
      <Flex gap={2}>
        <Flex gap={2} alignItems='center' flexGrow={1} alignSelf='flex-start'>
          <ParticipantAvatar
            size='medium'
            firstName={getApplicantFirstName(applicant) || applicant.firstName}
            lastName={getApplicantLastName(applicant) || applicant.lastName}
            avatarUrl={applicant.avatarUrl}
          />
          <Link to={`/users/${applicant.userId}`} prefixWithLocale={false}>
            <Flex flexDirection='column' gap={1} flexGrow={1}>
              <P mb={0}>{getApplicantFullName(applicant) || getUserFullName(applicant)}</P>
              <PSmall mb={0} color={60}>
                {applicant.emailAddress}
              </PSmall>
            </Flex>
          </Link>
        </Flex>
        <Flex gap={2} alignItems='center' alignSelf='flex-start'>
          {booking.hasEnabledStep.payment && booking.status === BookingStatus.BOOKED && (
            <RouterLink to={`./tenants/${applicant.userId}`} prefixWithLocale={false}>
              <TextButton>{tBase('actions.view')}</TextButton>
            </RouterLink>
          )}
          <RotatingIcon isRotated={isOpen} onClick={() => setIsOpen((curr) => !curr)} />
        </Flex>
      </Flex>
      <Collapse in={isOpen}></Collapse>
    </Flex>
  )
}

export const ApplicantList: React.FC<ApplicantListProps> = ({ booking }) => {
  return (
    <Flex flexDirection='column' gap={1}>
      {booking.applicants.map((applicant, index) => (
        <>
          <ApplicantListItem applicant={applicant} booking={booking} />
          {index < booking.applicants.length - 1 && <Divider />}
        </>
      ))}
    </Flex>
  )
}
