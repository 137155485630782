import React from 'react'

import { makeStyles } from '@guiker/components-core'
import { Flex, Link } from '@guiker/components-library'
import { FacebookIcon, InstagramIcon, LinkedInIcon, TikTokIcon, WeChatIcon } from '@guiker/icons'

const useStyles = makeStyles(
  () => ({
    socialIcon: {
      cursor: 'pointer',
      '&:hover': {
        opacity: 1,
      },
      opacity: 0.5,
    },
  }),
  { name: 'SocialGroup' },
)

const socialIcons = [
  {
    name: 'facebook',
    Icon: FacebookIcon,
    link: 'https://www.facebook.com/guikerhomes/',
  },
  {
    name: 'we-chat',
    Icon: WeChatIcon,
    openModal: (open: () => void) => open(),
  },
  {
    name: 'linked-in',
    Icon: LinkedInIcon,
    link: 'https://www.linkedin.com/company/guiker/',
  },
  {
    name: 'instagram',
    Icon: InstagramIcon,
    link: 'https://www.instagram.com/guikerhomes/',
  },
  {
    name: 'tiktok',
    Icon: TikTokIcon,
    link: 'https://www.tiktok.com/@guikerhomes/',
  },
]

type SocialGroupProp = {
  openWeChat: (boolean) => void
}

const SocialGroup: React.FC<SocialGroupProp> = ({ openWeChat }) => {
  const classes = useStyles()

  return (
    <Flex flexDirection='row' gap={2}>
      {socialIcons.map((socialIcon) => {
        const Icon = socialIcon.Icon
        return socialIcon.link ? (
          <Link
            target='_blank'
            to={socialIcon.link}
            isExternalLink
            rel='noreferrer'
            aria-label={socialIcon.name}
            key={socialIcon.name}
            adornment={<Icon color='white' className={classes.socialIcon} />}
          />
        ) : (
          <Link
            onClick={() => socialIcon.openModal(() => openWeChat(true))}
            aria-label={socialIcon.name}
            key={socialIcon.name}
            adornment={<Icon color='white' className={classes.socialIcon} />}
          />
        )
      })}
    </Flex>
  )
}

export { SocialGroup }
