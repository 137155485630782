import React, { useEffect } from 'react'

type EmptyStateProps = {
  onLoad: () => void
}

const EmptyState: React.FC<EmptyStateProps> = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
  }, [])
  return <></>
}

export { EmptyState }
