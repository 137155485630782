import { generateEventsDefinition } from '@guiker/event'

import { Notification } from '../../entity'

export const EventsDefinition = generateEventsDefinition({
  context: 'NOTIFICATION',
  entity: 'NOTIFICATION',
  eventTypes: {
    SENT: 'SENT',
  } as const,
  data: null as unknown as Notification,
})

export const Events = EventsDefinition.events
export const EventTypes = EventsDefinition.eventTypes
