import React, { useState } from 'react'

import { isAtMostTablette, makeStyles, padding, theme } from '@guiker/components-core'
import { Trans, useTranslation } from '@guiker/i18n'
import { CheckmarkIcon } from '@guiker/icons'
import { legacyRoutes } from '@guiker/legacy-routes'

import { Button, Flex, H1, H4, P, PBig, SecondaryButton } from '../../../components'
import { useServicesScreenContext } from '../context'
import { agentPackages, landlordPackages, PackageTier, PackageType } from './PackageEntity'
import { ServiceEnquiryModal } from './ServiceEnquiryModal'

const useStyle = makeStyles(
  {
    benefitText: {
      maxWidth: 255,
    },
    text: {
      whiteSpace: 'pre',
    },
    package: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 300,
      padding: padding(6, 5),
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      borderTop: ({ tier }: { tier: string }) =>
        `6px solid ${tier === 'standard' ? theme.palette.primary.main : 'white'}`,
      [isAtMostTablette]: {
        padding: theme.spacing(3),
      },
    },
    overview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      marginBottom: theme.spacing(5),
      maxHeight: 140,
      maxWidth: 280,
    },
    benefits: {
      alignItems: 'left',
      marginBottom: theme.spacing(3),
    },
    icon: {
      minWidth: 14,
      width: 14,
      height: 14,
    },
  },
  {
    name: 'Package',
  },
)

const Benefit: React.FC<React.PropsWithChildren & { enabled: boolean }> = ({ enabled, children }) => {
  const classes = useStyle()

  return (
    <Flex alignItems='center' mb={2} gap={1}>
      {enabled ? <CheckmarkIcon className={classes.icon} color='primary' /> : undefined}
      <P color={enabled ? 'primary' : 30} mb={0} className={classes.benefitText}>
        {children}
      </P>
    </Flex>
  )
}

type PackageProps = {
  tier: PackageTier
  type: PackageType
}

const Package: React.FC<PackageProps> = ({ tier, type }) => {
  const { t } = useTranslation(['webapp'])
  const classes = useStyle({ tier, type })
  const [isServiceEnquiryFormOpen, setIsServiceEnquiryFormOpen] = useState(false)
  const { setPackageTier } = useServicesScreenContext()

  const packageBenefits = type === PackageType.LANDLORD ? landlordPackages : agentPackages
  const PackageButton = tier === 'basic' ? SecondaryButton : Button
  const tPrefix = `services.packages.${type}.${tier}`

  const handleOnClick = () => {
    setPackageTier(tier)
    if (tier !== 'basic') setIsServiceEnquiryFormOpen(true)
    else window.top.location.href = legacyRoutes.MyListings
  }

  return (
    <>
      <div className={classes.package}>
        <PBig textAlign='center'>{t(`${tPrefix}.name`)}</PBig>
        <div className={classes.overview}>
          <Trans i18nKey={`webapp:${tPrefix}.price`}>
            <P className={classes.text} />
            {type === PackageType.AGENT && tier === 'full' ? <H4 textAlign='center'></H4> : <H1 mb={1} />}
            <P color={30} className={classes.text} />
          </Trans>
        </div>
        <div className={classes.benefits}>
          {Object.keys(packageBenefits).map((benefit) => (
            <Benefit key={`${type}-${benefit}`} enabled={packageBenefits[benefit][tier]}>
              {t(`services.packages.${type}.benefits.${benefit}`)}
            </Benefit>
          ))}
        </div>
        <PackageButton onClick={handleOnClick}>{t(`${tPrefix}.cta`)}</PackageButton>
      </div>
      <ServiceEnquiryModal open={isServiceEnquiryFormOpen} onClose={() => setIsServiceEnquiryFormOpen(false)} />
    </>
  )
}

export { Package }
