import { Errors, StatusCodes } from '@guiker/common'

export class BookingIsNotReadyToBeConfirmedError extends Errors.BaseError {
  constructor(bookingId: string, readiness: object) {
    super(
      `Booking ${bookingId} is not ready to be confirmed due to ${JSON.stringify(readiness)}`,
      BookingIsNotReadyToBeConfirmedError.name,
      StatusCodes.UNPROCESSABLE_ENTITY,
    )
  }
}
