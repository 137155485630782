import React from 'react'

import { Flex, H4, Modal, P } from '@guiker/components-library'

type SuccessCardProps = {
  header: string
  description: string
  ctaButton: React.ReactNode
  open: boolean
  onClose: () => void
}

export const InquirySuccessCard: React.FC<SuccessCardProps> = ({ header, description, ctaButton, onClose, open }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Flex py={2} px={4} flexDirection='column' alignItems='center'>
        <H4 textAlign='center' mb={2}>
          {header}
        </H4>
        <P textAlign='center' whiteSpace='pre-line'>
          {description}
        </P>
        <>{ctaButton}</>
      </Flex>
    </Modal>
  )
}
