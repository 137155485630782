import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'
import { buildPaginationMeta } from '@guiker/paginate'
import { RecommendedListing } from '@guiker/recommended-listing-entity'

import { paths } from './paths'
import { byCity, byCityAndNeighbourhood, byListingId } from './schemas'

export const routes = {
  readAllRecommendedListingPerCity: {
    path: paths.recommendedListing.all.withScope('cities', ':cityId'),
    method: HttpMethod.GET,
    authenticated: false,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: RecommendedListing[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    pathParamsValidator: (payload: unknown) => byCity.validate(payload),
    queryParamsValidator: validatorBuilder.buildPaginationQueryParamValidator(),
  },
  readAllRecommendedListingPerNeighbourhood: {
    path: paths.recommendedListing.all.withScopes([
      { type: 'cities', id: ':cityId' },
      { type: 'neighbourhoods', id: ':neighbourhoodSlug' },
    ]),
    method: HttpMethod.GET,
    authenticated: false,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: RecommendedListing[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    pathParamsValidator: (payload: unknown) => byCityAndNeighbourhood.validate(payload),
    queryParamsValidator: validatorBuilder.buildPaginationQueryParamValidator(),
  },
  readAllRecommendedListingsByListingId: {
    path: paths.recommendedListing.all.withScope('listings', ':listingId'),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: RecommendedListing[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    pathParamsValidator: (payload: unknown) => byListingId.validate(payload),
    queryParamsValidator: validatorBuilder.buildPaginationQueryParamValidator(),
  },
}
