import React from 'react'

import { FormSectionGroup } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { BackgroundCheck } from '../BackgroundCheck'
import { Guarantor } from './Guarantor'

type GuarantorAndBackgroundCheckFormContentProps = {
  tenantApplication?: TenantApplication
  schema?: yup.ObjectSchema
  readOnly?: boolean
}

const GuarantorAndBackgroundCheckFormContent: React.FC<GuarantorAndBackgroundCheckFormContentProps> = ({
  tenantApplication,
  schema,
  readOnly,
}) => (
  <FormSectionGroup>
    <Guarantor defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
    <BackgroundCheck defaultValue={tenantApplication} schema={schema} readOnly={readOnly} />
  </FormSectionGroup>
)

export { GuarantorAndBackgroundCheckFormContent }
