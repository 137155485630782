import React from 'react'

import { Flex, makeStyles, PSmall, useTranslation } from '@guiker/react-framework'

import { useTimer } from './TimerContextProvider'

const useStyles = makeStyles({
  timerTitle: {
    width: '100%',
    fontWeight: 400,
  },
  timerUnit: {
    textAlign: 'center',
  },
})

const tPrefix = 'screens.bookingModule.time'

const TimerCountdown: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const classes = useStyles()
  const { timerState: state } = useTimer()

  const renderTimeUnit = (value: number) => (value < 10 ? `0${value}` : value)

  const timerColor = state.status === 'aboutToExpire' ? 'alert' : 'textPrimary'

  return (
    <Flex flexDirection='column'>
      <Flex gap={2}>
        <div className={classes.timerUnit}>
          <PSmall color={timerColor}>{renderTimeUnit(state.hours)}</PSmall>
          <PSmall color={timerColor}>{t(`${tPrefix}.display.hours`)}</PSmall>
        </div>
        <PSmall color={timerColor}>:</PSmall>
        <div className={classes.timerUnit}>
          <PSmall color={timerColor}>{renderTimeUnit(state.minutes)}</PSmall>
          <PSmall color={timerColor}>{t(`${tPrefix}.display.minutes`)}</PSmall>
        </div>
        <PSmall color={timerColor}>:</PSmall>
        <div className={classes.timerUnit}>
          <PSmall color={timerColor}>{renderTimeUnit(state.seconds)}</PSmall>
          <PSmall color={timerColor}>{t(`${tPrefix}.display.seconds`)}</PSmall>
        </div>
      </Flex>
    </Flex>
  )
}

export { TimerCountdown }
