import React from 'react'

import { Flex, Form, H4 } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { PayInMethodSelector } from '../../PayInMethodSelector'

type InvoiceDetailPaymentMethodProps = {}

export const InvoiceDetailPaymentMethod: React.FC<InvoiceDetailPaymentMethodProps> = () => {
  const { tShared } = useT({})

  return (
    <Flex flexDirection='column'>
      <H4>{tShared('actions.selectPaymentMethod')}</H4>
      <Form formName='InvoiceDetailPaymentMethod'>
        <PayInMethodSelector />
      </Form>
    </Flex>
  )
}
