import React from 'react'

import {
  Divider,
  Flex,
  makeStyles,
  P,
  PBold,
  PSmaller,
  theme,
  toPx,
  Typography,
  useTranslation,
} from '@guiker/react-framework'
import { currency, money } from '@guiker/shared-framework'

import { useBookingContext, usePaymentVerificationContext } from '../../../../hooks'
import { StepIcon } from '../StepButton'
import { PaymentVerificationStatus } from './PaymentVerificationStatus'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  total: {
    display: 'flex',
    gap: toPx(theme.spacing(2)),
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    height: theme.typography.variants.body.lineHeight,
  },
})

const PaymentVerification: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const { paymentVerifications } = usePaymentVerificationContext()
  const { booking } = useBookingContext()
  const classes = useStyles()
  const totalContribution = paymentVerifications.reduce((acc, current) => acc + current.amount, 0)
  const { amount: totalAmount, currency: bookingCurrency } = booking.bookingPayment.total

  return (
    <Flex flexDirection='column' fullWidth>
      <Flex flexDirection='column' gap={1}>
        {paymentVerifications.map((verification, index) => (
          <PaymentVerificationStatus key={index} paymentVerification={verification} />
        ))}
      </Flex>

      <Divider my={1} color={10} />

      <div className={classes.total}>
        <div className={classes.icon}>
          <StepIcon checked={totalContribution === totalAmount} failed={totalContribution !== totalAmount} />
        </div>
        <div className={classes.label}>
          <PBold mb={0}>{t('unitManagerBookingSteps.total')}</PBold>
        </div>
        <P mb={0}>
          <PSmaller component='span'>{currency[bookingCurrency].symbol} </PSmaller>
          {money.fromAmount(totalContribution, currency[bookingCurrency]).toString(false)}
        </P>
      </div>

      {totalAmount > totalContribution && (
        <Flex ml={3.5} justifyContent='space-between'>
          <Typography>{t('unitManagerBookingSteps.remainder')}</Typography>
          <Typography>
            <PSmaller component='span'>{currency[bookingCurrency].symbol} </PSmaller>
            {money.fromAmount(totalAmount - totalContribution, currency[bookingCurrency]).toString(false)}
          </Typography>
        </Flex>
      )}
    </Flex>
  )
}

export { PaymentVerification }
