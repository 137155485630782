import { CountryCode } from '@guiker/base-entity'
import { DateTime } from '@guiker/date'
import { TypeOf, yup } from '@guiker/yup-util'

import { LegalAddress } from '../../../entity'
import { guarantorAddressSchema, ssnValidator, supportingDocumentSchema } from '../../schemas'
import { ConsentSchema, consentSchema } from './consent-schema'

export const updateInvitedGuarantorPayloadValidator = yup.object({
  guarantor: yup.object({
    profile: yup.object({
      legalFirstName: yup.string().required(),
      legalLastName: yup.string().required(),
      dateOfBirth: yup
        .string()
        .required()
        .test({
          name: 'max',
          test: (date) => {
            const maxDate = DateTime.local()
            const birthdate = DateTime.fromISO(date)
            return birthdate <= maxDate
          },
        }),
      occupation: yup.string().required(),
      annualSalary: yup.string().required(),
      phoneNumber: yup.string().required(),
    }),
    address: guarantorAddressSchema.required(),
    supportingDocuments: yup.array().of(supportingDocumentSchema).nullable(),
    backgroundCheck: yup.object().when(['address'], (address: LegalAddress['address']) =>
      address?.country === CountryCode.US
        ? yup.object({
            consent: consentSchema,
            ssn: yup.string().when(['consent'], (consent: ConsentSchema, schema: yup.StringSchema) => {
              return ssnValidator(consent.isConsentGiven, CountryCode.US, schema)
            }),
          })
        : yup.object({ consent: consentSchema }),
    ),
  }),
})

export type UpdateInvitedGuarantorPayloadValidator = TypeOf<typeof updateInvitedGuarantorPayloadValidator>
