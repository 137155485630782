import React from 'react'

import { Flex, makeStyles, P, PSmall, PSmaller, theme, toPx } from '@guiker/react-framework'
import { upperFirst } from '@guiker/shared-framework'

import { StepIcon } from '../StepButton'

const useStyles = makeStyles({
  icon: {
    alignItems: 'center',
    display: 'flex',
    height: theme.typography.variants.body.lineHeight,
  },
  name: {
    lineHeight: toPx(24),
  },
  status: {
    flexBasis: 'min-content',
    whiteSpace: 'nowrap',
  },
})

type StatusComponentProps = {
  signeeName: string[]
  status: string
  checked?: boolean
  failed?: boolean
}

const StatusComponent: React.FC<StatusComponentProps> = (props) => {
  const { signeeName, status, checked, failed } = props
  const classes = useStyles()
  if (!signeeName) return

  return (
    <Flex fullWidth gap={2} alignItems='flex-start'>
      <div className={classes.icon}>
        <StepIcon checked={checked} failed={failed} />
      </div>
      <Flex flexDirection='column' flexGrow={1}>
        <P mb={0}>{upperFirst(signeeName[0])}</P>
        {signeeName[1] && (
          <PSmaller className={classes.name} mb={0}>
            {signeeName[1]}
          </PSmaller>
        )}
      </Flex>
      <PSmall component='span' textAlign='end' className={classes.status}>
        {status}
      </PSmall>
    </Flex>
  )
}

export { StatusComponent }
