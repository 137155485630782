import React, { useState } from 'react'

import { AuthenticatedApi, BookingActions, BookingRoleNames } from '@guiker/booking-shared'
import {
  ApiForm,
  Dropdown,
  FeedbackComponent,
  SuccessDialog,
  useForm,
  useQuery,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'

import { useBookingContext } from '../../../../../hooks'
import { BookingPanelModal } from '../../../../BookingPanelModal'
import { InviteeForm } from './InviteeForm'
import { ListingOperatorForm } from './ListingOperatorForm'

type UnitManagerInvitationFormProps = {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  disablePortal?: boolean
}

const UnitManagerInvitationForm: React.FC<UnitManagerInvitationFormProps> = ({ open = false, setOpen }) => {
  const { t } = useTranslation(['main-booking', 'common'])
  const [invitationSentModalOpen, setInvitationSentModalOpen] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState<string>()
  const [invitedUserType, setInvitedUserType] = useState<BookingRoleNames>(BookingRoleNames.AGENT)

  const { apiClient, bookingId, can } = useBookingContext()
  const { reset: resetForm } = useForm()

  const { data: availableUnitManagers } = useQuery(['booking-unit-managers', bookingId], () =>
    apiClient.readAvailableUnitManagers({ pathParams: { bookingId } }),
  )

  const canInviteUnitManager = can(BookingActions.InviteUnitManager)
  const canInviteListingOperator = can(BookingActions.InviteListingOperator) && !!availableUnitManagers?.length

  const handleClose = () => {
    resetForm({ firstName: '', emailAddress: '', role: BookingRoleNames.AGENT })
    setInvitationSentModalOpen(false)
    setOpen(false)
  }

  const emailInvitableBookingRoleNames = [
    BookingRoleNames.AGENT,
    BookingRoleNames.LANDLORD,
    BookingRoleNames.INVITED_LANDLORD,
    BookingRoleNames.UNIT_MANAGER,
  ]

  const roleDropdownOptions = (): { label: string; value: BookingRoleNames }[] => {
    const tRolePrefix = 'common:user.role'

    const options = [
      { label: t(`${tRolePrefix}.agent`), value: BookingRoleNames.AGENT },
      { label: t(`${tRolePrefix}.landlord`), value: BookingRoleNames.LANDLORD },
      { label: t(`${tRolePrefix}.invited_landlord`), value: BookingRoleNames.INVITED_LANDLORD },
    ]

    if (canInviteListingOperator) {
      options.push({
        label: t(`${tRolePrefix}.unit_operator`),
        value: BookingRoleNames.LISTING_OPERATOR,
      })
    }

    if (canInviteUnitManager) {
      options.push({
        label: t(`${tRolePrefix}.unit_manager`),
        value: BookingRoleNames.UNIT_MANAGER,
      })
    }

    return options
  }

  return (
    <>
      <BookingPanelModal
        open={open}
        onClose={handleClose}
        title={t('main-booking:actions.invite.inviteUnitManagerTitle')}
        subtitle={t('main-booking:actions.invite.inviteUnitManagerSubtitle')}
      >
        <ApiForm
          formName='BookingUnitManagerInviteForm'
          formOptions={{
            resolver: yupResolver(AuthenticatedApi.Schema.inviteBookingUnitManagerByEmailPayloadSchema),
          }}
        >
          <Dropdown
            mb={4}
            name='role'
            label={t('common:user.role.userType')}
            defaultValue={invitedUserType}
            onChange={(event) => setInvitedUserType(event.target.value as BookingRoleNames)}
            maxWidth={290}
            options={roleDropdownOptions()}
          />

          {emailInvitableBookingRoleNames.includes(invitedUserType) && (
            <InviteeForm
              handleClose={handleClose}
              onSuccess={() => setInvitationSentModalOpen(true)}
              onError={(error: Error) => {
                if (error['meta']?.errorCode === 'InvitedUnitManagerIsOneOfParticipantsError') {
                  setApiErrorMsg(t('main-booking:errors.invitedUnitManagerIsOneOfParticipantsError'))
                } else {
                  setApiErrorMsg(t('main-booking:errors.inviteApplicantsFailed'))
                }
              }}
            />
          )}
        </ApiForm>
        {!emailInvitableBookingRoleNames.includes(invitedUserType) && canInviteListingOperator && (
          <ListingOperatorForm
            options={availableUnitManagers.map((um) => {
              return { label: `${um.firstName} ${um.lastName}`, value: um.userId }
            })}
            handleClose={handleClose}
            setApiErrorMsg={setApiErrorMsg}
          />
        )}

        {apiErrorMsg && (
          <FeedbackComponent
            toasterMessage={apiErrorMsg}
            toasterType='error'
            onToasterClose={() => setApiErrorMsg(undefined)}
          />
        )}
      </BookingPanelModal>

      <SuccessDialog
        open={invitationSentModalOpen}
        keepMounted={false}
        buttonProps={{ onClick: handleClose, children: t('common:actions.close') }}
        text={t('main-booking:actions.invite.invitationSentSuccess')}
        illustration={null}
      />
    </>
  )
}

export { UnitManagerInvitationForm }
