import React from 'react'

import {
  BathroomIcon,
  BedroomIcon,
  Flex,
  PSmaller,
  SquareFootageIcon,
  SvgIconProps,
  useT,
} from '@guiker/react-framework'
import { Unit } from '@guiker/real-estate-shared'
import { compact } from '@guiker/shared-framework'

type IconWithLabelProps = {
  Icon: React.FC<SvgIconProps>
  label: string
}

export const CardLabelWithIcon: React.FC<IconWithLabelProps> = ({ Icon, label }) => {
  return (
    label && (
      <Flex gap={1}>
        <Icon size='small' color={60} />
        <PSmaller color={60} mb={0}>
          {label}
        </PSmaller>
      </Flex>
    )
  )
}

type UnitConfigurationLabelProps = {
  unit: Unit
}

export const BedroomCountLabel: React.FC<UnitConfigurationLabelProps> = ({ unit }) => (
  <CardLabelWithIcon
    Icon={BedroomIcon}
    label={compact([unit?.roomConfigurations.bedroomCount, unit?.roomConfigurations.denCount]).join(' + ') || 'Studio'}
  />
)

export const BathroomCountLabel: React.FC<UnitConfigurationLabelProps> = ({ unit }) => (
  <CardLabelWithIcon
    Icon={BathroomIcon}
    label={compact([unit?.roomConfigurations.bathroomCount, unit?.roomConfigurations.halfBathroomCount]).join(' + ')}
  />
)
export const SquareFootageLabel: React.FC<UnitConfigurationLabelProps> = ({ unit }) => {
  const { tBase } = useT({})
  const label = tBase('unit.squarefeet', { value: unit?.squareFootage })
  return <CardLabelWithIcon Icon={SquareFootageIcon} label={label} />
}
