import React from 'react'

import { supportedLanguages } from '@guiker/base-entity'
import { clsx } from '@guiker/clsx'
import { useTranslation } from '@guiker/i18n'
import { useLocation } from '@guiker/router'

import { i18n } from '../../i18n'
import { Grid, Link, makeStyles, Typography } from '../'

type FooterLanguageSwitcherProps = {
  className?: string
}

const useStyles = makeStyles(
  {
    link: {
      '&:hover': {
        opacity: 1,
      },
      opacity: 0.6,
    },
    current: {
      opacity: 1,
    },
  },
  {
    name: 'FooterLanguageSwitcher',
  },
)

const replaceLocale = ({ locale, pathname, search }: { locale: string; pathname: string; search: string }) => {
  const regexPattern = `^\/(${supportedLanguages.join('|')})?(\/|$)`
  const regex = new RegExp(regexPattern)

  if (regex.test(pathname)) {
    return `${pathname.replace(regex, `/${locale}/`).replace(/\/$/, '')}${search}`
  }
}

const FooterLanguageSwitcher: React.FC<FooterLanguageSwitcherProps> = ({ className, ...props }) => {
  const { t } = useTranslation(['footer'])
  const { pathname, search } = useLocation()
  const currentLanguageTag = i18n.language?.split('-')[0]
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={className} {...props}>
      {supportedLanguages.map((locale) => (
        <Grid item key={locale}>
          <Link
            underline={false}
            hoverUnderline={false}
            isExternalLink
            to={replaceLocale({ locale, pathname, search })}
            prefixWithLocale={false}
            className={clsx(classes.link, {
              [classes.current]: currentLanguageTag === locale,
            })}
          >
            <Typography mb={0} color='white' variant={currentLanguageTag === locale ? 'bodyBold' : 'body'}>
              {t(`common:languages.${locale.toUpperCase()}`)}
            </Typography>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}

export { FooterLanguageSwitcher }
