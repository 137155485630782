import React from 'react'

import { Booking, BookingStatus } from '@guiker/booking-shared'
import { getInvoiceColor, useGetInvoiceStatus } from '@guiker/payment-app-components'
import { computeInvoiceTotalAmount, Invoice } from '@guiker/payment-shared'
import { AnyColor, PaymentIcon, RentalIcon, RentalProcessIcon, SvgIconProps, UserIcon } from '@guiker/react-framework'
import { ListingUnit, TableListing } from '@guiker/rental-listing-shared'
import { SearchableEntities, SearchResult } from '@guiker/search-shared'
import { addressFormatter, getUserFullName, isFunction, merge, money } from '@guiker/shared-framework'
import { User } from '@guiker/user-shared'

export type Item = {
  StartAdornment?: React.FC<SvgIconProps>
  url?: string
  primary: string
  secondary?: string
  date?: string
  status?: {
    text: string
    color: AnyColor
  }
}

export type ItemGroup = {
  seeAllResultsUrl?: string
  label: string
  StartAdornment: React.FC<SvgIconProps>
  items: Item[]
  total: number
  TableComponent?: React.ReactNode
}

export type MapperOverride = {
  booking?: Partial<MapperOverrideArgs<SearchableEntities.BOOKING>>
  invoice?: Partial<MapperOverrideArgs<SearchableEntities.INVOICE>>
  listing?: Partial<MapperOverrideArgs<SearchableEntities.LISTING>>
  listingUnit?: Partial<MapperOverrideArgs<SearchableEntities.LISTING_UNIT>>
  user?: Partial<MapperOverrideArgs<SearchableEntities.USER>>
}

const mapUser = (user: User): Item => {
  return {
    primary: user.emailAddress,
    secondary: getUserFullName(user),
  }
}

const mapRentalListing = (listing: TableListing): Item => {
  return {
    primary: addressFormatter.printShortAddress({ ...listing, street: listing.streetName }),
    secondary: `# ${listing.id}`,
    status: {
      text: listing.status,
      color: listing.status === 'LISTED' ? 'success' : listing.status === 'UNLISTED' ? 'warning' : 'info',
    },
  }
}

const mapRentalListingUnit = (inventory: ListingUnit): Item => {
  return {
    primary: addressFormatter.printShortAddress(inventory.address),
    secondary: `# ${inventory.id}`,
    status: {
      text: inventory.status,
      color: inventory.status === 'LISTED' ? 'success' : inventory.status === 'UNLISTED' ? 'warning' : 'info',
    },
  }
}

const useGetInvoiceMapper = () => {
  const getStatus = useGetInvoiceStatus()

  return (invoice: Invoice): Item => ({
    primary: money.fromAmount(computeInvoiceTotalAmount(invoice), money.currency[invoice.currency]).toString(true),
    secondary: invoice.info.label || `# ${invoice.id}`,
    status: {
      text: getStatus(invoice),
      color: getInvoiceColor(invoice),
    },
  })
}

const mapBooking = (booking: Booking): Item => {
  return {
    primary: addressFormatter.printShortAddress(booking.listing.address),
    secondary: `# ${booking.id}`,
    status: {
      text: booking.status,
      color: [BookingStatus.BOOKED].includes(booking.status)
        ? 'success'
        : [BookingStatus.EXPIRED, BookingStatus.REJECTED, BookingStatus.WITHDRAWN].includes(booking.status)
        ? 'alert'
        : 'info',
    },
  }
}

type MapperOverrideArgs<T extends SearchableEntities> = {
  label?: string
  mapper?: (entity: SearchResult<T>['results'][0]) => Partial<Item>
  seeAllResultsUrl?: (({ searchString }: { searchString: string }) => string) | string
  TableComponent?: (props: { data: SearchResult<T>['results']; searchString?: string }) => React.ReactNode
}

const computeItemGroup = <T extends SearchableEntities>(
  baseItemGroup: Partial<ItemGroup>,
  override: MapperOverrideArgs<T>,
  searchResults: SearchResult<T>,
  searchString: string,
): ItemGroup => {
  return merge(baseItemGroup, {
    total: searchResults.total,
    ...(override?.label ? { label: override.label } : { label: baseItemGroup.label }),
    ...(override?.mapper
      ? {
          items: baseItemGroup.items.map((item, index) =>
            merge({ ...item }, override.mapper(searchResults.results[index])),
          ),
        }
      : {}),
    ...(override?.seeAllResultsUrl
      ? {
          seeAllResultsUrl: isFunction(override.seeAllResultsUrl)
            ? override.seeAllResultsUrl({ searchString })
            : override.seeAllResultsUrl,
        }
      : {}),
    ...(override?.TableComponent
      ? { TableComponent: override.TableComponent({ data: searchResults.results, searchString }) }
      : {}),
  }) as ItemGroup
}

const useMapSearchResult = (mapperOverride: MapperOverride = {}) => {
  const mapInvoice = useGetInvoiceMapper()

  return (searchResult: SearchResult, searchString: string): ItemGroup => {
    switch (searchResult.entity) {
      case 'USER':
        return computeItemGroup(
          {
            items: searchResult.results.map(mapUser),
            label: 'Users',
            StartAdornment: UserIcon,
          },
          mapperOverride.user,
          searchResult,
          searchString,
        )
      case 'LISTING':
        return computeItemGroup(
          {
            items: searchResult.results.map(mapRentalListing),
            label: 'Listings',
            StartAdornment: RentalIcon,
          },
          mapperOverride.listing,
          searchResult,
          searchString,
        )
      case 'LISTING_UNIT':
        return computeItemGroup(
          {
            items: searchResult.results.map(mapRentalListingUnit),
            label: 'Units',
            StartAdornment: RentalIcon,
          },
          mapperOverride.listingUnit,
          searchResult,
          searchString,
        )
      case 'BOOKING':
        return computeItemGroup(
          {
            items: searchResult.results.map(mapBooking),
            label: 'Bookings',
            StartAdornment: RentalProcessIcon,
          },
          mapperOverride.booking,
          searchResult,
          searchString,
        )
      case 'INVOICE':
        return computeItemGroup(
          {
            items: searchResult.results.map(mapInvoice),
            label: 'Invoices',
            StartAdornment: PaymentIcon,
          },
          mapperOverride.invoice,
          searchResult,
          searchString,
        )
    }
  }
}

export const useSearchResultMapper = (mapperOverride: MapperOverride = {}) => {
  const mapper = useMapSearchResult(mapperOverride)

  return (searchResults: SearchResult[], searchString: string): ItemGroup[] => {
    return searchResults
      .map((searchResult) => mapper(searchResult, searchString))
      .filter((itemGroup) => itemGroup.items.length)
  }
}
