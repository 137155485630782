import { CountryCode } from 'libphonenumber-js'

import { yup } from '@guiker/yup-util'

import { parsePhoneNumber } from './utils'

const countrySchema = yup.string() as yup.StringSchema<CountryCode>
const numberSchemaNotRequired = yup
  .string()
  .nullable()
  .transform((val) => (val ? val : undefined))
  .when('country', (country: CountryCode, schema: yup.StringSchema) => {
    return country
      ? schema.test('invalidPhoneNumber', 'lead-without-plus', (value) => {
          if (!value) return true
          try {
            const pn = parsePhoneNumber(value, country)
            const regexNumber = new RegExp('^[0-9 ]+$', 'g')
            return pn?.isValid() && value.substring(0, 1) === '+' && regexNumber.test(value.substring(1))
          } catch (err) {
            return false
          }
        })
      : schema
  })
const numberSchemaRequired = yup
  .string()
  .required()
  .when('country', (country: CountryCode, schema: yup.StringSchema) => {
    return country
      ? schema.test('invalidPhoneNumber', 'lead-without-plus', (value) => {
          try {
            const pn = parsePhoneNumber(value, country)
            const regexNumber = new RegExp('^[0-9 ]+$', 'g')
            return pn?.isValid() && value.substring(0, 1) === '+' && regexNumber.test(value.substring(1))
          } catch (err) {
            return false
          }
        })
      : schema
  })

export const PhoneNumberSchema = (required: boolean) => {
  return required
    ? yup.object({
        country: countrySchema.required(),
        number: numberSchemaRequired,
      })
    : yup.object({
        country: countrySchema,
        number: numberSchemaNotRequired,
      })
}
