import React, { PropsWithChildren } from 'react'

import { Invoice } from '@guiker/payment-shared'
import { Flex, H2 } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { InvoiceDetailStatusChip } from './InvoiceDetailStatusChip'

type Props = PropsWithChildren & {
  invoice: Invoice
  providerLink?: React.ReactNode
}

export const InvoiceTitle: React.FC<Props> = ({ invoice, providerLink }) => {
  const { tShared } = useT({})

  return (
    <Flex flexDirection='column'>
      <Flex gap={2} alignItems='center'>
        <H2 mb={0}>{tShared(`paymentDetails`)}</H2>
        <InvoiceDetailStatusChip invoice={invoice} withDate />
      </Flex>
      {providerLink}
    </Flex>
  )
}
