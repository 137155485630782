import React from 'react'

import { Input } from '@guiker/components-library'
import { SupportedFileType } from '@guiker/dropzone'
import { useTranslation } from '@guiker/i18n'
import { get, useFormContext } from '@guiker/react-hook-form'

import { useAssetStorageContext } from '../../context'
import { AssetFile } from '../../entity'
import { AssetContainer } from '../AssetContainer'

/**
 * @param maxSizeMB Maximum size per file in mb
 * */
type AssetUploaderProps = {
  acceptedFileType?: SupportedFileType | SupportedFileType[]
  assetFlexDirection?: 'row' | 'column'
  disableDragAndDrop?: boolean
  displayNameEditable?: boolean
  displayDirection?: 'row' | 'column'
  groupName?: string
  label?: string
  maxFiles?: number
  maxSizeMB?: number
  name: string
  additionalFields?: {
    name: string
    prop: keyof AssetFile
  }[]
  readOnly?: boolean
  withThumbnail?: boolean
}

export const AssetUploader: React.FC<AssetUploaderProps> = ({
  acceptedFileType = ['image/*', 'application/pdf'],
  assetFlexDirection = 'column',
  disableDragAndDrop = false,
  displayDirection = 'column',
  displayNameEditable,
  name,
  additionalFields,
  groupName = name,
  label,
  maxFiles = 20,
  maxSizeMB: maxSize = 20,
  readOnly,
  withThumbnail = false,
}) => {
  const [t] = useTranslation()
  const formMethods = useFormContext<AssetFile>()
  const error = formMethods?.formState?.errors && get(formMethods?.formState?.errors, name)
  const { getOneAssetGroupByName } = useAssetStorageContext()
  const assetGroup = getOneAssetGroupByName(groupName)

  const onChangeOrder = (payload: AssetFile[]) => {
    const updatedAssets = payload.map((asset, index) => ({
      ...asset,
      name: `${name}[${index}]`,
    })) as AssetFile[]

    assetGroup?.updateAssets(updatedAssets)
  }

  const onAddAsset = (newAsset: AssetFile) => {
    assetGroup?.addAsset(newAsset)
  }

  const onRemoveAsset = (index: number) => {
    assetGroup?.removeAsset(index)
  }

  return (
    <Input label={label} error={error} errorMessage={error && t('errors:required')}>
      <AssetContainer
        assets={assetGroup?.assets}
        name={name}
        additionalFields={additionalFields}
        readOnly={readOnly}
        withThumbnail={withThumbnail}
        displayDirection={displayDirection}
        displayNameEditable={displayNameEditable}
        assetFlexDirection={assetFlexDirection}
        onAddAsset={onAddAsset}
        onRemoveAsset={onRemoveAsset}
        onChangeOrder={onChangeOrder}
        disableDragAndDrop={disableDragAndDrop}
        dropzoneOptions={{
          accept: Array.isArray(acceptedFileType) ? acceptedFileType : [acceptedFileType],
          maxFiles,
          maxSize,
        }}
      />
    </Input>
  )
}
