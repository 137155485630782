import React from 'react'

import { makeStyles, padding, SecondaryButton, useNavigate, useTranslation } from '@guiker/react-framework'
import { TenantInstalment, TenantInstalmentStatus } from '@guiker/rent-payment-shared'

type PayoutMethodStatusChipProps = {
  tenantInstalment: TenantInstalment
}

const useStyles = makeStyles({
  button: {
    fontSize: 14,
    borderWidth: '0.5px',
    padding: padding(1, 3),
  },
})

const TenantInstalmentActionButton: React.FC<PayoutMethodStatusChipProps> = ({ tenantInstalment }) => {
  const classes = useStyles()
  const { t } = useTranslation('main-bookingRentPayment')
  const navigate = useNavigate()
  const tSuffix = tenantInstalment.status === TenantInstalmentStatus.failed ? 'payNow' : 'seeDetails'
  const isInvoiced = !!tenantInstalment?.processedWith

  if (!isInvoiced) {
    return <></>
  }

  return (
    <SecondaryButton
      className={classes.button}
      type='button'
      size='small'
      onClick={() => {
        navigate(`instalments/${tenantInstalment.id}/invoice`)
      }}
    >
      {t(`rentPaymentsPlanTable.actions.${tSuffix}`)}
    </SecondaryButton>
  )
}

export { TenantInstalmentActionButton }
