import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'
import {
  CentralLocationIcon,
  CompetitiveCompensationIcon,
  FlexHoursIcon,
  HealthBenefitsIcon,
  OpenDialogIcon,
  StockOptionsIcon,
} from '@guiker/icons'

import { Box, H2, H4, P } from '../../components'
import { useMediaQuery } from '../../hooks'
import { ContentSection } from './ContentSection'

const useStyle = makeStyles({
  title: {
    width: '100%',
  },
  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  block: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    maxWidth: ({ isMobile }: { isMobile: boolean }) => (isMobile ? '50%' : 350),
  },
  packTitle: {
    marginTop: theme.spacing(2),
  },
})

const StarterPack: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyle({ isMobile })

  const blocks = [
    {
      icon: <CompetitiveCompensationIcon color='info' />,
      title: t('careers.competitiveCompensation'),
      description: [t('careers.weKnowYourWorth')],
    },
    {
      icon: <StockOptionsIcon color='info' />,
      title: t('careers.stockOptions'),
      description: [t('careers.becauseThisIsYourCompanyToo')],
    },
    {
      icon: <FlexHoursIcon color='info' />,
      title: t('careers.flexHours'),
      description: [t('careers.weKnowLifeHappens')],
    },
    {
      icon: <CentralLocationIcon color='info' />,
      title: t('careers.centralLocation'),
      description: [t('careers.convenientlyLocatedInTheTeartOfDowntownMontreal')],
    },
    {
      icon: <HealthBenefitsIcon color='info' />,
      title: t('careers.healthBenefits'),
      description: [t('careers.topTierBenefitsForYourHealthAndWellbeing')],
    },
    {
      icon: <OpenDialogIcon color='info' />,
      title: t('careers.openDialog'),
      description: [t('careers.conversationIsATwoWayStreetAndWeAreListening')],
    },
  ]

  return (
    <ContentSection justifyContent='flex-start'>
      <H2 className={classes.title}>{t('careers.starterPack')}</H2>
      <Box className={classes.blockContainer}>
        {blocks.map((b, index) => (
          <Box key={index} className={classes.block}>
            {b.icon}
            <H4 className={classes.packTitle}>{b.title}</H4>
            <P mb={0}>{b.description}</P>
          </Box>
        ))}
      </Box>
    </ContentSection>
  )
}

export { StarterPack }
