import { HttpMethod } from '@guiker/http'

import { paths } from './paths'
import { backgroundCheckIdPathValidator, createBackgroundCheckPayloadValidator } from './schemas'

export const routes = {
  create: {
    path: `${paths.backgroundCheck.all.withScope('targets', ':targetUserId')}`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (params: unknown) => backgroundCheckIdPathValidator.validate(params),
    payloadValidator: (payload: unknown) => createBackgroundCheckPayloadValidator.validate(payload),
  },
}
