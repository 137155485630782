import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '..'

export const { routes: mainRoutes, pathBuilder: mainPathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        search: '/',
        faq: '/faq',
        propSharing: '/prop-sharing',
        propertySale: '/full-ownership',
      },
    },
  },
  config.domainName,
)
