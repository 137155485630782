import { TypeOf, yup } from '@guiker/yup-util'

import { LeaseTermTypes, Quebec } from '../../../entity'

export const leaseTerm = yup.object<Omit<Quebec.LeaseTerm, 'completed'>>({
  type: yup.mixed<LeaseTermTypes>().oneOf(Object.values(LeaseTermTypes)).required(),
  startDate: yup.string().required(),
  endDate: yup.string().when('type', {
    is: LeaseTermTypes.Fixed,
    then: yup.string().required(),
  }),
  duration: yup.string().when('type', {
    is: LeaseTermTypes.Fixed,
    then: yup.string().required(),
  }),
})

export type LeaseTerm = TypeOf<typeof leaseTerm>
