import React, { useState } from 'react'

import { BackgroundCheckRoleNames } from '@guiker/background-check-shared'
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Collapse,
  makeStyles,
  PBig,
  Spinner,
  theme,
  useQuery,
  useTranslation,
} from '@guiker/react-framework'
import { DateTime } from '@guiker/shared-framework'

import { useBookingContext } from '../../../hooks'
import { useJwtBackgroundCheckApiClient } from '../../../lib'
import { filterConsentAndBackgroundCheckEvents } from '../../../utils'
import { BackgroundCheckHistoryItem } from './BackgroundCheckHistoryItem'

type BackgroundCheckHistoryProps = {
  backgroundCheckId: string
}

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    cursor: 'pointer',
  },
})

export const BackgroundCheckHistory: React.FC<BackgroundCheckHistoryProps> = ({ backgroundCheckId }) => {
  const { t } = useTranslation(['main-tenantApplication'])
  const classes = useStyle()
  const { accessControl } = useBookingContext({ shouldThrowIfUndefined: false })
  const apiClient = useJwtBackgroundCheckApiClient()

  const [showHistory, setShowHistory] = useState(false)

  const canViewBackgroundCheckEvent =
    accessControl?.claims &&
    accessControl.claims.acl.backgroundCheck?.roles.includes(BackgroundCheckRoleNames.SPECTATOR)

  const { data, isLoading } = useQuery(
    ['backgroundCheckEvents', backgroundCheckId],
    async () => {
      const events = await apiClient.readAllBackgroundCheckEvents({
        pathParams: { backgroundCheckId },
        accessControl: { token: accessControl?.token },
      })
      return filterConsentAndBackgroundCheckEvents(events || [])
    },
    {
      retry: 1,
      enabled: !!backgroundCheckId && !!canViewBackgroundCheckEvent,
    },
  )

  if (isLoading) {
    return <Spinner />
  } else if (!data?.length) {
    return <></>
  }

  return (
    <Box mb={1}>
      <Box className={classes.root} onClick={() => setShowHistory(!showHistory)}>
        <PBig mb={0}>{t(`screens.steps.backgroundCheck.activity.title`)}</PBig>
        {showHistory ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Box>
      <Collapse in={showHistory}>
        <Box mt={2}>
          {data.map(({ emittedAt, type }, index) => {
            const label = t(`screens.steps.backgroundCheck.activity.${type}`)
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const parsedDate = DateTime.fromJSDate(new Date(emittedAt), { zone: timeZone })
            const dateValue = t(`screens.steps.backgroundCheck.activity.timeStamp`, {
              label: parsedDate.toFormat('yyyy-MM-dd'),
              value: parsedDate.toLocaleString({
                hour: '2-digit',
                minute: '2-digit',
                timeZone,
              }),
            })
            return <BackgroundCheckHistoryItem label={label} value={dateValue} key={`backgroundcheck-trx-${index}`} />
          })}
        </Box>
      </Collapse>
    </Box>
  )
}
