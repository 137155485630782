import { routeBuilderHelper, routesBuilder } from '@guiker/shared-framework'

import { StockOrder, StockOrderStatus } from '../../entity'
import { paths } from './paths'
import {
  checkoutStockOrderSchema,
  claimPromotionalStockOrderSchema,
  stockOrderRiskAcknowledgementQuerySchema,
} from './schemas'

const builder = {
  stockOrder: {
    base: routesBuilder<StockOrder>()({ basePath: paths.stockOrder.all.base() }),
    idNested: routesBuilder<StockOrder>()({ basePath: paths.stockOrder.all.withId(':id') }),
    preview: routesBuilder<{ fingerprint: string; content: string; date: string }>()({
      basePath: `${paths.stockOrder.all.withId(':id')}`,
    }),
  },
}

export const stockOrderRoutes = {
  readOneStockOrder: builder.stockOrder.idNested.buildGet(),
  readAllStockOrders: builder.stockOrder.base.buildGet(routeBuilderHelper.withStatus(Object.values(StockOrderStatus))),
  checkoutStockOrder: builder.stockOrder.idNested.buildPost({
    path: '/checkout',
    payloadSchema: checkoutStockOrderSchema,
  }),
  claimPromotionalStockOrder: builder.stockOrder.idNested.buildPut({
    path: '/claim-promotion',
    payloadSchema: claimPromotionalStockOrderSchema,
  }),
  previewRiskAcknowledgement: builder.stockOrder.preview.buildGet({
    path: '/risk-acknowledgement/preview',
    queryParamsSchema: stockOrderRiskAcknowledgementQuerySchema,
  }),
}
