import React from 'react'

import { Gender, Roommate } from '@guiker/base-entity'
import { Chip, Flex, Grid, GridSize, P, PBold, useT } from '@guiker/react-framework'

type RoommateContentProps = {
  roommate?: Roommate
}

type RoommateInfoProp = {
  title: string
  content: string | React.ReactNode
  gridSize?: GridSize
}

const RoommateInfo: React.FC<RoommateInfoProp> = ({ title, content, gridSize }) => {
  return (
    <Grid item xs={gridSize ?? 6}>
      <Flex flexDirection='column'>
        <PBold>{title}</PBold>
        {typeof content === 'string' ? <P>{content}</P> : content}
      </Flex>
    </Grid>
  )
}

export const RoommateContent: React.FC<RoommateContentProps> = ({ roommate }) => {
  const { tShared } = useT({ domain: 'baseListing' })
  const { tMain } = useT({ domain: 'tenantApplication' })

  if (!roommate) return <></>

  return (
    <Grid container spacing={3}>
      {roommate.age && (
        <RoommateInfo
          title={tShared('roommateCard.age.label')}
          content={tShared(
            roommate.age.range === 0 ? 'roommateCard.age.under10' : `roommateCard.age.${roommate.age.boundary}`,
            { range: roommate.age.range },
          )}
        />
      )}
      {roommate.gender?.classification && (
        <RoommateInfo
          title={tShared('roommateCard.gender.label')}
          content={
            roommate?.gender?.classification === Gender.OTHER
              ? roommate?.gender?.other
              : tShared(`roommateCard.gender.${roommate.gender.classification}`)
          }
        />
      )}
      {roommate.cleanliness && (
        <RoommateInfo
          title={tMain('roommateProfile.cleanliness.shortLabel')}
          content={tMain(`roommateProfile.cleanliness.${roommate.cleanliness}`)}
        />
      )}
      {roommate.livingWithPets && (
        <RoommateInfo
          title={tMain('roommateProfile.livingWithPets.shortLabel')}
          content={tMain(`roommateProfile.livingWithPets.${roommate.livingWithPets}`)}
        />
      )}
      {roommate.interests?.length && (
        <RoommateInfo
          gridSize={12}
          title={tMain('roommateProfile.interests.label')}
          content={
            <Flex gap={1}>
              {roommate.interests?.map((interest) => (
                <Chip size='smaller' color={'primary'} variant='outlined'>
                  {tMain(`roommateProfile.interests.${interest}`)}
                </Chip>
              ))}
            </Flex>
          }
        />
      )}
      {roommate.sleepSchedule && (
        <RoommateInfo
          gridSize={12}
          title={tMain('roommateProfile.sleepSchedule.label')}
          content={
            <Flex gap={1}>
              {roommate.sleepSchedule?.map((sleep) => (
                <Chip size='smaller' color={'primary'} variant='outlined'>
                  {tMain(`roommateProfile.sleepSchedule.${sleep}`)}
                </Chip>
              ))}
            </Flex>
          }
        />
      )}
    </Grid>
  )
}
