import { conditionallyRequiredSchema, yup } from '@guiker/shared-framework'

type Options = {
  forBusiness?: boolean
}

export const addressSchema = (required: boolean, options: Options = {}) =>
  yup.object({
    country: conditionallyRequiredSchema(yup.string(), required),
    state: conditionallyRequiredSchema(yup.string(), required),
    city: conditionallyRequiredSchema(yup.string(), required),
    streetNumber: conditionallyRequiredSchema(yup.string(), required),
    streetName: conditionallyRequiredSchema(yup.string(), required),
    suiteNumber: yup.string().nullable(),
    postalCode: conditionallyRequiredSchema(yup.string(), required),
    yearsAtAddress: options.forBusiness
      ? yup.number().optional().default(0)
      : conditionallyRequiredSchema(yup.number(), required),
  })
