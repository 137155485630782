import { useContext } from 'react'

import { PayInMethodContext } from '../context'

export const usePayInMethodContext = () => {
  const context = useContext(PayInMethodContext)

  if (context === undefined) {
    throw new Error('usePayInMethodContext can only be used inside PaymentContextProvider')
  }

  return context
}
