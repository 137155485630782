import React from 'react'

import { Portfolio } from '@guiker/propsharing-shared'
import { PieChart } from '@guiker/react-chart'
import { Flex, getGraphColorKey, makeStyles, P, toPx } from '@guiker/react-framework'

import { useT } from '../../i18n'

const useStyles = makeStyles(() => ({
  pieChart: {
    minHeight: 260,
    minWidth: 380,
    fontSize: toPx(14),
  },
}))

type PositionQuantityPieChartProps = {
  portfolio: Portfolio
}

export const PositionQuantityPieChart: React.FC<PositionQuantityPieChartProps> = ({ portfolio }) => {
  const { tShared } = useT({ entity: 'portfolio.positionQuantity' })
  const classes = useStyles()
  if (!portfolio) return

  const dataset = portfolio?.map((position, index) => ({
    key: position.stock.tickerSymbol,
    label: position.stock.tickerSymbol,
    color: getGraphColorKey(index),
    value: position.total.quantity,
  }))

  const total = dataset.reduce((r, d) => r + d.value, 0)

  return (
    <Flex flexDirection='column'>
      <P mb={0}>{tShared('link')}</P>
      <Flex mt={2} className={classes.pieChart}>
        <PieChart
          dataset={dataset}
          total={total}
          highlight={{
            label: tShared('label'),
            value: portfolio?.reduce((acc, pos) => acc + pos.total.quantity, 0),
          }}
        />
      </Flex>
    </Flex>
  )
}
