import { TenantApplication } from '../entity'

export const hasCompletedRoommateProfile = (tenantApplication: TenantApplication): boolean => {
  const completeKeys = [
    'livingWithPets',
    'friendLevel',
    'sleepSchedule',
    'cleanliness',
    'interests',
    'personalDescription',
    'minimumStayConsent',
  ]
  const roommateProfile = tenantApplication?.roommateProfile as { [key in string]: unknown }
  return !!roommateProfile && completeKeys.every((val) => !!roommateProfile[val])
}
