import React from 'react'

import { AnyColor, Flex, P, PBold } from '@guiker/react-framework'
import { isString } from '@guiker/shared-framework'

type InvoiceItemProps = {
  label: React.ReactNode | string
  value: string
  color?: AnyColor
  bold?: boolean
}

export const InvoiceItem: React.FC<InvoiceItemProps> = ({ label, value, color, bold = false }) => {
  const Typo = bold ? PBold : P

  return (
    <Flex gap={1} fullWidth justifyContent='space-between'>
      {isString(label) ? (
        <Typo color={color} textAlign='left'>
          {label}
        </Typo>
      ) : (
        label
      )}
      <Typo color={color} textAlign='right'>
        {value}
      </Typo>
    </Flex>
  )
}
