const getTotalPages = (args: { total: number; perPage: number }) => {
  const perPage = args.perPage === 0 ? (args.total === 0 ? 100 : args.total) : args.perPage

  return Math.floor(args.total / perPage) + (args.total % perPage === 0 ? 0 : 1)
}

export const buildPaginationMeta = (total: number, page: number, perPage: number) => ({
  total,
  totalPages: getTotalPages({ total, perPage }),
  page,
  perPage,
})
