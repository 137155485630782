import { routesBuilder } from '@guiker/shared-framework'

import { Task } from '../../entity'
import { paths } from './paths'

const { buildGet } = routesBuilder<Task>()({
  basePath: paths.task.all.base(),
})

export const routes = {
  readAllTasks: buildGet({ paginated: true }),
}
