import { BuildingAmenityName } from '../amenity'

type BuildingAmenities<T = boolean | null> = {
  bikeParking: T
  communalOutdoorSpace: T
  concierge: T
  elevator: T
  gym: T
  laundryRoom: T
  storage: T
  indoorParking: T
  outdoorParking: T
  pool: T
  recreationRoom: T
  security: T
  wheelchairAccessible: T
}

const LegacyBuildingAmenityMap: { [key in BuildingAmenityName]: keyof BuildingAmenities } = {
  BIKE_PARKING: 'bikeParking',
  COMM_OUTDOOR_SPACE: 'communalOutdoorSpace',
  JANITOR: 'concierge',
  ELEVATOR: 'elevator',
  GYM: 'gym',
  LAUNDRY_ROOM: 'laundryRoom',
  LOCKER: 'storage',
  INPARK: 'indoorParking',
  OUTPARK: 'outdoorParking',
  POOL: 'pool',
  RECEPTION_HALL: 'recreationRoom',
  WHEELCHAIR: 'wheelchairAccessible',
}

export { BuildingAmenities, LegacyBuildingAmenityMap }
