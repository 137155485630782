import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'
import { PublicApiClient } from '@guiker/rental-listing-shared'

export const {
  useContext: usePublicListingContext,
  NestedRouter: PublicListingNestedRouter,
  ContextProvider: PublicListingContextProvider,
} = generateBaseApiContext({
  authLevel: AuthLevel.PUBLIC,
  apiClientGen: PublicApiClient,
  cacheKey: ({ listingId }: { listingId: string }) => `readOneListing-${listingId}`,
  fetcher: (apiClient, { listingId }: { listingId: string }) => apiClient.readOneListing({ pathParams: { listingId } }),
  renderNotFound: false,
})
