import React, { createContext, useEffect } from 'react'

import { TakeOver, TakeOverProps, useLayoutContext } from '@guiker/components-library'

type Context = {
  onBack?: (data?: unknown) => void
  onClose: (data?: unknown) => void
}

const TakeoverContext = createContext<Context>(null)

type Props = TakeOverProps

export const TakeoverContextProvider: React.FC<Props> = (props) => {
  const { setFooterIsDisplayed, setAppBarIsDisplayed } = useLayoutContext()

  useEffect(() => {
    setFooterIsDisplayed(false)
    setAppBarIsDisplayed(false)
  }, [])

  return (
    <TakeoverContext.Provider value={{ onClose: props.onClose, onBack: props.onBack || props.onClose }}>
      <TakeOver {...props} />
    </TakeoverContext.Provider>
  )
}

export { TakeoverContext }
