import React from 'react'

import { makeStyles, PBold, PSmall, theme } from '@guiker/react-framework'

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  },
  { name: 'SectionHeader' },
)

type SectionHeaderProps = React.PropsWithChildren & {
  adornment?: React.ReactNode
  subtitle?: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ adornment, children, subtitle }) => {
  const classes = useStyles()
  return (
    <div>
      <PBold mb={1} className={classes.root}>
        {children?.toString()}
        {adornment}
      </PBold>
      {subtitle && <PSmall color={60}>{subtitle}</PSmall>}
    </div>
  )
}

export { SectionHeader }
