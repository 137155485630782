import { TypeOf } from '@guiker/yup-util'

import * as Schema from './schema'

export enum SimpleConfig {
  BASE_URL = 'BASE_URL',
  MONGO_URL = 'MONGO_URL',
  SENTRY_DSN = 'SENTRY_DSN',
  WEBAPP_URL = 'WEBAPP_URL',
  GA_TRACKING_ID = 'GA_TRACKING_ID',
  JWT_PRIVATE_KEY = 'JWT_PRIVATE_KEY',

  // Legacy
  LEGACY_APP_API_ACCESS_TOKEN = 'LEGACY_APP_API_ACCESS_TOKEN',
  LEGACY_API_BASE_URL = 'LEGACY_API_BASE_URL',
  LEGACY_WEBAPP_NODE_URL = 'LEGACY_WEBAPP_NODE_URL',
  LEGACY_JWT_PRIVATE_KEY = 'LEGACY_JWT_PRIVATE_KEY',

  // Booking Application
  RENTAL_APPLICATION_API_BASE_URL = 'RENTAL_APPLICATION_API_BASE_URL',
  RENTAL_APPLICATION_API_CLIENT_ID = 'RENTAL_APPLICATION_API_CLIENT_ID',
  RENTAL_APPLICATION_API_SECRET = 'RENTAL_APPLICATION_API_SECRET',

  // Email
  EMAIL_SERVICE_API_KEY = 'EMAIL_SERVICE_API_KEY',
  EMAIL_SERVICE_FROM = 'EMAIL_SERVICE_FROM',
  EMAIL_SERVICE_URL = 'EMAIL_SERVICE_URL',
  GUIKER_SALES_EMAIL = 'GUIKER_SALES_EMAIL',

  // Encryption
  ENCRYPTION_KEY = 'ENCRYPTION_KEY',

  // Listing Lead
  SCRAPED_LISTING_API_BASE_URL = 'SCRAPED_LISTING_API_BASE_URL',
  SCRAPED_LISTING_API_KEY = 'SCRAPED_LISTING_API_KEY',

  // Websocket Api
  WEBSOCKET_API_ID = 'WEBSOCKET_API_ID',

  // Google Service Account
  GOOGLE_SERVICE_ACCOUNT_EMAIL = 'GOOGLE_SERVICE_ACCOUNT_EMAIL',
  GOOGLE_SERVICE_ACCOUNT_PRIVATE_KEY = 'GOOGLE_SERVICE_ACCOUNT_PRIVATE_KEY',
  GOOGLE_RECAPTCHA_SECRET = 'GOOGLE_RECAPTCHA_SECRET',
  GOOGLE_DYNAMIC_LINK_BASE_URL = 'GOOGLE_DYNAMIC_LINK_BASE_URL',

  // Office
  OFFICE_WEBAPP_URL = 'OFFICE_WEBAPP_URL',

  // Docusign
  DOCUSIGN_RSA_PRIVATE_KEY = 'DOCUSIGN_RSA_PRIVATE_KEY',

  // PropSharing
  PROPSHARING_TRUST_ACCOUNT_ID = 'PROPSHARING_TRUST_ACCOUNT_ID',
}

export enum ComplexConfig {
  ADMIN_EMAILS = 'ADMIN_EMAILS',
  AWS_CREDENTIALS = 'AWS_CREDENTIALS',
  CERTN_CLIENT_CFG = 'CERTN_CLIENT_CFG',
  DOCUSIGN_CONFIG = 'DOCUSIGN_CONFIG',
  GOOGLE_CONFIG = 'GOOGLE_CONFIG',
  LEGACY_CONVERSATION_API_CONFIG = 'LEGACY_CONVERSATION_API_CONFIG',
  LEGACY_PAYMENT_VERIFICATION_SERVICE_CONFIG = 'LEGACY_PAYMENT_VERIFICATION_SERVICE_CONFIG',
  LEGACY_SQL_CONFIG = 'LEGACY_SQL_CONFIG',
  PAYSAFE_CONFIG = 'PAYSAFE_CONFIG',
  PLAID_CONFIG = 'PLAID_CONFIG',
  RENTSYNC_CONFIG = 'RENTSYNC_CONFIG',
  REPLIERS_CONFIG = 'REPLIERS_CONFIG',
  STRIPE_CONFIG = 'STRIPE_CONFIG',
  TWILIO_CONFIG = 'TWILIO_CONFIG',
  TRANSFERWISE_CONFIG = 'TRANSFERWISE_CONFIG',
}

export const Config = { ...SimpleConfig, ...ComplexConfig }
export type Config = typeof Config

export type ConfigValues = Record<keyof typeof Config, string> & {
  ADMIN_EMAILS: TypeOf<typeof Schema.adminEmailsSchema>
  AWS_CREDENTIALS: TypeOf<typeof Schema.awsCredentialsSchema>
  CERTN_CLIENT_CFG: TypeOf<typeof Schema.certnClientConfigSchema>
  DOCUSIGN_CONFIG: TypeOf<typeof Schema.docusignConfigSchema>
  GOOGLE_CONFIG: TypeOf<typeof Schema.googleConfigSchema>
  LEGACY_CONVERSATION_API_CONFIG: TypeOf<typeof Schema.legacyConversationApiConfigSchema>
  LEGACY_PAYMENT_VERIFICATION_SERVICE_CONFIG: TypeOf<typeof Schema.legacyPaymentVerificationApiConfigSchema>
  PAYSAFE_CONFIG: TypeOf<typeof Schema.paysafeConfigSchema>
  PLAID_CONFIG: TypeOf<typeof Schema.plaidConfigSchema>
  RENTSYNC_CONFIG: TypeOf<typeof Schema.rentsyncConfigSchema>
  REPLIERS_CONFIG: TypeOf<typeof Schema.repliersConfigSchema>
  STRIPE_CONFIG: TypeOf<typeof Schema.stripeConfigSchema>
  TWILIO_CONFIG: TypeOf<typeof Schema.twilioConfigSchema>
  TRANSFERWISE_CONFIG: TypeOf<typeof Schema.transferwiseConfigSchema>
  LEGACY_SQL_CONFIG: TypeOf<typeof Schema.legacySqlConfigSchema>
}
