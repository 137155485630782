import React from 'react'

import { Application, ApplicationStatus, Booking, getApplicantFullName } from '@guiker/booking-shared'
import {
  Box,
  FilledCheckmarkSmallIcon,
  H4,
  makeStyles,
  NavigationList,
  P,
  theme,
  useTranslation,
} from '@guiker/react-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { useConfig } from '../../../hooks'

type BookingApplicationNavigationListProps = {
  booking: Booking
  bookingApplication?: Application
  onClickApplicant: (applicantId: string) => void
}

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  approved: {
    marginLeft: theme.spacing(1),
  },
})

export const BookingApplicationNavigationList: React.FC<BookingApplicationNavigationListProps> = ({
  booking,
  bookingApplication,
  onClickApplicant,
}) => {
  const { t } = useTranslation('main-bookingApplicant')
  const { legacyAssetBaseUrl } = useConfig()
  const classes = useStyles()
  const disableStatus = [ApplicationStatus.INITIAL, ApplicationStatus.STARTED]

  const buildApplicantItem = (applicant) => {
    const content = applicant.application?.content as TenantApplication
    const status =
      bookingApplication && bookingApplication?.userId === applicant.application?.userId
        ? bookingApplication?.status
        : applicant?.application?.status
    const fullName = getApplicantFullName(applicant)

    return {
      primaryText: (
        <Box display='flex' alignItems='center'>
          <H4 mb={0}>{fullName}</H4>
          {status === ApplicationStatus.ACCEPTED && <FilledCheckmarkSmallIcon className={classes.approved} />}
        </Box>
      ),
      avatarFallback: fullName,
      avatar: applicant.avatarUrl && `${legacyAssetBaseUrl}/pictures/users/${applicant.userId}/${applicant.avatarUrl}`,
      disabled: !content || disableStatus.includes(status),
      onClick: () => onClickApplicant(applicant.userId),
      statusText: (
        <P
          mb={0}
          color={
            status === ApplicationStatus.ACCEPTED
              ? 'success'
              : status === ApplicationStatus.CHANGE_REQUESTED
              ? 'alert'
              : 30
          }
        >
          {t(`main-bookingApplicant:status.${status}`)}
        </P>
      ),
    }
  }

  return (
    <NavigationList
      className={classes.fullWidth}
      items={booking?.applicants?.map((applicant) => buildApplicantItem(applicant))}
    />
  )
}
