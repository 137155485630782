import React from 'react'

import { Redirect } from '@guiker/router'

import { PageMetaTags } from '../../components'
import { pathBuilder, routes } from '../../routes'

export const DeprecatedGeneralPolicy: React.FC = () => {
  return (
    <>
      <PageMetaTags noIndex />
      <Redirect path={routes.PrivacyPolicy.path} />
    </>
  )
}

export const DeprecatedRentersPolicy: React.FC = () => {
  return (
    <>
      <PageMetaTags noIndex />
      <Redirect path={routes.TermsAndConditions.path} />
    </>
  )
}

export const DeprecatedLandlordsPolicy: React.FC = () => {
  return (
    <>
      <PageMetaTags noIndex />
      <Redirect path={routes.TermsAndConditions.path} />
    </>
  )
}

export const DeprecatedInvestmentListings: React.FC = () => {
  return (
    <>
      <PageMetaTags noIndex />
      <Redirect path={'/'} />
    </>
  )
}

export const DeprecatedConversation: React.FC = () => {
  return (
    <>
      <PageMetaTags noIndex />
      <Redirect
        path={pathBuilder.conversation.root.messages.path()}
        retainQueryParams={(querystring) => {
          return querystring.replace('convo_id', 'convoId').replace('booking_id', 'bookingId')
        }}
      />
    </>
  )
}
