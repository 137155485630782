import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Invoice, isInvoiceFailed, isInvoicePayable } from '@guiker/payment-shared'
import { Chip, ChipProps, Flex, useDateFormatter } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { getInvoiceColor, useGetInvoiceStatus } from '../../../utils/invoice-utils'

type InvoiceDetailStatusChipProps = Pick<ChipProps, 'size' | 'variant'> & {
  invoice: Pick<Invoice, 'dueBy' | 'createdAt' | 'status' | 'updatedAt' | 'transactions' | 'dispute' | 'customer'>
  withDate?: boolean
  direction?: 'row' | 'column'
}

export const InvoiceDetailStatusChip: React.FC<InvoiceDetailStatusChipProps> = ({
  invoice,
  size = 'small',
  variant = 'filled',
  direction = 'row',
  withDate,
}) => {
  const statusLabel = useGetInvoiceStatus()(invoice)
  const { formatDate } = useDateFormatter()
  const { user } = useAuthenticationContext()
  const { tShared } = useT({ entity: 'invoice' })
  const isPayer = invoice?.customer.id === user?.id
  const isPast = new Date(invoice.dueBy || invoice.createdAt) < new Date()
  const isOverdue = !isInvoiceFailed(invoice) && isInvoicePayable(invoice) && isPast
  const hasDispute = !!invoice.dispute?.status
  const date = formatDate(invoice.transactions[invoice.transactions.length - 1]?.createdAt || invoice.updatedAt)

  return (
    <Flex flexDirection={direction} gap={1}>
      <Chip variant={variant} size={size} color={getInvoiceColor(invoice)}>
        {withDate && !isOverdue ? `${statusLabel} | ${date}` : statusLabel}
      </Chip>
      {hasDispute ? (
        <Chip variant={variant} size={size} color={'alert'}>
          {`${tShared('disputed.label')} | ${tShared(`disputed.${isPayer ? 'payer.' : ''}${invoice.dispute.status}`)}`}
        </Chip>
      ) : undefined}
    </Flex>
  )
}
