import { buildQueueDefinition } from '@guiker/queue'
import { DeepPartial } from '@guiker/ts-utils'

import { config } from '../config'
import { TenantApplication } from '../entity'

export const Queue = {
  MigrateRoommateProfiles: buildQueueDefinition({
    name: 'MigrateRoommateProfiles',
    serviceName: config.apiName,
    payload: null as DeepPartial<TenantApplication>[],
    isFifo: false,
    batchSize: 1,
  }),
}
