import {
  BookingApplicantEvents,
  BookingEvents,
  BookingLeaseEvents,
  config as bookingConfig,
} from '@guiker/booking-shared'
import { config as payoutConfig, PayoutMethodEvent } from '@guiker/payout-shared'
import {
  config as propSharingConfig,
  InvestorProfileEventsDefinition,
  StockOrderEventsDefinition,
  StockOrderExecutionEventsDefinition,
} from '@guiker/propsharing-shared'
import { config as listingConfig } from '@guiker/rental-listing-shared'

export const NotificationTypeConfig = {
  [bookingConfig.context]: {
    [BookingEvents.definition.entity]: {
      INQUIRY_CREATED: BookingEvents.EventTypes.INQUIRY_CREATED,
      LEASE_READY_TO_BE_SIGNED: BookingLeaseEvents.EventTypes.LEASE_READY_TO_BE_SIGNED,
      APPLICATION_CHANGE_REQUESTED: BookingApplicantEvents.EventTypes.APPLICATION_CHANGE_REQUESTED,
      LEASE_TERM_EXTENDED: BookingEvents.EventTypes.LEASE_TERM_EXTENDED,
      EARLY_TERMINATION_SET: BookingEvents.EventTypes.EARLY_TERMINATION_SET,
    },
  },
  [propSharingConfig.context]: {
    [InvestorProfileEventsDefinition.entity]: {
      APPROVED: InvestorProfileEventsDefinition.eventTypes.APPROVED,
      TAX_SLIP_ADDED: 'TAX_SLIP_ADDED',
    },
    [StockOrderEventsDefinition.entity]: {
      BUYOUT: StockOrderEventsDefinition.eventTypes.BUYOUT,
    },
    [StockOrderExecutionEventsDefinition.entity]: {
      FULFILLED: StockOrderExecutionEventsDefinition.eventTypes.FULFILLED,
    },
  },
  [listingConfig.context]: {
    RECOMMENDED_LISTING: {
      NEW_RECOMMENDATION_ADDED: 'NEW_RECOMMENDATION_ADDED',
    },
  },
  [payoutConfig.context]: {
    [PayoutMethodEvent.EventsDefinition.entity]: {
      REJECTED: PayoutMethodEvent.EventsDefinition.eventTypes.REJECTED,
      ENABLED: PayoutMethodEvent.EventsDefinition.eventTypes.ENABLED,
    },
  },
  WILLOW: {
    MIGRATION: {
      WELCOME: 'WELCOME',
      UNIT_PRICE: 'UNIT_PRICE',
    },
  },
} as const
