import { yup } from '@guiker/yup-util'

import { SUPPORTING_DOCUMENT_TYPES, SupportingDocument } from '../../entity'
import { assetsSchema } from './asset'

export const supportingDocumentSchema = yup.object<SupportingDocument>({
  type: yup.string().oneOf(SUPPORTING_DOCUMENT_TYPES).nullable(),
  isWaiveRequested: yup
    .boolean()
    .nullable()
    .transform((val) => (val ? val : val === null ? null : false)),
  reasonForWaive: yup.string().when('isWaiveRequested', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
  assets: assetsSchema.when('isWaiveRequested', {
    is: true,
    then: assetsSchema.nullable().transform(() => null as null),
    otherwise: assetsSchema.required().default([]),
  }),
})
