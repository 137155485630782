import React from 'react'

import { Applicant, ApplicationStatus as ApplicationStatusEnum, Booking } from '@guiker/booking-shared'
import { useTranslation } from '@guiker/react-framework'
import { GuarantorStatus } from '@guiker/tenant-application-shared'

import { getApplicantName } from '../../../../utils'
import { StatusComponent } from './StatusComponent'

type ApplicationStatusProps = {
  booking: Booking
  applicant: Applicant
}

const getGuarantorStatus = (applicant: Applicant) => {
  const guarantor = applicant.application?.content?.hasGuarantor && applicant.application?.content?.guarantor

  if (!guarantor) return

  if (applicant.application?.content.willInviteGuarantor) {
    return guarantor.status
  }

  if (guarantor?.profile?.legalFirstName && guarantor?.profile?.legalLastName) {
    return GuarantorStatus.COMPLETED
  }

  return
}

const ApplicationStatus: React.FC<ApplicationStatusProps> = ({ booking, applicant }) => {
  const { t } = useTranslation(['main-booking'])
  const userId = applicant.userId
  const status = applicant.application?.status
  const guarantor = applicant.application?.content?.hasGuarantor && applicant.application?.content?.guarantor

  const guarantorStatus = getGuarantorStatus(applicant)

  const applicantName = getApplicantName({ booking, userIdOrEmailAddress: userId, t })
  const guarantorName =
    guarantor &&
    guarantor?.profile &&
    getApplicantName({
      booking,
      userIdOrEmailAddress: guarantor.profile.emailAddress,
      t,
    })

  return (
    <>
      <StatusComponent
        signeeName={applicantName}
        status={t(`unitManagerBookingSteps.applicationStatus.${status}`)}
        checked={status === ApplicationStatusEnum.ACCEPTED}
        failed={status === ApplicationStatusEnum.CHANGE_REQUESTED}
      />
      {guarantor && guarantor?.profile && (
        <StatusComponent
          signeeName={guarantorName}
          status={t(`unitManagerBookingSteps.applicationStatus.${guarantorStatus}`)}
          checked={status === ApplicationStatusEnum.ACCEPTED}
        />
      )}
    </>
  )
}

export { ApplicationStatus }
