import { isArray, mapValues } from 'lodash'

import { deeply } from './deeply'

const replaceEmptyObjects = <T>(object: object, replaceValue: any = undefined): T => {
  return deeply(mapValues)(object, (val: unknown, _k: string) =>
    typeof val === 'object' && val !== null && !isArray(val) && Object.keys(val).length < 1 ? replaceValue : val,
  ) as T
}

export { replaceEmptyObjects }
