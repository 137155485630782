import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { byScope } from '../schemas'
import { paths } from './paths'
import { documentAccessControlClaimsSchema, generateDocusignLinkPayloadValidator } from './schemas'

export const routes = {
  generateDocusignLink: {
    path: `${paths.envelope.one.withScope()}/generate-link`,
    method: HttpMethod.POST,
    authenticated: true,
    accessControlValidator: validatorBuilder.buildAccessControlValidator(documentAccessControlClaimsSchema),
    responseValidator: (response: unknown) => Promise.resolve(response as string),
    pathParamsValidator: (pathParams: unknown) => byScope.validate(pathParams),
    payloadValidator: (payload: unknown) => generateDocusignLinkPayloadValidator.validate(payload),
  },
}
