import React, { createContext, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Booking } from '@guiker/booking-shared'
import { FullScreenSpinner, generateUseContext, useQuery } from '@guiker/react-framework'

import { getApiClient } from './get-api-client'

export type BookingRoommateContextType = {
  bookingId: string
  booking: Booking
  apiClient: ReturnType<typeof getApiClient>
  isFetching: boolean
  error: unknown
  refetchBooking: () => Promise<void>
  tenantId: string
}

export const BookingRoommateContext = createContext<BookingRoommateContextType>(null)

export const BookingRoommateContextProvider: React.FC<React.PropsWithChildren & { bookingId: string }> = ({
  bookingId,
  children,
}) => {
  const apiClient = getApiClient()
  const { user } = useAuthenticationContext()
  const [booking, setBooking] = useState<Booking>(null)

  const {
    data,
    isLoading: isLoadingBooking,
    isFetching: isFetchingBooking,
    error,
    refetch,
  } = useQuery(['booking', bookingId], (_) => apiClient.readOneByCurrentTenant({ pathParams: { bookingId } }), {
    retry: 1,
    enabled: !!bookingId && !!user,
    onSuccess: setBooking,
  })

  const refetchBooking = async () => {
    await refetch()
  }

  const isFetching = isLoadingBooking || isFetchingBooking

  if (isFetching || !data) {
    return <FullScreenSpinner />
  }

  const value = {
    booking: (booking || data) as Booking,
    bookingId: bookingId || booking.id,
    apiClient,
    isFetching,
    refetchBooking,
    error,
    tenantId: user.id,
  }

  return <BookingRoommateContext.Provider value={value}>{children}</BookingRoommateContext.Provider>
}

export const useBookingRoommateContext = generateUseContext(BookingRoommateContext)
