import { yup } from '@guiker/yup-util'

export const checkoutStockOrderSchema = yup.object({
  payInMethodId: yup.string().required(),
  share: yup.object({
    quantity: yup.number().integer().positive().required(),
  }),
  consent: yup.object({
    fingerprint: yup.string().required(),
    isConsentGiven: yup.boolean().required().oneOf([true], 'The risk acknowledgement must be accepted.'),
    date: yup.string().required(),
  }),
})

export type CheckoutStockOrderSchema = yup.InferType<typeof checkoutStockOrderSchema>
