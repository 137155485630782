import { StatusCodes } from '@guiker/common'
import { LegacyRequestOptions, LegacyRestClient, Logger } from '@guiker/legacy-rest-client'

import { AppRole, LegacyUser, parseLegacyUser, User } from '../entity'

export { LegacyRequestOptions }

const usersApiPrefix = 'app_api/users'
const endpoints = {
  fetchUserById: `${usersApiPrefix}/{userId}`, // GET
  fetchUserAppRoles: `${usersApiPrefix}/{userId}/app_roles`, // GET
  fetchUserByEmail: `${usersApiPrefix}/search?perPage=100&email={email}`, // GET
  spoofIn: `${usersApiPrefix}/{userId}/impersonate`, // GET
}

const AppApiClient = (baseUrl: string, logger: Logger) => {
  const restClient = LegacyRestClient({ baseUrl, logger })

  const fetchUsersByEmail = async ({
    accessToken,
    email,
  }: {
    accessToken: string
    email: string
  }): Promise<LegacyUser[]> => {
    const url = endpoints.fetchUserByEmail.replace(`{email}`, encodeURIComponent(email))
    const headers = { 'Access-Token': accessToken }
    const response = await restClient.get<LegacyUser[]>(url, { headers })

    if (!response || response.body.data.length === 0) {
      throw new Error('Could not fetch users from legacy')
    }

    return response.body.data
  }

  return {
    fetchUserAppRoles: async (accessToken: string, userId: string): Promise<AppRole[] | undefined> => {
      try {
        const url = endpoints.fetchUserAppRoles.replace(`{userId}`, userId)
        const headers = { 'Access-Token': accessToken }
        const response = await restClient.get<AppRole[]>(url, { headers })
        return response.body.data
      } catch (error) {
        if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
          return
        }

        throw error
      }
    },
    fetchUserById: async (accessToken: string, userId: string): Promise<User> => {
      try {
        const url = endpoints.fetchUserById.replace(`{userId}`, userId)
        const headers = { 'Access-Token': accessToken }
        const response = await restClient.get<LegacyUser>(url, { headers })
        if (!response) {
          throw new Error('Cannot fetch user by id from legacy')
        }
        return parseLegacyUser(response.body.data)
      } catch (error) {
        throw error
      }
    },
    spoofIn: ({ userId, accessToken }: { userId: string; accessToken: string }) => {
      const url = endpoints.spoofIn.replace(`{userId}`, userId)
      const headers = { 'Access-Token': accessToken }
      return restClient.get<LegacyUser>(url, { headers })
    },
    fetchUsersByEmail,
    fetchUserByEmail: async ({
      accessToken,
      email,
      options = { exactMatch: false },
    }: {
      accessToken: string
      email: string
      options?: { exactMatch?: boolean }
    }): Promise<User | undefined> => {
      try {
        const users = await fetchUsersByEmail({ accessToken, email })
        return parseLegacyUser(
          users.length > 1
            ? options.exactMatch
              ? users
                  .filter((user) => {
                    const exactRegex = new RegExp(`^${email.trim()}$`, 'i')
                    return user.email.search(exactRegex) !== -1
                  })
                  .reduce((a, b) => (a.updatedAt > b.updatedAt ? a : b))
              : users.reduce((a, b) => (a.updatedAt > b.updatedAt ? a : b))
            : users[0],
        )
      } catch (error) {
        throw error
      }
    },
  }
}

export { AppApiClient }
