import React, { useCallback, useEffect } from 'react'

import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { FullScreenSpinner } from '@guiker/components-library'

import { useJwtTenantApplicationApiClient, useLocationQuery } from '../hooks'
import { TenantApplicationContextProvider } from './TenantApplicationContext'

export const JwtTenantApplicationContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()

  const jwtApiClient = useJwtTenantApplicationApiClient()

  useEffect(() => {
    setClaimsFromToken(token)
  }, [token])

  const readOneTenantApplication = useCallback(
    () =>
      jwtApiClient.readOneTenantApplication({
        pathParams: { tenantApplicationId: claims.tenantApplicationId as string },
      }),
    [claims?.tenantApplicationId],
  )

  if (!claims?.token) {
    return <FullScreenSpinner />
  }

  return (
    <TenantApplicationContextProvider
      queryDependency={claims?.token as string}
      readOneTenantApplication={readOneTenantApplication}
    >
      {children}
    </TenantApplicationContextProvider>
  )
}
