import { ValueOf } from '@guiker/shared-framework'

export const ChannelType = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  IN_APP: 'IN_APP',
} as const

export type ChannelType = ValueOf<typeof ChannelType>

export const ChannelStatus = {
  [ChannelType.EMAIL]: {
    CREATED: 'CREATED',
    DELIVERED: 'DELIVERED',
    FAILED: 'FAILED',
  },
  [ChannelType.SMS]: {
    CREATED: 'CREATED',
    DELIVERED: 'DELIVERED',
    FAILED: 'FAILED',
  },
  [ChannelType.IN_APP]: {
    CREATED: 'CREATED',
    VIEWED: 'VIEWED',
    READ: 'READ',
  },
} as const

export type ChannelStatus = typeof ChannelStatus

export type Channel = {
  type: ChannelType
  isEnabled: boolean
}
