import { yup } from '@guiker/yup-util'

export const readAllArticlesQueryParams = yup.object({
  _embed: yup.boolean().default(true),
  categories: yup.string(),
  orderby: yup.string().default('date'),
  order: yup.string().oneOf(['asc', 'desc']).default('desc'),
  page: yup.number().min(1).default(1),
  per_page: yup.number().min(1).max(100).default(12),
})
