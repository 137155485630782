import { yup } from '@guiker/yup-util'

import { Quebec } from '../../../entity'
import { address, initials, signature } from '../../schemas'

export const lessor = yup.object<Quebec.Lessor>({
  address: address,
  emailAddress: yup.string().email().required(),
  fullName: yup.string().required(),
  phoneNumber: yup.string().required(),
  initials: initials,
  signature: signature,
  dwelling: yup.object({
    smokeDetectorsInitials: initials,
  }),
})

export type Lessor = Quebec.Lessor
