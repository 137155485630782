import {
  Invoice,
  isInvoiceFailed,
  isInvoiceIncomplete,
  isInvoicePayable,
  isInvoiceSuccess,
} from '@guiker/payment-shared'
import { StatusKey } from '@guiker/react-framework'

import { useT } from '../i18n'

export const getInvoiceColor = (invoice: Pick<Invoice, 'dueBy' | 'createdAt' | 'status'>): StatusKey => {
  const isPast = new Date(invoice.dueBy || invoice.createdAt) < new Date()
  if (isInvoiceSuccess(invoice)) {
    return 'success'
  } else if (isInvoiceFailed(invoice)) {
    return 'alert'
  } else if (isInvoicePayable(invoice) && isPast) {
    return 'warning'
  } else if (isInvoiceIncomplete(invoice)) {
    return 'positive'
  } else {
    return 'info'
  }
}

export const useGetInvoiceStatus = () => {
  const { tShared } = useT({})
  return (invoice: Pick<Invoice, 'dueBy' | 'createdAt' | 'status'>) => {
    const isPast = new Date(invoice.dueBy || invoice.createdAt) < new Date()
    const isOverdue = !isInvoiceFailed(invoice) && isInvoicePayable(invoice) && isPast
    return tShared(`status.${isOverdue ? 'OVERDUE' : invoice.status}`)
  }
}
