import React, { useState } from 'react'

import {
  Applicant,
  BookingApplicantEvents,
  BookingRoleNames,
  isUnitManagerRole,
  Participant,
} from '@guiker/booking-shared'
import { clsx } from '@guiker/clsx'
import { CircularProgress, SecondaryButton } from '@guiker/components-library'
import { useDateFormatter } from '@guiker/i18n'
import { QuestionMarkIcon } from '@guiker/icons'
import {
  EventEntry,
  extractEvent,
  Flex,
  makeStyles,
  Modal,
  P,
  theme,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'

import { useBookingContext } from '../../../hooks'
import { useBookingApiClient, useJwtBookingApiClient } from '../../../lib'

const useStyle = makeStyles({
  historyButton: {
    bottom: theme.spacing(10),
    boxShadow: theme.shadows.light,
    maxHeight: theme.spacing(7),
    maxWidth: 'fit-content',
    padding: theme.spacing(1.5),
    position: 'fixed',
    right: theme.spacing(1),
    zIndex: 699,
    color: theme.palette.grey[30],
  },
  historyContent: {
    maxHeight: 300,
  },
  spinner: {
    padding: theme.spacing(0.5),
  },
})

export type HistoryModalProps = {
  bookingId: string
  applicationId: string
  className?: string
}

type BookingApplicationEvent = EventEntry & { actionBy?: Partial<Participant> }
const parseParticipant = ({ userId, emailAddress, firstName, lastName }: Participant) => ({
  userId,
  emailAddress,
  firstName,
  lastName,
})

export const HistoryModal: React.FC<HistoryModalProps> = ({ bookingId, applicationId, className }) => {
  const { t } = useTranslation('main-bookingApplicant')
  const classes = useStyle()
  const { booking, bookingUser } = useBookingContext()
  const bookingApiClient = useBookingApiClient()
  const bookingJwtApiClient = useJwtBookingApiClient()
  const { formatWithRelativeDateTime } = useDateFormatter()
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false)
  const [history, setHistory] = useState<BookingApplicationEvent[]>([])

  const participants = [
    ...booking.applicants.map((a) => parseParticipant(a)),
    ...booking.unitManagers.map((um) => parseParticipant(um)),
  ].reduce((result, participant) => {
    result[participant.userId] = participant
    return result
  }, {} as { [x in string]: Partial<Participant> })

  const onClose = () => setOpenHistoryModal(false)
  const { mutate: getHistory, isLoading: isLoadingHistory } = useMutation(
    async () => {
      const params = { pathParams: { applicantUserId: applicationId, bookingId } }
      return isUnitManagerRole({ booking, userId: bookingUser.id, role: BookingRoleNames.INVITED_LANDLORD })
        ? bookingJwtApiClient.readAllApplicantApplicationEventsByToken(params)
        : bookingApiClient.readAllApplicantApplicationEvents(params)
    },
    {
      onSuccess: (events) => {
        setHistory(
          events.map((event) => {
            let sourceUserId = event.sourceUserId

            if (event.type === BookingApplicantEvents.EventTypes.APPLICATION_ACCEPTED) {
              const applicant = (event.eventData as { applicants: Applicant[] }).applicants.pop()
              const approval = applicant.application.statusHistory.pop()
              sourceUserId = approval.changedBy
            }

            return {
              ...extractEvent(event),
              actionBy: participants[sourceUserId] ?? (sourceUserId ? { firstName: `ref:${sourceUserId}` } : undefined),
            }
          }),
        ),
          setOpenHistoryModal(true)
      },
    },
  )

  return (
    <>
      <SecondaryButton
        type='button'
        className={clsx(classes.historyButton, className)}
        onClick={() => getHistory()}
        startIcon={
          isLoadingHistory ? (
            <div className={classes.spinner}>
              <CircularProgress size={16} color='inherit' variant='indeterminate' />
            </div>
          ) : (
            <QuestionMarkIcon />
          )
        }
      >
        {t('history.title')}
      </SecondaryButton>
      <Modal open={openHistoryModal} onClose={onClose} title={t('history.title')}>
        <Flex flexDirection='column' className={classes.historyContent}>
          {history.map((event, index) => (
            <Flex key={index} justifyContent='space-between' alignItems='center' fullWidth gap={2}>
              <P>{formatWithRelativeDateTime(event.emittedAt)}</P>
              <P>
                {t(`history.status.${event.status}`)}
                {event.actionBy && ` / ${event.actionBy.firstName || event.actionBy.emailAddress}`}
              </P>
            </Flex>
          ))}
        </Flex>
      </Modal>
    </>
  )
}
