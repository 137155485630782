import React, { createContext, useState } from 'react'

import { jwt, parseClaimsFromToken } from './utils'

export type Claims = Record<string, unknown>

export type ClaimsAuthenticationContextType = React.PropsWithChildren & {
  token: string | null
  claims: Claims | null
  setClaims: (claims: Claims | null) => void
  setClaimsFromToken: (token: string) => void
}

export const ClaimsAuthenticationContext = createContext<ClaimsAuthenticationContextType>(null)

export const ClaimsAuthenticationContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [_token, setToken] = useState(null)
  const [claims, setClaims] = useState<Claims>(_token ? { token: _token, ...jwt.parse(_token)?.data } : null)

  const setClaimsFromToken = (token: string) => {
    if (token && _token !== token) {
      setToken(token)
      setClaims(token ? parseClaimsFromToken(token) : null)
    }
  }

  const value = {
    token: _token,
    claims,
    setClaims,
    setClaimsFromToken,
  }

  return <ClaimsAuthenticationContext.Provider value={value}>{children}</ClaimsAuthenticationContext.Provider>
}
