import React from 'react'

import { ActivityTracker, PSmall, PSmaller } from '@guiker/components-library'
import { useDateFormatter, useT } from '@guiker/i18n'
import { CheckOutlineIcon, OutlinedAlertIcon, TransferSmallIcon } from '@guiker/icons'
import { first, last } from '@guiker/lodash'
import { Payout, PayoutStatus, WithPayoutMethod, WithTransfers } from '@guiker/payment-shared'
import { isPaidOffline, payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { Event } from '@guiker/shared-framework'

import { TimelineMarker, TimelineMarkerProps } from './TimelineMarker'

type TimelineItemProps = {
  label: React.ReactNode
  content?: React.ReactNode
  marker: TimelineMarkerProps
}

type TimelineProps = {
  payout: WithPayoutMethod<WithTransfers<Payout>>
  events: Event<Payout>[]
}

const timelineSteps = [
  { label: 'pending', status: PayoutStatus.PENDING },
  { label: 'inTransit', status: PayoutStatus.IN_TRANSIT },
  { label: 'paid', status: PayoutStatus.PAID },
]

const transformTimelineItems = (items: TimelineItemProps[]) =>
  items.map((item) => {
    const { marker } = item

    return {
      ...item,
      marker: {
        isFirst: marker.isFirst,
        isLast: marker.isLast,
        icon: <TimelineMarker marker={marker} />,
      },
    }
  })

const selectIcon = (isFirst: boolean, isFailed: boolean) => {
  return isFirst ? <TransferSmallIcon /> : isFailed ? <OutlinedAlertIcon /> : <CheckOutlineIcon />
}

const isNextCompleted = (nextStep, events: Event<Payout>[]) => !!events.find((event) => event.type === nextStep?.status)

const buildDefaultTimelineEvents = () => {
  const { tShared } = useT({ domain: 'payout' })

  return timelineSteps.map(({ status }, index) => {
    const isFirst = index === 0
    const isFailed = false

    return {
      label: <PSmall mb={0}>{tShared(`events.status.${status}`)}</PSmall>,
      marker: {
        isFirst,
        isLast: index === timelineSteps.length - 1,
        isCompleted: false,
        isFailed,
        icon: selectIcon(isFirst, isFailed),
      },
    }
  })
}

const buildPayoutTimelineEvents = (payout: WithPayoutMethod<WithTransfers<Payout>>, events: Event<Payout>[]) => {
  const { formatWithRelativeDateTime } = useDateFormatter()
  const { tShared } = useT({ domain: 'payout' })
  const firstEvent = first(events)
  const lastEvent = last(events)
  const reversedEvents = events.map((e) => e).reverse()
  const isPayoutOffline = isPaidOffline(payout)
  const isPending = lastEvent.type === PayoutStatus.PENDING
  const payoutMethodInfo = payoutMethodInfoBuilder(payout.payoutMethod)?.getDisplay()
  let eventDate = firstEvent?.emittedAt

  const items = timelineSteps.map((step, index) => {
    const lastSuccessfulEvent = reversedEvents.find((event) => event.type === step.status)
    const isCompleted = !isPending && (!!lastSuccessfulEvent || isNextCompleted(timelineSteps[index + 1], events))
    const isFailed = !isPending && !isCompleted
    const isFirst = index === 0
    const isPaidOfflineReceived = step.label === 'paid' && !!isPayoutOffline && !isCompleted
    eventDate = !lastSuccessfulEvent ? eventDate : lastSuccessfulEvent?.emittedAt

    return {
      label: (
        <PSmall mb={0} color={isCompleted || !!eventDate || !!isPayoutOffline ? undefined : 30}>
          {step.status === PayoutStatus.PAID
            ? tShared(`events.status.${step.status}`, { account: isPayoutOffline ? payoutMethodInfo : '' })
            : tShared(`events.status.${step.status}`)}
        </PSmall>
      ),
      content: (
        <PSmaller mb={0} color={60}>
          {isPaidOfflineReceived
            ? tShared('events.status.PAID_OFFLINE')
            : !!eventDate
            ? formatWithRelativeDateTime(eventDate)
            : undefined}
        </PSmaller>
      ),
      marker: {
        isFirst,
        isLast: index === timelineSteps.length - 1,
        isCompleted: isCompleted || !!isPayoutOffline,
        isFailed,
        icon: selectIcon(isFirst, isFailed),
      },
    }
  })

  return items
}

const buildTimelineItems = (payout: WithPayoutMethod<WithTransfers<Payout>>, events: Event<Payout>[]) => {
  if (!!events?.length) {
    return buildPayoutTimelineEvents(payout, events)
  }

  return buildDefaultTimelineEvents()
}

export const PayoutTimeline: React.FC<TimelineProps> = ({ payout, events }) => {
  const items = buildTimelineItems(payout, events)
  const transformedItems = transformTimelineItems(items)

  return <ActivityTracker items={transformedItems} spacing={1} />
}
