import React, { createContext, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { FullScreenSpinner, generateUseContext } from '@guiker/react-framework'
import { useQuery } from '@guiker/react-query'
import { TenantApplication } from '@guiker/tenant-application-shared'

type Context = {
  tenantApplication: TenantApplication
  setTenantApplication: (tenantApplication: TenantApplication) => void
  isLoading: boolean
}

type TenantApplicationContextProviderProps = React.PropsWithChildren & {
  forceRender?: boolean
  queryDependency: string
  readOneTenantApplication: () => Promise<TenantApplication>
}

const QUERY_PREFIX = 'TenantApplicationContext'
export const TenantApplicationContext = createContext<Context>(undefined)

export const TenantApplicationContextProvider: React.FC<TenantApplicationContextProviderProps> = ({
  forceRender = false,
  readOneTenantApplication,
  children,
}) => {
  const [tenantApplication, setTenantApplication] = useState<TenantApplication>()
  const { user } = useAuthenticationContext()

  const queryKey = [`${QUERY_PREFIX}-readOneTenantApplication`, user?.id]

  const { isLoading, error } = useQuery<TenantApplication>(queryKey, readOneTenantApplication, {
    retry: 0,
    staleTime: 500,
    onSuccess: setTenantApplication,
  })

  if (!tenantApplication && !forceRender) {
    return <FullScreenSpinner />
  }

  const value = {
    tenantApplication,
    setTenantApplication,
    isLoading,
    error,
  }

  return <TenantApplicationContext.Provider value={value}>{children}</TenantApplicationContext.Provider>
}

export const useTenantApplicationContext = generateUseContext(TenantApplicationContext)
