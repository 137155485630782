import { ValueOfUnion } from '@guiker/shared-framework'

import { PayoutStatusGroupName, RentCollectionPayoutStatusGroups, RentPayoutStatus } from '../entity'

export const findPayoutGroupNameByStatus = (statuses: ValueOfUnion<RentPayoutStatus>[]): PayoutStatusGroupName => {
  const [groupName] = Object.entries(RentCollectionPayoutStatusGroups).find(([_, statusGroup]) => {
    return statusGroup.length === statuses.length && statusGroup.every((g) => statuses.includes(g))
  })
  return groupName as PayoutStatusGroupName
}
