import { TypeOf, yup } from '@guiker/yup-util'

import { Quebec } from '../../../entity'

export const leaseRestrictionsAndModifications = yup.object<
  Omit<Quebec.LeaseRestrictionsAndModifications, 'completed'>
>({
  immovableReadinessDate: yup
    .string()
    .when('type', (type: Quebec.LeaseRestrictionsAndModificationsTypes, schema: yup.StringSchema) => {
      if (!type || type === Quebec.LeaseRestrictionsAndModificationsTypes.noneOfTheAbove) {
        return schema.nullable().transform(() => null as null)
      } else {
        return schema.required()
      }
    }),
  type: yup
    .mixed<Quebec.LeaseRestrictionsAndModificationsTypes>()
    .oneOf([...Object.values(Quebec.LeaseRestrictionsAndModificationsTypes), null])
    .nullable(),
})

export type LeaseRestrictionsAndModifications = TypeOf<typeof leaseRestrictionsAndModifications>
