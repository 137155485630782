import { BaseListing, InvestmentAssumptions } from '@guiker/base-listing-shared'
import { DeepPartial } from '@guiker/shared-framework'

import { PropSharingInquiry } from './propsharing-inquiry'
import { Stock, WithLatestFinancialStatements } from './stock'

export const assetScopeType = 'fractional-ownership-listing'

export enum CreationStatus {
  DRAFTED = 'DRAFTED',
  COMPLETED = 'COMPLETED',
}

export type PropSharingListing = BaseListing & {
  assumptions: InvestmentAssumptions.Assumptions
  results: InvestmentAssumptions.Results
  stock?: Stock
  additionalFlags?: {
    isTrending: boolean
  }
}

export type WithInquiry<T> = T & {
  fractionalOwnershipInquiry?: DeepPartial<PropSharingInquiry>
}

export type WithFundingData<T> = T & {
  totalRaised: number
  investorCount: number
  percentFunded: number
  totalUnitSold: number
  totalUnitQuantity: number
}

export type PropSharingListingWithInquiry = WithInquiry<PropSharingListing>
export type PropSharingListingWithFundingData = WithFundingData<PropSharingListing>
export type WithStockLatestFinancialStatements<T> = Omit<T, 'stock'> & { stock: WithLatestFinancialStatements<Stock> }
