import React from 'react'

import { Flex, PSmall, Spinner } from '@guiker/components-library'
import { PieChart, PieChartKpi } from '@guiker/react-chart'
import { makeStyles, toPx, useTranslation } from '@guiker/react-framework'
import { RentCollectionKpiKey } from '@guiker/statistic-shared'

const useStyles = makeStyles({
  pieChart: {
    minHeight: 260,
    minWidth: 380,
    fontSize: toPx(14),
  },
})

type Props = {
  header: React.ReactNode
  data: PieChartKpi<RentCollectionKpiKey>[]
  error: unknown
  isFetching: boolean
}

const RentCollectionPieChart: React.FC<Props> = ({ header, data, error, isFetching }) => {
  const { t } = useTranslation(['main-statistic'])
  const classes = useStyles()
  const collectedData = data?.find(({ key }) => key === RentCollectionKpiKey.COLLECTED)
  const receivedData = data?.find(({ key }) => key === RentCollectionKpiKey.RECEIVED)
  const collectedValue = collectedData?.value ?? 0
  const receivedValue = receivedData?.value ?? 0
  const highlight = { ...collectedData, value: collectedValue + receivedValue }
  const total = data?.reduce((r, d) => r + d.value, 0)
  const collected = highlight?.value ?? 0
  const collectedRate = (collected / (!!total ? total : 1)) * 100

  const isEmptyState = data?.length && data.every((d) => d.value === 0)

  return (
    <Flex flexDirection='column' gap={2}>
      {header}
      <Flex className={classes.pieChart}>
        {isFetching ? (
          <Spinner />
        ) : error ? (
          <PSmall color={50}>{t('components.rentCollectionPieChart.error')}</PSmall>
        ) : isEmptyState ? (
          <PSmall color={50}>{t('components.rentCollectionPieChart.emptyState')}</PSmall>
        ) : (
          data?.length > 0 && (
            <PieChart
              dataset={data}
              total={data.reduce((r, d) => r + d.value, 0)}
              highlight={{
                ...highlight,
                labelFormatter: (value: number) => `${value.toFixed(0)} %`,
                value: collectedRate,
                label: t('components.rentCollectionPieChart.highlight'),
              }}
            />
          )
        )}
      </Flex>
    </Flex>
  )
}
export { RentCollectionPieChart }
