import React from 'react'

import { Booking } from '@guiker/booking-shared'
import { PageSection4, ThreeColumnsGridLayout } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { BookingInfo } from '../BookingInfo'
import { BookingStatusChip } from '../BookingStatusChip'

type OverviewProps = {
  booking: Booking
}

export const Overview: React.FC<OverviewProps> = ({ booking }) => {
  const { tComponents, tShared } = useT({ entity: 'booking', componentName: 'bookingContent' })

  return (
    <ThreeColumnsGridLayout>
      <PageSection4 title={tComponents('leaseTerm.label')}>
        <BookingInfo booking={booking} />
      </PageSection4>
      <PageSection4 title={tShared('status')}>{<BookingStatusChip booking={booking} />}</PageSection4>
    </ThreeColumnsGridLayout>
  )
}
