import React from 'react'

import { Booking, isBookingSuccess } from '@guiker/booking-shared'
import { PageSection2, PageSection3 } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { ApplicantList } from './ApplicantList'
import { Overview } from './Overview'

type BookingScreenContentProps = {
  booking: Booking
}

export const BookingScreenContent: React.FC<BookingScreenContentProps> = ({ booking }) => {
  const { tComponents } = useT({ componentName: 'bookingContent' })

  return (
    <PageSection2>
      <Overview booking={booking} />
      <PageSection3 title={isBookingSuccess(booking) ? tComponents('tenants') : tComponents('applicants')}>
        <ApplicantList booking={booking} />
      </PageSection3>
    </PageSection2>
  )
}
