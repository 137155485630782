import { PayoutMethod, PayoutMethodType } from '..'
import { CanadianBankPayoutMethod, USBankPayoutMethod } from '../entity'

export const typeChecker = {
  isEFT: (payoutMethod: PayoutMethod): payoutMethod is CanadianBankPayoutMethod => {
    return payoutMethod?.type === PayoutMethodType.CANADIAN_BANK_ACCOUNT
  },
  isACH: (payoutMethod: PayoutMethod): payoutMethod is USBankPayoutMethod => {
    return payoutMethod?.type === PayoutMethodType.US_BANK_ACCOUNT
  },
}
