import React, { useEffect } from 'react'

import { FormSubSection, RadioGroup, useFormContext, useTranslation, useWatch } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { BaseAddress, TenantApplication } from '@guiker/tenant-application-shared'

import { AddressForm } from '../../AddressForm'

type LegalAddressProps = {
  defaultValue: TenantApplication
  schema?: yup.ObjectSchema
  readOnly?: boolean
}

export const LegalAddress: React.FC<LegalAddressProps> = ({ defaultValue, schema, readOnly = false }) => {
  const { setValue } = useFormContext()
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const tPrefix = 'main-tenantApplication:screens.steps.backgroundCheck.legalAddress'
  const formPrefix = 'backgroundCheck.legalAddress'
  const legalAddress = defaultValue?.backgroundCheck?.legalAddress

  const isSameAsCurrentAddress = useWatch<TenantApplication, `${typeof formPrefix}.isSameAsCurrentAddress`>({
    name: `${formPrefix}.isSameAsCurrentAddress`,
    defaultValue: legalAddress?.isSameAsCurrentAddress ?? true,
  })

  const address = isSameAsCurrentAddress ? (defaultValue?.currentAddress as BaseAddress) : legalAddress?.address

  useEffect(() => {
    if (isSameAsCurrentAddress) {
      setValue(`${formPrefix}.address`, defaultValue?.currentAddress)
    } else {
      setValue(`${formPrefix}.address`, legalAddress?.address)
    }
  }, [isSameAsCurrentAddress])

  return (
    <FormSubSection>
      {!readOnly && (
        <RadioGroup
          name={`${formPrefix}.isSameAsCurrentAddress`}
          defaultValue={isSameAsCurrentAddress}
          options={[
            { value: true, label: t(`${tPrefix}.isSameAsCurrentAddress`) },
            { value: false, label: t(`${tPrefix}.manualInput`) },
          ]}
          maxWidth={420}
          readOnly={readOnly}
          condensed={readOnly}
          mb={3}
        />
      )}
      <AddressForm
        disabled={isSameAsCurrentAddress}
        defaultValue={address}
        formPrefix={`${formPrefix}.address`}
        schema={schema}
        readOnly={readOnly}
      />
    </FormSubSection>
  )
}
