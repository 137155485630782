import React from 'react'

import { webappRoutes } from '@guiker/main-webapp-shared'
import { usePromotionContext } from '@guiker/promotion-context'
import { CLCheckbox, Flex, Link, Modal, P, Trans, useModal } from '@guiker/react-framework'

import { useT } from '../../i18n'

export const SplitSpotBanner: React.FC = () => {
  const { tMain, tBase } = useT({ screenName: 'screens.home' })
  const { isOpen, closeModal } = useModal(true)
  const { unsetDirectedSource } = usePromotionContext()

  return (
    <Modal open={isOpen} onClose={closeModal} title={tMain('promotionBanner.splitSpot.title')}>
      <Flex flexDirection='column' gap={1}>
        <P color={'info'}>
          <Trans i18nKey='main-webapp:screens.home.promotionBanner.splitSpot.description'>
            <Link color={'info'} to={webappRoutes.Home.path}>
              <P fontStyle='italic' />
            </Link>
          </Trans>
        </P>
        <P color={'info'}>
          <Trans i18nKey='main-webapp:screens.home.promotionBanner.splitSpot.contact'>
            <Link color={'info'} isExternalLink to='mailto:support@guiker.com'>
              <P fontStyle='italic' />
            </Link>
          </Trans>
        </P>
        <P color={'info'}>{tMain('promotionBanner.splitSpot.thanks')}</P>
        <CLCheckbox onClick={unsetDirectedSource} label={tBase('actions.doNotShowAgain')} />
      </Flex>
    </Modal>
  )
}
