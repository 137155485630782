import { selectFromMap } from '@guiker/lodash'
import { CurrencyISO } from '@guiker/money'

export enum SupportedCountries {
  Canada = 'CA',
  UnitedStates = 'US',
}

const CurrencyMap = {
  [SupportedCountries.Canada]: CurrencyISO.CAD,
  [SupportedCountries.UnitedStates]: CurrencyISO.USD,
  default: CurrencyISO.CAD,
}

export const getCurrencyISOBySupportedCountries = selectFromMap(CurrencyMap)

export const SupportedStates = {
  CA: {
    Alberta: 'AB',
    BritishColumbia: 'BC',
    Manitoba: 'MB',
    NewBrunswick: 'NB',
    NewfoundlandAndLabrador: 'NL',
    NovaScotia: 'NS',
    NorthwestTerritories: 'NT',
    Nunavut: 'NU',
    Ontario: 'ON',
    PrinceEdwardIsland: 'PE',
    Quebec: 'QC',
    Saskatchewan: 'SK',
    Yukon: 'YT',
  },
  US: {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    DistrictOfColumbia: 'DC',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    NewHampshire: 'NH',
    NewJersey: 'NJ',
    NewMexico: 'NM',
    NewYork: 'NY',
    NorthCarolina: 'NC',
    NorthDakota: 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    RhodeIsland: 'RI',
    SouthCarolina: 'SC',
    SouthDakota: 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    WestVirginia: 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
  },
} as const
