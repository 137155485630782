export enum DealType {
  PRECON = 'pre-construction',
  NEW = 'new',
  RESALE = 'resale',
}

export type PreConstructionDeal = {
  type: DealType.PRECON
  deliveryDate: string
}

export type NewDeal = {
  type: DealType.NEW
}

export type ResaleDeal = {
  type: DealType.RESALE
  occupancy: {
    isVacant: boolean
    endsAt?: string
  }
}

export type Deal = PreConstructionDeal | NewDeal | ResaleDeal
