import React, { createContext } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { InvestorProfile } from '@guiker/propsharing-shared'
import { generateUseContext } from '@guiker/react-context'
import { useQuery } from '@guiker/react-query'

import { useAuthApiClient } from './use-api-client'

type Context = {
  investorProfile: InvestorProfile
  refetchInvestorProfile: () => void
  isFetching: boolean
}
export const InvestorProfileContext = createContext<Context>(null)

type Props = React.PropsWithChildren

export const InvestorProfileContextProvider: React.FC<Props> = ({ children }) => {
  const { user } = useAuthenticationContext()
  const propSharingApiClient = useAuthApiClient()

  const {
    data: investorProfile,
    refetch: refetchInvestorProfile,
    isFetching,
  } = useQuery('investor-profile', () => propSharingApiClient.readOneInvestorProfile(), {
    retry: 1,
    enabled: !!user,
  })

  const value = {
    investorProfile,
    isFetching,
    refetchInvestorProfile,
  }

  return <InvestorProfileContext.Provider value={value}>{children}</InvestorProfileContext.Provider>
}

export const useInvestorProfileContext = generateUseContext(InvestorProfileContext)
