import { CountByStatusMetadata, routesBuilder } from '@guiker/shared-framework'

import { BulkInvoiceCreationError, Invoice, InvoiceStatus } from '../../../entity'
import { routes as idNestedRoutes } from './:invoiceId'
import { routes as scopeNestedRoutes } from './:scope'
import { paths } from './paths'
import { bulkChargeInvoiceSchema, createCustomInvoicePayloadSchema, readAllInvoicesQueryParamsSchema } from './schemas'

const basePath = paths.invoices.path
const { buildPost, buildGet } = routesBuilder<Invoice>()({ basePath })

export const routes = {
  ...idNestedRoutes,
  ...scopeNestedRoutes,
  readAllInvoices: buildGet({
    paginated: true,
    queryParamsSchema: readAllInvoicesQueryParamsSchema,
    additionalMeta: {} as CountByStatusMetadata<InvoiceStatus>,
  }),
  createCustomInvoice: buildPost({
    payloadSchema: createCustomInvoicePayloadSchema,
  }),
  bulkChargeInvoices: buildPost({
    path: '/bulk-charges',
    payloadSchema: bulkChargeInvoiceSchema,
    responseIsArray: true,
  }),
  dryRunBulkChargeInvoices: buildPost({
    path: '/bulk-charges/dry-run',
    payloadSchema: bulkChargeInvoiceSchema,
    response: null as {
      errors: BulkInvoiceCreationError[]
      sum: number
      numberOfInvoices: number
    },
  }),
} as const
