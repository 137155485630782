import React, { createContext, PropsWithChildren } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Notification } from '@guiker/notification-shared'
import { generateUseContext, UseMutateFunction, useMutation, useNavigate, useQuery } from '@guiker/react-framework'

import { useAuthenticatedNotificationApiClient } from '../hooks/use-notification-api-client'

type NotificationContext = {
  notifications: Notification[]
  isLoading: boolean
  unreadCount: number
  markAsRead: UseMutateFunction<
    Notification,
    unknown,
    {
      id: string
      url: string
      isExternalLink?: boolean
    },
    unknown
  >
}

export const NotificationContext = createContext<NotificationContext>(null)

export const NotificationContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const apiClient = useAuthenticatedNotificationApiClient()
  const navigate = useNavigate()
  const { user } = useAuthenticationContext()

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    ['readAllNotifications', user?.id],
    () =>
      apiClient.readAllInAppNotifications({
        queryParams: { page: 1, perPage: 100, sort: 'createdAt', sortOrder: -1 },
      }),
    { enabled: !!user },
  )

  const { mutateAsync: markAsRead } = useMutation(
    ({ id }: { id: string; url: string; isExternalLink?: boolean }) => {
      return apiClient.updateNotificationStatus({
        pathParams: { id },
        payload: { channelType: 'IN_APP', channelStatus: 'READ' },
      })
    },
    {
      onSettled: (_data, __error, { url, isExternalLink }) => {
        refetch()
        navigate(url, { isExternalLink })
      },
    },
  )

  return (
    <NotificationContext.Provider
      value={{
        notifications: response?.data,
        markAsRead,
        unreadCount: response?.meta?.unreadCount || 0,
        isLoading,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotificationContext = generateUseContext(NotificationContext)
