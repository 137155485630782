import { Asset } from '@guiker/asset-shared'
import { ValueOf } from '@guiker/ts-utils'

export enum DocumentType {
  ID = 'ID',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  PR_CARD = 'PR_CARD',
  PASSPORT = 'PASSPORT',
}

export const KYCCheckStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
} as const
export type KYCCheckStatus = ValueOf<typeof KYCCheckStatus>

export const KYCCheckResultStatus = {
  VERIFIED: 'VERIFIED',
  FRAUD_DETECTED: 'FRAUD_DETECTED',
  INCONCLUSIVE: 'INCONCLUSIVE',
} as const
export type KYCCheckResultStatus = ValueOf<typeof KYCCheckResultStatus>

export type kycCheckReason = {
  field: string
  provided: string
  received: string
}

export type kycCheckReasons = {
  [key in 'address' | 'dateOfBirth' | 'name']: kycCheckReason
}

export type IdentityVerification = {
  governmentIdFront: Asset
  governmentIdBack?: Asset
  facePicture: Asset
  documentType: DocumentType
  kycCheck: {
    status: KYCCheckStatus
    result: KYCCheckResultStatus
    reasons: kycCheckReasons | null
  }
}
