enum RecaptchaAction {
  emailFoundListingOutsideQuestion = 'emailFoundListingOutsideQuestion',
  emailLandlordServiceQuestion = 'emailLandlordServiceQuestion',
  emailServiceQuestion = 'emailServiceQuestion',
  createCreditCard = 'createCreditCard',
  createDirectDebit = 'createDirectDebit',
  fractionalOwnershipInquiry = 'fractionalOwnershipInquiry',
  investorsEnquiry = 'investorsEnquiry',
  propertySaleInquiry = 'propertySaleInquiry',
}

// threshold: ranged 0 to 1
// 0 means likely a bot
// 1 means likely a user interaction
type RecaptchaActionThreshold = {
  [key in RecaptchaAction]: number
}

const recaptchaActionThreshold: RecaptchaActionThreshold = {
  emailFoundListingOutsideQuestion: 0.7,
  emailLandlordServiceQuestion: 0.7,
  emailServiceQuestion: 0.7,
  createCreditCard: 0.8,
  createDirectDebit: 0.8,
  investorsEnquiry: 0.7,
  propertySaleInquiry: 0.7,
  fractionalOwnershipInquiry: 0.7,
}

export { RecaptchaAction, recaptchaActionThreshold }
