import { yup } from '@guiker/yup-util'

import { Paginate } from './paginate'

export const buildPaginationQueryParamSchema = <T extends true | undefined = undefined>(
  args: {
    defaults?: Partial<Paginate>
    required?: T
  } = {},
): yup.ObjectSchema<T extends undefined ? Partial<Paginate> : Paginate> => {
  const { sort = 'createdAt', sortOrder = -1, page = 1, perPage = 100 } = { ...args.defaults }

  return yup
    .object({
      sort: yup.string().nullable().default(sort),
      sortOrder: yup.number().oneOf([1, -1]).nullable().default(sortOrder),
      page: yup.number().min(1).default(page),
      perPage: yup.number().min(1).max(100).default(perPage),
    })
    .notRequired()
}
