import React, { createContext, useState } from 'react'

import { FeedbackComponent } from '@guiker/components-library'
import { useQuery } from '@guiker/react-query'
import { TenantPreference } from '@guiker/tenant-preference-shared'

import { useAdminTenantPreferenceApiClient } from '../hooks'

type TenantPreferenceContextProviderType = {
  userId: string
  isAdmin: boolean
  columnNumber: 2 | 3
  children: React.ReactNode | RenderChildren
}

type RenderChildren = (context: TenantPreferenceContextType) => React.ReactNode

export type TenantPreferenceContextType = {
  userId: string
  tenantPreference: TenantPreference | null
  setTenantPreference: (tenantPreference: TenantPreference) => void
  isAdmin: boolean
  columnNumber: 2 | 3
  isLoading: boolean
}

export const TenantPreferenceContext = createContext<TenantPreferenceContextType>(null)

export const TenantPreferenceContextProvider: React.FC<TenantPreferenceContextProviderType> = ({
  userId,
  isAdmin,
  columnNumber,
  children,
}) => {
  const apiClient = useAdminTenantPreferenceApiClient()
  const [tenantPreference, setTenantPreference] = useState<TenantPreference>()

  const { data, isLoading, error } = useQuery(
    ['fetchTenantPreference', userId],
    () => apiClient.readOneTenantPreference({ pathParams: { userId } }),
    {
      retry: false,
      onSuccess: setTenantPreference,
      onError: () => setTenantPreference(undefined),
    },
  )

  const value = {
    userId,
    tenantPreference: (tenantPreference || data) as TenantPreference,
    setTenantPreference,
    isAdmin,
    columnNumber,
    isLoading,
    error,
  }

  if (isLoading) {
    return <FeedbackComponent loading={isLoading} />
  }

  return (
    <TenantPreferenceContext.Provider value={value}>
      {typeof children === 'function' ? (children as RenderChildren)(value) : children}
    </TenantPreferenceContext.Provider>
  )
}
