import React from 'react'

import { AuthenticatedApi } from '@guiker/booking-shared'
import { ApiForm, ButtonWithLoader, SecondaryButton, useTranslation, yupResolver } from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'
import { BookingPanelModal } from '../../../BookingPanelModal'
import { useBookingPanelContext } from '../../context'
import { UpdateBookingFormContent } from './UpdateBookingInfoFormContent'

type UpdateBookingInfoModalProps = {
  bookingId: string
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
}

export const UpdateBookingInfoModal: React.FC<UpdateBookingInfoModalProps> = ({ open, setOpen, bookingId }) => {
  const { t } = useTranslation()
  const { apiClient, refetchBooking } = useBookingContext()
  const { selectedPriceOptionId } = useBookingPanelContext()

  const resolver = yupResolver(AuthenticatedApi.Schema.updateBookingSchema, {
    defaultValues: {
      optionId: selectedPriceOptionId,
      hasEnabledStep: {
        payment: false,
        lease: false,
        application: false,
      },
    },
  })

  const handleClose = () => setOpen(false)
  const handleSubmit = (payload: Required<AuthenticatedApi.Schema.UpdateBookingSchema>) => {
    return apiClient.updateBooking({ pathParams: { bookingId }, payload })
  }

  return (
    <ApiForm
      formName='BookingUpdateInfoForm'
      formOptions={{ resolver }}
      onSubmit={handleSubmit}
      apiOptions={{ onSuccess: () => refetchBooking().then(() => handleClose()) }}
    >
      {({ isLoading }) => (
        <BookingPanelModal
          open={open}
          onClose={handleClose}
          title={t('main-booking:updateBookingInfoModal.title')}
          actions={
            <>
              <SecondaryButton onClick={handleClose} disabled={isLoading} fullWidth>
                {t('common:cancel')}
              </SecondaryButton>
              <ButtonWithLoader type='submit' isLoading={isLoading} fullWidth>
                {t('common:confirm')}
              </ButtonWithLoader>
            </>
          }
        >
          <UpdateBookingFormContent />
        </BookingPanelModal>
      )}
    </ApiForm>
  )
}
