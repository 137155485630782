import { createContext } from 'react'

import { User } from '@guiker/authentication-shared'

import { AuthType } from '../../auth-type'

export type AuthenticationModalOptions = {
  onSuccess?: (user: User) => void
  onDismiss?: () => void
}

export type AuthenticationModalContextType = {
  openAuthenticationModal: (authType: AuthType, options?: AuthenticationModalOptions) => void
  openAuthSuccessModal: (authType: AuthType, emailAddress?: string) => void
}

export const AuthenticationModalContext = createContext<AuthenticationModalContextType>(null)
