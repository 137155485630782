import { DateTime, parseDateTime } from '@guiker/date'

import { Applicant } from '../applicant'
import { RoommateIntro, RoommateIntroStatus, RoommateIntroTenant, RoommateIntroTenantDecision } from '../application'
import { Booking } from '../booking'

const REVIEWABLE_TENANT_DECISION_STATUS = [RoommateIntroTenantDecision.ACCEPTED, RoommateIntroTenantDecision.REJECTED]

export const isRoommateIntroTenantDecisionReviewable = (status: RoommateIntroTenantDecision) => {
  return REVIEWABLE_TENANT_DECISION_STATUS.includes(status)
}

const PRE_INTRO_STATUS = [RoommateIntroStatus.INITIAL, RoommateIntroStatus.PROFILED]
const REVIEWABLE_STATUS = [RoommateIntroStatus.INTRO_STARTED, RoommateIntroStatus.ACCEPTED]
const IMMUTABLE_STATUS = [RoommateIntroStatus.ACCEPTED]
const APPROVED_STATUS = [RoommateIntroStatus.ACCEPTED]

export const isRoommateIntroIncomplete = (status: RoommateIntroStatus) => {
  return PRE_INTRO_STATUS.includes(status)
}

export const isRoommateApproved = (status: RoommateIntroStatus) => {
  return APPROVED_STATUS.includes(status)
}

export const RoommateIntroStatusGroup = {
  REVIEWABLE: REVIEWABLE_STATUS,
  IMMUTABLE: IMMUTABLE_STATUS,
  REVIEWED: [...REVIEWABLE_STATUS, ...IMMUTABLE_STATUS],
  APPROVED: APPROVED_STATUS,
}

export const ROOMMATE_INTRO_DURATION = 48 // hours

export const getRoommateIntroTimer = () => {
  const now = DateTime.local().toUTC()

  return {
    startsAt: now.toISO(),
    expiresAt: now.plus({ hours: ROOMMATE_INTRO_DURATION }).toISO(),
  }
}

export const isTimerValid = (applicants: Applicant[]) => {
  const isValidTimer = (timer: RoommateIntro['timer']) => {
    if (!timer) return false
    const deadline = parseDateTime(timer.expiresAt).toMillis()
    const timeLeftSecs = (deadline - Date.now()) / 1000
    return timeLeftSecs > 0
  }
  return applicants.every((applicant) => isValidTimer(applicant.application?.roommateIntro?.timer))
}

export const findTenantByUserId = ({ booking, userId }: { booking: Booking; userId: string }): RoommateIntroTenant => {
  return booking.applicants.reduce((_, applicant) => {
    const tenant = applicant.application?.roommateIntro?.tenants?.find((t) => t.userId === userId)
    if (tenant) {
      return tenant
    }
  }, null as RoommateIntroTenant)
}
