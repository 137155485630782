import React from 'react'

import { Flex, PageSection3 } from '@guiker/react-framework'

import { ItemGroup } from '../../utils'
import { SearchListItem } from './SearchListItem'

export type SearchBarItemGroupProps = ItemGroup

export const SearchListItemGroup: React.FC<SearchBarItemGroupProps> = ({ label, items, TableComponent }) => {
  return (
    <PageSection3 title={label}>
      {TableComponent || (
        <Flex flexDirection='column' gap={1}>
          {items.map((item) => (
            <SearchListItem {...item} />
          ))}
        </Flex>
      )}
    </PageSection3>
  )
}
