import { scopeSchema } from '@guiker/access-control-shared'
import { BackgroundCheckRoleNames } from '@guiker/background-check-shared'
import { LeaseRoleNames } from '@guiker/lease-shared'
import { yup } from '@guiker/yup-util'

import { BookingAccessControlClaims } from '../booking-access-control-token'

/**@todo refactor to generateAclTokenSchema in @guiker/access-control-shared, and call function to get schema */
export const bookingAccessControlClaimsSchema = yup.object<BookingAccessControlClaims>({
  scope: scopeSchema,
  userId: yup.string(),
  emailAddress: yup.string().required(),
  acl: yup
    .object({
      lease: yup
        .object({
          roles: yup
            .array()
            .of(yup.string().oneOf(Object.values(LeaseRoleNames)))
            .required(),
          references: yup.array().of(yup.string()).optional(),
        })
        .optional(),
      backgroundCheck: yup
        .object({
          roles: yup
            .array()
            .of(yup.string().oneOf(Object.values(BackgroundCheckRoleNames)))
            .required(),
          references: yup.array().of(yup.string()).optional(),
        })
        .optional(),
    })
    .required(),
})

export type BookingAccessControlClaimsSchema = yup.InferType<typeof bookingAccessControlClaimsSchema>
