import { BackgroundCheckStatus, ConductFor, CreditReportOverallScore } from '@guiker/background-check-shared'
import {
  Consent,
  TenantApplication,
  TenantApplicationBackgroundCheck,
  TenantApplicationGuarantorBackgroundCheck,
} from '@guiker/tenant-application-shared'

export type Report = {
  type: ConductFor
  consent: Consent
  score: number
  status: BackgroundCheckStatus
  creditStatus: CreditReportOverallScore
  creditScore: number
  updatedAt: string
}

export const getApplicationBackgroundCheckReports = (application: TenantApplication): Report[] => {
  const buildReport = (
    type: Report['type'],
    backgroundCheck: TenantApplicationBackgroundCheck | TenantApplicationGuarantorBackgroundCheck,
  ) =>
    backgroundCheck && {
      type,
      consent: backgroundCheck.consent,
      status: backgroundCheck.status,
      creditStatus: backgroundCheck.creditStatus,
      creditScore: backgroundCheck.creditScore,
      score: backgroundCheck.certnScore,
      updatedAt: backgroundCheck.updatedAt,
    }

  return [
    buildReport('applicant', application.backgroundCheck),
    buildReport('guarantor', application.guarantor?.backgroundCheck),
  ].filter((i) => !!i)
}
