import React from 'react'

import { officePathBuilder, Position } from '@guiker/propsharing-shared'
import {
  ColumnConfig,
  Flex,
  Link,
  PaginatedTable,
  PaginatedTableProps,
  PSmall,
  PSmaller,
} from '@guiker/react-framework'
import { getUserFullName, money, numberToString, WithTimestamps } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type StockPositionsTableProps = Pick<PaginatedTableProps<Position>, 'queryKey' | 'fetcher' | 'exportAsCSV'>

const UnitQuantityComponent: React.FC<{ quantity: number; value: number }> = ({ quantity, value }) => {
  const { tShared } = useT({ entity: 'portfolio' })

  return (
    <Flex gap={0.5} flexDirection='column'>
      <Flex gap={0.5} alignItems='baseline' justifyContent='flex-end'>
        <PSmall mb={0} textAlign='right'>
          {tShared('unitOwned.value', { value: numberToString(quantity, 0) })}
        </PSmall>
      </Flex>
      <PSmaller>{money.fromAmountString(`${value}`, money.currency.CAD).toString()}</PSmaller>
    </Flex>
  )
}

const useGetColumns = (): ColumnConfig<WithTimestamps<Position>>[] => {
  const { tShared } = useT({ entity: 'portfolio.position' })
  const { tShared: tInvestorProfileShared } = useT({ entity: 'investorProfile' })

  return [
    {
      label: tInvestorProfileShared('fullName'),
      name: 'investorProfile.fullName',
      size: 2,
      renderValue: (position) => {
        return [
          <Link
            to={
              officePathBuilder.root.investorProfiles.byInvestorProfileId(position.investorProfile.id)
                .reviewInvestorProfile.path
            }
          >
            <PSmall mb={0}>{getUserFullName(position.investorProfile.personal.legalName)}</PSmall>
          </Link>,
          <PSmaller mb={0} color={60}>
            {`${position.investorProfile.personal.emailAddress} - ${position.investorProfile.personal.phone.number}`}
          </PSmaller>,
        ]
      },
    },
    {
      label: tShared('issued'),
      name: 'issuedQuantity',
      headerOptions: { textAlign: 'right' },
      renderValue: (position) => {
        return <UnitQuantityComponent quantity={position.issued.quantity} value={position.issued.value} />
      },
    },
    {
      label: tShared('reserved'),
      name: 'reservedQuantity',
      headerOptions: { textAlign: 'right' },
      renderValue: (position) => {
        return <UnitQuantityComponent quantity={position.reserved.quantity} value={position.reserved.value} />
      },
    },
    {
      label: tShared('total'),
      name: 'totalQuantity',
      headerOptions: { textAlign: 'right' },
      renderValue: (position) => {
        return <UnitQuantityComponent quantity={position.total.quantity} value={position.total.value} />
      },
    },
  ]
}

export const StockPositionsTable: React.FC<StockPositionsTableProps> = ({ queryKey, fetcher, exportAsCSV }) => {
  const columns = useGetColumns()

  return <PaginatedTable queryKey={queryKey} fetcher={fetcher} columns={columns} condensed exportAsCSV={exportAsCSV} />
}
