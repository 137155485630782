import React, { useEffect, useState } from 'react'

import { getLocationService } from '@guiker/base-entity'
import {
  Autocomplete,
  ColumnGridItem,
  Dropdown,
  getLocalizedCity,
  getLocalizedNeighbourhood,
  useFormContext,
  useTranslation,
} from '@guiker/react-framework'
import { LeasePreferences as LeasePreferencesType } from '@guiker/tenant-preference-shared'

type LeasePreferencesLocationProps = {
  defaultValue: LeasePreferencesType
  name: string
}

export const LeasePreferencesLocation: React.FC<LeasePreferencesLocationProps> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common-tenantPreference'])
  const { getCities, getCityBySlug, getCityNeighbourhoods, defaultCity } = getLocationService()
  const [location, setLocation] = useState(getCityBySlug(defaultValue?.location || defaultCity))
  const [neighbourhoods, setNeighbourhoods] = useState<string[]>([])
  const { setValue } = useFormContext()

  const getOptionLabel = (slug: string) =>
    getLocalizedNeighbourhood(t, {
      countryCode: location.country.countryCode,
      citySlug: location.city.slug,
      neighbourhoodSlug: slug,
    })

  const handleSelectCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(getCityBySlug(event.target.value))
    setValue(`${name}.neighbourhoods`, [])
  }

  useEffect(() => {
    const { country, state, city } = location
    setNeighbourhoods(getCityNeighbourhoods(country.countryCode, state.stateCode, city.slug).map((n) => n.slug))
  }, [location])

  return (
    <>
      <ColumnGridItem md={2} xs={1}>
        <Dropdown
          defaultValue={defaultValue?.location ?? defaultCity}
          name={`${name}.location`}
          label={t('common-tenantPreference:leasePreferences.location.label')}
          options={getCities().map(({ city, country }) => {
            return {
              value: city.slug,
              label: getLocalizedCity(t, { countryCode: country.countryCode, citySlug: city.slug }),
            }
          })}
          onChange={handleSelectCity}
        />
      </ColumnGridItem>
      <ColumnGridItem md={2} xs={1}>
        <Autocomplete
          name={`${name}.neighbourhoods`}
          label={t('common-tenantPreference:leasePreferences.location.neighbourhood')}
          options={neighbourhoods}
          getOptionLabel={getOptionLabel}
          type='tag'
          defaultValue={defaultValue?.neighbourhoods}
        />
      </ColumnGridItem>
    </>
  )
}
