import { yup } from '@guiker/yup-util'

import { LeaseRoleNames } from '../entity'

export const createLeaseFromBookingPayloadSchema = yup.object({
  draftedAs: yup
    .mixed<LeaseRoleNames.DRAFTER | LeaseRoleNames.LESSOR>()
    .oneOf([LeaseRoleNames.DRAFTER, LeaseRoleNames.LESSOR])
    .required(),
})

export type CreateLeaseFromBookingPayloadSchema = yup.InferType<typeof createLeaseFromBookingPayloadSchema>
