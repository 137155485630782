import React from 'react'

import { useTranslation } from '@guiker/i18n'
import { SquareFootageIcon, UnitsIcon } from '@guiker/icons'
import { BuildingTypes, Unit } from '@guiker/real-estate-shared'

import { Flex } from '..'
import { IconDescription } from './iconDescription'
import { findBuildingIcon, findTotalSquareFootage } from './utils'

type MultiUnitConfigProp = {
  units: Unit[]
  buildingType: BuildingTypes
}

export const MultiUnitConfig: React.FC<MultiUnitConfigProp> = ({ buildingType, units }) => {
  const { t } = useTranslation('common-realEstate')

  const BuildingIcon = findBuildingIcon(buildingType)
  const totalSquareFootage = findTotalSquareFootage(units)

  return (
    <Flex padding={2} justifyContent='space-evenly' width='100%' flexWrap='wrap' gap={3}>
      <IconDescription
        icon={<BuildingIcon />}
        description={t(`unitConfiguration.building.${buildingType}`)}
        value={''}
      />
      <IconDescription
        icon={<UnitsIcon />}
        description={t('unitConfiguration.unit', { count: units.length })}
        value={units.length}
      />
      <IconDescription
        icon={<SquareFootageIcon />}
        description={t('unitConfiguration.squareFootage')}
        value={totalSquareFootage.toLocaleString()}
      />
    </Flex>
  )
}
