import { yup } from '@guiker/yup-util'

export const invitedGuarantorClaimsSchema = yup.object({
  tenantApplicationId: yup
    .string()
    .required()
    .when('$tenantApplicationId', (tenantApplicationId: string, schema: yup.StringSchema) =>
      tenantApplicationId
        ? schema.test('mismatch', 'tenantApplicationId', (id: string) => id === tenantApplicationId)
        : schema,
    )
    .required(),
  role: yup.string().required(),
  emailAddress: yup.string().required(),
  userId: yup.string().required(),
})

export type InvitedGuarantorClaimsSchema = yup.InferType<typeof invitedGuarantorClaimsSchema>
