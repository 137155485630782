import { yup } from '@guiker/yup-util'

export const leaseEmailUniquenessSchema = yup
  .string()
  .email()
  .when('$emails', (emails: string[], schema: yup.ArraySchema<string>) => {
    return emails?.length
      ? schema.test('lease.emailUniqueness', 'Email is not unique to lease', function (value) {
          const arrayList = emails?.filter((email) => value?.toUpperCase() === email?.toUpperCase())
          return arrayList.length === 0
        })
      : schema
  })
