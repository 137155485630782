import React from 'react'

import { Button, Display2, Display4, Flex, RouterLink, TextButton, useLayoutContext } from '@guiker/react-framework'

export type HeroBoxProps = React.PropsWithChildren & {
  title: string
  subtitle?: string
  primaryCta?: {
    path: string
    label: string
  }
  secondaryCta?: {
    path: string
    label: string
  }
}

export const HeroBox: React.FC<HeroBoxProps> = ({ children, primaryCta, secondaryCta, subtitle, title }) => {
  const { isAtMostTablette } = useLayoutContext()

  return (
    <Flex flexDirection='column' p={isAtMostTablette ? 4 : 7}>
      <Flex flexDirection='column' gap={isAtMostTablette ? 5 : 7}>
        <Flex flexDirection='column' gap={isAtMostTablette ? 2 : 3}>
          <Display2 mb={0}>{title}</Display2>

          {subtitle && (
            <Display4 mb={0} color={60}>
              {subtitle}
            </Display4>
          )}
        </Flex>
        {children}
        {(primaryCta || secondaryCta) && (
          <Flex flexDirection={isAtMostTablette ? 'column' : 'row'} alignItems='center' gap={4}>
            {primaryCta && (
              <RouterLink to={primaryCta.path} fullWidth>
                <Button size='large' fullWidth>
                  {primaryCta.label}
                </Button>
              </RouterLink>
            )}
            {secondaryCta && (
              <RouterLink to={secondaryCta.path}>
                <TextButton size='large'>{secondaryCta.label}</TextButton>
              </RouterLink>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
