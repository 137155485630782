import React from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { Flex } from '@guiker/react-framework'

import { PayInMethodLabel } from '../PayInMethodLabel'

type Prop = {
  paymentMethod: PayInMethod
  renderViewAction?: (payInMethodId: string) => React.ReactNode
}

const PaymentMethod: React.FC<Prop> = ({ paymentMethod, renderViewAction }) => {
  if (!paymentMethod) return

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <PayInMethodLabel payInMethod={paymentMethod} />
      {!!renderViewAction && renderViewAction(paymentMethod.id)}
    </Flex>
  )
}

export { PaymentMethod }
