import { routesBuilder } from '@guiker/shared-framework'

import { FundingAccount, FundingAccountBalance, WithUser } from '../../../entity'
import { paths } from '../paths'
import { routes as depositRoutes } from './deposits'
import { createFundingAccountSchema, exportSchema, getTotalBalanceSchema } from './schemas'
import { routes as withdrawalRoutes } from './withdrawals'

const builder = routesBuilder<FundingAccount>()({ basePath: paths.fundingAccounts.path })
const userNested = routesBuilder<FundingAccount>()({ basePath: paths.users.withId.fundingAccount.path })

export const routes = {
  ...depositRoutes,
  ...withdrawalRoutes,
  createFundingAccount: builder.buildPost({
    payloadSchema: createFundingAccountSchema,
  }),
  readFundingAccountByUser: userNested.buildGet({}),
  getTotalFundingAccountBalance: builder.buildGet({
    path: '/total-balance',
    queryParamsSchema: getTotalBalanceSchema,
    response: null as FundingAccountBalance,
    responseIsArray: true,
  }),
  exportWithBalance: builder.buildGet({
    path: '/export',
    queryParamsSchema: exportSchema,
    response: null as WithUser<FundingAccountBalance>,
    responseIsArray: true,
  }),
}
