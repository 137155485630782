import React, { useCallback, useMemo } from 'react'

import { buildInvoiceMonetaryDetails, Invoice, InvoiceStatus, Item } from '@guiker/payment-shared'
import { Divider, Flex, P, PageSection4, PSmaller } from '@guiker/react-framework'
import { capitalize, money } from '@guiker/shared-framework'

import { useInvoiceContext } from '../../../hooks'
import { useT } from '../../../i18n'
import { InvoiceItem } from './InvoiceItem'

type Props = {
  invoice: Invoice
  itemComponent?: {
    AddItem: React.FC
    RemoveItem: React.FC<{ item: Item }>
  }
}

export const ItemBreakdown: React.FC<Props> = ({ itemComponent, invoice }) => {
  const { taxes } = useInvoiceContext()
  const { tShared } = useT({ entity: 'breakdown' })

  const { totalAmount, taxInfo } = useMemo(() => {
    return buildInvoiceMonetaryDetails({ invoice, taxes })
  }, [...invoice.items, invoice.id])

  const isEditable = itemComponent && invoice.status === InvoiceStatus.DRAFTED
  const { AddItem, RemoveItem } = { ...itemComponent }

  const amountToString = useCallback(
    (amount: number, onlySymbol = true) => {
      return money.fromAmount(amount, invoice.currency).toString(onlySymbol ? 'onlySymbol' : true)
    },
    [invoice.currency],
  )

  const buildItemLabel = useCallback(
    (item: Item) => {
      if (item.quantity === 1) return capitalize(item.label)

      return (
        <Flex gap={1} alignItems='center'>
          <P>{capitalize(item.label)}</P>
          <PSmaller color={60}>{`[ ${amountToString(item.pricePerUnit)} x ${item.quantity} ]`}</PSmaller>
        </Flex>
      )
    },
    [invoice.currency],
  )

  return (
    <PageSection4 hasDivider title={tShared('header')} topActions={isEditable ? <AddItem /> : undefined}>
      <Flex flexDirection='column' gap={1}>
        {invoice.items.map((item) => (
          <Flex fullWidth gap={1} alignItems='center'>
            <InvoiceItem
              key={item.id}
              label={buildItemLabel(item)}
              value={amountToString(item.pricePerUnit * item.quantity)}
            />
            {isEditable && <RemoveItem item={item} />}
          </Flex>
        ))}
        <Divider />
        <InvoiceItem label={tShared('taxes', taxInfo)} value={amountToString(totalAmount.tax)} />
        <InvoiceItem
          label={tShared('processingFee', { transactionFeePercentage: `${totalAmount.transactionFee.rate * 100}%` })}
          value={amountToString(totalAmount.transactionFee.amount)}
        />
        <InvoiceItem bold label={tShared('totalAmount')} value={amountToString(totalAmount.final, false)} />
      </Flex>
    </PageSection4>
  )
}
