import { StockOrder, StockOrderStatus } from '@guiker/propsharing-shared'

export const getStockOrderStatusColor = (stockOrder: Pick<StockOrder, 'status'>) => {
  return stockOrder.status === StockOrderStatus.FULFILLED
    ? 'success'
    : stockOrder.status === StockOrderStatus.PENDING_SHARE_ISSUANCE
    ? 'positive'
    : stockOrder.status === StockOrderStatus.CANCELLED
    ? 'cancelled'
    : stockOrder.status === StockOrderStatus.PENDING_EXECUTION_COMPLETION
    ? 'warning'
    : 'info'
}
