import { useContext } from 'react'

import { TenantPreferenceContext } from '../context'

export const useTenantPreferenceContext = () => {
  const context = useContext(TenantPreferenceContext)

  if (context === undefined) {
    throw new Error('useTenantPreferenceContext can only be used inside TenantPreferenceContext')
  }

  return context
}
