import { Payment } from '@guiker/base-entity'
import { flatMap, optionalConcat, uniq, uniqBy } from '@guiker/lodash'
import { Currency, currency as baseCurrency } from '@guiker/money'
import { fromTaxes } from '@guiker/tax'
import { MarkRequired } from '@guiker/ts-utils'

import { ACH_TRANSACTION_RATE, CREDIT_CARD_TRANSACTION_RATE, Invoice, Item, PayInMethodType, Tax } from '../entity'

export type InvoiceMonetaryDetails = {
  currency: Currency
  nonTaxableAmount: number
  taxableAmount: number
  taxes: Tax[]
  taxInfo: {
    taxPercentage: string
    taxTypes: string
  }
  totalAmount: {
    subTotal: number
    tax: number
    final: number
    transactionFee: {
      rate: number
      amount: number
    }
  }
}

export const buildInvoiceMonetaryDetails = (args: {
  invoice: MarkRequired<Partial<Invoice>, 'items' | 'currency' | 'transactions'>
  transactionFeeRate?: number
  taxes?: Tax[]
}): InvoiceMonetaryDetails => {
  const { invoice, taxes = [] } = args
  let nonTaxableAmount = 0
  let taxableAmount = 0
  let taxAmount = 0
  let appliedTaxes = [] as Tax[]

  invoice.items.forEach((item: Item) => {
    if (!item.taxes || item.taxes.length === 0) {
      nonTaxableAmount += item.pricePerUnit * item.quantity
    } else {
      taxableAmount += item.pricePerUnit * item.quantity
      taxAmount += item.taxes.reduce((totalTaxAmount, { taxId, rates }) => {
        appliedTaxes.push(taxes.find((t) => t.taxId === taxId))
        return totalTaxAmount + rates.reduce((subTaxAmount, { amount }) => subTaxAmount + amount, 0)
      }, 0)
    }
  })
  appliedTaxes = uniqBy(appliedTaxes, 'taxId')

  const nonRefundTransactions = invoice.transactions.filter((transaction) => transaction.intent !== 'REFUND')
  const lastNonRefundTransaction = nonRefundTransactions[nonRefundTransactions.length - 1]

  const subTotal = nonTaxableAmount + taxableAmount + taxAmount
  const transactionFeeRate =
    args.transactionFeeRate ??
    (lastNonRefundTransaction?.details.payInMethodType === PayInMethodType.CREDIT_CARD
      ? CREDIT_CARD_TRANSACTION_RATE
      : ACH_TRANSACTION_RATE)
  const transactionFee = subTotal * transactionFeeRate

  return {
    currency: baseCurrency[invoice.currency],
    nonTaxableAmount,
    taxableAmount,
    taxes: appliedTaxes,
    taxInfo: {
      taxPercentage: `${fromTaxes(appliedTaxes).toPercentage()}`,
      taxTypes: `${flatMap(uniq(Object.values(appliedTaxes) || []), (t) => t?.rates)
        .map((r) => r?.type)
        .join(' + ')}`,
    },
    totalAmount: {
      subTotal,
      tax: taxAmount,
      transactionFee: {
        rate: transactionFeeRate,
        amount: transactionFee,
      },
      final: subTotal + transactionFee,
    },
  }
}

export const buildPaymentInfo = (invoice: Invoice): Payment.PaymentInfo => {
  const { customer, items, info } = invoice

  return {
    payerName: optionalConcat([customer.firstName, customer.lastName], ' '),
    payerEmailAddress: customer.emailAddress,
    payerUserId: customer.id,
    label: info?.label ?? items[0]?.label ?? '',
    description: info?.description,
  }
}
