import React, { useContext, useEffect, useState } from 'react'

import { getPaymentVerifications, PaymentVerification } from '@guiker/booking-shared'

import { useBookingContext } from '../hooks'

type ContextProps = {
  paymentVerifications: PaymentVerification[]
  setPaymentVerifications: (value: PaymentVerification[]) => void
}

const PaymentVerificationContext = React.createContext<ContextProps>(undefined)

const PaymentVerificationContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { booking } = useBookingContext()
  const [paymentVerifications, setPaymentVerifications] = useState(getPaymentVerifications(booking))
  const value = { paymentVerifications, setPaymentVerifications }

  useEffect(() => {
    setPaymentVerifications(getPaymentVerifications(booking))
  }, [booking])

  return <PaymentVerificationContext.Provider value={value}>{children}</PaymentVerificationContext.Provider>
}

const usePaymentVerificationContext = () => {
  const context = useContext(PaymentVerificationContext)

  if (context === undefined) {
    throw new Error('usePaymentVerificationContext can only be used inside PaymentVerificationContextProvider')
  }

  return context
}

export { PaymentVerificationContextProvider, usePaymentVerificationContext }
