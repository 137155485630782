import { AssetGroup } from '@guiker/asset-shared'

import { SUPPORTING_DOCUMENT_TYPES, SupportingDocument } from '../entity'

export const buildAssetGroups = (supportingDocuments: SupportingDocument[], prefix: string): AssetGroup[] => {
  return SUPPORTING_DOCUMENT_TYPES.map((documentType) => {
    const groupIndex = supportingDocuments?.findIndex((sd) => sd.type === documentType)

    if (!supportingDocuments || groupIndex < 0) {
      return {
        type: documentType,
        assets: [],
      }
    }

    const group = supportingDocuments[groupIndex]

    return {
      ...group,
      assets:
        group.assets?.map((asset, index) => ({
          ...asset,
          name: `${prefix}[${groupIndex}].assets[${index}]`,
        })) || [],
    }
  })
}
