import { StatusCodes } from '@guiker/http'
import { routesBuilder } from '@guiker/shared-framework'

import { Stock, StockOrderStats, StockWithOrders } from '../../entity'
import { paths } from './paths'
import {
  createSeriesSchema,
  createStockSchema,
  delistStockSchema,
  updateClosedSeriesSchema,
  updateSeriesSchema,
  updateStockSchema,
} from './schemas'

const {
  buildGet: buildIdNestedGet,
  buildPut: buildIdNestedPut,
  buildPost: buildIdNestedPost,
} = routesBuilder<Stock>()({
  basePath: paths.stocks.withId.path,
})

const { buildPost, buildGet } = routesBuilder<Stock>()({
  basePath: paths.stocks.path,
})

export const routes = {
  delistStock: buildIdNestedPut({
    path: '/delist',
    payloadSchema: delistStockSchema,
  }),
  getStatsOverviewByStockId: buildIdNestedGet({
    path: '/stats-overview',
    response: null as StockOrderStats,
    responseIsArray: true,
  }),
  getStatsOverview: buildGet({
    path: '/stats-overview',
    response: null as StockOrderStats,
    responseIsArray: true,
  }),
  readOneStock: buildIdNestedGet({
    path: '/read',
    response: null as StockWithOrders,
  }),
  updateStock: buildIdNestedPut({
    payloadSchema: updateStockSchema,
  }),
  createStock: buildPost({
    successStatusCode: StatusCodes.CREATED,
    payloadSchema: createStockSchema,
  }),
  readAllStocks: buildGet({
    paginated: true,
  }),
  closeSeries: buildIdNestedPost({
    path: `/series/:seriesSlug/close`,
  }),
  updateSeries: buildIdNestedPut({
    path: `/series/:seriesSlug`,
    payloadSchema: updateSeriesSchema,
  }),
  updateClosedSeries: buildIdNestedPut({
    path: `/series/:seriesSlug/closed`,
    payloadSchema: updateClosedSeriesSchema,
  }),
  createSeries: buildIdNestedPost({
    path: `/series/new`,
    payloadSchema: createSeriesSchema,
  }),
}
