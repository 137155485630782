import React from 'react'

import { Transfer } from '@guiker/payout-shared'
import { Chip } from '@guiker/react-framework'

type TransferStatusChipProps = {
  transfer: Transfer
}

export const TransferStatusChip: React.FC<TransferStatusChipProps> = ({ transfer }) => {
  const color = transfer.status === 'PAID' ? 'success' : transfer.status === 'FAILED' ? 'alert' : 'info'

  return (
    <Chip size='small' color={color} variant='outlined'>
      {transfer.status}
    </Chip>
  )
}
