import React, { useEffect } from 'react'

import { DirectedSource, usePromotionContext } from '@guiker/promotion-context'
import {
  ApiForm,
  Button,
  Chip,
  DatePicker,
  Dropdown,
  Flex,
  getLocalizedCityFromLocation,
  RouterLink,
  TwoColumnsGridLayout,
  useFormContext,
  useLayoutContext,
  useWatch,
} from '@guiker/react-framework'
import { buildSearchListingsUrl } from '@guiker/rental-listing-components'
import { getLocationService } from '@guiker/router'
import { DateTime, toISODate } from '@guiker/shared-framework'

import { useT } from '../../../i18n'
import { HeroBox } from '../HeroBox'

export const RentHeroBoxFormContent: React.FC = () => {
  const { isAtMostTablette } = useLayoutContext()
  const { t, tBase, tMain } = useT({ screenName: 'screens.home.hero.rent' })
  const { directedSource } = usePromotionContext()
  const { setValue } = useFormContext()
  const desination = useWatch({ name: 'destination' })
  const moveInDate = useWatch({ name: 'moveInDate' })
  const locationService = getLocationService()
  const locations = locationService.getCities()

  const destination = locationService.getCityBySlug(desination)
  const roomRental = directedSource === DirectedSource.SPLITSPOT && destination.city.slug === 'boston'
  const searchUrl = buildSearchListingsUrl(destination, {
    moveInDate,
    rentalTypes: roomRental ? ['PRIVATE_ROOM'] : [],
    zoom: destination.city.zoom,
  })

  useEffect(() => {
    directedSource === DirectedSource.SPLITSPOT && setValue('destination', 'boston')
  }, [directedSource])

  return (
    <Flex flexDirection='column' gap={4}>
      <TwoColumnsGridLayout gap={isAtMostTablette ? 2 : 4}>
        <Dropdown
          fullWidth
          name='destination'
          label={tMain('destination')}
          options={locations.map((location) => ({
            value: location.city.slug,
            label: getLocalizedCityFromLocation(t, location),
            endAdornment: getLocationService().newCities.includes(location.city.slug) && (
              <Chip color='info'>{tBase('actions.new')}</Chip>
            ),
          }))}
        />
        <DatePicker
          name='moveInDate'
          label={tMain('moveInDate')}
          defaultValue={toISODate(DateTime.local())}
          minDate={toISODate(DateTime.local())}
        />
      </TwoColumnsGridLayout>
      <RouterLink isExternalLink to={searchUrl}>
        <Button size='large' fullWidth>
          {tMain('cta')}
        </Button>
      </RouterLink>
    </Flex>
  )
}

export const RentHeroBox: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.home.hero.rent' })
  const { defaultCity } = getLocationService()

  return (
    <HeroBox title={tMain('title')}>
      <ApiForm formName='HomeScreen' formOptions={{ defaultValues: { destination: defaultCity } }}>
        <RentHeroBoxFormContent />
      </ApiForm>
    </HeroBox>
  )
}
