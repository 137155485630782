import React from 'react'

import { webappRoutes } from '@guiker/main-webapp-shared'

import { useT } from '../../../i18n'
import { HeroBox } from '../HeroBox'

export const ServicesHeroBox: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.home.hero.services' })

  return (
    <HeroBox
      title={tMain('title')}
      subtitle={tMain('subtitle')}
      primaryCta={{
        label: tMain('cta'),
        path: webappRoutes.Services.learnMore().path,
      }}
    />
  )
}
