import { yup } from '@guiker/shared-framework'

import { DisputeStatus } from '../../../../../entity/invoice/invoice'
import { invoiceStatusSchema } from '../../../schemas'
import { searchStringSchema } from '../../../schemas/search-string-schema'

export const readAllBatchChargeInvoicesSchemas = invoiceStatusSchema.concat(searchStringSchema).concat(
  yup.object({
    disputeStatuses: yup
      .array()
      .of<DisputeStatus>(yup.mixed<DisputeStatus>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
  }),
)
