import { AuthLevel, buildPaths } from '@guiker/shared-framework'

export const paths = {
  ...buildPaths(
    {
      invoice: {},
    },
    AuthLevel.ADMIN,
  ),
}
