import { Actions as BaseActions, triggerBuilder } from '@guiker/trigger'

import { Applicant, Booking } from '../../entity'

const baseTrigger = {
  context: 'BOOKING',
  targetType: 'BOOKING',
}

type TimerPayload = { timer: Booking['timer'] }

type AutoApproveRoommate = { applicant: Applicant }

export const actions = {
  AUTOAPPROVE_ROOMMATE_INTRO: {
    type: 'AUTOAPPROVE_ROOMMATE_INTRO',
    payload: {} as AutoApproveRoommate,
  },
  EXPIRE: {
    type: 'EXPIRE',
    payload: {} as TimerPayload,
  },
  MARK_6_HOURS_BEFORE_EXPIRATION: {
    type: 'MARK_6_HOURS_BEFORE_EXPIRATION',
    payload: {} as TimerPayload,
  },
  MARK_1_HOUR_BEFORE_EXPIRATION: {
    type: 'MARK_1_HOUR_BEFORE_EXPIRATION',
    payload: {} as TimerPayload,
  },
}

export const Triggers = triggerBuilder(baseTrigger).buildTriggersFromActions(actions)
export type Actions = BaseActions<typeof actions>
export type ActionTypes = keyof typeof actions
