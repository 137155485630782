import React from 'react'

import { Payment } from '@guiker/base-entity'
import { CREDIT_CARD_TRANSACTION_RATE } from '@guiker/payment-shared'
import { Box, Flex, P, useTranslation } from '@guiker/react-framework'

import { PayInMethodLabel } from '../PayInMethodLabel'

const PaymentMethodCards: React.FC<{
  paymentMethods: Payment.PayInMethod[]
}> = ({ paymentMethods }) => {
  const { t } = useTranslation('office-paymentMethods')
  const isCreditCard = (type: Payment.PayInMethodType) => type === Payment.PayInMethodType.CREDIT_CARD

  return (
    <Flex flexWrap='wrap' gap={2} mt={2}>
      {paymentMethods.map((paymentMethod, index) => {
        const { type } = paymentMethod

        return (
          <Box key={index.toString()} minWidth={328}>
            <PayInMethodLabel payInMethod={paymentMethod} />
            <P color={30}>
              {isCreditCard(type)
                ? t('processingFee', {
                    processingFeePercentage: `${CREDIT_CARD_TRANSACTION_RATE * 100}%`,
                  })
                : t('noProcessingFee')}
            </P>
          </Box>
        )
      })}
    </Flex>
  )
}

export { PaymentMethodCards }
