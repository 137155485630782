import { TypeOf, yup } from '@guiker/yup-util'

import { leaseTerm } from './lease-term'
import { rent } from './rent'

export const leaseTermAndRent = yup.object({
  leaseTerm: leaseTerm,
  rent: rent,
})

export type LeaseTermAndRent = TypeOf<typeof leaseTermAndRent>
