import React from 'react'

import { PayoutMethod, payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { Flex, Payments, PBold } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { PayoutMethodIcon } from '../PayoutMethodIcon'

type PayoutMethodProps = {
  payoutMethod: PayoutMethod
  isPaidOffline?: boolean
}

export const PayoutMethodLabel: React.FC<PayoutMethodProps> = ({ payoutMethod, isPaidOffline }) => {
  const label = payoutMethodInfoBuilder(payoutMethod)?.getDisplay()
  const { tMain } = useT({})

  return (
    <Flex gap={2}>
      {isPaidOffline ? <Payments.Bank.BankIcon /> : <PayoutMethodIcon payoutMethod={payoutMethod} />}
      <PBold mb={0}>{isPaidOffline ? tMain('offline') : label}</PBold>
    </Flex>
  )
}
