import { CurrencyISO } from '@guiker/money'

import { SupportedCountries } from '../base'
import { Address } from './address'
import {
  AccountHolderType,
  CanadianBankPayoutMethod,
  Capabilities,
  PayoutMethod,
  PayoutMethodType,
  PayoutMethodWithPayouts,
  PayoutTransactionType,
  USBankPayoutMethod,
} from './payout-method'
import { PayoutProviderName } from './payout-provider'

type Args = {
  userId?: string
  payoutMethodType?: PayoutMethodType
}

const isSelectedCapabilitiesOnly = (capabilities: Capabilities[], capability: Capabilities) => {
  if (!capabilities?.length) return false
  return (capabilities ?? []).every((c) => c === capability)
}
const isSelectedCapabilitiesIncluded = (capabilities: Capabilities[], capability: Capabilities) => {
  if (!capabilities?.length) return false
  return (capabilities ?? []).some((c) => c === capability)
}

const checkCapabilities = {
  hasOnly: {
    investment: (capabilities: Capabilities[]) => isSelectedCapabilitiesOnly(capabilities, Capabilities.INVESTMENT),
    rental: (capabilities: Capabilities[]) => isSelectedCapabilitiesOnly(capabilities, Capabilities.RENTAL),
  },
  includes: {
    investment: (capabilities: Capabilities[]) => isSelectedCapabilitiesIncluded(capabilities, Capabilities.INVESTMENT),
    rental: (capabilities: Capabilities[]) => isSelectedCapabilitiesIncluded(capabilities, Capabilities.RENTAL),
  },
}

type ConfigOption = {
  capabilities?: Capabilities[]
  isManualInput?: boolean
}

const baseCanadianRequiredDocumentTypes = ['governmentId', 'voidCheck']
const documentTypes = [...baseCanadianRequiredDocumentTypes, 'deedOrTax', 'supportingDocs']
const baseCanadianRequiredProviders = [PayoutProviderName.STRIPE, PayoutProviderName.TRANSFERWISE]

const canadianProviderByCapabilities = {
  [Capabilities.RENTAL]: baseCanadianRequiredProviders,
  [Capabilities.INVESTMENT]: [PayoutProviderName.TRANSFERWISE],
}

const canadianRequirements = {
  documents: {
    manual: {
      [Capabilities.RENTAL]: [...baseCanadianRequiredDocumentTypes, 'deedOrTax'],
      [Capabilities.INVESTMENT]: baseCanadianRequiredDocumentTypes,
    },
    connector: {
      [Capabilities.RENTAL]: [...baseCanadianRequiredDocumentTypes, 'deedOrTax'],
      [Capabilities.INVESTMENT]: [] as string[],
    },
  },
  providers: {
    manual: canadianProviderByCapabilities,
    connector: canadianProviderByCapabilities,
  },
  documentTypes: {
    manual: {
      [Capabilities.RENTAL]: documentTypes,
      [Capabilities.INVESTMENT]: baseCanadianRequiredDocumentTypes,
    },
    connector: {
      [Capabilities.RENTAL]: documentTypes,
      [Capabilities.INVESTMENT]: [] as string[],
    },
  },
}

const findCanadianRequirement = (
  capabilities: Capabilities[],
  isManualInput: boolean,
  requirement: 'documents' | 'providers' | 'documentTypes',
): string[] => {
  if (!capabilities?.length) capabilities = [Capabilities.RENTAL, Capabilities.INVESTMENT]
  return capabilities.reduce((acc, cur) => {
    const req = canadianRequirements[requirement][isManualInput ? 'manual' : 'connector'][cur] as
      | string[]
      | PayoutProviderName[]
    req.map((r) => {
      if (!acc.includes(r)) {
        acc.push(r)
      }
    })
    return acc
  }, [])
}

const getPayoutConfigs = (payoutMethodType: PayoutMethodType, options?: ConfigOption) => {
  const { capabilities = [], isManualInput = false } = { ...options }
  switch (payoutMethodType) {
    case PayoutMethodType.CANADIAN_BANK_ACCOUNT:
      return {
        type: payoutMethodType,
        transactionType: PayoutTransactionType.EFT,
        country: SupportedCountries.Canada,
        currency: CurrencyISO.CAD,
        documentTypes: findCanadianRequirement(capabilities, isManualInput, 'documentTypes'),
        requiredDocumentTypes: findCanadianRequirement(capabilities, isManualInput, 'documents'),
        isManualInputAllowed: true,
        requiredProviders: findCanadianRequirement(capabilities, isManualInput, 'providers'),
      }
    case PayoutMethodType.US_BANK_ACCOUNT:
      return {
        type: payoutMethodType,
        transactionType: PayoutTransactionType.ACH,
        requiredProviders: [PayoutProviderName.STRIPE],
        country: SupportedCountries.UnitedStates,
        currency: CurrencyISO.USD,
        isManualInputAllowed: false,
        requiredDocumentTypes: ['governmentId'],
        documentTypes: ['governmentId', 'supportingDocs'],
      }
  }
}

const getRequiredProviders = (payoutMethod: PayoutMethod) => {
  return payoutMethod.providers.filter((p) =>
    getPayoutConfigs(payoutMethod.type, { capabilities: payoutMethod.capabilities }).requiredProviders?.includes(
      p?.name,
    ),
  )
}

const initializeAddress = (payoutMethodType: PayoutMethodType): Address => ({
  country: getPayoutConfigs(payoutMethodType).country,
  state: undefined,
  city: undefined,
  streetNumber: undefined,
  streetName: undefined,
  suiteNumber: undefined,
  postalCode: undefined,
  yearsAtAddress: 3,
})

const initializePayoutMethod = ({
  userId,
  payoutMethodType = PayoutMethodType.CANADIAN_BANK_ACCOUNT,
}: Args = {}): PayoutMethodWithPayouts => {
  const attributes = {
    userId,
    providers: [],
    verificationDocuments: [],
    status: 'CREATED',
    accountHolderType: AccountHolderType.INDIVIDUAL,
    accountHolder: {
      firstName: undefined,
      lastName: undefined,
      dateOfBirth: undefined,
      phoneNumber: undefined,
      emailAddress: undefined,
      addresses: [initializeAddress(payoutMethodType)],
    },
    consent: {
      ipAddress: undefined,
      date: undefined,
    },
    details: {},
    payouts: [],
  } as Partial<PayoutMethodWithPayouts>

  switch (payoutMethodType) {
    case PayoutMethodType.CANADIAN_BANK_ACCOUNT:
      return {
        ...attributes,
        currency: CurrencyISO.CAD,
        type: PayoutMethodType.CANADIAN_BANK_ACCOUNT,
        details: {
          institutionNumber: undefined,
          transitNumber: undefined,
          accountNumber: undefined,
          accountType: undefined,
        },
      } as PayoutMethodWithPayouts
    case PayoutMethodType.US_BANK_ACCOUNT:
      return {
        ...attributes,
        currency: CurrencyISO.USD,
        type: PayoutMethodType.US_BANK_ACCOUNT,
        details: {
          routingNumber: undefined,
          accountNumber: undefined,
          accountType: undefined,
        },
      } as PayoutMethodWithPayouts
    default:
      return undefined
  }
}

const buildBankInformation = (payoutMethod: Partial<PayoutMethod>) => {
  let details
  switch (payoutMethod?.type) {
    case PayoutMethodType.CANADIAN_BANK_ACCOUNT:
      details = (payoutMethod.details || {
        institutionNumber: undefined,
        transitNumber: undefined,
        accountNumber: undefined,
        accountType: 'checking',
      }) as CanadianBankPayoutMethod['details']
      break
    case PayoutMethodType.US_BANK_ACCOUNT:
      details = (payoutMethod.details || {
        routingNumber: undefined,
        accountNumber: undefined,
        accountType: 'checking',
      }) as USBankPayoutMethod['details']
      break
    default:
      return {}
  }

  return {
    isManualInput:
      !payoutMethod.verification &&
      getPayoutConfigs(payoutMethod.type, { capabilities: payoutMethod.capabilities }).isManualInputAllowed,
    verification: payoutMethod.verification,
    details,
  }
}

const getSupportedProviders = ({ payoutMethodType }: { payoutMethodType: PayoutMethodType }): PayoutProviderName[] => {
  if (payoutMethodType === PayoutMethodType.CANADIAN_BANK_ACCOUNT) {
    return [PayoutProviderName.STRIPE, PayoutProviderName.TRANSFERWISE]
  } else if (payoutMethodType === PayoutMethodType.US_BANK_ACCOUNT) {
    return [PayoutProviderName.STRIPE]
  } else {
    return []
  }
}

const findOperativeProviders = <P extends { providers: PayoutMethod['providers'] }>(
  payoutMethod: P,
  providerName?: PayoutProviderName,
) => {
  return payoutMethod.providers.filter(
    (p) => p.status !== 'TERMINATED' && (providerName ? p.name === providerName : true),
  )
}

export {
  initializeAddress,
  initializePayoutMethod,
  buildBankInformation,
  findOperativeProviders,
  getSupportedProviders,
  getPayoutConfigs,
  getRequiredProviders,
  checkCapabilities,
}
