import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { throttle } from '@guiker/lodash'
import { Camera } from '@mediapipe/camera_utils'
import { FaceMesh, Options, Results } from '@mediapipe/face_mesh'
export { Results as FaceDetectionResults }

type UseFaceDetectionProps = {
  enabled: boolean
  options?: Options
  onResults?: (results: Results) => void | Promise<void>
  onSuccess?: (results: Results) => void | Promise<void>
  webcamRef: MutableRefObject<{ video: HTMLVideoElement }>
}

export const useFaceDetection = ({ enabled, onResults, onSuccess, webcamRef, options }: UseFaceDetectionProps) => {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(true)
  const camera = useRef<Camera>()
  const faceMesh = useRef<FaceMesh>()

  useEffect(() => {
    if (!enabled || faceMesh.current) return

    faceMesh.current = new FaceMesh({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
    })

    faceMesh.current.setOptions(options)
    faceMesh.current.onResults((results) => {
      onResults?.(results)
      results.multiFaceLandmarks.length > 0 && onSuccess?.(results)
    })

    setScriptIsLoaded(true)
  }, [onResults, onSuccess, options, enabled])

  useEffect(() => {
    if (!faceMesh.current || !webcamRef?.current || camera.current || !scriptIsLoaded) return

    setTimeout(() => {
      const mediaSrc = webcamRef.current?.video

      camera.current = new Camera(mediaSrc, {
        width: mediaSrc?.videoWidth,
        height: mediaSrc?.videoHeight,
        onFrame: throttle(async () => {
          await faceMesh.current.send({ image: mediaSrc })
        }, 1000),
      })

      camera?.current?.start()
    }, 4000)

    return () => {
      camera?.current?.stop()
    }
  }, [faceMesh.current, webcamRef.current, onResults, onSuccess, options, enabled, scriptIsLoaded])
}
