import { Listing as BaseListingEntities, WithTimestamps } from '@guiker/base-entity'
import { RentalType } from '@guiker/rental-listing-shared'

type FloorPlans = BaseListingEntities.FloorPlans

export type LeasePreferences = {
  rentalType: RentalType | null
  location: string
  neighbourhoods?: string[]
  budget?: number //max budget
  floorPlan?: FloorPlans
  leaseStart?: string
  leaseDuration?: number
  furniture?: boolean | null
}

export type Utilities = BaseListingEntities.Utilities

export enum ApplianceTypes {
  airConditioner = 'airConditioner',
  balcony = 'balcony',
  dishwasher = 'dishwasher',
  freezer = 'freezer',
  fridge = 'fridge',
  oven = 'oven',
  washer = 'washer',
  petsAllowedSmall = 'petsAllowedSmall', //deprecate
  petsAllowedLarge = 'petsAllowedLarge', //deprecate
}

export type Appliances = BaseListingEntities.UnitAppliances & BaseListingEntities.KitchenAppliances

export type UnitPreferences = {
  utilities?: Utilities
  appliances?: Appliances
  petsAllowed: boolean
}

export enum Parking {
  indoor = 'indoorParking',
  outdoor = 'outdoorParking',
}

type BuildingAmenityExcluded =
  | 'bikeParking'
  | 'communalOutdoorSpace'
  | 'laundryRoom'
  | 'concierge'
  | 'indoorParking'
  | 'outdoorParking'
  | 'security'

export type BuildingPreferences = Omit<BaseListingEntities.BuildingAmenities, BuildingAmenityExcluded> & {
  parking: Parking | null
}

export type TenantPreference = WithTimestamps<{
  id: string
  userId: string
  emailNotificationEnabled: boolean
  leasePreferences: LeasePreferences
  unitPreferences: UnitPreferences
  buildingPreferences: BuildingPreferences
}>

const { FloorPlans } = BaseListingEntities
export { FloorPlans }
