import { yup } from '@guiker/yup-util'

import { Action, DryRunResult } from '../../../entity'

const CSVRentalOptionConfirmSchema = yup.object({
  csvIndex: yup.number().required(),
  listingId: yup.string().required(),
  rentalOptionId: yup.string().nullable(),
  inventoryId: yup.string().nullable(),
  unitNumber: yup.string().nullable(),
  price: yup.number().min(0).nullable(),
  availableDate: yup.string().nullable(),
  status: yup.string().nullable(),
  isFurnished: yup.boolean().nullable(),
  additionalParkingCount: yup.number().nullable(),
  bedroomCount: yup.number().nullable(),
  bathroomCount: yup.number().nullable(),
  kitchenCount: yup.number().nullable(),
  livingroomCount: yup.number().nullable(),
  error: yup.object({
    message: yup.string(),
    statusCode: yup.number(),
    errorCode: yup.string(),
  }),
  action: yup.mixed().oneOf(Object.values(Action)).required(),
  amenities: yup
    .object({
      electricity: yup.boolean(),
      airConditioner: yup.boolean(),
      washer: yup.boolean(),
      refrigerator: yup.boolean(),
      balcony: yup.boolean(),
      oven: yup.boolean(),
      smallPets: yup.boolean(),
      largePets: yup.boolean(),
      hotWater: yup.boolean(),
      heating: yup.boolean(),
      wifi: yup.boolean(),
      dishwasher: yup.boolean(),
    })
    .required(),
})

export const bulkUpdateConfirmSchema = yup.array<DryRunResult>(CSVRentalOptionConfirmSchema).required()

export type BulkUpdateConfirmSchema = yup.InferType<typeof bulkUpdateConfirmSchema>
