import * as React from 'react'

import { CardWithMedia, clsx, Flex, makeStyles } from '@guiker/components-library'
import { buildNestedListingRoutes, PropertySaleListing } from '@guiker/property-sale-shared'
import { RouterLink } from '@guiker/react-framework'
import { Project } from '@guiker/real-estate-shared'

import { CardAddressComponent } from '../CardAddressComponent'
import { BathroomCountLabel, BedroomCountLabel, SquareFootageLabel } from '../CardLabelWithIcon'
import { CardPriceComponent } from '../CardPriceComponent'

const useStyles = makeStyles(
  {
    anchor: {
      width: '100%',
      maxWidth: 340,
      height: '100%',
    },
  },
  { name: 'PropertySaleListingCard' },
)

export type ResidentialPropertySaleListingCardProps = {
  className?: string
  size?: 'small' | 'medium'
  listing: PropertySaleListing
  project?: Project
  shadow?: boolean
}

export const ResidentialPropertySaleListingCard: React.FC<ResidentialPropertySaleListingCardProps> = ({
  className,
  listing,
  shadow = false,
  size = 'medium',
}) => {
  const classes = useStyles()
  const unit = listing.property.units?.[0]

  return (
    <RouterLink to={buildNestedListingRoutes(listing).listing} className={clsx(className, classes.anchor)}>
      <CardWithMedia
        media={{ images: listing.pictures as { url: string }[] }}
        condensed={size === 'small'}
        shadow={shadow}
      >
        <Flex flexDirection='column' gap={3}>
          <Flex gap={1}>
            <CardAddressComponent listing={listing} size={size} />
            <CardPriceComponent listing={listing} size={size} />
          </Flex>
          <Flex gap={3} justifyContent='center'>
            <BedroomCountLabel unit={unit} />
            <BathroomCountLabel unit={unit} />
            <SquareFootageLabel unit={unit} />
          </Flex>
        </Flex>
      </CardWithMedia>
    </RouterLink>
  )
}
