import React, { useEffect, useState } from 'react'

import { InvitationMeta, invitationMetaScheme, isAuthenticationClaims } from '@guiker/authentication-shared'
import {
  Flex,
  H2,
  makeStyles,
  OneThirdTwoThirdsColumnsGridLayout,
  Redirect,
  safelyGetWindow,
  TakeOver,
  useLocationQuery,
  useNavigate,
} from '@guiker/react-framework'
import { sleep } from '@guiker/shared-framework'
import { buildUrl } from '@guiker/url'

import { ActivateAccountForm, FullScreenSpinner, Link, LogInForm, Note, SignUpForm } from '../../components'
import { useAuthenticationContext, useClaimsAuthenticationContext, useConfig } from '../../hooks'
import { useTranslation } from '../../i18n'
import { routes } from '../../routes'
import { mainRoutes } from '../../routes/main-routes'
import { InvitationSource } from './InvitationSource/InvitationSource'
import { useCompareEffect } from './use-compare-effect'

type ErrorType = 'login' | 'signup'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      height: '100vh',
      paddingTop: theme.dimensions.appBar.height.desktop,
      gridTemplateColumns: '1.2fr 1.8fr',
      gap: 0,
    },
  }),
  { name: 'MainInvited' },
)

const InvitedContent: React.FC<{ invitationMeta: InvitationMeta }> = ({ invitationMeta }) => {
  const { t } = useTranslation()
  const { appBaseUrl } = useConfig()
  const { user: currentUser, unsetUser } = useAuthenticationContext()
  const { claims } = useClaimsAuthenticationContext()
  const classes = useStyles()
  const navigate = useNavigate()

  const [error, setError] = useState<{ type: ErrorType; title: string }>(undefined)
  const baseUrl = safelyGetWindow()?.location.origin || appBaseUrl

  const handleSignUpError = async () => {
    sleep(500)
    setError({ type: 'signup', title: t('webapp:invited.signupError') })
  }

  const handleLoginMismatchError = async () => {
    sleep(500)
    setError({ type: 'login', title: t('webapp:invited.loginMismatchError') })
  }

  useEffect(() => {
    if (currentUser?.status === 'ACTIVE') {
      if (invitationMeta.userId && currentUser.id.toString() === invitationMeta.userId) {
        window.top.location.href = buildUrl({ baseUrl, path: invitationMeta.redirect })
      } else {
        handleLoginMismatchError()
      }
    }

    return () => setError(undefined)
  }, [currentUser])

  const urls = {
    forgetPasswordUrl: routes.RequestForgotPassword.path,
    privacyPolicyUrl: routes.PrivacyPolicy.path,
    termsAndConditionUrl: routes.TermsAndConditions.path,
  }

  const titleKey =
    currentUser?.status === 'INACTIVE'
      ? 'webapp:invited.activateAccount'
      : !currentUser && claims
      ? 'webapp:nav.signUp'
      : !currentUser
      ? 'webapp:nav.login'
      : undefined

  const title = titleKey ? t(titleKey) : undefined
  const onClose = currentUser?.status === 'INACTIVE' ? undefined : () => navigate(invitationMeta?.redirect ?? '../')

  return (
    <TakeOver onClose={onClose} title={title}>
      <OneThirdTwoThirdsColumnsGridLayout className={classes.container}>
        <Flex flexDirection='column' height='100%' justifyContent='center' p={4}>
          {title && <H2>{title}</H2>}
          {error && (
            <Note type='closable' statusColor='alert' title={error.title}>
              {error.type === 'login' && <Link onClick={() => unsetUser()}>{t('webapp:nav.logout')}</Link>}
            </Note>
          )}
          <Flex pb={4} maxWidth>
            {currentUser?.status === 'INACTIVE' ? (
              <ActivateAccountForm onError={handleSignUpError} {...urls} />
            ) : !currentUser && claims ? (
              <SignUpForm onError={handleSignUpError} {...urls} />
            ) : !currentUser ? (
              <LogInForm {...urls} />
            ) : (
              <FullScreenSpinner />
            )}
          </Flex>
        </Flex>

        <InvitationSource invitationMeta={invitationMeta} />
      </OneThirdTwoThirdsColumnsGridLayout>
    </TakeOver>
  )
}

const Invited: React.FC = () => {
  const query = useLocationQuery(['code', 'token'])
  const { claims, setClaimsFromToken } = useClaimsAuthenticationContext()
  const [invitationMeta, setInvitationMeta] = useState<InvitationMeta>(undefined)
  const navigate = useNavigate()

  useCompareEffect(() => {
    const { code, token } = query

    if (!code) {
      navigate('../')
      return
    }

    try {
      setInvitationMeta(code ? invitationMetaScheme.decode(code) : { redirect: '/' })
    } catch {
      navigate('../')
      return
    }

    !invitationMeta && token && setClaimsFromToken(token)

    return () => setClaimsFromToken(undefined)
  }, [query])

  if (claims && isAuthenticationClaims(claims)) {
    return <Redirect reload path={`${mainRoutes.Redirect.path}?code=${query.code}&token=${query.token}`} />
  }

  return invitationMeta ? <InvitedContent invitationMeta={invitationMeta} /> : <></>
}

export { Invited }
