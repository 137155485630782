import { routesBuilder, StatusCodes, yup } from '@guiker/shared-framework'

import { PayoutMethod } from '../../../entity'
import { paths } from '../paths'

const basePath = paths.constant.payoutMethods.withId.payoutProviders.path
const base = routesBuilder<PayoutMethod>()({ basePath })
const noContent = routesBuilder<void>()({ basePath })

export const payoutProviderRoutes = {
  reactivatePayoutProvider: base.buildPut({
    path: '/:payoutProviderName/:payoutProviderAccountId/reactivate',
    payloadSchema: yup.object().nullable(),
  }),
  resubmitVerificationDocumentsToPayoutProvider: noContent.buildPut({
    path: '/:payoutProviderName/:payoutProviderAccountId/resubmit-verification-documents',
    successStatusCode: StatusCodes.NO_CONTENT,
    payloadSchema: yup.object().nullable(),
  }),
  activatePayoutProvider: noContent.buildPut({
    path: '/:payoutProviderName/activate',
    successStatusCode: StatusCodes.NO_CONTENT,
    payloadSchema: yup.object().nullable(),
  }),
}
