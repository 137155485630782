import { yup } from '@guiker/shared-framework'

export const claimPromotionalStockOrderSchema = yup.object<{
  consent: {
    fingerprint: string
    isConsentGiven: boolean
    date: string
  }
}>({
  consent: yup.object({
    fingerprint: yup.string().required(),
    isConsentGiven: yup.boolean().required().oneOf([true], 'The risk acknowledgement must be accepted.'),
    date: yup.string().required(),
  }),
})

export type ClaimPromotionalStockOrderSchema = yup.InferType<typeof claimPromotionalStockOrderSchema>
