import { AgeGroup, Roommate } from '@guiker/base-entity'
import { compareDate, DateTime, flatten } from '@guiker/shared-framework'

import {
  Address,
  Applicant,
  Application,
  Booking,
  BookingInfo,
  BookingStatus,
  Listing,
  RoommateIntroTenantDecision,
} from '..'

type ExtendedApplicant = Applicant & {
  info: BookingInfo
  listing: Listing
}

const getAgeRange = (dateOfBirth?: string): Roommate['age'] => {
  if (!dateOfBirth) return

  const age = Math.abs(DateTime.fromISO(dateOfBirth).diffNow('years').years)
  const range = Math.floor(age / 10) * 10
  const mod = age % 10
  const boundary = mod <= 3 ? AgeGroup.EARLY : mod >= 7 ? AgeGroup.LATE : AgeGroup.MID
  return {
    range,
    boundary,
  }
}

const mergeDuplicatedRoommates = (
  existingRoommate: ExtendedApplicant,
  newRoommate: ExtendedApplicant,
): ExtendedApplicant => {
  const { from: existingRoommateFrom, to: existingRoommateTo } = existingRoommate.info.period
  const { from: newRoommateFrom, to: newRoommateTo } = newRoommate.info.period
  const newPeriod = {
    from: existingRoommateFrom > newRoommateFrom ? newRoommateFrom : existingRoommateFrom,
    to: existingRoommateTo > newRoommateTo ? existingRoommateTo : newRoommateTo,
  }
  const newApplication = !!newRoommate.application?.content?.roommateProfile
    ? newRoommate.application
    : existingRoommate.application

  return {
    ...existingRoommate,
    info: {
      ...existingRoommate.info,
      period: newPeriod,
    },
    application: newApplication,
  }
}

const mapApplicants = (applicants: ExtendedApplicant[]) => {
  const fields = ['room', 'street', 'streetName']

  const applicantsMap = applicants.reduce((acc, applicant) => {
    const existing = acc[applicant.userId]
    const shouldMerge =
      existing &&
      existing.userId === applicant.userId &&
      fields.every(
        (field) =>
          existing.listing.address[field as keyof Address] === applicant.listing.address[field as keyof Address],
      )
    const merged = shouldMerge ? mergeDuplicatedRoommates(existing, applicant) : applicant
    return { ...acc, [applicant.userId]: merged }
  }, {} as { [userId in string]: ExtendedApplicant })

  return Object.values(applicantsMap)
}

export const transformBookingsToRoommates = (
  bookings: Booking[],
  options: { withEmail?: boolean } = {},
): Roommate[] => {
  if (!bookings?.length) return []

  const applicants = flatten(
    bookings.map((b) => {
      return b.applicants.map((a) => ({ ...a, info: b.info, listing: b.listing }))
    }),
  )
  const mappedApplicants = mapApplicants(applicants)

  return mappedApplicants.map(({ info, listing, application, ...applicant }) => {
    const baseRoommate = {
      userId: applicant.userId,
      firstName: applicant.firstName,
      emailAddress: options.withEmail ? applicant.emailAddress : undefined,
      moveInDate: info.period.from,
      moveOutDate: info.period.to,
      address: listing.address,
    }
    const { profile, roommateProfile } = { ...application?.content }
    if (!roommateProfile) return baseRoommate

    return {
      ...baseRoommate,
      age: getAgeRange(profile.dateOfBirth),
      gender: profile.gender,
      interests: roommateProfile.interests,
      livingWithPets: roommateProfile.livingWithPets,
      friendLevel: roommateProfile.friendLevel,
      sleepSchedule: roommateProfile.sleepSchedule,
      cleanliness: roommateProfile.cleanliness,
      personalDescription: roommateProfile.personalDescription,
    }
  })
}

export const isTenantsRoommateIntroRejected = (application: Application) => {
  if (!application) return true
  return application.roommateIntro?.tenants?.some((t) => t.decision === RoommateIntroTenantDecision.REJECTED)
}

export const getProbableRentPeriod = (args: { status: BookingStatus; period: Booking['info']['period'] }) => {
  const { status, period } = args
  const today = DateTime.local().toUTC()
  const shouldEstimate = compareDate(period.from).isBefore(today)
  if (status === BookingStatus.BOOKED || !shouldEstimate) return period

  const startOfNextMonth = today.plus({ months: 1 }).startOf('month')
  const tentativeMoveIn = startOfNextMonth.toISODate()
  const from = period.from > tentativeMoveIn ? period.from : tentativeMoveIn
  const to = DateTime.fromISO(from).plus({ months: 12 }).toISODate()
  return { from, to } as Booking['info']['period']
}
