import { CountryCode } from './background-check'

export type Grade = 'A+' | 'A' | 'B+' | 'B' | 'C+' | 'C' | 'D' | 'F' | 'N/A'
export type GradeInformation = { grade: Grade; range: string; defaultChance: string }

export const calculateReportItems = (creditScore: number | null): GradeInformation | undefined => {
  if (creditScore == null || isNaN(creditScore)) {
    return {
      grade: 'N/A',
      range: 'N/A',
      defaultChance: 'N/A',
    }
  }

  if (creditScore >= 800) {
    return {
      grade: 'A+',
      range: '800+',
      defaultChance: '1%',
    }
  } else if (creditScore >= 750) {
    return {
      grade: 'A',
      range: '750 - 799',
      defaultChance: '2%',
    }
  } else if (creditScore >= 700) {
    return {
      grade: 'B+',
      range: '700 - 749',
      defaultChance: '5%',
    }
  } else if (creditScore >= 650) {
    return {
      grade: 'B',
      range: '650 - 699',
      defaultChance: '15%',
    }
  } else if (creditScore >= 600) {
    return {
      grade: 'C+',
      range: '600 - 649',
      defaultChance: '31%',
    }
  } else if (creditScore >= 550) {
    return {
      grade: 'C',
      range: '550 - 599',
      defaultChance: '51%',
    }
  } else if (creditScore >= 500) {
    return {
      grade: 'D',
      range: '500 - 549',
      defaultChance: '71%',
    }
  } else if (creditScore < 500) {
    return {
      grade: 'F',
      range: '< 500',
      defaultChance: '87%',
    }
  }
}

export const isValidCountryCode = (countryCode: any): countryCode is CountryCode =>
  Object.values(CountryCode).includes(countryCode)
