import { Args, buildPath, DataAndMeta, DataType, Metadata, PayloadType, RouteDefinition } from '@guiker/api-definition'

import { ApiClientArguments, getRestClient, RequestOptions } from './get-rest-client'
import { headerManager } from './header-manager'

type Options = {
  includeMetadata?: boolean
}

export type RecordRouteDefinition = Record<string, RouteDefinition>

type FunctionWithoutArgs<T extends RouteDefinition> = <
  O extends { includeMetadata?: true } | { includeMetadata?: false },
>(
  options?: O,
) => Promise<O['includeMetadata'] extends true ? DataAndMeta<DataType<T>> : DataType<T>>

type FunctionWithArgs<T extends RouteDefinition> = <O extends { includeMetadata?: true } | { includeMetadata?: false }>(
  args: Args<T>,
  options?: O,
) => Promise<O['includeMetadata'] extends true ? DataAndMeta<DataType<T>> : DataType<T>>

export type ApiClientFunction<T extends RouteDefinition> = Args<T> extends never
  ? FunctionWithoutArgs<T>
  : FunctionWithArgs<T>

export type ApiClient<T extends RecordRouteDefinition> = {
  [key in keyof T]: ApiClientFunction<T[key]>
}

type BaseApiClientFunction<T extends RouteDefinition> = (args: {
  url: string
  options?: RequestOptions
  payload?: PayloadType<T>
}) => Promise<{ body: { data: DataType<T>; meta?: Metadata } }>

const extractResponse =
  <T extends RouteDefinition>({ includeMetadata }: Options = {}) =>
  (r: { body: { data: DataType<T>; meta: Metadata } }) => {
    if (includeMetadata || (r.body.data && r.body.meta)) {
      return {
        data: r.body.data,
        meta: r.body.meta,
      }
    } else {
      return r.body.data
    }
  }

export const generateApiClient =
  <T extends RecordRouteDefinition>(prefix: string, routes: T) =>
  (args: ApiClientArguments): ApiClient<T> => {
    const baseClient = getRestClient(args)

    const apiClient: any = {}

    Object.keys(routes).forEach((routeName: keyof typeof routes) => {
      const route = routes[routeName]
      type RouteType = typeof route

      const lowerCaseHttpMethod = route.method.toLowerCase()

      if (
        route.pathParamsValidator ||
        route.payloadValidator ||
        route.queryParamsValidator ||
        route.accessControlValidator
      ) {
        apiClient[routeName] = (arg: Args<RouteType>, options?: Options) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { payload, pathParams, queryParams, accessControl } = (arg || {}) as any
          const baseClientMethod = baseClient[lowerCaseHttpMethod] as BaseApiClientFunction<RouteType>
          const path = `${prefix}${buildPath(route, pathParams || {}, queryParams || {})}`

          const requestOptions: RequestOptions = accessControl?.token
            ? {
                headers: {
                  ...headerManager.authorizationBearer.build(accessControl.token),
                  ...headerManager.accessToken.build(accessControl.token),
                },
              }
            : null
          return baseClientMethod({ url: path, options: requestOptions, payload }).then(extractResponse(options))
        }
      } else {
        apiClient[routeName] = (options?: Options) => {
          const path = `${prefix}${buildPath(route)}`
          const baseClientMethod = baseClient[lowerCaseHttpMethod] as BaseApiClientFunction<RouteType>

          return baseClientMethod({ url: path }).then(extractResponse(options))
        }
      }
    })

    return apiClient
  }
