import React from 'react'

import { Flex, makeStyles, PBold, PSmall, useTranslation } from '@guiker/react-framework'
import { printBedAndBathCount } from '@guiker/rental-listing-components'
import { TransformedRentalOption } from '@guiker/rental-listing-shared'
import { currency, money, optionalConcat } from '@guiker/shared-framework'

type RentalOptionLabelProps = {
  rentalOption: TransformedRentalOption
}

const useStyle = makeStyles({
  dropdownOption: {
    fontWeight: 'inherit',
  },
})

export const RentalOptionLabel: React.FC<RentalOptionLabelProps> = ({ rentalOption }) => {
  const classes = useStyle()
  const { t } = useTranslation(['main-rentalListing', 'common-rentalListing', 'listing'])
  const { roomCount, price } = rentalOption

  const buildHeader = (priceOption: TransformedRentalOption) => {
    const { type, size, suiteNumber } = priceOption
    return (
      `${suiteNumber ? `${t('listing:unitNumber', { unit: suiteNumber })}: ` : ''}` +
      `${optionalConcat([t(`listing:rentalType.${type}`), size], ' | ')} `
    )
  }

  const buildSubLabel = (priceOption: TransformedRentalOption) => {
    const { duration } = priceOption
    const { count, unit } = duration

    return `${printBedAndBathCount(t, roomCount)}` + ' | ' + `${t(`listing:durations.${unit}`, { count })}`
  }

  return (
    <Flex fullWidth flexDirection='column' pr={1}>
      <Flex fullWidth justifyContent='space-between' alignItems='center'>
        <PBold>{buildHeader(rentalOption)}</PBold>
        <PBold color={'primary'}>{money.fromAmount(price.amount, currency[price.currency]).toString(true, true)}</PBold>
      </Flex>
      <PSmall className={classes.dropdownOption}>{buildSubLabel(rentalOption)}</PSmall>
    </Flex>
  )
}
