import React from 'react'

import {
  FormSection3,
  Inline,
  NumberFormat,
  numberFormatter,
  ThreeColumnsGridLayout,
  useGetInputProps,
  useTranslation,
} from '@guiker/react-framework'

import { AssumptionComponentProps as Props } from '../assumption-props'

export const FinancingAssumptions: React.FC<Props> = ({ defaultValue, name, schema, readOnly = false }) => {
  const { t } = useTranslation(['common', 'common-investmentAssumption'])
  const tPrefix = 'common-investmentAssumption:financing.mortgage'
  const inputProps = useGetInputProps({ defaultValue, formPrefix: `${name}.mortgage`, schema, tPrefix, readOnly })

  const fields = {
    minimumDownPaymentPercentage: inputProps('minimumDownPaymentPercentage'),
    termsInYears: inputProps('termsInYears'),
    interestRate: inputProps('interestRate'),
  }

  return (
    <FormSection3 title={t(`${tPrefix}.title`)}>
      <ThreeColumnsGridLayout>
        <NumberFormat
          {...fields.minimumDownPaymentPercentage}
          endAdornment={<Inline>%</Inline>}
          decimalScale={2}
          allowNegative={false}
          formatter={numberFormatter.percentage(2)}
        />
        <NumberFormat
          {...fields.termsInYears}
          endAdornment={<Inline>{t('common:duration.year')}</Inline>}
          decimalScale={0}
          allowNegative={false}
        />
        <NumberFormat
          {...fields.interestRate}
          endAdornment={<Inline>%</Inline>}
          decimalScale={4}
          allowNegative={false}
          formatter={numberFormatter.percentage(4)}
        />
      </ThreeColumnsGridLayout>
    </FormSection3>
  )
}
