import React, { useMemo } from 'react'

import { isAtMostTablette, isMobile, padding, toPx } from '@guiker/components-core'
import { Drawer, Flex, makeStyles, theme, useLayoutContext, useOnScrollDirection } from '@guiker/components-library'
import { CloseIcon } from '@guiker/icons'
import { safelyGetWindow } from '@guiker/react-framework'

import { useGlosseryDrawerContext } from '../context'

const useStyles = makeStyles({
  menuPaper: {
    overflowY: 'auto',
    width: theme.dimensions.contentNavigation.width.desktop,
    marginTop: theme.dimensions.appBar.height.desktop + theme.dimensions.progressBar.height,
    height: `calc(100vh - ${toPx(
      theme.dimensions.appBar.height.desktop +
        theme.dimensions.progressBar.height +
        theme.dimensions.buttonContainer.height,
    )})`,
    [isAtMostTablette]: {
      marginTop: ({ shouldDisplayAsFixed }: { shouldDisplayAsFixed: boolean }) =>
        theme.dimensions.appBar.height.desktop +
        theme.dimensions.progressBar.height +
        (shouldDisplayAsFixed ? theme.dimensions.topTabNavigation.height.desktop : 0),
      height: ({ shouldDisplayAsFixed }: { shouldDisplayAsFixed: boolean }) =>
        `calc(100vh - ${toPx(
          theme.dimensions.appBar.height.desktop +
            theme.dimensions.buttonContainer.height +
            theme.dimensions.progressBar.height +
            (shouldDisplayAsFixed ? theme.dimensions.topTabNavigation.height.desktop : 0),
        )})`,
    },
    [isMobile]: {
      minWidth: theme.dimensions.contentNavigation.width.mobile,
      marginTop: ({ shouldDisplayAsFixed }: { shouldDisplayAsFixed: boolean }) =>
        theme.dimensions.appBar.height.mobile +
        theme.dimensions.progressBar.height +
        (shouldDisplayAsFixed ? theme.dimensions.topTabNavigation.height.mobile : 0),
      height: ({ shouldDisplayAsFixed }: { shouldDisplayAsFixed: boolean }) =>
        `calc(100vh - ${toPx(
          theme.dimensions.appBar.height.mobile +
            theme.dimensions.progressBar.height +
            (shouldDisplayAsFixed ? theme.dimensions.topTabNavigation.height.mobile : 0),
        )})`,
    },
  },
  closeBar: {
    width: '100%',
    justifyContent: 'flex-end',
    padding: padding(2, 2, 0, 2),
  },
})

export const GlosseryDrawer: React.FC = () => {
  const { content, onClose, anchor } = useGlosseryDrawerContext()
  const { isMobile } = useLayoutContext()
  const isScrollingUp = useOnScrollDirection(
    'up',
    isMobile ? theme.dimensions.appBar.height.mobile : theme.dimensions.appBar.height.desktop,
  )

  const shouldDisplayAsFixed = useMemo(() => {
    const pageYOffset = safelyGetWindow()?.pageYOffset
    const isInAlwaysDisplayZone = pageYOffset < 40

    return isInAlwaysDisplayZone || isScrollingUp
  }, [isScrollingUp])
  const classes = useStyles({ shouldDisplayAsFixed })

  return (
    <Drawer
      anchor='right'
      variant='persistent'
      keepMounted={true}
      open={!!anchor}
      onClose={onClose}
      classes={{ paper: classes.menuPaper }}
    >
      <Flex className={classes.closeBar} id={'glosseryDrawer'}>
        <CloseIcon onClick={onClose} />
      </Flex>
      <Flex flexDirection='column' px={2} py={4}>
        {content}
      </Flex>
    </Drawer>
  )
}
