import { TypeOf, yup } from '@guiker/yup-util'

import { pricePerUnitSchema } from './price-per-unit'

export const stockConfigurationSchema = yup.object({
  total: yup.number().required(),
  minPerTransaction: yup.number().required(),
  pricePerUnit: pricePerUnitSchema.required(),
})

export type StockConfigurationSchema = TypeOf<typeof stockConfigurationSchema>
