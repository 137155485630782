import { useEffect } from 'react'

import { PageViewEvent } from '@guiker/react-google-tag-manager'
import { useLocation } from '@guiker/router'

import { useDataTrackingContext } from './use-data-tracking-context'

export const useSendPageView = ({ pageName }: Pick<PageViewEvent, 'pageName'>) => {
  const context = useDataTrackingContext()
  const { pathname: pagePath } = useLocation()

  useEffect(() => {
    const { host: pageHostname } = window?.location
    context?.sendPageView({ pagePath, pageHostname, pageName })
  }, [])
}
