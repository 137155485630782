import { routesBuilder } from '@guiker/shared-framework'

import { Portfolio, Position } from '../../entity'
import { paths } from './paths'

const { buildGet: buildGetByInvestorProfileId } = routesBuilder<Portfolio>()({
  basePath: paths.investorProfiles.withId.portfolio.path,
})

const { buildGet: buildGetByStockId } = routesBuilder<Position>()({
  basePath: paths.stock.withId.portfolio.path,
})

export const routes = {
  readAllInvestorProfilePortfolio: buildGetByInvestorProfileId(),
  readStockPositions: buildGetByStockId({
    paginated: true,
  }),
  readStockPositionsCSVData: buildGetByStockId({
    path: '/csv',
    response: null as string[][],
  }),
}
