import { Developer, Project, ProjectWithDeveloper } from '../entity'

export const developerHasDocument = (developer: Developer) => {
  return developer?.documents && developer?.documents?.length > 0
}
export const projectHasDocument = (project: Project | ProjectWithDeveloper) => {
  return (
    (project?.documents && project?.documents.length > 0) ||
    developerHasDocument((project as ProjectWithDeveloper)?.developer)
  )
}
