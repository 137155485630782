import { TypeOf, yup } from '@guiker/yup-util'

import { tenantApplicationCurrentAddressSchema, tenantApplicationOccupationSchema } from '../../schemas'

export const updateTenantApplicationCurrentAddressOccupationPayloadValidator = yup.object({
  occupation: tenantApplicationOccupationSchema.required(),
  currentAddress: tenantApplicationCurrentAddressSchema.required(),
})

export type UpdateTenantApplicationCurrentAddressOccupationPayloadValidator = TypeOf<
  typeof updateTenantApplicationCurrentAddressOccupationPayloadValidator
>
