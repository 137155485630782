import { PublicApiClient } from '@guiker/property-sale-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export * from './use-api-client'

export const {
  useContext: usePublicPropertySaleListingIdContext,
  NestedRouter: PublicPropertySaleListingIdNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.PUBLIC,
  apiClientGen: PublicApiClient,
  cacheKey: ({ id }: { id: string }) => `readOneListingByUid-${id}`,
  fetcher: (apiClient, { id }: { id: string }) => apiClient.readOneListing({ pathParams: { id } }),
})

export const {
  useContext: usePublicPropertySaleListingContext,
  Context: PublicPropertySaleListingContext,
  ContextProvider: PublicPropertySaleListingContextProvider,
  NestedRouter: PublicPropertySaleListingRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.PUBLIC,
  apiClientGen: PublicApiClient,
})
