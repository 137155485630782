import React from 'react'

import { SoftcheckOverallScore } from '@guiker/background-check-shared'
import { Chip, ChipProps } from '@guiker/components-library'

import { i18n, I18nextProvider, useTranslation } from '../../i18n'

interface SoftcheckOverallScoreChipProps {
  status: SoftcheckOverallScore
}

const Content: React.FC<SoftcheckOverallScoreChipProps> = ({ status }) => {
  const { t } = useTranslation()
  let color: ChipProps['color'] = null

  switch (status) {
    case 'PASS':
      color = 'success'
      break
    case 'WARN':
      color = 'warning'
      break
    case 'FAIL':
      color = 'alert'
      break
    case 'NONE':
    default:
      color = 'info'
  }

  return <Chip color={color}>{t(`common-backgroundCheck:softcheck.overallScore.${status.toLowerCase()}`)}</Chip>
}

const SoftcheckOverallScoreChip: React.FC<SoftcheckOverallScoreChipProps> = (props) => (
  <I18nextProvider i18n={i18n}>
    <Content {...props} />
  </I18nextProvider>
)

export { SoftcheckOverallScoreChip }
