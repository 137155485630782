import React from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { Flex, P } from '@guiker/react-framework'

import { useGetPayInMethodLabel } from '../../utils'
import { PayInMethodDisabledChip } from '../PayInMethodDisabledChip'
import { PayInMethodIcon } from '../PayInMethodIcon'

type PayInMethodLabelProps = {
  payInMethod: PayInMethod
}

export const PayInMethodLabel: React.FC<PayInMethodLabelProps> = ({ payInMethod }) => {
  const getPayInMethodLabel = useGetPayInMethodLabel()
  const { type, details = {} } = { ...payInMethod }
  const brand = details ? details['brand'] : undefined
  const routingNumber = details['routingNumber'] ?? details['institutionId']

  return (
    <Flex gap={2} alignItems='center'>
      <PayInMethodIcon type={type} brand={brand} routingNumber={routingNumber} />
      <P color={60}>{getPayInMethodLabel(payInMethod, { hideCCBrand: true })}</P>
      <PayInMethodDisabledChip payInMethod={payInMethod} />
    </Flex>
  )
}
