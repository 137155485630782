import React, { useState } from 'react'

import { mainPathBuilder as bookingPathBuilder } from '@guiker/booking-shared'
import { mainPathBuilder as conversationPathBuilder } from '@guiker/conversation-shared'
import { isMobile, isNotMobile, Trans, useQuery } from '@guiker/react-framework'
import { PaginatedListingsGrid } from '@guiker/rental-listing-components'
import { isRoommatable, RentalListing } from '@guiker/rental-listing-shared'
import { stringToNumber, upperFirst } from '@guiker/shared-framework'
import { tenantApplicationValidator } from '@guiker/tenant-application-shared'

import {
  useAuthenticationContext,
  useListingInquiryContext,
  useRecommendedListingAuthApiClient,
  useTenantApplicationApiClient,
  useTranslation,
} from '../../hooks'
import { Button, Divider, Flex, H3, Link, makeStyles, Modal, P, PBig, SecondaryButton, theme, useMediaQuery } from '..'
import { EmptyState } from './EmptyState'

const useStyle = makeStyles({
  root: {
    width: '100%',
    maxWidth: ({ maxWidth }: { maxWidth: number | string }) => maxWidth,
  },
  closeButton: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey[50],
      transition: theme.transitions.create(['color']),
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
    [isMobile]: {
      flexDirection: 'column',
    },
  },
  centerButton: {
    [isNotMobile]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    [isMobile]: {
      marginTop: theme.spacing(1),
    },
  },
})

const ListingInquirySuccessModal: React.FC<{ listing: RentalListing }> = ({ listing }) => {
  const { t } = useTranslation(['main-rentalListing'])
  const classes = useStyle()
  const maxWidth = useMediaQuery(isMobile) ? 376 : 780
  const { user } = useAuthenticationContext()
  const { inquirySubmitted, setInquirySubmitted, listingInquiry } = useListingInquiryContext()
  const recommendedListingAuthApiClient = useRecommendedListingAuthApiClient()
  const tenantApplicationAuthApiClient = useTenantApplicationApiClient()
  const [completedApplication, setCompletedApplication] = useState(false)
  const [startedApplication, setStartedApplication] = useState(false)
  const [hasRecommendedListings, setHasRecommendedListings] = useState(true)
  const applicationPath = bookingPathBuilder.root.byId(listingInquiry?.booking?.id).applicants
  const isRoommatableInquiry = isRoommatable(listing, stringToNumber(listingInquiry?.rentalOptionId))

  const { isLoading } = useQuery(
    'listing-tenant-application',
    () => tenantApplicationAuthApiClient.readOneTenantApplication(),
    {
      enabled: !!user,
      onSuccess: (tenantApplication) => {
        const v = tenantApplicationValidator(tenantApplication)
        const isRoommateProfileCompleted = !isRoommatableInquiry || v.hasCompletedRoommateProfile()
        setStartedApplication(v.hasCompletedAny())
        setCompletedApplication(v.hasCompletedWithoutDocuments() && isRoommateProfileCompleted)
      },
    },
  )

  const fetcher = ({ page, perPage }: { page: number; perPage: number }) =>
    recommendedListingAuthApiClient.readAllRecommendedListingsByListingId({
      queryParams: { page, perPage, listingId: listing?.id },
    })

  const inquirySuccessMessages = upperFirst(
    [
      hasRecommendedListings ? [] : [t('inquiry.modal.recommendation'), t('inquiry.modal.or')],
      [t('inquiry.modal.application')],
    ]
      .filter((s) => !!s)
      .join(''),
  )

  if (isLoading) {
    return <></>
  }

  return (
    <Modal open={inquirySubmitted} onClose={() => setInquirySubmitted(false)} maxWidth={maxWidth}>
      <div className={classes.root}>
        <Flex p={2} px={4} flexDirection='column' alignItems='center'>
          <H3 className={classes.alignCenter} mb={1}>
            {t('inquiry.startedChat')}
          </H3>
          <P className={classes.alignCenter}>
            <Trans i18nKey='main-rentalListing:inquiry.modal.success' values={{ message: inquirySuccessMessages }}>
              <Link color='textPrimary' isExternalLink to='/recommended-listing' />
            </Trans>
          </P>
        </Flex>
        <Flex className={classes.buttonContainer} fullWidth px={4} pb={hasRecommendedListings ? 4 : 0}>
          <Link
            isExternalLink
            to={conversationPathBuilder.root.messages.path({ convoId: listingInquiry?.conversationId })}
            underline={false}
            hoverUnderline={false}
          >
            <SecondaryButton className={classes.centerButton}>{t('inquiry.goChat')}</SecondaryButton>
          </Link>
          <Link
            underline={false}
            hoverUnderline={false}
            isExternalLink
            to={completedApplication ? applicationPath.view.path : applicationPath.edit.path}
          >
            <Button className={classes.centerButton}>
              {completedApplication
                ? t('inquiry.submitApplication')
                : startedApplication
                ? t('inquiry.resumeApplication')
                : t('inquiry.startApplication')}
            </Button>
          </Link>
        </Flex>
        {hasRecommendedListings && (
          <>
            <Divider color={10} />
            <PBig p={3} className={classes.alignCenter} color={'textPrimary'}>
              {t('inquiry.similarListingTitle')}
            </PBig>
            <PaginatedListingsGrid
              maxColumns={2}
              cacheKey={null}
              fetcher={fetcher}
              withPagination={false}
              emptyState={<EmptyState onLoad={() => setHasRecommendedListings(false)} />}
            />
            <div className={classes.buttonContainer}>
              <Link
                isExternalLink
                color='textPrimary'
                to='/recommended-listing'
                underline={false}
                hoverUnderline={false}
              >
                <SecondaryButton>{t('inquiry.viewMore')}</SecondaryButton>
              </Link>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export { ListingInquirySuccessModal }
