import React from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  ApiForm,
  ApiFormAction,
  ColumnGridItem,
  Divider,
  Dropdown,
  Modal,
  ModalProps,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  yupResolver,
} from '@guiker/react-framework'
import { RecaptchaProvider, useRecaptcha } from '@guiker/react-recaptcha'
import { RecaptchaAction } from '@guiker/recaptcha-action'
import { PublicApi } from '@guiker/user-enquiry-shared'

import { useUserEnquiryApiClient } from '../InvestorsScreenContext'

type InvestorModalProps = ModalProps & {
  open: boolean
}

const FormContent: React.FC = () => {
  const { t } = useTranslation(['webapp', 'common'])

  const inputProps = useGetInputProps({
    schema: PublicApi.Schema.investorsEnquirySchema,
  })

  return (
    <TwoColumnsGridLayout>
      <TextField {...inputProps('data.name', { i18nKey: 'common:user.name' })} />
      <TextField {...inputProps('emailAddress', { i18nKey: 'common:user.emailAddress' })} />
      <TextField {...inputProps('data.phoneNumber', { i18nKey: 'common:user.phoneNumber' })} />
      <div></div>
      <ColumnGridItem md={2}>
        <Divider />
      </ColumnGridItem>
      <ColumnGridItem md={2}>
        <RadioGroup
          {...inputProps('data.accreditedInvestor', { i18nKey: 'webapp:investors.modal.accreditedInvestor' })}
          direction='row'
          options={[
            {
              label: t('common:yes'),
              value: 'yes',
            },
            {
              label: t('common:no'),
              value: 'no',
            },
          ]}
        />
      </ColumnGridItem>
      <Dropdown
        {...inputProps('data.experience', { i18nKey: 'webapp:investors.modal.experience.label' })}
        options={[
          {
            label: t('webapp:investors.modal.experience.beginner'),
            value: 'beginner',
          },
          {
            label: t('webapp:investors.modal.experience.intermediate'),
            value: 'intermediate',
          },
          {
            label: t('webapp:investors.modal.experience.expert'),
            value: 'expert',
          },
        ]}
      />
      <TextField {...inputProps('data.properties', { i18nKey: 'webapp:investors.modal.properties' })} />
      <ColumnGridItem md={2}>
        <TextField multiline={true} {...inputProps('data.comments', { i18nKey: 'webapp:investors.modal.comments' })} />
      </ColumnGridItem>
    </TwoColumnsGridLayout>
  )
}

const InvestorModalForm: React.FC<InvestorModalProps> = ({ open, ...props }) => {
  const { t } = useTranslation(['common'])
  const { executeRecaptcha } = useRecaptcha()
  const { apiClient } = useUserEnquiryApiClient()

  const resolver = yupResolver((PublicApi.Schema.investorsEnquirySchema as any).pick(['data', 'emailAddress']))

  const onSubmit = async (payload: PublicApi.Schema.InvestorsEnquirySchema) => {
    const recaptchaToken = await executeRecaptcha(RecaptchaAction.investorsEnquiry)

    return apiClient.createInvestorsEnquiry({
      payload: {
        ...payload,
        recaptchaToken,
      },
    })
  }

  return (
    <ApiForm
      apiOptions={{ onSuccess: () => props.onClose() }}
      onSubmit={onSubmit}
      formName={'InvestorModal'}
      formOptions={{
        resolver,
        defaultValues: {
          experience: '',
        },
      }}
    >
      <Modal
        open={open}
        maxWidth={800}
        actions={
          <ApiFormAction onClick={onSubmit} options={{ onSuccess: () => props.onClose() }}>
            {t('common:actions.submit')}
          </ApiFormAction>
        }
        {...props}
      >
        <FormContent />
      </Modal>
    </ApiForm>
  )
}

const InvestorModal: React.FC<InvestorModalProps> = (props) => {
  return (
    <RecaptchaProvider>
      <InvestorModalForm {...props} />
    </RecaptchaProvider>
  )
}

export { InvestorModal }
