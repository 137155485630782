import { BaseAddress, CurrentAddress, TenantApplication } from '@guiker/tenant-application-shared'

export const findLegalAddressFromTenantApplication = ({
  tenantApplication,
  isGuarantor,
}: {
  tenantApplication: TenantApplication
  isGuarantor: boolean
}): CurrentAddress | BaseAddress => {
  if (!tenantApplication) return
  else if (isGuarantor) {
    return tenantApplication.guarantor?.address
  } else if (tenantApplication.backgroundCheck?.legalAddress) {
    const legalAddress = tenantApplication.backgroundCheck.legalAddress
    return legalAddress.isSameAsCurrentAddress ? tenantApplication.currentAddress : legalAddress.address
  } else {
    return
  }
}
