export const SUPPORTING_DOCUMENT_TYPES = ['governmentId', 'proofOfIncome'] as const
export const documentTypes: typeof SUPPORTING_DOCUMENT_TYPES = ['governmentId', 'proofOfIncome']

export type SupportingDocumentType = (typeof SUPPORTING_DOCUMENT_TYPES)[number]

export type SupportingDocument = {
  type: SupportingDocumentType
  isWaiveRequested?: boolean
  assets?: { id: string }[]
  reasonForWaive?: string
}

export type TenantApplicationSupportingDocuments = {
  supportingDocuments: SupportingDocument[]
}
