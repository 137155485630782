import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'
import { DeepNullable } from '@guiker/ts-utils'

import { Asset, BulkOperationResponse } from '../../entity'
import { bulkOperation, byScope, byScopeType, PresignedUrlParams, presignedUrlParams, searchByIds } from '../schemas'
import { paths } from './paths'

const ASSETS_BY_SCOPE_TYPE_PATH = paths.asset.all.withScopeType()
const ASSETS_BY_SCOPE_PATH = paths.asset.all.withScope()
const SEARCH_ASSETS_PATH = `${paths.asset.all.base()}/search`

export const routes = {
  bulkUpdate: {
    path: ASSETS_BY_SCOPE_PATH,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as BulkOperationResponse<Asset>),
    pathParamsValidator: (payload: unknown) => byScope.validate(payload),
    payloadValidator: (payload: unknown) => bulkOperation.validate(payload),
  },
  bulkUpdateByScopeType: {
    path: ASSETS_BY_SCOPE_TYPE_PATH,
    method: HttpMethod.PUT,
    responseValidator: (response: unknown) => Promise.resolve(response as BulkOperationResponse<Asset>),
    pathParamsValidator: (payload: unknown) => byScopeType.validate(payload),
    payloadValidator: (payload: unknown) => bulkOperation.validate(payload),
  },
  readAllAssetsByScope: {
    path: ASSETS_BY_SCOPE_PATH,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as Asset[]),
    pathParamsValidator: (payload: unknown) => byScope.validate(payload),
    queryParamsValidator: (queryParams: unknown): Promise<PresignedUrlParams> =>
      presignedUrlParams.validate(queryParams),
  },
  readAllAssetsByIds: {
    path: SEARCH_ASSETS_PATH,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as DeepNullable<Asset>[]),
    payloadValidator: validatorBuilder.buildPayloadValidator(searchByIds),
  },
}
