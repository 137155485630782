import React from 'react'

import { ApiForm, Modal, ModalProps, useT } from '@guiker/react-framework'
import { yupResolver } from '@guiker/react-hook-form'
import { AuthenticatedApi } from '@guiker/tenant-preference-shared'
import { TenantPreference } from '@guiker/tenant-preference-shared'

import { TenantPreferenceForm } from '../../TenantPreferenceForm'
import { ButtonContainer } from './ButtonContainer'

export type TenantPreferenceModalProps = ModalProps & {
  tenantPreference: TenantPreference
}

const MAX_WIDTH = 784

const TenantPreferenceModal: React.FC<TenantPreferenceModalProps> = ({ tenantPreference, open, onClose, ...props }) => {
  const { tMain } = useT({ domain: 'tenantPreference' })

  return (
    <ApiForm
      formOptions={{
        resolver: yupResolver(AuthenticatedApi.Schema.upsertTenantPreference),
        defaultValues: {
          tenantPreference,
        },
      }}
      formName='MainUpdateTenantPreference'
    >
      <Modal
        maxWidth={MAX_WIDTH}
        open={open}
        title={tMain('modal.title')}
        subtitle={tMain('modal.description')}
        onClose={onClose}
        actions={<ButtonContainer onClose={onClose} />}
        {...props}
      >
        <TenantPreferenceForm tenantPreference={tenantPreference} />
      </Modal>
    </ApiForm>
  )
}

export { TenantPreferenceModal }
