import React from 'react'

import { CreationStatus } from '@guiker/base-listing-shared'
import { PropSharingListing } from '@guiker/propsharing-shared'
import { Chip, ColumnConfig, Flex, Link, useDateFormatter, useT } from '@guiker/react-framework'
import { addressFormatter } from '@guiker/shared-framework'

export type ToggleStatusArgs = { id: string; status: PropSharingListing['status'] }
export type ToggleStatus = (args: ToggleStatusArgs) => Promise<void>

export const useGetListingColumns = (toggleStatus: ToggleStatus): ColumnConfig<PropSharingListing>[] => {
  const { tShared, tBase } = useT({ domain: 'propsharing', entity: 'listing' })
  const { formatDate } = useDateFormatter()

  return [
    {
      label: tShared('building.address'),
      name: 'building.address',
      size: 4,
      renderValue: ({ property }) =>
        property?.building?.address &&
        addressFormatter.printShortAddress({
          ...property.building.address,
          suite: property.units && property.units[0]?.address?.unitNumber,
          streetNumber: property.building.address.streetNumbers[0],
        }),
    },
    {
      label: tShared('createdAt'),
      name: 'createdAt',
      size: 1,
      renderValue: ({ createdAt }) => formatDate(createdAt),
    },
    {
      label: tShared('status.label'),
      name: 'status',
      size: 1.5,
      renderValue: ({ status }) =>
        status.creationStatus === CreationStatus.DRAFTED ? (
          <Chip size='small' color={60}>
            {tShared('status.drafted')}
          </Chip>
        ) : status.isPublished ? (
          <Chip size='small' color='success'>
            {tShared('status.isPublished')}
          </Chip>
        ) : (
          <Chip size='small' color='warning'>
            {tShared('status.isNotPublished')}
          </Chip>
        ),
    },
    {
      label: tBase('actions.actions'),
      name: 'actions',
      size: 1,
      renderValue: ({ id, status }) => (
        <Flex alignItems='center'>
          {status.creationStatus !== CreationStatus.DRAFTED && (
            <Link underline color='textPrimary' onClick={() => toggleStatus({ id, status })}>
              {tShared(`status.${status.isPublished ? 'unpublish' : 'publish'}`)}
            </Link>
          )}
        </Flex>
      ),
    },
  ]
}
