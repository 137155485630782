import { TypeOf, yup } from '@guiker/yup-util'

import { representativeSchema } from './representative'
import { stockConfigurationSchema } from './stock-configuration'
import { stockIssuerSchema } from './stock-issuer'

export const stockSchema = yup.object({
  id: yup.string().required(),
  issuer: stockIssuerSchema.required(),
  tickerSymbol: yup.string().required(),
  stockConfiguration: stockConfigurationSchema.required(),
  representatives: yup.array().of(representativeSchema),
})

export type StockSchema = TypeOf<typeof stockSchema>
