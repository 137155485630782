import { HttpMethod } from '@guiker/http'

import { Stats } from '../../entity'
import { paths } from './paths'

export const routes = {
  readStats: {
    path: paths.stats.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (response: Stats) => Promise.resolve(response as Stats),
  },
  readAll: {
    path: paths.conversations.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as any[]),
  },
}
