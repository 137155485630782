import { PurchaseBase } from '../../purchase-base'
import { OperationCost } from './operation-cost'
import { PurchaseCost } from './purchase-cost'
import { PurchaseTaxes } from './purchase-taxes'

export * from './expense'

export const Chicago = {
  purchase: {
    ...PurchaseBase,
    costs: PurchaseCost,
    taxes: PurchaseTaxes,
  },
  operation: {
    costs: OperationCost,
  },
} as const
