import { HttpMethod } from '@guiker/http'

const BASE_PATH = '/webhook/mls-listings'

export const routes = {
  handleListingCreated: {
    path: `${BASE_PATH}/listing-created`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleListingDeleted: {
    path: `${BASE_PATH}/listing-deleted`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
  handleListingUpdated: {
    path: `${BASE_PATH}/listing-updated`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
}
