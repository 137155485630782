import { CountryCode as SupportedCountry } from '@guiker/background-check-shared'
import { TFunction } from '@guiker/i18n'
import {
  BaseAddress,
  CurrentAddress,
  GuarantorProfile,
  hasCompleteAddress,
  hasCompleteProfile,
  isAge18,
  TenantApplicationProfile,
} from '@guiker/tenant-application-shared'

export const isSupportedCountry = (currentAddress: CurrentAddress | BaseAddress) =>
  Object.values<string>(SupportedCountry).includes(currentAddress?.country)

export const buildErrors = ({
  address,
  profile,
  userId,
  isGuarantor,
  t,
}: {
  address: CurrentAddress | BaseAddress
  profile: GuarantorProfile | TenantApplicationProfile
  userId: string
  isGuarantor: boolean
  t: TFunction
}) => {
  const errors = []

  !hasCompleteAddress(address) && errors.push('incompleteAddress')
  !hasCompleteProfile(profile) && errors.push('incompleteProfile')
  !isAge18(profile?.dateOfBirth) && errors.push('underAge')
  !isSupportedCountry(address) && errors.push('notSupportedCountry')
  !userId && errors.push('missingInfo')

  if (errors.length > 0) {
    const person = isGuarantor
      ? t(`screens.steps.backgroundCheck.errors.guarantor`)
      : t(`screens.steps.backgroundCheck.errors.applicant`)

    return errors.map((error) => t(`screens.steps.backgroundCheck.errors.${error}`, { person }))
  }
}
