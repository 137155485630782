import { TypeOf, yup } from '@guiker/yup-util'

import { ApplicationStatus } from '../../../entity'
import { bookingIdSchema } from '../../schema'

type ApplicantSchema = {
  emailAddress: string
  firstName: string
}

export const disinviteBookingApplicantRequestSchema = yup
  .object()
  .shape({
    reason: yup.string().notRequired(),
  })
  .nullable()
  .transform(() => ({ reason: undefined }))

// TODO: extend yup.ArraySchema and register unique as method
// https://medium.com/@arkadyt/how-does-yup-addmethod-work-creating-custom-validation-functions-with-yup-8fddb71a5470
export const inviteBookingApplicantsRequestSchema = yup
  .array()
  .of(
    yup.object({
      emailAddress: yup.string().email().required(),
      firstName: yup.string().required(),
    }),
  )
  .test('unique', 'Should be unique', (list: ApplicantSchema[]) => {
    const emails = list.map((l) => l.emailAddress)
    return emails.length === new Set(emails).size
  })

export const bookingApplicantPathParamsSchema = {
  index: bookingIdSchema,
  one: bookingIdSchema.concat(
    yup.object({
      applicantUserId: yup.string().required(),
    }),
  ),
}

export const updateBookingApplicantApplicationStatusPayloadSchema = yup.object({
  applicationStatus: yup.string().oneOf<ApplicationStatus>(Object.values(ApplicationStatus)).required(),
  reviewalComments: yup.string().when('applicationStatus', {
    is: (applicationStatus: ApplicationStatus) => applicationStatus === ApplicationStatus.CHANGE_REQUESTED,
    then: yup.string().required(),
  }),
})

export const submitBookingApplicationPathParamsSchema = yup.object({
  bookingId: yup.string().required(),
  applicantUserId: yup.string().required(),
})

export const submitBookingApplicationPayloadSchema = yup.object({
  leasePromise: yup
    .string()
    .when('$isPromiseToLeaseEnabled', (isPromiseToLeaseEnabled: boolean, schema: yup.StringSchema) =>
      isPromiseToLeaseEnabled
        ? schema.when('$isGuikerManagedBooking', {
            is: true,
            then: yup.string().required(),
            otherwise: yup.string().nullable(),
          })
        : schema.nullable(),
    ),
})

export type SubmitBookingApplicationPathParamsSchema = yup.InferType<typeof submitBookingApplicationPathParamsSchema>
export type SubmitBookingApplicationPayloadSchema = TypeOf<typeof submitBookingApplicationPayloadSchema>
