import { Actions as BaseActions, triggerBuilder } from '@guiker/trigger'

const baseTrigger = {
  context: 'LEASE_CONTRACT',
  targetType: 'LEASE_CONTRACT',
}

export const actions = {
  MARK_AS_DRAFTED: {
    type: 'MARK_AS_DRAFTED',
    payload: {} as {
      listingId: string
      scopeId: string
      scopeType: string
    },
  },
}

export const Triggers = triggerBuilder(baseTrigger).buildTriggersFromActions(actions)
export type Actions = BaseActions<typeof actions>
export type ActionTypes = keyof typeof actions
