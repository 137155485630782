import React from 'react'

import { StepperLayout, StepperLayoutProps, useTranslation } from '@guiker/react-framework'
import { TenantApplication, tenantApplicationValidator } from '@guiker/tenant-application-shared'

type TenantApplicationStepperProps = StepperLayoutProps & { tenantApplication: TenantApplication }

const getApplicationStep = (application: TenantApplication, lastStepIndex: number): number => {
  const validator = tenantApplicationValidator(application)
  if (!application || !validator.hasCompletedProfile()) {
    return 0
  } else if (!validator.hasCompletedCurrentAddressOccupation()) {
    return 1
  } else if (!validator.hasCompletedGuarantorWithDocuments() || !validator.hasCompletedBackgroundCheck()) {
    return 2
  } else if (!validator.hasCompletedSupportingDocuments()) {
    return 3
  }
  return lastStepIndex
}

const TenantApplicationStepper: React.FC<TenantApplicationStepperProps> = ({ tenantApplication, ...stepperProps }) => {
  const { t } = useTranslation(['main-tenantApplication', 'common'])
  return (
    <StepperLayout
      finalLabel={t('common:actions.complete')}
      title={t('main-tenantApplication:screens.createTenantApplication.title')}
      startStepIndex={getApplicationStep(tenantApplication, stepperProps.steps.length - 1)}
      pageLayoutProps={{
        hasButtonContainer: true,
      }}
      {...stepperProps}
    />
  )
}

export { TenantApplicationStepper, TenantApplicationStepperProps }
