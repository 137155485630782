import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

import { BookingStatus, ParticipantType } from '../../../entity'

export const readAllBookingsQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object({
    participantType: yup.string().nullable().oneOf(Object.values(ParticipantType)),
    status: yup.string().nullable().oneOf(Object.values(BookingStatus)),
  }),
)

export type ReadAllBookingsQueryParamsSchema = yup.InferType<typeof readAllBookingsQueryParamsSchema>
