import { PropertySaleListing } from '@guiker/property-sale-shared'
import { PropSharingListingWithFundingData } from '@guiker/propsharing-shared'

export const InvestmentListingType = {
  FractionalOwnershipListing: 'FractionalOwnershipListing',
  PropertySaleListing: 'PropertySaleListing',
}

type BaseInvestmentListing =
  | ({ type: 'FractionalOwnershipListing' } & PropSharingListingWithFundingData)
  | ({ type: 'PropertySaleListing' } & PropertySaleListing)

export type InvestmentListing = BaseInvestmentListing & { originalUpdatedAt?: string }
