import { useAuthenticationContext } from '@guiker/authentication-context'
import { AuthenticatedApiClient } from '@guiker/booking-shared'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { useConfig } from '@guiker/config-context'
import { v4 } from '@guiker/uuid'

const getApiClient = () => {
  const authenticationContext = useAuthenticationContext()
  const claimsContext = useClaimsAuthenticationContext()
  const config = useConfig()
  const accessToken = (claimsContext?.claims?.token as string) || authenticationContext?.user?.accessToken

  return AuthenticatedApiClient({
    accessToken: accessToken,
    baseUrl: config.apiBaseUrl,
    correlatedRequestId: v4(),
    logger: config.debug ? console : undefined,
  })
}

export { getApiClient }
