import { constantCase } from '@guiker/lodash'

export const getDefaultConfig = <DN extends string>(domainName: DN) =>
  ({
    domainName,
    apiName: `${domainName}-api`,
    serviceName: `${domainName}-service`,
    stage: process.env.STAGE,
    context: constantCase(domainName),
  } as const)
