import {
  RentPaymentsPlanWithTenantInstalments,
  TenantInstalment,
  TenantInstalmentWithRentPaymentsPlan,
} from '@guiker/rent-payment-shared'
import { Event, routesBuilder } from '@guiker/shared-framework'

import { paths } from '../paths'

const statisticPath = paths.statistic.one

const rentPaymentsPlan = routesBuilder<RentPaymentsPlanWithTenantInstalments>()({
  basePath: statisticPath.withScope('booking', ':bookingId'),
})

const tenantInstalment = routesBuilder<TenantInstalmentWithRentPaymentsPlan>()({
  basePath: statisticPath.withScope('tenantInstalment', ':tenantInstalmentId'),
})

export const routes = {
  readRentPaymentsPlanByScope: rentPaymentsPlan.buildGet({}),
  readTenantInstalmentById: tenantInstalment.buildGet({}),
  readTenantInstalmentEvents: tenantInstalment.buildGet({
    path: '/events',
    response: null as Event<TenantInstalment>,
    responseIsArray: true,
  }),
}
