import { TypeOf, yup } from '@guiker/yup-util'

import { Series, SeriesStatus, SeriesType } from '../../../entity'
import { seriesDealSchema } from './series-deal'

export const createSeriesSchema = yup
  .object<Omit<Series, 'final' | 'slug'>>({
    name: yup.string().required(),
    pricePerUnit: yup.number().required(),
    target: yup
      .object<Series['target']>({
        unitQuantity: yup.number().required(),
        equity: yup.number().required(),
        deal: seriesDealSchema.required(),
      })
      .required(),
    startDate: yup.string(),
    closeDate: yup.string(),
    status: yup
      .mixed<SeriesStatus>()
      .oneOf([SeriesStatus.COMING_SOON, SeriesStatus.OPEN])
      .required()
      .default(SeriesStatus.COMING_SOON),
    seriesType: yup.mixed<SeriesType>().oneOf(Object.values(SeriesType)).required(),
  })
  .required()

export type CreateSeriesSchema = TypeOf<typeof createSeriesSchema>
