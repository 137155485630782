import React from 'react'

import { InvitationMeta } from '@guiker/authentication-shared'
import { Card, Display3, DisplayBody, Flex, makeStyles, margin, P } from '@guiker/react-framework'

import { useInvitationSource } from './use-invitation-source'

type Props = {
  invitationMeta: InvitationMeta
}

const useStyles = makeStyles(
  (theme) => ({
    container: (args: { backgroundColor: string; imageUrl: string }) => ({
      padding: theme.spacing(4),
      height: '100%',
      backgroundColor: args.backgroundColor,
      backgroundImage: `url('${args.imageUrl}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
    }),
    content: {
      maxWidth: 900,
      margin: margin(0, 'auto'),
      padding: theme.spacing(3),
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  }),
  { name: 'MainInvitationSource' },
)

export const InvitationSource: React.FC<Props> = ({ invitationMeta }) => {
  const { imageUrl, backgroundColor, title, contents } = useInvitationSource(invitationMeta)
  const classes = useStyles({ imageUrl, backgroundColor })

  return (
    <Flex className={classes.container} alignItems='center' fullWidth>
      <Card className={classes.content}>
        <Display3 color={'textSecondary'}>{title}</Display3>
        <DisplayBody>
          {contents.map((content) => (
            <P color={'textSecondary'}>{content}</P>
          ))}
        </DisplayBody>
      </Card>
    </Flex>
  )
}
