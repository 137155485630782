import { DealType } from '../../../../listing'
import { CostArgs } from '../../../utils'
import { CanadaPurchaseTaxes } from '../purchase-taxes'
import { GST, QST, WelcomeTax } from './expense'

export const QuebecPurchaseTaxes = (args: CostArgs) => ({
  ...CanadaPurchaseTaxes,
  ...([DealType.NEW, DealType.PRECON].includes(args.dealType) ? { QST, GST } : undefined),
  welcomeTax: WelcomeTax,
})
