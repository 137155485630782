enum CurrencyISO {
  CAD = 'CAD',
  USD = 'USD',
}

interface Currency {
  iso: CurrencyISO
  symbol: string
  sign: string
  decimalPlace: number
  separator: {
    position: number
    delimiter: string
  }
}

type SupportedCurrency = {
  [iso in CurrencyISO]: Currency
}

const currency: SupportedCurrency = {
  CAD: {
    iso: CurrencyISO.CAD,
    symbol: 'CA$',
    sign: '$',
    decimalPlace: 2,
    separator: {
      position: 3,
      delimiter: ',',
    },
  },
  USD: {
    iso: CurrencyISO.USD,
    symbol: 'US$',
    sign: '$',
    decimalPlace: 2,
    separator: {
      position: 3,
      delimiter: ',',
    },
  },
}

export { CurrencyISO, Currency, currency }
