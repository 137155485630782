import { useCallback } from 'react'

import { EventPattern } from './event-pattern'

type EventHandler = (eventPattern: EventPattern) => any

export interface WebsocketListenerManager {
  getListeners: (eventPattern: EventPattern) => EventHandler[]
  addListener: (eventPattern: EventPattern, callback: EventHandler) => void
  removeListener: (eventPattern: EventPattern, callback: EventHandler) => void
}

interface EventListenerMap {
  [id: string]: EventHandler[]
}

const eventListenerMap: EventListenerMap = {}

const useWebsocketListenerManager = (): WebsocketListenerManager => {
  const getListeners = (eventPattern: EventPattern): EventHandler[] => {
    const result = []

    for (let i = 0; i < eventPattern.length; i++) {
      const slicedEventPattern = eventPattern.slice(0, i + 1)
      const mapKey = JSON.stringify(slicedEventPattern)

      result.push(...(eventListenerMap[mapKey] || []))
    }

    return result
  }

  const addListener = useCallback((eventPattern: EventPattern, callback: EventHandler) => {
    const mapKey = JSON.stringify(eventPattern)
    if (!eventListenerMap[mapKey]) {
      eventListenerMap[mapKey] = []
    }

    eventListenerMap[mapKey].push(callback)
  }, [])

  const removeListener = useCallback((eventPattern: EventPattern, callback: EventHandler) => {
    const mapKey = JSON.stringify(eventPattern)
    if (eventListenerMap[mapKey]) {
      eventListenerMap[mapKey] = eventListenerMap[mapKey].filter((entry) => entry !== callback)
    }
  }, [])

  return {
    getListeners,
    addListener,
    removeListener,
  }
}

export { useWebsocketListenerManager }
