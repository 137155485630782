import { Payer, TenantInstalmentEventHistoryError } from '../../entity'

export const RentPaymentsPlanEventTypes = {
  ACTIVATED: 'ACTIVATED',
  ACTIVATION_FAILED: 'ACTIVATION_FAILED',
  CONTRIBUTION_RESPLIT_REQUESTED: 'CONTRIBUTION_RESPLIT_REQUESTED',
  CONTRIBUTION_RESPLIT_COMPLETED: 'CONTRIBUTION_RESPLIT_COMPLETED',
  INSTALMENTS_EXTENDED: 'INSTALMENTS_EXTENDED',
  INSTALMENTS_REDUCED: 'INSTALMENTS_REDUCED',
  RECEIVER_CHANGED: 'RECEIVER_CHANGED',
  UNIT_UPDATED: 'UNIT_UPDATED',
  PAYER_PAY_IN_METHOD_CHANGED: {
    type: 'PAYER_PAY_IN_METHOD_CHANGED' as const,
    eventData: null as {
      payerUserId: Payer['userId']
      payInMethod: Payer['payInMethod']
    },
  },
  PAYER_UPDATED: {
    type: 'PAYER_UPDATED' as const,
    eventData: null as {
      payers: Payer[]
      error?: TenantInstalmentEventHistoryError & { userId: string }
    },
  },
} as const
