import { TypeOf, yup } from '@guiker/yup-util'

import { LeaseRoleNames, Quebec, Signature } from '../../../entity'
import { signature } from '../../schemas'

export const newLesseeNotice = yup.object({
  lowestPrecedingRent: yup.number().nullable(),
  lowestPrecedingRentFrequency: yup
    .mixed<Quebec.Frequency>()
    .oneOf([...Object.values(Quebec.Frequency), null])
    .when('lowestPrecedingRent', (lowestPrecedingRent: number | null, schema: yup.MixedSchema<Quebec.Frequency>) => {
      if (!lowestPrecedingRent) {
        return schema.nullable().transform(() => null as null)
      } else {
        return schema.required()
      }
    }),
  lowestPrecedingRentFrequencyOther: yup.string().when('lowestPrecedingRentFrequency', {
    is: Quebec.Frequency.other,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
  sameCondition: yup
    .boolean()
    .when('lowestPrecedingRent', (lowestPrecedingRent: number | null, schema: yup.BooleanSchema) => {
      if (!lowestPrecedingRent) {
        return schema.nullable().transform(() => null as null)
      } else {
        return schema.required()
      }
    }),
  differentConditions: yup.string().when('sameCondition', {
    is: false,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
  lessorSignature: signature.when(
    'lowestPrecedingRent',
    (lowestPrecedingRent: number | null, schema: yup.ObjectSchema<Signature>) => {
      if (!lowestPrecedingRent) {
        return schema.nullable().transform(() => null as null)
      } else {
        return schema.when('draftedAs', {
          is: LeaseRoleNames.DRAFTER,
          then: schema.nullable().transform(() => null as null),
          otherwise: schema,
        })
      }
    },
  ),
  draftedAs: yup
    .mixed<LeaseRoleNames.DRAFTER | LeaseRoleNames.LESSOR>()
    .oneOf([LeaseRoleNames.DRAFTER, LeaseRoleNames.LESSOR])
    .required(),
})

export type NewLesseeNotice = TypeOf<typeof newLesseeNotice>
