export enum BookingRoleNames {
  LISTING_OPERATOR = 'LISTING_OPERATOR',
  AGENT = 'AGENT',
  LANDLORD = 'LANDLORD',
  UNIT_MANAGER = 'UNIT_MANAGER',
  INVITED_LANDLORD = 'INVITED_LANDLORD',
  LEASING_ASSOCIATE = 'LEASING_ASSOCIATE',
  APPLICANT = 'APPLICANT',
  SPECTATOR = 'SPECTATOR',
}
