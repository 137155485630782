import React from 'react'

import { fade, isAtMostTablette, isLargeScreenDesktop, isMobile, toPx } from '@guiker/components-core'
import { useStaticAssetContext } from '@guiker/static-asset-context'

import { Flex, makeStyles } from '../../../components'
import { InvestorKpi } from './InvestorKpi'

const elements = [
  {
    i18nKey: 'kpi2',
    value: '500+',
  },
  {
    i18nKey: 'kpi1',
    value: '1,000+',
  },
  {
    i18nKey: 'kpi3',
    value: '$125M',
  },
]

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      padding: `${theme.spacing(8)}px ${theme.spacing(10)}px`,
      width: `calc(100% + ${toPx(
        theme.spacing(theme.dimensions.pageLayout.padding.desktop.left) +
          theme.spacing(theme.dimensions.pageLayout.padding.desktop.right),
      )})`,
      left: -theme.spacing(theme.dimensions.pageLayout.padding.desktop.left),
      backgroundImage: ({ backgroundImage }: { backgroundImage: string }) => `url("${backgroundImage}")`,
      backgroundRepeat: 'round',
      backgroundPosition: 'center',
      [isMobile]: {
        padding: `${theme.spacing(8)}px ${theme.spacing(3)}px`,
        left: -theme.spacing(theme.dimensions.pageLayout.padding.mobile.left),
        width: `calc(100% + ${toPx(
          theme.spacing(theme.dimensions.pageLayout.padding.mobile.left) +
            theme.spacing(theme.dimensions.pageLayout.padding.mobile.right),
        )})`,
      },
      [isLargeScreenDesktop]: {
        width: '100%',
        left: 'initial',
        padding: `${theme.spacing(5)}px ${theme.spacing(20)}px`,
      },
    },
    content: {
      padding: theme.spacing(4),
      backgroundColor: fade(theme.palette.background.primary, 0.8),
      justifyContent: 'space-between',
      width: '100%',
      gap: theme.spacing(5),
      [isLargeScreenDesktop]: {
        gap: theme.spacing(10),
      },
      [isAtMostTablette]: {
        flexDirection: 'column',
      },
    },
    index: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'InvestorKpis' },
)

const InvestorKpis: React.FC = () => {
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('investors')
  const classes = useStyles({ backgroundImage: assets.pattern })

  return (
    <Flex className={classes.root}>
      <Flex className={classes.content}>
        {elements.map((el) => (
          <InvestorKpi key={el.i18nKey} i18nKey={el.i18nKey} value={el.value} />
        ))}
      </Flex>
    </Flex>
  )
}

export { InvestorKpis }
