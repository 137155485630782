import React from 'react'

import { Helmet, PageMetaTags } from '@guiker/react-framework'

import { useStaticAssetContext, useTranslation } from '../../hooks'

const AppMetaTags: React.FC = () => {
  const { getAsset } = useStaticAssetContext()
  const { t } = useTranslation('webapp')
  const assets = getAsset('root')

  return (
    <>
      <Helmet>
        <meta property='og:site_name' content='Guiker'></meta>
        <meta property='og:type' content='website'></meta>
      </Helmet>
      <PageMetaTags subtitle={t('screens.home.seo.subtitle')} imageUrl={assets.meta} />
    </>
  )
}

export { AppMetaTags }
