export enum IncomeSource {
  Employment = 'Employment',
  Support = 'Support',
  None = 'None',
}

export type Financial = {
  incomeSource?: IncomeSource
  annualIncome?: {
    amount: number
    currency: string
  }
}
