import React from 'react'

import { RouteConfig } from '@guiker/router'

import { CityNested, CountryNested, NeighbourhoodNested } from './LocationNestedScreens'

type BuildRoutes = {
  defaultRouteConfig?: Partial<RouteConfig>
  routes: {
    router: React.FC
    path: {
      id: React.FC
      slugified: React.FC
      country?: React.FC
      city?: React.FC
      neighbourhood?: React.FC
    }
  }
}

const rootNestedRoute = (rootNestedScreen?: React.FC) => {
  if (!rootNestedScreen) return

  return {
    root: {
      path: '/',
      Component: rootNestedScreen,
    },
  }
}

export const buildLocationNestedRoutes = ({
  defaultRouteConfig = {
    requireAuthentication: false,
    requireAccountActivation: false,
    exact: false,
  },
  routes,
}: BuildRoutes) => ({
  defaultRouteConfig,
  countryNested: {
    path: '/:countryCode',
    Component: CountryNested,
    routes: {
      ...rootNestedRoute(routes.path.city),
      cityNested: {
        path: '/:citySlug',
        Component: CityNested,
        routes: {
          ...rootNestedRoute(routes.path.city),
          neighbourhoodNested: {
            path: '/:neighbourhoodSlug',
            Component: NeighbourhoodNested,
            routes: {
              ...rootNestedRoute(routes.path.neighbourhood),
              idNested: {
                path: '/:id',
                Component: routes.router,
                routes: {
                  id: {
                    path: '/',
                    Component: routes.path.id,
                  },
                  slugified: {
                    path: '/:slug',
                    Component: routes.path.slugified,
                    routes: {},
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
