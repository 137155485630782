import React from 'react'

import { Redirect } from '@guiker/router'

import { LogInForm, PageLayout, PageMetaTags } from '../../components'
import { useRedirectOnAuthenticate } from '../../hooks'
import { useTranslation } from '../../i18n'
import { routes } from '../../routes'

const LogIn: React.FC = () => {
  const { t } = useTranslation('webapp')
  const { redirectTo } = useRedirectOnAuthenticate()

  if (redirectTo) {
    return <Redirect reload={true} path={redirectTo.path} />
  }

  return (
    <>
      <PageMetaTags subtitle={t('login.seo.subtitle')} description={t('login.seo.description')} />
      <PageLayout title={t('login.title')} maxWidth={900}>
        <LogInForm
          switchAuthTypeUrl={routes.SignUp.path}
          forgetPasswordUrl={routes.RequestForgotPassword.path}
          privacyPolicyUrl={routes.PrivacyPolicy.path}
          termsAndConditionUrl={routes.TermsAndConditions.path}
        />
      </PageLayout>
    </>
  )
}

export { LogIn }
