import { flatMap } from '@guiker/lodash'

import { RentalListing, TransformedRentalOption } from '../entity'
import { transformRentalOptions } from './transform-rental-options'

const initializeDropdownRentalOptions = (listing: RentalListing) => {
  const rentalOptionsArray = transformRentalOptions(listing.rentalOptions)

  return flatMap(
    rentalOptionsArray.map((rentalOption) =>
      rentalOption.priceOptions
        .sort((a, b) => a.price.amount - b.price.amount)
        .map((priceOption) => ({
          label: undefined,
          value: priceOption.legacyOptionId.toString(),
          roomCount: priceOption.roomCount,
          price: priceOption.price,
          duration: priceOption.duration,
          availablityDate: priceOption.availablityDate,
          expirationDate: priceOption.expirationDate,
          furnished: priceOption.furnished,
          suiteNumber: priceOption.suiteNumber,
          type: priceOption.type,
          size: priceOption.size,
        })),
    ),
  )
}

const rebuildRentalOptions = (value: string, rentalOptions: TransformedRentalOption[]) => {
  let selectedOption: TransformedRentalOption
  const rentalOptionsClone = [...rentalOptions].sort((a, b) => {
    if (a.roomCount.bedroom > b.roomCount.bedroom) return 1
    if (a.roomCount.bedroom < b.roomCount.bedroom) return -1
    if (a.roomCount.bathroom > b.roomCount.bathroom) return 1
    if (a.roomCount.bathroom < b.roomCount.bathroom) return -1
    if (a.price > b.price) return 1
    if (a.price < b.price) return -1
  })

  const filteredRentalOptions = rentalOptionsClone.filter((rentOption) => {
    if (rentOption.value === value) {
      selectedOption = rentOption
    }
    return rentOption.value !== value
  })

  filteredRentalOptions.unshift(selectedOption)
  return {
    filteredRentalOptions,
    selectedOption,
  }
}

export { rebuildRentalOptions, initializeDropdownRentalOptions }
