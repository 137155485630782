import { yup } from '@guiker/yup-util'

import { ListingOperator } from '../../entity'

export const listingOperatorSchema = yup.object<ListingOperator>({
  userId: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string().required(),
  role: yup.string().oneOf(['OWNER', 'AGENT', 'MANAGEMENT']).required(),
})
