import { yup } from '@guiker/yup-util'

import { BuildingPreferences, Parking } from '../../entity'
import { nullableBoolean } from './common'

export const buildingPreferences = yup.object<BuildingPreferences>({
  elevator: nullableBoolean,
  gym: nullableBoolean,
  pool: nullableBoolean,
  recreationRoom: nullableBoolean,
  storage: nullableBoolean,
  wheelchairAccessible: nullableBoolean,
  parking: yup.string().oneOf<Parking>([Parking.indoor, Parking.outdoor, null]).nullable(),
})

export type BuildingPreferencesSchema = yup.InferType<typeof buildingPreferences>
