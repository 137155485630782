import React, { PropsWithChildren } from 'react'

import { PartialAsset } from '@guiker/asset-shared'
import { useBulkAssetUploaderContext } from '@guiker/asset-uploader'
import { Step } from '@guiker/components-library'
import { ApiForm } from '@guiker/react-framework'

import { OnSubmit } from '../IdentityVerification'
import { DocumentTypes, useIdentityVerificationContext } from '../IdentityVerificationContext'
import { IdentityVerificationDocuments } from './IdentityVerificationDocuments'

type IdentityVerificationDocumentsFormProps = PropsWithChildren & {
  asStep?: boolean
  onSubmit?: OnSubmit
  onSuccess?: () => void
  scope: { type: string; id: string }
}

export const IdentityVerificationDocumentsForm: React.FC<Omit<IdentityVerificationDocumentsFormProps, 'scope'>> = ({
  asStep,
  onSubmit: onSubmitProps,
  onSuccess,
  ...props
}) => {
  const { bulkUpdate, isUploading, isFetching } = useBulkAssetUploaderContext()
  const { documentType } = useIdentityVerificationContext()

  const onSubmit = ({
    documentType,
    facePicture,
    governmentIdBack,
    governmentIdFront,
  }: {
    documentType: DocumentTypes
    facePicture: PartialAsset[]
    governmentIdBack: PartialAsset[]
    governmentIdFront: PartialAsset[]
  }) => {
    return onSubmitProps({
      documentType,
      facePicture: facePicture[0],
      governmentIdFront: governmentIdFront[0],
      governmentIdBack: governmentIdBack ? governmentIdBack[0] : null,
    })
  }

  return (
    <ApiForm
      formName='IdentityVerification'
      formOptions={{
        defaultValues: {
          documentType,
        },
      }}
      skipIfIsNotDirty={false}
      onSubmit={onSubmit}
      beforeValidation={() => bulkUpdate()}
      apiOptions={{
        onSuccess,
      }}
    >
      {({ formState: { isSubmitting, isSubmitSuccessful, errors } }) => (
        <Step
          isLoading={isFetching}
          isSubmitting={isUploading || isSubmitting || isSubmitSuccessful}
          errors={errors}
          {...props}
        >
          <IdentityVerificationDocuments />
        </Step>
      )}
    </ApiForm>
  )
}
