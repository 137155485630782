import { yup } from '@guiker/yup-util'

export const repliersConfigSchema = yup
  .object({
    apiKey: yup.string().required(),
    url: yup.string().required(),
    webhookSecrets: yup
      .object({
        'listing.created': yup.string().nullable(),
        'listing.deleted': yup.string().nullable(),
        'listing.updated': yup.string().nullable(),
      })
      .nullable(),
  })
  .required()
