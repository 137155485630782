import { Location } from '@guiker/base-entity'
import { ISO8601Date } from '@guiker/date'
import { RentalType } from '@guiker/rental-listing-shared'

const buildSearchListingsUrl = (
  location: Location,
  options: {
    bedroomCount?: string | number
    moveInDate?: ISO8601Date
    rentalTypes?: RentalType[]
    zoom?: number
  } = {},
) => {
  const { bedroomCount, moveInDate, rentalTypes, zoom } = options
  let searchUrl = `/listings?city=${location.city.slug}&latitude=${location.city.coordinates.latitude}&longitude=${location.city.coordinates.longitude}`

  if (bedroomCount && bedroomCount !== 'any') {
    searchUrl = `${searchUrl}&bcount=${bedroomCount}`
  }

  if (moveInDate) {
    searchUrl = `${searchUrl}&moveInDate=${moveInDate}`
  }

  if (rentalTypes?.length > 0) {
    searchUrl = `${searchUrl}&${rentalTypes.map((rt) => `cts=${rt}`).join('&')}`
  }

  if (zoom) {
    searchUrl = `${searchUrl}&zoom=${zoom}`
  }

  return searchUrl
}

export { buildSearchListingsUrl }
