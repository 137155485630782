import React from 'react'

import { ScopeType } from '@guiker/payment-shared'
import { Flex, KeyValuePair, P, useT } from '@guiker/react-framework'

type Props = {
  scopeType: ScopeType
}

export const WireTransferInstruction: React.FC<Props> = ({ scopeType }) => {
  const { tShared } = useT({ domain: 'payment', entity: 'wireTransfer' })

  if (scopeType === ScopeType.BUY_STOCK_ORDER) {
    return (
      <Flex flexDirection='column' gap={2}>
        <P mb={2}>{tShared('buyStockOrder.note')}</P>
        <KeyValuePair keyName={tShared('CA.recipientName')} value={tShared('buyStockOrder.recipientName')} />
        <KeyValuePair keyName={tShared('CA.accountHolder')} value={tShared('buyStockOrder.accountHolder')} />
        <KeyValuePair keyName={tShared('CA.recipientAddress')} value={tShared('buyStockOrder.recipientAddress')} />
        <KeyValuePair keyName={tShared('CA.bankName')} value={tShared('buyStockOrder.bankName')} />
        <KeyValuePair keyName={tShared('CA.branchAddress')} value={tShared('buyStockOrder.branchAddress')} />
        <KeyValuePair keyName={tShared('CA.insitutionNumber')} value={tShared('buyStockOrder.insitutionNumber')} />
        <KeyValuePair keyName={tShared('CA.branchNumber')} value={tShared('buyStockOrder.branchNumber')} />
        <KeyValuePair keyName={tShared('CA.transitNumber')} value={tShared('buyStockOrder.transitNumber')} />
        <KeyValuePair keyName={tShared('CA.accountNumber')} value={tShared('buyStockOrder.accountNumber')} />
        <KeyValuePair keyName={tShared('CA.swiftCode')} value={tShared('buyStockOrder.swiftCode')} />
      </Flex>
    )
  }

  return <></>
}
