import React from 'react'

import { Button, Link, Note, useTranslation } from '@guiker/react-framework'
import { Task } from '@guiker/task-shared'

import { taskMapping } from '../utils'

type TaskProp = {
  task: Task
}

export const Highlight: React.FC<TaskProp> = ({ task }) => {
  const { t } = useTranslation('common-task')
  const copy = taskMapping(task, t)
  return (
    <Note
      title={copy.header}
      actions={
        <Link to={copy.path} hoverUnderline={false}>
          <Button>{copy.cta}</Button>
        </Link>
      }
    >
      {copy.body}
    </Note>
  )
}
