import { routesBuilder } from '@guiker/shared-framework'

import { BatchCharge } from '../../../entity'
import { paths } from '../paths'
import { routes as nestedRoutes } from './:batchChargeId'
import { createBatchChargePayloadSchema } from './schemas'

const { buildGet, buildPost } = routesBuilder<BatchCharge>()({
  basePath: paths.batchCharges.path,
})

export const routes = {
  ...nestedRoutes,
  readAllBatchCharges: buildGet({ paginated: true }),
  createBatchCharge: buildPost({ payloadSchema: createBatchChargePayloadSchema }),
} as const
