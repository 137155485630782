import { CurrencyISO, Payment, yup } from '@guiker/shared-framework'

import { customerSchema, itemSchema, receiverSchema } from './base-schemas'

const metadataSchema = yup
  .object({
    tenantInstalmentId: yup.string().required(),
    listingId: yup.string().required(),
    unitId: yup.string().required(),
    customer: yup.string().required(),
    description: yup.string().required(),
  })
  .required()

export const createInvoiceWithScopePayloadSchema = yup
  .object({
    currency: yup.mixed().oneOf(Object.values(CurrencyISO)).required(),
    items: yup.array().of(itemSchema).required(),
    customer: customerSchema,
    metadata: metadataSchema,
    shouldVerify: yup.boolean().required(),
    autoChargeEnabled: yup.boolean(),
    receiver: receiverSchema.required(),
    payInMethod: yup
      .object({
        id: yup.string().required(),
        type: yup.string().oneOf(Object.values(Payment.PayInMethodType)).required(),
      })
      .required(),
  })
  .required()

export type CreateInvoiceWithScopePayloadSchema = yup.InferType<typeof createInvoiceWithScopePayloadSchema>
