import * as yup from 'yup'

import { DateTime } from '@guiker/date'

import { Initials } from '../../entity'

export const initials = yup.object<Initials>({
  content: yup.string().required(),
  date: yup.string().transform((value) => (value ? value : DateTime.local().toString())),
})
