import React from 'react'

import { PRIMARY_LANGUAGE, SupportedLanguages, supportedLanguages } from '@guiker/base-entity'
import { safelyGetWindow } from '@guiker/react-framework'
import { Outlet, Redirect, useLocation, useParams } from '@guiker/router'

import { PageMetaTags } from '../components'
import { i18n } from '../i18n'

const LocaleNested: React.FC = () => {
  const { locale } = useParams()
  const { pathname, search } = useLocation()
  const primaryLanguageTag = locale.split('-')[0]
  const isLocale = primaryLanguageTag && supportedLanguages.includes(primaryLanguageTag as SupportedLanguages)
  const isMainLanguage = primaryLanguageTag === PRIMARY_LANGUAGE
  let currentLanguageTag = i18n.language?.split('-')[0]
  const isCurrentLanguageSupported = supportedLanguages.includes(currentLanguageTag as SupportedLanguages)

  if (isLocale && primaryLanguageTag !== currentLanguageTag) {
    i18n.changeLanguage(primaryLanguageTag)
  } else if (!isLocale) {
    currentLanguageTag = isCurrentLanguageSupported ? currentLanguageTag : PRIMARY_LANGUAGE
    return <Redirect replace path={`/${currentLanguageTag}${pathname.replace(/\/$/, '')}${search}`} />
  }

  const { host, protocol } = safelyGetWindow()?.location || {}
  const hostName = host && protocol ? `${protocol}//${host}` : null

  return (
    <>
      <PageMetaTags
        noIndex={!isMainLanguage}
        canonicalUrl={!isMainLanguage ? `${hostName}${pathname.replace(/^\/[^\/]+\//, `/${PRIMARY_LANGUAGE}/`)}` : null}
        localizedUrls={
          hostName &&
          supportedLanguages
            .filter((language) => language !== PRIMARY_LANGUAGE)
            .map((language) => ({
              hrefLang: language,
              href: `${hostName}${pathname.replace(/^\/[^\/]+\//, `/${language}/`)}`,
            }))
        }
      />
      <Outlet />
    </>
  )
}

export { LocaleNested }
