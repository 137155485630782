import { HttpMethod } from '@guiker/http'

import { TenantApplication } from '../../entity'
import { paths } from './paths'
import { tenantApplicationByUserId } from './schemas'

const responseValidator = (payload: unknown) => Promise.resolve(payload as TenantApplication | undefined)

export const routes = {
  readOneTenantApplication: {
    path: paths.tenantApplication.one.withScope('user', ':userId'),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => tenantApplicationByUserId.validate(payload),
  },
}
