import { Role, RoleNameRoleResolver } from '@guiker/permissions'

import { BookingRoleNames, Roles } from '../permissions'

export const BookingRoleNamesRoleResolver: RoleNameRoleResolver<BookingRoleNames> = ({ roleNames }): Role[] => {
  if (!roleNames || roleNames.length === 0) {
    return []
  }

  const roles: Role[] = roleNames.map((name) => {
    switch (name) {
      case BookingRoleNames.UNIT_MANAGER:
        return Roles.UnitManagerRole
      case BookingRoleNames.AGENT:
        return Roles.AgentRole
      case BookingRoleNames.LANDLORD:
        return Roles.LandlordRole
      case BookingRoleNames.INVITED_LANDLORD:
        return Roles.InvitedLandlordRole
      case BookingRoleNames.LEASING_ASSOCIATE:
        return Roles.LeasingAssociateRole
      case BookingRoleNames.LISTING_OPERATOR:
        return Roles.ListingOperatorRole
    }
  })

  return roles
}
