import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '..'

export const { routes: mainRoutes, pathBuilder: mainPathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        byId: {
          path: '/:bookingId',
          routes: {
            embedded: '/embedded',
            applications: '/booking-applications',
            roommateIntroReview: '/roommate-intro-review',
            applicants: {
              path: '/applicants',
              routes: {
                edit: '/edit',
                view: '/application',
                applications: '/applications',
                byApplicantUserId: {
                  path: '/:userId',
                  routes: {
                    application: '/application',
                    roommateProfile: '/roommate-profile',
                  },
                },
              },
            },
            lease: {
              path: '/lease',
              routes: {
                guarantor: '/guarantor',
                invitedLessor: '/invited-lessor',
                sign: '/sign',
                view: '/view',
              },
            },
            document: {
              path: '/document',
              routes: {
                new: '/new',
                view: '/documents',
              },
            },
            payment: {
              path: '/booking-payment',
              routes: {
                contribution: '/contribution',
                reconnect: '/reconnect',
              },
            },
            rentPayment: {
              path: '/rent-payment',
              routes: {
                invoice: {
                  path: '/invoice',
                  routes: {
                    byId: {
                      path: '/:invoiceId',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
