import { Gender } from '@guiker/base-entity'
import { yup } from '@guiker/yup-util'

import { TenantApplicationProfile } from '../../entity'

export const genderSchema = yup.object<TenantApplicationProfile['gender']>({
  classification: yup.mixed().oneOf(Object.values(Gender)).required(),
  other: yup.string().when('classification', {
    is: Gender.OTHER,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
})
