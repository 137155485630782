import React from 'react'

import { SupportingDocument } from '@guiker/tenant-application-shared'

import { useBulkAssetUploaderContext } from '../../../hooks'
import { SupportingDocumentsContent } from '..'

type Props = {
  editRoute?: string
}

const Documents: React.FC<Props> = ({ editRoute }) => {
  const { assetGroups } = useBulkAssetUploaderContext()

  return (
    <SupportingDocumentsContent defaultValue={assetGroups as SupportingDocument[]} editRoute={editRoute} readOnly />
  )
}

export { Documents }
