import { yup } from '@guiker/yup-util'

export const profileSchema = yup.object({
  birthDate: yup
    .string()
    .transform((d) => d?.split('T')[0])
    .required(),
  accountHolderName: yup.string().required(),
  billingAddress: yup.object({
    nickName: yup.string().notRequired().nullable(),
    street2: yup.string().notRequired().nullable(),
    streetNumber: yup.string().required(),
    streetName: yup.string().required(),
    city: yup.string().required(),
    zip: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
  }),
})
