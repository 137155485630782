import { TypeOf, yup } from '@guiker/yup-util'

import { email } from './email'

export const logIn = yup
  .object({
    email,
    password: yup.string().trim().min(8).max(20).required(),
  })
  .required()

export type LogIn = TypeOf<typeof logIn>
