import { Booking } from '../booking'
import { BookingPayment } from '../booking-payment'
import { ContributionStatus } from '../contribution'
import { inferApplicantStepStatus as inferApplicantPaymentStepStatus } from '../contribution'
import { InvitationStatus } from '../participant'

export const getPaymentVerifications = (
  booking: Booking,
): {
  userId: string | null
  userName: string
  status: ContributionStatus
  isWaived: boolean | undefined
  amount: number | null
  stepStatus: ReturnType<typeof inferApplicantPaymentStepStatus>
}[] => {
  const applicants = booking.applicants
  const contributions = booking.bookingPayment.contributions

  return applicants
    .filter((a) => a.invitation.status === InvitationStatus.ACCEPTED)
    .map(({ userId, firstName, lastName }) => {
      const contribution = contributions.find((contribution) => contribution.userId === userId)

      return {
        userId: userId,
        userName: `${firstName} ${lastName ? lastName : ''}`,
        stepStatus: inferApplicantPaymentStepStatus(contribution?.status),
        status: contribution!.status,
        amount: contribution!.amount,
        isWaived: contribution?.isWaived,
      }
    })
}

export const canWaivePayment = ({ status }: { status: ContributionStatus }) => {
  return [
    ContributionStatus.INITIAL_STATE,
    ContributionStatus.CONTRIBUTION_INCOMPLETE,
    ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION,
    ContributionStatus.PAY_IN_METHOD_REJECTED,
  ].includes(status)
}

export const checkIfChargingProrataFirst = ({ bookingPayment }: { bookingPayment: BookingPayment }) => {
  const { instalments } = bookingPayment

  if (instalments?.length < 2) {
    return false
  }

  return instalments[0].amount < instalments[1].amount
}

export const hasRentPaymentsPlanSupport = ({ bookingPayment }: { bookingPayment: BookingPayment }) => {
  const { directDebitACH, directDebitPAD } = bookingPayment.eligibility
  return directDebitACH || directDebitPAD
}
