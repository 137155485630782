import { routesBuilder } from '@guiker/shared-framework'

import { NotificationPreference } from '../../../entity'
import { updateNotificationPreference } from '../../schemas'
import { paths } from './paths'

const { buildGet, buildPut } = routesBuilder<NotificationPreference>()({
  basePath: paths.notificationPreference.one.base(),
})

export const routes = {
  readNotificationPreference: buildGet({}),
  upsertNotificationPreference: buildPut({
    payloadSchema: updateNotificationPreference,
  }),
}
