import { yup } from '@guiker/shared-framework'

export const searchStringSchema = yup.object<{
  fundingAccountId?: string
  searchString?: string
}>({
  fundingAccountId: yup
    .string()
    .notRequired()
    .transform((v) => (!!v ? v : undefined)),
  searchString: yup
    .string()
    .notRequired()
    .transform((v) => (!!v ? v : undefined)),
})

export type SearchStringSchema = yup.InferType<typeof searchStringSchema>
