import { Gender } from './gender'

export enum AgeGroup {
  LATE = 'LATE',
  EARLY = 'EARLY',
  MID = 'MID',
}

export enum Interest {
  SPORTS = 'SPORTS',
  WORKOUT = 'WORKOUT',
  COOKING = 'COOKING',
  GARDENING = 'GARDENING',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  GAMING = 'GAMING',
  MUSIC = 'MUSIC',
  TECHNOLOGY = 'TECHNOLOGY',
  DANCING = 'DANCING',
  PAINTING = 'PAINTING',
  AUTOMOBILES = 'AUTOMOBILES',
  PARTYING = 'PARTYING',
  READING = 'READING',
  SLEEPING = 'SLEEPING',
  MOVIES = 'MOVIES',
  TRAVELLING = 'TRAVELLING',
}

export enum Cleanliness {
  SOMEWHAT = 'SOMEWHAT',
  MODERATE = 'MODERATE',
  EXTREMELY = 'EXTREMELY',
}

export enum SleepSchedule {
  EARLY_RISER = 'EARLY_RISER',
  SLEEPS_IN = 'SLEEPS_IN',
  EARLY_BEDTIME = 'EARLY_BEDTIME',
  NIGHT_OWL = 'NIGHT_OWL',
}

export enum PetConsent {
  YES = 'YES',
  NO = 'NO',
  DOGS_ONLY = 'DOGS_ONLY',
  CATS_ONLY = 'CATS_ONLY',
}

export enum HangingWithFriends {
  MOST = 'MOST',
  SMALL_GROUP = 'SMALL_GROUP',
  COMPANY = 'COMPANY',
  ALONE = 'ALONE',
}

export type Roommate = {
  firstName: string
  userId: string
  age?: {
    range: number
    boundary: AgeGroup
  }
  gender?: {
    classification: Gender
    other: string
  }
  livingWithPets?: PetConsent
  friendLevel?: HangingWithFriends
  sleepSchedule?: SleepSchedule[]
  interests?: Interest[]
  cleanliness?: Cleanliness
  personalDescription?: string
  address: {
    apartmentNumber?: string
    room?: string
    city: string
    countryCode: 'CA' | 'US'
    postalCode: string
    state?: string
    street: string
    streetNumber: string
  }
  moveInDate: string
  moveOutDate: string
  emailAddress?: string
}
