import { StepTypes } from './booking'

export enum InvitationStatus {
  ACCEPTED = 'ACCEPTED',
  INVITED = 'INVITED',
  REJECTED = 'REJECTED',
}

export enum ParticipantStepStatus {
  NOT_READY = 'NOT_READY',
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  PROCESSING = 'PROCESSING',
  FINALIZED = 'FINALIZED',
  FAILED = 'FAILED',
}

export enum ParticipantType {
  APPLICANT = 'APPLICANT',
  UNIT_MANAGER = 'UNIT_MANAGER',
}

export type Steps = {
  [K in StepTypes]?: {
    status: Partial<ParticipantStepStatus>
  }
}

export interface Participant {
  avatarUrl?: string | null
  emailAddress: string
  firstName: string
  lastName?: string | null
  invitation: {
    status: InvitationStatus
  }
  userId: string | null
  steps?: Steps
}
