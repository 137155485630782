import { parseBoolean } from '@guiker/shared-framework'

import { Config } from './config'

let baseConfig: Config = {
  logger: console,
  analytics: {
    enabled: false,
    debug: false,
    gtmId: 'GTM-P6PHXB5',
  },
  apiBaseUrl: `https://api.${process.env.REACT_APP_STAGE}.guiker.com`,
  appBaseUrl: 'https://staging.guiker.com',
  resourcesBaseUrl: 'https://resources.guiker.com',
  debug: true,
  docusign: {
    prepareUrl: 'https://appdemo.docusign.com/parpare',
    templateUrl: 'https://appdemo.docusign.com/templates',
  },
  google: {
    mapApiKey: 'AIzaSyB8XNlqT6TUCl_zZgFTxCUMP8HdbrQ5ziw',
  },
  legacyApiUrl: 'https://staging-server.guiker.com',
  legacyAssetBaseUrl: 'https://s3.amazonaws.com/guiker.staging.resources',
  plaid: {
    env: 'sandbox',
    publicKey: 'ec45549a482d8977a9a87773a8cc60',
    clientName: 'Guiker',
  },
  stripe: {
    US: {
      publishableKey:
        'pk_test_51HDabeGBsoDwgLanAbjqFdyaqxvsJ3rfCXIF1GlxRtSX25kP49TWQNuCtgKWIT49Wo1xj7Lje388eO6lsGTRGFME00n3AECWjh',
    },
    CA: {
      publishableKey: 'pk_test_xGPQA6pTQpgemZAm9yxzYhMR',
    },
  },
  reloadOnNotFoundPath: process.env.REACT_APP_DISABLE_NOT_FOUND_RELOAD ? false : true,
  recaptchaSiteKey: '6LcDH7cUAAAAAHoTixZqXxtJuaaAe5VmyQXXyjkG',
  sentry: {
    enabled: false,
    dsn: 'https://5968f60838f2428b8c97633202723c41@o90488.ingest.sentry.io/5254255',
  },
  stage: process.env.REACT_APP_STAGE,
  websocketApiBaseUrl: `wss://ws.${process.env.REACT_APP_STAGE}.guiker.com`,
  websocketAuthTokenExpiryTime: 30000, // the token is only valid for a minute so refetch every 30 seconds to increase the likelyhood that we have a fresh token
}

const silentLogger = {
  log: () => {},
  error: () => {},
  warn: () => {},
  info: () => {},
}

switch (process.env.REACT_APP_STAGE) {
  case 'test':
    baseConfig = {
      ...baseConfig,
    }
  case 'staging':
    baseConfig = {
      ...baseConfig,
      apiBaseUrl: 'https://api.staging.guiker.com',
      debug: false,
      sentry: {
        ...baseConfig.sentry,
        enabled: parseBoolean(process.env.REACT_APP_SENTRY_ENABLED, true) ?? true,
      },
      analytics: {
        ...baseConfig.analytics,
        enabled: true,
        debug: true,
      },
      google: {
        ...baseConfig.google,
        mapApiKey: process.env.REACT_APP_GOOGLE_API_KEY || baseConfig.google.mapApiKey,
      },
    }
    break
  case 'production':
    baseConfig = {
      ...baseConfig,
      appBaseUrl: 'https://www.guiker.com',
      apiBaseUrl: 'https://api.guiker.com',
      legacyApiUrl: 'https://server.guiker.com',
      legacyAssetBaseUrl: 'https://resources.guiker.com',
      debug: false,
      logger: silentLogger,
      docusign: {
        prepareUrl: 'https://app.docusign.com/prepare',
        templateUrl: 'https://app.docusign.com/templates',
      },
      plaid: {
        ...baseConfig.plaid,
        env: 'production',
      },
      stripe: {
        US: {
          publishableKey:
            'pk_live_51HDabeGBsoDwgLanmORLpDY1K3nn6UeH67fxK7jrPAnZr51j54QNWKUZyoplBhKC9sua9iCqY8JUs3Oxu2Do86EW00QmLHHlzs',
        },
        CA: {
          publishableKey: 'pk_live_84tNK036o8qsJ1eYg7FJisMi',
        },
      },
      sentry: {
        ...baseConfig.sentry,
        enabled: parseBoolean(process.env.REACT_APP_SENTRY_ENABLED, true) ?? true,
      },
      analytics: {
        ...baseConfig.analytics,
        enabled: true,
      },
      google: {
        ...baseConfig.google,
        mapApiKey: process.env.REACT_APP_GOOGLE_API_KEY || baseConfig.google.mapApiKey,
      },
      websocketApiBaseUrl: 'wss://ws.guiker.com',
    }
    break
  case 'local':
    baseConfig = {
      ...baseConfig,
      legacyApiUrl: 'http://localhost:8080',
    }
    break
  default:
    baseConfig = {
      ...baseConfig,
      debug: true,
      sentry: {
        ...baseConfig.sentry,
        enabled: false,
      },
      analytics: {
        ...baseConfig.analytics,
        enabled: false,
        debug: true,
      },
      google: {
        ...baseConfig.google,
        mapApiKey: process.env.REACT_APP_GOOGLE_API_KEY || baseConfig.google.mapApiKey,
      },
    }
    break
}

export { baseConfig }
