import React from 'react'

import { defaultRouteConfig } from './default-config'
import { mainRoutes } from './main-routes'
import { pathBuilder } from './path-builder'

// Note: importing from /components/*.ts so it does the tree-shaking to extract App from each microFE
// https://reactjs.org/docs/code-splitting.html#named-exports
const AccountComponent = React.lazy(() => import('../components/Account'))
const BlogComponent = React.lazy(() => import('../components/Blog'))
const BackgroundCheckComponent = React.lazy(() => import('../components/BackgroundCheck'))
const BookingComponent = React.lazy(() => import('../components/Booking'))
const ConversationComponent = React.lazy(() => import('../components/Conversation'))
const PropSharingComponent = React.lazy(() => import('../components/PropSharing'))
const InvestmentsComponent = React.lazy(() => import('../components/Investments'))
const ListingComponent = React.lazy(() => import('../components/Listing'))
const MyHomeComponent = React.lazy(() => import('../components/MyHome'))
const MyInvestmentsComponent = React.lazy(() => import('../components/MyInvestments'))
const NotificationComponent = React.lazy(() => import('../components/Notification'))
const PaymentComponent = React.lazy(() => import('../components/Payment'))
const PayoutComponent = React.lazy(() => import('../components/Payout'))
const PropertySaleComponent = React.lazy(() => import('../components/PropertySale'))
const RecommendedListingComponent = React.lazy(() => import('../components/RecommendedListing'))
const TenantApplicationComponent = React.lazy(() => import('../components/TenantApplication'))

const routes = {
  ...mainRoutes,
  Account: {
    ...defaultRouteConfig,
    path: '/account/*',
    exact: false,
    Component: AccountComponent as React.FC,
    displayFooter: false,
    externalRoutes: {
      fundingAccount: '/wallet/funding-account',
    },
  },
  BackgroundCheck: {
    ...defaultRouteConfig,
    path: '/background-check/*',
    exact: false,
    Component: BackgroundCheckComponent as React.FC,
    displayAppBar: false,
    displayFooter: false,
    externalRoutes: {
      reports: '/background-check',
    },
  },
  Blog: {
    ...defaultRouteConfig,
    path: '/blog/*',
    exact: false,
    Component: BlogComponent as React.FC,
    displayAppBar: false,
    displayFooter: true,
  },
  Booking: {
    ...defaultRouteConfig,
    path: '/booking/*',
    exact: false,
    Component: BookingComponent as React.FC,
    displayAppBar: false,
    displayFooter: false,
  },
  Conversation: {
    ...defaultRouteConfig,
    path: `${pathBuilder.conversation.root.path}/*`,
    exact: false,
    Component: ConversationComponent as React.FC,
    displayFooter: false,
  },
  PropSharing: {
    ...defaultRouteConfig,
    path: '/fractional-ownership/*',
    exact: false,
    Component: PropSharingComponent as React.FC,
  },
  Investments: {
    ...defaultRouteConfig,
    path: `${pathBuilder.investment.root.path}/*`,
    exact: false,
    Component: InvestmentsComponent as React.FC,
    displayFooter: false,
  },
  MyHome: {
    ...defaultRouteConfig,
    path: '/rental-tenancy/*',
    exact: false,
    Component: MyHomeComponent as React.FC,
    displayFooter: false,
  },
  MyInvestments: {
    ...defaultRouteConfig,
    path: '/my-investments/*',
    exact: false,
    Component: MyInvestmentsComponent as React.FC,
    displayFooter: false,
    externalRoutes: {
      root: '/my-investments/investment-dashboard',
    },
  },
  Payment: {
    ...defaultRouteConfig,
    path: '/payment/*',
    exact: false,
    Component: PaymentComponent as React.FC,
  },
  Listing: {
    ...defaultRouteConfig,
    path: '/listings/*',
    exact: false,
    Component: ListingComponent as React.FC,
    externalRoutes: {
      root: '/listings',
    },
  },
  PropertySale: {
    ...defaultRouteConfig,
    path: '/property-sale/*',
    exact: false,
    Component: PropertySaleComponent as React.FC,
  },
  Notification: {
    ...defaultRouteConfig,
    path: '/notification/*',
    exact: false,
    displayFooter: false,
    Component: NotificationComponent as React.FC,
    requireAuthentication: true,
  },
  Payout: {
    ...defaultRouteConfig,
    path: '/payout/*',
    exact: false,
    displayFooter: false,
    Component: PayoutComponent as React.FC,
  },
  RecommendedListing: {
    ...defaultRouteConfig,
    path: '/recommended-listing/*',
    exact: false,
    displayFooter: false,
    Component: RecommendedListingComponent as React.FC,
    requireAuthentication: true,
    externalRoutes: {
      recommendedListing: '/recommended-listing',
    },
  },
  TenantApplication: {
    ...defaultRouteConfig,
    path: '/tenant-application/*',
    exact: false,
    displayFooter: false,
    Component: TenantApplicationComponent as React.FC,
  },
} as const

export { routes }
