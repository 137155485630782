import React from 'react'

import { FullScreenSpinner } from '@guiker/components-library'

import { Document, Page } from './react-pdf'

type PdfThumbnailProps = {
  assetUrl: string
  height: number
  width: number
}

export const PdfThumbnail: React.FC<PdfThumbnailProps> = ({ assetUrl, height }) => {
  return (
    <Document file={assetUrl} loading={<FullScreenSpinner />}>
      <Page wrap height={height} pageNumber={1} />
    </Document>
  )
}
