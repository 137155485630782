import { CreditScoreRanking, TenantApplicationGuarantorBackgroundCheck } from './background-check'
import { BaseAddress } from './current-address'
import { SupportingDocument } from './supporting-documents'

export type GuarantorProfile = {
  userId?: string
  legalFirstName: string
  legalLastName: string
  emailAddress: string
  dateOfBirth: string
  occupation?: string
  relationship?: string
  annualSalary?: string
  phoneNumber?: string
  estimatedCreditScore?: CreditScoreRanking
}

export type GuarantorAddress = BaseAddress

export enum GuarantorStatus {
  INVITED = 'INVITED',
  COMPLETED = 'COMPLETED',
}

export type TenantApplicationGuarantor = {
  profile: GuarantorProfile
  address: GuarantorAddress
  supportingDocuments?: SupportingDocument[]
  backgroundCheck: TenantApplicationGuarantorBackgroundCheck
  status: GuarantorStatus
}
