import { enumLike, TypeOf, yup } from '@guiker/yup-util'

import { DocumentType } from '../../../../entity'

const assetSchema = yup.object({
  id: yup.string(),
})

export const identityVerificationSchema = yup.object({
  governmentIdFront: assetSchema.required(),
  governmentIdBack: assetSchema.nullable(),
  facePicture: assetSchema.required(),
  documentType: enumLike(DocumentType),
})

export type IdentityVerificationSchema = TypeOf<typeof identityVerificationSchema>
