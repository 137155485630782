import { yup } from '@guiker/yup-util'

export const presignedUrlParams = yup.object({
  // in seconds. min = 1 minute / max = 7 days / default = 1 hour
  expiresAfter: yup
    .number()
    .min(60)
    .max(60 * 60 * 24 * 7)
    .default(60 * 60),
})

export type PresignedUrlParams = yup.InferType<typeof presignedUrlParams>
