import { CountByStatusMetadata } from '@guiker/api-definition'
import { routesBuilder } from '@guiker/shared-framework'

import { PayoutMethod, PayoutMethodStatus, PayoutMethodWithPayouts } from '../../../entity'
import { paths } from '../paths'
import {
  readAllPayoutMethodQueryParamsSchema,
  readAllPlatformPayoutMethodsQueryParamsSchema,
  updateReviewPayloadSchema,
} from '../schemas'

const base = routesBuilder<PayoutMethod>()({
  basePath: paths.constant.payoutMethods.path,
})
const withId = routesBuilder<PayoutMethod>()({
  basePath: `${paths.constant.payoutMethods.path}/:payoutMethodId`,
})
const withIdPayouts = routesBuilder<PayoutMethodWithPayouts>()({
  basePath: `${paths.constant.payoutMethods.path}/:payoutMethodId`,
})
const userScoped = routesBuilder<PayoutMethod>()({
  basePath: paths.constant.users.withId.payoutMethods.path,
})
const platform = routesBuilder<PayoutMethod>()({
  basePath: paths.constant.platformPayoutMethods.path,
})

export const payoutMethodRoutes = {
  readOnePayoutMethod: withIdPayouts.buildGet(),
  readAllPayoutMethods: base.buildGet({
    paginated: true,
    queryParamsSchema: readAllPayoutMethodQueryParamsSchema,
    additionalMeta: {} as CountByStatusMetadata<PayoutMethodStatus>,
  }),
  readAllUserPayoutMethods: userScoped.buildGet({ responseIsArray: true }),
  readAllPlatformPayoutMethods: platform.buildGet({
    queryParamsSchema: readAllPlatformPayoutMethodsQueryParamsSchema,
    responseIsArray: true,
  }),
  updatePayoutMethodReview: withId.buildPut({
    path: '/review',
    payloadSchema: updateReviewPayloadSchema,
  }),
  revealPayoutMethodSSN: withId.buildGet({
    path: '/ssn',
    response: null as string,
  }),
}
