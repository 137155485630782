import { PhoneNumberSchema } from '@guiker/phone-number'
import { yup } from '@guiker/yup-util'

const userSchema = yup.object({
  userId: yup.string().required(),
  emailAddress: yup.string().email().required(),
  phone: PhoneNumberSchema(true).required(),
  firstName: yup.string().required(),
  lastName: yup.string().optional(),
})

export const createPropertySaleInquirySchema = yup.object({
  user: userSchema.required(),
  listingId: yup.string().required(),
  incomeVariables: yup.object(),
  expenses: yup.object(),
})

export type CreatePropertySaleInquirySchema = yup.InferType<typeof createPropertySaleInquirySchema>
