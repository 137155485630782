import { yup } from '@guiker/yup-util'

import { PayoutProviderName, PayoutProviderStatus } from '../../../entity'

export const payoutProviderSchema = yup.object({
  name: yup.mixed<PayoutProviderName>().oneOf(Object.values(PayoutProviderName)).required(),
  status: yup
    .mixed<PayoutProviderStatus>()
    .oneOf(['CREATED', 'PENDING_APPROVAL', 'NEED_MORE_INFORMATION', 'ENABLED', 'REJECTED', 'TERMINATED'])
    .required(),
  accountId: yup.string().required(),
  details: yup.mixed(),
})
