enum BankName {
  BANK_OF_MONTREAL = 'BANK_OF_MONTREAL',
  BANK_OF_NOVA_SCOTIA = 'BANK_OF_NOVA_SCOTIA',
  HSBC_CANADA = 'HSBC_CANADA',
  TORONTO_DOMINION_BANK = 'TORONTO_DOMINION_BANK',
  ROYAL_BANK_OF_CANADA = 'ROYAL_BANK_OF_CANADA',
  NATIONAL_BANK_OF_CANADA = 'NATIONAL_BANK_OF_CANADA',
  CANADIAN_IMPERIAL_BANK_OF_COMMERCE = 'CANADIAN_IMPERIAL_BANK_OF_COMMERCE',
  CANADIAN_WESTERN_BANK = 'CANADIAN_WESTERN_BANK',
  LAURENTIAN_BANK_OF_CANADA = 'LAURENTIAN_BANK_OF_CANADA',
  CITIBANK_CANADA = 'CITIBANK_CANADA',
  JPMORGAN_CHASE = 'JPMORGAN_CHASE',
  TANGERINE_BANK = 'TANGERINE_BANK',
  CAPITAL_ONE = 'CAPITAL_ONE',
  WELLS_FARGO_BANK = 'WELLS_FARGO_BANK',
  BANK_OF_AMERICA = 'BANK_OF_AMERICA',
}

interface Bank {
  shortName: string
  name: string
  routing: {
    routingNumber?: string[]
    institutionId?: string
  }
}

type SupportedBank = {
  [bank in BankName]: Bank
}

const banks: SupportedBank = {
  BANK_OF_MONTREAL: {
    shortName: 'BMO',
    name: BankName.BANK_OF_MONTREAL,
    routing: {
      institutionId: '001',
    },
  },
  BANK_OF_NOVA_SCOTIA: {
    shortName: 'Scotiabank',
    name: BankName.BANK_OF_NOVA_SCOTIA,
    routing: {
      institutionId: '002',
    },
  },
  ROYAL_BANK_OF_CANADA: {
    shortName: 'RBC',
    name: BankName.ROYAL_BANK_OF_CANADA,
    routing: {
      institutionId: '003',
    },
  },
  TORONTO_DOMINION_BANK: {
    shortName: 'TD Canada Trust',
    name: BankName.TORONTO_DOMINION_BANK,
    routing: {
      institutionId: '004',
    },
  },
  NATIONAL_BANK_OF_CANADA: {
    shortName: 'National Bank',
    name: BankName.NATIONAL_BANK_OF_CANADA,
    routing: {
      institutionId: '006',
    },
  },
  CANADIAN_IMPERIAL_BANK_OF_COMMERCE: {
    shortName: 'CIBC',
    name: BankName.CANADIAN_IMPERIAL_BANK_OF_COMMERCE,
    routing: {
      institutionId: '010',
    },
  },
  HSBC_CANADA: {
    shortName: 'HSBC',
    name: BankName.HSBC_CANADA,
    routing: {
      institutionId: '016',
    },
  },
  CANADIAN_WESTERN_BANK: {
    shortName: 'CWB',
    name: BankName.CANADIAN_WESTERN_BANK,
    routing: {
      institutionId: '030',
    },
  },
  LAURENTIAN_BANK_OF_CANADA: {
    shortName: 'Laurential Bank',
    name: BankName.LAURENTIAN_BANK_OF_CANADA,
    routing: {
      institutionId: '039',
    },
  },
  CITIBANK_CANADA: {
    shortName: 'Citibank',
    name: BankName.CITIBANK_CANADA,
    routing: {
      institutionId: '260',
    },
  },
  JPMORGAN_CHASE: {
    shortName: 'Chase',
    name: BankName.JPMORGAN_CHASE,
    routing: {
      institutionId: '270',
      routingNumber: [
        //@reference: https://www.chase.com/business/routing-number
        '065400137',
        '122100024',
        '065400137',
        '322271627',
        '102001017',
        '021100361',
        '044000037',
        '083000137',
        '267084131',
        '061092387',
        '123271978',
        '071000013',
        '074000010',
        '075000019',
        '103000648',
        '083000137',
        '065400137',
        '021000021',
        '044000037',
        '083000137',
        '072000326',
        '075000019',
        '103000648',
        '065400137',
        '102001017',
        '322271627',
        '021202337',
        '021000021',
        '022300173',
        '103000648',
        '083000137',
        '102001017',
        '072000326',
        '103000648',
        '044000037',
        '103000648',
        '325070760',
        '083000137',
        '083000137',
        '072000326',
        '103000648',
        '111000614',
        '065400137',
        '124001545',
        '044000037',
        '083000137',
        '325070760',
        '051900366',
        '075000019',
        '102001017',
        '028000121',
      ],
    },
  },
  TANGERINE_BANK: {
    shortName: 'Tangerine',
    name: BankName.TANGERINE_BANK,
    routing: {
      institutionId: '614',
    },
  },
  CAPITAL_ONE: {
    shortName: 'Capital One',
    name: BankName.CAPITAL_ONE,
    routing: {
      institutionId: '323',
      routingNumber: ['051405515', '056073502', '056073612'],
    },
  },
  WELLS_FARGO_BANK: {
    shortName: 'Wells Fargo',
    name: BankName.WELLS_FARGO_BANK,
    routing: {
      routingNumber: [
        '062000080',
        '125200057',
        '122105278',
        '111900659',
        '102000076',
        '021101108',
        '031100869',
        '054001220',
        '063107513',
        '061000227',
        '124103799',
        '071101307',
        '074900275',
        '073000228',
        '101089292',
        '055003201',
        '091101455',
        '062203751',
        '113105449',
        '092905278',
        '104000058',
        '321270742',
        '021200025',
        '107002192',
        '026012881',
        '053000219',
        '091300010',
        '041215537',
        '123006800',
        '031000503',
        '053207766',
        '091400046',
        '064003768',
        '112000066',
        '124002971',
        '051400549',
        '125008547',
        '075911988',
        '102301092',
        '121042882',
        '091000019',
      ],
    },
  },
  BANK_OF_AMERICA: {
    shortName: 'Bank of America',
    name: BankName.BANK_OF_AMERICA,
    routing: {
      routingNumber: [
        //@reference: https://wise.com/us/routing-number/bank-of-america#bank-of-america-routing-number
        '051000017',
        '051000018',
        '122101706',
        '082000073',
        '121000358',
        '123103716',
        '011900254',
        '031202084',
        '054001204',
        '063100277',
        '063000047',
        '063100277',
        '061000052',
        '123103716',
        '081904808',
        '071000505',
        '081904808',
        '071214579',
        '073000176',
        '101100045',
        '051000017',
        '011200365',
        '052001633',
        '011000138',
        '081000032',
        '101000035',
        '081000033',
        '122400724',
        '011400495',
        '021200339',
        '107000327',
        '021000322',
        '053000196',
        '051000017',
        '103000017',
        '323070380',
        '031202084',
        '011500010',
        '053904483',
        '064000020',
        '111000025',
        '113000023',
        '125000024',
        '026009593',
      ],
    },
  },
}

const getBankByRouting = (routing: string): Bank | undefined => {
  if (!routing) return

  for (const key in banks) {
    const bank = banks[key as BankName]
    if (
      (!!bank.routing.institutionId && bank.routing.institutionId === routing) ||
      (!!bank.routing.routingNumber && bank.routing.routingNumber?.includes(routing))
    )
      return bank
  }
}

export { banks, SupportedBank, Bank, BankName, getBankByRouting }
