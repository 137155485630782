import React from 'react'

import {
  Applicant,
  Booking,
  BookingLeaseParticipant,
  BookingLeaseParticipantRole,
  BookingLeaseParticipantStatus,
} from '@guiker/booking-shared'
import { useTranslation } from '@guiker/react-framework'

import { getApplicantName } from '../../../../utils'
import { StatusComponent } from './StatusComponent'

type LeaseStatusProps = { booking: Booking }

type LeaseStatusLesseeProps = LeaseStatusProps & { lessee: Applicant }
type LeaseStatusLessorProps = LeaseStatusProps

const getLeaseParticipant = (args: {
  participants: BookingLeaseParticipant[]
  role: BookingLeaseParticipantRole
  findBy: string // userId or emailAddress
}) => {
  const { findBy, participants = [], role } = args
  return participants.filter((p) => !p.role || p.role === role).find((p) => p.userId === findBy)
}

const LeaseStatusLessee: React.FC<LeaseStatusLesseeProps> = ({ booking, lessee }) => {
  const { t } = useTranslation(['main-booking'])
  const participants = booking.lease?.participants
  const role = BookingLeaseParticipantRole.LESSEE

  const applicant = getLeaseParticipant({ participants, role, findBy: lessee.userId })
  const guarantor = participants.find((p) => p.relatedUserId === applicant?.userId)

  const applicantName = applicant?.fullName
    ? [applicant.fullName.split(' ')[0]]
    : getApplicantName({ booking, userIdOrEmailAddress: lessee.userId, t })
  const guarantorName = guarantor && [
    guarantor.fullName?.split(' ')[0] || '-',
    t('unitManagerBookingSteps.guarantorOf', { name: applicantName }),
  ]

  const status = applicant?.status || 'null'

  return (
    <>
      <StatusComponent
        signeeName={applicantName}
        status={t(`unitManagerBookingSteps.leaseStatus.${status}`)}
        checked={status === BookingLeaseParticipantStatus.SIGNED}
        failed={status === BookingLeaseParticipantStatus.MISSING_SIGNATURE}
      />
      {guarantor && (
        <StatusComponent
          signeeName={guarantorName}
          status={t(`unitManagerBookingSteps.leaseStatus.${guarantor.status}`)}
          checked={guarantor.status === BookingLeaseParticipantStatus.SIGNED}
          failed={guarantor.status === BookingLeaseParticipantStatus.MISSING_SIGNATURE}
        />
      )}
    </>
  )
}

const LeaseStatusLessor: React.FC<LeaseStatusLessorProps> = ({ booking }) => {
  const { t } = useTranslation(['main-booking'])

  const lessor = booking.lease?.participants.find((p) => p.role === BookingLeaseParticipantRole.LESSOR)
  const lessorName = [(lessor?.fullName ?? '-').split(' ')[0], t('unitManagerBookingSteps.invitedLandlord')]
  const status = lessor?.status || 'null'

  return (
    <StatusComponent
      signeeName={lessorName}
      status={t(`unitManagerBookingSteps.leaseStatus.${status}`)}
      checked={status === BookingLeaseParticipantStatus.SIGNED}
      failed={status === BookingLeaseParticipantStatus.MISSING_SIGNATURE}
    />
  )
}

export { LeaseStatusLessee, LeaseStatusLessor }
