import React from 'react'

import { makeStyles } from '@guiker/components-core'

import { AssetFile } from '../../entity'

type AssetImageProps = {
  asset: AssetFile
}

const useStyle = makeStyles({
  image: {
    objectFit: 'cover',
    minHeight: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    maxWidth: '100%',
  },
})

const AssetImage: React.FC<AssetImageProps> = ({ asset }) => {
  const classes = useStyle()
  const url = asset?.presignedDownloadUrl

  return <img className={classes.image} src={url} />
}

export { AssetImage }
