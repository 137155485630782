import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import {
  isAtMostTablette,
  isMobile,
  isTablette,
  makeStyles,
  margin,
  padding,
  theme,
  ThemeContextProvider,
} from '@guiker/components-core'
import { Divider, Flex, GuikerLogoWithText, Image, Modal, PSmall } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'

import { useMediaQuery, useStaticAssetContext } from '../../hooks'
import { footerLinkGroups } from './footer-link-group'
import { FooterLanguageSwitcher } from './FooterLanguageSwitcher'
import { FooterLinksGroup } from './FooterLinkGroup'
import { SocialGroup } from './SocialGroup'

const useStyles = makeStyles(
  (theme) => ({
    footerWrapper: {
      width: '100%',
      backgroundColor: theme.palette.background.primary,
      padding: padding(15, 9),
      [isMobile]: {
        padding: padding(4, 2),
      },
    },
    footerContent: {
      width: '100%',
      maxWidth: theme.dimensions.pageLayout.maxWidth.desktop,
      margin: margin(0, 'auto'),
    },
    copyrightContainer: {
      marginRight: theme.spacing(3),
      [isTablette]: {
        marginBottom: theme.spacing(5),
        marginRight: 0,
      },
      [isMobile]: {
        marginBottom: theme.spacing(5),
        marginRight: 0,
      },
    },
    socialIcon: {
      cursor: 'pointer',
      '&:hover': {
        opacity: 1,
      },
      opacity: 0.5,
    },
    wechatQR: {
      width: '100%',
    },
    officeAddress: {
      maxWidth: 190,
      [isAtMostTablette]: '100%',
    },
  }),
  { name: 'Footer' },
)

const Footer: React.FC = () => {
  return (
    <ThemeContextProvider isDarkMode={true}>
      <FooterContent />
    </ThemeContextProvider>
  )
}

type OfficeAddressProps = { name: string; address: string; phone: string }
const OfficeAddress: React.FC<OfficeAddressProps> = ({ name, address, phone }) => {
  const classes = useStyles()
  return (
    <div className={classes.officeAddress}>
      <PSmall color={50}>{name}</PSmall>
      <PSmall color={50}>{address}</PSmall>
      <PSmall color={50}>{phone}</PSmall>
    </div>
  )
}

const FooterContent: React.FC = () => {
  const { t } = useTranslation('webapp')
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const [isWeChatOpen, setIsWeChatOpen] = useState(false)
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('root')

  return (
    <>
      <div className={clsx(classes.footerWrapper)}>
        <div className={classes.footerContent}>
          <Flex
            flexDirection={isTablet ? 'column' : 'row'}
            alignItems='flex-start'
            justifyContent='space-between'
            mb={isTablet ? 6 : 10}
            gap={isTablet ? 5 : 3}
          >
            <Flex flexDirection='column' gap={3}>
              <GuikerLogoWithText darkMode />
              <SocialGroup openWeChat={setIsWeChatOpen} />
            </Flex>
            <Flex flexDirection={isTablet ? 'column' : 'row'} gap={5} justifyContent='space-between' maxWidth={618}>
              {footerLinkGroups.map((linkGroup) => (
                <FooterLinksGroup key={linkGroup.title} {...linkGroup} />
              ))}
            </Flex>
          </Flex>
          <Divider color={80} />
          <Flex flexDirection={isTablet ? 'column' : 'row'} justifyContent='space-between' mt={isTablet ? 5 : 10}>
            <Flex flexDirection='column' justifyContent='space-between' className={classes.copyrightContainer}>
              <PSmall color='textPrimary' mb={5}>
                {t('webapp:footer.copyright', { year: new Date().getFullYear() })}
              </PSmall>
              <FooterLanguageSwitcher />
            </Flex>
            <Flex flexDirection={isTablet ? 'column' : 'row'} gap={isTablet ? 5 : 3}>
              <OfficeAddress
                name='Toronto Office'
                address='123 Edward Street, Suite 205, Toronto, ON, M5G 1E2, Canada'
                phone='+1 (416) 639-2254'
              />
              <OfficeAddress
                name='Montreal Office'
                address='747 Rue du Square-Victoria, Suite 700, Montreal, QC, H2Y 3Y9, Canada'
                phone='+1 (855) 224-0999'
              />
              <OfficeAddress
                name='Chicago Office'
                address='159 N Sangamon Street, 2nd Floor, Chicago, IL, 60607, United States'
                phone='+1 (617) 993-4802'
              />
              <OfficeAddress
                name='Boston Office'
                address='240 Elm Street, 2nd and 3rd Floor, Somerville, MA, 02144, United States'
                phone='+1 (617) 993-4802'
              />
            </Flex>
          </Flex>
        </div>
      </div>
      <Modal open={isWeChatOpen} onClose={() => setIsWeChatOpen(false)}>
        <Image src={assets.wechat} className={classes.wechatQR} width='100%' height='100%' />
      </Modal>
    </>
  )
}

export { Footer }
