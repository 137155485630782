import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { ShareJournalEntry } from '../../entity'
import { baseEvent, BaseEventTypes } from '../base'

const baseShareJournalEntryEvent = {
  ...baseEvent,
  entity: 'SHARE_JOURNAL_ENTRY',
  data: null as unknown as ShareJournalEntry,
}

export const ShareJournalEntryEventTypes = {
  ...BaseEventTypes,
  RETIRED: {
    ...baseShareJournalEntryEvent,
    data: null as {},
    type: 'RETIRED',
    eventData: {
      tickerSymbol: null as string,
      userId: null as string,
      stockOrderExecutionId: null as string,
      debit: null as ShareJournalEntry,
      credit: null as ShareJournalEntry,
    },
  },
  FAILED_FROM_STOCK_ORDER_EXECUTION: {
    ...baseShareJournalEntryEvent,
    data: null as {},
    type: 'FAILED_FROM_STOCK_ORDER_EXECUTION',
    eventData: {
      stockOrderExecutionId: null as string,
    },
  },
  INSERTED_FROM_STOCK_ORDER_EXECUTION: {
    ...baseShareJournalEntryEvent,
    data: null as {},
    type: 'INSERTED_FROM_STOCK_ORDER_EXECUTION',
    eventData: {
      stockOrderExecutionId: null as string,
      debit: null as ShareJournalEntry,
      credit: null as ShareJournalEntry,
    },
  },
} as const

export const ShareJournalEntryEventsDefinition = generateEventsDefinition(baseShareJournalEntryEvent)
export const ShareJournalEntryEvents = generateEventsFromEnum(baseShareJournalEntryEvent)(ShareJournalEntryEventTypes)
