import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'
import { signature } from '../../schemas'
import { leaseEmailUniquenessSchema } from '../shared'

export const guarantorWithoutSignature = yup.object<Ontario.GuarantorWithoutSignature>({
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  hasGuarantor: yup.boolean().nullable(),
  emailAddress: leaseEmailUniquenessSchema.nullable(),
})
export type GuarantorWithoutSignature = TypeOf<typeof guarantorWithoutSignature>

export const guarantor = yup.object<Ontario.Guarantor>({
  firstName: yup.string(),
  lastName: yup.string(),
  emailAddress: leaseEmailUniquenessSchema,
  signature: signature.required(),
})
export type Guarantor = TypeOf<typeof guarantor>
