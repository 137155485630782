import React from 'react'

import {
  Box,
  Checkbox,
  Collapse,
  PBold,
  PSmall,
  TextField,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { SupportingDocument } from '@guiker/tenant-application-shared'

import { AssetUploader } from '../..'

type Props = {
  defaultValue: SupportingDocument
  readOnly?: boolean
  name: string
  documentType: string
}

const RHFAssetInputDocumentType: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  const { register } = useFormContext()

  return <input type='hidden' name={name} value={value} {...register(name)} />
}

const SupportingDocumentSection: React.FC<Props> = ({ documentType, name, defaultValue, readOnly = false }) => {
  const { t } = useTranslation('main-tenantApplication')

  const isWaiveRequested: boolean = useWatch({
    name: `${name}.isWaiveRequested`,
    defaultValue: defaultValue?.isWaiveRequested ?? false,
  })

  return (
    <>
      {!readOnly ? (
        <>
          <PBold>{t(`${documentType}.label`).toUpperCase()}</PBold>
          <PSmall color={60} mb={2}>
            {t(`${documentType}.description`)}
          </PSmall>
        </>
      ) : (
        <>
          <PBold>{t(`${documentType}.label`).toUpperCase()}</PBold>
        </>
      )}
      {!readOnly && <RHFAssetInputDocumentType name={`${name}.type`} value={documentType} />}
      <Collapse in={!isWaiveRequested}>
        <AssetUploader
          groupName={documentType}
          name={`${name}.assets`}
          displayDirection={'row'}
          maxSizeMB={5}
          readOnly={readOnly}
          withThumbnail
          disableDragAndDrop
        />
      </Collapse>
      {readOnly && !isWaiveRequested ? null : (
        <Box mb={2}>
          <Checkbox
            defaultValue={isWaiveRequested}
            name={`${name}.isWaiveRequested`}
            label={t('cannotProvide')}
            maxWidth={420}
            readOnly={readOnly}
          />
        </Box>
      )}
      <Collapse in={isWaiveRequested || (readOnly && defaultValue?.isWaiveRequested)}>
        <TextField
          required
          maxWidth={420}
          readOnly={readOnly}
          name={`${name}.reasonForWaive`}
          label={t('reasonForCannotProvide')}
          defaultValue={defaultValue?.reasonForWaive}
        />
      </Collapse>
    </>
  )
}

export { SupportingDocumentSection }
