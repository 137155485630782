import { CountryCode } from '@guiker/base-entity'
import { TypeOf, yup } from '@guiker/yup-util'

export const kpiQueryParams = yup
  .object({
    month: yup
      .string()
      .required()
      .test('invalidDate', 'must be "yyyy-MM" format', (value: string) => {
        const yearDateRegex = new RegExp(`^[0-9]{4}-[0-9]{2}$`, 'g')
        return yearDateRegex.test(value)
      }),
    country: yup.mixed<CountryCode>().required(),
    listingId: yup.string().nullable().optional(),
    unitId: yup.string().nullable().optional(),
  })
  .required()

export type KpiQueryParams = TypeOf<typeof kpiQueryParams>
