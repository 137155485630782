import React from 'react'

import { SuccessPage, useNavigate } from '../../components'
import { useTranslation } from '../../i18n'

export const DocumentSuccess: React.FC = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <SuccessPage
      buttonProps={{
        onClick: () => navigate('/'),
        children: t('actions.return', {
          location: 'home',
        }),
      }}
    />
  )
}
