import React, { useMemo } from 'react'

import { FileRejection } from 'react-dropzone'

import { clsx, makeStyles, theme, useUtilityStyle } from '@guiker/react-framework'

import { DropzoneFeedback } from './DropzoneFeedback'
import { InputDisplay } from './InputDisplay'
import { styles } from './styles'
import { useDropzone } from './use-dropzone'
import { SupportedFileType } from './utils'

/**
 * @param maxSize Maximum size per file in mb
 * */
export type DropzoneProps = {
  mutateOptions: {
    onSuccess: (file: File) => void
    onError?: (errors: FileRejection) => void
  }
  fileOptions: {
    acceptedFileTypes?: SupportedFileType | SupportedFileType[]
    maxFiles?: number
    maxSize?: number
  }
  className?: string
  width?: number | string
  height?: number | string
  inputType?: 'zone' | 'button'
  shouldRenderAcceptedFileNames?: boolean
  disabled?: boolean
} & React.PropsWithChildren

const useStyle = makeStyles({
  root: {},
  focused: {
    borderColor: theme.palette.grey[50],
    color: theme.palette.grey[50],
  },
  reject: {
    borderColor: theme.palette.status.alert.hover,
    color: theme.palette.status.alert.hover,
  },
  zone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    height: ({ height }: { height: string | number }) => height,
    width: ({ width }: { width: string | number }) => width,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabled: {
    borderColor: theme.palette.grey[30],
    '&:hover': {
      cursor: 'auto',
    },
  },
})

const handleError = (error) => {
  console.log({ errorUploading: error })
}

export const Dropzone: React.FC<DropzoneProps> = ({
  mutateOptions: { onSuccess, onError = handleError },
  fileOptions: { acceptedFileTypes = [], maxFiles = 1, maxSize = 10 },
  className,
  width = 'fit-content',
  height = 'fit-content',
  inputType = 'zone',
  disabled = false,
  shouldRenderAcceptedFileNames = true,
  children,
}) => {
  const classes = useStyle({ height, width })
  const utilityStyles = useUtilityStyle({ maxWidth: width })

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    acceptedFiles.forEach((file) => {
      onSuccess(file)
    })
    rejectedFiles.forEach((rejectedFile) => {
      onError(rejectedFile)
    })
  }

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } =
    useDropzone({
      onDrop,
      maxFiles,
      maxSize,
      accept: acceptedFileTypes,
      disabled,
      multiple: maxFiles > 1,
      noClick: inputType === 'button',
      noKeyboard: inputType === 'button',
      noDrag: inputType === 'button',
    })

  const interactiveStyles = useMemo(
    () => ({
      ...(isFocused ? styles.focused : {}),
      ...(isDragAccept ? styles.focused : {}),
      ...(isDragReject ? styles.reject : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  return (
    <>
      <div
        {...getRootProps({
          style: interactiveStyles,
          className: clsx(utilityStyles.maxWidth, className, {
            [classes.zone]: inputType === 'zone',
            [classes.disabled]: disabled,
          }),
        })}
      >
        <input {...getInputProps()} />
        <InputDisplay
          acceptedFiles={acceptedFiles}
          inputType={inputType}
          shouldRenderAcceptedFileNames={shouldRenderAcceptedFileNames}
          open={open}
          disabled={disabled}
        >
          {children}
        </InputDisplay>
      </div>
      <DropzoneFeedback fileRejections={fileRejections} />
    </>
  )
}
