import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'

import { Box, H3, P } from '../../components'
import { ContentSection } from './ContentSection'

const useStyle = makeStyles({
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 660,
    padding: theme.spacing(4),
    paddingBottom: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
})

const OurCulture: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const classes = useStyle()

  return (
    <ContentSection>
      <Box className={classes.textContent}>
        <H3>{t('careers.ourCulture')}</H3>
        <P mb={0}>{t('careers.ourCultureSubtext')}</P>
      </Box>
    </ContentSection>
  )
}

export { OurCulture }
