import React from 'react'

import { P, PSmall, SecondaryButton, UploadIcon, useTranslation } from '@guiker/react-framework'

type Props = {
  acceptedFiles: File[]
  inputType: 'zone' | 'button'
  disabled: boolean
  shouldRenderAcceptedFileNames: boolean
  open?: () => void
} & React.PropsWithChildren

export const InputDisplay: React.FC<Props> = ({
  acceptedFiles,
  inputType,
  disabled,
  shouldRenderAcceptedFileNames,
  open,
  children,
}) => {
  const { t } = useTranslation(['common-components'])

  if (inputType === 'zone') {
    return !shouldRenderAcceptedFileNames || !acceptedFiles?.length ? (
      <P mb={0} mt={1} textAlign='center' color={disabled ? 30 : 'inherit'}>
        {children || t('dropzone.selectOrDropFiles')}
      </P>
    ) : (
      <>
        <P mb={0} color='inherit'>
          {t('dropzone.fileUploaded')}
        </P>
        {acceptedFiles.map((file) => (
          <PSmall mb={0} color={30} key={file.name}>
            {file.name}
          </PSmall>
        ))}
      </>
    )
  } else if (inputType === 'button') {
    return (
      <SecondaryButton startIcon={<UploadIcon color='primary' />} onClick={open} maxWidth={284} disabled={disabled}>
        {children || t('dropzone.selectFiles')}
      </SecondaryButton>
    )
  }

  return <></>
}
