import React from 'react'

import { Checkbox, FormSection4, TwoColumnsGridLayout, useTranslation } from '@guiker/react-framework'
import { Utilities as UtilitiesType } from '@guiker/tenant-preference-shared'

type UtilitiesProps = {
  defaultValue: UtilitiesType
  name: string
}

export const Utilities: React.FC<UtilitiesProps> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common-rentalListing'])

  return (
    <FormSection4 title={t('utilities.title')}>
      <TwoColumnsGridLayout>
        <Checkbox
          defaultValue={defaultValue?.electricity}
          name={`${name}.electricity`}
          label={t(`utilities.electricity`)}
        />
        <Checkbox defaultValue={defaultValue?.hotWater} name={`${name}.hotWater`} label={t(`utilities.hotWater`)} />
        <Checkbox defaultValue={defaultValue?.heating} name={`${name}.heating`} label={t(`utilities.heating`)} />
        <Checkbox defaultValue={defaultValue?.wifi} name={`${name}.wifi`} label={t(`utilities.wifi`)} />
      </TwoColumnsGridLayout>
    </FormSection4>
  )
}
