import React from 'react'

import { FormSection4, TwoColumnsGridLayout } from '@guiker/react-framework'

import { useInvestmentAssumptionContext } from '../../../hooks'
import { AssumptionComponentProps as Props } from '../assumption-props'
import { EditCost } from '../Cost'

export const OperationAssumptions: React.FC<Props> = ({ name, readOnly }) => {
  const { investmentAssumptions } = useInvestmentAssumptionContext()
  const costs = investmentAssumptions.operation.costs

  return (
    <FormSection4>
      <TwoColumnsGridLayout>
        {Object.values(costs || {}).map(({ type, ...expenseConfig }) => (
          <EditCost
            key={type}
            name={`${name}.costs`}
            costAssumptionType='operation'
            defaultValue={costs?.hasOwnProperty(type) ? costs[type] : undefined}
            expenseConfig={{ ...expenseConfig, type } as any}
            readOnly={readOnly}
          />
        ))}
      </TwoColumnsGridLayout>
    </FormSection4>
  )
}
