import { conditionallyRequiredSchema, yup } from '@guiker/shared-framework'

export const standardBankInformationSchema = (required: boolean) => ({
  accountNumber: conditionallyRequiredSchema(
    yup
      .string()
      .matches(/^[0-9]+$/)
      .min(7)
      .max(12),
    required,
  ),
  accountType: conditionallyRequiredSchema(yup.string().oneOf(['checking', 'savings']), required),
})

export const canadianBankInformationSchema = (required: boolean) =>
  yup.object({
    institutionNumber: conditionallyRequiredSchema(
      yup
        .string()
        .matches(/^[0-9]+$/)
        .min(3)
        .max(3),
      required,
    ),
    transitNumber: conditionallyRequiredSchema(
      yup
        .string()
        .matches(/^[0-9]+$/)
        .min(5)
        .max(5),
      required,
    ),
    ...standardBankInformationSchema(required),
  })

export const USBankInformationSchema = (required: boolean) =>
  yup.object({
    routingNumber: conditionallyRequiredSchema(
      yup
        .string()
        .matches(/^[0-9]+$/)
        .min(9)
        .max(9),
      required,
    ),
    ...standardBankInformationSchema(required),
  })
