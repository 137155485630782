import React from 'react'

import { transferHelper } from '@guiker/payout-app-components'
import { useAuthenticatedPayoutApiClient } from '@guiker/payout-context'
import { payoutMethodInfoBuilder } from '@guiker/payout-shared'
import { FetcherArg, Flex, PaginatedTable, useQuery, useT } from '@guiker/react-framework'
import { DataMetadataWithCountByStatus, DateTime, ValueOfUnion } from '@guiker/shared-framework'
import {
  PayoutStatusGroupName,
  RentCollectionPayoutCountByStatus,
  RentCollectionPayoutStatusGroups,
  RentCollectionPayoutTableDataPoint,
  RentPayoutStatus,
} from '@guiker/statistic-shared'

import { useAuthenticatedStatisticApiClient, useAuthenticationContext } from '../../hooks'
import { RentCollectionPayoutStatusGrouping } from '../RentCollectionPayoutStatusDefinition'
import { useRentCollectionPayoutColumns } from './use-rent-collection-payout-columns'

type Props = {
  date?: string
  header?: React.ReactNode
  selectedStatusGroup?: PayoutStatusGroupName
  isPaginated?: boolean
  setRentCollectionCount?: (value: RentCollectionPayoutCountByStatus[]) => void
  onSelect?: (statuses: ValueOfUnion<RentPayoutStatus>[]) => void
}

export type RentCollectionPerReceivedPayoutTableFetcher = (
  args: FetcherArg<ValueOfUnion<RentPayoutStatus>, never, never, { payoutMethodIds: string[] }> & {
    payoutMethodIds: string[]
  },
) => Promise<DataMetadataWithCountByStatus<RentCollectionPayoutTableDataPoint, string>>

export const RentCollectionPerReceivedPayoutTable: React.FC<Props> = ({
  header,
  selectedStatusGroup,
  onSelect,
  ...props
}) => {
  const payoutApiClient = useAuthenticatedPayoutApiClient()
  const { data: payoutMethods } = useQuery(
    'readAllPayoutMethods',
    () =>
      payoutApiClient
        .readAllPayoutMethods({
          queryParams: { sort: '', page: 1, perPage: 100, sortOrder: 1, searchString: '' },
        })
        .then((v) => v?.data),
    {
      retry: true,
    },
  )

  const { date = DateTime.local().toFormat('yyyy-MM'), isPaginated = true } = props
  const { user } = useAuthenticationContext()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { tMain } = useT({ domain: 'myInvestments', screenName: 'screens.rentCollectionPayout' })

  const fetcher: RentCollectionPerReceivedPayoutTableFetcher = async (params) => {
    const { statuses, payoutMethodIds, ...paginate } = params

    const queryParams = {
      date,
      payoutMethodIds,
      statuses,
      ...(isPaginated ? paginate : {}),
    }

    const { data, meta } = await statisticApi.readRentCollectionPerPayoutTable({ queryParams })
    const payouts = data.map((payout) => ({ ...payout, transfers: transferHelper.getFullTransfers(payout) }))
    return { data: payouts, meta }
  }

  return (
    <Flex flexDirection='column' gap={2}>
      {header}
      <PaginatedTable
        queryKey={`rentCollectionPerReceivedPayout-${user?.id}-${date}`}
        fetcher={fetcher}
        columns={useRentCollectionPayoutColumns()}
        pagination={{ perPage: 10, sort: 'status', sortOrder: 1 }}
        showPagination={isPaginated}
        isRowCollapsible={true}
        collapsibleOptions={{ isOpen: false }}
        filters={{
          payoutMethodIds: {
            type: 'FilterMultiSelect',
            name: 'payoutMethodIds',
            label: tMain('filters.payoutMethods.title'),
            inputLabel: tMain('filters.payoutMethods.title'),
            options: payoutMethods
              ? Object.values(payoutMethods).map((payoutMethod) => ({
                  value: payoutMethod.id,
                  label: payoutMethodInfoBuilder(payoutMethod)?.getDisplay(),
                }))
              : [],
          } as const,
        }}
        statusGroups={{
          mapper: RentCollectionPayoutStatusGroups,
          onSelect,
          selectedStatusGroupName: selectedStatusGroup,
          groups: [
            {
              name: 'all',
              label: tMain('statusGroup.all'),
            },
            {
              name: 'received',
              label: tMain('statusGroup.received'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.received} />,
            },
            {
              name: 'inTransit',
              label: tMain('statusGroup.inTransit'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.inTransit} />,
            },
            {
              name: 'upcoming',
              label: tMain('statusGroup.upcoming'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.upcoming} />,
            },
            {
              name: 'paidOffline',
              label: tMain('statusGroup.paidOffline'),
              tooltip: <RentCollectionPayoutStatusGrouping group={RentCollectionPayoutStatusGroups.paidOffline} />,
            },
          ],
        }}
      />
    </Flex>
  )
}
