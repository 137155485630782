import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'

import { Box, useMediaQuery } from '../../components'
import { ContentSection } from './ContentSection'

const useStyle = makeStyles({
  backgroundBlock: {
    position: 'absolute',
    width: ({ isMobile }: { isMobile: boolean }) => (isMobile ? '40%' : 'calc(65vw / 1.77)'),
    height: 'calc(100vw / 1.77)',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    flexGrow: 1,
    zIndex: -1,
    maxHeight: '80%',
    top: ({ direction }: { direction: 'left' | 'right' }) => (direction === 'left' ? -theme.spacing(2) : 'auto'),
    bottom: ({ direction }: { direction: 'left' | 'right' }) => (direction === 'right' ? -theme.spacing(2) : 'auto'),
    left: ({ direction }: { direction: 'left' | 'right' }) => (direction === 'left' ? -theme.spacing(2) : 'auto'),
    right: ({ direction }: { direction: 'left' | 'right' }) => (direction === 'right' ? -theme.spacing(2) : 'auto'),
  },
  imageContent: {
    padding: theme.spacing(2),
    '& > img': {
      objectFit: 'contain',
      width: '100%',
      borderTopRightRadius: theme.spacing(2),
      borderBottomLeftRadius: theme.spacing(2),
      zIndex: 9,
    },
  },
})

const StaticPictureContent: React.FC<{ asset: string }> = ({ asset }) => {
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ContentSection>
      <Box className={useStyle({ direction: 'left', isMobile }).backgroundBlock} />
      <Box className={useStyle({ direction: 'right', isMobile }).backgroundBlock} />
      <Box className={classes.imageContent}>
        <img src={asset} alt='sectionAsset' />
      </Box>
    </ContentSection>
  )
}

export { StaticPictureContent }
