import { HttpMethod } from '@guiker/http'
import { WebsocketAuthToken } from '@guiker/websocket-connection-entity'

export const WEBSOCKET_CONNECTION_PATH = '/websocket-connection'
const GENERATE_AUTH_TOKEN = `${WEBSOCKET_CONNECTION_PATH}/generate-auth-token`

const generateAuthTokenResponseValidator = (payload: unknown) => Promise.resolve(payload as WebsocketAuthToken)

export const routes = {
  generateAuthToken: {
    path: GENERATE_AUTH_TOKEN,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: generateAuthTokenResponseValidator,
  },
}
