import React, { PropsWithChildren } from 'react'

import { Invoice } from '@guiker/payment-shared'
import { PageSection2 } from '@guiker/react-framework'

import { InvoiceTitle } from './components'

type ViewInvoiceDetailProps = PropsWithChildren & {
  invoice: Invoice
  title?: { providerLink: React.ReactNode }
  topActions?: React.ReactNode
}

export const ViewInvoiceLayout: React.FC<ViewInvoiceDetailProps> = ({ children, invoice, title, topActions }) => {
  return (
    <PageSection2 topActions={topActions} title={<InvoiceTitle invoice={invoice} providerLink={title?.providerLink} />}>
      {children}
    </PageSection2>
  )
}
