import { SequentialId } from '@guiker/base-entity'
import { BaseListing, InvestmentAssumptions } from '@guiker/base-listing-shared'
import { DeepPartial } from '@guiker/ts-utils'

import { PropertySale } from './property-sale'

export const assetScopeType = 'property-sale-listing'

export enum CreationStatus {
  DRAFTED = 'DRAFTED',
  COMPLETED = 'COMPLETED',
}

export type PropertySaleListing = BaseListing &
  SequentialId & {
    assumptions: InvestmentAssumptions.Assumptions
    results: InvestmentAssumptions.Results
    propertySale?: DeepPartial<PropertySale>
  }
