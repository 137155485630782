import { CurrencyISO } from '@guiker/money'

import { Canada, UnitedStates } from './jurisdiction'
import { CostArgs } from './utils'

export const buildPurchaseConfiguration = (args: CostArgs) => ({
  'toronto-on': Canada.Ontario.Toronto.purchase(args),
  'ottawa-on': Canada.Ontario.Ottawa.purchase(args),
  'gatineau-qc': Canada.Quebec.Gatineau.purchase(args),
  'montreal-qc': Canada.Quebec.Montreal.purchase(args),
  'quebec-city-qc': Canada.Quebec.QuebecCity.purchase(args),
  'chicago-il': UnitedStates.Illinois.Chicago.purchase,
  [CurrencyISO.CAD]: Canada.Base.purchase(),
  [CurrencyISO.USD]: UnitedStates.Base.purchase(),
})

export type PurchaseConfiguration = ReturnType<typeof buildPurchaseConfiguration>
