import React, { useState } from 'react'

import {
  AnyColor,
  BikeParkingIcon,
  CheckmarkMiniIcon,
  Collapse,
  Divider,
  DrawerChestIcon,
  ElevatorIcon,
  Grid,
  GymIcon,
  JanitorIcon,
  LaundryRoomIcon,
  makeStyles,
  ParkBenchIcon,
  ParkingIcon,
  PoolIcon,
  RecreationRoomIcon,
  TextButton,
  theme,
  useT,
  WheelChairAccessibleIcon,
} from '@guiker/react-framework'
import { BuildingAmenities as BuildingAmenitiesEntity } from '@guiker/real-estate-shared'

import { AmenityInfoLabel } from '../AmenityInfoLabel'

const useStyle = makeStyles(
  {
    button: {
      marginTop: theme.spacing(2),
    },
    notIncluded: {
      marginTop: theme.spacing(1),
    },
  },
  {
    name: 'AmenityComponent',
  },
)

const BuildingAmenities: React.FC<{
  buildingAmenities: BuildingAmenitiesEntity[]
  displayNotIncluded?: boolean
}> = ({ buildingAmenities, displayNotIncluded = true }) => {
  const { tShared } = useT({ domain: 'realEstate' })
  const classes = useStyle()
  const [showNotIncluded, setShowNotIncluded] = useState(false)

  const amenitieInfos: {
    adornment: React.FC<{ color?: AnyColor }>
    label: BuildingAmenitiesEntity
  }[] = [
    { adornment: BikeParkingIcon, label: BuildingAmenitiesEntity.BIKE_PARKING },
    { adornment: ParkBenchIcon, label: BuildingAmenitiesEntity.COMMUNAL_OUTDOOR_SPACE },
    { adornment: JanitorIcon, label: BuildingAmenitiesEntity.CONCIERGE },
    { adornment: ElevatorIcon, label: BuildingAmenitiesEntity.ELEVATOR },
    { adornment: GymIcon, label: BuildingAmenitiesEntity.GYM },
    { adornment: LaundryRoomIcon, label: BuildingAmenitiesEntity.LAUNDRY_ROOM },
    { adornment: DrawerChestIcon, label: BuildingAmenitiesEntity.STORAGE },
    { adornment: ParkingIcon, label: BuildingAmenitiesEntity.INDOOR_PARKING },
    { adornment: ParkingIcon, label: BuildingAmenitiesEntity.OUTDOOR_PARKING },
    { adornment: PoolIcon, label: BuildingAmenitiesEntity.POOL },
    { adornment: RecreationRoomIcon, label: BuildingAmenitiesEntity.RECREATION_ROOM },
    { adornment: WheelChairAccessibleIcon, label: BuildingAmenitiesEntity.WHEEL_CHAIR_ACCESSIBLE },
    { adornment: JanitorIcon, label: BuildingAmenitiesEntity.JANITOR },
  ]

  const filtered = {
    available: amenitieInfos.filter(({ label }) => buildingAmenities.includes(label)),
    unavailable: amenitieInfos.filter(({ label }) => !buildingAmenities.includes(label)),
  }

  return (
    <div>
      <Grid container spacing={1}>
        {filtered.available.map(({ label }) => (
          <AmenityInfoLabel
            key={label}
            adornment={CheckmarkMiniIcon}
            label={tShared(`amenities.${label}`)}
            isAvailable={buildingAmenities.includes(label)}
          />
        ))}
      </Grid>
      {displayNotIncluded && (
        <>
          <div className={classes.notIncluded}>
            <Collapse in={showNotIncluded}>
              <Divider mb={3} />
              <Grid container spacing={1}>
                {filtered.unavailable.map(({ label }) => (
                  <AmenityInfoLabel
                    key={label}
                    label={tShared(`amenities.${label}`)}
                    isAvailable={buildingAmenities.includes(label)}
                  />
                ))}
              </Grid>
            </Collapse>
          </div>

          <TextButton onClick={() => setShowNotIncluded(!showNotIncluded)} className={classes.button}>
            {tShared(`amenities.actions.${showNotIncluded ? 'hideNotIncluded' : 'showNotIncluded'}`)}
          </TextButton>
        </>
      )}
    </div>
  )
}

export { BuildingAmenities }
