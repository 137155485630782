import React, { useState } from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { BookingActions, BookingStatus, checkConfirmReadiness, hasConfirmFailed } from '@guiker/booking-shared'
import {
  Button,
  Flex,
  Link,
  Note as BaseNote,
  NoteProps,
  OutlinedAlertIcon,
  PSmall,
  Trans,
  useModal,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'
import { useSentryContext } from '@guiker/sentry-context'
import { isArray, last } from '@guiker/shared-framework'

import { useBookingContext } from '../../../../hooks'
import { ConfirmBookingModal } from '../../../ConfirmBookingModal'

type BookingConfirmErrorData = {
  title: 'BOOKING_CONFIRM_REQUEST_FAILED'
  code: string
  details: string
  meta: {
    bookingId: string
    type: string
  }
}

const isBookingConfirmError = (meta: any): meta is BookingConfirmErrorData[] => {
  return isArray(meta) && meta?.length > 0 && last(meta)?.title === 'BOOKING_CONFIRM_REQUEST_FAILED'
}

const Note: React.FC<NoteProps> = (props) => <BaseNote type='closable' condensed {...props} />

const UnitManagerBookingConfirm: React.FC = () => {
  const tPrefix = 'main-booking:unitManagerBookingSteps'
  const { t } = useTranslation(['main-booking'])
  const { apiClient, bookingId, booking, can } = useBookingContext()
  const [errorDetail, setErrorDetail] = useState<string>()
  const { captureException } = useSentryContext()

  const isBooked = booking?.status === BookingStatus.BOOKED
  const { isReady: readyToConfirm, payout: isPayoutReady } = checkConfirmReadiness(booking)
  const confirmFailed = hasConfirmFailed(booking)
  const [isConfirmable, setIsConfirmable] = useState(readyToConfirm || confirmFailed)
  const {
    isOpen: isConfirmBookingModalOpen,
    openModal: openConfirmBookingModal,
    closeModal: closeConfirmBookingModal,
  } = useModal()

  const handleConfirmError = (error: any) => {
    try {
      const meta = error?.meta?.meta
      isBookingConfirmError(meta) && setErrorDetail(last(meta).meta.type)
    } catch {
      setErrorDetail('unknown')
      captureException({ error, errorInfo: { bookingId } })
    }
  }

  const { mutate: confirmBooking, status } = useMutation(
    () => apiClient.confirmBooking({ pathParams: { bookingId } }),
    {
      onSuccess: () => {
        setIsConfirmable(false)
        setErrorDetail(undefined)
      },
      onError: handleConfirmError,
    },
  )

  const canGetBookingSteps = can(BookingActions.GetBookingSteps)
  const canConfirmBooking = can(BookingActions.ConfirmBooking) && !isBooked && readyToConfirm

  if (isBooked) {
    return <></>
  }

  return (
    <Flex flexDirection='column' gap={2}>
      {confirmFailed && (
        <Note statusColor='alert' icon={<OutlinedAlertIcon />} title={t(`${tPrefix}.captionPaymentFailedTitle`)}>
          {t(`${tPrefix}.captionPaymentFailed`)}
        </Note>
      )}
      {errorDetail && (
        <Note statusColor='alert' icon={<OutlinedAlertIcon />} title={t(`${tPrefix}.captionBookingConfirmFailedTitle`)}>
          {t(`${tPrefix}.captionBookingConfirmFailed.${errorDetail}`)}
        </Note>
      )}

      {!isPayoutReady && (
        <Note
          icon={<OutlinedAlertIcon />}
          statusColor={isConfirmable ? 'alert' : 'warning'}
          title={t(`${tPrefix}.captionAddAPayoutMethodTitle`)}
        >
          <PSmall mb={0}>
            <Trans i18nKey={`${tPrefix}.captionAddAPayoutMethod`}>
              <Link
                underline={true}
                isExternalLink
                color={'textPrimary'}
                to={webappRoutes.root.wallet().payoutPreferences().path}
                target='_top'
              ></Link>
            </Trans>
          </PSmall>
        </Note>
      )}

      {!canGetBookingSteps && (
        <Note statusColor='warning' title={t(`${tPrefix}.captionNonUser`)}>
          <Link underline={true} to={'/signup'} target={'_blank'}>
            <PSmall mb={0}>{t(`${tPrefix}.captionNonUserLink`)}</PSmall>
          </Link>
        </Note>
      )}

      {isConfirmable && canConfirmBooking && (
        <>
          <Button
            color='primary'
            fullWidth
            onClick={openConfirmBookingModal}
            disabled={!isConfirmable || !isPayoutReady || status === 'loading'}
          >
            {t('unitManagerBookingSteps.confirmBooking')}
          </Button>
          <ConfirmBookingModal
            isLoading={status === 'loading'}
            open={isConfirmBookingModalOpen}
            onClose={closeConfirmBookingModal}
            handleConfirmation={confirmBooking}
          />
        </>
      )}
    </Flex>
  )
}

export { UnitManagerBookingConfirm }
