import React, { useMemo } from 'react'

import {
  Flex,
  FormSection3,
  FormSection4,
  Grid,
  H3,
  PSmall,
  Slider,
  sliderNumberFormatter,
  Spinner,
  TwoColumnsGridLayout,
  useScreenSize,
  useTranslation,
} from '@guiker/react-framework'
import { currency as baseCurrency, CurrencyISO, math, money } from '@guiker/shared-framework'

import { useInvestmentAssumptionContext } from '../../../context'
import { AssumptionComponentProps as Props } from '../assumption-props'
import { findMinAndMax, findMinAndMaxWithRange, getInputProps } from './utils'

const InvestmentAssumptions: React.FC<Props> = ({ defaultValue, name, readOnly = false, schema }) => {
  const { t } = useTranslation('common-investmentAssumption')
  const { investmentResults } = useInvestmentAssumptionContext()
  const { isSm } = useScreenSize()
  const currency = baseCurrency[defaultValue?.price.currency ?? CurrencyISO.CAD]

  const { rentalRevenueMinMax, rentalGrowthRateMinMax, propertyAppreciationRateMinMax } = useMemo(
    () => ({
      rentalRevenueMinMax: findMinAndMax(defaultValue?.assumptions.revenue.rentalRevenue, 0.5),
      rentalGrowthRateMinMax: findMinAndMaxWithRange(defaultValue?.assumptions.revenue.rentalGrowthRate, 0.25, 0.05),
      propertyAppreciationRateMinMax: findMinAndMaxWithRange(
        defaultValue?.assumptions.base.propertyAppreciationRate,
        0.25,
        0.03,
      ),
    }),
    [defaultValue?.assumptions.revenue],
  )

  const inputProps = getInputProps(defaultValue, name, schema, readOnly)

  if (!defaultValue.assumptions) {
    return <Spinner />
  }

  const results = (
    <Grid container spacing={6}>
      <Grid item md={6} xs={12}>
        <Flex flexDirection='column' alignItems='center'>
          <PSmall>{t(`common-propertySale:listing.results.irr`)}</PSmall>
          <H3 mb={0}>{`${math.decimal.round(investmentResults.irr * 100, 2)}%`}</H3>
        </Flex>
      </Grid>
      <Grid item md={6} xs={12}>
        <Flex flexDirection='column' alignItems='center'>
          <PSmall>{t(`common-propertySale:listing.results.estimatedSalesPrice`)}</PSmall>
          <H3 mb={0}>
            {money.fromAmount(investmentResults.estimatedSalesPrice, currency).toString('onlySymbol', true)}
          </H3>
        </Flex>
      </Grid>
    </Grid>
  )

  return (
    <FormSection3>
      {isSm && results}
      <FormSection4>
        <TwoColumnsGridLayout>
          <Slider
            {...inputProps.getRevenueInputProps('rentalRevenue')}
            track={false}
            min={rentalRevenueMinMax.min}
            max={rentalRevenueMinMax.max}
            disabled={readOnly}
            formatter={sliderNumberFormatter.currency(currency)}
            step={1000}
            marks={[
              {
                value: defaultValue.assumptions.revenue.rentalRevenue,
              },
            ]}
          />
          <Slider
            {...inputProps.getRevenueInputProps('rentalGrowthRate')}
            track={false}
            min={rentalGrowthRateMinMax.min}
            max={rentalGrowthRateMinMax.max}
            formatter={sliderNumberFormatter.percentage(2)}
            disabled={readOnly}
            step={0.0025}
            marks={[
              {
                value: defaultValue.assumptions.revenue.rentalGrowthRate,
              },
            ]}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
      <FormSection4>
        <TwoColumnsGridLayout>
          <Slider
            {...inputProps.getBaseInputProps('holdingPeriod')}
            track={false}
            min={1}
            max={25}
            formatter={sliderNumberFormatter.year()}
            step={1}
            marks={[
              {
                value: defaultValue.assumptions.base.holdingPeriod,
              },
            ]}
            disabled={readOnly}
          />
          <Slider
            {...inputProps.getBaseInputProps('propertyAppreciationRate')}
            track={false}
            min={propertyAppreciationRateMinMax.min}
            max={propertyAppreciationRateMinMax.max}
            step={0.0025}
            formatter={sliderNumberFormatter.percentage(2)}
            marks={[
              {
                value: defaultValue.assumptions.base.propertyAppreciationRate,
              },
            ]}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
      {!isSm && results}
    </FormSection3>
  )
}

export { InvestmentAssumptions }
