import { parseDateTime } from '@guiker/date'

import { Task, taskTypeRankings } from '../entity'

export const sortTaskByRanking = (tasks: Task[]): Task[] => {
  if (tasks?.length < 2) return tasks
  return tasks.sort((t1, t2) => {
    const rank1 = taskTypeRankings[t1.type]
    const rank2 = taskTypeRankings[t2.type]
    if (rank1 === rank2) return parseDateTime(t1.createdAt) > parseDateTime(t2.createdAt) ? -1 : 1
    return rank1 - rank2
  })
}
