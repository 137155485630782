import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Project } from '../../entity'
import { baseEvent } from '../base'

export const ProjectEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'PROJECTS',
  data: null as unknown as Project,
  eventTypes: {} as const,
})

export const ProjectEvents = ProjectEventsDefinition.events
export const ProjectEventTypes = ProjectEventsDefinition.eventTypes
export type ProjectEventTypes = ValueOfEventTypes<typeof ProjectEventsDefinition>
