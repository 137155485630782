import { Article, Category } from '@guiker/blog-entity'
import { HttpMethod } from '@guiker/http'

import { readAllArticlesQueryParams } from './schemas/read-all-articles-query-params'
import { readAllCategoriesQueryParams } from './schemas/read-all-categories-query-params'
import { readArticlesBySlugQueryParams } from './schemas/read-articles-by-slug-query-params'
import { readCategoriesBySlugQueryParams } from './schemas/read-categories-by-slug-query-params'

const ARTICLES_PATH = '/posts'
const CATEGORIES_PATH = '/categories'

const articlesResponseValidator = (res: unknown) => Promise.resolve(res as Article[])
const categoriesResponseValidator = (res: unknown) => Promise.resolve(res as Category[])

export const routes = {
  readAllArticles: {
    path: ARTICLES_PATH,
    method: HttpMethod.GET,
    authenticated: false,
    queryParamsValidator: (params: unknown) => readAllArticlesQueryParams.validate(params),
    responseValidator: articlesResponseValidator,
  },
  readArticlesBySlug: {
    path: ARTICLES_PATH,
    method: 'get' as HttpMethod,
    authenticated: false,
    queryParamsValidator: (params: unknown) => readArticlesBySlugQueryParams.validate(params),
    responseValidator: articlesResponseValidator,
  },
  readAllCategories: {
    path: CATEGORIES_PATH,
    method: HttpMethod.GET,
    authenticated: false,
    queryParamsValidator: (params: unknown) => readAllCategoriesQueryParams.validate(params),
    responseValidator: categoriesResponseValidator,
  },
  readCategoriesBySlug: {
    path: CATEGORIES_PATH,
    method: HttpMethod.GET,
    authenticated: false,
    queryParamsValidator: (params: unknown) => readCategoriesBySlugQueryParams.validate(params),
    responseValidator: categoriesResponseValidator,
  },
}
