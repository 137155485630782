import React from 'react'

import { Grid, P } from '@guiker/react-framework'

type BackgroundCheckHistoryItemProps = {
  label: string
  value: string
}

export const BackgroundCheckHistoryItem: React.FC<BackgroundCheckHistoryItemProps> = ({ label, value }) => (
  <Grid container spacing={2}>
    <Grid item xs={8} sm={6}>
      <P color={30} mb={1} textAlign='left'>
        {label}
      </P>
    </Grid>
    <Grid item xs={4} sm={6}>
      <P color={30} mb={1} textAlign='right'>
        {value}
      </P>
    </Grid>
  </Grid>
)
