export enum LeaseRestrictionsAndModificationsTypes {
  erectedFiveYearAgoOrLess = 'erectedFiveYearAgoOrLess',
  destinationChangedFiveYearAgoOrLess = 'destinationChangedFiveYearAgoOrLess',
  noneOfTheAbove = 'noneOfTheAbove',
}

export interface LeaseRestrictionsAndModifications {
  completed: boolean
  immovableReadinessDate: string | null
  type: LeaseRestrictionsAndModificationsTypes
}
