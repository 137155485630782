import { Role, RoleResolver } from '@guiker/permissions'

import { Lease, LeaseRoleNames } from '../entity'
import * as Roles from './roles'

type LeaseUser = {
  userId?: string | null | undefined
  emailAddress?: string | null | undefined
}

export const LeaseRoleResolver: RoleResolver<Lease> = ({ entity: lease, user }): Role[] => {
  if (!lease || !user) {
    return []
  }

  const roles: Role[] = []
  const creator = lease.creator
  const lessees = lease.leaseInformation?.lessees
  const isLessee = lessees?.some((lessee: LeaseUser) => lessee.userId === user.id)

  const lessors = lease.leaseInformation?.lessors
  const isLessor = lessors?.some((lessor: LeaseUser) => {
    const userIdMatch = user.id === lessor.userId
    const userEmailMatch = !!user.emailAddress && user.emailAddress === lessor.emailAddress

    return userIdMatch || userEmailMatch
  })

  const collaborators = lease.collaborators
  const isCollaborator = collaborators?.some(({ userId, type }) => userId === user.id && type === 'UNIT_MANAGER')
  const isSpectator = collaborators?.some(({ userId, type }) => userId === user.id && type === 'SPECTATOR')

  const isGuarantor = lessees?.some((lessee: { guarantor?: LeaseUser }) => {
    return !!user.emailAddress && lessee.guarantor?.emailAddress === user.emailAddress
  })

  roles.push(...(isLessee ? [Roles.LesseeRole] : []))
  roles.push(...(isGuarantor ? [Roles.GuarantorRole] : []))

  if (creator?.draftedAs === LeaseRoleNames.DRAFTER) {
    // If the lease is being drafted as an drafter, collaborators should be able to draft the lease but only the lessor should be able to sign it
    roles.push(...(isLessor ? [Roles.LessorRole] : []))
    roles.push(...(isCollaborator ? [Roles.DrafterRole] : []))
    roles.push(...(isSpectator ? [Roles.SpectatorRole] : []))
  } else {
    // If the lease is being drafted as a lessor, the lessors as well as each of the collaborators should be able to draft and sign the lease
    roles.push(...(isLessor ? [Roles.LessorRole, Roles.DrafterRole] : []))
    roles.push(...(isCollaborator ? [Roles.LessorRole, Roles.DrafterRole] : []))
    roles.push(...(isSpectator ? [Roles.SpectatorRole] : []))
  }

  return roles
}
