import { paginated } from '@guiker/paginate'
import { routesBuilder } from '@guiker/shared-framework'

import { Booking } from '../../../entity'
import { paths } from '../paths'
import {
  cancelBookingRequestSchema,
  createBookingPayloadSchema,
  readAllBookingsQueryParamsSchema,
  searchBookingQueryParamsSchema,
  updateBookingSchema,
  updateRoommateIntroTenantDecisionPayloadSchema,
} from '../schema'

export const BOOKING_PATH = paths.booking.one.base()
export const BOOKING_WITH_ID_PATH = paths.booking.one.withId(':bookingId')

const base = routesBuilder<Booking>()({ basePath: paths.booking.one.base() })
const idNested = routesBuilder<Booking>()({ basePath: BOOKING_WITH_ID_PATH })
const applicantIdNested = routesBuilder<Booking>()({
  basePath: paths.bookingApplicant.all.withScopeAndId('booking', ':bookingId', ':applicantUserId'),
})
const token = routesBuilder<string>()({ basePath: `${BOOKING_WITH_ID_PATH}/generate-access-token` })

export const routes = {
  createBooking: base.buildPost({
    payloadSchema: createBookingPayloadSchema,
  }),
  readAllBookings: base.buildGet({
    paginated,
    path: paths.booking.all.base(),
    queryParamsSchema: readAllBookingsQueryParamsSchema,
  }),
  searchBookings: base.buildGet({
    path: '/search',
    responseIsArray: true,
    queryParamsSchema: searchBookingQueryParamsSchema,
  }),
  requestTimerExtension: applicantIdNested.buildPut({
    path: '/request-timer-extension',
  }),
  readOneBooking: idNested.buildGet({}),
  readOneByCurrentTenant: idNested.buildGet({
    path: '/current-tenant',
  }),
  generateAccessControlToken: token.buildGet({}),
  updateBooking: idNested.buildPut({
    path: '/update',
    payloadSchema: updateBookingSchema,
  }),
  withdrawBooking: idNested.buildPut({
    path: '/withdraw',
    payloadSchema: cancelBookingRequestSchema,
  }),
  rejectBooking: idNested.buildPut({
    path: '/reject',
    payloadSchema: cancelBookingRequestSchema,
  }),
  confirmBooking: idNested.buildPut({
    path: '/confirm',
  }),
  updateRoommateIntroTenantDecision: idNested.buildPost({
    path: '/roommate-intro/update-tenant-decision',
    payloadSchema: updateRoommateIntroTenantDecisionPayloadSchema,
  }),
}
