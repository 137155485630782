import React, { ReactNode } from 'react'

import { AnyColor, Divider, Flex, H3, PBold } from '@guiker/react-framework'

import { useT } from '../../i18n'

type Props = {
  items: {
    color?: AnyColor
    label: string
    price: string
  }[]
  totalPrice?: string
  title?: ReactNode
}

export const Breakdown: React.FC<Props> = ({ items, totalPrice, title }) => {
  const { tShared } = useT({ entity: 'breakdown' })

  return (
    <div>
      {title && <H3 my={4}>{tShared('header')}</H3>}
      {items.map(({ color, label, price }) => (
        <Flex key={label} justifyContent='space-between' mb={2} gap={1}>
          <Flex flexShrink={1}>
            <PBold color={color}>{label}</PBold>
          </Flex>
          <Flex flexShrink={0}>
            <PBold color={color}>{price}</PBold>
          </Flex>
        </Flex>
      ))}
      {totalPrice && <Divider />}
      {totalPrice && (
        <Flex justifyContent='space-between' mb={0} mt={4} gap={1}>
          <PBold>{tShared('totalAmount')}</PBold>
          <PBold>{totalPrice}</PBold>
        </Flex>
      )}
    </div>
  )
}
