import React from 'react'

import { Payments } from '@guiker/react-framework'

type Props = {
  bank?: string
}

export const BankIcon: React.FC<Props> = ({ bank }) => {
  if (!bank) return <Payments.Bank.BankIcon />
  switch (bank) {
    case 'BANK_OF_MONTREAL':
      return <Payments.Bank.BmoIcon />
    case 'BANK_OF_NOVA_SCOTIA':
      return <Payments.Bank.ScotiaBankIcon />
    case 'HSBC_CANADA':
      return <Payments.Bank.HsbcIcon />
    case 'TORONTO_DOMINION_BANK':
      return <Payments.Bank.TdBankIcon />
    case 'ROYAL_BANK_OF_CANADA':
      return <Payments.Bank.RbcIcon />
    case 'NATIONAL_BANK_OF_CANADA':
      return <Payments.Bank.NationalBankIcon />
    case 'CANADIAN_IMPERIAL_BANK_OF_COMMERCE':
      return <Payments.Bank.CibcIcon />
    case 'CANADIAN_WESTERN_BANK':
      return <Payments.Bank.CanadianWesternBankIcon />
    case 'LAURENTIAN_BANK_OF_CANADA':
      return <Payments.Bank.LaurentianIcon />
    case 'CITIBANK_CANADA':
      return <Payments.Bank.CitiIcon />
    case 'JPMORGAN_CHASE':
      return <Payments.Bank.ChaseIcon />
    case 'TANGERINE_BANK':
      return <Payments.Bank.TangerineIcon />
    case 'CAPITAL_ONE':
      return <Payments.Bank.CapitalOneIcon />
    case 'WELLS_FARGO_BANK':
      return <Payments.Bank.WellsFargoIcon />
    case 'BANK_OF_AMERICA':
      return <Payments.Bank.BankOfAmericaIcon />
    default:
      return <Payments.Bank.BankIcon />
  }
}
