import React, { useState } from 'react'

import { PlusIcon, TextButton, useTranslation } from '@guiker/react-framework'

import { BaseBox } from '../BaseBox'
import { ApplicantInvitationForm } from './ApplicantInvitationForm'

type InviteApplicantProps = {
  bookingId: string
}

const InviteApplicant: React.FC<InviteApplicantProps> = ({ bookingId }) => {
  const { t } = useTranslation(['main-booking'])
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BaseBox onClick={() => setModalOpen(true)}>
        <PlusIcon />

        <TextButton>{t('actions.invite.inviteApplicant')}</TextButton>
      </BaseBox>

      <ApplicantInvitationForm bookingId={bookingId} open={modalOpen} setOpen={setModalOpen} />
    </>
  )
}

export { InviteApplicant }
