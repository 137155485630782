import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { InvestorProfile, StockOrder, StockOrderExecution, WithLiquidatedStock } from '../../entity'
import { baseEvent } from '../base'

export const StockOrderExecutionEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'STOCK_ORDER_EXECUTION',
  data: null as unknown as StockOrderExecution,
  eventTypes: {
    FULFILLED: 'FULFILLED',
    CANCELLED: 'CANCELLED',
    PROCESSING_PAYMENT: 'PROCESSING_PAYMENT',
    PENDING_SHARE_ISSUANCE: 'PENDING_SHARE_ISSUANCE',
    PROCESSING_SHARE_ISSUANCE: 'PROCESSING_SHARE_ISSUANCE',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    UPDATED_INVOICE_STATUS: 'UPDATED_INVOICE_STATUS',
    RESERVATION_CANCELLED: {
      type: 'RESERVATION_CANCELLED',
      eventData: null as { refundFee: boolean; buyStockOrder: StockOrder },
    },
    PROCESSING_BUYOUT: {
      type: 'PROCESSING_BUYOUT',
      eventData: null as { investorProfile: WithLiquidatedStock<InvestorProfile> },
    },
    PROMOTIONAL_ORDER_CREATED: 'PROMOTIONAL_ORDER_CREATED',
  } as const,
})

export const StockOrderExecutionEvents = StockOrderExecutionEventsDefinition.events
export const StockOrderExecutionEventTypes = StockOrderExecutionEventsDefinition.eventTypes
export type StockOrderExecutionEventTypes = ValueOfEventTypes<typeof StockOrderExecutionEventsDefinition>
