import { Booking } from '@guiker/booking-shared'
import { TFunction } from '@guiker/i18n'

export const getApplicantName = ({
  booking,
  userIdOrEmailAddress,
  t,
}: {
  booking: Booking
  userIdOrEmailAddress: string
  t: TFunction
}) => {
  const applicant = booking.applicants.find((a) => a.userId === userIdOrEmailAddress)

  if (applicant) {
    return [applicant.application.content?.profile?.legalFirstName || applicant.firstName]
  }

  const applicantGuarantor = booking.applicants.find((a) => {
    const { userId, emailAddress } = { ...a.application?.content?.guarantor?.profile }
    return userId === userIdOrEmailAddress || emailAddress === userIdOrEmailAddress
  })

  if (applicantGuarantor) {
    const guarantorName = applicantGuarantor.application?.content?.guarantor?.profile?.legalFirstName
    const applicantName =
      applicantGuarantor.application?.content?.profile?.legalFirstName || applicantGuarantor.firstName
    return [guarantorName, t('unitManagerBookingSteps.guarantorOf', { name: applicantName })]
  }

  return
}
