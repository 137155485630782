import { isArray, isPlainObject, map, mapValues } from 'lodash'

export const deeply = <T extends Function>(run: T) => {
  return (item: unknown, fn: Function): unknown => {
    if (isPlainObject(item)) {
      return run(
        mapValues(item as object, (v) => deeply(run)(v, fn)),
        fn,
      )
    }

    if (isArray(item)) {
      return map(item as unknown[], (v) => deeply(run)(v, fn))
    }

    return item
  }
}
