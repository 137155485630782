import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { Transfer } from '../../entity'
import { baseEvent } from '../base'

export const EventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'TRANSFER',
  data: null as unknown as Transfer,
  eventTypes: {
    FAILED: 'FAILED',
    PAID: 'PAID',
    REVERSED: 'REVERSED',
    REVERSAL_RECTIFIED: 'REVERSAL_RECTIFIED',
  } as const,
})

export const Events = EventsDefinition.events
export type EventTypes = ValueOfEventTypes<typeof EventsDefinition>
