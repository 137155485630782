import React from 'react'

import { PSmall, useTranslation, useWatch } from '@guiker/react-framework'
import { GuarantorStatus, TenantApplicationGuarantor } from '@guiker/tenant-application-shared'

type InviteGuarantorProps = {
  defaultValue?: TenantApplicationGuarantor
}

const InviteGuarantor: React.FC<InviteGuarantorProps> = ({ defaultValue }) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])

  const guarantorInvited = defaultValue?.status === GuarantorStatus.INVITED

  const guarantorEmail = useWatch({
    name: 'guarantor.profile.emailAddress',
    defaultValue: defaultValue?.profile?.emailAddress,
  })

  const hasGuarantorEmailChanged = defaultValue?.profile?.emailAddress !== guarantorEmail

  return (
    guarantorInvited &&
    !hasGuarantorEmailChanged && (
      <PSmall fontStyle='italic' color={50}>
        {t('main-tenantApplication:willInviteGuarantor.emailSent', {
          email: defaultValue?.profile?.emailAddress,
        })}
      </PSmall>
    )
  )
}

export { InviteGuarantor }
