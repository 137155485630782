import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { AppLayout, makeStyles, useLayoutContext } from '@guiker/components-library'
import { Features, useFeatureFlag } from '@guiker/react-framework'

import { AppBar } from '../AppBar'
import { Footer } from '../Footer'
import { MobileBottomNavigation } from '../MobileBottomNavigation'

const useStyle = makeStyles(
  (theme) => ({
    root: {
      marginBottom: ({ displayMobileNavigation }: { displayMobileNavigation: boolean }) =>
        displayMobileNavigation ? theme.spacing(10) : 0,
    },
  }),
  { name: 'Page' },
)

export const Page: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAtMostTablette } = useLayoutContext()
  const { user } = useAuthenticationContext()
  const isNewMobileNavigationEnabled = useFeatureFlag().isEnabled(Features.NewMobileTabsNavigation)
  const displayMobileNavigation = isNewMobileNavigationEnabled && isAtMostTablette && !!user
  const classes = useStyle({ displayMobileNavigation })

  return (
    <AppLayout className={classes.root} appBar={<AppBar />} footer={<Footer />}>
      {children}
      {displayMobileNavigation && <MobileBottomNavigation />}
    </AppLayout>
  )
}
