import { FlatValue } from '../../../../flat-value'
import { WelcomeTax } from '../expense'

export type Jurisdiction = 'montreal-qc'

export const MontrealExpense = {
  jurisdiction: 'montreal-qc',
} as const

export const SchoolTax = {
  ...MontrealExpense,
  jurisdiction: 'montreal-qc',
  type: 'schoolTax',
  frequency: 'yearly',
  value: {
    type: 'flat',
    amount: null,
  } as FlatValue,
} as const

export const MontrealWelcomeTax = {
  ...WelcomeTax,
  ...MontrealExpense,
  value: {
    type: 'bracket',
    bracket: [
      { rate: 0, threshold: 0 },
      { rate: 0.005, threshold: 55200 * 100 },
      { rate: 0.01, threshold: 276200 * 100 },
      { rate: 0.015, threshold: 552300 * 100 },
      { rate: 0.02, threshold: 1104700 * 100 },
      { rate: 0.025, threshold: 2136500 * 100 },
      { rate: 0.035, threshold: 3113000 * 100 },
      { rate: 0.04 },
    ],
  },
} as const
