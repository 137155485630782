import { AnyColor } from '@guiker/components-core'
import { RentCollectionKpiKey } from '@guiker/statistic-shared'

export const findRentCollectionColor = (status: RentCollectionKpiKey): AnyColor => {
  const mapper: {
    [key in RentCollectionKpiKey]: AnyColor
  } = {
    PENDING: 'info',
    OVERDUE: 'warning',
    PROCESSING: 'active',
    COLLECTED: 'positive',
    FAILED: 'alert',
    RECEIVED: 'success',
  }

  return mapper[status]
}
