import { InvestmentAssumptions } from '../entity'

export const expenseValueTypeChecker = {
  isFlatValue: (expenseValue: InvestmentAssumptions.ExpenseValue): expenseValue is InvestmentAssumptions.FlatValue => {
    return expenseValue?.type === 'flat'
  },
  isPercentageValue: (
    expenseValue: InvestmentAssumptions.ExpenseValue,
  ): expenseValue is InvestmentAssumptions.PercentageValue => {
    return expenseValue?.type === 'percentage'
  },
  isBracketValue: (
    expenseValue: InvestmentAssumptions.ExpenseValue,
  ): expenseValue is InvestmentAssumptions.BracketValue => {
    return expenseValue?.type === 'bracket'
  },
}
