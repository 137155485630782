import { useEffect } from 'react'

import { WebsocketEvent } from '@guiker/websocket-connection-entity'

import { EventPattern } from './event-pattern'
import { useWebsocketListenerManager } from './use-websocket-listener-manager'

type useWebsocketListenerArgs = {
  pattern: EventPattern
  callback: (websocketEvent?: WebsocketEvent) => void
}

export const useWebsocketListener = ({ pattern: eventPattern, callback: eventListener }: useWebsocketListenerArgs) => {
  const { addListener, removeListener } = useWebsocketListenerManager()

  useEffect(() => {
    addListener(eventPattern, eventListener)
    return () => removeListener(eventPattern, eventListener)
  }, [eventPattern])
}
