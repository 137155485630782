import { CurrencyISO } from '@guiker/shared-framework'

import { FinancialStatements } from './financial-statements'
import { Representative } from './representative'
import { Series, SeriesDocument } from './series'
import { StockIssuer } from './stock-issuer'
import { StockOrder } from './stock-order'

export type StockDocument = Omit<Stock, 'series'> & {
  series: SeriesDocument[]
}

export enum StockStatus {
  LISTED = 'LISTED', // open on public market to transact
  DELISTED = 'DELISTED', // removed from public market
}

export enum StockDelistReason {
  LIQUIDATION = 'LIQUIDATION',
}

export type Stock = {
  id: string
  issuer: StockIssuer
  label: string
  tickerSymbol: string
  minPerTransaction: number
  maxPerAccount: number
  representatives: Representative[]
  series?: Series[]
  status: StockStatus
  delistReason?: StockDelistReason
  currency: CurrencyISO
  createdAt?: string
}

export type WithLatestFinancialStatements<T> = T & {
  latestFinancialStatements: FinancialStatements
}

export type WithStockOrders<T> = T & {
  stockOrders: StockOrder[]
}

export type StockWithOrders = WithStockOrders<Stock>
export type StockWithLatestFinancialStatements = WithLatestFinancialStatements<Stock>
