import React, { useMemo } from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { isPaidOffline as isPaidOfflineBase, payInMethodInfoBuilder } from '@guiker/payment-shared'
import { ActivityTracker, PSmall, PSmaller, useDateFormatter, useT } from '@guiker/react-framework'
import { TenantInstalmentEventHistory, TenantInstalmentInvoiceEventStatus } from '@guiker/rent-payment-shared'
import { DateTime, first, last } from '@guiker/shared-framework'

import { TimelineMarker } from './TimelineMarker'
import { buildDefaultTimelineEvents, findBaseSteps, REFUND_STEP, selectIcon } from './utils'

type TimelineProps = {
  events: TenantInstalmentEventHistory[]
  payInMethod?: PayInMethod
}

const buildTenantInstalmentTimelineEvents = (events: TenantInstalmentEventHistory[], payInMethod?: PayInMethod) => {
  const { formatWithRelativeDateTime } = useDateFormatter()
  const { tShared } = useT({ domain: 'rentPayment' })
  const firstEvent = first(events)
  const lastEvent = last(events)
  const isPaidOffline = isPaidOfflineBase(payInMethod?.type)
  const baseSteps = findBaseSteps(isPaidOffline)
  const isLastEventChargePending = lastEvent.status === TenantInstalmentInvoiceEventStatus.CHARGE_PENDING
  const isLastEventChargeSuccess = lastEvent.status === TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED
  const memoizedExpectedDates = useMemo(() => {
    let expectedCharge
    let expectedSettle
    if (!!payInMethod && isPaidOffline) {
      expectedCharge = DateTime.fromISO(lastEvent.createdAt)
      expectedSettle = expectedCharge
    }
    if (isLastEventChargePending && !!payInMethod) {
      expectedCharge = payInMethodInfoBuilder(payInMethod).getExpectedSuccessDate(DateTime.fromISO(lastEvent.createdAt))
      expectedSettle = expectedCharge?.plus({ days: 3 })
    } else if (isLastEventChargeSuccess && !!payInMethod) {
      expectedCharge = DateTime.fromISO(lastEvent.createdAt)
      expectedSettle = expectedCharge?.plus({ days: 3 })
    }
    return { expectedCharge, expectedSettle }
  }, [payInMethod, isLastEventChargePending, isLastEventChargeSuccess])
  const reversedEvents = events.map((e) => e).reverse() // This is to get the latest event in case there are repeated ones

  if (isRefundAfterSettled(events)) {
    baseSteps.push(REFUND_STEP)
  }

  const items = baseSteps.map((step, index) => {
    const lastSuccessfulEvent = reversedEvents.find((event) => step.success.includes(event.status))
    const lastFailedEvent = reversedEvents.find((event) => step.failed?.includes(event.status))
    const isCompleted = !isLastEventChargePending && !!lastSuccessfulEvent
    const isFailed = !isLastEventChargePending && !isCompleted && !!lastFailedEvent
    const isFirst = index === 0
    const currentEventStatus = lastSuccessfulEvent || lastFailedEvent
    const isPaidOfflineSettled =
      step.label === 'settled' && !!isPaidOffline && (isLastEventChargePending || lastSuccessfulEvent)
    const eventDate = isFirst && !currentEventStatus ? firstEvent?.createdAt : currentEventStatus?.createdAt

    const baseMarker = {
      isFirst,
      isLast: index === baseSteps.length - 1,
      isCompleted: isCompleted || !!isPaidOffline,
      isFailed,
      icon: selectIcon(isFirst, isFailed),
    }

    const stepLabel = {
      success:
        step.success?.length === 1
          ? step.success[0]
          : step.success.includes(TenantInstalmentInvoiceEventStatus.CHARGE_PENDING)
          ? TenantInstalmentInvoiceEventStatus.CHARGE_PENDING
          : step.success[0],
      failed: step.failed?.length === 1 ? step.failed[0] : step.failed?.join('-'),
    }

    return {
      label: (
        <PSmall mb={0} color={isCompleted || !!eventDate || !!isPaidOffline ? undefined : 30}>
          {isPaidOfflineSettled
            ? tShared('tenantInstalment.events.status.PAID_OFFLINE')
            : tShared(
                `tenantInstalment.events.status.${
                  isCompleted || isLastEventChargePending || !step.failed ? stepLabel.success : stepLabel.failed
                }`,
              )}
        </PSmall>
      ),
      content: (
        <PSmaller mb={0} color={60}>
          {isPaidOfflineSettled
            ? formatWithRelativeDateTime(memoizedExpectedDates.expectedCharge)
            : !!eventDate
            ? formatWithRelativeDateTime(eventDate)
            : memoizedExpectedDates?.expectedCharge && memoizedExpectedDates?.expectedSettle
            ? tShared('tenantInstalment.events.expectedDate', {
                date:
                  step.label === 'settled'
                    ? formatWithRelativeDateTime(memoizedExpectedDates.expectedSettle)
                    : formatWithRelativeDateTime(memoizedExpectedDates.expectedCharge),
              })
            : undefined}
        </PSmaller>
      ),
      marker: {
        ...baseMarker,
        icon: <TimelineMarker marker={baseMarker} />,
      },
    }
  })

  return items
}

const isRefundAfterSettled = (events: TenantInstalmentEventHistory[]) => {
  if (!events) return
  const lastEvent = last(events)
  return lastEvent.status === TenantInstalmentInvoiceEventStatus.REFUND_SUCCEED
}

const buildTimelineItems = (events: TenantInstalmentEventHistory[], payInMethod?: PayInMethod) => {
  return !!events?.length ? buildTenantInstalmentTimelineEvents(events, payInMethod) : buildDefaultTimelineEvents()
}

export const TenantInstalmentEventsTimeline: React.FC<TimelineProps> = ({ events, payInMethod }) => {
  const items = buildTimelineItems(events, payInMethod)

  return <ActivityTracker items={items} spacing={1} />
}
