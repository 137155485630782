import { routesBuilder } from '@guiker/api-definition'

import { User } from '../../entity'
import { paths } from './paths'
import { findOneUserByEmailAddress } from './schemas'

const base = routesBuilder<User>()({ basePath: paths.user.all.base() })
const idNested = routesBuilder<User>()({ basePath: paths.user.all.withId() })

export const routes = {
  findOneUserByEmailAddress: base.buildGet({
    path: '/search',
    queryParamsSchema: findOneUserByEmailAddress,
  }),
  spoofIn: idNested.buildGet({
    path: '/spoof-in',
  }),
}
