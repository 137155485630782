import React from 'react'

import { PropertySaleListing } from '@guiker/property-sale-shared'
import { PropSharingListing } from '@guiker/propsharing-shared'
import { getLocalizedNeighbourhood, Grid, useTranslation } from '@guiker/react-framework'
import { ProjectWithDeveloper } from '@guiker/real-estate-shared'
import { pascalCase } from '@guiker/shared-framework'

import { ProjectAttribute } from './ProjectAttribute'

type ListOfProjectAttributesProps = {
  project: ProjectWithDeveloper
  listing: PropSharingListing | PropertySaleListing
}

const ListOfProjectAttributes: React.FC<ListOfProjectAttributesProps> = ({ project, listing }) => {
  const { t } = useTranslation('common-baseListing')

  return (
    <Grid container spacing={6}>
      <Grid item sm={4} xs={12}>
        <ProjectAttribute value={pascalCase(project.developer?.name)} label={t('projectAttributes.developer')} />
      </Grid>

      {listing.property?.building?.address?.neighbourhood && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={getLocalizedNeighbourhood(t, {
              countryCode: listing.property?.building?.address?.country,
              citySlug: listing.property?.building?.address?.city,
              neighbourhoodSlug: listing.property?.building?.address?.neighbourhood,
            })}
            label={t('projectAttributes.neighborhood')}
          />
        </Grid>
      )}

      <Grid item sm={4} xs={12}>
        <ProjectAttribute value={pascalCase(listing.deal?.type)} label={t('projectAttributes.status')} />
      </Grid>

      <Grid item sm={4} xs={12}>
        <ProjectAttribute
          value={t(`buildingType.${listing.property?.building?.type}`)}
          label={t('projectAttributes.buildingType')}
        />
      </Grid>

      {listing.property?.building?.configuration?.totalNumberOfUnits && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={listing.property.building.configuration.totalNumberOfUnits}
            label={t('projectAttributes.numberOfUnits')}
          />
        </Grid>
      )}

      {listing.property?.building?.configuration?.totalStoreys && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={listing.property?.building?.configuration?.totalStoreys}
            label={t('projectAttributes.numberOfStoreys')}
          />
        </Grid>
      )}

      {listing.property?.building?.construction?.year && (
        <Grid item sm={4} xs={12}>
          <ProjectAttribute
            value={listing.property?.building?.construction?.year}
            label={t('projectAttributes.buildCompleted')}
          />
        </Grid>
      )}
    </Grid>
  )
}

export { ListOfProjectAttributes }
