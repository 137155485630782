import { HttpMethod } from '@guiker/http'

import { LeaseContract } from '../../entity'
import { byScope, createLeaseContractPayloadValidator, listingIdSchema, nestedRouteValidator } from '../schemas'
import { paths } from './paths'

export const routes = {
  createLeaseContract: {
    path: paths.contract.one.withScope(':scopeType', ':scopeId'),
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as LeaseContract & { documentUrl: string }),
    pathParamsValidator: (params: unknown) => byScope.validate(params),
    payloadValidator: (payload: unknown) => createLeaseContractPayloadValidator.validate(payload),
  },
  sendOnboardingEmailForSignedWithListing: {
    path: `${paths.contract.one.withScope(':scopeType', ':scopeId')}/send-onboarding-email-for-signed-with-listing`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as LeaseContract),
    pathParamsValidator: (params: unknown) => byScope.validate(params),
    payloadValidator: (payload: unknown) => listingIdSchema.validate(payload),
  },
  readAllLeaseContracts: {
    path: paths.contract.one.withScope(':scopeType', ':scopeId'),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as LeaseContract[]),
    pathParamsValidator: (params: unknown) => byScope.validate(params),
  },
  generateEditUrl: {
    path: `${paths.contract.one.withScope(':scopeType', ':scopeId')}/generate-edit-url`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as string),
    pathParamsValidator: (pathParams: unknown) => byScope.validate(pathParams),
    payloadValidator: (payload: unknown) => nestedRouteValidator.validate(payload),
  },
}
