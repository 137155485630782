import { AuthenticatedApiClient } from '@guiker/payment-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export const {
  useContext: useAuthenticatedPaymentPayInMethodContext,
  Context: AuthenticatedPaymentPayInMethodContext,
  ContextProvider: AuthenticatedPaymentPayInMethodContextProvider,
  NestedRouter: AuthenticatedPaymentPayInMethodNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthenticatedApiClient,
  cacheKey: ({ payInMethodId }: { payInMethodId: string }) => `readOnePayInMethodById-${payInMethodId}`,
  fetcher: (apiClient, { payInMethodId }: { payInMethodId: string }) =>
    apiClient.readOnePayInMethod({ pathParams: { payInMethodId } }),
})

export const {
  useContext: useAuthenticatedFundingAccountContext,
  Context: AuthenticatedFundingAccountContext,
  ContextProvider: AuthenticatedFundingAccountContextProvider,
  NestedRouter: AuthenticatedFundingAccountNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthenticatedApiClient,
  cacheKey: () => `readOneFundingAccountByUser`,
  fetcher: (apiClient) => apiClient.readOneFundingAccountByUser(),
  renderLoadingSpinner: false,
})
