import { Payment } from '@guiker/base-entity'
import { ValueOf } from '@guiker/ts-utils'

import { WithFundingAccount, WithUser } from './entity'

export const FundingAccountDepositStatus = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  PAYMENT_SETTLED: 'PAYMENT_SETTLED',
  COMPLETED: 'COMPLETED',
  JOURNAL_ENTRY_INSERTION_FAILED: 'JOURNAL_ENTRY_INSERTION_FAILED',
} as const

export enum DepositType {
  FUNDING = 'FUNDING',
  DIRECT_PURCHASE = 'DIRECT_PURCHASE',
  REFUND = 'REFUND',
  UNITS_SOLD = 'UNITS_SOLD',
  UNITS_BUYOUT = 'UNITS_BUYOUT',
  CUSTOM = 'CUSTOM',
}

export type FundingAccountDepositStatus = ValueOf<typeof FundingAccountDepositStatus>

export const FundingAccountDepositAllowedPayInMethodTypes = [Payment.SupportedPayInMethodType.DIRECT_DEBIT_PAD]

export type WithPayInMethod<T> = T & {
  payInMethod: Payment.PayInMethod
}

export type FundingAccountDeposit = {
  id: string
  fundingAccountId: string
  payInMethod?: {
    id: string
    type: ValueOf<typeof FundingAccountDepositAllowedPayInMethodTypes>
  }
  type: DepositType
  amount: number
  status: FundingAccountDepositStatus
  metadata?: { note?: string } & Record<string, any>
  createdAt: string
}

export type FundingAccountDepositProjection = WithFundingAccount<WithUser<WithPayInMethod<FundingAccountDeposit>>>
