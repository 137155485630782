import React, { useEffect } from 'react'

import { UnexpectedError } from '@guiker/error-pages'
import { useSentryContext } from '@guiker/sentry-context'

import { useTranslation } from '../../i18n'
import { Button, P } from '..'

export type FunctionalErrorBoundaryProps = {
  error: Error
  errorInfo: Record<string, string>
  eventId: string
}

const FunctionalErrorBoundary: React.FC<FunctionalErrorBoundaryProps> = ({ error, errorInfo, eventId }) => {
  const { captureException, showReportDialog } = useSentryContext()
  const { t } = useTranslation('webapp')

  useEffect(() => {
    captureException({ error, errorInfo })
  }, [error, errorInfo, captureException])

  return (
    <UnexpectedError>
      {process.env.NODE_ENV !== 'production' && (
        <>
          <P>{error.message}</P>
          <P>{error.stack}</P>
        </>
      )}
      <Button onClick={() => showReportDialog({ eventId })}>{t('webapp:errors.unexpectedError.cta')}</Button>
    </UnexpectedError>
  )
}

export { FunctionalErrorBoundary }
