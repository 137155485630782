import { TenantApplication } from '@guiker/tenant-application-shared'

export enum ApplicationStatus {
  INITIAL = 'INITIAL',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
}

export type ApplicationStatusHistory = {
  status: ApplicationStatus
  changedAt: string
  changedBy: string // userId
}

export enum RoommateIntroStatus {
  INITIAL = 'INITIAL',
  PROFILED = 'PROFILED',
  INTRO_STARTED = 'INTRO_STARTED',
  ACCEPTED = 'ACCEPTED',
}

export enum RoommateIntroTenantDecision {
  INITIAL = 'INITIAL',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum RoommateIntroTenantDecisionReason {
  AGE_DIFFERENCE = 'AGE_DIFFERENCE',
  LIFESTYLE = 'LIFESTYLE',
  ONE_GENDER = 'ONE_GENDER',
  PET = 'PET',
  DOUBLE_OCCUPANCY = 'DOUBLE_OCCUPANCY',
  OTHER = 'OTHER',
}

export type Consent = {
  content?: string
  isConsentGiven?: boolean
  ipAddress: string
  updatedAt: string
}

export type RoommateIntroTenant = {
  userId: string
  firstName: string
  lastName?: string
  decision: RoommateIntroTenantDecision
  reason?: RoommateIntroTenantDecisionReason
  otherReason?: string
}

export type ForIntroEmail<T extends RoommateIntroTenant> = T & {
  aboutMe: string
  emailAddress: string
}

export type RoommateIntro = {
  status: RoommateIntroStatus
  timer?: {
    startsAt: string
    expiresAt: string
  }
  tenants?: RoommateIntroTenant[]
}

export type Application = {
  userId: string
  status: ApplicationStatus
  roommateIntro?: RoommateIntro
  statusHistory: ApplicationStatusHistory[]
  reviewalComments?: string
  content?: TenantApplication
  leasePromise?: Consent
  /** @deprecated referential id from rental.guiker.com::applicationId */
  rentalApplicationId?: number | null
}

export const RoommateIntroStatusStateTransitions = {
  [RoommateIntroStatus.INITIAL]: [RoommateIntroStatus.PROFILED, RoommateIntroStatus.INTRO_STARTED],
  [RoommateIntroStatus.PROFILED]: [
    RoommateIntroStatus.PROFILED,
    RoommateIntroStatus.INTRO_STARTED,
    RoommateIntroStatus.ACCEPTED,
  ],
  [RoommateIntroStatus.INTRO_STARTED]: [RoommateIntroStatus.INTRO_STARTED, RoommateIntroStatus.ACCEPTED],
  [RoommateIntroStatus.ACCEPTED]: [] as RoommateIntroStatus[],
}

export const ApplicationStatusStateTransitions = {
  [ApplicationStatus.INITIAL]: [
    ApplicationStatus.STARTED,
    ApplicationStatus.SUBMITTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.CHANGE_REQUESTED,
  ],
  [ApplicationStatus.STARTED]: [
    ApplicationStatus.STARTED,
    ApplicationStatus.COMPLETED,
    ApplicationStatus.SUBMITTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.CHANGE_REQUESTED,
  ],
  [ApplicationStatus.COMPLETED]: [ApplicationStatus.COMPLETED, ApplicationStatus.SUBMITTED],
  [ApplicationStatus.SUBMITTED]: [
    ApplicationStatus.SUBMITTED,
    ApplicationStatus.COMPLETED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.REJECTED,
    ApplicationStatus.CHANGE_REQUESTED,
  ],
  [ApplicationStatus.REJECTED]: [ApplicationStatus.COMPLETED, ApplicationStatus.SUBMITTED],
  [ApplicationStatus.CHANGE_REQUESTED]: [
    ApplicationStatus.COMPLETED,
    ApplicationStatus.SUBMITTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.REJECTED,
  ],
  [ApplicationStatus.ACCEPTED]: [ApplicationStatus.CHANGE_REQUESTED, ApplicationStatus.REJECTED],
}
