import { TFunction } from '@guiker/i18n'
import { Building, Orientation, Property, Unit, UnitType } from '@guiker/real-estate-shared'

export const findTotalSquareFootage = (units: Unit[]): number =>
  units?.reduce((sum, unit) => sum + unit.squareFootage, 0)

export const findTotalNumberOfUnitType = (units: Unit[], type: UnitType): number =>
  units?.reduce((count, unit) => {
    if (unit.type === type) return count + 1
    return count
  }, 0)

export const totalNumberOfUnits = (property: Property): number => {
  const configUnits = property.building?.configuration?.totalNumberOfUnits ?? 0
  const totalCalculatedUnits =
    findTotalNumberOfUnitType(property.units, UnitType.COMMERCIAL) +
    findTotalNumberOfUnitType(property.units, UnitType.RESIDENTIAL)
  return configUnits > 0 ? configUnits : totalCalculatedUnits
}

export const showTotalNumberOfUnits = (property: Property): boolean => totalNumberOfUnits(property) > 0

export const showTotalStoreys = (building: Building): boolean =>
  building.configuration?.totalStoreys && building.configuration.totalStoreys > 0

export const showBuildingOrientation = (building: Building): boolean =>
  building.configuration?.orientation && building.configuration.orientation !== Orientation.UNKNOWN

export const showTotalSquareFootage = (property: Property): boolean =>
  (property.totalSquareFootage && property.totalSquareFootage > 0) ||
  (property.units?.length > 0 && findTotalSquareFootage(property.units) > 0)

export const printRoomConfiguration = ({ unit, t }: { t: TFunction; unit: Unit }) => {
  const bedCount =
    unit.roomConfigurations.bedroomCount === 0
      ? t('listing:bedroomCount.studio')
      : t('listing:bedroomCount.nonStudio', { count: unit.roomConfigurations.bedroomCount })
  const bathCount = t('listing:bathroomCount', { count: unit.roomConfigurations.bathroomCount })

  const roomCounts = `${bedCount}${!!unit.roomConfigurations.bathroomCount ? ` · ${bathCount}` : ''}`
  return unit.squareFootage ? `${roomCounts} · ${unit.squareFootage} sq.ft` : roomCounts
}
