import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { TenantApplication } from '../entity'

export const EventsDefinition = generateEventsDefinition({
  context: 'TENANT_APPLICATION',
  entity: 'TENANT_APPLICATION',
  data: null as unknown as TenantApplication,
  eventTypes: {
    CREATED: 'CREATED',
    COUNTRY_MIGRATED: 'COUNTRY_MIGRATED',
    DELETED: 'DELETED',
    UPDATED: 'UPDATED',
    MIGRATED: 'MIGRATED',
    BREAKDOWN_FULLNAME_MIGRATED: 'BREAKDOWN_FULLNAME_MIGRATED',
    CURRENT_ADDRESS_MIGRATED: 'CURRENT_ADDRESS_MIGRATED',
    LEGAL_ADDRESS_MIGRATED: 'LEGAL_ADDRESS_MIGRATED',
    GUARANTOR_INVITED: 'GUARANTOR_INVITED',
    GUARANTOR_CONSENT_UPDATED: 'GUARANTOR_CONSENT_UPDATED',
    CONSENT_UPDATED: 'CONSENT_UPDATED',
    BACKGROUND_CHECK_RESULT_UPDATED: 'BACKGROUND_CHECK_RESULT_UPDATED',
    BACKGROUND_CHECK_FAILED: 'BACKGROUND_CHECK_FAILED',
    PREAPPLICATION_SUBMITTED: 'PREAPPLICATION_SUBMITTED',
    ROOMMATE_PROFILE_SUBMITTED: 'ROOMMATE_PROFILE_SUBMITTED',
    ROOMMATE_PROFILE_MIGRATED: 'ROOMMATE_PROFILE_MIGRATED',
  } as const,
})
export const Events = EventsDefinition.events
export const EventTypes = EventsDefinition.eventTypes
export type EventTypes = ValueOfEventTypes<typeof EventsDefinition>
