import { Article, ArticleCategory, getCategories } from '@guiker/blog-entity'
import { slugifyLowercase } from '@guiker/lodash'

export const routes = {
  blog: {
    path: '/blog',
    categoryNested: function (categorySlug: string) {
      return {
        path: `${this.path}/${slugifyLowercase(categorySlug)}`,
        article: function (articleSlug: string) {
          return {
            path: `${this.path}/${slugifyLowercase(articleSlug)}`,
          }
        },
      }
    },
  },
  getPathFromCategory: (category: ArticleCategory): string => {
    return routes.blog.categoryNested(category.slug).path
  },
  getPathFromArticle: (article: Article): string => {
    const categories = getCategories(article)
    const primaryCategory = categories[0]

    return routes.blog.categoryNested(primaryCategory.slug).article(article.slug).path
  },
} as const
