import React from 'react'

import { isFunction } from 'util'

import { staticAssets } from './static-assets'
import { StaticAssetContext, StaticAssetContextType } from './StaticAssetContext'

type RenderChildren = (context: StaticAssetContextType) => React.ReactNode

const StaticAssetContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const getAsset = <T extends keyof typeof staticAssets>(key: T) => staticAssets[key]

  const value = {
    getAsset,
  }

  return (
    <StaticAssetContext.Provider value={value}>
      {isFunction(children) ? (children as unknown as RenderChildren)(value) : (children as React.ReactNode)}
    </StaticAssetContext.Provider>
  )
}

export { StaticAssetContextProvider }
