export enum RentCollectionStatus {
  pending = 'pending',
  collected = 'collected',
  received = 'received',
  overdue = 'overdue',
  failed = 'failed',
  processing = 'processing',
  refunded = 'refunded',
  terminated = 'terminated',
  paidOffline = 'paidOffline',
}
