import React from 'react'

import { PageNotFound as PageNotFoundComponent } from '@guiker/error-pages'
import { DirectedSource, usePromotionContext } from '@guiker/promotion-context'

import { SplitSpotBanner } from '../../components'

export const PageNotFound: React.FC = () => {
  const { directedSource } = usePromotionContext()

  return (
    <>
      {directedSource === DirectedSource.SPLITSPOT && <SplitSpotBanner />}
      <PageNotFoundComponent />
    </>
  )
}
