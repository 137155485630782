import { Headers } from '@guiker/base-rest-client'
import { LegacyRestClient, Logger } from '@guiker/legacy-rest-client'

import {
  ApplicantRoles,
  InvitationStatus,
  InvitedApplicant,
  LegacyApplicant,
  LegacyApplicantCreationPayload,
  LegacyLeaseCancelPayload,
  LegacyParticipantDisinvitationPayload,
  LegacyUnitManager,
  LegacyUnitManagersInvitationPayload,
} from '../entity'

const BASE_API_PATH = 'app_api'
const BASE_BOOKING_PATH = `${BASE_API_PATH}/bookings`

const endpoints = {
  createApplicants: (bookingId: string): string => `${BASE_BOOKING_PATH}/${bookingId}/applicants`,
  inviteUnitManagers: (bookingId: string): string => `${BASE_BOOKING_PATH}/${bookingId}/unit_managers/invite`,
  withdrawBooking: (bookingId: string) => `${BASE_BOOKING_PATH}/${bookingId}/withdraw`,
  rejectBooking: (bookingId: string) => `${BASE_BOOKING_PATH}/${bookingId}/reject`,
  disinviteApplicants: (bookingId: string): string => `${BASE_BOOKING_PATH}/${bookingId}/applicants/disinvite`,
  completeBooking: (bookingId: string) => `${BASE_BOOKING_PATH}/${bookingId}/booked`,
  expireBooking: (bookingId: string) => `${BASE_BOOKING_PATH}/${bookingId}/expire`,
  readBookingInquiry: (bookingId: string): string => `${BASE_BOOKING_PATH}/${bookingId}/inquiry`,
  completeBookingWithoutPayment: (bookingId: string) => `${BASE_BOOKING_PATH}/${bookingId}/lease/effective`,
  changeRentalOption: (bookingId: string) => `${BASE_BOOKING_PATH}/${bookingId}/change_rental_option`,
}

interface AppApiClientArgs {
  baseUrl: string
  logger?: Logger
  headers?: Headers
}

const LegacyAppApiClient = ({ baseUrl, logger, headers }: AppApiClientArgs) => {
  const baseClient = LegacyRestClient({ baseUrl, logger, headers })

  return {
    processApplicantInvitation: async (
      bookingId: string,
      payload: LegacyApplicantCreationPayload,
    ): Promise<InvitedApplicant[]> => {
      const legacyApplicants = await baseClient
        .post<LegacyApplicant[]>(endpoints.createApplicants(bookingId), payload)
        .then((r) => r.body.data)

      return legacyApplicants.map(({ user }) => ({
        userId: user.id.toString(),
        emailAddress: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        avatarUrl: null,
        invitation: { status: InvitationStatus.INVITED },
        role: ApplicantRoles.APPLICANT,
        accessToken: user.accessToken,
      }))
    },

    processUnitManagerInvitation: async (
      bookingId: string,
      payload: LegacyUnitManagersInvitationPayload,
    ): Promise<LegacyUnitManager[]> => {
      return baseClient
        .put<LegacyUnitManager[]>(endpoints.inviteUnitManagers(bookingId), payload)
        .then((r) => r.body.data)
    },

    withdrawBooking: async (bookingId: string, payload: LegacyLeaseCancelPayload) => {
      return baseClient.put(endpoints.withdrawBooking(bookingId), payload)
    },

    rejectBooking: async (bookingId: string, payload: LegacyLeaseCancelPayload) => {
      return baseClient.put(endpoints.rejectBooking(bookingId), payload)
    },

    disinviteApplicant: async (bookingId: string, payload: LegacyParticipantDisinvitationPayload) => {
      return baseClient.put(endpoints.disinviteApplicants(bookingId), payload).then((r) => r.body.data)
    },

    completeBooking: async (bookingId: string) => {
      return baseClient.put(endpoints.completeBooking(bookingId), {})
    },

    expireBooking: async (bookingId: string) => {
      return baseClient.put(endpoints.expireBooking(bookingId), {})
    },

    changeRentalOption: (bookingId: string, payload: { optionId: number }) => {
      return baseClient.put(endpoints.changeRentalOption(bookingId), payload)
    },

    readBookingInquiry: async (bookingId: string) => {
      const response = await baseClient.get<{ externalId: string }>(endpoints.readBookingInquiry(bookingId))

      // Due to there being some ambiguity between inquiries fetched from rent sync and inquiries sourced internally (guiker.com)
      // just the attributes required for this particular end point are being returned below. In this case it's just the externalId
      // property which contains the conversationId. Reference :: Hyu 🤙

      return {
        externalId: response.body.data.externalId,
      }
    },

    completeBookingWithoutPayment: (bookingId: string) =>
      baseClient.put(endpoints.completeBookingWithoutPayment(bookingId), {}),
  }
}

export { LegacyAppApiClient }
