import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { ImageRecognition } from '../../entity'
import { baseEvent, BaseEventTypes } from '../base'

const baseImageRecognitionEvent = {
  ...baseEvent,
  entity: 'IMAGE_RECOGNITION',
  data: null as unknown as ImageRecognition,
}

export const ImageRecognitionEventTypes = {
  ...BaseEventTypes,
}

export const ImageRecognitionEventsDefinition = generateEventsDefinition(baseImageRecognitionEvent)
export const ImageRecognitionEvents = generateEventsFromEnum(baseImageRecognitionEvent)(ImageRecognitionEventTypes)
