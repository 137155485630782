import { getBankByRouting } from '@guiker/money'

import { PayoutMethod } from '../entity'
import { typeChecker } from './type-checker'

const last4digit = (val: string) => (!!val ? `···· ${val.slice(-4)}` : '')

const payoutMethodInfoBuilder = (payoutMethod: PayoutMethod) => ({
  getLast4Digits: (): string | null => {
    const label =
      typeChecker.isEFT(payoutMethod) || typeChecker.isACH(payoutMethod)
        ? last4digit(payoutMethod.details?.accountNumber)
        : null
    return !!label?.length ? label : null
  },
  getDisplay: (): string => {
    const findLabel = (routingNumber: string, accountNumber: string) => {
      const bank = getBankByRouting(routingNumber)
      const label = last4digit(accountNumber)
      return bank?.shortName ? `${bank.shortName} ${label}` : label
    }
    if (typeChecker.isEFT(payoutMethod)) {
      if (payoutMethod.details?.accountNumber)
        return findLabel(payoutMethod.details?.institutionNumber, payoutMethod.details?.accountNumber)
      else return '--- -- -----'
    } else if (typeChecker.isACH(payoutMethod)) {
      if (payoutMethod.details?.accountNumber)
        return findLabel(payoutMethod.details?.routingNumber, payoutMethod.details?.accountNumber)
      else return '--- -- -----'
    } else {
      return '--- -- -----'
    }
  },
})

export { payoutMethodInfoBuilder }
