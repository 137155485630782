import React, { useState } from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  Button,
  ColumnGridItem,
  ColumnGridLayout,
  Display3,
  Flex,
  makeStyles,
  useLayoutContext,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { InvestorHowItWorksCard } from './InvestorHowItWorksCard'

const elements = ['howItWorks1', 'howItWorks2', 'howItWorks3'] as const

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    image: {
      maxWidth: '100%',
    },
  }),
  { name: 'InvestorHowItWorks' },
)

type InvestorHowItWorksProps = {
  onClick: () => unknown
}

const InvestorHowItWorks: React.FC<InvestorHowItWorksProps> = ({ onClick }) => {
  const classes = useStyles()
  const { isTablette } = useLayoutContext()
  const { t } = useTranslation(['webapp', 'common'])
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('investors')
  const [openCard, setOpenCard] = useState<string>(elements[0])

  const Image = <img src={assets.howItWorks} width='570px' className={classes.image} />
  const Cards = (
    <div>
      <Display3 mb={5}>{t(`webapp:investors.howItWorks.title`)}</Display3>
      <Flex flexDirection='column' gap={isTablette ? 2 : 5}>
        {elements.map((el, index) => (
          <InvestorHowItWorksCard
            isOpen={openCard === el}
            onClick={() => setOpenCard(openCard === el ? null : el)}
            i18nKey={el}
            key={el}
            index={index}
          />
        ))}
      </Flex>
    </div>
  )

  const CallToAction = (
    <Flex fullWidth justifyContent='center'>
      <Button onClick={onClick}>{t(`webapp:investors.howItWorks.cta`)}</Button>
    </Flex>
  )

  return (
    <ColumnGridLayout gap={10} gridTemplateColumns={2} sm={{ gridTemplateColumns: 1 }}>
      {isTablette ? (
        <>
          {Cards}
          {CallToAction}
        </>
      ) : (
        <>
          {Image}
          {Cards}
          <ColumnGridItem md={2}>{CallToAction}</ColumnGridItem>
        </>
      )}
    </ColumnGridLayout>
  )
}

export { InvestorHowItWorks }
