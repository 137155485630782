import { generateEventsDefinition } from '@guiker/event'

import { FinancialStatementsDocument } from '../../entity'
import { baseEvent } from '../base'

export const FinancialStatementsEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'FINANCIAL_STATEMENTS',
  data: null as unknown as FinancialStatementsDocument,
  eventTypes: {} as const,
})
