import React from 'react'

import { useTranslation } from '@guiker/i18n'

import { H3, P, StaticPageContent } from '../../components'
import { useStaticAssetContext } from '../../hooks'

const WhoWeAre: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('careers')

  return (
    <StaticPageContent asset={assets?.image1} direction='right'>
      <H3>{t('careers.ourCraftHeader')}</H3>
      <P mb={0}>{t('careers.ourCraftSubtext')}</P>
      <P mb={0}>{t('careers.ourCraftSubtext2')}</P>
    </StaticPageContent>
  )
}

export { WhoWeAre }
