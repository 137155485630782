import { optionalConcat } from '@guiker/lodash'
import { PayInMethod, typeChecker } from '@guiker/payment-shared'

export const detailsByType = (payInMethod: PayInMethod, t: (key: string, options?: unknown) => string): string => {
  return typeChecker.isCreditCard(payInMethod)
    ? optionalConcat([payInMethod.details?.brand, payInMethod.details?.lastDigits], ' ')
    : typeChecker.isACH(payInMethod)
    ? optionalConcat([payInMethod.details?.bankName, payInMethod.details?.accountNumber], ' ')
    : typeChecker.isEFT(payInMethod)
    ? `${t(`failedNote.bank`, { accountNumber: payInMethod.details?.accountNumber })}`
    : `${t('offline')}`
}
