import { DateTime, parseDateTime } from '@guiker/date'
import { TypeOf, yup } from '@guiker/yup-util'

import { GuarantorProfile } from '../../entity'
import { tenantApplicationGuarantorBackgroundCheckSchema } from './background-check'
import { addressSchema } from './legal-address'
import { supportingDocumentSchema } from './supporting-document'

const guarantorEmailUniquenessSchema = yup
  .string()
  .email()
  .when('$email', (email: string, schema: yup.StringSchema) => {
    return email
      ? schema.test('tenantApplication.guarantorEmailUniqueness', 'Email is not unique', function (value) {
          return value?.toUpperCase() !== email?.toUpperCase()
        })
      : schema
  })

export const inviteGuarantorSchema = yup.object({
  profile: yup.object<Pick<GuarantorProfile, 'relationship' | 'emailAddress'>>({
    relationship: yup.string().required(),
    emailAddress: guarantorEmailUniquenessSchema.required(),
  }),
})

export const guarantorProfileSchema = yup.object<GuarantorProfile>({
  legalFirstName: yup.string().required(),
  legalLastName: yup.string().required(),
  dateOfBirth: yup
    .string()
    .required()
    .test({
      name: 'max',
      test: (date) => {
        const maxDate = DateTime.local()
        const birthdate = DateTime.fromISO(date)
        return birthdate <= maxDate
      },
    })
    .transform((date) => parseDateTime(date).toFormat('yyyy-LL-dd')),
  emailAddress: guarantorEmailUniquenessSchema.required(),
  occupation: yup.string().required(),
  relationship: yup.string().required(),
  annualSalary: yup.string().required(),
  phoneNumber: yup.string().required(),
})

export const guarantorAddressSchema = addressSchema

export const tenantApplicationGuarantorSchema = yup.object({
  profile: guarantorProfileSchema.required(),
  address: guarantorAddressSchema.required(),
  supportingDocuments: yup.array().of(supportingDocumentSchema).nullable(),
  backgroundCheck: tenantApplicationGuarantorBackgroundCheckSchema,
})

export type InviteGuarantorPayloadSchema = TypeOf<typeof inviteGuarantorSchema>
