import { generateEventsFromEnum } from '@guiker/event'

import { ListingInquiry } from '../../entity'
import { ListingInquiryEventTypes } from './event-types'

export const BaseListingInquiryEvent = {
  context: 'LISTING_INQUIRY',
  data: null as unknown as ListingInquiry,
} as const

export const ListingInquiryEvents = generateEventsFromEnum({
  ...BaseListingInquiryEvent,
  entity: 'LISTING_INQUIRY',
})(ListingInquiryEventTypes)
