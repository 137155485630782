import { ShareJournalEntry } from '../entity'

export const countDebitOrCredit = ({
  journalEntries,
  column,
}: {
  journalEntries: ShareJournalEntry[]
  column: 'credit' | 'debit'
}): number => journalEntries.reduce((acc, entry) => (entry.action === column ? acc + entry.unit.quantity : acc), 0)

export const findStockJournalBalance = (journalEntries: ShareJournalEntry[]) =>
  journalEntries.reduce(
    (acc, entry) => (entry.action === 'credit' ? acc + entry.unit.quantity : acc - entry.unit.quantity),
    0,
  )
