import React from 'react'

import { Button, Flex, Modal, PSmall, useT } from '@guiker/react-framework'

import { AccountInformation } from './AccountInformation'
import { Terms } from './Terms'

type Props = {
  modal: {
    isOpen: boolean
    openModal: () => void
    closeModal: () => void
  }
  state: {
    name: string
    routingNumber: string
    accountNumber: string
    mask: string
    email: string
  }
  acssSetupSecret: string
  accountId: string
  onSelect: (args: { publicToken: string; accountId: string }) => void
}

export const MandateAgreement: React.FC<Props> = ({ modal, state, acssSetupSecret, accountId, onSelect }) => {
  const { tBase } = useT({ domain: 'payment', screenName: 'screens.mandateAgreement' })

  const onSubmit = () => {
    onSelect({ accountId, publicToken: acssSetupSecret })
  }

  const { tMain } = useT({ domain: 'payment', screenName: 'screens.mandateAgreement' })

  const contactContent = [
    tMain('contact.line1'),
    tMain('contact.line2'),
    tMain('contact.line3'),
    tMain('contact.line4'),
    tMain('contact.line5'),
  ]

  return (
    <Modal title={tMain('title')} subtitle={tMain('guiker')} maxWidth={750} open={modal.isOpen}>
      <Flex flexDirection='column' gap={3}>
        <Flex flexDirection='column'>
          {contactContent.map((line) => (
            <PSmall fontStyle='italic' color={50}>
              {line}
            </PSmall>
          ))}
        </Flex>
        <AccountInformation state={state} />
        <Terms />
        <Flex fullWidth justifyContent='flex-end'>
          <Button onClick={onSubmit}>{tBase('actions.submit')}</Button>
        </Flex>
      </Flex>
    </Modal>
  )
}
