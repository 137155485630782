import { BookingRoleNames, UnitManager } from '../unit-manager'

export const adminManagedBookingUser = (unitManagers: UnitManager[]) => {
  const adminManagedRoles = [
    BookingRoleNames.LANDLORD,
    BookingRoleNames.LISTING_OPERATOR,
    BookingRoleNames.UNIT_MANAGER,
  ]
  return unitManagers.find(
    (unitManager) =>
      unitManager.emailAddress.toLowerCase().endsWith('@guiker.com') && adminManagedRoles.includes(unitManager.role),
  )
}

export const isAdminManagedBooking = (unitManagers: UnitManager[]) => {
  return !!adminManagedBookingUser(unitManagers)
}
