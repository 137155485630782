import { Role, RoleResolver } from '@guiker/permissions'

import { BookingRoleNames, Roles } from '../permissions'
import { Booking, isUnitManager } from '.'
import { isApplicant } from './helpers'

export const BookingRoleResolver: RoleResolver<Booking> = ({ entity: booking, user }): Role[] => {
  const userId = user?.id?.toString()
  const isApplicantUser = isApplicant({ booking, userId })
  const hasRole = isApplicantUser || isUnitManager({ booking, userId })

  if (userId && !hasRole) {
    return []
  }

  const { role } = isApplicantUser
    ? { role: BookingRoleNames.APPLICANT }
    : { ...booking.unitManagers.find((um) => um.userId === userId) }
  switch (role) {
    case BookingRoleNames.UNIT_MANAGER:
      return [Roles.UnitManagerRole]
    case BookingRoleNames.AGENT:
      return [Roles.AgentRole]
    case BookingRoleNames.LANDLORD:
      return [Roles.LandlordRole]
    case BookingRoleNames.INVITED_LANDLORD:
      return [Roles.InvitedLandlordRole]
    case BookingRoleNames.LEASING_ASSOCIATE:
      return [Roles.LeasingAssociateRole]
    case BookingRoleNames.APPLICANT:
      return [Roles.ApplicantRole]
    case BookingRoleNames.SPECTATOR:
      return [Roles.SpectatorRole]
    default:
      // default to ListingOperator to give unaccounted roles full authoritive power
      return [Roles.ListingOperatorRole]
  }
}
