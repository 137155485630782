import { Base64 } from '@guiker/base64'

// for packages/ui/app/main/app/src/screens/invited/Invited.tsx
export type InvitationMeta = {
  /** @description url path to be redirected after authenticated */
  redirect: string
  /** @description userId of invited account */
  userId?: string
  /** @description invitation source to control additional information */
  source?: 'willow'
}

const isInvitationMeta = (meta: any): meta is InvitationMeta => {
  return !!meta && typeof meta === 'object' && !!meta['redirect'] && !!meta['userId']
}

export const invitationMetaScheme = {
  encode: (meta: InvitationMeta) => Base64.encode(JSON.stringify(meta)),
  decode: (code: string) => {
    let meta: unknown

    try {
      meta = JSON.parse(Base64.decode(decodeURIComponent(code)))
    } catch {}

    if (isInvitationMeta(meta)) {
      return meta
    } else {
      throw new Error('Unable decode invitation meta')
    }
  },
}
