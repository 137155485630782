import React, { useEffect } from 'react'

import { clsx, Divider, I18nextProvider, initialize, makeStyles, theme } from '@guiker/react-framework'

import { LegacyBooking } from '../../'
import { useBookingContext } from '../../hooks'
import { ApplicantBookingSteps, BookingOverview, Participants } from './components'
import { UnitManagerBookingPanel } from './components/UnitManagerBookingSteps/UnitManagerBookingPanel'
import { BookingPanelContextProvider } from './context'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%',
    zIndex: 20,
  },
})

type BookingPanelProps = {
  className?: string
}

const Content: React.FC<BookingPanelProps> = ({ className }) => {
  const classes = useStyles()
  const { booking, bookingUser } = useBookingContext()

  useEffect(() => {
    booking &&
      bookingUser?.type === 'applicant' &&
      LegacyBooking.pushEvent({
        type: 'BOOKING_LOADED',
        value: booking.status,
      })
  }, [booking])

  if (!booking) {
    return <></>
  }

  return (
    <div className={clsx(classes.container, className)}>
      <BookingOverview />
      <Divider my={0} />
      <Participants />
      <Divider my={0} />

      {bookingUser.type === 'applicant' && <ApplicantBookingSteps />}
      {bookingUser.type === 'unitManager' && <UnitManagerBookingPanel />}
    </div>
  )
}

const BookingPanel: React.FC<BookingPanelProps> = ({ ...props }) => (
  <I18nextProvider i18n={initialize()}>
    <BookingPanelContextProvider>
      <Content {...props} />
    </BookingPanelContextProvider>
  </I18nextProvider>
)

export { BookingPanel }
