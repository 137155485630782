import React from 'react'

import { clsx } from '@guiker/clsx'
import { isMobile, toPx } from '@guiker/components-core'
import { Box, Flex, H4, makeStyles, P } from '@guiker/components-library'
import { isString } from '@guiker/lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    minHeight: `calc(100vh - ${toPx(theme.dimensions.appBar.height.desktop)})`,
    [isMobile]: {
      minHeight: `calc(100vh - ${toPx(theme.dimensions.appBar.height.mobile)})`,
    },
  },
  image: {
    maxWidth: 260,
    width: '100%',
  },
  center: {
    textAlign: 'center',
  },
}))

type ErrorPageProps = React.PropsWithChildren & {
  className?: string
  illustration?: React.ReactNode
  primaryText?: string | React.ReactNode
  secondaryText?: string | React.ReactNode
}

const ErrorPage: React.FC<ErrorPageProps> = ({ illustration, primaryText, secondaryText, className, children }) => {
  const classes = useStyles()

  return (
    <Flex
      px={3}
      flexGrow={1}
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='100%'
      className={clsx(className, classes.root)}
    >
      {illustration && <Box mb={4}>{illustration}</Box>}
      {primaryText && isString(primaryText) ? (
        <H4 mb={2} className={classes.center}>
          {primaryText}
        </H4>
      ) : (
        primaryText
      )}
      {secondaryText && isString(secondaryText) ? (
        <P mb={2} className={classes.center}>
          {secondaryText}
        </P>
      ) : (
        secondaryText
      )}
      {children && <Box mt={3}>{children}</Box>}
    </Flex>
  )
}

export { ErrorPage }
