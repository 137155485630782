import { BaseListing, Picture } from '../entity/listing'

export const mapListingPictures = <T extends BaseListing>({
  listings,
  pictures,
}: {
  listings: T[]
  pictures: Picture[]
}) => {
  return listings.map((listing) => ({
    ...listing,
    pictures: listing.pictures.map((listingPicture) => pictures.find((picture) => picture.id === listingPicture.id)),
  }))
}
