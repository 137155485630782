import React, { useEffect } from 'react'

import { PayInMethodType } from '@guiker/payment-shared'
import { CountryCode, PlaidLink } from '@guiker/payment-verification-components'
import {
  Box,
  Flex,
  Payments,
  TextField,
  TwoColumnsGridLayout,
  useApiFormAction,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'

const DirectDebitACHCreationForm: React.FC<{
  isProcessing: boolean
  closeForm: () => void
  isManualInput?: boolean
}> = ({ closeForm, isManualInput = false }) => {
  const tPrefix = 'common-payment:directDebit'

  const { t } = useTranslation(['common, common-payment'])
  const bankAccountId = useWatch({ name: 'bankAccountId', defaultValue: null })
  const formContext = useFormContext()
  const apiFormAction = useApiFormAction()

  const handleOnSelect = ({ publicToken, accountId }) => {
    formContext.setValue(`publicToken`, publicToken)
    formContext.setValue(`bankAccountId`, accountId)
    formContext.setValue(`payInMethodType`, PayInMethodType.DIRECT_DEBIT_ACH)
  }

  useEffect(() => {
    bankAccountId && apiFormAction.onSubmit()
  }, [bankAccountId])

  return (
    <Flex flexDirection='column' gap={2}>
      {isManualInput ? (
        <TwoColumnsGridLayout>
          <TextField required label={t(`${tPrefix}.ACH.routingNumber`)} name='bankAccount.routingNumber' />
          <TextField required label={t(`${tPrefix}.ACH.accountNumber`)} name='bankAccount.accountNumber' />
          <TextField required label={t(`${tPrefix}.ACH.wireRouting`)} name='bankAccount.wireRouting' />
        </TwoColumnsGridLayout>
      ) : (
        <>
          <Box display='none'>
            <TextField type='hidden' name='bankAccountId' />
            <TextField type='hidden' name='publicToken' />
            <TextField type='hidden' name='payInMethodType' />
          </Box>

          <Flex gap={1} flexWrap='nowrap' alignItems='center'>
            <Payments.Bank.BankIcon width={54} height={36} />
            <PlaidLink
              countryCode={CountryCode.UnitedStates}
              label={t('common-payment:connector.link')}
              onSelect={handleOnSelect}
              onError={() => closeForm()}
              isLoading
            />
          </Flex>
        </>
      )}
    </Flex>
  )
}

export { DirectDebitACHCreationForm }
