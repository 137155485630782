import { Roommate, routesBuilder } from '@guiker/shared-framework'

import { paths } from '../paths'

const idNestedRoommate = routesBuilder<Roommate>()({
  basePath: paths.booking.one.withId(':bookingId'),
})

export const routes = {
  readBookingRoommates: idNestedRoommate.buildGet({
    path: '/roomates',
    responseIsArray: true,
  }),
}
