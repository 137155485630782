import { routesBuilder } from '@guiker/shared-framework'

import { PropertySaleListing } from '../../entity'
import { paths } from './paths'
import {
  searchListingQuerySchema,
  updateListingAssumptionsSchema,
  updateListingPropertyBuildingDetailSchema,
  updateListingPropertyBuildingSchema,
  updateListingPropertyUnitsSchema,
  updateListingSchema,
  updateListingStatusSchema,
} from './schemas'

const base = routesBuilder<PropertySaleListing>()({ basePath: paths.listing.all.base() })
const idNested = routesBuilder<PropertySaleListing>()({ basePath: paths.listing.all.withId() })

export const routes = {
  readOneListing: idNested.buildGet({}),
  deleteOneListing: idNested.buildDelete({
    response: null as void,
  }),
  updateListingDisplayInfo: idNested.buildPut({
    payloadSchema: updateListingSchema,
  }),
  updateListingPropertyBuilding: idNested.buildPut({
    path: '/property/building/address',
    payloadSchema: updateListingPropertyBuildingSchema,
  }),
  updateListingPropertyBuildingDetail: idNested.buildPut({
    path: '/property/building',
    payloadSchema: updateListingPropertyBuildingDetailSchema,
  }),
  updateListingPropertyUnit: idNested.buildPut({
    path: '/property/units',
    payloadSchema: updateListingPropertyUnitsSchema,
  }),
  updateListingAssumptions: idNested.buildPut({
    path: '/assumptions',
    payloadSchema: updateListingAssumptionsSchema,
  }),
  updateListingStatus: idNested.buildPut({
    path: '/status',
    payloadSchema: updateListingStatusSchema,
  }),
  createListing: base.buildPost({ path: '/create' }),
  readAllListings: base.buildGet({
    paginated: true,
    queryParamsSchema: searchListingQuerySchema,
  }),
}
