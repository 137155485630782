import { generateEventsDefinition } from '@guiker/event'

import { NEWBuilding, NEWProperty } from '../../entity'
import { baseEvent } from '../base'

type SyncedEventData = {
  legacyId: number
  property: NEWProperty
}

const baseBuildingEvent = {
  ...baseEvent,
  entity: 'BUILDING',
  data: null as unknown as NEWBuilding,
}

export const BuildingEventsDefinition = generateEventsDefinition({
  ...baseBuildingEvent,
  eventTypes: {
    SYNCED: {
      type: 'SYNCED',
      eventData: null as SyncedEventData,
    },
  } as const,
})

export const Events = BuildingEventsDefinition.events
export const EventTypes = BuildingEventsDefinition.eventTypes
export type BuildingEventTypes = typeof EventTypes
