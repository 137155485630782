import React from 'react'

import { Chip, Flex, makeStyles, PSmall, useT } from '@guiker/react-framework'
import { camelCase } from '@guiker/shared-framework'
import { RentCollectionKpiKey, RentCollectionStatus } from '@guiker/statistic-shared'

import { findRentCollectionColor } from '../../utils'

const useStyle = makeStyles({
  status: {
    display: 'inline',
  },
})

const StatusChip: React.FC<{ status: RentCollectionStatus; groupKey: RentCollectionKpiKey }> = ({
  status,
  groupKey,
}) => {
  const { tMain } = useT({ domain: 'statistic', screenName: 'components' })
  const classes = useStyle()
  return (
    <Chip size='smaller' color={findRentCollectionColor(groupKey)} className={classes.status}>
      {tMain(`rentCollectionPieChart.status.${camelCase(status)}`)}
    </Chip>
  )
}

const RentCollectionStatusChip: React.FC<{ status: RentCollectionStatus; groupKey: RentCollectionKpiKey }> = ({
  status,
  groupKey,
}) => {
  const { tMain } = useT({ domain: 'statistic', screenName: 'components' })
  return (
    <div>
      <StatusChip status={status} groupKey={groupKey} />
      <PSmall color={'textSecondary'} component={'span'} ml={1}>
        {tMain(`rentCollectionPieChart.tooltip.${camelCase(status)}`)}
      </PSmall>
    </div>
  )
}

const RentCollectionStatusGrouping: React.FC<{ group: RentCollectionStatus[]; groupKey: RentCollectionKpiKey }> = ({
  group,
  groupKey,
}) => {
  return (
    <Flex flexDirection='column' gap={2}>
      {group?.map((status) => (
        <RentCollectionStatusChip status={status} groupKey={groupKey} />
      ))}
    </Flex>
  )
}
export { RentCollectionStatusGrouping }
