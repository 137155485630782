const GRADE_COLOR_MAP = {
  'A+': '#3BBC9C',
  A: '#4FC178',
  'B+': '#9AC445',
  B: '#DCC41A',
  'C+': '#F1B90F',
  C: '#F19316',
  D: '#F06A2D',
  F: '#E74C3C',
  'N/A': '#B4B4B4',
}

export const getGradeColor = (grade: keyof typeof GRADE_COLOR_MAP) => {
  return GRADE_COLOR_MAP[grade]
}
