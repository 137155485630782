import React from 'react'

import { Box, FormSectionGroup, Link, useTranslation } from '@guiker/react-framework'
import { SupportingDocument } from '@guiker/tenant-application-shared'

import { SupportingDocumentSection } from './SupportingDocumentSection'

type SupportingDocumentsContentProps = {
  defaultValue: SupportingDocument[]
  editRoute?: string
  readOnly: boolean
}

const documentTypes = ['governmentId', 'proofOfIncome']

const SupportingDocumentsContent: React.FC<SupportingDocumentsContentProps> = ({
  defaultValue,
  editRoute,
  readOnly,
}) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])

  return (
    <FormSectionGroup
      title={t('main-tenantApplication:screens.steps.documents.title')}
      subtitle={t('main-tenantApplication:screens.steps.documents.description')}
      topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
    >
      {documentTypes.map((documentType, index) => (
        <Box key={index} width='100%'>
          <SupportingDocumentSection
            defaultValue={defaultValue?.find((doc) => doc?.type === documentType)}
            documentType={documentType}
            name={`supportingDocuments[${index}]`}
            readOnly={readOnly}
          />
        </Box>
      ))}
    </FormSectionGroup>
  )
}

export { SupportingDocumentsContent }
