import { FundingAccountDeposit } from './deposit'
import { FundingAccountJournalEntry, FundingAccountJournalEntrySource } from './entity'
import { FundingAccountWithdrawal } from './withdrawal'

const buildJournalEntry = ({
  attributes,
  entrySource,
}: {
  attributes: Omit<FundingAccountJournalEntry, 'id' | 'idempotencyKey' | 'metadata'>
  entrySource: FundingAccountJournalEntrySource
}): Omit<FundingAccountJournalEntry, 'id'> => {
  const { action, fundingAccountId } = attributes
  const idempotencyKey = `${action}:${fundingAccountId}:${entrySource.entity}:${entrySource.id}`
  return { ...attributes, idempotencyKey, metadata: { source: entrySource } }
}

export const journalEntryBuilder = {
  fromDeposit: (deposit: FundingAccountDeposit) => {
    return {
      credit: () =>
        buildJournalEntry({
          entrySource: {
            entity: 'FundingAccountDeposit',
            id: deposit.id,
          },
          attributes: {
            action: 'credit',
            fundingAccountId: deposit.fundingAccountId,
            amount: deposit.amount,
          },
        }),
    }
  },
  fromWithdrawal: (withdrawal: FundingAccountWithdrawal) => {
    return {
      debit: () =>
        buildJournalEntry({
          entrySource: {
            entity: 'FundingAccountWithdrawal',
            id: withdrawal.id,
          },
          attributes: {
            action: 'debit',
            fundingAccountId: withdrawal.fundingAccountId,
            amount: withdrawal.amount,
          },
        }),
    }
  },
}
