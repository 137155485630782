import { Errors, StatusCodes } from '@guiker/common'

import { BookingStatus } from '../../booking-status'

export class InvalidBookingStatusTransitionError extends Errors.BaseError {
  constructor({
    bookingId,
    currentStatus,
    newStatus,
  }: {
    bookingId: string
    currentStatus: BookingStatus
    newStatus: BookingStatus
  }) {
    super(
      `Change of status for booking ${bookingId} from ${currentStatus} to ${newStatus} is invalid`,
      InvalidBookingStatusTransitionError.name,
      StatusCodes.UNPROCESSABLE_ENTITY,
    )
  }
}
