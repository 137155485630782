import { generateEventsFromEnum } from '@guiker/event'

import { baseEvent } from '../base'
import { ListingEventTypes, ListingInquiryEventTypes } from './event-types'

export const BaseLegacyListingEvent = {} as const

export const LegacyListingInquiryEvents = generateEventsFromEnum({
  ...baseEvent,
  data: null as unknown as { bookingId: string; userId: string },
  /** @todo update on Java legacy. this is not the right entity */
  entity: 'INQUIRY',
})(ListingInquiryEventTypes)

export const LegacyListingEvents = generateEventsFromEnum({
  ...baseEvent,
  data: null as unknown as { entityType: string; entityId: string; userId: string },
  entity: 'LEGACY_LISTING',
})(ListingEventTypes)
