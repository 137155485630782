import { PhoneNumberSchema } from '@guiker/phone-number'
import { ageVerification, yup } from '@guiker/yup-util'

const legalNameSchema = yup.object({
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  lastName: yup.string().required(),
})

export const aboutYouSchema = yup.object({
  legalName: legalNameSchema.required(),
  dateOfBirth: yup
    .string()
    .required()
    .test({
      name: 'ageMustOver18',
      test: (date) => ageVerification({ date }),
    }),
  preferredName: yup.string().nullable(),
  phone: PhoneNumberSchema(true).required(),
  address: yup
    .object({
      streetNumber: yup.string().required(),
      street: yup.string().required(),
      postalCode: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      country: yup.string().required(),
    })
    .required(),
})
