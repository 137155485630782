import { CurrencyISO } from '@guiker/money'

import { Price } from '../../../listing/price'

export const PurchaseBase = {
  price: {
    amount: 0,
    currency: CurrencyISO.USD,
  } as Price,
  topup: {
    amount: 0,
    currency: CurrencyISO.USD,
  } as Price,
} as const
