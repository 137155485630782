import React, { useState } from 'react'

import { Location } from '@guiker/base-entity'
import { makeStyles, theme } from '@guiker/components-core'
import {
  Box,
  Collapse,
  Flex,
  Grid,
  H3,
  H4,
  Modal,
  NavigationList,
  P,
  SecondaryButton,
  useMediaQuery,
} from '@guiker/components-library'
import { getLocalizedCity, getLocalizedCountry, getLocalizedNeighbourhood, useTranslation } from '@guiker/i18n'
import { BusIcon, ChevronDownIcon, ChevronUpIcon, DwelingIcon, FaceIdIcon, HouseSignalIcon } from '@guiker/icons'

import { getPriceStatistics } from './get-price-statistics'

const useStyle = makeStyles(
  {
    sections: {
      flex: '3 1 1020px',
    },
    statistics: {
      marginTop: theme.spacing(3),
      flex: '1 1 340px',
    },
    text: {
      whiteSpace: 'pre-line',
    },
    hover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  { name: 'ListingLivingSection' },
)

type Props = {
  tPrefix: string
  location: Location
  isNeighbourhood?: boolean
}

const getSectionIcon = (sectionKey: string) => {
  const icons = {
    section1: HouseSignalIcon,
    section2: BusIcon,
    section3: DwelingIcon,
    neighborhoodPersonality: HouseSignalIcon,
    transportationMethod: BusIcon,
    seeAndDo: FaceIdIcon,
    typesOfDwellings: DwelingIcon,
  }
  const Icon = icons[sectionKey]
  return <Icon color={'primary'} />
}

type RentalGuideProps = {
  tPrefix: string
  locationLabel: string
  isMobile: boolean
}

type BaseRentalGuideProps = RentalGuideProps & {
  tLivingPrefix: string
  sections: object
  toggleContent: (key: string) => void
}

const tLivingPrefix = 'main-rentalListing:living'

const RentalGuide: React.FC<BaseRentalGuideProps> = ({
  locationLabel,
  tPrefix,
  tLivingPrefix,
  isMobile,
  sections,
  toggleContent,
}) => {
  const classes = useStyle()
  const { t } = useTranslation(['common', 'main-rentalListing'])

  return (
    <Grid container spacing={2}>
      {Object.keys(sections).map((key) => (
        <Grid key={key} item sm={6} xs={12}>
          <Box mt={4} mr={4}>
            <Box mb={2}>{getSectionIcon(key)}</Box>
            <Box
              onClick={() => toggleContent(key)}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
              className={classes.hover}
            >
              <H4 color={isMobile ? 'primary' : undefined} mb={0}>
                {t(`${tLivingPrefix}.${key}`, { location: locationLabel })}
              </H4>
              {isMobile &&
                (sections[key] ? (
                  <Box minWidth='16'>
                    <ChevronUpIcon color='primary' />
                  </Box>
                ) : (
                  <Box minWidth='16'>
                    <ChevronDownIcon color='primary' />
                  </Box>
                ))}
            </Box>
            <Collapse in={!isMobile || sections[key]}>
              <P className={classes.text}>{t(`${tPrefix}.${key}`)}</P>
            </Collapse>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

const CityRentalGuide: React.FC<RentalGuideProps> = (props) => {
  const { isMobile } = props
  const [sections, setSections] = useState({
    section1: !isMobile,
    section2: !isMobile,
    section3: !isMobile,
  })

  const toggleContent = (sectionKey: string) => {
    isMobile && setSections({ ...sections, [sectionKey]: !sections[sectionKey] })
  }

  return (
    <RentalGuide
      tLivingPrefix={'main-rentalListing:cityScreen.livingGuide'}
      sections={sections}
      toggleContent={toggleContent}
      {...props}
    />
  )
}

const NeighbourhoodRentalGuide: React.FC<RentalGuideProps> = (props) => {
  const { isMobile } = props
  const [sections, setSections] = useState({
    neighborhoodPersonality: !isMobile,
    transportationMethod: !isMobile,
    seeAndDo: !isMobile,
    typesOfDwellings: !isMobile,
  })

  const toggleContent = (sectionKey: string) => {
    isMobile && setSections({ ...sections, [sectionKey]: !sections[sectionKey] })
  }

  return (
    <RentalGuide
      tLivingPrefix={'main-rentalListing:neighbourhoodScreen.livingGuide'}
      sections={sections}
      toggleContent={toggleContent}
      {...props}
    />
  )
}

const LivingSection: React.FC<Props> = ({ tPrefix, location: currentLocation, isNeighbourhood = false }) => {
  const { t } = useTranslation(['common', 'main-rentalListing'])
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openRentalGuide, setOpenRentalGuide] = useState(false)

  const { city, country, state, neighbourhood } = currentLocation
  const localizeBase = { countryCode: country.countryCode, stateCode: state.stateCode, citySlug: city.slug }

  const countryLabel = getLocalizedCountry(t, { countryCode: country.countryCode })
  const cityLabel = getLocalizedCity(t, localizeBase)
  const title = isNeighbourhood
    ? getLocalizedNeighbourhood(t, { ...localizeBase, neighbourhoodSlug: neighbourhood.slug })
    : cityLabel
  const rentalGuideLabel = [
    title.toUpperCase(),
    isNeighbourhood ? cityLabel.toUpperCase() : undefined,
    countryLabel.toUpperCase(),
  ]
    .filter((s) => !!s)
    .join(', ')

  const RentalGuideSection = isNeighbourhood ? NeighbourhoodRentalGuide : CityRentalGuide

  return (
    <div>
      <H3 textAlign={isMobile ? 'center' : 'left'}>{t('main-rentalListing:living.title', { title })}</H3>
      <Flex flexWrap='wrap' flexDirection={isMobile ? 'column' : 'row'}>
        {isMobile && t(`${tPrefix}.neighborhoodPersonality`) ? (
          <Box my={4} mx='auto'>
            <SecondaryButton onClick={() => setOpenRentalGuide(true)}>
              {t(`${tLivingPrefix}.seeRentalGuide`)}
            </SecondaryButton>
            <Modal
              open={openRentalGuide}
              fullScreen={true}
              title={t(`${tLivingPrefix}.rentalGuide`)}
              onClose={() => setOpenRentalGuide(false)}
            >
              <P color={30}>{rentalGuideLabel}</P>
              <RentalGuideSection locationLabel={title} tPrefix={tPrefix} isMobile={isMobile} />
            </Modal>
          </Box>
        ) : t(`${tPrefix}.neighborhoodPersonality`) ? (
          <Box className={!isMobile && classes.sections} mb={3}>
            <RentalGuideSection locationLabel={title} tPrefix={tPrefix} isMobile={isMobile} />
          </Box>
        ) : (
          <></>
        )}
        <Box className={classes.statistics}>
          <H4 textAlign={isMobile ? 'center' : 'left'}>{t(`main-rentalListing:living.averageRentByRoom`)}</H4>
          <NavigationList hasAvatar={false} items={getPriceStatistics(currentLocation, isNeighbourhood)} />
        </Box>
      </Flex>
    </div>
  )
}

export { LivingSection }
