import { optionalConcat } from '@guiker/lodash'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { Applicant } from '../applicant'
import { Application, ApplicationStatus, RoommateIntroStatus, RoommateIntroTenantDecision } from '../application'
import { ParticipantStepStatus } from '../participant'
import { ApplicationStatusGroup } from './booking-application-status'

export const getApplicantFirstName = (applicant: Applicant) => {
  const content = applicant?.application?.content as TenantApplication

  return content?.profile?.legalFirstName || applicant.firstName
}

export const getApplicantLastName = (applicant: Applicant) => {
  const content = applicant?.application?.content as TenantApplication

  return content?.profile?.legalLastName || applicant.lastName
}

export const getApplicantFullName = (applicant: Applicant): string => {
  if (!applicant) {
    return ''
  }

  return optionalConcat([getApplicantFirstName(applicant), getApplicantLastName(applicant)], ' ')
}

export const inferApplicantApplicationStepStatus = (status?: ApplicationStatus) => {
  switch (status) {
    case ApplicationStatus.INITIAL:
    case ApplicationStatus.REJECTED:
      return ParticipantStepStatus.NOT_STARTED
    case ApplicationStatus.STARTED:
    case ApplicationStatus.CHANGE_REQUESTED:
      return ParticipantStepStatus.STARTED // copy on green outline button with check mark off `resume application`
    case ApplicationStatus.COMPLETED:
    case ApplicationStatus.SUBMITTED:
      return ParticipantStepStatus.COMPLETED // copy on green outline button with check mark on `application in review`
    case ApplicationStatus.ACCEPTED:
      return ParticipantStepStatus.FINALIZED // copy on black outline button `application`
  }
}

export const inferRoommateProfileStepStatus = (status?: RoommateIntroStatus, applicantStatus?: ApplicationStatus) => {
  if (!!applicantStatus && ApplicationStatusGroup.PRE_REVIEW.includes(applicantStatus)) {
    return ParticipantStepStatus.NOT_READY
  }
  switch (status) {
    case RoommateIntroStatus.INITIAL:
      return ParticipantStepStatus.NOT_STARTED
    case RoommateIntroStatus.PROFILED:
      return ParticipantStepStatus.STARTED
    case RoommateIntroStatus.INTRO_STARTED:
      return ParticipantStepStatus.COMPLETED
    case RoommateIntroStatus.ACCEPTED:
      return ParticipantStepStatus.COMPLETED
    default:
      return ParticipantStepStatus.NOT_STARTED
  }
}

export const inferUnitManagerStepStatus = (applications: Application[]): ParticipantStepStatus => {
  if (applications.some((a) => a.status === ApplicationStatus.CHANGE_REQUESTED)) {
    return ParticipantStepStatus.STARTED
  }
  if (applications.some((a) => a.status === ApplicationStatus.ACCEPTED)) {
    return ParticipantStepStatus.COMPLETED
  }
  if (applications.some((a) => a.status === ApplicationStatus.SUBMITTED)) {
    return ParticipantStepStatus.STARTED // for UM side, started / completed has same copy on the button `View applications`
  }
  return ParticipantStepStatus.NOT_READY
}

export const inferUnitManagerRoommateProfileStepStatus = (applications: Application[]): ParticipantStepStatus => {
  if (applications.every((a) => a.roommateIntro?.status === RoommateIntroStatus.ACCEPTED)) {
    return ParticipantStepStatus.COMPLETED
  }
  if (
    applications.some((a) => a.roommateIntro?.tenants?.some((t) => t.decision === RoommateIntroTenantDecision.REJECTED))
  ) {
    return ParticipantStepStatus.FAILED
  }
  if (applications.some((a) => a.roommateIntro?.status === RoommateIntroStatus.INTRO_STARTED)) {
    return ParticipantStepStatus.STARTED
  }
  return ParticipantStepStatus.NOT_READY
}
