import React from 'react'

import { computeInvoiceTotalAmount, Invoice } from '@guiker/payment-shared'
import { ColumnConfig, Flex, Link, P, PSmall, RouterLink, TextButton, useDateFormatter } from '@guiker/react-framework'
import { currency, money } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { InvoiceReason } from '../InvoiceReason'
import { InvoiceDetailStatusChip } from '../ViewInvoiceDetail'

export const getInvoiceColumns = (): ColumnConfig<Invoice>[] => {
  const { tBase, tOffice } = useT({ screenName: 'listInvoices' })
  const { formatDate, formatTime } = useDateFormatter()

  return [
    {
      label: tOffice('customer'),
      name: 'customer',
      size: 1.5,
      verticalAlign: 'top',
      renderValue: ({ customer }) => (
        <Link to={`/user/${customer.id}`} prefixWithLocale={false}>
          <Flex flexDirection='column'>
            <P>{[customer.firstName, customer.lastName].join(' ')}</P>
            <PSmall color={60}>{customer.emailAddress}</PSmall>
          </Flex>
        </Link>
      ),
    },

    {
      label: tOffice('reason'),
      size: 1.5,
      name: 'reason',
      verticalAlign: 'top',
      renderValue: (invoice) => <InvoiceReason invoice={invoice} />,
    },
    {
      label: tOffice('status'),
      name: 'status',
      size: 1,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return <InvoiceDetailStatusChip invoice={invoice} direction='column' size='smaller' />
      },
    },
    {
      label: tOffice('dueBy'),
      name: 'dateCharged',
      size: 0.6,
      verticalAlign: 'top',
      renderValue: ({ dueBy, createdAt }) => formatDate(dueBy || createdAt),
    },
    {
      label: tOffice('lastUpdate'),
      name: 'updatedAt',
      size: 0.6,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        const lastUpdatedAt = invoice.transactions[invoice.transactions.length - 1]?.createdAt
        return (
          lastUpdatedAt && (
            <Flex flexDirection='column'>
              <P>{formatDate(lastUpdatedAt)}</P>
              <PSmall color={60}>{formatTime(lastUpdatedAt)}</PSmall>
            </Flex>
          )
        )
      },
    },
    {
      label: tOffice('amount'),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'amount',
      size: 0.9,
      verticalAlign: 'top',
      renderValue: (invoice) => (
        <Flex flexDirection='column' alignItems='flex-end'>
          {money.fromAmount(computeInvoiceTotalAmount(invoice), currency[invoice.currency]).toString(true)}
        </Flex>
      ),
    },
    {
      name: 'actions',
      headerOptions: {
        textAlign: 'center',
      },
      size: 0.5,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return (
          <Flex flexDirection='column' alignItems='center'>
            <RouterLink to={`/payment/invoices/${invoice.id}`} prefixWithLocale={false}>
              <TextButton>{tBase('actions.view')}</TextButton>
            </RouterLink>
          </Flex>
        )
      },
    },
  ]
}
