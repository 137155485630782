import * as yup from 'yup'

export const address = yup.object({
  apartmentNumber: yup.string().nullable(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  street: yup.string().required(),
  streetNumber: yup.string().required(),
})

export type Address = yup.InferType<typeof address>
