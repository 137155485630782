import React, { useEffect } from 'react'

import { AuthType, useAuthenticationContext } from '@guiker/authentication-context'
import { User } from '@guiker/authentication-shared'
import { Modal } from '@guiker/components-library'
import { selectFromMap } from '@guiker/lodash'

import { useAuthenticationModalContext } from '../../contexts'
import { useTranslation } from '../../i18n'
import { ActivateAccountForm, LogInForm, SignUpForm } from '../Forms'

const MAX_WIDTH = 780

const AuthFormMap = {
  [AuthType.LOGIN]: LogInForm,
  [AuthType.SIGN_UP]: SignUpForm,
  [AuthType.ACTIVATE]: ActivateAccountForm,
  default: SignUpForm,
}

const selectComponent = selectFromMap(AuthFormMap)

export type AuthenticationModalProps = {
  modalAuthType: AuthType
  open: boolean
  onClose: () => void
  onSuccess: (user: User) => void
  forgetPasswordUrl: string
  privacyPolicyUrl: string
  termsAndConditionUrl: string
}

const AuthenticationModal: React.FC<AuthenticationModalProps> = (props) => {
  const { modalAuthType, open, onSuccess, onClose, ...otherProps } = props
  const { t } = useTranslation('authentication')
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const { user } = useAuthenticationContext()

  useEffect(() => {
    if (onSuccess && user?.status === 'ACTIVE') {
      onSuccess(user)
    }
  }, [user, onSuccess])

  const Component = selectComponent(modalAuthType)
  const onAuthTypeSwitchClick = () =>
    modalAuthType === AuthType.LOGIN
      ? openAuthenticationModal(AuthType.SIGN_UP, { onSuccess })
      : openAuthenticationModal(AuthType.LOGIN, { onSuccess })

  return (
    <Modal open={open} maxWidth={MAX_WIDTH} onClose={onClose} title={t(`authentication:${modalAuthType}.modalTitle`)}>
      <Component {...otherProps} onAuthTypeSwitchClick={onAuthTypeSwitchClick} />
    </Modal>
  )
}

export { AuthenticationModal }
