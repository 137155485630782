import { TypeOf, yup } from '@guiker/yup-util'

import { assetsSchema } from './shared'

export const upsertProjectSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string(),
    url: yup.string(),
    documents: assetsSchema,
  })
  .required()

export type UpsertProjectSchema = TypeOf<typeof upsertProjectSchema>
