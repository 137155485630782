import { Payment } from '@guiker/base-entity'
import { yup } from '@guiker/yup-util'

export const payOneInvoicePayloadSchema = yup.object<{
  payInMethod: {
    id: string
    type: Payment.PayInMethodType
  }
}>({
  payInMethod: yup.object({
    id: yup.string().required(),
    type: yup.mixed<Payment.PayInMethodType>().oneOf(Object.values(Payment.PayInMethodType)).required(),
  }),
})

export type PayOneInvoicePayloadSchema = yup.InferType<typeof payOneInvoicePayloadSchema>
