import { routesBuilder } from '@guiker/shared-framework'

import { RentPaymentsPlanWithTenantInstalments } from '../../../../entity'
import { paths } from '../../paths'
import { updateRentPaymentsPlanSchema } from './schemas'

const { buildGet, buildPut } = routesBuilder<RentPaymentsPlanWithTenantInstalments>()({
  basePath: paths.rentPaymentsPlans.withId.path,
})

export const routes = {
  updateRentPaymentsPlan: buildPut({
    payloadSchema: updateRentPaymentsPlanSchema,
  }),
  readOneRentPaymentsPlanById: buildGet({}),
}
