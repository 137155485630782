export enum ParkingTypes {
  COVERED = 'covered',
  GARAGE = 'garage',
  INDOOR = 'indoor',
  OUTDOOR = 'outdoor',
}

export type Parking = {
  type: ParkingTypes
  chargingStation: boolean
}
