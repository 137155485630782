import { scopeSchema } from '@guiker/access-control-shared'
import { yup } from '@guiker/yup-util'

import { LeaseRoleNames } from '../../../entity'

export const leaseAccessControlClaimsSchema = yup.object({
  scope: scopeSchema,
  userId: yup.string(),
  emailAddress: yup.string().required(),
  acl: yup
    .object({
      lease: yup
        .object({
          roles: yup
            .array()
            .of(yup.string().oneOf(Object.values(LeaseRoleNames)))
            .required(),
          references: yup.array().of(yup.string()).optional(),
        })
        .required(),
    })
    .required(),
})

export type LeaseAccessControlClaimsSchema = yup.InferType<typeof leaseAccessControlClaimsSchema>
