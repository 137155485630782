import React from 'react'

import { findLatestSeries, PropSharingListing } from '@guiker/propsharing-shared'
import { H4, P, PSmall } from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type PricePerUnitProps = {
  size?: 'large' | 'medium'
  listing: PropSharingListing
}

export const PricePerUnit: React.FC<PricePerUnitProps> = ({ size = 'medium', listing }) => {
  const { SharedTrans } = useT({ entity: 'listing' })
  const series = findLatestSeries(listing.stock)

  const pricePerUnitStr = money.fromAmount(series.pricePerUnit, listing.stock.currency).toString('onlySymbol')

  const MainTypo = PSmall
  const ValueTypo = size === 'large' ? H4 : P

  return (
    <MainTypo mb={0} whiteSpace='nowrap'>
      <SharedTrans i18nKey={'stock.perUnit'} values={{ perUnit: pricePerUnitStr }}>
        <ValueTypo mb={0} component='span' fontWeight={500} whiteSpace='nowrap' />
      </SharedTrans>
    </MainTypo>
  )
}
