import React from 'react'

import { FundingAccountDepositProjection } from '@guiker/payment-shared'
import { Table, TableProps } from '@guiker/react-framework'

import { useGetColumns } from './get-columns'

type UserFundingAccountDepositsTableProps = {
  deposits: FundingAccountDepositProjection[]
  emptyState?: TableProps<unknown>['emptyState']
}

export const UserFundingAccountDepositsTable: React.FC<UserFundingAccountDepositsTableProps> = ({
  deposits,
  emptyState,
}) => {
  const columns = useGetColumns({
    columns: ['payInMethod.id', 'amount', 'status', 'createdAt'],
    callbackArgs: { showNote: false },
  })

  return <Table data={deposits} columns={columns} emptyState={emptyState} />
}
