import { validatorBuilder } from '@guiker/api-definition'
import { Event } from '@guiker/event'
import { HttpMethod } from '@guiker/http'

import { BackgroundCheck } from '../../entity'
import { paths } from './paths'
import {
  backgroundCheckAccessControlClaimsSchema,
  backgroundCheckIdPathValidator,
  consentBackgroundCheckPayloadValidator,
  sinBackgroundCheckPayloadValidator,
  ssnAndConsentBackgroundCheckPayloadValidator,
} from './schemas'

const BACKGROUND_CHECK_WITH_ID_PATH = paths.backgroundCheck.one.withId(':backgroundCheckId')

const backgroundCheckResponseValidator = (payload: unknown) => Promise.resolve(payload as BackgroundCheck)

export const routes = {
  consentBackgroundCheck: {
    path: `${BACKGROUND_CHECK_WITH_ID_PATH}/consent`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (params: unknown) => backgroundCheckIdPathValidator.validate(params),
    payloadValidator: (payload: unknown) => consentBackgroundCheckPayloadValidator.validate(payload),
    responseValidator: backgroundCheckResponseValidator,
  },
  updateSsnAndConsentBackgroundCheck: {
    path: `${BACKGROUND_CHECK_WITH_ID_PATH}/update-ssn-and-consent`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (params: unknown) => backgroundCheckIdPathValidator.validate(params),
    payloadValidator: (payload: unknown) => ssnAndConsentBackgroundCheckPayloadValidator.validate(payload),
    responseValidator: backgroundCheckResponseValidator,
  },
  updateSin: {
    path: `${BACKGROUND_CHECK_WITH_ID_PATH}/update-sin`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (params: unknown) => backgroundCheckIdPathValidator.validate(params),
    payloadValidator: (payload: unknown) => sinBackgroundCheckPayloadValidator.validate(payload),
    reponseValidator: backgroundCheckResponseValidator,
  },
  readAllBackgroundCheckEvents: {
    path: `${BACKGROUND_CHECK_WITH_ID_PATH}/events`,
    method: HttpMethod.GET,
    authenticated: true,
    accessControlValidator: validatorBuilder.buildAccessControlValidator(backgroundCheckAccessControlClaimsSchema),
    pathParamsValidator: (payload: unknown) => backgroundCheckIdPathValidator.validate(payload),
    responseValidator: (payload: unknown) => Promise.resolve(payload as Event<BackgroundCheck>[]),
  },
  generatePDFLink: {
    path: `${BACKGROUND_CHECK_WITH_ID_PATH}/generate-pdf-link`,
    method: HttpMethod.GET,
    authenticate: true,
    accessControlValidator: validatorBuilder.buildAccessControlValidator(backgroundCheckAccessControlClaimsSchema),
    pathParamsValidator: (params: unknown) => backgroundCheckIdPathValidator.validate(params),
    reponseValidator: (payload: unknown) => Promise.resolve(payload as string),
  },
}
