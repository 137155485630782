import React, { useEffect, useMemo } from 'react'

import { usePayoutContext } from '@guiker/payout-context'
import {
  Capabilities,
  checkCapabilities,
  mainPathBuilder as payoutRoutes,
  payoutMethodInfoBuilder,
} from '@guiker/payout-shared'
import { ColumnGridItem, Flex, Form, Link, P, PBold, PSmall, RadioGroup, useFormContext } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { getAllowedPayoutMethods } from '../../utils'
import { PayoutMethodIcon } from '../PayoutMethodIcon'

type Props = {
  capabilities?: Capabilities[]
}

export const FormContent: React.FC<Props> = () => {
  const name = 'payoutMethod'
  const { tMain } = useT({})
  const { payoutMethods, regions, setSelected, capabilities, selected: selectedPayoutMethod } = usePayoutContext()
  const { setValue } = useFormContext()

  const allowedPayoutMethods = useMemo(
    () => getAllowedPayoutMethods({ payoutMethods, regions, capabilities }),
    [payoutMethods, regions, capabilities],
  )

  useEffect(() => {
    if (selectedPayoutMethod) {
      setValue(name, selectedPayoutMethod.id)
    }
  }, [selectedPayoutMethod])

  if (!allowedPayoutMethods?.length) return <P mb={0}>{tMain('noPayoutMethod')}</P>

  return (
    <ColumnGridItem md={2}>
      <RadioGroup
        name={name}
        maxWidth='100%'
        defaultValue={selectedPayoutMethod?.id}
        adornmentPosition='start'
        onChange={(e) => {
          const selected = allowedPayoutMethods.find(({ id }) => id.toString() === e.target.value)
          setSelected(selected)
        }}
        options={allowedPayoutMethods.map((payoutMethod) => {
          const label = payoutMethodInfoBuilder(payoutMethod).getDisplay()

          return {
            value: payoutMethod.id,
            label: <PBold mb={0}>{label}</PBold>,
            adornment: <PayoutMethodIcon payoutMethod={payoutMethod} />,
          }
        })}
      />
    </ColumnGridItem>
  )
}

export const PayoutMethodSelector: React.FC<Props> = ({ capabilities = [] }) => {
  const { tMain } = useT({})
  const path = checkCapabilities.hasOnly.investment(capabilities)
    ? payoutRoutes.root.createInvestment.path
    : payoutRoutes.root.create.path
  return (
    <Flex flexDirection='column' gap={2}>
      <Form formName='PayoutMethodSelector'>
        <FormContent />
      </Form>
      <Link color={50} underline to={path}>
        <PSmall mb={0}>{tMain('addPayoutMethod')}</PSmall>
      </Link>
    </Flex>
  )
}
