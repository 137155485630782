import { PayoutProviderErrorCode, PayoutProviderName } from '../entity'

const mapStripeErrorCodesToPayoutProviderError = (errorCode: string): PayoutProviderErrorCode => {
  if (
    [
      'invalid_address_city_state_postal_code',
      'invalid_dob_age_under_18',
      'invalid_representative_country',
      'invalid_street_address',
      'invalid_tos_acceptance',
    ].includes(errorCode)
  )
    return PayoutProviderErrorCode.INVALID_PROFILE
  if (
    [
      'verification_document_address_mismatch',
      'verification_document_address_missing',
      'verification_document_corrupt',
      'verification_document_country_not_supported',
      'verification_document_dob_mismatch',
      'verification_document_duplicate_type',
      'verification_document_expired',
      'verification_document_failed_copy',
      'verification_document_failed_greyscale',
      'verification_document_failed_other',
      'verification_document_failed_test_mode',
      'verification_document_fraudulent',
      'verification_document_id_number_mismatch',
      'verification_document_id_number_missing',
      'verification_document_incomplete',
      'verification_document_invalid',
      'verification_document_issue_or_expiry_date_missing',
      'verification_document_manipulated',
      'verification_document_missing_back',
      'verification_document_missing_front',
      'verification_document_name_mismatch',
      'verification_document_name_missing',
      'verification_document_nationality_mismatch',
      'verification_document_not_readable',
      'verification_document_not_signed',
      'verification_document_not_uploaded',
      'verification_document_photo_mismatch',
      'verification_document_too_large',
      'verification_document_type_not_supported',
      'verification_failed_address_match',
      'verification_failed_business_iec_number',
      'verification_failed_document_match',
      'verification_failed_id_number_match',
      'verification_failed_keyed_identity',
      'verification_failed_keyed_match',
      'verification_failed_name_match',
      'verification_failed_other',
      'verification_failed_residential_address',
      'verification_failed_tax_id_match',
      'verification_failed_tax_id_not_issued',
      'verification_missing_executives',
      'verification_missing_owners',
      'verification_requires_additional_memorandum_of_associations',
    ].includes(errorCode)
  )
    return PayoutProviderErrorCode.VERIFICATION_FAILED
  return PayoutProviderErrorCode.OTHER
}

export const mapProviderErrorsToPayoutProviderError = (
  providerName: PayoutProviderName,
  errorCode: string,
): PayoutProviderErrorCode => {
  switch (providerName) {
    case PayoutProviderName.STRIPE:
      return mapStripeErrorCodesToPayoutProviderError(errorCode)
    default:
      return PayoutProviderErrorCode.OTHER
  }
}
