import React, { useState } from 'react'

import { BaseListing } from '@guiker/base-listing-shared'
import {
  Button,
  clsx,
  Flex,
  InfiniteList,
  InfiniteListProps,
  makeStyles,
  Modal,
  toPx,
  useLayoutContext,
} from '@guiker/react-framework'

import { SearchScreenMap } from '../SearchScreenMap'
import { useMapLayoutContext } from './MapLayoutContext'

const useStyles = makeStyles(
  (theme) => ({
    mobileCell: {
      minWidth: '100%',
      flex: `1 1 calc(50% - ${toPx(theme.spacing(2))})`,
    },
  }),
  { name: 'MapLayout' },
)

type MapLayoutProps<T extends BaseListing> = {
  CellComponent: React.FC<{ data: T; className?: string }>
  SearchBar: React.ReactNode
  queryKey: InfiniteListProps<T>['queryKey']
  fetcher: InfiniteListProps<T>['fetcher']
  toggleMapLabel: string
}

type DesktopMapLayoutProps<T extends BaseListing> = MapLayoutProps<T>

const DesktopMapLayout = <T extends BaseListing>({
  CellComponent,
  SearchBar,
  queryKey,
  fetcher,
}: DesktopMapLayoutProps<T>) => {
  const [data, setData] = useState<T[]>([])
  const { mapBounds } = useMapLayoutContext()

  return (
    <Flex flexDirection='column' gap={6} width='100%'>
      {SearchBar}
      <Flex height={850} gap={2} maxHeight='calc(100vh - 296px)' fullWidth>
        <InfiniteList
          onFetch={setData}
          queryKey={queryKey}
          fetcher={fetcher}
          enabled={!!mapBounds}
          columnCount={2}
          gap={2}
          maxWidth={612}
          CellComponent={({ data }) => <CellComponent data={data} />}
        />
        <SearchScreenMap
          listings={data}
          Component={({ listing, ...props }) => <CellComponent data={listing} {...props} />}
        />
      </Flex>
    </Flex>
  )
}

type MobileViewProps<T extends BaseListing> = MapLayoutProps<T>

const MobileMapLayout = <T extends BaseListing>({
  CellComponent,
  queryKey,
  fetcher,
  toggleMapLabel,
}: MobileViewProps<T>) => {
  const classes = useStyles()
  const [data, setData] = useState<T[]>([])
  const [showInfiniteList, setShowInfiniteList] = useState(false)
  const { mapBounds } = useMapLayoutContext()

  const toggleInfiniteList = () => setShowInfiniteList(!showInfiniteList)

  return (
    <Flex flexDirection='column' gap={2} width='100%'>
      <SearchScreenMap
        listings={data}
        cardStyle='bottom'
        height='calc(100vh - 236px)'
        Component={({ listing, className, ...props }) => (
          <CellComponent data={listing} className={clsx(classes.mobileCell, className)} {...props} />
        )}
      />
      <Button size='medium' onClick={() => toggleInfiniteList()}>
        {toggleMapLabel}
      </Button>
      <Modal
        keepMounted={!!mapBounds}
        open={showInfiniteList}
        onClose={() => setShowInfiniteList(false)}
        showCompact={true}
      >
        <InfiniteList
          onFetch={setData}
          queryKey={queryKey}
          fetcher={fetcher}
          enabled={!!mapBounds}
          CellComponent={({ data }) => <CellComponent data={data} className={classes.mobileCell} />}
        />
      </Modal>
    </Flex>
  )
}

export const MapLayout = <T extends BaseListing>(props: MapLayoutProps<T>) => {
  const { isAtLeastTablette: isDesktop } = useLayoutContext()
  return isDesktop ? <DesktopMapLayout {...props} /> : <MobileMapLayout {...props} />
}
