export enum LessorLesseeTypes {
  lessor = 'lessor',
  lessee = 'lessee',
}

export enum HeatingTypes {
  electricity = 'electricity',
  gas = 'gas',
  fuelOil = 'fuelOil',
}

export enum ServiceTypes {
  heating = 'heating',
  gas = 'gas',
  electricity = 'electricity',
  waterHeater = 'waterHeater',
  hotWater = 'hotWater',
  waterConsumption = 'waterConsumption',
}

export enum SnowRemovalTypes {
  parkingArea = 'parkingArea',
  balcony = 'balcony',
  entrance = 'entrance',
  stairs = 'stairs',
}

export type Janitor = {
  name: string
  phoneNumber: string
  emailAddress: string
  otherPhoneNumber: string
}

export type ServicesTaxesAndConsumptionCosts = {
  [key in ServiceTypes]: LessorLesseeTypes
} & {
  heatingType: HeatingTypes
  snowRemoval: {
    [key in SnowRemovalTypes]: LessorLesseeTypes
  }
}

export interface Conditions {
  accessToLand: {
    hasAccess: boolean
    description: string
  }
  rightToKeepAnimal: {
    hasRight: boolean
    description: string
  }
}

export interface WorkAndRepairs {
  repairBeforeDwellingDelivered: string
  repairDuringLease: string
}

export interface JanitorialServices {
  specification: string
  janitor: Janitor
}

export interface ServicesAndConditions {
  byLawsImmovableDate: string
  completed: boolean
  conditions: Conditions
  coOwnerShipImmovableDate: string
  janitorialServices: JanitorialServices
  otherServicesRestrictionCondition: string
  servicesTaxesAndConsumptionCosts: ServicesTaxesAndConsumptionCosts
  workAndRepairs: WorkAndRepairs
}
