import React from 'react'

import { Flex, makeStyles, theme } from '@guiker/react-framework'

export const useStyles = makeStyles({
  container: {
    padding: theme.spacing(1.5),
    gap: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[30]}`,
    maxWidth: 98,
    minWidth: 98,
    height: 120,
    '&:hover': {
      cursor: ({ hasModal }: { hasModal?: boolean }) => (hasModal ? 'pointer' : 'auto'),
    },
  },
})

type BaseBoxProps = React.PropsWithChildren & {
  onClick: () => unknown
}

const BaseBox: React.FC<BaseBoxProps> = ({ children, onClick }) => {
  const classes = useStyles({ hasModal: !!onClick })

  return (
    <Flex
      className={classes.container}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      onClick={onClick}
    >
      {children}
    </Flex>
  )
}

export { BaseBox }
