import React from 'react'

import { Location } from '@guiker/base-entity'
import { PBold } from '@guiker/components-library'
import { getLocalizedCity, getLocalizedNeighbourhood, useTranslation } from '@guiker/i18n'

import { buildSearchListingsUrl } from '../../utils'

type LocationStatistics = {
  [country in string]: {
    [city in string]: {
      [neighbourhood in string]: {
        [dwelingType in string]: {
          bedroomCount: number | string
          priceRange: string
        }
      }
    }
  }
}

const getPriceStatistics = (location: Location, isNeighbourhood: boolean) => {
  const { t } = useTranslation(['common', 'main-rentalListing'])
  const prefix = 'main-rentalListing:living.list'

  const searchUrl = buildSearchListingsUrl(location)
  const { country, city, neighbourhood } = location
  const localizeBase = { countryCode: country.countryCode, citySlug: city.slug }
  const locationName = isNeighbourhood
    ? getLocalizedNeighbourhood(t, { ...localizeBase, neighbourhoodSlug: neighbourhood.slug })
    : getLocalizedCity(t, localizeBase)

  const locationStatistics: LocationStatistics = {
    CA: {
      ottawa: {
        default: {
          studio: {
            bedroomCount: 0,
            priceRange: '1,299',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,580',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1,875',
          },
        },
        'lower-town-byward-market-sandy-hill': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,229',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,550',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1,950',
          },
        },
        'centretown-west': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,249',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,699',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2,138',
          },
        },
        centretown: {
          studio: {
            bedroomCount: 0,
            priceRange: '1,269',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,600',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1,975',
          },
        },
        'the-glebe': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,200',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,770',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2,480',
          },
        },
        'westboro-village': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,475',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,800',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2,275',
          },
        },
        carlington: {
          studio: {
            bedroomCount: 0,
            priceRange: '1,199',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,350',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1,623',
          },
        },
        'wellington-west': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,300',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,700',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2,100',
          },
        },
        'old-ottawa-south': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,050',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,350',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2,200',
          },
        },
        'downtown-parliament-hill': {
          studio: {
            bedroomCount: 0,
            priceRange: '1,625',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1,950',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2,400',
          },
        },
      },
      montreal: {
        default: {
          studio: {
            bedroomCount: 0,
            priceRange: '685-1,700',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '875-3,300',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '850-4,000',
          },
          inCity: {
            bedroomCount: 'any',
            priceRange: '685-4,000',
          },
        },
        downtown: {
          studio: {
            bedroomCount: 0,
            priceRange: '1350',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1650',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2575',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2995',
          },
        },
        lasalle: {
          studio: {
            bedroomCount: 0,
            priceRange: '780',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1195',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1600',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1900',
          },
        },
        mcgill: {
          studio: {
            bedroomCount: 0,
            priceRange: '1,045',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1323',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1900',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1900',
          },
        },
        'mile-end': {
          studio: {
            bedroomCount: 0,
            priceRange: '1150',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1400',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1695',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2300',
          },
        },
        'old-port': {
          studio: {
            bedroomCount: 0,
            priceRange: '1350',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1650',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2575',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2995',
          },
        },
        outremont: {
          studio: {
            bedroomCount: 0,
            priceRange: '1005',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1395',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1885',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1998',
          },
        },
        plateau: {
          studio: {
            bedroomCount: 0,
            priceRange: '995',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1295',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1625',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2250',
          },
        },
        rosemont: {
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1200',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1250',
          },
        },
        verdun: {
          studio: {
            bedroomCount: 0,
            priceRange: '935',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1250',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1425',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1898',
          },
        },
        westmount: {
          studio: {
            bedroomCount: 0,
            priceRange: '795',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1495',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1850',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2695',
          },
        },
        cdn: {
          studio: {
            bedroomCount: 0,
            priceRange: '1047',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1350',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1728',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2095',
          },
        },
      },
      toronto: {
        default: {
          studio: {
            bedroomCount: 0,
            priceRange: '1,150-2,350',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '750-7,150',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1,480-16,500',
          },
          inCity: {
            bedroomCount: 'any',
            priceRange: '1,150-16,500',
          },
        },
        'church-and-wellesley': {
          studio: {
            bedroomCount: 0,
            priceRange: '1550',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1873',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2775',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '3150',
          },
        },
        'downtown-south-harbourfront': {
          studio: {
            bedroomCount: 0,
            priceRange: '1650',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2100',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3200',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '3899',
          },
        },
        'financial-district': {
          studio: {
            bedroomCount: 0,
            priceRange: '1750',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2050',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2800',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '3950',
          },
        },
        'the-annex-yorkville': {
          studio: {
            bedroomCount: 0,
            priceRange: '1650',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2495',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '4123',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '5200',
          },
        },
        'bay-street-corridor': {
          studio: {
            bedroomCount: 0,
            priceRange: '1900',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2203',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3200',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '4725',
          },
        },
        'kensington-market-chinatown': {
          studio: {
            bedroomCount: 0,
            priceRange: '1500',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1850',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2250',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2098',
          },
        },
        'liberty-village-king-west': {
          studio: {
            bedroomCount: 0,
            priceRange: '1600',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1950',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2818',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '4700',
          },
        },
        mimico: {
          studio: {
            bedroomCount: 0,
            priceRange: '1345',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1625',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1975',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '3600',
          },
        },
      },
    },
    US: {
      chicago: {
        default: {
          studio: {
            bedroomCount: 0,
            priceRange: '800-1,275',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '895-2,000',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1,250-3,595',
          },
          inCity: {
            bedroomCount: 'any',
            priceRange: '800-3,595',
          },
        },
        'albany-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '925',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1153',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1385',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1600',
          },
        },
        andersonville: {
          studio: {
            bedroomCount: 0,
            priceRange: '947',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1250',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2325',
          },
        },
        'arcadia-terrace': {
          studio: {
            bedroomCount: 0,
            priceRange: '950',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1250',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1588',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2158',
          },
        },
        avondale: {
          studio: {
            bedroomCount: 0,
            priceRange: '850',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1250',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1580',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1995',
          },
        },
        bridgeport: {
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1285',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1250',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1575',
          },
        },
        bucktown: {
          studio: {
            bedroomCount: 0,
            priceRange: '1095',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1608',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2100',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2895',
          },
        },
        'chinatown-douglas': {
          studio: {
            bedroomCount: 0,
            priceRange: '845',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1239',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1935',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2493',
          },
        },
        edgewater: {
          studio: {
            bedroomCount: 0,
            priceRange: '947',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1250',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2325',
          },
        },
        'gold-coast': {
          studio: {
            bedroomCount: 0,
            priceRange: '1396',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2100',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3000',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '6000',
          },
        },
        'goose-island': {
          studio: {
            bedroomCount: 0,
            priceRange: '1450',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2000',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3000',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '5790',
          },
        },
        'humbodlt-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '895',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1195',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1355',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1575',
          },
        },
        'hyde-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '950',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1395',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1738',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2250',
          },
        },
        'irving-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '890',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1135',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1500',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1800',
          },
        },
        lakeview: {
          studio: {
            bedroomCount: 0,
            priceRange: '1030',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1450',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1895',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2550',
          },
        },
        'lincoln-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '1050',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1595',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2217',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2975',
          },
        },
        'lincoln-square': {
          studio: {
            bedroomCount: 0,
            priceRange: '950',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1250',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1588',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2158',
          },
        },
        'logan-square': {
          studio: {
            bedroomCount: 0,
            priceRange: '995',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1450',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1750',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2195',
          },
        },
        'noble-square': {
          studio: {
            bedroomCount: 0,
            priceRange: '1395',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1650',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1800',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2395',
          },
        },
        'north-center': {
          studio: {
            bedroomCount: 0,
            priceRange: '895',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1313',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2400',
          },
        },
        'north-park': {
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1150',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1350',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2048',
          },
        },
        'old-town': {
          studio: {
            bedroomCount: 0,
            priceRange: '895',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1313',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2400',
          },
        },
        pilsen: {
          studio: {
            bedroomCount: 0,
            priceRange: '1065',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1300',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1500',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2000',
          },
        },
        ravenswood: {
          studio: {
            bedroomCount: 0,
            priceRange: '995',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1299',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2200',
          },
        },
        'river-north': {
          studio: {
            bedroomCount: 0,
            priceRange: '1697',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2440',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3079',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '5500',
          },
        },
        'river-west': {
          studio: {
            bedroomCount: 0,
            priceRange: '1700',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2200',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3448',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '6354',
          },
        },
        'roscoe-village': {
          studio: {
            bedroomCount: 0,
            priceRange: '895',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1313',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2400',
          },
        },
        'rogers-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '875',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1150',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1495',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '1985',
          },
        },
        'south-loop': {
          studio: {
            bedroomCount: 0,
            priceRange: '1762',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1975',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2695',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '3550',
          },
        },
        streeterville: {
          studio: {
            bedroomCount: 0,
            priceRange: '1596',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2160',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3377',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '5570',
          },
        },
        'the-loop': {
          studio: {
            bedroomCount: 0,
            priceRange: '1915',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2322',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '3230',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '5108',
          },
        },
        'ukrainian-village': {
          studio: {
            bedroomCount: 0,
            priceRange: '1614',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1676',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1845',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2400',
          },
        },
        'university-village': {
          studio: {
            bedroomCount: 0,
            priceRange: '1886',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2215',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '3640',
          },
        },
        uptown: {
          studio: {
            bedroomCount: 0,
            priceRange: '986',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1300',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1700',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2100',
          },
        },
        'west-loop': {
          studio: {
            bedroomCount: 0,
            priceRange: '1814',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '2218',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '2400',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2850',
          },
        },
        'wicker-park': {
          studio: {
            bedroomCount: 0,
            priceRange: '1776',
          },
          oneBedOneBath: {
            bedroomCount: 1,
            priceRange: '1695',
          },
          twoBedOneBath: {
            bedroomCount: 2,
            priceRange: '1800',
          },
          threeBedroom: {
            bedroomCount: 3,
            priceRange: '2498',
          },
        },
      },
    },
  }

  const statisticsByCountry = locationStatistics[country.countryCode]
  const cityStatistics = statisticsByCountry[city.slug]

  if (!cityStatistics) {
    return []
  }

  const localProperties =
    (isNeighbourhood ? cityStatistics[neighbourhood.slug] : cityStatistics.default) || cityStatistics.default

  return Object.keys(localProperties).map((dwellingType) => {
    const price = localProperties[dwellingType]['priceRange'] as string

    return {
      primaryText: t(`${prefix}.${dwellingType}`, { locationName }),
      secondaryText: (
        <PBold mb={0} color={'primary'}>
          {t(`${prefix}.price`, { price })}
        </PBold>
      ),
      url: `/${searchUrl}&bcount=${localProperties[dwellingType]['bedroomCount']}`,
    }
  })
}

export { getPriceStatistics }
