import { Address } from '@guiker/common'
import { yup } from '@guiker/yup-util'

const baseAddressSchema = yup.object<Address>({
  suite: yup.string().nullable(),
  streetNumber: yup.string().required(),
  street: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.string().required(),
  postalCode: yup.string().required(),
})

export const addressSchema = baseAddressSchema.concat(
  yup.object({
    coordinates: yup
      .object({
        lat: yup.number().nullable(),
        lng: yup.number().nullable(),
      })
      .nullable(),
  }),
)

export const addressSchemaWithNeighborhood = addressSchema.concat(
  yup.object({
    neighbourhood: yup.string().optional(),
  }),
)
