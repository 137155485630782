import { idNestedParamsValidator } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { ProjectWithDeveloper } from '../../../entity'
import { paths } from './paths'

export const projectRoutes = {
  readOneProject: {
    path: paths.project.all.withId(),
    method: HttpMethod.GET,
    authenticated: false,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: (res: unknown) => Promise.resolve(res as ProjectWithDeveloper),
  },
}
