import { AuthLevel, buildPaths } from '@guiker/shared-framework'

export const paths = buildPaths(
  {
    payInMethod: {},
    user: {
      payInMethod: {},
    },
  },
  AuthLevel.ADMIN,
)
