import { LegacyRequestOptions, LegacyRestClient, Logger } from '@guiker/legacy-rest-client'

import { Authenticator, LegacyAuthenticator, LegacyUser, parseLegacyUser, Payload, User } from '../entity'

export { LegacyRequestOptions }

const usersApiPrefix = 'api/users'
const endpoints = {
  authenticate: `${usersApiPrefix}/authenticate`, // GET
  authenticateWithInvitationToken: `${usersApiPrefix}/exchange_token`, // POST
  signUp: `${usersApiPrefix}/`, //POST
  logIn: `${usersApiPrefix}/login`, // POST
  activateUser: `${usersApiPrefix}/activate`, // PUT
  requestPasswordReset: `${usersApiPrefix}/forgotpassword`, // POST
  fetchAuthenticators: 'api/authenticators', // GET
  changePassword: `${usersApiPrefix}/resetpassword`, // POST
  updateProfile: (userId: string) => `${usersApiPrefix}/${userId}`, // PUT
}

const parseLegacyAuthenticators = (data: LegacyAuthenticator[]): Authenticator[] =>
  data.map((authenticator) => {
    const { id, name, enabled } = authenticator

    return {
      id,
      provider: name,
      enabled,
    }
  })

const ApiClient = async (baseUrl: string, logger: Logger) => {
  const restClient = LegacyRestClient({ baseUrl, logger })

  return {
    activateUser: async ({ token: activationToken }: Payload.ActivateUser): Promise<User> => {
      const response = await restClient.put<LegacyUser>(endpoints.activateUser, { activationToken })
      return parseLegacyUser(response.body.data)
    },
    authenticateWithInvitationToken: async ({ token }: { token: string }): Promise<User> => {
      const response = await restClient.post<LegacyUser>(endpoints.authenticateWithInvitationToken, {
        invitationToken: token,
      })
      return parseLegacyUser(response.body.data)
    },
    logIn: async (payload: Payload.LogIn): Promise<User> => {
      const response = await restClient.post<LegacyUser>(endpoints.logIn, payload)
      return parseLegacyUser(response.body.data)
    },
    requestPasswordReset: async (payload: Payload.RequestPasswordReset): Promise<void> => {
      await restClient.post<LegacyUser>(endpoints.requestPasswordReset, payload)
    },
    signUp: async (payload: Payload.SignUp): Promise<User> => {
      const response = await restClient.post<LegacyUser>(endpoints.signUp, payload)
      return parseLegacyUser(response.body.data)
    },
    socialLogIn: async (payload: Payload.SocialLogIn): Promise<User> => {
      const response = await restClient.post<LegacyUser>(endpoints.logIn, payload)
      return parseLegacyUser(response.body.data)
    },
    fetchAuthenticators: async (): Promise<Authenticator[]> => {
      const response = await restClient.get<LegacyAuthenticator[]>(endpoints.fetchAuthenticators)
      return parseLegacyAuthenticators(response.body.data)
    },
    changePassword: async (payload: Payload.ChangePassword): Promise<User> => {
      const { accessToken, ...restPayload } = payload
      const response = await restClient.post<LegacyUser>(endpoints.changePassword, restPayload, {
        headers: { 'Access-Token': accessToken },
      })
      return parseLegacyUser(response.body.data)
    },
    updateProfile: async (payload: Payload.UpdateProfile): Promise<User> => {
      const { accessToken, userId, ...restPayload } = payload
      const response = await restClient.put<LegacyUser>(endpoints.updateProfile(userId), restPayload, {
        headers: { 'Access-Token': accessToken },
      })
      return parseLegacyUser(response.body.data)
    },
  }
}

export { ApiClient }
