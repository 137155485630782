import { yup } from '@guiker/yup-util'

import { Utilities } from '../../entity'
import { nullableBoolean } from './common'

export const utilities = yup.object<Utilities>({
  hotWater: nullableBoolean,
  electricity: nullableBoolean,
  heating: nullableBoolean,
  wifi: nullableBoolean,
})

export type UtilitiesSchema = yup.InferType<typeof utilities>
