import React, { useLayoutEffect, useMemo } from 'react'

import { parseAuthenticationToken, parseInvitationToken, User } from '@guiker/authentication-shared'
import { useTheme } from '@guiker/components-core'
import { PageNotFound } from '@guiker/error-pages'
import { Route, Routes, useLocationQuery, useSearchParams } from '@guiker/router'

import {
  AuthType,
  useAuthenticationApiClient,
  useAuthenticationContext,
  useAuthenticationModalContext,
  useConfig,
  useLayoutContext,
  usePublicAuthenticationApiClient,
  useQuery,
  useSendPageView,
} from '../hooks'
import { LocaleNested } from './LocaleNested'
import { routes } from './routes'
import { RouteConfig } from './type'

type PagedComponentProps = React.PropsWithChildren & {
  route: RouteConfig
  pageName: string
}

const PagedComponent: React.FC<PagedComponentProps> = ({ children, ...props }) => {
  const { route, pageName } = props
  const { displayAppBar, displayFooter, isDarkMode } = route
  const { setAppBarIsDisplayed, setFooterIsDisplayed } = useLayoutContext()
  const { setIsDarkMode } = useTheme()
  useSendPageView({ pageName })

  useLayoutEffect(() => {
    setAppBarIsDisplayed(displayAppBar)
    setFooterIsDisplayed(displayFooter)
    setIsDarkMode(isDarkMode)
  }, [displayAppBar, displayFooter, isDarkMode, setAppBarIsDisplayed, setFooterIsDisplayed, setIsDarkMode])

  return <>{children}</>
}

const RefreshOr404: React.FC = () => {
  const { reloadOnNotFoundPath } = useConfig()
  if (reloadOnNotFoundPath && typeof window !== 'undefined') {
    window.top.location.reload()
    return null
  } else {
    return <PageNotFound />
  }
}

const buildRoutes = (routes: { [key: string]: RouteConfig }, user: User) =>
  Object.values(routes).map((route: RouteConfig) => {
    const Component = route.Component

    return (
      <Route
        {...route}
        key={route.path}
        Component={
          Component &&
          (() => (
            <PagedComponent route={route} pageName={route.path}>
              <Component {...{ state: { user } }} />
            </PagedComponent>
          ))
        }
      />
    )
  })

const MemoizedRouter: React.FC<{ user: User | null }> = ({ user }) => {
  return (
    <Routes>
      <Route
        {...routes.Home}
        Component={() => {
          const Component = routes.Home.Component

          return (
            <PagedComponent route={routes.Home} pageName={routes.Home.path}>
              <Component />
            </PagedComponent>
          )
        }}
      />
      <Route path='/:locale/*' Component={LocaleNested}>
        {buildRoutes(routes, user)}
        <Route path='*' Component={RefreshOr404} />
      </Route>
      <Route path='*' Component={RefreshOr404} />
    </Routes>
  )
}

const Router: React.FC = () => {
  const { accessToken } = useLocationQuery('access_token')

  const { user, setUser, unsetUser } = useAuthenticationContext()
  const { openAuthSuccessModal } = useAuthenticationModalContext()
  const { setSearchParams } = useSearchParams()
  const authenticatedAuthApiClient = useAuthenticationApiClient(user?.accessToken)
  const publicAuthApiClient = usePublicAuthenticationApiClient()

  useQuery(
    ['user', accessToken],
    async () => {
      return publicAuthApiClient.activateUser({ payload: { token: accessToken } })
    },
    {
      onSuccess: (user) => {
        setUser(user)
        openAuthSuccessModal(AuthType.ACTIVATE)
      },
      onSettled: () => setSearchParams({}),
      retry: false,
      enabled: !user && !!accessToken,
    },
  )

  useQuery(
    ['user', user?.id],
    async () => {
      if (parseAuthenticationToken(user.accessToken, { validateExpiration: true })) {
        return authenticatedAuthApiClient.authenticate()
      } else if (parseInvitationToken(user.invitationToken)) {
        return publicAuthApiClient
          .authenticateWithInvitationToken({ payload: { token: user.invitationToken } })
          .then((res) => ({ ...res, invitationToken: user.invitationToken }))
      } else {
        return null
      }
    },
    {
      onSuccess: (user) => (user ? setUser(user) : unsetUser()),
      retry: false,
      enabled: !!user,
    },
  )

  return useMemo(() => <MemoizedRouter user={user} />, [user])
}

export { Router }
