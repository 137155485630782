import { yup } from '@guiker/yup-util'

import { ListingInquirySource, ListingInquiryStatus } from '../../../entity'

const userSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().nullable(),
  emailAddress: yup.string().email().required(),
})

const bookingInfoUpdateSchema = {
  price: yup
    .object({
      amount: yup.number().required(),
    })
    .required(),
  period: yup
    .object({
      from: yup.string().required(),
      to: yup.string().required(),
    })
    .required(),
  optionId: yup.string().required(),
}

export const createInquiryPayloadSchema = yup.object({
  ...bookingInfoUpdateSchema,
  listingId: yup.string().required(),
  users: yup.array().of(userSchema).required(),
  status: yup
    .mixed<ListingInquiryStatus>()
    .oneOf(Object.values(ListingInquiryStatus))
    .default(ListingInquiryStatus.COMPLETED)
    .required(),
  source: yup
    .mixed<ListingInquirySource>()
    .oneOf(Object.values(ListingInquirySource))
    .default(ListingInquirySource.OFFICE_LEASING_ASSOCIATE)
    .required(),
})

export type CreateInquiryPayloadSchema = yup.InferType<typeof createInquiryPayloadSchema>
