import * as React from 'react'

import { addressFormatter } from '@guiker/base-entity'
import { Flex, P, PSmall, PSmaller } from '@guiker/components-library'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { getLocalizedNeighbourhood } from '@guiker/react-framework'

import { useT } from '../../i18n'

export type CardAddressComponentProps = {
  listing: PropertySaleListing
  size?: 'small' | 'medium'
}

export const CardAddressComponent: React.FC<CardAddressComponentProps> = ({ listing, size = 'medium' }) => {
  const { t } = useT()

  const TitleComponent = size === 'medium' ? P : PSmall
  const SubtitleComponent = size === 'medium' ? PSmall : PSmaller

  return (
    <Flex flexDirection='column' flexGrow={1} gap={0.5}>
      <TitleComponent mb={0}>
        {addressFormatter.printStreetInformation({
          ...listing.property.building.address,
          streetNumber: listing.property.building.address.streetNumbers?.[0],
        })}
      </TitleComponent>
      <SubtitleComponent color={60} mb={0}>{`${addressFormatter.printCityName(
        listing.property.building.address,
      )} · ${getLocalizedNeighbourhood(t, {
        countryCode: listing.property.building.address.country,
        citySlug: listing.property.building.address.city,
        neighbourhoodSlug: listing.property.building.address.neighbourhood,
      })}`}</SubtitleComponent>
    </Flex>
  )
}
