import { config } from '../config'

export const baseEvent = {
  context: config.context,
} as const

export enum BaseEventTypes {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED',
}
