import { Scope } from '@guiker/base-entity'
import { slugifyLowercase } from '@guiker/lodash'

import { config } from '../config'

export const webappRoutes = {
  scope: function (scope: Scope) {
    return {
      path: `/${slugifyLowercase(scope.type)}/${scope.id}`,
      get rentPayments() {
        return {
          path: `${this.path}/${config.domainName}`,
        }
      },
    }
  },
}
