import React from 'react'

import { Checkbox, FormSection4, TwoColumnsGridLayout, useTranslation } from '@guiker/react-framework'
import { Appliances as AppliancesType, ApplianceTypes } from '@guiker/tenant-preference-shared'

type AppliancesProps = {
  defaultValue: AppliancesType
  name: string
}

export const Appliances: React.FC<AppliancesProps> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common-rentalListing'])

  const orderedApplianceTypes = [
    ApplianceTypes.airConditioner,
    ApplianceTypes.balcony,
    ApplianceTypes.oven,
    ApplianceTypes.dishwasher,
    ApplianceTypes.fridge,
    ApplianceTypes.washer,
  ]

  return (
    <FormSection4 title={t('appliances.title')}>
      <TwoColumnsGridLayout>
        {orderedApplianceTypes.map((appliance) => (
          <Checkbox
            key={appliance}
            defaultValue={defaultValue?.[appliance]}
            name={`${name}.${appliance}`}
            label={t(`appliances.${appliance}`)}
          />
        ))}
      </TwoColumnsGridLayout>
    </FormSection4>
  )
}
