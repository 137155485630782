import { DurationUnit } from 'luxon'

import { EligibleDate, parseDateTime } from './parse-date'

export const compareDate = (primaryDate: EligibleDate) => ({
  dateTime: parseDateTime(primaryDate),
  isTodayOrBefore: (comparedDate: EligibleDate) => parseDateTime(primaryDate) <= parseDateTime(comparedDate),
  isBefore: (comparedDate: EligibleDate) => parseDateTime(primaryDate) < parseDateTime(comparedDate),
  isAfter: (comparedDate: EligibleDate) => parseDateTime(primaryDate) > parseDateTime(comparedDate),
  getMonthDifference: (comparedDate: EligibleDate) => {
    const primaryDateTime = parseDateTime(primaryDate)
    const comparedDateTime = parseDateTime(comparedDate)

    return primaryDateTime.diff(comparedDateTime, 'months').months
  },
  getYearDifference: (comparedDate: EligibleDate = new Date()) => {
    const primaryDateTime = parseDateTime(primaryDate)
    const comparedDateTime = parseDateTime(comparedDate)

    return primaryDateTime.diff(comparedDateTime, 'years').years
  },
  isLessThanOneMonth: (comparedDate: EligibleDate) => {
    return compareDate(primaryDate).getMonthDifference(comparedDate) < 1
  },
  isWithinCertainMonth: (month: EligibleDate) => {
    return (
      parseDateTime(primaryDate).month === parseDateTime(month).month &&
      parseDateTime(primaryDate).year === parseDateTime(month).year
    )
  },
  getDifference: (
    comparedDate: EligibleDate,
    unit: DurationUnit | DurationUnit[],
  ): { [key in DurationUnit]?: number } => {
    const primaryDateTime = parseDateTime(primaryDate)
    const comparedDateTime = parseDateTime(comparedDate)
    const diffs = primaryDateTime.diff(comparedDateTime, unit).toObject()
    return Object.keys(diffs).reduce((a, b) => ({ ...a, [b]: Math.round(diffs[b as DurationUnit]) }), {})
  },
})
