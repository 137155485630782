import * as React from 'react'

import { LabeledMonetaryValue, LabeledPercentValue } from '@guiker/base-listing-components'
import { buildNestedListingRoutes, PropertySaleListing, SaleStatus } from '@guiker/property-sale-shared'
import { CardWithMedia, clsx, Flex, makeStyles, RouterLink } from '@guiker/react-framework'
import { Project } from '@guiker/real-estate-shared'

import { useT } from '../../i18n'
import { CardAddressComponent } from '../CardAddressComponent'
import { BathroomCountLabel, BedroomCountLabel, SquareFootageLabel } from '../CardLabelWithIcon'
import { CardPriceComponent } from '../CardPriceComponent'
import { PropertySaleListingCardChip } from './PropertySaleListingCardChip'

export type PropertySaleListingCardProps = {
  className?: string
  size?: 'small' | 'medium'
  listing: PropertySaleListing
  project?: Project
  shadow?: boolean
}

const useStyles = makeStyles(
  () => ({
    container: {
      position: 'relative',
      height: '100%',
    },
  }),
  { name: 'PropertySaleListingCard' },
)

export const PropertySaleListingCard: React.FC<PropertySaleListingCardProps> = ({
  className,
  listing,
  shadow = false,
  size = 'medium',
}) => {
  const classes = useStyles()
  const { tShared } = useT({ entity: 'listing' })
  const isSold = listing.propertySale?.saleStatus === SaleStatus.SOLD

  const unit = listing.property.units?.[0]

  return (
    <RouterLink to={buildNestedListingRoutes(listing).listing} className={clsx(classes.container, className)} fullWidth>
      <CardWithMedia
        media={{ images: (isSold ? [listing.pictures[0]] : listing.pictures) as { url: string }[] }}
        condensed={size === 'small'}
        shadow={shadow}
        Chip={(props) => <PropertySaleListingCardChip listing={listing} {...props} />}
      >
        <Flex flexDirection='column' gap={3}>
          <Flex gap={1}>
            <CardAddressComponent listing={listing} size={size} />
            {!isSold && <CardPriceComponent listing={listing} size={size} />}
          </Flex>
          {!isSold && (
            <Flex justifyContent='space-between'>
              <LabeledMonetaryValue
                label={tShared('results.targetEquity')}
                currencySymbol='onlySymbol'
                value={{
                  value: listing.results.targetEquity,
                  currency: listing.price.currency,
                }}
              />
              <LabeledPercentValue
                align='right'
                label={tShared('results.irr')}
                alignItems='flex-end'
                value={listing.results.irr}
              />
            </Flex>
          )}

          <Flex gap={3} justifyContent='center'>
            <BedroomCountLabel unit={unit} />
            <BathroomCountLabel unit={unit} />
            <SquareFootageLabel unit={unit} />
          </Flex>
        </Flex>
      </CardWithMedia>
    </RouterLink>
  )
}
