import React from 'react'

import { CheckOutlineIcon, OutlinedAlertIcon, PaymentSmallIcon, PSmall, useT } from '@guiker/react-framework'
import { TenantInstalmentInvoiceEventStatus } from '@guiker/rent-payment-shared'

import { TimelineMarker } from './TimelineMarker'

export const REFUND_STEP = {
  label: 'refund',
  success: [TenantInstalmentInvoiceEventStatus.REFUND_SUCCEED],
  failed: [TenantInstalmentInvoiceEventStatus.CHARGE_REFUND_FAILED],
}

const BASE_STEPS = [
  { label: 'pending', success: [TenantInstalmentInvoiceEventStatus.CHARGE_PENDING] },
  {
    label: 'charge',
    success: [TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED],
    failed: [TenantInstalmentInvoiceEventStatus.CHARGE_FAILED],
  },
  { label: 'settled', success: [TenantInstalmentInvoiceEventStatus.SETTLED] },
]

const OFFLINE_BASE_STEPS = [
  {
    label: 'charge',
    success: [TenantInstalmentInvoiceEventStatus.CHARGE_PENDING, TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED],
    failed: [TenantInstalmentInvoiceEventStatus.CHARGE_FAILED],
  },
  { label: 'settled', success: [TenantInstalmentInvoiceEventStatus.SETTLED] },
]

export const findBaseSteps = (isPaidOffline: boolean) => (isPaidOffline ? OFFLINE_BASE_STEPS : BASE_STEPS)

export const selectIcon = (isFirst: boolean, isFailed: boolean) => {
  return isFirst ? <PaymentSmallIcon /> : isFailed ? <OutlinedAlertIcon /> : <CheckOutlineIcon />
}

export const buildDefaultTimelineEvents = () => {
  const { tShared } = useT({ domain: 'rentPayment' })

  return BASE_STEPS.map(({ success }, index) => {
    const isFirst = index === 0
    const isFailed = false

    const baseMarker = {
      isFirst,
      isLast: index === BASE_STEPS.length - 1,
      isCompleted: false,
      isFailed,
      icon: selectIcon(isFirst, isFailed),
    }

    return {
      label: <PSmall mb={0}>{tShared(`tenantInstalment.events.status.${success}`)}</PSmall>,
      marker: {
        ...baseMarker,
        icon: <TimelineMarker marker={baseMarker} />,
      },
    }
  })
}
