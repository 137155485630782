import { CurrencyISO, yup } from '@guiker/shared-framework'

import { Capabilities } from '../../../entity'

export const readAllPlatformPayoutMethodsQueryParamsSchema = yup.object<{
  capabilities?: Capabilities[]
  currency?: CurrencyISO
}>({
  capabilities: yup
    .array()
    .of<Capabilities>(yup.mixed<Capabilities>())
    .nullable()
    .transform((v) => (!!v ? v : null)),
  currency: yup.mixed<CurrencyISO>().oneOf(Object.values(CurrencyISO)).nullable(),
})
