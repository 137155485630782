import { routesBuilder } from '@guiker/shared-framework'

import { RentPaymentsPlanWithTenantInstalments } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<RentPaymentsPlanWithTenantInstalments>()({
  basePath: paths[':scopeType'][':scopeId'].rentPaymentsPlan.path,
})

const { buildGet: buildEventGet } = routesBuilder<Event>()({
  basePath: paths[':scopeType'][':scopeId'].rentPaymentsPlan.path,
})

export const routes = {
  readRentPaymentsPlanByScope: buildGet({}),
  readAllRentPaymentEvents: buildEventGet({ path: '/events', responseIsArray: true }),
}
