import React from 'react'

import { CardCvcElement as StripeCardCvcElement, CardCvcElementProps } from '@stripe/react-stripe-js'

import { CardElementProps, RenderProps } from '../context'
import { InputBase } from './InputBase'

type Props = CardElementProps<CardCvcElementProps>

export const CardCvcElement: React.FC<Props> = ({ id, label, inputError, ...props }) => (
  <InputBase id={id} label={label} inputError={inputError}>
    {(renderProps: RenderProps) => <StripeCardCvcElement {...renderProps} {...props} />}
  </InputBase>
)
