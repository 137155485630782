import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { Box, SecondaryButton, useMediaQuery } from '@guiker/components-library'
import { ExpandIcon } from '@guiker/icons'
import { clsx } from '@guiker/react-framework'

import { useTranslation } from '../../i18n'

type GalleryProps = {
  pictures: { url: string }[]
  onClick?: () => void
  onClickEdit?: () => void
}

const PICTURE_SPACING = 0.5

const useStyle = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    borderRadius: 0,
    width: '100%',
    height: 440,
    overflow: 'hidden',
  },
  img: {
    objectFit: 'cover',
    display: 'flex',
    width: '100%',
    cursor: ({ clickable }: { clickable: boolean }) => (clickable ? 'pointer' : 'default'),
  },
  mainImageContainer: {
    display: 'flex',
    flex: ({ isMobile }: { isMobile: boolean }) => `2 2 ${isMobile ? '50' : '66'}%`,
    marginRight: theme.spacing(PICTURE_SPACING),
  },
  sideImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: ({ isMobile }: { isMobile: boolean }) => `1 1 ${isMobile ? '50' : '33'}%`,
    justifyContent: 'center',
  },
  sideImage: {
    display: 'flex',
    flex: '1 1 50%',
  },
  button: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    boxShadow: theme.shadows.medium,
  },
  link: {
    marginBottom: theme.spacing(2),
    right: 0,
    float: 'right',
  },
})

export const Gallery: React.FC<GalleryProps> = ({ pictures, onClick }) => {
  const { t } = useTranslation(['common'])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyle({ isMobile, clickable: !!onClick })
  const [image1, image2, image3] = pictures

  return (
    <Box className={classes.root} onClick={!!onClick ? onClick : () => {}}>
      <div className={classes.mainImageContainer}>
        <img className={classes.img} src={image1?.url} alt='image1' />
      </div>
      {image2 && !isMobile && (
        <div className={classes.sideImageContainer}>
          <Box className={classes.sideImage} mb={PICTURE_SPACING}>
            <img className={clsx(classes.img)} src={image2?.url} alt='image2' />
          </Box>
          {image3 && (
            <div className={classes.sideImage}>
              <img className={clsx(classes.img)} src={image3?.url} alt='image3' />
            </div>
          )}
        </div>
      )}
      {!isMobile && onClick && (
        <SecondaryButton
          size='small'
          className={classes.button}
          startIcon={<ExpandIcon size='smaller' color={60} />}
          onClick={onClick}
        >
          {t('actions.viewMedia')}
        </SecondaryButton>
      )}
    </Box>
  )
}
