import { ValueOf } from '@guiker/ts-utils'

export const InvestorProfileReviewalStatus = {
  APPROVED: 'APPROVED',
  NOT_READY: 'NOT_READY',
  PENDING_REVIEW: 'PENDING_REVIEW',
  REJECTED: 'REJECTED',
} as const

export type InvestorProfileReviewalStatus = ValueOf<typeof InvestorProfileReviewalStatus>
