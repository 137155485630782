import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { FundingAccountWithdrawal, WithChargeType } from '../../entity'
import { baseEvent } from '../base'

export const definition = generateEventsDefinition({
  ...baseEvent,
  entity: 'FUNDING_ACCOUNT_WITHDRAWAL',
  data: null as FundingAccountWithdrawal,
  eventTypes: {
    PROCESSED: 'PROCESSED',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    PAYOUT_COMPLETED: 'PAYOUT_COMPLETED',
    CHARGE_COMPLETED: 'CHARGE_COMPLETED',
    CHARGE_FAILED: {
      type: 'CHARGE_FAILED',
      eventData: null as WithChargeType<FundingAccountWithdrawal>['metadata'],
    },
  } as const,
})

export const { eventTypes: EventTypes, events: Events } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
