import { UnitType } from '@guiker/real-estate-shared'
import { yup } from '@guiker/yup-util'

import { InvestmentAssumptions } from '../../entity'
import { EstimatedSalePriceCalculationMethod } from '../../entity/investment-assumptions'
import { expenseValueTypeChecker } from '../../utils'
import { priceSchema } from './price'
import { topupSchema } from './topup'

const baseAssumptionsSchema = yup.object({
  estimatedSalePriceCalculationMethod: yup
    .mixed<EstimatedSalePriceCalculationMethod>()
    .oneOf(Object.values(EstimatedSalePriceCalculationMethod))
    .required()
    .default(EstimatedSalePriceCalculationMethod.APPRECIATION_RATE),
  holdingPeriod: yup.number().required(),
  npvDiscountRate: yup.number().nullable(),
  assessedValue: yup.number().nullable(),
  propertyAppreciationRate: yup.number(),
  capRate: yup.number(),
})

const factors = {
  rentalGrowthRate: yup.number().required(),
  vacancyRate: yup.number().required(),
}

const factorsSchema = yup.object({ ...factors })

const occupancySchema = yup.object({
  unit: yup.string().nullable(),
  type: yup.string().required().oneOf(Object.values(UnitType)),
  monthlyRent: yup.number().min(0).required(),
  isOccupied: yup.boolean().nullable().default(false),
  period: yup.object().when('isOccupied', {
    is: true,
    then: yup
      .object({
        from: yup.string().required(),
        to: yup.string().required(),
      })
      .required(),
    otherwise: yup.object().nullable(),
  }),
})

const revenueAssumptionsSchema = yup.object({
  ...factors,
  rentalRevenue: yup.number(),
  factors: factorsSchema.required(),
  isEstimatedRevenue: yup.boolean().default(false),
  occupancies: yup.array().of(occupancySchema).default([]),
})

const mortgageSchema = yup.object({
  minimumDownPaymentPercentage: yup.number().min(0).max(100).required(),
  termsInYears: yup.number().required(),
  interestRate: yup.number().min(0).max(100).required(),
})

const financingAssumptionsSchema = yup.object({
  mortgage: mortgageSchema.required(),
})

const costsSchema = yup
  .object()
  .test('costRequired', 'required', (costs: InvestmentAssumptions.Costs) => {
    return Object.values(costs || {}).every((cost) => {
      if (expenseValueTypeChecker.isFlatValue(cost.value)) {
        return cost.value && cost.value.amount?.currency != null && cost.value.amount.value != null
      } else if (expenseValueTypeChecker.isPercentageValue(cost.value)) {
        return cost.value?.rate != null && cost.value?.base != null
      }

      return true
    })
  })
  .default({})

const operationAssumptionsSchema = yup.object({ costs: costsSchema })
const purchaseAssumptionsSchema = yup.object({
  taxes: costsSchema,
  costs: costsSchema,
  topup: topupSchema,
  price: priceSchema.required(),
})

export const assumptionsSchema = yup.object({
  base: baseAssumptionsSchema.required(),
  revenue: revenueAssumptionsSchema.required(),
  purchase: purchaseAssumptionsSchema.required(),
  financing: financingAssumptionsSchema.required(),
  operation: operationAssumptionsSchema.required(),
})
