import { AdminApiClient } from '@guiker/payment-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export const { useContext: useAdminPayInMethodsContext, NestedRouter: AdminPayInMethodsNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    cacheKey: ({ userId }: { userId: string }) => `readAllUserPayInMethods-${userId}`,
    fetcher: (apiClient, { userId }: { userId: string }) => apiClient.readAllPayInMethods({ pathParams: { userId } }),
  })

export const { useContext: useAdminPayInMethodContext, NestedRouter: AdminPayInMethodNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    cacheKey: ({ payInMethodId }: { payInMethodId: string }) => `readOneUserPayInMethod-${payInMethodId}`,
    fetcher: (apiClient, { payInMethodId }: { payInMethodId: string }) =>
      apiClient.readOnePayInMethod({ pathParams: { id: payInMethodId } }),
  })
