import React, { useState } from 'react'

import { Collapse, Flex, TextButton, useT } from '@guiker/react-framework'
import { Task } from '@guiker/task-shared'

import { categoryMapping } from '../../utils'
import { Highlight } from '../Highlight'
import { TaskList } from '../TaskList'

type TaskWithHighlightProps = {
  tasks?: Task[]
  alwaysExpanded?: boolean
}

export const TaskWithHighlight: React.FC<TaskWithHighlightProps> = ({ tasks, alwaysExpanded = false }) => {
  const { tShared, tBase } = useT({ domain: 'task' })
  const [isOpen, setIsOpen] = useState(alwaysExpanded)

  if (!tasks?.length) return <></>

  return (
    <Flex flexDirection='column' gap={2}>
      <Highlight task={tasks[0]} />
      <Collapse in={isOpen}>
        <Flex flexDirection='column' gap={2} minWidth={'100%'} px={3} py={4}>
          <TaskList tasks={categoryMapping(tasks.slice(1))} />
        </Flex>
      </Collapse>
      {tasks?.length > 1 && (
        <Flex justifyContent='center' alignItems='center' flex={1}>
          {!alwaysExpanded &&
            (isOpen ? (
              <TextButton onClick={() => setIsOpen((curr) => !curr)}>{tBase('actions.seeLess')}</TextButton>
            ) : (
              <TextButton onClick={() => setIsOpen((curr) => !curr)}>
                {tShared('tasks.seeMore', { count: tasks?.length - 1 })}
              </TextButton>
            ))}
        </Flex>
      )}
    </Flex>
  )
}
