import { capitalize, optionalConcat } from '@guiker/lodash'

import { Address as BaseAddress } from '../address'
import { formatPostalCode } from './format-postal-code'

type Address = BaseAddress & { room?: string }

const printRoomSuite = (address: Partial<Address>, withRoom = true) => {
  const { room, suite } = { ...address }
  const printSuite = `${suite ? `${capitalize(suite)} - ` : ''}`
  const printRoom = withRoom && !!room ? `${room} | ` : ''
  return `${printRoom}${printSuite}`
}

const printCityName = (address: Partial<Address>) => {
  return capitalize(address?.cityName ?? address?.city)
}

const printStreetInformation = (address: Partial<Address>, withRoom = true) => {
  const { streetNumber, street } = { ...address }
  const roomSuite = printRoomSuite(address, withRoom)
  return `${roomSuite}${streetNumber ?? ''} ${street}`
}

const printPostalCode = (address: Partial<Address>) => {
  const { postalCode, country } = { ...address }
  return formatPostalCode(postalCode, country)
}

const printAddressLine2 = (address: Partial<Address>, withCountry = true) => {
  const { city, cityName, postalCode, country, state } = { ...address }
  return optionalConcat([
    capitalize(cityName ?? city),
    [state?.toUpperCase(), formatPostalCode(postalCode, country)].join(' ').trim(),
    withCountry ? country : undefined,
  ])
}

const printShortAddress = (address: Partial<Address>, withRoom = true) =>
  optionalConcat([printStreetInformation(address, withRoom), printCityName(address)])

const printFullAddress = (address: Partial<Address>) =>
  optionalConcat([printStreetInformation(address), printAddressLine2(address)], ', ')

export const addressFormatter = {
  printStreetInformation,
  printAddressLine2,
  printShortAddress,
  printPostalCode,
  printFullAddress,
  printCityName,
  printRoomSuite,
}
