import React from 'react'

import { useTranslation } from '@guiker/i18n'
import { ColumnGridLayout, Display3, Flex, makeStyles, useStaticAssetContext } from '@guiker/react-framework'

const elements = ['brivia', 'aquilini', 'cogir', 'horizon', 'montes']

const useStyles = makeStyles(
  () => ({
    image: {
      height: 'fit-content',
      maxWidth: 180,
    },
    container: {
      alignItems: 'center',
      justifyItems: 'center',
    },
  }),
  { name: 'InvestorPartners' },
)

const InvestorPartners: React.FC = () => {
  const { t } = useTranslation(['webapp', 'common'])
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('investors')
  const classes = useStyles()

  return (
    <Flex flexDirection='column' gap={5} alignItems='center'>
      <Display3>{t(`webapp:investors.partners.title`)}</Display3>
      <ColumnGridLayout
        className={classes.container}
        gap={4}
        gridTemplateColumns={elements.length}
        xs={{ gridTemplateColumns: 1 }}
        sm={{ gridTemplateColumns: 2 }}
      >
        {elements.map((el) => {
          const asset = assets.partners[el]
          return <img key={el} src={asset} className={classes.image} />
        })}
      </ColumnGridLayout>
    </Flex>
  )
}

export { InvestorPartners }
