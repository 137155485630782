import { yup } from '@guiker/yup-util'

import { Referral, ReferralType } from '../../entity'

export const referralSchema = yup.object<Referral>({
  type: yup.mixed().oneOf(Object.values(ReferralType)).required(),
  other: yup.string().when('type', {
    is: ReferralType.OTHER,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
})
