import React from 'react'

import { BaseTable, BaseTD, BaseTR, clsx, makeStyles, PBold, PSmall, useTranslation } from '@guiker/react-framework'
import { Currency, currency as baseCurrency, CurrencyISO, money } from '@guiker/shared-framework'

import { useInvestmentAssumptionContext } from '../../../hooks'

const AmountComponent: React.FC<React.PropsWithChildren & { currency: Currency; bold?: boolean }> = ({
  bold,
  children,
  currency,
}) => {
  const Component = bold ? PBold : PSmall

  return (
    <Component whiteSpace='pre' textAlign='right'>
      {money.fromAmount(Number(children), currency).toString('onlySymbol', true)}
    </Component>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
  },
  row: {
    display: 'table-row-group',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  emptyRow: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  rowHeader: {
    width: 200,
    position: 'absolute',
    display: 'block',
    borderBottom: 'none',
    paddingLeft: 0,
    '& > p': {
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  shifted: {
    paddingLeft: 230,
  },
}))

const Row: React.FC<{ data: any; currency: Currency; label: string; bold?: boolean }> = ({
  data,
  bold,
  currency,
  label,
}) => {
  const classes = useStyles()

  const Component = bold ? PBold : PSmall

  return (
    <BaseTR className={clsx(classes.row)}>
      <BaseTD className={classes.rowHeader}>
        <Component whiteSpace='pre'>{label}</Component>
      </BaseTD>
      {data.map((item, index) => (
        <BaseTD key={index} className={index === 0 && classes.shifted}>
          <AmountComponent bold={bold} currency={currency}>
            {item}
          </AmountComponent>
        </BaseTD>
      ))}
    </BaseTR>
  )
}

const EmptyRow: React.FC<{ colSpan: number }> = ({ colSpan }) => {
  const classes = useStyles()

  return (
    <BaseTR className={clsx(classes.row, classes.emptyRow)}>
      <BaseTD className={classes.rowHeader}>&nbsp;</BaseTD>
      {Array(colSpan)
        .fill(0)
        .map((_, index) => (
          <BaseTD key={index} className={index === 0 && classes.shifted}>
            &nbsp;
          </BaseTD>
        ))}
    </BaseTR>
  )
}

const YearlyPAndL: React.FC = () => {
  const { t } = useTranslation('common-investmentAssumption')
  const formPrefix = 'common-investmentAssumption:yearlyPAndL'
  const currency = baseCurrency[CurrencyISO.CAD]
  const { investmentResults } = useInvestmentAssumptionContext()
  const classes = useStyles()

  return (
    <>
      <BaseTable className={classes.root} fullWidth={false}>
        <tbody>
          <BaseTR className={classes.row}>
            <BaseTD className={classes.rowHeader}>
              <PBold whiteSpace='pre'>&nbsp;</PBold>
            </BaseTD>
            {investmentResults.yearlyCashflows.map(({ year }, index) => (
              <BaseTD key={index} className={index === 0 && classes.shifted}>
                <PBold textAlign='right'>{t(`${formPrefix}.year`, { year })}</PBold>
              </BaseTD>
            ))}
          </BaseTR>
          <Row
            label={t(`${formPrefix}.rentalRevenue`)}
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.rentalIncome)}
          />
          <Row
            label={t(`${formPrefix}.vacancyAllowance`)}
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.vacancyAllowance)}
          />
          <Row
            label={t(`${formPrefix}.netRentalRevenue`)}
            currency={currency}
            bold
            data={investmentResults.yearlyCashflows.map((d) => d.netRentalRevenue)}
          />
          {investmentResults.yearlyCashflows[0].operatingExpenses.map((cost, index) => (
            <Row
              key={index}
              label={t(`common-propsharing:listing.assumptions.operation.costs.${cost.jurisdiction}.${cost.type}`)}
              currency={currency}
              data={investmentResults.yearlyCashflows.map((d) => -d.operatingExpenses[index].computedValue)}
            />
          ))}
          <Row
            label={t(`${formPrefix}.netOperatingIncome`)}
            currency={currency}
            bold
            data={investmentResults.yearlyCashflows.map((d) => d.netOperatingIncome)}
          />
          <Row
            label={t(`${formPrefix}.totalDebtService`)}
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.totalDebtService)}
          />
          <Row
            label={t(`${formPrefix}.assetManagementFee`)}
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.assetManagementFee)}
          />
          <Row
            label={t(`${formPrefix}.netIncome`)}
            bold
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.netIncome)}
          />
          <EmptyRow colSpan={investmentResults.yearlyCashflows.length} />
          <Row
            label={t(`${formPrefix}.mortgageBalance`)}
            bold
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => -d.mortgageBalance)}
          />
          <Row
            label={t(`${formPrefix}.estimatedSalesPrice`)}
            bold
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.estimatedSalesPrice)}
          />
          <Row
            label={t(`${formPrefix}.netCashFlow`)}
            bold
            currency={currency}
            data={investmentResults.yearlyCashflows.map((d) => d.netCashFlow)}
          />
        </tbody>
      </BaseTable>
    </>
  )
}

export { YearlyPAndL }
