import React, { useState } from 'react'

import { BookingActions, isRejectable } from '@guiker/booking-shared'
import { TextButton, useTranslation } from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'
import { RejectApplicantModal } from './RejectBookingModal'

const UnitManagerBookingRejection: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const { booking, can } = useBookingContext()

  const [rejectApplicantModalOpen, setRejectApplicantModalOpen] = useState(false)

  const canBeRejected = can(BookingActions.RejectBooking) && isRejectable(booking)

  if (!canBeRejected) {
    return <></>
  }

  return (
    <div>
      <TextButton size='small' color='alert' onClick={() => setRejectApplicantModalOpen(true)}>
        {t('unitManagerBookingSteps.rejectApplicants')}
      </TextButton>
      <RejectApplicantModal open={rejectApplicantModalOpen} setOpen={setRejectApplicantModalOpen} />
    </div>
  )
}

export { UnitManagerBookingRejection }
