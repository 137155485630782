import { HttpMethod } from '@guiker/http'

import { Legacy } from '../../entity'
import { listingIdNested } from '../schemas'
import { paths } from './paths'

const LISTING_WITH_ID_PATH = paths.listing.one.withId(':listingId')
const responseValidator = (payload: unknown) => Promise.resolve(payload as Legacy.Listing)

export const routes = {
  readOneListing: {
    path: LISTING_WITH_ID_PATH,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (params: unknown) => listingIdNested.validate(params),
  },
}
