import { TypeOf, yup } from '@guiker/yup-util'

import { LeaseRoleNames } from '../../../entity'
import { signature } from '../../schemas'

export const lessorSignature = yup.object({
  emailAddress: yup.string().email().required(),
  signature: signature.when('draftedAs', {
    is: LeaseRoleNames.DRAFTER,
    then: signature.nullable().transform(() => null),
    otherwise: signature.required(),
  }),
  draftedAs: yup
    .mixed<LeaseRoleNames.DRAFTER | LeaseRoleNames.LESSOR>()
    .oneOf([LeaseRoleNames.DRAFTER, LeaseRoleNames.LESSOR])
    .required(),
})

export type LessorSignature = TypeOf<typeof lessorSignature>
