import * as yup from 'yup'

import { BookingRoleNames } from '../../../entity'

export const bookingIdAndUserIdPathParamsSchema = yup.object({
  bookingId: yup.string().required(),
  userId: yup.string().required(),
})

export const inviteBookingUnitManagerByEmailPayloadSchema = yup.object({
  emailAddress: yup.string().email().required(),
  firstName: yup.string().required(),
  role: yup.mixed<BookingRoleNames>().oneOf(Object.values(BookingRoleNames)),
})

export const inviteBookingUnitManagersPayloadSchema = yup
  .array()
  .of(
    yup.object({
      userId: yup.string().required(),
    }),
  )
  .test('unique', 'userIds should be unique', (list: { userId: string }[]) => {
    const userIds = list.map((l) => l.userId)
    return userIds.length === new Set(userIds).size
  })
