import { yup } from '@guiker/yup-util'

const expenseValueSchema = yup.object({
  type: yup.string().oneOf(['flat', 'percentage']).required(),
  amount: yup.mixed().when('type', (value: string, schema: yup.MixedSchema) => {
    switch (value) {
      case 'percentage':
        return yup.number().required()
      case 'flat':
        return yup
          .object({
            currency: yup.string().required(),
            value: yup.number().required(),
          })
          .required()
      default:
        return schema
    }
  }),
})

export const expenseSchema = yup.object({
  jurisdiction: yup.string().oneOf(['montreal-qc', 'toronto-on']).required(),
  type: yup.string().oneOf(['schoolTax', 'municipalTax', 'condoFee', 'managementFee']).required(),
  frequency: yup.string().oneOf(['monthly', 'yearly', 'oneTimeAtPurchase']).required(),
  value: expenseValueSchema.required(),
})
