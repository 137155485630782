import React from 'react'

import { AssetFile } from '@guiker/asset-uploader'
import { Step, StepProps } from '@guiker/components-library'
import { useMutation } from '@guiker/react-query'

import { GovernmentId } from '../GovernmentId'
import { useIdentityVerificationContext } from '../IdentityVerificationContext'

type GovernmentIdBackStepProps = {}

export const GovernmentIdBackStep: React.FC<StepProps & GovernmentIdBackStepProps> = (props) => {
  const { governmentIdBack, resetGovernmentIdBack, validateAndSetGovernmentIdBack } = useIdentityVerificationContext()

  const isLoading = governmentIdBack && !governmentIdBack?.status
  const hasError = governmentIdBack && !isLoading && governmentIdBack?.status !== 'SUCCESS'
  const isSuccess = governmentIdBack && !isLoading && governmentIdBack?.status === 'SUCCESS'

  const { mutate: onAddAsset } = useMutation(['validateAndSetGovernmentIdBack'], (asset: AssetFile) =>
    validateAndSetGovernmentIdBack({ image: asset }),
  )

  const onClickNext = () => {
    if (isSuccess) {
      props.onClickNext?.()
    }
  }

  return (
    <Step
      {...props}
      hasBackButton={false}
      nextButtonType='button'
      isSubmitting={isLoading}
      onClickNext={onClickNext}
      nextDisabled={!governmentIdBack}
      errors={hasError && { governmentIdBack: hasError }}
    >
      <GovernmentId
        isLoading={isLoading}
        onAdd={onAddAsset}
        onRemove={resetGovernmentIdBack}
        imageWithResult={governmentIdBack}
        name='governmentIdBack'
      />
    </Step>
  )
}
