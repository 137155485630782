import React from 'react'

import { mainPathBuilder } from '@guiker/my-investment-shared'
import { ColumnConfig, Flex, Link, P, PSmall, PSmaller, TextButton, useT } from '@guiker/react-framework'
import { TenantInstalmentStatusChipWithTooltip } from '@guiker/rent-payment-components'
import { addressFormatter, compact, CurrencyISO, money, optionalConcat } from '@guiker/shared-framework'
import { RentCollectionTableDataPoint } from '@guiker/statistic-shared'

export const useRentCollectionColumns = (): ColumnConfig<RentCollectionTableDataPoint>[] => {
  const { tBase, tShared } = useT({ domain: 'components', entity: 'statistic' })

  return compact([
    {
      label: tShared(`rentCollection.table.headers.address`),
      name: 'address',
      size: 1.8,
      sortable: false,
      renderValue: ({ unit }) => {
        const { address, listingId } = unit
        const listingUrl = `/myaccount/listings/${listingId}/rentals`

        return (
          <Link isExternalLink to={listingUrl} prefixWithLocale={false} target='_top' underline>
            <PSmall mb={0}>{addressFormatter.printShortAddress(address)}</PSmall>
          </Link>
        )
      },
    },
    {
      label: tShared(`rentCollection.table.headers.tenant`),
      name: 'payers',
      size: 1,
      sortable: false,
      renderValue: ({ payer }) => (
        <Flex flexDirection='column'>
          <PSmall mb={0}>{optionalConcat([payer.firstName, payer.lastName], ' ').trim()}</PSmall>
          <PSmaller mb={0} color={60}>
            {payer.emailAddress}
          </PSmaller>
        </Flex>
      ),
    },
    {
      label: tShared(`rentCollection.table.headers.rent`),
      name: 'rent',
      size: 0.8,
      headerOptions: { textAlign: 'end' },
      renderValue: ({ total, payer }) => {
        const amount = money.fromAmount(payer.payable, total.currency).toString('onlySymbol')
        return (
          <P textAlign='right' mb={0}>
            {amount}
          </P>
        )
      },
    },
    {
      label: tShared(`rentCollection.table.headers.receivable`),
      name: 'receivable',
      size: 0.8,
      headerOptions: { textAlign: 'end' },
      renderValue: ({ payer, total }) => {
        const amount = money.fromAmount(payer.receivable, total.currency).toString('onlySymbol')
        return (
          <P textAlign='right' mb={0}>
            {amount}
          </P>
        )
      },
    },
    {
      label: tShared(`rentCollection.table.headers.status`),
      name: 'status',
      size: 0.8,
      renderValue: ({ payer }) => <TenantInstalmentStatusChipWithTooltip tenantInstalment={payer.tenantInstalment} />,
    },
    {
      label: '',
      name: 'action',
      size: 0.3,
      sortable: false,
      renderValue: ({ scope, payer, total }) =>
        total.currency === CurrencyISO.USD ? (
          <Link
            to={
              mainPathBuilder.root.rentalTenancy.bookings
                .byBookingId(scope.id)
                .tenantInstalments.byTenantInstalmentId(payer.tenantInstalment.id).path
            }
          >
            <TextButton size='small'>{tBase('actions.view')}</TextButton>
          </Link>
        ) : (
          <></>
        ),
    },
  ])
}
