import { Action, isComplexAction } from './trigger'

type BuilderArgs<C, T> = {
  context: C
  targetType: T
}

export const triggerBuilder = <C extends string, T extends string>(args: BuilderArgs<C, T>) => ({
  buildTriggersFromActions: <A extends Record<string, Action>>(actions: A) =>
    Object.values(actions).reduce((acc, actionValue) => {
      const action = isComplexAction(actionValue) ? actionValue.type : actionValue

      return {
        ...acc,
        [action]: {
          context: args.context,
          target: { type: args.targetType },
          action,
          payload: null,
          executesAt: null as Date,
        },
      }
    }, {}) as {
      [key in keyof A]: {
        context: C
        target: { type: T }
        executesAt: Date
        action: A[key] extends { type: string } ? A[key]['type'] : A[key]
        payload: A[key] extends { payload: unknown } ? A[key]['payload'] : never
      }
    },
})
