import React, { useEffect, useState } from 'react'

import { Divider, HiddenInput, KeyValuePair, P, useT } from '@guiker/react-framework'
import { RentalOptionsDropdown } from '@guiker/rental-option-dropdown'
import { DateTime, money, toISODate } from '@guiker/shared-framework'

import { useFormContext, useListingInquiryContext } from '../../hooks'
import {
  ColumnGridLayout,
  DatePicker,
  Flex,
  Link,
  makeStyles,
  MinusSmallIcon,
  PBold,
  PlusSmallIcon,
  PSmall,
  TextField,
  theme,
} from '../'
import { TimeSlotScheduler } from '../TimeSlotScheduler'

const useStyle = makeStyles({
  formContainer: {
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(4),
  },
})

type ListingInquiryFormContentProps = {
  isRoommatable?: boolean
  isSuccess?: boolean
}

export const FormContent: React.FC<ListingInquiryFormContentProps> = ({ isRoommatable = false, isSuccess }) => {
  const { tBase } = useT({})
  const { tMain } = useT({ domain: 'rentalListing' })
  const classes = useStyle()
  const [showMessageBox, setShowMessageBox] = useState(false)

  const { selectedPriceOptionId, rentalOptions, setSelectedPriceOptionId, setRentalOptions } =
    useListingInquiryContext()
  const { setValue, reset } = useFormContext()

  useEffect(() => {
    isSuccess && reset()
  }, [isSuccess])

  useEffect(() => {
    selectedPriceOptionId && setValue('rentalOptionId', selectedPriceOptionId)
  }, [selectedPriceOptionId])

  const selectedRentalOption = rentalOptions.find(({ value }) => value === selectedPriceOptionId)
  const { currency } = selectedRentalOption.price
  const priceRange = { low: 7500, high: 12500 }
  const membershipFee = 3500

  return (
    <div className={classes.formContainer}>
      <ColumnGridLayout gap={3}>
        {rentalOptions?.length > 1 ? (
          <RentalOptionsDropdown
            label={tMain('rentalOptions')}
            maxWidth='100%'
            name='rentalOptionId'
            defaultValue={rentalOptions?.length ? rentalOptions[0].value : ''}
            setSelectedPriceOptionId={setSelectedPriceOptionId}
            rentalOptions={rentalOptions}
            setRentalOptions={setRentalOptions}
          />
        ) : (
          <HiddenInput name='rentalOptionId' defaultValue={rentalOptions[0].value} />
        )}

        <DatePicker
          name='moveInDate'
          label={tMain('inquiry.moveInDate')}
          maxWidth='100%'
          minDate={toISODate(DateTime.local())}
        />

        {!isRoommatable && <TimeSlotScheduler condensed emptyInit />}
        {showMessageBox ? (
          <TextField
            name='message'
            multiline
            maxWidth='100%'
            rows={2}
            label={
              <Flex justifyContent='space-between'>
                <PBold mb={0}>{tMain('inquiry.messageLabel')}</PBold>
                <Link
                  color={60}
                  onClick={() => setShowMessageBox(false)}
                  underline={false}
                  TextComponent={PSmall}
                  adornment={<MinusSmallIcon size='smallest' />}
                >
                  {tBase('actions.remove')}
                </Link>
              </Flex>
            }
          />
        ) : (
          <Link onClick={() => setShowMessageBox(true)} underline={false} adornment={<PlusSmallIcon size='smaller' />}>
            {tMain('inquiry.messageLabel')}
          </Link>
        )}
        {!!selectedRentalOption && isRoommatable && (
          <>
            <Divider />
            <KeyValuePair
              KeyComponent={PBold}
              ValueComponent={P}
              keyName={tMain('inquiry.roommate.securityDeposit')}
              value={money.fromPrice(selectedRentalOption.price).toString(true)}
            />
            <KeyValuePair
              KeyComponent={PBold}
              ValueComponent={P}
              keyName={tMain('inquiry.roommate.wifi')}
              value={tMain('inquiry.roommate.wifiPriceRange', {
                low: money.fromAmount(priceRange.low, currency),
                high: money.fromAmount(priceRange.high, currency),
              })}
            />
            <KeyValuePair
              KeyComponent={PBold}
              ValueComponent={P}
              keyName={tMain('inquiry.roommate.fee')}
              value={money.fromAmount(membershipFee, currency).toString(true)}
            />
          </>
        )}
      </ColumnGridLayout>
    </div>
  )
}
