import { Role } from '@guiker/permissions'

import { BookingActions } from '../booking-actions'
import { BookingRoleNames } from '../role-names'

export const ApplicantRole: Role = {
  name: BookingRoleNames.APPLICANT,
  permissions: [
    { action: BookingActions.ParticipateChat },
    { action: BookingActions.InviteApplicant },
    { action: BookingActions.DisinviteApplicant },
  ],
}
