import { idNestedParamsValidator, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { PropertySaleInquiry, PropertySaleListing } from '../../entity'
import { paths } from './paths'
import {
  createPropertySaleInquirySchema,
  searchListingQuerySchema,
  SearchListingSchema,
  searchListingSchema,
} from './schemas'

const oneListingPath = paths.listing.all.withId()

export const routes = {
  readOneListing: {
    path: oneListingPath,
    method: HttpMethod.GET,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleListing),
  },
  readAllListing: {
    path: paths.listing.all.base(),
    method: HttpMethod.POST,
    queryParamsValidator: (queryParams: unknown) => searchListingQuerySchema.validate(queryParams),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<PropertySaleListing, {}>(),
    payloadValidator: validatorBuilder.buildPayloadValidator<SearchListingSchema>(searchListingSchema),
  },
  createPropertySaleInquiry: {
    path: paths.listingInquiry.all.base(),
    method: HttpMethod.POST,
    authenticated: false,
    payloadValidator: (payload: unknown) => createPropertySaleInquirySchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleInquiry),
  },
}
