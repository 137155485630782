import React, { useEffect, useState } from 'react'

import { User } from '@guiker/authentication-shared'
import { DataTrackingCTA, useDataTrackingContext } from '@guiker/data-tracking-context'
import { useMutation } from '@guiker/react-query'

import { AuthType } from '../../auth-type'
import { Authenticator, useAuthenticationContext, usePublicAuthenticationApiClient } from '../../authentication'
import { ButtonWithLoader, Flex, makeStyles, SecondaryButton, theme, useMediaQuery } from '../../components'
import { useTranslation } from '../../i18n'
import { config } from './config'
import { FacebookLogo } from './FacebookLogo'
import { loadScript } from './load-scripts'

const useStyles = makeStyles({
  button: {
    fontSize: 14,
    paddingLeft: 12,
    justifyContent: 'flex-start',
  },
})

interface Props {
  authenticator: Authenticator
  initialized: boolean
  authType: AuthType
}

export const LogInButtonFacebook: React.FC<Props> = ({ authenticator, initialized, authType }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const apiClient = usePublicAuthenticationApiClient()
  const [loaded, setLoaded] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const { sendEvent } = useDataTrackingContext()
  const { user, setUser } = useAuthenticationContext()
  const { t } = useTranslation('authentication')

  const { mutate: handleLogIn } = useMutation(
    (...args: Parameters<typeof apiClient.socialLogIn>) => apiClient.socialLogIn(...args),
    {
      onSuccess: (res: User) => {
        sendEvent({ event: authType, userId: res.id.toString() })
        setUser(res, { type: authType })
      },
    },
  )

  useEffect(() => {
    const { provider, scriptSrc, clientId: appId } = config.facebook

    loadScript(provider, scriptSrc, () => {
      ;(window as any).fbAsyncInit = () => FB.init({ appId, version: 'v6.0' })
      setLoaded(true)
    })
  }, [])

  const onClick = () => {
    if (loaded && !isProcessing) {
      setIsProcessing(true)

      FB.login(
        ({ status, authResponse }) => {
          if (status === 'connected') {
            handleLogIn({
              payload: {
                authenticatorId: authenticator.id,
                password: authResponse.accessToken,
                invitationToken: user?.invitationToken,
              },
            })
          }
          setIsProcessing(false)
        },
        { scope: 'email' },
      )
    }
  }

  return (
    <DataTrackingCTA key={'FacebookLogIn'}>
      <ButtonWithLoader
        className={classes.button}
        fullWidth={true}
        isLoading={isProcessing}
        disabled={!initialized || isProcessing}
        onClick={onClick}
        buttonComponent={SecondaryButton}
        startIcon={<FacebookLogo />}
      >
        <Flex justifyContent='center' fullWidth>
          {t(`${authType}.social.facebook`)}
        </Flex>
      </ButtonWithLoader>
    </DataTrackingCTA>
  )
}
