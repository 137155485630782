import { generateEventsDefinition } from '@guiker/shared-framework'

import { Payer, RentPaymentsPlan, TenantInstalment, TenantInstalmentEventHistory } from '../../entity'
import { baseEvent as domainBaseEvent } from '../base'

type ReminderEventData = {
  amount: number
  rentPaymentsPlan: RentPaymentsPlan
  payInMethod: Payer['payInMethod']
}

export const EventsDefinition = generateEventsDefinition({
  ...domainBaseEvent,
  entity: 'TENANT_INSTALMENT',
  data: null as unknown as TenantInstalment,
  eventTypes: {
    TRANSACTION_EVENT_ADDED: {
      type: 'TRANSACTION_EVENT_ADDED',
      eventData: null as { event: TenantInstalmentEventHistory },
    },
    TRANSACTION_FAILED_EVENT_ADDED: {
      type: 'TRANSACTION_EVENT_ADDED',
      eventData: null as {
        payer: Payer
        transactionEvent: TenantInstalmentEventHistory
        rentPaymentsPlan: RentPaymentsPlan
      },
    },
    INSTALMENTS_ADJUSTED: 'INSTALMENTS_ADJUSTED',
    PROCESSED_INSTALMENTS_CANCELLED: {
      type: 'PROCESSED_INSTALMENTS_CANCELLED',
      eventData: null as {
        cancelledTenantInstalments: TenantInstalment[]
      },
    },
    RENT_CHARGE_REMINDER_SET: {
      type: 'RENT_CHARGE_REMINDER_SET',
      eventData: null as ReminderEventData,
    },
    INVALID_VERIFICATION_TOKEN_REMINDER_SET: {
      type: 'INVALID_VERIFICATION_TOKEN_REMINDER_SET',
      eventData: null as ReminderEventData,
    },
    OFFLINE_PAY_IN_METHOD_REMINDER_SET: {
      type: 'INVALID_VERIFICATION_TOKEN_REMINDER_SET',
      eventData: null as ReminderEventData,
    },
  } as const,
})
export const Events = EventsDefinition.events
