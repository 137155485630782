import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'

import { useAuthenticatedTenantApplicationApiClient } from '../hooks'
import { TenantApplicationContextProvider } from './TenantApplicationContext'

type Props = React.PropsWithChildren & { forceRender?: boolean }

export const AuthTenantApplicationContextProvider: React.FC<Props> = ({ children, forceRender }) => {
  const apiClient = useAuthenticatedTenantApplicationApiClient()
  const { user } = useAuthenticationContext()

  return (
    <TenantApplicationContextProvider
      queryDependency={user?.id}
      readOneTenantApplication={apiClient.readOneTenantApplication}
      forceRender={forceRender}
    >
      {children}
    </TenantApplicationContextProvider>
  )
}
