import { TypeOf, yup } from '@guiker/yup-util'

export const ssnAndConsentBackgroundCheckPayloadValidator = yup.object({
  consent: yup.boolean().required(),
  ssn: yup.string().when('consent', {
    is: true,
    then: yup
      .string()
      .required()
      .test('invalidSSN', 'must be 9 digits', (value: string) => {
        const parsed = value.split('-').join('')
        if (parsed.length < 9) return false
        return true
      }),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

export type SsnAndConsentBackgroundCheckPayload = TypeOf<typeof ssnAndConsentBackgroundCheckPayloadValidator>
