import React, { useState } from 'react'

import { Booking, UnitManager as UnitManagerInterface } from '@guiker/booking-shared'

import { ParticipantBox } from '../ParticipantBox'
import { UnitManagerViewModal } from './UnitManagerViewModal'

type UnitManagerProps = {
  unitManager: UnitManagerInterface
  booking: Booking
  hasModal: boolean
}

const UnitManager: React.FC<UnitManagerProps> = ({ booking, hasModal, unitManager }) => {
  const [viewModalOpen, setViewModalOpen] = useState(false)

  return (
    <>
      <ParticipantBox
        participant={unitManager}
        booking={booking}
        showStepStatus={false}
        onClick={() => hasModal && setViewModalOpen(true)}
      />

      <UnitManagerViewModal open={viewModalOpen} setOpen={setViewModalOpen} {...unitManager} />
    </>
  )
}

export { UnitManager }
