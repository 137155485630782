import { CurrencyISO, Payment, yup } from '@guiker/shared-framework'

import { InvoicePayoutMethod, PayoutMethodStatus } from '../../../../entity'
import { payoutProviderSchema } from './base-schemas'

export const verifyOneInvoicePayloadSchema = yup
  .object<{
    payInMethod: { id: string; type: Payment.PayInMethodType }
    payoutMethod: InvoicePayoutMethod
  }>({
    payInMethod: yup
      .object({
        id: yup.string().required(),
        type: yup.string().oneOf(Object.values(Payment.PayInMethodType)).required(),
      })
      .required(),
    payoutMethod: yup
      .object<InvoicePayoutMethod>({
        id: yup.string().required(),
        userId: yup.string().required(),
        currency: yup.mixed().oneOf(Object.values(CurrencyISO)).required(),
        status: yup.mixed().oneOf(Object.values(PayoutMethodStatus)),
        provider: yup.array().of(payoutProviderSchema).required(),
        type: yup.mixed().oneOf(Object.values(Payment.SupportedPayoutMethodType)),
        accountHolderType: yup.mixed().oneOf(Object.values(Payment.SupportedAccountHolderType)),
      })
      .required(),
  })
  .required()
