import React, { createContext, useCallback, useMemo, useState } from 'react'

import { generateUseContext } from '@guiker/react-framework'

import { AssetFile, AssetGroup } from '../entity'

export type AssetStorageContextProviderProps = React.PropsWithChildren & {
  assetGroups?: AssetGroup[]
}

export type AssetGroupStorage = {
  addAsset: (asset: AssetFile) => void
  removeAsset: (assetIndex: number) => AssetFile
  updateAssets: (assets: AssetFile[]) => void
  type: string
  assets: Partial<AssetFile>[]
}

type AssetStorageContext = {
  assetGroups: AssetGroup[]
  setAssetGroups: (assetGroups: AssetGroup[]) => unknown
  deletedAssets: AssetFile[]
  getOneAssetGroupByName: (groupName: string) => AssetGroupStorage
}

const AssetStorageContext = createContext<AssetStorageContext>(null)

export const AssetStorageContextProvider: React.FC<AssetStorageContextProviderProps> = ({
  children,
  assetGroups: initialAssetGroups = [],
}) => {
  const [assetGroups, setAssetGroups] = useState<AssetGroup[]>(initialAssetGroups)
  const [deletedAssets, setDeletedAssets] = useState<AssetFile[]>([])
  const addDeletedAsset = (deletedAsset: AssetFile) => setDeletedAssets((curr) => [...curr, deletedAsset])

  const getOneAssetGroupByName = useCallback(
    (groupName: string) => {
      const index = assetGroups.findIndex((group) => group.type === groupName)

      if (index === -1) {
        setAssetGroups((assetGroups) => {
          assetGroups[assetGroups.length] = {
            type: groupName,
            assets: [],
          }

          return [...assetGroups]
        })

        return
      }

      const updateAssets = (assets: AssetFile[]) => {
        setAssetGroups((assetGroups) => {
          assetGroups[index] = {
            ...assetGroups[index],
            assets,
          }
          return [...assetGroups]
        })
      }

      const addAsset = (asset: AssetFile) => {
        setAssetGroups((assetGroups) => {
          assetGroups[index] = {
            ...assetGroups[index],
            assets: [...assetGroups[index].assets, asset],
          }
          return [...assetGroups]
        })
      }

      const removeAsset = (assetIndex: number) => {
        const deletedAsset = assetGroups[index].assets[assetIndex]

        setAssetGroups((assetGroups) => {
          const updatedAssets = [...assetGroups[index].assets]
          updatedAssets.splice(assetIndex, 1)
          addDeletedAsset(deletedAsset)

          assetGroups[index] = {
            ...assetGroups[index],
            assets: updatedAssets,
          }

          return [...assetGroups]
        })

        return deletedAsset
      }

      return {
        ...assetGroups[index],
        addAsset,
        removeAsset,
        updateAssets,
      }
    },
    [assetGroups],
  )

  const value = {
    assetGroups,
    setAssetGroups,
    deletedAssets,
    getOneAssetGroupByName,
  }

  return <AssetStorageContext.Provider value={value}>{children}</AssetStorageContext.Provider>
}

export const useAssetStorageContext = generateUseContext(AssetStorageContext)

export const useAssetStorage = ({ groupName }: { groupName: string }) => {
  const { getOneAssetGroupByName } = useAssetStorageContext()

  return useMemo(() => getOneAssetGroupByName(groupName), [groupName])
}
