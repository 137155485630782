import React from 'react'

import { useConfig } from '@guiker/config-context'
import { SupportedCountries } from '@guiker/payment-shared'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const ElementsComponent = Elements as React.FC<React.PropsWithChildren & Parameters<typeof Elements>[0]>

const StripeProvider: React.FC<React.PropsWithChildren & { region: SupportedCountries }> = ({ region, children }) => {
  const { stripe: config } = useConfig()
  const stripePromise = loadStripe(config[region].publishableKey)
  const options = {
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Inter:300,400,500,700&display=swap' }],
  }

  return (
    <ElementsComponent stripe={stripePromise} options={options}>
      {children}
    </ElementsComponent>
  )
}

export { Elements, StripeProvider }
