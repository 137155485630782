import React from 'react'

import { AppContextProvider } from './AppContextProvider'
import { AppMetaTags, CssBaseline, ErrorBoundary } from './components'
import { SpoofInComponent } from './components/SpoofInModal/SpoofInModal'
import { Router } from './routes'

const App: React.FC = () => (
  <AppContextProvider>
    <CssBaseline />
    <ErrorBoundary>
      <AppMetaTags />
      <React.Suspense>
        <SpoofInComponent />
        <Router />
      </React.Suspense>
    </ErrorBoundary>
  </AppContextProvider>
)

export { App }
