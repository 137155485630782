import React from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  Display3,
  Flex,
  makeStyles,
  PBig,
  TwoColumnsGridLayout,
  useLayoutContext,
  useStaticAssetContext,
} from '@guiker/react-framework'

type InvestorServiceProps = {
  orientation?: 'reverse' | 'default'
  serviceKey: 'service1' | 'service2' | 'service3'
}

const useStyles = makeStyles(
  {
    image: {
      maxWidth: '100%',
    },
  },
  { name: 'InvestorService' },
)

const InvestorService: React.FC<InvestorServiceProps> = ({ orientation, serviceKey }) => {
  const { t } = useTranslation(['webapp', 'common'])
  const { getAsset } = useStaticAssetContext()
  const { isTablette } = useLayoutContext()
  const assets = getAsset('investors')
  const classes = useStyles()
  const asset = assets.services[serviceKey]

  const TextComponent = (
    <div>
      <Display3>{t(`webapp:investors.services.${serviceKey}.title`)}</Display3>
      <PBig>{t(`webapp:investors.services.${serviceKey}.description`)}</PBig>
    </div>
  )

  const ImageComponent = (
    <Flex justifyContent='center'>
      <img src={asset.url} width={asset.width} className={classes.image} />
    </Flex>
  )

  return (
    <TwoColumnsGridLayout gap={10} xs={{ gap: 4 }} sm={{ gap: 4, gridTemplateColumns: 1 }}>
      {orientation === 'default' || isTablette ? (
        <>
          {TextComponent}
          {ImageComponent}
        </>
      ) : (
        <>
          {ImageComponent}
          {TextComponent}
        </>
      )}
    </TwoColumnsGridLayout>
  )
}

export { InvestorService }
