import React, { useState } from 'react'

import { useAdminAuthenticationApiClient, useAuthenticationContext } from '@guiker/authentication-context'
import { LegacyAppApiClient, parseLegacyUser, User } from '@guiker/authentication-shared'
import { Button, Flex, Modal, SecondaryButton, TextField, Typography } from '@guiker/components-library'
import { useConfig } from '@guiker/config-context'
import { useMutation } from '@guiker/react-query'

import { useKeyPress } from '../../hooks/use-key-press'
import { useSpoofInContext } from './use-spoof-in-context'

const shortcuts = ['Control', 'Alt', 'Meta']

export const useAuthenticationAppApiClient = () => {
  const { legacyApiUrl: baseUrl } = useConfig()
  return LegacyAppApiClient(baseUrl, console)
}

const canSpoof = (user: User) => {
  return user?.appRoles?.some((user) => ['Can Spoof', 'SUPER_ADMINISTRATOR'].includes(user.appRoleName))
}

type SpoofInModalProps = {
  userPerformingAction: User
  isOpen: boolean
  onClose: () => unknown
}

const SpoofInModal: React.FC<SpoofInModalProps> = ({ isOpen, userPerformingAction, onClose: _onClose }) => {
  const { user, setUser } = useAuthenticationContext()
  const { setOriginalUser, originalUser } = useSpoofInContext()
  const [searchString, setSearchString] = useState<string>()

  const legacyApiClient = useAuthenticationAppApiClient()
  const apiClient = useAdminAuthenticationApiClient(userPerformingAction?.accessToken)

  const {
    mutate: search,
    data: searchResult,
    reset,
  } = useMutation(async () => {
    const result = await legacyApiClient.fetchUsersByEmail({
      accessToken: userPerformingAction.accessToken,
      email: searchString,
    })

    return result.map(parseLegacyUser)
  })

  const onClose = () => {
    _onClose()
    reset()
    setSearchString('')
  }

  const { mutate: spoofIn } = useMutation(
    async ({ userId }: { userId: string }) => apiClient.spoofIn({ pathParams: { id: userId } }),
    {
      onSuccess: (spoofedInUser: User) => {
        setOriginalUser({
          originalUser: originalUser ?? userPerformingAction,
          user: spoofedInUser,
        })
        onClose()
      },
    },
  )

  const spoofOut = () => {
    setUser(originalUser)
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onClose} title='Spoof in'>
      <Flex flexDirection='column' gap={2}>
        {originalUser && originalUser?.id !== user?.id && (
          <Flex flexDirection='column' gap={1}>
            <Typography mb={0}>{`Original user: ${originalUser.emailAddress}`}</Typography>
            <Typography mb={0}>{`Currently logged in as: ${user.emailAddress}`}</Typography>
            <Button fullWidth onClick={spoofOut}>
              Spouf out
            </Button>
          </Flex>
        )}
        <Flex gap={2}>
          <TextField
            fullWidth
            value={searchString}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setSearchString(event.target.value)
            }
          />
          <Button onClick={search}>Search</Button>
        </Flex>
        {searchResult?.map((u) => (
          <Flex gap={2} key={u.id} justifyContent='space-between' alignItems='center'>
            <Typography mb={0}>{`${u.emailAddress} - ${[u.firstName, u.lastName]
              .filter((e) => !!e)
              .join(' ')}`}</Typography>
            <SecondaryButton size='small' onClick={() => spoofIn({ userId: u.id })}>
              Spoof
            </SecondaryButton>
          </Flex>
        ))}
      </Flex>
    </Modal>
  )
}

const SpoofInKeyPressGattedComponent: React.FC<{
  userPerformingAction: User
}> = ({ userPerformingAction }) => {
  const { spoof } = useSpoofInContext()
  useKeyPress(shortcuts, () => spoof.setIsOpen(true))

  const onClose = () => spoof.setIsOpen(false)

  return <SpoofInModal isOpen={spoof.isOpen} onClose={onClose} userPerformingAction={userPerformingAction} />
}

const SpoofInComponent: React.FC = () => {
  const { user } = useAuthenticationContext()
  const { originalUser } = useSpoofInContext()

  const userPerformingAction = originalUser || user

  if (!userPerformingAction || !canSpoof(userPerformingAction)) {
    return
  }

  return <SpoofInKeyPressGattedComponent userPerformingAction={userPerformingAction} />
}

export { SpoofInComponent, canSpoof }
