import { yup } from '@guiker/yup-util'

import { ChannelType, PreferenceType } from '../../entity'

const preferenceTypeSchema = yup.object<PreferenceType>({
  context: yup.string().required(),
  entity: yup.string().required(),
})

export const updateNotificationPreference = yup.object({
  preferenceType: preferenceTypeSchema,
  channelType: yup.mixed<ChannelType>().oneOf(Object.values(ChannelType)).required(),
  isEnabled: yup.boolean().required(),
})
