import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const rent = yup.object<Omit<Ontario.Rent, 'totalRentAmount' | 'completed'>>({
  firstPaymentDate: yup.string().nullable(),
  paymentDate: yup.string().required(),
  rentFrequency: yup.mixed<Ontario.RentFrequency>().oneOf(Object.values(Ontario.RentFrequency)).required(),
  rentAmount: yup.number().required(),
  paymentMethod: yup.string().required(),
  rentFrequencyOther: yup.string(),
  parkingAmount: yup.number().nullable(),
  rentOtherAmounts: yup
    .array()
    .max(3)
    .of(
      yup.object({
        otherAmount: yup.number().required(),
        otherDescription: yup.string().required(),
      }),
    )
    .default([]),
  rentPayableTo: yup.string().required(),
  partialPaymentAmount: yup.number().nullable(),
  partialRentCoversFromDate: yup.string().nullable(),
  partialRentCoversToDate: yup.string().nullable(),
  nsfChargeAmount: yup.number().nullable(),
})

export type Rent = TypeOf<typeof rent>
