import React from 'react'

import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { Box, CurrencyTextField, Inline, NumberFormat, numberFormatter, TextField } from '@guiker/react-framework'

import { useInvestmentAssumptionContext } from '../../../hooks'
import {
  ExpenseConfiguration,
  ExpenseConfigurationFlatValue,
  ExpenseConfigurationPercentageValue,
} from '../../../utils'

type FlatExpenseProps = {
  name: string
  defaultValue?: InvestmentAssumptions.FlatValue
  expenseValue: ExpenseConfigurationFlatValue
  readOnly: boolean
}

type PercentageExpenseProps = {
  name: string
  defaultValue?: InvestmentAssumptions.PercentageValue
  expenseValue: ExpenseConfigurationPercentageValue
  readOnly: boolean
}

type Props = {
  name: string
  defaultValue?:
    | InvestmentAssumptions.PercentageValue
    | InvestmentAssumptions.FlatValue
    | InvestmentAssumptions.BracketValue
  expenseValue: ExpenseConfiguration['value']
  readOnly?: boolean
}

const FlatExpense: React.FC<FlatExpenseProps> = ({ name, defaultValue, expenseValue }) => {
  const { isEditable = true } = expenseValue
  const { currency } = useInvestmentAssumptionContext()

  return (
    <>
      <Box display='none'>
        <TextField type='hidden' name={`${name}.type`} defaultValue={'flat'} />
        <TextField type='hidden' name={`${name}.amount.currency`} defaultValue={currency.iso} />
      </Box>
      <CurrencyTextField
        name={`${name}.amount.value`}
        defaultValue={defaultValue?.amount?.value}
        currency={currency}
        allowNegative={false}
        disabled={!isEditable}
        required
      />
    </>
  )
}

const PercentageExpense: React.FC<PercentageExpenseProps> = ({ name, defaultValue, expenseValue }) => {
  const { isEditable = true } = expenseValue

  return (
    <>
      <Box display='none'>
        <TextField type='hidden' name={`${name}.type`} defaultValue={'percentage'} />
        <TextField type='hidden' name={`${name}.base`} defaultValue={expenseValue.base} />
      </Box>
      <NumberFormat
        name={`${name}.rate`}
        defaultValue={defaultValue?.rate}
        endAdornment={<Inline>%</Inline>}
        decimalScale={2}
        allowNegative={false}
        formatter={numberFormatter.percentage(4)}
        disabled={!isEditable}
        required
      />
    </>
  )
}

export const ExpenseValue: React.FC<Props> = ({ name, defaultValue, expenseValue, readOnly = false }) => {
  return expenseValue.type === 'flat' ? (
    <FlatExpense {...{ name, expenseValue, readOnly }} defaultValue={defaultValue as InvestmentAssumptions.FlatValue} />
  ) : expenseValue.type === 'percentage' ? (
    <PercentageExpense
      {...{ name, expenseValue, readOnly }}
      defaultValue={defaultValue as InvestmentAssumptions.PercentageValue}
    />
  ) : (
    <div>{expenseValue.type}</div>
  )
}
