import { TypeOf, yup } from '@guiker/shared-framework'

import { PayoutMethodStatus } from '../../../entity'

export const updateReviewPayloadSchema = yup.object({
  status: yup
    .mixed<PayoutMethodStatus>()
    .required()
    .oneOf([PayoutMethodStatus.SUBMITTED, PayoutMethodStatus.CHANGE_REQUESTED]),
  comment: yup.string().when('status', {
    is: PayoutMethodStatus.CHANGE_REQUESTED,
    then: yup.string().required(),
    else: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

export type UpdateReviewPayloadSchema = TypeOf<typeof updateReviewPayloadSchema>
