import { DeepPartial } from '@guiker/ts-utils'

import { Invoice, Item, ScopeType } from '../invoice'

export const computeItemAmount = (item: DeepPartial<Item>) => {
  const totalAmount = item.quantity * item.pricePerUnit

  const taxes = (item.taxes || []).reduce((acc, tax) => {
    const taxRates = tax.rates.reduce((totalRates, taxRate) => (totalRates += taxRate.amount), 0)
    return acc + taxRates
  }, 0)

  return totalAmount + Math.round(taxes)
}

export const computeInvoiceTotalAmount = (invoice: DeepPartial<Invoice>) => {
  return invoice.items.reduce((totalAmount, item) => totalAmount + computeItemAmount(item), 0)
}

export const computeInvoiceFees = (invoice: DeepPartial<Invoice>) => {
  if (!invoice.receiver?.details?.feeAmount) return 0

  const feeAmount = invoice.receiver.details.feeAmount
  const taxes = invoice.receiver.details.taxBreakdown.reduce((acc, tax) => {
    return acc + tax.amount
  }, 0)

  return Math.round(feeAmount + taxes)
}

export const findInvoiceLabel = (invoice: DeepPartial<Invoice>) => {
  if (!invoice.scope) return (invoice as Invoice<undefined>).metadata?.reason
  switch (invoice.scope.type) {
    case ScopeType.LEGACY:
    case ScopeType.FUNDING_ACCOUNT_DEPOSIT:
    case ScopeType.BUY_STOCK_ORDER:
      return invoice.items[0].label
    case ScopeType.TENANT_INSTALMENT:
      return `${invoice.items[0].label} - ${invoice.items[0].description}`
  }
}
