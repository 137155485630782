import { TypeOf, yup } from '@guiker/yup-util'

import { TenantApplicationSupportingDocuments } from '../../../entity'
import { supportingDocumentSchema } from '../../schemas'

export const updateTenantApplicationSupportingDocumentsPayloadValidator =
  yup.object<TenantApplicationSupportingDocuments>({
    supportingDocuments: yup.array().of(supportingDocumentSchema).required(),
  })

export type UpdateTenantApplicationSupportingDocumentsPayload = TypeOf<
  typeof updateTenantApplicationSupportingDocumentsPayloadValidator
>
