import { TypeOf, yup } from '@guiker/yup-util'

import { addressSchemaWithNeighborhood, buildingAmenitiesSchema, pictureSchema } from '../../schemas'

export const updateListingPayloadSchema = yup.object({
  address: addressSchemaWithNeighborhood,
  description: yup.string().required(),
  buildingAmenities: buildingAmenitiesSchema.required(),
  pictures: yup.array().of(pictureSchema),
})

export type UpdateListingPayloadSchema = TypeOf<typeof updateListingPayloadSchema>
