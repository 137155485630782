import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { Applicant, ApplicationStatus, Booking, ForIntroEmail, RoommateIntroTenant } from '../../entity'
import { BaseBookingEvent } from '../base-event'

type BaseApplicantsEventData = {
  applicants: {
    userId: string
    application: {
      status: ApplicationStatus
      content: TenantApplication
    }
  }[]
}

type ApplicantsEventData = {
  applicants: Applicant[]
}

export const definition = generateEventsDefinition({
  ...BaseBookingEvent,
  entity: 'APPLICANT',
  data: null as unknown as Booking,
  eventTypes: {
    APPLICATION_ACCEPTED: {
      type: 'APPLICATION_ACCEPTED',
      eventData: null as ApplicantsEventData,
    },
    APPLICATION_COMPLETED: {
      type: 'APPLICATION_COMPLETED',
      eventData: null as ApplicantsEventData,
    },
    APPLICATION_REJECTED: {
      type: 'APPLICATION_REJECTED',
      eventData: null as ApplicantsEventData,
    },
    APPLICATION_CHANGE_REQUESTED: {
      type: 'APPLICATION_CHANGE_REQUESTED',
      eventData: null as ApplicantsEventData,
    },
    APPLICATION_STARTED: {
      type: 'APPLICATION_STARTED',
      eventData: null as ApplicantsEventData,
    },
    BACKGROUND_CHECK_RESULT_UPDATED: {
      type: 'BACKGROUND_CHECK_RESULT_UPDATED',
      eventData: null as ApplicantsEventData,
    },
    APPLICATION_CHANGE_SUBMITTED: {
      type: 'APPLICATION_CHANGE_SUBMITTED',
      eventData: null as BaseApplicantsEventData,
    },
    APPLICATION_SUBMITTED: {
      type: 'APPLICATION_SUBMITTED',
      eventData: null as BaseApplicantsEventData,
    },
    APPLICATION_UPDATED: {
      type: 'APPLICATION_UPDATED',
      eventData: null as BaseApplicantsEventData,
    },
    ROOMMATE_PROFILE_UPDATED: {
      type: 'ROOMMATE_PROFILE_UPDATED',
      eventData: null as BaseApplicantsEventData,
    },
    ROOMMATE_PROFILE_SUBMITTED: {
      type: 'ROOMMATE_PROFILE_SUBMITTED',
      eventData: null as BaseApplicantsEventData,
    },
    ROOMMATE_INTRO_APPROVED: {
      type: 'ROOMMATE_INTRO_APPROVED',
      eventData: null as ApplicantsEventData,
    },
    ROOMMATE_INTRO_STARTED: {
      type: 'ROOMMATE_INTRO_STARTED',
      eventData: null as {
        applicantUserId: string
        tenants: ForIntroEmail<RoommateIntroTenant>[]
      },
    },
    ROOMMATE_INTRO_TENANT_APPROVED: {
      type: 'ROOMMATE_INTRO_TENANT_APPROVED',
      eventData: null as {
        applicant: Applicant
        tenant: RoommateIntroTenant
      },
    },
    ROOMMATE_INTRO_TENANT_REJECTED: {
      type: 'ROOMMATE_INTRO_TENANT_REJECTED',
      eventData: null as {
        applicant: Applicant
        tenant: RoommateIntroTenant
      },
    },
  } as const,
})
export const { events: Events, eventTypes: EventTypes } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
