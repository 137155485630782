import React from 'react'

import { useTranslation } from '@guiker/i18n'
import {
  Display2,
  Display3,
  Flex,
  isAtMostTablette,
  makeStyles,
  margin,
  P,
  PageLayout,
  PageMetaTags,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { FadingCard, PackagesSection, ServicesHeroSection, TestimonialSection } from './components'
import { ServicesScreenContextProvider } from './context'

const useStyle = makeStyles(
  {
    headline: {
      margin: margin(15, 2, 20, 2),
      maxWidth: 1060,
      whiteSpace: 'pre-line',
      textAlign: 'center',
      [isAtMostTablette]: {
        textAlign: 'left',
      },
    },
  },
  {
    name: 'services',
  },
)

export const Services: React.FC = () => {
  const { t } = useTranslation(['webapp', 'common'])
  const { getAsset } = useStaticAssetContext()
  const assets = getAsset('services')
  const classes = useStyle()

  return (
    <>
      <PageMetaTags subtitle={t('webapp:services.seo.subtitle')} description={t('webapp:services.seo.description')} />
      <ServicesScreenContextProvider>
        <PageLayout noPaddingBottom noPaddingTop>
          <ServicesHeroSection />
          <Flex flexDirection='column' alignItems='center'>
            <Display2 className={classes.headline}>{t('webapp:services.hero.subheader')}</Display2>
            <FadingCard
              asset={assets.feature1}
              moreContent={
                <P breakline mb={2}>
                  {t('services.feature1.moreContent')}
                </P>
              }
              header={<Display3 mb={2}>{t('services.feature1.header')}</Display3>}
              cta={t('services.feature1.cta')}
            >
              <P mb={2}>{t('services.feature1.content')}</P>
            </FadingCard>
            <FadingCard
              asset={assets.feature2}
              direction='right'
              header={<Display3 mb={2}>{t('services.feature2.header')}</Display3>}
              moreContent={
                <P breakline mb={2}>
                  {t('services.feature2.moreContent')}
                </P>
              }
              cta={t('services.feature2.cta')}
            >
              <P mb={2}>{t('services.feature2.content')}</P>
            </FadingCard>
            <FadingCard
              asset={assets.feature3}
              cta={t('services.feature3.cta')}
              header={<Display3 mb={2}>{t('services.feature3.header')}</Display3>}
              moreContent={
                <P mb={2} breakline>
                  {t('services.feature3.moreContent')}
                </P>
              }
            >
              <P mb={2}>{t('services.feature3.content')}</P>
            </FadingCard>
            <TestimonialSection />
            <PackagesSection />
          </Flex>
        </PageLayout>
      </ServicesScreenContextProvider>
    </>
  )
}
