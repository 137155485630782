import { yup } from '@guiker/shared-framework'

import { MultipostListingInquirySource } from '../../../entity/multipost-listing-inquiry'

export const multipostInquiryPerformanceStatsPayloadSchema = yup.object<{
  listingIds: string[]
  source: MultipostListingInquirySource
  days?: number
}>({
  listingIds: yup.array().of(yup.string()).min(1).required(),
  source: yup.string().oneOf(Object.values(MultipostListingInquirySource)).required(),
  days: yup.number().optional().default(7),
})
