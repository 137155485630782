import { routesBuilder } from '@guiker/shared-framework'

import { PayoutMethod, PayoutMethodWithPayouts, TermsAndConditions } from '../../../../entity'
import { paths } from '../../paths'
import {
  consentSchema,
  updateAccountHolderSchema,
  updateAccountHolderTypeSchema,
  updateBankInformationInvestmentSchema,
  updateBankInformationSchema,
  updateCapabiltiesSchema,
  updateTypeSchema,
  updateVerificationDocumentsSchema,
} from '../../schemas'
import { routes as nestedRoutes } from './payout-providers'

export const path = paths.payoutMethod.all.withId(':payoutMethodId')

const { buildGet, buildPut, buildDelete } = routesBuilder<PayoutMethod>()({
  basePath: path,
})

export const routes = {
  ...nestedRoutes,
  readOnePayoutMethod: buildGet({
    response: null as PayoutMethodWithPayouts,
  }),
  revealPayoutMethodSSN: buildGet({
    path: '/ssn',
    response: null as string,
  }),
  updatePayoutMethodType: buildPut({
    path: '/type',
    payloadSchema: updateTypeSchema,
  }),
  updatePayoutMethodAccountHolderType: buildPut({
    path: '/account-holder-type',
    payloadSchema: updateAccountHolderTypeSchema,
  }),
  updatePayoutMethodAccountHolder: buildPut({
    path: '/account-holder',
    payloadSchema: updateAccountHolderSchema,
  }),
  updatePayoutMethodCapabilities: buildPut({
    path: '/capabilities',
    payloadSchema: updateCapabiltiesSchema,
  }),
  updatePayoutMethodConsent: buildPut({
    path: '/consent',
    payloadSchema: consentSchema,
  }),
  updatePayoutBankInformation: buildPut({
    path: '/bank-information',
    payloadSchema: updateBankInformationSchema,
  }),
  updatePayoutBankInformationAndSubmit: buildPut({
    path: '/bank-information-investment',
    payloadSchema: updateBankInformationInvestmentSchema,
  }),
  updatePayoutVerificationDocuments: buildPut({
    path: '/verification-documents',
    payloadSchema: updateVerificationDocumentsSchema,
  }),
  updatePayoutInvestmentVerificationDocuments: buildPut({
    path: '/verification-documents/investment',
    payloadSchema: updateVerificationDocumentsSchema,
  }),
  readAllPayoutMethodTermsAndConditions: buildGet({
    path: '/terms-and-conditions',
    responseIsArray: true,
    response: null as TermsAndConditions,
  }),
  deletePayoutMethod: buildDelete({}),
} as const
