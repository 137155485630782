import { yup } from '@guiker/yup-util'

export const adminEmailsSchema = yup
  .object({
    customerSuccessEmail: yup.string().required(),
    mlsTorontoManagerEmail: yup.string().required(),
    mlsChicagoManagerEmail: yup.string().required(),
    salesSupportEmail: yup.string().required(),
    rentalsEmail: yup.string().required(),
    leasingSupportEmail: yup.string().required(),
    investEmail: yup.string().required(),
  })
  .required()
