import { routesBuilder } from '@guiker/shared-framework'

import { TermsAndConditions } from '../../../../../entity'
import { path as parentPath } from '../routes'

export const path = `${parentPath}/payout-providers/:payoutProviderName` as const

const { buildGet } = routesBuilder<TermsAndConditions>()({
  basePath: `${path}/payout-providers/:payoutProviderName`,
})

export const routes = {
  readPayoutProviderTermsAndConditions: buildGet({
    path: '/:payoutProviderAccountId/terms-and-conditions',
  }),
} as const
