import { CountryCode } from '@guiker/background-check-shared'
import { yup } from '@guiker/yup-util'

import { Consent, LegalAddress } from '../../entity'
import { legalAddressSchema } from './legal-address'

export const ssnValidator = (consent = false, country: string, schema: yup.StringSchema) => {
  return consent && country === CountryCode.US
    ? schema
        .test('missingSSN', 'SSN is missing', (value: string) => !!value)
        .test('invalidSSN', 'SSN must be 9 digits', (value: string) => {
          const parsed = value?.split('-').join('')
          return parsed.length === 9 && /^[0-9]+$/.test(parsed)
        })
        .transform((value) => value?.split('-').join(''))
        .required()
    : schema
}

type ConsentSchema = Pick<Consent, 'isConsentGiven'>
const consentSchema = yup.object<ConsentSchema>({
  isConsentGiven: yup.boolean(),
})

export const tenantApplicationBackgroundCheckSchema = yup.object({
  consent: yup.object<ConsentSchema>({
    isConsentGiven: yup.boolean().test('missingConsent', 'Consent is missing', (value: boolean) => !!value),
  }),
  legalAddress: legalAddressSchema.nullable().default(null),
  ssn: yup
    .string()
    .nullable()
    .when(
      ['consent', 'legalAddress'],
      (consent: ConsentSchema, legalAddress: LegalAddress, schema: yup.StringSchema) => {
        return ssnValidator(consent?.isConsentGiven, legalAddress?.address?.country, schema)
      },
    ),
})

export const tenantApplicationGuarantorBackgroundCheckSchema = yup.object({
  consent: consentSchema,
})
