import { Claims, parseInvitationToken } from '@guiker/authentication-shared'
import { Base64 } from '@guiker/base64'

export const jwt = {
  parse: <T>(token: string): Claims & { data: T } => {
    const encodedParts = token.split('.')

    if (encodedParts.length === 3) {
      const claims = JSON.parse(Base64.decode(encodedParts[1]))

      return {
        ...claims,
        data: !!claims.data ? (JSON.parse(claims.data) as unknown as T) : claims,
      }
    }
  },
}

export const parseClaimsFromToken = (token: string) =>
  parseInvitationToken(token) || { token, ...jwt.parse(token)?.data }
