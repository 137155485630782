import { AdminApiClient } from '@guiker/propsharing-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

import { ListingContextProvider } from '../ListingContextProvider'
import { StockContextProvider } from '../StockContextProvider'

export * from './use-api-client'

export const { useContext: useAdminPropSharingListingIdContext, NestedRouter: AdminPropSharingListingIdNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    DataAccessContextProvider: ListingContextProvider,
    cacheKey: ({ listingId }: { listingId: string }) => `readOneListingByUid-${listingId}`,
    fetcher: (apiClient, { listingId }: { listingId: string }) =>
      apiClient.readOneListing({ pathParams: { id: listingId } }),
  })

export const { useContext: useAdminStockIdContext, NestedRouter: AdminStockIdNestedRouter } = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
  cacheKey: ({ stockId }: { stockId: string }) => `readOneStockByUid-${stockId}`,
  fetcher: (apiClient, { stockId }: { stockId: string }) => apiClient.readOneStock({ pathParams: { id: stockId } }),
  DataAccessContextProvider: StockContextProvider,
})

export const { useContext: useAdminStockOrderIdContext, NestedRouter: AdminStockOrderIdNestedRouter } =
  generateBaseApiContext({
    authLevel: AuthLevel.ADMIN,
    apiClientGen: AdminApiClient,
    cacheKey: ({ stockOrderId, stockId }: { stockOrderId: string; stockId: string }) =>
      `readOneStockOrderByUid-${stockId}-${stockOrderId}`,
    fetcher: (apiClient, { stockOrderId, stockId }: { stockOrderId: string; stockId: string }) =>
      apiClient.readOneStockOrder({ pathParams: { id: stockOrderId, stockId } }),
  })

export const {
  useContext: useAdminPropSharingContext,
  Context: AdminPropSharingContext,
  ContextProvider: AdminPropSharingContextProvider,
  NestedRouter: AdminPropSharingRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
})
