import { UnitedStatesOperationCost } from './operation-cost'
import { PurchaseBase } from './purchase-base'
import { UnitedStatesPurchaseCost } from './purchase-cost'
import { UnitedStatesPurchaseTaxes } from './purchase-taxes'

export * from './expense'

export const Base = {
  purchase: () => ({
    ...PurchaseBase,
    costs: UnitedStatesPurchaseCost,
    taxes: UnitedStatesPurchaseTaxes,
  }),
  operation: {
    costs: UnitedStatesOperationCost,
  },
} as const
