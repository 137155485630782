export enum LeaseTermTypes {
  Fixed = 'fixed',
  Indeterminate = 'indeterminate',
}

export type LeaseTerm = {
  type: LeaseTermTypes
  startDate?: string
  endDate?: string | null
  duration?: string | null
}
