import { CurrencyISO, yup } from '@guiker/shared-framework'

import { InvoiceMetadata, Scopeless } from '../../../../entity'
import { paymentInfoSchema } from '../../../schemas'

const metadataSchema = yup.object<InvoiceMetadata<Scopeless>>().when('context', {
  is: 'NONE',
  then: yup.object({
    reason: yup.string().required(),
  }),
  otherwise: yup.object({
    listingId: yup.string().required(),
    unit: yup.string().nullable(),
    reason: yup.string().required(),
  }),
})

const itemSchema = yup.object({
  label: yup.string(),
  quantity: yup.number().default(1),
  pricePerUnit: yup.number(),
  taxes: yup
    .array()
    .of(
      yup.object({
        taxId: yup.string().required(),
      }),
    )
    .default([]),
})

export const customerSchema = yup
  .object({
    id: yup.string().required(),
    emailAddress: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().nullable(),
    phoneNumber: yup.string().notRequired().nullable(),
  })
  .noUnknown()

export const chargeAmountSchema = yup.object({
  nonTaxableAmount: yup.number().required(),
  taxableAmount: yup.number().required(),
  taxId: yup.string().nullable(),
})

export const createCustomInvoicePayloadSchema = yup.object({
  currency: yup.mixed().oneOf(Object.values(CurrencyISO)).required(),
  items: yup.array().of(itemSchema).required(),
  context: yup.mixed<'LISTING' | 'NONE'>().oneOf(['LISTING', 'NONE']).required(),
  customer: customerSchema,
  metadata: metadataSchema,
  info: paymentInfoSchema,
})

export type CreateCustomInvoicePayloadSchema = Required<yup.InferType<typeof createCustomInvoicePayloadSchema>>
