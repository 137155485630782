import React from 'react'

import {
  CurrencyTextField,
  FormSection3,
  FormSection4,
  Inline,
  NumberFormat,
  numberFormatter,
  Spinner,
  TwoColumnsGridLayout,
} from '@guiker/react-framework'
import { currency as baseCurrency, CurrencyISO } from '@guiker/shared-framework'

import { useInvestmentAssumptionContext } from '../../../context'
import { useT } from '../../../i18n'
import { AssumptionComponentProps as Props } from '../assumption-props'
import { getInputProps } from './utils'

const InvestmentAssumptionsMobile: React.FC<Props> = ({ defaultValue, name, readOnly = false, schema }) => {
  const { t } = useT({})
  const { investmentAssumptions } = useInvestmentAssumptionContext()
  const currency = baseCurrency[defaultValue?.price.currency ?? CurrencyISO.CAD]

  const inputProps = getInputProps(defaultValue, name, schema, readOnly)

  if (!investmentAssumptions) {
    return <Spinner />
  }

  return (
    <FormSection3>
      <FormSection4
        title={t('main-propertySale:screens.listingScreen.tab1.sections.investmentAssumptions.rentalIncome')}
      >
        <TwoColumnsGridLayout>
          <CurrencyTextField
            {...inputProps.getRevenueInputProps('rentalRevenue')}
            currency={currency}
            allowNegative={false}
          />
          <NumberFormat
            {...inputProps.getRevenueInputProps('rentalGrowthRate')}
            endAdornment={<Inline>%</Inline>}
            decimalScale={2}
            max={100}
            allowNegative={false}
            formatter={numberFormatter.percentage(2)}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
      <FormSection4
        title={t('main-propertySale:screens.listingScreen.tab1.sections.investmentAssumptions.propertyValueGrowth')}
      >
        <TwoColumnsGridLayout>
          <NumberFormat
            {...inputProps.getBaseInputProps('holdingPeriod')}
            allowNegative={false}
            endAdornment={<Inline>{t('common:duration.year')}</Inline>}
          />
          <NumberFormat
            {...inputProps.getBaseInputProps('propertyAppreciationRate')}
            endAdornment={<Inline>%</Inline>}
            decimalScale={2}
            max={100}
            allowNegative={false}
            formatter={numberFormatter.percentage(2)}
          />
        </TwoColumnsGridLayout>
      </FormSection4>
    </FormSection3>
  )
}

export { InvestmentAssumptionsMobile }
