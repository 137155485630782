import { TypeOf, yup } from '@guiker/yup-util'

import { lessee, lessor } from '.'

export const participants = yup.object({
  lessors: yup.array().of(lessor).required(),
  lessees: yup.array().of(lessee).required(),
})

export type Participants = TypeOf<typeof participants>
