import { isPlainObject, omitBy as _omitBy, ValueKeyIteratee } from 'lodash'

export const omitBy = <T>(input: T, predicate?: ValueKeyIteratee<any>): Partial<T> => {
  const omitByDeepByOnOwnProps = (object: any): Partial<T> => {
    if (!Array.isArray(object) && !isPlainObject(object)) {
      return object
    }

    if (Array.isArray(object)) {
      return object.map((element) => omitBy(element, predicate)) as T
    }

    const temp: T = {} as T
    for (const [key, value] of Object.entries(object)) {
      ;(temp as any)[key] = omitBy(value as any, predicate)
    }

    return _omitBy(temp, predicate) as Partial<T>
  }

  return omitByDeepByOnOwnProps(input)
}
