import React from 'react'

import { Transaction } from '@guiker/payment-shared'
import { CollapseWithHeader, useDateFormatter } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { detailsByType } from '../../../utils'
import { InvoiceItem } from './InvoiceItem'

type PaymentHistoryProps = {
  transactions: Transaction[]
  isOpen?: boolean
}

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({ transactions, isOpen = false }) => {
  const { tShared } = useT({ entity: 'paymentHistory' })
  const { formatDateTime } = useDateFormatter()

  return (
    <CollapseWithHeader in={isOpen} hasBorder={false} header={tShared(`header`)}>
      {transactions.map(({ intent, status, createdAt, payInMethod }, index) => {
        const label = `${tShared(`intent.${intent}`)} ${tShared(`status.${status}`)} ${
          status === 'FAILED' ? `- ${detailsByType(payInMethod, tShared)}` : ''
        }`

        return <InvoiceItem label={label} value={formatDateTime(createdAt)} color={50} key={`invoice-trx-${index}`} />
      })}
    </CollapseWithHeader>
  )
}
