import React from 'react'

import { PropertySaleListing, SaleStatus } from '@guiker/property-sale-shared'
import { Chip, HandShakingIcon } from '@guiker/react-framework'

import { useT } from '../../i18n'

type PropertySaleListingCardChipProps = {
  listing: PropertySaleListing
  className?: string
}

export const PropertySaleListingCardChip: React.FC<PropertySaleListingCardChipProps> = ({ className, listing }) => {
  const { tShared } = useT({ entity: 'listing.propertySale' })
  const isSold = listing.propertySale?.saleStatus === SaleStatus.SOLD

  return isSold ? (
    <Chip className={className} size='small' startAdornment={<HandShakingIcon size='small' />}>
      {tShared('saleStatus.options.SOLD')}
    </Chip>
  ) : (
    <></>
  )
}
