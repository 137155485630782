import { PointOfInterest, PointOfInterestType, universityWhitelist } from '../entity'

export const filterLocations = (type: PointOfInterestType, data: PointOfInterest[]) => {
  switch (type) {
    case PointOfInterestType.UNIVERSITY:
      return data.filter((poi: PointOfInterest) => Object.values<string>(universityWhitelist).includes(poi.placeId))
    default:
      return data
  }
}
