import React from 'react'

import { clsx } from '@guiker/clsx'
import { Flex, makeStyles } from '@guiker/react-framework'

export type TimelineMarkerProps = {
  isCompleted?: boolean
  isFailed?: boolean
  isFirst?: boolean
  isLast?: boolean
  icon: React.ReactNode
}

const useStyles = makeStyles(
  (theme) => ({
    isCompleted: {
      color: theme.palette.status.success.main,
    },
    isAwaiting: {
      color: theme.palette.grey[15],
    },
    failedIcon: {
      color: theme.palette.status.alert.main,
    },
  }),
  { name: 'MarkerContainer' },
)

export const TimelineMarker: React.FC<{ marker: TimelineMarkerProps }> = ({ marker }) => {
  const classes = useStyles()

  return (
    <Flex
      className={clsx(
        !marker.isFirst && !marker.isCompleted && !marker.isFailed && classes.isAwaiting,
        !marker.isFirst && marker.isCompleted && classes.isCompleted,
        marker.isFailed && classes.failedIcon,
      )}
    >
      {marker.icon}
    </Flex>
  )
}
