import { routesBuilder } from '@guiker/shared-framework'

import { RentPaymentsPlanWithTenantInstalments } from '../../entity'
import { paths } from './paths'
import { changePayInMethodSchema, requestContributionResplitSchema } from './schemas'

const withScope = routesBuilder<RentPaymentsPlanWithTenantInstalments>()({
  basePath: paths.rentPaymentsPlan.one.withScope(),
})

export const routes = {
  readOneRentPaymentsPlan: withScope.buildGet(),
  changePayInMethod: withScope.buildPut({
    path: '/change-payin-method',
    payloadSchema: changePayInMethodSchema,
  }),
  requestContributionResplit: withScope.buildPut({
    path: '/resplit',
    payloadSchema: requestContributionResplitSchema,
  }),
}
