import { AdminApiClient } from '@guiker/property-sale-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export * from './use-api-client'

export const {
  useContext: useAdminPropertySaleListingIdContext,
  NestedRouter: AdminPropertySaleListingIdNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
  cacheKey: ({ id }: { id: string }) => `readOneListingByUid-${id}`,
  fetcher: (apiClient, { id }: { id: string }) => apiClient.readOneListing({ pathParams: { id } }),
})

export const {
  useContext: useAdminPropertySaleListingContext,
  Context: AdminPropertySaleListingContext,
  ContextProvider: AdminPropertySaleListingContextProvider,
  NestedRouter: AdminPropertySaleListingRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
})
