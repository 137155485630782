import React from 'react'

import { Link } from '../../components'
import { routes } from '../../routes'

const PRIVACY_POLICY_LINK_IDENTIFIER = 'Privacy Policy'
const TERMS_LINK_IDENTIFIER = 'Terms of Use'

const termsAndConditionsContent = () => {
  return {
    title: 'TERMS OF USE',
    subtitle: 'Terms and Conditions',
    metaDescription:
      'These Terms and Conditions are intended to inform you that by using Guiker’s Technology Services, you enter into this Agreement with consent and knowledge.',
    lastUpdated: 'Last updated: June 21, 2021',
    generalTerms: {
      title: 'GENERAL TERMS',
      list: [
        {
          title: '1. ACCEPTANCE OF TERMS',
          clauses: [
            'Please read these terms and conditions (this “Agreement” or these “Terms”) carefully. By navigating this website (the “Platform”), the User agrees to be bound by the terms of this Agreement. ',
            'These Terms constitute an agreement, which includes the privacy policy (“Privacy Policy”), by and between Solutions Guiker inc., a corporation whose principal place of business is in Montreal (Canada) (“Guiker”, “we” or “us”) and any user, corporation, partnership, sole proprietorship or other business entity executing this Agreement, and all or any employee, contractor, agent or affiliate of such business entity (together the “User”, “Users” or “you”)  that uses or accesses the Services (defined hereinafter). Guiker reserves the right to update and change these Terms of Use at any time without prior notice by posting updates and changes to the Website, which will take effect on the moment of publication or any other moment indicated. If you have a Guiker Account (your “Account”), we will also send you an e-mail explaining the changes we will be implementing thirty (30) days before they become effective.',
            <>
              You are advised to check the Terms of Use from time to time for changes and updates that may impact you.
              Any use of the Services after such updates and changes will mean that you accept them. You can always
              review the last updated version of the{' '}
              <Link to={routes.TermsAndConditions.path}>{TERMS_LINK_IDENTIFIER}</Link>. Please also review from time to
              time our <Link to={routes.PrivacyPolicy.path}>{PRIVACY_POLICY_LINK_IDENTIFIER}</Link>
            </>,
          ],
        },
        {
          title: '2. SERVICES',
          clauses: [
            'Guiker offers, through its Platform, an online listing service of apartment rental units and various properties as well as property management services (the “Services”). Users who list and offer rental units are “Landlords” and Users who rent said rental units are “Tenants”. ',
            <>
              The information provided by you through the Services is protected by our{' '}
              <Link to={routes.PrivacyPolicy.path}>{PRIVACY_POLICY_LINK_IDENTIFIER}</Link>
            </>,
            <>
              Any new services, features, or tools which are added to the current Services shall be also subject to the
              Terms of Use. You can review the current version of the{' '}
              <Link to={routes.TermsAndConditions.path}>{TERMS_LINK_IDENTIFIER}</Link> at any time.
            </>,
            'You must be over 18 years of age in order to access and use the Services and to enter into this Agreement. By navigating the Website and using the Services you warrant that you are over 18 years of age.',
          ],
        },
        {
          title: '3. ACCOUNT AND ELIGIBILITY',
          clauses: [
            'The Services require that you sign up for an Account. By using the Services, you represent and warrant that (a) all information you submit to Guiker are truthful and accurate; (b) you will maintain the accuracy of all such information; (c) you will uphold and respect these Terms at all times; (d) your use of the Services does not violate any applicable law or regulation; (e) you will safeguard and protect your Account information. You will supervise and be completely responsible for any use of your Account or your password or any transaction by you and anyone other than you made with your Account. Your Account may be deleted without warning if we believe that you violate any of these Terms.',
          ],
        },
        {
          title: '4. TERM',
          clauses: [
            'This Agreement will remain in full force and effect while you use the Services and/or have an Account (the “Term”). Your Account enables you to access and use the Platform in accordance with this Agreement. You can terminate your Account by following the instructions provided in sections 16 and 27 of these Terms. ',
          ],
        },
        {
          title: '5. TERMINATION AND SUSPENSION OF YOUR GUIKER ACCOUNT',
          clauses: [
            `In the event of breach of this Agreement by a User, Guiker may terminate this Agreement by giving the User written notice specifying the nature of the breach in reasonable detail and Guiker’s intention to terminate (a "Termination Notice"). If the breach has not been cured within the period ending thirty (30) days following delivery of the Termination Notice, then this Agreement shall automatically terminate. If the User is in breach of any of the provisions of this Agreement under the sections labelled “Licence” or “Confidential Information”, then Guiker may immediately suspend or terminate the User's use of the Services. `,
            'Guiker may also suspend the User’s access to the Services immediately without notice if Guiker, in its sole discretion, believes: (1) such suspension is required by law; or (2) there is a security or privacy risk to the User. Any suspension of the User’s access to the Services will not limit or waive Guiker’s rights to terminate this Agreement or the User’s access to the Services. ',
            'Upon termination of this Agreement, the User shall discontinue its use of the Services. Notwithstanding the previous sentence, termination of this Agreement by Guiker shall not restrict Guiker from pursuing any other remedies available to it, including injunctive relief. The sections of this Agreement labelled “Intellectual Property”, “No Liability”, “No Warranty”, “Indemnity”, “Severability, Force Majeure, Entire Agreement and Headings”, “Representations and Warranties”, and “Governing Law” shall remain in full force and effect. ',
            'Users agrees that following termination of the User’s Account and/or use of the Services, Guiker may immediately deactivate the User’s Account and delete the Users data. Users further agree that Guiker shall not be liable to the User nor to any third party for any termination of the User’s access to the Services or deletion of the Users data. ',
          ],
        },
        {
          title: '6. LICENSE',
          clauses: [
            'During the Term, Guiker grants Users a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to use the Services on the terms set forth in this Agreement. Users agree that all rights, title and interest in and to all the intellectual property rights in the Services, and all modifications, extensions, scripts and other derivative works of the Services provided or developed by Guiker are owned exclusively by Guiker or its licensors. All rights not granted to the Users in this Agreement are reserved by Guiker.  ',
          ],
        },
        {
          title: '7. LICENSE RESTRICTIONS',
          clauses: [
            'Users shall not (i) decompile, disassemble, or otherwise reverse engineer or attempt to reconstruct or discover any source code, underlying ideas, algorithms, file formats or programming or interoperability interfaces of or used by the Services, by any means whatsoever; (ii) distribute viruses or other harmful or malicious computer code via or into the Services; (iii) engage in any conduct that disrupts or impedes a third party’s use and enjoyment of the Services; (iv) remove any product identification, copyright or other notices from the Services; (v) sell, lease, lend, assign, sublicense, grant access or otherwise transfer or disclose the Services in whole or in part, to any third party; (vi) use the Services for timesharing, service bureau or hosting purposes or otherwise use, resell, sublicense, distribute or transfer or allow others to use the Services to or for the benefit of third parties; (vii) modify or incorporate into or with other software or create a derivative work of any part of the Services; (viii) use the output or other information generated by the Services for any purpose other than as contemplated by this Agreement; (ix) use the Services for any use other than the User’s personal use; (x) use unauthorized modified versions of the Services, including without limitation, for the purpose of building a similar or competitive product or service or for the purpose of obtaining unauthorized access to the Services; or (xi) use the Services in any way that is contrary to applicable local, state/provincial, federal and foreign laws, including without limitation those relating to privacy, electronic communications and anti-spam legislation. Guiker retains all title to, and, except as expressly licensed herein, all rights to the Services, all copies, derivatives and improvements thereof and all related documentation and materials.',
          ],
        },
        {
          title: '8. INTELLECTUAL PROPERTY',
          clauses: [
            'The Services contain copyrighted material, trademarks and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music and sound, and the entire contents of the Services are copyrighted as a collective work under copyright laws. Guiker owns a copyright or displays copyrighted material on exclusive authorization provided by a third party in the selection, coordination, arrangement and enhancement of such content, as well as in the content original to it. Users may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. Except as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material will be permitted without the express permission of Guiker and the copyright owner. In the event of any permitted copying, redistribution or publication of copyrighted material, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made. No license or right of use with regards to intellectual Property is conferred or can be inferred from the use of the Website in favor of Users.',
          ],
        },
        {
          title: '9. CONFIDENTIAL INFORMATION',
          clauses: [
            `Guiker and Users shall each retain in confidence all information received from the other party pursuant to or in connection with this Agreement that the disclosing party identifies as being proprietary and/or confidential or that, by the nature of the circumstances surrounding the disclosure, ought in good faith to be treated as proprietary and/or confidential ("Confidential Information"), and will make no use of such Confidential Information except as necessary to fulfill their respective obligations under the terms and during the term of this Agreement. Guiker may collect some Confidential Information from Users which includes, without limitation, their name, phone number, email address and postal address. Either party may disclose such information in confidence to its immediate legal and financial consultants as required in the ordinary course of that party's; business. The restrictions set forth above will not apply to (i) information previously known to the receiving party without reference to the disclosing party's Confidential Information, (ii) information which is or becomes publicly known through no wrongful act of the receiving party, (iii) information that is independently developed by either of the parties, or (iv) information required to be disclosed pursuant to applicable law.`,
          ],
        },
        {
          title: '10. YOUR CONTENT',
          clauses: [
            'The Services may allow you to submit, post, display or upload content including but not limited to text, files, photos and other materials (together the “Content”). You retain ownership of your Content, but by uploading or entering it onto any software, program or platform provided as part of the Services, you are granting us a license to use, copy, reproduce, process, adapt, publish, transmit, host and display that Content for the purpose of (i) providing you the Services and associated support; and (ii) analyzing and improving the operation of the Services. We reserve the right to remove Content on any software, program or platform provided as part of the Services that the Corporation, in its sole discretion, finds to be in violation of the provisions hereof or otherwise objectionable.',
            'Users will not post, upload, publish, submit or transmit any Content that: (i) is fraudulent, false, misleading (directly or by omission or failure to update information) or deceptive; (ii) is defamatory, obscene, pornographic, vulgar or offensive; (iii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (iv) is violent or threatening or promotes violence or actions that are threatening to any other person or animal; (v) promotes illegal or harmful activities or substances. Guiker may, without prior notice, remove or disable access to any Content that Guiker finds to be in violation of applicable law, these Terms or otherwise may be harmful or objectionable to Guiker, its Members, third parties, or property.            ',
          ],
        },
        {
          title: '11. THIRD PARTY SERVICES',
          clauses: [
            'Guiker may from time to time recommend, provide you with access to, or enable third party software, applications, products, services or website links (collectively, “Third Party Services”) for your consideration or use. Such Third Party Services are made available only as a convenience, and purchase, access, or use of any such Third Party Services is solely between you and the applicable third party services provider (“Third Party Provider”). Any use by you of Third Party Services offered through the Services or the Website is entirely at your own risk and discretion, and it is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third Party Services before using them. Under no circumstances shall we be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from any Third Party Services or your contractual relationship with any Third Party Provider.',
          ],
        },
      ],
    },
    tenantsTerms: {
      title: 'TENANTS TERMS',
      list: [
        {
          title: '12. SEARCHING ON THE PLATFORM',
          clauses: [
            'The Platform allows Tenants to search and find available properties (“Rental units”) for leasing. The Platform allows searches by location, size of unit, move-in-date and price, while also detailing the amenities provided, the services included in the price and the general information related to the Rental unit.',
          ],
        },
        {
          title: '13. SCHEDULING VISITS ',
          clauses: [
            <>
              Once a Tenant has selected a Rental unit he wishes to visit, the Tenant can schedule a visit by contacting
              Guiker’s technical support. The Tenant can either contact Guiker directly by asking a question via the
              Platform or schedule a visit via text message by providing a phone number. The phone number collected is
              subject to Guiker’s <Link to={routes.PrivacyPolicy.path}>{PRIVACY_POLICY_LINK_IDENTIFIER}</Link>
            </>,
          ],
        },
        {
          title: '14. CONTRACTING WITH LANDLORDS',
          clauses: [
            'Through the Platform, Guiker provides Users with a system for the delivery of an electronic lease contract between a Tenant and a Landlord (the “Lease”). When a Tenant wishes to lease a Rental unit, Guiker will prepare an applicable Lease between the Tenant and the Landlord and execute such Lease through the Platform on the Landlord’s behalf. ',
            'When the Tenant signs a Lease, they are entering into a contractual engagement directly with the Landlord and are responsible for delivering the obligations detailed under the Lease. Tenants specifically agree to pay the rent directly to Guiker, via the Platform, in the manner and for the amount prescribed in the Lease.',
          ],
        },
        {
          title: '15. PAYMENTS',
          clauses: [
            'All rent prices displayed on the Platform are in Canadian dollars and exclude additional fees. Rental fees (the “Rent”) are collected via the payment processing functionality offered on the Platform. Once the Rent is paid by a Tenant via the Platform, such payment shall be considered the same as payment made directly by the Tenant to the Landlord.',
            <>
              When a Tenant proceeds to make a rent payment, the Tenant must provide a valid payment instrument and
              represent that he has permission to use that payment instrument. When the Tenant makes a payment, he also
              authorizes Guiker to collect and record the payment instrument and any other information related to the
              payment. The information collected is subject to Guiker’s{' '}
              <Link to={routes.PrivacyPolicy.path}>{PRIVACY_POLICY_LINK_IDENTIFIER}</Link>.
            </>,
            'In order to prevent financial loss, Guiker may contact the issuer of the Tenant’s payment instrument, law enforcement authorities, or any affected Third Party (including other Users) and may share details of any payment with which the Tenants are associated if Guiker believe that such action may prevent financial loss or a violation of the law.',
          ],
        },
        {
          title: '16. TERMINATION OF YOUR TENANT ACCOUNT',
          clauses: [
            'If a Tenant did not sign a Lease through the Platform and wishes to terminate his Account, the Account can be terminated at any time, for any reason, by following the instructions in the Account management page or by contacting Guiker. After the Tenant’s Account is terminated, this Agreement will terminate, except for the provisions of the sections labelled “Intellectual Property”, “Confidential Information”, “Limitation of Liability”, “No Liability”, “No Warranty”, “Indemnity”, “Severability, Force Majeure, Entire Agreement and Headings”, “Representations and Warranties”, and “Governing Law”.',
            'If a Tenant has signed a Lease through the Platform, this Agreement shall remain in effect until the Lease is terminated in accordance with any laws, rules and regulations applicable where the Rental unit is located. Once the Lease is terminated, this Agreement will terminate, except for the provisions of the sections labelled “Intellectual Property”, “Confidential Information”, “Limitation of Liability”, “No Liability”, “No Warranty”, “Indemnity”, “Severability, Force Majeure, Entire Agreement and Headings”, “Representations and Warranties”, and “Governing Law”.',
          ],
        },
        {
          title: '17. RESPONSIBILITY AND ASSUMPTION OF RISK',
          clauses: [
            'Tenants are responsible and liable for their own acts and omissions and are also responsible for the acts and omissions of anyone to whom they allow access to the Rental unit. ',
          ],
        },
        {
          title: '18. LIMITATION OF LIABILITY ',
          clauses: [
            'In no event shall Guiker be liable for any loss of enjoyment of the Rental unit or default of the Landlord to respect his obligations, nor will Guiker be liable in any circumstances for any incidental, consequential, direct or indirect damages arising from or in relation to this Agreement or the provision of the Services, however caused and regardless of theory of liability. This limitation will apply even if Guiker has been advised or is aware of the possibility of such damages.',
          ],
        },
      ],
    },
    landLordTerms: {
      title: '',
      list: [
        {
          title: '19. LANDLORD SERVICES',
          clauses: [
            'Guiker offers on-demand lead generation and related services that enable Landlords, via the Platform, to advertise Rental units, and to reach out to, and connect and contract with Tenants, with respect to such Rental units. Guiker also offers access to the Platform, payment services, related support services systems, which may be modified from time to time, and Rental unit management services (all together the “Landlord services”). ',
            'Before a Rental unit is listed on the Platform, Landlord and Guiker shall agree on the scope of the Landlord services, as well as the Service fee (as defined below) and the cancellations fees, if applicable in a duly executed statement of work (the “Statement of work”). ',
            'For more information about the Landlord services available, please contact Guiker at contact@guiker.com.',
          ],
        },
        {
          title: '20. MANAGING YOUR LISTING',
          clauses: [
            'The Platform provides Landlord with an efficient Platform that allows them to set up and manage their Listing(s). Landlords are in control of their Listing(s), and can set the price, the availability, the Lease’s term, the included amenities, and indicate other relevant information in the Listing’s description. ',
            'Listings must include complete and accurate information about the Rental unit, specifically about the price, any other charges and any rules or requirements that apply to the Rental unit.',
            'Landlords are responsible for keeping their Listing’s information and content up-to-date and accurate at all times and/or communicating changes to Guiker in a timely manner.',
          ],
        },
        {
          title: '21. CONTRACTING WITH TENANTS',
          clauses: [
            'When a Landlord accepts to lease a Rental unit to a Tenant through the Platform, they are entering into a contract directly with the Tenant and are responsible for delivering the obligations detailed under the Lease. ',
            'Landlords acknowledge and agree that they are solely responsible and liable to provide and deliver to Tenants all Rental units that they advertise and/or post on the Platform, in the same condition that such Rental units are advertised. Landlords acknowledge and agree that they are solely responsible for the delivery of such Rental Units in accordance with all applicable laws and regulation. Landlords also agree that they shall not contact any Tenant or use any of the Tenant’s personal data for any reason other than for the purposes of fulfilling the Landlord Services for such Tenant. Landlords also agree to pay the applicable fees to Guiker for the Landlord services. Fees owed to Guiker will be deducted from the monthly payments received by Guiker from the Tenant, unless a different payment method is agreed upon. ',
            'Any additional agreement between the Landlord and the Tenant must be consistent with Guiker’s Terms and Privacy Policy and be consistent with the information provided in the Listing.',
          ],
        },
        {
          title: '22. LEASE',
          clauses: [
            'As part of the Landlord services, Guiker provides Landlords with a system for the execution of an electronic lease with Tenants for the Rental units rented out through the Platform. Once an agreement is reached between a Landlord and a Tenant, Guiker will prepare an applicable Lease between the Parties and execute such Lease through the Platform on the Landlord’s behalf. ',
            'The Landlord authorizes Guiker to prepare an applicable Lease between the Landlord and the Tenant and execute such Lease through the Platform on the Landlord’s behalf. The Landlord also authorizes and appoints Guiker as their collection agent for the purpose of collecting the Rent and applicable taxes on their behalf and authorizes Guiker to retain the applicable service fees (the “Service fees”) as laid out in the Statement of Work, from the payment. ',
          ],
        },
        {
          title: '23. APPOINTMENT OF GUIKER AS A LIMITED COLLECTION AGENT',
          clauses: [
            'Landlords hereby: (i) appoint Guiker as their limited payment collection agent solely for the purpose of collecting the Rental fees and applicable taxes, when applicable, from Tenants on their behalf via the payment processing functionality facilitated by the Services; and (ii) agree that payment made by Tenants to Guiker or to an affiliate of Guiker acting as an agent of Guiker shall be considered the same as payment made directly by the Tenant to the Landlord. Guiker shall consider all such requests from Landlords in good faith. Guiker agrees to remit, or cause to be remitted, to the Landlord no later than every fifteenth (15th) day of the month, following the transaction between the Landlord and the Tenant: (a) the Rental fees less the applicable Service fees; and (b) depending on the region, certain taxes and ancillary fees.',
          ],
        },
        {
          title: '24. SERVICE FEES',
          clauses: [
            'In consideration of Guiker’s provision of the Platform and the Landlord services for the Landlords use and benefit, Landlords agree to pay Guiker the Service fees (the “Service fee(s)”) provided in the Statement of work. In the event regulations applicable to your region require taxes to be calculated on the Service fee, Guiker shall calculate the Service Fee based on the Rent net of such taxes. For more information about the Service fees, please contact Guiker at contact@guiker.com.',
          ],
        },
        {
          title: '25. NO ADDITIONAL AMOUNTS',
          clauses: [
            'Landlords acknowledge and agree that, for the mutual benefit of the Parties, through advertising and marketing, Guiker may seek to attract new Users to the Guiker Platform and to increase existing Users’ use of the Platform. Landlords acknowledge and agree that such advertising or marketing does not entitle them to any additional monetary amounts beyond the amounts expressly set forth in this Agreement.',
          ],
        },
        {
          title: '26. TAXES',
          clauses: [
            'Landlords acknowledge and agree that they are required to: (a) complete all tax registration obligations and calculate and remit all tax liabilities related to your provision of Landlord services as required by applicable law; and (b) provide Guiker with all relevant tax information. Landlords further acknowledge and agree that they are responsible for taxes on their own income arising from the performance of Landlord services. Notwithstanding anything to the contrary in this Agreement, Guiker may in its reasonable discretion based on applicable tax and regulatory considerations, collect and remit taxes resulting from the Landlords provision of Landlord services and/or provide any of the relevant tax information they have provided pursuant to the foregoing requirements in this section labelled “Taxes” directly to the applicable governmental tax authorities on their behalf or otherwise.',
          ],
        },
        {
          title: '27. TERMINATION OF YOUR LANDLORD ACCOUNT',
          clauses: [
            'If the Landlord listed Rental units, but did not sign a Lease through the Platform and wishes to terminate his Account, the Account can be terminated at any time, for any reason, by following the instructions in the Account management page or by contacting Guiker. After the Landlord’s Account is terminated, this Agreement will terminate, except for the provisions of the sections labelled “Intellectual Property”, “Confidential Information”, “Limitation of Liability”, “No Liability”, “No Warranty”, “Indemnity”, “Severability, Force Majeure, Entire Agreement and Headings”, “Representations and Warranties”, and “Governing Law”.',
            'If the Landlord has signed a Lease through the Platform, this Agreement shall remain in effect until terminated in accordance with the Statement of work. Upon termination of the Statement of work, this Agreement will terminate, except for the provisions of the sections labelled “Intellectual Property”, “Confidential Information”, “Limitation of Liability”, “No Liability”, “No Warranty”, “Indemnity”, “Severability, Force Majeure, Entire Agreement and Headings”, “Representations and Warranties”, and “Governing Law”. Additionally, any cancellation fee, if applicable, payable in connection with the termination of the Statement of work, shall be immediately due and payable as liquidated damages.',
          ],
        },
        {
          title: '28. TERMINATION FOR BREACH',
          clauses: [
            'In the event that the Landlord fails to comply with any obligation under this Agreement, Guiker may provide the Landlord with a written notice to the Landlord to remedy the default within five (5) business days of receipt of the notice. If the default is not remedied within such time, Guiker may terminate the Agreement upon written notice, in which case all Service fees and cancellation fees, if applicable, payable in connection with the performance of Landlord services provided in the Statement of work shall be immediately due and payable as liquidated damages.',
          ],
        },
        {
          title: '29. RIGHT TO REVIEW THE RENTAL UNIT ',
          clauses: [
            'Guiker reserves the right to review the state of a Rental unit. If Guiker finds, in its sole discretion, that the state of a Rental unit is not acceptable, then Guiker shall send a notice to the Landlord to that effect. The Landlord shall have five (5) days to remedy the default after which the Agreement shall terminate without further notice.',
          ],
        },
        {
          title: '30. COLLABORATION ',
          clauses: [
            'Guiker and the Landlord acknowledge that timely and satisfactory completion of their responsibilities and obligations as provided in this Agreement require information and cooperation between them as set out in this Agreement. Guiker or the Landlord shall not be liable for any delay or deficiency in performing its responsibilities and obligations as provided in this Agreement to the extent that such delay or deficiency results from the other party’s failure to fulfill on a timely basis its own responsibilities and obligations. Guiker or the Landlord shall be entitled to rely on the accuracy and completeness of information provided by the other Party and third parties without further investigation.',
          ],
        },
        {
          title: '31. REPAIRS ORDERED BY THE AUTHORITY ',
          clauses: [
            'In the event that any governmental agency, authority or department should order the repair, alteration or removal of any such structures or matter in or on the Rental unit, and if after written notice of such matter to the Landlord by such body or by Guiker, the Landlord fails to authorize Guiker or its subcontractor to make such repairs, alterations or removal, Guiker shall be released from any responsibility in connection therewith, and the Landlord shall be responsible to such body for any penalties and fines whatsoever imposed because of such failure on the Landlord’s part.',
          ],
        },
        {
          title: '32. FIRE AND SAFETY',
          clauses: [
            'The Landlord represents and warrants that to the best of its knowledge, the Rental unit and its equipment are compliant with all relevant federal and provincial laws, statutes and regulations regarding fire safety.',
          ],
        },
        {
          title: '33. RELATIONSHIP WITH GUIKER',
          clauses: [
            'Landlords acknowledge and agree that Guiker’s provision to them of the Platform and the Landlord services creates a direct business relationship between Guiker and the Landlord. Guiker does not, and shall not be deemed to, direct or control the Landlord generally or in the Landlords performance under this Agreement specifically, including in connection with the Landlords Rental units and relationship with Tenants, their acts or omissions, or their operation and maintenance of the Rental Units. Landlords retain the sole right to determine when, where, and for how long they will use the Platform or the Landlord services. Landlords retain the option, via the Platform, to attempt to accept or to decline or ignore a Tenant’s request to rent out the Rental units.',
          ],
        },
        {
          title: '34. INDEPENDENT CONTRACTORS',
          clauses: [
            'This Agreement is not intended to create nor shall be construed to create any relationship between the Guiker and the Landlord other than that of an independent contractor for the purpose of effecting the provisions of this Agreement. No party, nor any of their representatives, shall be construed to be the agent, employer, employee or representatives of the other.',
          ],
        },
        {
          title: '35. SUBCONTRACTORS ',
          clauses: [
            'Guiker may hire or engage or use any person, firm or corporation associated, affiliated or otherwise connected with it, including a parent or subsidiary corporation to perform any work or services for the Rental unit within the scope of Guiker’s duties under the provisions of this Agreement.',
          ],
        },
        {
          title: '36. RESPONSIBILITY AND ASSUMPTION OF RISK',
          clauses: [
            'Landlords are responsible and liable for their own acts and omissions and are also responsible for the acts and omissions of anyone they allow to participate in providing the leasing of the Rental unit.',
          ],
        },
        {
          title: '37. LEGAL OBLIGATIONS',
          clauses: [
            'Landlords are responsible for understanding and complying with any laws, rules and regulations applicable to the place where the Rental unit is located. Landlords are also responsible to comply with condominium association rules, restrictions or other prohibitions. Guiker strongly recommends that Landlords consult the applicable legislative requirements before offering their Rental unit for leasing. The information provided by Guiker is solely for informational purposes and Landlords should independently confirm their obligations.',
            <>
              Landlords are also responsible for handling and using Tenant’s personal data in compliance with applicable
              privacy laws and Guiker’s <Link to={routes.TermsAndConditions.path}>{TERMS_LINK_IDENTIFIER}</Link> and{' '}
              <Link to={routes.PrivacyPolicy.path}>{PRIVACY_POLICY_LINK_IDENTIFIER}</Link>.,
            </>,
          ],
        },
        {
          title: '38. LISTING AS AN ORGANIZATION ',
          clauses: [
            'If the Landlord offers Rental units as part of an organization, each individual who participates in providing the Rental units is responsible and liable as a Landlord under these Terms. If the Landlord accepts to enter into a contract, the Landlord represents and warrants that he is authorized to enter into contracts and that his business entity is in good standing under the laws of the place where it is established. ',
          ],
        },
        {
          title: '39. LIMITATION OF LIABILITY',
          clauses: [
            'Guiker’s liability for all claims arising out of this Agreement shall be limited to the amount of fees paid by the Landlord to Guiker under this Agreement. In no event shall Guiker be liable for any loss of rental income by vacancies or default of the Tenant to pay any rent, nor for the damages to or destruction of the premises or property leased by a Tenant, nor for the removal of articles by the Tenant or others, or for misappropriation or theft nor liable in any circumstances for any incidental, consequential, direct or indirect damages arising from or in relation to this Agreement or the provision of the Services, however caused and regardless of theory of liability. This limitation will apply even if Guiker has been advised or is aware of the possibility of such damages.',
          ],
        },
      ],
    },
    miscellaneous: {
      title: 'MISCELLANEOUS',
      list: [
        {
          title: '40. NOTICES',
          clauses: [
            'Any notice delivered by Guiker to the Users under this Agreement will be delivered by email to the email address associated with the Users Account or by posting on the portal available to you on the Platform. Any notice delivered by the User to Guiker under this Agreement will be delivered by contacting Guiker at contact@guiker.com.',
          ],
        },
        {
          title: '41.  NO WARRANTY',
          clauses: [
            'Guiker works to keep the Website bug-free and safe, but the Users agree to use the Services at their own risk. Users are provided the Services “as is” and “as available” without any representations and warranties, express, implied or statutory, not expressly set out in these Terms, including the implied warranties of merchantability, fitness for a particular purpose and non-infringement. In addition, neither Users, its affiliates nor any of their respective employees, agents, third party content providers or licensors make any representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability or availability of the Website or any services or goods requested through the use of the Website, or that the Website will be uninterrupted or error-free.',
          ],
        },
        {
          title: '42.  NO LIABILITY',
          clauses: [
            'Guiker will not be liable for any damages that may be caused by technical, technological or human error, problem, malfunction, defect of the network, online computer systems, servers, computer equipment, software, data transmission or congestion of the Website.',
            'As permitted by law, Guiker will also not be liable for any damages that may occur or be caused by any malfunction, manufacturing defect, shipment, damage, use or wear of Guiker products. ',
            'In no event shall Guiker or its respective suppliers or affiliates be liable for any special, indirect or consequential damages or any damages whatsoever resulting from loss of use, data or profits, or any damages or loss arising from the Services provided or from Guiker products, whether in an action of contract, negligence or other civil liability action, arising out of or in connection with the use of the Services or Guiker products except in the case of intentional or gross fault.',
          ],
        },
        {
          title: '43.  INDEMNITY',
          clauses: [
            `Users agrees to defend, indemnify and hold harmless Guiker, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including without limitation, reasonable attorneys' fees (collectively the “Costs”), to the extent that such Costs are attributable to any breach by the User, of any representations, warranties, or other obligations set forth in this Agreement or the Privacy Policy or arising out of the use of the Services by the User. `,
          ],
        },
        {
          title: '44.  GOVERNING LAWS',
          clauses: [
            'These Terms of Service shall be construed in accordance with the laws applicable in the province of Quebec, Canada, without regard to its conflict of laws rules. Users hereby agree, for any dispute or controversy arising under or in connection with this Agreement or resulting from the Services or Corporation’s website shall be settled exclusively by the courts of the judicial district of Montreal.',
          ],
        },
        {
          title: '45.  SEVERABILITY, FORCE MAJEURE, ENTIRE AGREEMENT AND HEARINGS',
          clauses: [
            'If one or more of the provisions of this Agreement is held to be invalid, illegal or unenforceable in any respect by a court of competent jurisdiction, then the validity, legality and enforceability of the remaining provisions of this Agreement shall be unaffected. Neither party shall be deemed in default or otherwise liable for any delay in or failure of its performance under this Agreement (other than payment obligations) by reason of any act of God, fire, natural disaster, accident, act of government, shortage of materials or supplies, failure of transportation or communication or of suppliers of goods or services, or any other cause to the extent it is beyond the reasonable control of such party. This Agreement constitutes the entire agreement and understanding between the parties with respect to the subject matter hereof and supersedes all prior or contemporaneous written, electronic or oral communications, representations, agreements or understandings between the parties with respect thereto. The headings in this Agreement have been inserted solely for ease of reference and shall not modify, in any manner whatsoever, the meaning or scope of the provisions hereof.',
          ],
        },
        {
          title: '46.  ASSIGNMENT ',
          clauses: [
            'This Agreement is assigned by Guiker upon written notice to the User.  Upon such assignment, all future liabilities of Guiker shall terminate as of the date of such assignment, the assignee shall be liable to the User in all respects as if it had executed this Agreement, and Guiker shall then be released of any obligations arising from this Agreement.',
          ],
        },
        {
          title: '47.  NO THIRD PARTY BENEFICIARIES ',
          clauses: [
            'There are no third party beneficiaries to this Agreement. Nothing contained in this Agreement is intended to or shall be interpreted to create any third-party beneficiary claims.',
          ],
        },
        {
          title: '48.  REPRESENTATIONS AND WARRANTIES',
          clauses: [
            `Users represents and warrants that currently and throughout the Term (i) it is fully authorized to enter into this Agreement and that the User is fully authorized to use the Services; and (ii) Users are and will remain in compliance with all applicable laws and regulations with respect to its activities related to this Agreement, including but not limited to privacy laws. Users agree to comply with all of Guiker’s policies and all laws, rules and regulations relating to the use of the Services. Any violation of this section may result in loss of features, up to and including termination of the User's Account. Users will indemnify Guiker for any costs, fines or damages incurred by Guiker due to the User's failure to comply with this section.`,
          ],
        },
        {
          title: '49.  LANGUAGE',
          clauses: [
            'The parties hereto have expressly required that this Agreement be drafted in English. Les parties aux présentes ont expressément exigé que la présente entente soit rédigée en anglais.',
          ],
        },
      ],
    },
  }
}

export { termsAndConditionsContent }
