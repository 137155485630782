import { HttpMethod } from '@guiker/http'

import { PropertySaleInquiry } from '../../entity'
import { paths } from './paths'
import { createPropertySaleInquirySchema } from './schemas'

export const routes = {
  createPropertySaleInquiry: {
    path: paths.listingInquiry.all.base(),
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => createPropertySaleInquirySchema.validate(payload),
    responseValidator: (res: unknown) => Promise.resolve(res as PropertySaleInquiry),
  },
}
