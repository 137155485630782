export enum RentFrequency {
  month = 'month',
  week = 'week',
}

export enum PaymentDates {
  firstDayOfMonth = 'firstDayOfMonth',
  firstDayOfWeek = 'firstDayOfWeek',
  other = 'other',
}

export enum PaymentMethod {
  guikerOnlinePayment = 'guikerOnlinePayment',
  cash = 'cash',
  cheque = 'cheque',
  electronicBankTransfer = 'electronicBankTransfer',
  other = 'other',
}

export interface Rent {
  completed: boolean
  firstPaymentDate: string
  isLesseeEligibleForSubsidy: boolean
  lesseeAgreeOnPostDatedCheque: boolean
  otherPayment: string
  paymentDate: PaymentDates
  paymentDateOther: string
  paymentMethod: PaymentMethod
  rentFrequency: RentFrequency
  rentAmount: number
  rentPayableAt: string
  rentSubsidy?: string
  serviceCharge: number
}
