import { yup } from '@guiker/yup-util'

import { CountByStatusMetadata } from './metadata'

export const routeBuilderHelper = {
  withStatus: <S extends string>(statuses: S[]) =>
    ({
      paginated: true,
      queryParamsSchema: yup.object<{ searchString?: string; statuses?: S[] }>({
        searchString: yup.string().nullable().optional(),
        statuses: yup
          .array()
          .of<S>(yup.mixed<S>().oneOf(statuses))
          .nullable()
          .transform((v) => (!!v ? v : null)),
      }),
      additionalMeta: {} as CountByStatusMetadata<S>,
    } as const),
}
