import { Role } from '@guiker/permissions'

import { BookingActions } from '../booking-actions'
import { BookingRoleNames } from '../role-names'

const prohibitedActions: BookingActions[] = []

export const UnitManagerRole: Role = {
  name: BookingRoleNames.UNIT_MANAGER,
  permissions: Object.values(BookingActions)
    .filter((action) => !prohibitedActions.includes(action))
    .map((action) => ({ action })),
}
