import { ApiError } from '@guiker/base-rest-client'
import { Errors } from '@guiker/common'

enum LegacyApiErrorCode {
  USER_ALREADY_REGISTERED = '20101',
}

interface LegacyApiErrorItem {
  status: number
  code: string // TODO: map it with Java error enum
  title: string
  details: string
  source: string
}

class LegacyApiError extends Errors.BaseError {
  items: LegacyApiErrorItem[]
  response: unknown

  constructor(error: ApiError) {
    super(`Error while processing legacy api request`, LegacyApiError.name, error.statusCode, error.meta)

    if (error.meta) {
      this.items = error.meta as LegacyApiErrorItem[]
      this.response = error.message
    }
  }
}

export { LegacyApiError, LegacyApiErrorItem, LegacyApiErrorCode }
