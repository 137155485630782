import { webappRoutes as rentalListingWebappRoutes } from '@guiker/rental-listing-shared'

export const webappRoutes = {
  Home: {
    path: '/',
  },
  LogIn: {
    path: '/login',
  },
  SignUp: {
    path: '/signup',
  },
  DocumentSuccess: {
    path: '/document/success',
  },
  Redirect: {
    path: '/redirect',
  },
  RequestForgotPassword: {
    path: '/forgot-password',
  },
  RequestForgotPasswordSuccess: {
    path: '/forgot-password-success',
  },
  Investors: {
    path: '/investors',
  },
  Invited: {
    path: '/invited',
  },
  About: {
    path: '/about-us',
  },
  Careers: {
    path: '/careers',
  },
  PrivacyPolicy: {
    path: '/privacy-policy',
  },
  TermsAndConditions: {
    path: '/terms-and-conditions',
  },
  Rent: {
    path: '/rent',
  },
  Listings: rentalListingWebappRoutes,
  Invest: {
    path: '/invest',
    learnMore: function () {
      return {
        path: `${this.path}/learn-more`,
      }
    },
  },
  Services: {
    path: '/services',
    learnMore: function () {
      return {
        path: `${this.path}/learn-more`,
      }
    },
  },
} as const
