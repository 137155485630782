import React, { createContext, useState } from 'react'

import { scrollToAnchor } from '@guiker/components-library'
import { generateUseContext } from '@guiker/react-framework'

import { GlosseryDrawer } from '../components'

type Context = {
  onClose: () => void
  onOpen: (event: React.SyntheticEvent, id?: string) => void
  anchor: Element
  content: React.ReactNode
  setContent: React.Dispatch<React.SetStateAction<React.ReactNode>>
}

const GlosseryDrawerContext = createContext<Context>(null)

const GlosseryDrawerContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [anchor, setAnchor] = useState<Element>(null)
  const [content, setContent] = useState<React.ReactNode>()

  const onOpen = (event: React.SyntheticEvent, id: string) => {
    scrollToAnchor(id)
    setAnchor(event.currentTarget)
  }

  const onClose = () => {
    setAnchor(null)
  }

  const value = {
    onClose,
    onOpen,
    anchor,
    content,
    setContent,
  }

  return (
    <GlosseryDrawerContext.Provider value={value}>
      {children}
      <GlosseryDrawer />
    </GlosseryDrawerContext.Provider>
  )
}

const useGlosseryDrawerContext = generateUseContext(GlosseryDrawerContext)

export { GlosseryDrawerContext, GlosseryDrawerContextProvider, useGlosseryDrawerContext }
