import { yup } from '@guiker/yup-util'

import { Trigger } from './trigger'

export const triggerSchema = yup.object<Trigger>({
  context: yup.string().required(),
  target: yup
    .object({
      id: yup.string().required(),
      type: yup.string().required(),
    })
    .required(),
  action: yup.string().required(),
  executesAt: yup.date(),
  payload: yup.mixed(),

  uuid: yup.string().required(),
  correlatedRequestId: yup.string(),
  sourceUserId: yup.string(),
  serviceName: yup.string(),
  stage: yup.string(),
})
