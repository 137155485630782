import React from 'react'

import { PropSharingListingWithFundingData } from '@guiker/propsharing-shared'
import { InfiniteList, InfiniteListProps, PaginatedGrid, useLayoutContext } from '@guiker/react-framework'

import { PropSharingListingCard } from '../PropSharingListingCard'

type PropSharingListingGridProps = Omit<InfiniteListProps<PropSharingListingWithFundingData>, 'CellComponent'>

export const PropSharingListingGrid: React.FC<PropSharingListingGridProps> = (props) => {
  const { isMobile } = useLayoutContext()
  const CellComponent = ({ data }) => <PropSharingListingCard listing={data} shadow={true} />

  if (isMobile) {
    return <InfiniteList CellComponent={CellComponent} {...props} />
  }

  return <PaginatedGrid cacheKey={props.queryKey} {...props} CellComponent={CellComponent} />
}
