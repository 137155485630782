type ParsedName = {
  firstName?: string
  lastName?: string
}

export const parseToFirstAndLastName = (fullName: string, delimiter = ' '): ParsedName => {
  if (!fullName || !fullName.length) {
    return { firstName: undefined, lastName: undefined }
  }

  const splitName = fullName.split(delimiter)
  const lastName = splitName.pop()

  return {
    firstName: splitName.join(' ').trim() || lastName, // for cases where split array only has 1 element
    lastName,
  }
}
