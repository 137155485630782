import * as bookingRoutes from './booking'
import * as bookingApplicantRoutes from './booking-applicant'
import * as bookingDocumentRoutes from './booking-document'
import * as bookingPaymentRoutes from './booking-payment'
import * as bookingRoommateRoutes from './booking-roommate'
import * as bookingUnitManagerRoutes from './booking-unit-manager'

export const routes = {
  ...bookingRoutes.routes,
  ...bookingApplicantRoutes.routes,
  ...bookingDocumentRoutes.routes,
  ...bookingPaymentRoutes.routes,
  ...bookingUnitManagerRoutes.routes,
  ...bookingRoommateRoutes.routes,
}
