export { isInvitationClaims, parseInvitationToken } from '@guiker/authentication-shared'

import { useConfig } from '@guiker/config-context'
import { FeatureFlag } from '@guiker/feature-flag'
import { usePersistancyContext } from '@guiker/react-persistance'

import { ContextUser } from './types'

export const usePersistedUser = () => {
  const { data, setData } = usePersistancyContext<{ user?: ContextUser }>()
  const user = data?.user

  const setUser = (newUser?: ContextUser) => {
    setData({
      user:
        newUser?.id.toString() === user?.id.toString()
          ? { ...user, ...newUser, invitationToken: user.invitationToken }
          : newUser,
    })
  }

  const unsetUser = () => {
    setData({ user: null })
  }

  return { user, setUser, unsetUser }
}

export const useFeatureFlag = () => {
  const { stage } = useConfig()

  return (user: ContextUser) => FeatureFlag({ stage, user })
}
