import {
  Application,
  ApplicationStatus,
  ApplicationStatusGroup,
  RoommateIntroStatus,
  RoommateIntroStatusGroup,
} from '@guiker/booking-shared'

import { EnabledSteps, generateProgressTrackerItemBuilder } from '../../../../util'

const isReviewable = {
  application: (status: ApplicationStatus) => ApplicationStatusGroup.REVIEWED.includes(status),
  roommateIntro: (status: RoommateIntroStatus) => RoommateIntroStatusGroup.REVIEWED.includes(status),
}

export const getSteps = (args: { isBooked: boolean; enabledSteps: EnabledSteps; application: Application }) => {
  const { isBooked, enabledSteps, application } = args
  const isAvailable = (condition: boolean) => !isBooked || (isBooked && condition)

  const isApplicationAvailable = isAvailable(isReviewable.application(application?.status))
  const isRoommateProfileAvailable = isAvailable(isReviewable.roommateIntro(application?.roommateIntro?.status))

  return {
    application: {
      enabled: enabledSteps.application?.enabled && isApplicationAvailable,
      item: generateProgressTrackerItemBuilder(enabledSteps.application),
    },
    roommate: {
      enabled: enabledSteps.roommate?.enabled && isRoommateProfileAvailable,
      item: generateProgressTrackerItemBuilder(enabledSteps.roommate),
    },
    document: {
      enabled: enabledSteps.document?.enabled,
      item: generateProgressTrackerItemBuilder(enabledSteps.document),
    },
    lease: {
      enabled: enabledSteps.lease?.enabled,
      item: generateProgressTrackerItemBuilder(enabledSteps.lease),
    },
    payment: {
      enabled: enabledSteps.payment?.enabled,
      item: generateProgressTrackerItemBuilder(enabledSteps.payment),
    },
  }
}
