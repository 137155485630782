import React, { useEffect, useState } from 'react'

import { User } from '@guiker/authentication-shared'
import { Booking, BookingActions, BookingRoleResolver, BookingStatus, mainPathBuilder } from '@guiker/booking-shared'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { mainPathBuilder as conversationPathBuilder } from '@guiker/conversation-shared'
import { PageNotFound } from '@guiker/error-pages'
import { initializeCanWithUser } from '@guiker/permissions'
import { FullScreenSpinner, useLocationQuery, useNavigate, useQuery } from '@guiker/react-framework'

import { toBookingUser } from './booking-user'
import { BookingContext } from './BookingContext'
import { getApiClient } from './get-api-client'
import { getJwtApiClient } from './get-jwt-api-client'

export const BookingJwtContextProvider: React.FC<React.PropsWithChildren & { bookingId: string }> = ({
  bookingId,
  children,
}) => {
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()
  const jwtApiClient = getJwtApiClient()
  const apiClient = getApiClient()
  const navigate = useNavigate()

  const chatLinkPath = conversationPathBuilder.root.messages.path({ bookingId })
  const [booking, setBooking] = useState<Booking>(null)

  const {
    data,
    isLoading: isLoadingBooking,
    isFetching: isFetchingBooking,
    error,
  } = useQuery(
    ['booking', bookingId, claims],
    (_) => jwtApiClient.readOneByInvitedUnitManagerToken({ pathParams: { bookingId } }),
    { retry: 1, enabled: !!claims, onSuccess: setBooking },
  )

  useEffect(() => {
    setClaimsFromToken(token)
  }, [token])

  // @TODO: find() in new `collaborators` property when ready
  const unitManager = (data as Booking)?.unitManagers.find(
    (um) => um?.userId === claims?.userId || um?.emailAddress === claims?.emailAddress,
  )

  const isFetching = isLoadingBooking || isFetchingBooking

  if (isFetching || !data) {
    return <FullScreenSpinner />
  }

  if (!claims || !unitManager) {
    return <PageNotFound />
  }

  const user = {
    id: unitManager.userId,
    emailAddress: unitManager.emailAddress,
    firstName: unitManager.firstName,
    lastName: unitManager.lastName,
    avatar: unitManager.avatarUrl,
  } as User

  const { can, canOrThrow } = initializeCanWithUser<Booking, BookingActions>({
    user,
    roleResolver: BookingRoleResolver,
    entity: booking,
  })

  const value = {
    booking: (booking || data) as Booking,
    bookingId: bookingId || booking.id,
    can,
    canOrThrow,
    apiClient,
    bookingUser: toBookingUser({ user, booking }),
    isFetching,
    error,
    linkPath: mainPathBuilder.root.byId(bookingId || booking.id),
    bookingChat: {
      path: chatLinkPath,
      navigate: () => navigate(chatLinkPath),
    },
    bookingStatus: {
      isBooked: booking?.status === BookingStatus.BOOKED,
      isExpired: booking?.status === BookingStatus.EXPIRED,
    },
  }

  return <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
}
