import { generateEventsDefinition } from '@guiker/event'

import { Series, Stock } from '../../entity'
import { baseEvent } from '../base'

export const StockEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'STOCK',
  data: null as unknown as Stock,
  eventTypes: {
    SERIES_UPDATED: 'SERIES_UPDATED',
    SERIES_CLOSED: {
      type: 'SERIES_CLOSED',
      eventData: null as { series: { slug: string }[] },
    },
    SERIES_CANCELLED: {
      type: 'SERIES_CANCELLED',
      eventData: null as { series: { slug: string }[] },
    },
    SERIES_CLOSING_EQUITY_DATA_UPDATED: {
      type: 'SERIES_CLOSING_EQUITY_DATA_UPDATED',
      eventData: null as { series: Series[] },
    },
    SERIES_ADDED: {
      type: 'SERIES_ADDED',
      eventData: null as { series: Series[] },
    },
    LIQUIDATION_DELISTED: {
      type: 'LIQUIDATION_DELISTED',
      eventData: null as { lastProcessedUserId?: string },
    },
  } as const,
})
export const StockEvents = StockEventsDefinition.events
export const StockEventTypes = StockEventsDefinition.eventTypes
