import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const rentDiscounts = yup.object<Omit<Ontario.RentDiscounts, 'completed'>>({
  isDiscounted: yup.boolean().required(),
  discountAmount: yup.string().when('isDiscounted', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

export type RentDiscounts = TypeOf<typeof rentDiscounts>
