import { yup } from '@guiker/shared-framework'

import { SupportedCountries } from '../../../../entity'

export const createPayInMethodPayloadSchema = yup.object<{
  region: SupportedCountries
  tempToken: string
  recaptchaToken: string
  // legacy info
  paymentVerificationId?: number
  paymentAgentId?: number
}>({
  region: yup.string().oneOf(Object.values(SupportedCountries)).required(),
  tempToken: yup.string().required(),
  recaptchaToken: yup.string().required(),
  paymentVerificationId: yup.number().nullable().optional(),
  paymentAgentId: yup.number().optional(),
})
