import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const servicesAndUtilities = yup.object<Omit<Ontario.ServicesAndUtilities, 'completed'>>({
  gas: yup.mixed<Ontario.YesNoTypes>().oneOf(Object.values(Ontario.YesNoTypes)).required(),
  airConditioning: yup.mixed<Ontario.YesNoTypes>().oneOf(Object.values(Ontario.YesNoTypes)).required(),
  onSiteLaundry: yup
    .mixed<Ontario.YesNoWithChargeTypes>()
    .oneOf(Object.values(Ontario.YesNoWithChargeTypes))
    .required(),
  guestParking: yup.mixed<Ontario.YesNoWithChargeTypes>().oneOf(Object.values(Ontario.YesNoWithChargeTypes)).required(),
  otherServicesAndUtilities: yup.string().nullable(),
  electricity: yup.mixed<Ontario.LessorLesseeTypes>().oneOf(Object.values(Ontario.LessorLesseeTypes)).required(),
  heat: yup.mixed<Ontario.LessorLesseeTypes>().oneOf(Object.values(Ontario.LessorLesseeTypes)).required(),
  water: yup.mixed<Ontario.LessorLesseeTypes>().oneOf(Object.values(Ontario.LessorLesseeTypes)).required(),
  lesseeResponsibilityDetails: yup.string().nullable(),
})

export type ServicesAndUtilities = TypeOf<typeof servicesAndUtilities>
