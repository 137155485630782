import React, { createContext, useContext, useEffect, useState } from 'react'

import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { FullScreenSpinner, useLocationQuery, useQuery } from '@guiker/react-framework'
import {
  initializeDropdownRentalOptions,
  RentalListing,
  TransformedRentalOption,
  transformLegacyListingToListing,
} from '@guiker/rental-listing-shared'

import {
  useAuthenticatedListingApiClient,
  useBookingContext,
  useJwtAuthenticatedListingApiClient,
} from '../../../hooks'

type BookingPanelContextType = {
  rentalOptions: TransformedRentalOption[]
  setRentalOptions: React.Dispatch<React.SetStateAction<TransformedRentalOption[]>>
  listing: RentalListing
  selectedPriceOptionId: string
  setSelectedPriceOptionId: React.Dispatch<React.SetStateAction<string>>
}

export const BookingPanelContext = createContext<BookingPanelContextType>(null)

export const BookingPanelContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [rentalOptions, setRentalOptions] = useState<TransformedRentalOption[]>()
  const listingApiClient = useAuthenticatedListingApiClient()
  const jwtListingApiClient = useJwtAuthenticatedListingApiClient()
  const { token } = useLocationQuery('token')
  const { setClaimsFromToken, claims } = useClaimsAuthenticationContext()
  const { booking } = useBookingContext()
  const [selectedPriceOptionId, setSelectedPriceOptionId] = useState<string>()

  const { data: listing, isFetching } = useQuery(
    'listing',
    (_) =>
      (claims ? jwtListingApiClient : listingApiClient)
        .readOneListing({
          pathParams: { listingId: String(booking.listing.externalId) },
        })
        .then((result) => transformLegacyListingToListing({ listing: result })),
    {
      retry: 1,
      enabled: !!booking || !!claims,
    },
  )

  useEffect(() => {
    listing && setRentalOptions(initializeDropdownRentalOptions(listing))
  }, [listing])

  useEffect(() => {
    booking && setSelectedPriceOptionId(String(booking.optionId))
  }, [booking])

  useEffect(() => {
    setClaimsFromToken(token)
  }, [token])

  const value = {
    rentalOptions,
    setRentalOptions,
    selectedPriceOptionId,
    setSelectedPriceOptionId,
    listing,
  }

  if (isFetching || !listing || !booking) {
    return <FullScreenSpinner />
  }

  return <BookingPanelContext.Provider value={value}>{children}</BookingPanelContext.Provider>
}

export const useBookingPanelContext = () => {
  const context = useContext(BookingPanelContext)

  if (context === undefined) {
    throw new Error('useBookingPanelContext can only be used inside useBookingPanelContextProvider')
  }

  return context
}
