import { TypeOf, yup } from '@guiker/yup-util'

import { leadOptionSchema } from '../../schemas'
import { createListingPayloadSchema } from './create-listing-payload-schema'
import { updateListingPayloadSchema } from './update-listing-payload-schema'

export const upsertListingPayloadSchema = createListingPayloadSchema.concat(updateListingPayloadSchema).shape({
  externalId: yup.string().required(),
  title: yup.string().optional(),
  leadOption: leadOptionSchema.required(),
  source: yup.object({
    name: yup.string().required(),
    cityId: yup.number().required(),
    postedBy: yup.string().nullable(),
    reference: yup.string().nullable(),
  }),
  shouldProcessListingLead: yup.boolean(),
})

export type UpsertListingPayloadSchema = TypeOf<typeof upsertListingPayloadSchema>
