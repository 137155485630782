import React from 'react'

import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { Currency } from '@guiker/money'

import { useTranslation } from '../../hooks'
import { ColumnGridLayout, numberFormatter } from '..'
import { ResultItem } from './ResultItem'

type CardResultsProps = {
  currency: Currency
  inquiryResults: InvestmentAssumptions.Results
}

export const CardResults: React.FC<CardResultsProps> = ({ inquiryResults, currency }) => {
  const { t } = useTranslation(['common', 'main-propertySale'])
  const resultTPrefix = 'common-propertySale:listing.results'

  return (
    <ColumnGridLayout gap={2}>
      <ResultItem
        label={t(`${resultTPrefix}.estimatedSalesPrice`)}
        name='results.estimatedSalesPrice'
        value={inquiryResults.estimatedSalesPrice}
        currency={currency}
        type='currency'
      />
      <ResultItem
        label={t(`${resultTPrefix}.targetEquity`)}
        name='results.targetEquity'
        value={inquiryResults.targetEquity}
        currency={currency}
        type='currency'
      />
      <ResultItem
        label={t(`${resultTPrefix}.irr`)}
        name='results.irr'
        value={numberFormatter.percentage(4).toDisplay(inquiryResults.irr)}
      />
    </ColumnGridLayout>
  )
}
