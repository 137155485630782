export enum LeaseTermTypes {
  Fixed = 'fixed',
  Monthly = 'monthly',
  Other = 'other',
  Indeterminate = 'indeterminate',
}

export type LeaseTerm = {
  type: LeaseTermTypes
  startDate: string
  endDate?: string | null
  otherDescription?: string
  completed?: boolean
}
