import React from 'react'

import { isMobile, isNotMobile, makeStyles, theme } from '@guiker/components-core'

import { Box, H2, H4, P, PageMetaTags, PBig, PBold } from '../../components'
import { useTranslation } from '../../hooks'
import { termsAndConditionsContent } from './terms-conditions-content'

const useStyle = makeStyles({
  pageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [isMobile]: {
      padding: theme.spacing(3),
    },
    [isNotMobile]: {
      padding: theme.spacing(10),
    },
    backgroundColor: theme.palette.common.white,
  },
  contentContainer: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    maxWidth: 1440,
    flexDirection: 'column',
  },
})

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const classes = useStyle()
  const tcc = termsAndConditionsContent()

  const buildListContent = (list) => {
    return list.map((item) => (
      <Box key={item.title}>
        <PBold color={80}>{item.title}</PBold>
        {item.clauses.map((clause, index: number) => (
          <P color={80} key={`${item.title}-clause${index}`}>
            {clause}
          </P>
        ))}
      </Box>
    ))
  }

  return (
    <>
      <PageMetaTags
        subtitle={t('termsAndConditions.seo.subtitle')}
        description={t('termsAndConditions.seo.description')}
      />
      <Box className={classes.pageContainer}>
        <Box className={classes.contentContainer}>
          <H2>{tcc.title}</H2>
          <PBig>{tcc.lastUpdated}</PBig>
          <Box>
            <H4>{tcc.generalTerms.title}</H4>
            {buildListContent(tcc.generalTerms.list)}
          </Box>
          <Box>
            <H4>{tcc.tenantsTerms.title}</H4>
            {buildListContent(tcc.tenantsTerms.list)}
          </Box>
          <Box>
            <H4>{tcc.landLordTerms.title}</H4>
            {buildListContent(tcc.landLordTerms.list)}
          </Box>
          <Box>
            <H4>{tcc.miscellaneous.title}</H4>
            {buildListContent(tcc.miscellaneous.list)}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export { TermsAndConditions }
