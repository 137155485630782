import { last } from '@guiker/shared-framework'

import {
  FrequencyType,
  PropSharingListingWithFundingData,
  SeriesStatus,
  Stock,
  StockConfiguration,
  StockWithLatestFinancialStatements,
} from '../entity'
import { hydrateFinancialStatements } from './financial-statements'
import { hydrateSeries } from './series'

export const isStockWithLatestFinancialStatements = (
  stock: Stock | StockWithLatestFinancialStatements,
): stock is StockWithLatestFinancialStatements => {
  return !!(stock as StockWithLatestFinancialStatements).latestFinancialStatements
}

export const hydrateStock = <S extends Stock | StockWithLatestFinancialStatements>(stock: S): S => {
  if (stock && isStockWithLatestFinancialStatements(stock)) {
    stock.latestFinancialStatements = hydrateFinancialStatements(stock.latestFinancialStatements)
  }

  return {
    ...stock,
    series: stock?.series?.map((s) => hydrateSeries(s)),
  }
}

export const getTargetEquities = (stockConfiguration: StockConfiguration) => {
  const { pricePerUnit, total } = stockConfiguration
  return pricePerUnit.amount * total
}

const getAllOpenOrClosedSeries = (stock: Stock) =>
  stock.series?.filter((s) => [SeriesStatus.OPEN, SeriesStatus.CLOSED].includes(s.status))

export const totalUnitQuantity = (stock: Stock) => {
  return getAllOpenOrClosedSeries(stock).reduce(
    (acc, series) => acc + (series.final?.unitQuantity || series.target?.unitQuantity || 0),
    0,
  )
}

export const totalEquity = (stock: Stock) => {
  return getAllOpenOrClosedSeries(stock).reduce(
    (acc, series) => acc + (series.final?.equity || series.target?.equity || 0),
    0,
  )
}

export const findLatestSeries = (stock: Stock, options: { status?: SeriesStatus } = {}) => {
  const series = stock?.series || []
  return options.status ? last(series.filter((s) => s.status === options.status)) : last(series)
}

export const getCurrentMarketValue = (stock: Stock) => {
  return findLatestSeries(stock)?.pricePerUnit
}

export const getStockHelper = (listing: PropSharingListingWithFundingData) => {
  const { stock } = listing
  const series = findLatestSeries(stock)
  const targetEquity = series.target.equity
  const latestFinancialStatements = (stock as StockWithLatestFinancialStatements).latestFinancialStatements

  const propertyValue = latestFinancialStatements?.balanceSheet.assets?.find((a) => a.label === 'propertyValue')?.amount
  const pricePerUnit = latestFinancialStatements?.shareholderEquityStatement?.netAssetValuePerUnit

  const soldFromSeries = stock.series.reduce((acc, s) => {
    if (s.target.unitQuantity < 0) {
      return acc + s.target.unitQuantity
    } else {
      return acc
    }
  }, 0)

  const availableEquities = (listing.totalUnitQuantity - listing.totalUnitSold - soldFromSeries) * pricePerUnit
  const totalEquities = listing.totalUnitQuantity * pricePerUnit

  const hasOpenSeries = stock.series?.some((s) => s.status === SeriesStatus.OPEN)
  const latestSeries = findLatestSeries(listing.stock)

  const percent = (totalEquities - availableEquities) / totalEquities
  const allSeriesClosed = stock.series?.every((s) => s.status === SeriesStatus.CLOSED)
  const isFullyFunded = percent >= 1 || allSeriesClosed
  const percentFunded = allSeriesClosed ? 1 : percent
  const isSold = latestFinancialStatements?.frequencyType === FrequencyType.CLOSING

  return {
    targetEquity,
    availableEquities,
    totalEquities,
    hasOpenSeries,
    percentFunded,
    latestSeries,
    isFullyFunded,
    propertyValue,
    pricePerUnit,
    isSold,
  }
}
