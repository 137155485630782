import { routesBuilder } from '@guiker/shared-framework'

import { Invoice } from '../../../../entity'
import { paths } from '../../paths'
import { createChargableInvoicePayloadSchema, createInvoiceWithScopePayloadSchema } from '../schemas'

const basePath = paths.invoice.all.withScope()
const { buildPost, buildGet } = routesBuilder<Invoice>()({ basePath })

export const routes = {
  readLastInvoiceByScope: buildGet({
    path: '/last',
  }),
  createImmediateChargeableInvoice: buildPost({
    path: '/create-chargeable',
    payloadSchema: createChargableInvoicePayloadSchema,
  }),
  createChargeableInvoiceWithScope: buildPost({
    path: '/chargeable',
    payloadSchema: createInvoiceWithScopePayloadSchema,
  }),
  createInvoiceWithScope: buildPost({
    payloadSchema: createInvoiceWithScopePayloadSchema,
  }),
} as const
