import { Payment } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'
import { PayInMethodType } from '@guiker/payment-shared'
import { PayoutProvider, PayoutProviderName, PayoutProviderStatus } from '@guiker/payout-shared'
import { compact } from '@guiker/shared-framework'

import { PayoutMethod } from '../entity'

type Eligibility = Payment.PaymentEligibility

const transactablePaymentType = {
  [CurrencyISO.CAD]: ['creditCard', 'directDebitPAD'],
  [CurrencyISO.USD]: ['creditCard', 'directDebitACH'],
}

const canProviderTransact = (provider: PayoutProvider, paymentType: keyof Eligibility, currency: CurrencyISO) => {
  const transactableStatus: PayoutProviderStatus[] = ['ENABLED', 'NEED_MORE_INFORMATION']

  if (!transactableStatus.includes(provider.status)) {
    return false
  }

  switch (provider.name) {
    case PayoutProviderName.STRIPE:
      return transactablePaymentType[currency]?.includes(paymentType)
    default:
      return false
  }
}

export const getPaymentEligibility = (payoutMethod: PayoutMethod) => {
  const eligibility: Eligibility = {
    creditCard: false,
    directDebitEFT: false,
    directDebitACH: false,
    directDebitPAD: false,
  }

  if (!payoutMethod) {
    return eligibility
  }

  const providers = payoutMethod.provider
  const currency = payoutMethod.currency

  eligibility.creditCard = providers.some((p) => canProviderTransact(p, 'creditCard', currency))
  eligibility.directDebitPAD = providers.some((p) => canProviderTransact(p, 'directDebitPAD', currency))
  eligibility.directDebitACH = providers.some((p) => canProviderTransact(p, 'directDebitACH', currency))

  return eligibility
}

const EligibilityMap = {
  creditCard: PayInMethodType.CREDIT_CARD,
  directDebitACH: PayInMethodType.DIRECT_DEBIT_ACH,
  directDebitEFT: PayInMethodType.DIRECT_DEBIT_EFT,
  directDebitPAD: PayInMethodType.DIRECT_DEBIT_PAD,
  fundingAccount: PayInMethodType.FUNDING_ACCOUNT,
} as const

export const mapEligibilityToPayInMethodTypes = (eligibility: Eligibility) => {
  return compact(
    Object.keys(eligibility).map((key: keyof Eligibility) => (eligibility[key] ? EligibilityMap[key] : undefined)),
  )
}

export const getAllowedTypesFromEligibility = (payoutMethod: PayoutMethod) => {
  return mapEligibilityToPayInMethodTypes(getPaymentEligibility(payoutMethod))
}
