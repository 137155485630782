export enum LessorLesseeTypes {
  lessor = 'lessor',
  lessee = 'lessee',
}

export enum YesNoTypes {
  yes = 'yes',
  no = 'no',
}

export enum YesNoWithChargeTypes {
  yes = 'yes',
  noCharge = 'noCharge',
  payPerUse = 'payPerUse',
  no = 'no',
}

export type ServicesAndUtilities = {
  completed?: boolean
  gas: YesNoTypes
  airConditioning: YesNoTypes
  onSiteLaundry: YesNoWithChargeTypes
  guestParking: YesNoWithChargeTypes
  otherServicesAndUtilities?: string
  otherServicesAndUtilitiesDetails?: string
  electricity: LessorLesseeTypes
  heat: LessorLesseeTypes
  water: LessorLesseeTypes
  lesseeResponsibilityDetails?: string
}
