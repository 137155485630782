export enum RentFrequency {
  month = 'month',
  other = 'other',
}

export interface RentOtherAmount {
  otherAmount: number
  otherDescription: string
}

export enum PaymentMethod {
  guikerOnlinePayment = 'Guiker Online Payment',
}

export interface Rent {
  completed?: boolean
  firstPaymentDate?: string
  paymentDate: string
  rentFrequency: RentFrequency
  rentAmount: number
  paymentMethod: string
  totalRentAmount?: number
  rentFrequencyOther?: string
  parkingAmount?: number
  rentOtherAmounts?: RentOtherAmount[]
  rentPayableTo: string
  partialPaymentAmount?: number
  partialRentCoversFromDate?: string
  partialRentCoversToDate?: string
  nsfChargeAmount?: number
}
