import { AnyColor } from '@guiker/components-core'
import { ValueOfUnion } from '@guiker/shared-framework'
import { RentPayoutStatus } from '@guiker/statistic-shared'

export const findPayoutColor = (status: ValueOfUnion<RentPayoutStatus>): AnyColor => {
  const mapper: {
    [key in ValueOfUnion<RentPayoutStatus>]: AnyColor
  } = {
    PENDING: 'info',
    IN_TRANSIT: 'active',
    FAILED: 'alert',
    CANCELED: 'alert',
    PAID: 'success',
    PAID_OFFLINE: 'success',
  }

  return mapper[status]
}
