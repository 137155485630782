import { User } from '@guiker/authentication-shared'
import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { LeaseContract, SignedWith } from '../entity'

export const EventsDefinition = generateEventsDefinition({
  context: 'LEASE_CONTRACT',
  entity: 'LEASE_CONTRACT',
  data: null as unknown as LeaseContract,
  eventTypes: {
    CREATED: 'CREATED',
    DELETED: 'DELETED',
    UPDATED: 'UPDATED',
    DRAFTED: {
      type: 'DRAFTED',
      eventData: null as {
        user: User
        signedWith?: SignedWith
        listingId: string
      },
    },
    COMPLETED: 'COMPLETED',
  } as const,
})
export const Events = EventsDefinition.events
export const EventTypes = EventsDefinition.eventTypes
export type EventTypes = ValueOfEventTypes<typeof EventsDefinition>
