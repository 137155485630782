import { AdminApiClient } from '@guiker/payment-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'

export const {
  useContext: useAdminFundingAccountContext,
  Context: AdminFundingAccountContext,
  ContextProvider: AdminFundingAccountContextProvider,
  NestedRouter: AdminFundingAccountNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AdminApiClient,
  cacheKey: ({ userId }: { userId: string }) => `readOneFundingAccountByUser-${userId}`,
  fetcher: (apiClient, { userId }: { userId: string }) => {
    return apiClient.readFundingAccountByUser({ pathParams: { userId } })
  },
  renderLoadingSpinner: false,
})
