import { useContext } from 'react'

import { Context, DataTrackingContext } from './DataTrackingContext'

export const useDataTrackingContext = (): Context => {
  const context = useContext(DataTrackingContext)

  if (context === undefined) {
    throw new Error('useGoogleTagManager can only be used inside DataTrackingContext')
  }

  if (context === null) {
    console.warn('DataTrackingContext is not initialized')

    return {
      dataLayer: { push: (e) => console.warn(e) },
      sendEvent: (e) => console.warn(e),
      sendPageView: (e) => console.warn(e),
    } as Context
  }

  return context
}
