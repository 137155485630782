export enum Visibility {
  'SHOW' = 'SHOW',
  'HIDE' = 'HIDE',
}

export type Assignee = {
  visibility: Visibility
  userId: string
  emailAddress?: string
  firstName?: string
  lastName?: string
}
