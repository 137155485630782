import React, { createContext, PropsWithChildren } from 'react'

import { generateUseContext } from '@guiker/react-context'

import { BaseTaskContext, TaskContextProvider } from '../base'
import { useAuthApiClient } from './use-api-client'

export const AuthenticatedTaskContext = createContext<BaseTaskContext>(null)

export const AuthenticatedTaskContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const taskApiClient = useAuthApiClient()

  return (
    <TaskContextProvider apiClient={taskApiClient} ContextProvider={AuthenticatedTaskContext.Provider}>
      {children}
    </TaskContextProvider>
  )
}

export const useAuthenticatedTaskContext = generateUseContext(AuthenticatedTaskContext)
