import React from 'react'

import { usePayInMethodContext, usePaymentApiClient } from '@guiker/payment-context'
import { AuthApi } from '@guiker/payment-shared'
import { ApiForm, Flex, Modal, useScreenSize, useTranslation, yupResolver } from '@guiker/react-framework'
import { RecaptchaProvider, useRecaptcha } from '@guiker/react-recaptcha'
import { RecaptchaAction } from '@guiker/recaptcha-action'

import { DirectDebitACHCreationForm } from './DirectDebitACHCreationForm'
import { DirectDebitPADCreationForm } from './DirectDebitPADCreationForm'

type Props = {
  closeForm: () => void
  type: 'ACH' | 'PAD'
}

const Content: React.FC<Props> = ({ closeForm, type }) => {
  const resolver = yupResolver(
    (AuthApi.Schemas.createDirectDebitWithVerificationPayloadSchema as any).pick(['bankAccountId', 'publicToken']),
  )
  const apiClient = usePaymentApiClient()
  const { region, onPaymentMethodAdded } = usePayInMethodContext()
  const { executeRecaptcha } = useRecaptcha()

  const onSubmit = async (payload: Required<AuthApi.Schemas.CreateDirectDebitWithVerificationPayloadSchema>) => {
    const recaptchaToken = await executeRecaptcha(RecaptchaAction.createDirectDebit)
    return apiClient
      .createDirectDebitWithVerification({ payload: { ...payload, recaptchaToken, region } })
      .then((p) => onPaymentMethodAdded(p))
      .finally(() => closeForm())
  }

  return (
    <ApiForm
      onSubmit={onSubmit}
      formOptions={{ resolver }}
      apiOptions={{ skipMutation: true }}
      formName={'CreateDirectDebitForm'}
    >
      {({ isLoading, isSuccess }) => (
        <>
          {type === 'ACH' && <DirectDebitACHCreationForm isProcessing={isLoading || isSuccess} closeForm={closeForm} />}
          {type === 'PAD' && <DirectDebitPADCreationForm isProcessing={isLoading || isSuccess} closeForm={closeForm} />}
        </>
      )}
    </ApiForm>
  )
}

type DirectDebitModalProps = {
  type: 'ACH' | 'PAD'
}

const DirectDebitModal: React.FC<DirectDebitModalProps> = ({ type }) => {
  const { t } = useTranslation('common')
  const { isSm } = useScreenSize()
  const { modalManager } = usePayInMethodContext()
  const { isOpen, closeModal } = modalManager.addDirectDebit

  return (
    <Modal
      fullScreen={!isSm}
      open={isOpen}
      onClose={closeModal}
      title={t('common-payment:directDebit.title')}
      maxWidth={750}
      disableEnforceFocus={true}
      disableBackdropClick
    >
      <Flex flexDirection='column' gap={2}>
        <RecaptchaProvider>
          <Content type={type} closeForm={closeModal} />
        </RecaptchaProvider>
      </Flex>
    </Modal>
  )
}

export { DirectDebitModal }
