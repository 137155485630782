import { yup } from '@guiker/yup-util'

import { TenantPreference } from '../../entity'
import { buildingPreferences, leasePreferences, unitPreferences } from './'

export const tenantPreference = yup.object<Omit<TenantPreference, 'userId' | 'id' | 'createdAt' | 'updatedAt'>>({
  emailNotificationEnabled: yup.boolean().nullable(),
  leasePreferences,
  unitPreferences,
  buildingPreferences,
})

export type TenantPreferenceSchema = yup.InferType<typeof tenantPreference>
