import { compareDate, DateTime } from '@guiker/date'

import { PayInMethod, typeChecker } from '../entity'

const payInMethodInfoBuilder = (payInMethod: PayInMethod) => ({
  getLastDigits: (): string => {
    const masked = '----'
    if (!payInMethod) return masked

    if (typeChecker.isCreditCard(payInMethod)) {
      return payInMethod.details.lastDigits
    } else if (typeChecker.isEFT(payInMethod)) {
      return payInMethod.details?.accountNumber ?? masked
    } else if (typeChecker.isACH(payInMethod) || typeChecker.isPAD(payInMethod)) {
      return payInMethod.details?.accountNumber
    } else if (typeChecker.isFundingAccount(payInMethod)) {
      return ''
    } else {
      return masked
    }
  },
  getExpirationDate: (fromFormat = 'M/yyyy'): string => {
    return typeChecker.isCreditCard(payInMethod)
      ? DateTime.fromFormat(payInMethod.details.expirationDate, fromFormat).toFormat('MM/yy')
      : null
  },
  getBrand: () => {
    return typeChecker.isCreditCard(payInMethod) ? payInMethod.details.brand : null
  },
  getInstitutionName: () => {
    if (typeChecker.isCreditCard(payInMethod)) {
      return payInMethod.details.brand
    } else if (typeChecker.isACH(payInMethod) || typeChecker.isPAD(payInMethod)) {
      return payInMethod.details.bankName
    } else {
      return undefined
    }
  },
  getExpectedSuccessDate: (chargeDate: DateTime): DateTime => {
    const today = DateTime.local()
    const compareNow = compareDate(today)
    if (!chargeDate) return
    let expectedDate

    const isAfterExpectedDate = (expectedDate: DateTime) => {
      return compareNow.isAfter(expectedDate)
    }

    if (typeChecker.isCreditCard(payInMethod) || typeChecker.isOffline(payInMethod)) {
      expectedDate = chargeDate.plus({ days: 1 })
      if (isAfterExpectedDate(expectedDate)) {
        return today
      }
      return expectedDate
    }
    if (typeChecker.isACH(payInMethod) || typeChecker.isEFT(payInMethod) || typeChecker.isPAD(payInMethod)) {
      expectedDate = chargeDate.plus({ days: 5 })
      if (isAfterExpectedDate(expectedDate)) {
        return today
      }
      return expectedDate
    } else {
      return today.plus({ days: 3 })
    }
  },
})

export { payInMethodInfoBuilder }
