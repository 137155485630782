import { yup } from '@guiker/yup-util'

export const plaidConfigSchema = yup
  .object({
    clientId: yup.string().required(),
    secret: yup.string().required(),
    url: yup.string().required(),
    webhook: yup.string().required(),
    customization: yup.string().required(),
  })
  .required()
