import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { flex, isMobile, makeStyles, padding, theme } from '@guiker/components-core'

import { Box, BoxProps, flattenChildren, P, TextButton } from '../../../components'
import { useTranslation } from '../../../i18n'

const useStyle = makeStyles(
  {
    mainContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(4),
      marginBottom: theme.spacing(10),
    },
    reverse: {
      flexDirection: 'row-reverse',
      [isMobile]: {
        flexDirection: 'row',
      },
    },
    contentContainer: {
      display: 'flex',
      flex: flex(1, 1, 520),
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '@keyframes fadeInDown': {
      from: {
        opacity: 0,
        transform: 'translate3d(0, -20%, 0)',
      },
      to: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
    '@keyframes fadeOutUp': {
      from: {
        opacity: 1,
        transform: `translate3d(0, 0, 0)`,
      },
      to: {
        opacity: 0,
        transform: `translate3d(0, -20%, 0)`,
      },
    },
    fadeOut: {
      opacity: 0,
      animation: `$fadeOutUp 0.7s linear`,
      visibility: 'hidden',
    },
    fadeIn: {
      opacity: 1,
      animation: `$fadeInDown 0.7s linear`,
      visibility: 'inherit',
    },
    content: {
      backgroundColor: ({ showMoreContent }: { showMoreContent: boolean }) =>
        showMoreContent ? theme.palette.grey[5] : 'white',
      flex: flex(1, 1, 520),
      padding: padding(16, 9),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: 500,
      [isMobile]: {
        padding: padding(4, 2),
        minHeight: 'auto',
      },
    },
    imageContainer: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[5],
      flex: flex(1, 1, 520),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 500,
      '& > img': {
        width: 500,
        [isMobile]: {
          objectFit: 'contain',
          width: '100%',
        },
      },
      [isMobile]: {
        minHeight: 'initial',
      },
    },
    centerAlign: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    name: 'FadingCard',
  },
)

type FadeSectionProps = React.PropsWithChildren & {
  showMoreContent: boolean
}

const FadeSection: React.FC<FadeSectionProps> = ({ showMoreContent, children }) => {
  const classes = useStyle()
  return (
    <Box position='relative' display='flex' flexDirection='column' justifyContent='center'>
      {flattenChildren(children).map((child, index) => {
        const initial = index === 0
        return (
          <Box
            key={index}
            position={initial ? undefined : 'absolute'}
            className={clsx({
              [classes.fadeIn]: initial ? showMoreContent : !showMoreContent,
              [classes.fadeOut]: initial ? !showMoreContent : showMoreContent,
            })}
          >
            {child}
          </Box>
        )
      })}
    </Box>
  )
}

type FadingCardProps = BoxProps &
  React.PropsWithChildren & {
    direction?: 'left' | 'right'
    asset: string
    moreContent: React.ReactNode
    header: React.ReactNode
    cta: string
  }

export const FadingCard: React.FC<FadingCardProps> = ({
  direction = 'left',
  asset,
  moreContent,
  cta,
  children,
  header,
  ...props
}) => {
  const [showMoreContent, setShowMoreContent] = useState<boolean>(false)
  const classes = useStyle({ direction, showMoreContent })
  const { t } = useTranslation(['common'])

  const handleShowContent = () => setShowMoreContent(!showMoreContent)

  return (
    <Box className={clsx(classes.mainContainer, { [classes.reverse]: direction === 'right' })} {...props}>
      <div className={clsx(classes.contentContainer)}>
        <FadeSection showMoreContent={showMoreContent}>
          <div className={classes.content}>
            {header}
            <P>{moreContent}</P>
            <div>
              <TextButton onClick={handleShowContent}>{t('actions.close')}</TextButton>
            </div>
          </div>
          <div className={classes.content}>
            {header}
            <P>{children}</P>
            <div>
              <TextButton onClick={handleShowContent}>{cta}</TextButton>
            </div>
          </div>
        </FadeSection>
      </div>
      <div className={classes.imageContainer}>
        <img src={asset} alt='sectionAsset' />
      </div>
    </Box>
  )
}
