import { LegacyUser } from './legacy-user'
import { User } from './user'

export const parseLegacyUser = (data: LegacyUser): User => {
  const { id, email, phone, ...user } = { ...data }

  return {
    ...user,
    id: `${id}`,
    emailAddress: email,
    phoneNumber: phone,
  }
}
