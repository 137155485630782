import { yup } from '@guiker/yup-util'

export const upsertRealOwnerSchema = yup
  .object({
    owner: yup
      .object<{
        userId?: string
        emailAddress: string
        firstName: string
        lastName?: string
      }>({
        userId: yup.string().nullable().default(null),
        emailAddress: yup.string().required(),
        firstName: yup.string().required(),
        lastName: yup.string().nullable(),
      })
      .required(),
  })
  .required()
