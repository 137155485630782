import React from 'react'

import { PayInMethodType } from '@guiker/payment-shared'
import {
  Chip,
  makeStyles,
  PSmall,
  StatusKey,
  useDateFormatter,
  useTranslation,
  WithTooltip,
} from '@guiker/react-framework'
import {
  TenantInstalment,
  TenantInstalmentEventHistory,
  TenantInstalmentInvoiceEventStatus,
  TenantInstalmentStatus,
} from '@guiker/rent-payment-shared'
import { camelCase, compareDate, DateTime, last, optionalConcat } from '@guiker/shared-framework'

type PayoutMethodStatusChipProps = {
  tenantInstalment: TenantInstalment
  className?: string
  size?: 'small' | 'medium' | 'smaller'
  withDate?: boolean
}

export enum UpdatedStatus {
  upcoming = 'UPCOMING',
  dueNow = 'DUENOW',
}

const getUpdatedStatus = (
  status: TenantInstalmentStatus,
  chargeDate: string,
): UpdatedStatus | TenantInstalmentStatus => {
  const today = DateTime.local()
  if (status === TenantInstalmentStatus.created && compareDate(chargeDate).isTodayOrBefore(today)) {
    return UpdatedStatus.dueNow
  }
  if (
    status === TenantInstalmentStatus.created &&
    compareDate(chargeDate).getDifference(today, 'weeks').weeks <= 1 &&
    compareDate(chargeDate).isAfter(today)
  ) {
    return UpdatedStatus.upcoming
  }
  return status
}

const inferInvoiceFailedReason = (events: TenantInstalmentEventHistory[]) => {
  const lastEvent = last(events ?? [])
  if (!lastEvent) return

  return lastEvent.error?.code?.toLowerCase()
}

const inferInvoicePaidStatus = (events: TenantInstalmentEventHistory[]) => {
  const eventsDesc = (events ?? []).map((e) => e).reverse()
  const unsuccessfulStatuses = [
    TenantInstalmentInvoiceEventStatus.REFUND_SUCCEED,
    TenantInstalmentInvoiceEventStatus.TRANSFER_FAILED,
    TenantInstalmentInvoiceEventStatus.TRANSFER_REVERSED,
  ]
  const prevStatuses: TenantInstalmentInvoiceEventStatus[] = []

  for (const { status } of eventsDesc) {
    const hasUnsuccessfulStatus = prevStatuses.some((s) => unsuccessfulStatuses.includes(s))
    if (status === TenantInstalmentInvoiceEventStatus.CHARGE_SUCCEED && !hasUnsuccessfulStatus) {
      return true
    } else {
      prevStatuses.push(status)
    }
  }

  return false
}

const useGetStatusInfo = (
  tenantInstalment: TenantInstalment,
): { color: StatusKey; name: string; chargeDate?: string } => {
  const { formatDate } = useDateFormatter()
  const { status, chargeDate } = tenantInstalment
  const { created, processing, cancelled, failed, completed, refunded } = TenantInstalmentStatus
  const { dueNow, upcoming } = UpdatedStatus

  const updatedStatus = getUpdatedStatus(status, chargeDate)
  const hasPaidInvoice = status === processing && inferInvoicePaidStatus(tenantInstalment.events)
  const name = updatedStatus.toLowerCase()

  if (updatedStatus === created) {
    if (!!chargeDate) {
      const [year, month, day] = chargeDate.split('-')
      const formattedChargeDate = [month, day, year].join()
      const humanChargeDate = formatDate(formattedChargeDate)

      return { color: 'info', name, chargeDate: humanChargeDate }
    }

    return { color: 'info', name }
  } else if ([dueNow, upcoming].includes(updatedStatus as UpdatedStatus)) {
    return { color: 'warning', name }
  } else if (hasPaidInvoice) {
    return { color: 'positive', name: 'collected' }
  } else if (updatedStatus === processing) {
    return { color: 'active', name }
  } else if (updatedStatus === completed) {
    if (!!tenantInstalment.processedWith?.payer?.payInMethod) {
      const { type } = tenantInstalment.processedWith.payer.payInMethod
      return type === PayInMethodType.OFFLINE ? { color: 'positive', name: 'paidOffline' } : { color: 'success', name }
    }
    return { color: 'success', name }
  } else if (updatedStatus === failed) {
    const failedReason = inferInvoiceFailedReason(tenantInstalment.events)
    return { color: 'alert', name: failedReason || name }
  } else if ([cancelled, refunded].includes(updatedStatus as TenantInstalmentStatus)) {
    return { color: 'cancelled', name }
  }
}

export const TenantInstalmentStatusChip: React.FC<PayoutMethodStatusChipProps> = ({
  tenantInstalment,
  className,
  size = 'small',
  withDate = false,
}) => {
  const { t } = useTranslation('main-bookingRentPayment')
  const { color, name, chargeDate } = useGetStatusInfo(tenantInstalment)

  return (
    <Chip size={size} borderRadiusStyle='semi-round' color={color} className={className}>
      {withDate
        ? optionalConcat([t(`rentPaymentsPlanTable.status.${name}`), chargeDate], ' | ')
        : t(`rentPaymentsPlanTable.status.${name}`)}
    </Chip>
  )
}

const useStyle = makeStyles({
  status: {
    display: 'inline',
  },
})
export const TenantInstalmentStatusChipWithTooltip: React.FC<PayoutMethodStatusChipProps> = ({ tenantInstalment }) => {
  const classes = useStyle()
  const { t } = useTranslation('main-bookingRentPayment')
  const { name } = useGetStatusInfo(tenantInstalment)

  return (
    <WithTooltip
      title={
        <div>
          <TenantInstalmentStatusChip tenantInstalment={tenantInstalment} className={classes.status} size={'smaller'} />
          <PSmall color={'textSecondary'} component={'span'} ml={1}>
            {t(`rentPaymentsPlanTable.tooltip.${camelCase(name)}`)}
          </PSmall>
        </div>
      }
    >
      <TenantInstalmentStatusChip tenantInstalment={tenantInstalment} withDate={true} />
    </WithTooltip>
  )
}
