import { generateEventsDefinition } from '@guiker/event'

import { Lease } from '../../entity'
import { EventTypes as EventTypesEnum } from './event-types'

export const EventsDefinition = generateEventsDefinition({
  context: 'LEASE',
  entity: 'LEASE',
  data: null as unknown as Lease,
  eventTypes: EventTypesEnum,
})

export const { eventTypes: EventTypes, events: Events } = EventsDefinition
