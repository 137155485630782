import { parseDateTime } from '@guiker/date'
import { conditionallyRequiredSchema, yup } from '@guiker/yup-util'

import { Landlord, PreviousLeaseInfo } from '../../entity'
import { addressSchema } from './legal-address'

export const tenantApplicationLandlordSchema = (required: boolean) =>
  yup.object<Landlord>({
    legalFirstName: conditionallyRequiredSchema(yup.string(), required),
    legalLastName: conditionallyRequiredSchema(yup.string(), required),
    emailAddress: conditionallyRequiredSchema(yup.string().email(), required),
    phoneNumber: conditionallyRequiredSchema(yup.string(), required),
  })

export const tenantApplicationPreviousLeaseInfoSchema = (required: boolean) =>
  yup.object<PreviousLeaseInfo>({
    monthlyRent: conditionallyRequiredSchema(yup.number().min(0), required),
    landlord: conditionallyRequiredSchema(tenantApplicationLandlordSchema(required), required),
  })

export const tenantApplicationCurrentAddressSchema = addressSchema.shape({
  moveOutDate: yup
    .string()
    .required()
    .transform((date) => parseDateTime(date).toFormat('yyyy-LL-dd')),
  yearsAtAddress: yup.number().min(0).default(0).required(),
  isPreviouslyLessee: yup.boolean().required(),
  previousLeaseInfo: tenantApplicationPreviousLeaseInfoSchema(false).when('isPreviouslyLessee', {
    is: true,
    then: tenantApplicationPreviousLeaseInfoSchema(true).required(),
    otherwise: tenantApplicationPreviousLeaseInfoSchema(false)
      .nullable()
      .transform(() => null as null),
  }),
})
