import { InvestorProfile, InvestorProfileReviewalStatus, InvestorProfileStatus } from '../entity'

const unableToPurchaseStatus: InvestorProfileReviewalStatus[] = [
  InvestorProfileReviewalStatus.NOT_READY,
  InvestorProfileReviewalStatus.REJECTED,
]
const rejectedStatus: InvestorProfileReviewalStatus[] = [InvestorProfileReviewalStatus.REJECTED]
const incompleteProfileStatus: InvestorProfileReviewalStatus[] = [InvestorProfileReviewalStatus.NOT_READY]

export const isRejected = (investorProfile: Partial<InvestorProfile>) =>
  rejectedStatus.includes(investorProfile.reviewalStatus)
export const isIncomplete = (investorProfile: Partial<InvestorProfile>) =>
  incompleteProfileStatus.includes(investorProfile.reviewalStatus)
export const isAbleToPurchase = (investorProfile: Partial<InvestorProfile>) =>
  !unableToPurchaseStatus.includes(investorProfile.reviewalStatus)
export const hasTIN = (investorProfile: Partial<InvestorProfile>) =>
  !!investorProfile?.financial?.taxInfo?.details?.taxIdNumber
export const isCompleted = (investorProfile: Partial<InvestorProfile>) =>
  investorProfile.status === InvestorProfileStatus.INFORMATION_COMPLETED
