import { routesBuilder } from '@guiker/shared-framework'

import { Contract } from '../../entity'
import { paths } from './paths'
import {
  contractPropSharingBuyStockOrderGeneratePayloadSchema,
  contractPropSharingBuyStockOrderPreviewPayloadSchema,
  findOneContractQueryParamsSchema,
  generatePDFPayloadSchema,
} from './schemas'

const CONTRACT_PATH = paths.contract.path
const PROP_SHARING_STOCK_ORDER_PATH = paths.contract.propSharing.stockOrder.withId.path

const builder = {
  contract: {
    base: routesBuilder<Contract>()({ basePath: CONTRACT_PATH }),
    propSharing: {
      content: routesBuilder<{ content: string; fingerprint: string }>()({ basePath: PROP_SHARING_STOCK_ORDER_PATH }),
      generate: routesBuilder<{ content: string; fingerprint: string; contract: Contract; url: string }>()({
        basePath: PROP_SHARING_STOCK_ORDER_PATH,
      }),
    },
  },
}

const propSharingRoutes = {
  generateBuyStockOrderRiskAcknowledgement: builder.contract.propSharing.generate.buildPost({
    path: `/generate`,
    payloadSchema: contractPropSharingBuyStockOrderGeneratePayloadSchema,
  }),
  previewBuyStockOrderRiskAcknowledgement: builder.contract.propSharing.content.buildPost({
    path: `/preview`,
    payloadSchema: contractPropSharingBuyStockOrderPreviewPayloadSchema,
  }),
}

export const routes = {
  findOne: builder.contract.base.buildGet({
    queryParamsSchema: findOneContractQueryParamsSchema,
  }),
  generatePDF: builder.contract.base.buildPost({
    path: '/generate-pdf',
    payloadSchema: generatePDFPayloadSchema,
    response: null as {
      content: string
      fingerprint: string
    },
  }),
  ...propSharingRoutes,
}
