import { TypeOf, yup } from '@guiker/shared-framework'

import { Capabilities } from '../../../../entity'

export const updateCapabiltiesSchema = yup.object({
  capabilities: yup
    .array(yup.string().oneOf(Object.values(Capabilities)))
    .min(1)
    .required(),
})

export type UpdateCapabiltiesSchema = TypeOf<typeof updateCapabiltiesSchema>
