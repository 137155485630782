import { Application, ApplicationStatus } from '../application'

const REVIEWABLE = [ApplicationStatus.SUBMITTED, ApplicationStatus.ACCEPTED, ApplicationStatus.CHANGE_REQUESTED]
const PRE_REVIEW = [ApplicationStatus.INITIAL, ApplicationStatus.STARTED, ApplicationStatus.COMPLETED]

export const ApplicationStatusGroup = {
  IN_REVIEW_PROGRESS: [ApplicationStatus.CHANGE_REQUESTED, ApplicationStatus.SUBMITTED],
  APPROVED: [ApplicationStatus.ACCEPTED],
  REVIEWED: [...REVIEWABLE, ApplicationStatus.REJECTED],
  REVIEWABLE,
  PRE_REVIEW,
}

export const isApplicationSubmittable = (bookingApplication: Pick<Application, 'status'>) =>
  ApplicationStatusGroup.PRE_REVIEW.includes(bookingApplication?.status) ||
  ApplicationStatusGroup.IN_REVIEW_PROGRESS.includes(bookingApplication?.status)
export const isApplicationApproved = (bookingApplication: Pick<Application, 'status'>) =>
  ApplicationStatusGroup.APPROVED.includes(bookingApplication?.status)
export const isApplicationInReviewProgress = (bookingApplication: Pick<Application, 'status'>) =>
  ApplicationStatusGroup.IN_REVIEW_PROGRESS.includes(bookingApplication?.status)
