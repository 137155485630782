import React, { useEffect } from 'react'

import { InvestmentAssumptions } from '@guiker/base-listing-shared'
import { Currency } from '@guiker/money'

import { useFormContext, useTranslation } from '../../hooks'
import { Box, ColumnGridLayout, Divider, Flex, makeStyles, PhoneNumber, TextField, theme } from '..'
import { CardResults } from './CardResults'

type FormContentProps = {
  currency: Currency
  isSuccess?: boolean
  inquiryResults: InvestmentAssumptions.Results
  purchasePrice: number
}

const useStyle = makeStyles(
  {
    formContainer: {
      width: '100%',
      height: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  {
    name: 'PropertySaleInquiryFormContent',
  },
)

export const FormContent: React.FC<FormContentProps> = ({ inquiryResults, purchasePrice, currency, isSuccess }) => {
  const { t } = useTranslation(['common', 'main-propertySale'])
  const classes = useStyle()

  const { reset } = useFormContext()

  useEffect(() => {
    isSuccess && reset()
  }, [isSuccess])

  return (
    <Flex flexDirection='column' className={classes.formContainer} gap={2}>
      <Box display='none'>
        <input name='results.purchasePrice' value={purchasePrice} />
      </Box>
      <CardResults inquiryResults={inquiryResults} currency={currency} />
      <Divider />
      <ColumnGridLayout gap={2}>
        <TextField name='user.firstName' label={t('common:user.firstName')} />
        <TextField name='user.lastName' label={t('common:user.lastName')} />
        <PhoneNumber label={t('common:user.phoneNumber')} name='user.phone' maxWidth='100%' disableFlags />
        <TextField name='user.emailAddress' label={t('common:user.emailAddress')} />
      </ColumnGridLayout>
    </Flex>
  )
}
