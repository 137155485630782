import React from 'react'

import { Redirect } from '@guiker/router'

import { PageLayout, PageMetaTags, SignUpForm } from '../../components'
import { useRedirectOnAuthenticate } from '../../hooks'
import { useTranslation } from '../../i18n'
import { routes } from '../../routes'

const SignUp: React.FC = () => {
  const { t } = useTranslation('webapp')
  const { redirectTo } = useRedirectOnAuthenticate()

  if (redirectTo) {
    return <Redirect reload={true} path={redirectTo.path} />
  }

  return (
    <>
      <PageMetaTags subtitle={t('signup.seo.subtitle')} description={t('signup.seo.description')} />
      <PageLayout title={t('signup.title')}>
        <SignUpForm
          switchAuthTypeUrl={routes.LogIn.path}
          forgetPasswordUrl={routes.RequestForgotPassword.path}
          privacyPolicyUrl={routes.PrivacyPolicy.path}
          termsAndConditionUrl={routes.TermsAndConditions.path}
        />
      </PageLayout>
    </>
  )
}

export { SignUp }
