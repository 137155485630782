import React, { useEffect, useState } from 'react'

import { useDateFormatter } from '@guiker/i18n'
import { VisitSchedule } from '@guiker/listing-inquiry-shared'
import { useFieldArray, useFormContext, useFormInput } from '@guiker/react-hook-form'
import { DateTime, parseDate, toISODate } from '@guiker/shared-framework'

import { useTranslation } from '../../hooks'
import { Button, EditAlternativeIcon, Flex, isMobile, Link, Modal, PBold, PlusSmallIcon, PSmall, PSmaller } from '../'
import { TimeSlot } from './TimeSlot'

export type TimeSlotSchedulerProps = {
  condensed?: boolean
  emptyInit?: boolean
  minSlots?: number
  maxSlots?: number
}

type buildTimeSlotScheduleProps = {
  lastDate?: string
}

const buildTimeSlotSchedule = ({ lastDate = toISODate(DateTime.local()) }: buildTimeSlotScheduleProps) => {
  return {
    date: toISODate(parseDate(lastDate).plus({ day: 1 })),
    timeSlot: {
      morning: true,
      afternoon: false,
      evening: false,
    },
  }
}

type VisitSchedules = { visitSchedules: VisitSchedule[] }
export const TimeSlotScheduler: React.FC<TimeSlotSchedulerProps> = ({
  condensed,
  emptyInit = false,
  minSlots = 0,
  maxSlots = 5,
}) => {
  const name = 'visitSchedules'
  const { t } = useTranslation(['main-rentalListingInquiry'])
  const { formatDate } = useDateFormatter()
  const { getValues, formState, control } = useFormContext<VisitSchedules>()
  const formInput = useFormInput<VisitSchedules, 'visitSchedules'>({ name, t })
  const { errorMessage, error, errorType } = formInput
  const [duplicates, setDuplicates] = useState([])
  const [slotError, setSlotError] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  const appendTimeSlot = () => {
    const { visitSchedules } = getValues()

    const newTimeSlot = buildTimeSlotSchedule(
      visitSchedules?.length ? { lastDate: [...visitSchedules].pop().date } : {},
    )

    append(newTimeSlot)
  }

  useEffect(() => {
    const { visitSchedules } = getValues()
    const dupes = []
    const noSlots = []
    const dupeSet = new Set()
    visitSchedules?.forEach((schedule: VisitSchedule, index: number) => {
      if (!schedule.timeSlot.morning && !schedule.timeSlot.afternoon && !schedule.timeSlot.evening) {
        noSlots.push(index)
      }
      if (dupeSet.has(toISODate(schedule.date))) {
        dupes.push(index)
      } else {
        dupeSet.add(toISODate(schedule.date))
      }
    })
    setDuplicates(dupes)
    setSlotError(noSlots)
  }, [formState])

  useEffect(() => {
    !emptyInit && fields?.length === 0 && append(buildTimeSlotSchedule({}))
  }, [emptyInit, fields])

  const openModal = () => {
    setIsOpen(true)
    if (fields.length === 0) {
      appendTimeSlot()
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const formatTimeSlots = (visit: VisitSchedule) => {
    const availables = Object.entries(visit.timeSlot).filter(([, value]) => !!value)

    if (availables.length === 3) return t('timeSlotScheduler.options.all')

    return availables.map(([key]) => t(`timeSlotScheduler.options.${key}`)).join(', ')
  }

  return (
    <>
      {fields.length === 0 ? (
        <Link onClick={openModal} underline={false} adornment={<PlusSmallIcon size='smaller' />}>
          {t('timeSlotScheduler.cta')}
        </Link>
      ) : (
        <Flex flexDirection='column' gap={1}>
          <Flex justifyContent='space-between'>
            <PBold mb={0}>{t('timeSlotScheduler.label')}</PBold>
            <Link
              color={60}
              onClick={openModal}
              underline={false}
              TextComponent={PSmall}
              adornment={<EditAlternativeIcon size='smaller' />}
            >
              {t('common:actions.edit')}
            </Link>
          </Flex>
          <Flex flexDirection='column' gap={0.5}>
            {fields.map((item) => (
              <Flex key={item.date} alignItems='center' gap={1}>
                <PSmall mb={0}>{formatDate(item.date)}</PSmall>
                <PSmaller mb={0}>({formatTimeSlots(item)})</PSmaller>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
      <Modal
        open={isOpen}
        onClose={closeModal}
        title={t('timeSlotScheduler.modal.title')}
        minWidth={isMobile ? undefined : 500}
        minHeight={500}
        actions={<Button onClick={closeModal}>{t('common:actions.confirm')}</Button>}
      >
        <Flex flexDirection='column' gap={2}>
          <Flex flexDirection='column'>
            {fields.map((item, index) => (
              <TimeSlot
                name={`${name}[${index}]`}
                defaultValue={item as VisitSchedule}
                key={item.id}
                onRemove={() => remove(index)}
                condensed={condensed}
                error={duplicates.includes(index) && errorType === 'differentDates' && error}
                timeslotError={slotError.includes(index) && errorType === 'oneTimeSlot' && error}
                errorMessage={duplicates.includes(index) && errorType === 'differentDates' && errorMessage}
                timeslotErrorMessage={slotError.includes(index) && errorType === 'oneTimeSlot' && errorMessage}
                hideRemoveLink={fields.length <= minSlots}
              />
            ))}
          </Flex>
          {fields.length < maxSlots && (
            <Link onClick={() => appendTimeSlot()} underline={false} adornment={<PlusSmallIcon size='smaller' />}>
              {t('timeSlotScheduler.actions.add')}
            </Link>
          )}
        </Flex>
      </Modal>
    </>
  )
}
