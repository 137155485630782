import React, { useState } from 'react'

import { usePayInMethodContext, usePaymentApiClient } from '@guiker/payment-context'
import {
  Button,
  ColumnGridItem,
  DeleteIcon,
  FeedbackComponent,
  Flex,
  Link,
  Modal,
  PSmall,
  SecondaryButton,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'

type Props = {}

export const SelectedPayInMethod: React.FC<Props> = () => {
  const { t } = useTranslation('common-payment')
  const { selected: selectedPayInMethod, onPaymentMethodRemoved } = usePayInMethodContext()
  const apiClient = usePaymentApiClient()

  const [apiErrorMsg, setApiErrorMsg] = useState(null)
  const [payInMethodToRemove, setPayInMethodToRemove] = useState(null)

  const { mutate: removePayInMethod, isLoading: isDeleting } = useMutation(
    (payInMethodId: string) => apiClient.deleteOnePayInMethod({ pathParams: { payInMethodId } }),
    {
      onSuccess: (_, deletedPayInMethodId: string) => {
        onPaymentMethodRemoved(deletedPayInMethodId)
        setPayInMethodToRemove(null)
      },
      onError: () => setApiErrorMsg(t('removePayInMethodFailed')),
    },
  )

  const handleRemoveDialogConfirm = () => {
    removePayInMethod(selectedPayInMethod.id)
  }

  if (!selectedPayInMethod) return <></>

  return (
    <>
      <ColumnGridItem md={2}>
        <Flex gap={1}>
          <DeleteIcon width={16} height={16} />
          <Link color={50} onClick={() => setPayInMethodToRemove(selectedPayInMethod)} underline disabled={isDeleting}>
            <PSmall mb={0}>{t('removePayInMethod')}</PSmall>
          </Link>
        </Flex>
      </ColumnGridItem>
      <Modal
        title={t('areYouSureToRemove')}
        open={payInMethodToRemove !== null}
        onClose={() => setPayInMethodToRemove(null)}
        actions={
          <>
            <SecondaryButton color='secondary' onClick={() => setPayInMethodToRemove(null)}>
              {t('no')}
            </SecondaryButton>
            <Button type='button' color='primary' onClick={handleRemoveDialogConfirm}>
              {t('yes')}
            </Button>
          </>
        }
      >
        {apiErrorMsg && (
          <FeedbackComponent
            loading={false}
            toasterMessage={apiErrorMsg}
            onToasterClose={() => setApiErrorMsg(undefined)}
          />
        )}
      </Modal>
    </>
  )
}
