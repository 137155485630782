import React from 'react'

import { useTranslation } from 'react-i18next'

import { StepConfig, Stepper, theme } from '@guiker/components-library'

import { OnSubmit } from '../IdentityVerification'
import { useIdentityVerificationContext } from '../IdentityVerificationContext'
import { FacePictureVerificatorStep } from './FacePictureVerificatorStep'
import { GovernmentIdBackStep } from './GovernmentIdBackStep'
import { GovernmentIdFrontStep } from './GovernmentIdFrontStep'
import { SummaryStep } from './SummaryStep'

type IdentityVerificationStepperProps = {
  scope: { type: string; id: string }
  onClose: () => void
  onSubmit: OnSubmit
  title?: string
}

export const IdentityVerificationStepper: React.FC<IdentityVerificationStepperProps> = ({
  onClose,
  onSubmit,
  scope,
  title,
}) => {
  const { t } = useTranslation(['common', 'common-components'])
  const tPrefix = 'common-components:identityVerification'
  const { governmentIdFront, governmentIdBack, documentType, facePicture } = useIdentityVerificationContext()

  const getSteps = (): StepConfig[] => {
    return [
      {
        name: 'government-id-front',
        component: GovernmentIdFrontStep,
        label: t(`${tPrefix}.governmentIdFront.label`),
        description: t(`${tPrefix}.governmentIdFront.description`),
      },
      governmentIdFront &&
        documentType !== 'PASSPORT' && {
          name: 'government-id-back',
          component: GovernmentIdBackStep,
          label: t(`${tPrefix}.governmentIdBack.label`),
          description: t(`${tPrefix}.governmentIdBack.description`),
        },
      governmentIdFront &&
        (documentType === 'PASSPORT' || governmentIdBack) && {
          name: 'face-picture',
          component: FacePictureVerificatorStep,
          label: t(`${tPrefix}.facePicture.label`),
          description: t(`${tPrefix}.facePicture.description`),
        },
      governmentIdFront &&
        facePicture &&
        (documentType === 'PASSPORT' || governmentIdBack) && {
          name: 'summary',
          component: (props) => <SummaryStep scope={scope} onSubmit={onSubmit} {...props} />,
          label: t(`${tPrefix}.summary.label`),
        },
    ].filter((s) => !!s)
  }

  return (
    <Stepper
      pageLayoutProps={{
        maxWidth: theme.dimensions.narrowPageLayout.maxWidth.desktop,
        hasButtonContainer: true,
      }}
      title={title}
      onClose={onClose}
      hasProgressBar={false}
      nextLabel={t('common:next')}
      backLabel={t('common:back')}
      steps={getSteps()}
    />
  )
}
