import { yup } from '@guiker/yup-util'

export const certnClientConfigSchema = yup
  .object({
    accessTokenCA: yup.string().required(),
    webhookSecretCA: yup.string().required(),
    accessTokenUS: yup.string().required(),
    webhookSecretUS: yup.string().required(),
    baseUrl: yup.string().required(),
  })
  .required()
