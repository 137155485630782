import { yup } from '@guiker/yup-util'

export const transferPropertyOwnershipSchema = yup.object({
  userId: yup.string().nullable(),
  emailAddress: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().nullable(),
})

export type TransferPropertyOwnershipSchema = yup.InferType<typeof transferPropertyOwnershipSchema>
