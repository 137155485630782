import { Modify } from '@guiker/ts-utils'

enum PayoutProviderName {
  TRANSFERWISE = 'TRANSFERWISE',
  STRIPE = 'STRIPE',
  PAYSAFE = 'PAYSAFE',
  GUIKER = 'GUIKER',
}

type PayoutProviderStatus =
  | 'INITIALIZED'
  | 'CREATED'
  | 'PENDING_APPROVAL'
  | 'NEED_MORE_INFORMATION'
  | 'REJECTED'
  | 'ENABLED'
  | 'TERMINATED'

enum PayoutProviderErrorCode {
  INVALID_PROFILE = 'INVALID_PROFILE',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  OTHER = 'OTHER',
}

type PayoutProvider = {
  name: PayoutProviderName
  status: PayoutProviderStatus
  accountId: string
  details: unknown
  errorCodes?: PayoutProviderErrorCode[]
}

type TransferwisePayoutProvider = Modify<
  PayoutProvider,
  {
    name: PayoutProviderName.TRANSFERWISE
    details: {}
  }
>

type StripePayoutProvider = Modify<
  PayoutProvider,
  {
    name: PayoutProviderName.STRIPE
    details: {
      bankAccountId: string
      secretKey: string
      publishKey: string
    }
  }
>

type PaysafePayoutProvider = Modify<
  PayoutProvider,
  {
    name: PayoutProviderName.PAYSAFE
    details: {
      merchantId: string
      creditCardAccountId: string
      directDebitAccountId: string
      raw?: unknown
    }
  }
>

export {
  PayoutProvider,
  PayoutProviderName,
  PayoutProviderStatus,
  TransferwisePayoutProvider,
  StripePayoutProvider,
  PaysafePayoutProvider,
  PayoutProviderErrorCode,
}
