import { routesBuilder } from '@guiker/api-definition'

import { Invoice } from '../../../../../entity'
import { paths } from '../../../paths'

const { buildGet } = routesBuilder<Invoice>()({
  basePath: paths.invoice.all.withScope('users', ':userId'),
})

export const routes = {
  readAllLateInvoicesByUser: buildGet({
    path: '/late-info',
    responseIsArray: true,
  }),
  readAllInvoicesByUser: buildGet({
    paginated: true,
  }),
}
