import { TypeOf, yup } from '@guiker/yup-util'

import { stockSchema } from './stock-schema'

export const upsertListingStockSchema = yup.object({
  stock: stockSchema.required().concat(
    yup.object({
      id: yup.string().optional(),
    }),
  ),
})

export type UpsertListingStockSchema = TypeOf<typeof upsertListingStockSchema>
