import React from 'react'

import { Avatar, makeStyles, P, theme } from '@guiker/react-framework'

type ParticipantAvatarProps = {
  size: 'large' | 'medium' | 'small'
  avatarUrl?: string
  firstName: string
  lastName: string
}

const SizeMap = {
  large: 50,
  medium: 32,
  small: 24,
}

const useStyles = makeStyles(
  () => ({
    avatar: {
      backgroundColor: theme.palette.background.secondary,
      height: ({ size }: Pick<ParticipantAvatarProps, 'size'>) => SizeMap[size],
      width: ({ size }: Pick<ParticipantAvatarProps, 'size'>) => SizeMap[size],
    },
    text: {
      color: theme.palette.text.secondary.main,
    },
  }),
  { name: 'ParticipantAvatar' },
)

const ParticipantAvatar: React.FC<ParticipantAvatarProps> = ({ avatarUrl, firstName, lastName, size = 'medium' }) => {
  const classes = useStyles({ size })

  return (
    <Avatar className={classes.avatar} src={avatarUrl}>
      <P mb={0} className={classes.text}>{`${getInitial(firstName)}${getInitial(lastName)}`}</P>
    </Avatar>
  )
}

const getInitial = (name: string) => (!name ? '' : name.split('')[0].toUpperCase())

export { ParticipantAvatar }
