import React from 'react'

import { currency, money } from '@guiker/money'
import { computeInvoiceTotalAmount, Invoice, InvoiceStatus } from '@guiker/payment-shared'
import { webappRoutes as paymentWebappRoutes } from '@guiker/payment-shared'
import { Button, ColumnConfig, Flex, P, RouterLink, TextButton, useDateFormatter, useT } from '@guiker/react-framework'

import { InvoiceDetailStatusChip, InvoiceReason } from '../../../components'

const getInvoiceStatusInfo = (invoice: Invoice): { cta: string } => {
  switch (invoice.status) {
    case InvoiceStatus.SENT:
      return { cta: 'pay' }
    case InvoiceStatus.VERIFICATION_FAILED:
    case InvoiceStatus.PAYMENT_FAILED:
      return { cta: 'retry' }
    case InvoiceStatus.PAID:
    case InvoiceStatus.SETTLED:
    case InvoiceStatus.VERIFICATION_SUCCEED:
    case InvoiceStatus.REFUNDED:
    case InvoiceStatus.PENDING:
    case InvoiceStatus.REFUND_PENDING:
    case InvoiceStatus.CANCELED:
    case InvoiceStatus.REFUND_FAILED:
      return { cta: 'view' }
  }
}

export const getColumns = ({ withAction }: { withAction: boolean }): ColumnConfig<Invoice>[] => {
  const { tMain } = useT({ domain: 'account', screenName: 'components.invoicesTable' })
  const { formatDate } = useDateFormatter()

  return [
    {
      label: tMain(`description`),
      size: 2.5,
      name: 'description',
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return <InvoiceReason invoice={invoice} />
      },
    },
    {
      name: 'invoice.status',
      label: tMain('status'),
      size: 1.5,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return (
          <InvoiceDetailStatusChip invoice={invoice} variant='outlined' size='small' withDate direction={'column'} />
        )
      },
    },
    {
      label: tMain(`dueBy`),
      name: 'dueBy',
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return formatDate(invoice.createdAt)
      },
    },
    {
      label: tMain(`amount`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'amount',
      size: 1,
      verticalAlign: 'top',
      renderValue: (invoice) => {
        return (
          <P mb={0} textAlign='right'>
            {money.fromAmount(computeInvoiceTotalAmount(invoice), currency[invoice.currency]).toString(true)}
          </P>
        )
      },
    },
    ...(withAction
      ? [
          {
            name: 'action',
            size: 1,
            verticalAlign: 'top' as const,
            renderValue: (invoice) => {
              const info = getInvoiceStatusInfo(invoice)
              const ButtonType = info.cta !== 'view' ? Button : TextButton

              return (
                <Flex flexDirection='column' alignItems='center'>
                  <RouterLink to={paymentWebappRoutes.root.invoice.byId(invoice.id).path}>
                    <ButtonType size='small'>{tMain(`actions.${info.cta}`)}</ButtonType>
                  </RouterLink>
                </Flex>
              )
            },
          },
        ]
      : []),
  ]
}
