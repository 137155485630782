import { assumptionsSchema } from '@guiker/base-listing-shared'
import { TypeOf, yup } from '@guiker/yup-util'

export const updateListingAssumptionsSchema = yup
  .object({
    assumptions: assumptionsSchema,
  })
  .required()

export type UpdateListingAssumptionsSchema = TypeOf<typeof updateListingAssumptionsSchema>
