import { Payment } from '@guiker/base-entity'
import { currency, CurrencyISO } from '@guiker/money'

import { SupportedCountries } from '..'

export const getSupportedCountryFromCurrency = (currency: CurrencyISO) => {
  return currency === CurrencyISO.CAD ? Payment.SupportedCountries.Canada : Payment.SupportedCountries.UnitedStates
}

export const getCurrencyFromSupportedCountry = (country: SupportedCountries) => {
  return country === Payment.SupportedCountries.Canada ? currency.CAD : currency.USD
}

export const typeChecker = {
  isCreditCard: (payInMethod: Payment.PayInMethod): payInMethod is Payment.CreditCardPayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.CREDIT_CARD
  },
  isEFT: (payInMethod: Payment.PayInMethod): payInMethod is Payment.DirectDebitEFTPayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.DIRECT_DEBIT_EFT
  },
  isACH: (payInMethod: Payment.PayInMethod): payInMethod is Payment.DirectDebitACHPayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.DIRECT_DEBIT_ACH
  },
  isPAD: (payInMethod: Payment.PayInMethod): payInMethod is Payment.DirectDebitPADPayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.DIRECT_DEBIT_PAD
  },
  isFundingAccount: (payInMethod: Payment.PayInMethod): payInMethod is Payment.FundingAccountPayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.FUNDING_ACCOUNT
  },
  isOffline: (payInMethod: Payment.PayInMethod): payInMethod is Payment.OfflinePayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.OFFLINE
  },
  isStale: (payInMethod: Payment.PayInMethod): payInMethod is Payment.StalePayInMethod => {
    return payInMethod?.type === Payment.PayInMethodType.STALE
  },
}
