import { BackgroundCheckStatus } from '@guiker/background-check-shared'
import { DateTime } from '@guiker/date'

export const shouldConductNewBackgroundCheck = ({
  updatedAt,
  status,
}: {
  updatedAt: string
  status: BackgroundCheckStatus
}): boolean => {
  if (!status || !updatedAt) {
    return true
  }
  const processingStatus = ['Pending', 'Analyzing']
  const lastUpdatedTime = status === 'Returned' && updatedAt && DateTime.fromISO(updatedAt)
  return (
    !processingStatus.includes(status) || (lastUpdatedTime && lastUpdatedTime < DateTime.local().minus({ month: 1 }))
  )
}
