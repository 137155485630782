import { parseDateTime } from '@guiker/date'
import { yup } from '@guiker/yup-util'

import { VisitSchedule } from '../../entity'

const visitSchedulesSchema = yup
  .array<VisitSchedule>()
  .transform((value: VisitSchedule[]) => {
    return value.map((schedule) => {
      return { ...schedule, date: parseDateTime(schedule.date).toFormat('yyyy-LL-dd') }
    })
  })
  .test('oneTimeSlot', 'dates should have one timeslot', (schedule: VisitSchedule[]) => {
    if (!schedule) return true
    return schedule.every((date) => {
      if (!date.timeSlot.morning && !date.timeSlot.afternoon && !date.timeSlot.evening) {
        return false
      }
      return true
    })
  })
  .test('differentDates', 'dates should be unique', (schedule: VisitSchedule[]) => {
    if (!schedule) return true
    const dates = schedule.map((time) => time?.date)
    const uniqueDates = [...new Set(dates)]
    return dates.length === uniqueDates?.length
  })
  .default([])

export { visitSchedulesSchema }
