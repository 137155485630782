import React, { useMemo } from 'react'

import { AuthBulkAssetUploaderContextProvider, JwtBulkAssetUploaderContextProvider } from '@guiker/asset-uploader'
import { RoommateCard } from '@guiker/base-listing-components'
import {
  Flex,
  FormSection1,
  FormSection2,
  FullScreenSpinner,
  PBold,
  Trans,
  useTranslation,
} from '@guiker/react-framework'
import { Roommate } from '@guiker/shared-framework'
import { AuthenticatedApi, buildAssetGroups, TenantApplication } from '@guiker/tenant-application-shared'

import { useApplicationComponentsContext } from '../../../context'
import { useAuthenticationContext } from '../../../hooks'
import {
  BackgroundCheckReportsListFormContent,
  CurrentAddressContent,
  Guarantor,
  MyProfileFormContent,
  OccupationContent,
  RoommateProfileFormContent,
} from '../../'
import { BackgroundCheck } from '../BackgroundCheck'
import { Documents } from './Documents'

type AuthType = {
  authType: 'auth' | 'jwt'
}

type ViewTenantApplicationProps = Partial<AuthType> & {
  tenantApplication: TenantApplication
  editRoutePath?: string
  isLoading: boolean
  showRoommateProfile: boolean
  roommates?: Roommate[]
}

const buildEditRoute = (editRoutePath: string, step: number) =>
  editRoutePath ? `${editRoutePath}?step=${step}` : undefined

const ViewTenantApplication: React.FC<ViewTenantApplicationProps> = ({
  authType = 'auth',
  tenantApplication,
  editRoutePath,
  isLoading,
  showRoommateProfile,
  roommates = [],
}) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const { user } = useAuthenticationContext()
  const { canViewBackgroundCheck, requestBackgroundCheck, isApplicationApproved } = useApplicationComponentsContext()
  const BulkAssetUploaderContextProvider =
    authType === 'jwt' ? JwtBulkAssetUploaderContextProvider : AuthBulkAssetUploaderContextProvider

  if (isLoading || !tenantApplication) {
    return <FullScreenSpinner />
  }

  const memoizedGuarantorSupportingDocs = useMemo(() => {
    return buildAssetGroups(tenantApplication.guarantor?.supportingDocuments, 'guarantor.supportingDocuments')
  }, [tenantApplication.guarantor?.supportingDocuments])

  const memoizedSupportingDocs = useMemo(() => {
    return buildAssetGroups(tenantApplication.supportingDocuments, 'supportingDocuments')
  }, [tenantApplication.supportingDocuments])

  return (
    <FormSection1>
      <MyProfileFormContent
        tenantApplication={tenantApplication}
        editRoute={buildEditRoute(editRoutePath, 1)}
        readOnly
      />
      <CurrentAddressContent defaultValue={tenantApplication} editRoute={buildEditRoute(editRoutePath, 2)} readOnly />
      <OccupationContent defaultValue={tenantApplication} editRoute={buildEditRoute(editRoutePath, 2)} readOnly />
      {tenantApplication.hasGuarantor && (
        <BulkAssetUploaderContextProvider
          assetGroups={memoizedGuarantorSupportingDocs}
          scope={{
            type: 'TenantApplication',
            id: tenantApplication?.id,
          }}
        >
          <Guarantor defaultValue={tenantApplication} editRoute={buildEditRoute(editRoutePath, 3)} readOnly />
        </BulkAssetUploaderContextProvider>
      )}
      {!!canViewBackgroundCheck && (
        <FormSection2
          title={t('main-tenantApplication:screens.steps.backgroundCheck.umTitle')}
          subtitle={
            <Flex flexDirection='column' gap={2}>
              {!!requestBackgroundCheck && isApplicationApproved && (
                <div>
                  <Trans i18nKey={'main-tenantApplication:screens.steps.backgroundCheck.cannotConductWhenApproved'}>
                    <PBold color='inherit' component='span' />
                  </Trans>
                </div>
              )}
              <div>
                <Trans i18nKey={'main-tenantApplication:screens.steps.backgroundCheck.umDescription'}>
                  <PBold color='inherit' component='span' />
                </Trans>
              </div>
            </Flex>
          }
        >
          <BackgroundCheckReportsListFormContent application={tenantApplication} />
        </FormSection2>
      )}
      {tenantApplication.userId === user?.id && (
        <BackgroundCheck
          defaultValue={tenantApplication}
          editRoute={buildEditRoute(editRoutePath, 3)}
          schema={AuthenticatedApi.Schema.updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator}
          readOnly
        />
      )}
      <BulkAssetUploaderContextProvider
        assetGroups={memoizedSupportingDocs}
        scope={{
          type: 'TenantApplication',
          id: tenantApplication?.id,
        }}
      >
        <Documents editRoute={buildEditRoute(editRoutePath, 4)} />
      </BulkAssetUploaderContextProvider>
      {showRoommateProfile && (
        <RoommateProfileFormContent
          tenantApplication={tenantApplication}
          schema={AuthenticatedApi.Schema.updateTenantApplicationRoommateProfilePayloadValidator}
          readOnly
          editRoute={buildEditRoute(editRoutePath, 5)}
        />
      )}
      {showRoommateProfile && roommates.length > 0 && (
        <FormSection2
          title={t('main-tenantApplication:screens.steps.roommate.title')}
          subtitle={t('main-tenantApplication:screens.steps.roommate.subtitle')}
        >
          <Flex flexWrap='wrap' gap={2}>
            {roommates.map((roommate) => (
              <RoommateCard roommate={roommate} />
            ))}
          </Flex>
        </FormSection2>
      )}
    </FormSection1>
  )
}

export { ViewTenantApplication }
