import { BaseRestClient } from '@guiker/base-rest-client'
import { sanitizeFileName } from '@guiker/lodash'

interface S3Info {
  acl: string
  s3Bucket: string
  s3Key: string
  contentLengthMin: number
  contentLengthMax: number
  amzAlgorithm: string
  amzSecurityToken: string
  amzCredential: string
  amzDate: string
  policy: string
  signature: string
}

export const buildS3FormDataUpload = ({ file, s3Info }: { file: File; s3Info: S3Info }) => {
  const formData = new FormData()

  formData.set('key', s3Info.s3Key)
  formData.set('acl', s3Info.acl)
  formData.set('Content-Type', file.type)
  formData.set('Content-Disposition', `attachment; filename=${sanitizeFileName(file.name)}`)
  formData.set('X-Amz-Credential', s3Info.amzCredential)
  formData.set('X-Amz-Algorithm', s3Info.amzAlgorithm)
  formData.set('X-Amz-Date', s3Info.amzDate)
  formData.set('X-Amz-Signature', s3Info.signature)
  formData.set('Policy', s3Info.policy)
  formData.set('X-Amz-Security-Token', s3Info.amzSecurityToken)
  formData.set('file', file)

  return formData
}

export type S3UploadFilePayload = {
  url: string
  file: File
  s3Info: S3Info
}

export const getS3ApiClient = () => {
  const restClient = new BaseRestClient(console)

  return {
    uploadFile: ({ url, file, s3Info }: S3UploadFilePayload) => {
      const formData = buildS3FormDataUpload({ file, s3Info })
      return restClient.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
  }
}
