import * as Canada from './canada'
import * as UnitedStates from './united-states'

export type Jurisdiction = 'common' | Canada.CanadaJurisdiction | UnitedStates.UnitedStatesJurisdiction

export { CommonOperationCost } from './operation-cost'
export { CommonPurchaseCost } from './purchase-cost'
export { CommonPurchaseTaxes } from './purchase-taxes'

export { Canada, UnitedStates }
