import React from 'react'

import {
  ApiForm,
  ApiFormAction,
  Dropdown,
  Grid,
  makeStyles,
  OneColumnGridLayout,
  SecondaryButton,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'

import { useBookingContext } from '../../../../../hooks'

type ListingOperatorFormProps = {
  handleClose: () => void
  options: { label: string; value: string }[]
  setApiErrorMsg: (msg: string) => void
}

const useStyles = makeStyles({
  ctaContainer: {
    marginTop: 15,
  },
})

const ListingOperatorForm: React.FC<ListingOperatorFormProps> = ({ handleClose, options, setApiErrorMsg }) => {
  const { t } = useTranslation(['main-booking', 'common'])
  const classes = useStyles()
  const { apiClient, bookingId } = useBookingContext()

  const resolver = yupResolver(
    yup.object({
      userId: yup.string().required(),
    }),
  )
  const inviteBookingListingOperator = (payload: { userId: string }) =>
    apiClient.inviteBookingUnitManagers({ pathParams: { bookingId }, payload: [payload] })

  return (
    <ApiForm formName='InviteListingOperatorForm' formOptions={{ resolver }}>
      <OneColumnGridLayout>
        <Dropdown
          name='userId'
          label={t('common:user.role.manager')}
          defaultValue=''
          options={options}
          maxWidth={290}
        />

        <Grid container spacing={3} className={classes.ctaContainer}>
          <Grid item xs={6}>
            <SecondaryButton onClick={handleClose} fullWidth>
              {t('common:actions.cancel')}
            </SecondaryButton>
          </Grid>

          <Grid item xs={6}>
            <ApiFormAction
              onClick={inviteBookingListingOperator}
              options={{
                onSuccess: handleClose,
                onError: () => setApiErrorMsg(t('main-booking:errors.inviteUnitManagersFailed')),
              }}
              buttonProps={{ fullWidth: true }}
            >
              {t('common:actions.invite')}
            </ApiFormAction>
          </Grid>
        </Grid>
      </OneColumnGridLayout>
    </ApiForm>
  )
}

export { ListingOperatorForm }
