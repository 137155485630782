import React, { useState } from 'react'

import { BookingActions, BookingRoleNames, BookingStatus, hasConfirmRequested } from '@guiker/booking-shared'
import { Collapse, Flex, useTranslation } from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'
import { Applicant, InviteApplicant } from './Applicant'
import { ParticipantHeader } from './ParticipantHeader'
import { InviteUnitManager, UnitManager } from './UnitManager'

const Participants: React.FC = () => {
  const { t } = useTranslation(['common'])
  const { booking, bookingUser, can } = useBookingContext()
  const { applicants, unitManagers, statusHistory } = booking

  const [applicantsCollapsed, setApplicantsCollapsed] = useState(true)
  const [unitManagersCollapsed, setUnitManagersCollapsed] = useState(true)

  const isConfirmRequested = hasConfirmRequested(booking)
  const isBooked = statusHistory.some((sh) => sh.status === BookingStatus.BOOKED)
  const bookingUnitManagers = unitManagers.filter((um) => um.role !== BookingRoleNames.SPECTATOR)
  const canChangeApplicants =
    !isConfirmRequested && (bookingUser?.type === 'applicant' || can(BookingActions.InviteApplicant))
  const canInviteUnitManagers = can(BookingActions.InviteUnitManager)

  return (
    <Flex flexDirection='column' gap={2}>
      <div>
        <ParticipantHeader
          onClick={() => setApplicantsCollapsed(!applicantsCollapsed)}
          count={applicants.length}
          collapsed={applicantsCollapsed}
        >
          {t('user.role.applicants')}
        </ParticipantHeader>

        <Collapse in={!applicantsCollapsed}>
          <Flex gap={1} mt={1} flexWrap='wrap'>
            {applicants.map((applicant) => (
              <Applicant
                key={applicant.userId}
                booking={booking}
                hasModal={!isBooked}
                showStepStatus={!isBooked}
                applicant={applicant}
              />
            ))}

            {canChangeApplicants && <InviteApplicant bookingId={booking.id} />}
          </Flex>
        </Collapse>
      </div>

      <div>
        <ParticipantHeader
          onClick={() => setUnitManagersCollapsed(!unitManagersCollapsed)}
          count={bookingUnitManagers.length}
          collapsed={unitManagersCollapsed}
        >
          {t('user.role.unit_managers')}
        </ParticipantHeader>

        <Collapse in={!unitManagersCollapsed}>
          <Flex gap={1} mt={1} flexWrap='wrap'>
            {bookingUnitManagers.map((unitManager) => (
              <UnitManager key={unitManager.userId} unitManager={unitManager} booking={booking} hasModal />
            ))}

            {bookingUser?.type === 'unitManager' && canInviteUnitManagers && <InviteUnitManager />}
          </Flex>
        </Collapse>
      </div>
    </Flex>
  )
}

export { Participants }
