import { initialize, useT as baseUseT } from '@guiker/i18n'
export * from '@guiker/i18n'

const i18n = initialize({
  resources: {},
})

export const useT = (args: { screenName?: string; entity?: string }) => baseUseT({ ...args, domain: 'payout' })

export { i18n }
