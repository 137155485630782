import { Role } from '@guiker/permissions'

import { LeaseRoleNames } from '../../entity'
import { LeaseActions } from '../lease-actions'

export const SpectatorRole: Role = {
  name: LeaseRoleNames.SPECTATOR,
  permissions: [
    {
      action: LeaseActions.ViewLease,
    },
  ],
}
