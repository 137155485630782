import React from 'react'

import { AssetFile } from '@guiker/asset-uploader'
import { Step, StepProps } from '@guiker/components-library'
import { useMutation } from '@guiker/react-query'

import { GovernmentId } from '../GovernmentId'
import { useIdentityVerificationContext } from '../IdentityVerificationContext'

type GovernmentIdFrontStepProps = {}

export const GovernmentIdFrontStep: React.FC<StepProps & GovernmentIdFrontStepProps> = (props) => {
  const { governmentIdFront, resetGovernmentIdFront, validateAndSetGovernmentIdFront } =
    useIdentityVerificationContext()

  const isLoading = governmentIdFront && !governmentIdFront?.status
  const hasError = governmentIdFront && !isLoading && governmentIdFront?.status !== 'SUCCESS'
  const isSuccess = governmentIdFront && !isLoading && governmentIdFront?.status === 'SUCCESS'

  const { mutate: onAddAsset } = useMutation(['validateAndSetGovernmentIdFront'], (asset: AssetFile) =>
    validateAndSetGovernmentIdFront({ image: asset }),
  )

  const onClickNext = () => {
    if (isSuccess) {
      props.onClickNext?.()
    }
  }

  return (
    <Step
      {...props}
      hasBackButton={false}
      nextButtonType='button'
      isSubmitting={isLoading}
      onClickNext={onClickNext}
      nextDisabled={!governmentIdFront}
      errors={hasError && { governmentIdFront: hasError }}
    >
      <GovernmentId
        isLoading={isLoading}
        onAdd={onAddAsset}
        onRemove={resetGovernmentIdFront}
        imageWithResult={governmentIdFront}
        name='governmentIdFront'
      />
    </Step>
  )
}
