import { useCallback, useEffect, useState } from 'react'

import baseUseWebSocket, { ReadyState } from 'react-use-websocket'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useConfig } from '@guiker/config-context'
import { useQuery } from '@guiker/react-query'
import { WebsocketAuthToken } from '@guiker/websocket-connection-entity'

import { EventPattern } from './event-pattern'
import { useWebsocketConnectionApiClient } from './use-websocket-connection-api-client'
import { useWebsocketListenerManager } from './use-websocket-listener-manager'

const useWebsocket = (): void => {
  const { user } = useAuthenticationContext()
  const apiClient = useWebsocketConnectionApiClient()
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const config = useConfig()
  const { getListeners } = useWebsocketListenerManager()

  const { data: authToken } = useQuery<WebsocketAuthToken, string>(
    'websocket-auth-token',
    () => apiClient.generateAuthToken(),
    {
      retry: true,
      staleTime: 1000,
      enabled: !!user && !!user?.accessToken && !isConnected,
      refetchInterval: config.websocketAuthTokenExpiryTime,
    },
  )

  const onMessage = useCallback(({ data }: { data: string }) => {
    const parsedData = JSON.parse(data)

    const eventPattern: EventPattern = [parsedData.context, parsedData.entity, parsedData.type, parsedData.entityId]
    const eventListeners = getListeners(eventPattern)

    if (config.debug) {
      config.logger.log(`Got Websocket Message with ${eventListeners.length} listeners:`, eventPattern)
    }

    eventListeners.forEach((listener) => listener(parsedData))
  }, [])

  const shouldConnect = !!user && !!authToken // only attempt to connect if the auth token is ready and the user is logged in
  const { readyState } = baseUseWebSocket(
    config.websocketApiBaseUrl,
    {
      retryOnError: true,
      share: true,
      shouldReconnect: () => true, // reconnect on all close events
      queryParams: {
        authToken: authToken?.authToken,
      },
      onMessage,
    },
    shouldConnect,
  )

  useEffect(() => {
    if (config.debug) {
      config.logger.log('Websocket State Changed:', ReadyState[readyState])
    }

    setIsConnected(readyState === ReadyState.OPEN)
  }, [readyState])
}

export { useWebsocket }
