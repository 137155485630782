import { Errors, StatusCodes } from '@guiker/common'

export class InvalidLeasePeriodError extends Errors.BaseError {
  constructor() {
    super(
      `Invalid lease period: must be at least 1 month.`,
      InvalidLeasePeriodError.name,
      StatusCodes.UNPROCESSABLE_ENTITY,
    )
  }
}
