import {
  baseParamsValidator,
  buildPaginationQueryParamSchema,
  idNestedParamsValidator,
  validatorBuilder,
} from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { PropSharingListing, PropSharingListingWithFundingData } from '../../entity'
import { searchListingQuerySchema, SearchListingSchema, searchListingSchema } from '../schemas'
import { paths } from './paths'
import {
  updateListingAssumptionsSchema,
  updateListingPropertyBuildingDetailSchema,
  updateListingPropertyBuildingSchema,
  updateListingPropertyUnitsSchema,
  updateListingSchema,
  updateListingStatusSchema,
  upsertListingStockSchema,
} from './schemas'

const oneListingPath = paths.listings.withId.path
const responseValidator = (res: unknown) => Promise.resolve(res as PropSharingListing)

export const routes = {
  updateListingPropertyBuilding: {
    path: `${oneListingPath}/property/building/address`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingPropertyBuildingSchema.validate(payload),
    responseValidator,
  },
  updateListingPropertyBuildingDetail: {
    path: `${oneListingPath}/property/building`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingPropertyBuildingDetailSchema.validate(payload),
    responseValidator,
  },
  updateListingPropertyUnit: {
    path: `${oneListingPath}/property/units`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingPropertyUnitsSchema.validate(payload),
    responseValidator,
  },
  upsertListingStock: {
    path: `${oneListingPath}/stock`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => upsertListingStockSchema.validate(payload),
    responseValidator,
  },
  updateListing: {
    path: oneListingPath,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingSchema.validate(payload),
    responseValidator,
  },
  updateListingAssumptions: {
    path: oneListingPath,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingAssumptionsSchema.validate(payload),
    responseValidator,
  },
  updateListingStatus: {
    path: `${oneListingPath}/status`,
    method: HttpMethod.PUT,
    pathParamsValidator: idNestedParamsValidator,
    payloadValidator: (payload: unknown) => updateListingStatusSchema.validate(payload),
    responseValidator,
  },
  readOneListing: {
    path: oneListingPath,
    method: HttpMethod.GET,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator,
  },
  createListing: {
    path: `${paths.listings.path}/create`,
    method: HttpMethod.POST,
    responseValidator,
  },
  readAllListings: {
    path: `${paths.listings.path}/search`,
    method: HttpMethod.POST,
    queryParamsValidator: (queryParams: unknown) => searchListingQuerySchema.validate(queryParams),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<PropSharingListingWithFundingData, {}>(),
    payloadValidator: validatorBuilder.buildPayloadValidator<SearchListingSchema>(searchListingSchema),
  },
  readAllPublishedListings: {
    path: `${paths.listings.path}/published`,
    method: HttpMethod.GET,
    queryParamsValidator: (queryParams: unknown) => searchListingQuerySchema.validate(queryParams),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<PropSharingListingWithFundingData, {}>(),
  },
  readAllListingsByStock: {
    path: `${paths.listings.path}/stock/:stockId`,
    method: HttpMethod.GET,
    pathParamsValidator: baseParamsValidator('stockId'),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<PropSharingListing, {}>(),
    queryParamsValidator: (payload: unknown) => buildPaginationQueryParamSchema().validate(payload),
  },
}
