import React, { useEffect } from 'react'

import { useSentryContext } from '@guiker/sentry-context'

import { AuthType } from '../../../auth-type'
import { useAuthenticationContext } from '../../../authentication'
import { Trans, useTranslation } from '../../../i18n'
import { Box, Divider, Flex, isAtLeastTablette, Link, makeStyles, P, PSmall, theme, useMediaQuery } from '../..'
import { SocialLogIn } from '../../SocialLogIn'

const useStyles = makeStyles({
  main: {
    gap: theme.spacing(2),
    minWidth: 248,
  },
  divider: {
    flexGrow: 1,
    [isAtLeastTablette]: {
      minHeight: 'initial',
    },
  },
  or: {
    textTransform: 'uppercase',
    margin: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

export type AuthenticationFormProps = React.PropsWithChildren & {
  authType: AuthType
  forgetPasswordUrl?: string
  onAuthTypeSwitchClick?: () => void
  privacyPolicyUrl?: string
  switchAuthTypeUrl?: string
  termsAndConditionUrl?: string
}

const MAX_WIDTH = 328

const AuthenticationForm: React.FC<AuthenticationFormProps> = ({
  authType,
  children,
  forgetPasswordUrl,
  switchAuthTypeUrl,
  onAuthTypeSwitchClick,
  privacyPolicyUrl,
  termsAndConditionUrl,
}) => {
  const { t } = useTranslation('authentication')
  const isSm = useMediaQuery(theme.breakpoints.down('xs'))
  const isMd = useMediaQuery(theme.breakpoints.down('sm'))

  const classes = useStyles()
  const { error: authError, socialAuthProviders } = useAuthenticationContext()
  const { captureException } = useSentryContext()

  useEffect(() => {
    authError &&
      captureException({
        error: new Error(authError),
        errorInfo: {},
      })
  }, [authError])

  return (
    <Flex flexDirection='column' fullWidth>
      {authError && <PSmall color='alert'>{t(`authentication:errors.${authError}`)}</PSmall>}
      {privacyPolicyUrl && termsAndConditionUrl && (
        <PSmall color={60} mb={3}>
          <Trans i18nKey={`authentication:${authType}.tAndC`}>
            <Link color='textPrimary' isExternalLink to={privacyPolicyUrl} />
            <Link color='textPrimary' isExternalLink to={termsAndConditionUrl} />
          </Trans>
        </PSmall>
      )}
      <Flex flexDirection={isMd ? 'column-reverse' : 'row'}>
        <Flex className={classes.main} flexDirection='column' width={isSm ? '100%' : MAX_WIDTH}>
          {children}
          {authType !== AuthType.ACTIVATE && forgetPasswordUrl && (
            <Link color='textPrimary' isExternalLink to={forgetPasswordUrl}>
              <PSmall mb={0}>{t('authentication:forgotPassword')}</PSmall>
            </Link>
          )}
          {(switchAuthTypeUrl || onAuthTypeSwitchClick) && (
            <PSmall color={60} mb={0}>
              <Trans i18nKey={`authentication:${authType}.switchAuthType`}>
                <Link
                  color='textPrimary'
                  {...(switchAuthTypeUrl ? { to: switchAuthTypeUrl } : { onClick: onAuthTypeSwitchClick })}
                />
              </Trans>
            </PSmall>
          )}
        </Flex>
        {socialAuthProviders.length && (
          <>
            <Box
              display='flex'
              flexDirection={isMd ? 'row' : 'column'}
              alignItems='center'
              my={isMd ? 2 : 0}
              mx={isMd ? 0 : 2}
              maxWidth={isSm ? '100%' : MAX_WIDTH}
            >
              <Divider orientation={isMd ? 'horizontal' : 'vertical'} className={classes.divider} />
              <P className={classes.or} color={30}>
                {t('or')}
              </P>
              <Divider orientation={isMd ? 'horizontal' : 'vertical'} className={classes.divider} />
            </Box>
            <Box width='100%' maxWidth={isSm ? '100%' : MAX_WIDTH}>
              <SocialLogIn authType={authType} />
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export { AuthenticationForm }
