import { Tax } from './entity'

const fromTax = (tax: Tax) => {
  return {
    toPercentage: () => `${tax?.rates.reduce((total, r) => total + r.percentage, 0) * 100}%`,
  }
}

const fromTaxes = (taxes: Tax[]) => {
  const total = taxes.reduce(
    (total, tax) => total + (tax ? tax.rates.reduce((subTotal, { percentage }) => subTotal + percentage, 0) : 0),
    0,
  )

  return {
    toPercentage: () => `${total * 100}%`,
  }
}

export { fromTax, fromTaxes }
