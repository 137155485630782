import { IRR, NPV, PMT } from '@guiker/finance'
import { math } from '@guiker/lodash'

import { InvestmentAssumptions } from '../entity'
import { Assumptions, EstimatedSalePriceCalculationMethod } from '../entity/investment-assumptions'
import { expenseValueTypeChecker } from './expense-value-type-checker'

const _monthlyMortgagePayment = (termsInYears: number, interestRate: number, loanAmount: number) => {
  if (termsInYears === 0) return 0
  return math.decimal.round(-PMT(computeMonthlyInterestRate(interestRate), termsInYears * 12, loanAmount), 0)
}

const _totalTaxes = (computedTaxes: InvestmentAssumptions.ComputedExpense[]): number => {
  return computedTaxes.reduce((acc, curr) => acc + curr.computedValue, 0)
}

const computeBracketExpense = (brackets: InvestmentAssumptions.Bracket[], amount: number) => {
  if (amount <= 0) return 0

  return brackets.reduce((taxAmount, bracket, currentIndex) => {
    const lowerBracketThreshold = brackets[currentIndex - 1]?.threshold || 0
    const upperBracketThresold = bracket.threshold == null ? amount : Math.min(amount, bracket.threshold)
    const taxable = upperBracketThresold - lowerBracketThreshold
    return taxable > 0 ? taxAmount + taxable * bracket.rate : taxAmount
  }, 0)
}

const _applyExpense = ({
  baseValue,
  expense,
}: {
  baseValue: number
  expense: InvestmentAssumptions.BaseExpense
}): number => {
  if (!expense) return 0

  if (expenseValueTypeChecker.isFlatValue(expense.value)) {
    return expense.value?.amount?.value || 0
  } else if (expenseValueTypeChecker.isPercentageValue(expense.value)) {
    return math.decimal.round((baseValue ?? 0) * expense.value.rate, 0)
  } else if (expenseValueTypeChecker.isBracketValue(expense.value)) {
    return computeBracketExpense(expense.value.bracket, baseValue)
  }

  return 0
}

const computeExpense = ({
  purchasePrice,
  rentalIncome,
  expense,
}: {
  purchasePrice: number
  rentalIncome: number
  expense: InvestmentAssumptions.BaseExpense
}): number => {
  if (!expense?.value) return 0

  const base = expense.value?.type === 'percentage' ? expense.value?.base : undefined
  const baseValue = base === 'rentalIncome' ? rentalIncome : purchasePrice
  const factor = expense.frequency === 'monthly' ? 12 : 1

  return (
    _applyExpense({
      baseValue,
      expense: expense,
    }) * factor
  )
}

const computeExpenses = ({
  purchasePrice,
  rentalIncome,
  expenses,
}: {
  purchasePrice: number
  rentalIncome: number
  expenses: InvestmentAssumptions.BaseExpense[]
}): InvestmentAssumptions.ComputedExpense[] => {
  return (expenses || []).map((expense: InvestmentAssumptions.BaseExpense) => ({
    ...expense,
    computedValue: computeExpense({ purchasePrice, rentalIncome, expense }),
  }))
}

const _vacancyAllowance = (rentalIncome: number, vacancyRate: number) => {
  return math.decimal.round(vacancyRate * rentalIncome)
}

const _netOperatingIncome = ({
  netRentalRevenue,
  operatingExpenses,
}: {
  netRentalRevenue: number
  operatingExpenses: InvestmentAssumptions.ComputedExpense[]
}) => {
  const totalExpense = operatingExpenses.reduce((acc, curr) => acc - curr.computedValue, 0)

  return math.decimal.round(netRentalRevenue + totalExpense, 0)
}

const computeMonthlyInterestRate = (interestRate: number) => {
  return Math.pow(1 + interestRate, 1 / 12) - 1
}

const _monthlyPaymentsData = (
  loanAmount: number,
  interestRate: number,
  monthlyMortgagePayment: number,
  termsInYears: number,
) => {
  const round = (value: number) => math.decimal.round(value, 0)
  const monthlyInterestRate = computeMonthlyInterestRate(interestRate)

  const compute = ({ month, prevPrincipalBalance }: { month: number; prevPrincipalBalance: number }) => {
    const interestPaid = round(prevPrincipalBalance * monthlyInterestRate)
    const paymentAmount = round(Math.min(monthlyMortgagePayment, prevPrincipalBalance + interestPaid))
    const principalPaid = round(paymentAmount - interestPaid)
    const principalBalance = round(prevPrincipalBalance - principalPaid)
    return { month, paymentAmount, interestPaid, principalPaid, principalBalance }
  }

  const data = [compute({ month: 1, prevPrincipalBalance: loanAmount })]

  for (let month = 2; month <= termsInYears * 12; month++) {
    const { principalBalance } = data[data.length - 1]
    data.push(compute({ month, prevPrincipalBalance: principalBalance }))
  }

  return data
}

const _computeIRR = (targetEquity: number, netCashFlowData: number[]) => {
  return math.decimal.round(IRR([-targetEquity, ...netCashFlowData]), 4)
}

const sumMonthlyPaymentsData = (data: ReturnType<typeof _monthlyPaymentsData>, year: number) => {
  let paymentAmountSum = 0
  let interestPaidSum = 0
  let principalPaidSum = 0

  const startMonth = (year - 1) * 12 + 1
  const endMonth = year * 12

  for (let month = startMonth; month <= endMonth; month++) {
    const { paymentAmount, interestPaid, principalPaid } = data[month - 1] || {
      paymentAmount: 0,
      interestPaid: 0,
      principalPaid: 0,
    }
    paymentAmountSum += paymentAmount
    interestPaidSum += interestPaid
    principalPaidSum += principalPaid
  }

  return { paymentAmountSum, interestPaidSum, principalPaidSum }
}

const computeMorgageResults = ({
  assumptions,
  computedTaxes,
}: {
  assumptions: InvestmentAssumptions.Assumptions
  computedTaxes: InvestmentAssumptions.ComputedExpense[]
}) => {
  const {
    financing: { mortgage },
    purchase: { price },
  } = assumptions
  const amount = price?.amount + _totalTaxes(computedTaxes)
  const { minimumDownPaymentPercentage, loanAmount: mortgageLoanAmount, termsInYears, interestRate } = mortgage

  const { downPaymentAmount, loanAmount } = mortgageLoanAmount
    ? {
        loanAmount: mortgageLoanAmount,
        downPaymentAmount: amount - mortgageLoanAmount,
      }
    : {
        downPaymentAmount: math.decimal.round(amount * minimumDownPaymentPercentage, 0),
        loanAmount: mortgageLoanAmount ?? math.decimal.round(amount * (1 - minimumDownPaymentPercentage), 0),
      }

  const monthlyMortgagePayment = _monthlyMortgagePayment(termsInYears, interestRate, loanAmount)

  return {
    downPaymentAmount,
    loanAmount,
    monthlyMortgagePayment,
  }
}

const computeRentalRevenue = (assumption: Pick<Assumptions, 'revenue'>) => {
  return !assumption.revenue.occupancies?.length
    ? assumption.revenue.rentalRevenue
    : assumption.revenue.occupancies.reduce((acc, cur) => cur.monthlyRent + acc, 0) || 0
}

const computeYearlyCashflow = ({
  assumptions,
  monthlyMortgagePayment,
  loanAmount,
}: {
  assumptions: InvestmentAssumptions.Assumptions
  monthlyMortgagePayment: number
  loanAmount: number
}) => {
  const yearlyCashflows: InvestmentAssumptions.YearlyCashflow[] = []
  const { base, revenue, operation, financing, purchase } = assumptions
  const { holdingPeriod, propertyAppreciationRate, estimatedSalePriceCalculationMethod, capRate } = base
  const { price } = purchase
  const {
    costs: { assetManagementFee, ...costs },
  } = operation
  const { vacancyRate, rentalGrowthRate } = revenue
  const rentalRevenue = computeRentalRevenue(assumptions)

  const { mortgage } = financing
  const { interestRate, termsInYears } = mortgage

  const monthlyMortgagePayments = _monthlyPaymentsData(loanAmount, interestRate, monthlyMortgagePayment, termsInYears)

  for (let year = 1; year <= holdingPeriod; year++) {
    const rentalIncome = year > 1 ? yearlyCashflows[year - 2].rentalIncome * (1 + rentalGrowthRate) : rentalRevenue * 12 //computeCoumpoundInterest(rentalRevenue * 12, rentalGrowthRate, year)
    const vacancyAllowance = -_vacancyAllowance(rentalIncome, vacancyRate)

    const assetManagementFeeCost = -computeExpense({
      expense: assetManagementFee,
      rentalIncome,
      purchasePrice: price.amount,
    })

    const netRentalRevenue = rentalIncome + vacancyAllowance

    const operatingExpenses = computeExpenses({
      purchasePrice: price.amount,
      rentalIncome,
      expenses: Object.values(costs),
    })

    const netOperatingIncome = _netOperatingIncome({
      netRentalRevenue,
      operatingExpenses,
    })

    const {
      paymentAmountSum: totalDebtService,
      interestPaidSum,
      principalPaidSum,
    } = sumMonthlyPaymentsData(monthlyMortgagePayments, year)
    const debtService = { interestPaidSum, principalPaidSum }

    const netIncome = netOperatingIncome - totalDebtService + assetManagementFeeCost
    const prevPrincipalBalance = year === 1 ? loanAmount : yearlyCashflows[yearlyCashflows.length - 1].mortgageBalance
    const mortgageBalance = prevPrincipalBalance - principalPaidSum

    const estimatedSalesPrice =
      estimatedSalePriceCalculationMethod === EstimatedSalePriceCalculationMethod.CAP_RATE
        ? netOperatingIncome * (1 / capRate)
        : math.decimal.round(
            (year === 1 ? price.amount : yearlyCashflows[yearlyCashflows.length - 1].estimatedSalesPrice) *
              (1 + propertyAppreciationRate),
            0,
          )

    const equityPosition = year === holdingPeriod ? estimatedSalesPrice - mortgageBalance : 0
    const netCashFlow = netIncome + equityPosition

    yearlyCashflows.push({
      year,
      rentalIncome,
      vacancyAllowance,
      assetManagementFee: assetManagementFeeCost,
      netRentalRevenue,
      operatingExpenses,
      netOperatingIncome,
      totalDebtService: -totalDebtService,
      debtService,
      netIncome,
      mortgageBalance,
      netCashFlow,
      estimatedSalesPrice,
    })
  }

  return yearlyCashflows
}

export const computeInvestmentResult = (
  assumptions: InvestmentAssumptions.Assumptions,
): InvestmentAssumptions.Results => {
  if (!assumptions) return
  const { base, purchase } = assumptions
  const { npvDiscountRate, assessedValue } = base
  const rentalIncome = computeRentalRevenue(assumptions)

  const purchasePrice = purchase.price.amount
  const topupAmount = purchase.topup?.amount ?? 0
  const taxablePurchasePrice = Math.max(purchase.price.amount || 0, assessedValue || 0)

  const computedPurchaseTaxes = computeExpenses({
    purchasePrice: taxablePurchasePrice,
    rentalIncome,
    expenses: Object.values(purchase.taxes || {}),
  })

  const computedPurchaseCosts = computeExpenses({
    purchasePrice,
    rentalIncome,
    expenses: Object.values(purchase.costs || {}),
  })

  const computedPurchaseTaxesAmount = computedPurchaseTaxes.reduce((acc, curr) => acc + curr.computedValue, 0)
  const computedPurchaseCostAmount = computedPurchaseCosts.reduce((acc, curr) => acc + curr.computedValue, 0)

  const { downPaymentAmount, monthlyMortgagePayment, loanAmount } = computeMorgageResults({
    assumptions,
    computedTaxes: computedPurchaseTaxes,
  })

  const totalProjectCost = purchase.price.amount + computedPurchaseTaxesAmount + computedPurchaseCostAmount
  const targetEquity = math.decimal.round(totalProjectCost - loanAmount, 0) + topupAmount

  const yearlyCashflows = computeYearlyCashflow({ assumptions, monthlyMortgagePayment, loanAmount })
  const netCashFlowData = yearlyCashflows.map(({ netCashFlow }) => netCashFlow)
  const totalNetCashFlowData = netCashFlowData.reduce((sum, curr) => sum + curr, 0)

  const returnMultiple = math.decimal.round(totalNetCashFlowData / targetEquity, 4)
  const irr = _computeIRR(targetEquity, netCashFlowData)
  const npv = math.decimal.round(NPV(npvDiscountRate ?? 0, -targetEquity, ...netCashFlowData), 0)
  const coc = math.decimal.round(totalNetCashFlowData / targetEquity, 4)

  return {
    computedPurchaseTaxes,
    computedPurchaseCosts,
    totalProjectCost,
    estimatedSalesPrice: yearlyCashflows[yearlyCashflows.length - 1]?.estimatedSalesPrice,
    monthlyMortgagePayment,
    downPaymentAmount,
    loanAmount,
    returnMultiple,
    targetEquity,
    yearlyCashflows,
    npv,
    irr,
    coc,
  }
}
