import { HttpMethod } from '@guiker/http'

import { TenantApplication } from '../../entity'
import { paths } from './paths'
import {
  updatePreApplicationPayloadValidator,
  updateTenantApplicationCurrentAddressOccupationPayloadValidator,
  updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator,
  updateTenantApplicationPayloadValidator,
  updateTenantApplicationProfilePayloadValidator,
  updateTenantApplicationRoommateProfilePayloadValidator,
  updateTenantApplicationSupportingDocumentsPayloadValidator,
} from './schemas'

const BASE_TENANT_APPLICATION_URL = paths.tenantApplication.all.base()

const responseValidator = (payload: unknown) => Promise.resolve(payload as TenantApplication)

export const routes = {
  readOneTenantApplication: {
    path: BASE_TENANT_APPLICATION_URL,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
  },
  createTenantApplication: {
    path: BASE_TENANT_APPLICATION_URL,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator,
  },
  updateTenantApplication: {
    path: BASE_TENANT_APPLICATION_URL,
    method: HttpMethod.PUT,
    authenticated: true,
    payloadValidator: (payload: unknown) => updateTenantApplicationPayloadValidator.validate(payload),
    responseValidator,
  },
  updateProfile: {
    path: `${BASE_TENANT_APPLICATION_URL}/profile`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => updateTenantApplicationProfilePayloadValidator.validate(payload),
    responseValidator,
  },
  updateRoommateProfile: {
    path: `${BASE_TENANT_APPLICATION_URL}/roommate-profile`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => updateTenantApplicationRoommateProfilePayloadValidator.validate(payload),
    responseValidator,
  },
  updateAddressAndOccupation: {
    path: `${BASE_TENANT_APPLICATION_URL}/address-and-occupation`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) =>
      updateTenantApplicationCurrentAddressOccupationPayloadValidator.validate(payload),
    responseValidator,
  },
  updateGuarantorAndBackgroundCheck: {
    path: `${BASE_TENANT_APPLICATION_URL}/guarantor-and-background-check`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) =>
      updateTenantApplicationGuarantorAndBackgroundCheckPayloadValidator.validate(payload),
    responseValidator,
  },
  updateSupportingDocuments: {
    path: `${BASE_TENANT_APPLICATION_URL}/supportingDocuments`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) =>
      updateTenantApplicationSupportingDocumentsPayloadValidator.validate(payload),
    responseValidator,
  },
  upsertPreApplication: {
    path: `${BASE_TENANT_APPLICATION_URL}/preapplication`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => updatePreApplicationPayloadValidator.validate(payload),
  },
}
