import React from 'react'

import { makeStyles, theme } from '@guiker/components-library'
import { SearchIcon } from '@guiker/icons'
import { AsyncAutocomplete } from '@guiker/react-framework'
import { ListingUnit } from '@guiker/rental-listing-shared'

import { useAdminListingApiClient } from '../../hooks'

const useStyle = makeStyles({
  input: {
    '& .MuiInputBase-fullWidth': {
      paddingTop: theme.spacing(1),
    },
  },
  startAdornment: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
})

type SearchListingUnitProps = {
  name?: string
  selected: ListingUnit
  setSelected: (listingUnit: ListingUnit) => void
  includeAll?: boolean
  helperText?: string
  errorMessage?: string
}

export const SearchListingUnit: React.FC<SearchListingUnitProps> = ({
  errorMessage,
  helperText,
  includeAll = false,
  name,
  selected,
  setSelected,
}) => {
  const classes = useStyle()
  const apiClient = useAdminListingApiClient()

  const searchOptions = (searchString: string) =>
    apiClient.searchAllListingUnits({ queryParams: { searchString, includeAll } })
  const onSelect = (listingUnit: ListingUnit) => setSelected(listingUnit)

  return (
    <AsyncAutocomplete<ListingUnit>
      name={name}
      maxWidth={600}
      className={classes.input}
      defaultValue={selected}
      handleSelectOption={onSelect}
      searchOptions={searchOptions}
      helperText={helperText}
      errorMessage={errorMessage}
      startAdornment={<SearchIcon size='small' className={classes.startAdornment} />}
      getOptionLabel={(option: ListingUnit) => {
        return `${option.isDeleted ? '-- deleted -- ' : ''}${option?.title ?? ''}`
      }}
      getOptionSelected={(option: ListingUnit, value: ListingUnit) => {
        return value.inventoryId === option.inventoryId
      }}
    />
  )
}
