import React, { useState } from 'react'

import { Applicant as ApplicantInterface, Booking } from '@guiker/booking-shared'

import { ParticipantBox } from '../ParticipantBox'
import { ApplicantViewModal } from './ApplicantViewModal'

type ApplicantProps = {
  applicant: ApplicantInterface
  hasModal: boolean
  showStepStatus: boolean
  booking: Booking
}

const Applicant: React.FC<ApplicantProps> = ({ applicant, booking, hasModal, showStepStatus }) => {
  const [viewModalOpen, setViewModalOpen] = useState(false)

  return (
    <>
      <ParticipantBox
        participant={applicant}
        booking={booking}
        showStepStatus={showStepStatus}
        onClick={() => hasModal && setViewModalOpen(true)}
      />

      <ApplicantViewModal open={viewModalOpen} setOpen={setViewModalOpen} applicant={applicant} />
    </>
  )
}

export { Applicant }
