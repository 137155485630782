import { URLSearchParams } from 'url'

/**
 * Change a format for querystring
 * @param data { email: 'test@guiker.com', id: '12345' }
 * @return email=test@guiker.com&id=12345
 */
const parseJsonToQuerystring = (data: { [key: string]: string }): string => {
  const params = new URLSearchParams()
  Object.keys(data).forEach((key) => params.append(key, data[key]))

  return params.toString()
}

export { parseJsonToQuerystring }
