import { AdminApiClient, ScopeType } from '@guiker/payment-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'
import { isScope, Scope } from '@guiker/shared-framework'

type FetchArg = { invoiceId: string } | { scope: Scope<ScopeType> }

export const {
  useContext: useAdminInvoiceContext,
  Context: AdminInvoiceContext,
  ContextProvider: AdminInvoiceContextProvider,
  NestedRouter: AdminInvoiceNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.ADMIN,
  apiClientGen: AdminApiClient,
  cacheKey: (fetchArg: FetchArg) => `readOneInvoiceAdmin-${JSON.stringify(fetchArg)}`,
  fetcher: (apiClient, fetchArg: FetchArg) => {
    if (isScope(fetchArg)) {
      return apiClient.readLastInvoiceByScope({
        pathParams: { scopeType: fetchArg.scope.type, scopeId: fetchArg.scope.id },
      })
    } else {
      return apiClient.readOneInvoice({ pathParams: { invoiceId: fetchArg.invoiceId } })
    }
  },
})
