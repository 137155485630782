import React from 'react'

import { StockOrderExecutionStatus } from '@guiker/propsharing-shared'
import { Chip, ChipProps, useTranslation } from '@guiker/react-framework'

export const StatusChip: React.FC<{ status: StockOrderExecutionStatus | 'sold'; size: ChipProps['size'] }> = ({
  size,
  status,
}) => {
  const { t } = useTranslation('common-propsharing')

  switch (status) {
    case 'sold':
      return (
        <Chip size={size} color='success'>
          {t('stockOrderExecution.status.sold')}
        </Chip>
      )
    case StockOrderExecutionStatus.FULFILLED:
      return (
        <Chip size={size} color='success'>
          {t('stockOrderExecution.status.fulfilled')}
        </Chip>
      )
    case StockOrderExecutionStatus.PENDING_SHARE_ISSUANCE:
      return (
        <Chip size={size} color='info'>
          {t('stockOrderExecution.status.pendingShareIssuance')}
        </Chip>
      )
    case StockOrderExecutionStatus.PROCESSING_SHARE_ISSUANCE:
      return (
        <Chip size={size} color='info'>
          {t('stockOrderExecution.status.processingShareIssuance')}
        </Chip>
      )
  }
}
