export const ipoInvestorProfileId = 'IPO'

export type ShareJournalEntry = {
  id: string
  stockId: string
  action: 'credit' | 'debit'
  description: string
  idempotencyKey: string
  unit: {
    quantity: number
    price: number
  }
  investorProfileId: string
  meta: {
    stockOrderExecutionId?: string
  }
  createdAt?: string
  updatedAt?: string
}

export type ShareJournalEntrySource = {
  investorProfileId?: string
  entity: 'Stock' | 'StockOrderExecution'
  id: string
}
