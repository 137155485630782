import { TypeOf, yup } from '@guiker/yup-util'

import { leaseRestrictionsAndModifications } from './lease-restrictions-and-modifications'
import { newLesseeNotice } from './new-lessee-notice'

export const newLesseeNoticeAndLeaseRestrictionsAndModifications = yup.object({
  newLesseeNotice: newLesseeNotice.nullable(),
  leaseRestrictionsAndModifications,
})

export type NewLesseeNoticeAndLeaseRestrictionsAndModifications = TypeOf<
  typeof newLesseeNoticeAndLeaseRestrictionsAndModifications
>
