import React from 'react'

import { PaginatedGrid, PaginatedGridProps } from '@guiker/react-query-components'
import { RentalListing } from '@guiker/rental-listing-shared'

import { ListingCard } from '../ListingCard'

export type PaginatedListingsGridProps = Omit<PaginatedGridProps<RentalListing>, 'CellComponent'>

const PaginatedListingsGrid: React.FC<PaginatedListingsGridProps> = (props) => {
  return <PaginatedGrid {...props} CellComponent={({ data }) => <ListingCard listing={data} />} />
}

export { PaginatedListingsGrid }
