import { flow } from '@guiker/lodash'

import { CompareFacesResult, Label, LabelizeResult, LabelNames } from '../entity'

export enum ResultStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  INSUFFICIENT_QUALITY = 'INSUFFICIENT_QUALITY',
}

const isAtLeastId = (labels: Label[]) => {
  return labels.find((label) => label.name === LabelNames.ID)
}

const oneOfAllowableLabels = (labels: Label[], allowableLabels: string[]) => {
  return labels.find((label) => allowableLabels.includes(label.name))
}

const sortLabelByConfidence = (labels: Label[]) => labels.sort((l1, l2) => l2.confidence - l1.confidence)

const filterByAllowedLabels = (allowableLabels: string[], labels: Label[]) => {
  return labels.filter((label) => allowableLabels.includes(label.name))
}

const filterByConfidence = (confidenceThreshold: number, labels: Label[]) => {
  return labels.filter((l) => l.confidence > confidenceThreshold)
}

export const extractDocumentType = (
  result: LabelizeResult,
  allowableLabels: string[] = [],
  confidenceThreshold = 90,
): LabelNames | null => {
  const filterLabel = (labels: Label[]): Label[] =>
    flow([
      filterByConfidence.bind(this, confidenceThreshold),
      filterByAllowedLabels.bind(this, allowableLabels),
      sortLabelByConfidence,
    ])(labels)
  const sortedLabels = filterLabel(result.labels)

  if (!isAtLeastId(sortedLabels) && !oneOfAllowableLabels(sortedLabels, allowableLabels)) return null
  const labelsExcludingId = sortedLabels.filter((l) => l.name !== LabelNames.ID)
  return labelsExcludingId[0]?.name || LabelNames.ID
}

export const extractLabelResult = (
  result: LabelizeResult,
  allowableLabels: string[],
): { status: ResultStatus; documentType: LabelNames } => {
  const documentType = extractDocumentType(result, allowableLabels)

  return {
    documentType,
    status:
      result.labels.length === 0
        ? ResultStatus.FAILED
        : documentType && documentType !== LabelNames.ID
        ? ResultStatus.SUCCESS
        : ResultStatus.FAILED,
  }
}

export const getCompareFaceStatus = (
  result: CompareFacesResult,
  {
    confidenceThreshold = 95,
    similarityThreshold = 90,
  }: { similarityThreshold?: number; confidenceThreshold?: number } = {},
) => {
  return result.confidence < confidenceThreshold
    ? ResultStatus.INSUFFICIENT_QUALITY
    : result.similarity < similarityThreshold
    ? ResultStatus.FAILED
    : ResultStatus.SUCCESS
}
