import React, { useState } from 'react'

import { Modal, Thumbnail } from '@guiker/components-library'
import { Carousel as BaseCarousel } from '@guiker/react-carousel'

export type Props = {
  pictures: { url: string }[]
  open: boolean
  closeModal: () => void
}

const Carousel: React.FC<Props> = ({ open, closeModal, pictures }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <Modal
      open={open}
      fullScreen={true}
      onClose={closeModal}
      title={`${(currentIndex % pictures.length) + 1} / ${pictures.length}`}
    >
      <BaseCarousel
        items={pictures.map((pic, index) => (
          <Thumbnail key={index} src={pic.url}></Thumbnail>
        ))}
        onChange={(index) => setCurrentIndex(index)}
      />
    </Modal>
  )
}

export { Carousel }
