import { RentCollectionStatus } from '../rent-collection-status'
import { Kpi } from './kpi'

export enum RentCollectionKpiKey {
  OVERDUE = 'OVERDUE',
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  COLLECTED = 'COLLECTED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export const RentCollectionKpiStatusGroups: { [key in RentCollectionKpiKey]: RentCollectionStatus[] } = {
  OVERDUE: [RentCollectionStatus.overdue],
  RECEIVED: [RentCollectionStatus.received],
  PENDING: [RentCollectionStatus.pending],
  PROCESSING: [RentCollectionStatus.processing],
  COLLECTED: [RentCollectionStatus.collected, RentCollectionStatus.paidOffline],
  FAILED: [RentCollectionStatus.failed],
}

export type RentCollectionKpi = Kpi<RentCollectionKpiKey> & { unit: string }
