import { TypeOf, yup } from '@guiker/shared-framework'

import {
  AccreditedReason,
  EligibleReason,
  Financial,
  HighLowScale,
  InvestmentReason,
  InvestorTypeKind,
  MissingTINReason,
  TaxResidentInOtherCountry,
  TimeScale,
} from '../../../../entity/investor-profile'

export const financialProfileSchema = yup.object({
  investmentReason: yup.string().oneOf(Object.values(InvestmentReason)).required(),
  withdrawalTimeline: yup.string().oneOf([TimeScale.Long, TimeScale.Intermediate, TimeScale.Short]).required(),
  balance: yup
    .object({
      annualIncome: yup.number().min(0).required(),
      liquidAssets: yup.number().min(0).required(),
      fixedAssets: yup.number().min(0).required(),
      liabilities: yup.number().min(0).required(),
    })
    .required(),
  taxInfo: yup.object({
    taxResidentInOtherCountry: yup.string().oneOf(Object.values(TaxResidentInOtherCountry)).required(),
    hasCertifiedDetails: yup.boolean().required().oneOf([true], 'You must accept before moving forward.'),
    details: yup
      .object({
        country: yup.string().nullable(),
        taxIdNumber: yup.string().nullable(),
        reasonForNoTIN: yup.string().oneOf(Object.values(MissingTINReason)).nullable(),
      })
      .when(
        'taxResidentInOtherCountry',
        (taxResidentInfo: TaxResidentInOtherCountry, _: yup.ObjectSchema<Financial['taxInfo']['details']>) => {
          if (taxResidentInfo === TaxResidentInOtherCountry.WithoutTIN) {
            return yup
              .object({
                country: yup.string().required(),
                taxIdNumber: yup.string().nullable(),
                reasonForNoTIN: yup.string().oneOf(Object.values(MissingTINReason)).required(),
              })
              .required()
          } else if (taxResidentInfo === TaxResidentInOtherCountry.WithTIN) {
            return yup
              .object({
                country: yup.string().required(),
                taxIdNumber: yup.string().required(),
                reasonForNoTIN: yup.string().nullable(),
              })
              .required()
          } else {
            return yup
              .object({
                country: yup.string().nullable(),
                reasonForNoTIN: yup.string().nullable(),
                taxIdNumber: yup
                  .string()
                  .transform((val) => (val ? val : (null as null)))
                  .length(9)
                  .test(
                    'invalidTaxIdNumber',
                    'taxIdNumber as number',
                    (value: string) => !value || /^[0-9]+$/.test(value),
                  )
                  .required(),
              })
              .required()
          }
        },
      ),
  }),

  investmentKnowledge: yup.string().oneOf(Object.values(HighLowScale)).required(),
  investmentExperience: yup.string().oneOf(Object.values(TimeScale)).required(),
  riskAppetite: yup.string().oneOf(Object.values(HighLowScale)).required(),

  investorType: yup
    .object({
      kind: yup.string().oneOf(Object.values(InvestorTypeKind)),
      accredited: yup.string().oneOf(Object.values(AccreditedReason)).required(),
      eligible: yup
        .string()
        .oneOf(Object.values(EligibleReason))
        .when('accredited', {
          is: (accredited: AccreditedReason) => accredited === AccreditedReason.NotApplicable,
          then: yup.string().oneOf(Object.values(EligibleReason)).required(),
          otherwise: yup
            .string()
            .optional()
            .nullable()
            .transform(() => undefined),
        }),
    })
    .required(),
})

export const financialTaxIdSchema = yup.object({
  taxIdNumber: yup.string().required(),
})
export type FinancialTaxIdSchema = TypeOf<typeof financialTaxIdSchema>
