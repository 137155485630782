import React from 'react'

import { Booking } from '@guiker/booking-shared'
import { PSmall, useDateFormatter } from '@guiker/react-framework'

type BookingPeriodProps = { booking: Pick<Booking, 'info'> }

export const BookingPeriod: React.FC<BookingPeriodProps> = ({ booking }) => {
  const { period } = booking.info
  const { formatDate } = useDateFormatter()
  return <PSmall mb={0}>{`${formatDate(period.from)} - ${formatDate(period.to)}`}</PSmall>
}
