import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { MapLocation } from '../entity'

const baseEvent = {
  context: 'MAP_LOCATION',
  entity: 'MAP_LOCATION',
  data: null as unknown as MapLocation,
}

export enum EventTypes {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED',
}

export const EventsDefinition = generateEventsDefinition(baseEvent)
export const Events = generateEventsFromEnum(baseEvent)(EventTypes)
