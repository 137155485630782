import { upperFirst } from '@guiker/lodash'

import { Lease, LeaseRoleNames } from '../entity'
import { typeChecker } from './type-checker'

type Signer = {
  id: string // userId or emailAddress
  fullName: string
  hasSigned: boolean
  role: LeaseRoleNames
  relatedUserId?: string
}

const buildSigner = <T extends { content: string; date: string }>(args: {
  id: string
  fullName: string | string[]
  signature: T
  role: LeaseRoleNames
  relatedUserId?: string
}) => {
  const { id, fullName, signature, role, relatedUserId } = args
  const name = typeof fullName === 'string' ? [fullName] : fullName

  return {
    id,
    fullName: name
      .map((n) => upperFirst(n?.toLowerCase() ?? ''))
      .join(' ')
      .trim(),
    hasSigned: !!signature?.content,
    role,
    relatedUserId,
  }
}

export const extractAllSigners = ({ lease }: { lease: Lease }): Signer[] => {
  const signers: Signer[] = []

  if (typeChecker.isCanadaQuebecLease(lease)) {
    lease?.leaseInformation?.lessees.forEach(({ userId, fullName, signature, guarantor }) => {
      signers.push(buildSigner({ id: userId, fullName, signature, role: LeaseRoleNames.LESSEE }))

      !!guarantor &&
        signers.push(
          buildSigner({
            id: guarantor.emailAddress,
            fullName: guarantor.fullName,
            signature: guarantor.signature,
            role: LeaseRoleNames.GUARANTOR,
            relatedUserId: userId,
          }),
        )
    })
    lease?.leaseInformation?.lessors.forEach(({ userId, fullName, signature }) => {
      signers.push(buildSigner({ id: userId, fullName, signature, role: LeaseRoleNames.LESSOR }))
    })
  }

  if (typeChecker.isCanadaOntarioLease(lease)) {
    lease?.leaseInformation?.lessees.forEach(({ userId, firstName, lastName, signature, guarantor }) => {
      signers.push(
        buildSigner({
          id: userId,
          fullName: [firstName, lastName],
          signature,
          role: LeaseRoleNames.LESSEE,
        }),
      )

      !!guarantor &&
        signers.push(
          buildSigner({
            id: guarantor.emailAddress,
            fullName: [guarantor.firstName, guarantor.lastName],
            signature: guarantor.signature,
            role: LeaseRoleNames.GUARANTOR,
            relatedUserId: userId,
          }),
        )
    })
    lease?.leaseInformation?.lessors.forEach(({ userId, firstName, lastName, signature }) => {
      signers.push(
        buildSigner({
          id: userId,
          fullName: [firstName, lastName],
          signature,
          role: LeaseRoleNames.LESSOR,
        }),
      )
    })
  }

  return signers
}
