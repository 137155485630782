import React from 'react'

import { Checkbox, FormSection4, useTranslation } from '@guiker/react-framework'

type PetsAllowedProps = {
  defaultValue: boolean
  name: string
}

export const PetsAllowed: React.FC<PetsAllowedProps> = ({ defaultValue, name }) => {
  const { t } = useTranslation(['common-tenantPreference', 'common-rentalListing'])

  return (
    <FormSection4>
      <Checkbox
        key={'petsAllowed'}
        defaultValue={defaultValue}
        name={name}
        label={t('common-tenantPreference:unitPreferences.pets')}
      />
    </FormSection4>
  )
}
