import React from 'react'

import { Flex, FormSection2 } from '@guiker/components-library'
import { Invoice } from '@guiker/payment-shared'

import { useT } from '../../i18n'
import { LateInvoice } from './LateInvoice'

type Prop = {
  invoices: Invoice[]
  renderAction?: (invoiceId: string, invoiceStatus?: string) => React.ReactNode
}

const ListLateInvoices: React.FC<Prop> = ({ invoices, renderAction }) => {
  const { tShared } = useT({})

  return (
    <FormSection2 title={tShared('paymentMethods.lateInvoices.title')}>
      <Flex flexDirection='column' gap={2}>
        {invoices.map((invoice) => (
          <LateInvoice invoice={invoice} renderAction={renderAction} />
        ))}
      </Flex>
    </FormSection2>
  )
}

export { ListLateInvoices }
