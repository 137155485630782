import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { Invoice, PayInMethod } from '@guiker/payment-shared'
import { FetcherArg, PaginatedTable, useNavigate, useT } from '@guiker/react-framework'
import { DataAndMeta } from '@guiker/shared-framework'

import { getColumns } from './get-columns'

type AllInvoicesByPaymentMethodTableProp = {
  payInMethod: PayInMethod
  type: 'RENTALS' | 'PAYMENTS'
  fetcher: (paginate: FetcherArg) => Promise<DataAndMeta<Invoice[]>>
}

export const AllInvoicesByPaymentMethodTable: React.FC<AllInvoicesByPaymentMethodTableProp> = ({
  payInMethod,
  type,
  fetcher,
}) => {
  const { tMain } = useT({ domain: 'account', screenName: 'components.invoicesByPaymentMethodTable' })
  const navigate = useNavigate()

  return (
    <PaginatedTable
      title={tMain(type === 'RENTALS' ? 'rentalTitle' : 'title')}
      queryKey={
        type === 'RENTALS'
          ? `readAllLegacyInvoicesByPaymentMethod-${payInMethod.legacyId}`
          : `readAllInvoicesByPaymentMethod-${payInMethod.id}`
      }
      fetcher={fetcher}
      hideIfEmpty
      onRowClick={(invoice) => navigate(webappRoutes.root.wallet().payments().invoice().byId(invoice.id).path)}
      columns={getColumns({ withAction: true })}
    />
  )
}
