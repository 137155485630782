import { DealType } from '@guiker/base-listing-shared'
import { BuildingTypes } from '@guiker/real-estate-shared'
import { yup } from '@guiker/yup-util'

const geocoordinatesSchema = yup.object({
  lat: yup.number(),
  lng: yup.number(),
})

export const searchListingSchema = yup.object({
  city: yup.string().transform((value) => value || undefined),
  mapBounds: yup
    .object({
      bottomLeft: geocoordinatesSchema,
      topRight: geocoordinatesSchema,
    })
    .nullable()
    .transform((value) => value || undefined),
  dealType: yup
    .mixed()
    .oneOf(Object.values(DealType))
    .transform((value) => value || undefined),
  buildingType: yup
    .mixed()
    .oneOf(Object.values(BuildingTypes))
    .transform((value) => value || undefined),
  price: yup
    .array<[number, number]>()
    .of(yup.number())
    .transform((value) => value || undefined),
  projectedIRR: yup
    .array<[number, number]>()
    .of(yup.number())
    .transform((value) => value || undefined),
})

export type SearchListingSchema = yup.InferType<typeof searchListingSchema>
