import { CountByStatusMetadata, routesBuilder } from '@guiker/shared-framework'

import {
  FundingAccountWithdrawal,
  FundingAccountWithdrawalProjection,
  FundingAccountWithdrawalStatus,
} from '../../../../entity/funding-account'
import { paths } from '../../paths'
import { getStatusesSchemas } from '../../schemas/status-schema'
import { createPayoutWithdrawalSchema, searchStringSchema } from '../schemas'

const builder = {
  projection: {
    base: routesBuilder<FundingAccountWithdrawalProjection>()({
      basePath: paths.fundingAccounts.withdrawals.path,
    }),
    withdrawal: routesBuilder<FundingAccountWithdrawalProjection>()({
      basePath: paths.fundingAccounts.withdrawal.withId.path,
    }),
  },
  base: routesBuilder<FundingAccountWithdrawal>()({
    basePath: paths.fundingAccounts.withdrawals.path,
  }),
}

export const routes = {
  readAllWithdrawals: builder.projection.base.buildGet({
    paginated: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountWithdrawalStatus>()),
    additionalMeta: {} as CountByStatusMetadata<FundingAccountWithdrawalStatus>,
  }),
  exportWithdrawals: builder.projection.base.buildGet({
    path: '/export',
    responseIsArray: true,
    queryParamsSchema: searchStringSchema.concat(getStatusesSchemas<FundingAccountWithdrawalStatus>()),
  }),
  createPayoutWithdrawal: builder.base.buildPost({
    payloadSchema: createPayoutWithdrawalSchema,
  }),
  readOneWithdrawal: builder.projection.withdrawal.buildGet({}),
} as const
