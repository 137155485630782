export enum AnimalType {
  DOG = 'DOG',
  CAT = 'CAT',
  OTHER = 'OTHER',
}

export type Pets = {
  additionalInfo?: string
  isSupportServiceAnimal?: boolean
  type?: AnimalType
  other?: string
}
