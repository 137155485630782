import { yup } from '@guiker/yup-util'

import { LeadOption, RentalOption } from '../../entity'
import { RentalType } from '../../entity/legacy'
import { roomCountSchema } from './floor-plan-schema'
import { priceOptionSchema } from './price-option-schema'
import { unitAmenitiesSchema } from './unit-amenities-schema'
import { utilitiesSchema } from './utilities-schema'

const baseRentalOption = {
  type: yup.string().nullable().oneOf(Object.values(RentalType)).default(RentalType.ENTIRE),
  suiteNumber: yup.string().nullable(),
  size: yup.string().nullable(),
  roomCount: roomCountSchema.required().default(0),
  unitAmenities: unitAmenitiesSchema.nullable().default(0),
  utilities: utilitiesSchema.nullable().default(0),
}

export const leadOptionSchema = yup.object<LeadOption>({
  ...baseRentalOption,
  priceOption: priceOptionSchema.required().default(0),
})

export const rentalOptionSchema = yup.object<RentalOption>({
  ...baseRentalOption,
  priceOptions: yup.array().of(priceOptionSchema).min(1).ensure(),
})
