import React from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { BookingRoleNames } from '@guiker/booking-shared'
import { ApiFormAction, Flex, SecondaryButton, TextField, useTranslation } from '@guiker/react-framework'

type InviteeFormProps = {
  handleClose: () => void
  onSuccess: () => void
  onError: (error: Error) => void
}

const InviteeForm: React.FC<InviteeFormProps> = ({ handleClose, onSuccess, onError }) => {
  const { t } = useTranslation(['common'])
  const { apiClient, bookingId } = useBookingContext()

  const onSubmit = (payload: { emailAddress: string; firstName: string; role: BookingRoleNames }) =>
    apiClient.inviteBookingUnitManagerByEmail({ pathParams: { bookingId }, payload })

  return (
    <Flex flexDirection='column' gap={2}>
      <TextField name='firstName' label={t('user.firstName')} defaultValue='' />
      <TextField name='emailAddress' label={t('user.emailAddress')} defaultValue='' />

      <Flex fullWidth gap={2}>
        <SecondaryButton onClick={handleClose} fullWidth>
          {t('actions.cancel')}
        </SecondaryButton>

        <ApiFormAction
          fullWidth
          onClick={onSubmit}
          options={{ onSuccess, onError }}
          buttonType='primary'
          buttonProps={{ fullWidth: true }}
        >
          {t('actions.invite')}
        </ApiFormAction>
      </Flex>
    </Flex>
  )
}

export { InviteeForm }
