import { Role } from '@guiker/permissions'

import { BookingActions } from '../booking-actions'
import { BookingRoleNames } from '../role-names'

export const InvitedLandlordRole: Role = {
  name: BookingRoleNames.INVITED_LANDLORD,
  permissions: [
    { action: BookingActions.EditApplicationStatus },
    { action: BookingActions.GetBookingPayment },
    { action: BookingActions.DraftLease },
    { action: BookingActions.ViewBackgroundCheck },
    { action: BookingActions.ViewSSN },
    { action: BookingActions.ViewBookingDocument },
  ],
}
