import { camelCase as untypedCamelCase, kebabCase as untypedKebabCase, snakeCase as untypedSnakecase } from 'lodash'

import { CamelCase, ConstantCase, KebabCase, SnakeCase } from '@guiker/ts-utils'

export const camelCase = <V extends string>(value: V): CamelCase<V> => {
  return untypedCamelCase(value) as CamelCase<V>
}

export const snakeCase = <V extends string>(value: V): SnakeCase<V> => {
  return untypedSnakecase(value) as SnakeCase<V>
}

export const constantCase = <V extends string>(value: V): ConstantCase<V> => {
  return untypedSnakecase(value).toUpperCase() as ConstantCase<V>
}

export const kebabCase = <V extends string>(value: V): KebabCase<V> => {
  return untypedKebabCase(value) as KebabCase<V>
}
