import React from 'react'

import { ColumnConfig, P, TFunction } from '@guiker/react-framework'
import { Currency } from '@guiker/shared-framework'

import { RentalInfo, UnitConfiguration } from './BaseComponents'
import { Unit } from './type'

export const getColumns = (currency: Currency, t: TFunction): ColumnConfig<Unit>[] => {
  return [
    {
      name: 'unit',
      size: 1.5,
      label: t('revenue.occupancy.unit'),
      verticalAlign: 'top',
      renderValue: ({ unit }) => <P mb={0}>{unit}</P>,
    },
    {
      name: 'leaseTerm',
      size: 3,
      label: t('revenue.occupancy.leaseTerm.label'),
      verticalAlign: 'top',
      renderValue: (unit) => {
        return <UnitConfiguration unit={unit} />
      },
    },
    {
      name: 'monthlyRent',
      size: 2,
      label: t('revenue.occupancy.monthlyRent.label'),
      verticalAlign: 'top',
      renderValue: (unit) => {
        return !!unit.monthlyRent ? <RentalInfo unit={unit} currency={currency.iso} /> : <></>
      },
    },
  ]
}
