import { payoutRoutes } from './payout'
import { payoutMethodRoutes } from './payout-method'
import { payoutProviderRoutes } from './payout-provider'
import { transferRoutes } from './transfer'

export const routes = {
  ...payoutMethodRoutes,
  ...payoutProviderRoutes,
  ...payoutRoutes,
  ...transferRoutes,
}
