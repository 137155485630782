import React, { createContext, useEffect, useState } from 'react'

import { Geobox, Geocoordinates } from '@guiker/base-entity'
import { BaseListing } from '@guiker/base-listing-shared'
import { generateUseContext } from '@guiker/react-context'

type MapLayoutContext<T extends BaseListing> = {
  listings: T[]
  mapCenter: Geocoordinates
  mapBounds: Geobox
  zoom: number
  setListings: (listings: T[]) => void
  setMapInfo: (args: { center?: Geocoordinates; bounds?: Geobox }) => void
}

const MapLayoutContext = createContext<MapLayoutContext<BaseListing>>(null)

export type MapLayoutContextProviderProps = React.PropsWithChildren & {
  mapCenter: Geocoordinates
  zoom: number
}

export const MapLayoutContextProvider: React.FC<MapLayoutContextProviderProps> = (props) => {
  const [listings, setListings] = useState<BaseListing[]>([])
  const [zoom, setZoom] = useState<number>(props.zoom)
  const [mapCenter, setMapCenter] = useState<Geocoordinates>(props.mapCenter)
  const [mapBounds, setMapBounds] = useState<Geobox>(undefined)

  useEffect(() => {
    zoom !== props.zoom && setZoom(props.zoom)
  }, [props.zoom])

  const setMapInfo = (args: { center?: Geocoordinates; bounds?: Geobox }) => {
    args.center && setMapCenter(args.center)
    args.bounds && setMapBounds(args.bounds)
  }

  const value = { listings, setListings, mapCenter, mapBounds, zoom, setMapInfo }
  return <MapLayoutContext.Provider value={value}>{props.children}</MapLayoutContext.Provider>
}

export const useMapLayoutContext = generateUseContext(MapLayoutContext)
