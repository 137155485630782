import React from 'react'

import { makeStyles } from '@guiker/components-core'
import { CloseIcon, DeleteMiniIcon, DownloadMiniIcon } from '@guiker/icons'

import { Button, Dialog, Flex } from '../../components'
import { AssetFile } from '../../entity'
import { isImageType } from '../../utils'
import { AssetImage } from './AssetImage'

type ViewAssetProps = {
  asset: AssetFile
  open: boolean
  readOnly: boolean
  onClose: () => void
  removeAsset: () => void
}

const useStyle = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    heights: '100%',
  },
  buttonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

const ViewAsset: React.FC<ViewAssetProps> = ({ asset, open, onClose, removeAsset, readOnly }) => {
  const classes = useStyle()
  const onDownloadClicked = () => typeof window !== 'undefined' && window.open(asset.presignedDownloadUrl)

  return (
    <Dialog open={open} onClose={onClose} maxWidth={900}>
      <div className={classes.root}>
        <Flex p={2} gap={1} className={classes.buttonContainer}>
          {!readOnly && (
            <Button
              size='small'
              onClick={() => {
                removeAsset()
                onClose()
              }}
              startIcon={<DeleteMiniIcon />}
            />
          )}
          {readOnly && <Button size='small' startIcon={<DownloadMiniIcon />} onClick={onDownloadClicked} />}
          <Button size='small' startIcon={<CloseIcon />} onClick={onClose} />
        </Flex>
      </div>
      {isImageType(asset.mimeType) && <AssetImage asset={asset} />}
    </Dialog>
  )
}

export { ViewAsset }
