import { Role } from '@guiker/permissions'

import { BookingActions } from '../booking-actions'
import { BookingRoleNames } from '../role-names'

export const AgentRole: Role = {
  name: BookingRoleNames.AGENT,
  permissions: [
    { action: BookingActions.ParticipateChat },
    { action: BookingActions.GetBookingSteps },
    { action: BookingActions.GetBookingPayment },
    { action: BookingActions.EditApplicationStatus },
    { action: BookingActions.DraftLease },
    { action: BookingActions.ViewBackgroundCheck },
    { action: BookingActions.ViewSSN },
  ],
}
