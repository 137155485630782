import { yup } from '@guiker/shared-framework'

export const getStatusesSchemas = <T extends string>() =>
  yup.object<{
    statuses?: T[]
  }>({
    statuses: yup
      .array()
      .of<T>(yup.mixed<T>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
  })
