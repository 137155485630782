import { DealType, InvestmentAssumptions } from '@guiker/base-listing-shared'
import { DeepPartial } from '@guiker/ts-utils'

import { PropSharingListing } from '../entity'

const purchaseAssumptions = InvestmentAssumptions.buildPurchaseConfiguration({ dealType: DealType.NEW })

export const defaultAssumptions: DeepPartial<PropSharingListing> = {
  assumptions: {
    base: {
      holdingPeriod: 10,
      npvDiscountRate: 0,
      propertyAppreciationRate: 0.02,
      assessedValue: 0,
    },
    revenue: {
      rentalRevenue: 0,
      rentalGrowthRate: 0.02,
      vacancyRate: 0.03,
      factors: {
        rentalGrowthRate: 0.01,
        vacancyRate: 0.01,
      },
    },
    purchase: purchaseAssumptions['montreal-qc'],
    financing: {
      mortgage: {
        minimumDownPaymentPercentage: 0.2,
        termsInYears: 25,
        interestRate: 0.06,
      },
    },
    operation: InvestmentAssumptions.OperationConfiguration['montreal-qc'],
  },
  price: purchaseAssumptions['montreal-qc'].price,
  results: {
    monthlyMortgagePayment: 0,
    targetEquity: 0,
    npv: 0,
    irr: 0,
    coc: 0,
  },
}
