import { booleanFalseByDefault, booleanTrue, TypeOf, yup } from '@guiker/yup-util'

const typeOfReport = yup.object().shape(
  {
    requestSoftcheck: booleanFalseByDefault.when('requestCreditReport', {
      is: (value) => !value,
      then: booleanTrue,
    }),
    requestCreditReport: booleanFalseByDefault.when('requestSoftcheck', {
      is: (value) => !value,
      then: booleanTrue,
    }),
  },
  [['requestCreditReport', 'requestSoftcheck']],
)

export const createBackgroundCheckPayloadValidator = typeOfReport.shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  provinceState: yup.string().required(),
  postalCode: yup.string().required(),
  country: yup.string().required(),
  dateOfBirth: yup.string().required(),
  targetId: yup.string(),
  ssn: yup.string().nullable(),
  email: yup.string().email().required(),
  consent: yup.boolean().nullable().default(null),
  sanitize: yup.boolean().nullable().default(true),
})

export type CreateBackgroundCheckPayload = TypeOf<typeof createBackgroundCheckPayloadValidator>
