import { ComplexConfig, Config } from './config'
import * as Schema from './schema'

const isComplexConfig = <K extends Config>(configKey: keyof K): configKey is ComplexConfig => {
  return Object.values(ComplexConfig).includes(configKey as unknown as ComplexConfig)
}

const complexConfigValidator: {
  [key in ComplexConfig]: (payload: unknown) => Promise<unknown>
} = {
  ADMIN_EMAILS: (payload: unknown) => Schema.adminEmailsSchema.validate(payload, { abortEarly: false }),
  AWS_CREDENTIALS: (payload: unknown) => Schema.awsCredentialsSchema.validate(payload, { abortEarly: false }),
  CERTN_CLIENT_CFG: (payload: unknown) => Schema.certnClientConfigSchema.validate(payload, { abortEarly: false }),
  DOCUSIGN_CONFIG: (payload: unknown) => Schema.docusignConfigSchema.validate(payload, { abortEarly: false }),
  GOOGLE_CONFIG: (payload: unknown) => Schema.googleConfigSchema.validate(payload, { abortEarly: false }),
  LEGACY_CONVERSATION_API_CONFIG: (payload: unknown) =>
    Schema.legacyConversationApiConfigSchema.validate(payload, { abortEarly: false }),
  LEGACY_PAYMENT_VERIFICATION_SERVICE_CONFIG: (payload: unknown) =>
    Schema.legacyPaymentVerificationApiConfigSchema.validate(payload, { abortEarly: false }),
  LEGACY_SQL_CONFIG: (payload: unknown) => Schema.legacySqlConfigSchema.validate(payload, { abortEarly: false }),
  PAYSAFE_CONFIG: (payload: unknown) => Schema.paysafeConfigSchema.validate(payload, { abortEarly: false }),
  PLAID_CONFIG: (payload: unknown) => Schema.plaidConfigSchema.validate(payload, { abortEarly: false }),
  RENTSYNC_CONFIG: (payload: unknown) => Schema.rentsyncConfigSchema.validate(payload, { abortEarly: false }),
  REPLIERS_CONFIG: (payload: unknown) => Schema.repliersConfigSchema.validate(payload, { abortEarly: false }),
  STRIPE_CONFIG: (payload: unknown) => Schema.stripeConfigSchema.validate(payload, { abortEarly: false }),
  TWILIO_CONFIG: (payload: unknown) => Schema.twilioConfigSchema.validate(payload, { abortEarly: false }),
  TRANSFERWISE_CONFIG: (payload: unknown) => Schema.transferwiseConfigSchema.validate(payload, { abortEarly: false }),
}

export const configValidator = <K extends Config>(configKey: keyof K, payload: unknown) => {
  if (isComplexConfig(configKey)) {
    return complexConfigValidator[configKey](payload)
  } else {
    return payload
  }
}
