import { TFunction } from '@guiker/i18n'
import { RoomCount } from '@guiker/rental-listing-shared'

const printBedAndBathCount = (t: TFunction, roomCount: RoomCount, delimiter = '·') => {
  const bedCount =
    roomCount?.bedroom === 0
      ? t('listing:bedroomCount.studio')
      : t('listing:bedroomCount.nonStudio', { count: roomCount?.bedroom })
  const bathCount = t('listing:bathroomCount', { count: roomCount?.bathroom })

  return `${bedCount} ${delimiter} ${bathCount}`
}

export { printBedAndBathCount }
