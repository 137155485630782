import { TypeOf, yup } from '@guiker/yup-util'

import { genderSchema, roommateProfileSchema } from '../../schemas'

export const updateTenantApplicationRoommateProfilePayloadValidator = yup.object({
  roommateProfile: roommateProfileSchema.required(),
  profile: yup.object({
    gender: genderSchema.required(),
  }),
})

export type UpdateTenantApplicationRoommateProfilePayload = TypeOf<
  typeof updateTenantApplicationRoommateProfilePayloadValidator
>
