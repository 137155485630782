import { yup } from '@guiker/yup-util'

import { businessTypes, EmploymentType } from '../../../../entity/investor-profile'

const nullableStringSchema = yup
  .string()
  .optional()
  .nullable()
  .transform(() => undefined)

export const employmentInformationSchema = yup.object({
  isPEPorHIO: yup.boolean().required(),
  type: yup.string().oneOf(Object.values(EmploymentType)).required(),
  incomeSource: yup.string().when('type', {
    is: (type: EmploymentType) => ![EmploymentType.Employed, EmploymentType.SelfEmployed].includes(type),
    then: yup.string().required(),
    otherwise: nullableStringSchema,
  }),
  employerName: yup.string().when('type', {
    is: (type: EmploymentType) => EmploymentType.Employed === type,
    then: yup.string().required(),
    otherwise: nullableStringSchema,
  }),
  businessType: yup.string().when('type', {
    is: (type: EmploymentType) => [EmploymentType.Employed, EmploymentType.SelfEmployed].includes(type),
    then: yup.string().oneOf(businessTypes).required(),
    otherwise: nullableStringSchema,
  }),
  jobTitle: yup.string().when('type', {
    is: (type: EmploymentType) => EmploymentType.Employed === type,
    then: yup.string().required(),
    otherwise: nullableStringSchema,
  }),
})
