import { compareDate, DateTime, parseDateTime } from '@guiker/date'
import { CurrencyISO } from '@guiker/money'
import { yup } from '@guiker/yup-util'

import { PriceOption } from '../../entity'

export const priceSchema = yup.object({
  amount: yup.number().required(),
  currency: yup.string().oneOf(Object.values(CurrencyISO)).required(),
})

export const priceOptionSchema = yup.object<Omit<PriceOption, 'status'>>({
  price: priceSchema.required(),
  availablityDate: yup
    .string()
    .required()
    .test('minDate', 'availablityDate should be after min date', (date: string) => {
      return compareDate(date).isAfter(DateTime.local().minus({ months: 1 }))
    }),
  expirationDate: yup
    .string()
    .when(
      ['availablityDate', 'duration'],
      (availablityDate: string, duration: { count: number; unit: string }, schema: yup.StringSchema) => {
        return schema.transform((value) => {
          return !!value ? value : parseDateTime(availablityDate).plus({ months: duration.count }).toISO()
        })
      },
    ),
  legacyOptionId: yup.number().nullable(),
  duration: yup
    .object({
      count: yup.number().required(),
      unit: yup.string().oneOf(['month', 'year', 'week']).required(),
    })
    .required(),
  furnished: yup.string().oneOf(['fully', 'partially', 'none']).required(),
})
