import React, { useState } from 'react'

import { CountryCode } from '@guiker/base-entity'
import { makeStyles, toPx } from '@guiker/components-core'
import { Flex, Link, P, Spinner, TextButton } from '@guiker/components-library'
import { DateTime } from '@guiker/date'
import { useTranslation } from '@guiker/i18n'
import { legacyRoutes } from '@guiker/legacy-routes'
import { PieChart } from '@guiker/react-chart'
import { UnitsOccupancyKpiKey } from '@guiker/statistic-shared'

import { useAuthenticatedStatisticApiClient, useAuthenticationContext, useQuery } from '../../hooks'
import { transformKpiDataToDataset } from './utils'

const useStyles = makeStyles({
  pieChart: {
    minHeight: 260,
    minWidth: 380,
    fontSize: toPx(14),
  },
})

// Not used, will use when working on occupancy
const UnitsOccupancyPieChart: React.FC<{ region: CountryCode }> = ({ region }) => {
  const { user } = useAuthenticationContext()
  const statisticApi = useAuthenticatedStatisticApiClient()
  const { t } = useTranslation(['main-statistic'])
  const [error, setError] = useState(undefined)
  const classes = useStyles()

  const { data, isFetching } = useQuery(
    ['unitsOccupancyPieChart', user.id],
    () =>
      statisticApi
        .generateUnitsOccupancyKpi({
          queryParams: {
            month: DateTime.local().toFormat('yyyy-MM'),
            country: region,
            listingId: null,
            unitId: null,
          },
        })
        .then((res) => transformKpiDataToDataset(res, t)),
    {
      enabled: !!user.metadata && !!region,
      onError: setError,
      retry: 1,
    },
  )

  const total = data?.reduce((r, d) => r + d.value, 0)
  const rented = data?.find(({ key }) => key === UnitsOccupancyKpiKey.RENTED)?.value
  const occupancyRate = (rented / total) * 100

  return (
    <Flex flexDirection='column'>
      <Link isExternalLink underline={false} hoverUnderline={false} to={`${legacyRoutes.MyPaymentHistory}`}>
        <TextButton>{t('components.unitsOccupancyPieChart.header')}</TextButton>
      </Link>
      <Flex p={2} mt={2} className={classes.pieChart}>
        {isFetching && <Spinner />}
        {error ? (
          <P mb={0}>{t('components.unitsOccupancyPieChart.error')}</P>
        ) : (
          data?.length > 0 && (
            <PieChart
              dataset={data}
              total={total}
              highlight={{
                labelFormatter: (value: number) => `${value.toFixed(0)} %`,
                value: occupancyRate,
                label: t('components.unitsOccupancyPieChart.highlight'),
              }}
            />
          )
        )}
      </Flex>
    </Flex>
  )
}
export { UnitsOccupancyPieChart }
