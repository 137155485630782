import React from 'react'

import { ReportChip } from '@guiker/background-check-components'
import { BackgroundCheckRoleNames, ConductFor, CountryCode as SupportedCountry } from '@guiker/background-check-shared'
import { BookingActions, isApplicationInReviewProgress } from '@guiker/booking-shared'
import { SsnTextField } from '@guiker/masked-text-field'
import {
  AlertIcon,
  DownloadMiniIcon,
  FormSection3,
  Link,
  Note,
  OneColumnGridLayout,
  P,
  SecondaryButton,
  useQuery,
  useTranslation,
} from '@guiker/react-framework'
import { parseDateTime } from '@guiker/shared-framework'
import { BaseAddress, TenantApplication } from '@guiker/tenant-application-shared'

import { useApplicationComponentsContext } from '../../../context'
import { getApplicationBackgroundCheckReports, Report } from '../../../helper'
import { useBookingApplicationContext, useBookingContext } from '../../../hooks'
import { useJwtBackgroundCheckApiClient } from '../../../lib'
import { buildErrors } from '../../../utils'
import { AddressForm } from '../../AddressForm'
import { BackgroundCheckConsentFormContent } from './BackgroundCheckConsentFormContent'
import { BackgroundCheckHistory } from './BackgroundCheckHistory'
import { findLegalAddressFromTenantApplication } from './helpers'

type BackgroundCheckReportsListProps = {
  application: TenantApplication
}

const BackgroundCheckReport: React.FC<{ report: Report; application: TenantApplication }> = ({
  application,
  report: { consent, creditStatus = 'NONE', creditScore, status, type, updatedAt },
}) => {
  const { t } = useTranslation(['main-tenantApplication', 'common'])
  const { accessControl, can, booking } = useBookingContext({ shouldThrowIfUndefined: false })
  const apiClient = useJwtBackgroundCheckApiClient()
  const bookingApplication = booking.applicants.find((a) => a.userId === application.userId)?.application
  const isInReviewProcess = isApplicationInReviewProgress(bookingApplication)
  const {
    handleBackgroundCheckModal,
    setConductCheckFor,
    requestBackgroundCheck: canRequestBackgroundCheck,
  } = useApplicationComponentsContext()

  const { decryptSSN, decryptGuarantorSSN } = useBookingApplicationContext({ shouldThrowIfUndefined: false })

  const canViewSSN = !!can && can(BookingActions.ViewSSN)
  const isGuarantor = type === 'guarantor'
  const formPrefix = `${isGuarantor && 'guarantor.'}backgroundCheck.consent`
  const isPendingBackgroundCheck = status === 'Pending'

  const { profile, userId, address, backgroundCheck } = isGuarantor
    ? application.guarantor
      ? {
          address: findLegalAddressFromTenantApplication({ tenantApplication: application, isGuarantor: true }),
          profile: application.guarantor.profile,
          userId: application.guarantor.profile?.userId,
          backgroundCheck: application.guarantor.backgroundCheck,
        }
      : ({} as Partial<TenantApplication> & { address: BaseAddress })
    : {
        ...application,
        address: findLegalAddressFromTenantApplication({ tenantApplication: application, isGuarantor: false }),
      }

  const errors = buildErrors({ address, profile, userId, isGuarantor, t })
  const canDownload = accessControl?.claims.acl.backgroundCheck?.roles.includes(BackgroundCheckRoleNames.SPECTATOR)

  const { data: reportUrl } = useQuery(
    [`backgroundCheck-link-${type}`, backgroundCheck?.id],
    () =>
      apiClient.generatePDFLink({
        pathParams: { backgroundCheckId: backgroundCheck?.id },
        accessControl: { token: accessControl.token },
      }),
    {
      enabled: accessControl && !!backgroundCheck?.id && backgroundCheck?.status === 'Returned' && canDownload,
    },
  )

  const requestBackgroundCheck = (conductCheckFor: ConductFor) => {
    setConductCheckFor(conductCheckFor)
    handleBackgroundCheckModal()
  }

  return (
    <FormSection3
      title={t(`screens.steps.backgroundCheck.${type}Report`)}
      subtitle={
        isPendingBackgroundCheck
          ? t('main-tenantApplication:screens.steps.backgroundCheck.inProgress')
          : updatedAt && parseDateTime(updatedAt).toFormat('dd / LL / yyyy')
      }
    >
      {status === 'Returned' && <ReportChip creditStatus={creditStatus} score={creditScore} />}
      {reportUrl && (
        <Link
          to={reportUrl}
          isExternalLink
          target='_blank'
          underline={false}
          adornment={<DownloadMiniIcon color='primary' />}
        >
          {t('common:actions.download')}
        </Link>
      )}
      {consent?.isConsentGiven && !!canRequestBackgroundCheck && isInReviewProcess ? (
        <>
          {!isGuarantor && (
            <AddressForm
              defaultValue={address}
              readOnly={true}
              label={t('screens.steps.backgroundCheck.legalAddress.label')}
            />
          )}
          {address?.country === SupportedCountry.US && canViewSSN && (
            <OneColumnGridLayout>
              <SsnTextField
                defaultValue={backgroundCheck?.ssn}
                name='backgroundCheck.ssn'
                readOnly={true}
                revealText={isGuarantor ? decryptGuarantorSSN : decryptSSN}
              />
            </OneColumnGridLayout>
          )}

          <div>
            <SecondaryButton
              fullWidth={false}
              disabled={isPendingBackgroundCheck || !!errors}
              onClick={() => requestBackgroundCheck(type)}
            >
              {t('main-tenantApplication:screens.steps.backgroundCheck.conductCheck')}
            </SecondaryButton>
          </div>

          <BackgroundCheckHistory backgroundCheckId={backgroundCheck?.id} />

          {errors && (
            <Note icon={<AlertIcon />} statusColor='alert' title={t(`screens.steps.backgroundCheck.errors.startError`)}>
              {
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>
                      <P mb={0}>{error}</P>
                    </li>
                  ))}
                </ul>
              }
            </Note>
          )}
        </>
      ) : (
        <BackgroundCheckConsentFormContent consentFor={type} defaultValue={consent} formPrefix={formPrefix} readOnly />
      )}
    </FormSection3>
  )
}

export const BackgroundCheckReportsListFormContent: React.FC<BackgroundCheckReportsListProps> = ({ application }) => {
  const reports = getApplicationBackgroundCheckReports(application)

  if (!application) {
    return <></>
  }

  return (
    <>
      {reports.map((report, index) => (
        <BackgroundCheckReport key={index} application={application} report={report} />
      ))}
    </>
  )
}
