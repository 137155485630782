import React from 'react'

import { Flex } from '@guiker/react-framework'

import { UnitManagerBookingConfirm } from './UnitManagerBookingConfirm'
import { UnitManagerBookingRejection } from './UnitManagerBookingRejection'
import { UnitManagerBookingSteps } from './UnitManagerBookingSteps'

const UnitManagerBookingPanel: React.FC = () => {
  return (
    <Flex flexDirection='column' gap={8}>
      <Flex flexDirection='column' gap={3}>
        <UnitManagerBookingSteps />
        <UnitManagerBookingConfirm />
      </Flex>
      <UnitManagerBookingRejection />
    </Flex>
  )
}

export { UnitManagerBookingPanel }
