import { yup } from '@guiker/yup-util'

import { UnitPreferences } from '../../entity'
import { appliances } from './appliances'
import { utilities } from './utilities'

export const unitPreferences = yup.object<UnitPreferences>({
  utilities,
  appliances,
  petsAllowed: yup.boolean().nullable().default(true),
})

export type UnitPreferencesSchema = yup.InferType<typeof unitPreferences>
