import { ValueOf } from '@guiker/ts-utils'

import { BaseEventTypes } from './base-event-types'
import { EventType } from './event-type'
import { GeneratedEvents, generateEventsFromEnum } from './generate-events-from-enum'

type EventTypes<ET> = {
  [key in keyof ET]: ET[key] extends string
    ? ET[key]
    : ET[key] extends { type: string; eventData: unknown }
    ? ET[key]['type']
    : never
}

export type EventsDefinition<ET extends Record<string, EventType>, C extends string, E extends string, D> = {
  context: C
  entity: E
  eventTypes?: EventTypes<ET>
  events: GeneratedEvents<typeof BaseEventTypes & ET, C, E, D>
}

export const generateEventsDefinition = <ET extends Record<string, EventType>, C extends string, E extends string, D>({
  context,
  entity,
  eventTypes = {} as ET,
  data,
}: {
  context: C
  entity: E
  eventTypes?: ET
  data: D
}): EventsDefinition<typeof BaseEventTypes & ET, C, E, D> => {
  const allEventTypes = {
    ...BaseEventTypes,
    ...eventTypes,
  }

  const events = generateEventsFromEnum({ context, entity, data })(allEventTypes)

  return {
    context,
    entity,
    eventTypes: Object.entries(events).reduce(
      (acc, [type, event]) => ({
        ...acc,
        ...{
          [type]: event.type || type,
        },
      }),
      {},
    ) as EventTypes<typeof BaseEventTypes & ET>,
    events,
  }
}

export type ValueOfEventTypes<T extends ReturnType<typeof generateEventsDefinition>> =
  | ValueOf<typeof BaseEventTypes>
  | ValueOf<T['eventTypes']>
