import { UnauthorizedError } from './errors'
import { Role } from './role'
import { RoleNameRoleResolver } from './role-name-role-resolver'
import { RoleOwner } from './role-owner'
import { RoleResolver } from './role-resolver'

type UserPermissionMap = {
  [actionName: string]: true
}

type ResultCache = {
  [cacheKey: string]: boolean
}

const buildUserPermissionMap = (roles: Role[]): UserPermissionMap => {
  const permissionMap: UserPermissionMap = {}
  if (!roles?.length) {
    return permissionMap
  }

  for (const role of roles) {
    if (role && role.permissions) {
      for (const permission of role.permissions) {
        // actions can be an array or string. Ensure it's always an array so we don't need two branches of logic
        const actionsArray = Array.isArray(permission.action) ? permission.action : [permission.action]
        actionsArray.forEach((actionName) => (permissionMap[actionName] = true))
      }
    }
  }

  return permissionMap
}

export type CanOptions = {
  adminOnly?: boolean
}

export type CanFunction<Action> = (action: Action | Action[], options?: CanOptions) => boolean
export type canOrThrowFunction<Action> = (action: Action | Action[]) => true

export type InitialzeCan<Action> = () => { can: CanFunction<Action>; canOrThrow: canOrThrowFunction<Action> }

const getCachedResult = <Action>(action: Action | Action[], resultCache: ResultCache) => {
  const cacheKey = JSON.stringify(action)
  if (resultCache[cacheKey]) {
    return resultCache[cacheKey]
  }
}

const setAndReturnCachedResult = <Action>(
  action: Action | Action[],
  result: boolean,
  resultCache: ResultCache,
): boolean => {
  const cacheKey = JSON.stringify(action)
  resultCache[cacheKey] = result
  return result
}

const isAuthorized = <Action extends string>({
  roles,
  action,
  resultCache,
  hasPermission = true,
}: {
  roles: Role[]
  action: Action | Action[]
  resultCache: ResultCache
  hasPermission?: boolean
}) => {
  const permissionMap = buildUserPermissionMap(roles)

  const actionsArray = Array.isArray(action) ? action : [action]

  for (const actionName of actionsArray) {
    const allActionsAreAllowed = !!permissionMap['*']
    const thisActionIsAllowed = !!permissionMap[actionName]

    const isAuthorized = hasPermission && (allActionsAreAllowed || thisActionIsAllowed)
    if (!isAuthorized) {
      return setAndReturnCachedResult(action, isAuthorized, resultCache)
    }
  }
  return setAndReturnCachedResult(action, true, resultCache)
}

export const initializeCanWithUser = <Entity, Action extends string>({
  user,
  roleResolver,
  entity,
}: {
  user: RoleOwner
  roleResolver: RoleResolver<Entity>
  entity?: Entity
}) => {
  const resultCache: ResultCache = {}

  const userRoles = roleResolver({ user, entity })
  const can = (action: Action | Action[], options: CanOptions = {}): boolean => {
    const cachedResult = getCachedResult<Action>(action, resultCache)
    const { adminOnly = false } = options
    const hasPermission = adminOnly ? user.emailAddress?.split('@').pop() === 'guiker.com' : true
    if (cachedResult) return cachedResult

    return isAuthorized({ roles: userRoles, action, resultCache, hasPermission })
  }

  const canOrThrow = (action: Action | Action[], options: CanOptions = {}): true => {
    if (!can(action, options)) {
      throw new UnauthorizedError(action)
    }

    return true
  }
  return { can, canOrThrow }
}

export const initializeCanWithRoleNames = <RoleName, Action extends string>({
  roleNames,
  roleResolver,
}: {
  roleNames: RoleName[]
  roleResolver: RoleNameRoleResolver<RoleName>
}) => {
  const roles = roleResolver({ roleNames })
  const resultCache: ResultCache = {}

  /**
   * @description
   * User authorization should happen first before using this function
   */
  const can = (action: Action | Action[]): boolean => {
    const cachedResult = getCachedResult(action, resultCache)
    if (cachedResult) return cachedResult

    return isAuthorized({ roles, action, resultCache })
  }

  /**
   * @description
   * User authorization should happen first before using this function
   */
  const canOrThrow = (action: Action | Action[]): true => {
    if (!can(action)) {
      throw new UnauthorizedError(action)
    }

    return true
  }
  return { can, canOrThrow }
}
