import { LeaseRoleNames } from '../../entity'

export const EventTypes = {
  COLLABORATORS_REPLACED: 'COLLABORATORS_REPLACED',
  CONFIRMED: 'CONFIRMED',
  INITIALIZED: 'INITIALIZED',
  INITIALIZED_FROM_BOOKING: 'INITIALIZED_FROM_BOOKING',
  LESSORS_OR_LESSEES_UPDATED: 'LESSORS_OR_LESSEES_UPDATED',
  READY_TO_BE_SIGNED: 'READY_TO_BE_SIGNED',
  SIGNED: {
    type: 'SIGNED',
    eventData: null as {
      signedBy: string
      role: LeaseRoleNames
    },
  },
  SIGNED_BY_ALL_PARTIES: 'SIGNED_BY_ALL_PARTIES',
  UPDATED_LEASE_TERM_AND_RENT: 'UPDATED_LEASE_TERM_AND_RENT',
}
