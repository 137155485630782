import { Deal, FinancialStatements, LoanType, Series, SeriesDocument } from '../entity'

export const hydrateSeries = (series: SeriesDocument): Series => {
  return {
    ...series,
  }
}

export const draftDealFromFinancialStatements = (financialStatements: FinancialStatements): Deal => {
  const { assets, totalLiabilityValues } = financialStatements.balanceSheet
  const findAsset = (label: string) => assets?.find((asset) => asset.label === label)

  return {
    liabilities: {
      loan: {
        loanType: LoanType.MORTGAGE,
        amount: totalLiabilityValues,
        termsInYear: 25,
        rate: 0,
      },
    },
    assets: {
      taxesAndFees: findAsset('taxesAndFees')?.amount,
      propertyValue: findAsset('propertyValue')?.amount,
      roundCashValue: findAsset('roundCashValue')?.amount,
      cashValue: findAsset('cashValue')?.amount,
    },
  }
}
