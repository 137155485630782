import React from 'react'

import { User } from '@guiker/authentication-shared'
import { ColumnGridLayout, Divider } from '@guiker/components-library'
import { useDataTrackingContext } from '@guiker/data-tracking-context'
import { ApiForm, TextField, useGetInputProps } from '@guiker/react-framework'
import { yupResolver } from '@guiker/react-hook-form'
import { useSentryContext } from '@guiker/sentry-context'

import { AuthType } from '../../../auth-type'
import { PublicApi, useAuthenticationContext, usePublicAuthenticationApiClient } from '../../../authentication'
import { i18n, I18nextProvider, useTranslation } from '../../../i18n'
import { ButtonWithLoader, makeStyles, Note } from '../../'
import { PasswordTextField } from '../../PasswordTextField'
import { AuthenticationForm, AuthenticationFormProps } from '../AuthenticationForm'

type FormContentProps = {
  error?: unknown
  isLoading: boolean
  user: User
}

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}))

const FormContent: React.FC<FormContentProps> = ({ error, isLoading, user }) => {
  const { t } = useTranslation(['common', 'authentication'])
  const classes = useStyles()

  const getInputProps = useGetInputProps({
    namespaces: ['common'],
    tPrefix: 'user',
    defaultValue: user,
  })

  const common = { required: true, disabled: isLoading }

  return (
    <ColumnGridLayout gap={2} gridTemplateColumns={1}>
      {error && (
        <Note statusColor='alert' title={t('authentication:errors.failedToRegister.title')}>
          {t('authentication:errors.failedToRegister.content')}
        </Note>
      )}
      {user && <Note statusColor='success' title={user.emailAddress} />}
      <TextField {...getInputProps('emailAddress')} {...common} className={classes.hidden} name='email' disabled />
      <TextField {...getInputProps('firstName')} {...common} />
      <TextField {...getInputProps('lastName')} {...common} />
      <PasswordTextField {...getInputProps('password')} {...common} />
      <Divider />
      <ButtonWithLoader type='submit' isLoading={isLoading} fullWidth={true}>
        {t('authentication:activation.cta')}
      </ButtonWithLoader>
    </ColumnGridLayout>
  )
}

type Props = Omit<AuthenticationFormProps, 'authType'> & {
  onSuccess?: () => void
  onError?: () => void
}

const Content: React.FC<Props> = ({ onSuccess, onError, ...props }) => {
  const { setUser, user } = useAuthenticationContext()
  const { sendEvent } = useDataTrackingContext()
  const { captureException, captureMessage } = useSentryContext()
  const apiClient = usePublicAuthenticationApiClient()
  const resolver = yupResolver(PublicApi.Schemas.signUp)
  const authType = AuthType.ACTIVATE

  const onSubmit = async (payload: PublicApi.Schemas.SignUp) =>
    apiClient.signUp({ payload: { ...payload, invitationToken: user?.invitationToken } })

  return (
    <AuthenticationForm authType={authType} {...props}>
      <ApiForm
        onSubmit={onSubmit}
        formOptions={{ resolver, defaultValues: { ...user } }}
        apiOptions={{
          onError: (error: unknown) => {
            const message = 'Account activation failed'
            let trusted: string = null

            if (typeof error === 'object') {
              if (error['meta']) {
                trusted = JSON.stringify(error['meta']).replace(/password/g, '****')
              }

              if (error['baseError']) {
                captureException({ error: error['baseError'], trusted })
              } else if (error['message'] && error['stack']) {
                captureException({ error: error as Error, trusted })
              }
            } else {
              captureMessage({ message, trusted: error as string })
            }

            onError && onError()
          },
          onSuccess: (res) => {
            if (user?.invitationToken) {
              setUser(res, { type: authType })
            }

            onSuccess?.()
            sendEvent({ event: authType, userId: user.id.toString() })
          },
        }}
        formName={'ActivateAccountForm'}
      >
        {({ isLoading, error }) => <FormContent isLoading={isLoading} error={error} user={user} />}
      </ApiForm>
    </AuthenticationForm>
  )
}

const ActivateAccountForm: React.FC<Props> = (props) => (
  <I18nextProvider i18n={i18n}>
    <Content {...props} />
  </I18nextProvider>
)

export { ActivateAccountForm }
