import React from 'react'

import {
  CurrencyTextField,
  Flex,
  Grid,
  H3,
  Inline,
  NumberFormat,
  numberFormatter,
  PSmall,
  theme,
  TwoColumnsGridLayout,
  useGetInputProps,
  useMediaQuery,
  useTranslation,
} from '@guiker/react-framework'
import { currency as baseCurrency, CurrencyISO, money } from '@guiker/shared-framework'

import { useInvestmentAssumptionContext } from '../../../context'
import { AssumptionComponentProps as Props } from '../assumption-props'

export const FinancingAssumptions: React.FC<Props> = ({ defaultValue, schema, readOnly }) => {
  const { t } = useTranslation(['common', 'common-investmentAssumption'])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { investmentResults } = useInvestmentAssumptionContext()
  const currency = baseCurrency[defaultValue?.price.currency ?? CurrencyISO.CAD]
  const tNamespace = 'common-investmentAssumption'
  const tPrefix = `${tNamespace}:financing`

  const getMortgageInputProps = useGetInputProps({
    defaultValue,
    formPrefix: 'assumptions.financing',
    schema,
    tPrefix,
    readOnly,
  })

  const getPurchaseInputProps = useGetInputProps({
    defaultValue,
    formPrefix: 'assumptions',
    schema,
    namespaces: tNamespace,
    readOnly,
  })

  const inputProps = {
    currency: getPurchaseInputProps<string>('purchase.price.currency'),
    priceAmount: getPurchaseInputProps('purchase.price.amount'),
    minimumDownPaymentPercentage: getMortgageInputProps('mortgage.minimumDownPaymentPercentage'),
    termsInYears: getMortgageInputProps('mortgage.termsInYears'),
    interestRate: getMortgageInputProps('mortgage.interestRate'),
  }

  return (
    <Flex flexDirection={isMobile ? 'column-reverse' : 'column'} gap={6}>
      <Grid container spacing={6}>
        <Grid item md={4} xs={12}>
          <Flex flexDirection='column' alignItems='center'>
            <PSmall>{t(`${tPrefix}.mortgage.mortgageRequired`)}</PSmall>
            <H3 mb={0}>{money.fromAmount(investmentResults.loanAmount, currency).toString('onlySymbol', true)}</H3>
          </Flex>
        </Grid>
        <Grid item md={4} xs={12}>
          <Flex flexDirection='column' alignItems='center'>
            <PSmall>{t(`${tPrefix}.mortgage.downPayment`)}</PSmall>
            <H3 mb={0}>
              {money.fromAmount(investmentResults.downPaymentAmount, currency).toString('onlySymbol', true)}
            </H3>
          </Flex>
        </Grid>
        <Grid item md={4} xs={12}>
          <Flex flexDirection='column' alignItems='center'>
            <PSmall>{t(`${tPrefix}.mortgage.monthlyPayment`)}</PSmall>
            <H3 mb={0}>
              {money.fromAmount(investmentResults.monthlyMortgagePayment, currency).toString('onlySymbol', true)}
            </H3>
          </Flex>
        </Grid>
      </Grid>
      <TwoColumnsGridLayout>
        <input type='hidden' {...inputProps.currency} />
        <CurrencyTextField
          {...inputProps.priceAmount}
          label={t('common-investmentAssumption:purchase.price')}
          currency={currency}
          allowNegative={false}
        />
        <NumberFormat
          {...inputProps.minimumDownPaymentPercentage}
          endAdornment={<Inline>%</Inline>}
          decimalScale={2}
          allowNegative={false}
          max={100}
          formatter={numberFormatter.percentage(2)}
        />
        <NumberFormat
          {...inputProps.termsInYears}
          endAdornment={<Inline>{t('common:duration.years')}</Inline>}
          decimalScale={0}
          allowNegative={false}
        />
        <NumberFormat
          {...inputProps.interestRate}
          endAdornment={<Inline>%</Inline>}
          decimalScale={4}
          allowNegative={false}
          formatter={numberFormatter.percentage(4)}
        />
      </TwoColumnsGridLayout>
    </Flex>
  )
}
