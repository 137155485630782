import { routesBuilder, yup } from '@guiker/shared-framework'

import { Payout } from '../../../entity'
import { paths } from '../paths'

const base = routesBuilder<Payout>()({
  basePath: paths.constant.transfers.withId.payouts.path,
})

export const payoutRoutes = {
  createPayoutFromTransfer: base.buildPost({
    payloadSchema: yup.object().nullable(),
  }),
}
