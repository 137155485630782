import { idNestedParamsValidator, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'
import { yup } from '@guiker/yup-util'

import { Project } from '../../../entity'
import { upsertProjectSchema } from '../schemas'
import { paths } from './paths'

const basePath = paths.developerNestedProject.all

export const developerNestedSchema = yup.object({
  developerId: yup.string().required(),
})

export const developerNestedWithIdSchema = developerNestedSchema.shape({
  id: yup.string().required(),
})

export const projectRoutes = {
  readOneProject: {
    path: basePath.withId(':id'),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: validatorBuilder.buildResponseValidator<Project>(),
  },
  createProject: {
    path: basePath.base(),
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: validatorBuilder.buildParamsValidator(developerNestedSchema),
    payloadValidator: validatorBuilder.buildPayloadValidator(upsertProjectSchema),
    responseValidator: validatorBuilder.buildResponseValidator<Project>(),
  },
  updateProject: {
    path: basePath.withId(':id'),
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: validatorBuilder.buildParamsValidator(developerNestedWithIdSchema),
    payloadValidator: validatorBuilder.buildPayloadValidator(upsertProjectSchema),
    responseValidator: validatorBuilder.buildResponseValidator<Project>(),
  },
}
