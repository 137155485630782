import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'
import { PayoutCapabilitiesType } from '../entity'

export const { routes: mainRoutes, pathBuilder: mainPathBuilder } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        byId: {
          path: '/:payoutMethodId',
          routes: {
            edit: '/edit',
          },
        },
        create: {
          path: `/new?type=${PayoutCapabilitiesType.payout}`,
        },
        createInvestment: {
          path: `/new?type=${PayoutCapabilitiesType.investment}`,
        },
      },
    },
  } as const,
  config.domainName,
)
