import { routesBuilder } from '@guiker/api-definition'

import { ListingInquiry, MultipostListingInquiry, MultipostListingInquiryStats } from '../../entity'
import { paths } from './paths'
import { createInquiryPayloadSchema, multipostInquiryPerformanceStatsPayloadSchema } from './schemas'

const listingInquiry = routesBuilder<ListingInquiry>()({
  basePath: paths.listingInquiry.one.base(),
})
const multipostInquiry = routesBuilder<MultipostListingInquiry>()({
  basePath: paths.multipostInquiry.all.base(),
})

export const routes = {
  createListingInquiry: listingInquiry.buildPost({
    payloadSchema: createInquiryPayloadSchema,
  }),
  getMultipostInquiryPerformanceStats: multipostInquiry.buildPost({
    path: '/performance-stats',
    payloadSchema: multipostInquiryPerformanceStatsPayloadSchema,
    responseIsArray: true,
    response: null as MultipostListingInquiryStats,
  }),
}
