import React, { useState } from 'react'

import {
  Application,
  ApplicationStatus,
  AuthenticatedApi,
  BookingActions,
  BookingRoleNames,
  BookingStatus,
  isUnitManagerRole,
} from '@guiker/booking-shared'
import {
  ApiForm,
  ApiFormAction,
  ButtonContainer,
  ConfirmDialog,
  FormSection2,
  MessagesBubbleIcon,
  Note,
  P,
  SecondaryButton,
  TextField,
  theme,
  useMediaQuery,
  useTranslation,
  yupResolver,
} from '@guiker/react-framework'
import { RequestBackgroundCheckModal, ViewTenantApplication } from '@guiker/tenant-application-components'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { useBookingContext } from '../../../hooks'
import { useBookingApiClient, useJwtBookingApiClient } from '../../../lib'

type BookingApplicationProps = React.PropsWithChildren & {
  bookingApplication: Application
  bookingId: string
  isLoading?: boolean
  onActionSuccess: (status: ApplicationStatus) => void
}

export const BookingApplication: React.FC<BookingApplicationProps> = ({
  bookingApplication,
  bookingId,
  isLoading,
  onActionSuccess,
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const bookingApiClient = useBookingApiClient()
  const jwtBookingApiClient = useJwtBookingApiClient()
  const { bookingUser, booking, can } = useBookingContext()

  const [openRequestChangeModal, setOpenRequestChangeModal] = useState<boolean>(false)
  const tenantApplication = bookingApplication?.content as TenantApplication

  const isInvitedLandlord = isUnitManagerRole({
    booking,
    userId: bookingUser.id,
    role: BookingRoleNames.INVITED_LANDLORD,
  })

  const canEditApplicationStatus =
    (isInvitedLandlord || can(BookingActions.EditApplicationStatus)) &&
    ![BookingStatus.CONFIRM_REQUESTED, BookingStatus.BOOKED].includes(booking.status)

  const handleProposeChangesModal = () => setOpenRequestChangeModal(!openRequestChangeModal)
  const resolver = yupResolver(AuthenticatedApi.Schema.updateBookingApplicantApplicationStatusPayloadSchema)

  const requestChange = (payload: { reviewalComments: string }) => {
    const params = {
      pathParams: { bookingId, applicantUserId: tenantApplication.userId },
      payload: { ...payload, applicationStatus: ApplicationStatus.CHANGE_REQUESTED },
    }
    return isInvitedLandlord
      ? jwtBookingApiClient.updateBookingApplicationStatusByToken(params)
      : bookingApiClient.updateBookingApplicantApplicationStatus(params)
  }

  const approveApplication = () => {
    const params = {
      pathParams: { bookingId, applicantUserId: tenantApplication.userId },
      payload: {
        applicationStatus: ApplicationStatus.ACCEPTED,
        reviewalComments: '',
      },
    }
    return isInvitedLandlord
      ? jwtBookingApiClient.updateBookingApplicationStatusByToken(params)
      : bookingApiClient.updateBookingApplicantApplicationStatus(params)
  }

  return (
    <>
      <ApiForm formName='UnitManagerViewApplication'>
        <FormSection2 hasDivider>
          {bookingApplication?.reviewalComments && (
            <Note
              mb={!isMobile ? 2 : 0}
              icon={<MessagesBubbleIcon />}
              title={t('main-bookingApplicant:screens.viewBookingApplication.reviewalComments.title')}
              statusColor='info'
            >{`"${bookingApplication?.reviewalComments}"`}</Note>
          )}

          <ViewTenantApplication
            authType={isInvitedLandlord ? 'jwt' : 'auth'}
            isLoading={isLoading}
            showRoommateProfile={false}
            tenantApplication={tenantApplication}
          />
        </FormSection2>
        {canEditApplicationStatus && (
          <ButtonContainer>
            <SecondaryButton type='button' colorVariant='background' color='alert' onClick={handleProposeChangesModal}>
              {t('main-bookingApplicant:actions.requestChanges')}
            </SecondaryButton>
            {bookingApplication?.status !== ApplicationStatus.ACCEPTED && (
              <ApiFormAction
                onClick={approveApplication}
                options={{ onSuccess: () => onActionSuccess(ApplicationStatus.ACCEPTED) }}
              >
                {t('main-bookingApplicant:actions.approve')}
              </ApiFormAction>
            )}
          </ButtonContainer>
        )}
      </ApiForm>

      <ApiForm
        formName='UnitManagerApplicationRequestChange'
        formOptions={{
          resolver,
          defaultValues: { applicationStatus: ApplicationStatus.CHANGE_REQUESTED },
        }}
      >
        <ConfirmDialog
          open={openRequestChangeModal}
          title={t('main-bookingApplicant:screens.unitManagerViewBookingApplication.modal.title')}
          description={
            <>
              <P>{t('main-bookingApplicant:screens.unitManagerViewBookingApplication.modal.subtitle')}</P>
              <TextField
                name='reviewalComments'
                label={t('main-bookingApplicant:screens.unitManagerViewBookingApplication.modal.description')}
                rows={5}
                multiline
              />
            </>
          }
          onClose={handleProposeChangesModal}
          cancelButtonProps={{ onClick: handleProposeChangesModal, children: t('common:cancel') }}
        >
          <ApiFormAction
            onClick={requestChange}
            options={{
              onSuccess: () => onActionSuccess(ApplicationStatus.CHANGE_REQUESTED),
            }}
          >
            {t('main-bookingApplicant:actions.send')}
          </ApiFormAction>
        </ConfirmDialog>
      </ApiForm>

      <RequestBackgroundCheckModal tenantApplication={tenantApplication} />
    </>
  )
}
