import { InvitationMeta } from '@guiker/authentication-shared'
import { theme, useStaticAssetContext, useT } from '@guiker/react-framework'

type InvitationSource = {
  imageUrl: string
  backgroundColor: string
  title: string
  contents: string[]
}

export const useInvitationSource = ({ source }: InvitationMeta): InvitationSource => {
  const { tMain } = useT({ domain: 'webapp', screenName: 'invited.invitationSource' })
  const { getAsset } = useStaticAssetContext()

  switch (source) {
    case 'willow':
      return {
        imageUrl: getAsset('investments').propSharing.willow,
        backgroundColor: '#143F1B',
        title: tMain('willow.title'),
        contents: [tMain('willow.content1'), tMain('willow.content2'), tMain('willow.content3')],
      }
    default:
      return {
        imageUrl: getAsset('about-us').hero,
        backgroundColor: theme.palette.background.primary,
        title: tMain('default.title'),
        contents: [tMain('default.content1'), tMain('default.content2')],
      }
  }
}
