import { Address, Creator, LeaseTypes, Ontario, Person, Quebec } from '.'
import { Collaborator } from './collaborator'
import { LeaseTerm } from './lease-term'

export enum Status {
  CONFIRMED = 'CONFIRMED',
  READY_TO_BE_SIGNED = 'READY_TO_BE_SIGNED',
  SIGNED_BY_ALL_PARTIES = 'SIGNED_BY_ALL_PARTIES',
  STARTED = 'STARTED',
}

type LeaseInformation = Ontario.LeaseInformation | Quebec.LeaseInformation

export interface Lease {
  id?: string
  creator: Creator
  lessors?: Person[]
  lessees?: Person[]
  address?: Address
  type?: LeaseTypes
  scope: {
    type: string
    id: string
  }
  leaseTerm: LeaseTerm
  leaseInformation?: LeaseInformation
  status: Status
  downloadLink: string

  collaborators: Collaborator[]
}
