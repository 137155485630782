import { AuthLevel, generateApiPath } from '@guiker/api-definition'

const paths = {
  booking: generateApiPath({ prefix: AuthLevel.AUTH, name: 'booking' }),
  bookingDocument: generateApiPath({ prefix: AuthLevel.AUTH, name: 'document' }),
  bookingApplicant: generateApiPath({ prefix: AuthLevel.AUTH, name: 'applicant' }),
  bookingUnitManager: generateApiPath({ prefix: AuthLevel.AUTH, name: 'unit-manager' }),
  bookingContribution: generateApiPath({ prefix: AuthLevel.AUTH, name: 'contribution' }),
}

export { paths }
