import { addressFormatter } from '@guiker/base-entity'
import { slugifyLowercase } from '@guiker/lodash'
import { BuildingTypes, Project, Unit, UnitType } from '@guiker/real-estate-shared'
import { DeepPartial } from '@guiker/ts-utils'

import { BaseListing } from '../entity/listing'

type Property = BaseListing['property']

export const slugifyListingProperty = (property: DeepPartial<Property>) => {
  if (!property?.building) return ''
  const slugable: string[] = []

  if (property.building.construction?.developer?.name) {
    slugable.push(property.building.construction.developer.name)
  }

  if (property.building.construction?.project?.name) {
    slugable.push(property.building.construction.project.name)
  }

  const streetNumbers = property.building.address.streetNumbers.join('-')
  slugable.push(`${streetNumbers}-${property.building.address.street}`)

  return slugifyLowercase(slugable.join('-'))
}

export const buildDisplayName = (property: DeepPartial<Property>, project?: Project) => {
  const isPreCon = property?.building?.type === BuildingTypes.CONDO && !!project

  const streetNumber =
    property?.units?.[0]?.address?.streetNumber || property?.building?.address?.streetNumbers?.[0] || ''

  return isPreCon
    ? `${project.name} - ${property?.units[0]?.address?.unitNumber}`
    : addressFormatter.printShortAddress({
        ...property?.building?.address,
        postalCode: property?.building?.address?.zipcode,
        suite: property?.units?.[0]?.address?.unitNumber,
        streetNumber,
      })
}

export const findRepartitionOfUnits = (units: Unit[]) => {
  let commercial = 0
  let residential = 0
  units?.map((unit) => (unit.type === UnitType.RESIDENTIAL ? residential++ : commercial++))

  return { commercial, residential }
}
