import React, { useEffect, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { CountryCode } from '@guiker/payment-verification-shared'
import { ButtonWithLoader, SecondaryButton, useModal, useMutation } from '@guiker/react-framework'
import { useSentryContext } from '@guiker/sentry-context'
import { getUserFullName } from '@guiker/shared-framework'
import { StripeProvider, useAuthenticatedPaymentVerificationApiClient, useStripe } from '@guiker/stripe-context'

import { MandateAgreement } from '../MandateAgreement'
import { PlaidLink } from '../PlaidLink'

type StripeLinkProps = {
  label: string
  countryCode: CountryCode
  withPlaid?: boolean
  onSelect: (args: { publicToken: string; accountId: string }) => void
  onError?: () => void
}

const sentryMessage = 'Stripe::ConfirmAcssDebitSetup'

const Content: React.FC<StripeLinkProps> = ({ label, countryCode, onSelect, onError, withPlaid = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [accountId, setAccountId] = useState<string>()
  const [acssSetupSecret, setAcssSetupSecret] = useState<string>()
  const { captureMessage } = useSentryContext()
  const { user } = useAuthenticationContext()
  const [state, setState] = useState<{
    name: string
    routingNumber: string
    accountNumber: string
    mask: string
    email: string
  }>()

  const mandateAgreementModal = useModal()

  const apiClient = useAuthenticatedPaymentVerificationApiClient()
  const stripe = useStripe()

  const { mutate: generateAcssSetupSecret, isLoading: isGenerating } = useMutation(
    () => apiClient.createStripeLinkToken({ payload: { countryCode } }),
    {
      onSuccess: (res) => {
        setIsLoading(true)
        setAcssSetupSecret(res)
      },
    },
  )

  useEffect(() => {
    if (acssSetupSecret) {
      captureMessage({ message: sentryMessage, messageInfo: { acssSetupStarted: 'loaded' } })
      const [institution, transit] = state?.routingNumber?.split('-') ?? []
      stripe.client
        .confirmAcssDebitSetup(acssSetupSecret, {
          payment_method: {
            billing_details: {
              name: withPlaid ? state.name : getUserFullName(user),
              email: user.emailAddress,
            },
            ...(withPlaid
              ? {
                  acss_debit: {
                    institution_number: institution,
                    transit_number: transit,
                    account_number: state.accountNumber,
                  },
                }
              : undefined),
          },
        })
        .then(({ error, setupIntent }) => {
          const triggerMicroDepositFlow =
            setupIntent.status === 'requires_action' && setupIntent.next_action.type === 'verify_with_microdeposits'
          const messageInfo =
            error ?? (setupIntent.status !== 'succeeded' && !triggerMicroDepositFlow ? setupIntent : undefined)

          if (messageInfo) {
            captureMessage({ message: sentryMessage, messageInfo: messageInfo as Record<string, any> })
            onError?.()
            return
          }

          if (triggerMicroDepositFlow) {
            mandateAgreementModal.openModal()
          }

          setAccountId(setupIntent.id)
        })
        .catch((error) => {
          captureMessage({ message: sentryMessage, messageInfo: error })
          onError?.()
        })
        .finally(() => setIsLoading(false))
    }
  }, [acssSetupSecret])

  useEffect(() => {
    if (!withPlaid) {
      accountId && onSelect({ accountId, publicToken: acssSetupSecret })
    }
  }, [accountId, acssSetupSecret])

  useEffect(() => {
    !withPlaid && generateAcssSetupSecret()
  }, [])

  if (withPlaid) {
    return (
      <>
        {state && accountId && acssSetupSecret && (
          <MandateAgreement
            modal={mandateAgreementModal}
            state={state}
            accountId={accountId}
            acssSetupSecret={acssSetupSecret}
            onSelect={onSelect}
          />
        )}
        <PlaidLink
          label={label}
          countryCode={countryCode}
          isLoading={isLoading || isGenerating}
          onSelect={({ auth }) => {
            setState(auth)
            generateAcssSetupSecret()
          }}
        />
      </>
    )
  }

  return (
    <ButtonWithLoader
      fullWidth
      type='button'
      variant='outlined'
      color='textPrimary'
      isLoading={isGenerating || isLoading}
      buttonComponent={SecondaryButton}
      onClick={() => generateAcssSetupSecret()}
    >
      {label}
    </ButtonWithLoader>
  )
}

export const StripeLink: React.FC<StripeLinkProps> = (props) => {
  return (
    <StripeProvider region={props.countryCode}>
      <Content {...props} />
    </StripeProvider>
  )
}
