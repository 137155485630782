import React from 'react'

import { AuthType } from '@guiker/authentication-context'
import { useDataTrackingContext } from '@guiker/data-tracking-context'
import { ApiForm, TextField, yupResolver } from '@guiker/react-framework'

import { PublicApi, useAuthenticationContext, usePublicAuthenticationApiClient } from '../../../authentication'
import { useAuthenticationModalContext } from '../../../contexts'
import { i18n, I18nextProvider, useTranslation } from '../../../i18n'
import { ButtonWithLoader, ColumnGridLayout, PSmall } from '../../'
import { PasswordTextField } from '../../PasswordTextField'
import { AuthenticationForm, AuthenticationFormProps } from '../AuthenticationForm'

type Props = Omit<AuthenticationFormProps, 'authType'> & {
  onSuccess?: () => void
  onError?: () => void
}

const Content: React.FC<Props> = ({ onSuccess, onError, ...props }) => {
  const { t } = useTranslation(['common', 'authentication'])
  const { user, setUser } = useAuthenticationContext()
  const { sendEvent } = useDataTrackingContext()
  const { openAuthSuccessModal } = useAuthenticationModalContext()

  const apiClient = usePublicAuthenticationApiClient()
  const resolver = yupResolver(PublicApi.Schemas.signUp)
  const onSubmit = async (payload: PublicApi.Schemas.SignUp) =>
    apiClient.signUp({ payload: { ...payload, invitationToken: user?.invitationToken } })

  return (
    <AuthenticationForm authType={AuthType.SIGN_UP} {...props}>
      <ApiForm
        onSubmit={onSubmit}
        formOptions={{ resolver }}
        apiOptions={{
          onError,
          onSuccess: (res) => {
            if (user?.invitationToken) {
              setUser(res)
            }

            openAuthSuccessModal(AuthType.SIGN_UP, res.emailAddress)
            sendEvent({ event: 'signup', userId: res.id.toString() })
            onSuccess?.()
          },
        }}
        formName={'SignUpForm'}
      >
        {({ isLoading, error }) => (
          <ColumnGridLayout gap={2} gridTemplateColumns={1}>
            {error && (
              <PSmall color='alert'>{`${t('authentication:errors.failedToRegister.title')}. ${t(
                'authentication:errors.failedToRegister.content',
              )}`}</PSmall>
            )}
            <TextField label={t('common:user.firstName')} name='firstName' required disabled={isLoading} />
            <TextField label={t('common:user.lastName')} name='lastName' required disabled={isLoading} />
            <TextField label={t('common:user.emailAddress')} name='email' required disabled={isLoading} />
            <PasswordTextField label={t('common:user.password')} name='password' required disabled={isLoading} />
            <ButtonWithLoader type='submit' isLoading={isLoading} fullWidth={true}>
              {t('authentication:signup.cta')}
            </ButtonWithLoader>
          </ColumnGridLayout>
        )}
      </ApiForm>
    </AuthenticationForm>
  )
}

const SignUpForm: React.FC<Props> = (props) => (
  <I18nextProvider i18n={i18n}>
    <Content {...props} />
  </I18nextProvider>
)

export { SignUpForm }
