import { yup } from '@guiker/shared-framework'

import { InvoiceStatus, ScopeType } from '../../../../entity'
import { DisputeStatus } from '../../../../entity/invoice/invoice'
import { searchStringSchema } from '../../schemas/search-string-schema'

export const readAllInvoicesQueryParamsSchema = yup
  .object({
    statuses: yup
      .array()
      .of<InvoiceStatus>(yup.mixed<InvoiceStatus>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
    disputeStatuses: yup
      .array()
      .of<DisputeStatus>(yup.mixed<DisputeStatus>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
    scopeTypes: yup
      .array()
      .of<ScopeType>(yup.mixed<ScopeType>())
      .nullable()
      .transform((v) => (!!v ? v : null)),
    customerId: yup
      .string()
      .nullable()
      .transform((v) => (!!v ? v : null)),
  })
  .concat(searchStringSchema)

export type readAllInvoicesQueryParamsSchema = yup.InferType<typeof readAllInvoicesQueryParamsSchema>
