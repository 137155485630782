import { Transfer, TransferProjection, TransferScopeType } from '../entity'

export const transferTypeChecker = {
  isfundingAccountWithdrawal: (
    transfer: Transfer | TransferProjection,
  ): transfer is TransferProjection<TransferScopeType.FUNDING_ACCOUNT_WITHDRAWAL> => {
    return transfer?.scope?.type === TransferScopeType.FUNDING_ACCOUNT_WITHDRAWAL
  },
  isInvoice: (transfer: Transfer | TransferProjection): transfer is TransferProjection<TransferScopeType.INVOICE> => {
    return transfer?.scope?.type === TransferScopeType.INVOICE
  },
}
