import axios from '@guiker/axios'
import { camelizeKeys } from '@guiker/lodash'

export type Position = {
  jobTitle: string
  type: string
  location: string
  link: string
}

type Workable = {
  name: string
  description: string
  jobs: {
    title: string
    codde: string
    country: string
    state: string
    city: string
    department: string
    employmentType: string
    url: string
    shortlink: string
    description: string
    industry: string
    function: string
    experience: string
    education: string
  }[]
}

const useCareerApiClient = () => {
  return {
    getJobList: async () => {
      try {
        const res = await axios.get('https://apply.workable.com/api/v1/widget/accounts/guiker')
        const data = camelizeKeys<Workable>(res.data)

        return data.jobs.map(({ title, employmentType, city, state, shortlink }) => ({
          jobTitle: title,
          type: employmentType,
          location: `${city}, ${state}`,
          link: shortlink,
        })) as Position[]
      } catch {
        return [] as Position[]
      }
    },
  }
}

export { useCareerApiClient }
