import React, { useRef, useState } from 'react'

import { User } from '@guiker/authentication-shared'

import { AuthType } from '../../auth-type'
import { AuthenticationModal, AuthenticationSuccessModal, useModal } from '../../components'
import { AuthenticationModalContext, AuthenticationModalOptions } from './AuthenticationModalContext'

export type AuthenticationModalContextProviderProps = React.PropsWithChildren & {
  forgetPasswordUrl: string
  privacyPolicyUrl: string
  termsAndConditionUrl: string
}

export const AuthenticationModalContextProvider: React.FC<AuthenticationModalContextProviderProps> = ({
  children,
  ...props
}) => {
  const [modalAuthType, setModalAuthType] = useState<AuthType>()
  const modalOptions = useRef<AuthenticationModalOptions>({})
  const emailAddressRef = useRef('')

  const { isOpen: isAuthModalOpen, openModal: openAuthModal, closeModal: closeAuthModal } = useModal()
  const { isOpen: isSuccessModalOpen, openModal: openSuccessModal, closeModal: closeSuccessModal } = useModal()

  const setOptions = (update: AuthenticationModalOptions) => {
    modalOptions.current = update
  }

  const handleSuccess = (user: User) => {
    modalOptions.current?.onSuccess?.(user)
    setOptions({})
    closeAuthModal()
  }

  const handleDismiss = () => {
    modalOptions.current?.onDismiss?.()
    setOptions({})
    closeAuthModal()
  }

  const value = {
    openAuthenticationModal: (authType: AuthType, options?: AuthenticationModalOptions) => {
      openAuthModal()
      setModalAuthType(authType)
      modalOptions.current = {
        onSuccess: options?.onSuccess,
        onDismiss: options?.onDismiss,
      }
    },
    openAuthSuccessModal: (authType: AuthType, emailAddress?: string) => {
      if (!!emailAddress) {
        emailAddressRef.current = emailAddress
      }
      setModalAuthType(authType)
      openSuccessModal()
    },
  }

  return (
    <AuthenticationModalContext.Provider value={value}>
      <AuthenticationModal
        modalAuthType={modalAuthType}
        open={isAuthModalOpen && !isSuccessModalOpen}
        onClose={handleDismiss}
        onSuccess={handleSuccess}
        {...props}
      />
      {children}
      <AuthenticationSuccessModal
        authType={modalAuthType}
        open={isSuccessModalOpen}
        onClose={closeSuccessModal}
        emailAddressRef={emailAddressRef}
      />
    </AuthenticationModalContext.Provider>
  )
}
