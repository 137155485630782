import React from 'react'

import { numberToOrdinal, useTranslation } from '@guiker/i18n'
import { BathroomIcon, BedroomIcon, DenIcon, SquareFootageIcon, ToiletIcon } from '@guiker/icons'
import { BuildingTypes, Orientation, Unit } from '@guiker/real-estate-shared'

import { Flex } from '..'
import { IconDescription } from './iconDescription'
import { findBuildingIcon } from './utils'

type SingleUnitConfigProp = {
  buildingType: BuildingTypes
  unit: Unit
}

export const SingleUnitConfig: React.FC<SingleUnitConfigProp> = ({ unit, buildingType }) => {
  const { t } = useTranslation('common-realEstate')
  const BuildingIcon = findBuildingIcon(buildingType)

  return (
    <Flex padding={2} justifyContent='space-evenly' width='100%' flexWrap='wrap' gap={3}>
      <IconDescription
        icon={<BuildingIcon />}
        description={t(`unitConfiguration.building.${buildingType}`)}
        value={''}
      />
      <IconDescription
        icon={<BedroomIcon />}
        icon2={<DenIcon />}
        description={t('unitConfiguration.bedroom', { count: unit.roomConfigurations.bedroomCount })}
        value={unit.roomConfigurations.bedroomCount}
        value2={unit.roomConfigurations.denCount}
      />
      <IconDescription
        icon={<BathroomIcon />}
        icon2={<ToiletIcon />}
        description={t('unitConfiguration.bathroom', { count: unit.roomConfigurations.bathroomCount })}
        value={unit.roomConfigurations.bathroomCount}
        value2={unit.roomConfigurations.halfBathroomCount}
      />
      <IconDescription
        icon={<SquareFootageIcon />}
        description={t('unitConfiguration.squareFootage')}
        value={unit.squareFootage.toLocaleString()}
      />
      {unit.floorLevel && (
        <IconDescription
          icon={<></>}
          description={t('unitConfiguration.floorLevel')}
          value={numberToOrdinal(unit.floorLevel)}
        />
      )}
      {unit.orientation && unit.orientation !== Orientation.UNKNOWN && (
        <IconDescription
          icon={<></>}
          description={t('unitConfiguration.orientation')}
          value={t(`unitConfiguration.${unit.orientation}`)}
        />
      )}
    </Flex>
  )
}
