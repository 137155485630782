import React from 'react'

import {
  ApiForm,
  Box,
  Button,
  Collapse,
  DeepMap,
  FieldError,
  RadioGroup,
  SecondaryButton,
  TextField,
  TFunction,
  useForm,
  useMutation,
  useTranslation,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'

import { useBookingContext } from '../../../../hooks'
import { BookingPanelModal } from '../../../BookingPanelModal'

type RejectApplicantModalProps = {
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  disablePortal?: boolean
}

type RejectFormData = {
  reason?: string
  reasonOther?: string
}

const reasons = {
  acceptedOtherApplicants: 'accepted_other_applicants',
  applicantsOtherQualifications: 'applicants_other_qualifications',
  somethingElse: 'something_else',
}

const validationSchema = (t: TFunction) =>
  yup.object({
    reason: yup.string().required(t('main-booking:errors.required')),
    reasonOther: yup.string().when('reason', {
      is: reasons.somethingElse,
      then: yup.string().required(t('main-booking:errors.required')),
      otherwise: yup
        .string()
        .nullable()
        .transform(() => null),
    }),
  })

const RejectApplicantModal: React.FC<RejectApplicantModalProps> = ({ open = false, setOpen }) => {
  const { t } = useTranslation(['main-booking', 'common'])
  const { bookingId, apiClient } = useBookingContext()

  const { mutate: rejectBooking, status } = useMutation(({ reason, reasonOther }: RejectFormData) =>
    apiClient.rejectBooking({
      pathParams: { bookingId },
      payload: { cancelReason: reasonOther || t(reason) },
    }),
  )
  const resolver = yupResolver(validationSchema(t))
  const {
    reset: resetForm,
    formState: { errors },
  } = useForm<RejectFormData>({
    defaultValues: {
      reason: reasons.acceptedOtherApplicants,
    },
  })

  const handleClose = () => {
    resetForm()
    setOpen(false)
  }

  const requestReject = async (formData: RejectFormData) => {
    await rejectBooking(formData)
    setOpen(false)
  }

  return (
    <ApiForm formName='BookingRejectApplicantForm' formOptions={{ resolver }} onSubmit={requestReject}>
      <BookingPanelModal
        open={open}
        onClose={handleClose}
        title={t('main-booking:actions.reject.rejectApplicantReasonTitle')}
        actions={
          <>
            <SecondaryButton onClick={handleClose} disabled={status === 'loading'} fullWidth>
              {t('common:cancel')}
            </SecondaryButton>
            <Button type='submit' disabled={status === 'loading'} fullWidth>
              {t('main-booking:actions.reject.cta')}
            </Button>
          </>
        }
      >
        <RadioGroup
          name='reason'
          options={Object.keys(reasons).map((key) => {
            return { value: reasons[key], label: t(`main-booking:actions.reject.${reasons[key]}`) }
          })}
        />
        <ReasonTextField errors={errors} />
      </BookingPanelModal>
    </ApiForm>
  )
}

const ReasonTextField: React.FC<{ errors: DeepMap<RejectFormData, FieldError> }> = ({ errors }) => {
  const watchReason = useWatch({ name: 'reason', defaultValue: undefined })

  return (
    <Collapse in={watchReason === reasons.somethingElse}>
      <Box mt={2}>
        <TextField
          maxWidth='100%'
          name='reasonOther'
          error={!!errors['reasonOther']}
          errorMessage={errors['reasonOther']?.message}
        />
      </Box>
    </Collapse>
  )
}

export { RejectApplicantModal }
