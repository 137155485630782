import { Concat, DedupSlash, ExtractKeyOf, RemoveTrailingDelimiter, SafeConcat } from '@guiker/ts-utils'

import { isString } from '..'

type WebAppRouteConfig<K extends string = string, P extends string = string> = {
  path: P
  queryParams?: {
    [key in string]: boolean | string | number | string[] | number[]
  }
  routes?: {
    [key in K]: WebAppRoute<string, string>
  }
}

export type WebAppRoute<K extends string = string, P extends string = string> = WebAppRouteConfig<K, P> | P

export type WebAppRoutes<K extends string = string> = {
  [key in K]: WebAppRoute
}

type NestedRoutes<C extends WebAppRoute, Param extends string, P extends string = ''> = C extends object
  ? {
      [key in ExtractKeyOf<C['routes']>]: key extends string ? Path<C['routes'][key], SafeConcat<P, Param>> : never
    }
  : never

type PathWithRoutes<C extends WebAppRoute, Param extends string, P extends string = ''> = C extends object
  ? C['queryParams'] extends { [K in string]: unknown }
    ? {
        path: (queryParam?: Partial<C['queryParams']>) => `${SafeConcat<P, Param>}?${ExtractKeyOf<C['queryParams']>}`
      } & NestedRoutes<C, Param, P>
    : { path: SafeConcat<P, Param> } & NestedRoutes<C, Param, P>
  : never

type Path<C extends WebAppRoute, P extends string = ''> = C extends string
  ? { path: SafeConcat<P, C> }
  : C extends object
  ? C['path'] extends `:${string}` | `/:${string}`
    ? <Param extends string>(param: Param) => PathWithRoutes<C, Param, P>
    : PathWithRoutes<C, C['path'], P>
  : {}

type Paths<K extends string, C extends WebAppRoutes<K>, P extends string = never> = {
  [key in ExtractKeyOf<C>]: key extends string ? Path<C[key], P extends never ? '/' : SafeConcat<'', P>> : never
}

const safeConcatParts = (main: string, prefix: string) => removeTrailingSlash(dedupSlash(concatParts(main, prefix)))

const concatParts = <P extends string, M extends string>(main: M, prefix: P): Concat<P, M, '/'> =>
  (prefix ? `${prefix}/${main}` : main) as Concat<P, M, '/'>

const dedupSlash = <S extends string>(main: S): DedupSlash<S> => main.replace(/(\/)+/g, '/') as DedupSlash<S>
const removeTrailingSlash = <S extends string>(main: S): RemoveTrailingDelimiter<S, '/'> =>
  (main !== '/' ? main.replace(/\/$/g, '') : main) as RemoveTrailingDelimiter<S, '/'>

const buildPathWithQueryParams = <P extends object>(fullPath: string, params: P) => {
  const queryParams = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
  return queryParams ? `${fullPath}?${queryParams}` : fullPath
}

const buildSingleRoute = (args: {
  path: string
  prefix: string
  routes: WebAppRoutes
  queryParams?: WebAppRouteConfig['queryParams']
}) => {
  const { path, prefix, routes, queryParams } = args
  const fullPath = safeConcatParts(path, prefix)

  return {
    path: queryParams ? (args: object = {}) => buildPathWithQueryParams(fullPath, args) : fullPath,
    ...(routes ? buildNested(routes, fullPath) : {}),
  }
}

const routeIsString = (route: WebAppRoute): route is string => {
  return isString(route)
}

const buildNested = <K extends string, R extends WebAppRoutes<K>, P extends string = '/'>(routes: R, prefix?: P) => {
  return Object.keys(routes).reduce<Paths<K, R, P>>((acc, key): Paths<K, R, P> => {
    const conf = routes[key as keyof R] as R[K]
    const {
      path,
      routes: nestedroutes,
      queryParams,
    } = routeIsString(conf) ? ({ path: conf, routes: {} } as WebAppRouteConfig) : conf
    const pathIsParams = path.startsWith(':') || path.startsWith('/:')

    return {
      ...acc,
      [`${key}`]: pathIsParams
        ? (pathParam: string) => buildSingleRoute({ path: pathParam, prefix, routes: nestedroutes, queryParams })
        : buildSingleRoute({ path, prefix, routes: nestedroutes, queryParams }),
    } as Paths<K, R, P>
  }, {} as Paths<K, R, P>)
}

export const buildWebAppRoutes = <K extends string, R extends WebAppRoutes<K>, P extends string = '/'>(
  routes: R,
  prefix?: P,
) => {
  return {
    routes,
    pathBuilder: buildNested(routes, prefix?.startsWith('/') ? prefix : `/${prefix || ''}`) as Paths<K, R, P>,
  }
}
