import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { ListingInquiry } from '../../entity'
import { ListingInquiryEventTypes } from './event-types'

export const EventsDefinition = generateEventsDefinition({
  context: 'LISTING_INQUIRY',
  entity: 'LISTING_INQUIRY',
  data: null as unknown as ListingInquiry,
  eventTypes: {
    ...ListingInquiryEventTypes,
  } as const,
})

export const { eventTypes: EventTypes, events: Events } = EventsDefinition
export type EventTypes = ValueOfEventTypes<typeof EventsDefinition>
