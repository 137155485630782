import { StockOrderExecution, StockOrderExecutionStatus, StockOrderExecutionType } from '../entity'

export const findTotalOrderExecutionUnits = (orders: StockOrderExecution[]) => {
  return orders.reduce(
    (acc, order) =>
      !order.buyStockOrder.investorProfile || !order.sellStockOrder.investorProfile ? order.price.quantity + acc : acc,
    0,
  )
}

export const reservedStatuses = [
  StockOrderExecutionStatus.PENDING_SHARE_ISSUANCE,
  StockOrderExecutionStatus.PROCESSING_PAYMENT,
  StockOrderExecutionStatus.PROCESSING_SHARE_ISSUANCE,
]

export const processingStatuses = [...reservedStatuses, StockOrderExecutionStatus.CREATED]

export const activeStatuses = [...processingStatuses, StockOrderExecutionStatus.FULFILLED]

export const isStockOrderExecutionReserved = (stockOrderExecution: Partial<StockOrderExecution>) =>
  reservedStatuses.includes(stockOrderExecution.status)

export const isStockOrderExecutionProcessing = (stockOrderExecution: Partial<StockOrderExecution>) =>
  processingStatuses.includes(stockOrderExecution.status)

export const isStockOrderExecutionActive = (stockOrderExecution: Partial<StockOrderExecution>) =>
  activeStatuses.includes(stockOrderExecution.status)

export const getStockOrderExecutionType = (stockOrderExecution: StockOrderExecution) => {
  const { buyStockOrder, sellStockOrder } = stockOrderExecution
  const isBuyAsInvestor = !!buyStockOrder.investorProfile?.id
  const isSellAsInvestor = !!sellStockOrder.investorProfile?.id

  if (isBuyAsInvestor && !isSellAsInvestor) {
    return StockOrderExecutionType.IPO
  } else if (!isBuyAsInvestor && isSellAsInvestor) {
    return StockOrderExecutionType.BUYOUT
  } else if (isBuyAsInvestor && isSellAsInvestor) {
    return StockOrderExecutionType.MARKET_TRADE
  } else {
    throw new Error(`At least one investorProfileId should present on buy or sell`)
  }
}
