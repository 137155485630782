import React, { useState } from 'react'

import { useDataTrackingContext } from '@guiker/data-tracking-context'
import { usePayInMethodContext, usePaymentApiClient } from '@guiker/payment-context'
import {
  Button,
  isAtMostTablette,
  makeStyles,
  SecondaryButton,
  toPx,
  useFormContext,
  useMutation,
  useTranslation,
} from '@guiker/react-framework'
import { useRecaptcha } from '@guiker/react-recaptcha'
import { RecaptchaAction } from '@guiker/recaptcha-action'
import { useStripe } from '@guiker/stripe-context'

import { InputErrors } from './utils'

type ButtonContainerProps = {
  onClose: () => void
  setInputError: React.Dispatch<InputErrors>
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: toPx(theme.spacing(2)),
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    [isAtMostTablette]: {
      flexDirection: 'column',
    },
  },
}))

export const ButtonContainer: React.FC<ButtonContainerProps> = ({ onClose, setInputError }) => {
  const { t } = useTranslation('common-payment')
  const { sendEvent } = useDataTrackingContext()
  const classes = useStyles()
  const apiClient = usePaymentApiClient()

  const { region, onPaymentMethodAdded } = usePayInMethodContext()
  const { trigger, getValues } = useFormContext()
  const { executeRecaptcha } = useRecaptcha()
  const { client, elements } = useStripe()

  const [onProcessing, setOnProcessing] = useState(false)

  const { mutate: createPayInMethod } = useMutation(
    async (tempToken: string) => {
      const recaptchaToken = await executeRecaptcha(RecaptchaAction.createCreditCard)
      return apiClient.createCreditCard({ payload: { tempToken, recaptchaToken, region } })
    },
    {
      onSuccess: async (p) => {
        onPaymentMethodAdded(p)
        sendEvent({ event: 'formSubmitSuccess', formName: 'StripeCreditCardFormSubmitted' })
        onClose()
      },
      onSettled: () => setOnProcessing(false),
    },
  )

  const handleSubmit = async () => {
    sendEvent({ event: 'formSubmitAttempt', formName: 'StripeCreditCardFormSubmitted' })
    if (!client || !elements) {
      return
    }

    const card = elements.getElement('cardNumber')
    if (card === null) {
      return
    }

    setOnProcessing(true)
    const isValid = await trigger()
    const { cardholderName } = getValues()
    const { token, error } = await client.createToken(card, { name: cardholderName })

    if (error || !isValid) {
      setOnProcessing(false)

      if (error) {
        setInputError({
          ...(error.code.indexOf('number') > -1 ? { number: error } : undefined),
          ...(error.code.indexOf('expiry') > -1 ? { expiry: error } : undefined),
          ...(error.code.indexOf('cvc') > -1 ? { cvc: error } : undefined),
        })
      }

      return
    }

    createPayInMethod(token.id)
  }

  return (
    <div className={classes.container}>
      <SecondaryButton type='button' onClick={() => onClose()}>
        {t('common:actions.cancel')}
      </SecondaryButton>
      <Button onClick={() => handleSubmit()} disabled={onProcessing}>
        {t('common:actions.add')}
      </Button>
    </div>
  )
}
