const registeredScript: {
  [key in string]: boolean
} = {}

export async function loadScript(id: string, src: string, cb: () => void) {
  if (registeredScript[id]) {
    cb()
  } else {
    registeredScript[id] = true

    const script = 'script'
    const refScript = document.getElementsByTagName(script)[0]
    const newScript = document.createElement(script)
    newScript.src = src
    newScript.onload = cb

    if (refScript && refScript.parentNode) {
      refScript.parentNode.insertBefore(newScript, refScript)
    } else {
      document.head.appendChild(newScript)
    }
  }
}
