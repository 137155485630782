export enum RentChargeType {
  CHARGE_ALL = 'CHARGE_ALL',
}

export type RentCharge = {
  id: string
  type: RentChargeType
  chargeDate: string
  chargedBy: {
    userId: string
    emailAddress: string
    name: string
  }
  info: {
    loaded: number
    processed: number
    failed: number
  }
  createdAt: string
  updatedAt: string
}
