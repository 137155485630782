import { SupportedCountries } from '@guiker/payment-shared'
import { Capabilities, PayoutMethod, regionPayoutMethodMapper } from '@guiker/payout-shared'

export const getAllowedPayoutMethods = ({
  payoutMethods,
  capabilities,
  regions,
}: {
  payoutMethods: PayoutMethod[]
  capabilities: Capabilities[]
  regions: SupportedCountries[]
}) => {
  return payoutMethods?.filter(
    (p) => capabilities.find((c) => p.capabilities.includes(c)) && regionPayoutMethodMapper(regions).includes(p.type),
  )
}
