import { selectFromMap } from '@guiker/lodash'
import { CurrencyISO } from '@guiker/money'

import { CountryCode } from './address'

const CurrencyMap = {
  [CountryCode.CA]: CurrencyISO.CAD,
  [CountryCode.US]: CurrencyISO.USD,
  default: CurrencyISO.CAD,
}

export const getCurrencyISOByCountryCode = selectFromMap(CurrencyMap)
