import { yup } from '@guiker/shared-framework'

import { DepositType } from '../../../../entity'

export type CreateSystemDepositSchema = {
  userId: string
  note: string
  amount: number
  type: DepositType
}

export const createSystemDepositSchema = yup.object<CreateSystemDepositSchema>({
  userId: yup.string().required(),
  amount: yup.number().positive().required(),
  note: yup.string().required(),
  type: yup.string().oneOf(Object.values(DepositType)).default(DepositType.CUSTOM),
})
