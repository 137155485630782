import { kebabCase } from 'lodash'

import { ExtractKeyOf, KebabCase } from '@guiker/ts-utils'

export type Config<K extends string> = {
  [key in K]: Partial<Config<any>>
}

export type Resource<R extends string, IsPlural extends boolean> = `${KebabCase<R>}${IsPlural extends true ? `s` : ''}`

export type Path<
  R extends string,
  N extends Config<R>,
  P extends string = '',
  WithId extends string = ':id',
  IsPlural extends boolean = false,
> = {
  path: `${P}/${Resource<R, IsPlural>}`
  withId: {
    path: `${P}/${Resource<R, IsPlural>}/${WithId}`
  } & {
    [key in ExtractKeyOf<N>]: Path<key, N[key], `${P}/${Resource<R, IsPlural>}/:${R}Id`>
  } & {
    [key in ExtractKeyOf<N> as `${key}s`]: Path<key, N[key], `${P}/${Resource<R, IsPlural>}/:${R}Id`, ':id', true>
  }
} & {
  [key in ExtractKeyOf<N>]: Path<key, N[key], `${P}/${Resource<R, IsPlural>}`>
} & {
  [key in ExtractKeyOf<N> as `${key}s`]: Path<key, N[key], `${P}/${Resource<R, IsPlural>}`, ':id', true>
}

export type Paths<K extends string, C extends Config<K>, P extends string> = {
  [key in ExtractKeyOf<C>]: key extends string
    ? Path<key, C[key], P extends `/${infer Prefix}` ? Prefix : `/${P}`>
    : never
} & {
  [key in ExtractKeyOf<C> as `${key}s`]: key extends string
    ? Path<key, C[key], P extends `/${infer Prefix}` ? Prefix : `/${P}`, ':id', true>
    : never
}

export type Builder<Prefix extends string> = {
  build: () => `${Prefix}`
  withId: <Id extends string = ':id'>(id?: Id) => Builder<`${Prefix}/${Id}`>
}

const buildPath = <Prefix extends string>(prefix: Prefix): Builder<Prefix> => ({
  build: () => `${prefix}` as const,
  withId: <Id extends string = ':id'>(id: Id = ':id' as Id) => buildPath(`${prefix}/${id}`),
})

export const buildPaths = <K extends string, C extends Config<K>, P extends string>(config: C, prefix?: P) => {
  return Object.keys(config).reduce<Paths<K, C, P>>((acc, key): Paths<K, C, P> => {
    const conf = config[key as keyof C] as C[K]
    const kebabKey = key.startsWith(':') ? key : kebabCase(key)
    const prefixWithSlash = prefix?.startsWith('/') ? prefix : `/${prefix}`
    const singularPath = prefix ? `${prefixWithSlash}/${kebabKey}` : `/${kebabKey}`
    const pluralPath = prefix ? `${prefixWithSlash}/${kebabKey}s` : `/${kebabKey}s`
    const singularBuilder = buildPath(singularPath)
    const pluralBuilder = buildPath(pluralPath)

    return {
      ...acc,
      [`${key}`]: {
        path: singularBuilder.build(),
        withId: {
          path: singularBuilder.withId().build(),
          ...buildPaths(conf, singularBuilder.withId(`:${key}Id`).build()),
        },
        ...buildPaths(conf, singularPath),
      },
      [`${key}s`]: {
        path: pluralBuilder.build(),
        withId: {
          path: pluralBuilder.withId().build(),
          ...buildPaths(conf, pluralBuilder.withId(`:${key}Id`).build()),
        },
        ...buildPaths(conf, pluralPath),
      },
    }
  }, {} as Paths<K, C, P>)
}
