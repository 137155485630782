import { S3AccessControlList } from './bulk-operation'

export enum AssetStatus {
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  UPLOADED = 'UPLOADED',
}

export type PartialAsset = {
  id: string
}

export interface Asset {
  id: string
  creatorId: string
  scope: {
    type: string
    id?: string
  }
  size: number
  mimeType: string
  displayName: string
  fileName: string
  status: AssetStatus
  presignedDownloadUrl?: string
  details?: {
    acl: S3AccessControlList
    s3Bucket: string
    s3Key: string
    contentLengthMin: number
    contentLengthMax: number
    contentTypeStartsWith: string
    url: string
    amzAlgorithm: string
    amzCredential: string
    amzSecurityToken: string
    amzDate: string
    policy: string
    signature: string
    expiresAt: string
    createdAt: string
  }
}
