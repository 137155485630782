import validate from 'uuid-validate'

import BaseDictionary from './base-dictionary'
import UUIDv4 from './uuidv4'

export function toCamelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|-|\b\w)/g, (ltr: string, idx: number) => (idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()))
    .replace(/\s+|-/g, '')
}

export function toUUID(str: string): UUIDv4 {
  if (!validate(str.toString(), 4)) {
    throw new Error(`${str} is not UUIDv4 formatted string`)
  }

  return str as unknown as UUIDv4
}

export function insensitiveGet(dict: BaseDictionary<string> | undefined, searchKey: string): string | undefined {
  if (!dict) {
    return
  }

  return dict[Object.keys(dict).find((key) => key.toLowerCase() === searchKey.toLowerCase())]
}
