import React, { createContext, useMemo } from 'react'

import { Asset, AuthenticatedApi } from '@guiker/asset-shared'
import { compact } from '@guiker/lodash'
import { ApiClient, generateUseContext, renderChildren } from '@guiker/react-framework'
import { useQuery } from '@guiker/react-query'

import { AssetFile, AssetGroup } from '../entity'
import { useAssetAuthenticatedApiClient, useAssetJwtAuthenticatedApiClient } from '../hooks'
import { isObjectId } from '../utils'
import {
  AssetGroupStorage,
  AssetStorageContextProvider,
  AssetStorageContextProviderProps,
  useAssetStorageContext,
} from './AssetStorageContext'

export type AssetFetcherContext = {
  assetGroups: AssetGroup[]
  getOneAssetGroupByName: (groupName: string) => AssetGroupStorage
  setAssets: (assets: Asset[]) => unknown
  isFetching: boolean
}

export const AssetFetcherContext = createContext<AssetFetcherContext>(null)

type BaseAssetFetcherContextProviderProps = React.PropsWithChildren & {
  scope?: {
    type: string
    id?: string
  }
}

export type GeneratorAssetFetcherContextProviderProps = BaseAssetFetcherContextProviderProps &
  AssetStorageContextProviderProps & {
    authType: 'jwt' | 'auth'
  }

type GeneratedAssetFetcherContextProviderProps = BaseAssetFetcherContextProviderProps & {
  apiClient: ApiClient<typeof AuthenticatedApi.routes>
}

export const BaseAssetFetcherContextProvider = ({ apiClient, children }: GeneratedAssetFetcherContextProviderProps) => {
  const { assetGroups, setAssetGroups, getOneAssetGroupByName } = useAssetStorageContext()
  const assetIds = useMemo(
    () =>
      compact(
        assetGroups.reduce((acc, group) => {
          const groupIds = compact(group.assets.map((g) => (isObjectId(g?.id) ? g.id : undefined)))
          return [...acc, ...groupIds]
        }, []),
      ),
    [assetGroups],
  )

  const setAssets = (assets: Asset[]) => {
    const injectedAssetGroups = assetGroups?.map((assetGroup) => ({
      ...assetGroup,
      assets:
        compact(
          assetGroup.assets?.map((asset) => ({
            ...(assets.find((fetchedAsset) => fetchedAsset.id === asset?.id) ||
              ((asset as AssetFile)?.file ? asset : null)),
          })),
        ) || [],
    }))

    setAssetGroups(injectedAssetGroups ?? [])
  }

  const { isLoading: isFetching } = useQuery(
    ['readAllAssetsByIds', ...assetIds],
    () => {
      return apiClient.readAllAssetsByIds({
        payload: {
          ids: assetIds,
          attributes: null,
        },
      })
    },
    {
      enabled: assetIds.length > 0,
      onSuccess: setAssets,
      // staleTime: 1000,
    },
  )

  const value = {
    assetGroups,
    getOneAssetGroupByName,
    setAssets,
    isFetching,
  }

  return (
    <AssetFetcherContext.Provider value={value}>
      {renderChildren<typeof value>(children, value)}
    </AssetFetcherContext.Provider>
  )
}

const AssetFetcherContextProvider: React.FC<GeneratorAssetFetcherContextProviderProps> = ({
  authType,
  children,
  assetGroups,
  ...props
}) => {
  const apiClient = authType === 'jwt' ? useAssetJwtAuthenticatedApiClient() : useAssetAuthenticatedApiClient()

  return (
    <AssetStorageContextProvider assetGroups={assetGroups}>
      <BaseAssetFetcherContextProvider apiClient={apiClient as any} {...props}>
        {children}
      </BaseAssetFetcherContextProvider>
    </AssetStorageContextProvider>
  )
}

export const JwtAssetFetcherContextProvider = (props: Omit<GeneratorAssetFetcherContextProviderProps, 'authType'>) => (
  <AssetFetcherContextProvider authType='jwt' {...props} />
)

export const AuthAssetFetcherContextProvider = (props: Omit<GeneratorAssetFetcherContextProviderProps, 'authType'>) => (
  <AssetFetcherContextProvider authType='auth' {...props} />
)

export const useAssetFetcher = generateUseContext(AssetFetcherContext)
