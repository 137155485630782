import { Payment } from '@guiker/base-entity'

export const CREDIT_CARD_TRANSACTION_RATE = 0.03

/**@todo change this rate later */
export const ACH_TRANSACTION_RATE = 0

export type PaymentEligibility = Payment.PaymentEligibility
export type TaxBreakdown = Payment.TaxBreakdown
import SupportedStates = Payment.SupportedStates
import SupportedPayInMethodType = Payment.SupportedPayInMethodType
import SupportedPayoutMethodType = Payment.SupportedPayoutMethodType
import SupportedAccountHolderType = Payment.SupportedAccountHolderType
import SupportedCountries = Payment.SupportedCountries
import PayInMethod = Payment.PayInMethod
import PayInMethodType = Payment.PayInMethodType
import PayInProviderName = Payment.PayInProviderName
import PayInProviderAccount = Payment.PayInProviderAccount
import PayInMethodProvider = Payment.Provider
import PayInProfile = Payment.PayInProfile

export type PaymentProviderAccount = Payment.PaymentProviderAccount
export type CreditCardPayInMethod = Payment.CreditCardPayInMethod
export type DirectDebitACHPayInMethod = Payment.DirectDebitACHPayInMethod
export type DirectDebitEFTPayInMethod = Payment.DirectDebitEFTPayInMethod
export type DirectDebitPADPayInMethod = Payment.DirectDebitPADPayInMethod
export type FundingAccountPayInMethod = Payment.FundingAccountPayInMethod
export type OfflinePayInMethod = Payment.OfflinePayInMethod
export type StalePayInMethod = Payment.StalePayInMethod
export type CreditCardBrand = Payment.CreditCardBrand

export {
  SupportedCountries,
  SupportedPayInMethodType,
  SupportedPayoutMethodType,
  SupportedAccountHolderType,
  SupportedStates,
  PayInProfile,
  PayInMethod,
  PayInMethodType,
  PayInProviderName,
  PayInProviderAccount,
  PayInMethodProvider,
}
