import React, { createContext } from 'react'

import { Booking, BookingAccessControl, BookingActions, mainPathBuilder } from '@guiker/booking-shared'
import { CanFunction } from '@guiker/permissions'

import { BookingUser } from './booking-user'
import { getApiClient } from './get-api-client'

export type BookingContextType = {
  bookingId: string
  booking: Booking
  can: CanFunction<BookingActions>
  canOrThrow: CanFunction<BookingActions>
  accessControl?: BookingAccessControl
  apiClient: ReturnType<typeof getApiClient>
  bookingUser: BookingUser
  isFetching: boolean
  error: unknown
  refetchBooking?: () => Promise<void>
  containerRef?: React.MutableRefObject<React.ReactInstance>
  linkPath: ReturnType<typeof mainPathBuilder.root.byId>
  bookingChat: {
    path: string
    navigate: () => void
  }
  bookingStatus: {
    isBooked: boolean
    isExpired: boolean
  }
}

export const BookingContext = createContext<BookingContextType>(null)
