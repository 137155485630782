export enum ReviewalStatus {
  PENDING_REVIEW = 'PENDING_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  WAIVED = 'WAIVED',
}

export interface StatusHistory<T> {
  status: T
  changedAt: Date
  changedBy: string
}

export type ReviewalStatusHistoryElement = StatusHistory<ReviewalStatus>
export type ReviewalStatusHistory = ReviewalStatusHistoryElement[]
