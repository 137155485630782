import { buildWebAppRoutes } from '@guiker/shared-framework'

import { config } from '../config'

export const { routes: mainRoutes, pathBuilder: webappRoutes } = buildWebAppRoutes(
  {
    root: {
      path: '/',
      routes: {
        invoice: {
          path: '/invoice',
          routes: {
            byId: {
              path: '/:id',
            },
          },
        },
        payInMethod: {
          path: '/pay-in-method',
          routes: {
            byId: {
              path: '/:id',
              routes: {
                verification: {
                  path: '/verification',
                },
                resolve: {
                  path: '/resolve',
                  routes: {
                    plaidTokenExpired: {
                      path: '/plaid-token-expired',
                    },
                    insufficientFundFlagged: {
                      path: '/insufficient-fund-flagged',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  } as const,
  config.domainName,
)
