import { paginated, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { DryRunResponse, DryRunResult, ListingUnit, RentalListing, TableListing } from '../../entity'
import { listingIdNested } from '../schemas'
import { paths } from './paths'
import {
  bulkUpdateConfirmSchema,
  bulkUpdateDryRunSchema,
  createListingPayloadSchema,
  readAllListingsQueryParamsSchema,
  readOneListingLeadListingQueryParamsSchema,
  searchAllListingUnitsQueryParamsSchema,
  updateListingPayloadSchema,
  upsertListingPayloadSchema,
  upsertListingWithRentalOptionSchema,
} from './schemas'
import { externalIdSchema } from './schemas/externalId-schema'

const LISTING_PATH = paths.listing.one.base()
const LISTING_WITH_ID_PATH = paths.listing.one.withId(':listingId')

export const routes = {
  bulkUpdateConfirm: {
    path: `${LISTING_PATH}/bulk-update/confirm`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as DryRunResult[]),
    payloadValidator: (payload: unknown) => bulkUpdateConfirmSchema.validate(payload as DryRunResult[]),
  },
  bulkUpdateDryRun: {
    path: `${LISTING_PATH}/bulk-update/dry-run`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as DryRunResponse),
    payloadValidator: (payload: unknown) => bulkUpdateDryRunSchema.validate(payload),
  },
  readAllListings: {
    path: `${LISTING_PATH}`,
    method: HttpMethod.GET,
    authenticated: true,
    paginated,
    queryParamsValidator: (payload: unknown) => readAllListingsQueryParamsSchema.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<TableListing[]>(),
  },
  searchAllListingUnits: {
    path: `${LISTING_PATH}/search-units`,
    method: HttpMethod.GET,
    authenticated: true,
    queryParamsValidator: (payload: unknown) => searchAllListingUnitsQueryParamsSchema.validate(payload),
    responseValidator: validatorBuilder.buildResponseValidator<ListingUnit[]>(),
  },
  readOneListingLeadListing: {
    path: `${paths.listing.all.withId(':externalId')}`,
    method: HttpMethod.GET,
    authenticated: true,
    queryParamsValidator: (queryParams: unknown) => readOneListingLeadListingQueryParamsSchema.validate(queryParams),
    pathParamsValidator: (pathParams: unknown) => externalIdSchema.validate(pathParams),
    responseValidator: (response: unknown) => Promise.resolve(response as RentalListing | null),
  },
  upsertOneListing: {
    path: `${paths.listing.all.base()}/upsert`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => upsertListingPayloadSchema.validate(payload),
    responseValidator: (response: unknown) => Promise.resolve(response as RentalListing),
  },
  upsertOneListingWithRentalOptions: {
    path: `${paths.listing.all.base()}/upsert-with-rental-options`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => upsertListingWithRentalOptionSchema.validate(payload),
    responseValidator: (response: unknown) => Promise.resolve(response as RentalListing),
  },
  updateOneListing: {
    path: LISTING_WITH_ID_PATH,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (params: unknown) => listingIdNested.validate(params),
    payloadValidator: (payload: unknown) => updateListingPayloadSchema.validate(payload),
    responseValidator: (response: unknown) => Promise.resolve(response as RentalListing),
  },
  createListing: {
    path: `${paths.listing.all.base()}`,
    method: HttpMethod.POST,
    authenticated: true,
    payloadValidator: (payload: unknown) => createListingPayloadSchema.validate(payload),
    responseValidator: (response: unknown) => Promise.resolve(response as RentalListing),
  },
}
