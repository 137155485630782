import { BuildingIcon, HouseIcon } from '@guiker/icons'
import { BuildingTypes, Unit, UnitType } from '@guiker/real-estate-shared'

export const findTotalSquareFootage = (units: Unit[]): number =>
  units?.reduce((sum, unit) => sum + unit.squareFootage, 0)

export const findTotalNumberOfUnitType = (units: Unit[], type: UnitType): number =>
  units?.reduce((count, unit) => {
    if (unit.type === type) return count + 1
    return count
  }, 0)

export const findBuildingIcon = (buildingType: BuildingTypes) => {
  const tallBuildings = [BuildingTypes.COMMERCIAL, BuildingTypes.CONDO, BuildingTypes.MIXED]
  return tallBuildings.includes(buildingType) ? BuildingIcon : HouseIcon
}
