import React from 'react'

import { currency, money } from '@guiker/money'
import { computeInvoiceTotalAmount, findInvoiceLabel, Invoice } from '@guiker/payment-shared'
import { Flex, P, PBold, useDateFormatter } from '@guiker/react-framework'

import { useT } from '../../i18n'
import { InvoiceDetailStatusChip } from '../ViewInvoiceDetail'

type Prop = {
  invoice: Invoice
  renderAction?: (invoiceId: string, invoiceStatus?: string) => React.ReactNode
}

const LateInvoice: React.FC<Prop> = ({ invoice, renderAction }) => {
  const { SharedTrans } = useT({})
  const { formatDate } = useDateFormatter()

  return (
    <Flex justifyContent='space-between' alignItems='center' gap={4}>
      <Flex gap={2}>
        <span>
          <SharedTrans
            i18nKey={'paymentMethods.lateInvoices.payment'}
            values={{
              label: findInvoiceLabel(invoice),
              date: formatDate(!!invoice.dueBy ? invoice.dueBy : invoice.createdAt),
              amount: money.fromAmount(computeInvoiceTotalAmount(invoice), currency[invoice.currency]).toString(true),
            }}
          >
            <P mb={0} component='span' />
            <PBold mb={0} component='span' />
          </SharedTrans>
        </span>
        <InvoiceDetailStatusChip invoice={invoice} variant='filled' size='small' />
      </Flex>
      {!!renderAction && renderAction(invoice.id, invoice.status)}
    </Flex>
  )
}

export { LateInvoice }
