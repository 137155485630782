import React from 'react'

import { BookingPayment, getPaymentVerifications } from '@guiker/booking-shared'
import { Button, Modal, P, SecondaryButton, useMutation, useTranslation } from '@guiker/react-framework'

import { useBookingContext, usePaymentVerificationContext } from '../../../../hooks'

const PaymentWaiveDialog: React.FC<{
  isToWaive: boolean
  dialogOpen: boolean
  state: {
    userId: string
    userName: string
  } | null
  handleClose: any
}> = ({ isToWaive, dialogOpen, state, handleClose }) => {
  const { t } = useTranslation(['main-booking'])
  const { booking, bookingId, apiClient } = useBookingContext()
  const { setPaymentVerifications } = usePaymentVerificationContext()

  const onSuccess = (res: BookingPayment) => {
    setPaymentVerifications(getPaymentVerifications({ ...booking, bookingPayment: res }))
  }

  const { mutate: waivePayment, isLoading: isWaiving } = useMutation(
    (applicantUserId: string) =>
      apiClient.waiveOneBookingPaymentContribution({ pathParams: { bookingId, applicantUserId } }),
    { onSuccess },
  )

  const { mutate: requirePayment, isLoading: isRequiring } = useMutation(
    (applicantUserId: string) =>
      apiClient.requireOneBookingPaymentContribution({ pathParams: { bookingId, applicantUserId } }),
    { onSuccess },
  )

  const handleClick = async () => {
    isToWaive ? await waivePayment(state.userId) : await requirePayment(state.userId)
    handleClose()
  }

  return (
    <Modal
      open={dialogOpen}
      onClose={handleClose}
      title={t(
        `${isToWaive ? 'unitManagerBookingSteps.waiveModalTitle' : 'unitManagerBookingSteps.unwaiveModalTitle'}`,
      )}
      actions={
        <>
          <SecondaryButton onClick={handleClose}>{t('unitManagerBookingSteps.cancel')}</SecondaryButton>
          <Button onClick={handleClick} disabled={isWaiving || isRequiring}>
            {t(`${isToWaive ? 'unitManagerBookingSteps.waive' : 'unitManagerBookingSteps.require'}`)}
          </Button>
        </>
      }
    >
      <P>
        {t(
          `${
            isToWaive
              ? 'unitManagerBookingSteps.areYouSureToWaivePayment'
              : 'unitManagerBookingSteps.unwaivingRequiresPayOnline'
          }`,
          {
            userName: state ? state.userName : '',
          },
        )}
      </P>
      <P>
        {t(
          `${
            isToWaive
              ? 'unitManagerBookingSteps.youHaveToCollectPaymentOffline'
              : 'unitManagerBookingSteps.requirePayingOnline'
          }`,
        )}
      </P>
    </Modal>
  )
}

export { PaymentWaiveDialog }
