export enum EnquiryTypes {
  LandlordServiceEnquiryToSales = 'landlordServiceEnquiryToSales',
  ServiceEnquiryToSales = 'serviceEnquiryToSales',
  InvestorsEnquiry = 'investorsEnquiry',
  FoundListingOutsideEnquiryToLeasing = 'foundListingOutsideEnquiryToLeasing',
}

export type UserEnquiry = {
  emailAddress: string
  enquiryType: EnquiryTypes
  data: object
}
