import { useTranslation } from '@guiker/react-framework'
import { CurrentAddress, GuarantorAddress } from '@guiker/tenant-application-shared'

import { hasCompleteAddress } from './index'

export const completeAddress = (address: GuarantorAddress | CurrentAddress) => {
  const { t } = useTranslation('common')
  let fullAddress = ''

  if (hasCompleteAddress(address)) {
    const { suite, streetNumber, streetName, city, state, postalCode } = address

    if (suite) fullAddress += `${suite} - `

    fullAddress += [
      `${streetNumber} ${streetName}`,
      city,
      `${state} ${postalCode}`,
      t(`countries.${address.country}.name`),
    ]
      .join(', ')
      .trim()
  }

  return fullAddress
}
