import { DateTime, TypeOf, yup } from '@guiker/shared-framework'

import { AccountHolderType, PayoutMethodType } from '../../../../entity'
import { accountHolderTypeSchema } from './account-holder-type'
import { addressSchema } from './address'
import { businessSchema } from './business'
import { payoutMethodTypeSchema } from './payout-method-type'

export const accountHolderSchema = yup.object({
  payoutMethodType: payoutMethodTypeSchema.required(),
  accountHolderType: accountHolderTypeSchema.required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  dateOfBirth: yup
    .string()
    .required()
    .test({
      name: 'max',
      test: (date) => {
        const maxDate = DateTime.local().minus({ years: 18 })
        const birthdate = DateTime.fromISO(date)
        return birthdate <= maxDate
      },
    }),
  phoneNumber: yup.string().required(),
  emailAddress: yup.string().email().required(),
  addresses: yup.array().of(addressSchema(true)).min(1).ensure(),
  ssn: yup
    .string()
    .transform((val) => (val ? val : (null as null)))
    .length(9)
    .test('invalidSSN', 'SSN as number', (value: string) => !value || /^[0-9]+$/.test(value))
    .when('payoutMethodType', {
      is: PayoutMethodType.US_BANK_ACCOUNT,
      then: (schema: yup.StringSchema) => schema.required(),
      otherwise: (schema: yup.StringSchema) => schema.nullable(),
    }),
  business: yup.object().when('accountHolderType', {
    is: AccountHolderType.COMPANY,
    then: businessSchema(true).required(),
    otherwise: yup
      .object({})
      .nullable()
      .transform(() => null as null),
  }),
})

export type AccountHolderSchema = TypeOf<typeof accountHolderSchema>
