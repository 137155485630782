import { yup } from '@guiker/yup-util'

const template = yup
  .object({
    uid: yup.string().required(),
    name: yup.string().required(),
  })
  .required()

export const attachBookingDocumentPayloadValidator = yup.object({
  templates: yup.array(template),
})

export type AttachBookingDocumentPayload = yup.InferType<typeof attachBookingDocumentPayloadValidator>
