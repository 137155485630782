import { CountryCode } from '@guiker/base-entity'
import { TypeOf, yup } from '@guiker/yup-util'

export const summaryKpiQueryParams = yup
  .object({
    country: yup.mixed<CountryCode>().required(),
  })
  .required()

export type summaryKpiQueryParams = TypeOf<typeof summaryKpiQueryParams>
