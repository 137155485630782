import React from 'react'

import { clsx, DropdownIcon, makeStyles, RotatingIcon } from '@guiker/react-framework'

type PerformanceIndicatorProps = {
  isPositive: boolean
  size?: 'small' | 'medium'
}

const useStyles = makeStyles(
  (theme) => ({
    success: {
      color: theme.palette.status.success.main,
    },
    notSuccess: {
      color: theme.palette.status.alert.main,
    },
  }),
  { name: 'PerformanceIndicator' },
)

export const PerformanceIndicator: React.FC<PerformanceIndicatorProps> = ({ isPositive, size = 'medium' }) => {
  const classes = useStyles()

  return (
    <RotatingIcon
      isRotated={isPositive}
      Icon={({ className }) => (
        <DropdownIcon size={size} className={clsx(className, isPositive ? classes.success : classes.notSuccess)} />
      )}
    />
  )
}
