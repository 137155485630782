import { BracketValue } from '../../../bracket-value'

export type Jurisdiction = 'quebec'

export const QuebecExpense = {
  jurisdiction: 'quebec',
}

export const WelcomeTax = {
  ...QuebecExpense,
  type: 'welcomeTax',
  frequency: 'oneTimeAtPurchase',
  value: {
    type: 'bracket',
    bracket: null,
  } as BracketValue,
} as const

export const GST = {
  ...QuebecExpense,
  type: 'GST',
  frequency: 'oneTimeAtPurchase',
  value: {
    type: 'percentage',
    base: 'purchasePrice',
    rate: 0.05,
  },
} as const

export const QST = {
  ...QuebecExpense,
  type: 'QST',
  frequency: 'oneTimeAtPurchase',
  value: {
    type: 'percentage',
    base: 'purchasePrice',
    rate: 0.09975,
  },
} as const
