import React, { useState } from 'react'

import { Carousel, Spinner } from '@guiker/components-library'
import { useQuery } from '@guiker/react-query'
import { Fetcher } from '@guiker/react-query-components'
import { usePaginationQueryParams } from '@guiker/router'

type Props<T> = {
  cacheKey: string | string[]
  perPage?: number
  fetcher?: Fetcher<T>
  CellComponent: ({ data }: { data: T }) => JSX.Element
}

const CarouselListings = <T,>({ cacheKey, perPage = 3, fetcher, CellComponent }: Props<T>) => {
  const { page: queryPage } = usePaginationQueryParams()
  const [page] = useState(() => queryPage || 1)
  const { data: response, isLoading } = useQuery([cacheKey, page, perPage], () => fetcher({ page, perPage }), {
    keepPreviousData: true,
  })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Carousel swipe navButtonsAlwaysInvisible autoPlay={false}>
      {response?.data.map((listing, index) => (
        <CellComponent key={index} data={listing} />
      ))}
    </Carousel>
  )
}

export { CarouselListings }
