import React, { PropsWithChildren } from 'react'

import { Invoice } from '@guiker/payment-shared'
import { Flex, P, PBold, useDateFormatter } from '@guiker/react-framework'

import { useT } from '../../../i18n'
import { InvoiceReason } from '../../InvoiceReason'

type InvoiceDetailInfoProps = PropsWithChildren & { invoice: Invoice }

type DetailContentItem = {
  label: string
  value: React.ReactNode | React.ReactNode[]
}

type DetailContentProps = {
  left: DetailContentItem
  right?: DetailContentItem
}

const DetailContentItem: React.FC<DetailContentItem & { textAlign: 'left' | 'right' }> = (props) => {
  const { label, textAlign, value } = props
  return (
    <Flex flexDirection='column' gap={1}>
      <PBold textAlign={textAlign}>{label}</PBold>
      <Flex flexDirection='column'>{value}</Flex>
    </Flex>
  )
}

export const DetailContent: React.FC<DetailContentProps> = ({ left, right }) => {
  return (
    <Flex gap={2} flexWrap='wrap' justifyContent='space-between'>
      <DetailContentItem {...left} textAlign='left' />
      {right ? <DetailContentItem {...right} textAlign='right' /> : <div />}
    </Flex>
  )
}

export const InvoiceDetailInfo: React.FC<InvoiceDetailInfoProps> = ({ invoice, children }) => {
  const { tShared } = useT({})
  const { formatDate } = useDateFormatter()
  const issueDate = formatDate(invoice.dueBy || invoice.createdAt)

  return (
    <Flex gap={4} flexDirection='column' justifyContent='center'>
      <DetailContent
        left={{ label: tShared('info.reason'), value: <InvoiceReason invoice={invoice} /> }}
        right={{ label: tShared('info.issueDate'), value: <P textAlign='right'>{issueDate}</P> }}
      />
      {children}
    </Flex>
  )
}
