import React from 'react'

import { mainPathBuilder } from '@guiker/investment-shared'
import { webappRoutes as mainWebappRoutes } from '@guiker/main-webapp-shared'
import { DirectedSource, usePromotionContext } from '@guiker/promotion-context'
import {
  Flex,
  Headline,
  PageLayout,
  PageMetaTags,
  RouterLink,
  SecondaryButton,
  StaticPageContents,
  theme,
  ThemeContextProvider,
  useLayoutContext,
  useStaticAssetContext,
} from '@guiker/react-framework'

import { SplitSpotBanner } from '../../components'
import { useT } from '../../i18n'
import { HeroSection } from './HeroSection'
import { OurVision } from './OurVision'

export const HomeScreen: React.FC = () => {
  const { getAsset } = useStaticAssetContext()
  const { directedSource } = usePromotionContext()
  const assets = getAsset('home')
  const { tMain } = useT({ screenName: 'screens.home' })
  const { isAtMostTablette } = useLayoutContext()

  return (
    <>
      <PageMetaTags subtitle={tMain('seo.subtitle')} description={tMain('seo.description')} />
      <PageLayout
        noPaddingBottom
        noPaddingTop={!isAtMostTablette}
        noBreadcrumpSpacer
        maxWidth={theme.dimensions.pageLayout.landingPagesMaxWidth.desktop}
      >
        {directedSource === DirectedSource.SPLITSPOT && <SplitSpotBanner />}
        <HeroSection />
        <Flex flexDirection='column' alignItems='center' gap={15}>
          <ThemeContextProvider isDarkMode={false}>
            <Headline>{tMain('headline')}</Headline>
          </ThemeContextProvider>
          <OurVision />
          <StaticPageContents
            items={[
              {
                asset: (
                  <object type='image/svg+xml' data={assets.v3.sections.section1}>
                    svg-animation
                  </object>
                ),
                title: tMain('sections.section1.title'),
                content: tMain('sections.section1.content'),
                cta: (
                  <RouterLink to={mainWebappRoutes.Listings.listings.path}>
                    <SecondaryButton size='large'>{tMain('sections.section1.cta')}</SecondaryButton>
                  </RouterLink>
                ),
              },
              {
                asset: (
                  <object type='image/svg+xml' data={assets.v3.sections.section2}>
                    svg-animation
                  </object>
                ),
                title: tMain('sections.section2.title'),
                content: tMain('sections.section2.content'),
                cta: (
                  <RouterLink to={mainPathBuilder.root.path}>
                    <SecondaryButton size='large'>{tMain('sections.section2.cta')}</SecondaryButton>
                  </RouterLink>
                ),
              },
              {
                asset: (
                  <object type='image/svg+xml' data={assets.v3.sections.section3}>
                    svg-animation
                  </object>
                ),
                title: tMain('sections.section3.title'),
                content: tMain('sections.section3.content'),
                cta: (
                  <RouterLink to={mainWebappRoutes.Services.learnMore().path}>
                    <SecondaryButton size='large'>{tMain('sections.section3.cta')}</SecondaryButton>
                  </RouterLink>
                ),
              },
            ]}
          />
          <Headline>{tMain('conclusion')}</Headline>
        </Flex>
      </PageLayout>
    </>
  )
}
