import { InvestorProfile } from '../entity'

enum InvestorProfileStep {
  ActivateAccount = 'ActivateAccount',
  AboutYou = 'AboutYou',
  EmploymentInformation = 'EmploymentInformation',
  FinancialProfile = 'FinancialProfile',
  IdentityVerification = 'IdentityVerification',
}

type Status = 'complete' | 'incomplete'

const getStatus = (investorProfile: InvestorProfile, step: InvestorProfileStep): Status => {
  switch (step) {
    case InvestorProfileStep.ActivateAccount:
      return investorProfile ? 'complete' : 'incomplete'
    case InvestorProfileStep.AboutYou:
      return investorProfile.personal?.legalName ? 'complete' : 'incomplete'
    case InvestorProfileStep.EmploymentInformation:
      return investorProfile.employment?.type ? 'complete' : 'incomplete'
    case InvestorProfileStep.FinancialProfile:
      return investorProfile.financial?.investmentReason ? 'complete' : 'incomplete'
    case InvestorProfileStep.IdentityVerification:
      return investorProfile.identityVerification?.facePicture?.id ? 'complete' : 'incomplete'
    default:
      return 'incomplete'
  }
}

type InvestorProfileOnboardingProgression = {
  [key in InvestorProfileStep]: {
    status: Status
    isNext: boolean
    order: number
    isFirst: boolean
    isLast: boolean
  }
}

export const computeInvestorProfileProgression = ({ investorProfile }: { investorProfile: InvestorProfile }) => {
  let isNextSet = false

  return Object.values(InvestorProfileStep).reduce((result, step, index, steps) => {
    const status = getStatus(investorProfile, step)
    let isNext = false

    if (!isNextSet && status === 'incomplete') {
      isNextSet = true
      isNext = true
    }

    result[step] = {
      status,
      isNext,
      isFirst: index === 0,
      isLast: index === steps.length - 1,
      order: index + 1,
    }

    return result
  }, {} as InvestorProfileOnboardingProgression)
}
