import { TypeOf, yup } from '@guiker/yup-util'

import { BuildingTypes, Orientation, Parking, ParkingTypes, UnitType } from '../../../entity'

const roomConfigurationsSchema = yup.object({
  bedroomCount: yup.number().default(0).nullable(),
  denCount: yup.number().default(0).nullable(),
  bathroomCount: yup.number().default(0).nullable(),
  halfBathroomCount: yup.number().default(0).nullable(),
  kitchenCount: yup.number().default(0).nullable(),
})

const otherConfigurationsSchema = yup.object({
  backyard: yup.boolean().nullable().default(false),
  balcony: yup.boolean().nullable().default(false),
  locker: yup.boolean().nullable().default(false),
  parkings: yup
    .array()
    .of<Parking>(
      yup
        .object({
          type: yup.mixed<ParkingTypes>().oneOf(Object.values(ParkingTypes)).required(),
          chargingStation: yup.boolean().nullable().default(false),
        })
        .nullable(),
    )
    .nullable(),
})

const addressSchema = (buildingTypeContextKey: string) =>
  yup.object().when(buildingTypeContextKey, (type: BuildingTypes) => {
    if (type === BuildingTypes.PLEX) {
      return yup.object({ streetNumber: yup.string().required() }).required()
    } else {
      return yup.object({ unitNumber: yup.string() })
    }
  })

const unitSchema = (buildingTypeContextKey: string) =>
  yup.object({
    id: yup.string().nullable(),
    type: yup.mixed<UnitType>().oneOf(Object.values(UnitType)).required(),
    uniqueKey: yup.string().nullable(),
    buildingId: yup.string().nullable(),
    propertyId: yup.string().nullable(),
    squareFootage: yup.number().required(),
    roomConfigurations: roomConfigurationsSchema.required(),
    otherConfigurations: otherConfigurationsSchema.required(),
    address: addressSchema(buildingTypeContextKey).required(),
    floorLevel: yup.number().nullable(),
    orientation: yup.mixed<Orientation>().oneOf(Object.values(Orientation)).required(),
  })

export const getCompletePropertyCreationPayloadSchema = (buildingTypeContextKey: string) =>
  yup
    .object({
      hasDetailedUnitInformation: yup.boolean().required(),
      units: yup.array().of(unitSchema(buildingTypeContextKey)).nullable(),
      repartitionOfUnits: yup
        .object({
          commercial: yup.number().required(),
          residential: yup.number().required(),
        })
        .required(),
    })
    .required()

export const completePropertyCreationPayloadSchema = getCompletePropertyCreationPayloadSchema('$building.type')

export type CompletePropertyCreationPayloadSchema = TypeOf<typeof completePropertyCreationPayloadSchema>
