import React from 'react'

import { usePayInMethodContext } from '@guiker/payment-context'
import { Flex, Link, PlusIcon, PSmall, SecondaryButton, useTranslation } from '@guiker/react-framework'

type ButtonProps = {
  i18nKey: string
}

export const BaseAddDirectDebitMethodButton: React.FC<ButtonProps> = ({ i18nKey }) => {
  const { t } = useTranslation('common-payment')
  const { modalManager } = usePayInMethodContext()
  const { openModal } = modalManager.addDirectDebit
  const LinkButton = modalManager.buttonType.value === 'button' ? SecondaryButton : PSmall

  return (
    <Flex gap={1} alignItems='center'>
      <PlusIcon width={16} height={16} />
      <Link color={50} onClick={openModal} underline={modalManager.buttonType.value === 'p'}>
        <LinkButton>{t(i18nKey)}</LinkButton>
      </Link>
    </Flex>
  )
}
