import React, { createContext, useState } from 'react'

import { AssetFile } from '@guiker/asset-uploader'
import { extractLabelResult, getCompareFaceStatus, LabelNames, ResultStatus } from '@guiker/image-recognition-shared'
import { generateUseContext } from '@guiker/react-context'
import { ValueOf } from '@guiker/ts-utils'

import { useImageRecognitionAuthenticatedApiClient } from './use-authenticated-image-recognition-api-client'

type IdentityVerificationContextProviderProps = React.PropsWithChildren & {
  defaultValue?: {
    documentType: DocumentTypes
    facePicture?: ImageWithResult
    governmentIdBack?: ImageWithResult
    governmentIdFront?: ImageWithResult
  }
}

export const DocumentTypes = {
  ...LabelNames,
  PR_CARD: 'PR_CARD',
} as const
export type DocumentTypes = ValueOf<typeof DocumentTypes>

export type ImageWithResult = {
  image?: AssetFile
  status?: ResultStatus
}

type IventityVerificationContext = {
  documentType: DocumentTypes
  facePicture?: ImageWithResult
  governmentIdBack?: ImageWithResult
  governmentIdFront?: ImageWithResult
  resetGovernmentIdBack?: () => void
  resetFacePicture?: () => void
  resetGovernmentIdFront?: () => void
  validateAndSetGovernmentIdBack: ({ image }: { image: AssetFile }) => Promise<void>
  validateAndSetFacePicture: ({ image }: { image: AssetFile }) => Promise<void>
  validateAndSetGovernmentIdFront: ({ image }: { image: AssetFile }) => Promise<void>
}

const EXTRACT_DATA_URL_CONTENT_REGEX = /(?:data:image\/\w*;base64,)(.*)/

const extractBase64Content = (image: string) => {
  const [, data] = image.match(EXTRACT_DATA_URL_CONTENT_REGEX)

  return data
}

const blobToDataUrl = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result.toString())
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

const isFile = (arg: string | File): arg is File => {
  return !!(arg as File)?.arrayBuffer
}

const imageToString = async (image: string | File) => (isFile(image) ? await blobToDataUrl(image) : image)

const IventityVerificationContext = createContext<IventityVerificationContext>(null)

export const IdentityVerificationContextProvider: React.FC<IdentityVerificationContextProviderProps> = ({
  children,
  defaultValue,
}) => {
  const [documentType, setDocumentType] = useState<DocumentTypes>(defaultValue?.documentType)
  const [governmentIdFront, setGovernmentIdFront] = useState<ImageWithResult>(defaultValue?.governmentIdFront)
  const [governmentIdBack, setGovernmentIdBack] = useState<ImageWithResult>(defaultValue?.governmentIdBack)
  const [facePicture, setFacePicture] = useState<ImageWithResult>(defaultValue?.facePicture)

  const apiClient = useImageRecognitionAuthenticatedApiClient()

  const verifyGovernmentId = async ({ image, labels }: { image: File; labels?: LabelNames[] }) => {
    const data = extractBase64Content(await imageToString(image))
    const result = await apiClient.verifyGovernmentId({
      payload: { source: { data }, labels: labels ?? [] },
    })

    return extractLabelResult(result, labels)
  }

  const compareFaces = async ({ image }: { image: File }) => {
    const sourceData = extractBase64Content(await imageToString(governmentIdFront.image.file))
    const targetData = extractBase64Content(await imageToString(image))

    const result = await apiClient.compareFaces({
      payload: { source: { data: sourceData }, target: { data: targetData } },
    })

    return getCompareFaceStatus(result)
  }

  const resetGovernmentIdFront = () => {
    setGovernmentIdFront(null)
    setFacePicture(null)
    resetGovernmentIdBack()
  }

  const resetGovernmentIdBack = () => {
    setGovernmentIdBack(null)
    resetFacePicture()
  }

  const resetFacePicture = () => {
    setFacePicture(null)
  }

  const validateAndSetFacePicture = async ({ image }: { image: AssetFile }) => {
    resetFacePicture()
    setFacePicture({ image })
    const result = await compareFaces({ image: image.file })

    setFacePicture({
      image: image as any,
      status: result,
    })
  }

  const validateAndSetGovernmentIdFront = async ({ image }: { image: AssetFile }) => {
    resetGovernmentIdFront()
    setGovernmentIdFront({ image })

    const allowableFrontLabels = [LabelNames.ID, LabelNames.DRIVING_LICENSE, LabelNames.PASSPORT]
    const result = await verifyGovernmentId({ image: image.file, labels: allowableFrontLabels })

    setGovernmentIdFront({
      image,
      status: result.status,
    })

    setDocumentType(result.documentType)
  }

  const validateAndSetGovernmentIdBack = async ({ image }: { image: AssetFile }) => {
    resetGovernmentIdBack()
    setGovernmentIdBack({ image })

    const result = await verifyGovernmentId({ image: image.file, labels: [LabelNames.DOCUMENT] })
    setGovernmentIdBack({
      image,
      status: result.status,
    })
  }

  const value = {
    governmentIdBack,
    governmentIdFront,
    documentType,
    facePicture,
    resetFacePicture,
    resetGovernmentIdBack,
    resetGovernmentIdFront,
    validateAndSetGovernmentIdBack,
    validateAndSetGovernmentIdFront,
    validateAndSetFacePicture,
  }

  return <IventityVerificationContext.Provider value={value}>{children}</IventityVerificationContext.Provider>
}

export const useIdentityVerificationContext = generateUseContext(IventityVerificationContext)
