import React from 'react'

import { Flex, H5, P, useTranslation } from '@guiker/react-framework'
import { Task } from '@guiker/task-shared'

import { taskMapping } from '../utils'
import { IndividualTask } from './IndividualTask'

type TaskProp = {
  tasks: Task[]
}

export const Category: React.FC<TaskProp> = ({ tasks }) => {
  const { t } = useTranslation('common-task')
  const copy = taskMapping(tasks[0], t, tasks?.length)

  return (
    <Flex flexDirection='column' gap={2}>
      <H5 mb={0}>{copy.category}</H5>
      <Flex flexDirection='column' gap={1} ml={2}>
        {tasks.map((task) => {
          return <IndividualTask key={task.entityId} task={task} TitleComponent={P} />
        })}
      </Flex>
    </Flex>
  )
}
