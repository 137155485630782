import { TypeOf, yup } from '@guiker/yup-util'

import { smoking } from './smoking'
import { tenantsInsurance } from './tenants-insurance'

export const smokingAndTenantsInsurance = yup.object({
  smoking: smoking,
  tenantsInsurance: tenantsInsurance,
})

export type SmokingAndTenantsInsurance = TypeOf<typeof smokingAndTenantsInsurance>
