import React from 'react'

import { isRoommatable, RentalListing } from '@guiker/rental-listing-shared'

import { useListingInquiryContext, useTranslation } from '../../hooks'
import { ButtonWithLoader, InquiryCard } from '..'
import { FormContent } from './FormContent'
import { ListingInquirySuccessModal } from './ListingInquirySuccessCard'

type ListingInquiryCardProps = {
  listing: RentalListing
  isSuccess: boolean
  isLoading: boolean
}

const ListingInquiryCard: React.FC<ListingInquiryCardProps> = ({ isLoading, isSuccess, listing }) => {
  const { t } = useTranslation(['main-rentalListing'])

  const { openInquiryCardModal, setOpenInquiryCardModal, checkAuthentication } = useListingInquiryContext()

  return (
    <>
      <InquiryCard
        title={t('inquiry.title')}
        buttonLabel={t('inquiry.cta')}
        open={openInquiryCardModal}
        onOpen={() => checkAuthentication({ shouldThrowError: false })}
        onClose={() => setOpenInquiryCardModal(false)}
        isLoading={isLoading}
        SubmitButton={(props) => <ButtonWithLoader type='submit' {...props} />}
      >
        <FormContent isRoommatable={isRoommatable(listing)} isSuccess={isSuccess} />
      </InquiryCard>

      <ListingInquirySuccessModal listing={listing} />
    </>
  )
}

export { ListingInquiryCard }
