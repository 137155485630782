import { useEffect, useRef, useState } from 'react'

const useModuleHeight = (isModuleRendered: boolean) => {
  const [moduleHeight, setModuleHeight] = useState<number>(0)
  const ref = useRef()

  useEffect(() => {
    setModuleHeight((ref.current as any)?.offsetHeight)
  }, [isModuleRendered])

  return {
    moduleHeight,
    ref,
  }
}

export { useModuleHeight }
