import React, { useEffect } from 'react'

import {
  InvitationMeta,
  invitationMetaScheme,
  isAuthenticationClaims,
  isInvitationClaims,
} from '@guiker/authentication-shared'
import { FullScreenSpinner } from '@guiker/components-library'
import { safelyGetWindow } from '@guiker/react-framework'
import { useLocationQuery, useNavigate } from '@guiker/router'
import { compareDate, DateTime, parseDateTime } from '@guiker/shared-framework'

import {
  useAuthenticationApiClient,
  useAuthenticationContext,
  useClaimsAuthenticationContext,
  useConfig,
} from '../../hooks'

const Redirect: React.FC = () => {
  const { user: currentUser, setUser, unsetUser } = useAuthenticationContext()
  const { token: contextToken, claims, setClaimsFromToken } = useClaimsAuthenticationContext()
  const { code, token } = useLocationQuery(['code', 'token'])
  const authenticatedAuthApiClient = useAuthenticationApiClient(token)
  const navigate = useNavigate()
  const { appBaseUrl } = useConfig()
  const baseUrl = safelyGetWindow()?.location.origin || appBaseUrl

  useEffect(() => {
    if (!token) {
      navigate('/')
      return
    }
    try {
      const decoded: InvitationMeta = code ? invitationMetaScheme.decode(code) : { redirect: '/' }

      if (currentUser) {
        if (decoded.userId && currentUser.id.toString() === decoded.userId) {
          window.top.location.href = `${baseUrl}${decoded.redirect}`
        } else if (currentUser.id.toString() !== decoded.userId) {
          // User logged in as different user, log user out and go through the claim process
          unsetUser()
        }
        return
      } else if (claims) {
        if (claims.exp) {
          if (compareDate(parseDateTime((claims.exp as number) * 1000)).isBefore(DateTime.local())) {
            navigate('/expired')
          }
        }
        if (isAuthenticationClaims(claims)) {
          authenticatedAuthApiClient.authenticate().then((data) => {
            const authenticated = data
            authenticated.id !== currentUser?.id && setUser(authenticated)
          })
          return
        } else if (isInvitationClaims(claims)) {
          // claims can be initialized before currentUser set
          // do nothing to wait and let useEffect to take effect again with currentUser
          return
        } else {
          // claim exists but it is neither of Auth/Invitation type, bounce user to home page
          navigate('/')
          return
        }
      }

      contextToken !== token && setClaimsFromToken(token)
    } catch {
      navigate('/')
      return
    }
  }, [claims, currentUser, token])

  return <FullScreenSpinner />
}

export { Redirect }
