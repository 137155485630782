import { TypeOf, yup } from '@guiker/yup-util'

import { lessee } from './lessee'
import { lessor } from './lessor'
import { rentalUnit } from './rental-unit'

export const partiesToTheAgreementAndRentalUnit = yup.object({
  lessors: yup.array().of(lessor).min(1),
  lessees: yup.array().of(lessee).min(1),
  rentalUnit: rentalUnit,
})

export type PartiesToTheAgreementAndRentalUnit = TypeOf<typeof partiesToTheAgreementAndRentalUnit>
