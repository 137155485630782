import { Payment, Scope } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'

import { PayoutMethodStatus, PayoutProvider } from '../payout-method'

type ItemTaxRate = {
  amount: number
  percentage: number
  taxType: string
  taxAccountId: string
}

type ItemTax = {
  taxId: string
  rates: ItemTaxRate[]
}

type Item = {
  id?: string
  description?: string // address of listing / unit
  label: string // rent charge for 2022-03-01
  quantity: number
  pricePerUnit: number // contribution amount
  taxes?: ItemTax[]
}

export enum InvoiceCancelReason {
  CHANGE_IN_CONTRIBUTION = 'CHANGE_IN_CONTRIBUTION',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
  RENT_PAYMENTS_TERMINATED = 'RENT_PAYMENTS_TERMINATED',
}

enum InvoiceStatus {
  SENT = 'SENT',
  PENDING = 'PENDING',
  PAID = 'PAID',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUND_FAILED = 'REFUND_FAILED',
  VERIFICATION_REQUESTED = 'VERIFICATION_REQUESTED',
  VERIFICATION_SUCCEED = 'VERIFICATION_SUCCEED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  SETTLED = 'SETTLED',
  DRAFTED = 'DRAFTED',
}

const incompletedStatus = [InvoiceStatus.DRAFTED]
const immutableInvoiceStatus = [
  InvoiceStatus.REFUNDED,
  InvoiceStatus.REFUND_PENDING,
  InvoiceStatus.PENDING,
  InvoiceStatus.SETTLED,
  InvoiceStatus.CANCELED,
  InvoiceStatus.PAID,
]
const activeInvoiceStatus = [
  InvoiceStatus.SENT,
  InvoiceStatus.PENDING,
  InvoiceStatus.PAYMENT_FAILED,
  InvoiceStatus.REFUND_PENDING,
  InvoiceStatus.REFUND_FAILED,
  InvoiceStatus.VERIFICATION_FAILED,
  InvoiceStatus.VERIFICATION_SUCCEED,
  InvoiceStatus.VERIFICATION_REQUESTED,
]
const lateInvoiceStatus = [InvoiceStatus.SENT, InvoiceStatus.PAYMENT_FAILED, InvoiceStatus.VERIFICATION_FAILED]

type Customer = {
  id: string
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
}

type TransactionType = 'CREDIT' | 'DEBIT' | 'INFO'
type TransactionIntent = 'CHARGE' | 'REFUND' | 'VERIFICATION' | 'TRANSFER' | 'CANCEL'
type TransactionStatus = 'SUCCESS' | 'FAILED' | 'PENDING'

type TransactionError = {
  raw: unknown
  code: Payment.PaymentError
}

type Transaction = {
  payInMethod: Payment.PayInMethod
  principalAmount: number
  incidentalAmount: number
  amount: number
  transferAmount?: number
  currency: CurrencyISO
  type: TransactionType
  intent: TransactionIntent
  status: TransactionStatus
  details: {
    provider?: Payment.PayInProviderName
    payInMethodType?: Payment.PayInMethodType
    paymentIntentId?: string
    uid?: string
    note?: string
    error?: TransactionError
    transferId?: string
  }
  createdAt: string
}

type InvoicePayoutMethod = {
  id: string
  userId: string
  currency: CurrencyISO
  status: PayoutMethodStatus
  provider: PayoutProvider[]
  type: Payment.SupportedPayoutMethodType
  accountHolderType: Payment.SupportedAccountHolderType
}

type Receiver = {
  transfer: number
  userId?: string
  payoutMethod?: InvoicePayoutMethod
  details?: {
    feeAmount?: number
    taxBreakdown?: Payment.TaxBreakdown[]
  }
}

enum ScopeType {
  TENANT_INSTALMENT = 'tenantInstalment',
  BUY_STOCK_ORDER = 'buyStockOrder',
  FUNDING_ACCOUNT_DEPOSIT = 'fundingAccountDeposit',
  DISPUTED_INVOICE = 'disputedInvoice',
  /** @todo deprecate later */
  LEGACY = 'legacy',
}

enum DisputeReason {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INCORRECT_ACCOUNT_DETAILS = 'INCORRECT_ACCOUNT_DETAILS',
  BANK_CANNOT_PROCESS = 'BANK_CANNOT_PROCESS',
  UNKNOWN = 'UNKNOWN',
}

enum DisputeStatus {
  WON = 'WON',
  LOST = 'LOST',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type Scopeless = undefined

export type Dispute = {
  reason: DisputeReason
  status: DisputeStatus
  details: {
    rawStatus: string
    rawReason?: string
  }
}

export type InvoiceMetadata<S extends ScopeType | Scopeless> = S extends Scopeless
  ? {
      listingId?: string
      unit?: string
      reason: string
    }
  : S extends ScopeType.TENANT_INSTALMENT
  ? {
      unitId: string
      listingId: string
      tenantInstalmentId: string
      customer: string
      description: string
      isLegacy?: string
    }
  : S extends ScopeType.FUNDING_ACCOUNT_DEPOSIT
  ? {
      fundingAccountId: string
      fundingAccountDepositId: string
    }
  : S extends ScopeType.BUY_STOCK_ORDER
  ? {
      stockId: string
      stockOrderExecutionId: string
      stockLabel: string
    }
  : never

export type Invoice<S extends ScopeType | Scopeless = ScopeType> = {
  id: string
  batchId?: string
  createdAt: string
  updatedAt: string
  scope?: Scope<S>
  currency: CurrencyISO
  info: Pick<Payment.PaymentInfo, 'label' | 'description'>
  items: Item[]
  metadata: InvoiceMetadata<S>
  customer: Customer
  status: InvoiceStatus

  dueBy?: string
  transactions: Transaction[]

  autoChargeEnabled: boolean
  /** @description if true, first transaction should perform Preauth or Balance check */
  shouldVerify: boolean
  receiver?: Receiver

  idempotencyKey?: string

  dispute?: Dispute
}

export {
  ItemTaxRate,
  ItemTax,
  Item,
  InvoiceStatus,
  InvoicePayoutMethod,
  immutableInvoiceStatus,
  activeInvoiceStatus,
  lateInvoiceStatus,
  Customer,
  ScopeType,
  Transaction,
  TransactionError,
  TransactionType,
  TransactionStatus,
  TransactionIntent,
  Receiver,
  DisputeReason,
  DisputeStatus,
  incompletedStatus,
}
