import React, { useState } from 'react'

import { BookingActions, canWaivePayment, ContributionStatus, PaymentVerification } from '@guiker/booking-shared'
import { Link, makeStyles, P, PSmall, PSmaller, theme, toPx, Typography, useTranslation } from '@guiker/react-framework'
import { currency, money } from '@guiker/shared-framework'

import { useBookingContext } from '../../../../hooks'
import { StepIcon } from '../StepButton'
import { PaymentWaiveDialog } from './PaymentWaiveModal'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: toPx(theme.spacing(2)),
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 160,
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    height: theme.typography.variants.body.lineHeight,
  },
  amount: {
    minWidth: 'fit-content',
  },
})

type PaymentVerificationStatusProps = {
  paymentVerification: PaymentVerification
}

const PaymentVerificationStatus: React.FC<PaymentVerificationStatusProps> = ({ paymentVerification }) => {
  const classes = useStyles()
  const { t } = useTranslation(['main-booking'])
  const { isWaived, userId, userName, status, amount } = paymentVerification
  const { booking, can, refetchBooking } = useBookingContext()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const checkedStatus = [
    ContributionStatus.PENDING_PAY_IN_METHOD_VERIFICATION,
    ContributionStatus.PROCESSING_PAY_IN_METHOD_VERIFICATION,
    ContributionStatus.PAY_IN_METHOD_VERIFIED,
    ContributionStatus.COMPLETED,
  ]

  const isWaiveAllowed = can(BookingActions.WaiveBookingPaymentContribution) && canWaivePayment({ status })
  const bookingCurrency = booking.bookingPayment.total.currency

  return (
    <>
      <div className={classes.root}>
        <div className={classes.icon}>
          <StepIcon
            checked={checkedStatus.includes(status)}
            failed={status === ContributionStatus.PAY_IN_METHOD_REJECTED}
          />
        </div>
        <div className={classes.label}>
          <Typography wordWrap='break-word'>{userName}</Typography>
          {isWaiveAllowed && booking.hasEnabledStep.payment && (
            <Link onClick={() => setDialogOpen(true)} underline={true}>
              <PSmall color={60}>
                {isWaived ? t('unitManagerBookingSteps.require') : t('unitManagerBookingSteps.waivePayment')}
              </PSmall>
            </Link>
          )}
        </div>
        <P mb={0} className={classes.amount}>
          <PSmaller component='span'>{currency[bookingCurrency].symbol} </PSmaller>
          {money.fromAmount(amount, currency[bookingCurrency]).toString(false)}
        </P>
      </div>
      <PaymentWaiveDialog
        isToWaive={!isWaived}
        dialogOpen={dialogOpen}
        state={{ userId, userName }}
        handleClose={() => {
          refetchBooking()
          setDialogOpen(false)
        }}
      />
    </>
  )
}

export { PaymentVerificationStatus }
