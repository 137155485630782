import React from 'react'

import { Flex, Link, P } from '@guiker/components-library'
import { DownloadMiniIcon } from '@guiker/icons'

type DocumentProp = {
  url: string
  displayName: string
}

const Document: React.FC<DocumentProp> = ({ url, displayName }) => {
  return (
    <Flex alignItems='center' justifyContent='space-between' gap={6} width='100%'>
      <Link target='_blank' to={url} download={false} isExternalLink rel='noreferrer' aria-label={displayName}>
        <P mb={0}>{displayName}</P>
      </Link>
      <Link to={url} download isExternalLink rel='noreferrer' aria-label={displayName}>
        <DownloadMiniIcon />
      </Link>
    </Flex>
  )
}

export { Document }
