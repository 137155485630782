import { yup } from '@guiker/yup-util'

import { SignedWith, SignedWithType } from '../../entity'

export const createLeaseContractPayloadValidator = yup.object({
  signer: yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    emailAddress: yup.string().required(),
    roleName: yup.string().required(),
  }),
  signedWith: yup.object<SignedWith>({
    type: yup.string().oneOf(Object.values(SignedWithType)).required(),
    id: yup.string().required(),
  }),
})

export type CreateLeaseContractPayloadValidator = yup.InferType<typeof createLeaseContractPayloadValidator>
