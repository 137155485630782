import React from 'react'

import {
  Collapse,
  ColumnGridItem,
  DatePicker,
  FormSection,
  FormSubSection,
  Link,
  NumericInput,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

import { AddressForm } from '../..'

type CurrentAddressContentProps = {
  defaultValue: Partial<TenantApplication>
  schema?: yup.ObjectSchema
  editRoute?: string
  readOnly?: boolean
}

const CurrentAddressContent: React.FC<CurrentAddressContentProps> = ({
  defaultValue,
  schema,
  editRoute,
  readOnly = false,
}) => {
  const namespaces = ['common', 'main-tenantApplication']
  const { t } = useTranslation(namespaces)

  const isPreviouslyLessee = useWatch<TenantApplication, 'currentAddress.isPreviouslyLessee'>({
    name: 'currentAddress.isPreviouslyLessee',
    defaultValue: defaultValue?.currentAddress?.isPreviouslyLessee,
  })

  const inputProps = useGetInputProps({
    namespaces,
    formPrefix: 'currentAddress.previousLeaseInfo',
    tPrefix: 'common:user',
    defaultValue,
    schema,
    readOnly,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
  })

  const addressInputProps = useGetInputProps({
    namespaces,
    formPrefix: 'currentAddress',
    tPrefix: 'common:address',
    defaultValue,
    schema,
    readOnly,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
  })

  return (
    <>
      <FormSection
        title={t('main-tenantApplication:screens.steps.currentAddress.title')}
        topActions={editRoute && <Link to={editRoute}>{t('common:actions.edit')}</Link>}
      >
        <TwoColumnsGridLayout>
          <ColumnGridItem md={2}>
            <AddressForm
              defaultValue={defaultValue?.currentAddress}
              formPrefix='currentAddress'
              schema={schema}
              readOnly={readOnly}
            />
          </ColumnGridItem>
          <NumericInput
            {...addressInputProps('yearsAtAddress', {
              namespace: 'main-tenantApplication:currentAddress',
            })}
            max={30}
            defaultValue={defaultValue?.currentAddress?.yearsAtAddress || 0}
          />
          <DatePicker
            {...addressInputProps('moveOutDate', {
              namespace: 'main-tenantApplication:currentAddress',
            })}
          />
          <ColumnGridItem md={2}>
            <RadioGroup
              name='currentAddress.isPreviouslyLessee'
              defaultValue={isPreviouslyLessee}
              label={t('main-tenantApplication:isPreviouslyLessee.title')}
              options={[
                { value: true, label: t('common:yes') },
                { value: false, label: t('common:no') },
              ]}
              maxWidth='100%'
              readOnly={readOnly}
              condensed={readOnly}
            />
          </ColumnGridItem>
        </TwoColumnsGridLayout>
        <Collapse in={isPreviouslyLessee ?? isPreviouslyLessee === undefined} unmountOnExit={true}>
          <FormSubSection title={t('main-tenantApplication:previousLeaseInfo.landlordInfo.title')}>
            <TwoColumnsGridLayout>
              <TextField {...inputProps('landlord.legalFirstName', { i18nKey: 'legalFirstName' })} />
              <TextField {...inputProps('landlord.legalLastName', { i18nKey: 'legalLastName' })} />
              <TextField {...inputProps('landlord.phoneNumber', { i18nKey: 'phoneNumber' })} />
              <TextField {...inputProps('landlord.emailAddress', { i18nKey: 'emailAddress' })} />
              <TextField
                {...inputProps('monthlyRent', {
                  namespace: 'main-tenantApplication:previousLeaseInfo',
                })}
              />
            </TwoColumnsGridLayout>
          </FormSubSection>
        </Collapse>
      </FormSection>
    </>
  )
}

export { CurrentAddressContent }
