import React from 'react'

import { makeStyles } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'

import { ErrorPage } from '../ErrorPage/ErrorPage'
import { I18nProvider } from '../I18nProvider'

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
  },
})

const UnexpectedError: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <I18nProvider>
      <BaseUnexpectedError {...props} />
    </I18nProvider>
  )
}

const BaseUnexpectedError: React.FC<React.PropsWithChildren> = (props) => {
  const { t } = useTranslation('unexpectedError')
  const classes = useStyles()

  return (
    <ErrorPage primaryText={t('primaryText')} secondaryText={t('secondaryText')} className={classes.root} {...props} />
  )
}

export { UnexpectedError }
