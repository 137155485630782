import { yup } from '@guiker/yup-util'

import { ListingBuildingAmenities } from '../../entity'
import { amenitySchema } from './amenity-schema'

export const buildingAmenitiesSchema = yup.object<ListingBuildingAmenities>({
  bikeParking: amenitySchema,
  communalOutdoorSpace: amenitySchema,
  concierge: amenitySchema,
  elevator: amenitySchema,
  gym: amenitySchema,
  laundryRoom: amenitySchema,
  storage: amenitySchema,
  indoorParking: amenitySchema,
  outdoorParking: amenitySchema,
  pool: amenitySchema,
  recreationRoom: amenitySchema,
  security: amenitySchema,
  wheelchairAccessible: amenitySchema,
})
