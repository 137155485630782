import { yup } from '@guiker/yup-util'

import { ApplicationStatus } from '../../../entity'

export const updateBookingApplicationStatusByTokenPayloadSchema = yup.object({
  applicationStatus: yup.string().oneOf<ApplicationStatus>(Object.values(ApplicationStatus)).required(),
  reviewalComments: yup.string().when('applicationStatus', {
    is: (applicationStatus: ApplicationStatus) => applicationStatus === ApplicationStatus.CHANGE_REQUESTED,
    then: yup.string().required(),
  }),
})

export type UpdateBookingApplicationStatusByTokenPayloadSchema = yup.InferType<
  typeof updateBookingApplicationStatusByTokenPayloadSchema
>
