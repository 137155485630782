import React from 'react'

import { Flex, theme, useMediaQuery } from '@guiker/components-library'

import { InvestorService } from './InvestorService'

const elements = ['service1', 'service2', 'service3'] as const

const InvestorServices: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Flex gap={isMobile ? 16 : 26} flexDirection='column'>
      {elements.map((el, index) => (
        <InvestorService key={el} orientation={index % 2 === 0 ? 'default' : 'reverse'} serviceKey={el} />
      ))}
    </Flex>
  )
}

export { InvestorServices }
