import { baseParamsValidator, idNestedParamsValidator } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { Building } from '../../../entity'
import { paths } from './paths'

export const buildingRoutes = {
  readAllBuildings: {
    path: paths.buildings.all.base(),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (res: unknown) => Promise.resolve(res as Building[]),
  },
  readOneBuildingByExternalId: {
    path: paths.buildings.all.withId(':externalId'),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: baseParamsValidator('externalId'),
    responseValidator: (res: unknown) => Promise.resolve(res as Building),
  },
  readOneBuilding: {
    path: paths.buildings.all.withId(),
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: (res: unknown) => Promise.resolve(res as Building),
  },
}
