import { DataMetadataWithCountByStatus, routesBuilder, ValueOfUnion } from '@guiker/shared-framework'

import {
  CSVBody,
  RentCollectionKpi,
  RentCollectionPayoutTableDataPoint,
  RentCollectionStatus,
  RentCollectionTableDataPoint,
  RentPayoutStatus,
  SummaryCollectionKpi,
  UnitsOccupancyKpi,
} from '../../entity'
import { paths } from './paths'
import { routes as payoutRoutes } from './payout'
import { routes as rentPaymentRroutes } from './rent-payment'
import {
  kpiQueryParams,
  readRentCollectionCSVDataQueryParams,
  readRentCollectionPerPayoutTableQueryParams,
  readRentCollectionPerTenantTableQueryParams,
  summaryKpiQueryParams,
} from './schemas'

const router = routesBuilder()({ basePath: paths.statistic.one.base() })

export const routes = {
  ...payoutRoutes,
  ...rentPaymentRroutes,
  getRentCollectionPieChart: router.buildGet({
    path: '/rent-collection-pie-chart',
    response: null as RentCollectionKpi,
    responseIsArray: true,
    queryParamsSchema: kpiQueryParams,
  }),
  generateUnitsOccupancyKpi: router.buildGet({
    path: '/units-occupancy-kpi',
    response: null as UnitsOccupancyKpi,
    responseIsArray: true,
    queryParamsSchema: kpiQueryParams,
  }),
  readRentCollectionPerTenantTable: router.buildGet({
    path: '/rent-collection-per-tenant-table',
    response: null as DataMetadataWithCountByStatus<RentCollectionTableDataPoint, RentCollectionStatus>,
    queryParamsSchema: readRentCollectionPerTenantTableQueryParams,
  }),
  readRentCollectionSummaryKpi: router.buildGet({
    path: '/rent-collection-summary-kpis',
    response: null as SummaryCollectionKpi,
    queryParamsSchema: summaryKpiQueryParams,
  }),
  readRentCollectionPerPayoutTable: router.buildGet({
    path: '/rent-collection-per-payout-table',
    response: null as DataMetadataWithCountByStatus<RentCollectionPayoutTableDataPoint, ValueOfUnion<RentPayoutStatus>>,
    queryParamsSchema: readRentCollectionPerPayoutTableQueryParams,
  }),
  readRentCollectionCSVData: router.buildGet({
    path: '/export-rent-collection',
    response: null as CSVBody[],
    queryParamsSchema: readRentCollectionCSVDataQueryParams,
  }),
}
