import { MarkRequired } from '@guiker/ts-utils'

export enum Action {
  CREATE_INVENTORY = 'CREATE_INVENTORY',
  CREATE_INVENTORY_CONFIGURATION_OPTION = 'CREATE_INVENTORY_CONFIGURATION_OPTION',
  UPDATE_INVENTORY_CONFIGURATION_OPTION = 'UPDATE_INVENTORY_CONFIGURATION_OPTION',
}

export type ActionError = {
  message: string
  statusCode?: number
  errorCode?: string
}

export type CSVTransformedBulkData = {
  csvIndex: number
  listingId: string
  rentalOptionId?: string
  inventoryId?: string
  unitNumber?: string
  price?: number
  availableDate?: string
  expiresAt?: string
  status?: string
  isFurnished?: boolean
  additionalParkingCount?: number
  bedroomCount?: number
  bathroomCount?: number
  kitchenCount?: number
  livingroomCount?: number
  action: Action
  amenities: {
    electricity: boolean
    airConditioner: boolean
    washer: boolean
    refrigerator: boolean
    balcony: boolean
    oven: boolean
    smallPets: boolean
    largePets: boolean
    hotWater: boolean
    heating: boolean
    wifi: boolean
    dishwasher: boolean
  }
}

export type CSVPayload = CSVTransformedBulkData[]

export type DryRunResult = CSVTransformedBulkData & {
  error?: ActionError
}

export type DryRunResponse = {
  results: DryRunResult[]
  errors: MarkRequired<DryRunResult, 'error'>[]
  meta: {
    [Action.CREATE_INVENTORY]: number
    [Action.CREATE_INVENTORY_CONFIGURATION_OPTION]: number
    [Action.UPDATE_INVENTORY_CONFIGURATION_OPTION]: number
  }
}
