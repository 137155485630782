import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { RentalListing } from '../../entity'
import { baseEvent as domainBaseEvent, EventTypes as DomainEventTypes } from '../base'

const baseEvent = {
  ...domainBaseEvent,
  entity: 'LISTING',
  data: null as unknown as RentalListing,
}

const EventTypes = {
  ...DomainEventTypes,
  PROCESSED_FROM_LEAD: 'PROCESSED_FROM_LEAD',
}
type EventTypes = typeof EventTypes

export const EventsDefinition = generateEventsDefinition(baseEvent)
export const Events = generateEventsFromEnum(baseEvent)(EventTypes)
