import React from 'react'

import { makeStyles } from '@guiker/components-core'
import { Box, Flex, Grid, H5, P, PSmall } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { WalkIcon } from '@guiker/icons'
import { PointOfInterest, PointOfInterestType } from '@guiker/map-shared'

type PointOfInterestProps = {
  pointOfInterests: PointOfInterest[]
  type: PointOfInterestType
}

const useStyle = makeStyles(
  {
    type: {
      marginTop: 2,
    },
    poiName: {
      wordBreak: 'break-all',
    },
  },
  {
    name: 'PointOfInterest',
  },
)

export const PointOfInterestContainer: React.FC<PointOfInterestProps> = ({ pointOfInterests, type }) => {
  const { t } = useTranslation(['common-baseListing'])
  const classes = useStyle()

  return (
    <Flex flexDirection='column'>
      <H5 className={classes.type}>{t(`pointsOfInterest.${type}`)}</H5>
      <Grid container direction='row' spacing={2} component={Box}>
        {pointOfInterests.map((poi) => (
          <React.Fragment key={poi.placeId}>
            <Grid item key={poi.name} xs={6} sm={8}>
              <P mb={0} className={classes.poiName}>
                {poi.name}
              </P>
            </Grid>
            <Grid item key={poi.placeId} xs={6} sm={4}>
              <Flex gap={2} alignItems='center' flexWrap='nowrap' ml={2}>
                <WalkIcon />
                <PSmall mb={0}>{poi.travelTime}</PSmall>
              </Flex>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Flex>
  )
}
