import React, { useCallback, useRef, useState } from 'react'

import {
  CircularProgress,
  clsx,
  CLTextField,
  CLTextFieldProps,
  Flex,
  makeStyles,
  MaxWidth,
  PBold,
  Popover,
  PSmall,
  SearchIcon,
  theme,
} from '@guiker/react-framework'
import { debounce } from '@guiker/shared-framework'

import { useT } from '../../i18n'
import { ItemGroup } from '../../utils'
import { SearchMenu } from './SearchMenu'

const INPUT_HEIGHT = 40

const useStyles = makeStyles(
  (theme) => ({
    textField: {
      paddingLeft: theme.spacing(1),
    },
    inputBase: {
      paddingLeft: theme.spacing(2),
    },
    rounded: {
      borderRadius: theme.spacing(1),
      maxHeight: INPUT_HEIGHT,
    },
    menu: {
      width: '95%',
      maxWidth: ({ menuMaxWidth }: { menuMaxWidth: MaxWidth }) => menuMaxWidth || 700,
      maxHeight: `calc(100% - ${theme.dimensions.appBar.height.desktop + theme.spacing(2)}px)`,
    },
  }),
  { name: 'SearchBar' },
)

type SearchBarProps = {
  anchorOriginHorizontal?: 'left' | 'right' | 'center'
  itemPerGroupLimit?: number
  menuMaxWidth?: MaxWidth
  textFieldProps?: Partial<CLTextFieldProps>
  variant?: 'rounded' | 'default'
  showSeeAll?: boolean
  useMutationPerformSearch: (searchString: string) => {
    data: ItemGroup[]
    isLoading: boolean
    mutate: (searchString: string) => void
  }
  withAdornment?: boolean
}

export const SearchBar: React.FC<SearchBarProps> = ({
  anchorOriginHorizontal = 'center',
  itemPerGroupLimit,
  menuMaxWidth,
  showSeeAll,
  textFieldProps = {},
  useMutationPerformSearch,
  variant,
  withAdornment,
}) => {
  const classes = useStyles({ menuMaxWidth })
  const ref = useRef<Element>()
  const { tOffice } = useT({ screenName: 'components' })
  const [searchString, setSearchString] = useState<string>('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { data: searchResults, isLoading, mutate: triggerSearch } = useMutationPerformSearch(searchString)

  const debouncedSearch = useCallback(
    debounce((searchString: string) => {
      searchString?.length > 3 && triggerSearch(searchString)
    }, 400),
    [],
  )

  const handleInputChange = (value: string) => {
    setIsMenuOpen(!!value)
    setSearchString(value)

    debouncedSearch(value)
  }

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  return (
    <>
      <CLTextField
        fullWidth
        maxWidth={500}
        innerRef={ref}
        startAdornment={
          isLoading ? <CircularProgress size={16} color='inherit' variant='indeterminate' /> : <SearchIcon />
        }
        onClick={() => setIsMenuOpen(true)}
        onChange={(event) => handleInputChange(event.target.value)}
        value={searchString}
        inputBaseProps={{
          className: clsx(classes.inputBase, { [classes.rounded]: variant === 'rounded' }),
        }}
        className={classes.textField}
        type='text'
        {...textFieldProps}
      />
      <Popover
        disableAutoFocus={true}
        classes={{ paper: classes.menu }}
        open={isMenuOpen && !!searchString && !!searchResults}
        anchorEl={ref.current}
        onClose={closeMenu}
        disableEnforceFocus
        anchorOrigin={{ vertical: INPUT_HEIGHT + theme.spacing(1), horizontal: anchorOriginHorizontal }}
        transformOrigin={{ vertical: 'top', horizontal: anchorOriginHorizontal }}
      >
        {searchResults && searchResults.length > 0 ? (
          <SearchMenu
            groups={searchResults}
            isLoading={!searchResults && isLoading}
            itemPerGroupLimit={itemPerGroupLimit}
            onClose={closeMenu}
            searchString={searchString}
            showSeeAll={showSeeAll}
            withAdornment={withAdornment}
          />
        ) : (
          <Flex flexDirection='column' alignItems='center' p={2}>
            <Flex flexDirection='column' maxWidth={500} alignItems='center'>
              <PBold textAlign='center' whiteSpace='pre-line'>
                {tOffice('emptyState.label')}
              </PBold>
              <PSmall mb={0} textAlign='center' whiteSpace='pre-line'>
                {tOffice('emptyState.description')}
              </PSmall>
            </Flex>
          </Flex>
        )}
      </Popover>
    </>
  )
}
