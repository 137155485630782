import { SupportingDocument } from '../entity'

export const hasCompletedSupportingDocument = (supportingDocument: SupportingDocument) => {
  return (
    (supportingDocument?.assets && supportingDocument?.assets?.length > 0 && !supportingDocument?.isWaiveRequested) ||
    (supportingDocument?.isWaiveRequested && supportingDocument?.reasonForWaive)
  )
}

export const hasAnySupportingDocuments = (supportingDocuments: SupportingDocument[]) => {
  return supportingDocuments?.some((supportingDocument) => hasCompletedSupportingDocument(supportingDocument))
}
