import { CurrencyISO } from '@guiker/money'

import { QuebecOperationCost } from '../operation-cost'

export const OperationCost = {
  ...QuebecOperationCost,
  municipalTax: {
    jurisdiction: 'montreal-qc',
    type: 'municipalTax',
    frequency: 'yearly',
    value: {
      type: 'percentage',
      base: 'purchasePrice',
      rate: 0.008,
    },
  },
  schoolTax: {
    jurisdiction: 'montreal-qc',
    type: 'schoolTax',
    frequency: 'yearly',
    value: {
      type: 'flat',
      amount: {
        currency: CurrencyISO.CAD,
        value: 0,
      },
    },
  },
} as const
