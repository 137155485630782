/** For detail of each event: https://developers.docusign.com/platform/webhooks/connect/event-triggers/ */
const EnvelopeEvent = {
  SENT: 'envelope-sent',
  DELIVERED: 'envelope-delivered',
  COMPLETED: 'envelope-completed',
  DECLINED: 'envelope-declined',
  VOIDED: 'envelope-voided',
  RESENT: 'envelope-resent',
  CORRECTED: 'envelope-corrected',
  PURGE: 'envelope-purge',
  DELETED: 'envelope-deleted',
  DISCARDED: 'envelope-discard',
} as const

const RecipientEvent = {
  SENT: 'recipient-sent',
  AUTO_RESPONDED: 'recipient-autoresponded',
  DELIVERED: 'recipient-delivered',
  COMPLETED: 'recipient-completed',
  DECLINED: 'recipient-declined',
  AUTH_FAILED: 'recipient-authenticationfailed',
  RESENT: 'recipient-resent',
  DELEGATE: 'recipient-delegate',
  REASSIGN: 'recipient-reassign',
  FINISH_LATER: 'recipient-finish-later',
} as const

const TemplateEvent = {
  CREATED: 'template-created',
  MODIFIED: 'template-modified',
  DELETED: 'template-deleted',
} as const

export type EnvelopeEventType = (typeof EnvelopeEvent)[keyof typeof EnvelopeEvent]
export type RecipientEventType = (typeof RecipientEvent)[keyof typeof RecipientEvent]
export type TemplateEventType = (typeof TemplateEvent)[keyof typeof TemplateEvent]

export const DocusignEventEnum: {
  Envelope: { [Key in keyof typeof EnvelopeEvent]: EnvelopeEventType }
  Template: { [Key in keyof typeof TemplateEvent]: TemplateEventType }
  Recipient: { [Key in keyof typeof RecipientEvent]: RecipientEventType }
} = {
  Envelope: EnvelopeEvent,
  Template: TemplateEvent,
  Recipient: RecipientEvent,
} as const

type DocusignEventType = EnvelopeEventType | RecipientEventType | TemplateEventType

export type Tab = {
  status?: string
  documentId: string
  tabLabel: string
  value?: string
  optional?: string
}

export type EnvelopeSummaryDocument = {
  name: string
  documentId: string
  documentIdGuid: string
}

export type EnvelopeSummary = {
  recipients: {
    signers: {
      email: string
      recipientId: string
      recipientIdGuid: string
      name: string
      status: string
      roleName: string
      tabs?: Tab[]
    }[]
  }
  envelopeDocuments: EnvelopeSummaryDocument[]
}

export type DocusignWebhookEvent = {
  event: DocusignEventType
  uri: string
  retryCount: string
  configurationId: string
  apiVersion: string
  generatedDateTime: string
  data: {
    accountId: string
    userId: string
    envelopeId: string
    envelopeSummary: EnvelopeSummary
    recipientId?: string
  }
}
