import { TypeOf, yup } from '@guiker/yup-util'

import { Ontario } from '../../../entity'

export const rentDeposit = yup.object<Omit<Ontario.RentDeposit, 'completed'>>({
  isDepositRequired: yup.boolean().required(),
  depositAmount: yup.number().when('isDepositRequired', {
    is: true,
    then: yup.number().required(),
    otherwise: yup
      .number()
      .nullable()
      .transform(() => null as null),
  }),
})

export type RentDeposit = TypeOf<typeof rentDeposit>
