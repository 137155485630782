import { Base64 } from '@guiker/base64'

const isValidJWT = (token: string) => !!token && token.split('.').length === 3

export const parseAccessControlToken = (token: string, options: { validateExpiration?: boolean } = {}) => {
  const parsed = isValidJWT(token) && JSON.parse(Base64.decode(token.split('.')[1]))
  const claims = !!parsed.data ? (JSON.parse(parsed.data) as unknown) : parsed

  if (options.validateExpiration) {
    const isExpired = claims?.exp ? claims.exp * 1000 < Date.now() : false
    return isExpired ? undefined : claims
  }

  return claims
}
