import { AnyColor } from '@guiker/components-core'
import { TFunction } from '@guiker/i18n'
import { PieChartKpi } from '@guiker/react-chart'
import { UnitsOccupancyKpi, UnitsOccupancyKpiKey } from '@guiker/statistic-shared'

const findColor = (status: UnitsOccupancyKpiKey): AnyColor => {
  switch (status) {
    case UnitsOccupancyKpiKey.RENTED:
      return 'success'
    case UnitsOccupancyKpiKey.AVAILABLE:
      return 15
  }
}

export const transformKpiDataToDataset = (
  data: UnitsOccupancyKpi[],
  t: TFunction,
): PieChartKpi<UnitsOccupancyKpiKey>[] =>
  data.map((point) => ({
    ...point,
    value: point.value,
    label: t(`components.unitsOccupancyPieChart.status.${point.key}`),
    color: findColor(point.key),
  }))
