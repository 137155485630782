import { AuthLevel, buildPaths, generateApiPath } from '@guiker/api-definition'

const prefix = AuthLevel.AUTH

const paths = {
  payInMethod: generateApiPath({ prefix, name: 'payment-method' }),
  paymentAgent: generateApiPath({ prefix, name: 'payment-agent' }),
  invoice: generateApiPath({ prefix, name: 'invoice' }),
  tax: generateApiPath({ prefix, name: 'tax' }),
  ...buildPaths(
    {
      fundingAccount: {
        deposit: {},
        withdrawal: {},
      },
    },
    prefix,
  ),
}

export { paths }
