import { scopeSchema } from '@guiker/access-control-shared'
import { yup } from '@guiker/yup-util'

import { DocumentRoleNames } from '../../../permissions'

export const documentAccessControlClaimsSchema = yup.object({
  scope: scopeSchema,
  userId: yup.string(),
  emailAddress: yup.string().required(),
  acl: yup
    .object({
      document: yup
        .object({
          roles: yup
            .array()
            .of(yup.string().oneOf(Object.values(DocumentRoleNames)))
            .required(),
        })
        .required(),
    })
    .required(),
})

export type DocumentAccessControlClaimsSchema = yup.InferType<typeof documentAccessControlClaimsSchema>
