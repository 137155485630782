import { Role, RoleNameRoleResolver } from '@guiker/permissions'

import { LeaseRoleNames } from '../entity'
import * as Roles from './roles'

export const LeaseRoleNamesRoleResolver: RoleNameRoleResolver<LeaseRoleNames> = ({ roleNames }): Role[] => {
  if (!roleNames || roleNames.length === 0) {
    return []
  }

  const roles: Role[] = roleNames.map((name) => {
    switch (name) {
      case LeaseRoleNames.DRAFTER:
        return Roles.DrafterRole
      case LeaseRoleNames.GUARANTOR:
        return Roles.GuarantorRole
      case LeaseRoleNames.LESSEE:
        return Roles.LesseeRole
      case LeaseRoleNames.LESSOR:
        return Roles.LessorRole
      case LeaseRoleNames.SPECTATOR:
        return Roles.SpectatorRole
    }
  })

  return roles
}
