import { useEffect, useState } from 'react'

import { useBookingContext } from '@guiker/booking-context'
import { Booking, BookingStatus } from '@guiker/booking-shared'
import { LeaseRoleNames } from '@guiker/lease-shared'
import { useMutation } from '@guiker/react-query'

import { useLeaseApiClient, useLeaseJwtAuthenticatedApiClient } from './use-lease-api-client'

interface LeaseDownloadLink {
  leaseDownloadLink: string | null
}

const useLeaseDownloadLink = (booking?: Booking) => {
  const apiClient = useLeaseApiClient()
  const jwtApiClient = useLeaseJwtAuthenticatedApiClient()
  const [result, setResult] = useState<LeaseDownloadLink>({ leaseDownloadLink: null })
  const { accessControl } = useBookingContext()

  const isSpectator = accessControl?.claims && accessControl.claims.acl.lease?.roles.includes(LeaseRoleNames.SPECTATOR)

  const { mutate: fetchLeaseDownloadLink } = useMutation(
    ({ scopeType, scopeId }: { scopeType: string; scopeId: string }) =>
      isSpectator
        ? jwtApiClient.getLeaseDownloadLinkByScopeAndRoleToken({
            pathParams: { scopeType, scopeId },
            queryParams: {},
            accessControl: { token: accessControl.token },
          })
        : apiClient.getLeaseDownloadLinkByScope({ pathParams: { scopeType, scopeId }, queryParams: {} }),
    {
      onSuccess: (data) => setResult({ leaseDownloadLink: data.downloadLink }),
      onError: () => setResult({ leaseDownloadLink: null }),
    },
  )

  useEffect(() => {
    if (booking && booking.status === BookingStatus.BOOKED) {
      fetchLeaseDownloadLink({ scopeType: 'Booking', scopeId: booking.id })
    }
  }, [booking])

  return result
}

export { useLeaseDownloadLink }
