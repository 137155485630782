import { buildPaginationQueryParamSchema } from '@guiker/paginate'
import { yup } from '@guiker/yup-util'

export const readAllUsersQueryParamsSchema = buildPaginationQueryParamSchema().concat(
  yup.object({
    searchString: yup.string(),
  }),
)

export type ReadAllUsersQueryParams = yup.InferType<typeof readAllUsersQueryParamsSchema>
