import { CurrencyISO, Payment, yup } from '@guiker/shared-framework'

import { paymentInfoSchema } from '../../../schemas'
import { customerSchema, itemSchema } from './base-schemas'

export const createChargableInvoicePayloadSchema = yup
  .object({
    currency: yup.mixed().oneOf(Object.values(CurrencyISO)).required(),
    items: yup.array().of(itemSchema).required(),
    customer: customerSchema.required(),
    metadata: yup.mixed(),
    info: paymentInfoSchema,
    receiver: yup.object({ transfer: yup.number().required() }),
    shouldVerify: yup.boolean().required(),
    autoChargeEnabled: yup.boolean(),
    payInMethod: yup
      .object({
        id: yup.string().required(),
        type: yup.string().oneOf(Object.values(Payment.PayInMethodType)).required(),
      })
      .required(),
  })
  .required()

export type CreateChargableInvoicePayloadSchema = yup.InferType<typeof createChargableInvoicePayloadSchema>
