import {
  removeAllExtraWhiteSpaces,
  removeAllWhiteSpaces,
  removeSpecialCharsAndWhiteSpaces,
  replaceNonNumeric,
  testIfNumeric,
} from '@guiker/lodash'

export const validateZipCode = (str: string, country: 'CA' | 'US') => {
  const sanitizedZipCode = removeSpecialCharsAndWhiteSpaces(str)
  switch (country) {
    case 'CA':
      return testIfValidCanadianPostalCode(sanitizedZipCode)
    case 'US':
      return testIfNumeric(sanitizedZipCode) && sanitizedZipCode.length === 5
    default:
      return false
  }
}

export const sanitizeZipcode = (zipcode: string, country: 'CA' | 'US') => {
  if (country === 'US') {
    return replaceNonNumeric(removeAllWhiteSpaces(zipcode))
  }
  return removeSpecialCharsAndWhiteSpaces(zipcode)
}

export const testIfValidCanadianPostalCode = (val: string): boolean => {
  // criteria for testing Canadian postal code
  // the format A1A 1A1, where A is a letter and 1 is a digit.
  // do not include the letters D, F, I, O, Q or U.
  // the first position does not make use of the letters W or Z.
  const regex = /^(?!.*[DFIOQU])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/g
  return regex.test(val)
}

const ADDRESS_KEYWORDS = [
  'allée',
  'allee',
  'alley',
  'allee',
  'ally',
  'aly',
  'annex',
  'anex',
  'annx',
  'anx',
  'avenue',
  'ave',
  'av',
  'aven',
  'avenu',
  'avn',
  'avnue',
  'bayou',
  'burg',
  'byu',
  'bayoo',
  'bend',
  'bnd',
  'boulevard',
  'boul',
  'boulv',
  'bld',
  'blvd',
  'bridge',
  'bypass',
  'bypa',
  'bypas',
  'byps',
  'byp',
  'causeway',
  'cswy',
  'causway',
  'chemin',
  'circle',
  'circ',
  'circl',
  'crcl',
  'crcle',
  'cir',
  'court',
  'crescent',
  'cres',
  'crt',
  'cour',
  'commons',
  'common',
  'cmn',
  'cmns',
  'creek',
  'crest',
  'crossroad',
  'crossing',
  'drive',
  'dr',
  'drives',
  'drv',
  'driv',
  'esplanade',
  'grove',
  'hill',
  'lane',
  'path',
  'pike',
  'pk',
  'place',
  'pl',
  'sentier',
  'street',
  'str',
  'square',
  'rd',
  'road',
  'rue',
  'ruelle',
  'way',
  'walk',
  'voie',
  'north',
  'northeast',
  'north east',
  'northwest',
  'north west',
  'south',
  'southwest',
  'southeast',
  'south west',
  'south east',
  's',
  'e',
  'n',
  'w',
  'nw',
  'o',
  'ne',
  'sw',
  'se',
  'east',
  'est',
  'nord',
  'sud',
  'west',
  'ouest',
]

const removeSpecialCharsReplaceWithSpace = (val: string): string => {
  const regex = /[^0-9a-zA-Z ]+/gi
  return val.replace(regex, ' ').toLowerCase()
}

export const sanitizeStreetName = (streetName: string) => {
  const removeSpecialCharsStreetName = removeSpecialCharsReplaceWithSpace(streetName.trim()).trim().toLowerCase()
  const removeSuffixRegex = new RegExp('\\b(' + ADDRESS_KEYWORDS.join('|') + ')\\b', 'gm')
  const sanitizedStreetName = removeSpecialCharsStreetName.replace(removeSuffixRegex, '')
  return removeAllExtraWhiteSpaces(sanitizedStreetName)
}
