import { Address, Payment, Scope } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'
import { PayoutMethodStatus, PayoutProvider } from '@guiker/payout-shared'

export type PayoutMethod = {
  id: string
  userId: string
  currency: CurrencyISO
  status: PayoutMethodStatus
  provider: PayoutProvider[]
  type: Payment.SupportedPayoutMethodType
  accountHolderType: Payment.SupportedAccountHolderType
  details?: unknown
}

export type Person = {
  userId: string
  firstName: string
  lastName?: string
  emailAddress: string
}

export enum PayerVerificationStatus {
  INITIAL = 'INITIAL',
  VERIFICATION_REJECTED = 'VERIFICATION_REJECTED',
  VERIFICATION_PASSED = 'VERIFICATION_PASSED',
}

export type Payer = Person & {
  amount: number
  tentativeAmount?: number
  verificationStatus: PayerVerificationStatus
  payInMethod: Payment.PayInMethod
}

export type Receiver = Person

export type Instalment = {
  isAdvancePayment: boolean
  chargeDate: string
  amount: number
}

export enum ScopeType {
  BOOKING = 'booking',
}

export enum RentPaymentsPlanStatus {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TERMINATED = 'TERMINATED',
}

export type Unit = {
  address: Address & { room?: string }
  listingId: string
  rentalOptionId: string
}

export type TotalRent = {
  amount: number
  currency: CurrencyISO
}

export type RentPaymentsPlan = {
  id: string
  status: RentPaymentsPlanStatus
  scope: Scope
  unit: Unit
  receiver: Receiver
  payoutMethod: PayoutMethod
  fee: Payment.RentalServiceFee
  instalments: Instalment[]
  isResplitRequested: boolean
  payers: Payer[]
  total: TotalRent
  createdAt: string
  updatedAt: string
}

export type RentChargeable = {
  count: number
  chargeDate: string
  receiver: Receiver
  chargeables: {
    total: TotalRent
    instalment: Instalment
    payer: Payer
    unit: Unit
    fee: Payment.RentalServiceFee
  }[]
}
