import { ListingInquiryRentalOption, RentalOption } from '../entity'

type ListingInquiryRentalOptionsMap = {
  [key: string]: ListingInquiryRentalOption
}

export const transformRentalOptions = (rentalOptions: RentalOption[]) => {
  const reconstructedOptionsMap: ListingInquiryRentalOptionsMap = {}

  // map rentalOptions to object by bedAndBathCount
  rentalOptions.forEach((rentalOption) => {
    const { bedroom, bathroom } = rentalOption.roomCount
    const bedAndBathCount = `${bedroom}${bathroom}`

    const reconstructedPriceOption = rentalOption.priceOptions.map((priceOption) => ({
      ...rentalOption,
      ...priceOption,
    }))

    reconstructedOptionsMap[bedAndBathCount]
      ? (reconstructedOptionsMap[bedAndBathCount].priceOptions = reconstructedOptionsMap[
          bedAndBathCount
        ].priceOptions.concat([...reconstructedPriceOption]))
      : (reconstructedOptionsMap[bedAndBathCount] = {
          roomCount: rentalOption.roomCount,
          priceOptions: [...reconstructedPriceOption],
        })
  })

  // return array from reconstructedOptionsMap and sort
  return Object.values(reconstructedOptionsMap).sort((a, b) => {
    const roomCountA = a.roomCount
    const roomCountB = b.roomCount
    if (roomCountA.bedroom > roomCountB.bedroom) return 1
    if (roomCountA.bedroom < roomCountB.bedroom) return -1
    if (roomCountA.bathroom > roomCountB.bathroom) return 1
    if (roomCountA.bathroom < roomCountB.bathroom) return -1
  })
}
