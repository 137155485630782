import { HttpMethod } from '@guiker/http'

import { Envelope, Template } from '../../entity'
import { byScope } from '../schemas'
import { paths } from './paths'
import {
  createEnvelopePayload,
  GetTemplatesQueryParams,
  getTemplatesQueryParams,
  getUrlPayloadValidator,
  updateEnvelopePrefillTabsPayload,
} from './schemas'

export const routes = {
  createEnvelope: {
    path: paths.envelope.one.withScope(),
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as Envelope & { editUrl: string }),
    pathParamsValidator: (payload: unknown) => byScope.validate(payload),
    payloadValidator: (payload: unknown) => createEnvelopePayload.validate(payload),
  },
  updateEnvelopePrefillTabs: {
    path: `${paths.envelope.one.withScope()}/update-prefill-tabs`,
    method: HttpMethod.POST,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => byScope.validate(payload),
    payloadValidator: (payload: unknown) => updateEnvelopePrefillTabsPayload.validate(payload),
  },
  getTemplates: {
    path: `${paths.envelope.one.base()}/templates`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as Template[]),
    queryParamsValidator: (queryParams: unknown): Promise<GetTemplatesQueryParams> =>
      getTemplatesQueryParams.validate(queryParams),
  },
  generateDocusignLink: {
    path: `${paths.envelope.one.withScope()}/generate-url`,
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (response: unknown) => Promise.resolve(response as string),
    pathParamsValidator: (pathParams: unknown) => byScope.validate(pathParams),
    payloadValidator: (payload: unknown) => getUrlPayloadValidator.validate(payload),
  },
  checkIsDocumentLocked: {
    path: `${paths.envelope.one.withScope()}/is-locked`,
    method: HttpMethod.GET,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => byScope.validate(payload),
  },
  sendEnvelope: {
    path: `${paths.envelope.one.withScope()}/send`,
    method: HttpMethod.PUT,
    authenticated: true,
    pathParamsValidator: (payload: unknown) => byScope.validate(payload),
  },
}
