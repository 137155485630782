import React, { createContext } from 'react'

import { useWebsocket } from '../hooks/use-websocket'

export type Context = {}
export const WebsocketContext = createContext<Context>(null)

const WebsocketProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  useWebsocket()

  return <WebsocketContext.Provider value={{}}>{children}</WebsocketContext.Provider>
}

export { WebsocketProvider }
