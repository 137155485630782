import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { Box, Flex, FormHelperText, InputLabel, makeStyles } from '@guiker/components-library'
import { renderChildren } from '@guiker/react-framework'
import { StripeError } from '@stripe/stripe-js'

import { cardElementStyle } from '../context'
import { useTranslation } from '../hooks'

type RenderProps = {
  onFocus: () => unknown
  onBlur: () => unknown
}

type Props = {
  children: (renderProps: RenderProps) => React.ReactNode
  id?: string
  label: string
  inputError: StripeError
}

const useStyle = makeStyles(
  {
    ...cardElementStyle,
  },
  {
    name: 'StripeCardElement',
  },
)

export const InputBase: React.FC<Props> = ({ id, label, inputError, children }) => {
  const { t } = useTranslation(['common-payment'])
  const classes = useStyle()
  const [focus, setFocus] = useState(false)

  const renderProps = {
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false),
  }

  return (
    <Flex flexDirection='column' justifyContent='flex-start' gap={1}>
      <InputLabel htmlFor={id} text={label} />
      <Box className={clsx(classes.inputContainer, { [classes.focused]: focus, [classes.inputError]: inputError })}>
        {renderChildren(children, renderProps)}
      </Box>
      {inputError && (
        <FormHelperText role='alert' color='alert'>
          {t('invalid')}
        </FormHelperText>
      )}
    </Flex>
  )
}
