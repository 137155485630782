import { BaseExpense } from '../../../expense'

export type Jurisdiction = 'ontario'

export interface OntarioExpense extends BaseExpense {
  jurisdiction: Jurisdiction
}

export const HST = {
  jurisdiction: 'ontario',
  type: 'HST',
  frequency: 'oneTimeAtPurchase',
  value: {
    type: 'percentage',
    base: 'purchasePrice',
    rate: 0.09,
  },
} as const
