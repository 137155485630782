import * as React from 'react'

import { P, PSmall } from '@guiker/components-library'
import { PropertySaleListing } from '@guiker/property-sale-shared'
import { money } from '@guiker/shared-framework'

export type CardPriceComponentProps = {
  listing: PropertySaleListing
  size?: 'small' | 'medium'
}

export const CardPriceComponent: React.FC<CardPriceComponentProps> = ({ listing, size = 'medium' }) => {
  const TextComponent = size === 'medium' ? P : PSmall

  return (
    <TextComponent fontWeight={500} whiteSpace='nowrap'>
      {money.fromAmount(listing.assumptions.purchase.price.amount, listing.price.currency).toString('onlySymbol', true)}
    </TextComponent>
  )
}
