import React from 'react'

import { PropSharingListingWithFundingData, SeriesStatus } from '@guiker/propsharing-shared'
import { AnnouncementIcon, CheckCircle, Chip, TrendingIcon } from '@guiker/react-framework'

import { useGetHelpers } from '../../hooks'
import { useT } from '../../i18n'

type PropSharingListingCardChipProps = {
  listing: PropSharingListingWithFundingData
  className?: string
}

export const PropSharingListingCardChip: React.FC<PropSharingListingCardChipProps> = ({ className, listing }) => {
  const { tMain } = useT({ screenName: 'components.propsharingListingCard' })

  const {
    stock: { isFullyFunded, latestSeries, isSold },
  } = useGetHelpers(listing)

  return isSold ? (
    <Chip className={className} size='small' startAdornment={<AnnouncementIcon size='smaller' />}>
      {tMain('sold')}
    </Chip>
  ) : latestSeries.status === SeriesStatus.COMING_SOON ? (
    <Chip className={className} size='small' startAdornment={<AnnouncementIcon size='smaller' />}>
      {tMain('comingSoon')}
    </Chip>
  ) : isFullyFunded ? (
    <Chip className={className} size='small' startAdornment={<CheckCircle size='smaller' />}>
      {tMain('fullyFunded')}
    </Chip>
  ) : listing.additionalFlags?.isTrending ? (
    <Chip className={className} size='small' startAdornment={<TrendingIcon size='smaller' />}>
      {tMain('trending')}
    </Chip>
  ) : (
    <></>
  )
}
