import React, { useRef } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { IFrame, IFrameProps, isLocalhost, useConfig } from '@guiker/react-framework'
import { compact } from '@guiker/shared-framework'

type Props<T> = Omit<IFrameProps<T>, 'originRegex'>

export const LegacyIFrame = <T extends object = object>({ src, ...props }: Props<T>) => {
  const { appBaseUrl } = useConfig()
  const { user } = useAuthenticationContext()

  const authToken = isLocalhost() ? `auth_token=${user.accessToken}` : undefined
  const [path, query] = src.split('?')

  const srcRef = useRef(`${appBaseUrl}${path}?${compact([query, authToken]).join('&')}`)
  const originRegex = new RegExp('https://(.*.)?guiker.com')

  return <IFrame<T> {...props} src={srcRef.current} originRegex={originRegex} />
}
