import { camelCase, mapKeys, snakeCase } from 'lodash'

import { deeply } from './deeply'

export const camelizeKeys = <T>(object: object): T => {
  return deeply(mapKeys)(object, (_v: unknown, key: string) => camelCase(key)) as T
}

export const snakeizeKeys = <T>(object: object): T => {
  return deeply(mapKeys)(object, (_v: unknown, key: string) => snakeCase(key)) as T
}

export const transformKeys = <T>(object: object, transform: (key: string) => string): T => {
  return deeply(mapKeys)(object, (_v: unknown, key: string) => transform(key)) as T
}
