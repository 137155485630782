import { DealType } from '@guiker/base-listing-shared'
import { BuildingTypes } from '@guiker/real-estate-shared'
import { yup } from '@guiker/yup-util'

export const searchListingSchema = yup.object({
  city: yup.string().transform((value) => value || undefined),
  dealType: yup
    .mixed()
    .oneOf(Object.values(DealType))
    .transform((value) => value || undefined),
  buildingType: yup
    .mixed()
    .oneOf(Object.values(BuildingTypes))
    .transform((value) => value || undefined),
  pricePerUnit: yup
    .array()
    .of(yup.number())
    .transform((value) => value || undefined),
  projectedIRR: yup
    .array()
    .of(yup.number())
    .transform((value) => value || undefined),
  percentFunded: yup
    .array()
    .of(yup.number())
    .transform((value) => value || undefined),
})

export type SearchListingSchema = yup.InferType<typeof searchListingSchema>
