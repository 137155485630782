import { Base64 } from '@guiker/base64'

type UserTokenClaims = {
  ID: string
  EMAIL: string
  FIRST_NAME: string
  LAST_NAME: string
}

type AuthenticationTokenClaims = UserTokenClaims & {
  category: 'AUTHENTICATE'
  exp?: number
}

type InvitationTokenClaims = UserTokenClaims & {
  category: 'INVITATION'
}

const isAuthenticationClaims = (claims: unknown): claims is AuthenticationTokenClaims => {
  const casted = claims as AuthenticationTokenClaims
  return !!(casted.ID && casted.EMAIL && casted.category === 'AUTHENTICATE')
}

const isInvitationClaims = (claims: unknown): claims is InvitationTokenClaims => {
  const casted = claims as InvitationTokenClaims
  return !!(casted.ID && casted.EMAIL && casted.category === 'INVITATION')
}

const isValidJWT = (token: string) => !!token && token.split('.').length === 3

const parseAuthenticationToken = (
  token: string,
  options: { validateExpiration?: boolean } = {},
): AuthenticationTokenClaims => {
  const parsed = isValidJWT(token) && JSON.parse(Base64.decode(token.split('.')[1]))
  const claims = !!parsed && isAuthenticationClaims(parsed) ? parsed : undefined

  if (options.validateExpiration) {
    const isExpired = claims?.exp ? claims.exp * 1000 < Date.now() : false
    return isExpired ? undefined : claims
  }

  return claims
}

const parseInvitationToken = (token: string): InvitationTokenClaims => {
  const claims = isValidJWT(token) && JSON.parse(atob(token.split('.')[1]))
  return !!claims && isInvitationClaims(claims) ? claims : undefined
}

const passwordRequirements = {
  hasLowercase: (password: string) => {
    const lowercasePattern = '(?=.*[a-z])'
    const lowercaseRegex = new RegExp(lowercasePattern)
    return lowercaseRegex.test(password)
  },
  hasUppercase: (password: string) => {
    const uppercasePattern = '(?=.*[A-Z])'
    const uppercaseRegex = new RegExp(uppercasePattern)
    return uppercaseRegex.test(password)
  },
  hasDigits: (password: string) => {
    const digitPattern = '(?=.*[0-9])'
    const digitRegex = new RegExp(digitPattern)
    return digitRegex.test(password)
  },
  hasSpecialCharacters: (password: string) => {
    const specialCharacterPattern = `(?=.*[@$!%*?&_-])`
    const specialCharacterRegex = new RegExp(specialCharacterPattern)
    return specialCharacterRegex.test(password)
  },
}

export {
  AuthenticationTokenClaims,
  InvitationTokenClaims,
  isAuthenticationClaims,
  isInvitationClaims,
  parseAuthenticationToken,
  parseInvitationToken,
  passwordRequirements,
}
