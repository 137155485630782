import React from 'react'

import { clsx } from '@guiker/clsx'
import {
  AnyColor,
  isAtMostTablette,
  isLargeScreenDesktop,
  isMidSizeDesktop,
  isMobile,
  isNotLargeScreenDesktop,
  makeStyles,
  theme,
} from '@guiker/components-core'

import { Box, BoxProps, P, PBold, Quote, TagTypography } from '..'

type UseStyleProps = {
  color: AnyColor
  isMedium: boolean
  isSmall: boolean
  isXSmall: boolean
}
const useStyle = makeStyles(
  {
    root: {
      display: 'flex',
      width: '100%',
      maxWidth: 1130,
      [isNotLargeScreenDesktop]: {
        flexDirection: 'column',
      },
      alignItems: 'center',
    },
    image: {
      zIndex: 105,
      alignSelf: 'flex-start',
      borderRadius: `0 ${theme.spacing(4)}px`,
      objectFit: 'cover',
      height: 627,
      maxWidth: '100%',
      width: 509,
    },
    line: {
      width: 40,
      height: 0,
      marginRight: theme.spacing(1),
      border: `1px solid ${theme.palette.getColor('primary')};`,
    },
    container: {
      alignSelf: 'inherit',
      marginTop: 0,
      [isAtMostTablette]: {
        marginTop: theme.spacing(4),
      },
      [isMidSizeDesktop]: {
        alignSelf: 'flex-end',
      },
    },
    content: ({ color }: UseStyleProps) => ({
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.getColor(color, 'background'),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(8),
      paddingRight: theme.spacing(7),
      paddingLeft: theme.spacing(7),
      maxWidth: 640,
      borderTopLeftRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(6),
      [isMobile]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
      [isAtMostTablette]: {
        maxWidth: '100%',
      },
      [isMidSizeDesktop]: {
        marginTop: -theme.spacing(5),
      },
      [isLargeScreenDesktop]: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    }),
  },
  { name: 'Testimonial' },
)

type TestimonialProps = BoxProps & {
  alt: string
  author?: string
  authorRole?: string
  className?: string
  color?: AnyColor
  content?: string
  headline?: string
  url: string
}

const Testimonial: React.FC<TestimonialProps> = ({
  author,
  className,
  color = 'info',
  authorRole,
  url,
  alt,
  headline,
  content,
  ...props
}) => {
  const classes = useStyle({ color })

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <img src={url} alt={alt} title={alt} className={classes.image} />
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box display='flex' alignItems='center' mb={4}>
            <span className={classes.line} />
            <TagTypography>{headline}</TagTypography>
          </Box>
          <Quote mb={2}>“{content}”</Quote>
          <PBold mb={0}>{author}</PBold>
          <P mb={0}>{authorRole}</P>
        </Box>
      </Box>
    </Box>
  )
}

export { Testimonial }
