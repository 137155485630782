import React from 'react'

import {
  Inline,
  NumberFormat,
  numberFormatter,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
} from '@guiker/react-framework'

import { AssumptionComponentProps as Props } from '../assumption-props'

const decimalPrecision = 6

export const BaseAssumptions: React.FC<Props> = ({ defaultValue, name, schema, readOnly = false }) => {
  const { t } = useTranslation(['common', 'common-investmentAssumption'])
  const tPrefix = 'common-investmentAssumption:base'

  const inputProps = useGetInputProps({ defaultValue, formPrefix: name, schema, tPrefix, readOnly })

  const fields = {
    holdingPeriod: inputProps('holdingPeriod'),
    propertyAppreciationRate: inputProps('propertyAppreciationRate'),
  }

  return (
    <TwoColumnsGridLayout>
      <NumberFormat
        {...fields.holdingPeriod}
        endAdornment={<Inline>{t('common:duration.year')}</Inline>}
        allowNegative={false}
      />
      <NumberFormat
        {...fields.propertyAppreciationRate}
        endAdornment={<Inline>%</Inline>}
        decimalScale={decimalPrecision}
        allowNegative={false}
        formatter={numberFormatter.percentage(decimalPrecision)}
      />
    </TwoColumnsGridLayout>
  )
}
