import React, { useState } from 'react'

import { getLocationService, Location } from '@guiker/base-entity'
import {
  BoxProps,
  Button,
  Chip,
  CLDatePicker as DatePicker,
  CLDropdown as Dropdown,
  Flex,
  isAtMostTablette,
  makeStyles,
  RouterLink,
  theme,
  TwoColumnsGridLayout,
} from '@guiker/react-framework'
import { DateTime, ISO8601Date, toISODate } from '@guiker/shared-framework'

import { getLocalizedCityFromLocation, useT } from '../../hooks'
import { buildSearchListingsUrl } from '../../utils'

const useStyle = makeStyles(
  {
    root: {
      width: '100%',
      maxWidth: 410,
      [isAtMostTablette]: {
        maxWidth: '100%',
      },
    },
  },
  { name: 'SearchListing' },
)

const BedroomCountOptions = ['any', 0, 1, 2, 3, 4, 5]

type SearchListingProps = BoxProps & {
  onLocationChange?: (citySlug: string) => void
  locations: Location[]
  currentLocation: Location
  title?: string
  subheader?: string
}

const SearchListing: React.FC<SearchListingProps> = ({ locations, currentLocation, onLocationChange }) => {
  const classes = useStyle()
  const { tMain, tBase, t } = useT({ screenName: 'search' })
  const [bedroomCount, setBedroomCount] = useState<(typeof BedroomCountOptions)[number]>('any')
  const [moveInDate, setMoveInDate] = useState<ISO8601Date>()

  const searchUrl = buildSearchListingsUrl(currentLocation, { bedroomCount, moveInDate })

  return (
    <Flex flexDirection='column' gap={6} className={classes.root}>
      <Flex flexDirection='column' gap={2}>
        <Dropdown
          fullWidth
          name='location'
          value={currentLocation.city.slug}
          onChange={(event) => onLocationChange(event.target.value)}
          label={tMain('location')}
          options={locations.map((location) => ({
            value: location.city.slug,
            label: getLocalizedCityFromLocation(t, location),
            endAdornment: getLocationService().newCities.includes(location.city.slug) && (
              <Chip color='info'>{tBase('actions.new')}</Chip>
            ),
          }))}
        />
        <TwoColumnsGridLayout>
          <Dropdown
            value={bedroomCount}
            fullWidth
            onChange={(event) => setBedroomCount(event.target.value)}
            label={tMain('size')}
            options={BedroomCountOptions.map((key) => ({
              value: `${key}`,
              label: tMain(`sizes.${key}`),
            }))}
          />
          <DatePicker
            label={tMain('date')}
            value={moveInDate}
            onChange={(value) => setMoveInDate(value)}
            hoverStateBackground={theme.palette.primary.main}
            minDate={toISODate(DateTime.local())}
          />
        </TwoColumnsGridLayout>
      </Flex>
      <RouterLink isExternalLink to={searchUrl}>
        <Button size='large' fullWidth>
          {tMain('cta')}
        </Button>
      </RouterLink>
    </Flex>
  )
}

export { SearchListing }
