import { HttpMethod } from '@guiker/http'

export const routes = {
  handleBackgroundCheckResult: {
    path: `/webhook`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
}
