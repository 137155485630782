import { useContext } from 'react'

import { TakeoverContext } from './TakeoverContextProvider'

export const useTakeoverContext = () => {
  const context = useContext(TakeoverContext)

  if (context === undefined) {
    throw new Error('useTakeoverContext can only be used inside TakeoverContextProvider')
  }

  return context
}
