import React from 'react'

import {
  Collapse,
  DatePicker,
  FormSection3,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { TenantApplication } from '@guiker/tenant-application-shared'

type VehicleContentProps = {
  defaultValue: Partial<TenantApplication>
  readOnly?: boolean
  schema?: yup.ObjectSchema
}

const VehicleContent: React.FC<VehicleContentProps> = ({ defaultValue, schema, readOnly = false }) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])

  const hasVehicle = useWatch<TenantApplication, 'hasVehicle'>({
    name: 'hasVehicle',
    defaultValue: defaultValue?.hasVehicle ?? false,
  })
  const inputProps = useGetInputProps({
    namespaces: 'main-tenantApplication',
    formPrefix: 'vehicle',
    tPrefix: 'vehicle',
    defaultValue,
    schema,
    readOnly,
    style: {
      mb: 0,
      maxWidth: '100%',
    },
  })

  const dependency = {
    hasVehicle,
  }

  return (
    <FormSection3 title={t('main-tenantApplication:vehicle.title')}>
      <TwoColumnsGridLayout perItemGridColumn={{ 1: { md: 2 } }} gap={2}>
        <RadioGroup
          name='hasVehicle'
          defaultValue={defaultValue?.hasVehicle}
          options={[
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
          ]}
          mb={2}
          maxWidth='100%'
          readOnly={readOnly}
          condensed={readOnly}
          label={t('main-tenantApplication:vehicle.hasVehicle')}
        />
        <Collapse in={hasVehicle}>
          <TwoColumnsGridLayout>
            <TextField {...inputProps('brand', { dependency })} />
            <TextField {...inputProps('model', { dependency })} />
            <DatePicker format={'yyyy'} views={['year']} {...inputProps('dateOfIssue')} />
            <TextField {...inputProps('plateNumber')} />
          </TwoColumnsGridLayout>
        </Collapse>
      </TwoColumnsGridLayout>
    </FormSection3>
  )
}

export { VehicleContent }
