import { useConfig } from '@guiker/config-context'
import { AuthApiClient } from '@guiker/user-metadata-shared'
import { v4 as uuid } from '@guiker/uuid'

export const useUserMetadataAuthApiClient = () => {
  const { apiBaseUrl: baseUrl } = useConfig()

  return (accessToken?: string) =>
    AuthApiClient({
      baseUrl,
      accessToken,
      correlatedRequestId: uuid(),
    })
}
