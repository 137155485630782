import { TypeOf, yup } from '@guiker/yup-util'

import { getPayoutConfigs, VERIFICATION_DOCUMENT_TYPES } from '../../../../entity'
import { assetSchema } from './asset'

const assetsSchema = yup.array().of(assetSchema)

export const verificationDocumentSchema = yup
  .object({
    type: yup.string().oneOf(VERIFICATION_DOCUMENT_TYPES).required(),
    comment: yup.string().optional().nullable(),
    assets: assetsSchema.when(['$payoutMethodType', 'type'], {
      is: (payoutMethodType, type) => {
        const documentTypes = getPayoutConfigs(payoutMethodType, { isManualInput: true })?.requiredDocumentTypes
        return documentTypes && documentTypes.includes(type)
      },
      then: assetsSchema.min(1).required(),
      otherwise: assetsSchema.optional().default([]),
    }),
  })
  .required()

export type VerificationDocumentSchema = TypeOf<typeof verificationDocumentSchema>
