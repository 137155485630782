import { theme } from '@guiker/react-framework'

export const styles = {
  focused: {
    borderColor: theme.palette.grey[50],
    color: theme.palette.grey[50],
  },
  reject: {
    borderColor: theme.palette.status.alert.hover,
    color: theme.palette.status.alert.hover,
  },
}
