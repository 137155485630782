import React, { useEffect } from 'react'

import { useInvestmentAssumptionContext } from '@guiker/investment-assumption-components'
import { mainPathBuilder } from '@guiker/investment-shared'
import { PropertySaleListing, SaleStatus } from '@guiker/property-sale-shared'
import {
  Button,
  ButtonWithLoader,
  Flex,
  H2,
  H4,
  InquiryCard,
  PBold,
  PSmall,
  RouterLink,
  useModal,
  useScreenSize,
  useTranslation,
} from '@guiker/react-framework'
import { money } from '@guiker/shared-framework'

import { FormContent } from './FormContent'
import { PropertySaleInquirySuccessCard } from './PropertySaleInquirySuccessCard'

type Props = {
  isLoading: boolean
  isSuccess: boolean
  listing: PropertySaleListing
  displayName: string
}

const InquiryCardtitle: React.FC = () => {
  const { t } = useTranslation(['common-propertySale'])
  const { isMd } = useScreenSize()
  const { investmentAssumptions, currency } = useInvestmentAssumptionContext()
  const Header = isMd ? H2 : H4

  return (
    <Flex flexDirection='column' mb={isMd ? 4 : 2} gap={1}>
      <Flex alignItems='baseline' gap={2} justifyContent='space-between'>
        <PBold mb={0}>{t('listing.assumptions.purchase.amount')}</PBold>
        <Header mb={0}>{`${money
          .fromAmount(investmentAssumptions.purchase.price.amount, currency)
          .toString(true, true)}`}</Header>
      </Flex>
    </Flex>
  )
}

export const PropertySaleInquiryCard: React.FC<Props> = ({ isLoading, isSuccess, displayName, listing }) => {
  const { t } = useTranslation(['main-propertySale'])
  const isSold = listing.propertySale?.saleStatus === SaleStatus.SOLD

  const { investmentResults, investmentAssumptions, currency } = useInvestmentAssumptionContext()

  const { isOpen: isInquiryModalOpen, openModal, closeModal } = useModal()
  const {
    isOpen: isInquirySuccessModalOpen,
    openModal: openInquirySuccessModal,
    closeModal: closeInquirySuccessModal,
  } = useModal()

  useEffect(() => {
    isSuccess && closeModal()
    isSuccess && openInquirySuccessModal()
  }, [isSuccess])

  return (
    <>
      <InquiryCard
        isSwipable={!isSold}
        drawerBleeding={isSold ? 136 : 260}
        buttonLabel={isSold ? t('components.inquiryCard.soldCTA') : t('components.inquiryCard.cta')}
        title={isSold ? <></> : <InquiryCardtitle />}
        open={isInquiryModalOpen}
        onOpen={openModal}
        onClose={closeModal}
        isLoading={isLoading}
        SubmitButton={(props) =>
          isSold ? (
            <RouterLink to={mainPathBuilder.root.propertySale.path} {...(props as any)}>
              <Button {...props}>{t('components.inquiryCard.soldCTA')}</Button>
            </RouterLink>
          ) : (
            <ButtonWithLoader type='submit' {...props} />
          )
        }
      >
        {isSold ? (
          <Flex flexDirection='column' alignItems='center' mb={3}>
            <PSmall mb={0} textAlign='center'>
              {t('components.inquiryCard.soldLabel')}
            </PSmall>
          </Flex>
        ) : (
          <FormContent
            purchasePrice={investmentAssumptions.purchase.price.amount}
            inquiryResults={investmentResults}
            currency={currency}
            isSuccess={isSuccess}
          />
        )}
      </InquiryCard>
      <PropertySaleInquirySuccessCard
        isOpen={isInquirySuccessModalOpen}
        onClose={closeInquirySuccessModal}
        displayName={displayName}
      />
    </>
  )
}
