import { HttpMethod } from '@guiker/http'

import { Quebec } from '../../../entity'
import { leaseIdNested } from '../../lease-id-nested'
import {
  Guarantor,
  guarantor,
  InvitedLessorSignatureAndInitials,
  invitedLessorSignatureAndInitials,
} from '../../schemas/quebec'
import { paths } from './paths'

export const QUEBEC_LEASE_INFORMATION_WITH_ID_PATH = `${paths.quebecLease.one.withId(':leaseId')}/lease-information`

const responseValidator = (payload: unknown) => Promise.resolve(payload as Quebec.Lease)

export const routes = {
  updateQuebecLeaseGuarantor: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/guarantor`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<Guarantor> => guarantor.validate(payload),
  },
  updateQuebecLeaseInvitedLessorSignatureAndInitials: {
    path: `${QUEBEC_LEASE_INFORMATION_WITH_ID_PATH}/invited-lessor-signature-and-initials`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => leaseIdNested.validate(payload),
    payloadValidator: (payload: unknown): Promise<InvitedLessorSignatureAndInitials> =>
      invitedLessorSignatureAndInitials.validate(payload),
  },
}
