import { User } from '@guiker/base-entity'
import { CurrencyISO } from '@guiker/money'

export const maxDepositAmount = {
  [CurrencyISO.CAD]: 2000000,
  [CurrencyISO.USD]: 0,
} as const

export type FundingAccount = {
  id: string
  userId: string
  currency: CurrencyISO
  balance?: number
}

export type WithUser<T> = T & {
  user: User
}

export type WithFundingAccount<T> = T & {
  fundingAccount: FundingAccount
}

export type FundingAccountJournalEntry = {
  id: string
  fundingAccountId: string
  action: 'debit' | 'credit'
  amount: number
  metadata: {
    source: FundingAccountJournalEntrySource
  }
  idempotencyKey: string
}

export type FundingAccountJournalEntrySource = {
  entity: 'FundingAccountDeposit' | 'FundingAccountWithdrawal'
  id: string
}

export type FundingAccountBalance = {
  deposit: number
  withdrawal: number
  balance: number
  date: string
}
