import React, { useMemo } from 'react'

import { AuthType } from '@guiker/authentication-context'
import { ConfirmDialog } from '@guiker/components-library'
import { selectFromMap } from '@guiker/lodash'

import { useTranslation } from '../../i18n'

type Props = {
  authType: AuthType
  open: boolean
  onClose: () => void
  emailAddressRef?: React.MutableRefObject<string>
}

export const AuthenticationSuccessModal: React.FC<Props> = ({ authType, open, onClose, emailAddressRef }) => {
  const { t } = useTranslation(['authentication'])

  const props = useMemo(() => {
    const selectProps = selectFromMap({
      [AuthType.SIGN_UP]: {
        title: t('authentication:signupSuccess.modalTitle'),
        description: t('authentication:signupSuccess.description', { emailAddress: emailAddressRef.current }),
        children: <></>,
        onClose: () => {
          emailAddressRef.current = ''
          onClose()
        },
      },
      [AuthType.ACTIVATE]: {
        title: t('authentication:activationSuccess.modalTitle'),
        description: t('authentication:activationSuccess.description'),
        confirmButtonProps: {
          children: t('authentication:activationSuccess.cta'),
          onClick: onClose,
        },
      },
      [AuthType.LOGIN]: {},
      default: {},
    })
    return selectProps(authType)
  }, [authType, emailAddressRef.current])

  return <ConfirmDialog open={open} onClose={onClose} {...props} />
}
