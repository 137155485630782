import React from 'react'

import { Checkbox, Flex, FormSection3, useTranslation } from '@guiker/react-framework'
import { useTenantPreferenceContext } from '@guiker/tenant-preference-context'
import { TenantPreference } from '@guiker/tenant-preference-shared'

import { Appliances, BuildingPreferences, LeasePreferences, PetsAllowed, Utilities } from './components'

type TenantPrefenceFormProps = {
  tenantPreference: Omit<TenantPreference, 'id' | 'userId'>
}

export const TenantPreferenceForm: React.FC<TenantPrefenceFormProps> = ({ tenantPreference }) => {
  const { t } = useTranslation(['main-tenantPreference', 'common-tenantPreference'])
  const baseName = 'tenantPreference'
  const { isAdmin } = useTenantPreferenceContext()

  return (
    <Flex flexDirection='column' gap={3}>
      <Checkbox
        defaultValue={tenantPreference?.emailNotificationEnabled}
        name={`${baseName}.emailNotificationEnabled`}
        label={t('main-tenantPreference:modal.emailNotificationEnabled')}
        maxWidth={458}
      />
      <FormSection3 title={t(`common-tenantPreference:leasePreferences.${isAdmin ? 'admin' : 'main'}Title`)}>
        <LeasePreferences defaultValue={tenantPreference?.leasePreferences} name={`${baseName}.leasePreferences`} />
      </FormSection3>
      <FormSection3 title={t('common-tenantPreference:unitPreferences.title')}>
        <Utilities
          defaultValue={tenantPreference?.unitPreferences?.utilities}
          name={`${baseName}.unitPreferences.utilities`}
        />
        <Appliances
          defaultValue={tenantPreference?.unitPreferences?.appliances}
          name={`${baseName}.unitPreferences.appliances`}
        />
        <PetsAllowed
          defaultValue={tenantPreference?.unitPreferences?.petsAllowed ?? true}
          name={`${baseName}.unitPreferences.petsAllowed`}
        />
        <BuildingPreferences
          defaultValue={tenantPreference?.buildingPreferences}
          name={`${baseName}.buildingPreferences`}
        />
      </FormSection3>
    </Flex>
  )
}
