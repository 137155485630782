import React from 'react'

import { PageLayout, PBig } from '../../components'
import { useTranslation } from '../../i18n'

const ForgotPasswordSuccess: React.FC = () => {
  const { t } = useTranslation('webapp')

  return (
    <PageLayout maxWidth={900 as const} title={t('forgotPassword.success.title')}>
      <PBig>{t('forgotPassword.success.description')}</PBig>
    </PageLayout>
  )
}

export { ForgotPasswordSuccess }
