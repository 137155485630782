import { PayInMethod } from '../entity'
import { ACH_TRANSACTION_RATE, CREDIT_CARD_TRANSACTION_RATE, PayInMethodType } from '../entity'

export const getTransactionFeeRate = (payInMethodType: PayInMethodType) => {
  switch (payInMethodType) {
    case PayInMethodType.CREDIT_CARD:
      return CREDIT_CARD_TRANSACTION_RATE
    case PayInMethodType.DIRECT_DEBIT_ACH:
      return ACH_TRANSACTION_RATE
    default:
      return 0
  }
}

export const getPaymentHelpers = (paymentMethod?: PayInMethod) => {
  const fees = getTransactionFeeRate(paymentMethod?.type)
  const hasFees = fees !== 0

  return {
    fees,
    hasFees,
  }
}
