import React from 'react'

import { Flex, H1, PBig, Switch, theme } from '../../../components'
import { useMediaQuery, useTranslation } from '../../../hooks'
import { useServicesScreenContext } from '../context'
import { Package } from './Package'
import { packageTiers, PackageType } from './PackageEntity'

export const PackagesSection: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const { packageType, setPackageType } = useServicesScreenContext()
  const isTablette = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOnClick = () => {
    const newPackageType = packageType === PackageType.LANDLORD ? PackageType.AGENT : PackageType.LANDLORD
    setPackageType(newPackageType)
  }

  return (
    <Flex fullWidth flexDirection='column' alignItems='center' py={10} id={'PackageSection'}>
      <H1 mb={3} mx={2} textAlign='center'>
        {t('services.packages.header')}
      </H1>
      <PBig>{t('services.packages.subheader')}</PBig>
      <Switch
        checked={packageType === PackageType.LANDLORD}
        onChange={handleOnClick}
        options={[
          {
            label: t('services.packages.AGENT.name'),
            value: PackageType.AGENT,
          },
          {
            label: t('services.packages.LANDLORD.name'),
            value: PackageType.LANDLORD,
          },
        ]}
      />
      <Flex flexDirection={isTablette ? 'column' : 'row'} py={10} gap={3}>
        {packageTiers.map((tier) => (
          <Package key={tier} tier={tier} type={packageType} />
        ))}
      </Flex>
    </Flex>
  )
}
