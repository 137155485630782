import { User } from '@guiker/base-entity'

import { Collaborator, Lease } from '../'

export const transformUserToCollaborator = (lease: Lease, user: User, type: Collaborator['type']) => {
  lease.collaborators.push({
    userId: user.id,
    emailAddress: user.emailAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    type,
  })
}
