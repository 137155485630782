import { generateEventsDefinition, Unpacked, ValueOfEventTypes } from '@guiker/shared-framework'

import { Applicant, BookingPayment, ContributionPayInMethodType } from '../../entity'
import { BaseBookingEvent } from '../base-event'

type ApplicantUserIdEventData = {
  applicantUserId: string
}

export const definition = generateEventsDefinition({
  ...BaseBookingEvent,
  entity: 'PAYMENT',
  eventTypes: {
    INITIAL_STATE: 'INITIAL_STATE',
    CREATED: 'CREATED',
    CONTRIBUTION_INCOMPLETE: 'CONTRIBUTION_INCOMPLETE',
    CONTRIBUTION_RESPLIT_COMPLETED: 'CONTRIBUTION_RESPLIT_COMPLETED',
    PENDING_PAY_IN_METHOD_VERIFICATION: {
      type: 'PENDING_PAY_IN_METHOD_VERIFICATION',
      eventData: null as ApplicantUserIdEventData,
    },
    PROCESSING_PAY_IN_METHOD_VERIFICATION: 'PROCESSING_PAY_IN_METHOD_VERIFICATION',
    PAY_IN_METHOD_REJECTED: {
      type: 'PAY_IN_METHOD_REJECTED',
      eventData: null as ApplicantUserIdEventData,
    },
    PAY_IN_METHOD_VERIFIED: {
      type: 'PAY_IN_METHOD_VERIFIED',
      eventData: null as ApplicantUserIdEventData,
    },
    COMPLETED: 'COMPLETED',
    PAY_IN_METHOD_WAIVED: {
      type: 'PAY_IN_METHOD_WAIVED',
      eventData: null as ApplicantUserIdEventData,
    },
    CHARGE_FAILED: {
      type: 'CHARGE_FAILED',
      eventData: null as {
        applicant: Applicant
        instalment: Unpacked<BookingPayment['instalments']>
      },
    },
    RENTAL_PAYOUT_METHOD_CHANGED: {
      type: 'RENTAL_PAYOUT_METHOD_CHANGED',
      eventData: null as {
        payoutMethodId: string
        receiver: {
          userId: string
          firstName: string
          lastName?: string
          emailAddress: string
        }
      },
    },
    EFT_RECONNECTED: {
      type: 'EFT_RECONNECTED',
      eventData: null as ApplicantUserIdEventData & {
        payInMethod: {
          id: string
          type: ContributionPayInMethodType.DIRECT_DEBIT_PAD
        }
      },
    },
  } as const,
})

export const { events: Events, eventTypes: EventTypes } = definition
export type EventTypes = ValueOfEventTypes<typeof definition>
