import React, { createContext, useState } from 'react'

import { ConductFor, CountryCode } from '@guiker/background-check-shared'
import { TenantApplication } from '@guiker/tenant-application-shared'

type RequestBackgroundCheckMethod = (payload: {
  conductCheckFor: ConductFor
  countryCode?: CountryCode
}) => Promise<void>

type Context = {
  conductCheckFor: ConductFor
  setConductCheckFor: (conductCheckFor: ConductFor) => void
  canViewBackgroundCheck: boolean
  editRoutePath: string
  setEditRoutePath: (path: string) => void
  openBackgroundCheckModal: boolean
  handleBackgroundCheckModal: () => void
  requestBackgroundCheck: RequestBackgroundCheckMethod
  setCanViewBackgroundCheck: (canViewBackgroundCheck: boolean) => void
  setRequestBackgroundCheck: (method: () => RequestBackgroundCheckMethod) => void
  isApplicationApproved: boolean
  setIsApplicationApproved: React.Dispatch<React.SetStateAction<boolean>>
}

export const ApplicationComponentsContext = createContext<Context>(undefined)

type ApplicationComponentsContextProviderProps = React.PropsWithChildren & {
  queryKey: string
  bookingId?: string
  tenantApplication?: TenantApplication
}

export const ApplicationComponentsContextProvider: React.FC<ApplicationComponentsContextProviderProps> = ({
  children,
}) => {
  const [editRoutePath, setEditRoutePath] = useState<string>('')
  const [openBackgroundCheckModal, setOpenBackgroundCheckModal] = useState(false)
  const [conductCheckFor, setConductCheckFor] = useState<ConductFor>('applicant')
  const [canViewBackgroundCheck, setCanViewBackgroundCheck] = useState<boolean>(true)

  const [requestBackgroundCheck, setRequestBackgroundCheck] = useState<RequestBackgroundCheckMethod>(null)
  const [isApplicationApproved, setIsApplicationApproved] = useState<boolean>(null)

  const handleBackgroundCheckModal = () => setOpenBackgroundCheckModal(!openBackgroundCheckModal)

  const value = {
    conductCheckFor,
    canViewBackgroundCheck,
    setConductCheckFor,
    editRoutePath,
    setEditRoutePath,
    isApplicationApproved,
    setIsApplicationApproved,
    openBackgroundCheckModal,
    handleBackgroundCheckModal,
    requestBackgroundCheck,
    setCanViewBackgroundCheck,
    setRequestBackgroundCheck,
  }

  return <ApplicationComponentsContext.Provider value={value}>{children}</ApplicationComponentsContext.Provider>
}
