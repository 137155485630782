import { UnitType } from '@guiker/real-estate-shared'
import { ValueOf } from '@guiker/ts-utils'

import { OperationConfiguration } from './operation'
import { PurchaseConfiguration } from './purchase'

export enum EstimatedSalePriceCalculationMethod {
  APPRECIATION_RATE = 'APPRECIATION_RATE',
  CAP_RATE = 'CAP_RATE',
}

export type BaseAssumptions = {
  estimatedSalePriceCalculationMethod: EstimatedSalePriceCalculationMethod
  holdingPeriod: number
  npvDiscountRate: number
  assessedValue: number
  propertyAppreciationRate?: number
  capRate?: number
}

export type Occupancy = {
  unit: string
  type: UnitType
  monthlyRent: number
  isOccupied: boolean
  period?: {
    from?: string
    to?: string
  }
}

export type Factors = {
  rentalGrowthRate: number // +- for rate
  vacancyRate: number // +-
}

export type RevenueAssumptions = {
  isEstimatedRevenue: boolean
  occupancies?: Occupancy[]
  rentalRevenue: number
  rentalGrowthRate: number
  vacancyRate: number
  factors: Factors
}

export type Mortgage = {
  loanAmount: number
  minimumDownPaymentPercentage: number
  termsInYears: number
  interestRate: number // {percentage}  0.05 for 5 percent
}

export type FinancingAssumptions = {
  mortgage: Mortgage
}

export type PurchaseAssumptions = ValueOf<PurchaseConfiguration>

export type OperationAssumptions = ValueOf<typeof OperationConfiguration>

export type Assumptions = {
  base: BaseAssumptions
  revenue: RevenueAssumptions
  purchase: PurchaseAssumptions
  financing: FinancingAssumptions
  operation: OperationAssumptions
}
