import { User as BaseAuthenticationUser } from '@guiker/authentication-shared'
import { UserMetadata } from '@guiker/user-metadata-shared'

export enum AuthType {
  LOGIN = 'login',
  SIGN_UP = 'signup',
  ACTIVATE = 'activation',
}

export type AuthError = 'activation.accountMismatch'

export type SetUserOptions = {
  type?: AuthType
}

export type ContextUser = BaseAuthenticationUser & {
  metadata?: Omit<UserMetadata, 'userId' | 'id'>
}
