import { routes as scopeNestedRoutes } from './:scopeType'
import { routes as payInMethodNestedRoutes } from './pay-in-methods'
import { routes as rentChargeRoutes } from './rent-charge'
import { routes as rentPaymentsPlanRoutes } from './rent-payments-plan'
import { routes as tenantInstalments } from './tenant-instalment'

export const routes = {
  ...payInMethodNestedRoutes,
  ...scopeNestedRoutes,
  ...rentChargeRoutes,
  ...rentPaymentsPlanRoutes,
  ...tenantInstalments,
}
