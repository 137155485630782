export type Geocoordinates = {
  lat: number
  lng: number
}

export type Geobox = {
  bottomLeft: Geocoordinates
  topRight: Geocoordinates
}

export enum GeoJSONType {
  POINT = 'Point',
}

export type GeoSpatialLocation = {
  type: GeoJSONType
  coordinates: [Number, Number] //[longitude, latitude] opposite of google
}
