import { generateEventsFromEnum } from '@guiker/event'

enum PreAuthorizationPaymentEventTypes {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CAPTURE_REJECTED = 'CAPTURE_REJECTED',
}

const BasePaymentEvent = {
  context: 'PAYMENT',
  data: null as unknown as { bookingId: string; payInMethodId: number },
} as const

export const PreAuthorizationPaymentEvents = generateEventsFromEnum({
  ...BasePaymentEvent,
  entity: 'PRE_AUTHORIZATION',
})(PreAuthorizationPaymentEventTypes)
