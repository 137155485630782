import { HttpMethod } from '@guiker/http'

import { Authenticator, User } from '../../entity'
import { paths } from './paths'
import { activateUser, authenticateWithInvitationToken, logIn, passwordReset, signUp, socialLogIn } from './schemas'

const USER_BASE_PATH = paths.user.all.base()

const authenticatorsResponseValidator = (payload: unknown) => Promise.resolve(payload as Authenticator[])
const userResponseValidator = (res: unknown) => Promise.resolve(res as User)

export const routes = {
  getAllAuthenticators: {
    path: paths.authenticator.all.base(),
    method: HttpMethod.GET,
    authenticated: false,
    responseValidator: authenticatorsResponseValidator,
  },
  activateUser: {
    path: `${USER_BASE_PATH}/activation`,
    method: HttpMethod.PUT,
    authenticated: false,
    responseValidator: userResponseValidator,
    payloadValidator: (payload: unknown) => activateUser.validate(payload),
  },
  logIn: {
    path: `${USER_BASE_PATH}/log-in`,
    method: HttpMethod.POST,
    authenticated: false,
    payloadValidator: (payload: unknown) => logIn.validate(payload),
    responseValidator: userResponseValidator,
  },
  socialLogIn: {
    path: `${USER_BASE_PATH}/social-log-in`,
    method: HttpMethod.POST,
    authenticated: false,
    responseValidator: userResponseValidator,
    payloadValidator: (payload: unknown) => socialLogIn.validate(payload),
  },
  requestPasswordReset: {
    path: `${USER_BASE_PATH}/password-reset`,
    method: HttpMethod.POST,
    authenticated: false,
    payloadValidator: (payload: unknown) => passwordReset.validate(payload),
  },
  signUp: {
    path: USER_BASE_PATH,
    method: HttpMethod.POST,
    authenticated: false,
    responseValidator: userResponseValidator,
    payloadValidator: (payload: unknown) => signUp.validate(payload),
  },
  authenticateWithInvitationToken: {
    path: `${USER_BASE_PATH}/authenticate-with-invitation-token`,
    method: HttpMethod.POST,
    authenticated: false,
    responseValidator: userResponseValidator,
    payloadValidator: (payload: unknown) => authenticateWithInvitationToken.validate(payload),
  },
}
