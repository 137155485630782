import React from 'react'

import { AlertIcon, Box, CheckmarkSmallIcon, clsx, makeStyles } from '@guiker/react-framework'

type StepIconProps = {
  checked: boolean
  disabled?: boolean
  failed?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      alignItems: 'center',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      height: 13,
      width: 13,
      padding: 2,
    },
    disabled: {
      border: `1px solid ${theme.palette.grey[10]}`,
      backgroundColor: theme.palette.grey[10],
    },
    checked: {
      backgroundColor: theme.palette.status.success.main,
      border: `1px solid ${theme.palette.status.success.main}`,
    },
    unchecked: {
      border: `1px solid ${theme.palette.grey[30]}`,
      backgroundColor: theme.palette.grey[30],
    },
  }),
  { name: 'StepIcon' },
)

const StepIcon: React.FC<StepIconProps> = (props) => {
  const { disabled, checked, failed = false } = props
  const classes = useStyles(props)

  if (!disabled && failed) {
    return <AlertIcon size='smallest' color='alert' />
  }

  return (
    <Box
      className={clsx(classes.container, {
        [classes.checked]: !disabled && checked,
        [classes.unchecked]: !disabled && !checked,
        [classes.disabled]: disabled,
      })}
    >
      {checked && <CheckmarkSmallIcon size='smallest' color='white' />}
    </Box>
  )
}

export { StepIcon }
