import { buildQueueDefinition } from '@guiker/queue'

import { config } from '../config'
import { RentChargeable, TenantInstalmentWithRentPaymentsPlan } from '../entity'

export const Queue = {
  ProcessRentChargeReminder: buildQueueDefinition({
    name: 'ProcessRentChargeReminder',
    serviceName: config.apiName,
    payload: null as TenantInstalmentWithRentPaymentsPlan[],
    batchSize: 1,
  }),
  ProcessRentChargeablePerReceiver: buildQueueDefinition({
    name: 'ProcessRentChargeablePerReceiver',
    serviceName: config.apiName,
    payload: null as RentChargeable[],
    batchSize: 1,
  }),
}
