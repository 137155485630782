export type QueueDefinition<P extends Record<string, any>> = {
  name: string
  serviceName: string
  payload: P
  isFifo: boolean
  batchSize: number
}

export const buildQueueDefinition = <SN extends string, P extends Record<string, any>>({
  name,
  serviceName,
  payload,
  isFifo = false,
  batchSize = 10,
}: {
  name: string
  serviceName: SN
  payload: P
  isFifo?: boolean
  batchSize?: number
}): QueueDefinition<P> => ({
  name,
  serviceName,
  payload,
  isFifo,
  batchSize,
})
