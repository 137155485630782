import { HttpMethod } from '@guiker/http'
import { DeepPartial } from '@guiker/ts-utils'

import { Envelope } from '../../entity'

const BASE_PATH = '/webhook'

export const routes = {
  handleDocusignWebhookEvent: {
    path: `${BASE_PATH}/docusign`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as DeepPartial<Envelope>),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as any),
  },
}
