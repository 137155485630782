import { CurrencyISO, Payment, yup } from '@guiker/shared-framework'

import {
  InvoicePayoutMethod,
  Item,
  PayoutMethodStatus,
  PayoutProviderName,
  PayoutProviderStatus,
  Receiver,
} from '../../../../entity'

export const itemSchema = yup.object<
  {
    taxes?: { taxId: string }[]
  } & Omit<Item, 'taxes'>
>({
  label: yup.string().required(),
  description: yup.string().nullable(),
  quantity: yup.number().integer().positive().required().default(1),
  pricePerUnit: yup.number().integer().required(),
  taxes: yup
    .array()
    .of(
      yup.object({
        taxId: yup.string().required(),
      }),
    )
    .default([]),
})

export const customerSchema = yup
  .object({
    id: yup.string().required(),
    emailAddress: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().notRequired().nullable(),
    phoneNumber: yup.string().notRequired().nullable(),
  })
  .noUnknown()

export const payoutProviderSchema = yup.object({
  name: yup.mixed<PayoutProviderName>().oneOf(Object.values(PayoutProviderName)).required(),
  status: yup
    .mixed<PayoutProviderStatus>()
    .oneOf(['CREATED', 'PENDING_APPROVAL', 'NEED_MORE_INFORMATION', 'ENABLED', 'REJECTED', 'TERMINATED'])
    .required(),
  accountId: yup.string().required(),
  details: yup.mixed(),
})

const payoutMethodSchema = yup.object<InvoicePayoutMethod>({
  id: yup.string().required(),
  userId: yup.string().required(),
  currency: yup.mixed().oneOf(Object.values(CurrencyISO)).required(),
  status: yup.mixed().oneOf(Object.values(PayoutMethodStatus)),
  provider: yup.array().of(payoutProviderSchema).required(),
  type: yup.mixed().oneOf(Object.values(Payment.SupportedPayoutMethodType)),
  accountHolderType: yup.mixed().oneOf(Object.values(Payment.SupportedAccountHolderType)),
})

const taxBreakdownSchema = yup.object({
  accountId: yup.string(),
  amount: yup.number(),
  type: yup.string(),
  percentage: yup.number(),
})

export const receiverSchema = yup.object<Receiver>({
  userId: yup.string().required(),
  payoutMethod: payoutMethodSchema,
  transfer: yup.number().required(),
  details: yup
    .object({
      feeAmount: yup.number().nullable(),
      taxBreakdown: yup.array().of(taxBreakdownSchema).nullable(),
    })
    .nullable(),
})
