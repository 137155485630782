import React, { useState } from 'react'

import { Modal, Thumbnail } from '@guiker/components-library'
import { Carousel } from '@guiker/react-carousel'
import { RentalListing } from '@guiker/rental-listing-shared'

export type Props = {
  pictures: RentalListing['pictures']
  open: boolean
  closeModal: () => void
}

const ListingPictureCarouselModal: React.FC<Props> = ({ open, closeModal, pictures }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <Modal
      open={open}
      fullScreen={true}
      onClose={closeModal}
      title={`${(currentIndex % pictures.length) + 1} / ${pictures.length}`}
    >
      <Carousel
        items={pictures.map((pic, index) => (
          <Thumbnail key={index} src={pic.url}></Thumbnail>
        ))}
        onChange={(index) => setCurrentIndex(index)}
      />
    </Modal>
  )
}

export { ListingPictureCarouselModal }
