import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { PropSharingListing } from '../../entity'
import { baseEvent } from '../base'

const basePropSharingListingEvent = {
  ...baseEvent,
  entity: 'FRACTIONAL_OWNERSHIP_LISTING',
  data: null as unknown as PropSharingListing,
}

export enum PropSharingListingEventTypes {
  DISPLAY_INFORMATION_UPDATED = 'DISPLAY_INFORMATION_UPDATED',
  PROPERTY_UPDATED = 'PROPERTY_UPDATED',
  FINANCIAL_ASSUMPTIONS_UPDATED = 'FINANCIAL_ASSUMPTIONS_UPDATED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export const PropSharingListingEventsDefinition = generateEventsDefinition({
  ...basePropSharingListingEvent,
  eventTypes: PropSharingListingEventTypes,
})

export const PropSharingListingEvents =
  generateEventsFromEnum(basePropSharingListingEvent)(PropSharingListingEventTypes)
