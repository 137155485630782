import { AccreditedReason, EligibleReason, Financial, HighLowScale, InvestorTypeKind, TimeScale } from './financial'
import { InvestorProfile } from './investor-profile'

export const areAllInformationCompleted = (investorProfile: InvestorProfile) => {
  return (
    investorProfile.personal &&
    investorProfile.employment &&
    investorProfile.financial &&
    investorProfile.identityVerification
  )
}

const getRiskAppetiteFactor = (riskAppetite?: HighLowScale) => {
  if (!riskAppetite) {
    return { factor: 0, value: 0 }
  }

  return {
    [HighLowScale.VeryHigh]: { factor: 0.25, value: 5 },
    [HighLowScale.High]: { factor: 0.2, value: 4 },
    [HighLowScale.Medium]: { factor: 0.15, value: 3 },
    [HighLowScale.Low]: { factor: 0.1, value: 2 },
    [HighLowScale.VeryLow]: { factor: 0.05, value: 1 },
  }[riskAppetite]
}
const getKnowledgeFactor = (investmentKnowledge?: HighLowScale) => {
  if (!investmentKnowledge) {
    return { factor: 0, value: 0 }
  }

  return {
    [HighLowScale.VeryHigh]: { factor: 1.5, value: 5 },
    [HighLowScale.High]: { factor: 1.33, value: 4 },
    [HighLowScale.Medium]: { factor: 1.0, value: 3 },
    [HighLowScale.Low]: { factor: 0.75, value: 2 },
    [HighLowScale.VeryLow]: { factor: 0.5, value: 1 },
  }[investmentKnowledge]
}
const getExperienceFactor = (investmentExperience?: TimeScale) => {
  if (!investmentExperience) {
    return { factor: 0, value: 0 }
  }

  return {
    [TimeScale.VeryLong]: { factor: 1.5, value: 5 },
    [TimeScale.Long]: { factor: 1.33, value: 4 },
    [TimeScale.Intermediate]: { factor: 1.0, value: 3 },
    [TimeScale.Short]: { factor: 0.5, value: 1 },
  }[investmentExperience]
}

const buildComputationFactors = (financialInfo: Financial) => ({
  riskAppetite: getRiskAppetiteFactor(financialInfo.riskAppetite),
  knowledge: getKnowledgeFactor(financialInfo.investmentKnowledge),
  experience: getExperienceFactor(financialInfo.investmentExperience),
})

const riskRatings = [
  { threshold: 12, value: 5 },
  { threshold: 9, value: 4 },
  { threshold: 7, value: 3 },
  { threshold: 5, value: 2 },
  { threshold: 0, value: 1 },
]

const computeFactor = {
  base: { high: 3, low: 2 },
  limit: { high: 10000, low: 500 },
  cap: { high: 0.25, low: 0.2 },
}

const baseInvestmentLimit = {
  eligible: 10000000,
  none: 1000000,
}

export const computeNetWorth = ({ fixedAssets = 0, liquidAssets = 0, liabilities = 0 }: Financial['balance']) => {
  return fixedAssets + liquidAssets - liabilities
}

export const computeRecommendation = (financialInfo: Financial) => {
  if (!financialInfo) return { limit: 0, riskRating: 0 }
  const factors = buildComputationFactors(financialInfo)
  const { limit, riskScore, cap } = computeResults(financialInfo)
  if (limit === 0) return { limit: 0, riskRating: 0 }

  const baseLimit = Math.min(riskScore, cap, limit ?? 0)
  /** @todo no idea why this numbers are picked for factor */
  const limitFactor = baseLimit > baseInvestmentLimit.eligible ? computeFactor.limit.high : computeFactor.limit.low
  const recommendedLimit = Math.round(baseLimit / limitFactor) * limitFactor

  const riskTotal = Object.values(factors).reduce((total, factor) => total + factor.value, 0)
  const riskRating = riskRatings.find((rating) => riskTotal >= rating.threshold)?.value ?? 1

  return { limit: recommendedLimit, riskRating }
}

export const computeResults = (financialInfo: Financial): Financial['results'] => {
  const { balance, investorType } = financialInfo
  const { kind } = investorType
  const factors = buildComputationFactors(financialInfo)
  const netWorth = computeNetWorth(financialInfo.balance)
  const annualIncome = balance.annualIncome

  const assessedBase = Math.max(
    netWorth,
    annualIncome * (netWorth >= annualIncome ? computeFactor.base.high : computeFactor.base.low),
  )
  const riskScore =
    Math.max(netWorth, annualIncome * (netWorth > annualIncome ? computeFactor.base.high : computeFactor.base.low)) *
    factors.riskAppetite.factor *
    factors.knowledge.factor *
    factors.experience.factor

  const limit =
    kind === InvestorTypeKind.Accredited
      ? riskScore
      : kind === InvestorTypeKind.Eligible
      ? baseInvestmentLimit.eligible
      : baseInvestmentLimit.none
  const cap =
    assessedBase * (investorType.kind === InvestorTypeKind.Accredited ? computeFactor.cap.high : computeFactor.cap.low)

  return { assessedBase, limit, cap, riskScore }
}

export const getInvestorTypeKind = ({
  accredited,
  eligible,
}: {
  accredited: AccreditedReason
  eligible?: EligibleReason
}) => {
  if (accredited !== AccreditedReason.NotApplicable) {
    return InvestorTypeKind.Accredited
  } else if (eligible && eligible !== EligibleReason.NotApplicable) {
    return InvestorTypeKind.Eligible
  } else {
    return InvestorTypeKind.None
  }
}
