import React, { useEffect, useState } from 'react'

import { BookingActions, checkIfChargingProrataFirst, isRoommatable, PaymentCondition } from '@guiker/booking-shared'
import {
  Box,
  Checkbox,
  Collapse,
  CurrencyTextField,
  DatePicker,
  Dropdown,
  FormSection3,
  FormSection4,
  RadioGroup,
  RadioGroupProps,
  TextField,
  useFormContext,
  useTranslation,
  useWatch,
} from '@guiker/react-framework'
import { RentalOptionsDropdown } from '@guiker/rental-option-dropdown'
import { camelCase, currency, CurrencyISO, DateTime, money } from '@guiker/shared-framework'

import { useBookingContext } from '../../../../hooks'
import { useBookingPanelContext } from '../../context'

const UpdateBookingFormContent: React.FC = () => {
  const { t } = useTranslation(['main-booking'])
  const { booking, can } = useBookingContext()
  const { setValue } = useFormContext()

  const { rentalOptions, setRentalOptions, selectedPriceOptionId, setSelectedPriceOptionId } = useBookingPanelContext()
  const { info, optionId, bookingPayment } = booking
  const [hasProrata, setHasProrata] = useState(false)

  const isLeaseSupported = booking.bookingPayment.total.currency === CurrencyISO.CAD

  const canEditBookingSteps = can(BookingActions.EditBookingSteps)
  const canEditBookingDocument = can(BookingActions.EditBookingDocument, { adminOnly: true })
  const canEditBookingPayment = {
    pricing: can(BookingActions.EditPricing),
    prorata: can(BookingActions.EditProrata),
    paymentCondition: can(BookingActions.EditPaymentCondition),
    serviceFee: can(BookingActions.EditServiceFee, { adminOnly: true }),
  }

  const defaultRentalOption = rentalOptions?.find(({ value }) => value === optionId.toString())
  const defaultChargingProrataFirst = checkIfChargingProrataFirst({ bookingPayment })

  const paymentCondition = useWatch({ name: 'hasEnabledStep.payment', defaultValue: booking.hasEnabledStep.payment })
  const periodFrom = useWatch({ name: 'period.from', defaultValue: info.period.from })
  const serviceFeeId = useWatch({
    name: 'serviceFee.legacyId',
    defaultValue: bookingPayment.serviceFee?.legacyId?.toString(),
  })

  const isBookingInfoCustomized = () => {
    if (!defaultRentalOption) {
      return true
    }

    const { price: optionPrice, availablityDate, expirationDate } = defaultRentalOption
    const { price: infoPrice, period } = info

    const amount = money.toAmount(infoPrice.amount, currency[infoPrice.currency]).amount
    return optionPrice.amount !== amount || availablityDate !== period.from || expirationDate !== period.to
  }

  useEffect(() => {
    const rentalOption = rentalOptions?.find((r) => r.value === selectedPriceOptionId)
    if (rentalOption && !isBookingInfoCustomized()) {
      setValue('period.from', rentalOption.availablityDate)
      setValue('period.to', rentalOption.expirationDate)
      setValue('price.amount', rentalOption.price.amount)
      setValue('shouldChargeProrataFirst', false)
    }
  }, [selectedPriceOptionId])

  useEffect(() => {
    if (periodFrom) {
      const shouldProrata = DateTime.fromISO(periodFrom).toUTC().day !== 1
      shouldProrata && setValue('shouldChargeProrataFirst', defaultChargingProrataFirst)
      setHasProrata(shouldProrata)
    }
  }, [periodFrom])

  useEffect(() => {
    if (canEditBookingPayment.serviceFee) {
      const serviceFee = booking.bookingPayment.serviceFees?.find((fee) => {
        return serviceFeeId ? fee.legacyId.toString() === serviceFeeId : fee.default
      })
      !!serviceFee && setValue('serviceFee', serviceFee)
    } else if (!canEditBookingPayment.serviceFee) {
      setValue('serviceFee', bookingPayment.serviceFee)
    }
  }, [serviceFeeId, canEditBookingPayment.serviceFee])

  const steps = [
    { step: 'application', defaultValue: booking.hasEnabledStep.application || false },
    ...(isRoommatable(booking.listing)
      ? [{ step: 'roommate', defaultValue: booking.hasEnabledStep.roommate || false }]
      : []),
    ...(canEditBookingDocument ? [{ step: 'document', defaultValue: booking.hasEnabledStep.document || false }] : []),
    { step: 'payment', defaultValue: booking.hasEnabledStep.payment || false },
    ...(isLeaseSupported ? [{ step: 'lease', defaultValue: booking.hasEnabledStep.lease || false }] : []),
  ]

  const paymentConditions: RadioGroupProps['options'] = [
    {
      value: PaymentCondition.CHARGE_NOW,
      label: t('updateBookingInfoModal.payment.option1'),
      description: t('updateBookingInfoModal.payment.option1Tip'),
    },
    {
      value: PaymentCondition.CHARGE_ON_MOVE_IN_DATE,
      label: t('updateBookingInfoModal.payment.option2'),
      description: t('updateBookingInfoModal.payment.option2Tip'),
    },
  ]

  return (
    <FormSection3>
      <FormSection4
        title={t('updateBookingInfoModal.rentalOptions.title')}
        subtitle={t('updateBookingInfoModal.rentalOptions.description')}
      >
        <RentalOptionsDropdown
          name='optionId'
          defaultValue={selectedPriceOptionId || defaultRentalOption?.value}
          maxWidth={550}
          rentalOptions={rentalOptions}
          setRentalOptions={setRentalOptions}
          setSelectedPriceOptionId={setSelectedPriceOptionId}
        />
      </FormSection4>
      <FormSection4
        title={t('updateBookingInfoModal.customizeInfo.title')}
        subtitle={t('updateBookingInfoModal.customizeInfo.description')}
      >
        <CurrencyTextField
          maxWidth='100%'
          label={t('updateBookingInfoModal.customizeInfo.price')}
          currency={currency[info.price.currency]}
          defaultValue={info.price.amount}
          name='price.amount'
          required
        />
        <DatePicker
          label={t('updateBookingInfoModal.customizeInfo.moveInDate')}
          name='period.from'
          defaultValue={info.period.from}
          autoOk
          fullWidth
        />
        <DatePicker
          label={t('updateBookingInfoModal.customizeInfo.moveOutDate')}
          name='period.to'
          defaultValue={info.period.to}
          autoOk
          fullWidth
        />
        {canEditBookingPayment.serviceFee && bookingPayment.serviceFees?.length > 1 && (
          <>
            <Dropdown
              label={t('updateBookingInfoModal.customizeInfo.serviceFee.label')}
              name='serviceFee.legacyId'
              defaultValue={bookingPayment.serviceFee?.legacyId}
              options={bookingPayment.serviceFees.map((fee) => ({
                value: fee.legacyId.toString(),
                label: t(`updateBookingInfoModal.customizeInfo.serviceFee.${camelCase(fee.type)}`, {
                  value: fee.value,
                }),
              }))}
            />
            <Box display='none'>
              <TextField name='serviceFee.value' type='hidden' />
              <TextField name='serviceFee.type' type='hidden' />
            </Box>
          </>
        )}

        {canEditBookingPayment.prorata && (
          <Box hidden={!hasProrata}>
            <Checkbox
              align='center'
              adornmentPosition='end'
              name='shouldChargeProrataFirst'
              label={t(`updateBookingInfoModal.customizeInfo.shouldChargeProrataFirst`)}
              defaultValue={defaultChargingProrataFirst}
            />
          </Box>
        )}
      </FormSection4>

      {canEditBookingSteps && (
        <FormSection4
          title={t('updateBookingInfoModal.steps.title')}
          subtitle={t('updateBookingInfoModal.steps.description')}
        >
          <div>
            {steps.map(({ step, defaultValue }) => (
              <Checkbox
                key={step}
                align='center'
                name={`hasEnabledStep.${step}`}
                label={t(`updateBookingInfoModal.steps.${step}`)}
                defaultValue={defaultValue}
                description={step === 'payment' ? t('updateBookingInfoModal.steps.paymentTip') : undefined}
              />
            ))}
          </div>
        </FormSection4>
      )}

      {canEditBookingPayment.paymentCondition && (
        <Collapse in={paymentCondition} unmountOnExit={false}>
          <FormSection4 title={t('updateBookingInfoModal.payment.title')}>
            <RadioGroup
              name='condition'
              defaultValue={booking.bookingPayment?.condition}
              options={paymentConditions}
              maxWidth='100%'
            />
          </FormSection4>
        </Collapse>
      )}
    </FormSection3>
  )
}

export { UpdateBookingFormContent }
