import React from 'react'

import { Booking, InvitationStatus, Participant } from '@guiker/booking-shared'
import { Flex, PSmall, useTranslation } from '@guiker/react-framework'

import { calculateSteps } from '../../../../'
import { ApplicantProgressIndicator } from './Applicant/ApplicantProgressIndicator'
import { BaseBox } from './BaseBox'
import { ParticipantAvatar } from './ParticipantAvatar'

type ParticipantBoxProps = {
  booking: Booking
  onClick: () => unknown
  participant: Participant
  showStepStatus?: boolean
}

const ParticipantBox: React.FC<ParticipantBoxProps> = ({ participant, booking, onClick, showStepStatus }) => {
  const { t } = useTranslation(['common', 'main-booking'])
  const { firstName, lastName, avatarUrl, invitation } = participant
  const { enabledSteps } = calculateSteps(booking, participant)
  const firstNameFormatted = firstName.length <= 7 ? firstName : firstName.substring(0, 7) + '...'

  return (
    <BaseBox onClick={onClick}>
      <ParticipantAvatar size='large' firstName={firstName} lastName={lastName} avatarUrl={avatarUrl} />
      <Flex flexDirection='column' justifyContent='center' alignItems='center'>
        <PSmall mb={0}>{firstNameFormatted}</PSmall>
        {invitation?.status === InvitationStatus.INVITED && (
          <PSmall mb={0} color={50}>
            {t('main-booking:actions.pending')}
          </PSmall>
        )}
      </Flex>
      {showStepStatus && invitation.status === InvitationStatus.ACCEPTED && enabledSteps && (
        <ApplicantProgressIndicator steps={enabledSteps} />
      )}
    </BaseBox>
  )
}

export { ParticipantBox }
