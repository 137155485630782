import { TypeOf, yup } from '@guiker/yup-util'

export const representativeSchema = yup.object({
  id: yup.string().required(),
  emailAddress: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  roles: yup.array().of(yup.string()).required(),
})

export type RepresentativeSchema = TypeOf<typeof representativeSchema>
