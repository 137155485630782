import { usePlaidLink as useBasePlaidLink } from 'react-plaid-link'

import { camelizeKeys } from '@guiker/lodash'

type Event = {
  requestId?: string
  linkSessionId?: string
}

type LinkError = {
  errorCode: string
  errorMessage: string
}

type Account = {
  id: string
  mask: string
  name: string
  subtype: string
}

type PlaidLinkArgs = {
  linkToken: string
  onSuccess: (publicToken: string, accounts: Account[]) => void
  onEvent?: (eventName: string, event: Event) => void
  onError?: (errorCode: string, errorMessage: string, event: Event) => void
}

const usePlaidLink = ({ onSuccess, onEvent, onError, linkToken }: PlaidLinkArgs) => {
  const handleOnSuccess = (publicToken: string, metadata: object = {}) => {
    const { accounts } = camelizeKeys<{ accounts?: Account[] & { id: string } }>(metadata)
    onSuccess(publicToken, accounts)
  }

  const handleOnEvent = (eventName: string, rawEvent: object = {}) => {
    const event = camelizeKeys<Event>(rawEvent)

    if (onEvent) {
      onEvent(eventName, event)
    }
  }

  const handleOnExit = (rawError?: object, rawEvent: object = {}) => {
    const event = camelizeKeys<Event>(rawEvent)

    if (rawError && onError) {
      const { errorCode, errorMessage } = camelizeKeys<LinkError>(rawError)
      onError(errorCode, errorMessage, event)
    }
  }

  return useBasePlaidLink({
    token: linkToken,
    onSuccess: handleOnSuccess,
    onEvent: handleOnEvent,
    onExit: handleOnExit,
  })
}

export { usePlaidLink, Account }
