import { PropertyOwnership } from '@guiker/real-estate-shared'
import { DeepPartial } from '@guiker/ts-utils'

import { Representative } from './representative'

export enum SaleStatus {
  LISTED = 'LISTED',
  SOLD = 'SOLD',
}

export type PropertySale = {
  id: string
  propertyOwnership: DeepPartial<PropertyOwnership>
  representatives: Representative[]
  saleStatus: SaleStatus
}
