import React from 'react'

import {
  Collapse,
  Dropdown,
  FormSection3,
  RadioGroup,
  TextField,
  TwoColumnsGridLayout,
  useGetInputProps,
  useT,
  useWatch,
} from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { AnimalType, TenantApplication } from '@guiker/tenant-application-shared'

type PetContentProps = {
  defaultValue: Partial<TenantApplication>
  readOnly?: boolean
  schema?: yup.ObjectSchema
}

const PetContent: React.FC<PetContentProps> = ({ defaultValue, schema, readOnly = false }) => {
  const { t, tMain } = useT({ domain: 'tenantApplication', screenName: 'pets' })

  const hasPets = useWatch<TenantApplication, 'hasPets'>({
    name: 'hasPets',
    defaultValue: defaultValue?.hasPets ?? false,
  })

  const animalType = useWatch<TenantApplication, 'pets.type'>({
    name: 'pets.type',
    defaultValue: defaultValue?.pets?.type,
  })

  const inputProps = useGetInputProps({
    namespaces: ['common', 'main-tenantApplication'],
    formPrefix: 'pets',
    tPrefix: 'main-tenantApplication:pets',
    defaultValue,
    schema,
    readOnly,
  })

  return (
    <FormSection3 title={tMain('title')}>
      <TwoColumnsGridLayout perItemGridColumn={{ 1: { md: 2 } }} gap={2}>
        <RadioGroup
          name='hasPets'
          defaultValue={hasPets}
          options={[
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
          ]}
          readOnly={readOnly}
          condensed={readOnly}
          label={tMain('hasPets')}
        />

        <Collapse in={hasPets}>
          <TwoColumnsGridLayout>
            <Dropdown
              {...inputProps('type')}
              options={Object.values(AnimalType).map((animal) => ({
                label: t(`main-tenantApplication:pets.animals.${animal}`),
                value: animal,
              }))}
              fullWidth
            />
            {animalType === AnimalType.OTHER ? <TextField {...inputProps('other')} maxWidth='100%' /> : <div />}
            <RadioGroup
              {...inputProps('isSupportServiceAnimal')}
              options={[
                { value: true, label: t('common:yes') },
                { value: false, label: t('common:no') },
              ]}
              condensed={readOnly}
            />
            <TextField
              multiline
              rows={readOnly ? undefined : 5}
              placeholder={tMain('additionalInfoHelper')}
              {...inputProps('additionalInfo')}
            />
          </TwoColumnsGridLayout>
        </Collapse>
      </TwoColumnsGridLayout>
    </FormSection3>
  )
}

export { PetContent }
