import React from 'react'

import { Box, Flex, makeStyles, theme } from '@guiker/react-framework'

import { EnabledSteps } from '../../../../../'

type ProgressIndicatorProps = {
  steps: EnabledSteps
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    gap: 2,
  },
  indicator: {
    height: 2,
    width: ({ total }: { total: number }) => `${Math.floor(100 / total)}%`,
    backgroundColor: theme.palette.grey[15],
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
  },
})

const ApplicantProgressIndicator: React.FC<ProgressIndicatorProps> = ({ steps }) => {
  const stepArray = Object.values(steps).filter((step) => step.enabled)
  const classes = useStyles({ total: stepArray.length })

  return (
    <Flex className={classes.container}>
      {stepArray.map((step, index) => (
        <Box key={index} className={`${classes.indicator} ${step.isCompleted ? classes.completed : ''}`} />
      ))}
    </Flex>
  )
}

export { ApplicantProgressIndicator }
