import { addressFormatter as addressUtils, getLocationService } from '@guiker/base-entity'
import { Booking } from '@guiker/booking-shared'
import { routesFromLocation, slugifyListing } from '@guiker/rental-listing-shared'

const getFullListingRoutePath = (listing: { address: Booking['listing']['address']; id: string }) => {
  const { getLocationFromAddress } = getLocationService()
  const location = getLocationFromAddress({
    ...listing.address,
    country: listing.address.countryCode,
  })

  const slug = slugifyListing(listing) ?? listing.id
  return routesFromLocation(location)?.neighbourhoodNested?.withId?.(slug)?.path
}

export { addressUtils, getFullListingRoutePath }
