import React, { useState } from 'react'

import { ConfirmDialog, TextButton, useMutation, useTranslation } from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'

const ApplicantTimerExtension: React.FC = () => {
  const { t } = useTranslation(['common', 'main-booking'])
  const tPrefix = 'main-booking:screens.bookingModule.time'
  const { apiClient, booking, bookingUser, refetchBooking } = useBookingContext()

  const [openModal, setOpenModal] = useState(false)
  const handleModal = () => setOpenModal(!openModal)

  const { mutate: handleRequestTimerExtension, isLoading } = useMutation(
    () => apiClient.requestTimerExtension({ pathParams: { bookingId: booking.id, applicantUserId: bookingUser.id } }),
    {
      onSuccess: () => {
        refetchBooking()
        handleModal()
      },
    },
  )

  return (
    <>
      <div>
        <TextButton size='small' onClick={() => handleModal()}>
          {t('main-booking:actions.time.applicantExtend')}
        </TextButton>
      </div>
      <ConfirmDialog
        open={openModal}
        title={t(`${tPrefix}.applicantExtendModal.title`)}
        description={t(`${tPrefix}.applicantExtendModal.description`)}
        cancelButtonProps={{
          children: t('common:actions.no'),
          onClick: () => handleModal(),
        }}
        confirmButtonProps={{
          children: t('common:actions.yes'),
          isLoading,
          onClick: () => handleRequestTimerExtension(),
        }}
      />
    </>
  )
}

export { ApplicantTimerExtension }
