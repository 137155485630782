import { RentPaymentsPlan } from './rent-payments-plan'
import { TenantInstalment } from './tenant-instalment'

export * from './rent-charge'
export * from './rent-payments-plan'
export * from './tenant-instalment'
export * from './utils'

export type TenantInstalmentWithRentPaymentsPlan = TenantInstalment & { rentPaymentsPlan: RentPaymentsPlan }
export type RentPaymentsPlanWithTenantInstalments = RentPaymentsPlan & { tenantInstalments: TenantInstalment[] }
