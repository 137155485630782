import { TypeOf, yup } from '@guiker/yup-util'

import {
  preApplicationGuarantorSchema,
  preApplicationOccupationSchema,
  preApplicationProfileSchema,
} from '../../schemas'

export const updatePreApplicationPayloadValidator = yup.object({
  hasGuarantor: yup.boolean().required(),
  profile: yup.object().when('hasGuarantor', {
    is: false,
    then: preApplicationProfileSchema.required(),
    otherwise: yup.object().nullable(),
  }),
  occupation: yup.object().when('hasGuarantor', {
    is: false,
    then: preApplicationOccupationSchema.required(),
    otherwise: preApplicationOccupationSchema.nullable(),
  }),
  guarantor: yup.object().when('hasGuarantor', {
    is: true,
    then: preApplicationGuarantorSchema.required(),
    otherwise: preApplicationGuarantorSchema.nullable(),
  }),
})

export type UpdatePreApplicationPayloadValidator = TypeOf<typeof updatePreApplicationPayloadValidator>
