export enum FileMimeType {
  csv = 'text/csv',
  jpg = 'image/jpeg',
  png = 'image/png',
  webp = 'image/webp',
}

export const FileMimeTypeMap = {
  [FileMimeType.csv]: ['.csv'],
  [FileMimeType.jpg]: ['.jpg', '.jpeg'],
  [FileMimeType.webp]: ['.webp'],
  [FileMimeType.png]: ['.png'],
  'image/*': ['.jpg', '.jpeg', '.webp', '.png'],
  'application/pdf': ['.pdf'],
}

export type SupportedFileType = keyof typeof FileMimeTypeMap
