import React from 'react'

import { useT } from '@guiker/i18n'
import { Invoice } from '@guiker/payment-shared'
import { FormSection2, Table, TextButton, useNavigate } from '@guiker/react-framework'

import { getColumns } from '../ViewPaymentMethod'

type Prop = {
  type: 'RENTAL' | 'PAYMENTS'
  invoices: Invoice[]
  invoicesUrl: string
  onTopActionClick?: () => void
}

const TopInvoicesTable: React.FC<Prop> = ({ invoices, type, onTopActionClick, invoicesUrl }) => {
  const { tShared } = useT({ domain: 'payment', entity: 'paymentMethods.history' })
  const navigate = useNavigate()

  if (!invoices?.length) return
  return (
    <FormSection2
      title={tShared(type === 'RENTAL' ? 'rentalTitle' : 'title')}
      topActions={
        onTopActionClick && (
          <TextButton onClick={onTopActionClick}>{tShared(type === 'RENTAL' ? 'rentalAction' : 'action')}</TextButton>
        )
      }
    >
      <Table
        data={invoices}
        columns={getColumns({ withAction: false })}
        onRowClick={(invoice) => navigate(`${invoicesUrl}/${invoice.id}`)}
      />
    </FormSection2>
  )
}

export { TopInvoicesTable }
