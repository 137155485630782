import { TypeOf, yup } from '@guiker/yup-util'

export const updatePropertyBuildingAddressSchema = yup
  .object({
    building: yup
      .object({
        externalId: yup.string().required(),
        address: yup
          .object({
            neighbourhood: yup.string().required(),
            streetNumbers: yup.array().of(yup.string().required()),
            street: yup.string().required(),
            zipcode: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
            country: yup.string().required(),
            cityName: yup.string().nullable(),
          })
          .required(),
        geocoordinates: yup
          .object({
            lat: yup.number().required(),
            lng: yup.number().required(),
          })
          .optional(),
      })
      .strict(true)
      .required(),
  })
  .required()

export type UpdatePropertyBuildingAddressSchema = TypeOf<typeof updatePropertyBuildingAddressSchema>
