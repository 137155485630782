import { generateEventsDefinition, generateEventsFromEnum } from '@guiker/event'

import { LegalProfile } from '../../entity'

const baseEvent = {
  context: 'USER',
  entity: 'LEGAL_PROFILE',
  data: null as unknown as LegalProfile,
} as const

export enum LegalProfileEventTypes {
  EMPLOYMENT_INFORMATION_UPDATED = 'EMPLOYMENT_INFORMATION_UPDATED',
  FINANCIAL_INFORMATION_UPDATED = 'FINANCIAL_INFORMATION_UPDATED',
  IDENTITY_VERIFICATION_INFORMATION_UPDATED = 'IDENTITY_VERIFICATION_INFORMATION_UPDATED',
  IDENTITY_VERIFICATION_KYC_CHECK_FRAUD_DETECTED = 'IDENTITY_VERIFICATION_KYC_CHECK_FRAUD_DETECTED',
  IDENTITY_VERIFICATION_KYC_CHECK_INCONCLUSIVE = 'IDENTITY_VERIFICATION_KYC_CHECK_INCONCLUSIVE',
  IDENTITY_VERIFICATION_KYC_CHECK_VERIFIED = 'IDENTITY_VERIFICATION_KYC_CHECK_VERIFIED',
  IDENTITY_VERIFICATION_KYC_CHECK_UPDATED = 'IDENTITY_VERIFICATION_KYC_CHECK_UPDATED',
  PERSONAL_INFORMATION_UPDATED = 'PERSONAL_INFORMATION_UPDATED',
}

export const LegalProfileEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  eventTypes: LegalProfileEventTypes,
})

export const LegalProfileEvents = generateEventsFromEnum(baseEvent)({
  ...LegalProfileEventTypes,
})
