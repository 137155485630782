import { TypeOf, yup } from '@guiker/yup-util'

import { LabelNames } from '../../../entity'
import { imageSchema } from './common'

export const verifyGovernmentIdSchema = yup
  .object({
    source: imageSchema.required(),
    labels: yup.array(yup.string().oneOf(Object.values(LabelNames))).nullable(),
  })
  .required()

export type VerifyGovernmentIdSchema = TypeOf<typeof verifyGovernmentIdSchema>
