import { yup } from '@guiker/yup-util'

import { password } from './password'

export const socialLogIn = yup
  .object({
    authenticatorId: yup.number().positive().integer().moreThan(0).required(),
    invitationToken: yup.string().optional(),
    password,
  })
  .required()

export type SocialLogInPayload = yup.InferType<typeof socialLogIn>
