import { Accept, DropzoneOptions, DropzoneState, useDropzone as useReactDropzone } from 'react-dropzone'

import { FileMimeTypeMap, SupportedFileType } from './utils'

/**
 * @param maxSize Maximum size per file in mb
 * @param accept [FileMimeType](packages/ui/app/components/dropzone/src/utils.ts)[]
 * */
export type UseDropzoneOptions = Omit<DropzoneOptions, 'accept'> & {
  accept: SupportedFileType | SupportedFileType[]
}

export const useDropzone = (options?: UseDropzoneOptions): DropzoneState => {
  const accept = (Array.isArray(options?.accept) ? options.accept : [options.accept]).reduce((res, fileType) => {
    res[fileType] = FileMimeTypeMap[fileType] ?? []
    return res
  }, {} as Accept)

  return useReactDropzone({
    ...options,
    maxSize: options?.maxSize * 1024 * 1024,
    accept,
  })
}
