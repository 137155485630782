import { TypeOf, yup } from '@guiker/yup-util'

export const extendLeaseTermSchema = yup.object({
  period: yup
    .object({
      from: yup.date().required(),
      to: yup.date().required(),
    })
    .required(),
})

export type ExtendLeaseTermSchema = TypeOf<typeof extendLeaseTermSchema>
