import { FlatValue } from '../../flat-value'

export type Jurisdiction = 'canada'

export const CanadaExpense = {
  jurisdiction: 'canada',
} as const

export const CondoFee = {
  ...CanadaExpense,
  type: 'condoFee',
  frequency: 'monthly',
  value: {
    type: 'flat',
    amount: null,
  } as FlatValue,
} as const
