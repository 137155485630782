import React from 'react'

import { PageNotFound } from '@guiker/error-pages'
import { Outlet, useLocationFromPath } from '@guiker/router'

export const CountryNested: React.FC = () => {
  const { country, countryCode } = useLocationFromPath()

  if (!country && isNaN(parseInt(countryCode.split('-')[0]))) {
    return <PageNotFound />
  }

  return <Outlet />
}

export const CityNested: React.FC = () => {
  const { city } = useLocationFromPath()

  if (!city) {
    return <PageNotFound />
  }

  return <Outlet />
}

export const NeighbourhoodNested: React.FC = () => {
  const { neighbourhood, neighbourhoodSlug, city } = useLocationFromPath()

  if (!neighbourhood && neighbourhoodSlug !== city.defaultNeighbourhood) {
    return <PageNotFound />
  }

  return <Outlet />
}
