import { useState } from 'react'

import { useMutation, useQuery } from '@guiker/react-framework'
import { SearchableEntities, SearchResult } from '@guiker/search-shared'
import { compact, isObjectId, Scope } from '@guiker/shared-framework'

import { ItemGroup, MapperOverride, useSearchResultMapper } from '../utils'

type ApiClient = {
  search: (args: { queryParams: { searchString; entities; scopeType; scopeId } }) => Promise<SearchResult[]>
}

export const getUseSearch = (
  apiClient: ApiClient,
  mapperOverride: MapperOverride,
  { entities, scope }: { entities?: SearchableEntities[]; scope?: Scope } = {},
) => {
  const { id: scopeId, type: scopeType } = { ...scope }
  const useMutationPerformSearch = (searchString: string) => {
    const mapper = useSearchResultMapper(mapperOverride)
    const [data, setData] = useState<ItemGroup[]>()
    let searchEntites = entities ?? []

    if (isObjectId(searchString)) {
      searchEntites = compact([...(entities ?? []), SearchableEntities.BOOKING, SearchableEntities.INVOICE])
    }

    const { isLoading, mutate } = useMutation(
      ['search', searchString, scopeId, scopeType],
      () => apiClient.search({ queryParams: { searchString, entities: searchEntites, scopeType, scopeId } }),
      {
        onSuccess: (data) => setData(mapper(data, searchString)),
      },
    )

    return { data, isLoading, mutate }
  }

  const useQueryPerformSearch = (searchString: string) => {
    const mapper = useSearchResultMapper(mapperOverride)

    return useQuery(
      ['search', searchString, scopeType, scopeId],
      () => apiClient.search({ queryParams: { searchString, entities, scopeId, scopeType } }),
      {
        select: (data) => mapper(data, searchString),
      },
    )
  }

  return {
    useMutationPerformSearch,
    useQueryPerformSearch,
  }
}
