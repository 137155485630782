import { AuthLevel, generateApiPath } from '@guiker/api-definition'

const prefix = AuthLevel.AUTH

const paths = {
  listing: generateApiPath({ prefix: AuthLevel.AUTH, name: 'listing' }),
  inquiry: generateApiPath({ prefix, name: 'inquiry', plural: 'inquiries' }),
  investorProfile: generateApiPath({ prefix, name: 'investor-profile' }),
  stockOrder: generateApiPath({ prefix, name: 'stock-order' }),
  portfolio: generateApiPath({ prefix, name: 'portfolio' }),
}

export { paths }
