import { TypeOf, yup } from '@guiker/yup-util'

import { InvestorsEnquiry } from '../../../entity'

export const investorsEnquirySchema = yup.object<Omit<InvestorsEnquiry, 'enquiryType'> & { recaptchaToken: string }>({
  emailAddress: yup.string().email().required(),
  recaptchaToken: yup.string().required(),
  data: yup.object<InvestorsEnquiry['data']>({
    name: yup.string().required(),
    phoneNumber: yup.string().required(),
    accreditedInvestor: yup.string().oneOf(['yes', 'no', null]).notRequired().nullable(),
    experience: yup.string().oneOf(['beginner', 'intermediate', 'expert', null]).notRequired().nullable(),
    properties: yup.string().nullable(),
    comments: yup.string().nullable(),
  }),
})

export type InvestorsEnquirySchema = TypeOf<typeof investorsEnquirySchema>
