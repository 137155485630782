import { yup } from '@guiker/yup-util'

import { S3AccessControlList } from '../../entity'

export const bulkOperation = yup.array(
  yup.object({
    id: yup.string(),
    _delete: yup.boolean(),
    fileName: yup.string(),
    displayName: yup.string(),
    mimeType: yup.string(),
    size: yup.number(),
    acl: yup.string().oneOf(Object.values(S3AccessControlList)),
  }),
)
