import React from 'react'

import { FundingAccountDeposit, FundingAccountDepositStatus } from '@guiker/payment-shared'
import { Chip, ChipProps, StatusKey } from '@guiker/react-framework'
import { ValueOf } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type DeposiStatusChipProps = Pick<ChipProps, 'size' | 'variant'> & {
  deposit: Pick<FundingAccountDeposit, 'status'>
  withDate?: boolean
}

export const getDepositStatusColor = (deposit: Pick<FundingAccountDeposit, 'status'>): StatusKey => {
  const successStatus: ValueOf<FundingAccountDepositStatus>[] = [
    FundingAccountDepositStatus.PAYMENT_SETTLED,
    FundingAccountDepositStatus.COMPLETED,
  ]

  const errorStatus: ValueOf<FundingAccountDepositStatus>[] = [
    FundingAccountDepositStatus.FAILED,
    FundingAccountDepositStatus.JOURNAL_ENTRY_INSERTION_FAILED,
  ]

  const inProgressStatus: ValueOf<FundingAccountDepositStatus>[] = [FundingAccountDepositStatus.PROCESSING]

  return successStatus.includes(deposit.status)
    ? 'success'
    : errorStatus.includes(deposit.status)
    ? 'alert'
    : inProgressStatus.includes(deposit.status)
    ? 'positive'
    : 'info'
}

export const DepositStatusChip: React.FC<DeposiStatusChipProps> = ({ deposit, size = 'small', variant = 'filled' }) => {
  const { tShared } = useT({ entity: 'fundingAccount.deposit' })
  const color = getDepositStatusColor(deposit)

  return (
    <Chip color={color} size={size} variant={variant}>
      {tShared(`status.options.${deposit.status}`)}
    </Chip>
  )
}
