import { AuthLevel, buildPaths } from '@guiker/api-definition'

export const paths = {
  ...buildPaths(
    {
      ':scopeType': {
        ':scopeId': {
          rentPaymentsPlan: {},
        },
      },
      payInMethod: {
        rentPaymentPlan: {},
      },
      rentPaymentsPlan: {},
      rentCharge: {},
      tenantInstalment: {},
    },
    AuthLevel.ADMIN,
  ),
}
