import { TypeOf, yup } from '@guiker/yup-util'

import { address, initials, signature } from '../../schemas'
import { guarantorWithoutSignature } from './guarantor'

export const lessee = yup.object({
  address: address,
  emailAddress: yup
    .string()
    .email()
    .test('lease.sameAsGuarantor', "Email cannot be the same as guarantor's email", function (value) {
      return !this.parent.hasGuarantor || value !== this.parent.guarantor.emailAddress
    })
    .required(),
  fullName: yup.string().required(),
  phoneNumber: yup.string().required(),
  initials: initials.required(),
  signature: signature.required(),
  dwelling: yup.object({
    smokeDetectorsInitials: initials,
  }),
  liability: yup.object({
    initials: initials,
  }),
  hasGuarantor: yup
    .boolean()
    .required()
    .transform((value) => !!value),
  guarantor: guarantorWithoutSignature.when('hasGuarantor', {
    is: true,
    then: guarantorWithoutSignature.required(),
    otherwise: guarantorWithoutSignature.nullable().transform(() => null),
  }),
})

export type Lessee = TypeOf<typeof lessee>
