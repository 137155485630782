import { TypeOf, yup } from '@guiker/yup-util'

import { assetsSchema } from './shared'

export const upsertDeveloperSchema = yup
  .object({
    name: yup.string().required(),
    description: yup.string(),
    url: yup.string(),
    documents: assetsSchema,
  })
  .required()

export type UpsertDeveloperSchema = TypeOf<typeof upsertDeveloperSchema>
