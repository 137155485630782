import { sourceSchema } from '@guiker/base-listing-shared'
import { DealType } from '@guiker/base-listing-shared'
import { TypeOf, yup } from '@guiker/yup-util'

const assetSchema = yup.object({ id: yup.string().required() })
const assetsSchema = yup.array().of(assetSchema)

const occupancySchema = yup.object({
  isVacant: yup.boolean().default(false).required(),
  endsAt: yup.string().when('isVacant', {
    is: false,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .transform(() => null as null),
  }),
})

const dealSchema = yup.object({
  type: yup.mixed().oneOf(Object.values(DealType)).required(),
  occupancy: yup.object().when('type', {
    is: DealType.RESALE,
    then: occupancySchema.required(),
    otherwise: yup.object().nullable().default(null),
  }),
  deliveryDate: yup.string().when('type', {
    is: DealType.PRECON,
    then: yup.string().required(),
    otherwise: yup.string().nullable().default(null),
  }),
})

export const updateListingSchema = yup
  .object({
    description: yup.string().nullable(),
    pictures: assetsSchema.min(1).required(),
    deal: dealSchema.required(),
    onSaleSince: yup.string().required(),
    documents: assetsSchema,
    source: sourceSchema.optional(),
  })
  .required()

export type UpdateListingSchema = TypeOf<typeof updateListingSchema>
