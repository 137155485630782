import React from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { FloatingBadge, IconButton, Link, makeStyles, Typography } from '@guiker/components-library'
import { ChatIcon, HouseIcon, MyInvestmentsIcon, SearchHomeIcon, UserIcon } from '@guiker/icons'
import { useQuery } from '@guiker/react-query'

import { useConversationApiClient } from '../../hooks'

const getItems = ({ unreadCount }: { unreadCount: number }) => [
  {
    to: '/my-investments',
    icon: <MyInvestmentsIcon />,
    label: 'Invest',
  },
  {
    to: '/myaccount/home',
    isExternalLink: true,
    icon: <HouseIcon />,
    label: 'Home',
  },
  {
    to: '/listings',
    isExternalLink: true,
    icon: <SearchHomeIcon />,
    label: 'Search',
  },
  {
    to: '/conversation',
    isExternalLink: true,
    icon: (
      <IconButton>
        <FloatingBadge badgeNumber={unreadCount}>
          <ChatIcon />
        </FloatingBadge>
      </IconButton>
    ),
    label: 'Chat',
  },
  {
    to: '/myaccount/account/profile',
    isExternalLink: true,
    icon: <UserIcon />,
    label: 'Profile',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[95],
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.grey[90],
  },
  tab: {
    flex: '1 1 0px',
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignSelf: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  tabText: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
  },
}))

export const MobileBottomNavigation: React.FC = () => {
  const classes = useStyles()
  const { user } = useAuthenticationContext()
  const apiClient = useConversationApiClient()

  const { data: conversationStats } = useQuery('readStats', () => apiClient.readStats(), {
    enabled: !!user,
  })

  return (
    <div className={classes.root}>
      {getItems({ unreadCount: conversationStats?.unreadCount }).map((item) => (
        <Link key={item.label} className={classes.tab} to={item.to}>
          {item.icon}
          <Typography className={classes.tabText} textAlign='center' mb={0}>
            {item.label}
          </Typography>
        </Link>
      ))}
    </div>
  )
}
