type Scope = {
  type: string
  id: string
}

// eslint-disable-next-line prettier/prettier
const generateApiPath = <Name extends string, Prefix extends string, Plural extends string = `${Name}s`>({
  name,
  prefix,
  plural = `${name}s` as Plural,
}: {
  name: Name
  prefix: Prefix
  plural?: Plural
}) => {
  const singular = name
  // eslint-disable-next-line prettier/prettier
  const urlPrefix: `/${Prefix}` = (prefix ? (prefix.startsWith('/') ? prefix : `/${prefix}`) : '') as `/${Prefix}`

  const buildScope = <Type extends string = ':scopeType', Id extends string = undefined>(
    type: Type = ':scopeType' as Type,
    id?: Id,
  ): Id extends undefined ? Type : `${Type}/${Id}` => (id ? (`${type}/${id}` as const) : (type as any))
  const buildScopes = (scopes: Scope[]) => scopes.map((scope) => buildScope(scope.type, scope.id)).join('/')

  const buildPaths = <Suffix extends string>(suffix: Suffix) => ({
    base: () => `${urlPrefix}/${suffix}` as const,
    withId: <Id extends string = ':id'>(id: Id = ':id' as Id) => `${urlPrefix}/${suffix}/${id}` as const,
    withScopeType: <Type extends string = ':scopeType'>(type: Type = ':scopeType' as Type) =>
      `${urlPrefix}/${buildScope(type)}/${suffix}` as const,
    withScope: <Type extends string = ':scopeType', Id extends string = ':scopeId'>(
      type: Type = ':scopeType' as Type,
      id: Id = ':scopeId' as Id,
    ) => `${urlPrefix}/${buildScope(type, id)}/${suffix}` as const,
    withScopeAndId: <
      Type extends string = ':scopeType',
      ScopeId extends string = ':scopeId',
      Id extends string = ':id',
    >(
      type: Type = ':scopeType' as Type,
      scopeId: ScopeId = ':scopeId' as ScopeId,
      id: Id = ':id' as Id,
    ) => `${urlPrefix}/${buildScope(type, scopeId)}/${suffix}/${id}` as const,
    withScopes: (scopes: Scope[]) => `${urlPrefix}/${buildScopes(scopes)}/${suffix}` as const,
  })

  return {
    all: buildPaths<Plural>(plural),
    one: buildPaths(singular),
  }
}

export type ApiPathBuilder = ReturnType<typeof generateApiPath>

export { generateApiPath }
