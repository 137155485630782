import { TypeOf, yup } from '@guiker/yup-util'

import { email } from './email'

export const passwordReset = yup
  .object({
    email,
  })
  .required()

export type PasswordReset = TypeOf<typeof passwordReset>
