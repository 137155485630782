import React from 'react'

import { PayInMethodLabel } from '@guiker/payment-app-components'
import { PayInMethod } from '@guiker/payment-shared'
import { ColumnConfig, P, useT } from '@guiker/react-framework'
import { RentPaymentsPlanWithTenantInstalments, TenantInstalmentStatus } from '@guiker/rent-payment-shared'
import { money, parseDateTime } from '@guiker/shared-framework'

import { ParsedTenantInstalment } from '../utils'
import { TenantInstalmentActionButton } from './TenantInstalmentActionButton'
import { TenantInstalmentStatusChip } from './TenantInstalmentStatusChip'

export const useTenantInstalmentColumns = (
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments,
  activePayInMethod: PayInMethod,
): ColumnConfig<ParsedTenantInstalment>[] => {
  const { tMain } = useT({ domain: 'bookingRentPayment', screenName: 'rentPaymentsPlanTable' })

  return [
    {
      label: tMain(`columns.month`),
      name: 'month',
      size: 1,
      renderValue: ({ chargeDate }) => {
        return <P m={0}>{parseDateTime(chargeDate).toFormat('dd MMM yyyy')}</P>
      },
    },
    {
      label: tMain(`columns.method`),
      name: 'method',
      size: 1,
      renderValue: ({ processedWith }) => {
        const payInMethod = processedWith?.payer?.payInMethod ?? activePayInMethod
        return payInMethod && <PayInMethodLabel payInMethod={payInMethod} />
      },
    },
    {
      label: tMain(`columns.amount`),
      name: 'amount',
      size: 1,
      renderValue: ({ processedWith, amount }) => {
        const currency = rentPaymentsPlan.total.currency
        const processedAmount = processedWith?.amount ?? amount ?? 0
        return <P m={0}>{money.fromAmount(processedAmount, currency).toString()}</P>
      },
    },
    {
      label: tMain(`columns.status`),
      name: 'status',
      size: 1,
      renderValue: (instalment) => {
        return <TenantInstalmentStatusChip tenantInstalment={instalment} />
      },
    },
    {
      label: tMain(`columns.action`),
      name: 'action',
      size: 1,
      renderValue: (instalment) => {
        if (instalment.status !== TenantInstalmentStatus.created) {
          return <TenantInstalmentActionButton tenantInstalment={instalment} />
        } else return <></>
      },
    },
  ]
}
