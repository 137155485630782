import React from 'react'

import { webappRoutes } from '@guiker/account-shared'
import { Invoice, Scopeless, ScopeType } from '@guiker/payment-shared'
import { ActivityLabel, ActivityMarker, Link, Source, useT } from '@guiker/react-framework'
import { compact, Event, formatDateTime, formatMonthAndYear, selectFromMap } from '@guiker/shared-framework'

export const useTransformToComponentMethod = (invoice: Invoice) => {
  const { tMain, MainTrans } = useT({ domain: 'payment', screenName: 'screens.activityLog' })

  const reason = !invoice.scope
    ? (invoice as Invoice<Scopeless>).metadata.reason
    : selectFromMap<string>({
        [ScopeType.BUY_STOCK_ORDER]: tMain('buyStockOrder'),
        [ScopeType.FUNDING_ACCOUNT_DEPOSIT]: tMain('fundingAccountDeposit'),
        [ScopeType.TENANT_INSTALMENT]: `${tMain('rentPayment')} ${formatMonthAndYear(invoice.createdAt)}`,
        default: invoice.items[0]?.label,
      })(invoice.scope.type)

  return {
    transform: ({ event }: { event: Event<Invoice> }) => {
      const { emittedAt, type, data: invoice } = event
      const timestamp = formatDateTime(emittedAt)

      switch (type) {
        case 'CREATED':
        case 'IMMEDIATE_CHARGEABLE_CREATED':
          return {
            content: <Source content={timestamp} />,
            label: <ActivityLabel content={tMain(type, { reason })} />,
          }
        case 'CANCELLED':
        case 'VERIFICATION_REQUESTED':
        case 'VERIFICATION_FAILED':
        case 'VERIFICATION_SUCCEED':
        case 'FUNDING_ACCOUNT_CHARGE_PENDING':
        case 'FUNDING_ACCOUNT_CHARGE_SUCCEED':
        case 'FUNDING_ACCOUNT_CHARGE_FAILED':
        case 'STOCK_ORDER_INVOICE_REIMBURSED':
        case 'CHARGE_PENDING':
        case 'CHARGE_SUCCEED':
        case 'CHARGE_FAILED':
        case 'OFFLINE_CHARGE_SUCCEED':
        case 'OFFLINE_CHARGE_FAILED':
        case 'OFFLINE_CHARGE_UNDONE':
        case 'REFUND_PENDING':
        case 'REFUND_FAILED':
        case 'REFUND_SUCCEED':
        case 'DISPUTE_CREATED':
          return {
            label: <ActivityLabel content={tMain(type)} />,
            content: <Source content={timestamp} />,
          }
        case 'DISPUTE_CLOSED':
          const path = webappRoutes.root.wallet().payments().invoice().byId(invoice.id).recoverDispute().path
          return {
            content: <Source content={timestamp} />,
            label: (
              <ActivityLabel
                content={
                  <MainTrans i18nKey={`DISPUTE_CLOSED_${invoice.dispute.status}`}>
                    <Link color={60} to={path} />
                  </MainTrans>
                }
              />
            ),
          }
        default: {
          return
        }
      }
    },
  }
}

export const transformEventsToActivityItems = (args: { invoice: Invoice; events?: Event[] }) => {
  const { invoice, events = [] } = args
  const { transform } = useTransformToComponentMethod(invoice)
  const filteredEvents = compact(events.map((event) => transform({ event: event as Event<Invoice> })))

  return filteredEvents?.map((item, index) => ({
    ...item,
    marker: {
      isFirst: index === 0,
      isLast: index === filteredEvents.length - 1,
      icon: <ActivityMarker />,
    },
  }))
}
