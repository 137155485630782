import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '@guiker/icons'

type FacebookLogoProps = SvgIconProps

const FacebookLogo: React.FC<FacebookLogoProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <circle cx='12' cy='12' r='12' fill='url(#paint0_linear)' />
      <g clipPath='url(#clip0)'>
        <path
          d='M16.4711 5.66422L14.0928 5.6604C11.421 5.6604 9.69434 7.43192 9.69434 10.1738V12.2548H7.30315C7.09652 12.2548 6.9292 12.4223 6.9292 12.6289V15.644C6.9292 15.8507 7.09671 16.018 7.30315 16.018H9.69434V23.6261C9.69434 23.8327 9.86166 24 10.0683 24H13.1881C13.3947 24 13.5621 23.8325 13.5621 23.6261V16.018H16.3579C16.5645 16.018 16.7319 15.8507 16.7319 15.644L16.733 12.6289C16.733 12.5297 16.6935 12.4347 16.6235 12.3645C16.5535 12.2943 16.4581 12.2548 16.3589 12.2548H13.5621V10.4907C13.5621 9.64283 13.7641 9.2124 14.8686 9.2124L16.4707 9.21183C16.6771 9.21183 16.8444 9.04431 16.8444 8.83787V6.03817C16.8444 5.83192 16.6773 5.6646 16.4711 5.66422Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='19.9245'
          y1='3.16981'
          x2='3.73585'
          y2='22.1887'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#02A3FA' />
          <stop offset='1' stopColor='#0059FA' />
        </linearGradient>
        <clipPath id='clip0'>
          <rect width='18.3396' height='18.3396' fill='white' transform='translate(2.71704 5.6604)' />
        </clipPath>
      </defs>
    </SvgIconMediumViewBox>
  )
}

export { FacebookLogo }
