import React from 'react'

import { makeStyles, margin, theme, toPx } from '@guiker/components-core'

import { Box, BoxProps, Inline, P } from '../../../components'

const useStyle = makeStyles(
  {
    root: {
      maxWidth: toPx(1108),
    },
    line: {
      width: 30,
      height: 0,
      marginRight: theme.spacing(1),
      border: `0.5px solid ${theme.palette.grey[60]};`,
    },
    grey: {
      color: theme.palette.grey[60],
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      alignItems: 'right',
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(3),
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      margin: margin(4, 1),
    },
  },
  { name: 'Testimonial' },
)

type TestimonialProps = BoxProps & {
  author: string
  content: string
  cta: React.ReactNode
  direction?: 'right' | 'left'
}

const Testimonial: React.FC<TestimonialProps> = ({ author, content, cta, direction = 'left', ...props }) => {
  const classes = useStyle({ direction })

  return (
    <Box className={classes.root} {...props}>
      <Box className={classes.content}>
        <P>{content}</P>
        <Box display='flex' alignItems='center' justifyContent={direction === 'right' ? 'end' : 'start'} mb={3}>
          <Inline className={classes.line} />
          <Inline mb={0} className={classes.grey}>
            {author}
          </Inline>
        </Box>
        <Box width='100%' justifyContent={direction === 'right' ? 'end' : 'start'} display='flex'>
          {cta}
        </Box>
      </Box>
    </Box>
  )
}

export { Testimonial }
