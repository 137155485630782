import { CurrencyISO } from '@guiker/money'

import { PayoutProviderName } from '../payout-method'
import { Transfer, TransferProjection } from '../transfer'

enum PayoutStatus {
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  PAID = 'PAID',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

type Payout = {
  id: string
  batchId: string // 'bch-{uuid}'
  provider: {
    name: PayoutProviderName
    externalId?: string
  }
  status: PayoutStatus
  amount: number
  currency: CurrencyISO
  payoutMethodId: string
  expectedPayoutDate: string
  createdAt: string
}

type WithTransfers<T, P extends TransferProjection | Transfer = TransferProjection> = T & { transfers?: P[] }

export { Payout, PayoutStatus, WithTransfers }
