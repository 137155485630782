import React from 'react'

import { MessagesBubbleIcon, Note, P, useT } from '@guiker/react-framework'

import { useInvoiceContext } from '../../../hooks'

export const WireTransferNotice: React.FC = () => {
  const { tShared } = useT({ domain: 'payment', entity: 'wireTransfer' })
  const { invoiceState } = useInvoiceContext()

  if (!invoiceState.isPendingWireTransfer) return <></>

  return (
    <Note icon={<MessagesBubbleIcon />} title={tShared('notice.title')} statusColor='info' mb={3}>
      <P mb={0}>{tShared('notice.content')}</P>
    </Note>
  )
}
