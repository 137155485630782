export enum ReferralType {
  FRIENDS_AND_FAMILY = 'FRIENDS_AND_FAMILY',
  GOOGLE = 'GOOGLE',
  UNIVERSITY = 'UNIVERSITY',
  ZILLOW = 'ZILLOW',
  AIRBNB = 'AIRBNB',
  AKAMAI = 'AKAMAI',
  ALPACA = 'ALPACA',
  APARTMENTSDOTCOM = 'APARTMENTSDOTCOM',
  BMP = 'BMP',
  CRAIGSLIST = 'CRAIGSLIST',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  FORRENTDOTCOM = 'FORRENTDOTCOM',
  HOTPADS = 'HOTPADS',
  MERL = 'MERL',
  SPAREROOM = 'SPAREROOM',
  STUDENTDOTCOM = 'STUDENTDOTCOM',
  TRANSPARENTCITY = 'TRANSPARENTCITY',
  TRULIA = 'TRULIA',
  UNIVERSITYLIVINGDOTCOM = 'UNIVERSITYLIVINGDOTCOM',
  ZUMPER = 'ZUMPER',
  OTHER = 'OTHER',
}

export type Referral = {
  type?: ReferralType
  other?: string
}
