import { Scope } from '@guiker/base-entity'
import { CheckboxTab, TextTab } from '@guiker/docusign'

export enum EnvelopeStatus {
  CREATED = 'CREATED',
  DRAFTED = 'DRAFTED',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum ParticipantDocumentStatus {
  INCOMPLETED = 'INCOMPLETED',
  COMPLETED = 'COMPLETED',
}

export enum ParticipantRole {
  SIGNER = 'SIGNER',
  SPECTATOR = 'SPECTATOR',
  EDITOR = 'EDITOR',
}

export enum ParticipantStatus {
  SENT = 'SENT',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  OPENED = 'OPENED',
  DECLINED = 'DECLINED',
  COMPLETED = 'COMPLETED',
}

export type Template = {
  uid: string // from docusign
  name: string
}

export type PrefillTabs = {
  textTabs?: TextTab[]
  checkboxTabs?: CheckboxTab[]
}

export type ParticipantDocument = {
  name: string
  uid: string
  status: ParticipantDocumentStatus
  role: ParticipantRole
  tabs: {
    status?: string
    tabLabel: string
    value?: string
  }[]
}

/** @reference https://www.docusign.com/blog/developers/the-trenches-whos-locking-my-envelopes */
export const DEFAULT_DOCUSIGN_LOCK_EXPIRATION_MINUTE = 15

export enum ParticipantRoleName {
  LESSOR = 'Lessor',
  LESSEE = 'Lessee',
  GUARANTOR = 'Guarantor',
  EDITOR = 'Editor',
}

/** @todo Add provider field when introduce a third party tool other than Docusign */
export type Participant = {
  firstName: string
  lastName: string
  emailAddress: string
  uid?: string // docusign recipientId
  guid?: string // docusign unique GUID
  userId?: string //userId that we passed to docusign, if participant does not have our userid, generate uuid and assign to docuSign
  status?: ParticipantStatus
  documents?: ParticipantDocument[]
}

export type EnvelopeDocument = {
  uid: string
  name: string
}

export type Signer = {
  firstName: string
  lastName: string
  emailAddress: string
  roleName: string
}

export enum ScopeType {
  BOOKING = 'booking',
  LEASE_CONTRACT = 'lease-contract',
}

export type Envelope = {
  id: string
  uid: string // docusign envelopeId
  scope: Scope
  title: string
  status: EnvelopeStatus
  templates: Template[]
  participants: Participant[]
  documents: EnvelopeDocument[]
  redirectUrl: string
  createdAt: string
  updatedAt: string
}
