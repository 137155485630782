import React from 'react'

import { PayInMethod } from '@guiker/payment-shared'
import { Table } from '@guiker/react-framework'
import { Payer, RentPaymentsPlanWithTenantInstalments } from '@guiker/rent-payment-shared'

import { parseTenantInstalments } from '../utils'
import { useTenantInstalmentColumns } from './tenant-instalment-columns'

type Prop = {
  rentPaymentsPlan: RentPaymentsPlanWithTenantInstalments
  payer: Payer
  payInMethod: PayInMethod
}

export const RentPaymentsPlanTable: React.FC<Prop> = ({ rentPaymentsPlan, payer, payInMethod }) => {
  return (
    <Table
      columns={useTenantInstalmentColumns(rentPaymentsPlan, payInMethod)}
      data={parseTenantInstalments({ rentPaymentsPlan, payer })}
    />
  )
}
