export type BulkError = {
  name: string
  details: unknown
}

export type BulkOperationResponseItem<T> =
  | {
      status: 'success'
      data: T
    }
  | {
      status: 'failed'
      data: BulkError
    }

export type BulkOperationResponse<T> = {
  status: 'success' | 'partial-success' | 'failed'
  items: BulkOperationResponseItem<T>[]
}

export enum S3AccessControlList {
  PUBLIC = 'public-read',
  PRIVATE = 'private',
}
