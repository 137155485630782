import { yup } from '@guiker/yup-util'

const phoneNumberSchema = yup.object({
  CA: yup.object({
    phoneNumber: yup.string().required(),
    states: yup.object({
      QC: yup.object({
        phoneNumber: yup.string(),
        cities: yup.object({
          gatineau: yup.object({
            phoneNumber: yup.string(),
          }),
          montreal: yup.object({
            phoneNumber: yup.string(),
          }),
        }),
      }),
      ON: yup.object({
        phoneNumber: yup.string(),
        cities: yup.object({
          ottawa: yup.object({
            phoneNumber: yup.string(),
          }),
          toronto: yup.object({
            phoneNumber: yup.string(),
          }),
        }),
      }),
    }),
  }),
  US: yup.object({
    phoneNumber: yup.string().required(),
    states: yup.object({
      IL: yup.object({
        phoneNumber: yup.string(),
        cities: yup.object({
          chicago: yup.object({
            phoneNumber: yup.string(),
          }),
        }),
      }),
    }),
  }),
})

export const twilioConfigSchema = yup
  .object({
    accountSid: yup.string().required(),
    authToken: yup.string().required(),
    phoneNumbers: phoneNumberSchema.required(),
  })
  .required()
