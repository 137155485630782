import { AxiosError } from '@guiker/axios'
import { Errors } from '@guiker/common'

export class ApiError extends Errors.BaseError {
  public baseError: AxiosError
  public correlatedRequestId: string

  constructor(
    httpMethod: string,
    url: string,
    statusCode: number,
    baseError: AxiosError,
    correlatedRequestId?: string,
  ) {
    super(
      `Error while processing request to ${httpMethod} ${url}. Received a response with status code ${statusCode}.`,
      'ApiError',
      statusCode,
      baseError?.response?.data,
    )

    this.correlatedRequestId = correlatedRequestId
    this.baseError = baseError
  }
}
