import React from 'react'

import { Flex, P, PBig } from '..'

type iconDescriptionProps = {
  icon: React.ReactNode
  icon2?: React.ReactNode
  description: string
  value: string | number
  value2?: string | number
}

export const IconDescription: React.FC<iconDescriptionProps> = ({ icon, icon2, value2, description, value }) => {
  return (
    <Flex gap={1} flexDirection='column' justifyContent='flex-end' alignItems='center'>
      <Flex alignItems='center' justifyContent='flex-end' gap={1}>
        <PBig mb={-1}>{value}</PBig>
        {icon}
        {icon2 && value2 ? (
          <Flex alignItems='center' justifyContent='flex-end' gap={1}>
            <PBig mb={-1}>+ {value2}</PBig>
            {icon2}
          </Flex>
        ) : undefined}
      </Flex>
      <P mb={0}>{description}</P>
    </Flex>
  )
}
