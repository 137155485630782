import { UtilityAmenityName } from '../amenity'

type Utilities<T = boolean | null> = {
  hotWater: T
  electricity: T
  heating: T
  wifi: T
}

const LegacyUtilityAmenityMap: { [key in UtilityAmenityName]: keyof Utilities } = {
  WATER: 'hotWater',
  ELECTRICITY: 'electricity',
  HEATING: 'heating',
  WIFI: 'wifi',
}

export { Utilities, LegacyUtilityAmenityMap }
