import { Args, buildPath } from '@guiker/api-definition'
import { initializeRestClient, Logger } from '@guiker/base-rest-client'
import { routes } from '@guiker/blog-api-definition'
import { Article, Category } from '@guiker/blog-entity'
import { DeepPartial } from '@guiker/ts-utils'

export const ApiClient = ({ baseUrl, logger }: { baseUrl: string; logger?: Logger }) => {
  const baseRestClient = initializeRestClient(logger)

  return {
    readAllArticles: async (params: DeepPartial<Args<typeof routes.readAllArticles>>) =>
      baseRestClient.get<Article[]>(
        `${baseUrl}${buildPath(
          routes.readAllArticles,
          null as never,
          await routes.readAllArticles.queryParamsValidator(params.queryParams),
        )}`,
      ),
    readAllCategories: async (params: DeepPartial<Args<typeof routes.readAllCategories>>) =>
      baseRestClient.get<Category[]>(
        `${baseUrl}${buildPath(
          routes.readAllCategories,
          null as never,
          await routes.readAllCategories.queryParamsValidator(params.queryParams),
        )}`,
      ),
    readOneCategory: async ({ queryParams }: { queryParams: { slug: string } }) => {
      const response = await baseRestClient.get<Category[]>(
        `${baseUrl}${buildPath(
          routes.readCategoriesBySlug,
          null as never,
          await routes.readCategoriesBySlug.queryParamsValidator(queryParams),
        )}`,
      )

      const { statusCode, headers, body } = response

      return {
        statusCode,
        headers,
        body: body[0],
      }
    },
    readOneArticle: async ({ queryParams }: { queryParams: { slug: string } }) => {
      const response = await baseRestClient.get<Article[]>(
        `${baseUrl}${buildPath(
          routes.readArticlesBySlug,
          null as never,
          await routes.readArticlesBySlug.queryParamsValidator(queryParams),
        )}`,
      )

      const { statusCode, headers, body } = response

      return {
        statusCode,
        headers,
        body: body[0],
      }
    },
  }
}
