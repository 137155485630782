import { useContext } from 'react'

import { AuthenticationModalContext } from './AuthenticationModalContext'

export const useAuthenticationModalContext = () => {
  const context = useContext(AuthenticationModalContext)

  if (context === undefined) {
    throw new Error('useAuthenticationModalContext can only be used inside AuthenticationModalContextProvider')
  }

  return context
}
