import { DeepPartial } from '@guiker/ts-utils'

import { Building, NEWBuilding, WithBuilding } from './building'
import { FinancialInfo } from './financial-information'
import { NEWUnit, Unit } from './unit'

export enum CreationStatus {
  DRAFTED = 'DRAFTED',
  COMPLETED = 'COMPLETED',
}

export type Property = {
  id: string
  status?: {
    creationStatus: CreationStatus
    isPublished: boolean
  }
  building?: Building
  units?: Unit[]
  createdAt?: string
  updatedAt?: string
  totalSquareFootage?: number
  repartitionOfUnits?: {
    commercial: number
    residential: number
  }
  hasDetailedUnitInformation?: boolean
} // deprecate

/////////

export enum PropertyType {
  RENTAL_APARTMENT = 'RENTAL_APARTMENT',
  CONDO = 'CONDO',
  PLEX = 'PLEX',
  SINGLE_FAMILY_HOUSE = 'SINGLE_FAMILY_HOUSE',
  COMMERCIAL = 'COMMERCIAL',
  MIXED = 'MIXED',
}

export enum PropertyAffix {
  BUILDING = 'BUILDING',
  UNIT = 'UNIT',
}

export type NEWProperty = {
  id: string
  building: DeepPartial<NEWBuilding>
  type: PropertyType
  affix: PropertyAffix
  unit?: NEWUnit
  financialInfo: FinancialInfo
  createdAt?: string
  updatedAt?: string
  meta?: {
    legacyIds: number[]
  }
}

export type WithPropertyHasBuilding<T> = T & {
  property: WithBuilding<NEWProperty>
}
