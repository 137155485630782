import React from 'react'

import { FindHomeIcon, InvestIcon, ServicesIcon, Tab, Tabs } from '@guiker/react-framework'

import { useT } from '../../i18n'

export type HomeTabsProps = {
  currentIndex: number
  onChange: (value: number) => unknown
}

export const HomeTabs: React.FC<HomeTabsProps> = ({ currentIndex, onChange }) => {
  const { tMain } = useT({ screenName: 'screens.home.hero' })

  const tabs = [
    {
      key: 'rent.tab',
      icon: <FindHomeIcon />,
      label: tMain('rent.tab'),
    },
    {
      key: 'invest.tab',
      icon: <InvestIcon />,
      label: tMain('invest.tab'),
    },
    {
      key: 'services.tab',
      icon: <ServicesIcon />,
      label: tMain('services.tab'),
    },
  ]

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChange(value)
  }

  return (
    <Tabs size='large' value={currentIndex} variant='fullWidth' onChange={handleChange as any} withBorder={true}>
      {tabs.map((props) => (
        <Tab {...props} />
      ))}
    </Tabs>
  )
}
