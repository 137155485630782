import { HttpMethod } from '@guiker/http'

import { RentalListing } from '../../entity'
import { listingIdNested, referenceId } from '../schemas'

export const LISTING_PATH = '/public/listing'
export const LISTING_WITH_ID_PATH = `${LISTING_PATH}/:listingId`
export const REFERENCE_LISTING = '/public/listing-reference/:referenceId'

const responseValidator = (payload: unknown) => Promise.resolve(payload as RentalListing)

export const routes = {
  readOneListing: {
    path: LISTING_WITH_ID_PATH,
    method: HttpMethod.GET,
    responseValidator,
    pathParamsValidator: (params: unknown) => listingIdNested.validate(params),
  },
  readOneListingWithSSReference: {
    path: REFERENCE_LISTING,
    method: HttpMethod.GET,
    responseValidator: (payload: unknown) => Promise.resolve(payload as string),
    pathParamsValidator: (params: unknown) => referenceId.validate(params),
  },
}
