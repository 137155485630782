import { BookingAccessControl } from '@guiker/booking-shared'
import { AuthLevel, generateBaseApiContext } from '@guiker/react-framework'
import { AuthApiClient, JwtApiClient, RentPaymentsPlan } from '@guiker/rent-payment-shared'

type Scope = RentPaymentsPlan['scope']

export const {
  useContext: useAuthenticatedRentPaymentsPlanScopeNestedContext,
  Context: AuthenticatedRentPaymentsPlanContext,
  ContextProvider: AuthenticatedRentPaymentsPlanContextProvider,
  NestedRouter: AuthenticatedRentPaymentsPlanScopeNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.AUTH,
  apiClientGen: AuthApiClient,
  cacheKey: ({ scope }: { scope: Scope }) => `readOneRentPaymentsPlanByScope-${scope.type}-${scope.id}`,
  fetcher: (apiClient, { scope }: { scope: Scope }) =>
    apiClient.readOneRentPaymentsPlan({
      pathParams: { scopeType: scope.type, scopeId: scope.id },
    }),
})

export const {
  useContext: useJwtBookingRentPaymentsPlanScopeNestedContext,
  Context: JwtBookingRentPaymentsPlanContext,
  ContextProvider: JwtBookingRentPaymentsPlanContextProvider,
  NestedRouter: JwtBookingRentPaymentsPlanScopeNestedRouter,
} = generateBaseApiContext({
  authLevel: AuthLevel.JWT_AUTH,
  apiClientGen: JwtApiClient,
  cacheKey: ({ acl }: { acl: BookingAccessControl }) => {
    const scope = acl.claims?.scope
    return `readOneRentPaymentsPlanByAcl-${scope.type}-${scope.id}`
  },
  fetcher: (apiClient, { acl }: { acl: BookingAccessControl }) => {
    const { token, claims } = acl
    return apiClient.readOneRentPaymentsPlanByToken({
      pathParams: { scopeType: claims?.scope.type, scopeId: claims?.scope.id },
      accessControl: { token },
    })
  },
})
