import React from 'react'

import { ItemGroup } from '../../utils'
import { SearchListItemGroup } from './SearchListingItemGroup'

type SearchResultListProps = {
  searchString: string
  useQueryPerformSearch: (searchString: string) => {
    data: ItemGroup[]
  }
}

export const SearchResultList: React.FC<SearchResultListProps> = ({ useQueryPerformSearch, searchString }) => {
  const { data } = useQueryPerformSearch(searchString)

  return <>{data?.map((group) => group?.items?.length > 0 && <SearchListItemGroup {...group} />)}</>
}
