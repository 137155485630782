import { PointOfInterest } from './point-of-interest'
export * from '@guiker/google-map'
export * from './university-whitelist'

/** @description Stored with accuracy of 4 decimal places */
export type Coordinate = {
  lat: number
  lng: number
}

export type MapLocation = {
  id?: string
  coordinate: Coordinate
  pointsOfInterests: [PointOfInterest]
}

export const DEFAULT_POI_RADIUS = 900

export enum PointOfInterestType {
  SUBWAY_STATION = 'subway_station',
  UNIVERSITY = 'university',
  SUPERMARKET = 'supermarket',
  PARK = 'park',
  NEARBY = 'nearby',
}
