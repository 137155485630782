import { yup } from '@guiker/yup-util'

import { Lease, LeaseTypes, Ontario, Quebec } from '../entity'

export const typeChecker = {
  isUserIdToken: (claims: unknown): claims is { userId: string } => {
    return yup.object({ userId: yup.string().required() }).isValidSync(claims)
  },
  isCanadaQuebecLease: (lease: Lease): lease is Quebec.Lease => lease.type === LeaseTypes.CanadaQuebec,
  isCanadaOntarioLease: (lease: Lease): lease is Ontario.Lease => lease.type === LeaseTypes.CanadaOntario,
}
