import { AnyColor } from '@guiker/components-core'
import { TFunction, useTranslation } from '@guiker/i18n'
import { Currency, money } from '@guiker/money'
import { CREDIT_CARD_TRANSACTION_RATE, PayInMethodType } from '@guiker/payment-shared'

export type Item = {
  color?: AnyColor
  label: string
  price: string
}

export type Items = Item[]

export type TotalAmount = {
  subTotal: number
  tax: number
  serviceFee?: number
  final: number
}

export type TaxInfo = {
  taxPercentage: string
  taxTypes: string
}

export const standardInvoice = ({
  nonTaxableAmount,
  taxableAmount,
  totalAmount,
  taxInfo,
  currency,
}: {
  nonTaxableAmount: number
  taxableAmount: number
  totalAmount: TotalAmount
  taxInfo: TaxInfo
  currency: Currency
}): Items => {
  const { t } = useTranslation('common')
  const tPrefix = 'common-payment:invoice'
  return [
    {
      label: t(`${tPrefix}.nonTaxableAmount`),
      price: money.fromAmount(nonTaxableAmount, currency).toString(true),
    },
    {
      label: t(`${tPrefix}.taxableAmount`),
      price: money.fromAmount(taxableAmount, currency).toString(true),
    },
    {
      color: 30,
      label: t(`${tPrefix}.taxes`, taxInfo),
      price: money.fromAmount(totalAmount.tax, currency).toString(true),
    },
  ]
}

export const creditCardProcessingFee = ({
  t,
  tPrefix,
  subTotalAmount,
  currency,
}: {
  t: TFunction
  tPrefix: string
  subTotalAmount: number
  currency: Currency
}): Item => ({
  label: t(`${tPrefix}.processingFee`, {
    transactionFeePercentage: `${CREDIT_CARD_TRANSACTION_RATE * 100}%`,
  }),
  price: money.fromAmount(subTotalAmount * CREDIT_CARD_TRANSACTION_RATE, currency).toString(true),
})

export const buildInvoiceItems = ({
  payinMethodType,
  nonTaxableAmount,
  taxableAmount,
  totalAmount,
  taxInfo,
  currency,
}: {
  payinMethodType: PayInMethodType
  nonTaxableAmount: number
  taxableAmount: number
  totalAmount: TotalAmount
  taxInfo: TaxInfo
  currency: Currency
}): Items => {
  const { t } = useTranslation('common')
  const tPrefix = 'common-payment:invoice'
  const standardLabel = standardInvoice({ nonTaxableAmount, taxableAmount, totalAmount, taxInfo, currency })
  if (payinMethodType === PayInMethodType.CREDIT_CARD) {
    return [...standardLabel, creditCardProcessingFee({ t, tPrefix, subTotalAmount: totalAmount.subTotal, currency })]
  } else {
    return standardLabel
  }
}
