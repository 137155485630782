export const webappRoutes = {
  invited: function ({ code, token }: { code: string; token?: string }) {
    return {
      path: `/invited?code=${code}${token ? `&token=${token}` : ''}`,
    }
  },
  redirect: function ({ code, token }: { code: string; token?: string }) {
    return {
      path: `/redirect?code=${code}${token ? `&token=${token}` : ''}`,
    }
  },
} as const
