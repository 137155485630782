type SelectMap<T, K extends string = string> = { [key in K]?: T } & { default: T }

const when = <T, K extends string>(testCase: K, returnValue: T) => {
  return (key: K) => testCase === key && returnValue
}

const select = <T, K>(list: ((key: K) => T)[]) => {
  return (key: K) => list.reduce((acc, next) => acc || next(key), null as T)
}

export const selectFromMap = <T = unknown, K extends string = string>(map: SelectMap<T, K>) => {
  return select<T, K>(
    Object.entries(map).map(([key, value]: [K, T]) => {
      return key === 'default' ? () => value : when<T, K>(key, value)
    }),
  )
}
