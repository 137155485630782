import { buildQueueDefinition } from '@guiker/queue'

import { User } from '../entity'

export const Queue = {
  EmailAllUsers: buildQueueDefinition({
    name: 'EmailAllUsers',
    serviceName: 'email-api',
    payload: null as Partial<User>[],
    batchSize: 10,
  }),
} as const
