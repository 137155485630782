import React, { createContext, useLayoutEffect, useState } from 'react'

import { generateUseContext } from '@guiker/react-context'
import { LocalStorageKey, persistancy } from '@guiker/react-persistance'
import { useSearchParams } from '@guiker/router'

export enum DirectedSource {
  SPLITSPOT = 'splitspot',
}

export type Context = {
  directedSource: DirectedSource
  setDirectedSource: (source?: DirectedSource) => void
  unsetDirectedSource: () => void
}

export const PromotionContext = createContext<Context>(null)

type PromotionContextProviderProps = React.PropsWithChildren

const isDirectedSource = (source: unknown): source is DirectedSource => {
  return typeof source === 'string' && Object.values(DirectedSource).includes(source as any)
}

export const PromotionContextProvider: React.FC<PromotionContextProviderProps> = ({ children }) => {
  const [directedSource, _setDirectedSource] = useState<DirectedSource>()
  const { getSearchParam } = useSearchParams()
  const { persistItem, getItem, clear } = persistancy(LocalStorageKey.DIRECTED_SOURCE)
  const source = getSearchParam('source') as DirectedSource

  const setDirectedSource = (source?: DirectedSource) => {
    persistItem(source)
    _setDirectedSource(source)
  }

  const unsetDirectedSource = () => {
    clear()
    _setDirectedSource(null)
  }

  useLayoutEffect(() => {
    const persistedSource = getItem() ?? source
    isDirectedSource(persistedSource) && setDirectedSource(persistedSource)
  }, [source])

  const value = {
    directedSource,
    setDirectedSource,
    unsetDirectedSource,
  }

  return <PromotionContext.Provider value={value}>{children}</PromotionContext.Provider>
}

export const usePromotionContext = generateUseContext(PromotionContext)
