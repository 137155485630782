import React from 'react'

import {
  CheckboxAdornment,
  Flex,
  PBold,
  PSmall,
  RouterLink,
  SecondaryButton,
  TypographyProps,
  useDateFormatter,
  useLayoutContext,
  useTranslation,
} from '@guiker/react-framework'
import { Status, Task } from '@guiker/task-shared'

import { taskMapping } from '../utils'

type TaskProp = {
  task: Task
  TitleComponent?: React.FC<React.PropsWithChildren & { mb: TypographyProps['mb'] }>
}

export const IndividualTask: React.FC<TaskProp> = ({ task }) => {
  const { t } = useTranslation('common-task')
  const copy = taskMapping(task, t)
  const { isAtMostTablette } = useLayoutContext()
  const { formatWithRelativeDateTime } = useDateFormatter()
  const isCompleted = task.status === Status.COMPLETED

  return (
    <Flex alignItems='flex-start' gap={2}>
      {isCompleted ? (
        <CheckboxAdornment readOnly checked={isCompleted} />
      ) : (
        <RouterLink to={copy.path}>
          <CheckboxAdornment checked={isCompleted} />
        </RouterLink>
      )}
      <Flex flexDirection={isAtMostTablette ? 'column' : 'row'} flexGrow={1} gap={2}>
        <Flex flexDirection='column' flexGrow={1} gap={1}>
          <PBold mb={0}>{copy.header}</PBold>
          <PSmall mb={0} color={60}>
            {copy.body}
          </PSmall>
        </Flex>
        <Flex
          {...(isAtMostTablette
            ? { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
            : { flexDirection: 'column', alignItems: 'flex-end', gap: 1 })}
        >
          <PSmall mb={0} mt={0.5} color={60} whiteSpace='nowrap'>
            {formatWithRelativeDateTime(task.createdAt)}
          </PSmall>
          {!isCompleted && (
            <RouterLink to={copy.path}>
              <SecondaryButton size='small'>{copy.cta}</SecondaryButton>
            </RouterLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
