import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { BackgroundCheck } from '../entity'

const definition = generateEventsDefinition({
  context: 'BACKGROUND_CHECK',
  entity: 'BACKGROUND_CHECK',
  data: null as unknown as BackgroundCheck,
  eventTypes: {
    RESULT_UPDATED: 'RESULT_UPDATED',
    REQUESTED: 'REQUESTED',
    COMPLETED: 'COMPLETED',
    UPDATED: 'UPDATED',
    DELETED: {
      type: 'DELETED',
      eventData: null as { error: string },
    },
    FAILED_RESULT_UPDATED: 'FAILED_RESULT_UPDATED',
  } as const,
})

export type BackgroundCheckEventTypes = ValueOfEventTypes<typeof definition>
export const BackgroundCheckEventsDefinition = definition
export const BackgroundCheckEvents = definition.events
export const BackgroundCheckEventTypes = definition.eventTypes
