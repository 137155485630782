export enum BookingLeaseParticipantStatus {
  WAITING_ON_LEASE_TO_BE_READY = 'WAITING_ON_LEASE_TO_BE_READY',
  MISSING_SIGNATURE = 'MISSING_SIGNATURE',
  SIGNED = 'SIGNED',
}

export enum BookingLeaseParticipantRole {
  LESSOR = 'LESSOR',
  LESSEE = 'LESSEE',
  GUARANTOR = 'GUARANTOR',
  UNKNOWN = 'UNKNOWN',
}

export interface BookingLeaseParticipant {
  userId: string
  status: BookingLeaseParticipantStatus

  fullName?: string
  role?: BookingLeaseParticipantRole
  relatedUserId?: string
}

export enum BookingLeaseStatus {
  STARTED = 'STARTED',
  READY_TO_BE_SIGNED = 'READY_TO_BE_SIGNED',
  SIGNED_BY_ALL_PARTIES = 'SIGNED_BY_ALL_PARTIES',
}

export interface BookingLease {
  status: BookingLeaseStatus
  participants: BookingLeaseParticipant[]
}
