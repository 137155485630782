import { ApplicationStatus } from '@guiker/booking-shared'

export const getApplicationStatusAfterSubmit = (prevStatus: ApplicationStatus): ApplicationStatus => {
  let status = prevStatus
  switch (prevStatus) {
    case ApplicationStatus.CHANGE_REQUESTED:
      status = ApplicationStatus.SUBMITTED
      break
    case ApplicationStatus.INITIAL:
    case ApplicationStatus.STARTED:
      status = ApplicationStatus.STARTED
      break
    default:
  }

  return status
}
