import {
  BackgroundCheckResult,
  BackgroundCheckStatus,
  CreditReportOverallScore,
  OverallScore,
} from '@guiker/background-check-shared'

import { Consent } from './consent'

export enum CreditScoreRanking {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  VERY_POOR = 'VERY_POOR',
  POOR = 'POOR',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  VERY_GOOD = 'VERY_GOOD',
  EXCELLENT = 'EXCELLENT',
}

export type LegalAddress = {
  isSameAsCurrentAddress: boolean
  address?: {
    suite?: string
    streetNumber: string
    streetName: string
    city: string
    state: string
    postalCode: string
    country: string
  }
}

export type TenantApplicationBackgroundCheck = {
  id?: string
  legalAddress: LegalAddress
  consent: Consent
  result?: BackgroundCheckResult
  status?: BackgroundCheckStatus
  ssn?: string
  creditStatus?: CreditReportOverallScore
  creditScore?: number
  certnScore?: number
  certnScoreLabel?: OverallScore
  updatedAt?: string
}

export type TenantApplicationGuarantorBackgroundCheck = Omit<TenantApplicationBackgroundCheck, 'legalAddress'>
