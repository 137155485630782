import { generateEventsDefinition, ValueOfEventTypes } from '@guiker/event'

import { StockOrder } from '../../entity'
import { baseEvent } from '../base'

export const StockOrderEventsDefinition = generateEventsDefinition({
  ...baseEvent,
  entity: 'STOCK_ORDER',
  data: null as unknown as StockOrder,
  eventTypes: {
    BUYOUT_CONTRACT_ADDED: {
      type: 'BUYOUT_CONTRACT_ADDED',
      eventData: null as { bucket: string; key: string; displayName: string },
    },
    BUY_ORDER_CREATED: 'BUY_ORDER_CREATED',
    SELL_ORDER_CREATED: 'SELL_ORDER_CREATED',
    IPO_BUY_ORDER_CREATED: 'IPO_BUY_ORDER_CREATED',
    PENDING_EXECUTION_COMPLETION: 'PENDING_EXECUTION_COMPLETION',
    PENDING_SHARE_ISSUANCE: 'PENDING_SHARE_ISSUANCE',
    FULFILLED: 'FULFILLED',
    CANCELLED: 'CANCELLED',
    SOLD: 'SOLD',
    BUYOUT: 'BUYOUT',
    RESERVATION_CANCELLED: 'RESERVATION_CANCELLED',
    OFFLINE_PAYMENT_FORCED: 'OFFLINE_PAYMENT_FORCED',
    PROMOTIONAL_BUY_ORDER_CREATED: 'PROMOTIONAL_BUY_ORDER_CREATED',
    PROMOTIONAL_BUY_ORDER_CLAIMED: 'PROMOTIONAL_BUY_ORDER_CLAIMED',
  } as const,
})
export const StockOrderEvents = StockOrderEventsDefinition.events
export const StockOrderEventTypes = StockOrderEventsDefinition.eventTypes
export type StockOrderEventTypes = ValueOfEventTypes<typeof StockOrderEventsDefinition>
