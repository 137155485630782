import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'
import { buildPaginationMeta } from '@guiker/paginate'

import { BackgroundCheck } from '../../../entity'
import { paths } from '../paths'
import { createBackgroundCheckPayloadValidator, readOneBackgroundCheckPathValidator } from './schemas'

const BASE_PATH = paths.backgroundCheck.one.base()

const responseValidator = (payload: unknown) => Promise.resolve(payload as BackgroundCheck)

export const routes = {
  readAllBackgroundChecks: {
    path: BASE_PATH,
    method: HttpMethod.GET,
    authenticated: false,
    responseValidator: validatorBuilder.buildResponseValidator<{
      data: BackgroundCheck[]
      meta: ReturnType<typeof buildPaginationMeta>
    }>(),
    queryParamsValidator: validatorBuilder.buildPaginationQueryParamValidator(),
  },
  readOneBackgroundCheck: {
    path: paths.backgroundCheck.one.withScope('applicant', ':applicantId'),
    method: HttpMethod.GET,
    authenticated: false,
    pathParamsValidator: (payload: unknown) => readOneBackgroundCheckPathValidator.validate(payload),
    responseValidator,
  },
  createBackgroundCheck: {
    path: BASE_PATH,
    method: HttpMethod.POST,
    authenticated: false,
    payloadValidator: (payload: unknown) => createBackgroundCheckPayloadValidator.validate(payload),
    responseValidator,
  },
}
