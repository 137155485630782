import React from 'react'

import { isMobile, isNotMobile, makeStyles, theme } from '@guiker/components-core'

import { Box, H2, H4, P, PageMetaTags, PBig, PBold } from '../../components'
import { useTranslation } from '../../hooks'
import { privacyPolicyContent as ppc } from './privacy-policy-content'

const useStyle = makeStyles({
  pageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [isMobile]: {
      padding: theme.spacing(3),
    },
    [isNotMobile]: {
      padding: theme.spacing(10),
    },
    backgroundColor: theme.palette.common.white,
  },
  contentContainer: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    maxWidth: 1440,
    flexDirection: 'column',
  },
})

const PSpanBold: React.FC<React.PropsWithChildren> = ({ children }) => (
  <PBold color={80} component='span'>
    {children}
  </PBold>
)
const PSpan: React.FC<React.PropsWithChildren> = ({ children }) => (
  <P color={80} component='span'>
    {children}
  </P>
)
const PBoldGrey: React.FC<React.PropsWithChildren> = ({ children }) => <PBold color={80}>{children}</PBold>
const PGrey: React.FC<React.PropsWithChildren> = ({ children }) => <P color={80}>{children}</P>
const Li: React.FC<React.PropsWithChildren> = ({ children }) => (
  <li style={{ marginBottom: theme.spacing(2) }}>{children}</li>
)

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation(['webapp'])
  const classes = useStyle()

  return (
    <>
      <PageMetaTags subtitle={t('privacyPolicy.seo.subtitle')} description={t('privacyPolicy.seo.description')} />
      <Box className={classes.pageContainer}>
        <Box className={classes.contentContainer}>
          <H2>{ppc.privacyPolicyHeader}</H2>
          <PBig>{ppc.lastUpdate}</PBig>
          <Box>
            <H4>{ppc.acceptanceAndApplication.title}</H4>
            {ppc.acceptanceAndApplication.clauses.map((clause, index) => (
              <PGrey key={`acceptanceAndApplication-clause${index}`}>{clause}</PGrey>
            ))}
          </Box>
          <Box>
            <H4>{ppc.informationCollectionAndUse.title}</H4>
            <PBoldGrey>{ppc.informationCollectionAndUse.infoProvidedToUs.title}</PBoldGrey>
            <Box>
              <PBoldGrey>{ppc.informationCollectionAndUse.infoProvidedToUs.forPlatform.title}</PBoldGrey>
              <PGrey>{ppc.informationCollectionAndUse.infoProvidedToUs.forPlatform.clause}</PGrey>
              <ul>
                {ppc.informationCollectionAndUse.infoProvidedToUs.forPlatform.list.map((item) => (
                  <Li key={item.title}>
                    <PSpanBold>{item.title}</PSpanBold>
                    <PSpan>{item.clause}</PSpan>
                  </Li>
                ))}
              </ul>
            </Box>
            <Box>
              <PBoldGrey>{ppc.informationCollectionAndUse.infoProvidedToUs.forPayment.title}</PBoldGrey>
              <PGrey>{ppc.informationCollectionAndUse.infoProvidedToUs.forPayment.clause}</PGrey>
              <ul>
                {ppc.informationCollectionAndUse.infoProvidedToUs.forPayment.list.map((item) => (
                  <Li key={item.title}>
                    <PSpanBold>{item.title}</PSpanBold>
                    <PSpan>{item.clause}</PSpan>
                  </Li>
                ))}
              </ul>
            </Box>
            <PBoldGrey>{ppc.informationCollectionAndUse.informationPaymentAutoCollected.title}</PBoldGrey>
            <PGrey>{ppc.informationCollectionAndUse.informationPaymentAutoCollected.clause}</PGrey>
            <ul>
              {ppc.informationCollectionAndUse.informationPaymentAutoCollected.list.map((item) => (
                <Li key={item.title}>
                  <PSpanBold>{item.title}</PSpanBold>
                  <PSpan>{item.clause}</PSpan>
                  {item.list && (
                    <ul style={{ marginTop: '16px' }}>
                      {item.list.map((info, index) => (
                        <Li key={`informationPaymentAutoCollected-info${index}`}>
                          <PGrey>{info}</PGrey>
                        </Li>
                      ))}
                    </ul>
                  )}
                  {item.closingStatement && <PGrey>{item.closingStatement}</PGrey>}
                </Li>
              ))}
            </ul>
          </Box>
          <Box>
            <H4>{ppc.useInformationCollected.title}</H4>
            <PGrey>{ppc.useInformationCollected.clause}</PGrey>
            {ppc.useInformationCollected.list.map((item) => (
              <div key={item.title}>
                <PSpanBold>{item.title}</PSpanBold>
                <PSpan>{item.clause}</PSpan>
                <ul>
                  {item.usages.map((usage, index) => (
                    <Li key={`useInformationCollected-usage${index}`}>
                      <PGrey>{usage}</PGrey>
                    </Li>
                  ))}
                </ul>
              </div>
            ))}
            <PGrey>{ppc.useInformationCollected.closeStatement}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.rightToExamineInfo.title}</H4>
            <PGrey>{ppc.rightToExamineInfo.clause}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.withdrawalOfConsent.title}</H4>
            <PGrey>{ppc.withdrawalOfConsent.clause}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.promotionalCommunications.title}</H4>
            <PGrey>{ppc.promotionalCommunications.clause}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.sharingInfo.title}</H4>
            <PGrey>{ppc.sharingInfo.clause}</PGrey>
            <ul>
              {ppc.sharingInfo.list.map((item) => (
                <Li key={item.title}>
                  <PSpanBold>{item.title}</PSpanBold>
                  <PSpan>{item.clause}</PSpan>
                </Li>
              ))}
            </ul>
          </Box>
          <Box>
            <H4>{ppc.security.title}</H4>
            <PGrey>{ppc.security.clause}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.employeesAgentsAndContractors.title}</H4>
            {ppc.employeesAgentsAndContractors.clauses.map((clause, index) => (
              <PGrey key={`employeesAgentsAndContractors-clause${index}`}>{clause}</PGrey>
            ))}
          </Box>
          <Box>
            <H4>{ppc.thirdParties.title}</H4>
            <PGrey>{ppc.thirdParties.clause}</PGrey>
            <ul>
              {ppc.thirdParties.list.map((item) => (
                <Li key={item.title}>
                  <PSpanBold>{item.title}</PSpanBold>
                  <PSpan>{item.clause}</PSpan>
                </Li>
              ))}
            </ul>
          </Box>
          <Box>
            <H4>{ppc.internationalTransfer.title}</H4>
            <PGrey>{ppc.internationalTransfer.clause}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.contactUs.title}</H4>
            <PGrey>{ppc.contactUs.clause}</PGrey>
          </Box>
          <Box>
            <H4>{ppc.termsOfService.title}</H4>
            <PGrey>{ppc.termsOfService.clause}</PGrey>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export { PrivacyPolicy }
