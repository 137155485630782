import { HttpMethod } from '@guiker/http'

import { TenantApplication } from '../../entity'
import { tenantApplicationById } from '../auth-api/schemas'
import { paths } from './paths'
import { updateInvitedGuarantorPayloadValidator } from './schemas'

const responseValidator = (payload: unknown) => Promise.resolve(payload as TenantApplication | undefined)

export const routes = {
  readOneTenantApplication: {
    path: paths.tenantApplication.one.withId(':tenantApplicationId'),
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator,
    pathParamsValidator: (payload: unknown) => tenantApplicationById.validate(payload),
  },
  updateInvitedGuarantor: {
    path: `${paths.tenantApplication.one.withId(':tenantApplicationId')}/update-invited-guarantor`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator,
    payloadValidator: (payload: unknown) => updateInvitedGuarantorPayloadValidator.validate(payload),
    pathParamsValidator: (payload: unknown) => tenantApplicationById.validate(payload),
  },
}
