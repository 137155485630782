export enum InvestmentReason {
  CapitalGrowth = 'CapitalGrowth',
  IncomeGeneration = 'IncomeGeneration',
  RetirementSavings = 'RetirementSavings',
  PropertyPurchase = 'PropertyPurchase',
  RealEstateExposure = 'RealEstateExposure',
  NotSure = 'NotSure',
}

export enum TimeScale {
  VeryLong = 'VeryLong',
  Long = 'Long',
  Intermediate = 'Intermediate',
  Short = 'Short',
}

export enum HighLowScale {
  VeryHigh = 'VeryHigh',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  VeryLow = 'VeryLow',
}

export enum InvestorTypeKind {
  Accredited = 'Accredited',
  Eligible = 'Eligible',
  None = 'None',
}

export enum AccreditedReason {
  /** @desc owns assets combined with spouse net value at 1M ~ 5M */
  ByHighJointAssets = 'ByHighJointAssets',
  /** @desc owns investments combined with spouse net value at 5M+ */
  ByHighJointInvestments = 'ByHighJointInvestments',
  /** @desc annual income of 200k+ alone for past 2 recent years */
  ByHighSelfIncome = 'ByHighSelfIncome',
  /** @desc annual income of 300k+ combined with spouse for past 2 recent years */
  ByHighJointIncome = 'ByHighJointIncome',
  /** @desc registered under legislation of authorities; ie: Canadian Adviser/Dealer */
  ByAuthorityApproval = 'ByAuthorityApproval',
  /** @desc Not applicable */
  NotApplicable = 'NotApplicable',
}

export enum EligibleReason {
  /** @desc owns assets combined with spouse net value at 400k+ */
  ByHighJointAssets = 'ByHighJointAssets',
  /** @desc annual income of 75k+ alone for past 2 recent years */
  ByHighSelfIncome = 'ByHighSelfIncome',
  /** @desc annual income of 125k+ combined with spouse for past 2 recent years */
  ByHighJointIncome = 'ByHighJointIncome',
  /** @desc Not applicable */
  NotApplicable = 'NotApplicable',
}

export enum TaxResidentInOtherCountry {
  NotApplicable = 'NotApplicable',
  WithTIN = 'WithTIN',
  WithoutTIN = 'WithoutTIN',
}

export enum MissingTINReason {
  NotReceived = 'NotReceived',
  NotIssuable = 'NotIssuable',
  Other = 'Other',
}

export type Financial = {
  // financial base & investment plan
  investmentReason: InvestmentReason
  withdrawalTimeline: TimeScale.Long | TimeScale.Intermediate | TimeScale.Short
  balance: {
    annualIncome: number
    liquidAssets: number
    fixedAssets: number
    liabilities: number
  }
  taxInfo: {
    taxResidentInOtherCountry: TaxResidentInOtherCountry
    hasCertifiedDetails: boolean
    details?: {
      country?: string
      taxIdNumber?: string
      reasonForNoTIN?: MissingTINReason
    }
  }

  // investment style
  investmentKnowledge: HighLowScale
  investmentExperience: TimeScale
  riskAppetite: HighLowScale

  // investor type
  investorType: {
    kind: InvestorTypeKind
    accredited: AccreditedReason
    eligible?: EligibleReason
  }

  // result for reviewer
  results?: {
    assessedBase: number
    /**
     * @description
     * The Personal Investment Risk Score Determines
     * a Six-Month Investment Probability Range of Investment Results
     * that the Investor Can Be Comfortable With.
     */
    riskScore: number
    limit: number
    cap: number
  }
}
