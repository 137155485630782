import { HttpMethod } from '@guiker/http'
import { routesBuilder } from '@guiker/shared-framework'

import { ListingInquiry, MultipostListingInquiry } from '../../entity'
import { paths } from './paths'
import {
  createInquirySchema,
  listingInquiryIdPathParamSchema,
  readOneListingInquirySchema,
  updateInquiryOptionIdSchema,
  updateInquirySchema,
} from './schemas'

const base = routesBuilder<ListingInquiry>()({ basePath: paths.listingInquiry.all.base() })
const idNested = routesBuilder<ListingInquiry>()({ basePath: paths.listingInquiry.all.withId() })

export const routes = {
  readLatestListingInquiry: base.buildGet({
    path: '/listing/:listingId/latest',
  }),
  updateListingInquiryOptionId: idNested.buildPut({
    path: '/optionId',
    payloadSchema: updateInquiryOptionIdSchema,
  }),
  createListingInquiry: {
    path: paths.listingInquiry.one.base(),
    method: HttpMethod.POST,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as ListingInquiry),
    payloadValidator: (payload: unknown) => createInquirySchema.validate(payload),
  },
  readOneMultipostListingInquiry: {
    path: `${paths.multipostListingInquiry.one.withId(':listingInquiryId')}`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as MultipostListingInquiry),
    pathParamsValidator: (params: unknown) => listingInquiryIdPathParamSchema.validate(params),
  },
  readOneListingInquiry: {
    path: `${paths.listingInquiry.one.base()}/rentalOption/:rentalOptionId`,
    method: HttpMethod.GET,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as ListingInquiry),
    pathParamsValidator: (payload: unknown) => readOneListingInquirySchema.validate(payload),
  },
  updateListingInquiry: {
    path: `${paths.listingInquiry.one.withId(':listingInquiryId')}`,
    method: HttpMethod.PUT,
    authenticated: true,
    responseValidator: (payload: unknown) => Promise.resolve(payload as ListingInquiry),
    pathParamsValidator: (params: unknown) => listingInquiryIdPathParamSchema.validate(params),
    payloadValidator: (payload: unknown) => updateInquirySchema.validate(payload),
  },
}
