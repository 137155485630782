import React from 'react'

import { getGraphColorKey, makeStyles, toPx } from '@guiker/components-core'
import { Flex, P } from '@guiker/components-library'
import { money } from '@guiker/money'
import { getPositionStats, Portfolio } from '@guiker/propsharing-shared'
import { PieChart } from '@guiker/react-chart'

import { useT } from '../../i18n'

const useStyles = makeStyles(() => ({
  pieChart: {
    minHeight: 260,
    minWidth: 380,
    fontSize: toPx(14),
  },
}))

type PortfolioValuePieChartProps = {
  portfolio: Portfolio
}

export const PositionValuePieChart: React.FC<PortfolioValuePieChartProps> = ({ portfolio }) => {
  const { tShared } = useT({ entity: 'portfolio.positionValue' })
  const classes = useStyles()
  if (!portfolio) return

  let total = 0
  const dataset = portfolio?.map((position, index) => {
    const currency = position.stock.currency
    const positionStats = getPositionStats(position)
    const data = money.fromAmount(positionStats.netAssetValue, currency)
    total = Number((total + data.monetized).toFixed(data.currency.decimalPlace))

    return {
      key: position.stock.tickerSymbol,
      label: position.stock.tickerSymbol,
      color: getGraphColorKey(index),
      unit: money.getCurrencySymbol(currency, 'onlySymbol'),
      value: data.monetized,
    }
  })

  return (
    <Flex flexDirection='column'>
      <P mb={0}>{tShared('link')}</P>
      <Flex mt={2} className={classes.pieChart}>
        <PieChart
          dataset={dataset}
          total={total}
          highlight={{
            label: tShared('label'),
            unit: portfolio?.[0] && money.getCurrencySymbol(portfolio?.[0]?.stock.currency, 'onlySymbol'),
            value: total,
          }}
        />
      </Flex>
    </Flex>
  )
}
