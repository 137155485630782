import { validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'

import { InvestmentListing } from '../../entity/investment-listing'
import { paths } from './paths'
import { searchListingQuerySchema, SearchListingSchema, searchListingSchema } from './schemas'

export const routes = {
  readAllListing: {
    path: paths.listing.all.base(),
    method: HttpMethod.POST,
    queryParamsValidator: (queryParams: unknown) => searchListingQuerySchema.validate(queryParams),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<InvestmentListing, {}>(),
    payloadValidator: validatorBuilder.buildPayloadValidator<SearchListingSchema>(searchListingSchema),
  },
}
