import React, { useEffect, useRef, useState } from 'react'

import { Booking, BookingActions, BookingStatus, hasConfirmRequested } from '@guiker/booking-shared'
import { Flex, Note, PSmall, useTranslation } from '@guiker/react-framework'

import { useBookingContext } from '../../../../hooks'
import { TimerContextProvider, TimerCountdown, useTimer } from '../../..'
import { ApplicantTimerExtension } from './ApplicantTimerExtension'
import { TimerExtension } from './TimerExtension'

const tPrefix = 'screens.bookingModule.time'

type CountdownContentProp = {
  booking: Booking
}

const CountdownContent: React.FC<CountdownContentProp> = ({ booking }) => {
  const { t } = useTranslation(['main-booking'])
  const { timerState: state } = useTimer()
  const { bookingUser, can } = useBookingContext()

  const isConfirmRequested = hasConfirmRequested(booking)
  const [canUnitManagerExtendTimer, setCanUnitManagerExtendTimer] = useState(false)
  const [canApplicantExtendTimer, setCanApplicantExtendTimer] = useState(false)
  const isBookingExpired = booking?.status === BookingStatus.EXPIRED || state.status === 'expired'
  const isApplicant = bookingUser.type === 'applicant'

  const perPersonTPrefix = isApplicant
    ? `${tPrefix}.applicant.${state.status}`
    : `${tPrefix}.unitManager.${state.status}`

  const titleColor = state.status === 'expired' ? 'alert' : state.status === 'aboutToExpire' ? 'alert' : 'textPrimary'

  const description = t([`${perPersonTPrefix}.description`, ''])
  const title = t(`${perPersonTPrefix}.title`)

  useEffect(() => {
    if (booking && bookingUser) {
      if (isApplicant) {
        setCanApplicantExtendTimer(state.hours < 1 && !booking?.timer?.extension && !isBookingExpired)
      } else {
        setCanUnitManagerExtendTimer(!isConfirmRequested && can(BookingActions.ExtendCountdown))
      }
    }
  }, [booking, bookingUser, state.hours])

  const Content = (description || !isBookingExpired) && (
    <Flex gap={2} flexDirection='column'>
      {!isBookingExpired && <TimerCountdown />}
      {description && (
        <PSmall mb={0} color={60}>
          {description}
        </PSmall>
      )}
    </Flex>
  )

  const Actions = (
    <div>
      {canApplicantExtendTimer && <ApplicantTimerExtension />}
      {canUnitManagerExtendTimer && <TimerExtension hasExpired={isBookingExpired} />}
    </div>
  )

  if (isBookingExpired) {
    return (
      <Note statusColor='alert' condensed title={title} actions={Actions}>
        <Flex justifyContent='center'>{Content}</Flex>
      </Note>
    )
  }

  return (
    <TimerContextProvider timer={booking.timer}>
      <Flex gap={2} flexDirection='column'>
        <PSmall mb={0} color={titleColor}>
          {title}
        </PSmall>
        {Content}
        {Actions}
      </Flex>
    </TimerContextProvider>
  )
}

const Countdown: React.FC = () => {
  const { booking } = useBookingContext()
  const bookingRef = useRef<Booking>()

  useEffect(() => {
    bookingRef.current = booking
  }, [booking])

  return (
    <TimerContextProvider timer={bookingRef?.current?.timer}>
      {bookingRef.current && <CountdownContent booking={bookingRef.current} />}
    </TimerContextProvider>
  )
}

export { Countdown }
