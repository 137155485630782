import { HttpMethod } from '@guiker/http'

const BASE_PATH = '/webhook'

export const routes = {
  handleRentsyncInquiryReceived: {
    path: `${BASE_PATH}/rentsync/inquiry-received`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as unknown),
  },
  handleZillowInquiryReceived: {
    path: `${BASE_PATH}/zillow/inquiry-received`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as unknown),
  },
  handleCostarInquiryReceived: {
    path: `${BASE_PATH}/costar/inquiry-received`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as unknown),
  },
  handleEmailInquiryReceived: {
    path: `${BASE_PATH}/email/inquiry-received`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as unknown),
  },
  handleFacebookInquiryReceived: {
    path: `${BASE_PATH}/facebook/inquiry-received`,
    method: HttpMethod.POST,
    responseValidator: (response: unknown) => Promise.resolve(response as unknown),
    payloadValidator: (payload: unknown) => Promise.resolve(payload as unknown),
  },
}
