import React from 'react'

import { getLocationService } from '@guiker/base-entity'
import { FormSubSection, P, useTranslation, useWatch } from '@guiker/react-framework'
import { yup } from '@guiker/shared-framework'
import { GuarantorAddress, TenantApplication } from '@guiker/tenant-application-shared'

import { AddressForm } from '../../AddressForm'

type GuarantorAddressProps = {
  defaultValue: GuarantorAddress
  formPrefix: string
  schema?: yup.ObjectSchema
  readOnly?: boolean
}

const GuarantorAddress: React.FC<GuarantorAddressProps> = ({ defaultValue, formPrefix, schema, readOnly = false }) => {
  const { t } = useTranslation(['common', 'main-tenantApplication'])
  const tPrefix = 'main-tenantApplication:'
  const supportedCountries = getLocationService()
    .getCountries()
    .map((country) => country.countryCode)

  const hasGuarantor = useWatch<TenantApplication, 'hasGuarantor'>({ name: 'hasGuarantor' })
  const willInviteGuarantor = useWatch<TenantApplication, 'willInviteGuarantor'>({ name: 'willInviteGuarantor' })
  const guarantorCountry: string = useWatch<TenantApplication, 'guarantor.address.country'>({
    name: 'guarantor.address.country',
    defaultValue: defaultValue?.country,
  })

  const dependency = {
    hasGuarantor,
    willInviteGuarantor,
  }

  return (
    <FormSubSection
      title={t(`${tPrefix}screens.steps.guarantor.currentAddress.title`)}
      subtitle={!readOnly && t(`${tPrefix}screens.steps.guarantor.currentAddress.description`)}
    >
      <AddressForm
        defaultValue={defaultValue}
        formPrefix={formPrefix}
        schema={schema}
        readOnly={readOnly}
        dependency={dependency}
        label={t(`${tPrefix}screens.steps.backgroundCheck.legalAddress.label`)}
      />
      {guarantorCountry && !supportedCountries.includes(guarantorCountry) && (
        <P color={'alert'}>{t(`${tPrefix}screens.steps.guarantor.currentAddress.warning`)}</P>
      )}
    </FormSubSection>
  )
}

export { GuarantorAddress }
