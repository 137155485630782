import { EnvelopeStatus, ParticipantDocument, ParticipantStatus, Template } from '@guiker/document-shared'

type AdditionalDocument = {
  uid: string
  name: string
}

type BookingDocumentParticipant = {
  userId?: string
  documents?: ParticipantDocument[]
  status?: ParticipantStatus
}

export const INCOMPLETE_ENVELOPE_STATUS = [EnvelopeStatus.CREATED, EnvelopeStatus.DRAFTED]

type BookingDocument = {
  envelopeId: string
  templates?: Template[]
  status: EnvelopeStatus
  updatedAt: string
  participants: BookingDocumentParticipant[]
  documents?: AdditionalDocument[]
}

export { BookingDocument, AdditionalDocument, BookingDocumentParticipant, ParticipantDocument }
