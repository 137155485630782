import { yup } from '@guiker/yup-util'

import { FloorPlan, RoomCount } from '../../entity'
import { priceSchema } from './price-option-schema'

const priceRangeSchema = yup.object({
  min: priceSchema.required(),
  max: priceSchema.required(),
})

const sizeRangeSchema = yup.object({
  min: yup.string().required(),
  max: yup.string().required(),
})

export const roomCountSchema = yup.object<RoomCount>({
  bedroom: yup.number().required(),
  bathroom: yup.number().required(),
  kitchen: yup.number().required(),
  livingRoom: yup.number().required(),
  den: yup.number().nullable(),
})

export const floorPlanSchema = yup.object<FloorPlan>({
  roomCount: roomCountSchema.required(),
  priceRange: priceRangeSchema.required(),
  sizeRange: sizeRangeSchema.required(),
})
