import { idNestedParamsValidator, validatorBuilder } from '@guiker/api-definition'
import { HttpMethod } from '@guiker/http'
import { routesBuilder } from '@guiker/shared-framework'

import { PropSharingListingWithFundingData, StockAllowance, WithStockLatestFinancialStatements } from '../../entity'
import { searchListingQuerySchema, SearchListingSchema, searchListingSchema } from '../schemas'
import { paths } from './paths'

const oneListingPath = paths.listing.all.withId()

const { buildGet: StockAllowance } = routesBuilder<StockAllowance>()({
  basePath: paths.investorProfile.all.base(),
})

export const routes = {
  getAllowanceForStock: StockAllowance({
    path: paths.investorProfile.all.withScope('stock', ':stockId'),
  }),
  readOneListing: {
    path: oneListingPath,
    method: HttpMethod.GET,
    pathParamsValidator: idNestedParamsValidator,
    responseValidator: (res: unknown) =>
      Promise.resolve(res as WithStockLatestFinancialStatements<PropSharingListingWithFundingData>),
  },
  readAllListing: {
    path: paths.listing.all.base(),
    method: HttpMethod.POST,
    queryParamsValidator: (queryParams: unknown) => searchListingQuerySchema.validate(queryParams),
    responseValidator: validatorBuilder.buildPaginatedResponseValidator<PropSharingListingWithFundingData, {}>(),
    payloadValidator: validatorBuilder.buildPayloadValidator<SearchListingSchema>(searchListingSchema),
  },
}
