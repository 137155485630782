import { routesBuilder } from '@guiker/shared-framework'

import { PayInMethod, Transaction } from '../../../../entity'
import { paths } from '../paths'
import { checkBalancePayloadSchema } from '../schemas'

const basePath = paths.payInMethods.withId.path
const { buildPost, buildGet } = routesBuilder<PayInMethod>()({ basePath })

export const routes = {
  readOnePayInMethod: buildGet({}),
  checkBalance: buildPost({
    path: '/check-balance',
    payloadSchema: checkBalancePayloadSchema,
    response: null as Transaction,
  }),
} as const
