import React from 'react'

import { mainPathBuilder } from '@guiker/investment-shared'
import { webappRoutes } from '@guiker/main-webapp-shared'

import { useT } from '../../../i18n'
import { HeroBox } from '../HeroBox'

export const InvestHeroBox: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.home.hero.invest' })

  return (
    <HeroBox
      title={tMain('title')}
      subtitle={tMain('subtitle')}
      primaryCta={{
        label: tMain('cta'),
        path: mainPathBuilder.root.path,
      }}
      secondaryCta={{
        label: tMain('secondaryCta'),
        path: webappRoutes.Invest.learnMore().path,
      }}
    />
  )
}
